import React from 'react'

const StagingIndicator = props => {
  return (
    <div className='ta-staging__indicator'>
      <div className='ta-staging__indicator__top' />
      <div className='ta-staging__indicator__bottom' />
      <div className='ta-staging__indicator__left' />
      <div className='ta-staging__indicator__right' />
    </div>
  )
}

export default StagingIndicator
