import React from 'react'
import { PopupServicesImportOptions } from '../../../Beauties'

export const PopupServicesImportOptionsWrapper = props => {
  // to be refactored if used
  let { id, closePopup } = props
  const { companyId } = id || {}

  return <PopupServicesImportOptions companyId={companyId} closePopup={closePopup} />
}

export default PopupServicesImportOptionsWrapper
