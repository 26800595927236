import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { ServiceChangedLocallyRestore } from '../../Beauties'

const ServiceChangedLocallyRestoreWrapper = props => {
  const { pending, form, service, branch } = props
  const isCombination = service?.combinationServiceIds && !!service?.combinationServiceIds?.length

  useEffect(() => {
    handlers.serviceRestoreFormGet(service)
  }, [])

  const onSubmit = () => {
    if (!branch) {
      let { locallyUpdatedBranches } = service
      locallyUpdatedBranches = locallyUpdatedBranches || {}
      handlers.serviceLocallyChangedReset({
        companyIds: Object.keys(locallyUpdatedBranches),
        internalId: service.internalId
      })
    } else {
      handlers.serviceLocallyChangedReset({
        companyIds: [branch.id],
        internalId: service.internalId
      })
    }
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServiceChangedLocallyRestore
          title={t('globals.restore.form.heading', [{
            key: 'ELEMENT',
            value: isCombination ? t('servicesGroups.list.serviceCombination.headline') : t('global.service')
          }])}
          cancelLink={`/management/services/${service.id}`}
          onSubmit={onSubmit}
          form={form}
          service={service}
          branch={branch}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  branch: (state.router.data.branchId && state.branches.list.find(b => b.id === state.router.data.branchId)),
  service: (state.router.data.id && state.services.list.find(r => r.id === state.router.data.id)) || {},
  form: state.forms.serviceChangedLocally,
  pending: state.services.pendingForm
})

export default memo(connect(maps)(ServiceChangedLocallyRestoreWrapper))
