import moment from 'moment'
import { store } from '../../../Store'
import { translateServerCode, validator, getOS } from '../../../Utils'

export const transform = statistics => {
  if (!statistics) return

  return {
    ...statistics,
    revenueService: statistics.revenueService && JSON.parse(statistics.revenueService),
    eventsByDayOfWeek: statistics.eventsByDayOfWeek && JSON.parse(statistics.eventsByDayOfWeek),
    eventsByHours: statistics.eventsByHours && JSON.parse(statistics.eventsByHours),
    topBranchesByEvents: statistics.topBranchesByEvents && JSON.parse(statistics.topBranchesByEvents)
  }
}

export const dashboardFilterFormPopulate = labels => {
  const {
    lifetime: lifetimeLabel,
    month: monthLabel,
    until: untilLabel
  } = labels
  let yearsOptions = []
  const now = moment()
  const currentYear = now.year()
  const currentMonth = now.month() + 1
  const currentDay = now.format('YYYY-MM-DD')
  const prevYear = currentYear - 1
  const previousMonth = currentMonth - 1 || 12
  const prevDay = moment().subtract(1, 'day').format('DD MMM')
  const months = moment.months().map((item, i) => ({ label: item, value: moment().month(i).format('MM'), i: i + 1 }))
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
  const isFirstDayOfMonth = currentDay === firstDayOfCurrentMonth
  const lastDayOfLastMonth = moment().subtract(1, 'day').format('DD MMM')
  let monthsOptions = months.filter(item => item.i <= (isFirstDayOfMonth ? previousMonth : currentMonth)).map((item, i) => item.i === (isFirstDayOfMonth ? previousMonth : currentMonth)
    ? {
      label: `${item.label} (${untilLabel} ${isFirstDayOfMonth ? lastDayOfLastMonth : prevDay})`,
      value: item.value,
      i: item.i
    }
    : item
  )
  // start of calculation of statistics November, 2020. Disable all months + years before that
  if (currentYear === 2020 || (currentYear === 2021 && isFirstDayOfMonth && currentMonth === 1)) monthsOptions = monthsOptions.filter((item, i) => i > 9)
  for (let year = (isFirstDayOfMonth && currentMonth === 1) ? prevYear : currentYear; year >= 2020; year--) yearsOptions.push({ label: year.toString(), value: year.toString() })

  return {
    type: {
      value: 'lifetime',
      options: [
        { label: lifetimeLabel, value: 'lifetime' },
        { label: monthLabel, value: 'months' }
      ]
    },
    year: {
      value: yearsOptions[0].value,
      options: yearsOptions
    },
    month: {
      value: monthsOptions.find(item => item.i === (isFirstDayOfMonth ? previousMonth : currentMonth)).value,
      options: monthsOptions
    }
  }
}

export const dashboardMarketingFormPopulate = ({
  campaign,
  content,
  source,
  medium,
  utmTerm
}) => {
  return {
    type: {
      value: 'utmCampaign',
      options: [
        { label: campaign, value: 'utmCampaign' },
        { label: content, value: 'utmContent' },
        { label: source, value: 'utmSource' },
        { label: medium, value: 'utmMedium' },
        { label: utmTerm, value: 'utmTerm' }
      ]
    }
  }
}

export const dashboardMostBookedResourcesFilterFormPopulate = ({ allKey, dependenciesKey, bookableKey }) => {
  return {
    type: {
      value: 'topBookingResources',
      options: [
        { label: allKey, value: 'topBookingResources' },
        { label: bookableKey, value: 'topBookingBookableResources' },
        { label: dependenciesKey, value: 'topBookingDependencyResources' }
      ]
    }
  }
}

export const dashboardPeakSignUpTimesFilterFormPopulate = () => {
  return {
    type: {
      value: false
    }
  }
}

export const getDashboardFiltersFromState = () => {
  const state = store.getState()
  const dashboardFiltersForm = ((state.forms && state.forms.dashboardFilters) && state.forms.dashboardFilters) || {}

  return dashboardFiltersForm
}

export const formatDashboardStatisticsFilter = (statsMode, getPrevMonth) => {
  const now = moment()
  const currentYear = now.format('YYYY')
  const currentMonth = now.format('MM')
  const currentDay = now.format('YYYY-MM-DD')
  const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
  const isFirstDayOfMonth = currentDay === firstDayOfCurrentMonth
  const { year, month } = getDashboardFiltersFromState()
  const { value: dashboardFiltersYear } = year || {}
  const { value: dashboardFiltersMonth, options: monthOptions } = month || {}
  const currentYearAndMonth = currentYear === dashboardFiltersYear && currentMonth === dashboardFiltersMonth
  const previousMonthIndex = (monthOptions.find(item => item.value === dashboardFiltersMonth) || {}).i
  const previousMonth = moment().month(previousMonthIndex - 2).format('MM')
  const differenceInFilteredMonths = parseFloat(currentMonth) - parseFloat(dashboardFiltersMonth)
  if (isFirstDayOfMonth) {
    return (differenceInFilteredMonths > 1 || differenceInFilteredMonths < 0)
      ? {
        filter: {
          year: (previousMonth === '12' && getPrevMonth) || (previousMonth === '12' && isFirstDayOfMonth) ? `${dashboardFiltersYear - 1}` : dashboardFiltersYear,
          month: getPrevMonth ? previousMonth : dashboardFiltersMonth,
          statisticsMode: 'MONTHLY'
        }
      }
      : {
        filter: {
          year: (previousMonth === '12' && getPrevMonth) || (previousMonth === '12' && isFirstDayOfMonth) ? `${dashboardFiltersYear - 1}` : dashboardFiltersYear,
          month: dashboardFiltersMonth,
          statisticsMode: getPrevMonth ? 'MONTHLY' : statsMode
        }
      }
  }
  return currentYearAndMonth
    ? { filter: { statisticsMode: statsMode } }
    : {
      filter: {
        year: (previousMonth === '12' && getPrevMonth) || (previousMonth === '12' && isFirstDayOfMonth) ? `${dashboardFiltersYear - 1}` : dashboardFiltersYear,
        month: getPrevMonth ? previousMonth : dashboardFiltersMonth,
        statisticsMode: 'MONTHLY'
      }
    }
}

// Export

export const dashboardExportFormTransform = ({ hash, translations, dashboardFilters }) => {
  const { year, month } = dashboardFilters || {}

  return {
    year,
    month,
    exportMode: {
      value: 'ALL'
    },
    exportBookings: {
      value: false
    },
    exportLocations: {
      value: false
    },
    exportFinances: {
      value: false
    },
    exportResources: {
      value: false
    },
    exportCustomers: {
      value: false
    }
  }
}

export const dashboardExportFormValidate = form => {
  if (!form) return
  const {
    exportMode,
    exportBookings,
    exportLocations,
    exportFinances,
    exportResources,
    exportCustomers
  } = form || {}
  const exportModeValue = exportMode && exportMode.value
  const exportBookingsValue = exportBookings && exportBookings.value
  const exportLocationsValue = exportLocations && exportLocations.value
  const exportFinancesValue = exportFinances && exportFinances.value
  const exportResourcesValue = exportResources && exportResources.value
  const exportCustomersValue = exportCustomers && exportCustomers.value
  const rules = [
    { 'year.value': ['required'] },
    { 'month.value': ['required'] }
  ]
  const messages = {
    year: {
      required: 'global.year.label'
    },
    month: {
      required: 'global.month.label'
    }
  }
  const errors = validator(form, rules, messages)

  if (
    exportModeValue === 'SOME' &&
    !exportBookingsValue &&
    !exportLocationsValue &&
    !exportFinancesValue &&
    !exportResourcesValue &&
    !exportCustomersValue
  ) {
    errors.push({ key: 'globalErrors', value: 'errors.statistics.export.categories.atLeastOne' })
  }

  return errors.length && errors
}

export const dashboardExportFormSaveTransform = (form) => {
  const {
    exportMode,
    exportBookings,
    exportLocations,
    exportCustomers,
    exportFinances,
    exportResources,
    month,
    year,
    type
  } = form || {}
  const exportModeValue = exportMode && exportMode.value
  const exportBookingsValue = exportBookings && exportBookings.value
  const exportLocationsValue = exportLocations && exportLocations.value
  const exportCustomersValue = exportCustomers && exportCustomers.value
  const exportFinancesValue = exportFinances && exportFinances.value
  const exportResourcesValue = exportResources && exportResources.value
  const monthValue = month && month.value
  const yearValue = year && year.value
  const typeValue = type && type.value
  let statisticsSections = []
  if (exportModeValue === 'ALL') statisticsSections = ['RESOURCE', 'BOOKING', 'LOCATION', 'CUSTOMER', 'REVENUE']
  if (exportModeValue === 'SOME' && exportResourcesValue) statisticsSections.push('RESOURCE')
  if (exportModeValue === 'SOME' && exportBookingsValue) statisticsSections.push('BOOKING')
  if (exportModeValue === 'SOME' && exportLocationsValue) statisticsSections.push('LOCATION')
  if (exportModeValue === 'SOME' && exportCustomersValue) statisticsSections.push('CUSTOMER')
  if (exportModeValue === 'SOME' && exportFinancesValue) statisticsSections.push('REVENUE')
  let statisticsMode = 'MONTHLY'
  if (typeValue === 'lifetime') statisticsMode = 'OVERALL'

  const result = {
    fileTypeName: getOS() === 'Apple' ? 'CSV' : 'EXCEL',
    statisticsSections,
    filter: {
      month: monthValue,
      year: yearValue,
      statisticsMode
    }
  }
  return result
}

export const dashboardExportFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }

  return errors
}
