import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { Loader, t } from '../../../Common'
import { SecuritySettingsEditForm } from '../../../Beauties'

const SecuritySettingsEditFormWrapper = props => {
  let {
    pending,
    form,
    settings,
    isInactivityLoggedOut,
    auth2FAs,
    hash,
    account
  } = props
  form = form || {}
  account = account || {}
  settings = settings || {}
  const accountUses2FA = auth2FAs && auth2FAs.length && auth2FAs[0] && auth2FAs[0].isActive
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsWrite } = enterprisePermissions || {}
  settingsWrite = !!isAdmin || !!settingsWrite

  const [initial] = useState({
    settings,
    settingsWrite
  })

  // ComponentDidMount
  useEffect(() => {
    let { settings, settingsWrite } = initial
    settings = settings || {}
    settingsWrite = !!settingsWrite
    settingsWrite ? handlers.settingsSecurityFormPopulate(settings) : handlers.navigateToPath('/settings/security')
  }, [initial])

  const onSubmit = cb => {
    handlers.settingsSecurityFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <SecuritySettingsEditForm
          title={t('settings.section.security.form.title')}
          cancelLink='/settings/security'
          onSubmit={onSubmit}
          isInactivityLoggedOut={isInactivityLoggedOut}
          accountUses2FA={accountUses2FA}
          settings={settings}
          hash={hash}
        />
      )}
    </>
  )
}

SecuritySettingsEditFormWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  form: state.forms.settingsSecurity,
  settings: state.company.settings,
  pending: state.settings.pendingForm,
  auth2FAs: state.account && state.account.auth2FAs,
  hash: state.router.hash,
  isInactivityLoggedOut: state.forms.settingsSecurity && state.forms.settingsSecurity.isInactivityLoggedOut && state.forms.settingsSecurity.isInactivityLoggedOut.value,
  account: state.account
})

export default connect(maps)(SecuritySettingsEditFormWrapper)
