import React from 'react'
import PropTypes from 'prop-types'
import {
  FontAwesome5,
  Title,
  Loader,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  Input,
  t
} from '../../Common'
import { AccountsList, AccountsItem } from '../../Beauties'

import './Accounts.css'

const Accounts = props => {
  const {
    pending,
    company,
    confirmedAccounts,
    pendingAccounts,
    onAccountChange,
    onResendEmailClick,
    showSearch,
    search
  } = props
  const filteredCofirmedAccounts = confirmedAccounts.filter(item =>
    (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
    (item.address && item.address.formatted && item.address.formatted.toLowerCase().includes(search.toLowerCase()))
  )
  const filteredPendingAccounts = pendingAccounts.filter(item =>
    (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
    (item.address && item.address.formatted && item.address.formatted.toLowerCase().includes(search.toLowerCase()))
  )

  return (
    <div className='ta-accounts'>
      <FixedContent>
        <FixedContentHeader theme='blank'>
          <div className='ta-accounts__title'>
            <FontAwesome5 icon='store-alt' type='solid' />
            {t('accountSelector.heading')}
          </div>
          <Title size='m' label={t('accountSelector.currentSession')} />
          <AccountsItem account={company} selected />
        </FixedContentHeader>
        <FixedContentBody>
          <Loader active={pending} />
          {(showSearch &&
            <Form name='accountsSearch'>
              <Input
                name='search'
                label={t('accountSelector.searchAccount.label')}
                clearable
              />
            </Form>
          )}
          {(filteredCofirmedAccounts.length > 0 &&
            <>
              <Title size='m' label={t('accountSelector.available')} />
              <AccountsList accounts={filteredCofirmedAccounts} onAccountChange={onAccountChange} />
            </>
          )}
          {(filteredPendingAccounts.length > 0 &&
            <>
              <Title size='m' label={t('accountSelector.invitationPending')} />
              <AccountsList accounts={filteredPendingAccounts} onResendEmailClick={onResendEmailClick} />
            </>
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

Accounts.propTypes = {
  pending: PropTypes.bool.isRequired,
  message: PropTypes.object
}

export default Accounts
