import React from 'react'
import { Alert, Button, t } from '../../Common'

export default () => {
  return (
    <div className='ta-route-not-found'>
      <Alert noOffset label={t('global.pageNotFound')} theme='no-content'>
        <Button as='link' to='/' isPrimary>Back to home</Button>
      </Alert>
    </div>
  )
}
