import React from 'react'
import { APP_VERSION, APP_VERSION_SUFFIX } from '../../Settings'

import './VersionIndicator.css'

const VersionIndicator = props => {
  return (
    <div className='ta-version-indicator'>
      <p className='ta-version-indicator__text'>v{APP_VERSION}-{APP_VERSION_SUFFIX}</p>
    </div>
  )
}

export default VersionIndicator
