import { translateServerCode, validator } from '../../../Utils'

// List

export const permissionTypesListErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// One

export const permissionTypeDetailErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// Form

export const permissionTypeFormTransform = (permissionType, branches, account) => {
  branches = branches || []
  let {
    permissions,
    name,
    externalId,
    color,
    icon,
    id,
    allowedBranchExternalIds,
    allowedBranchIds
  } = permissionType || {}
  allowedBranchIds = allowedBranchIds || []
  allowedBranchExternalIds = allowedBranchExternalIds || []
  permissions = permissions || {}
  let { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  let {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !id
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []

  const defaultCustomPermissions = {
    // Selected resources
    selectedResources: [],
    // Account
    accountRead: false,
    // Calendar
    calendarRead: true,
    calendarWrite: true,
    calendarDelete: true,
    // ShiftPlan
    shiftPlanRead: true,
    shiftPlanWrite: false,
    shiftPlanDelete: false,
    // Statistics
    statisticsRead: false,
    // // Notifications
    notificationsRead: true,
    // Customers
    customersRead: true,
    customersWrite: true,
    customersDelete: true,
    // Resources
    resourcesRead: true,
    // Services and groups
    servicesRead: true,
    servicesWrite: false,
    servicesDelete: false,
    // Apps
    appsRead: false,
    // Permission Types
    permissionsGroupsRead: true
  }

  const rules = {
    // Account
    permissionsAccountRead: {
      value: permissions.accountRead !== undefined
        ? !!permissions.accountRead
        : defaultCustomPermissions.accountRead
    },
    // Calendar
    permissionsCalendarRead: {
      value: permissions.calendarReadSet !== undefined
        ? !!permissions.calendarReadSet
        : defaultCustomPermissions.calendarRead
    },
    permissionsCalendarReadOptions: {
      value: (permissions.calendarReadSet && (
        permissions.calendarReadSet.specificResourceCategoryIds && permissions.calendarReadSet.specificResourceCategoryIds.length === 0 &&
        permissions.calendarReadSet.specificResourceIds && permissions.calendarReadSet.specificResourceIds.length === 0
      )) || !Object.keys(permissions).length ? 'all' : 'selected'
    },
    permissionsCalendarReadCategories: {
      values: (permissions.calendarReadSet && permissions.calendarReadSet.specificResourceCategoryIds) || []
    },
    permissionsCalendarReadItems: {
      values: (permissions.calendarReadSet && permissions.calendarReadSet.specificResourceIds) || []
    },
    permissionsCalendarWrite: {
      value: permissions.calendarWrite !== undefined
        ? !!permissions.calendarWrite
        : defaultCustomPermissions.calendarWrite
    },
    permissionsCalendarDelete: {
      value: permissions.calendarDelete !== undefined
        ? !!permissions.calendarDelete
        : defaultCustomPermissions.calendarDelete
    },
    // ShiftPlan
    permissionsShiftPlanRead: {
      value: permissions.shiftRead !== undefined
        ? !!permissions.shiftRead
        : defaultCustomPermissions.shiftPlanRead
    },
    permissionsShiftPlanWrite: {
      value: permissions.shiftWrite !== undefined
        ? !!permissions.shiftWrite
        : defaultCustomPermissions.shiftWrite
    },
    permissionsShiftPlanDelete: {
      value: permissions.shiftDelete !== undefined
        ? !!permissions.shiftDelete
        : defaultCustomPermissions.shiftDelete
    },
    // Statistics
    permissionsStatisticsRead: {
      value: permissions.statisticsRead !== undefined
        ? !!permissions.statisticsRead
        : defaultCustomPermissions.statisticsRead
    },
    // Notifications
    permissionsNotificationsRead: {
      value: permissions.notificationsRead !== undefined
        ? !!permissions.notificationsRead
        : defaultCustomPermissions.notificationsRead
    },
    // Customers
    permissionsCustomersRead: {
      value: permissions.customersReadSet !== undefined
        ? !!permissions.customersReadSet
        : defaultCustomPermissions.customersRead
    },
    permissionsCustomersReadOptions: {
      value: (permissions.customersReadSet && (
        permissions.customersReadSet.specificResourceCategoryIds && permissions.customersReadSet.specificResourceCategoryIds.length === 0 &&
        permissions.customersReadSet.specificResourceIds && permissions.customersReadSet.specificResourceIds.length === 0
      )) || !Object.keys(permissions).length ? 'all' : 'selected'
    },
    permissionsCustomersReadCategories: {
      values: (permissions.customersReadSet && permissions.customersReadSet.specificResourceCategoryIds) || []
    },
    permissionsCustomersReadItems: {
      values: (permissions.customersReadSet && permissions.customersReadSet.specificResourceIds) || []
    },
    permissionsCustomersWrite: {
      value: permissions.customersWrite !== undefined
        ? !!permissions.customersWrite
        : defaultCustomPermissions.customersWrite
    },
    permissionsCustomersDelete: {
      value: permissions.customersDelete !== undefined
        ? !!permissions.customersDelete
        : defaultCustomPermissions.customersDelete
    },
    // Resources
    permissionsResourcesRead: {
      value: permissions.resourcesReadSet !== undefined
        ? !!permissions.resourcesReadSet
        : defaultCustomPermissions.resourcesRead
    },
    permissionsResourcesReadOptions: {
      value:
        (permissions.resourcesReadSet && (
          permissions.resourcesReadSet.specificResourceCategoryIds && permissions.resourcesReadSet.specificResourceCategoryIds.length === 0 &&
          permissions.resourcesReadSet.specificResourceIds && permissions.resourcesReadSet.specificResourceIds.length === 0
        )) || !Object.keys(permissions).length ? 'all' : 'selected'
    },
    permissionsResourcesWrite: {
      value: permissions.resourcesWrite !== undefined
        ? !!permissions.resourcesWrite
        : defaultCustomPermissions.resourcesWrite
    },
    permissionsResourcesDelete: {
      value: permissions.resourcesDelete !== undefined
        ? !!permissions.resourcesDelete
        : defaultCustomPermissions.resourcesDelete
    },
    permissionsResourcesReadCategories: {
      values: (permissions.resourcesReadSet && permissions.resourcesReadSet.specificResourceCategoryIds) || []
    },
    permissionsResourcesReadItems: {
      values: (permissions.resourcesReadSet && permissions.resourcesReadSet.specificResourceIds) || []
    },
    // Services and groups
    permissionsServicesRead: {
      value: permissions.servicesRead !== undefined
        ? !!permissions.servicesRead
        : defaultCustomPermissions.servicesRead
    },
    permissionsServicesWrite: {
      value: permissions.servicesWrite !== undefined
        ? !!permissions.servicesWrite
        : defaultCustomPermissions.servicesWrite
    },
    permissionsServicesDelete: {
      value: permissions.servicesDelete !== undefined
        ? !!permissions.servicesDelete
        : defaultCustomPermissions.servicesDelete
    },
    // Apps
    permissionsAppsRead: {
      value: permissions.appsRead !== undefined
        ? !!permissions.appsRead
        : defaultCustomPermissions.appsRead
    },
    // Permission Types
    permissionsGroupsRead: {
      value: true
    },
    name: {
      value: name || ''
    },
    externalId: {
      value: externalId || ''
    },
    ownExternalId: externalId, // needed for external id validation
    icon: {
      value: icon || 'user'
    },
    color: {
      value: color || '#82a774'
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }

  if (id) {
    rules.id = id
  }

  return rules
}

export const permissionTypeSaveTransform = (permissionType, resources) => {
  permissionType = permissionType || {}
  resources = resources || []
  const permissions = {}

  // Account
  permissions.accountRead = false
  if (permissionType.permissionsAccountRead.value) {
    permissions.accountRead = true
  }
  // Calendar
  permissions.calendarReadSet = null
  permissions.calendarWrite = false
  permissions.calendarDelete = false
  if (permissionType.permissionsCalendarRead.value) {
    permissions.calendarReadSet = {
      specificResourceCategoryIds: permissionType.permissionsCalendarReadOptions.value === 'all'
        ? []
        : permissionType.permissionsCalendarReadCategories.values.length > 0
          ? permissionType.permissionsCalendarReadCategories.values
          : null,
      specificResourceIds: permissionType.permissionsCalendarReadOptions.value === 'all'
        ? []
        : permissionType.permissionsCalendarReadItems.values.length > 0
          ? permissionType.permissionsCalendarReadItems.values.filter(item => {
            const resource = resources.find(resourceItem => resourceItem.id === item) || {}
            return !permissionType.permissionsCalendarReadCategories.values.includes(resource.categoryId)
          })
          : null
    }
    if (permissionType.permissionsCalendarWrite.value) {
      permissions.calendarWrite = true
      if (permissionType.permissionsCalendarDelete.value) {
        permissions.calendarDelete = true
      }
    }
  }
  // ShiftPlan
  permissions.shiftRead = false
  permissions.shiftWrite = false
  permissions.shiftDelete = false
  if (permissionType.permissionsShiftPlanRead.value) {
    permissions.shiftRead = true
    if (permissionType.permissionsShiftPlanWrite.value) {
      permissions.shiftWrite = true
      if (permissionType.permissionsShiftPlanDelete.value) {
        permissions.shiftDelete = true
      }
    }
  }
  // Statistics
  permissions.statisticsRead = false
  if (permissionType.permissionsStatisticsRead.value) {
    permissions.statisticsRead = true
  }
  // Notifications
  permissions.notificationsRead = false
  if (permissionType.permissionsNotificationsRead.value) {
    permissions.notificationsRead = true
  }
  // Customers
  permissions.customersReadSet = null
  permissions.customersWrite = false
  permissions.customersDelete = false
  if (permissionType.permissionsCustomersRead.value) {
    permissions.customersReadSet = {
      specificResourceCategoryIds: permissionType.permissionsCustomersReadOptions.value === 'all'
        ? []
        : permissionType.permissionsCustomersReadCategories.values.length > 0
          ? permissionType.permissionsCustomersReadCategories.values
          : null,
      specificResourceIds: permissionType.permissionsCustomersReadOptions.value === 'all'
        ? []
        : permissionType.permissionsCustomersReadItems.values.length > 0
          ? permissionType.permissionsCustomersReadItems.values.filter(item => {
            const resource = resources.find(resourceItem => resourceItem.id === item) || {}
            return !permissionType.permissionsCustomersReadCategories.values.includes(resource.categoryId)
          })
          : null
    }
    if (permissionType.permissionsCustomersWrite.value) {
      permissions.customersWrite = true
      if (permissionType.permissionsCustomersDelete.value) {
        permissions.customersDelete = true
      }
    }
  }
  // Resources
  permissions.resourcesReadSet = null
  permissions.resourcesWrite = false
  permissions.resourcesDelete = false
  if (permissionType.permissionsResourcesRead.value) {
    permissions.resourcesReadSet = {
      specificResourceCategoryIds: permissionType.permissionsResourcesReadOptions.value === 'all'
        ? []
        : permissionType.permissionsResourcesReadCategories.values.length > 0
          ? permissionType.permissionsResourcesReadCategories.values
          : null,
      specificResourceIds: permissionType.permissionsResourcesReadOptions.value === 'all'
        ? []
        : permissionType.permissionsResourcesReadItems.values.length > 0
          ? permissionType.permissionsResourcesReadItems.values.filter(item => {
            const resource = resources.find(resourceItem => resourceItem.id === item) || {}
            return !permissionType.permissionsResourcesReadCategories.values.includes(resource.categoryId)
          })
          : null
    }
    if (permissionType.permissionsResourcesWrite.value) {
      permissions.resourcesWrite = true
      if (permissionType.permissionsResourcesDelete.value) {
        permissions.resourcesDelete = true
      }
    }
  }
  // Apps
  permissions.appsRead = false
  if (permissionType.permissionsAppsRead.value) {
    permissions.appsRead = true
  }
  // Permission Types
  permissions.permissionsGroupsRead = false
  if (permissionType.permissionsGroupsRead.value) {
    permissions.permissionsGroupsRead = true
  }

  const result = {
    name: permissionType.name.value,
    icon: permissionType.icon.value,
    externalId: permissionType.externalId.value,
    color: permissionType.color.value,
    permissions
  }

  if (permissionType.id) {
    result.id = permissionType.id
  }

  if (permissionType.location && permissionType.location.values && !permissionType.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...permissionType.location.values,
        ...(permissionType.location.insufficient || [])
      ]
    }
  }

  return result
}

export const permissionTypeFormServerErrorsTransform = ({ error, branchesList, translations }) => {
  const errors = []

  if (error.code === 'GlobalPermissionsGroupSaveError') {
    const { data } = error || {}
    let { localErrors } = data || {}
    localErrors = localErrors || []
    const branchesWithErrors = localErrors.map(item => {
      const company = branchesList.find(branch => branch.id === item.companyId) || {}
      return company.name
    }) || []
    const type = translations.resource
    errors.push({
      key: 'globalErrors',
      value: 'errors.servicesGroups.missingLocally',
      replace: [{
        key: 'TYPE', value: type
      }, {
        key: 'BRANCHES', value: branchesWithErrors.join(' <br/>')
      }]
    })
  } else if (error.code === 'InvalidBranchIds') {
    errors.push({ key: 'location', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

// Validators
export const permissionTypeFormValidate = (permissionType, externalIds) => {
  if (!permissionType) return

  const rules = [
    { 'name.value': ['required'] }
  ]

  const messages = {
    'name': {
      'required': 'errors.required'
    }
  }

  const errors = validator(permissionType, rules, messages)

  // external Id
  const externalIdValue = permissionType.externalId && permissionType.externalId.value
  externalIds = externalIds.filter(item => item !== permissionType.ownExternalId)
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

// Filter form
export const permissionTypeFilterTransform = ({ permissionTypes, branchesList, translations }) => {
  let branchIds = []
  permissionTypes.forEach(item => {
    if ((item.allowedBranchIds || []).length) branchIds = [...branchIds, ...item.allowedBranchIds]
  })
  const uniqueBranchIds = [...new Set(branchIds)]
  const branchOptions = branchesList
    .filter(item => uniqueBranchIds.includes(item.id))
    .map(item => ({ label: item.name, value: item.id }))
  const all = { label: translations.all, value: 'all' }

  return {
    branchName: {
      value: 'all',
      options: [all, ...branchOptions]
    }
  }
}
