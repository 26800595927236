import React from 'react'
import {
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  t
} from '../../../Common'

const ResourceCategoryPreviewDetails = props => {
  const { category } = props
  const { id, name, isDependency, externalId } = category || {}

  return (
    <div className='ta-resource-category__preview__details'>
      {(id &&
        <IconListItem icon='id-badge' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.categoryID')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{id}</IconListItemBody>
        </IconListItem>
      )}
      {(externalId &&
        <IconListItem icon='fingerprint'>
          <IconListItemTitle width='180px'>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      {(name &&
        <IconListItem icon='cube' iconType='solid'>
          <IconListItemTitle width='180px'>{t('global.name')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{name === 'default' ? t('branches.form.resource.permissions.sectionDefault.title') : name}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon='briefcase' iconType='solid'>
        <IconListItemTitle width='180px'>{t('global.type')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>
          <div className={`ta-resource-category--type ${isDependency ? 'dependency' : 'bookable'}`}>{isDependency ? t('global.dependency') : t('global.bookable')}</div>
        </IconListItemBody>
      </IconListItem>
    </div>
  )
}

export default ResourceCategoryPreviewDetails
