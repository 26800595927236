import React from 'react'
import { connect } from '../../../Store'
import { UsersFormCustomPermissions } from '../../../Beauties'

const UserFormCustomPermissionsWrapper = props => {
  let { users } = props
  users = users || []

  return (
    <UsersFormCustomPermissions {...props} users={users} />
  )
}

const maps = state => ({
  users: state.users.list,
  isOwner: state.account.isOwner,
  // Account
  permissionsAccountRead: state.forms.users && state.forms.users.permissionsAccountRead && state.forms.users.permissionsAccountRead.value,
  // Branches
  permissionsBranchesRead: state.forms.users && state.forms.users.permissionsBranchesRead && state.forms.users.permissionsBranchesRead.value,
  permissionsBranchesWrite: state.forms.users && state.forms.users.permissionsBranchesWrite && state.forms.users.permissionsBranchesWrite.value,
  // Tags
  permissionsTagsRead: state.forms.users && state.forms.users.permissionsTagsRead && state.forms.users.permissionsTagsRead.value,
  permissionsTagsWrite: state.forms.users && state.forms.users.permissionsTagsWrite && state.forms.users.permissionsTagsWrite.value,
  // Customers
  permissionsGlobalCustomersRead: state.forms.users && state.forms.users.permissionsGlobalCustomersRead && state.forms.users.permissionsGlobalCustomersRead.value,
  permissionsGlobalCustomersWrite: state.forms.users && state.forms.users.permissionsGlobalCustomersWrite && state.forms.users.permissionsGlobalCustomersWrite.value,
  // DataFields
  permissionsGlobalDataFieldsRead: state.forms.users && state.forms.users.permissionsGlobalDataFieldsRead && state.forms.users.permissionsGlobalDataFieldsRead.value,
  permissionsGlobalDataFieldsWrite: state.forms.users && state.forms.users.permissionsGlobalDataFieldsWrite && state.forms.users.permissionsGlobalDataFieldsWrite.value,
  // Tags
  permissionsGlobalTagsRead: state.forms.users && state.forms.users.permissionsGlobalTagsRead && state.forms.users.permissionsGlobalTagsRead.value,
  permissionsGlobalTagsWrite: state.forms.users && state.forms.users.permissionsGlobalTagsWrite && state.forms.users.permissionsGlobalTagsWrite.value,
  // Services
  permissionsGlobalServicesRead: state.forms.users && state.forms.users.permissionsGlobalServicesRead && state.forms.users.permissionsGlobalServicesRead.value,
  permissionsGlobalServicesWrite: state.forms.users && state.forms.users.permissionsGlobalServicesWrite && state.forms.users.permissionsGlobalServicesWrite.value,
  // Groups
  permissionsGlobalGroupsRead: state.forms.users && state.forms.users.permissionsGlobalGroupsRead && state.forms.users.permissionsGlobalGroupsRead.value,
  permissionsGlobalGroupsWrite: state.forms.users && state.forms.users.permissionsGlobalGroupsWrite && state.forms.users.permissionsGlobalGroupsWrite.value,
  // Resource Categories
  permissionsGlobalResourceCategoriesRead: state.forms.users && state.forms.users.permissionsGlobalResourceCategoriesRead && state.forms.users.permissionsGlobalResourceCategoriesRead.value,
  permissionsGlobalResourceCategoriesWrite: state.forms.users && state.forms.users.permissionsGlobalResourceCategoriesWrite && state.forms.users.permissionsGlobalResourceCategoriesWrite.value,
  // Global Permission Groups
  permissionsGlobalPermissionsGroupsRead: state.forms.users.permissionsGlobalPermissionsGroupsRead && state.forms.users.permissionsGlobalPermissionsGroupsRead.value,
  permissionsGlobalPermissionsGroupsWrite: state.forms.users.permissionsGlobalPermissionsGroupsWrite && state.forms.users.permissionsGlobalPermissionsGroupsWrite.value,
  // Settings
  permissionsSettingsRead: state.forms.users && state.forms.users.permissionsSettingsRead && state.forms.users.permissionsSettingsRead.value,
  permissionsSettingsWrite: state.forms.users && state.forms.users.permissionsSettingsWrite && state.forms.users.permissionsSettingsWrite.value,
  // Apps
  permissionsAppsRead: state.forms.users.permissionsAppsRead && state.forms.users.permissionsAppsRead.value,
  // Users
  permissionsUsersRead: state.forms.users && state.forms.users.permissionsUsersRead && state.forms.users.permissionsUsersRead.value,
  permissionsUsersWrite: state.forms.users && state.forms.users.permissionsUsersWrite && state.forms.users.permissionsUsersWrite.value,
  // Permission Groups
  permissionsPermissionsGroupsRead: state.forms.users.permissionsPermissionsGroupsRead && state.forms.users.permissionsPermissionsGroupsRead.value,
  permissionsPermissionsGroupsWrite: state.forms.users.permissionsPermissionsGroupsWrite && state.forms.users.permissionsPermissionsGroupsWrite.value,
  // Custom Access
  branchAccess: state.forms.users.branchAccess && state.forms.users.branchAccess.value,
  isBranchesAccessWhitelistDisabled: state.forms.users.branchesAccessWhitelist && state.forms.users.branchesAccessWhitelist.disabled,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist,
  isAdmin: state.account.isAdmin,
  canLogedInUserWriteUsers: state.account.enterprisePermissions.usersWrite
})

export default connect(maps)(UserFormCustomPermissionsWrapper)
