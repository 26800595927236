import md5 from 'md5'
import { getInitialsByName } from '../Utils'
import { EMOJIS_REGEX } from '../Settings'

export default (name = '') => {
  const initials = getInitialsByName(name)
  const clearedFromEmojis = initials.replace(new RegExp(EMOJIS_REGEX, 'ug'), '')

  return '#' + md5(escape(clearedFromEmojis)).substring(0, 6)
}
