import React from 'react'
import {
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  FontAwesome5,
  t
} from '../../../Common'

const CustomerFieldPreviewDetails = props => {
  const {
    id,
    externalId,
    category,
    description,
    color,
    label,
    type,
    isPublished,
    isMandatoryOffline,
    isMandatoryOnline,
    isActive,
    isUniqueKey,
    placeholder,
    hasOverwrite,
    displayOnHover,
    displayOnBookingForm
  } = props
  const shouldUseTranslation = category.translationKey && !category.name
  const categoryName = shouldUseTranslation ? t(category.translationKey) : category.name

  return (
    <div className='ta-customer-fields__preview__details'>
      <IconListItem icon='id-badge' iconType='regular'>
        <IconListItemTitle width='180px'>{t('global.customerFieldID')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{id}</IconListItemBody>
      </IconListItem>
      {(externalId &&
        <IconListItem icon='fingerprint'>
          <IconListItemTitle width='180px'>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      {(categoryName &&
        <IconListItem icon='folder' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.category')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{categoryName}</IconListItemBody>
        </IconListItem>
      )}
      {(label &&
        <IconListItem icon='list-ul' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.title')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{label}</IconListItemBody>
        </IconListItem>
      )}
      {(placeholder &&
        <IconListItem icon='sliders-h' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.placeholder')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{placeholder}</IconListItemBody>
        </IconListItem>
      )}
      {(type &&
        <IconListItem icon='sliders-h' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.type.label')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{t(`customerFields.fieldType.${type.toLowerCase()}`)}</IconListItemBody>
        </IconListItem>
      )}
      {(description &&
        <IconListItem icon='file' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.description')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{description}</IconListItemBody>
        </IconListItem>
      )}
      {(color &&
        <IconListItem icon='eye-dropper' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.color')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>
            <FontAwesome5 icon='square' type='solid' color={color} className='ta-customer-fields__preview__details__color' />
          </IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon={isPublished ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='180px'>{t('global.published')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{isPublished ? t('global.yes') : t('global.no')}</IconListItemBody>
      </IconListItem>

      <IconListItem icon={isMandatoryOffline ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='180px'>{t('global.internalUsage.mandatory')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{isMandatoryOffline ? t('global.yes') : t('global.no')}</IconListItemBody>
      </IconListItem>

      <IconListItem icon={isMandatoryOnline ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='180px'>{t('global.onlineBooking.mandatory')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{isMandatoryOnline ? t('global.yes') : t('global.no')}</IconListItemBody>
      </IconListItem>

      {(type !== 'CHECKBOX' &&
        <IconListItem icon={isUniqueKey ? 'check-square' : 'square'} iconType='solid'>
          <IconListItemTitle width='180px'>{t('global.isUniqueIdentifier')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{isUniqueKey ? t('global.yes') : t('global.no')}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon={displayOnHover ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='180px'>{t('customerFields.form.displaySettings.checboxDisplayOnHover.description')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{displayOnHover ? t('global.yes') : t('global.no')}</IconListItemBody>
      </IconListItem>
      {(hasOverwrite &&
        <IconListItem icon={displayOnBookingForm ? 'check-square' : 'square'} iconType='solid'>
          <IconListItemTitle width='180px'>{t('customerFields.form.displaySettings.checboxDisplayOnBookingForm.description')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{displayOnBookingForm ? t('global.yes') : t('global.no')}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon={isActive ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='180px'>{t('global.enabled')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{isActive ? t('global.yes') : t('global.no')}</IconListItemBody>
      </IconListItem>
    </div>
  )
}

export default CustomerFieldPreviewDetails
