import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../Common'
import { MenuItem } from '../../Beauties'

const MenuList = props => {
  const { menu, route, onClickMenuItem } = props

  return (
    <ul>
      {menu.map((item) => (
        <MenuItem
          key={item.id}
          label={item.label || t(item.translationKey)}
          icon={item.icon}
          iconType={item.iconType}
          to={item.to}
          hasChildrens={item.submenu && item.submenu.length > 0}
          notifications={item.notifications}
          onClick={onClickMenuItem}
          active={(item.submenu && item.submenu.length > 0
            ? item.submenu.some((subItem) => (subItem.routes || []).indexOf(route) > -1)
            : (item.routes || []).indexOf(route) > -1
          )}
        >
          {(item.submenu && item.submenu.length > 0 &&
            <MenuList {...props} menu={item.submenu} />
          )}
        </MenuItem>
      ))}
    </ul>
  )
}

MenuList.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
  route: PropTypes.string
}

export default MenuList
