import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Button,
  FontAwesome5,
  Loader,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { AppsList, AppsFilters } from '../../Beauties'

import './Apps.css'

const Apps = props => {
  const { pending, ...rest } = props

  return (
    <div className='ta-apps'>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='th' iconTheme='grey' label={t('global.apps')}>
            <PopupTriggerWrapper name='apps-help-box' position='bottom' extraClassName='ta-help-box'>
              <Button as='link' className='ta-title__link' external>
                {t('global.help')} <FontAwesome5 icon='lightbulb' />
              </Button>
            </PopupTriggerWrapper>
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Loader active={pending} />
          {(!pending &&
            <>
              <AppsFilters />
              <AppsList {...rest} />
            </>
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

Apps.propTypes = {
  pending: PropTypes.bool.isRequired,
  message: PropTypes.object
}

export default Apps
