import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { filter, debounceTime, tap } from 'rxjs/operators'
import { q } from '../../API'

import {
  tagsTransform,
  tagsFormValidate,
  tagsSaveTransform,
  tagsFormServerErrorsTransform
} from './utils'

// Global actions
let isLoading = false
globalActions.populateTags = async () => {
  if (isLoading) return
  isLoading = true
  const state = store.getState()
  const newTagInstance = state.tags && state.tags.list && state.tags.list.map(tag => ({
    ...tag
  }))
  if (state.tags && state.tags.areFetched) {
    handlers.tagsListPopulate({
      tags: newTagInstance
    })
    isLoading = false
    return newTagInstance
  } else {
    const tags = await q('getEnterpriseTags') || []
    const newTagInstance = (tags || []).map(tag => ({
      ...tag
    }))
    const { error } = tags
    if (error) {
      isLoading = false
      return
    }
    handlers.tagsListPopulate({
      tags: newTagInstance
    })
    isLoading = false
    return newTagInstance
  }
}

globalActions.transformTags = tags => {
  return tagsTransform(tags)
}

// List
payloads$(actions.TAGS_LIST_GET)
  .subscribe(async () => {
    const tags = await q('getEnterpriseTags')
    const { error } = tags
    if (error) return error
    handlers.tagsListPopulate({
      tags: tags.map(tag => ({
        ...tag
      }))
    })
  })

// List Filters
payloads$(actions.FORM_FIELDS_UPDATE)
  .pipe(
    filter(fields => fields.form === 'tagsFilters'),
    tap(() => handlers.tagsListPending()),
    debounceTime(500)
  ).subscribe(() => {
    handlers.tagsSearch()
  })

// Preview
payloads$(actions.TAGS_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/branches/tags')
      return
    }
    const state = store.getState()
    const { list = [] } = state.tags
    const selectedTag = list.find(tag => tag.id === id) || {}
    const { isComplete } = selectedTag
    if (isComplete && !forceFetch) {
      handlers.tagsPreviewPopulate(id)
      return
    }
    const tag = (await q('getEnterpriseTag', { id })) || { error: { text: 'Not found ' } }
    const { error } = tag
    if (error) return
    handlers.tagsUpdate({ ...tag, isComplete: true })
    handlers.tagsPreviewPopulate(id)
  })

// Form
payloads$(actions.TAGS_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    let { list } = state.tags || {}
    list = list || []
    if (!id) {
      handlers.tagsFormPopulate()
      return
    }
    const selectedTag = list.find(tag => tag.id === id) || {}
    const { isComplete } = selectedTag

    const tag = isComplete
      ? selectedTag
      : tagsTransform(await q('getEnterpriseTag', { id }))

    const { error } = tag
    if (error) {
      handlers.navigateToPath(id ? `/branches/tags/${id}` : '/branches/tags')
      return
    }
    handlers.tagsUpdate({ ...tag, isComplete: true })
    handlers.tagsFormPopulate({
      ...tag
    })
  })

// Save
payloads$(actions.TAGS_FORM_SAVE)
  .subscribe(async ({ tag, scrollToError }) => {
    const state = store.getState()
    let { list: tagsList } = state.tags || {}
    tagsList = tagsList || []
    const tagExternalIds = tagsList.filter(tagItem => tagItem.id !== tag.id).map(tag => tag.externalId).filter(Boolean) || []
    const errors = tagsFormValidate(tag, tagExternalIds)
    if (errors.length) return setTagsFormSaveErrors(errors, scrollToError)
    const savedTag = await q('saveEnterpriseTag', { tag: tagsSaveTransform(tag) })
    const { error, id } = savedTag
    if (error) return setTagsFormSaveErrors(tagsFormServerErrorsTransform(error), scrollToError)
    handlers.tagsUpdate({ ...savedTag, isComplete: true })
    handlers.navigateToPath(`/branches/tags/${id}/preview`)
  })

const setTagsFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('tags', errors)
  scrollToError && scrollToError(errors)
  handlers.tagsFormReady()
}

// Update
payloads$(actions.TAGS_UPDATE)
  .subscribe(async tag => {
    if (!tag) return handlers.tagsPreviewPopulate()
    setTimeout(() => handlers.tagsUpdated(tag), 2000)
    handlers.branchesReset()
  })

// Delete
payloads$(actions.TAGS_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.tagsPreviewPopulate()
    const { error } = await q('deleteEnterpriseTag', { id })
    if (error) return
    handlers.tagsDeleted(id)
    setTimeout(() => handlers.tagsRemoveDeleted(id), 2000)
    handlers.branchesReset()
    setTimeout(() => handlers.navigateToPath('/branches/tags'), 0)
  })
