import React from 'react'
import PropTypes from 'prop-types'

import { AppsPreviewInstallation } from '../../../Beauties'

import './AppsPreview.css'

// const mock = {
//   withPayment: true,
//   pricingType: 'payPerUsage',
//   hasOneTimeSetup: true,
//   fixedPrice: true,
//   entityCount: 14,
//   entityName: 'SMS',
//   oneTimeSetupText: 'Includes the set up and 30min onboarding with a colleague from our customer experience team.',
//   oneTimeSetupValue: 25,
//   isVariablePrice: false,
//   price: 0.1,
//   priceRange: [0.0095, 0.30],
//   payPerUsageText: '* The price per SMS varies based on the country to which the SMS is sent to and the telecommunication operator.'
// }

const AppsPreview = props => {
  const {
    id,
    name,
    version,
    plan,
    onSubmit,
    benefits,
    description,
    functionality,
    privacyUrl,
    gallery,
    tutorialUrl,
    roles,
    developer,
    message,
    copyright,
    hasPayment,
    activationFeePrice,
    trialUnits,
    unitDescription,
    unitDescriptionPlural,
    usageMaxUnitPrice,
    usageMinUnitPrice,
    usageUnitPrice,
    priceDescription,
    hasUsage,
    isPublic,
    isPaidByEnterprise,
    currencyCode
  } = props
  // const {
  // withPayment,
  // pricingType,
  // hasOneTimeSetup,
  // fixedPrice,
  // entityCount,
  // entityName,
  // oneTimeSetupText
  // oneTimeSetupValue,
  // isVariablePrice,
  // price,
  // priceRange,
  // payPerUsageText
  // } = mock
  const pricingType = (hasUsage || (hasUsage === null && !isPaidByEnterprise)) ? 'payPerUsage' : 'monthly'
  const isVariablePrice = !!usageMaxUnitPrice && !!usageMinUnitPrice && usageMinUnitPrice !== usageMaxUnitPrice
  const priceRange = [usageMinUnitPrice, usageMaxUnitPrice]

  const classNames = ['ta-apps__preview']

  return (
    <div className={classNames.join(' ')}>
      <AppsPreviewInstallation
        classNames={classNames}
        id={id}
        name={name}
        version={version}
        plan={plan}
        onSubmit={onSubmit}
        benefits={benefits}
        description={description}
        functionality={functionality}
        privacyUrl={privacyUrl}
        gallery={gallery}
        tutorialUrl={tutorialUrl}
        copyright={copyright}
        roles={roles}
        developer={developer}
        message={message}
        hasPayment={hasPayment}
        pricingType={pricingType}
        activationFeePrice={activationFeePrice}
        trialUnits={trialUnits}
        unitDescription={unitDescription}
        unitDescriptionPlural={unitDescriptionPlural}
        isVariablePrice={isVariablePrice}
        usageUnitPrice={usageUnitPrice}
        priceDescription={priceDescription}
        priceRange={priceRange}
        isPublic={isPublic}
        currencyCode={currencyCode}
      />
    </div>
  )
}

AppsPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default AppsPreview
