import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Error,
  t,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBodyItem,
  SimpleTabsBody
} from '../../../Common'
import { CourseFormRemindersWrapper } from '../../../Beasts'
import { CourseFormSummary } from '../../../Beauties'

import './CourseForm.css'

const CourseForm = props => {
  let {
    showExternalIds,
    cancelLink,
    onSubmit,
    title,
    hash,
    type,
    isAdmin,
    branchesAccessBlacklist,
    location
  } = props
  location = location || []
  const availableTabs = ['summary', 'availability', 'advanced']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-courses__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='courses'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='summary' icon='list-ul' label={t('global.summary')} />
                <SimpleTabsHeaderItem name='advanced' icon='cogs' label={t('global.advanced')} />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='summary'>
                  <CourseFormSummary
                    showExternalIds={showExternalIds}
                    type={type}
                    hasAll={hasAll}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='advanced'>
                  <CourseFormRemindersWrapper />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
            <FormSection>
              <Error name='globalErrors' noTopOffset />
              <Error name='globalCategoryErrors' noTopOffset shouldNotTranslate />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CourseForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default CourseForm
