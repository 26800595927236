import React, { useEffect } from 'react'
import { UserPermissionsPreview } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'

const UserPermissionsPreviewWrapper = props => {
  let {
    id,
    hash,
    users,
    pending,
    account,
    pendingUsers,
    branches,
    userPermissions,
    canLogedInUserReadUsers
  } = props
  pendingUsers = !!pendingUsers
  pending = !!pending
  userPermissions = userPermissions || []
  users = users || []
  account = account || {}
  let { enterprisePermissions, isAdmin, enterprisePermissionsGroupId } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { permissionsGroupsRead, permissionsGroupsWrite, permissionsGroupsDelete } = enterprisePermissions || {}
  permissionsGroupsRead = !!isAdmin || !!permissionsGroupsRead
  permissionsGroupsWrite = !!isAdmin || !!permissionsGroupsWrite
  permissionsGroupsDelete = !!isAdmin || !!permissionsGroupsDelete
  const isEditingOwnPG = enterprisePermissionsGroupId === id

  useEffect(() => {
    if (!permissionsGroupsRead) handlers.navigateToPath('/managers/user-permissions')
  })
  const userPermission = userPermissions.find(permissionType => permissionType.id === id) || {}
  const onDeletePermissionType = permissionTypeId => {
    if (!permissionTypeId) return
    handlers.userPermissionTypeDelete(permissionTypeId)
  }
  const userPermissionTypeWithUsers = {
    ...userPermission,
    users: userPermission.id === 'ADMIN'
      ? users.filter(user => !user.enterprisePermissionsGroupId && user.roles.includes('ADMIN'))
      : users.filter(user => user.enterprisePermissionsGroupId === userPermission.id)
  }

  return (
    pending
      ? <Loader active />
      : (
        <UserPermissionsPreview
          hash={hash}
          isAdmin={isAdmin}
          canLogedInUserReadUsers={canLogedInUserReadUsers}
          userPermission={userPermissionTypeWithUsers}
          onDeletePermissionType={onDeletePermissionType}
          pendingUsers={pendingUsers}
          permissionsGroupsWrite={permissionsGroupsWrite}
          permissionsGroupsDelete={permissionsGroupsDelete}
          branches={branches}
          isEditingOwnPG={isEditingOwnPG}
        />
        )
  )
}

const maps = state => ({
  userPermissions: state.userPermissions.list,
  branches: state.branches.list,
  id: state.router.data.id,
  hash: state.router.hash,
  isAdmin: state.account.isAdmin,
  pending: state.userPermissions.pendingPreview,
  users: state.users.list,
  pendingUsers: state.users.pendingList,
  canLogedInUserReadUsers: state.account.enterprisePermissions.permissionsGroupsRead,
  account: state.account
})

export default connect(maps)(UserPermissionsPreviewWrapper)
