import React from 'react'

import './FormText.css'

const FormText = props => {
  const { children, className, bold, noOffset } = props
  const classNames = ['ta-form-text']
  if (className) classNames.push(className)
  if (bold) classNames.push('bold')
  if (noOffset) classNames.push('noOffset')

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default FormText
