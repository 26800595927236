import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupFacebookConversion = () => {
  return (
    <div className='ta-popup__services-duration-before-and-after'>
      <div className='ta-popup__text'>{dangerousHTML(t('settings.section.widget.conversionTracking.faebookPixel.ID.tooltip.text'), false, true)}</div>
    </div>
  )
}

export default PopupFacebookConversion
