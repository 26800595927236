import React from 'react'
import {
  Form,
  Input,
  FontAwesome5,
  InfiniteScroll,
  TableRow,
  TableRowMainCol,
  TableRowMainColTitle,
  Alert,
  t
} from '../../../Common'
import { highlight, getNameByColor, truncateText } from '../../../Utils'

const PopupDashboardStatisticsSettings = props => {
  const {
    search,
    onChange,
    items,
    statisticId,
    onSelectItem,
    isOverallStatistic
  } = props

  return (
    <div className='ta-popup__brach-statistics'>
      <Form name={`${isOverallStatistic ? statisticId + 'Overall' : statisticId}StatisticsForm`}>
        <Input
          name='search'
          addon={<FontAwesome5 icon='search' type='s' />}
          onChange={onChange}
          clearable
        />
        <InfiniteScroll loadMore={() => false}>
          {items.map((item, i) => {
            const { color, name } = item || {}
            const colorName = color ? getNameByColor(color) : 'transparent'
            return (
              <div className='ta-tags__item' key={i} onClick={() => onSelectItem(item)}>
                <TableRow color={colorName}>
                  <TableRowMainCol>
                    <TableRowMainColTitle className='ta-tags__item__title'>
                      {highlight(truncateText(name, 25, true) || '-', search)}
                    </TableRowMainColTitle>
                  </TableRowMainCol>
                </TableRow>
              </div>
            )
          })}
          {(items.length <= 1 &&
            <Alert noOffset label={t('global.noResults')} theme='no-content' />
          )}
        </InfiniteScroll>
      </Form>
    </div>
  )
}

export default PopupDashboardStatisticsSettings
