import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Loader,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Alert,
  t
} from '../../Common'
import { ActivityLogsList } from '../../Beauties'

import './ActivityLogs.css'

const ActivityLogs = props => {
  const {
    pending,
    activityLogs,
    loadMore,
    hasMore,
    hasAdvancedFilter,
    toggleAdvancedOptions,
    message,
    activityLogsCount
  } = props
  const classNames = ['ta-activity-logs']
  if (hasAdvancedFilter) classNames.push('has-advanced-filter')
  if (message) classNames.push('has-error')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-activity__title'>
            <Title label={t('global.activityLog')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Loader active={pending} />
          {message && (
            <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
              {message.text && t(message.text)}
            </Alert>
          )}
          <ActivityLogsList
            activityLogs={activityLogs}
            loadMore={loadMore}
            hasMore={hasMore}
            activityLogsCount={activityLogsCount}
            hasAdvancedFilter={hasAdvancedFilter}
            toggleAdvancedOptions={toggleAdvancedOptions}
            pending={pending}
            message={message}
          />
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

ActivityLogs.propTypes = {
  pending: PropTypes.bool.isRequired,
  hideCustomer: PropTypes.bool
}

export default ActivityLogs
