import React from 'react'
import PropTypes from 'prop-types'
import { PopupTriggerWrapper } from '../../Beasts'
import { AVAILABLE_LANGUAGES, SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../../Settings'
import {
  Title,
  Button,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  Address,
  Blur,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../Common'

import './Account.css'

const Account = props => {
  let { enterprise, accountRead, isOwner } = props
  accountRead = !!accountRead
  isOwner = !!isOwner
  let {
    id,
    slug,
    name,
    region,
    ownerName,
    ownerEmail,
    timezone,
    address,
    phone,
    locale
  } = enterprise || {}
  const {
    formatted: formattedAddress,
    details,
    latitude,
    longitude
  } = address || {}
  const mappedLocales = {}
  const availableLocales = AVAILABLE_LANGUAGES.map(language => language.locale)
  SUPPORTED_LOCALES.forEach(locale => {
    mappedLocales[locale] = availableLocales.find(availableLocale => locale.includes(availableLocale.split('-')[0])) || 'en-gb'
  })
  locale = mappedLocales[locale] || DEFAULT_LOCALE
  const language = (AVAILABLE_LANGUAGES.find(item => item.locale.toString().toLowerCase() === locale.toString().toLowerCase())) || {}
  const formattedPhone = (phone && phone.number) && `+${phone.prefix} (0) ${phone.number}`

  return (
    <div className='ta-account'>
      <FixedContent>
        <FixedContentHeader>
          {/* <div className='ta-account__title'> */}
          <Title icon='briefcase' iconTheme='grey' label={t('global.details')}>
            <PopupTriggerWrapper name='account-details-help-box' position='bottom' extraClassName='ta-help-box'>
              <Button as='link' className='ta-title__link' external>
                {t('global.heading.help')} <FontAwesome5 icon='lightbulb' />
              </Button>
            </PopupTriggerWrapper>
            {(isOwner &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('account.buttonHeading.editTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/account/edit' isPrimary icon='pen' iconType='r' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
          {/* </div> */}
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!accountRead} label={t('global.accessDenied')} icon='ban'>
            <SimpleTabs active='details'>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='details' label={t('global.details')} icon='user' />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='details'>
                  {(id &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.keyAccountId')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>{id}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(slug &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.slug')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>{slug}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(name &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.keyAccountName')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>{name}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(region &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.keyAccountRegion')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>{region}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(ownerName &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.ownerName')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>{ownerName}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(ownerEmail &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.ownerEmail')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>
                        <Button as='link' className='ta-email' to={`mailto:${ownerEmail}`} external>{ownerEmail}</Button>
                      </IconListItemBody>
                    </IconListItem>
                  )}
                  {(formattedPhone &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.ownerPhone')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'>{formattedPhone}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(language.name &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.defaultLanguage')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'> {language.name}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(timezone &&
                    <IconListItem>
                      <IconListItemTitle width='130px'>{t('account.details.tabDetails.defaultTimezone')}:</IconListItemTitle>
                      <IconListItemBody offset='130px'> {timezone}</IconListItemBody>
                    </IconListItem>
                  )}
                  {(formattedAddress &&
                    <>
                      <Title size='m' label='Address' icon='map' />
                      <Address address={formattedAddress} details={details} lat={latitude} lng={longitude} />
                    </>
                  )}
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

Account.propTypes = {
  message: PropTypes.object
}

export default Account
