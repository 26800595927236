import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../Store'
import { ForgottenPassword } from '../../Beauties'

class ForgottenPasswordWrapper extends Component {
  componentDidMount () {
    handlers.loginFormPopulate()
  }

  onSubmit (cb) {
    const form = store.getState().forms.login
    handlers.loginWithEmailAndPassword(form, errors => {
      cb && cb(errors)
    })
  }

  onLanguageChange () {
    const form = store.getState().forms.login
    handlers.navigateToPath(`/login?locale=${form.language.value}`)
  }

  togglePasswordFieldType () {
    const form = store.getState().forms.login
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('login', { password })

    return password.type
  }

  render () {
    const { pending, pendingLanguage, passwordFieldType } = this.props

    return (
      <ForgottenPassword
        pendingLanguage={pendingLanguage}
        pending={pending}
        passwordFieldType={passwordFieldType}
        onSubmit={this.onSubmit}
        togglePasswordFieldType={this.togglePasswordFieldType}
        onLanguageChange={this.onLanguageChange}
      />
    )
  }
}

ForgottenPasswordWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  passwordFieldType: PropTypes.string.isRequired
}

const maps = state => ({
  router: state.router || {},
  pendingLanguage: !!(state.app && state.app.pendingLanguage),
  pending: !!(state.auth && state.auth.pendingForm),
  passwordFieldType:
    (state.forms.login &&
      state.forms.login.password &&
      state.forms.login.password.type) ||
    'password'
})

export default connect(maps)(ForgottenPasswordWrapper)
