import React, { useState, useEffect, useMemo } from 'react'
import { connect, handlers } from '../../../Store'
import { Loader, t } from '../../../Common'
import { BookingSettingsEditForm } from '../../../Beauties'

const BookingSettingsEditFormWrapper = props => {
  let {
    enforceResourcesAllowanceTypeCheckbox,
    enforceReschedulingTagRuleCheckbox,
    enforceServiceDurationLimitCheckbox,
    snapToGridInterval,
    internalSnapToGridInterval,
    maximumLeadTime,
    maximumLeadTimeDuration,
    minimumLeadTime,
    minimumLeadTimeDuration,
    useCancellationLeadTime,
    cancellationLeadTimeAnyTime,
    showWidgetCategoriesClosed,
    showCustomTermsAndConditions,
    showCustomPrivacyPolicy,
    showDisclaimer,
    hasServiceAllocations,
    enforceResourceAllowanceType,
    enforceReschedulingTagRuleInheritCheckbox,
    useFacebookConversionTracking,
    useGoogleConversionTracking,
    useCustomiseConsentMessage,
    showWidgetExternalUrlRedirect,
    pending,
    form,
    globalTags,
    errors,
    hash,
    hasDateFormat,
    dateFormatPosition,
    dateFormatYear,
    dateFormatMonth,
    dateFormatDay,
    dateFormatSeparator,
    hasTimeFormat,
    timeFormatType,
    timeFormat12,
    timeFormat24,
    hasPublicHolidays,
    publicHolidays,
    publicHolidaysData,
    resourceCategories,
    areTagsFetched,
    customersMiddlewareUrl,
    account,
    enterpriseCustomisation,
    callCentreEnabled,
    forbidUpdatingBookingsInThePast,
    allowPartialUpdateOfBookingsInThePast,
    pastBookingPrepAndFollowUp,
    pastBookingResources,
    pastBookingGroupPrice,
    pastBookingDuration,
    pastBookingCustomDataFields
  } = props
  form = form || {}
  pastBookingPrepAndFollowUp = pastBookingPrepAndFollowUp || false
  pastBookingResources = pastBookingResources || false
  pastBookingGroupPrice = pastBookingGroupPrice || false
  pastBookingDuration = pastBookingDuration || false
  pastBookingCustomDataFields = pastBookingCustomDataFields || false
  enterpriseCustomisation = enterpriseCustomisation || {}
  resourceCategories = resourceCategories || []
  globalTags = globalTags || []
  errors = errors || []
  account = account || {}
  areTagsFetched = !!areTagsFetched
  publicHolidays = publicHolidays || {}
  publicHolidaysData = publicHolidaysData || []
  const {
    values: publicHolidaysValues,
    deleted: publicHolidaysDeleted,
    modified: publicHolidaysModified
  } = publicHolidays || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsWrite } = enterprisePermissions || {}
  settingsWrite = !!isAdmin || !!settingsWrite
  forbidUpdatingBookingsInThePast = forbidUpdatingBookingsInThePast || false
  allowPartialUpdateOfBookingsInThePast = allowPartialUpdateOfBookingsInThePast || false
  const { payload: customisaitonPayload } = enterpriseCustomisation || {}
  const [initial] = useState({ settingsWrite })

  // ComponentDidMount
  useEffect(() => {
    const { settingsWrite } = initial
    settingsWrite ? handlers.bookingSettingsFormGet() : handlers.navigateToPath('/settings/bookings')
  }, [initial])

  const onSubmit = cb => {
    handlers.bookingSettingsFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const regionsByUniqueKey = useMemo(() => publicHolidaysData.reduce((acc, item) => ({
    ...acc,
    [item.uniqueKey]: {
      value: item.uniqueKey,
      label: `${item.countryName}${item.stateName ? ` (${item.stateName})` : ''}`
    }
  }), {}), [publicHolidaysData])

  const dateFormatValues = {
    position: dateFormatPosition,
    year: dateFormatYear,
    month: dateFormatMonth,
    day: dateFormatDay,
    separator: dateFormatSeparator
  }
  const timeFormatValues = {
    type: timeFormatType,
    punctuation: timeFormat12,
    separator: timeFormat24
  }

  const generateModifiedPublicHolidays = (newHolidays, index) => {
    const newModified = [...publicHolidaysModified].map(item => ({ ...item }))
      .filter(item => newHolidays[index].id ? item.id !== newHolidays[index].id : item.index !== index)
    newModified.push({ index, ...newHolidays[index] })
    return [...newModified]
  }

  const onPublicHolidayRegionsChange = (newValues, index, isDelete) => {
    if (!publicHolidaysValues[index]) return
    const newHolidays = [...publicHolidaysValues].map(item => ({ ...item }))
    if (isDelete) newHolidays[index].publicHolidayKeys = [...newHolidays[index].publicHolidayKeys].filter(item => item !== newValues) || []
    else newHolidays[index].publicHolidayKeys = newValues.map(item => item.value) || []
    handlers.formFieldsUpdate('bookingSettings', {
      publicHolidays: {
        ...publicHolidays,
        values: [...newHolidays],
        modified: generateModifiedPublicHolidays(newHolidays, index)
      }
    })
  }

  const onPublicHolidayRadioClick = (index, value) => {
    const newHolidays = [...publicHolidaysValues].map(item => ({ ...item }))
    if (value === 'all') newHolidays[index].resourceCategoryIds = ['all']
    if (value === 'some') newHolidays[index].resourceCategoryIds = [...newHolidays[index].resourceCategoryIds.filter(item => item !== 'all')]
    handlers.formFieldsUpdate('bookingSettings', {
      publicHolidays: {
        ...publicHolidays,
        values: [...newHolidays],
        modified: generateModifiedPublicHolidays(newHolidays, index)
      }
    })
  }

  const onPublicHolidayAdd = () => {
    handlers.formFieldsUpdate('bookingSettings', {
      publicHolidays: {
        ...publicHolidays,
        values: [
          ...publicHolidaysValues,
          {
            isNew: true, // needed for unique keys in form map method, is deleted in formSave
            id: Math.random(), // needed for unique keys in form map method is deleted in formSave
            publicHolidayKeys: [],
            resourceCategoryIds: ['all']
          }
        ]
      }
    })
  }

  const onPublicHolidayDelete = index => {
    const deletedHoliday = { ...publicHolidaysValues[index] }
    const newModified = [...publicHolidaysModified].filter(item =>
      (!deletedHoliday.isNew && deletedHoliday.id)
        ? item.id !== deletedHoliday.id
        : item.index !== index
    )
    const newDeleted = [...publicHolidaysDeleted]
    if (deletedHoliday.id && !deletedHoliday.isNew) newDeleted.push(deletedHoliday.id)
    handlers.formFieldsUpdate('bookingSettings', {
      publicHolidays: {
        ...publicHolidays,
        values: [...publicHolidaysValues.filter((item, key) => key !== index)],
        modified: newModified,
        deleted: newDeleted
      }
    })
  }

  const onPublicHolidayResourceCategoriesChange = (value, index, isDelete) => {
    if (!publicHolidaysValues[index]) return
    const newHolidays = [...publicHolidaysValues].map(item => ({ ...item }))
    if (isDelete) newHolidays[index].resourceCategoryIds = [...newHolidays[index].resourceCategoryIds].filter(item => item !== value) || []
    else newHolidays[index].resourceCategoryIds = value.map(item => item.value) || []
    handlers.formFieldsUpdate('bookingSettings', {
      publicHolidays: {
        ...publicHolidays,
        values: [...newHolidays],
        modified: generateModifiedPublicHolidays(newHolidays, index)
      }
    })
  }
  const resourceCategoriesOptions = resourceCategories.map(category => ({
    label: category.name === 'default' ? t('resources.list.sectionDefault.title') : category.name,
    value: category.id
  }))

  return (!pending && areTagsFetched)
    ? (
      <BookingSettingsEditForm
        title={t('global.settings.edit')}
        cancelLink={`/settings/bookings@@${hash || 'booking'}`}
        enforceResourceAllowanceType={enforceResourceAllowanceType}
        enforceResourcesAllowanceTypeCheckbox={enforceResourcesAllowanceTypeCheckbox}
        enforceReschedulingTagRuleCheckbox={enforceReschedulingTagRuleCheckbox}
        enforceServiceDurationLimitCheckbox={enforceServiceDurationLimitCheckbox}
        snapToGridInterval={snapToGridInterval}
        internalSnapToGridInterval={internalSnapToGridInterval}
        maximumLeadTime={maximumLeadTime}
        maximumLeadTimeDuration={maximumLeadTimeDuration}
        minimumLeadTime={minimumLeadTime}
        minimumLeadTimeDuration={minimumLeadTimeDuration}
        useCancellationLeadTime={useCancellationLeadTime}
        cancellationLeadTimeAnyTime={cancellationLeadTimeAnyTime}
        showWidgetCategoriesClosed={showWidgetCategoriesClosed}
        showCustomTermsAndConditions={showCustomTermsAndConditions}
        showCustomPrivacyPolicy={showCustomPrivacyPolicy}
        useFacebookConversionTracking={useFacebookConversionTracking}
        useGoogleConversionTracking={useGoogleConversionTracking}
        useCustomiseConsentMessage={useCustomiseConsentMessage}
        enforceReschedulingTagRuleInheritCheckbox={enforceReschedulingTagRuleInheritCheckbox}
        showWidgetExternalUrlRedirect={showWidgetExternalUrlRedirect}
        hasTags={globalTags.filter(item => item.isForEvents).length > 0}
        errors={errors}
        onSubmit={onSubmit}
        hash={hash}
        showDisclaimer={showDisclaimer}
        hasDateFormat={hasDateFormat}
        dateFormatValues={dateFormatValues}
        timeFormatValues={timeFormatValues}
        hasTimeFormat={hasTimeFormat}
        timeFormatType={timeFormatType}
        hasServiceAllocations={hasServiceAllocations}
        resourceCategories={resourceCategoriesOptions}
        hasPublicHolidays={hasPublicHolidays}
        publicHolidays={publicHolidaysValues}
        regionsByUniqueKey={regionsByUniqueKey}
        customisations={customisaitonPayload}
        onPublicHolidayRegionsChange={onPublicHolidayRegionsChange}
        onPublicHolidayRadioClick={onPublicHolidayRadioClick}
        onPublicHolidayAdd={onPublicHolidayAdd}
        onPublicHolidayDelete={onPublicHolidayDelete}
        onPublicHolidayResourceCategoriesChange={onPublicHolidayResourceCategoriesChange}
        customersMiddlewareUrl={customersMiddlewareUrl}
        isAdmin={isAdmin}
        callCentreEnabled={callCentreEnabled}
        forbidUpdatingBookingsInThePast={forbidUpdatingBookingsInThePast}
        allowPartialUpdateOfBookingsInThePast={allowPartialUpdateOfBookingsInThePast}
        pastBookingPrepAndFollowUp={pastBookingPrepAndFollowUp}
        pastBookingResources={pastBookingResources}
        pastBookingGroupPrice={pastBookingGroupPrice}
        pastBookingDuration={pastBookingDuration}
        pastBookingCustomDataFields={pastBookingCustomDataFields}
      />
      )
    : <Loader active />
}

const maps = state => ({
  id: state.router.data.id,
  hash: state.router.hash,
  form: state.forms.bookingSettings,
  enforceResourceAllowanceType: state.forms.bookingSettings.enforceResourceAllowanceType && state.forms.bookingSettings.enforceResourceAllowanceType.value,
  enforceResourcesAllowanceTypeCheckbox: state.forms.bookingSettings.enforceResourcesAllowanceTypeCheckbox && state.forms.bookingSettings.enforceResourcesAllowanceTypeCheckbox.value,
  enforceReschedulingTagRuleCheckbox: state.forms.bookingSettings.enforceReschedulingTagRuleCheckbox && state.forms.bookingSettings.enforceReschedulingTagRuleCheckbox.value,
  enforceServiceDurationLimitCheckbox: state.forms.bookingSettings.enforceServiceDurationLimitCheckbox && state.forms.bookingSettings.enforceServiceDurationLimitCheckbox.value,
  snapToGridInterval: state.forms.bookingSettings.snapToGridInterval && state.forms.bookingSettings.snapToGridInterval.value,
  internalSnapToGridInterval: state.forms.bookingSettings.internalSnapToGridInterval && state.forms.bookingSettings.internalSnapToGridInterval.value,
  maximumLeadTime: state.forms.bookingSettings.maximumLeadTime && state.forms.bookingSettings.maximumLeadTime.value,
  maximumLeadTimeDuration: state.forms.bookingSettings.maximumLeadTimeDuration && state.forms.bookingSettings.maximumLeadTimeDuration.value,
  minimumLeadTime: state.forms.bookingSettings.minimumLeadTime && state.forms.bookingSettings.minimumLeadTime.value,
  minimumLeadTimeDuration: state.forms.bookingSettings.minimumLeadTimeDuration && state.forms.bookingSettings.minimumLeadTimeDuration.value,
  useCancellationLeadTime: state.forms.bookingSettings.useCancellationLeadTime && state.forms.bookingSettings.useCancellationLeadTime.value,
  cancellationLeadTimeAnyTime: state.forms.bookingSettings.cancellationLeadTimeAnyTime && state.forms.bookingSettings.cancellationLeadTimeAnyTime.value,
  showWidgetCategoriesClosed: state.forms.bookingSettings.showWidgetCategoriesClosed && state.forms.bookingSettings.showWidgetCategoriesClosed.value,
  showCustomTermsAndConditions: state.forms.bookingSettings.showCustomTermsAndConditions && state.forms.bookingSettings.showCustomTermsAndConditions.value,
  showCustomPrivacyPolicy: state.forms.bookingSettings.showCustomPrivacyPolicy && state.forms.bookingSettings.showCustomPrivacyPolicy.value,
  useFacebookConversionTracking: state.forms.bookingSettings.useFacebookConversionTracking && state.forms.bookingSettings.useFacebookConversionTracking.value,
  useGoogleConversionTracking: state.forms.bookingSettings.useGoogleConversionTracking && state.forms.bookingSettings.useGoogleConversionTracking.value,
  useCustomiseConsentMessage: state.forms.bookingSettings.useCustomiseConsentMessage && state.forms.bookingSettings.useCustomiseConsentMessage.value,
  // reschedulingSwitch: state.forms.bookingSettings.reschedulingSwitch && state.forms.bookingSettings.reschedulingSwitch.value,
  showWidgetExternalUrlRedirect: state.forms.bookingSettings.showWidgetExternalUrlRedirect && state.forms.bookingSettings.showWidgetExternalUrlRedirect.value,
  showDisclaimer: state.forms.bookingSettings.showDisclaimer && state.forms.bookingSettings.showDisclaimer.value,
  hasServiceAllocations: state.forms.bookingSettings.hasServiceAllocations && state.forms.bookingSettings.hasServiceAllocations.value,
  errors: state.bookingSettings.errors,
  globalTags: state.companyTags.list,
  areTagsFetched: state.companyTags.areFetched,
  pending: state.bookingSettings.pendingForm,
  hasPublicHolidays: state.forms.bookingSettings.hasPublicHolidays && state.forms.bookingSettings.hasPublicHolidays.value,
  publicHolidays: state.forms.bookingSettings.publicHolidays,
  publicHolidaysData: state.staticData.publicHolidays,
  resourceCategories: state.resources.categoriesList,
  customersMiddlewareUrl: state.company.customersMiddlewareUrl,
  account: state.account,
  enterpriseCustomisation: state.company.enterpriseCustomisation,
  // Date format
  hasDateFormat: state.forms.bookingSettings.hasDateFormat && state.forms.bookingSettings.hasDateFormat.value,
  dateFormatPosition: state.forms.bookingSettings.dateFormatPosition && state.forms.bookingSettings.dateFormatPosition.value,
  dateFormatYear: state.forms.bookingSettings.dateFormatYear && state.forms.bookingSettings.dateFormatYear.value,
  dateFormatMonth: state.forms.bookingSettings.dateFormatMonth && state.forms.bookingSettings.dateFormatMonth.value,
  dateFormatDay: state.forms.bookingSettings.dateFormatDay && state.forms.bookingSettings.dateFormatDay.value,
  dateFormatSeparator: state.forms.bookingSettings.dateFormatSeparator && state.forms.bookingSettings.dateFormatSeparator.value,
  // Time format
  hasTimeFormat: state.forms.bookingSettings.hasTimeFormat && state.forms.bookingSettings.hasTimeFormat.value,
  timeFormatType: state.forms.bookingSettings.timeFormatType && state.forms.bookingSettings.timeFormatType.value,
  timeFormat12: state.forms.bookingSettings.timeFormat12 && state.forms.bookingSettings.timeFormat12.value,
  timeFormat24: state.forms.bookingSettings.timeFormat24 && state.forms.bookingSettings.timeFormat24.value,
  callCentreEnabled: state.company.settings.callCentreEnabled,
  // Post Booking
  forbidUpdatingBookingsInThePast: state.forms.bookingSettings.forbidUpdatingBookingsInThePast && state.forms.bookingSettings.forbidUpdatingBookingsInThePast.value,
  allowPartialUpdateOfBookingsInThePast: state.forms.bookingSettings.allowPartialUpdateOfBookingsInThePast && state.forms.bookingSettings.allowPartialUpdateOfBookingsInThePast.value,
  pastBookingPrepAndFollowUp: state.forms.bookingSettings.pastBookingPrepAndFollowUp && state.forms.bookingSettings.pastBookingPrepAndFollowUp.value,
  pastBookingResources: state.forms.bookingSettings.pastBookingResources && state.forms.bookingSettings.pastBookingResources.value,
  pastBookingGroupPrice: state.forms.bookingSettings.pastBookingGroupPrice && state.forms.bookingSettings.pastBookingGroupPrice.value,
  pastBookingDuration: state.forms.bookingSettings.pastBookingDuration && state.forms.bookingSettings.pastBookingDuration.value,
  pastBookingCustomDataFields: state.forms.bookingSettings.pastBookingCustomDataFields && state.forms.bookingSettings.pastBookingCustomDataFields.value
})

export default connect(maps)(BookingSettingsEditFormWrapper)
