
import React from 'react'
import { ResourceCategoriesListItem } from '../../Beauties'
import {
  Form,
  SingleSelect,
  FixedContentBody,
  t
} from '../../Common'

const ResourceCategoriesActive = props => {
  const { categories } = props
  return (
    <div className='ta-resource-categories__active'>
      <div className='ta-resource-categories__filters'>
        <Form name='resourceCategoriesFilter' className='ta-resource-categories__filter'>
          <SingleSelect name='branchName' label={`${t('global.location')}:`} />
          {/* <div className='ta-separator' /> */}
        </Form>
      </div>
      <FixedContentBody>
        {categories
          .filter(category => category.isDefault)
          .map((category, index) => (
            <ResourceCategoriesListItem
              key={category.id}
              index={index}
              category={category}
              isLast={index === categories.length - 2} // - 2 because we are skipping the first category (the default one)
            />
          ))}
        {categories
          .filter(category => !category.isDefault)
          .map((category, index) => (
            <ResourceCategoriesListItem
              key={category.id}
              index={index}
              category={category}
              isLast={index === categories.length - 2} // - 2 because we are skipping the first category (the default one)
            />
          ))}
      </FixedContentBody>
    </div>
  )
}

export default ResourceCategoriesActive
