import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  Title,
  Row,
  Col,
  Alert,
  Button,
  FontAwesome5,
  FilesInput,
  Form,
  FormButtons,
  FormSection,
  Error,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'
import './Branches.css'

const BranchesImport = props => {
  const {
    onSubmit,
    onCancelClick,
    enableButton,
    onClickDownload
  } = props

  return (
    <div className='ta-branches__import'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('branches.list.import.title')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesImport'>
            <FormSection>
              <Alert label={t('branches.list.import.bulk.label')} theme='alert'>
                {dangerousHTML(t('branches.list.import.bulk.message'))}
                {/* Uncomment when Clare is done with article <Button as='link' to='...'>More information is available in this article.</Button> */}
              </Alert>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>1</div>
                    <p className='ta-branches__import__step-title'>{t('branches.list.import.downloadTemplate')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>{t('branches.list.import.clickToDownload')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button as='link' onClick={() => onClickDownload('exportBranchesTemplate')} isSecondary>
                    <FontAwesome5 icon='file-download' type='solid' /> {t('branches.list.import.downloadTemplate.title')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>{t('branches.list.import.downloadTemplate.additionalInfo')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button as='link' onClick={() => onClickDownload('exportTimezonesTemplate')} className='ta-link__list-item'>
                    {t('branches.list.import.downloadTimezones')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button as='link' onClick={() => onClickDownload('exportBranchSettingsTemplate')} className='ta-link__list-item'>
                    {t('branches.list.import.downloadCompanySettings')}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button as='link' onClick={() => onClickDownload('exportBusinessSectorsTemplate')} className='ta-link__list-item'>
                    {t('branches.list.import.downloadCategories')}
                  </Button>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>2</div>
                    <p className='ta-branches__import__step-title'>{t('branches.list.import.uploadCSV')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>
                    {dangerousHTML(t('branches.list.import.uploadCSVFinish'))}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FilesInput
                    name='files'
                    maxFileSize='20MB'
                    acceptedFileTypes={[
                      'text/csv',
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]}
                    acceptedFileTypesLabel='csv'
                    label={t('global.chooseFileOrDnD')}
                    hideError
                    shouldNotTranslateError
                  />
                </Col>
              </Row>
              <Error name='globalErrors' />
              <Error name='files' />
              <Error name='requestLimit' shouldNotTranslate />
            </FormSection>
            <FormButtons>
              <Button onClick={onCancelClick} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button
                disabled={!enableButton}
                type='submit'
                isPrimary
                className='ta-btn ta-btn-submit'
              >
                <FontAwesome5 type='s' icon='upload' /> {t('global.import')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesImport
