import {
  customerTransform,
  customerFormTransform,
  customerEventFormTransform
} from './utils'
const reducer = {}

reducer.initialState = {
  list: [],
  availableLetters: [],
  pendingLoadMore: false,
  pendingList: true,
  pendingForm: true,
  pendingPreview: true,
  pendingBookings: false,
  selected: null,
  dragging: {},
  page: 1,
  bookingsPage: 1,
  areFetched: false,
  messageList: null,
  messagePreview: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  customersReset: () => dispatch(actions.CUSTOMERS_RESET),

  // Subscription
  customersSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.CUSTOMERS_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  customersListGet: () => dispatch(actions.CUSTOMERS_LIST_GET),
  customersListPending: () => dispatch(actions.CUSTOMERS_LIST_PENDING),
  customersListPopulate: (customers, availableLetters, isLoadMore, total) => dispatch(actions.CUSTOMERS_LIST_POPULATE, { customers, availableLetters, isLoadMore, total }),
  customersListOptionsChange: options => dispatch(actions.CUSTOMERS_LIST_OPTIONS_CHANGE, options),
  customersListPageIncrement: () => dispatch(actions.CUSTOMERS_LIST_PAGE_INCREMENT),
  customersListReady: () => dispatch(actions.CUSTOMERS_LIST_READY),
  customersDownload: () => dispatch(actions.CUSTOMERS_DOWNLOAD),
  customersListMessageSet: message => dispatch(actions.CUSTOMERS_LIST_MESSAGE_SET, message),

  // List to delete
  customersToDeleteGet: () => dispatch(actions.CUSTOMERS_TO_DELETE_GET),
  customersToDeletePopulate: ({ customersToDelete }) => dispatch(actions.CUSTOMERS_TO_DELETE_POPULATE, { customersToDelete }),
  customerToDeleteDelete: ({ companyId, region, externalId, id }) => dispatch(actions.CUSTOMER_TO_DELETE_DELETE, { companyId, region, externalId, id }),
  customersToDeleteDeleteReady: () => dispatch(actions.CUSTOMERS_TO_DELETE_READY),

  // One
  customerUpdate: (customer, skipTransform) => dispatch(actions.CUSTOMER_UPDATE, customerTransform(customer, skipTransform)),
  customerUpdated: (customer, availableLetters) => dispatch(actions.CUSTOMER_UPDATED, { customer, availableLetters }),
  customerDelete: id => dispatch(actions.CUSTOMER_DELETE, id),
  customerDeleted: (id, availableLetters) => dispatch(actions.CUSTOMER_DELETED, { id, availableLetters }),
  customerRemoveDeleted: id => dispatch(actions.CUSTOMER_REMOVE_DELETED, id),
  customerResetGlobal: internalId => dispatch(actions.CUSTOMER_RESET_GLOBAL, internalId),
  customerResetGlobalReady: payload => dispatch(actions.CUSTOMER_RESET_GLOBAL_READY, payload),

  // Preview
  customerPreviewGet: id => dispatch(actions.CUSTOMER_PREVIEW_GET, id),
  customerPreviewPopulate: customer => dispatch(actions.CUSTOMER_PREVIEW_POPULATE, customer),
  customersPreviewMessageSet: message => dispatch(actions.CUSTOMERS_PREVIEW_MESSAGE_SET, message),
  customersPreviewBookingsMessageSet: message => dispatch(actions.CUSTOMERS_PREVIEW_BOOKINGS_MESSAGE_SET, message),

  // Locally changed
  customerLocallyChangedGet: ({ id, internalId }) => dispatch(actions.CUSTOMER_LOCALLY_CHANGED_GET, { id, internalId }),
  customerLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.CUSTOMER_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  customerLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.CUSTOMER_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  customerLocallyChangedResetReady: ({ customer, companyId }) => dispatch(actions.CUSTOMER_LOCALLY_CHANGED_RESET_READY, { customer, companyId }),

  // Preview bookings
  customerBookingsGet: id => dispatch(actions.CUSTOMER_BOOKINGS_GET, id),
  customerBookingsPopulate: ({ id, events }) => dispatch(actions.CUSTOMER_BOOKINGS_POPULATE, { id, events }),
  customerBookingsPageIncrement: () => dispatch(actions.CUSTOMERS_BOOKINGS_PAGE_INCREMENT),

  // Form
  customerFormGet: ({ id, addon }) => dispatch(actions.CUSTOMER_FORM_GET, { id, addon }),
  customerFormPopulate: ({ customer, branches, account }) => {
    handlers.formSet('customers', customerFormTransform({ customer, branches, account }))
    handlers.customerFormReady()
  },
  customerFormReady: () => dispatch(actions.CUSTOMER_FORM_READY),
  customerFormSave: (customer, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FORM_SAVE, { customer, scrollToError }),
  customerEventFormGet: () => dispatch(actions.CUSTOMER_EVENT_FORM_GET),
  customerEventFormPopulate: (options) => {
    handlers.formSet('customerEvents', customerEventFormTransform(options))
    handlers.customerEventFormReady()
  },
  customerEventFormReady: () => dispatch(actions.CUSTOMER_EVENT_FORM_READY),

  // Print customer bookings
  customerBookingsPrint: () => dispatch(actions.CUSTOMER_BOOKING_PRINT)
})

// Reset
reducer.CUSTOMERS_RESET = state => reducer.initialState

// Subscriptions

reducer.CUSTOMERS_SUBSCRIPTION_SET = state => state

// List
reducer.CUSTOMERS_LIST_GET = state => ({
  ...state,
  pendingList: true,
  page: 1,
  messagePreview: null,
  messageList: null
})

reducer.CUSTOMERS_LIST_PENDING = state => ({
  ...state,
  pendingList: true
})

reducer.CUSTOMERS_LIST_OPTIONS_CHANGE = (state, options = {}) => ({
  ...state,
  ...options
})

reducer.CUSTOMERS_LIST_POPULATE = (state, { customers, availableLetters, isLoadMore = false, total }) => ({
  ...state,
  total,
  list: (isLoadMore && [...state.list, ...customers || []]) || customers || [],
  availableLetters: availableLetters || [],
  pendingList: false,
  pendingLoadMore: false,
  areFetched: true,
  page: state.page || 1
})

reducer.CUSTOMERS_LIST_PAGE_INCREMENT = state => ({
  ...state,
  pendingLoadMore: true,
  page: state.page + 1
})

reducer.CUSTOMERS_LIST_READY = state => ({
  ...state,
  pendingList: false
})

reducer.CUSTOMERS_LIST_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingList: false,
  messageList: message
})

reducer.CUSTOMERS_DOWNLOAD = state => state

// One
reducer.CUSTOMER_UPDATE = (state, customer) => {
  if (!customer) return state
  const list = [...state.list]
  let index = list.findIndex(item => item.id === customer.id)
  if (index < 0) return state
  list[index] = { ...customer, isUpdated: true }
  return {
    ...state,
    list
  }
}

reducer.CUSTOMER_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.CUSTOMER_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = {
    ...list[index],
    locallyChangedData
  }
  return {
    ...state,
    list,
    pendingPreview: false
  }
}

reducer.CUSTOMER_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingPreview: true
})

reducer.CUSTOMER_LOCALLY_CHANGED_RESET_READY = (state, { customer, companyId }) => {
  const { id, internalId } = customer || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  list[index].locallyChangedData = list[index].locallyChangedData.filter(item => item.id !== id)
  delete list[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    list,
    pendingPreview: false
  }
}

reducer.CUSTOMER_UPDATED = (state, { customer, availableLetters }) => {
  if (!customer) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === customer.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  availableLetters = availableLetters || []
  return {
    ...state,
    list,
    availableLetters
  }
}

reducer.CUSTOMER_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.CUSTOMER_DELETED = (state, { id, availableLetters }) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  availableLetters = availableLetters || []
  return {
    ...state,
    list,
    availableLetters
  }
}

reducer.CUSTOMER_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

reducer.CUSTOMERS_TO_DELETE_GET = state => ({
  ...state,
  pendingToDeleteList: true
})

reducer.CUSTOMERS_TO_DELETE_POPULATE = (state, { customersToDelete }) => ({
  ...state,
  listToDelete: customersToDelete || [],
  pendingToDeleteList: false
})

reducer.CUSTOMER_TO_DELETE_DELETE = (state, { id }) => {
  if (!id) return state
  const customersToDelete = state.listToDelete || []
  const remainingCustomersToDelete = customersToDelete.filter(item => item.id !== id)
  return {
    ...state,
    listToDelete: remainingCustomersToDelete,
    pendingToDeleteList: true
  }
}

reducer.CUSTOMERS_TO_DELETE_READY = state => ({
  ...state,
  pendingToDeleteList: false
})

// Reset global entity
reducer.CUSTOMER_RESET_GLOBAL = state => ({
  ...state,
  // pendingList: true,
  pendingPreview: true
})

reducer.CUSTOMER_RESET_GLOBAL_READY = state => ({
  ...state,
  // pendingList: false,
  pendingPreview: false
})

// Preview
reducer.CUSTOMER_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true,
  messagePreview: null,
  messageList: null
})

reducer.CUSTOMER_PREVIEW_POPULATE = (state, customer) => {
  if (!customer) return { ...state, pendingPreview: false }
  return {
    ...state,
    pendingPreview: false,
    selected: customer
  }
}

reducer.CUSTOMERS_PREVIEW_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingPreview: false,
  messagePreview: message,
  selected: null
})

// Preview bookings
reducer.CUSTOMER_BOOKINGS_GET = state => ({
  ...state,
  pendingBookings: true,
  messagePreviewBookings: null
})

reducer.CUSTOMER_BOOKINGS_POPULATE = (state, { id, events = [], isLoadMore = false }) => ({
  ...state,
  selected: { ...state.selected, events: (isLoadMore && [...state.selected.events, ...events]) || events },
  pendingBookings: false,
  bookingsPage: state.bookingsPage || 1
})

reducer.CUSTOMERS_BOOKINGS_PAGE_INCREMENT = state => ({
  ...state,
  bookingsPage: state.bookingsPage + 1
})

reducer.CUSTOMERS_PREVIEW_BOOKINGS_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingBookings: false,
  messagePreviewBookings: message
})

// Form
reducer.CUSTOMER_FORM_GET = (state, { id, addon }) => ({
  ...state,
  pendingForm: true,
  id: id || null,
  addon
})

reducer.CUSTOMER_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.CUSTOMER_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.CUSTOMER_EVENT_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.CUSTOMER_EVENT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.CUSTOMER_BOOKING_PRINT = state => ({
  ...state
})

export default reducer
