import React from 'react'
import PropTypes from 'prop-types'
import { handlers, connect } from '../../../Store'
import { PopupServiceCategoryDelete } from '../../../Beauties'

const PopupServiceCategoryDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    if (id) handlers.serviceCategoryDelete(id)
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupServiceCategoryDelete approve={approve} reject={reject} />
  )
}

PopupServiceCategoryDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

const maps = state => ({
  form: state.forms.serviceCategoryDelete || {}
})

export default connect(maps)(PopupServiceCategoryDeleteWrapper)
