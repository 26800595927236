import React from 'react'
import { Loader } from '../../Common'
import { CustomerFieldPreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const CustomerFieldPreviewChangedLocallyWrapper = props => {
  let { customerField, account, pending } = props
  customerField = customerField || {}
  account = account || {}
  const { locallyChangedData, internalId } = customerField || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalDataFieldsWrite } = enterprisePermissions || {}
  globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite

  const onResetGlobalEntity = (companyId, region) => handlers.customerFieldLocallyChangedReset({ companyId, region, internalId })

  if (pending) return <Loader active />
  return (
    <CustomerFieldPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalDataFieldsWrite={globalDataFieldsWrite}
    />
  )
}

const maps = state => ({
  customerField: (state.router.data.id && state.customerFields.list.find(item => item.id === state.router.data.id)) || {},
  account: state.account,
  pending: state.customerFields && state.customerFields.pendingLocallyChangedPreview
})

export default connect(maps)(CustomerFieldPreviewChangedLocallyWrapper)
