import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Error,
  Title,
  ListItem,
  FormButtons,
  Loader,
  Row,
  Col,
  t,
  Button
} from '../../Common'

import './TwoFAForm.css'

const TwoFADisableForm = ({ code, onSubmit, onCancel, pending }) => {
  return (
    <Form name='twoFA' onSubmit={onSubmit} className='ta-2fa-disable-container'>
      {pending && <Loader active />}
      <Title size='m' icon='lock-open' label={t('user.preview.security.disable.title')} ignoreTopOffset />
      <ListItem>{t('user.preview.security.disable.description')}</ListItem>
      <Row>
        <Col>
          <Input
            name='code'
            label={t('global.pinCode.label')}
            mandatory
            hideError
          />
          <Error noOffset name='code' />
        </Col>
      </Row>
      <FormButtons>
        <Button onClick={onCancel} type='button' isSecondary>{t('global.no')}</Button>
        <Button type='submit' disabled={!code} isPrimary>{t('global.disable')}</Button>
      </FormButtons>
    </Form>
  )
}

TwoFADisableForm.propTypes = {
  code: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  pending: PropTypes.bool
}

export default TwoFADisableForm
