import { store } from '../../../Store'
import { validator, translateServerCode } from '../../../Utils'

// LIST
export const tagsListTransform = tags => {
  if (!tags) return
  return tags.map(item => ({
    ...item
  }))
}

// FORM
export const tagsFormTransform = (tag = {}) => {
  const result = {
    name: {
      value: tag.name || ''
    },
    color: {
      value: tag.color || '#82a774'
    },
    note: {
      value: tag.note || ''
    },
    externalId: {
      value: tag.externalId || ''
    }
  }
  if (tag.id) result.id = tag.id
  return result
}

export const tagsFormValidate = (tag, externalIds) => {
  if (!tag) return
  const rules = [{
    'name.value': ['required', 'max:255']
  }, {
    'color.value': ['required', 'max:255']
  }, {
    'note.value': ['max:400']
  }]
  const messages = {
    'name': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'color': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'note': {
      'max': 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    'name': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'color': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.color.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'note': {
      'max': {
        key: 'MAX',
        value: '400'
      }
    },
    'externalId': {
      'max': {
        value: '255'
      }
    }
  }

  const form = store.getState().forms.tags
  const errors = validator(form, rules, messages, replaces)
  // ADVANCED VALIDATION

  // external id
  if (externalIds.includes(externalIds)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

export const tagsFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

export const tagsSaveTransform = tag => {
  tag = tag || {}
  return {
    id: tag.id,
    name: tag.name.value || '',
    color: (tag.color.value && tag.color.value.toString().toLowerCase()) || '',
    note: tag.note.value || '',
    externalId: tag.externalId.value || null
  }
}

// UPDATE
export const tagsTransform = tag => {
  if (!tag) return
  return {
    ...tag
  }
}
