import React from 'react'
import moment from 'moment'
import {
  Title,
  BorderedBox,
  FormSection,
  t,
  Accordion,
  AccordionTitle,
  AccordionBody,
  FormText,
  ListItem,
  format,
  Row,
  Col
} from '../../../Common'
import { dangerousHTML, generateDateFormat, generateTimeFormat } from '../../../Utils'

const BookingSettingsCalendar = props => {
  let {
    dateFormat,
    timeFormat,
    enforceServiceDurationLimit,
    resetCopyBookingClipboardAfterPaste,
    forbidMoreThanOneBookingInFBW,
    hasNotifyOnResources,
    hasNotifyOnCustomers,
    // hasNotifyResourceSelected,
    // hasNotifyCustomerSelected,
    rawSettings,
    publicHolidays,
    regionsByUniqueKey,
    resourceCategories,
    customisations,
    forbidBookingsInThePast
  } = props
  dateFormat = dateFormat || {}
  timeFormat = timeFormat || {}
  publicHolidays = publicHolidays || []
  regionsByUniqueKey = regionsByUniqueKey || {}
  const hasDateFormat = Object.values(dateFormat).length > 0 && !Object.values(dateFormat).every(item => item === null)
  const hasTimeFormat = Object.values(timeFormat).length > 0 && !Object.values(timeFormat).every(item => item === null)
  const dateFormatValues = {
    position: dateFormat.dateFormatPosition,
    year: dateFormat.dateFormatYear,
    month: dateFormat.dateFormatMonth,
    day: dateFormat.dateFormatDay,
    separator: dateFormat.dateFormatSeparator
  }
  const timeFormatValues = {
    type: timeFormat.timeFormatType,
    punctuation: timeFormat.timeFormatTwelve,
    separator: timeFormat.timeFormatTwentyFour
  }
  const nextYear = moment().add(1, 'years').format('YYYY')
  const exampleDate = `${nextYear}-02-01`
  const exampleTime = moment().format('HH:mm')
  // Customisations
  const { settings } = customisations || {}
  const { hideCalendarSettingsDateFormat, hideCalendarSettingsTimeFormat, hideCalendarSettingsPastBookings } = settings || {}
  let {
    forbidAddingBookingsInThePast,
    forbidUpdatingBookingsInThePast,
    allowPartialUpdateOfBookingsInThePast,
    forbidPastBookingsUpdate,
    forbidDeletingBookingInThePast
  } = forbidBookingsInThePast || {}
  forbidAddingBookingsInThePast = forbidAddingBookingsInThePast || false
  forbidUpdatingBookingsInThePast = forbidUpdatingBookingsInThePast || false
  allowPartialUpdateOfBookingsInThePast = allowPartialUpdateOfBookingsInThePast || false
  forbidDeletingBookingInThePast = forbidDeletingBookingInThePast || false
  let {
    title: pastBookingTitle,
    startTime: pastBookingStartTime,
    duration: pastBookingDuration,
    resources: pastBookingResources,
    prepAndFollowUp: pastBookingPrepAndFollowUp,
    customer: pastBookingCustomer,
    customDataFields: pastBookingCustomDataFields,
    notes: pastBookingNotes,
    tags: pastBookingTags,
    participantsAndGuests: pastBookingParticipantsAndGuests,
    groupPrice: pastBookingGroupPrice
  } = forbidPastBookingsUpdate || {}
  pastBookingTitle = pastBookingTitle || false
  pastBookingStartTime = pastBookingStartTime || false
  pastBookingDuration = pastBookingDuration || false
  pastBookingResources = pastBookingResources || false
  pastBookingPrepAndFollowUp = pastBookingPrepAndFollowUp || false
  pastBookingCustomer = pastBookingCustomer || false
  pastBookingCustomDataFields = pastBookingCustomDataFields || false
  pastBookingNotes = pastBookingNotes || false
  pastBookingTags = pastBookingTags || false
  pastBookingParticipantsAndGuests = pastBookingParticipantsAndGuests || false
  pastBookingGroupPrice = pastBookingGroupPrice || false
  const isPastEditingBookingDisabled = forbidUpdatingBookingsInThePast && !allowPartialUpdateOfBookingsInThePast

  return (
    <div className='ta-booking-settings__calendar'>
      {(!hideCalendarSettingsDateFormat &&
        <FormSection className='no-margin'>
          <Title size='m' isCompact label={t('settings.section.dateFormat.title')} icon='calendar-week' />
          <ListItem>
            {(rawSettings.dateFormat === null &&
              <>
                <strong>{t('global.defaultValue')}</strong>
                <br />
              </>
            )}
            {!hasDateFormat && dangerousHTML(t('settings.section.dateFormat.hasNotDateFormat'))}
            {(hasDateFormat &&
              dangerousHTML(t('settings.section.dateFormat.hasDateFormat', [
                { key: 'DATE', value: format(exampleDate, generateDateFormat(dateFormatValues)) }
              ]))
            )}
          </ListItem>
        </FormSection>
      )}
      {(!hideCalendarSettingsTimeFormat &&
        <FormSection className='no-margin'>
          <Title size='m' isCompact label={t('settings.section.timeFormat.title')} icon='clock' />
          <ListItem>
            {(rawSettings.timeFormat === null &&
              <>
                <strong>{t('global.defaultValue')}</strong>
                <br />
              </>
            )}
            {!hasTimeFormat && dangerousHTML(t('settings.section.timeFormat.hasNotTimeFormat'))}
            {(hasTimeFormat &&
              dangerousHTML(t('settings.section.timeFormat.hasTimeFormat', [
                { key: 'TIME', value: format(exampleTime, generateTimeFormat(timeFormatValues), { isUTC: true, format: 'HH:mm' }) }
              ]))
            )}
          </ListItem>
        </FormSection>
      )}
      <FormSection>
        <Title size='m' isCompact label={t('booking.settings.bookingLengthFlexibility.title')} icon='expand' />
        <ListItem>
          {(rawSettings.enforceServiceDurationLimit === -1 &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {(enforceServiceDurationLimit !== undefined && enforceServiceDurationLimit !== null && enforceServiceDurationLimit === -1
            ? <strong>{t('settings.bookingLengthFlexibility.notSet')}</strong>
            : <p>{dangerousHTML(t('settings.bookingLengthFlexibility.set', [{ key: 'LIMIT', value: enforceServiceDurationLimit }]))}</p>
          )}
        </ListItem>
      </FormSection>
      <FormSection>
        <Title size='m' isCompact label={t('settings.section.copyPaste.heading')} icon='copy' />
        <ListItem>
          {(rawSettings.resetCopyBookingClipboardAfterPaste === null &&
            <strong>{t('global.defaultValue')}</strong>
          )}
          {resetCopyBookingClipboardAfterPaste && t('settings.section.copyBooking.radioClearAfterPaste')}
          {!resetCopyBookingClipboardAfterPaste && t('settings.section.copyBooking.radioKeepCopyAfterPaste')}
        </ListItem>
      </FormSection>
      {(!hideCalendarSettingsPastBookings &&
        <FormSection className='no-margin'>
          <Title size='m' isCompact label={t('customers.tabBookings.section.past.heading')} icon='ban' />
          <ListItem>
            <Title theme='striped' label={t('settings.section.pastBooking.lockAddingPastBookingLabel')} />
            <Row>
              <Col>
                <ListItem className='bullet-item service-allocations'>
                  <strong>{t(forbidAddingBookingsInThePast ? 'global.notPermitted' : 'global.permitted')}</strong>
                </ListItem>
              </Col>
            </Row>
            <Title theme='striped' label={t('settings.section.pastBooking.lockEditingPastBookingLabel')} />
            {(!forbidUpdatingBookingsInThePast &&
              <Row>
                <Col>
                  <ListItem className='bullet-item service-allocations'>
                    <strong>{t('global.permitted')}</strong>
                  </ListItem>
                </Col>
              </Row>
            )}
            {(forbidUpdatingBookingsInThePast && isPastEditingBookingDisabled &&
              <Row>
                <Col>
                  <ListItem className='bullet-item service-allocations'>
                    <strong>{t('global.notPermitted')}</strong>
                  </ListItem>
                </Col>
              </Row>
            )}
            {(forbidUpdatingBookingsInThePast && !isPastEditingBookingDisabled &&
              <>
                <ListItem className='bullet-item service-allocations'>
                  <strong>{t('settings.section.pastBooking.disableSomeLabel.previewLabel')}</strong>
                </ListItem>
                <ListItem>
                  {(pastBookingTitle &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('settings.section.pastBooking.preventEditing.disableSomeLabel.title')}
                    </ListItem>
                  )}
                  {(pastBookingStartTime &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('settings.section.pastBooking.preventEditing.disableSomeLabel.startTime')}
                    </ListItem>
                  )}
                  {(pastBookingDuration &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('settings.section.pastBooking.preventEditing.disableSomeLabel.duration')}
                    </ListItem>
                  )}
                  {(pastBookingResources &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('branches.form.group.edit.resources.title')}
                    </ListItem>
                  )}
                  {(pastBookingPrepAndFollowUp &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('settings.section.pastBooking.preventEditing.disableSomeLabel.prepAndFollowUp')}
                    </ListItem>
                  )}
                  {(pastBookingCustomer &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('global.customer')}
                    </ListItem>
                  )}
                  {(pastBookingCustomDataFields &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('settings.section.pastBooking.preventEditing.disableSomeLabel.customDataFields')}
                    </ListItem>
                  )}
                  {(pastBookingGroupPrice &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('global.price')}
                    </ListItem>
                  )}
                  {(pastBookingParticipantsAndGuests &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('settings.section.pastBooking.preventEditing.disableSomeLabel.participantsAndGuests')}
                    </ListItem>
                  )}
                  {(pastBookingNotes &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('global.notes')}
                    </ListItem>
                  )}
                  {(pastBookingTags &&
                    <ListItem className='bullet-item service-allocations ta-settings-ics__list_items'>
                      {t('global.tags')}
                    </ListItem>
                  )}
                </ListItem>
              </>
            )}
            <Title theme='striped' label={t('settings.section.pastBooking.lockDeletingPastBookingLabel')} />
            <ListItem className='bullet-item service-allocations'>
              <strong>{t(forbidDeletingBookingInThePast ? 'global.notPermitted' : 'global.permitted')}</strong>
            </ListItem>
          </ListItem>
        </FormSection>
      )}
      <FormSection>
        <Title size='m' isCompact label={t('booking.settings.fastBookingWidget.title')} icon='tasks' />
        <ListItem>
          {(rawSettings.forbidMoreThanOneBookingInFBW === null &&
            <strong>{t('global.defaultValue')}</strong>
          )}
          <p>{t(forbidMoreThanOneBookingInFBW ? 'global.disabled' : 'global.enabled')}</p>
        </ListItem>
      </FormSection>
      <FormSection className='no-margin'>
        <Title size='m' isCompact label={t('settings.section.publicHolidays.title')} icon='wreath' />
        <ListItem>
          {(publicHolidays.length > 0
            ? dangerousHTML(t('settings.section.publicHolidays.hasPublicHolidays'))
            : dangerousHTML(t('settings.section.publicHolidays.hasNotPublicHolidays'))
          )}
        </ListItem>
        {(publicHolidays.length > 0 &&
          <div className='ta-settings-booking__calendar__holidays'>
            {publicHolidays.map(item => {
              const holidayResourceCategories = item.resourceCategoryIds || []
              const selectedResourceCategories = resourceCategories.filter(resourceCategoryItem => holidayResourceCategories.includes(resourceCategoryItem.id))

              return (
                <Accordion key={item.id} className='ta-settings-booking__calendar__holidays-accordion'>
                  <AccordionTitle>
                    {t('global.resourceGroup')}
                    {(item.publicHolidayKeys && item.publicHolidayKeys.length > 0 &&
                      <span> (
                        {regionsByUniqueKey[item.publicHolidayKeys[0]] && regionsByUniqueKey[item.publicHolidayKeys[0]].label}
                        {item.publicHolidayKeys.length > 1 && ` + ${item.publicHolidayKeys.length - 1} ${t(`settings.section.publicHolidays.accordion.other${item.publicHolidayKeys.length - 1 === 1 ? '' : 's'}`)}`}
                        )
                      </span>
                    )}
                  </AccordionTitle>
                  <AccordionBody>
                    <FormText>{t('settings.section.publicHolidays.selected.holidays')}</FormText>
                    {item.publicHolidayKeys.map(regionItem => (
                      <span key={regionItem} className='ta-settings-booking__calendar__holiday-value-item'>
                        {regionsByUniqueKey[regionItem] && regionsByUniqueKey[regionItem].label}
                      </span>
                    ))}
                    <br />
                    <br />
                    <FormText>{t('settings.section.publicHolidays.selected.resources')}</FormText>
                    {(item.resourceCategoryIds && item.resourceCategoryIds.length === 0
                      ? <span className='ta-settings-booking__calendar__holiday-value-item'>
                        {t('global.allResources')}
                        </span>
                      : <>
                        <Title theme='striped' label={t('global.categories')} />
                        {selectedResourceCategories.map((resourceCategory, key) => (
                          <div className='ta-settings-booking__calendar__holiday-value-item' key={key}>
                            <div className='ta-settings-booking__calendar__holiday-value-item-label'>
                              {resourceCategory.label}
                            </div>
                          </div>
                        ))}
                        </>
                    )}
                  </AccordionBody>
                </Accordion>
              )
            })}
          </div>
        )}
      </FormSection>
      <FormSection>
        <Title size='m' isCompact label={t('settings.section.notifyCheckboxes.heading')} icon='envelope' />
        <BorderedBox className='no-border'>
          <ListItem className='ta-notifications-settings__item'>
            {hasNotifyOnResources === 'default' && t('settings.section.notifyCheckboxes.resources.radio.flexiblySelectedDefault')}
            {hasNotifyOnResources === 'selected' && t('settings.section.notifyCheckboxes.resources.radio.alwaysSelected')}
            {hasNotifyOnResources === 'deselected' && t('settings.section.notifyCheckboxes.resources.radio.neverSelected')}
          </ListItem>
          <ListItem className='ta-notifications-settings__item'>
            {hasNotifyOnCustomers === 'default' && t('settings.section.notifyCheckboxes.customers.radio.flexiblySelectedDefault')}
            {hasNotifyOnCustomers === 'selected' && t('settings.section.notifyCheckboxes.customers.radio.alwaysSelected')}
            {hasNotifyOnCustomers === 'deselected' && t('settings.section.notifyCheckboxes.customers.radio.neverSelected')}
          </ListItem>
        </BorderedBox>
      </FormSection>
    </div>
  )
}

export default BookingSettingsCalendar
