import React from 'react'
import moment from 'moment'
import {
  Row,
  Col,
  SingleSelect,
  Input,
  Textarea,
  Checkbox,
  PhoneInput,
  DateInput,
  ImageInput,
  AddressInput,
  t
} from '../../../Common'

const CustomerFormField = props => {
  const { field = {}, availableFields = [], timezones = [], hasAutocomplete } = props
  const classNames = ['ta-customers__form-row']
  if (field.isDefault) {
    if (field.defaultId === 'avatar') classNames.push('avatar')
    if (availableFields.includes('avatar') && field.defaultId !== 'avatar') classNames.push('with-avatar')
    if (
      availableFields.includes('salutation') &&
      availableFields.includes('title') &&
      (field.defaultId === 'salutation' || field.defaultId === 'title')
    ) {
      classNames.push('half')
      if (field.defaultId === 'title') classNames.push('left')
    }
  }
  const name = `customerFields${field.id}`
  const mandatory = field.isMandatoryOffline
  const label = field.label || t(field.translationKey)
  const TYPE_INPUT = ['TEXT', 'EMAIL']
  let type = field.type
  if (field.defaultId === 'avatar') type = 'AVATAR'

  return (
    <Row className={classNames.join(' ')}>
      <Col>
        {(TYPE_INPUT.includes(type) &&
          <Input
            name={name}
            label={label}
            mandatory={mandatory}
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'SELECT' &&
          <SingleSelect
            name={name}
            label={label}
            mandatory={mandatory}
            clearable
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'TIMEZONE' &&
          <SingleSelect
            name={name}
            label={label}
            mandatory={mandatory}
            options={timezones.map(item => ({ value: item.code, label: item.label }))}
            clearable
            searchable
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'TEXTAREA' &&
          <Textarea
            name={name}
            label={label}
            mandatory={mandatory}
            limit={3000}
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'CHECKBOX' &&
          <Checkbox name={name} label={field.label} />
        )}
        {(type === 'PHONE' &&
          <PhoneInput
            name={name}
            label={label}
            mandatory={mandatory}
            top
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'DATE' &&
          <DateInput
            name={name}
            label={label}
            icon='calendar'
            timezone='UTC'
            showMonth
            showYear
            mandatory={mandatory}
            maxDate={field.defaultId === 'birthday' ? moment() : null}
            clearable
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'ADDRESS' &&
          <AddressInput
            name={name}
            label={label}
            defaultZoom={1.5}
            defaultBorderRadius={50}
            controls={{ radius: false }}
            validation={{ maxSize: 7 }}
            mandatory={mandatory}
            withMap
            clearable
            hasAutocomplete={hasAutocomplete}
          />
        )}
        {(type === 'FILE' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            title={t('customers.form.section.details.avatar.title')}
            text={t('customers.form.section.details.avatar.text')}
          />
        )}
        {(type === 'AVATAR' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            title={t('customers.form.section.details.avatar.title')}
            text={t('customers.form.section.details.avatar.text')}
          />
        )}
      </Col>
    </Row>
  )
}

export default CustomerFormField
