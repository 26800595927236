import {
  settingsNotificationsFormTransform,
  settingsCustomersFormTransform,
  settingsSecurityFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: true
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  settingsNotificationsReset: () => dispatch(actions.SETTINGS_NOTIFICATIONS_RESET),

  // Form Notifications
  settingsNotificationsFormPopulate: settings => {
    handlers.formSet('settings', settingsNotificationsFormTransform(settings))
    handlers.settingsNotificationsFormReady()
  },
  settingsNotificationsFormReady: () => dispatch(actions.SETTINGS_NOTIFICATIONS_FORM_READY),
  settingsNotificationsFormSave: (settings, scrollToError = () => { }) => dispatch(actions.SETTINGS_NOTIFICATIONS_FORM_SAVE, { settings, scrollToError }),

  // Form Customers
  settingsCustomersFormPopulate: settings => {
    handlers.formSet('settingsCustomers', settingsCustomersFormTransform(settings))
    handlers.settingsCustomersFormReady()
  },
  settingsCustomersFormReady: () => dispatch(actions.SETTINGS_CUSTOMERS_FORM_READY),
  settingsCustomersFormSave: (settings, scrollToError = () => { }) => dispatch(actions.SETTINGS_CUSTOMERS_FORM_SAVE, { settings, scrollToError }),
  settingsCustomersFormAddRule: () => dispatch(actions.SETTINGS_CUSTOMERS_FORM_ADD_RULE),

  // Form Security
  settingsSecurityFormPopulate: settings => {
    handlers.formSet('settingsSecurity', settingsSecurityFormTransform(settings))
    handlers.settingsSecurityFormReady()
  },
  settingsSecurityFormReady: () => dispatch(actions.SETTINGS_SECURITY_FORM_READY),
  settingsSecurityFormSave: (settings, scrollToError = () => {}) => dispatch(actions.SETTINGS_SECURITY_FORM_SAVE, { settings, scrollToError })
})

reducer.SETTINGS_NOTIFICATIONS_RESET = state => reducer.initialState

reducer.SETTINGS_NOTIFICATIONS_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.SETTINGS_NOTIFICATIONS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.SETTINGS_CUSTOMERS_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.SETTINGS_CUSTOMERS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.SETTINGS_CUSTOMERS_FORM_ADD_RULE = state => ({
  ...state,
  pendingForm: true
})

reducer.SETTINGS_SECURITY_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.SETTINGS_SECURITY_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
