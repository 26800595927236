import React from 'react'
import {
  TableRow,
  TableRowCol,
  TableRowMainCol,
  TableRowMainColTitle,
  TableRowMainColContent,
  PlanBadge,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  format,
  t,
  Button
} from '../../Common'
import { highlight } from '../../Utils'

const BranchesListItem = props => {
  const {
    branch,
    search,
    index,
    onClick,
    hideBranchLoginBtn,
    tagsRead,
    allowFulfilment,
    showBranchLoginWithoutCustomisationsBtn
  } = props
  let {
    id,
    name,
    personName,
    tags,
    address,
    phone,
    plan,
    createdAt,
    onlineStatus,
    ownerEmail
  } = branch || {}
  tags = tags || []
  const isFirstItem = index === 0
  const { wantsToBeOnline, isOnline } = onlineStatus || {}
  const { phone: formattedPhone } = phone || {}
  const {
    formatted: formattedAddress,
    city,
    country
  } = address || {}
  let color = 'grey'
  if (isOnline) color = 'green'
  else if (wantsToBeOnline) color = 'yellow'
  let buttonsColWidth = '32px'
  if (showBranchLoginWithoutCustomisationsBtn && !hideBranchLoginBtn) buttonsColWidth = '62px'

  return (
    <div className='ta-branches__list__item'>
      <TableRow
        color={color}
        to={`/branches/${id}/preview`}
      >
        <TableRowMainCol>
          <TableRowMainColTitle className='ta-branches__list__item__title'>
            {highlight(name || '-', search)}
            {tagsRead && tags.map((item, i) =>
              <HoverPopup key={i}>
                <HoverPopupContent position={isFirstItem ? 'bottom' : 'top'} autoSize>
                  {item.name}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <FontAwesome5 icon='circle' type='solid' style={{ color: item.color || null }} />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </TableRowMainColTitle>
          <TableRowMainColContent>
            <ul>
              {(address &&
                <li>
                  <FontAwesome5 icon='map' type='solid' /> <strong>{highlight(formattedAddress || `${city}, ${country}` || '-', search)}</strong>
                </li>
              )}
              {(personName &&
                <li>
                  <FontAwesome5 icon='user' type='solid' /> {highlight(personName || '-', search)}
                </li>
              )}
              {(formattedPhone &&
                <li>
                  <FontAwesome5 icon='phone' type='solid' /> {highlight(formattedPhone || '-', search)}
                </li>
              )}
              {(ownerEmail &&
                <li>
                  <FontAwesome5 icon='envelope' type='solid' /> {highlight(ownerEmail || '-', search)}
                </li>
              )}
            </ul>
          </TableRowMainColContent>
        </TableRowMainCol>
        <TableRowCol size='110px'>
          <PlanBadge plan={plan} />
        </TableRowCol>
        <TableRowCol size='110px'>{createdAt ? format(createdAt, 'longWithTime') : '-'}</TableRowCol>
        {(allowFulfilment && (showBranchLoginWithoutCustomisationsBtn || !hideBranchLoginBtn) &&
          <TableRowCol size={buttonsColWidth} relative>
            <div className='ta-branches__list__item__buttons'>
              {(showBranchLoginWithoutCustomisationsBtn &&
                <HoverPopup>
                  <HoverPopupContent position='left'>
                    {t('branches.list.tooltip.loginWithoutCustomisations.message')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button icon='key' iconType='r' isGhost isTertiary className='ta-branches__list__item__link-btn ta-btn__login-without-customisations' onClick={e => onClick({ e, id, isCustomisationIgnored: true })} />
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(!hideBranchLoginBtn &&
                <HoverPopup>
                  <HoverPopupContent position='left'>
                    {t('branches.list.tooltip.loginWithCustomisations.message')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button icon='key' isSecondary iconType='solid' className='ta-branches__list__item__link-btn ta-btn__login-without-customisations' onClick={e => onClick({ e, id })} />
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </div>
          </TableRowCol>
        )}
      </TableRow>
    </div>
  )
}

export default BranchesListItem
