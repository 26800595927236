import React from 'react'
import { connect } from '../../Store'
import {
  AppsPreviewWrapper,
  AppsIframeWrapper,
  AppsUninstallWrapper,
  AppsSubscriptionWrapper,
  AppsSubscriptionAddWrapper,
  AppsSubscriptionCancelledWrapper,
  AppsSubscriptionFailedWrapper,
  AppsSubscriptionGoneWrapper,
  BranchesPreviewWrapper,
  BranchesAddWrapper,
  BranchesImportWrapper,
  AccountEditWrapper,
  UsersAddWrapper,
  UsersEditWrapper,
  UsersPreviewWrapper,
  UserGroupsAddWrapper,
  UserGroupsEditWrapper,
  UserGroupsPreviewWrapper,
  BranchesEditWrapper,
  BranchesPreviewResourcesEditWrapper,
  BranchesPreviewResourcesPasswordChangeWrapper,
  BranchesPreviewServicesEditWrapper,
  BranchesPreviewServiceCombinationEditWrapper,
  BranchesServicesImportWrapper,
  BranchesServiceCategoriesImportWrapper,
  BranchesGroupsImportWrapper,
  BranchesGroupCategoriesImportWrapper,
  BranchesResourcesImportWrapper,
  BranchesResourceCategoriesImportWrapper,
  BranchesPreviewGroupsEditWrapper,
  BranchesPreviewCustomerEditWrapper,
  UserEditWrapper,
  UserEmailChangeWrapper,
  UserPasswordChangeWrapper,
  BookingSettingsEditFormWrapper,
  // WebAppSettingsEditFormWrapper,
  EmailSettingsEditFormWrapper,
  CustomersSettingsEditFormWrapper,
  CustomerFieldPreviewWrapper,
  CustomerFieldAddWrapper,
  CustomerFieldEditWrapper,
  CustomerFieldCategoryPreviewWrapper,
  CustomerFieldCategoryAddWrapper,
  CustomerFieldCategoryEditWrapper,
  CourseAddWrapper,
  CourseEditWrapper,
  CoursePreviewWrapper,
  CourseCategoryAddWrapper,
  CourseCategoryEditWrapper,
  CourseCategoryPreviewWrapper,
  ServiceAddWrapper,
  ServiceEditWrapper,
  ServicePreviewWrapper,
  ServiceCategoryAddWrapper,
  ServiceCategoryEditWrapper,
  ServiceCategoryPreviewWrapper,
  ServiceCombinationAddWrapper,
  ServiceCombinationEditWrapper,
  CustomerAddWrapper,
  CustomerEditWrapper,
  CustomerPreviewWrapper,
  TagsPreviewWrapper,
  TagsEditWrapper,
  TagsAddWrapper,
  CompanyTagPreviewWrapper,
  CompanyTagEditWrapper,
  CompanyTagAddWrapper,
  ResourceCategoryPreviewWrapper,
  ResourceCategoryAddWrapper,
  ResourceCategoryEditWrapper,
  GlobalsSettingsEditWrapper,
  UserLanguageEditWrapper,
  SecuritySettingsEditFormWrapper,
  BillingEditWrapper,
  UserPermissionsPreviewWrapper,
  UserPermissionsEditWrapper,
  UserPermissionsAddWrapper,
  PermissionTypeAddWrapper,
  PermissionTypeEditWrapper,
  PermissionTypePreviewWrapper,
  ServiceChangedLocallyRestoreWrapper,
  CourseChangedLocallyRestoreWrapper
} from '../../Beasts'

const LeftSecondSidebarWrapper = props => {
  const { route } = props

  return (
    <>
      {route === 'appsPreview' && <AppsPreviewWrapper />}
      {route === 'appsIframe' && <AppsIframeWrapper />}
      {route === 'appsUninstall' && <AppsUninstallWrapper />}
      {route === 'appsSubscription' && <AppsSubscriptionWrapper />}
      {route === 'appsSubscriptionAdd' && <AppsSubscriptionAddWrapper />}
      {route === 'appsSubscriptionCancelled' && <AppsSubscriptionCancelledWrapper />}
      {route === 'appsSubscriptionFailed' && <AppsSubscriptionFailedWrapper />}
      {route === 'appsSubscriptionGone' && <AppsSubscriptionGoneWrapper />}
      {route === 'accountEdit' && <AccountEditWrapper />}
      {route === 'accountBillingEdit' && <BillingEditWrapper />}
      {route === 'tagsAdd' && <TagsAddWrapper />}
      {route === 'tagsEdit' && <TagsEditWrapper />}
      {route === 'tagsPreview' && <TagsPreviewWrapper />}
      {route === 'branchesPreview' && <BranchesPreviewWrapper />}
      {route === 'branchesAdd' && <BranchesAddWrapper />}
      {route === 'branchesImport' && <BranchesImportWrapper />}
      {route === 'branchesEdit' && <BranchesEditWrapper />}
      {route === 'branchesResourceEdit' && <BranchesPreviewResourcesEditWrapper />}
      {route === 'branchesResourcePasswordChange' && <BranchesPreviewResourcesPasswordChangeWrapper />}
      {route === 'branchesServiceEdit' && <BranchesPreviewServicesEditWrapper />}
      {route === 'branchesServiceCombinationEdit' && <BranchesPreviewServiceCombinationEditWrapper />}
      {route === 'branchesServicesImport' && <BranchesServicesImportWrapper />}
      {route === 'branchesServiceCategoriesImport' && <BranchesServiceCategoriesImportWrapper />}
      {route === 'branchesGroupsImport' && <BranchesGroupsImportWrapper />}
      {route === 'branchesGroupCategoriesImport' && <BranchesGroupCategoriesImportWrapper />}
      {route === 'branchesResourcesImport' && <BranchesResourcesImportWrapper />}
      {route === 'branchesResourceCategoriesImport' && <BranchesResourceCategoriesImportWrapper />}
      {route === 'branchesGroupEdit' && <BranchesPreviewGroupsEditWrapper />}
      {route === 'branchesCustomerEdit' && <BranchesPreviewCustomerEditWrapper />}
      {route === 'usersAdd' && <UsersAddWrapper />}
      {route === 'usersEdit' && <UsersEditWrapper />}
      {route === 'usersPreview' && <UsersPreviewWrapper />}
      {route === 'userGroupsAdd' && <UserGroupsAddWrapper />}
      {route === 'userGroupsEdit' && <UserGroupsEditWrapper />}
      {route === 'userGroupsPreview' && <UserGroupsPreviewWrapper />}
      {route === 'userEdit' && <UserEditWrapper />}
      {route === 'userEditPassword' && <UserPasswordChangeWrapper />}
      {route === 'userEditEmail' && <UserEmailChangeWrapper />}
      {route === 'userEditLanguage' && <UserLanguageEditWrapper />}
      {route === 'customersAdd' && <CustomerAddWrapper />}
      {route === 'customersEdit' && <CustomerEditWrapper />}
      {route === 'customerPreview' && <CustomerPreviewWrapper />}
      {route === 'customerFieldsPreview' && <CustomerFieldPreviewWrapper />}
      {route === 'customerFieldAdd' && <CustomerFieldAddWrapper />}
      {route === 'customerFieldsEdit' && <CustomerFieldEditWrapper />}
      {route === 'customerFieldCategoryPreview' && <CustomerFieldCategoryPreviewWrapper />}
      {route === 'customerFieldsCategoryAdd' && <CustomerFieldCategoryAddWrapper />}
      {route === 'customerFieldsCategoryEdit' && <CustomerFieldCategoryEditWrapper />}
      {route === 'courseAdd' && <CourseAddWrapper />}
      {route === 'courseEdit' && <CourseEditWrapper />}
      {route === 'coursePreview' && <CoursePreviewWrapper />}
      {(route === 'courseRestore' || route === 'courseBranchRestore') && <CourseChangedLocallyRestoreWrapper />}
      {route === 'courseCategoryAdd' && <CourseCategoryAddWrapper />}
      {route === 'courseCategoryEdit' && <CourseCategoryEditWrapper />}
      {route === 'courseCategoryPreview' && <CourseCategoryPreviewWrapper />}
      {route === 'globalsSettingsEdit' && <GlobalsSettingsEditWrapper />}
      {route === 'serviceAdd' && <ServiceAddWrapper />}
      {route === 'serviceEdit' && <ServiceEditWrapper />}
      {route === 'serviceCombinationAdd' && <ServiceCombinationAddWrapper />}
      {route === 'serviceCombinationEdit' && <ServiceCombinationEditWrapper />}
      {route === 'servicePreview' && <ServicePreviewWrapper />}
      {(route === 'serviceBranchRestore' || route === 'serviceRestore') && <ServiceChangedLocallyRestoreWrapper />}
      {route === 'serviceCategoryAdd' && <ServiceCategoryAddWrapper />}
      {route === 'serviceCategoryEdit' && <ServiceCategoryEditWrapper />}
      {/* {route === 'webAppSettingsEdit' && <WebAppSettingsEditFormWrapper />} */}
      {route === 'bookingSettingsEdit' && <BookingSettingsEditFormWrapper />}
      {route === 'emailSettingsEdit' && <EmailSettingsEditFormWrapper />}
      {route === 'customersSettingsEdit' && <CustomersSettingsEditFormWrapper />}
      {route === 'securitySettingsEdit' && <SecuritySettingsEditFormWrapper />}
      {route === 'branchesTagsPreview' && <TagsPreviewWrapper />}
      {route === 'branchesTagsEdit' && <TagsEditWrapper />}
      {route === 'branchesTagsAdd' && <TagsAddWrapper />}
      {route === 'serviceCategoryPreview' && <ServiceCategoryPreviewWrapper />}
      {route === 'tagPreview' && <TagsPreviewWrapper />}
      {route === 'tagEdit' && <TagsEditWrapper />}
      {route === 'tagAdd' && <TagsAddWrapper />}
      {route === 'resourceCategoryPreview' && <ResourceCategoryPreviewWrapper />}
      {route === 'resourceCategoryAdd' && <ResourceCategoryAddWrapper />}
      {route === 'resourceCategoryEdit' && <ResourceCategoryEditWrapper />}
      {route === 'companyTagPreview' && <CompanyTagPreviewWrapper />}
      {route === 'companyTagEdit' && <CompanyTagEditWrapper />}
      {route === 'companyTagAdd' && <CompanyTagAddWrapper />}
      {route === 'permissionTypeAdd' && <PermissionTypeAddWrapper />}
      {route === 'permissionTypeEdit' && <PermissionTypeEditWrapper />}
      {route === 'permissionTypePreview' && <PermissionTypePreviewWrapper />}
      {route === 'userPermissionsPreview' && <UserPermissionsPreviewWrapper />}
      {route === 'userPermissionsEdit' && <UserPermissionsEditWrapper />}
      {route === 'userPermissionsAdd' && <UserPermissionsAddWrapper />}
    </>
  )
}

const maps = state => ({
  route: state.router.name
})

export default connect(maps)(LeftSecondSidebarWrapper)
