import React from 'react'
import DOMPurify from 'dompurify'
import { encodeHtml } from '../Utils'

export default (text, highlight, isBlackTextColor) => {
  if (!text) return null
  if (!highlight) return text
  text = DOMPurify.sanitize(encodeHtml(text))
  highlight = encodeHtml(highlight.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'))
  const regex = new RegExp(`(${highlight})`, 'ig')
  return <span dangerouslySetInnerHTML={{ __html: text.replace(regex, `<span class="highlight${isBlackTextColor ? '-black' : ''}">$1</span>`) }} />
}
