import React from 'react'

const ChartShapeRoundBar = props => {
  const { fill, x, y, width, height, horizontal } = props
  const getPath = (x, y, width, height) => {
    return !horizontal
      ? `M${x},${y + 3}
            L${x},${y + height}
            L${x + width},${y + height}
            L${x + width},${y + 3}
            A3,3 0 0,0 ${x + width - 3},${y}
            L${x + 3},${y}
            A3,3 0 0,0 ${x},${y + 3}
            Z`
      : `M${x + width - 3},${y}
        L${x},${y}
        L${x},${y + height}
        L${x + width - 3},${y + height}
        A3,3 0 0,0 ${x + width},${y + height - 3}
        L${x + width},${y + 3}
        A3,3 0 0,0 ${x + width - 3},${y}
        Z`
  }

  return <path d={getPath(x, y, width, height)} stroke='none' fill={fill} />
}

export default ChartShapeRoundBar
