import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Form,
  FormButtons,
  FormSection,
  Checkbox,
  Error,
  t,
  Alert,
  BorderedBox,
  Row,
  Col,
  Radio
} from '../../../../Common'

const SettingsForm = props => {
  const {
    title,
    onSubmit,
    cancelLink,
    isCourseLocalUpdateEnabled,
    isServiceLocalUpdateEnabled,
    isCombinationServiceLocalUpdateEnabled,
    singleServiceEdit,
    courseEdit,
    combinationEdit,
    hash
  } = props
  const availableTabs = ['calendar']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]

  return (
    <div className='ta-globals-settings__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='globalsSettings'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem icon='edit' name='calendar' label={t('globalSettings.form.tab.localUpdates')} />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='calendar'>
                  <Alert theme='info'>
                    {t('globalSettings.form.section.message.body')}
                  </Alert>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.global.form.section.services.localChanges')} icon='list' />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isServiceLocalUpdateEnabled'
                          label={t('globalSettings.form.section.services.localChanges.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {isServiceLocalUpdateEnabled.value && (
                      <BorderedBox>
                        <Row>
                          <Col>
                            <Radio name='singleServiceEdit' value='ALL' label={t('globalSettings.form.attributes.selectAll.label')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Radio name='singleServiceEdit' value='SOME' label={t('globalSettings.form.attributes.selectSome.label')} />
                          </Col>
                        </Row>
                        {singleServiceEdit.value === 'SOME' && (
                          <BorderedBox className='global-settings_attributes'>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceExternalID' label={t('global.externalId')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceServiceName' label={t('globalSettings.form.section.attributes.serviceName')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceServiceColor' label={t('globalSettings.form.section.attributes.serviceColor')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceServiceDescription' label={t('globalSettings.form.section.attributes.serviceDescription')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceDurationSplitInterval' label={t('globalSettings.form.section.attributes.duration')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServicePreparationTime' label={t('globalSettings.form.section.attributes.preparationTime')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceFollowupTime' label={t('globalSettings.form.section.attributes.followup')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceResourcesAndDependencies' label={t('globalSettings.form.section.attributes.resources')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServicePrice' label={t('globalSettings.form.section.attributes.price')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceSettingDisplayInBookingWidget' label={t('globalSettings.form.section.attributes.displayInBookingWidget')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceSettingOnlinePayment' label={t('globalSettings.form.section.attributes.onlinePayment')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceSettingCheckboxToMakeOnlinePaymentMandatory' label={t('globalSettings.form.section.attributes.onlinePaymentMandatory')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceCustomerEmailReminders' label={t('globalSettings.form.section.attributes.customerEmailReminders')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='singleServiceResourceEmailReminders' label={t('globalSettings.form.section.attributes.resourceEmailReminders')} theme='switch' />
                              </Col>
                            </Row>
                          </BorderedBox>
                        )}
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('globalSettings.form.section.services.combinationChanges')} icon='list' />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isCombinationServiceLocalUpdateEnabled'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {isCombinationServiceLocalUpdateEnabled.value && (
                      <BorderedBox>
                        <Row>
                          <Col>
                            <Radio name='combinationEdit' value='ALL' label={t('globalSettings.form.attributes.selectAll.label')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Radio name='combinationEdit' value='SOME' label={t('globalSettings.form.attributes.selectSome.label')} />
                          </Col>
                        </Row>
                        {combinationEdit.value === 'SOME' && (
                          <BorderedBox className='global-settings_attributes'>
                            <Row>
                              <Col>
                                <Checkbox name='combinationExternalID' label={t('global.externalId')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationServiceName' label={t('globalSettings.form.section.attributes.serviceName')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationServiceDescription' label={t('globalSettings.form.section.attributes.serviceDescription')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationServiceSelection' label={t('globalSettings.form.section.attributes.serviceSelection')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationPreparationTime' label={t('globalSettings.form.section.attributes.preparationTime')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationFollowupTime' label={t('globalSettings.form.section.attributes.followup')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationPrice' label={t('globalSettings.form.section.attributes.price')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationAssignToSameResource' label={t('globalSettings.form.section.attributes.assignToSameResource')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationSettingDisplayInBookingWidget' label={t('globalSettings.form.section.attributes.displayInBookingWidget')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationSettingOnlinePayment' label={t('globalSettings.form.section.attributes.onlinePayment')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationSettingCheckboxToMakeOnlinePaymentMandatory' label={t('globalSettings.form.section.attributes.onlinePaymentMandatory')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationCustomerEmailReminders' label={t('globalSettings.form.section.attributes.customerEmailReminders')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='combinationResourceEmailReminders' label={t('globalSettings.form.section.attributes.resourceEmailReminders')} theme='switch' />
                              </Col>
                            </Row>
                          </BorderedBox>
                        )}
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.global.form.section.courses.title')} icon='list' />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isCourseLocalUpdateEnabled'
                          label={t('globalSettings.form.section.courses.changes.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {isCourseLocalUpdateEnabled.value && (
                      <BorderedBox>
                        <Row>
                          <Col>
                            <Radio name='courseEdit' value='ALL' label={t('globalSettings.form.attributes.selectAll.label')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Radio name='courseEdit' value='SOME' label={t('globalSettings.form.attributes.selectSome.label')} />
                          </Col>
                        </Row>
                        {courseEdit.value === 'SOME' && (
                          <BorderedBox className='global-settings_attributes'>
                            <Row>
                              <Col>
                                <Checkbox name='courseExternalID' label={t('global.externalId')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseServiceName' label={t('globalSettings.form.section.attributes.serviceName')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseServiceColor' label={t('globalSettings.form.section.attributes.serviceColor')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseServiceDescription' label={t('globalSettings.form.section.attributes.serviceDescription')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseDurationSplitInterval' label={t('globalSettings.form.section.attributes.duration')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='coursePreparationTime' label={t('globalSettings.form.section.attributes.preparationTime')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseFollowupTime' label={t('globalSettings.form.section.attributes.followup')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseMaxParticipants' label={t('global.participants')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseGuests' label={t('globalSettings.form.section.attributes.guests')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseResourcesAndDependencies' label={t('globalSettings.form.section.attributes.resources')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='coursePrice' label={t('globalSettings.form.section.attributes.price')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseSettingDisplayInBookingWidget' label={t('globalSettings.form.section.attributes.displayInBookingWidget')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseSettingOnlinePayment' label={t('globalSettings.form.section.attributes.onlinePayment')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseSettingCheckboxToMakeOnlinePaymentMandatory' label={t('globalSettings.form.section.attributes.onlinePaymentMandatory')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseCustomerEmailReminders' label={t('globalSettings.form.section.attributes.customerEmailReminders')} theme='switch' />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox name='courseResourceEmailReminders' label={t('globalSettings.form.section.attributes.resourceEmailReminders')} theme='switch' />
                              </Col>
                            </Row>
                          </BorderedBox>
                        )}
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.global.form.section.customers.title')} icon='user-edit' />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isCustomersLocalUpdateEnabled'
                          label={t('globalSettings.form.section.customers.changes.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.global.form.section.customerFields.title')} icon='database' />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isCustomerFieldsLocalUpdateEnabled'
                          label={t('globalSettings.form.section.customers.changes.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.global.form.section.tags.title')} icon='tag' ignoreTopOffset />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isCompanyTagsLocalUpdateEnabled'
                          label={t('globalSettings.form.section.tags.changes.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.global.form.section.categories.title')} icon='folder-tree' ignoreTopOffset />
                    <Row>
                      <Col>
                        <Checkbox
                          name='isResourceLocalUpdateEnabled'
                          label={t('globalSettings.form.section.categories.changes.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  {/* <FormSection>
                    <Title isCompact label={t('settings.global.form.section.permissionType.title')} icon='pencil' ignoreTopOffset />
                    <Checkbox
                      name='isPermissionsGroupsLocalUpdateEnabled'
                      label={t('settings.global.form.section.permissionType.changes.enable')}
                      theme='switch'
                    />
                  </FormSection> */}
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
            <FormSection>
              <Error noOffset name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default SettingsForm
