import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps, dangerousHTML } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './Radio.css'

class Radio extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onClick = this.onClick.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onClick () {
    let { disabled, onChangeAddon, name, form, value } = this.props
    form = form || { value: '' }
    if (disabled || form.disabled) return null
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    let {
      label,
      name,
      value,
      disabled,
      hideError,
      inline,
      children,
      className,
      form
    } = this.props
    form = form || { value: '' }
    const classNames = ['ta-radio']
    if (value === form.value) classNames.push('active')
    if (focused) classNames.push('focused')
    if (disabled !== undefined ? disabled : form.disabled) classNames.push('disabled')
    const wrapperClassNames = ['ta-radio-wrapper']
    if (inline) wrapperClassNames.push('inline')
    if (className) wrapperClassNames.push(className)

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={wrapperClassNames.join(' ')}>
        <FormGroup disabled={disabled !== undefined ? disabled : form.disabled}>
          <input
            className='ta-radio-field'
            ref={ref => { this.ref = ref }}
            type='radio'
            name={name}
            checked={value === form.value}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={() => { }}
            disabled={disabled !== undefined ? disabled : form.disabled}
            autoComplete='off'
          />
          <div className={classNames.join(' ')} onClick={this.onClick}>
            {label !== undefined ? dangerousHTML(`${label}`) : form.label || children}
          </div>
          {!hideError &&
            <Error noOffset name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

Radio.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(Radio), FormContext)
