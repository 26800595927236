import React, { useEffect, useState } from 'react'
import { Toastr } from '../../Beauties'
import { connect, handlers } from '../../Store'

const ToastrWrapper = props => {
  const { isToastrVisible, toastrMessage, toastrTitle } = props
  const [intervalID, setIntervalID] = useState()

  useEffect(() => {
    if (isToastrVisible) {
      setIntervalID(setInterval(() => handlers.appToastrHide(), 5000))
    }
  }, [isToastrVisible])

  const onDismiss = () => {
    clearInterval(intervalID)
    handlers.appToastrHide()
  }

  return (
    <Toastr visible={isToastrVisible} title={toastrTitle} message={toastrMessage} onDismiss={onDismiss} />
  )
}

const maps = state => ({
  isToastrVisible: (state.app && state.app.isToastrVisible) || false,
  toastrMessage: (state.app && state.app.toastrMessage) || '',
  toastrTitle: (state.app && state.app.toastrTitle) || ''
})

export default connect(maps)(ToastrWrapper)
