import React from 'react'
import { t } from '../../../Common'

const PopupPastBookingTooltip = () =>
  (
    <div className='ta-popup__resources-allowance-type-inherit'>
      <div className='ta-popup__text ta-popup__text_past_booking_tooltip'>
        {t('settings.section.pastBooking.preventEditing.disableSomeLabel.title.description')}
      </div>
    </div>
  )

export default PopupPastBookingTooltip
