import React from 'react'
import { handlers } from '../../Store'

import './TableRow.css'

const TableRow = props => {
  const { children, to, color } = props
  const classNames = ['ta-table-row']
  if (color) classNames.push(`color ${color}`)

  const onClick = e => {
    if (!to) return null
    e.preventDefault()
    handlers.navigateToPath(to)
  }

  return (
    <div onClick={onClick} className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default TableRow
