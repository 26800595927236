import { handlers, store } from '../..'

export const customerUpdated = customer => {
  if (!customer) return
  const { customers = {} } = store.getState()
  const { isComplete, ts } = customers.list.find(item => item.id === customer.id) || {}
  if (ts === customer.ts) return
  if (isComplete) return handlers.customerPreviewGet({ id: customer.id, forceFetch: true })
  handlers.customerUpdate({ ...customer })
}

export const customerDeleted = id => {
  if (!id) return
  const { customers = {} } = store.getState()
  const customer = customers.list.find(item => item.id === id) || {}
  if (!customer || customer.isDeleted) return
  handlers.customerDeleted(id)
  setTimeout(() => handlers.customerRemoveDeleted(id), 2000)
}
