import { createSelector } from '../../../Utils'

export const appsSelector = state => state.apps

export const appsListSelector = createSelector(
  appsSelector,
  apps => (apps || {}).list
)

export const appsFieldSelector = createSelector(
  appsSelector,
  (_, props) => props.field,
  (apps, field) => (apps || {})[field]
)

export const appsSelectedSelector = createSelector(
  appsListSelector,
  state => appsFieldSelector(state, { field: 'selected' }),
  (list, selected) => (list || []).find(item => item.id === selected)
)

export const appsFindByIdSelector = createSelector(
  appsListSelector,
  (_, props) => props.id,
  (list, id) => (list || []).find(item => item.id === id)
)

export const appsFindByExternalIdSelector = createSelector(
  appsListSelector,
  (_, props) => props.externalId,
  (list, externalId) => (list || []).find(item => item.externalId === externalId)
)
