import React from 'react'
import { PopupResourcesImportOptions } from '../../../Beauties'

export const PopupResourcesImportOptionsWrapper = props => {
  // to be refactored if used
  let { id, closePopup } = props
  const { companyId } = id || {}

  return <PopupResourcesImportOptions companyId={companyId} closePopup={closePopup} />
}

export default PopupResourcesImportOptionsWrapper
