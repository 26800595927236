import React from 'react'
import { connect } from '../../Store'
import { BranchesGroupFormSettings } from '../../Beauties'

const BranchesPreviewGroupFormSettingsWrapper = props => {
  const {
    hasOnlinePayment,
    hasStripeConnected,
    customisations,
    allowedSet
  } = props

  return (
    <BranchesGroupFormSettings
      hasOnlinePayment={hasOnlinePayment}
      hasStripeConnected={hasStripeConnected}
      customisations={customisations}
      allowedSet={allowedSet}
    />
  )
}

const maps = state => ({
  hasOnlinePayment: (state.forms.branchesGroup.hasOnlinePayment && state.forms.branchesGroup.hasOnlinePayment.value) || false
})

export default connect(maps)(BranchesPreviewGroupFormSettingsWrapper)
