import React, { useEffect } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { AppsPrivateApps } from '../../Beauties'
import { isRouteDisabledByCustomisations } from '../../Common'

const AppsPrivateAppsWrapper = props => {
  const {
    privateAccessKey,
    messageAccessKey,
    hash
  } = props

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) handlers.navigateToPath('/')
  }, [hash])

  const onClickCopy = () => {
    handlers.appsPrivateAccessKeyReset()
  }
  const generatePrivateAccessKey = () => {
    handlers.appsPrivateAccessKeyGet()
  }
  return (
    <AppsPrivateApps
      privateAccessKey={privateAccessKey}
      generatePrivateAccessKey={generatePrivateAccessKey}
      onClickCopy={onClickCopy}
      messageAccessKey={messageAccessKey}
    />
  )
}

const maps = state => ({
  privateAccessKey: selectors.formFieldPropertySelector(state, { formName: 'privateApp', name: 'privateAccessKey', property: 'value' }),
  messageAccessKey: selectors.appsFieldSelector(state, { field: 'messageAccessKey' }),
  hash: selectors.routerFieldSelector(state, { field: 'hash' })
})

export default connect(maps)(AppsPrivateAppsWrapper)
