import React from 'react'
import { BranchesPreviewGroupFormRemindersWrapper } from '../../../../Beasts'

import './BranchesGroupForm.css'

const BranchesGroupFormAdvanced = props => {
  const { plan, allowedSet } = props

  return (
    plan === 'ENTERPRISE' && <BranchesPreviewGroupFormRemindersWrapper allowedSet={allowedSet} plan={plan} />
  )
}

export default BranchesGroupFormAdvanced
