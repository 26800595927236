import React, { memo } from 'react'
import { connect } from '../../Store'
import { ServiceFormAllowancePlan } from '../../Beauties'

const ServiceFormAllowancePlanWrapper = props => {
  const { services } = props
  let { hasSpecificBookingDays } = services || {}
  hasSpecificBookingDays = hasSpecificBookingDays || {}
  hasSpecificBookingDays = hasSpecificBookingDays.value || false
  const hasAllowancePlan = hasSpecificBookingDays && Object.keys(services).filter(
    key => key.indexOf('bookingWeeklyAllowancePlan') > -1
  ).length > 0

  return (
    <ServiceFormAllowancePlan
      hasSpecificBookingDays={hasSpecificBookingDays}
      hasAllowancePlan={hasAllowancePlan}
    />
  )
}

const maps = state => ({
  services: state.forms.services
})

export default memo(connect(maps)(ServiceFormAllowancePlanWrapper))
