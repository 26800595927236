import React from 'react'

const TableRowMainColTitle = props => {
  const { children, className } = props
  const classNames = ['ta-table-row__main-col__title']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default TableRowMainColTitle
