import React from 'react'
import { connect } from '../../Store'
import { Tags } from '../../Beauties'

const TagsWrapper = props => {
  let { account, pending } = props
  account = account || {}
  pending = !!pending
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { tagsRead, tagsWrite } = enterprisePermissions || {}
  tagsRead = !!isAdmin || !!tagsRead
  tagsWrite = !!isAdmin || !!tagsWrite

  return <Tags tagsRead={tagsRead} tagsWrite={tagsWrite} pending={pending} />
}

const maps = state => ({
  account: state.account,
  pending: state.tags.pendingList
})

export default connect(maps)(TagsWrapper)
