import React from 'react'
import PropTypes from 'prop-types'

import './CustomerPreviewDetailsTagItem.css'

const CustomerPreviewDetailsTagItem = props => {
  const { name, color } = props
  const classNames = ['ta-customers__details-preview__tag-item']
  const style = { backgroundColor: color }

  return <div style={style} className={classNames.join(' ')}>{name}</div>
}

CustomerPreviewDetailsTagItem.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
}

export default CustomerPreviewDetailsTagItem
