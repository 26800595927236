import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesome5, Avatar } from '../../Common'

const SelectTag = (props) => {
  let {
    item,
    index,
    disabled,
    onDelete,
    renderSelectedItem
  } = props
  const { label, avatarName, avatarImage, avatarColor, value, tagColor, abbreviation } = item || {}
  const hasAvatar = avatarName || avatarImage || avatarColor

  const style = {}
  if (tagColor) style.backgroundColor = tagColor

  const classNames = ['ta-multi-select__values-item']
  if (tagColor) classNames.push('hasPattern')

  if (!item) return null
  if (item && renderSelectedItem) return renderSelectedItem(item, index, props)
  return (
    <div style={style} className={classNames.join(' ')}>
      {hasAvatar && (
        <div className='ta-multi-select__values-item-avatar'>
          <Avatar
            name={avatarName}
            image={avatarImage}
            color={avatarColor}
            abbreviation={abbreviation}
          />
        </div>
      )}
      <div className='ta-multi-select__values-item-label'>
        {label}
      </div>
      {!disabled && (
        <div
          className='ta-multi-select__values-item-delete'
          onClick={(e) => {
            e.stopPropagation()
            !disabled && onDelete(value)
          }}
        >
          <FontAwesome5 icon='times' />
        </div>
      )}
    </div>
  )
}

SelectTag.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  renderSelectedItem: PropTypes.func
}

export default SelectTag
