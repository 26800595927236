import React, { useEffect } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { Apps } from '../../../Beauties'
import { isRouteDisabledByCustomisations, t } from '../../../Common'

const AppsListWrapper = props => {
  let {
    account,
    filters,
    apps,
    pending,
    hash,
    privateAccessKey,
    message,
    messageAccessKey
  } = props
  apps = apps || []
  account = account || {}
  pending = !!pending
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  const appsRead = !!isAdmin || !!enterprisePermissions.appsRead
  const { search } = filters || {}
  let { value: searchValue } = search || {}
  searchValue = searchValue || ''

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    appsRead
      ? handlers.appsListGet()
      : handlers.appsListPopulate({})

    return () => {
      // Clear the appsFilters from state
      handlers.formSet('appsFilters', {})
    }
  }, [appsRead])

  const isAppIncludedInSearch = app => (
    app.id === searchValue ||
      (app.name && app.name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (app.plan && app.plan.toLowerCase().includes(searchValue.toLowerCase())) ||
      t(`apps.list.categories.${app.categoryName}`).toLowerCase().includes(searchValue.toLowerCase())
  )

  const installedApps = apps.filter(item => item.installedVersion)
  const listedApps = apps.filter(item => !item.installedVersion && item.isPublic)
  const privateApps = apps.filter(item => !item.installedVersion && !item.isPublic)
  const hasNoResultInstalledApps = searchValue && installedApps.length > 0 && !installedApps.find(isAppIncludedInSearch)
  const hasNoResultListedApps = searchValue && listedApps.length > 0 && !listedApps.find(isAppIncludedInSearch)
  const hasNoResultPrivateApps = searchValue && privateApps.length > 0 && !privateApps.find(isAppIncludedInSearch)
  const categorizedInstalledApps = Object.values(
    installedApps
      .filter(isAppIncludedInSearch)
      .reduce((acc, app) => {
        acc[app.categoryName] = {
          name: app.categoryName,
          items: ((acc[app.categoryName] && acc[app.categoryName].items) || []).concat(app)
        }
        return acc
      }, {})
  )
  const categorizedListedApps = Object.values(
    listedApps
      .filter(isAppIncludedInSearch)
      .reduce((acc, app) => {
        acc[app.categoryName] = {
          name: app.categoryName,
          items: ((acc[app.categoryName] && acc[app.categoryName].items) || []).concat(app)
        }
        return acc
      }, {})
  )
  const categorizedPrivateApps = Object.values(
    privateApps
      .filter(isAppIncludedInSearch)
      .reduce((acc, app) => {
        acc[app.categoryName] = {
          name: app.categoryName,
          items: ((acc[app.categoryName] && acc[app.categoryName].items) || []).concat(app)
        }
        return acc
      }, {})
  )

  return (
    <Apps
      installedApps={categorizedInstalledApps}
      listedApps={categorizedListedApps}
      privateApps={categorizedPrivateApps}
      hasNoResultInstalledApps={hasNoResultInstalledApps}
      hasNoResultListedApps={hasNoResultListedApps}
      hasNoResultPrivateApps={hasNoResultPrivateApps}
      hash={hash}
      pending={pending}
      appsRead={appsRead}
      privateAccessKey={privateAccessKey}
      message={message}
      messageAccessKey={messageAccessKey}
    />
  )
}

const maps = state => ({
  apps: selectors.appsListSelector(state),
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  pending: selectors.appsFieldSelector(state, { field: 'pendingList' }),
  account: selectors.accountSelector(state),
  privateAccessKey: selectors.formFieldPropertySelector(state, { formName: 'privateApp', name: 'privateAccessKey', property: 'value' }),
  message: selectors.appsFieldSelector(state, { field: 'messageList' }),
  messageAccessKey: selectors.appsFieldSelector(state, { field: 'messageAccessKey' }),
  filters: selectors.formSelector(state, { formName: 'appsFilters' })
})

export default connect(maps)(AppsListWrapper)
