export default (options) => {
  let {
    type,
    punctuation,
    separator
  } = options || {}
  separator = separator || ':'
  if (!options || !type) return null

  if (type === '12') {
    const format = 'h:mma'
    if (punctuation === 'dotWithSpacing') return format.replace('a', ' aa')
    if (punctuation === 'capital') return format.replace('a', ' A')
    if (punctuation === 'withSpacing') return format.replace('a', ' a')
    return format
  }

  return `HH${separator}mm`
}
