import React from 'react'
import { connect } from '../../Store'
import { ServiceCombinationFormDuration } from '../../Beauties'

const ServiceCombinationFormDurationWrapper = props => {
  const {
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    customisations
  } = props

  return (
    <ServiceCombinationFormDuration
      showDurationBeforeAndAfter={!!showDurationBeforeAndAfter}
      showDurationDaySelector={!!showDurationDaySelector}
      customisations={customisations}
    />
  )
}

const maps = state => ({
  showDurationBeforeAndAfter: state.forms.services.showDurationBeforeAndAfter && state.forms.services.showDurationBeforeAndAfter.value,
  showDurationDaySelector: (state.account.companySettings && state.account.companySettings.showDurationDaySelector) || false
})

export default connect(maps)(ServiceCombinationFormDurationWrapper)
