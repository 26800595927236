import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './PriceInput.css'

class PriceInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const value = this.ref.value || ''
    const { name, form, formName, onChange, onChangeAddon } = this.props
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    const state = store.getState()
    const { staticData, account } = state || {}
    let { countries } = staticData || {}
    countries = countries || []
    const { enterprise } = account || {}
    const { locale: enterpriseLocale } = enterprise || {}
    const defaultLocale = enterpriseLocale || 'en-gb'
    const countryCode = defaultLocale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { currency: countryCurrency } = country || {}
    const { code: currencyCode } = countryCurrency || {}
    const defaultCurrency = currencyCode || ''

    let {
      label,
      placeholder,
      hintText,
      currency,
      name,
      value,
      mandatory,
      disabled,
      hideError,
      className,
      limit,
      form
    } = this.props
    currency = currency || defaultCurrency
    limit = limit || 32
    form = form || {}
    const hasError = form.errors && form.errors.length > 0

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
          hasError={hasError}
        >
          <input
            className='ta-form-control'
            ref={ref => { this.ref = ref }}
            type='text'
            name={name}
            value={form.value}
            placeholder={placeholder || form.placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            maxLength={limit}
            disabled={disabled || form.disabled}
            autoComplete='off'
          />
          {(currency &&
            <div className='ta-form-control__addon is-text'>
              {currency.toUpperCase()}
            </div>
          )}
          {(hintText &&
            <div className='ta-form-control__hint'>
              {hintText && hintText}
            </div>
          )}
          {(!hideError &&
            <Error noOffset name={name} />
          )}
        </FormGroup>
      </div>
    )
  }
}

PriceInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  limit: PropTypes.number,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(PriceInput), FormContext)
