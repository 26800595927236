export default (index, type = 'long') => {
  switch (index) {
    case 1:
      return 'mo'
    case 2:
      return 'tu'
    case 3:
      return 'we'
    case 4:
      return 'th'
    case 5:
      return 'fr'
    case 6:
      return 'sa'
    default:
      return 'su'
  }
}
