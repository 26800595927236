import React from 'react'
import {
  DnDDraggable,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  FontAwesome5,
  ListButton,
  t
} from '../../Common'

import { getNameByColor, truncateText, dangerousHTML } from '../../Utils'
import './CompanyTagsStatuses.css'

const CompanyTagsListItem = props => {
  const {
    tag,
    index,
    isDragAndDropMode
  } = props
  const classNames = ['ta-company-tags__tag']
  if (isDragAndDropMode) classNames.push('is-draggable')
  if (tag.isDeleted) classNames.push('deleted')
  if (tag.isUpdated) classNames.push('updated')
  const { locallyUpdatedBranches } = tag || {}
  const globalLabelClassNames = ['global-label', 'edited']
  const locallyChangedBranches = (locallyUpdatedBranches && Object.keys(locallyUpdatedBranches).length) || false
  const isFirstInList = index === 0
  const globalLabelPosition = isFirstInList
    ? (tag.name || '').length > 16
        ? 'bottom'
        : 'right'
    : (tag.name || '').length > 2
        ? 'top'
        : 'right'

  return (
    <div className={classNames.join(' ')}>
      <DnDDraggable
        className='ta-company-tags__tag__dnd-wrapper'
        type='tag'
        id={tag.id}
        index={index}
        isDragDisabled={!isDragAndDropMode}
      >
        <SimpleListItem
          to={!isDragAndDropMode && `/customers/company-tags/${tag.id}`}
          status={getNameByColor(tag.color)}
          draggable={isDragAndDropMode}
        >
          <SimpleListItemTitle title={truncateText(tag.name, 37, true)}>
            {(locallyChangedBranches &&
              <>
                <HoverPopup>
                  <HoverPopupContent position={globalLabelPosition}>
                    {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
                  </HoverPopupContent>
                  <div className={globalLabelClassNames.join(' ')}>
                    <HoverPopupTrigger>
                      <FontAwesome5 icon='pencil' type='s' />
                      {t('global.changedLocally')}
                    </HoverPopupTrigger>
                  </div>
                </HoverPopup>
              </>
            )}
          </SimpleListItemTitle>
          <SimpleListItemContent>
            <ul className='ta-list-inline'>
              {(tag.isForEvents > 0 &&
                <li><FontAwesome5 icon='calendar' type='solid' /> {t('customerTags.used.text', [{ key: 'AMOUNT', value: tag.eventsCount || '0' }])}</li>
              )}
              {(tag.isForCustomers > 0 &&
                <li><FontAwesome5 icon='user-tag' type='solid' /> {t('customerTags.used.text', [{ key: 'AMOUNT', value: tag.customersCount || '0' }])}</li>
              )}
            </ul>
          </SimpleListItemContent>
          {(isDragAndDropMode &&
            <SimpleListItemButtons>
              <ListButton icon='arrows-v' />
            </SimpleListItemButtons>
          )}
        </SimpleListItem>
      </DnDDraggable>
    </div>
  )
}

export default CompanyTagsListItem
