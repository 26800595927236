import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '../../Common'
import { connect, handlers, globalActions } from '../../Store'
import { PermissionTypes } from '../../Beauties'

const MOCKED_PERMISSION_TYPES = [
  {
    id: 'ADMIN1',
    name: 'Admin',
    isGlobal: true,
    icon: 'user-shield',
    color: '#cb3b43',
    permissions: {
      accountRead: true,
      calendarReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      calendarWrite: true,
      calendarDelete: true,
      statisticsRead: true,
      notificationsRead: true,
      permissionsGroupsRead: true,
      customersReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      customersWrite: true,
      customersDelete: true,
      appsRead: true,
      resourcesReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      shiftRead: true,
      shiftWrite: true,
      shiftDelete: true
    }
  },
  {
    id: 'Employees',
    name: 'Employees',
    isGlobal: true,
    icon: 'user-shield',
    color: '#cb3b43',
    permissions: {
      accountRead: true,
      calendarReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      calendarWrite: true,
      calendarDelete: true,
      statisticsRead: true,
      notificationsRead: true,
      permissionsGroupsRead: true,
      customersReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      customersWrite: true,
      customersDelete: true,
      appsRead: true,
      resourcesReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      shiftRead: true,
      shiftWrite: true,
      shiftDelete: true
    }
  },
  {
    id: 'CRM managers',
    name: 'CRM managers',
    isGlobal: true,
    icon: 'user-shield',
    color: '#cb3b43',
    permissions: {
      accountRead: true,
      calendarReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      calendarWrite: true,
      calendarDelete: true,
      statisticsRead: true,
      notificationsRead: true,
      permissionsGroupsRead: true,
      customersReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      customersWrite: true,
      customersDelete: true,
      appsRead: true,
      resourcesReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      shiftRead: true,
      shiftWrite: true,
      shiftDelete: true
    }
  },
  {
    id: 'Software Developers',
    name: 'Software Developers',
    isGlobal: true,
    icon: 'user-shield',
    color: '#cb3b43',
    permissions: {
      accountRead: true,
      calendarReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      calendarWrite: true,
      calendarDelete: true,
      statisticsRead: true,
      notificationsRead: true,
      permissionsGroupsRead: true,
      customersReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      customersWrite: true,
      customersDelete: true,
      appsRead: true,
      resourcesReadSet: {
        specificResourceCategoryIds: [],
        specificResourceIds: []
      },
      shiftRead: true,
      shiftWrite: true,
      shiftDelete: true
    }
  }
]

class PermissionTypesWrapper extends PureComponent {
  componentDidMount () {
    let { plan, account } = this.props
    plan = plan || 'CLASSIC'
    let { enterprisePermissions, isAdmin } = account || {}
    isAdmin = !!isAdmin
    enterprisePermissions = enterprisePermissions || {}
    let { globalPermissionsGroupsRead } = enterprisePermissions || {}
    globalPermissionsGroupsRead = !!isAdmin || !!globalPermissionsGroupsRead
    if (plan === 'ENTERPRISE' && globalPermissionsGroupsRead) {
      handlers.permissionTypesListGet()
      globalActions.populateEnterpriseResourceCategories()
    } else {
      handlers.permissionTypesListPopulate()
    }
  }

  render () {
    let {
      pending,
      list,
      plan,
      account,
      hash,
      branchFilter
    } = this.props
    pending = !!pending
    list = list || []
    plan = plan || 'CLASSIC'
    const filteredList = branchFilter === 'all'
      ? list
      : list.filter(item => item.id === 'ADMIN' || (item.allowedBranchIds || []).includes(branchFilter))
    let { enterprisePermissions, isAdmin, isOwner } = account || {}
    isAdmin = !!isAdmin
    isOwner = !!isOwner
    enterprisePermissions = enterprisePermissions || {}
    let { globalPermissionsGroupsRead, globalPermissionsGroupsWrite, globalPermissionsGroupsDelete } = enterprisePermissions || {}
    globalPermissionsGroupsRead = !!isAdmin || !!globalPermissionsGroupsRead
    globalPermissionsGroupsWrite = !!isAdmin || !!globalPermissionsGroupsWrite
    globalPermissionsGroupsDelete = !!isAdmin || !!globalPermissionsGroupsDelete

    return (
      <>
        <Loader active={pending && plan === 'ENTERPRISE'} />
        {((!pending || (pending && plan !== 'ENTERPRISE')) &&
          <PermissionTypes
            list={globalPermissionsGroupsRead ? filteredList : MOCKED_PERMISSION_TYPES}
            plan={plan}
            isOwner={isOwner}
            hash={hash}
            permissionTypesCount={list.length}
            globalPermissionsGroupsRead={globalPermissionsGroupsRead}
            globalPermissionsGroupsWrite={globalPermissionsGroupsWrite}
            globalPermissionsGroupsDelete={globalPermissionsGroupsDelete}
          />
        )}
      </>
    )
  }
}

PermissionTypesWrapper.propTypes = {
  plan: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  list: PropTypes.array
}

const maps = (state) => ({
  plan: (state.company && state.company.plan) || 'ENTERPRISE',
  pending: state.permissionTypes.pendingList,
  list: state.permissionTypes.list,
  hash: state.router.hash,
  branchFilter: (state.forms.permissionTypesFilter && state.forms.permissionTypesFilter.branchName && state.forms.permissionTypesFilter.branchName.value) || null,
  account: state.account
})

export default connect(maps)(PermissionTypesWrapper)
