import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { feedContextInProps, truncateText } from '../../../Utils'
import {
  FormContext,
  FormGroup,
  Error,
  FontAwesome5,
  Select,
  DnDWrapper,
  DnDDroppable,
  DnDDraggable,
  t
} from '../../../Common'

import './ServiceCombinationSelect.css'

class ServiceCombinationSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (values) {
    const { form, formName, name, onChangeAddon } = this.props
    const newValues = values.map(value => value.value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues } })
    this.setState({ filled: values.length > 0 })
    onChangeAddon && onChangeAddon(newValues, name)
  }

  onDelete (value) {
    const { form, formName, name } = this.props
    const newValues = form.values.filter(v => v !== value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues } })
    this.setState({ filled: newValues.length > 0 })
  }

  onDragStart = res => { }

  onDragUpdate = res => { }

  onDragEnd = ({ type, id, oldIndex, newIndex }) => {
    const { form, formName, name } = this.props
    const oldValues = [...form.values]
    const item = oldValues.find((item, index) => index === oldIndex)
    const newValues = form.values
    newValues.splice(oldIndex, 1)
    newValues.splice(newIndex, 0, item)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues } })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  render () {
    const { focused } = this.state
    const {
      label,
      name,
      mandatory,
      disabled,
      form = {},
      services
    } = this.props
    let { values } = form || {}
    values = values || []
    const hasError = form.errors && form.errors.length > 0

    return (
      <div className='ta-service-combination-select'>
        <FormGroup
          focused={focused}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
          hasError={hasError}
        >
          <Select
            className='ta-service-combination-select__select'
            noResultsText={t('global.noResults')}
            name={name}
            value={form.values}
            arrowRenderer={this.renderArrows}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onDelete={this.onDelete}
            multi
            searchable
            options={form.options || []}
            disabled={values.length === 5 || disabled}
            autoComplete='off'
            position='bottom'
            hideSelected
            hasCategories
            hasError={hasError}
            noLabel={!label && !form.label}
          />
          <DnDWrapper
            onDragUpdate={this.onDragUpdate}
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
          >
            <DnDDroppable id='serviceCombination' type='serviceCombination'>
              {values.map((item, index) => {
                const service = services.find(s => s.id === item)
                const { name, duration } = service || {}
                const isDeleted = !name || !duration
                const classNames = ['ta-service-combination-select__selected-item']
                if (isDeleted) classNames.push('is-deleted')

                return (
                  <DnDDraggable
                    isDragDisabled={disabled}
                    className='ta-service-combination-select__selected-item__dnd-wrapper'
                    type='serviceCombinationItem'
                    id={index.toString()}
                    key={index}
                    index={index}
                    isDragDisabled={disabled}
                  >
                    <div className={classNames.join(' ')}>
                      <div className='ta-service-combination-select__selected-item__index'>0{index + 1}.</div>
                      <div className='ta-service-combination-select__selected-item__body'>
                        {isDeleted &&
                          <>
                            ID: {item} - {t('servicesGroups.form.section.serviceSelection.serviceDeleted')}
                          </>}
                        {!isDeleted &&
                          <>
                            {truncateText(name, 50, true)} <span>{duration || 0} {t('global.minutes.short')}</span>
                          </>}
                      </div>
                      <div
                        className='ta-service-combination-select__selected-item__delete-btn'
                        onClick={() => !disabled && this.onDelete(item)}
                      >
                        <FontAwesome5 icon='times' />
                      </div>
                    </div>
                  </DnDDraggable>
                )
              })}
            </DnDDroppable>
          </DnDWrapper>
          <Error noOffset name={name} />
        </FormGroup>
      </div>
    )
  }
}

const maps = (state, props) => {
  const branches = state.branches.list || []
  const branchId = state.router.data.branchId
  const branch = branches.find(item => item.id === branchId) || {}
  const { services: branchesServices } = branch || {}
  let { services: branchServices } = branchesServices || {}
  branchServices = branchServices || []
  return {
    form: state.forms[props.formName][props.name] || { values: [], options: [] },
    services: branchId ? branchServices : state.services.list || []
  }
}

export default feedContextInProps(connect(maps)(ServiceCombinationSelect), FormContext)
