import moment from 'moment-timezone'

export default (date, format, options = {}) => {
  if (!format) return 'errors.missingFormat'
  if (!date) return 'errors.missingDate'
  if (options.timezone) date = moment.utc(date, options.format || 'YYYY-MM-DD HH:mm').tz(options.timezone)
  else date = moment.utc(date, options.format || 'YYYY-MM-DD HH:mm')
  const shortDate = getShortDate(date)

  // DD/MM
  if (format === 'short') return shortDate

  // DD/MM HH:mm
  else if (format === 'shortWithTime') return `${shortDate} ${date.format('LT')}`

  // dd, DD/MM
  else if (format === 'shortWithShortWeekDay') return `${date.format('dd')}, ${shortDate}`

  // dddd, DD/MM
  else if (format === 'shortWithLongWeekDay') return `${date.format('dddd')}, ${shortDate}`

  // DD/MM/YYYY
  else if (format === 'long') return date.format('L')

  // DD/MM/YYYY HH:mm
  else if (format === 'longWithTime') return date.format('L LT')

  // dd, DD/MM/YYYY
  else if (format === 'longWithShortWeekDay') return date.format('dd, L')

  // dddd, DD/MM/YYYY
  else if (format === 'longWithLongWeekDay') return date.format('dddd, L')

  // HH:mm
  else if (format === 'time') return date.format('LT')

  // h:mm a with dots
  else if (format.includes('aa')) return date.format(format.replace('aa', 'a')).replace('am', 'a.m.').replace('pm', 'p.m.')

  // ddd. or dd. (never dd..)
  else if (format.includes('ddd')) return date.format(format).replace('..', '.')

  // Custom format
  else return date.format(format)
}

const getShortDate = date => {
  const year = date.format('YYYY')
  let short = date.format('L').replace(year, '')
  if (isNaN(parseInt(short[0], 10))) {
    short = short.slice(1)
  }
  if (isNaN(parseInt(short[short.length - 1], 10))) {
    short = short.slice(0, -1)
  }
  return short
}
