import React from 'react'
import moment from 'moment'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  ListButton,
  Image,
  FontAwesome5,
  PlanBadge,
  t
} from '../../../Common'
import { AppsListActionsPopupWrapper } from '../../../Beasts'
import { highlight, dangerousHTML } from '../../../Utils'

import './AppsList.css'

// const mock = {
//   withPayment: true,
//   onTrial: false,
//   trialLeft: '4 days',
//   activeSubscription: false,
//   trialEnded: false,
//   creditsLeft: 8,
//   paymentFailed: false
// }

const AppsListItem = props => {
  let {
    app,
    activeTab,
    search,
    popupShown,
    setPopupShown,
    noAutoCollect
  } = props
  app = app || {}
  const { installedVersion, appInstallation } = app || {}
  // const {
  // withPayment,
  // onTrial,
  // trialLeft,
  // activeSubscription,
  // trialEnded,
  // creditsLeft,
  // paymentFailed
  // } = mock
  const {
    isTrial,
    trialEndAt,
    hasTrial,
    subscribedAt,
    unsubscribedAt,
    trialUnits,
    remainingUnits,
    hasUsage,
    blockedReasonCode,
    renewedAt,
    isBlocked,
    hasDuePayment,
    limitReached,
    hasPayment: hasPaymentInstallation,
    blockingAt
  } = appInstallation || {}
  const paymentFailed = hasDuePayment || ['MAX_UNITS'].includes(blockedReasonCode)
  const hasPayment = app.hasPayment || hasPaymentInstallation
  const today = moment()
  const trialEndDay = moment(trialEndAt, 'YYYY-MM-DD')
  const trialDaysLeft = trialEndDay.diff(today, 'days')

  const handleOnClick = e => {
    e.stopPropagation()
    setPopupShown(app.id)
  }

  return (
    <SimpleListItem to={`/apps/${app.id}@@${activeTab}`} className='ta-apps__list__app'>
      <SimpleListItemTitle htmlTitle={app.name} title={highlight(app.name, search)}>
        <PlanBadge plan={app.isPublic ? app.plan || 'CLASSIC' : 'PRIVATE'} search={search} />
      </SimpleListItemTitle>
      <SimpleListItemContent>
        <div className='ta-apps__list__app-developer'>{t('global.by')} {app.developerCompanyName}</div>
        <div className='ta-apps__list__app-data'>
          {(hasPayment &&
            <div className='ta-apps__list__app-data__item green'>
              <FontAwesome5 icon='money-bill' type='s' />
              {t('apps.list.withPayment.label')}
            </div>
          )}
          {(installedVersion && !hasUsage && isTrial &&
            <div className='ta-apps__list__app-data__item'>
              <FontAwesome5 icon='clock' type='s' />
              {dangerousHTML(t('apps.list.onTrial.label', [{ key: 'DAYS', value: `${trialDaysLeft}` }]))}
            </div>
          )}
          {/* {installedVersion && subscribedAt && !unsubscribedAt && (
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='file-contract' type='s' />
              {t('apps.list.activeSubscription.label')}
            </div>
          )} */}
          {(installedVersion && subscribedAt && !unsubscribedAt && (!isBlocked && !limitReached) &&
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='file-contract' type='s' />
              {t('apps.list.activeSubscription.label')}
            </div>
          )}
          {(installedVersion && unsubscribedAt &&
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='file-contract' type='s' />
              {t('apps.list.cancelledSubscription.label')}
            </div>
          )}
          {(installedVersion && !hasUsage && hasTrial && trialDaysLeft < 0 &&
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='clock' type='s' />
              {t('apps.list.trialEnded.label')}
            </div>
          )}
          {(installedVersion && !subscribedAt && ((!renewedAt && isBlocked && blockedReasonCode === 'SUBSCRIBE' && trialUnits !== 0) || remainingUnits === 0) &&
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='coins' type='s' />
              {t('apps.list.noFreeCredits.label')}
            </div>
          )}
          {/* {installedVersion && !subscribedAt && !renewedAt && limitReached && trialUnits !== 0 && (
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='coins' type='s' />
              {t('apps.list.limitReached.label')}
            </div>
          )} */}
          {(installedVersion && limitReached &&
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='coins' type='s' />
              {t('apps.list.limitReached.label')}
            </div>
          )}
          {(installedVersion && isTrial && !!remainingUnits &&
            <div className='ta-apps__list__app-data__item bold'>
              <FontAwesome5 icon='coins' type='s' />
              {t('apps.list.creditsLeft.label', [{ key: 'AMOUNT', value: remainingUnits }])}
            </div>
          )}
          <div className='ta-apps__list__app-data__item italic'>
            v
            {(app.installedVersion &&
              <span>
                {app.version}
                {app.version !== app.installedVersion && (
                  <span className='ta-apps__list__app-version-update-available'>{t('global.new')}</span>
                )}
              </span>
            )}
            {!app.installedVersion && app.version}
          </div>
        </div>
        {(app.installedVersion && paymentFailed && !noAutoCollect &&
          <div className='ta-apps__list__app-payment-failed'>
            <FontAwesome5 icon='exclamation-triangle' type='s' />
            {hasDuePayment && t('apps.list.paymentFailed.label')}
            {['MAX_UNITS'].includes(blockedReasonCode) && t('apps.list.paymentFailed.maxUnits.label')}
          </div>
        )}
        {(app.installedVersion && paymentFailed && noAutoCollect && isBlocked && blockingAt &&
          <div className='ta-apps__list__app-payment-failed'>
            <FontAwesome5 icon='exclamation-triangle' type='s' />
            {t('apps.list.paymentFailed.offline.label')}
          </div>
        )}
      </SimpleListItemContent>
      <SimpleListItemButtons>
        {(app.installedVersion &&
          <>
            <ListButton icon='cog' external onClick={handleOnClick} className={popupShown && 'active'} />
            <AppsListActionsPopupWrapper active={popupShown} closePopup={() => setPopupShown(null)} id={app.id} />
          </>
        )}
        {(app.installedVersion && app.version !== app.installedVersion &&
          <ListButton hasText>{t('global.update')}</ListButton>
        )}
      </SimpleListItemButtons>
      <Image className='ta-apps__list__app-image' src={app.imageUrl || ''} alt={app.name} />
    </SimpleListItem>
  )
}

export default AppsListItem
