import React from 'react'
import {
  Button,
  Form,
  Title,
  Textarea,
  Row,
  Col,
  Loader,
  FormText,
  t,
  Error
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAppsCancelSubscription = props => {
  const {
    onSubmit,
    onCancel,
    pending,
    name
  } = props

  return (
    <div className='ta-popup__apps-subscription-cancel'>
      <Loader active={pending} />
      <Title
        size='m'
        label={dangerousHTML(t('apps.subscription.cancel'))}
        icon='unlink'
        ignoreTopOffset
      />
      <Form name='popup' onSubmit={onSubmit}>
        <FormText>
          {dangerousHTML(t('apps.subscription.cancel.popup.text', [{ key: 'APP_NAME', value: name }]))}
        </FormText>
        <Row>
          <Col>
            <Textarea
              name='reason'
              label={t('apps.subscription.cancel.popup.reason.label')}
              hintText={t('apps.subscription.cancel.popup.reason.hint')}
              limit={200}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Error name='globalErrors' />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Button as='link' isSecondary onClick={onCancel}>
            {t('global.exit')}
          </Button>
          <Button type='submit' isPrimary>
            {t('apps.subscription.cancel.popup.buttonYes')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default PopupAppsCancelSubscription
