import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Overlay } from '../../Beauties'

const OverlayWrapper = props => {
  let { isActive, isModalActive } = props
  isActive = !!isActive

  const onClick = () => {
    if (isModalActive) handlers.modalHide()
    handlers.overlayHide('top')
  }

  return (
    <Overlay position='top' isActive={isActive} onClick={onClick} />
  )
}

OverlayWrapper.propTypes = {
  redirectOnClose: PropTypes.func,
  defaultRedirect: PropTypes.string,
  hash: PropTypes.string
}

const maps = state => ({
  isActive: state.overlay.isTopActive || false,
  isModalActive: state.modal.isActive || false
})

export default connect(maps)(OverlayWrapper)
