import React from 'react'
import { FontAwesome5 } from '../../Common'

import './IconAvatar.css'

const IconAvatar = props => {
  const { color, className, icon, type = 'solid' } = props
  const styles = { backgroundColor: color }
  const classNames = ['ta-icon-avatar']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} style={styles}>
      <div className='ta-icon-avatar__icon'>
        <FontAwesome5 icon={icon || ''} type={type} />
      </div>
    </div>
  )
}

export default IconAvatar
