import React from 'react'

import './InlineFields.css'

const InlineFields = props => {
  const { children } = props

  return (
    <div className='ta-inline-fields__text'>
      {children}
    </div>
  )
}

export default InlineFields
