import React from 'react'
import {
  Title,
  Accordion,
  AccordionTitle,
  AccordionBody,
  Alert,
  t
} from '../../Common'
import {
  InvoicesListItem
} from '../../Beauties'

import './Invoices.css'

const InvoicesList = props => {
  const {
    invoices,
    pendingDownload,
    onClickInvoiceLink
  } = props
  const currentYear = (new Date()).getFullYear().toString()

  return (
    <div className='ta-invoices__list'>
      {invoices.map(category => (
        <Accordion key={category.year} inactive={currentYear === category.year}>
          <AccordionTitle>
            <Title size='m' label={category.year} icon='bullhorn' />
          </AccordionTitle>
          <AccordionBody>
            {category.items.map(item => (
              <InvoicesListItem
                key={item.id}
                invoice={item}
                pendingDownload={pendingDownload}
                onClickInvoiceLink={onClickInvoiceLink}
              />
            ))}
          </AccordionBody>
        </Accordion>
      ))}
      {(!invoices.length > 0 &&
        <Alert noOffset label={t('invoices.noResultsBox.empty.text')} theme='no-content' />
      )}
    </div>
  )
}

export default InvoicesList
