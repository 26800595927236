import { validator, translateServerCode } from '../../../Utils'

export const billingFormTransform = data => {
  data = data || {}
  const result = {
    name: {
      value: data.name || ''
    },
    address1: {
      value: data.address1 || ''
    },
    address2: {
      value: data.address2 || ''
    },
    zipCode: {
      value: data.zipCode || ''
    },
    city: {
      value: data.city || ''
    },
    vatCountryCode: {
      value: data.vatCountryCode || ''
    },
    vatNo: {
      value: data.vatNo || ''
    }
  }
  return result
}

export const billingFormValidate = data => {
  if (!data) return

  const rules = [
    { 'name.value': ['required', 'max:255'] },
    { 'address1.value': ['required', 'max:150'] },
    { 'address2.value': ['max:150'] },
    { 'city.value': ['required', 'max:32'] },
    { 'zipCode.value': ['required', 'max:20'] },
    { 'vatCountryCode.value': ['max:2'] },
    { 'vatNo.value': ['max:32'] }
  ]

  const messages = {
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    address1: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    address2: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    city: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    zipCode: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    vatCountryCode: {
      max: 'errors.invalidMaxLength'
    },
    vatNo: {
      max: 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    address1: {
      required: {
        key: 'FIELD_NAME',
        value: 'address.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    address2: {
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    city: {
      required: {
        key: 'FIELD_NAME',
        value: 'address.city',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    },
    zipCode: {
      required: {
        key: 'FIELD_NAME',
        value: 'address.zip',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '20'
      }
    },
    vatCountryCode: {
      max: {
        key: 'MAX',
        value: 2
      }
    },
    vatNo: {
      max: {
        key: 'MAX',
        value: '32'
      }
    }
  }

  const errors = validator(data, rules, messages, replaces)

  return errors.length && errors
}

export const billingSaveTransform = data => ({
  name: data.name.value,
  address1: data.address1.value,
  address2: data.address2.value,
  zipCode: data.zipCode.value,
  city: data.city.value,
  vatCountryCode: data.vatCountryCode.value,
  vatNo: data.vatNo.value
})

export const billingServerErrorsTransform = (error) => {
  const errors = []

  if (error.code === 'BillingDataError' && error.data && ['vat_number', 'billing_address[country]'].includes(error.data.field)) {
    errors.push({ key: 'vatNo', value: translateServerCode('InvalidVAT') })
  } else if (error.code === 'BillingDataError' && error.data && ['billing_address[company]'].includes(error.data.field)) {
    errors.push({ key: 'name', value: translateServerCode('InvalidCompanyName') })
  } else if (error.code === 'BillingDataError' && error.data && error.data.field === 'billing_address[zip]') {
    errors.push({ key: 'zipCode', value: translateServerCode('InvalidZipCode') })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}
