import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { CustomerForm } from '../../Beauties'
import { groupCustomerFieldsByCategory } from '../../Utils'

class CustomerEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const { id } = this.props
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }
    handlers.customerFormGet({ id })
  }

  onSubmit (cb) {
    const form = store.getState().forms.customers
    handlers.customerFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    let {
      id,
      pending,
      plan,
      fields,
      categories,
      showExternalIds,
      timezones,
      allowOnlyGlobalCustomers,
      isAdmin,
      branchesAccessBlacklist,
      form
    } = this.props
    form = form || {}
    pending = !!pending
    fields = fields || []
    categories = categories || []
    timezones = timezones || []
    showExternalIds = !!showExternalIds
    allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
    const filteredFields = fields.filter(item => item.hasOverwrite && item.isActive)
    const groupedFields = groupCustomerFieldsByCategory(filteredFields, categories)
    const availableFields = filteredFields
      .filter(item => item.defaultId && item.isActive)
      .map(item => item.defaultId) || []

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <CustomerForm
            plan={plan}
            id={id}
            title={t('customers.form.heading.edit')}
            type='edit'
            groupedFields={groupedFields}
            availableFields={availableFields}
            cancelLink={`/customers/${id}`}
            onSubmit={this.onSubmit}
            showExternalIds={showExternalIds}
            allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
            timezones={timezones}
            isAdmin={isAdmin}
            branchesAccessBlacklist={branchesAccessBlacklist}
            location={form.location && form.location.values}
          />
        )}
      </>
    )
  }
}

CustomerEditWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.id,
  pending: state.customers.pendingForm,
  fields: state.customerFields.list,
  categories: state.customerFields.categoriesList,
  timezones: state.staticData.timezones,
  showExternalIds: state.company.settings.showExternalIds,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers,
  form: state.forms.customers,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default connect(maps)(CustomerEditWrapper)
