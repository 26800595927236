import { handlers } from '../../Store'
import FontAwesome5 from '../FontAwesome/FontAwesome5'
import './Button.css'

const TYPES = {
  isPrimary: 'ta-btn-primary',
  isSecondary: 'ta-btn-secondary',
  isTertiary: 'ta-btn-tertiary',
  isGhost: 'ta-btn-ghost',
  isBlock: 'ta-btn-block',
  isBlank: 'ta-btn-blank',
  rounded: 'ta-btn-rounded',
  disabled: 'ta-btn-disabled'
}

const HTML_LEAK_KEYS = [
  'isPrimary',
  'isSecondary',
  'isTertiary',
  'isGhost',
  'isBlock',
  'isBlank',
  'rounded'
]

const preventHTMLKeyLeak = (data, keys) => {
  const res = { ...data }
  for (const key of HTML_LEAK_KEYS) {
    delete res[key]
  }
  return res
}

const SIZES = {
  s: 'ta-btn-sm',
  m: 'ta-btn-md',
  l: '',
  xl: 'ta-btn-xl'
}

const BORDER_SIZES = {
  s: 'ta-btn-border-sm',
  m: 'ta-btn-border-md',
  l: ''
}

const Button = ({
  to,
  as,
  type,
  size,
  style,
  action,
  rounded,
  onClick,
  children,
  external,
  iconType,
  className,
  reducedBorder,
  testid,
  borderSize,
  ...rest
}) => {
  const { disabled, icon } = rest
  const classNames = [
    'ta-btn',
    ...(className ? [className] : []),
    ...(children && icon ? ['ta-btn-with-icon'] : !children && icon ? ['ta-btn-icon-only'] : []),
    ...(size && SIZES[size] ? [SIZES[size]] : []),
    ...(borderSize && BORDER_SIZES[borderSize] ? [BORDER_SIZES[borderSize]] : []),
    ...(Object.keys(TYPES).reduce((acc, type) => rest[type] ? [...acc, TYPES[type]] : [...acc], []))
  ]

  const handleOnClick = (e) => {
    if (disabled) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (onClick) {
      onClick(e)
    } else if (as === 'link' && !disabled) {
      if (action) action()
      if (external) return null
      e.preventDefault()
      e.stopPropagation()
      if (!to || to.length === 0) return null
      handlers.navigateToPath(to)
    }
  }

  if (as === 'link') {
    return (
      <a
        type={type}
        className={classNames.join(' ')}
        onClick={handleOnClick}
        disabled={disabled}
        href={disabled ? undefined : to}
        data-testid={testid || 'link'}
        style={style}
        {...preventHTMLKeyLeak(rest, HTML_LEAK_KEYS)}
      >
        {icon && <FontAwesome5 icon={icon} type={iconType} />}
        {children}
      </a>
    )
  }

  return (
    <button
      type={type || 'button'}
      data-testid={testid || 'button'}
      onClick={handleOnClick}
      className={classNames.join(' ')}
      disabled={disabled}
      style={style}
      {...preventHTMLKeyLeak(rest, HTML_LEAK_KEYS)}
    >
      {icon && <FontAwesome5 icon={icon} type={iconType} />}
      {children}
    </button>
  )
}

export default Button
