import React, { Component } from 'react'
// import { LoadScript } from '@react-google-maps/api'
import { connect } from '../../Store'
import { ErrorBoundary, Loader } from '../../Common'
import { App, Staging } from '../../Beauties'
// import { GOOGLE_MAPS_API_KEY } from '../../Settings'
import 'moment/min/locales'

// const libraries = ['geometry', 'places', 'drawing']

class AppWrapper extends Component {
  render () {
    const {
      isAppReady,
      isAuthenticated,
      hasTokens,
      route,
      isPending
      // company
    } = this.props
    // const styles = { height: '100%' }
    // const { locale } = company || {}

    return (
      <ErrorBoundary>
        <>
          {(['development', 'staging'].includes(process.env.REACT_APP_ENV) &&
            <Staging />
          )}
          {(isAppReady
            ? (
              <App
                isAuthenticated={isAuthenticated}
                hasTokens={hasTokens}
                route={route}
                isPending={isPending}
              />
              )
          // <LoadScript
          //   googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          //   loadingElement={<div style={styles} />}
          //   libraries={libraries}
          //   language={locale}
          // >

            // </LoadScript>
            : <Loader active hideBackground />
          )}
        </>
      </ErrorBoundary>
    )
  }
}

const maps = state => ({
  isAppReady: state.app.isReady,
  isPending: state.app.isPending,
  isAuthenticated: !!state.account.userId,
  hasTokens: !!state.auth.tokens,
  route: state.router.name,
  company: state.company || {}
})

export default connect(maps)(AppWrapper)
