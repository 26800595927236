const reducer = {}

reducer.initialState = {
  isReady: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  chargebeeReset: () => dispatch(actions.CHARGEBEE_RESET),
  chargebeePopulate: (data) => dispatch(actions.CHARGEBEE_POPULATE, data),
  chargebeePortalOpen: (section) => dispatch(actions.CHARGEBEE_PORTAL_OPEN, section)
})

reducer.COMPANY_RESET = () => reducer.initialState

reducer.CHARGEBEE_POPULATE = (state, data = {}) => ({
  ...state,
  ...data
})

reducer.CHARGEBEE_PORTAL_OPEN = state => state

export default reducer
