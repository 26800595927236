import React from 'react'
import { connect } from '../../Store'
import { OverlayMessage } from '../../Common'

const OverlayMessageWrapper = props => {
  let { isActive } = props
  isActive = !!isActive

  return (
    <OverlayMessage {...props} isActive={isActive} />
  )
}

const maps = state => ({
  isActive: state.overlay.isMessageActive,
  message: state.overlay.message,
  buttonLabel: state.overlay.buttonLabel
})

export default connect(maps)(OverlayMessageWrapper)
