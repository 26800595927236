import React from 'react'
import PropTypes from 'prop-types'

import './StripedTitle.css'

const StripedTitle = props => {
  const { label, extraText, customStyle, className, topOffset } = props
  const classNames = ['ta-striped-title']
  if (className) classNames.push(className)
  if (topOffset) classNames.push('top-offset')

  return (
    <div className={classNames.join(' ')} style={customStyle} data-testid='striped-title'>
      <div className='ta-striped-title__label' data-testid='striped-title-label'>
        <span>{label}</span>
      </div>
      {extraText && (
        <div className='ta-striped-title__extraText' data-testid='striped-title-extra-text'>
          <span>({extraText})</span>
        </div>
      )}
    </div>
  )
}

StripedTitle.propTypes = {
  label: PropTypes.string.isRequired,
  extraText: PropTypes.string
}

export default StripedTitle
