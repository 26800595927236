import { payloads$, actions, store, handlers } from '../../../Store'
import { getCssFileLink, getParamsFromUrlString } from '../../../Utils'
import { t } from '../../../Common'
import { q } from '../../API'
import {
  accountFormValidate,
  accountFormServerErrorsTransform,
  accountFormSaveTransform,
  accountPasswordChangeFormValidate,
  accountSaveUserDataTransform,
  accountPasswordChangeFormServerErrorsTransform,
  accountUserEmailChangeFormValidate,
  accountUserEmailChangeTransform,
  accountUserEmailChangeFormServerErrorsTransform,
  accountEmailChangeCancelFormValidate,
  accountUserEmailChangeCancelTransform,
  accountEmailChangeCancelSaveServerErrorsTransform
} from './utils'
import {
  accountUpdated,
  accountDeleted
} from './subscriptions'

// Account select
payloads$(actions.ACCOUNT_SELECT)
  .subscribe(async ({ accountId }) => {
    const tokens = await q('selectAccount', { accountId })
    const { error } = tokens
    // TODO: handle the error correct
    if (error && error.code === 'Auth2FACodeRequired') {
      handlers.accountPendingSet(false)
      handlers.userSecurityErrorToShow([{ key: 'globalErrors', value: 'You should set up 2FA in order to select that company' }])
      return handlers.navigateToPath('/user@@userSecurity')
    }
    if (error) return error

    // Reset data
    handlers.branchesReset()
    handlers.accountReset()
    handlers.branchesReset()
    handlers.invoicesReset()
    handlers.companyReset()
    handlers.tagsReset()
    handlers.usersReset()
    handlers.appsReset()
    handlers.servicesReset()
    handlers.resourcesReset()
    handlers.coursesReset()
    handlers.customersReset()
    handlers.customerFieldsReset()
    handlers.companyTagsReset()
    handlers.appsPrivateAccessKeyReset()
    handlers.permissionTypesReset()
    handlers.userPermissionTypesReset()
    handlers.overlayHide()

    handlers.authTokensChange(tokens)
    const state = store.getState()
    const { router } = state || {}
    const { query } = router || {}
    const { redirect } = query
    const { account } = tokens || {}
    const { accounts = [] } = account
    if (redirect && redirect.includes('download')) {
      const allowedAccount = accounts.find(account => accountId === account.accountId)
      if (!allowedAccount) {
        handlers.logout(true)
        setTimeout(() => {
          handlers.formErrorsSet('login', [{ key: 'emailOrPassword', value: 'errors.files.accessDeniedForFileDownload' }])
        }, 1000)
      } else {
        const params = getParamsFromUrlString(redirect.split('?')[1]) || {}
        const url = (query || {}).url || params.url
        if (url) handlers.navigateToPath(`/download?url=${url}&accountId=${accountId}`)
      }
      return
    }

    const redirectUrl = redirect || `/?enterprise=${accountId}`
    handlers.navigateToPath(redirectUrl)
  })

// Account changed
payloads$(actions.ACCOUNT_CHANGED)
  .subscribe(async account => {
    const state = store.getState()
    const query = state.router.query
    const { redirect, enterpriseId, accountId } = query
    let {
      authScope,
      enterprise,
      auth2FAs,
      jwtAuth2FAs,
      accounts = []
    } = account || {}
    accounts = accounts || []
    const { chargebee } = enterprise || {}
    const params = []
    if (redirect && !redirect.includes('logout')) { params.push(`redirect=${encodeURIComponent(redirect)}`) }
    if (accountId) params.push(`accountId=${accountId}`)
    const redirectUrl = params.length > 0
      ? `/?${params.join('&')}`
      : '/'
    if (authScope !== 'ENTERPRISE') {
      handlers.logout()
      return
    }
    handlers.accountPopulate(account)
    handlers.systemMessagesListGet()

    // Cleanup enterprise css
    const enterpriseCssOverwriteElement = document.getElementById('ta-enterprise-css-overwrite')
    enterpriseCssOverwriteElement && enterpriseCssOverwriteElement.remove()

    if (redirect && redirect.includes('download')) {
      const allowedAccount = accounts.find(account => accountId === account.accountId)
      if (!allowedAccount) {
        handlers.logout(true)
        setTimeout(() => {
          handlers.formErrorsSet('login', [{ key: 'emailOrPassword', value: 'errors.files.accessDeniedForFileDownload' }])
        }, 1000)
        return
      }
    }

    const isAccountUses2FA = auth2FAs && auth2FAs[0] && auth2FAs[0].isActive
    const isAuth2FA = jwtAuth2FAs && jwtAuth2FAs[0] && jwtAuth2FAs[0].isAuth
    const isActive2FA = isAccountUses2FA && !isAuth2FA
    const isRedirectingToTheSame = !!(enterprise && enterprise.id === accountId)

    // Preselect account
    const selectedAccountId = enterpriseId || accountId
    if (selectedAccountId && !isActive2FA && !isRedirectingToTheSame) {
      const hasAccount = accounts.findIndex(item => item.accountId === selectedAccountId) > -1
      if (hasAccount) return handlers.accountSelect(selectedAccountId)
    } else if (isRedirectingToTheSame) {
      return handlers.accountSelect(selectedAccountId)
    }

    // Has enterprise account
    if (enterprise) {
      handlers.customerFieldsGet()
      handlers.companyPopulate(enterprise)
      const {
        hasOfflinePayments,
        paymentTermDays,
        billingData,
        subscriptions
      } = chargebee || {}
      handlers.chargebeePopulate({
        hasOfflinePayments,
        paymentTermDays
      })
      handlers.billingPopulate({ billingData, subscriptions })
      // Appcues init
      handlers.companyAppcuesInit()
    }

    // Apply customisations
    handlers.accountCustomisationsApply()

    state.router.name === 'login' && handlers.navigateToPath(redirectUrl)
  })

// Form
payloads$(actions.ACCOUNT_FORM_GET)
  .subscribe(async () => {
    const { account = {}, staticData = {} } = store.getState()
    const allBusinessSectors = (staticData.businessSectors || []).map(item => ({ label: item, value: item }))
    handlers.accountFormPopulate({ ...account, allBusinessSectors })
  })

const setAccountFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('account', errors)
  scrollToError && scrollToError(errors)
  handlers.accountFormReady()
}

// Save
payloads$(actions.ACCOUNT_FORM_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const errors = accountFormValidate(account)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)

    const savedAccount = await q('saveEnterpriseProfile', { enterprise: accountFormSaveTransform(account) })
    const { error } = savedAccount
    if (error) return setAccountFormSaveErrors(accountFormServerErrorsTransform(error), scrollToError)

    const baseAccount = store.getState().account
    handlers.accountPopulate(
      {
        ...baseAccount,
        enterprise: {
          ...savedAccount
        }
      }
    )
    handlers.navigateToPath('/account')
  })

// Resend confirmation email
payloads$(actions.ACCOUNT_EMAIL_CONFIRMATION_SEND)
  .subscribe(async ({ accountId, email }) => {
    await q('sendEmailConfirmationCode', { accountId, email })
    // TODO: display some message or something
  })

// Change password save
payloads$(actions.ACCOUNT_FORM_PASSWORD_CHANGE_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const state = store.getState()
    const { account: accountState } = state
    let { enforcePasswordChange } = accountState || {}
    enforcePasswordChange = !!enforcePasswordChange
    const errors = accountPasswordChangeFormValidate(account, enforcePasswordChange)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const savedAccount = await q('saveUserData', { userData: accountSaveUserDataTransform(account) })
    const { error } = savedAccount || { error: {} }
    if (error) return setAccountFormSaveErrors(accountPasswordChangeFormServerErrorsTransform(error), scrollToError)
    if (!enforcePasswordChange) return handlers.navigateToPath('/user')
    handlers.accountFormReady()
    handlers.accountPreviewMessageChange({ text: t('account.password.enfroceReset.success.message'), type: 'success' })
    setTimeout(() => {
      handlers.accountFormPasswordChangeRedirect()
    }, 3000)
  })

payloads$(actions.ACCOUNT_FORM_PASSWORD_CHANGE_REDIRECT)
  .subscribe(() => {
    const state = store.getState()
    const { account: accountState, forms } = state
    const { email } = accountState || {}
    const { account: accountForm } = forms || {}
    const { newPassword } = accountForm || {}
    // if user is using temporary password, log him in
    handlers.loginWithEmailAndPassword({ email: { value: email }, password: { value: newPassword && newPassword.value } })
  })

// Change email save
payloads$(actions.ACCOUNT_FORM_EMAIL_CHANGE_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const errors = accountUserEmailChangeFormValidate(account)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const savedAccount = await q('changeEnterpriseUserEmail', accountUserEmailChangeTransform(account))
    const { error } = savedAccount || { error: {} }
    if (error) return setAccountFormSaveErrors(accountUserEmailChangeFormServerErrorsTransform(error), scrollToError)
    const { email } = account || {}
    const { value: emailValue } = email || {}
    handlers.accountPendingEmailPopulate(emailValue)
    handlers.navigateToPath('/user')
  })

// Unlink resource
payloads$(actions.ACCOUNT_UNLINK_RESOURCE)
  .subscribe(async companyId => {
    if (!companyId) return
    await q('unlinkConnectedAccountToCompanyResource', { companyId })
    handlers.accountDeleted(companyId)
  })

// Unlink account
payloads$(actions.ACCOUNT_UNLINK)
  .subscribe(async id => {
    if (!id) return
    await q('unlinkConnectedAccountToEnterprise', { enterpriseId: id })
    handlers.accountDeleted(id)
  })

// Change email cancel save
payloads$(actions.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const errors = accountEmailChangeCancelFormValidate(account)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const savedAccount = await q('cancelChangeEnterpriseUserEmail', accountUserEmailChangeCancelTransform(account))
    const { error } = savedAccount || { error: {} }
    if (error && error.code !== 'InvalidCancelRequest') return setAccountFormSaveErrors(accountEmailChangeCancelSaveServerErrorsTransform(error), scrollToError)
    handlers.accountFormEmailChangeCancelInvalidRequest()
    handlers.navigateToPath('/user')
    handlers.popupHide('email-change-cancel')
    setTimeout(() => { handlers.popupSet() }, 500)
  })

// Customisations
payloads$(actions.ACCOUNT_CUSTOMISATIONS_APPLY)
  .subscribe(() => {
    const { company } = store.getState()
    const { enterpriseCustomisation } = company || {}
    const { payload: customisaitonPayload } = enterpriseCustomisation || {}
    const { settings } = customisaitonPayload || {}
    const { cssOverwriteUrl } = settings || {}
    const cssUrlLink = getCssFileLink(cssOverwriteUrl)
    cssOverwriteUrl && document.head.appendChild(cssUrlLink)
  })

payloads$(actions.ACCOUNT_TWO_FA_DISABLE)
  .subscribe(async ({ withLogout, code, cb }) => {
    handlers.twoFAPendingFormSet(true)
    const tokens = await q('disable2FA', { type: 'OTP', code })

    handlers.twoFAPendingFormSet(false)
    const { error } = tokens
    if (error && error.code === 'OTP2FATokenVerificationFailed') {
      return handlers.formErrorsSet('twoFA', [{ key: 'code', value: 'errors.twoFA.invalidToken' }])
    }

    if (tokens) {
      handlers.account2FADisabled()
      handlers.userSecurityFormGet()
      if (cb) cb()
      if (withLogout) return handlers.logout()

      const { accessToken, refreshToken, sessionDuration } = tokens
      handlers.authTokensPopulate({
        accessToken,
        refreshToken,
        expires: (new Date()).getTime() + parseInt(sessionDuration, 10)
      })
    }
  })

// Subscription
payloads$(actions.ACCOUNT_SUBSCRIPTION_SET)
  .subscribe(async ({ name, data }) => {
    const { accountId, userId } = data || {}
    if (name === 'accountUpdated') {
      const account = (await q('getAccount')) || {}
      if (account.error) return
      let { accounts } = account || {}
      accounts = accounts || []
      accountUpdated({
        ...account,
        accounts
      })
    }
    if (name === 'accountDeleted') {
      const account = (await q('getAccount')) || {}
      const { enterprise } = account || {}
      if (enterprise) handlers.companyPopulate(enterprise)
      handlers.accountPopulate(account)
      if (accountId) accountDeleted(data)
    }
    if (name === 'invalidate2faSessions') handlers.accountCompanySessionConfirm(accountId)
    if (name === 'invalidate2faSession') handlers.accountSessionConfirm({ userId, data })
  })

// Permissions changed
payloads$(actions.ACCOUNT_PERMISSIONS_CHANGED)
  .subscribe(async () => {
    handlers.overlayMessageSet({
      message: t('user.overlay.permissionsChangeMessage'),
      buttonLabel: t('user.overlay.permissionsChangeMessage.buttonLabel')
    })

    setTimeout(() => {
      handlers.navigateToPath('/')
      window.location.reload(true)
    }, 4000)
  })

payloads$(actions.ACCOUNT_COMPANY_SESSION_CONFIRM)
  .subscribe(({ id }) => {
    const state = store.getState()
    const { account, company } = state || {}
    const { id: companyId } = company || {}
    const { jwtAuth2FAs } = account || {}

    if (id !== companyId) return
    if (jwtAuth2FAs && jwtAuth2FAs.length && jwtAuth2FAs[0] && jwtAuth2FAs[0].isAuth) return

    handlers.logout()
  })

payloads$(actions.ACCOUNT_SESSION_CONFIRM)
  .subscribe(({ id, data }) => {
    const state = store.getState()
    const { account, twoFA, company } = state || {}
    const { jwtAuth2FAs, userId } = account || {}
    const { pending2FASetup } = twoFA || {}
    const { isEnabled } = data || {}
    const { settings } = company || {}
    const { requiredEnterpriseAuth2FAs } = settings || {}
    const isJwtSet = jwtAuth2FAs && jwtAuth2FAs.length && jwtAuth2FAs[0] && jwtAuth2FAs[0].isAuth
    const isCompanyRequires2FA = requiredEnterpriseAuth2FAs === 'OTP'
    const isCurrentUser = !!isJwtSet === isEnabled

    if (isCompanyRequires2FA && isCurrentUser && !isEnabled) return handlers.logout()
    if (!isCurrentUser) return handlers.logout()
    if (id !== userId || pending2FASetup) return
    if (isJwtSet) return

    handlers.logout()
  })
