import React from 'react'
import { FontAwesome5 } from '../../../Common'

import './AppsPreview.css'

const AppsPreviewRoles = props => {
  const { roles } = props

  const getIconByRole = role => {
    // customers
    // bookings
    // courses
    // services
    // employees
    // timeshiftdays
    // companies
    // profiles
    // search
    // notification-service
    if (role === 'CUSTOMERS') return 'user'
    if (role === 'BOOKINGS') return 'calendar'
    if (role === 'COMPANIES') return 'building'
    if (role === 'RESOURCES') return 'users'
    if (role === 'PROFILES') return 'smile-o'
    return 'circle'
  }

  return (
    <div className='ta-apps__preview__roles'>
      <ul>
        {roles.map(role => (
          <li key={role.name}>
            <div className='ta-apps__preview__roles-icon'>
              <FontAwesome5 icon={getIconByRole(role.name)} />
            </div>
            <div className='ta-apps__preview__roles-role'>{role.name.toLowerCase()}</div>
            <div className='ta-apps__preview__roles-types'>({role.types.join('/')})</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AppsPreviewRoles
