import React from 'react'
import { FontAwesome5, Alert } from '../../Common'
import './Toastr.css'

const Toastr = props => {
  const { visible, message, title, onDismiss } = props
  const classNames = ['ta-toastr']
  if (visible) classNames.push('visible')

  return (
    <div className={classNames.join(' ')}>
      {(visible &&
        <Alert theme='error' label={title}>
          {(onDismiss &&
            <div className='ta-message__close-btn' onClick={onDismiss} data-testid='message-close'>
              <FontAwesome5 icon='times' />
            </div>
          )}
          {message}
        </Alert>
      )}
    </div>
  )
}

export default Toastr
