import React from 'react'
import PropTypes from 'prop-types'
import { format, t, FontAwesome5 } from '../../../Common'
import { truncateText, highlight } from '../../../Utils'

import './ActivityLogsItemSystemAction.css'

const ActivityLogsItemSystemAction = props => {
  const { activityLog, search } = props
  const {
    operation,
    typeTranslationKey,
    createdAt,
    timifyUserName,
    expiresAt,
    acceptedAt,
    revokedAt,
    revokedUserName = '-/-',
    accessUserName = '-/-'
  } = activityLog
  const classNames = ['ta-history__activity-logs__system-action', operation.toLowerCase()]
  let operations = {}
  if (operation === 'EXPIRED') operations = { context: t('activity.list.expired.context', [{ key: 'ACCESS_NAME', value: timifyUserName }]), from: acceptedAt, to: expiresAt }
  if (operation === 'DECLINED') operations = { context: t('activity.list.denied.context', [{ key: 'ACCESS_NAME', value: timifyUserName }]), byName: accessUserName }
  if (operation === 'APPROVED') operations = { context: t('activity.list.granted.context', [{ key: 'ACCESS_NAME', value: timifyUserName }]), from: acceptedAt, to: expiresAt, byName: accessUserName }
  if (operation === 'REVOKED') operations = { context: t('activity.list.revoked.context', [{ key: 'ACCESS_NAME', value: timifyUserName }]), from: acceptedAt, to: revokedAt, byName: revokedUserName }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-history__activity-logs__system-action__head'>
        <div className='ta-history__activity-logs__system-action__type'>{t(typeTranslationKey)}</div>
        {!!operations?.byName && (
          <div className='ta-history__activity-logs__system-action__created-by'>
            {t('global.by')}: {highlight(truncateText(operations?.byName, 30, true), search)}
          </div>
        )}
      </div>
      <div className='ta-history__activity-logs__system-action__body'>
        <div className='ta-history__activity-logs__system-action__date-time-box'>
          <div className='ta-history__activity-logs__system-action__date-time-box__content'>
            <div className='ta-history__activity-logs__system-action__date-time-box__content-date'>
              {createdAt ? format(createdAt, 'shortWithShortWeekDay') : ''}
            </div>
            <div className='ta-history__activity-logs__system-action__date-time-box__content-time'>
              {createdAt ? format(createdAt, 'time') : ''}
            </div>
          </div>
        </div>
        <div className='ta-history__activity-logs__system-action__content'>
          <div className='ta-history__activity-logs__system-action__content__head'>
            {operations.context}
          </div>
          {(operations?.from && operations?.to &&
            <div className='ta-history__activity-logs__system-action__content__body'>
              <FontAwesome5 icon='hourglass-half' type='solid' /> {operations.from ? format(operations.from, 'longWithTime') : ''} - {operations.to ? format(operations.to, 'longWithTime') : ''}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ActivityLogsItemSystemAction.propTypes = {
  activityLog: PropTypes.object.isRequired
}

export default ActivityLogsItemSystemAction
