import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  Row,
  Col,
  BorderedBox,
  Button,
  Image,
  Carousel,
  Markdown,
  t,
  Alert,
  Price,
  FormText,
  PlanBadge
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'
import { AppsPreviewRoles, AppsSubscriptionRow } from '../../../Beauties'
import { dangerousHTML } from '../../../Utils'

import './AppsPreview.css'

const AppsPreviewInstallation = (props) => {
  const {
    id,
    name,
    version,
    plan,
    onSubmit,
    benefits,
    description,
    functionality,
    privacyUrl,
    gallery,
    tutorialUrl,
    copyright,
    roles,
    developer,
    message,
    hasPayment,
    pricingType,
    activationFeePrice,
    trialUnits,
    unitDescription,
    unitDescriptionPlural,
    isVariablePrice,
    usageUnitPrice,
    priceDescription,
    priceRange,
    isPublic,
    currencyCode
  } = props || {}
  let [minPrice, maxPrice] = priceRange || []
  minPrice = minPrice || ''
  maxPrice = maxPrice || ''
  const oneTimeSetupPrice = <Price currency={currencyCode} price={activationFeePrice} />
  let payPerUsagePrice = <Price currency={currencyCode} price={usageUnitPrice} />
  if (isVariablePrice) {
    payPerUsagePrice = (
      <>
        <Price price={minPrice} currency={currencyCode} /> - <Price currency={currencyCode} price={maxPrice} />
      </>
    )
  }
  let pricingExtraLabel = ''
  if (hasPayment && pricingType === 'monthly') pricingExtraLabel = t('apps.preview.section.pricing.monthlySubscription')
  if (hasPayment && pricingType === 'payPerUsage') pricingExtraLabel = t('apps.preview.section.pricing.payPerUsage')
  let pricingPayPerUsageLabel = ''
  if (hasPayment && pricingType === 'payPerUsage' && !isVariablePrice) pricingPayPerUsageLabel = t('apps.subscriptions.entityPrice', [{ key: 'NAME', value: unitDescription }])
  if (hasPayment && pricingType === 'payPerUsage' && isVariablePrice) pricingPayPerUsageLabel = t('apps.subscriptions.variablePrice')

  return (
    <FixedContent>
      <FixedContentHeader>
        <Title label={name || t('apps.appDetails')}>
          <div className='ta-apps__preview__head-version'>v {version}</div>
          <PlanBadge plan={isPublic ? plan || 'CLASSIC' : 'PRIVATE'} />
        </Title>
      </FixedContentHeader>
      <FixedContentBody>
        {(message &&
          <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
            {message.text && t(message.text)}
          </Alert>
        )}
        {(id && !message &&
          <Form onSubmit={onSubmit} name='apps'>
            {gallery && gallery.length > 1 && (
              <Carousel className='ta-apps__preview__gallery' controls dots>
                {gallery.map((image, index) => (
                  <div key={index}>
                    <Image src={image} alt={`image-${index}`} />
                  </div>
                ))}
              </Carousel>
            )}
            {(gallery && gallery.length === 1 &&
              <div className='ta-apps__preview__cover'>
                <Image src={gallery[0]} alt='cover' />
              </div>
            )}
            {(hasPayment &&
              <>
                <Title
                  size='m'
                  icon='money-bill'
                  label={t('apps.preview.section.pricing.heading')}
                  extraLabel={pricingExtraLabel}
                  className='pricing'
                />
                <Alert
                  theme='alert'
                  label={t('apps.preview.section.pricing.message.header')}
                >
                  {pricingType === 'monthly' && !!activationFeePrice && (
                    dangerousHTML(t('apps.preview.section.pricing.message.monthlySubscription.oneTimeSetup.text'))
                  )}
                  {pricingType === 'monthly' && !activationFeePrice && (
                    dangerousHTML(t('apps.preview.section.pricing.message.monthlySubscription.text'))
                  )}
                  {pricingType === 'payPerUsage' && !!activationFeePrice && (
                    dangerousHTML(t('apps.preview.section.pricing.message.payPerUsage.oneTimeSetup.text', [{ key: 'COUNT', value: `${trialUnits}` }, { key: 'NAME', value: unitDescriptionPlural }]))
                  )}
                  {pricingType === 'payPerUsage' && !activationFeePrice && (
                    dangerousHTML(t('apps.preview.section.pricing.message.payPerUsage.text', [{ key: 'COUNT', value: `${trialUnits}` }, { key: 'NAME', value: unitDescriptionPlural }]))
                  )}
                </Alert>
                <Alert theme='info'>
                  {t('global.vatApplicability')}
                </Alert>
              </>
            )}
            {(hasPayment && !!activationFeePrice &&
              <>
                <Title theme='striped' label={t('apps.preview.section.pricing.oneTimeSetup.title')} />
                <AppsSubscriptionRow
                  title={t('apps.preview.section.pricing.oneTimeSetup.setup')}
                  text={t('apps.preview.section.pricing.oneTimeSetup.text')}
                  boxValue={oneTimeSetupPrice}
                  boxLabel={t('apps.preview.section.pricing.oneTimeSetup.oneTime').toLowerCase()}
                />
              </>
            )}
            {(hasPayment && pricingType === 'payPerUsage' &&
              <>
                {!!activationFeePrice && (
                  <Title theme='striped' label={t('apps.preview.section.pricing.payPerUsage')} />
                )}
                <AppsSubscriptionRow
                  title={pricingPayPerUsageLabel}
                  text={priceDescription}
                  boxValue={payPerUsagePrice}
                  boxLabel={`/${unitDescription}`}
                  big={isVariablePrice}
                  bottomOffset
                />
                <Alert theme='alert'>
                  {t('apps.subscriptions.threshold.message')}
                </Alert>
              </>
            )}
            {(description &&
              <div className='ta-apps__preview__description'>{dangerousHTML(description)}</div>
            )}
            {(benefits &&
              <>
                <Title size='m' icon='check' label={t('apps.preview.section.advantages.heading')} />
                <Markdown text={benefits} />
              </>
            )}
            {(functionality &&
              <>
                <Title size='m' icon='wrench' label={t('apps.preview.section.functionality.heading')} />
                <Markdown text={functionality} />
              </>
            )}
            {(roles &&
              <>
                <Title size='m' icon='universal-access' label={t('apps.preview.section.access.heading')} />
                <AppsPreviewRoles roles={roles} />
              </>
            )}
            {((tutorialUrl || developer?.website || privacyUrl || copyright) &&
              <>
                <Title size='m' icon='external-link' label={t('apps.preview.section.usefulLinks.heading')} />
                {(tutorialUrl &&
                  <Button as='link' to={tutorialUrl} className='ta-apps__preview__usfeful-link' target='_blank' external>
                    {t('apps.preview.section.usefulLinks.buttonGuide.label')}
                  </Button>
                )}
                {(developer && developer.website &&
                  <Button as='link' to={developer.website} className='ta-apps__preview__usfeful-link' target='_blank' external>
                    {t('apps.preview.section.usefulLinks.buttonDevWebsite.label')}
                  </Button>
                )}
                {(privacyUrl &&
                  <Button as='link' to={privacyUrl} className='ta-apps__preview__usfeful-link' target='_blank' external>
                    {t('apps.preview.section.usefulLinks.buttonPrivacyPolicy.label')}
                  </Button>
                )}
                {(copyright &&
                  <Button as='link' to={copyright} className='ta-apps__preview__usfeful-link' target='_blank' external>
                    {t('apps.preview.section.usefulLinks.buttonCopyright.label')}
                  </Button>
                )}
              </>
            )}
            <div className='ta-apps__preview__fixed-bottom-box'>
              {(!hasPayment &&
                <>
                  <Button type='submit' isBlock isPrimary>
                    {t('global.install')}
                  </Button>
                </>
              )}
              {(hasPayment &&
                <BorderedBox>
                  <Row>
                    <Col>
                      {pricingType === 'monthly' && (
                        <FormText className='ta-apps__preview__install-text'>
                          {t('apps.preview.monthlySubscription.install.text')}
                        </FormText>
                      )}
                      {pricingType === 'payPerUsage' && (
                        <FormText className='ta-apps__preview__install-text'>
                          {dangerousHTML(
                            t('apps.preview.payPerUsage.install.text', [{ key: 'COUNT', value: `${trialUnits}` }, { key: 'NAME', value: unitDescriptionPlural }])
                          )}
                        </FormText>
                      )}
                    </Col>
                  </Row>
                  <PopupTriggerWrapper
                    name='apps-paid-install'
                    position='top'
                    id={id}
                    block
                    extraClassName='ta-apps-paid-install'
                  >
                    <Button as='link' isPrimary isBlock external>
                      {pricingType === 'monthly' && t('apps.preview.monthlySubscription.install.button.label')}
                      {pricingType === 'payPerUsage' && t('global.install')}
                    </Button>
                  </PopupTriggerWrapper>
                </BorderedBox>
              )}
            </div>
          </Form>
        )}
      </FixedContentBody>
    </FixedContent>
  )
}

AppsPreviewInstallation.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object,
  name: PropTypes.string,
  hasAccess: PropTypes.bool,
  hasAcceptTerms: PropTypes.bool,
  version: PropTypes.string,
  plan: PropTypes.string,
  onSubmit: PropTypes.func,
  benefits: PropTypes.string,
  description: PropTypes.string,
  functionality: PropTypes.string,
  privacyUrl: PropTypes.string,
  gallery: PropTypes.array,
  tutorialUrl: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.object),
  developer: PropTypes.string
}

export default AppsPreviewInstallation
