import React from 'react'
import { connect } from '../../Store'

const RightFirstSidebarWrapper = props => {
  // const { route } = props

  return (
    <div>
      {/* TODO */}
    </div>
  )
}

const maps = state => ({
  route: state.router.name
})

export default connect(maps)(RightFirstSidebarWrapper)
