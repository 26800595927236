import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesPreviewCustomers } from '../../Beauties'
import { Loader } from '../../Common'
import { sortBy } from '../../Utils'

class BranchesPreviewCustomersWrapper extends Component {
  componentDidMount () {
    const { id } = this.props
    handlers.branchPreviewCustomersGet(id)
  }

  render () {
    const {
      id,
      isPending,
      branches,
      search
    } = this.props
    let { value: searchValue } = search || {}
    searchValue = searchValue ? searchValue.toLowerCase() : ''
    const branch = branches.find(item => item.id === id)
    let {
      areCustomersFetched,
      customers
    } = branch || {}
    customers = customers || []
    if (areCustomersFetched) {
      customers = customers
        .filter(item => {
          let { fullName, id } = item
          fullName = fullName || ''
          return (
            (fullName.toLowerCase().includes(searchValue)) ||
            (id.includes(searchValue))
          )
        })
        .sort(sortBy('-createdAt'))
    }

    return (
      !isPending
        ? <BranchesPreviewCustomers
            branch={branch}
            search={searchValue}
            customers={customers}
            isPending={!areCustomersFetched}
          />
        : <Loader active />
    )
  }
}

const maps = state => ({
  id: state.router.data.id,
  branches: state.branches.list || [],
  isPending: state.branches.pendingPreview || false,
  search: state.forms.branchPreviewCustomersFilter && state.forms.branchPreviewCustomersFilter.search
})

export default connect(maps)(BranchesPreviewCustomersWrapper)
