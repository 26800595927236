import React from 'react'
import {
  BranchesPreviewServiceFormDurationWrapper,
  BranchesPreviewServiceFormSettingsWrapper
} from '../../../../Beasts'
import {
  BranchesServiceFormDependencies,
  BranchesServiceFormBasicDetails,
  BranchesServiceFormExternalId,
  BranchesServiceFormPrice
} from '../../../../Beauties'

import './BranchesServiceForm.css'

const BrachesServiceFormSummary = props => {
  const {
    plan,
    showExternalIds,
    hasStripeConnected,
    allowedSet
  } = props

  return (
    <>
      {showExternalIds && <BranchesServiceFormExternalId allowedSet={allowedSet} />}
      <BranchesServiceFormBasicDetails allowedSet={allowedSet} plan={plan} ignoreTopOffset={!showExternalIds} />
      <BranchesPreviewServiceFormDurationWrapper allowedSet={allowedSet} plan={plan} />
      <BranchesServiceFormDependencies allowedSet={allowedSet} />
      <BranchesServiceFormPrice allowedSet={allowedSet} />
      <BranchesPreviewServiceFormSettingsWrapper allowedSet={allowedSet} hasStripeConnected={hasStripeConnected} />
    </>
  )
}

export default BrachesServiceFormSummary
