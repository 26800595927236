import { handlers, store } from '../..'

export const customerFieldsMoved = customerFields => {
  handlers.customerFieldsListOrderChanged(customerFields)
}

export const customerFieldEnterpriseUpdated = customerField => {
  if (!customerField) return
  const { customerFields = {} } = store.getState()
  const { ts } = customerFields.list.find(item => item.id === customerField.id) || {}
  if (ts === customerField.ts) return
  handlers.customerFieldUpdate(customerField)
}

export const customerFieldEnterpriseDeleted = id => {
  if (!id) return
  const { customerFields = {}, router = {} } = store.getState()
  const { name: routeName } = router || {}
  const customerField = customerFields.list.find(item => item.id === id) || {}
  if (!customerField || customerField.isDeleted) return
  if (routeName === 'customerFieldsPreview') handlers.navigateToPath('/customers/customer-fields')
  handlers.customerFieldDeleted(id)
  setTimeout(() => handlers.customerFieldRemoveDeleted(id), 2000)
}

// export const customerFieldCategoriesMoved = categories => {
//   handlers.customerFieldCategoryOrderChanged(categories)
// }

export const customerFieldEnterpriseCategoryUpdated = category => {
  if (!category) return
  handlers.customerFieldCategoryUpdate(category)
  setTimeout(() => {
    handlers.customerFieldCategoryUpdateReady()
  }, 2000)
}

export const customerFieldEnterpriseCategoryDeleted = id => {
  if (!id) return
  const { customerFields = {}, router = {} } = store.getState()
  const { name: routeName } = router || {}
  const category = customerFields.categoriesList.find(item => item.id === id) || {}
  if (category && routeName === 'customerFieldCategoryPreview') handlers.navigateToPath('/customers/customer-fields')
  if (!category || category.isDeleted) return
  handlers.customerFieldCategoryDeleted(id)
  setTimeout(() => handlers.customerFieldCategoryRemoveDeleted(id), 2000)
}
