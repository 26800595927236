import React from 'react'
import {
  FormSection,
  Title,
  Row,
  Col,
  BorderedBox,
  Checkbox,
  FormText,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { CourseRemindersSelect, CourseOtherRecipientsSelect } from '../../../Beauties'
import './CourseForm.css'

const CourseFormReminders = props => {
  const {
    courseCustomReminderCustomerSwitch,
    courseReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    courseCustomReminderStaffSwitch,
    courseReminderStaffSwitch,
    courseReminderStaffOthersCheckBox,
    courseReminderStaffOthers,
    resourceEmailRemindersMinutes
  } = props

  return (
    <FormSection ignoreBottomOffset>
      <Title size='m' isCompact label={t('servicesGroups.reminder.heading')} icon='bell' ignoreTopOffset />
      <Row>
        <Col>
          <Row>
            <Col>
              <FormText noOffset>{dangerousHTML(t('servicesGroups.reminder.questionText'))}</FormText>
            </Col>
          </Row>
          <Row>
            <Col>
              <Checkbox
                name='courseCustomReminderCustomerSwitch'
                label={t('servicesGroups.reminder.customers.custom.checkbox.label')}
                theme='switch'
              />
            </Col>
          </Row>
          {(courseCustomReminderCustomerSwitch &&
            <BorderedBox>
              <Row>
                <Col>
                  <Checkbox
                    name='courseReminderCustomerSwitch'
                    label={t('servicesGroups.reminder.customers.checkbox.label')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(courseReminderCustomerSwitch &&
                <Row className='ta-row__no-margin'>
                  <Col>
                    <CourseRemindersSelect
                      formName='courses'
                      reminders={customerEmailRemindersMinutes}
                      name='customerEmailRemindersMinutes'
                    />
                  </Col>
                </Row>
              )}
            </BorderedBox>
          )}
          <Row>
            <Col>
              <Checkbox
                name='courseCustomReminderStaffSwitch'
                label={t('servicesGroups.reminder.staff.custom.checkbox.label')}
                theme='switch'
              />
            </Col>
          </Row>
          {(courseCustomReminderStaffSwitch &&
            <BorderedBox>
              <Row>
                <Col>
                  <Checkbox
                    name='courseReminderStaffSwitch'
                    label={t('servicesGroups.reminder.staff.checkbox.label')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(courseReminderStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <BorderedBox className='ta-courses__form--outer-box'>
                        <Row>
                          <Col>
                            <Checkbox disabled name='courseReminderStaffOwnerCheckBox' label={t('global.owner')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox disabled name='courseReminderStaffResourcesCheckBox' label={t('servicesGroups.resources')} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox disabled name='courseReminderStaffOthersCheckBox' label={t('servicesGroups.others')} />
                          </Col>
                        </Row>
                        {(courseReminderStaffOthersCheckBox &&
                          <CourseOtherRecipientsSelect
                            recipients={courseReminderStaffOthers}
                            name='courseReminderStaffOthers'
                          />
                        )}
                      </BorderedBox>
                    </Col>
                  </Row>
                  <CourseRemindersSelect
                    formName='courses'
                    reminders={resourceEmailRemindersMinutes}
                    name='resourceEmailRemindersMinutes'
                  />
                </BorderedBox>
              )}
            </BorderedBox>
          )}
        </Col>
      </Row>
    </FormSection>
  )
}

export default CourseFormReminders
