// Add here subscriptions to handler relation
export default {
  // Statistics
  enterpriseStatisticsUpdated: 'dashboardUpdated',
  // Account
  invalidate2faSessions: 'accountSubscriptionSet',
  invalidate2faSession: 'accountSubscriptionSet',
  // Company
  publicHolidayUpdated: 'companySubscriptionSet',
  publicHolidaysUpdated: 'companySubscriptionSet',
  publicHolidayDeleted: 'companySubscriptionSet',
  publicHolidaysDeleted: 'companySubscriptionSet',
  publicHolidayEnterpriseUpdated: 'companySubscriptionSet',
  publicHolidaysEnterpriseDeleted: 'companySubscriptionSet',
  enterpriseUpdated: 'companySubscriptionSet',
  // Customers
  customerEnterpriseUpdated: 'customersSubscriptionSet',
  customerEnterpriseGlobalUpdated: 'customersSubscriptionSet',
  customerEnterpriseGlobalDeleted: 'customersSubscriptionSet',
  // Customer fields

  customerFieldEnterpriseUpdated: 'customerFieldsSubscriptionSet',
  customerFieldsEnterpriseUpdated: 'customerFieldsSubscriptionSet',
  customerFieldEnterpriseDeleted: 'customerFieldsSubscriptionSet',
  customerFieldEnterpriseCategoryUpdated: 'customerFieldsSubscriptionSet',
  customerFieldCategoryEnterpriseUpdated: 'customerFieldsSubscriptionSet',
  customerFieldCategoryEnterpriseGlobalUpdated: 'customerFieldsSubscriptionSet',
  customerFieldCategoryEnterpriseGlobalDeleted: 'customerFieldsSubscriptionSet',
  customerFieldCategoryEnterpriseDeleted: 'customerFieldsSubscriptionSet',
  // Company Tags
  companyTagsMoved: 'companyTagsSubscriptionSet',
  companyTagEnterpriseUpdated: 'companyTagsSubscriptionSet',
  companyTagEnterpriseDeleted: 'companyTagsSubscriptionSet',
  companyTagEnterpriseGlobalUpdated: 'companyTagsSubscriptionSet',
  companyTagEnterpriseGlobalDeleted: 'companyTagsSubscriptionSet',
  // Resources
  resourceCategoryEnterpriseUpdated: 'resourcesSubscriptoinsSet',
  resourceCategoryEnterpriseDeleted: 'resourcesSubscriptoinsSet',
  // Apps
  appInstalled: 'appsSubscriptionSet',
  appUninstalled: 'appsSubscriptionSet',
  appInstallationUpdated: 'appsSubscriptionSet',
  // User Permission Types
  permissionsEnterpriseGroupUpdated: 'userPermissionTypesSubscriptionSet',
  permissionEnterpriseGroupDeleted: 'userPermissionTypesSubscriptionSet',
  // Account
  accountUpdated: 'accountSubscriptionSet',
  accountDeleted: 'accountSubscriptionSet',
  // Enterprise User
  enterpriseUserUpdated: 'enterpriseUserSubscriptionSet',
  enterpriseUserDeleted: 'enterpriseUserSubscriptionSet',
  // Notifications
  temporaryRemoteAccessRequested: 'systemMessagesSubscriptionSet',
  temporaryRemoteAccessAccepted: 'systemMessagesSubscriptionSet',
  temporaryRemoteAccessDeclined: 'systemMessagesSubscriptionSet',
  temporaryRemoteAccessRevoked: 'systemMessagesSubscriptionSet',
  temporaryRemoteAccessExpired: 'systemMessagesSubscriptionSet',
  temporaryRemoteAccessLapsed: 'systemMessagesSubscriptionSet',
  // Activity
  activityLogUpdated: 'activityLogsSubscriptionSet'
}
