import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, Error, FontAwesome5, Button, t } from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'

import './Category.css'

export class Category extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidMount () {
    const { create, edit } = this.props
    const { addRef } = this.props
    addRef && addRef(this)

    if (create || edit) setTimeout(() => ReactDOM.findDOMNode(this.ref).focus(), 0)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (e) {
    const tempValue = e.target.value
    e.target.value = ''
    e.target.value = tempValue
  }

  onChange () {
    const value = this.ref.value || ''
    const { name, form, formName } = this.props
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  onEdit () {
    setTimeout(() => ReactDOM.findDOMNode(this.ref).focus(), 0)
  }

  onCancel () {
    const { name, form, formName, label } = this.props
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: label || '' } })
  }

  render () {
    const {
      create,
      type,
      name,
      label,
      editRedirectUrl,
      cancelRedirectUrl,
      onSave,
      edit,
      pendingCategory,
      form = {}
    } = this.props
    const classNames = ['ta-category']
    const uncategorized = !label || label === t('global.uncategorized')
    if (uncategorized && !create) classNames.push('uncategorized')
    if (pendingCategory === name.replace('category', '')) classNames.push('loading')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }}>
        <div className={classNames.join(' ')}>
          <div className='ta-category__icon'>
            <FontAwesome5 icon={!uncategorized || create ? 'folder' : 'folder-o'} />
          </div>
          {!edit && !create &&
            <div className='ta-category__label'>
              {label || t('global.uncategorized')}
            </div>
          }
          <div className={`ta-category__form-control ${!edit && !create && 'hide'}`}>
            <input
              className='ta-form-control'
              ref={ref => { this.ref = ref }}
              type='text'
              name={name}
              value={form.value}
              onFocus={this.onFocus}
              onChange={this.onChange}
              autoComplete='off'
            />
          </div>
          {!edit && !create &&
            <div className='ta-category__actions'>
              <PopupTriggerWrapper
                name={`${type}-categories-delete`}
                position='right'
                extraClassName='ta-category-popup'
                id={name}
              >
                <Button as='link' className='ta-category__actions__btn' external>
                  <FontAwesome5 icon='trash-o' />
                </Button>
              </PopupTriggerWrapper>
              <Button as='link' to={editRedirectUrl} action={this.onEdit} className='ta-category__actions__btn'>
                <FontAwesome5 icon='pencil' />
              </Button>
            </div>
          }
          {(edit || create) &&
            <div className='ta-category__edit-actions'>
              {form.value &&
                <div className='ta-category__actions__btn' onClick={() => onSave(name)}>
                  <FontAwesome5 icon='save' />
                </div>
              }
              <Button as='link' action={this.onCancel} to={cancelRedirectUrl} className='ta-category__actions__btn'>
                <FontAwesome5 icon='times' />
              </Button>
            </div>
          }
        </div>
        <Error name={name} />
      </div>
    )
  }
}

Category.propTypes = {
  create: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  editRedirectUrl: PropTypes.string,
  cancelRedirectUrl: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  form: PropTypes.object
}

export const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(Category), FormContext)
