import React from 'react'
import { UserPermissionTypeSummary } from '../../../Beauties'
import { t } from '../../../Common'
import { ADMIN_PERMISSION_TYPE } from '../../../Store/Components/UserPermissions'

const UsersPreviewPermissions = props => {
  let {
    rules,
    roles,
    permissionTypeAssignedToUser,
    branches,
    isLogedInUserAdmin,
    canLogedInUserReadUsers
  } = props
  rules = rules || []
  roles = roles || []
  let userPermission = { permissions: rules }
  const isAdmin = roles.includes('ADMIN')
  const isOwner = roles.includes('OWNER')
  const isCustom = !permissionTypeAssignedToUser && roles.includes('CUSTOM')
  if (isAdmin) userPermission = ADMIN_PERMISSION_TYPE
  if (isOwner) {
    userPermission = {
      ...ADMIN_PERMISSION_TYPE,
      permissions: {
        ...ADMIN_PERMISSION_TYPE.permissions,
        branchesDelete: true
      }
    }
  }
  if (isCustom) {
    userPermission = {
      ...userPermission,
      icon: 'cogs',
      color: '#385F87',
      name: t('global.custom')
    }
  }
  if (permissionTypeAssignedToUser) userPermission = permissionTypeAssignedToUser

  return (
    <UserPermissionTypeSummary
      canLogedInUserReadUsers={canLogedInUserReadUsers}
      isLogedInUserAdmin={isLogedInUserAdmin}
      userPermission={userPermission}
      branches={branches}
      hideUserCount
    />
  )
}

export default UsersPreviewPermissions
