import React from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  FontAwesome5,
  t
} from '../../Common'

const AppsFilters = props => {
  const { disabled } = props
  return (
    <div className='ta-apps__filters'>
      <Form name='appsFilters'>
        <Row>
          <Col>
            <Input
              label={t('global.search.label')}
              type='text'
              name='search'
              clearable={!disabled}
              disabled={disabled}
              addon={<FontAwesome5 icon='search' type='r' />}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AppsFilters
