import React from 'react'
import {
  Row,
  Col,
  Checkbox,
  DurationInput,
  BorderedBox,
  Title,
  t
} from '../../../Common'
import { hex2rgba } from '../../../Utils'

const ServiceCombinationFormDuration = props => {
  const {
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    customisations
  } = props
  const { settings } = customisations || {}
  const { hideServicesFormDurationBeforeAndAfter } = settings || {}
  const color = '#385f87'
  const bufferTimesStyles = { background: hex2rgba(color, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color, 1) }

  return (
    <>
      {(!hideServicesFormDurationBeforeAndAfter &&
        <>
          <Row>
            <Col>
              <Checkbox
                name='showDurationBeforeAndAfter'
                label={t('servicesGroups.form.section.duration.checkboxPrepFollowUpTime.description')}
                theme='switch'
                inline
              />
            </Col>
          </Row>
          {(showDurationBeforeAndAfter &&
            <BorderedBox>
              <div className='ta-buffer-times'>
                <div className='ta-buffer-times__box' style={bufferTimesStyles}>
                  <div className='ta-buffer-times__box__content' style={bufferTimesContentContentStyles} />
                </div>
                <Row>
                  <Col>
                    <Title theme='striped' label={t('global.duration.preparationTime')} />
                    <DurationInput name='durationBefore' noDays={!showDurationDaySelector} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Title theme='striped' label={t('global.duration.followUpTime')} />
                    <DurationInput name='durationAfter' noDays={!showDurationDaySelector} />
                  </Col>
                </Row>
              </div>
            </BorderedBox>
          )}
        </>
      )}
    </>
  )
}

export default ServiceCombinationFormDuration
