
import React from 'react'
import PropTypes from 'prop-types'
import './Logo.css'

const BlueTemplateBadge = props => {
  const { text, color = null, background = null } = props

  return (
    <div className='ta-blue-template-badge' style={{ color: color, backgroundColor: background }}>
      {text}
    </div>
  )
}

BlueTemplateBadge.propTypes = {
  locale: PropTypes.string
}

export default BlueTemplateBadge
