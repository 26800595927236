const setCustomTimeout = ({ config, handlers, handlerName, timeout }) => setTimeout(() => {
  handlers[handlerName](config[handlerName].ids)
  clearTimeout(config[handlerName].timeoutFn)
  config[handlerName].ids = []
  config[handlerName].timeoutFn = null
}, timeout)

/**
 * Factory method that prepares the configuration object responsible for collecting the ids within certain period
 * of time
 *
 * @param {[String]} configNames      An array of config names which correspond to "subscriptionName"
 * @param {Object} handlers           A full list of handlers
 * @param {Number} [timeout]          A timeout provided in ms, which indicates the "window" of collecting subscriptions
 * @param {Boolean} [shouldDebounce]  If provided, every new subscription will be debounced by the provided "timeout"
 * @returns {Function}
 */
export const setSubscriptionIdsCollectorFactory = ({ configNames, handlers, timeout = 2000, shouldDebounce }) => {
  // first setup the configuration for the collector
  const config = configNames.reduce((result, name) =>
    ({
      ...result,
      [name]: {
        timeoutFn: null,
        ids: []
      }
    }), {})

  // and here we can perform pushing of ids to collector by subscription name
  return ({ handlerName, id }) => {
    if (config[handlerName].timeoutFn) {
      // if debouncing is true, then clear current timeout and setup new one
      if (shouldDebounce) {
        clearTimeout(config[handlerName].timeoutFn)
        config[handlerName].timeoutFn = setCustomTimeout({ config, handlers, handlerName, timeout })
      }
      config[handlerName].ids.push(id)
      return
    }

    config[handlerName].ids.push(id)
    config[handlerName].timeoutFn = setCustomTimeout({ config, handlers, handlerName, timeout })
  }
}
