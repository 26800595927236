import React, { useEffect } from 'react'
import { connect, handlers } from '../../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../../Common'
import { ResourceCategoryPreview } from '../../../Beauties'

const ResourceCategoryPreviewWrapper = props => {
  let {
    categoryId,
    categories,
    hash,
    pending,
    account
  } = props
  pending = !!pending
  categories = categories || []
  account = account || {}
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.resourceCategoryGet(categoryId)
  }, [categoryId])
  const category = categories.find(item => item.id === categoryId) || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalResourceCategoriesRead, globalResourceCategoriesWrite, globalResourceCategoriesDelete } = enterprisePermissions || {}
  globalResourceCategoriesRead = !!isAdmin || !!globalResourceCategoriesRead
  globalResourceCategoriesWrite = !!isAdmin || !!globalResourceCategoriesWrite
  globalResourceCategoriesDelete = !!isAdmin || !!globalResourceCategoriesDelete

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ResourceCategoryPreview
          category={category}
          hash={hash}
          pending={pending}
          globalResourceCategoriesRead={globalResourceCategoriesRead}
          globalResourceCategoriesWrite={globalResourceCategoriesWrite}
          globalResourceCategoriesDelete={globalResourceCategoriesDelete}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: state.router.hash,
  categoryId: (state.router.data && state.router.data.categoryId),
  categories: state.resources.categoriesList,
  pending: state.resources.pendingPreview,
  account: state.account
})

export default connect(maps)(ResourceCategoryPreviewWrapper)
