import React from 'react'
import {
  SimpleTabsHeaderItem,
  FixedContentHeader,
  SimpleTabsBodyItem,
  HoverPopupContent,
  HoverPopupTrigger,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FixedContent,
  FontAwesome5,
  SimpleTabs,
  HoverPopup,
  Alert,
  Loader,
  Title,
  Button,
  Blur,
  t
} from '../../Common'
import { ResourceCategoriesActive } from '../../Beauties'
import { PopupTriggerWrapper, ResourceCategoriesToDeleteWrapper } from '../../Beasts'

import './Resources.css'

const ResourceCategoriesList = props => {
  const {
    categories,
    pending,
    hash,
    globalResourceCategoriesRead,
    globalResourceCategoriesWrite
  } = props
  const availableTabs = ['activeResourceCategories', 'deletedResourceCategories']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeResourceCategories'

  return (
    <div className='ta-resource-categories'>
      <FixedContent className='ta-resource-categories__fixed-content'>
        <FixedContentHeader>
          <Title icon='suitcase' iconTheme='grey' label={t('resource.category.title')}>
            <PopupTriggerWrapper name='resources-help-box' position='bottom' extraClassName='ta-help-box'>
              <Button as='link' className='ta-title__link' external>
                {t('global.help')} <FontAwesome5 icon='lightbulb' />
              </Button>
            </PopupTriggerWrapper>
            {(globalResourceCategoriesWrite &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('resource.categories.add')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/management/resource-categories/add' isPrimary icon='plus' iconType='solid' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalResourceCategoriesRead ? 'inactive' : ''} active={!globalResourceCategoriesRead && !pending} icon='ban' label={t('global.accessDenied')}>
            <Loader active={pending} />
            <div className='ta-resource-categories__list'>
              <Alert label={t('resource.category.global.label')} theme='alert'>
                {t('resource.category.global.message')}
              </Alert>
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='activeResourceCategories' label={t('resource.category.activeCategories')} icon='sliders-h' />
                  <SimpleTabsHeaderItem name='deletedResourceCategories' label={t('resource.category.deleteCategories')} icon='minus-octagon' />
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalResourceCategoriesRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='activeResourceCategories'>
                    <ResourceCategoriesActive categories={categories} />
                  </SimpleTabsBodyItem>
                  <SimpleTabsBodyItem name='deletedResourceCategories' renderOnlyIfActive>
                    <ResourceCategoriesToDeleteWrapper />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            </div>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default ResourceCategoriesList
