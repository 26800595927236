import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { connect, handlers } from '../../Store'
import { Dashboard, DashboardMock } from '../../Beauties'
import { Loader, t } from '../../Common'

const DashboardWrapper = props => {
  const [isMessageShown, setIsMessageShown] = useState(true)
  let {
    pending,
    dailyStatistics,
    monthlyStatistics,
    overallStatistics,
    marketingConversionsValue,
    dashboardFiltersForm,
    dashboardMostBookedResourcesTypeForm,
    signUpTimesValue,
    locale,
    totalCompanyPlans,
    countries,
    lastStatisticsStateChange,
    account,
    messageExport
  } = props
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  isAdmin = !!isAdmin
  let { statisticsRead } = enterprisePermissions
  statisticsRead = isAdmin || statisticsRead
  statisticsRead = !!statisticsRead
  //* FILTER + BRANCHES INFORMATION *//
  const { type, year, month } = dashboardFiltersForm || {}
  const { value: dashboardFiltersType } = type || {}
  const { value: dashboardFiltersYear } = year || {}
  const { value: dashboardFiltersMonth, options: monthOptions = [] } = month || {}
  const { month: dailyStatisticsMonth } = dailyStatistics || {}
  const { month: monthlyStatisticsMonth } = monthlyStatistics || {}

  useEffect(() => {
    if (statisticsRead) {
      handlers.dashboardFilterFormGet()
      handlers.dashboardGet()
      handlers.dashboardStatisticsOverallGet()
      handlers.dashboardMostBookedResourcesFiltersGet()
      handlers.dashboardPeakSignUpTimesFiltersGet()
    }
  }, [statisticsRead])

  const onFilterYearSubmit = year => {
    const currentYear = moment().format('YYYY')
    const currentMonth = moment().format('MM')
    const previousMonth = moment().subtract('1', 'month').format('MM')
    const lastDayOfLastMonth = moment().subtract('1', 'day').format('DD MMM')
    const previousDay = moment().subtract('1', 'day').format('DD')
    const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
    const isFirstDayOfCurrentMonth = moment().format('YYYY-MM-DD') === firstDayOfCurrentMonth
    const months = moment.months().map((item, i) => ({
      label: item,
      value: moment().month(i).format('MM'),
      i: i + 1
    }))
    const { month } = dashboardFiltersForm || {}
    const { value: filtersMonth } = month || {}
    const monthsOptions = months.filter(item => item.i <= (isFirstDayOfCurrentMonth ? previousMonth : currentMonth)).map(item => item.value === (isFirstDayOfCurrentMonth ? previousMonth : currentMonth)
      ? {
          label: `${item.label} (${t('global.until')} ${isFirstDayOfCurrentMonth ? lastDayOfLastMonth : previousDay} ${item.label.substring(0, 3)})`,
          value: item.value,
          i: item.i
        }
      : item
    )
    handlers.formFieldsUpdate('dashboardFilters', {
      month: {
        options: year === '2020'
          ? months.filter(item => item.i >= 11)
          : year === currentYear
            ? monthsOptions
            : months,
        value: year === '2020'
          ? '12'
          : year === currentYear
            ? parseFloat(filtersMonth) > parseFloat(currentMonth)
              ? currentMonth
              : filtersMonth
            : filtersMonth
      }
    })
    handlers.dashboardGet()
  }

  const onFilterMonthSubmit = () => {
    handlers.dashboardGet()
  }

  const onExportClick = () => {
    if (dashboardFiltersType === 'lifetime') {
      handlers.dashboardStatisticsExportFormSave({
        ...dashboardFiltersForm,
        type: {
          value: 'lifetime'
        },
        exportMode: {
          value: 'ALL'
        }
      })
      return
    }
    handlers.modalSet('export-dashboard-statistics')
  }

  dailyStatistics = dailyStatistics || {}
  monthlyStatistics = monthlyStatistics || {}
  overallStatistics = overallStatistics || {}

  const {
    averageBookingParticipantsPerCourseEvent,
    averageServiceBookingsPerResource,
    averageDailyServiceBookings,
    averageBookingGuestsPerCourseEvent,
    averageWeeklyServiceBookings,
    averageWeeklyCancelledBookings,
    blockerDenialsStatistics,
    eventSignupsByDaysOfWeekUTC,
    eventSignupsByDaysUTC,
    eventSignupsByDaysWithGuestsUTC,
    eventSignupsByHoursOfDayUTC,
    eventsByDaysUTC,
    onlineMarketingUtmStatistics,
    sickDenialsStatistics,
    topBookingBookableResources,
    topBookingBranches,
    topBookingDependencyResources,
    topBookingResources,
    topBookingServices,
    topBookingServicesWithPrice,
    totalCancelledEvents,
    totalCancelledInternalEvents,
    totalCancelledOnlineEvents,
    totalCourseBookingGuests,
    totalCourseBookingParticipants,
    totalCourseBookings,
    totalCourseBookingsWithId,
    totalCourseBookingsWithTitle,
    totalCourseEvents,
    totalCourseEventsWithId,
    totalCourseEventsWithTitle,
    totalCourseInStorePaidRevenue,
    totalCourseOnlinePaidRevenue,
    totalCourseRevenue,
    totalCustomers,
    totalEvents,
    totalInternalCourseBookingsWithId,
    totalInternalCourseBookingsWithTitle,
    totalInternalCustomers,
    totalInternalServiceEventsWithId,
    totalInternalServiceEventsWithIdAndCustomer,
    totalOnlineCourseBookingsWithId,
    totalOnlineCourseBookingsWithTitle,
    totalOnlineCustomers,
    totalOnlinePaidRevenue,
    totalOnlineServiceEventsWithId,
    totalOnlineServiceEventsWithIdAndCustomer,
    totalRescheduledEvents,
    totalResources,
    totalResourcesOnlineBookedTimes,
    totalResourcesOnlineTimes,
    totalResourcesWorkingTimes,
    totalResourcesWorkingBookedTimes,
    totalRevenue,
    totalServiceEvents,
    totalServiceEventsWithCustomer,
    totalServiceEventsWithId,
    totalServiceEventsWithIdAndCustomer,
    totalServiceEventsWithTitle,
    totalServiceEventsWithTitleAndCustomer,
    totalServiceInStorePaidRevenue,
    totalServiceOnlinePaidRevenue,
    totalServiceRevenue,
    vacationDenialsStatistics
  } = dailyStatistics || {}
  const {
    topBookingServices: topBookingServicesOverall,
    totalCourseBookings: totalCourseBookingsOverall,
    totalCourseBookingsWithId: totalCourseBookingsWithIdOverall,
    totalCourseBookingsWithTitle: totalCourseBookingsWithTitleOverall,
    totalCourseEvents: totalCourseEventsOverall,
    totalCourseEventsWithId: totalCourseEventsWithIdOverall,
    totalCourseEventsWithTitle: totalCourseEventsWithTitleOverall,
    totalCourseInStorePaidRevenue: totalCourseInStorePaidRevenueOverall,
    totalCourseOnlinePaidRevenue: totalCourseOnlinePaidRevenueOverall,
    totalCourseRevenue: totalCourseRevenueOverall,
    totalCustomers: totalCustomersOverall,
    totalInternalCourseBookingsWithId: totalInternalCourseBookingsWithIdOverall,
    totalInternalCourseBookingsWithTitle: totalInternalCourseBookingsWithTitleOverall,
    totalInternalCustomers: totalInternalCustomersOverall,
    totalInternalServiceEventsWithId: totalInternalServiceEventsWithIdOverall,
    totalInternalServiceEventsWithIdAndCustomer: totalInternalServiceEventsWithIdAndCustomerOverall,
    totalOnlineCourseBookingsWithId: totalOnlineCourseBookingsWithIdOverall,
    totalOnlineCourseBookingsWithTitle: totalOnlineCourseBookingsWithTitleOverall,
    totalOnlineCustomers: totalOnlineCustomersOverall,
    totalOnlineServiceEventsWithId: totalOnlineServiceEventsWithIdOverall,
    totalOnlineServiceEventsWithIdAndCustomer: totalOnlineServiceEventsWithIdAndCustomerOverall,
    totalRevenue: totalRevenueOverall,
    totalServiceEvents: totalServiceEventsOverall,
    totalServiceEventsWithCustomer: totalServiceEventsWithCustomerOverall,
    totalServiceEventsWithId: totalServiceEventsWithIdOverall,
    totalServiceEventsWithIdAndCustomer: totalServiceEventsWithIdAndCustomerOverall,
    totalServiceEventsWithTitle: totalServiceEventsWithTitleOverall,
    totalServiceEventsWithTitleAndCustomer: totalServiceEventsWithTitleAndCustomerOverall,
    totalServiceInStorePaidRevenue: totalServiceInStorePaidRevenueOverall,
    totalServiceOnlinePaidRevenue: totalServiceOnlinePaidRevenueOverall,
    totalServiceRevenue: totalServiceRevenueOverall
  } = overallStatistics || {}

  //* FILTER + BRANCHES INFORMATION *//
  const isFirstDayOfMonth = moment().date() === 1
  const currentMonth = moment().format('MM')
  const isFilteredMonthCurrent = currentMonth === dashboardFiltersMonth
  const filteredMonthLabel = (monthOptions.find(item => item.value === dashboardFiltersMonth) || { label: '' }).label.split(' ')[0]
  const areStatisticsForSameMonth = dailyStatisticsMonth === monthlyStatisticsMonth

  //* STATISTICS BY MONTH *//
  // Single bookings - all single bookings with or without customers
  const totalServiceEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEvents : totalServiceEvents
  const totalServiceEventsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithTitle : totalServiceEventsWithTitle
  const totalServiceEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithId : totalServiceEventsWithId
  const totalOnlineServiceEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineServiceEventsWithId : totalOnlineServiceEventsWithId
  const totalInternalServiceEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalServiceEventsWithId : totalInternalServiceEventsWithId
  // Single bookings - all single bookings with customers
  const totalServiceEventsWithTitleAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithTitleAndCustomer : totalServiceEventsWithTitleAndCustomer
  const totalServiceEventsWithCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithCustomer : totalServiceEventsWithCustomer
  const totalOnlineServiceEventsWithIdAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineServiceEventsWithIdAndCustomer : totalOnlineServiceEventsWithIdAndCustomer
  const totalInternalServiceEventsWithIdAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalServiceEventsWithIdAndCustomer : totalInternalServiceEventsWithIdAndCustomer
  const totalServiceEventsWithIdAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithIdAndCustomer : totalServiceEventsWithIdAndCustomer
  // Avg single bookings /day
  const averageDailyServiceBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.averageDailyServiceBookings : averageDailyServiceBookings
  // Avg single bookings /week
  const averageWeeklyServiceBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.averageWeeklyServiceBookings : averageWeeklyServiceBookings
  // Group bookings
  const totalCourseEventsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseEventsWithTitle : totalCourseEventsWithTitle
  const totalCourseEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseEventsWithId : totalCourseEventsWithId
  const totalCourseEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseEvents : totalCourseEvents
  // Group booking sign-ups
  const totalOnlineCourseBookingsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineCourseBookingsWithTitle : totalOnlineCourseBookingsWithTitle
  const totalInternalCourseBookingsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalCourseBookingsWithTitle : totalInternalCourseBookingsWithTitle
  const totalCourseBookingsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingsWithTitle : totalCourseBookingsWithTitle
  const totalOnlineCourseBookingsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineCourseBookingsWithId : totalOnlineCourseBookingsWithId
  const totalInternalCourseBookingsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalCourseBookingsWithId : totalInternalCourseBookingsWithId
  const totalCourseBookingsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingsWithId : totalCourseBookingsWithId
  const totalCourseBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookings : totalCourseBookings
  // Group guests vs participants + totalCourseBookings
  const totalCourseBookingParticipantsValue = (areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingParticipants : totalCourseBookingParticipants) || 0
  const totalCourseBookingGuestsValue = (areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingGuests : totalCourseBookingGuests) || 0
  const totalCourseBookingGuestsAndParticipantsValue = totalCourseBookingParticipantsValue + totalCourseBookingGuestsValue
  // Avg participants
  const averageBookingParticipantsPerCourseEventValue = areStatisticsForSameMonth ? monthlyStatistics.averageBookingParticipantsPerCourseEvent : averageBookingParticipantsPerCourseEvent
  // Avg guests
  const averageBookingGuestsPerCourseEventValue = areStatisticsForSameMonth ? monthlyStatistics.averageBookingGuestsPerCourseEvent : averageBookingGuestsPerCourseEvent
  // Cancellations
  const totalCancelledInternalEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCancelledInternalEvents : totalCancelledInternalEvents
  const totalCancelledOnlineEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCancelledOnlineEvents : totalCancelledOnlineEvents
  const totalCancelledEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCancelledEvents : totalCancelledEvents
  // Avg cancellations /week
  const averageWeeklyCancelledBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.averageWeeklyCancelledBookings : averageWeeklyCancelledBookings
  // Rescheduled - totalRescheduledEvents => has daily + monthlyValue
  const totalRescheduledEventsDaily = totalRescheduledEvents
  const totalRescheduledEventsMonthly = monthlyStatistics.totalRescheduledEvents
  // Peak sign-up times
  const eventSignupsByHoursOfDayUTCValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByHoursOfDayUTC : eventSignupsByHoursOfDayUTC
  // Peak sign-up days
  const eventSignupsByDaysOfWeekUTCValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByDaysOfWeekUTC : eventSignupsByDaysOfWeekUTC
  // Daily sign-up totals
  const eventSignupsByDaysUTCValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByDaysUTC : eventSignupsByDaysUTC
  const eventSignupsByDaysUTCWithGuestsValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByDaysWithGuestsUTC : eventSignupsByDaysWithGuestsUTC
  const eventSignUpsByDaysUTC = signUpTimesValue ? eventSignupsByDaysUTCWithGuestsValue : eventSignupsByDaysUTCValue
  // Daily calendar bookings
  const eventsByDaysUTCValue = areStatisticsForSameMonth ? monthlyStatistics.eventsByDaysUTC : eventsByDaysUTC
  // Online marketing conversions
  const onlineMarketingStatisticsDaily = (onlineMarketingUtmStatistics && Array.isArray(onlineMarketingUtmStatistics[marketingConversionsValue])) ? onlineMarketingUtmStatistics[marketingConversionsValue] : []
  const onlineMarketingStatisticsMonthly = (monthlyStatistics.onlineMarketingUtmStatistics && Array.isArray(monthlyStatistics.onlineMarketingUtmStatistics[marketingConversionsValue])) ? monthlyStatistics.onlineMarketingUtmStatistics[marketingConversionsValue] : []
  const onlineMarketingStatisticsValue = areStatisticsForSameMonth ? onlineMarketingStatisticsMonthly : onlineMarketingStatisticsDaily
  // Most booked services
  const topBookingServicesValue = areStatisticsForSameMonth ? monthlyStatistics.topBookingServices : topBookingServices
  // Total resources - totalResources => has daily + monthlyValue
  const totalResourcesDaily = totalResources
  const totalResourcesMonthly = monthlyStatistics.totalResources
  // Sick days
  const { days: sickDenialsDaysDaily } = sickDenialsStatistics || {}
  const { days: sickDenialsDaysMonthly } = monthlyStatistics.sickDenialsStatistics || {}
  // Blocked days
  const { days: blockerDenialsDaysDaily } = blockerDenialsStatistics || {}
  const { days: blockerDenialsDaysMonthly } = monthlyStatistics.blockerDenialsStatistics || {}
  // Holidays /WILL BE RELEASED IN V2/
  const { days: vacationDenialsDaysDaily } = vacationDenialsStatistics || {}
  const { days: vacationDenialsDaysMonthly } = monthlyStatistics.vacationDenialsStatistics || {}
  // Avg bookings / resource
  const averageServiceBookingsPerResourceValue = areStatisticsForSameMonth ? monthlyStatistics.averageServiceBookingsPerResource : averageServiceBookingsPerResource
  // Working hours capacity
  const {
    hours: totalResourcesWorkingHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesWorkingTimes : totalResourcesWorkingTimes) || {}
  const {
    hours: totalResourcesWorkingBookedHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesWorkingBookedTimes : totalResourcesWorkingBookedTimes) || {}
  // Booking hours capacity
  const {
    hours: totalResourcesOnlineHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesOnlineTimes : totalResourcesOnlineTimes) || {}
  const {
    hours: totalResourcesOnlineBookedHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesOnlineBookedTimes : totalResourcesOnlineBookedTimes) || {}
  // Most booked resources
  const { type: dashboardMostBookedResourcesTypeFormType } = dashboardMostBookedResourcesTypeForm || {}
  const { value: dashboardMostBookedResourcesTypeFormValue } = dashboardMostBookedResourcesTypeFormType || {}
  const topBookingResourcesValue = {
    topBookingResources: areStatisticsForSameMonth ? monthlyStatistics.topBookingResources : topBookingResources,
    topBookingBookableResources: areStatisticsForSameMonth ? monthlyStatistics.topBookingBookableResources : topBookingBookableResources,
    topBookingDependencyResources: areStatisticsForSameMonth ? monthlyStatistics.topBookingDependencyResources : topBookingDependencyResources
  }[dashboardMostBookedResourcesTypeFormValue] || null
  // Customers
  const totalInternalCustomersValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalCustomers : totalInternalCustomers
  const totalOnlineCustomersValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineCustomers : totalOnlineCustomers
  const totalCustomersValue = areStatisticsForSameMonth ? monthlyStatistics.totalCustomers : totalCustomers
  // Revenue
  const totalServiceOnlinePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceOnlinePaidRevenue : totalServiceOnlinePaidRevenue
  const totalServiceInStorePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceInStorePaidRevenue : totalServiceInStorePaidRevenue
  const totalServiceRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceRevenue : totalServiceRevenue
  const totalCourseOnlinePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseOnlinePaidRevenue : totalCourseOnlinePaidRevenue
  const totalCourseInStorePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseInStorePaidRevenue : totalCourseInStorePaidRevenue
  const totalCourseRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseRevenue : totalCourseRevenue
  const totalRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalRevenue : totalRevenue
  // Frequently booked services with a fee
  const topBookingServicesWithPriceValue = areStatisticsForSameMonth ? monthlyStatistics.topBookingServicesWithPrice : topBookingServicesWithPrice
  // Paid in advance vs. on-site + totalRevenue
  const totalOnlinePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlinePaidRevenue : totalOnlinePaidRevenue
  // Branches with most booking sign-ups
  const topBookingBranchesValue = areStatisticsForSameMonth ? monthlyStatistics.topBookingBranches : topBookingBranches

  const countryCode = locale.split('-')[1]
  const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
  const { currency } = country || {}
  const { code: currencyCode } = currency || {}
  const statisticsLastUpdate = (lastStatisticsStateChange || '00 0070').split(' ')[1] || ''

  return (
    <>
      {(pending
        ? <Loader active />
        : totalCompanyPlans > 0 && statisticsRead
          ? <Dashboard
            // FILTER + BRANCHES INFORMATION
              dashboardFiltersType={dashboardFiltersType}
              dashboardFiltersYear={dashboardFiltersYear}
              dashboardFiltersMonth={dashboardFiltersMonth}
              isFirstDayOfMonth={isFirstDayOfMonth}
              isFilteredMonthCurrent={isFilteredMonthCurrent}
              filteredMonthLabel={filteredMonthLabel}
              areStatisticsForSameMonth={areStatisticsForSameMonth}
              dailyStatisticsMonth={dailyStatisticsMonth}
              monthlyStatisticsMonth={monthlyStatisticsMonth}
              isMessageShown={isMessageShown}
              setIsMessageShown={setIsMessageShown}
              branchCurrency={currencyCode}
              onFilterYearSubmit={onFilterYearSubmit}
              onFilterMonthSubmit={onFilterMonthSubmit}
              locale={locale}
              statisticsLastUpdate={statisticsLastUpdate}
              onExportClick={onExportClick}
              messageExport={messageExport}
            // onFilterTypeChange={onFilterTypeChange}
            // OVERALL STATISTICS VALUES
              totalServiceEventsOverall={totalServiceEventsOverall}
              totalServiceEventsWithTitleOverall={totalServiceEventsWithTitleOverall}
              totalServiceEventsWithTitleAndCustomerOverall={totalServiceEventsWithTitleAndCustomerOverall}
              totalOnlineServiceEventsWithIdOverall={totalOnlineServiceEventsWithIdOverall}
              totalInternalServiceEventsWithIdOverall={totalInternalServiceEventsWithIdOverall}
              totalServiceEventsWithIdOverall={totalServiceEventsWithIdOverall}
              totalServiceEventsWithCustomerOverall={totalServiceEventsWithCustomerOverall}
              totalOnlineServiceEventsWithIdAndCustomerOverall={totalOnlineServiceEventsWithIdAndCustomerOverall}
              totalInternalServiceEventsWithIdAndCustomerOverall={totalInternalServiceEventsWithIdAndCustomerOverall}
              totalServiceEventsWithIdAndCustomerOverall={totalServiceEventsWithIdAndCustomerOverall}
              totalCourseEventsOverall={totalCourseEventsOverall}
              topBookingServicesOverall={topBookingServicesOverall}
              totalCustomersOverall={totalCustomersOverall}
              totalInternalCustomersOverall={totalInternalCustomersOverall}
              totalOnlineCustomersOverall={totalOnlineCustomersOverall}
              totalCourseEventsWithTitleOverall={totalCourseEventsWithTitleOverall}
              totalCourseEventsWithIdOverall={totalCourseEventsWithIdOverall}
              totalCourseBookingsWithTitleOverall={totalCourseBookingsWithTitleOverall}
              totalOnlineCourseBookingsWithTitleOverall={totalOnlineCourseBookingsWithTitleOverall}
              totalInternalCourseBookingsWithTitleOverall={totalInternalCourseBookingsWithTitleOverall}
              totalCourseBookingsWithIdOverall={totalCourseBookingsWithIdOverall}
              totalOnlineCourseBookingsWithIdOverall={totalOnlineCourseBookingsWithIdOverall}
              totalInternalCourseBookingsWithIdOverall={totalInternalCourseBookingsWithIdOverall}
              totalRevenueOverall={totalRevenueOverall}
              totalServiceRevenueOverall={totalServiceRevenueOverall}
              totalServiceInStorePaidRevenueOverall={totalServiceInStorePaidRevenueOverall}
              totalServiceOnlinePaidRevenueOverall={totalServiceOnlinePaidRevenueOverall}
              totalCourseRevenueOverall={totalCourseRevenueOverall}
              totalCourseInStorePaidRevenueOverall={totalCourseInStorePaidRevenueOverall}
              totalCourseOnlinePaidRevenueOverall={totalCourseOnlinePaidRevenueOverall}
              totalCourseBookingsOverall={totalCourseBookingsOverall}
            // NORMAL STATISTICS VALUES
              totalServiceEventsWithTitleAndCustomer={totalServiceEventsWithTitleAndCustomerValue}
              totalServiceEventsWithCustomer={totalServiceEventsWithCustomerValue}
              totalOnlineServiceEventsWithIdAndCustomer={totalOnlineServiceEventsWithIdAndCustomerValue}
              totalInternalServiceEventsWithIdAndCustomer={totalInternalServiceEventsWithIdAndCustomerValue}
              totalServiceEventsWithIdAndCustomer={totalServiceEventsWithIdAndCustomerValue}
              averageDailyServiceBookings={averageDailyServiceBookingsValue}
              averageWeeklyServiceBookings={averageWeeklyServiceBookingsValue}
              totalCourseEventsWithTitle={totalCourseEventsWithTitleValue}
              totalCourseEventsWithId={totalCourseEventsWithIdValue}
              totalCourseEvents={totalCourseEventsValue}
              totalOnlineCourseBookingsWithTitle={totalOnlineCourseBookingsWithTitleValue}
              totalInternalCourseBookingsWithTitle={totalInternalCourseBookingsWithTitleValue}
              totalCourseBookingsWithTitle={totalCourseBookingsWithTitleValue}
              totalOnlineCourseBookingsWithId={totalOnlineCourseBookingsWithIdValue}
              totalInternalCourseBookingsWithId={totalInternalCourseBookingsWithIdValue}
              totalCourseBookingsWithId={totalCourseBookingsWithIdValue}
              totalCourseBookingGuests={totalCourseBookingGuestsValue}
              totalCourseBookingGuestsAndParticipants={totalCourseBookingGuestsAndParticipantsValue}
              averageBookingParticipantsPerCourseEvent={averageBookingParticipantsPerCourseEventValue}
              averageBookingGuestsPerCourseEvent={averageBookingGuestsPerCourseEventValue}
              totalCancelledInternalEvents={totalCancelledInternalEventsValue}
              totalCancelledOnlineEvents={totalCancelledOnlineEventsValue}
              totalCancelledEvents={totalCancelledEventsValue}
              averageWeeklyCancelledBookings={averageWeeklyCancelledBookingsValue}
              totalRescheduledEventsDaily={totalRescheduledEventsDaily}
              totalRescheduledEventsMonthly={totalRescheduledEventsMonthly}
              eventSignupsByHoursOfDayUTC={eventSignupsByHoursOfDayUTCValue}
              eventSignupsByDaysOfWeekUTC={eventSignupsByDaysOfWeekUTCValue}
              eventSignupsByDaysUTC={eventSignUpsByDaysUTC}
              eventsByDaysUTC={eventsByDaysUTCValue}
              onlineMarketingStatistics={onlineMarketingStatisticsValue}
              topBookingServices={topBookingServicesValue}
              totalResourcesDaily={totalResourcesDaily}
              totalResourcesMonthly={totalResourcesMonthly}
              sickDenialsDaysDaily={sickDenialsDaysDaily}
              sickDenialsDaysMonthly={sickDenialsDaysMonthly}
              blockerDenialsDaysDaily={blockerDenialsDaysDaily}
              blockerDenialsDaysMonthly={blockerDenialsDaysMonthly}
              vacationDenialsDaysDaily={vacationDenialsDaysDaily}
              vacationDenialsDaysMonthly={vacationDenialsDaysMonthly}
              averageServiceBookingsPerResource={averageServiceBookingsPerResourceValue}
              totalResourcesWorkingHours={totalResourcesWorkingHours}
              totalResourcesWorkingBookedHours={totalResourcesWorkingBookedHours}
              totalResourcesOnlineHours={totalResourcesOnlineHours}
              totalResourcesOnlineBookedHours={totalResourcesOnlineBookedHours}
              topBookingResourcesValue={topBookingResourcesValue} // for most booked resources chart
              totalInternalCustomers={totalInternalCustomersValue}
              totalOnlineCustomers={totalOnlineCustomersValue}
              totalCustomers={totalCustomersValue}
              totalServiceOnlinePaidRevenue={totalServiceOnlinePaidRevenueValue}
              totalServiceInStorePaidRevenue={totalServiceInStorePaidRevenueValue}
              totalServiceRevenue={totalServiceRevenueValue}
              totalCourseOnlinePaidRevenue={totalCourseOnlinePaidRevenueValue}
              totalCourseInStorePaidRevenue={totalCourseInStorePaidRevenueValue}
              totalCourseRevenue={totalCourseRevenueValue}
              totalRevenue={totalRevenueValue}
              topBookingServicesWithPrice={topBookingServicesWithPriceValue}
              totalOnlinePaidRevenue={totalOnlinePaidRevenueValue}
              topBookingBranches={topBookingBranchesValue}
              totalEvents={totalEvents}
              totalServiceEvents={totalServiceEventsValue}
              totalCourseBookings={totalCourseBookingsValue}
              totalServiceEventsWithTitle={totalServiceEventsWithTitleValue}
              totalOnlineServiceEventsWithId={totalOnlineServiceEventsWithIdValue}
              totalInternalServiceEventsWithId={totalInternalServiceEventsWithIdValue}
              totalServiceEventsWithId={totalServiceEventsWithIdValue}
            />
          : <DashboardMock usePermissionText={!statisticsRead} />
      )}
    </>
  )
}

const maps = state => ({
  dailyStatistics: ((state.dashboard && state.dashboard.dailyStatistics) && state.dashboard.dailyStatistics) || {},
  monthlyStatistics: ((state.dashboard && state.dashboard.monthlyStatistics) && state.dashboard.monthlyStatistics) || {},
  overallStatistics: ((state.dashboard && state.dashboard.overallStatistics) && state.dashboard.overallStatistics) || {},
  pending: !(state.dashboard && state.dashboard.areStatisticsFetched) || false,
  marketingConversionsValue: (state.forms && state.forms.marketingConversions && state.forms.marketingConversions.type && state.forms.marketingConversions.type.value) || '',
  dashboardFiltersForm: ((state.forms && state.forms.dashboardFilters) && state.forms.dashboardFilters) || {},
  dashboardMostBookedResourcesTypeForm: (state.forms && state.forms.dashboardMostBookedResourcesType) || {},
  signUpTimesValue: (state.forms.dashboardStatisticsSignUpTimes && state.forms.dashboardStatisticsSignUpTimes.type && state.forms.dashboardStatisticsSignUpTimes.type.value) || false,
  branches: ((state.branches && state.branches.list) || []).length,
  totalCompanyPlans: state.company.totalCompanyPlans || 0,
  locale: (state.company && state.company.locale) || 'en-us',
  countries: (state.staticData && state.staticData.countries) || [],
  lastStatisticsStateChange: state.company.lastStatisticsStateChange || '',
  account: state.account,
  messageExport: state.dashboard.messageExport,
  // for some reasons, dashboard is not rerendered when locale is changed back to default.
  // adding locale in props will force dashboard to rerender
  language: (state.app && state.app.locale) || 'en-gb'
})

export default connect(maps)(DashboardWrapper)
