import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  IconListItem,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Blur,
  t
} from '../../Common'

import './BillingPreview.css'

const BillingMock = props => {
  return (
    <div className='ta-billing__preview'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-billing__title'>
            <Title label={t('global.billing')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur label={t('global.accessDenied')} icon='ban' active>
            <SimpleTabs active='billing'>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='billing' label={t('billing.list.section.billingDetails.heading')} />
                <SimpleTabsHeaderItem name='payments' icon='credit-card' label={t('billing.list.section.paymentDetails.heading')} />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='billing'>
                  <>
                    <IconListItem>
                      <strong>{t('global.name')}:</strong> John Doe
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('address.label')} 1:</strong> Lorem Ipsum est dolar sit ammet
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('address.city')}:</strong> Berlin
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('address.zip')}:</strong> 22222
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('address.label')} 1:</strong> Lorem Ipsum est dolar sit ammet
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('global.vatNumber')}:</strong> 123123123
                    </IconListItem>
                  </>
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BillingMock
