import React from 'react'
import {
  Button,
  IconListItem,
  Address,
  format,
  t
} from '../../../Common'

const CustomerPreviewDetailsField = props => {
  const { item, timezones, showIcon } = props
  const classNames = ['ta-customers-preview__item']
  let icon = null
  let selectLabel = ''
  if (item.type === 'FILE') icon = 'sliders-h'
  if (item.type === 'PHONE') icon = 'phone'
  if (item.type === 'ADDRESS') {
    icon = 'map'
    classNames.push('address')
  }
  if (item.type === 'EMAIL') icon = 'envelope'
  if (item.type === 'DATE') icon = 'calendar'
  if (item.type === 'TEXT') icon = 'sliders-h'
  if (item.type === 'TEXTAREA') icon = 'sliders-h'
  if (item.type === 'SELECT') {
    icon = 'sliders-h'
    selectLabel = item.selectOptions.find(({ value }) => value === item.value)?.label
  }
  if (item.type === 'CHECKBOX') icon = item.value + '' === 'true' ? 'check-square' : 'square'
  if (item.defaultId === 'birthday') icon = 'birthday-cake'
  if (item.defaultId === 'mobilePhone') icon = 'mobile'
  const filterByDefaultId = ['salutation', 'title']

  return (
    <IconListItem className={classNames.join(' ')} icon={showIcon && icon} key={item.id}>
      <strong>{item.label || t(item.translationKey)}: </strong>
      {(item.type === 'EMAIL' &&
        <Button as='link' className='ta-customers-email' to={`mailto:${item.value}`} target='_top' external>{item.value}</Button>
      )}
      {(item.type === 'ADDRESS' && item.value &&
        <Address
          address={item.value.formatted}
          details={item.value.details}
          lat={item.value.latitude}
          lng={item.value.longitude}
        />
      )}
      {item.type === 'FILE' && 'TODO'}
      {item.type === 'PHONE' && item.value.phone}
      {item.type === 'DATE' && format(item.value, 'long', { isUTC: true })}
      {item.type === 'TEXT' && item.value}
      {item.type === 'TEXTAREA' && item.value}
      {item.type === 'SELECT' && !filterByDefaultId.includes(item.defaultId) && selectLabel}
      {item.type === 'SELECT' && item.defaultId === 'title' && t(`global.title.${item.value}`)}
      {item.type === 'SELECT' && item.defaultId === 'salutation' && (item.value === 'mrs' ? 'Ms.' : 'Mr.')}
      {item.type === 'CHECKBOX' && (item.value + '' === 'true' ? t('global.yes') : t('global.no'))}
      {item.type === 'TIMEZONE' && (timezones.find(timezone => timezone.code === item.value) || {}).label}
    </IconListItem>
  )
}

export default CustomerPreviewDetailsField
