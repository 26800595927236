import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  FontAwesome5,
  t
} from '../../Common'

const BillingPreviewMethodCreditCard = props => {
  const { data, isDefault } = props
  const {
    cardType,
    // cardholderName,
    expirationDate,
    maskedNumber
  } = data || {}

  return (
    <SimpleListItem status={isDefault ? 'active' : ''} className='payment-method'>
      <SimpleListItemTitle title={maskedNumber}>
        {(isDefault &&
          <span className='ta-billing__preview__method-primary'>{t('billing.list.section.paymentDetails.tabPrimary.label')}</span>
        )}
      </SimpleListItemTitle>
      {/* {(cardholderName &&
        <SimpleListItemContent>
          <strong>Translate -> Card Holder:</strong> {cardholderName}
        </SimpleListItemContent>
      )} */}
      {(expirationDate &&
        <SimpleListItemContent>
          <strong>{t('billing.form.paymentMethod.section.creditCard.expirationDate.title')}:</strong> {expirationDate}
        </SimpleListItemContent>
      )}
      <FontAwesome5 icon={cardType && `cc-${cardType.toLowerCase()}`} type='brand' className='ta-billing__preview__method-icon' />
    </SimpleListItem>
  )
}

export default BillingPreviewMethodCreditCard
