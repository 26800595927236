import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { PermissionTypeAdd } from '../../Beauties'

const PermissionTypeAddWrapper = props => {
  let {
    pending,
    form,
    showExternalIds,
    companyCustomisations,
    isAdmin,
    branchesAccessBlacklist
  } = props
  form = form || {}
  showExternalIds = !!showExternalIds
  const customisations = companyCustomisations || {}
  const { settings } = customisations || {}
  const { readOnlyExternalIds } = settings || {}

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.permissionTypeFormGet()
  }, [])

  const onSubmit = cb => {
    handlers.permissionTypeFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <PermissionTypeAdd
          onSubmit={onSubmit}
          cancelLink='/management/permission-types'
          title={t('permissionTypes.add.headingAddPermissionType')}
          readOnlyExternalIds={readOnlyExternalIds}
          showExternalIds={showExternalIds}
          isAdmin={isAdmin}
          branchesAccessBlacklist={branchesAccessBlacklist}
          location={form.location && form.location.values}
        />
      )}
    </>
  )
}

const maps = state => ({
  pending: state.permissionTypes.pendingForm,
  form: state.forms.permissionType,
  showExternalIds: state.company.settings && state.company.settings.showExternalIds,
  companyCustomisations: state.company.customisations,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default memo(connect(maps)(PermissionTypeAddWrapper))
