import React from 'react'
import {
  Checkbox,
  BorderedBox,
  Row,
  Col,
  t
} from '../../../Common'
import { WeekDays } from '../../../Beauties'

const ServiceFormAllowancePlan = props => {
  const { hasSpecificBookingDays } = props
  const allowancePlanClassNames = ['ta-services__form__times__plan']

  return (
    <div className='ta-services__form__times'>
      <Row>
        <Col>
          <Checkbox
            name='hasSpecificBookingDays'
            label={t('servicesGroups.form.section.settings.checkboxSpecificBookingDays.label')}
            theme='switch'
          />
        </Col>
      </Row>
      {(hasSpecificBookingDays &&
        <Row>
          <Col>
            <BorderedBox className={allowancePlanClassNames.join(' ')}>
              <WeekDays prefix='bookingWeeklyAllowancePlan' intervalGapMessage={t('weekdays.interval.gap')} />
            </BorderedBox>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ServiceFormAllowancePlan
