import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { CustomerFieldPreview } from '../../Beauties'

const CustomerFieldPreviewWrapper = props => {
  let {
    id,
    customerField,
    pending,
    message,
    hash,
    enterpriseId,
    account,
    allowOnlyGlobalCustomers
  } = props
  customerField = customerField || {}
  pending = !!pending
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalDataFieldsRead, globalDataFieldsWrite, globalDataFieldsDelete } = enterprisePermissions || {}
  globalDataFieldsRead = !!isAdmin || !!globalDataFieldsRead
  globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite
  globalDataFieldsDelete = !!isAdmin || !!globalDataFieldsDelete
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
  useEffect(() => {
    handlers.customerFieldPreviewGet({ id })
  }, [id])

  return (
    pending
      ? <Loader active />
      : (
        <CustomerFieldPreview
          customerField={customerField}
          hash={hash}
          message={message}
          enterpriseId={enterpriseId}
          customerFieldsRead={globalDataFieldsRead}
          customerFieldsWrite={globalDataFieldsWrite}
          customerFieldsDelete={globalDataFieldsDelete}
          allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        />
        )
  )
}

CustomerFieldPreviewWrapper.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object,
  hash: PropTypes.string,
  customerField: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: (state.router.data && state.router.data.id),
  hash: state.router.hash,
  customerField: (state.router.data.id && state.customerFields.list.find(item => item.id === state.router.data.id)) || {},
  pending: state.customerFields.pendingPreview,
  message: state.customerFields.messagePreview,
  enterpriseId: state.account.enterprise.id,
  account: state.account,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default connect(maps)(CustomerFieldPreviewWrapper)
