import React from 'react'
import PropTypes from 'prop-types'
import { Button, FontAwesome5, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupResourceUnlink = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__account-unlink'>
      <div className='ta-popup__title'>
        <FontAwesome5 icon='unlink' type='solid' />{t('popup.account.unlink.label')}
      </div>
      <div className='ta-popup__text'>
        {dangerousHTML(t('popup.resource.unlink.message'))}
      </div>
      <div className='ta-popup__buttons'>
        <Button as='link' isSecondary onClick={reject}>{t('global.no')}</Button>
        <Button as='link' isPrimary onClick={approve}>{t('popup.resource.unlink.confirm')}</Button>
      </div>
    </div>
  )
}

PopupResourceUnlink.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupResourceUnlink
