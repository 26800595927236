import React, { useState, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, globalActions } from '../../Store'
import { Courses, CoursesMock } from '../../Beauties'
import { Loader } from '../../Common'
import { sortByOrderIndex } from '../../Utils'

const CoursesListWrapper = props => {
  let {
    categories,
    pending,
    hash,
    route,
    account,
    filters,
    courses
  } = props
  categories = categories || []
  pending = !!pending
  account = account || {}
  filters = filters || {}
  courses = courses || []
  const [areExpanded, setAreExpanded] = useState(true)
  useEffect(() => {
    globalActions.populateCoursesAndCourseCategories()
    globalActions.populateEnterpriseResourceCategories()
    handlers.coursesToDeleteGet()
  }, [])
  // const onDragStart = res => {}
  // const onDragUpdate = res => {}
  const onCategoriesToggle = () => {
    setAreExpanded(!areExpanded)
  }
  const onDragEnd = ({ type, id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
    if (type === 'category') {
      handlers.courseCategoryOrderChange({ id, oldIndex, newIndex })
      return
    }

    handlers.coursesListOrderChange({
      id,
      oldIndex,
      newIndex,
      sourceCategoryId,
      destinationCategoryId
    })
  }

  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsRead, globalGroupsWrite } = enterprisePermissions || {}
  globalGroupsRead = !!isAdmin || !!globalGroupsRead
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite
  const isDragAndDropMode = !!(route === 'coursesReorder')
  const { search } = filters || {}
  const { value: searchValue } = search || {}
  const hasFilteredCourses = searchValue && !courses.find(course => course.name.toLowerCase().includes(searchValue.toLowerCase()) || course.id === searchValue)
  const localCategories = categories.filter(item => !item.internalId)
  const globalCategories = categories.filter(item => item.internalId && !item.isDefault)
  const defaultCategory = categories.find(item => item.isDefault)
  const sortedCategories = [defaultCategory, ...localCategories, ...globalCategories].filter(Boolean)

  return (
    globalGroupsRead
      ? pending
        ? <Loader active />
        : (
          <Courses
            categories={sortedCategories}
            hash={hash}
            isDragAndDropMode={isDragAndDropMode}
            // onDragStart={onDragStart}
            // onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
            areExpanded={areExpanded}
            onCategoriesToggle={onCategoriesToggle}
            globalGroupsWrite={globalGroupsWrite}
            hasFilteredCourses={hasFilteredCourses}
          />
          )
      : <CoursesMock />
  )
}

CoursesListWrapper.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  route: state.router.name,
  hash: state.router.hash,
  categories: (state.courses.categoriesList || []).sort(sortByOrderIndex),
  hasCourses: !!(state.courses && state.courses.list && state.courses.list.length > 0),
  pending: state.courses.pendingList,
  account: state.account,
  courses: (state.courses.list || []).sort(sortByOrderIndex),
  filters: state.forms.coursesFilters
})

export default memo(connect(maps)(CoursesListWrapper))
