import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { CompanyTagPreview } from '../../Beauties'

const CompanyTagPreviewWrapper = props => {
  let {
    id,
    tag,
    hash,
    account,
    pending,
    message,
    enterpriseId,
    enterpriseSettings,
    allowOnlyGlobalCustomers
  } = props
  tag = tag || {}
  message = message || null
  pending = !!pending
  account = account || {}
  enterpriseSettings = enterpriseSettings || {}
  const { enforceReschedulingTagRule } = enterpriseSettings || {}
  const { internalId } = tag || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalTagsRead, globalTagsWrite, globalTagsDelete } = enterprisePermissions || {}
  globalTagsRead = !!isAdmin || !!globalTagsRead
  globalTagsWrite = !!isAdmin || !!globalTagsWrite
  globalTagsDelete = !!isAdmin || !!globalTagsDelete
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.companyTagPreviewGet(id)
  }, [id])

  useEffect(() => {
    if (internalId) handlers.companyTagLocallyChangedGet({ id, internalId })
  }, [id, internalId])

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CompanyTagPreview
          tag={tag}
          hash={hash}
          message={message}
          enterpriseId={enterpriseId}
          enforceReschedulingTagRule={enforceReschedulingTagRule}
          globalTagsRead={globalTagsRead}
          globalTagsWrite={globalTagsWrite}
          globalTagsDelete={globalTagsDelete}
          allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        />
        )}
    </>
  )
}

CompanyTagPreviewWrapper.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object,
  hash: PropTypes.string,
  tag: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = (state, props) => ({
  id: state.router.data.id,
  message: state.resources.messagePreview,
  hash: state.router.hash,
  tag: (state.companyTags.selected && state.companyTags.list.find(r => r.id === state.companyTags.selected)) || {},
  pending: state.companyTags.pendingPreview,
  enterpriseId: (state.account && state.account.enterprise && state.account.enterprise.id),
  enterpriseSettings: state.company.settings,
  account: state.account,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default memo(connect(maps)(CompanyTagPreviewWrapper))
