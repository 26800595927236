import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { DnDContainer } from '../../Common'

const DnDDraggable = props => {
  const {
    children,
    className,
    id,
    index,
    isDragDisabled
  } = props

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <DnDContainer
          className={className}
          snapshot={snapshot}
          provided={provided}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </DnDContainer>
      )}
    </Draggable>
  )
}

export default DnDDraggable
