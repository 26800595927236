import React from 'react'
import {
  Form,
  Input,
  CopyToClipboard,
  Button,
  t,
  Alert,
  Row,
  Col,
  FormSection
} from '../../Common'
import { dangerousHTML } from '../../Utils'

const AppsPrivateApps = props => {
  const {
    privateAccessKey,
    generatePrivateAccessKey,
    onClickCopy,
    messageAccessKey
  } = props

  return (
    <Alert label={t('apps.list.tabPrivate.note.title')} theme='alert'>
      <Row>
        <Col>
          <p>{dangerousHTML(t('apps.list.tabPrivate.note.text'))}</p>
        </Col>
      </Row>
      {(!privateAccessKey &&
        <Button as='link' isPrimary isBlock onClick={generatePrivateAccessKey} external>
          {t('apps.list.tabPrivate.note.buttonGenerate.label')}
        </Button>
      )}
      {(privateAccessKey &&
        <FormSection ignoreBottomOffset={!messageAccessKey}>
          <Form name='privateApp'>
            <CopyToClipboard
              text={privateAccessKey}
              textButton={t('global.copy')}
              onClickAddon={onClickCopy}
            >
              <Input name='privateAccessKey' label={t('global.privateAccessKey')} disabled /> {/* Private access key */}
            </CopyToClipboard>
          </Form>
        </FormSection>
      )}
      {(messageAccessKey &&
        <Alert theme={messageAccessKey.type} label={t('errors.somethingWentWrong')}>
          {messageAccessKey.text && t(messageAccessKey.text)}
        </Alert>
      )}
    </Alert>
  )
}

export default AppsPrivateApps
