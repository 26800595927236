import { handlers, store } from '../..'

export const companyTagsMoved = tags => {
  handlers.companyTagsListOrderChanged(tags)
}

export const companyTagUpdated = tag => {
  if (!tag) return
  const { companyTags = {} } = store.getState()
  const { ts } = companyTags.list.find(item => item.id === tag.id) || {}
  if (ts === tag.ts) return
  handlers.companyTagUpdate(tag)
}

export const companyTagDeleted = id => {
  if (!id) return
  const { tags = {}, router } = store.getState()
  const { name: routeName } = router || {}
  const tag = tags.list.find(item => item.id === id) || {}
  if (!tag || tag.isDeleted) return
  if (routeName === 'tagPreview') handlers.navigateToPath('/customers/company-tags')
  handlers.companyTagDeleted(id)
  setTimeout(() => handlers.companyTagRemoveDeleted(id), 2000)
}
