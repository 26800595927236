import React, { useCallback, useEffect, useRef } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { AppsListActionsPopup } from '../../../Beauties'

const AppsListActionsPopupWrapper = props => {
  let {
    chargebeeId,
    closePopup,
    isAdmin,
    active,
    app,
    id
  } = props
  app = app || {}
  const { appInstallation, hasPayment } = app || {}
  const {
    subscribedAt,
    unsubscribedAt,
    hasTrial,
    isTrial,
    renewedAt,
    isBlocked,
    blockedReasonCode,
    isPaidByEnterprise,
    limitReached
  } = appInstallation || {}
  const isInvoiceShown = !!chargebeeId
  const isSubscriptionAddShown = hasPayment && (!isPaidByEnterprise || (hasTrial && isTrial) || (!subscribedAt && !renewedAt && ((isBlocked && ['SUBSCRIBE'].includes(blockedReasonCode)) || limitReached)))
  const isSubscriptionViewShown = hasPayment && subscribedAt && !unsubscribedAt
  const isUninstallShown = true
  const popupRef = useRef()

  const handleOutsideClick = useCallback((e) => {
    const popupClicked = popupRef && popupRef.current && popupRef.current.contains(e.target)
    const triggerClicked =
      [...e.target.classList].includes('fa-cog') ||
      ([...e.target.children].length > 0 && [...e.target.children[0].classList].includes('fa-cog'))
    if (!popupClicked && active && !triggerClicked) closePopup()
  }, [active, closePopup])

  // attach/detach document click handlers
  useEffect(() => {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mousedown', handleOutsideClick)
    } else {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      if (!document.removeEventListener && document.detachEvent) {
        document.detachEvent('mousedown', handleOutsideClick)
      } else {
        document.removeEventListener('mousedown', handleOutsideClick)
      }
    }
  }, [handleOutsideClick])

  const onClickInvoices = () => {
    handlers.navigateToRoute('accountInvoices')
    closePopup()
  }

  const onClickSubscriptionAdd = () => {
    if (!isAdmin) return
    handlers.navigateToRoute('appsSubscriptionAdd', { id })
    closePopup()
  }

  const onClickSubscriptionView = () => {
    if (!isAdmin) return
    handlers.navigateToRoute('appsSubscription', { id })
    closePopup()
  }

  const onClickUninstall = () => {
    if (!isAdmin) return
    handlers.navigateToRoute('appsUninstall', { id })
    closePopup()
  }

  return (
    <AppsListActionsPopup
      ref={popupRef}
      active={active}
      isInvoiceShown={isInvoiceShown}
      isSubscriptionAddShown={isSubscriptionAddShown}
      isSubscriptionViewShown={isSubscriptionViewShown}
      isUninstallShown={isUninstallShown}
      onClickInvoices={onClickInvoices}
      onClickSubscriptionAdd={onClickSubscriptionAdd}
      onClickSubscriptionView={onClickSubscriptionView}
      onClickUninstall={onClickUninstall}
      isAdmin={isAdmin}
      isPaidByEnterprise={isPaidByEnterprise}
    />
  )
}

const maps = (state, props) => ({
  app: selectors.appsFindByIdSelector(state, { id: props.id }),
  chargebeeId: selectors.companyFieldSelector(state, { field: 'chargebeeId' }),
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' })
})

export default connect(maps)(AppsListActionsPopupWrapper)
