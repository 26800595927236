import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { AVAILABLE_SERVICE_HEXCOLORS } from '../../../Settings'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FontAwesome5, Error } from '../../../Common'

import './ColorSelect.css'

class ColorSelect extends Component {
  constructor(props, context) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.state = { open: false }
  }

  componentDidMount() {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.addEventListener('mouseup', this.handleOutsideClick)
    }
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount() {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.removeEventListener('mouseup', this.handleOutsideClick)
    }
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOutsideClick(e) {
    if (this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  toggle() {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  onChange(color) {
    const { name, form } = this.props
    this.setState({ open: false })
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value: color } })
  }

  render() {
    const { open } = this.state

    const {
      name,
      pattern = '',
      colors = AVAILABLE_SERVICE_HEXCOLORS,
      value,
      children,
      form = {},
      hideError,
      hide
    } = this.props

    const optionsClasses = ['ta-color-select__options']
    const selectedOptionColorClasses = ['ta-color-select__options__color']
    const selectedClassNames = ['ta-color-select__selected']

    if (open) {
      optionsClasses.push('active')
      selectedClassNames.push('active')
    }
    if (pattern !== '') {
      optionsClasses.push(pattern)
      selectedOptionColorClasses.push(pattern)
    }
    if (value || form.value) selectedClassNames.push('hasValue')

    return (
      <div ref={wrapper => { this.wrapper = wrapper?.getElementsByClassName('ta-color-select__options')?.[0] }}>
        <div className='ta-color-select'>
          {children}
          {!hide &&
            <>
              <div className={selectedClassNames.join(' ')} onClick={this.toggle}>
                <div className={selectedOptionColorClasses.join(' ')} style={{ backgroundColor: value || form.value }} />
                <FontAwesome5 icon='sort' type='solid' />
              </div>
              <div className={optionsClasses.join(' ')}>
                {colors.map(color => {
                  const colorClassNames = [...selectedOptionColorClasses]
                  if (color === (value || form.value)) colorClassNames.push('ta-color-select__options__color__active')

                  return (
                    <div
                      key={color}
                      className={colorClassNames.join(' ')}
                      onClick={() => this.onChange(color)}
                      style={{
                        backgroundColor: color,
                        boxShadow: `0 0 0 1px ${color}`
                      }}
                    />
                  )
                })}
              </div>
            </>
          }
        </div>
        {!hideError &&
          <Error noOffset name={name} />
        }
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: state.forms[props.formName][props.name] || { value: '' }
})

export default feedContextInProps(connect(maps)(ColorSelect), FormContext)
