import React from 'react'
import {
  FormSection,
  Title,
  PriceInput,
  Checkbox,
  FormText,
  Row,
  Col,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'

const BranchesServiceCombinationFormPrice = props => {
  const { hasPriceOverwrite, allowedSet } = props
  const isIsCombinationPriceOverwrittenDisabled = !allowedSet?.includes('price')
  const isPriceDisabled = !allowedSet?.includes('price')

  return (
    <FormSection>
      <Title size='m' label={t('global.price.label')} icon='money-bill' />
      <FormText>
        {t('servicesGroups.form.section.serviceCombinationPrice.description')}
      </FormText>
      <Row>
        <Col>
          <HoverPopup disabled={!isIsCombinationPriceOverwrittenDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                disabled={isIsCombinationPriceOverwrittenDisabled}
                name='hasPriceOverwrite'
                label={t('servicesGroups.form.section.serviceCombinationPrice.overwrite')}
                theme='switch'
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      {(hasPriceOverwrite &&
        <HoverPopup disabled={!isPriceDisabled} className='ta-service-form-inputs__popup'>
          <HoverPopupContent position='top'>
            {t('globalSettings.form.section.attributes.disabled')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <PriceInput
              name='price'
              disabled={isPriceDisabled}
              label={t('global.price.label')}
              hintText={t('global.price.hint')}
            />
          </HoverPopupTrigger>
        </HoverPopup>
      )}
    </FormSection>
  )
}

export default BranchesServiceCombinationFormPrice
