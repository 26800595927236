export default ({ list, id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  if (!list) return []

  let modifiedList = [...list]
  const sourceCategoryServices = list.filter(s => s.categoryId === sourceCategoryId)
  const draggedItem = { ...sourceCategoryServices[oldIndex], categoryId: destinationCategoryId }
  const targetCategoryServices = list.filter(s => s.categoryId === destinationCategoryId && s.id !== draggedItem.id)
  findMinMoves(
    targetCategoryServices,
    draggedItem,
    (targetCategoryServices[newIndex] || { orderIndex: Infinity }).orderIndex
  ).map(changedItem => {
    const listKey = modifiedList.findIndex(listItem => listItem.id === changedItem.id)
    modifiedList[listKey] = changedItem
    return changedItem
  })

  return modifiedList.sort(sortByOrderIndex)
}

export const findMinMoves = (records, draggedRecord, orderIndex, minIndex) => {
  if (!minIndex || minIndex < 0) minIndex = 1
  // this is min orderIndex possible
  if (!orderIndex || orderIndex < minIndex) orderIndex = minIndex

  // calculate last maximum index
  let max = Math.max(minIndex - 1, ...records.map(c => c.orderIndex).filter(o => o < orderIndex))

  // start from here
  orderIndex = max + 1
  // this will be after my orderIndex
  const moves = records
    // filter out the forbidden to move, itself and those less then desired index
    .filter(c => c.orderIndex >= orderIndex)
    .sort(sortByOrderIndex)
  let localMax = orderIndex
  const result = moves.reduce((acc, c) => {
    if (c.orderIndex > localMax) localMax = c.orderIndex
    else localMax += 1
    return [...acc, {
      ...c,
      id: c.id,
      old: c.orderIndex,
      orderIndex: localMax
    }]
  }, [{
    ...draggedRecord, orderIndex
  }]).filter(c => c.orderIndex !== c.old)
  return result
}

export const sortByOrderIndex = (a, b) => a.orderIndex < b.orderIndex ? -1 : (a.orderIndex === b.orderIndex ? 0 : 1)
