export default (data) => {
  const metaData = JSON.parse(data.metadata || '{}')
  const {
    'x-amz-meta-fileextension': extension,
    'x-amz-meta-filename': name
  } = metaData || {}
  const link = 'data:' + data.contentType + ';' + data.contentEncoding + ',' + data.content
  const downloadLink = document.createElement('a')
  downloadLink.href = link
  downloadLink.download = `${name || 'download'}${extension}`
  downloadLink.click()
}
