import React from 'react'
import { CoursePreviewChangedLocally } from '../../Beauties'
import { connect } from '../../Store'

const CoursePreviewChangedLocallyWrapper = props => {
  let { course, account, branches } = props
  course = course || {}
  account = account || {}
  let { locallyUpdatedBranches } = course || {}
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  locallyUpdatedBranches = locallyUpdatedBranches || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsWrite, globalGroupsRead } = enterprisePermissions || {}
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite
  globalGroupsRead = !!isAdmin || !!globalGroupsRead
  const locallyChangedData =
    Object.keys(locallyUpdatedBranches)
      .filter(id => !Object.keys(locallyUpdatedBranches[id]).includes('locallyUpdatedServiceIds'))
      .map(id => ({
        branch: branches.find(branch => branch.id === id),
        id: course.id,
        localUpdates: locallyUpdatedBranches[id]
      }))

  return (
    <CoursePreviewChangedLocally
      locallyChangedData={locallyChangedData}
      globalGroupsWrite={globalGroupsWrite}
      globalGroupsRead={globalGroupsRead}
    />
  )
}

const maps = state => ({
  course: (state.router.data.id && state.courses.list.find(item => item.id === state.router.data.id)) || {},
  courses: state.courses.list,
  branches: state.branches.list,
  account: state.account
})

export default connect(maps)(CoursePreviewChangedLocallyWrapper)
