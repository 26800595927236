import React from 'react'
import ReactMarkdown from 'react-markdown'

import './Markdown.css'

const Markdown = props => {
  const { text, className } = props
  const classNames = ['ta-markdown']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      <ReactMarkdown source={text} />
    </div>
  )
}

export default Markdown
