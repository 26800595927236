import { map, distinctUntilChanged } from 'rxjs/operators'
import { payloads$, actions, handlers, store, globalActions, store$ } from '../../../Store'
import { q } from '../../API'
import {
  serviceTransform,
  serviceFormValidate,
  serviceSaveTransform,
  serviceFormServerErrorsTransform,
  serviceCombinationFormServerErrorsTransform,
  serviceCombinationSaveTransform,
  serviceCombinationFormValidate,
  serviceCategoryFormValidate,
  serviceCategorySaveTransform,
  serviceCategoryFormServerErrorsTransform,
  servicePreviewErrorTransform,
  serviceLocallyChangedResetServerErrorsTransform
} from './utils'
import { serverErrorsTransform } from '../../../Utils'
import { t } from '../../../Common'
import {
  servicesMoved,
  serviceUpdated,
  serviceUpdatedCollect,
  // serviceCategoryUpdatedId,
  serviceDeleted,
  serviceCategoriesMoved,
  serviceCategoryUpdated,
  serviceCategoryDeleted
} from './subscriptions'

// Global actions
globalActions.populateServicesAndServiceCategories = async () => {
  const { areFetched = false } = store.getState().services || {}
  if (areFetched) return
  const result = await q('getEnterpriseServicesAndServiceCategories')
  const { error } = result || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    return result
  }
  handlers.servicesListPopulate({
    services: result.getEnterpriseServices || [],
    serviceCategories: result.getEnterpriseServiceCategories || []
  })
  return result
}

globalActions.transformService = service => {
  return serviceTransform(service)
}

// Subscription
payloads$(actions.SERVICES_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'servicesMoved' && data) servicesMoved(data)
    if (name === 'serviceUpdated' && id) serviceUpdatedCollect(id)
    if (name === 'serviceDeleted' && id) serviceDeleted(id)
    if (name === 'serviceCategoriesMoved' && data) serviceCategoriesMoved(data)
    if (name === 'serviceCategoryUpdated') {
      const serviceCategory = (await q('getServiceCategory', { id })) || {}
      if (!serviceCategory.id || serviceCategory.error) return
      serviceCategoryUpdated(serviceCategory)
    }
    if (name === 'serviceCategoryDeleted' && id) serviceCategoryDeleted(id)
  })

payloads$(actions.SERVICES_UPDATED)
  .subscribe(async (ids) => {
    if (!ids.length) return
    const services = (await q('getServices', { filter: { ids } })) || []
    services.forEach(service => serviceUpdated(service))
  })

// Preview
payloads$(actions.SERVICE_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/management/services')
      return
    }
    const state = store.getState()
    const { list = [] } = state.services
    await globalActions.populateBranches()
    const selectedService = list.find(service => service.id === id) || {}
    const { isComplete } = selectedService || {}
    await globalActions.populateEnterpriseResourceCategories()
    if (isComplete && !forceFetch) {
      handlers.servicePreviewPopulate(id)
      const { internalId } = selectedService || {}
      handlers.serviceLocallyChangedGet({ id, internalId })
      return
    }
    const service = (await q('getEnterpriseService', { id })) || { error: { text: 'errors.api.unavailable' } }
    const { internalId } = service || {}
    handlers.serviceLocallyChangedGet({ id, internalId })
    const { error } = service
    if (error) {
      handlers.servicePreviewMessageSet(servicePreviewErrorTransform(error))
      return
    }
    handlers.serviceUpdate({ ...service, isComplete: true })
    handlers.servicePreviewPopulate(id)
  })

// Form
payloads$(actions.SERVICE_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateEnterpriseResourceCategories()
    const state = store.getState()
    let { company, account } = state
    company = company || {}
    let { settings: companySettings } = company || {}
    companySettings = companySettings || {}
    let { query } = state.router
    query = query || {}
    let { list: branches } = state.branches || {}
    branches = branches || []
    let { list, categoriesList } = state.services || {}
    list = list || []
    categoriesList = categoriesList || []
    let { list: availableResources, categoriesList: availableCategories } = state.resources || {}
    availableResources = availableResources || []
    availableCategories = availableCategories || []
    const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || null
    const defaultCategory = categoriesList.find(category => category.isDefault)
    let category = queryCategory || defaultCategory
    if (!category) return handlers.navigateToPath('/management/services')
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    if (!id) {
      handlers.serviceFormPopulate({
        category,
        availableResources,
        availableCategories,
        branches,
        companySettings,
        account
      })
      return
    }
    const selectedService = list.find(service => service.id === id) || {}
    const serviceCategory = categoriesList.find(category => category.id === selectedService.categoryId) || null
    const { isComplete } = selectedService || {}
    const service = isComplete
      ? selectedService
      : serviceTransform(await q('getEnterpriseService', { id }))
    const { error } = service || {}
    if (error) {
      handlers.navigateToPath(id ? `/management/services/${id}` : '/management/services')
      return
    }
    category = serviceCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    handlers.serviceUpdate({ ...service, isComplete: true })
    handlers.serviceFormPopulate({
      ...service,
      category,
      availableResources: state.resources.list,
      availableCategories: state.resources.categoriesList,
      branches,
      companySettings,
      account
    })
  })

// Save
payloads$(actions.SERVICE_FORM_SAVE)
  .subscribe(async ({ service, scrollToError }) => {
    const state = store.getState()
    const { company, branches, staticData, router, services } = state
    let { list: servicesList } = services || {}
    servicesList = servicesList || []
    const serviceExternalIds = servicesList.filter(serviceItem => serviceItem.id !== service.id).map(serviceItem => serviceItem.externalId).filter(Boolean)
    const { hash } = router || {}
    const { list: branchesList } = branches || {}
    let { countries } = staticData || {}
    let { locale, settings } = company || {}
    locale = locale || 'en-gb'
    countries = countries || []
    const {
      resourceRemindersEmailRecipients: settingsResourceRemindersEmailRecipients,
      customerEmailRemindersMinutes: settingsCustomerEmailRemindersMinutes
    } = settings || {}
    service.settingsResourceRemindersEmailRecipients = settingsResourceRemindersEmailRecipients
    service.settingsCustomerEmailRemindersMinutes = settingsCustomerEmailRemindersMinutes
    const countryCode = locale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    const errors = serviceFormValidate(service, serviceExternalIds, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode)
    if (errors.length) return setServiceFormSaveErrors(errors, scrollToError)
    const savedService = await q('saveEnterpriseService', serviceSaveTransform(service))
    const { error, id } = savedService || {}
    const translations = { resource: t('global.resourceCategory'), service: t('global.service') }
    if (error) return setServiceFormSaveErrors(serviceFormServerErrorsTransform({ error, branchesList, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode, translations }), scrollToError)
    handlers.serviceUpdate({ ...savedService, isComplete: true })
    handlers.navigateToPath(`/management/services/${id}@@${hash}`)
  })

const setServiceFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('services', errors)
  scrollToError && scrollToError(errors)
  handlers.serviceFormReady()
}

// Update
payloads$(actions.SERVICE_UPDATE)
  .subscribe(async service => {
    if (!service) return handlers.servicePreviewPopulate()
    setTimeout(() => handlers.serviceUpdated(service), 2000)
  })

// Delete
payloads$(actions.SERVICE_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.servicePreviewPopulate()
    const { error } = await q('deleteEnterpriseService', { id })
    if (error) return
    handlers.serviceDeleted(id)
    setTimeout(() => handlers.serviceRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/management/services'), 0)
  })

// Reorder
payloads$(actions.SERVICES_LIST_ORDER_CHANGE)
  .subscribe(async ({ id }) => {
    // TODO: DOES NOT WORK with this endpoint!
    const { orderIndex, categoryId } = store.getState().services.list.find(service => service.id === id)
    const { error } = await q('moveService', { id, orderIndex, categoryId })
    if (error) console.warn('Drag n drop service error!')
  })

// Category form
payloads$(actions.SERVICE_CATEGORY_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateServicesAndServiceCategories()
    const { services, branches, account } = store.getState()
    let { categoriesList } = services || {}
    categoriesList = categoriesList || []
    if (categoriesList.length === 0) return handlers.navigateToPath('/management/services')
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    if (branchesList.length === 0) branchesList = await globalActions.populateBranches()
    const selectedCategory = categoriesList.find(category => category.id === id) || {}
    handlers.serviceCategoryFormPopulate({
      category: {
        ...selectedCategory,
        defaultCategoryName: t('servicesGroups.list.service.defaultCategoryName')
      },
      branches: branchesList,
      account
    })
  })

// Category save
payloads$(actions.SERVICE_CATEGORY_FORM_SAVE)
  .subscribe(async ({ category, scrollToError }) => {
    const state = store.getState()
    let { services } = state
    services = services || {}
    let { categoriesList } = services || {}
    categoriesList = categoriesList || []
    const categoryId = category.id && category.id.value
    const categoryExternalIds = categoriesList.filter(categoryItem => categoryItem.id !== categoryId).map(categoryItem => categoryItem.externalId).filter(Boolean)
    const errors = serviceCategoryFormValidate(category, categoryExternalIds)
    if (errors.length) return setServiceCategoryErrors(errors, scrollToError)
    const savedCategory = await q('saveEnterpriseServiceCategory', serviceCategorySaveTransform(category))
    const { error } = savedCategory
    if (error) return setServiceCategoryErrors(serviceCategoryFormServerErrorsTransform(error), scrollToError)
    handlers.serviceCategoryUpdate(savedCategory)
    handlers.navigateToPath('/management/services')
  })

const setServiceCategoryErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('serviceCategory', errors)
  handlers.serviceCategoryFormReady()
  scrollToError && scrollToError(errors)
}

// Category delete
payloads$(actions.SERVICE_CATEGORY_DELETE)
  .subscribe(async id => {
    // const { id } = category || {}
    if (!id) return
    const response = await q('deleteEnterpriseServiceCategory', { id })
    // const response = await q('deleteServiceCategory', serviceCategoryDeleteTransform(category))
    const { error } = response || { error: { text: 'errors.api.unavailable' } }
    if (error) return setCategoryErrors(id, serverErrorsTransform(error))
    handlers.serviceCategoryDeleted(id)
    setTimeout(() => handlers.serviceCategoryRemoveDeleted(id), 2000)
  })

const setCategoryErrors = (id, errors, scrollToError) => {
  handlers.formErrorsSet(`serviceCategory${id}`, errors)
  handlers.serviceCategoryDeleteFailed()
  scrollToError && scrollToError(errors)
  setTimeout(() => {
    handlers.formErrorsSet(`serviceCategory${id}`, [])
  }, 3500)
}

// Categories reorder
payloads$(actions.SERVICE_CATEGORIES_LIST_ORDER_CHANGE)
  .subscribe(async ({ id }) => {
    const { orderIndex } = store.getState().services.categoriesList.find(category => category.id === id)
    const { error } = await q('moveServiceCategory', { id, orderIndex })
    if (error) console.warn('Drag n drop service category error!')
  })

// List to delete
payloads$(actions.SERVICES_TO_DELETE_GET)
  .subscribe(async () => {
    const servicesToDelete = await q('getEnterpriseServices', {
      filter: {
        isGloballyDeleted: true,
        onlyLocals: true
      }
    })
    const { error } = servicesToDelete || {}
    if (error) return handlers.navigateToPath('/management/services')
    handlers.servicesToDeletePopulate(servicesToDelete)
  })

payloads$(actions.SERVICES_TO_DELETE_DELETE)
  .subscribe(async ({ companyId, region, externalId, id }) => {
    if (!id) return
    const service = await q('deleteBranchService', { companyId, region, externalId, id })
    const { error } = service || {}
    if (error) return handlers.navigateToPath('/management/services')
    handlers.servicesToDeleteDeleteReady(id)
  })

// Category preview
payloads$(actions.SERVICE_CATEGORY_LOCALLY_CHANGED_GET)
  .subscribe(async (categoryId) => {
    if (!categoryId) return handlers.navigateToPath('/management/services')
    const { branches, services } = store.getState()
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    if (branchesList.length === 0) branchesList = await globalActions.populateBranches()
    let { categoriesList = [] } = services || {}
    categoriesList = categoriesList || []
    if (categoriesList.length < 1) {
      const services = await globalActions.populateServicesAndServiceCategories()
      categoriesList = services.getEnterpriseServiceCategories || []
    }
    const category = categoriesList.find(item => item.id === categoryId) || {}
    const { locallyUpdatedBranches = {} } = category || {}
    const locallyUpdatedBranchesArr = Object.keys(locallyUpdatedBranches || {}) || []
    const locallyChangedData = locallyUpdatedBranchesArr.map(item => ({
      id: categoryId,
      branch: branchesList.find(branch => branch.id === item) || item
    }))
    handlers.serviceCategoryLocallyChangedPopulate({ id: categoryId, locallyChangedData })
  })

payloads$(actions.SERVICE_CATEGORY_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const serviceCategory = await q('resetGlobalBranchServiceCategory', { companyId, region, internalId })
    const { error } = serviceCategory || {}
    if (error || !serviceCategory) return handlers.navigateToPath('/management/services')
    handlers.serviceCategoryLocallyChangedResetReady({ serviceCategory, companyId })
  })

// Locally changed
payloads$(actions.SERVICE_LOCALLY_CHANGED_GET)
  .subscribe(async ({ id, internalId }) => {
    if (!internalId) return handlers.navigateToPath('/management/services')
    const state = store.getState()
    const { branches } = state || {}
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    const services = await q('getEnterpriseServices', {
      filter: {
        internalId,
        isUpdatedLocally: true,
        onlyLocals: true
      }
    })
    const { error } = services || {}
    if (error || !services) return handlers.navigateToPath('/management/services')
    const locallyChangedData = services.map(item => ({ id: item.id, branch: branchesList.find(branch => branch.id === item.companyId) }))
    handlers.serviceLocallyChangedPopulate({ id, locallyChangedData })
  })

payloads$(actions.SERVICE_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyIds, internalId }) => {
    if (!internalId) return
    const state = store.getState()
    const { forms, company, services } = state || {}
    const { list: servicesList } = services || {}
    const initialService = servicesList.find(({ internalId: initialServiceInternalId }) => initialServiceInternalId === internalId)
    const { serviceChangedLocally } = forms || {}
    const {
      customerEmailReminders,
      durationSplitInterval,
      externalId,
      followupTime,
      preparationTime,
      price,
      resourceEmailReminders,
      resourcesAndDependencies,
      serviceColor,
      serviceDescription,
      serviceName,
      settingCheckboxToMakeOnlinePaymentMandatory,
      settingDisplayInBookingWidget,
      settingOnlinePayment,
      restoreOptions,
      combinationServiceIds,
      hasCombinationSameResourcesPreference
    } = serviceChangedLocally || {}

    const { value: customerEmailRemindersValue } = customerEmailReminders || {}
    const { value: durationSplitIntervalValue } = durationSplitInterval || {}
    const { value: externalIdValue } = externalId || {}
    const { value: followupTimeValue } = followupTime || {}
    const { value: preparationTimeValue } = preparationTime || {}
    const { value: priceValue } = price || {}
    const { value: resourceEmailRemindersValue } = resourceEmailReminders || {}
    const { value: resourcesAndDependenciesValue } = resourcesAndDependencies || {}
    const { value: serviceColorValue } = serviceColor || {}
    const { value: serviceDescriptionValue } = serviceDescription || {}
    const { value: serviceNameValue } = serviceName || {}
    const { value: settingCheckboxToMakeOnlinePaymentMandatoryValue } = settingCheckboxToMakeOnlinePaymentMandatory || {}
    const { value: settingDisplayInBookingWidgetValue } = settingDisplayInBookingWidget || {}
    const { value: settingOnlinePaymentValue } = settingOnlinePayment || {}
    const { value: combinationServiceIdsValue } = combinationServiceIds || {}
    const { value: hasCombinationSameResourcesPreferenceValue } = hasCombinationSameResourcesPreference || {}

    const keysMap = {
      customerEmailRemindersMinutes: customerEmailRemindersValue,
      durationsPattern: durationSplitIntervalValue,
      externalId: externalIdValue,
      durationAfter: followupTimeValue,
      durationBefore: preparationTimeValue,
      price: priceValue,
      resourceEmailRemindersMinutes: resourceEmailRemindersValue,
      dependencies: resourcesAndDependenciesValue,
      color: serviceColorValue,
      description: serviceDescriptionValue,
      name: serviceNameValue,
      isPaymentMandatory: settingCheckboxToMakeOnlinePaymentMandatoryValue,
      isBookable: settingDisplayInBookingWidgetValue,
      hasOnlinePayment: settingOnlinePaymentValue,
      combinationServiceIds: combinationServiceIdsValue,
      hasCombinationSameResourcesPreference: hasCombinationSameResourcesPreferenceValue
    }

    const keys = Object.keys(keysMap).filter(item => !!keysMap[item]).map(item => item)
    const service = await q(initialService?.isCombination ? 'resetToGlobalBranchServiceCombinations' : 'resetToGlobalBranchServices', { companyIds, region: company.region, internalId, keys: restoreOptions.value === 'ALL' ? [] : keys })
    const { error } = service || {}
    if (!service) return handlers.navigateToPath('/management/services')
    if (error) return setServiceLocallyChangedResetErrors(serviceLocallyChangedResetServerErrorsTransform(error), service)
    const updatedService = await q('getEnterpriseService', { id: internalId })
    handlers.serviceLocallyChangedResetReady({ service: updatedService })
    handlers.navigateToPath(`/management/services/${updatedService.internalId}`)
  })

const setServiceLocallyChangedResetErrors = (errors, service) => {
  handlers.formErrorsSet('serviceChangedLocally', errors)
  handlers.serviceLocallyChangedResetReady({ service: service[0] })
}

// Combination Form
payloads$(actions.SERVICE_COMBINATION_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateEnterpriseResourceCategories()
    const state = store.getState()
    let { company } = state
    company = company || {}
    const { settings: companySettings } = company || {}
    let { query } = state.router
    query = query || {}
    let { list: branches } = state.branches || {}
    branches = branches || []
    let { list, categoriesList } = state.services || {}
    list = list || []
    categoriesList = categoriesList || []
    const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || null
    const defaultCategory = categoriesList.find(category => category.isDefault)
    let category = queryCategory || defaultCategory
    if (!category) return handlers.navigateToPath('/management/services')
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    const categorizedServices = categoriesList
      .map(category => ({
        name: category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name,
        items: list
          .filter(item =>
            item.categoryId === category.id &&
            item.durationsPattern &&
            item.durationsPattern.length === 1 &&
            !item.isCombination
          )
          .map(item => ({ label: item.name, value: item.id }))
      }))
      .filter(category => category.items.length > 0)
    if (!id) {
      handlers.serviceCombinationFormPopulate({
        category,
        categorizedServices,
        branches,
        companySettings
      })
      return
    }
    const selectedService = list.find(service => service.id === id) || {}
    const serviceCategory = categoriesList.find(category => category.id === selectedService.categoryId) || null
    const { isComplete } = selectedService
    const service = isComplete
      ? selectedService
      : serviceTransform((await q('getEnterpriseService', { id })) || { error: { text: 'errors.api.unavailable' } })
    const { error } = service || {}
    if (error) {
      handlers.navigateToPath(id ? `/management/services/${id}` : '/management/services')
      return
    }
    category = serviceCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    handlers.serviceUpdate({ ...service, isComplete: true })
    handlers.serviceCombinationFormPopulate({
      ...service,
      category,
      categorizedServices,
      branches,
      companySettings
    })
  })

// Combination Save
payloads$(actions.SERVICE_COMBINATION_FORM_SAVE)
  .subscribe(async ({ service, scrollToError }) => {
    const state = store.getState()
    const { services, branches, staticData, company, router } = state || {}
    const { list: branchesList } = branches || {}
    const { list: servicesList } = services || {}
    const { locale, settings } = company || {}
    const { hash } = router || {}
    const {
      resourceRemindersEmailRecipients: settingsResourceRemindersEmailRecipients,
      customerEmailRemindersMinutes: settingsCustomerEmailRemindersMinutes
    } = settings || {}
    service.settingsResourceRemindersEmailRecipients = settingsResourceRemindersEmailRecipients
    service.settingsCustomerEmailRemindersMinutes = settingsCustomerEmailRemindersMinutes
    const { countries } = staticData || {}
    const countryCode = locale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    const externalIds = servicesList.filter(serviceItem => serviceItem.id !== service.id).map(serviceItem => serviceItem.externalId).filter(Boolean)
    service.allServices = services.list || []
    const errors = serviceCombinationFormValidate(
      service,
      stripeMinAmountOnlinePaymentsCountryCurrency,
      locale,
      currencyCode,
      externalIds
    )
    if (errors.length) return setServiceCombinationFormSaveErrors(errors, scrollToError)
    const savedService = await q('saveEnterpriseService', serviceCombinationSaveTransform(service))
    const { error, id } = savedService
    const translations = { resource: t('global.resourceCategory'), service: t('global.service') }
    if (error) return setServiceCombinationFormSaveErrors(serviceCombinationFormServerErrorsTransform(error, branchesList, translations, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode), scrollToError)
    handlers.serviceUpdate({ ...savedService, isComplete: true })
    handlers.navigateToPath(`/management/services/${id}@@${hash}`)
  })

// Service restore
payloads$(actions.SERVICE_RESTORE_FORM_GET)
  .subscribe(async ({ service, scrollToError }) => {
    handlers.serviceRestoreFormPopulate(service)
  })

const setServiceCombinationFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('services', errors)
  scrollToError && scrollToError(errors)
  handlers.serviceCombinationFormReady()
}

// Clear service changed locally error on route change
store$
  .pipe(
    map(state => state.router && state.router.hash),
    distinctUntilChanged()
  ).subscribe(hash => {
    if (hash !== 'changedLocally') {
      const { forms } = store.getState()
      const { serviceChangedLocally } = forms || {}
      const { globalErrors } = serviceChangedLocally || {}
      if (globalErrors && globalErrors.error) handlers.formErrorsSet('serviceChangedLocally', [])
    }
  })
