import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { CompanyTagForm } from '../../Beauties'

const CompanyTagEditWrapper = props => {
  let {
    id,
    pending,
    form,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist
  } = props
  pending = !!pending
  form = form || {}
  showExternalIds = !!showExternalIds
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers

  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.companyTagFormGet(id)
  }, [id])

  const onSubmit = cb => {
    handlers.companyTagFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return pending
    ? <Loader active={pending} />
    : <CompanyTagForm
        id={id}
        title='Edit tag'
        type='edit'
        cancelLink={`/customers/company-tags/${id}`}
        onSubmit={onSubmit}
        showExternalIds={showExternalIds}
        allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        isAdmin={isAdmin}
        branchesAccessBlacklist={branchesAccessBlacklist}
        location={form.location && form.location.values}
      />
}

CompanyTagEditWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.id,
  pending: state.companyTags.pendingForm,
  form: state.forms.companyTags,
  showExternalIds: state.company.settings.showExternalIds,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default memo(connect(maps)(CompanyTagEditWrapper))
