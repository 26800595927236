import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../../Store'
import { PopupUsersDelete } from '../../../Beauties'

export const PopupUsersDeleteWrapper = props => {
  useEffect(() => {
    handlers.formSet('usersDelete', {})
  }, [])

  const approve = cb => {
    const { id } = props
    if (id) {
      const form = store.getState().forms.usersDelete || {}
      form.id = id
      handlers.userDeleteFormSave(form, (errors) => {
        cb && cb(errors)
      })
    }
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupUsersDelete approve={approve} reject={reject} />
  )
}

PopupUsersDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

const maps = state => ({
  pending: state.users.pendingDeleteForm,
  users: state.users.list
})

export default connect(maps)(PopupUsersDeleteWrapper)
