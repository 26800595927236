import { compose, createStore } from 'redux'
import persistState from 'redux-localstorage'
import { Subject } from 'rxjs'
import { filter, pluck } from 'rxjs/operators'
import { assignDeep } from '../Utils'
import slicer from './Config/localStorage'
import reducers from './Config/reducers'
import selectors from './Config/selectors'

const initialState = Object
  .keys(reducers)
  .reduce((acc, key) => ({ ...acc, [key]: reducers[key].initialState || {} }), {})

const reducer = reducerInitialState => (state = reducerInitialState || initialState, action) => Object
  .keys(reducers)
  .reduce((acc, subkey) => ({
    ...acc,
    [subkey]: reducers[subkey][action.type]
      ? reducers[subkey][action.type](state[subkey], action.payload, state)
      : state[subkey]
  }), state)

const persistStateFunction = persistState(null, {
  key: 'store',
  slicer,
  merge: (_ini, persistedState) => {
    return assignDeep({}, initialState, persistedState)
  }
})

// middlewares
const enhancer = (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__)
  ? compose(persistStateFunction, window.__REDUX_DEVTOOLS_EXTENSION__())
  : compose(persistStateFunction)

export const store = createStore(reducer(), enhancer)
export const mockStore = (initialState) => createStore(reducer(initialState))
export const actions = Object
  .keys(reducers)
  .reduce((acc, subkey) => ({
    ...acc,
    ...Object
      .keys(reducers[subkey])
      .filter(type => !['handlers', 'initialState'].includes(type))
      .reduce((a, type) => ({ ...a, [type]: type }), {})
  }), {})

export const dispatcher$ = new Subject()
export const store$ = new Subject()
export { selectors }

store.subscribe(() => store$.next(store.getState()))

export let handlers = {}
handlers = Object
  .keys(reducers)
  // Attention at this assign, should be mutable because we need to pass it to specific handlers
  // DONT USE { ... }
  .reduce((acc, subkey) => Object
    .assign(acc, {
      ...acc,
      ...reducers[subkey].handlers && reducers[subkey].handlers((type, payload) => {
        store.dispatch({ type, payload })
        dispatcher$.next({ type, payload })
      }, actions, handlers)
    }), handlers)

export const actions$ = (...args) =>
  !args.length
    ? dispatcher$
    : dispatcher$
      .pipe(
        filter(action => Object
          .keys(args)
          .some(key => args[key] === action.type)
        )
      )

export const payloads$ = (...args) => actions$(...args).pipe(pluck('payload'))
export const globalActions = {}
export { translations, languageChange } from '../Store/intl'
export { connect, Provider } from 'react-redux'
