import React from 'react'
import {
  FormButtons,
  Button,
  t
} from '../../../../Common'

const BranchesServiceFormButtons = props => {
  const { cancelLink, disabled } = props || {}

  return (
    <FormButtons>
      <Button as='link' to={cancelLink} isSecondary>
        {t('buttons.cancel.label')}
      </Button>
      <Button disabled={disabled} type='submit' isPrimary>
        {t('buttons.save.label')}
      </Button>
    </FormButtons>
  )
}

export default BranchesServiceFormButtons
