import React from 'react'

export default (Component, Context) => {
  const WrappedComponent = props => (
    <Context.Consumer>
      {values => (
        <Component {...values} {...props} />
      )}
    </Context.Consumer>
  )
  return WrappedComponent
}
