import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Input,
  Textarea,
  ColorSelect,
  Error,
  t
} from '../../../Common'

import './TagsForm.css'

const TagsForm = props => {
  const { title, onSubmit, cancelLink, showExternalIds } = props

  return (
    <FixedContent>
      <FixedContentHeader>
        <Title label={title} />
      </FixedContentHeader>
      <FixedContentBody>
        <div className='ta-tags__form'>
          <Form onSubmit={onSubmit} name='tags'>
            <FormSection>

              {(showExternalIds &&
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId.label')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <ColorSelect name='color' pattern='dotted'>
                    <Input name='name' label={t('global.name.label')} mandatory />
                  </ColorSelect>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Textarea
                    name='note'
                    label={t('global.note.label')}
                    form='tags'
                    limit={400}
                  />
                </Col>
              </Row>
              <Error name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </div>
      </FixedContentBody>
    </FixedContent>
  )
}

TagsForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default TagsForm
