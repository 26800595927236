import React from 'react'
import {
  Title,
  ListItem,
  BorderedBox,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { BookingRemindersExample } from '../../../Beauties'

const ServicePreviewAdvanced = props => {
  let {
    customerEmailRemindersMinutes,
    resourceEmailRemindersMinutes,
    resourceRemindersEmailRecipients,
    companyCustomerEmailRemindersMinutes,
    companyResourceEmailRemindersMinutes
  } = props
  resourceRemindersEmailRecipients = resourceRemindersEmailRecipients || []
  const hasCustomerReminders = customerEmailRemindersMinutes !== null &&
    companyCustomerEmailRemindersMinutes &&
    companyCustomerEmailRemindersMinutes.length > 0 &&
    (companyCustomerEmailRemindersMinutes.length !== 1 || companyCustomerEmailRemindersMinutes[0] !== 0)
  const hasResourceReminders = resourceEmailRemindersMinutes !== null && resourceRemindersEmailRecipients.length > 0
  const customerReminders = customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes
  const resourceReminders = resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes
  const bookingRemindersHasCustomers = customerReminders && customerReminders.length > 0
  const bookingRemindersHasStaff = resourceReminders && resourceReminders.length > 0
  const bookingRemindersHasOwner = resourceRemindersEmailRecipients.includes('owner')
  const bookingRemindersHasResource = resourceRemindersEmailRecipients.includes('resource')
  const bookingRemindersOthers = (resourceRemindersEmailRecipients.length && resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')) || []

  return (
    (hasCustomerReminders || hasResourceReminders) &&
      <div className='ta-services__preview__advanced'>
        <Title size='m' isCompact label={t('servicesGroups.reminder.heading')} icon='bell' ignoreTopOffset />
        <ListItem>
          {dangerousHTML(t('servicesGroups.reminder.infoText'))}
          {(hasCustomerReminders &&
            <>
              <strong><li>{t('servicesGroups.reminder.emails.customersAndParticipants')}</li></strong>
              <BorderedBox>
                {!bookingRemindersHasCustomers && <strong>{t('global.disabled')}</strong>}
                {bookingRemindersHasCustomers && <BookingRemindersExample values={customerReminders} size='small' />}
              </BorderedBox>
            </>
        )}
          {(hasResourceReminders &&
            <>
              <strong><li>{t('global.staff')}</li></strong>
              <BorderedBox>
                {!bookingRemindersHasStaff && <strong>{t('global.disabled')}</strong>}
                {(bookingRemindersHasStaff &&
                  <>
                    {bookingRemindersHasOwner && <strong><li>{t('global.owner')}</li></strong>}
                    {bookingRemindersHasResource && <strong><li>{t('servicesGroups.resources')}</li></strong>}
                    {(bookingRemindersOthers.length > 0 &&
                      <>
                        <strong><li>{t('servicesGroups.others')}</li></strong>
                        {bookingRemindersOthers.map(item => <li className='ta-list-item__participant' key={item}>{item}</li>)}
                      </>
                  )}
                    <BookingRemindersExample values={resourceReminders} size='small' />
                  </>
              )}
              </BorderedBox>
            </>
        )}
        </ListItem>
      </div>
  )
}

export default ServicePreviewAdvanced
