import { store } from '../../../Store'
import { validator, translateServerCode, sortBy, uniq } from '../../../Utils'

// LIST

export const usersListTransform = users => {
  if (!users) return
  return users.map(item => ({
    ...item
  }))
}

// FORM

export const usersFormTransform = (user, permissionOptions, branches) => {
  user = user || {}
  branches = branches || []
  permissionOptions = permissionOptions || []
  let {
    id,
    email,
    name,
    phone,
    roles,
    rules: userPermissions,
    enterprisePermissionsGroupId,
    myself,
    account,
    isPermissionUpdateAllowedByEnterpriseIssuer,
    permissionType
  } = user || {}
  roles = roles || []
  permissionType = permissionType || null
  myself = myself || {}
  userPermissions = userPermissions || []
  isPermissionUpdateAllowedByEnterpriseIssuer = typeof isPermissionUpdateAllowedByEnterpriseIssuer === 'boolean'
    ? isPermissionUpdateAllowedByEnterpriseIssuer
    : true
  const isOwner = roles.includes('OWNER')
  let { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions, userId: accountUserId } = account || {}
  accountPermissions = accountPermissions || {}
  let {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions
  accountBranchesAccessWhitelist = accountBranchesAccessWhitelist || []
  const amICustom = !amIOwner && !amIAdmin
  const arePermissionChangeDisabled = !isPermissionUpdateAllowedByEnterpriseIssuer || id === accountUserId
  const isTypeAdd = !id
  const defaultCustomPermissions = {
    // Account
    accountRead: false,
    // Statistics
    statisticsRead: false,
    // Branches
    branchesRead: false,
    branchesWrite: false,
    branchesDelete: isOwner,
    allowFulfilment: false,
    // Tags
    tagsRead: false,
    tagsWrite: false,
    tagsDelete: false,
    // Customers
    globalCustomersRead: false,
    globalCustomersWrite: false,
    globalCustomersDelete: false,
    // DataFields
    globalDataFieldsRead: false,
    globalDataFieldsWrite: false,
    globalDataFieldsDelete: false,
    // Tags
    globalTagsRead: false,
    globalTagsWrite: false,
    globalTagsDelete: false,
    // Services
    globalServicesRead: false,
    globalServicesWrite: false,
    globalServicesDelete: false,
    // Groups
    globalGroupsRead: false,
    globalGroupsWrite: false,
    globalGroupsDelete: false,
    // Resource categories
    globalResourceCategoriesRead: false,
    globalResourceCategoriesWrite: false,
    globalResourceCategoriesDelete: false,
    // Global Permission groups
    globalPermissionsGroupsRead: false,
    globalPermissionsGroupsWrite: false,
    globalPermissionsGroupsDelete: false,
    // Settings
    settingsRead: false,
    settingsWrite: false,
    // Apps
    appsRead: false,
    // Users
    usersRead: false,
    usersWrite: false,
    usersDelete: false,
    // Permission groups
    permissionsGroupsRead: false,
    permissionsGroupsWrite: false,
    permissionsGroupsDelete: false
  }
  let assignedPermission = 'ADMIN'
  if (!isTypeAdd && enterprisePermissionsGroupId) assignedPermission = enterprisePermissionsGroupId
  if (!isTypeAdd && !enterprisePermissionsGroupId && userPermissions && !roles.includes('ADMIN')) assignedPermission = 'CUSTOM'
  if (isTypeAdd && myself.enterprisePermissionsGroupId) assignedPermission = myself.enterprisePermissionsGroupId
  if (isTypeAdd && !myself.enterprisePermissionsGroupId) assignedPermission = 'CUSTOM'
  if (permissionType) assignedPermission = permissionType
  userPermissions = userPermissions || {}
  const {
    branchesAccessWhitelist,
    branchesAccessBlacklist
  } = userPermissions
  let branchAccess = 'ALLOW'
  if (branchesAccessBlacklist && branchesAccessBlacklist.length) branchAccess = 'DENY'
  const branchesAccessWhitelistOptions = branches
    .filter(branch => !(branchesAccessBlacklist || []).includes(branch))
    .map(branch => ({
      label: branch.name,
      value: branch.id
    }))
    .sort(sortBy('label'))
  const branchesAccessBlacklistOptions = branches
    .filter(branch => !(branchesAccessWhitelist || []).includes(branch))
    .map(branch => ({
      label: branch.name,
      value: branch.id
    }))
    .sort(sortBy('label'))
  const permissionsBranchesRead = isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.branchesRead))
    ? false
    : userPermissions.branchesRead !== undefined
      ? !!userPermissions.branchesRead
      : defaultCustomPermissions.branchesRead

  const result = {
    isOwner: roles.includes('OWNER'),
    email: {
      value: email || '',
      disabled: !!id
    },
    name: {
      value: name || ''
    },
    phone: {
      phoneCountry: (phone && phone.country) || 'DE',
      phone: (phone && phone.number) || ''
    },
    roles: {
      value: (roles && roles[0]) || 'ADMIN'
    },
    permissionType: {
      value: assignedPermission,
      options: permissionOptions
    },
    isPermissionUpdateAllowedByEnterpriseIssuer: typeof isPermissionUpdateAllowedByEnterpriseIssuer === 'boolean'
      ? isPermissionUpdateAllowedByEnterpriseIssuer
      : true,
    // Account
    permissionsAccountRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.accountRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.accountRead))
        ? false
        : userPermissions.accountRead !== undefined
          ? !!userPermissions.accountRead
          : defaultCustomPermissions.accountRead
    },
    // Statistics
    permissionsStatisticsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.statisticsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.statisticsRead))
        ? false
        : userPermissions.statisticsRead !== undefined
          ? !!userPermissions.statisticsRead
          : defaultCustomPermissions.statisticsRead
    },
    // Branches
    permissionsBranchesRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.branchesRead),
      value: permissionsBranchesRead
    },
    permissionsBranchesWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.branchesWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.branchesWrite))
        ? false
        : userPermissions.branchesWrite !== undefined
          ? !!userPermissions.branchesWrite
          : defaultCustomPermissions.branchesWrite
    },
    permissionsBranchesDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.branchesDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.branchesDelete))
        ? false
        : defaultCustomPermissions.branchesDelete
    },
    permissionsAllowFulfilment: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.allowFulfilment),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.allowFulfilment))
        ? false
        : userPermissions.allowFulfilment !== undefined
          ? !!userPermissions.allowFulfilment
          : defaultCustomPermissions.allowFulfilment
    },
    branchAccess: {
      disabled: arePermissionChangeDisabled || (amICustom && (!accountPermissions.branchesRead || !accountPermissions.usersWrite)),
      value: branchAccess
    },
    branchesAccessWhitelist: {
      disabled: arePermissionChangeDisabled || (amICustom && (!accountPermissions.branchesRead || !accountPermissions.usersWrite)),
      options: branchesAccessWhitelistOptions,
      insufficient: amICustom && branchAccess === 'ALLOW' && branchesAccessWhitelist && branchesAccessWhitelist.length
        ? branchesAccessWhitelist.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !accountBranchesAccessWhitelist.includes(branch))
        : [],
      values: branchAccess === 'ALLOW' && branchesAccessWhitelist && branchesAccessWhitelist.length
        ? branchesAccessWhitelist.filter(branch => !(accountBranchesAccessBlacklist || []).includes(branch))
        : permissionsBranchesRead && branchAccess === 'ALLOW' && (!amICustom || !isTypeAdd)
          ? ['all']
          : []
    },
    branchesAccessBlacklist: {
      disabled: amICustom,
      options: branchesAccessBlacklistOptions,
      insufficient: amICustom && branchAccess === 'DENY' && branchesAccessBlacklist && branchesAccessBlacklist.length
        ? branchesAccessBlacklist.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !accountBranchesAccessWhitelist.includes(branch))
        : amICustom && branchesAccessWhitelist && branchesAccessWhitelist.length === 0
          ? accountBranchesAccessBlacklist || []
          : [],
      values: (branchesAccessBlacklist || []).filter(branch => !(accountBranchesAccessBlacklist || []).includes(branch))
    },
    // Tags
    permissionsTagsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.tagsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.tagsRead))
        ? false
        : userPermissions.tagsRead !== undefined
          ? !!userPermissions.tagsRead
          : defaultCustomPermissions.tagsRead
    },
    permissionsTagsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.tagsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.tagsWrite))
        ? false
        : userPermissions.tagsWrite !== undefined
          ? !!userPermissions.tagsWrite
          : defaultCustomPermissions.tagsWrite
    },
    permissionsTagsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.tagsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.tagsDelete))
        ? false
        : userPermissions.tagsDelete !== undefined
          ? !!userPermissions.tagsDelete
          : defaultCustomPermissions.tagsDelete
    },
    // Customers
    permissionsGlobalCustomersRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalCustomersRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalCustomersRead))
        ? false
        : userPermissions.globalCustomersRead !== undefined
          ? !!userPermissions.globalCustomersRead
          : defaultCustomPermissions.globalCustomersRead
    },
    permissionsGlobalCustomersWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalCustomersWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalCustomersWrite))
        ? false
        : userPermissions.globalCustomersWrite !== undefined
          ? !!userPermissions.globalCustomersWrite
          : defaultCustomPermissions.globalCustomersWrite
    },
    permissionsGlobalCustomersDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalCustomersDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalCustomersDelete))
        ? false
        : userPermissions.globalCustomersDelete !== undefined
          ? !!userPermissions.globalCustomersDelete
          : defaultCustomPermissions.globalCustomersDelete
    },
    // DataFields
    permissionsGlobalDataFieldsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalDataFieldsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalDataFieldsRead))
        ? false
        : userPermissions.globalDataFieldsRead !== undefined
          ? !!userPermissions.globalDataFieldsRead
          : defaultCustomPermissions.globalDataFieldsRead
    },
    permissionsGlobalDataFieldsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalDataFieldsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalDataFieldsWrite))
        ? false
        : userPermissions.globalDataFieldsWrite !== undefined
          ? !!userPermissions.globalDataFieldsWrite
          : defaultCustomPermissions.globalDataFieldsWrite
    },
    permissionsGlobalDataFieldsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalDataFieldsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalDataFieldsDelete))
        ? false
        : userPermissions.globalDataFieldsDelete !== undefined
          ? !!userPermissions.globalDataFieldsDelete
          : defaultCustomPermissions.globalDataFieldsDelete
    },
    // Tags
    permissionsGlobalTagsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalTagsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalTagsRead))
        ? false
        : userPermissions.globalTagsRead !== undefined
          ? !!userPermissions.globalTagsRead
          : defaultCustomPermissions.globalTagsRead
    },
    permissionsGlobalTagsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalTagsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalTagsWrite))
        ? false
        : userPermissions.globalTagsWrite !== undefined
          ? !!userPermissions.globalTagsWrite
          : defaultCustomPermissions.globalTagsWrite
    },
    permissionsGlobalTagsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalTagsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalTagsDelete))
        ? false
        : userPermissions.globalTagsDelete !== undefined
          ? !!userPermissions.globalTagsDelete
          : defaultCustomPermissions.globalTagsDelete
    },
    // Services
    permissionsGlobalServicesRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalServicesRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalServicesRead))
        ? false
        : userPermissions.globalServicesRead !== undefined
          ? !!userPermissions.globalServicesRead
          : defaultCustomPermissions.globalServicesRead
    },
    permissionsGlobalServicesWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalServicesWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalServicesWrite))
        ? false
        : userPermissions.globalServicesWrite !== undefined
          ? !!userPermissions.globalServicesWrite
          : defaultCustomPermissions.globalServicesWrite
    },
    permissionsGlobalServicesDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalServicesDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalServicesDelete))
        ? false
        : userPermissions.globalServicesDelete !== undefined
          ? !!userPermissions.globalServicesDelete
          : defaultCustomPermissions.globalServicesDelete
    },
    // Groups
    permissionsGlobalGroupsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalGroupsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalGroupsRead))
        ? false
        : userPermissions.globalGroupsRead !== undefined
          ? !!userPermissions.globalGroupsRead
          : defaultCustomPermissions.globalGroupsRead
    },
    permissionsGlobalGroupsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalGroupsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalGroupsWrite))
        ? false
        : userPermissions.globalGroupsWrite !== undefined
          ? !!userPermissions.globalGroupsWrite
          : defaultCustomPermissions.globalGroupsWrite
    },
    permissionsGlobalGroupsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalGroupsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalGroupsDelete))
        ? false
        : userPermissions.globalGroupsDelete !== undefined
          ? !!userPermissions.globalGroupsDelete
          : defaultCustomPermissions.globalGroupsDelete
    },
    // Resource categories
    permissionsGlobalResourceCategoriesRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalResourceCategoriesRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalResourceCategoriesRead))
        ? false
        : userPermissions.globalResourceCategoriesRead !== undefined
          ? !!userPermissions.globalResourceCategoriesRead
          : defaultCustomPermissions.globalResourceCategoriesRead
    },
    permissionsGlobalResourceCategoriesWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalResourceCategoriesWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalResourceCategoriesWrite))
        ? false
        : userPermissions.globalResourceCategoriesWrite !== undefined
          ? !!userPermissions.globalResourceCategoriesWrite
          : defaultCustomPermissions.globalResourceCategoriesWrite
    },
    permissionsGlobalResourceCategoriesDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalResourceCategoriesDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalResourceCategoriesDelete))
        ? false
        : userPermissions.globalResourceCategoriesDelete !== undefined
          ? !!userPermissions.globalResourceCategoriesDelete
          : defaultCustomPermissions.globalResourceCategoriesDelete
    },
    // Global Permission groups
    permissionsGlobalPermissionsGroupsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalPermissionsGroupsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalPermissionsGroupsRead))
        ? false
        : userPermissions.globalPermissionsGroupsRead !== undefined
          ? !!userPermissions.globalPermissionsGroupsRead
          : defaultCustomPermissions.globalPermissionsGroupsRead
    },
    permissionsGlobalPermissionsGroupsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalPermissionsGroupsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalPermissionsGroupsWrite))
        ? false
        : userPermissions.globalPermissionsGroupsWrite !== undefined
          ? !!userPermissions.globalPermissionsGroupsWrite
          : defaultCustomPermissions.globalPermissionsGroupsWrite
    },
    permissionsGlobalPermissionsGroupsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.globalPermissionsGroupsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.globalPermissionsGroupsDelete))
        ? false
        : userPermissions.globalPermissionsGroupsDelete !== undefined
          ? !!userPermissions.globalPermissionsGroupsDelete
          : defaultCustomPermissions.globalPermissionsGroupsDelete
    },
    // Settings
    permissionsSettingsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.settingsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.settingsRead))
        ? false
        : userPermissions.settingsRead !== undefined
          ? !!userPermissions.settingsRead
          : defaultCustomPermissions.settingsRead
    },
    permissionsSettingsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.settingsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.settingsWrite))
        ? false
        : userPermissions.settingsWrite !== undefined
          ? !!userPermissions.settingsWrite
          : defaultCustomPermissions.settingsWrite
    },
    // Apps
    permissionsAppsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.appsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.appsRead))
        ? false
        : userPermissions.appsRead !== undefined
          ? !!userPermissions.appsRead
          : defaultCustomPermissions.appsRead
    },
    permissionsAppsWrite: {
      disabled: true,
      value: false
    },
    // Users
    permissionsUsersRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.usersRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.usersRead))
        ? false
        : userPermissions.usersRead !== undefined
          ? !!userPermissions.usersRead
          : defaultCustomPermissions.usersRead
    },
    permissionsUsersWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.usersWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.usersWrite))
        ? false
        : userPermissions.usersWrite !== undefined
          ? !!userPermissions.usersWrite
          : defaultCustomPermissions.usersWrite
    },
    permissionsUsersDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.usersDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.usersDelete))
        ? false
        : userPermissions.usersDelete !== undefined
          ? !!userPermissions.usersDelete
          : defaultCustomPermissions.usersDelete
    },
    // Permission groups
    permissionsPermissionsGroupsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.permissionsGroupsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.permissionsGroupsRead))
        ? false
        : userPermissions.permissionsGroupsRead !== undefined
          ? !!userPermissions.permissionsGroupsRead
          : defaultCustomPermissions.permissionsGroupsRead
    },
    permissionsPermissionsGroupsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.permissionsGroupsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.permissionsGroupsWrite))
        ? false
        : userPermissions.permissionsGroupsWrite !== undefined
          ? !!userPermissions.permissionsGroupsWrite
          : defaultCustomPermissions.permissionsGroupsWrite
    },
    permissionsPermissionsGroupsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !accountPermissions.permissionsGroupsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !accountPermissions.permissionsGroupsDelete))
        ? false
        : userPermissions.permissionsGroupsDelete !== undefined
          ? !!userPermissions.permissionsGroupsDelete
          : defaultCustomPermissions.permissionsGroupsDelete
    }
  }
  if (id) result.id = id
  return result
}

export const usersFormValidate = ({ userEmails }) => {
  const rules = [{
    'name.value': ['required', 'max:255']
  }, {
    'email.value': ['required', 'email', 'max:255']
  }, {
    'phone.phone': ['required', 'phone', 'max:32']
  }, {
    'roles.value': ['required', 'max:255']
  }]

  const messages = {
    'name': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'email': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength',
      'email': 'errors.email.invalid'
    },
    'phone': {
      'required': 'errors.required',
      'phone': 'errors.phone.invalid',
      'max': 'errors.invalidMaxLength'
    },
    'roles': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    'name': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'email': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.email.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'phone': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.phone.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '32'
      }
    },
    'roles': {
      'required': {
        key: 'FIELD_NAME',
        value: 'User group'
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    }
  }

  const form = store.getState().forms.users
  let { email, permissionsBranchesRead, branchAccess, branchesAccessWhitelist, branchesAccessBlacklist } = form || {}
  permissionsBranchesRead = permissionsBranchesRead || {}
  branchAccess = branchAccess || {}
  branchesAccessWhitelist = branchesAccessWhitelist || {}
  branchesAccessBlacklist = branchesAccessBlacklist || {}
  const { value: emailValue } = email || {}
  const errors = validator(form, rules, messages, replaces)
  if (userEmails.includes(emailValue)) errors.push({ key: 'email', value: 'errors.email.exists' })
  if (permissionsBranchesRead.value && branchAccess.value === 'ALLOW' && !branchesAccessWhitelist.values.length) {
    errors.push({ key: 'branchesAccessWhitelist', value: 'errors.branchesAccessWhitelist.required' })
  }
  if (permissionsBranchesRead.value && branchAccess.value === 'DENY' && !branchesAccessBlacklist.values.length) {
    errors.push({ key: 'branchesAccessBlacklist', value: 'errors.branchesAccessBlacklist.required' })
  }

  return errors
}

export const usersFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else if (error.code === 'InvalidUserPermission') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else if (error.code === 'InvalidUserCreatePermission') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else if (error.code === 'InvalidUserUpdatePermission') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

export const usersSaveTransform = (user, isEditingOwnUser, amICustom) => {
  user = user || {}
  let permissions = {}
  const permissionTypeValue = user.permissionType.value
  if (permissionTypeValue === 'CUSTOM' && user.isPermissionUpdateAllowedByEnterpriseIssuer) {
    // Account
    permissions.accountRead = false
    if (user.permissionsAccountRead.value) {
      permissions.accountRead = true
    }
    // Statistics
    permissions.statisticsRead = false
    if (user.permissionsStatisticsRead.value) {
      permissions.statisticsRead = true
    }
    // Branches
    permissions.branchesRead = null
    permissions.branchesWrite = false
    permissions.branchesDelete = false
    permissions.allowFulfilment = null
    if (user.permissionsBranchesRead.value) {
      permissions.branchesRead = true
      if (user.permissionsBranchesWrite.value) {
        permissions.branchesWrite = true
        // if (user.permissionsBranchesDelete.value) {
        //   permissions.branchesDelete = true
        // }
      }
      if (user.permissionsAllowFulfilment.value) {
        permissions.allowFulfilment = true
      }
    }
    // Tags
    permissions.tagsRead = null
    permissions.tagsWrite = false
    permissions.tagsDelete = false
    if (user.permissionsTagsRead.value) {
      permissions.tagsRead = true
      if (user.permissionsTagsWrite.value) {
        permissions.tagsWrite = true
        if (user.permissionsTagsDelete.value) {
          permissions.tagsDelete = true
        }
      }
    }
    // Customers
    permissions.globalCustomersRead = null
    permissions.globalCustomersWrite = false
    permissions.globalCustomersDelete = false
    if (user.permissionsGlobalCustomersRead.value) {
      permissions.globalCustomersRead = true
      if (user.permissionsGlobalCustomersWrite.value) {
        permissions.globalCustomersWrite = true
        if (user.permissionsGlobalCustomersDelete.value) {
          permissions.globalCustomersDelete = true
        }
      }
    }
    // Data Fields
    permissions.globalDataFieldsRead = null
    permissions.globalDataFieldsWrite = false
    permissions.globalDataFieldsDelete = false
    if (user.permissionsGlobalDataFieldsRead.value) {
      permissions.globalDataFieldsRead = true
      if (user.permissionsGlobalDataFieldsWrite.value) {
        permissions.globalDataFieldsWrite = true
        if (user.permissionsGlobalDataFieldsDelete.value) {
          permissions.globalDataFieldsDelete = true
        }
      }
    }
    // Global Tags
    permissions.globalTagsRead = null
    permissions.globalTagsWrite = false
    permissions.globalTagsDelete = false
    if (user.permissionsGlobalTagsRead.value) {
      permissions.globalTagsRead = true
      if (user.permissionsGlobalTagsWrite.value) {
        permissions.globalTagsWrite = true
        if (user.permissionsGlobalTagsDelete.value) {
          permissions.globalTagsDelete = true
        }
      }
    }
    // Services
    permissions.globalServicesRead = null
    permissions.globalServicesWrite = false
    permissions.globalServicesDelete = false
    if (user.permissionsGlobalServicesRead.value) {
      permissions.globalServicesRead = true
      if (user.permissionsGlobalServicesWrite.value) {
        permissions.globalServicesWrite = true
        if (user.permissionsGlobalServicesDelete.value) {
          permissions.globalServicesDelete = true
        }
      }
    }
    // Groups
    permissions.globalGroupsRead = null
    permissions.globalGroupsWrite = false
    permissions.globalGroupsDelete = false
    if (user.permissionsGlobalGroupsRead.value) {
      permissions.globalGroupsRead = true
      if (user.permissionsGlobalGroupsWrite.value) {
        permissions.globalGroupsWrite = true
        if (user.permissionsGlobalGroupsDelete.value) {
          permissions.globalGroupsDelete = true
        }
      }
    }
    // Resource Categories
    permissions.globalResourceCategoriesRead = null
    permissions.globalResourceCategoriesWrite = false
    permissions.globalResourceCategoriesDelete = false
    if (user.permissionsGlobalResourceCategoriesRead.value) {
      permissions.globalResourceCategoriesRead = true
      if (user.permissionsGlobalResourceCategoriesWrite.value) {
        permissions.globalResourceCategoriesWrite = true
        if (user.permissionsGlobalResourceCategoriesDelete.value) {
          permissions.globalResourceCategoriesDelete = true
        }
      }
    }
    // Global Permission groups
    permissions.globalPermissionsGroupsRead = null
    permissions.globalPermissionsGroupsWrite = false
    permissions.globalPermissionsGroupsDelete = false
    if (user.permissionsGlobalPermissionsGroupsRead.value) {
      permissions.globalPermissionsGroupsRead = true
      if (user.permissionsGlobalPermissionsGroupsWrite.value) {
        permissions.globalPermissionsGroupsWrite = true
        if (user.permissionsGlobalPermissionsGroupsDelete.value) {
          permissions.globalPermissionsGroupsDelete = true
        }
      }
    }
    // Settings
    permissions.settingsRead = null
    permissions.settingsWrite = false
    if (user.permissionsSettingsRead.value) {
      permissions.settingsRead = true
      if (user.permissionsSettingsWrite.value) {
        permissions.settingsWrite = true
      }
    }
    // Apps
    permissions.appsRead = null
    if (user.permissionsAppsRead.value) {
      permissions.appsRead = true
    }
    // Users
    permissions.usersRead = null
    permissions.usersWrite = false
    permissions.usersDelete = false
    if (user.permissionsUsersRead.value) {
      permissions.usersRead = true
      if (user.permissionsUsersWrite.value) {
        permissions.usersWrite = true
        if (user.permissionsUsersDelete.value) {
          permissions.usersDelete = true
        }
      }
    }
    // Permission groups
    permissions.permissionsGroupsRead = null
    permissions.permissionsGroupsWrite = false
    permissions.permissionsGroupsDelete = false
    if (user.permissionsPermissionsGroupsRead.value) {
      permissions.permissionsGroupsRead = true
      if (user.permissionsPermissionsGroupsWrite.value) {
        permissions.permissionsGroupsWrite = true
        if (user.permissionsPermissionsGroupsDelete.value) {
          permissions.permissionsGroupsDelete = true
        }
      }
    }
  }
  const isAdmin = permissionTypeValue === 'ADMIN'
  const { branchAccess } = user
  const { value: branchAccessValue } = branchAccess || {}
  const isAllowedMode = branchAccessValue === 'ALLOW'

  if (user.branchesAccessWhitelist.values.length && (user.branchesAccessBlacklist.values.length === 0 || isAllowedMode) && user.branchesAccessWhitelist.values[0] === 'all') {
    permissions.branchesAccessWhitelist = null
    permissions.branchesAccessBlacklist = []
  } else {
    permissions.branchesAccessWhitelist = isAllowedMode && user.branchesAccessWhitelist.values.length ? user.branchesAccessWhitelist.values : null
    if (isAllowedMode) permissions.branchesAccessBlacklist = null
    if (!isAllowedMode && !amICustom) permissions.branchesAccessBlacklist = user.branchesAccessBlacklist.values.length ? user.branchesAccessBlacklist.values : null
    if (!isAllowedMode && amICustom) {
      permissions.branchesAccessBlacklist = user.branchesAccessBlacklist.values.length
        ? uniq([...(user.branchesAccessBlacklist.values || []), ...(user.branchesAccessBlacklist.insufficient || [])])
        : null
    }
  }

  const result = {
    id: user.id,
    name: user.name.value || '',
    email: user.email.value || '',
    phone: (user.phone && user.phone.phoneCountry && user.phone.phone) && {
      country: (user.phone && user.phone.phoneCountry) || '',
      number: (user.phone && user.phone.phone) || ''
    },
    isAdmin,
    permissions: permissionTypeValue !== 'CUSTOM' ? null : permissions,
    permissionsGroupId: isAdmin || permissionTypeValue === 'CUSTOM' ? null : permissionTypeValue
  }
  if (isEditingOwnUser) {
    delete result.email
    delete result.isAdmin
    delete result.permissions
    delete result.permissionsGroupId
  }
  if (!user.isPermissionUpdateAllowedByEnterpriseIssuer) {
    delete result.permissions
    delete result.permissionsGroupId
  }
  return result
}

// UPDATE

export const usersTransform = user => {
  if (!user) return
  const { rules, roles } = user || {}
  const isOwner = (roles || []).includes('OWNER')
  return {
    ...user,
    isBatch: (user.durationsPattern || []).length > 1,
    rules: {
      ...(rules || {}),
      branchesDelete: isOwner || rules.branchesDelete
    }
  }
}
