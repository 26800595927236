import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './SimpleTable.css'

class SimpleTable extends Component {
  constructor (props, context) {
    super(props)

    this.state = {
      thSize: this.props.size
    }
  }

  render () {
    const { children } = this.props

    return (
      <SimpleTableContext.Provider value={this.state}>
        <div className='ta-simple-table'>
          {children}
        </div>
      </SimpleTableContext.Provider>
    )
  }
}

SimpleTable.propTypes = {
  size: PropTypes.string
}

export const SimpleTableContext = React.createContext({
  thSize: null
})

export default SimpleTable
