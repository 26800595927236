import {
  EMAIL_VALIDATION_REGEX,
  AVAILABLE_LANGUAGES,
  DEFAULT_LOCALE
} from '../../../Settings'
import { translateServerCode, validator } from '../../../Utils'

export const loginFormInitialValues = () => {
  return {
    remember: {
      value: true
    },
    email: {
      value: ''
    },
    password: {
      value: '',
      type: 'password'
    },
    language: {
      value: DEFAULT_LOCALE,
      options: AVAILABLE_LANGUAGES.map(language => ({
        label: language.name,
        value: language.locale
      }))
    }
  }
}

export const loginSaveTransform = (form = {}) => {
  return {
    email: (form.email && form.email.value) || '',
    password: (form.password && form.password.value) || '',
    remember: form.remember && form.remember.value,
    locale: form.language && form.language.value
  }
}

export const loginFormValidate = (fields) => {
  const errors = []
  const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)
  let hasError = false

  // Email
  if (fields.email.length === 0) {
    errors.push({ key: 'email', value: '' })
    errors.push({ key: 'emailOrPassword', value: 'errors.email.required' })
    hasError = true
  }
  if (fields.email.length > 0 && !emailRegex.test(fields.email)) {
    errors.push({ key: 'email', value: '' })
    errors.push({ key: 'emailOrPassword', value: 'errors.email.invalid' })
    hasError = true
  }

  // Password
  if (!hasError && fields.password.length === 0) {
    errors.push({ key: 'password', value: '' })
    errors.push({ key: 'emailOrPassword', value: 'errors.password.required' })
  }
  if (!hasError && fields.password.length > 0 && fields.password.length < 6) {
    errors.push({ key: 'password', value: '' })
    errors.push({ key: 'emailOrPassword', value: 'errors.password.invalid' })
  }
  return errors
}

export const loginServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'emailOrPassword', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'emailOrPassword', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// SSO

export const loginSSOFormInitialValues = () => {
  return {
    email: {
      value: ''
    }
  }
}

export const loginSSOFormValidate = fields => {
  if (!fields) return []

  const rules = [{ 'email.value': ['required', 'email'] }]
  const messages = {
    'email': {
      'required': 'errors.email.required',
      'email': 'errors.email.invalid'
    }
  }
  const errors = validator(fields, rules, messages, {})

  return errors
}

export const loginSSOFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  return errors
}
