import React from 'react'
import {
  Alert,
  // Button,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCoursesHelpBox = props => {
  return (
    <div className='ta-popup__courses-help-box'>
      <Alert noOffset noBorder label={t('servicesGroups.list.group.heading.helpTooltip.title')} theme='alert'>
        <div>
          {dangerousHTML(t('servicesGroups.list.group.heading.helpTooltip.text'))}
        </div>
        {/* <iframe
          title=t('global.groups')
          width='320'
          height='180'
          src={'https://www.youtube.com/embed/240OR5MVlwE'}
          frameBorder='0'
          allowFullScreen
        />
        <Button as='link' to='/management/courses' isPrimary className='ta-btn ta-start-tutorial-btn'>{t('buttons.startTutorial.label')}</Button> */}
      </Alert>
    </div>
  )
}

export default PopupCoursesHelpBox
