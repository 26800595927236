const reducer = {}

reducer.initialState = {
  list: [],
  isPending: true
}

reducer.handlers = (dispatch, actions, handlers) => ({
  paymentMethodsReset: () => dispatch(actions.PAYMENT_METHODS_RESET),
  paymentMethodsGet: () => dispatch(actions.PAYMENT_METHODS_GET),
  paymentMethodsPopulate: paymentMethods => dispatch(actions.PAYMENT_METHODS_POPULATE, paymentMethods)
})

reducer.PAYMENT_METHODS_RESET = state => reducer.initialState

reducer.PAYMENT_METHODS_GET = state => ({
  ...state,
  isPending: true
})

reducer.PAYMENT_METHODS_POPULATE = (state, paymentMethods) => ({
  ...state,
  list: paymentMethods || [],
  isPending: false
})

export default reducer
