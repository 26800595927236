import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupResourcesAccountCreate = props => {
  return (
    <div className='ta-popup__resources-account-create'>
      <div className='ta-popup__text'>
        {dangerousHTML(t('popup.resourcesAccount.create'))}
      </div>
    </div>
  )
}

export default PopupResourcesAccountCreate
