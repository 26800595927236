import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'

import './SimpleList.css'

const SimpleListItem = props => {
  const {
    to,
    status,
    children,
    className,
    draggable,
    hasHeading
  } = props
  const classNames = ['ta-simple-list-item']
  if (className) classNames.push(className)
  if (status) classNames.push(status)
  if (!to && !draggable) classNames.push('no-pointer')
  if (draggable) classNames.push('draggable')
  if (hasHeading) classNames.push('has-heading')

  const onClick = e => {
    if (!to) return null
    e.preventDefault()
    handlers.navigateToPath(to)
  }

  return (
    <div onClick={onClick} className={classNames.join(' ')}>
      {children}
    </div>
  )
}

SimpleListItem.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string
}

export default SimpleListItem
