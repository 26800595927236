const reducer = {}

reducer.initialState = {
  systemMessages: [],
  pendingSystemMessages: true,
  systemMessagesPage: 1,
  areFetchedSystemMessages: false,
  systemMessagesCount: 0
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // System messages
  systemMessagesSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.SYSTEM_MESSAGES_SUBSCRIPTION_SET, { name, id, data, ts }),
  systemMessagesListGet: () => dispatch(actions.SYSTEM_MESSAGES_LIST_GET),
  systemMessagesListPending: () => dispatch(actions.SYSTEM_MESSAGES_LIST_PENDING),
  systemMessagesListPopulate: (systemMessages, isLoadMore) => dispatch(actions.SYSTEM_MESSAGES_LIST_POPULATE, { systemMessages, isLoadMore }),
  systemMessagesListCounterPopulate: count => dispatch(actions.SYSTEM_MESSAGES_LIST_COUNTER_POPULATE, count),
  systemMessagesListOptionsChange: options => dispatch(actions.SYSTEM_MESSAGES_LIST_OPTIONS_CHANGE, options),
  systemMessagesListPageIncrement: () => dispatch(actions.SYSTEM_MESSAGES_LIST_PAGE_INCREMENT),
  systemMessagesListReady: () => dispatch(actions.SYSTEM_MESSAGES_LIST_READY),
  systemMessagesDecline: id => dispatch(actions.SYSTEM_MESSAGES_DECLINE, id),
  systemMessagesAccept: id => dispatch(actions.SYSTEM_MESSAGES_ACCEPT, id),
  systemMessagesRevoke: id => dispatch(actions.SYSTEM_MESSAGES_REVOKE, id),
  systemMessagesDeleted: id => dispatch(actions.SYSTEM_MESSAGES_DELETED, id),
  systemMessagesAccepted: respone => dispatch(actions.SYSTEM_MESSAGES_ACCEPTED, respone)
})

// System messages
reducer.SYSTEM_MESSAGES_SUBSCRIPTION_SET = state => state

reducer.SYSTEM_MESSAGES_LIST_GET = state => ({
  ...state,
  pendingSystemMessages: true,
  systemMessagesPage: 1
})

reducer.SYSTEM_MESSAGES_LIST_PENDING = state => ({
  ...state,
  pendingSystemMessages: true
})

reducer.SYSTEM_MESSAGES_LIST_OPTIONS_CHANGE = (state, options = {}) => ({
  ...state,
  ...options
})

reducer.SYSTEM_MESSAGES_LIST_POPULATE = (state, { systemMessages = [], isLoadMore = false }) => ({
  ...state,
  systemMessages: (isLoadMore && [...state.systemMessages, ...systemMessages]) || systemMessages,
  pendingSystemMessages: false,
  areFetchedSystemMessages: true,
  systemMessagesPage: state.systemMessagesPage || 1
})

reducer.SYSTEM_MESSAGES_LIST_COUNTER_POPULATE = (state, count) => ({
  ...state,
  systemMessagesCount: count || 0
})

reducer.SYSTEM_MESSAGES_LIST_PAGE_INCREMENT = state => ({
  ...state,
  systemMessagesPage: state.systemMessagesPage + 1
})

reducer.SYSTEM_MESSAGES_LIST_READY = state => ({
  ...state,
  pendingSystemMessages: false
})

reducer.SYSTEM_MESSAGES_ACCEPTED = (state, response) => ({
  ...state,
  systemMessages: state.systemMessages.map(item => item.id === response.id ? response : item)
})

reducer.SYSTEM_MESSAGES_ACCEPT = state => ({
  ...state
})

reducer.SYSTEM_MESSAGES_DECLINE = state => ({
  ...state
})

reducer.SYSTEM_MESSAGES_REVOKE = state => ({
  ...state
})

reducer.SYSTEM_MESSAGES_DELETED = (state, id) => ({
  ...state,
  systemMessages: state.systemMessages.filter(item => item.id !== id),
  systemMessagesCount: state.systemMessagesCount - 1
})

export default reducer
