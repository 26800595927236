import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { ServicePreviewAdvanced } from '../../Beauties'

const ServicePreviewAdvancedWrapper = props => {
  let {
    service,
    company
  } = props
  company = company || {}
  service = service || {}
  const { settings } = company || {}
  const {
    resourceRemindersEmailRecipients,
    customerEmailRemindersMinutes,
    resourceEmailRemindersMinutes
  } = settings || {}

  return (
    <ServicePreviewAdvanced
      {...service}
      companyCustomerEmailRemindersMinutes={customerEmailRemindersMinutes}
      companyResourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
      resourceRemindersEmailRecipients={resourceRemindersEmailRecipients}
    />
  )
}

ServicePreviewAdvancedWrapper.propTypes = {
  service: PropTypes.object.isRequired,
  plan: PropTypes.string
}

const maps = (state, props) => ({
  company: state.company
})

export default connect(maps)(ServicePreviewAdvancedWrapper)
