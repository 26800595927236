import React from 'react'
import {
  Title,
  t
} from '../../../Common'

import {
  SettingsBookingPreviewIcsFileCustomisationCustomer,
  SettingsBookingPreviewIcsFileCustomisationResource
} from '../../../Beauties'

const SettingsBookingPreviewIcsFileCustomisation = ({ settings, customerFields }) => {
  const { icsFileCustomisation } = settings || {}
  let { icsCustomisationCustomer, icsCustomisationResource } = icsFileCustomisation || {}

  const hasIcsFileCustomisationCustomerSetting = (
    icsCustomisationCustomer &&
    icsCustomisationCustomer.customerIcsAddress &&
    icsCustomisationCustomer.customerIcsNotes &&
    icsCustomisationCustomer.customerIcsSubject
  )
  const hasIcsFileCustomisationResourceSetting = (
    icsCustomisationResource &&
    icsCustomisationResource.resourceIcsAddress &&
    icsCustomisationResource.resourceIcsNotes &&
    icsCustomisationResource.resourceIcsSubject
  )

  icsCustomisationCustomer = icsCustomisationCustomer || {}
  icsCustomisationResource = icsCustomisationResource || {}
  const addresses = {
    ACCOUNT_ADDRESS: t('settings.section.bookingLocation.option.companyAddress.preview.label'),
    CUSTOM_ADDRESS: t('settings.section.bookingLocation.option.otherAddress.preview.label'),
    NONE: t('settings.section.bookingLocation.option.noAddress.preview.label')
  }

  const subjects = {
    COMPANY_NAME: t('settings.section.icsFileCustomisation.titleOfBooking.option.companyName.preview.label'),
    CUSTOMER_NAME: t('settings.section.icsFileCustomisation.titleOfBooking.option.customerName.preview.label'),
    BOOKED_SERVICE: t('settings.section.icsFileCustomisation.titleOfBooking.option.bookedService.preview.label'),
    ONLINE_BOOKABLE_RESOURCE: t('settings.section.icsFileCustomisation.titleOfBooking.option.bookedResource.preview.label')
  }

  return (
    <>
      {(hasIcsFileCustomisationCustomerSetting &&
        <>
          <Title theme='striped' label={t('settings.section.icsFileCustomisation.customer.title')} />
          <SettingsBookingPreviewIcsFileCustomisationCustomer
            customerFields={customerFields}
            icsSubject={icsCustomisationCustomer.customerIcsSubject}
            icsAddressObject={icsCustomisationCustomer.customerIcsAddress}
            icsNotes={icsCustomisationCustomer.customerIcsNotes}
            addresses={addresses}
            subjects={subjects}
          />
        </>
      )}
      {(hasIcsFileCustomisationResourceSetting &&
        <>
          <Title theme='striped' label={t('settings.section.icsFileCustomisation.resource.title')} />
          <SettingsBookingPreviewIcsFileCustomisationResource
            customerFields={customerFields}
            icsSubject={icsCustomisationResource.resourceIcsSubject}
            icsAddressObject={icsCustomisationResource.resourceIcsAddress}
            icsNotes={icsCustomisationResource.resourceIcsNotes}
            addresses={addresses}
            subjects={subjects}
          />
        </>
      )}
    </>
  )
}

export default SettingsBookingPreviewIcsFileCustomisation
