import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent
} from '../../Common'
import { getNameByColor } from '../../Utils'

import './TagsStatuses.css'

const TagsListItem = props => {
  const { tag } = props
  const classNames = ['ta-tags__tag']
  const status = getNameByColor(tag.color)

  if (tag.isDeleted) classNames.push('deleted')
  if (tag.isUpdated) classNames.push('updated')
  if (tag.isStalled) classNames.push('is-stalled')

  return (
    <div className={classNames.join(' ')}>
      <SimpleListItem to={`/branches/tags/${tag.id}/preview`} status={status}>
        {tag.name && <SimpleListItemTitle title={tag.name} />}
        {(tag.note &&
          <SimpleListItemContent>
            <ul className='ta-list-inline'>
              <li className='ta-list-inline__item'>
                {tag.note}
              </li>
            </ul>
          </SimpleListItemContent>
        )}
      </SimpleListItem>
    </div>
  )
}

export default TagsListItem
