import React from 'react'

const DottedTimelineItem = props => {
  const { children, type, className } = props
  const classNames = ['ta-dotted-timeline__item', type]
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>{children}</div>
  )
}

export default DottedTimelineItem
