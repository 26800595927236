import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../Common'

import './Sidebar.css'

const Sidebar = props => {
  const {
    position,
    level,
    size,
    status,
    children,
    onClose,
    hideCloseButton,
    noScroll
  } = props
  let classNames = ['ta-sidebar']
  classNames.push(`ta-sidebar__${position}-${level}`, status)
  if (hideCloseButton) classNames.push('hide-close-button')
  if (size === 'large') classNames.push('large')
  if (size === 'big') classNames.push('big')
  if (size === 'medium') classNames.push('medium')
  if (noScroll) classNames.push('no-scroll')

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-sidebar__close-button' onClick={onClose}>
        <FontAwesome5 icon='times' />
      </div>
      <div className='ta-sidebar__content'>
        <div className='ta-sidebar__content-close-button' onClick={onClose} />
        {children}
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  position: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Sidebar
