import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { UserPasswordChangeForm } from '../../Beauties'

class UserPasswordChangeWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.toggleNewPasswordFieldType = this.toggleNewPasswordFieldType.bind(this)
    this.toggleOldPasswordFieldType = this.toggleOldPasswordFieldType.bind(this)
  }

  componentDidMount () {
    handlers.accountFormGet()
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.accountFormPasswordChangeSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  toggleOldPasswordFieldType () {
    const { form } = this.props
    const oldPassword = {
      value: form.oldPassword.value,
      type: form.oldPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { oldPassword })

    return oldPassword.type
  }

  toggleNewPasswordFieldType () {
    const { form } = this.props
    const newPassword = {
      value: form.newPassword.value,
      type: form.newPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { newPassword })

    return newPassword.type
  }

  render () {
    const {
      pending,
      errors,
      oldPasswordFieldType,
      newPasswordFieldType
    } = this.props

    return (
      <>
        <Loader active={pending} />
        <UserPasswordChangeForm
          title={t('user.edit.password.heading')}
          cancelLink='/user'
          errors={errors}
          onSubmit={this.onSubmit}
          pending={pending}
          oldPasswordFieldType={oldPasswordFieldType}
          newPasswordFieldType={newPasswordFieldType}
          toggleOldPasswordFieldType={this.toggleOldPasswordFieldType}
          toggleNewPasswordFieldType={this.toggleNewPasswordFieldType}
        />
      </>
    )
  }
}

UserPasswordChangeWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = (state) => ({
  errors: state.account.errors || [],
  pending: state.account.pendingForm,
  form: state.forms.account || {},
  oldPasswordFieldType:
    (state.forms.account &&
      state.forms.account.oldPassword &&
      state.forms.account.oldPassword.type) ||
    'password',
  newPasswordFieldType:
    (state.forms.account &&
      state.forms.account.newPassword &&
      state.forms.account.newPassword.type) ||
    'password'
})

export default connect(maps)(UserPasswordChangeWrapper)
