import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Range } from 'rc-slider'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, Error, FontAwesome5 } from '../../../Common'

import 'rc-slider/assets/index.css'
import './RangeSlider.css'

class RangeSlider extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onChange (value) {
    const { form, formName, name } = this.props
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  render () {
    const {
      left,
      leftLabel,
      right,
      rightLabel,
      name,
      hintText,
      disabled,
      hideError,
      min,
      max,
      pushable,
      defaultValue,
      arrow,
      arrowStep,
      form = {}
    } = this.props
    const steps = max - min
    const stepSize = 100 / steps
    const leftOffset = form.value && form.value[0] ? stepSize * form.value[0] : 0
    const rightOffset = form.value && form.value[1] ? stepSize * form.value[1] : 100
    const centerOffset = (leftOffset + rightOffset) / 2

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className='ta-range-slider'>
        <div className='ta-range-slider__content'>
          <div className='ta-range-slider__body'>
            {(left && form.value &&
            <div className='ta-range-slider__left-value' style={{ left: `${leftOffset}%` }}>
              {form.value[0]}:00
            </div>
            )}
            <Range
              min={min || form.min}
              max={max || form.max}
              pushable={pushable || form.pushable}
              defaultValue={defaultValue || form.defaultValue}
              value={form.value || [0, 1]}
              disabled={disabled || form.disabled}
              onChange={this.onChange}
            />
            {(arrow && form.value && form.value[1] - form.value[0] > arrowStep &&
            <div className='ta-range-slider__center' style={{ left: `${centerOffset}%` }}>
              <FontAwesome5 icon='arrows-h' />
            </div>
            )}
            {(right && form.value &&
            <div className='ta-range-slider__right-value' style={{ left: `${rightOffset}%` }}>
              {form.value[1]}:00
            </div>
            )}
          </div>
          {((leftLabel || rightLabel) &&
          <div className='ta-range-slider__label-container'>
            <div>{leftLabel}</div>
            <div>{rightLabel}</div>
          </div>
          )}
        </div>
        {(hintText &&
        <div className='ta-form-control__hint'>{hintText}</div>
        )}
        {(!hideError &&
        <Error name={name} />
        )}
      </div>
    )
  }
}

RangeSlider.propTypes = {
  name: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: state.forms[props.formName][props.name] || { value: '' }
})

export default feedContextInProps(connect(maps)(RangeSlider), FormContext)
