import {
  userFormTransform,
  userPasswordChangeFormTransform,
  userLanguageChangeFormTransform,
  userSecurityFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  pendingSecurityForm: false,
  errorToShow: null
}

reducer.handlers = (dispatch, actions, handlers) => ({

  // ONE
  userPreviewGet: (id) => dispatch(actions.USER_PREVIEW_GET, id),
  userPreviewPopulate: user => dispatch(actions.USER_PREVIEW_POPULATE, { user }),
  userPreviewReady: () => dispatch(actions.USER_PREVIEW_READY),
  userDelete: id => dispatch(actions.USER_DELETE, id),

  //  LIST
  userGet: () => dispatch(actions.USER_LIST_GET),
  userPopulate: user => dispatch(actions.USER_LIST_POPULATE, { user }),

  // FORM
  userFormGet: userId => dispatch(actions.USER_FORM_GET, userId),
  userFormPopulate: (user) => {
    handlers.formSet('user', userFormTransform(user))
    handlers.userFormReady()
  },
  userPasswordChangeFormGet: userId => dispatch(actions.USER_FORM_GET, userId),
  userPasswordChangeFormPopulate: (userId) => {
    handlers.formSet('userPasswordChange', userPasswordChangeFormTransform(userId))
    handlers.userFormReady()
  },
  userFormReady: () => dispatch(actions.USER_FORM_READY),
  userFormSave: (user, userId, scrollToError = () => { }) => {
    dispatch(actions.USER_FORM_SAVE, { user, userId, scrollToError })
  },
  userFormPasswordChangeSave: (user, userId, scrollToError = () => { }) => {
    dispatch(actions.USER_FORM_PASSWORD_CHANGE_SAVE, { user, userId, scrollToError })
  },
  userLanguageChangeFormGet: () => dispatch(actions.USER_LANGUAGE_FORM_GET),
  userLanguageChangeFormPopulate: (user) => {
    handlers.formSet('userLanguageChange', userLanguageChangeFormTransform(user))
    handlers.userLanguageFormReady()
  },
  userLanguageFormReady: () => dispatch(actions.USER_LANGUAGE_FORM_READY),
  userLanguageFormSave: (user, scrollToError = () => {}) => {
    dispatch(actions.USER_LANGUAGE_FORM_CHANGE_SAVE, { user, scrollToError })
  },
  userSecurityFormGet: () => dispatch(actions.USER_SECURITY_FORM_GET),
  userSecurityFormPopulate: (user, errorToShow) => {
    handlers.formSet('userSecurity', userSecurityFormTransform(user))
    if (errorToShow) {
      handlers.formErrorsSet('userSecurity', errorToShow)
      handlers.userSecurityErrorToShow(null)
    }
    handlers.userSecurityFormReady()
  },
  userSecurityFormReady: () => dispatch(actions.USER_SECURITY_FORM_READY),
  userSecurityErrorToShow: error => dispatch(actions.USER_SECURITY_ERROR_TO_SHOW, { error })
})

// ONE
reducer.USER_PREVIEW_GET = (state) => ({
  ...state,
  pendingPreview: true
})
reducer.USER_PREVIEW_POPULATE = (state, { user = {} }) => ({
  ...state,
  user: user
})
reducer.USER_PREVIEW_READY = (state) => ({
  ...state,
  pendingPreview: false
})
reducer.USER_DELETE = state => ({
  ...state,
  pendingPreview: true
})

// LIST
reducer.USER_LIST_GET = (state) => ({
  ...state,
  pending: true,
  areFetched: true
})
reducer.USER_LIST_POPULATE = (state, { user = [] }) => ({
  ...state,
  userList: user,
  pending: false
})

// FORM
reducer.USER_FORM_GET = (state) => ({
  ...state,
  pendingForm: true
})
reducer.USER_FORM_READY = (state) => ({
  ...state,
  pendingForm: false
})
reducer.USER_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})
reducer.USER_FORM_PASSWORD_CHANGE_SAVE = state => ({
  ...state,
  pendingForm: true
})
reducer.USER_LANGUAGE_FORM_GET = state => ({
  ...state,
  pendingLanguageForm: true
})
reducer.USER_LANGUAGE_FORM_READY = state => ({
  ...state,
  pendingLanguageForm: false
})
reducer.USER_LANGUAGE_FORM_CHANGE_SAVE = state => ({
  ...state,
  pendingLanguageForm: true
})

reducer.USER_SECURITY_FORM_GET = state => ({
  ...state,
  pendingSecurityForm: true
})
reducer.USER_SECURITY_FORM_READY = state => ({
  ...state,
  pendingSecurityForm: false
})

reducer.USER_SECURITY_ERROR_TO_SHOW = (state, { error }) => ({
  ...state,
  errorToShow: error
})

export default reducer
