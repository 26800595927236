import React from 'react'
import { FontAwesome5 } from '../../Common'

import './InfiniteScrollLoader.css'

const InfiniteScrollLoader = props => {
  return (
    <div className='ta-infinite-scroll-loader'>
      <FontAwesome5 icon='spinner' spin />
    </div>
  )
}

export default InfiniteScrollLoader
