import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader, t, isRouteDisabledByCustomisations, isRouteDisabledByPermissions } from '../../Common'
import { BranchesForm } from '../../Beauties'
import { sortAlphabetically } from '../../Utils'
import { ALLOWED_REGIONS } from '../../Settings'

class BranchesAddWrapper extends Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const isRouteDisabled = isRouteDisabledByCustomisations() || isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.branchesFormGet({})
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.branchesFormSaveCreate(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const {
      pending,
      tags,
      locales,
      timezones,
      form,
      enterpriseRegion
    } = this.props
    const locale = form.locale ? form.locale.value : null

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <BranchesForm
            title={t('branches.list.addNew')}
            type='add'
            cancelLink='/branches'
            onSubmit={this.onSubmit}
            tags={tags}
            locale={locale}
            locales={locales}
            timezones={timezones}
            enterpriseRegion={ALLOWED_REGIONS.includes(enterpriseRegion) ? null : enterpriseRegion}
          />
        )}
      </>
    )
  }
}

BranchesAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  form: state.forms.branches || {},
  pending: (state.branches && state.branches.pendingForm),
  tags: (state.tags && state.tags.list.sort(sortAlphabetically)) || [],
  locales: state.staticData.locales || [],
  timezones: state.staticData.timezones || [],
  enterpriseRegion: (state.account && state.account.enterprise && state.account.enterprise.region) || 'EUROPE'
})

export default connect(maps)(BranchesAddWrapper)
