import { createSelector } from '../../../Utils'

export const appSelector = state => state.app

export const appLocaleSelector = createSelector(
  appSelector,
  app => (app || {}).locale
)

export const appFieldSelector = createSelector(
  appSelector,
  (_, props) => props.field,
  (app, field) => (app || {})[field]
)
