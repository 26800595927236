import React from 'react'
import {
  FixedContentHeader,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  FontAwesome5,
  FixedContent,
  Title,
  FormSection,
  BorderedBox,
  HoverPopup,
  ListItem,
  Button,
  Blur,
  t
} from '../../../Common'
import { CustomerPreviewDetailsTagItem } from '../../../Beauties'
import { PopupTriggerWrapper } from '../../../Beasts'
import { dangerousHTML } from '../../../Utils'

import './CustomersSettings.css'

const CustomersSettings = props => {
  let {
    tags,
    EUGDPRRules: eugdprRules,
    rawSettings,
    settingsRead,
    settingsWrite
  } = props
  tags = tags || []
  eugdprRules = eugdprRules || []
  rawSettings = rawSettings || {}
  const eugdprRulesEnabled = eugdprRules.length > 0

  return (
    <div className='ta-customers-settings'>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='cogs' iconTheme='grey' label={t('global.customers')}>
            <PopupTriggerWrapper name='customers-settings-help-box' position='bottom' extraClassName='ta-help-box'>
              <Button as='link' className='ta-title__link' external>
                {t('global.help')} <FontAwesome5 icon='lightbulb' />
              </Button>
            </PopupTriggerWrapper>
            {(settingsWrite &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.settings.edit')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/settings/customers/edit' isPrimary icon='pencil' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={settingsRead ? 'inactive' : ''} active={!settingsRead} icon='ban' label={t('global.accessDenied')}>
            <FormSection>
              <Title size='m' isCompact label={t('settings.section.customers.eugdpr.heading')} icon='eraser' />
              <ListItem>
                {(rawSettings.EUGDPRRules === null &&
                  <>
                    <strong>{t('global.defaultValues')}</strong>
                    <br />
                  </>
                )}
                <strong>{eugdprRulesEnabled ? t('global.enabled') : t('global.disabled')}</strong>
                <br />
                {(eugdprRulesEnabled
                  ? t('settings.section.customers.eugdpr.enabled.infoText')
                  : t('settings.section.customers.eugdpr.disabled.infoText')
                )}
                {(eugdprRulesEnabled &&
                  eugdprRules.map((item, index) => {
                    let { customerType, frequency, frequencyUnit, customerTagId } = item
                    customerType = customerType || ''
                    frequency = frequency || ''
                    const tag = tags.find(item => item.id === customerTagId) || {}
                    let ruleTitleKey = `settings.section.customers.eugdpr.enabled.${customerType.toLowerCase()}.title`
                    if (customerType.toLowerCase() === 'external_id') ruleTitleKey = 'settings.section.customers.eugdpr.enabled.externalId.title'
                    else if (customerType.toLowerCase() === 'manually_created') ruleTitleKey = 'settings.section.customers.eugdpr.enabled.manuallyCreated.title'

                    return (
                      <BorderedBox topOffset key={index}>
                        <strong>{t(ruleTitleKey)}{' '}</strong>
                        {(customerType === 'TAG' &&
                          <CustomerPreviewDetailsTagItem name={tag.name} color={tag.color} />
                        )}
                        <br />
                        {dangerousHTML(
                          t('settings.section.customers.eugdpr.enabled.rule.infoText', [
                            { key: 'PERIOD_COUNT', value: frequency }, { key: 'PERIOD_LABEL', value: t(`global.${frequencyUnit.toLowerCase()}.counter`) }
                          ])
                        )}
                      </BorderedBox>
                    )
                  })
                )}
              </ListItem>
            </FormSection>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomersSettings
