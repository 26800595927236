import moment from 'moment'
import momenttz from 'moment-timezone'
import { payloads$, actions, handlers, globalActions, store, store$ } from '../../../Store'
import { q } from '../../API'
import {
  createSaveTransform,
  editSaveTransform,
  formValidateCreate,
  formValidateEdit,
  serverErrorsTransform,
  branchesResourceSaveTransform,
  branchesResourceFormValidate,
  branchesResourceFormServerErrorsTransform,
  branchesResourcePasswordChangeFormValidate,
  branchesResourcePasswordChangeSaveDataTransform,
  branchesResourcePasswordChangeFormServerErrorsTransform,
  branchesServiceSaveTransform,
  branchesServiceFormValidate,
  branchesServiceFormServerErrorsTransform,
  branchesGroupSaveTransform,
  branchesGroupFormValidate,
  branchesGroupFormServerErrorsTransform,
  branchesCustomerSaveTransform,
  branchesCustomerFormValidate,
  branchesImportFormValidate,
  branchesImportFormSaveTransform,
  branchesImportFormServerErrorsTransform,
  branchResourcesImportFormValidate,
  branchResourcesImportFormSaveTransform,
  branchResourcesImportFormServerErrorsTransform,
  branchResourceCategoriesImportFormValidate,
  branchResourceCategoriesImportFormSaveTransform,
  branchResourceCategoriesImportFormServerErrorsTransform,
  branchServicesImportFormValidate,
  branchServicesImportFormSaveTransform,
  branchServicesImportFormServerErrorsTransform,
  branchServiceCategoriesImportFormValidate,
  branchServiceCategoriesImportFormSaveTransform,
  branchServiceCategoriesImportFormServerErrorsTransform,
  branchesServiceCombinationSaveTransform,
  branchesServiceCombinationFormValidate,
  branchesServiceCombinationFormServerErrorsTransform,
  branchesServiceTransform,
  branchGroupsImportFormValidate,
  branchGroupsImportFormSaveTransform,
  branchGroupsImportFormServerErrorsTransform,
  branchGroupCategoriesImportFormValidate,
  branchGroupCategoriesImportFormSaveTransform,
  branchGroupCategoriesImportFormServerErrorsTransform,
  branchesCreateFormServerErrorsTransform,
  branchesEditFormServerErrorsTransform,
  branchesDeleteFormServerErrorsTransform,
  branchDeleteFormValidate,
  branchesStatisticsMostBookedResourcesFilterFormPopulate,
  branchMarketingFormPopulate,
  formatBranchStatisticsFilter,
  transformStatistics,
  branchExportFormTransform,
  branchExportFormValidate,
  branchExportFormSaveTransform,
  branchExportFormServerErrorsTransform,
  branchBookingExportFormValidate,
  branchBookingExportFormSaveTransform
} from './utils'
import { sortByOrderIndex, getOS, translateServerCode } from '../../../Utils'
import { t } from '../../../Common'
import { WEBAPP_URL, DEFAULT_LOCALE, ALLOWED_REGIONS, countries } from '../../../Settings'
import { distinctUntilChanged, map } from 'rxjs'

let isFetching = false

globalActions.populateBranches = async forceFetch => {
  const state = store.getState()
  const { areFetched } = state.branches || {}
  if (areFetched && !forceFetch) return state.branches.list || []
  isFetching = true
  const tags = await globalActions.populateTags()
  const branches = await q('getBranches')
  const { error } = branches || {}
  if (error) {
    isFetching = false
    return error
  }
  handlers.branchesListPopulate(branches, tags)
  isFetching = false
  return branches
}

// GET LIST
payloads$(actions.BRANCHES_LIST_GET)
  .subscribe(async forceFetch => {
    await globalActions.populateBranches(forceFetch)
  })

// FORM
payloads$(actions.BRANCHES_FORM_GET)
  .subscribe(async ({ id }) => {
    await globalActions.populateTags()
    await globalActions.populateBranches()
    const { branches, account } = store.getState()
    const { enterprise } = account || {}
    const { region: enterpriseRegion } = enterprise || {}
    const isCustomRegion = !ALLOWED_REGIONS.includes(enterpriseRegion)
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    let selectedBranch = {}
    if (id) selectedBranch = branchesList.find(branch => branch.id === id) || {}
    const data = {
      ...selectedBranch,
      enterpriseRegion: isCustomRegion && !id ? enterpriseRegion : null
    }
    handlers.branchesFormPopulate(data)
  })

// PREVIEW
payloads$(actions.BRANCHES_PREVIEW_GET)
  .subscribe(async ({ id }) => {
    const state = store.getState()
    let branches = state.branches.list || []
    if (branches.length === 0 && !isFetching) branches = await globalActions.populateBranches()
    const branch = branches.find(item => item.id === id)
    const { region = 'EUROPE' } = branch || {}
    const result = await q('getBranchPreviewDetails', { companyId: id, region })
    const { error } = result || {}
    if (error) {
      handlers.navigateToPath('/branches')
      return
    }
    handlers.branchesPreviewPopulate(result)
  })

payloads$(actions.BRANCHES_PREVIEW_COMPANY_CONFIRM)
  .subscribe(async ({ resourceId, branchId, companyId, userId, region, email }) => {
    const confirmCompany = await q('confirmBranchUserAccount', { companyId, region, userId, email }) || { error: { code: t('errors.somethingWentWrong') } }
    const { error } = confirmCompany

    if (!error) {
      handlers.branchesPreviewCompanyConfirmed({ resourceId, branchId })
    } else {
      const { code: errorCode } = error || {}
      handlers.branchesPreviewCompanyConfirmed({ resourceId, branchId, confirmationError: translateServerCode(errorCode) })
    }
  })

payloads$(actions.BRANCHES_PREVIEW_CONFIRMATION_RESEND)
  .subscribe(async ({ resourceId, companyId, userId, region }) => {
    const sendConfirmationEmail = await q('sendBranchAccountConfirmationEmail', { companyId, region, userId }) || { errors: [{ message: t('errors.somethingWentWrong') }] }
    const { errors } = sendConfirmationEmail

    if (!errors) {
      handlers.branchesPreviewConfirmationResendSended({ resourceId, branchId: companyId })
    } else {
      console.error('ERRORS: ', errors)
    }
  })

payloads$(actions.BRANCHES_PREVIEW_PASSWORD_RESET_SEND)
  .subscribe(async ({ email, resourceId, branchId }) => {
    const sendPasswordReset = await q('sendPasswordResetCodeEnterprise', { email }) || { errors: [{ message: t('errors.somethingWentWrong') }] }
    const { errors } = sendPasswordReset

    if (!errors) {
      handlers.branchesPreviewPasswordResetSended({ resourceId, branchId })
    } else {
      console.error('ERRORS: ', errors)
    }
  })

payloads$(actions.BRANCH_RESOURCE_DELETE_FORM_SAVE)
  .subscribe(async ({ resource, scrollToError }) => {
    if (!resource.id) return
    const state = store.getState()
    const { router, branches } = state
    const { id: branchId } = router.data || {}
    const { list = [] } = branches
    const branch = list.find(item => item.id === branchId)
    const { resources } = branch.resources || {}
    const selectedResource = resources.find(item => item.id === resource.id.id || '')
    if (!selectedResource) return
    if (resource.name.value.toLowerCase() !== selectedResource.name.toLowerCase()) {
      handlers.formErrorsSet('branchResourceDelete', [{ key: 'name', value: 'errors.resourceNameNotMatch' }])
      handlers.branchResourceDeleteFormReady()
      return
    }
    handlers.branchResourceDelete(resource.id)
    handlers.popupHide('branches-resources-delete')
    setTimeout(() => { handlers.popupSet() }, 500)
  })

payloads$(actions.BRANCH_RESOURCE_DELETE)
  .subscribe(async ({ companyId, id, externalId }) => {
    if (!id) return
    const state = store.getState()
    const branches = state.branches.list || []
    const branchId = (state.router.data && state.router.data.id) || ''
    const branch = branches.find(item => item.id === branchId) || {}
    const { region } = branch
    const { error } = await q('deleteBranchResource', { companyId, region, id, externalId }) || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      if (error.code === 'ResourceHasFutureBookings') {
        handlers.formErrorsSet('branchResourcePreview', [{ key: 'globalErrors', value: 'errors.resourceHasFutureEvents' }])
        handlers.branchResourceDeleteFailed()
        setTimeout(() => handlers.formErrorsSet('branchResourcePreview', []), 6000)
      }
      return
    }
    handlers.branchResourceDeleted({ id, branchId })
  })

payloads$(actions.BRANCHES_RESOURCE_FORM_GET)
  .subscribe(async (id) => {
    if (!id) return
    const state = store.getState()
    const { router, account } = state
    const { data } = router || {}
    const { branchId } = data || {}
    const { email: userEmail } = account || {}
    const branch = state.branches.list.find(item => item.id === branchId) || {}
    const { resources = [], resourceCategories = [] } = branch.resources || await q('getBranchResources', { companyId: branch.id, region: branch.region })
    const {
      plan = 'CLASSIC',
      ownerEmail = ''
    } = branch || {}
    const { query = {} } = state.router
    const myself = resources.find(resource => resource.email === state.account.email || '') || null
    const queryCategory = (query.c && resourceCategories.find(category => category.id === query.c)) || null
    const defaultCategory = resourceCategories.find(category => category.isDefault) || {}
    let category = queryCategory || defaultCategory || {}
    const selectedResource = resources.find(resource => resource.id === id) || {}
    const resourcesCategory = resourceCategories.find(category => category.id === selectedResource.categoryId) || {}
    const { error } = selectedResource
    if (error) {
      handlers.navigateToPath(`/branches/${branch.id}/perview@@resources`)
      return
    }
    category = resourcesCategory || defaultCategory
    category.name = category.name === 'default' ? t('resources.list.sectionDefault.title') : category.name
    const isOwner = ownerEmail === selectedResource.email
    handlers.branchesResourceFormPopulate({
      ...selectedResource,
      plan,
      ownerEmail,
      myself,
      category,
      categories: resourceCategories.filter(item => item.isDependency === category.isDependency) || [],
      companyTimezone: branch.timezone,
      availableResources: resources,
      availableCategories: resourceCategories,
      defaultCategoryName: t('resources.list.sectionDefault.title'),
      isOwner,
      userEmail
    })
  })

payloads$(actions.BRANCHES_RESOURCE_FORM_SAVE)
  .subscribe(async ({ branch, resource, scrollToError }) => {
    const state = store.getState()
    const { router, account } = state
    const { data } = router || {}
    const { branchId } = data || {}
    const { email: userEmail } = account || {}
    const errors = branchesResourceFormValidate(resource)
    const { plan } = branch || {}
    resource.plan = plan
    const transformedResource = branchesResourceSaveTransform({ ...resource, userEmail })
    if (plan === 'CLASSIC') {
      delete transformedResource.color
      delete transformedResource.externalId
    }
    delete transformedResource.companyId
    if (errors.length) return branchesSetResourceFormSaveErrors(errors, scrollToError)
    const savedResource = await q('saveBranchResource', { companyId: resource.companyId, region: branch.region, resource: transformedResource })
    const { error } = savedResource
    if (error) return branchesSetResourceFormSaveErrors(branchesResourceFormServerErrorsTransform(error), scrollToError)
    handlers.branchesResourceUpdate({ branchId, resource: savedResource })
    handlers.navigateToPath(`/branches/${branchId}/preview@@resources`)
  })

const branchesSetResourceFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesResource', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesResourceFormReady()
}

payloads$(actions.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_GET)
  .subscribe(() => {
    handlers.branchesResourcePasswordChangeFormPopulate()
  })

// Change password save
payloads$(actions.BRANCHES_RESOURCE_PASSWORD_CHANGE_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchesResourcePasswordChangeFormValidate(form)
    if (errors.length) return setBranchesResourcePasswordChangeFormSaveErrors(errors, scrollToError)
    const transformedData = branchesResourcePasswordChangeSaveDataTransform(form) || {}
    const state = store.getState()
    let { router, branches } = state
    router = router || {}
    const { list: branchList } = branches || {}
    const { branchId, id } = router.data || {}
    const branch = (branchList || []).find(item => item.id === branchId) || {}
    const { resources } = branch.resources || {}
    const resource = (resources || []).find(item => item.id === id) || {}
    const { userId } = resource.account || {}
    const data = await q('resetUserPasswordBranch', {
      userId,
      userPassword: transformedData.userPassword,
      enterprisePassword: transformedData.enterprisePassword
    })
    const { error } = data || { error: {} }
    if (error) return setBranchesResourcePasswordChangeFormSaveErrors(branchesResourcePasswordChangeFormServerErrorsTransform(error), scrollToError)
    handlers.navigateToPath(`/branches/${branchId}/preview@@resources`)
  })

const setBranchesResourcePasswordChangeFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesResourcePasswordChange', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesResourcePasswordChangeFormReady()
}

payloads$(actions.BRANCH_SERVICE_DELETE)
  .subscribe(async ({ companyId, id, externalId }) => {
    if (!id) return
    const state = store.getState()
    const branches = state.branches.list || []
    const branchId = (state.router.data && state.router.data.id) || ''
    const branch = branches.find(item => item.id === branchId) || {}
    const { region } = branch
    const { errors } = await q('deleteBranchService', { companyId, region, id, externalId })
    if (!errors) {
      handlers.branchServiceDeleted({ id, branchId })
    } else {
      console.error('ERRORS: ', errors)
    }
  })

// Services
payloads$(actions.BRANCHES_SERVICE_FORM_GET)
  .subscribe(async ({ id, branch }) => {
    if (!id) return
    const state = store.getState()
    let { query } = state.router || {}
    query = query || {}
    const {
      services: branchServices,
      resources: branchResources,
      plan,
      settings: companySettings
    } = branch || {}
    let { services, serviceCategories } = branchServices || {}
    services = services || []
    serviceCategories = serviceCategories || []
    const queryCategory = (query.c && serviceCategories.find(category => category.id === query.c)) || null
    const defaultCategory = serviceCategories.find(category => category.isDefault) || {}
    let category = queryCategory || defaultCategory
    const selectedService = branchesServiceTransform(services.find(service => service.id === id) || {})
    const serviceCategory = serviceCategories.find(category => category.id === selectedService.categoryId) || null
    category = serviceCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    let { resources, resourceCategories } = branchResources || {}
    resources = resources || []
    resourceCategories = resourceCategories || []
    handlers.branchesServiceFormPopulate({
      ...selectedService,
      isGlobal: !!selectedService.internalId,
      category,
      availableResources: resources,
      availableCategories: resourceCategories,
      plan,
      companySettings
    })
  })

payloads$(actions.BRANCHES_SERVICE_FORM_SAVE)
  .subscribe(async ({ branch, service, scrollToError }) => {
    const state = store.getState()
    const { data } = state.router
    const { branchId } = data || {}
    let { locale, services: branchServices } = branch || {}
    locale = locale || 'en-gb'
    branchServices = branchServices || {}
    let { services } = branchServices || {}
    services = services || []
    const serviceExternalIds = services.filter(item => item.id !== service.id).map(service => service.externalId).filter(Boolean) || []
    const { staticData } = state
    let { countries } = staticData || {}
    countries = countries || []
    const countryCode = locale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    const errors = branchesServiceFormValidate(service, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode, serviceExternalIds)
    const { plan, timezone } = branch || {}
    const transformedService = branchesServiceSaveTransform(service, timezone)
    if (plan === 'CLASSIC') {
      delete transformedService.color
      delete transformedService.externalId
    }
    delete transformedService.companyId
    if (errors.length) return branchesSetServiceFormSaveErrors(errors, scrollToError)
    const savedService = await q('saveBranchService', { companyId: service.companyId, region: branch.region, service: transformedService })
    const { error } = savedService
    if (error) return branchesSetServiceFormSaveErrors(branchesServiceFormServerErrorsTransform(error), scrollToError)
    handlers.navigateToPath(`/branches/${branchId}/preview@@services`)
  })

const branchesSetServiceFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesService', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesServiceFormReady()
}

// Service Combination
payloads$(actions.BRANCHES_SERVICE_COMBINATION_FORM_GET)
  .subscribe(async ({ service, branch }) => {
    const state = store.getState()
    let { query, data } = state.router || {}
    query = query || {}
    data = data || {}
    const { branchId } = data || {}
    if (!service || !branch) return handlers.navigateToPath(`/branches/${branchId}/preview@@services`)
    branch = branch || {}
    service = service || {}
    let { settings: companySettings, services: branchServices } = branch || {}
    branchServices = branchServices || {}
    let { services: list, serviceCategories: categoriesList } = branchServices || {}
    list = list || []
    categoriesList = categoriesList || []
    const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || null
    const defaultCategory = categoriesList.find(category => category.isDefault)
    let category = queryCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    const categorizedServices = categoriesList
      .map(category => ({
        name: category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name,
        items: list
          .filter(item =>
            item.categoryId === category.id &&
            item.durationsPattern &&
            item.durationsPattern.length === 1 &&
            !item.isCombination
          )
          .map(item => ({ label: item.name, value: item.id }))
      }))
      .filter(category => category.items.length > 0)
    const serviceCategory = categoriesList.find(category => category.id === service.categoryId) || null
    category = serviceCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
    handlers.branchesServiceCombinationFormPopulate({
      ...service,
      isGlobal: !!service.internalId,
      category,
      categorizedServices,
      companySettings
    })
  })

payloads$(actions.BRANCHES_SERVICE_COMBINATION_FORM_SAVE)
  .subscribe(async ({ branch, service, scrollToError }) => {
    const state = store.getState()
    let {
      id: companyId,
      timezone,
      locale,
      region,
      services: branchServices
    } = branch || {}
    locale = locale || 'en-gb'
    branchServices = branchServices || {}
    let { services } = branchServices || {}
    services = services || []
    const serviceExternalIds = services.filter(item => item.id !== service.id).map(service => service.externalId).filter(Boolean) || []
    const { staticData } = state
    let { countries } = staticData || {}
    countries = countries || []
    const countryCode = locale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    service.companyTimezone = timezone
    service.allServices = services
    const errors = branchesServiceCombinationFormValidate(
      service,
      stripeMinAmountOnlinePaymentsCountryCurrency,
      locale,
      currencyCode,
      serviceExternalIds
    )
    if (errors.length) return setBranchesServiceCombinationFormSaveErrors(errors, scrollToError)
    const serviceForSave = branchesServiceCombinationSaveTransform(service)
    const savedService = await q('saveBranchService', { companyId, region, service: serviceForSave })
    const { error } = savedService
    if (error) return setBranchesServiceCombinationFormSaveErrors(branchesServiceCombinationFormServerErrorsTransform(error, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode), scrollToError)
    handlers.serviceUpdate({ ...savedService, isComplete: true })
    handlers.navigateToPath(`/branches/${companyId}/preview@@services`)
  })

const setBranchesServiceCombinationFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesService', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesServiceCombinationFormReady()
}

// Preview resources
payloads$(actions.BRANCH_PREVIEW_RESOURCES_GET)
  .subscribe(async id => {
    const state = store.getState()
    let branches = state.branches.list || []
    if (branches.length === 0) branches = await globalActions.populateBranches()
    const branch = branches.find(item => item.id === id)
    const { areResourcesFetched, region } = branch || {}
    if (areResourcesFetched) return
    const result = await q('getBranchResources', { companyId: id, region })
    const { error } = result || {}
    if (error) {
      handlers.navigateToPath('/branches')
      return
    }
    handlers.branchPreviewResourcesPopulate({ id, resources: result })
  })

// Preview services
payloads$(actions.BRANCH_PREVIEW_SERVICES_GET)
  .subscribe(async id => {
    const state = store.getState()
    let branches = state.branches.list || []
    if (branches.length === 0) branches = await globalActions.populateBranches()
    const branch = branches.find(item => item.id === id)
    const { arServicesFetched, region } = branch || {}
    if (arServicesFetched) return
    const result = await q('getBranchServices', { companyId: id, region })
    const { error } = result || { error: {} }
    if (error || result.length === 0) {
      handlers.navigateToPath('/branches')
      return
    }
    handlers.branchPreviewServicesPopulate({ id, services: result })
  })

// Preview groups
payloads$(actions.BRANCH_PREVIEW_GROUPS_GET)
  .subscribe(async id => {
    const state = store.getState()
    let branches = state.branches.list || []
    if (branches.length === 0) branches = await globalActions.populateBranches()
    const branch = branches.find(item => item.id === id)
    const { arGroupsFetched, region } = branch || {}
    if (arGroupsFetched) return
    const result = await q('getBranchGroups', { companyId: id, region })
    const { error } = result || { error: {} }
    if (error || result.length === 0) {
      handlers.navigateToPath('/branches')
      return
    }
    const {
      courses,
      courseCategories
    } = result || {}
    handlers.branchPreviewGroupsPopulate({ id, courses, courseCategories })
  })

payloads$(actions.BRANCHES_PREVIEW_ACCOUNT_UNLOCK)
  .subscribe(async ({ resourceId, userId }) => {
    if (!userId) return
    const result = await q('unblockBranchUser', { userId })
    const { error } = result || { error: {} }
    if (error) return
    const state = store.getState()
    const branchId = (state.router.data && state.router.data.id) || ''
    handlers.branchesPreviewAccountUnlockReady({ resourceId, branchId })
  })

payloads$(actions.BRANCH_COURSE_DELETE)
  .subscribe(async ({ companyId, id, externalId }) => {
    if (!id) return
    const state = store.getState()
    const branches = state.branches.list || []
    const branchId = (state.router.data && state.router.data.id) || ''
    const branch = branches.find(item => item.id === branchId) || {}
    const { region } = branch
    const { errors } = await q('deleteBranchCourse', { companyId, region, id, externalId })
    if (!errors) {
      handlers.branchCourseDeleted({ id, branchId })
    } else {
      console.error('ERRORS: ', errors)
    }
  })

payloads$(actions.BRANCHES_GROUP_FORM_GET)
  .subscribe(async (id) => {
    if (!id) return
    const state = store.getState()
    const { data } = state.router
    const { branchId } = data || {}
    const branch = state.branches.list.find(item => item.id === branchId) || {}
    if (!branch.id) return handlers.navigateToPath(`/branches/${branchId}/preview@@groups`)
    const { query = {} } = state.router
    let { settings: companySettings, groups: branchGroups } = branch || {}
    companySettings = companySettings || {}
    branchGroups = branchGroups || {}
    let { courses = [], courseCategories = [] } = branchGroups || {}
    courses = courses || []
    courseCategories = courseCategories || []
    const queryCategory = (query.c && courseCategories.find(category => category.id === query.c)) || null
    const defaultCategory = courseCategories.find(category => category.isDefault) || {}
    let category = queryCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name
    const selectedGroup = courses.find(group => group.id === id) || {}
    const groupsCategory = courseCategories.find(category => category.id === selectedGroup.categoryId) || null
    const { error } = selectedGroup
    if (error) {
      handlers.navigateToPath(`/branches/${branch.id}/perview@@groups`)
      return
    }
    category = groupsCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name
    const hasCategoriesLoaded = !!(branch.resources && branch.resources.resourceCategories)
    const { resources = [], resourceCategories = [] } = hasCategoriesLoaded ? branch.resources : await q('getBranchResources', { companyId: branch.id, region: branch.region })
    handlers.branchesGroupFormPopulate({
      ...selectedGroup,
      isGlobal: !!selectedGroup.internalId,
      category,
      availableResources: resources,
      availableCategories: resourceCategories,
      companySettings
    })
  })

payloads$(actions.BRANCHES_GROUP_FORM_SAVE)
  .subscribe(async ({ branch, group, scrollToError }) => {
    const state = store.getState()
    let { locale, courses: branchCourses } = branch || {}
    locale = locale || 'en-gb'
    branchCourses = branchCourses || {}
    let { courses } = branchCourses || {}
    courses = courses || []
    const courseExternalIds = courses.filter(item => item.id !== group.id).map(course => course.externalId).filter(Boolean) || []
    const { staticData } = state
    let { countries } = staticData || {}
    countries = countries || []
    const countryCode = locale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    const errors = branchesGroupFormValidate(
      group,
      stripeMinAmountOnlinePaymentsCountryCurrency,
      locale,
      currencyCode,
      courseExternalIds
    )
    const { plan } = branch || {}
    const transformedGroup = branchesGroupSaveTransform(group)
    if (plan === 'CLASSIC') {
      delete transformedGroup.color
      delete transformedGroup.externalId
    }
    delete transformedGroup.companyId
    if (errors.length) return branchesSetGroupFormSaveErrors(errors, scrollToError)
    const savedGroup = await q('saveBranchCourse', { companyId: group.companyId, region: branch.region, course: transformedGroup })
    const { error } = savedGroup
    if (error) return branchesSetGroupFormSaveErrors(branchesGroupFormServerErrorsTransform(error), scrollToError)
    handlers.navigateToPath(`/branches/${branch.id}/preview@@groups`)
  })

const branchesSetGroupFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesGroup', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesGroupFormReady()
}

// Preview customers
payloads$(actions.BRANCH_PREVIEW_CUSTOMERS_GET)
  .subscribe(async id => {
    const state = store.getState()
    const branches = state.branches.list || []
    const branch = branches.find(item => item.id === id)
    const { areCustomersUpdated } = state.branches
    const { areCustomersFetched, region } = branch || {}
    if (areCustomersFetched && !areCustomersUpdated) return
    const customers = await q('getBranchCustomers', { companyId: id, region })
    const { error } = customers || { error: {} }
    if (error) {
      handlers.navigateToPath('/branches')
      return
    }
    const companyCustomerFields = (await q('getBranchCustomerFields', { companyId: id, region })) || []
    const customerFieldsCategories = (await q('getBranchCustomerFieldCategories', { companyId: id, region })) || []
    customers.forEach(item => {
      const fields = []
      companyCustomerFields.forEach(field => {
        let hasValue = false
        item.fields.forEach(customerField => {
          if (field.id === customerField.id) {
            fields.push({
              ...field,
              value: customerField.value
            })
            hasValue = true
          }
        })
        if (!hasValue) {
          fields.push(field)
        }
      })

      item.fields = fields
      item.fieldCategories = customerFieldsCategories
    })
    handlers.branchPreviewCustomersPopulate({ id, customers })
  })

payloads$(actions.BRANCHES_CUSTOMER_FORM_GET)
  .subscribe(async (id) => {
    if (!id) return handlers.navigateToPath('/branches')
    const state = store.getState()
    const { data } = state.router
    const { branchId } = data || {}
    const branch = state.branches.list.find(item => item.id === branchId)
    if (!branch) return handlers.navigateToPath('/branches')
    const { locale: companyLocale } = branch
    await globalActions.populateTags()
    const availableTags = (state.tags.list || []).filter(item => item.isForCustomers)
    const customers = branch.customers || []
    const customer = customers.find(item => item.id === id) || {}
    const customerFieldsWithValues = prepareBranchesCustomerFieldsWithValues(customer.fields)
    handlers.branchesCustomerFormPopulate({
      ...customer,
      fields: prepareBranchCustomerFieldsForForm(customerFieldsWithValues),
      availableTags,
      companyLocale
    })
  })

const prepareBranchesCustomerFieldsWithValues = values => {
  if (!values) return []
  const parsedValues = {}
  values.forEach(item => {
    parsedValues[item.id] = item.value
  })
  return values
    .map(item => ({ ...item, value: parsedValues[item.id] === undefined ? null : parsedValues[item.id] }))
    .filter(item => item.isActive)
    .sort(sortByOrderIndex)
}

const prepareBranchCustomerFieldsForForm = fields => {
  return fields
    .map(item => ({
      ...item,
      selectOptions: (item.selectOptions || []).map(option => ({
        label: option.label || t(option.translationKey),
        value: option.value
      }))
    }))
    .filter(item => item.isActive)
    .sort(sortByOrderIndex)
}

payloads$(actions.BRANCHES_CUSTOMER_FORM_SAVE)
  .subscribe(async ({ branch, customer, scrollToError }) => {
    const errors = branchesCustomerFormValidate(customer)
    if (errors.length) return setBranchesCustomerFormSaveErrors(errors, scrollToError)
    const savedCustomer = await q('saveBranchCustomer', { companyId: customer.companyId, region: branch.region, customer: branchesCustomerSaveTransform(customer) })
    const { error } = savedCustomer || { error: { text: 'Not found ' } }
    if (error) return setBranchesCustomerFormSaveErrors(serverErrorsTransform(error), scrollToError)
    await handlers.branchPreviewCustomersGet(branch.id)
    handlers.navigateToPath(`/branches/${branch.id}/preview@@customers`)
  })

const setBranchesCustomerFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesCustomer', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesCustomerFormReady()
}

payloads$(actions.BRANCH_CUSTOMER_DELETE)
  .subscribe(async ({ companyId, id, externalId }) => {
    if (!id) return
    const state = store.getState()
    const branches = state.branches.list || []
    const branchId = (state.router.data && state.router.data.id) || ''
    const branch = branches.find(item => item.id === branchId) || {}
    const { region } = branch
    const { errors } = await q('deleteBranchCustomer', { companyId, region, id, externalId })
    if (!errors) {
      handlers.branchCustomerDeleted({ id, branchId })
    } else {
      console.error('ERRORS: ', errors)
    }
  })

// Preview statistics
payloads$(actions.BRANCH_PREVIEW_STATISTICS_GET)
  .subscribe(async id => {
    const state = store.getState()
    const branches = state.branches.list || []
    const branch = branches.find(item => item.id === id)
    const { region } = branch || {}
    const currentMonth = moment().format('MM')
    handlers.branchPreviewStatisticsResetSingleStatisticFilters()
    const dailyStatisticsFilter = formatBranchStatisticsFilter('DAILY', false, id,
      region)
    const dailyStatistics = await q('getBranchStatisticsV2', dailyStatisticsFilter)
    const { error: dailyStatisticsError } = dailyStatistics || { error: {} }
    if (dailyStatisticsError) return handlers.navigateToPath('/branches')
    dailyStatistics.month = (dailyStatisticsFilter.filter && dailyStatisticsFilter.filter.month) || currentMonth
    const monthlyStatisticsFilter = formatBranchStatisticsFilter('MONTHLY', true, id,
      region)
    const monthlyStatistics = await q('getBranchStatisticsV2', monthlyStatisticsFilter)
    const { error: monthlyStatisticsError } = monthlyStatistics || { error: {} }
    if (monthlyStatisticsError) return handlers.navigateToPath('/branches')
    monthlyStatistics.month = (monthlyStatisticsFilter.filter && monthlyStatisticsFilter.filter.month) || currentMonth
    const transformedDailyStatistics = transformStatistics(dailyStatistics)
    const transformedMonthlyStatistics = transformStatistics(monthlyStatistics)
    handlers.branchPreviewStatisticsPopulate({ id, dailyStatistics: transformedDailyStatistics, monthlyStatistics: transformedMonthlyStatistics })
    handlers.branchPreviewMarketingFormGet()
    handlers.branchPreviewStatiticsPeakSignUpTimesFiltersGet()
  })

payloads$(actions.BRANCHES_STATISTICS_MOST_BOOKED_RESOURCES_FILTERS_GET)
  .subscribe(() => {
    handlers.formSet('statisticsMostBookedResourcesType', branchesStatisticsMostBookedResourcesFilterFormPopulate({
      allKey: t('global.all'),
      dependenciesKey: t('resources.categories.dependency'),
      bookableKey: t('statistics.resources.bookable')
    }))
  })

// Overall
payloads$(actions.BRANCH_PREVIEW_STATISTICS_OVERALL_GET)
  .subscribe(async ({ id, region }) => {
    const overallFilter = { companyId: id, region, filter: { statisticsMode: 'OVERALL', month: 'IGNORE', year: 'IGNORE' } }
    const overallStatistics = await q('getBranchStatisticsV2Overall', overallFilter)
    const { error: overallStatisticsError } = overallStatistics || { error: {} }
    if (overallStatisticsError) return handlers.navigateToPath('/branches')
    handlers.branchPreviewStatisticsOverallPopulate({ id, overallStatistics })
  })

payloads$(actions.DASHBOARD_MARKETING_FORM_GET)
  .subscribe(() => {
    handlers.formSet('branchMarketingConversions', branchMarketingFormPopulate({
      campaign: t('statistics.marketing.campaign'),
      content: t('statistics.marketing.content'),
      source: t('statistics.marketing.source'),
      medium: t('statistics.marketing.medium'),
      utmTerm: t('statistics.marketing.term')
    }))
  })

// filter by tag/resource
payloads$(actions.BRANCH_PREVIEW_STATISTIC_OVERALL_FILTER)
  .subscribe(async ({ companyId, statisticId, filter }) => {
    if (!statisticId) return
    const statisticIds = statisticId.split(' ')
    const filterableStatistics = {
      totalEvents: 'getBranchStatisticsV2OverallTotalEvents',
      totalOnlineEvents: 'getBranchStatisticsV2OverallTotalOnlineEvents',
      totalInternalEvents: 'getCompanyStatisticsV2OverallTotalInternalEvents'
    }
    statisticIds.forEach(async statistic => {
      const queryName = filterableStatistics[statistic]
      if (!queryName) return
      const state = store.getState()
      const branches = state.branches.list || []
      const branch = branches.find(item => item.id === companyId)
      const { region } = branch || {}
      const overallFilter = { companyId, region, filter: { statisticsMode: 'OVERALL', month: 'IGNORE', year: 'IGNORE' } }
      overallFilter.partialStatsFilter = filter
      const filteredOverallStatisticData = await q(queryName, overallFilter)
      const { error: monthlyStatsError } = filteredOverallStatisticData || {}
      if (monthlyStatsError) return monthlyStatsError
      const filteredStatistic = filteredOverallStatisticData[statistic]
      handlers.branchPreviewStatisticOverallUpdate({
        companyId,
        statisticId: statistic,
        filteredStatistic
      })
    })
  })
payloads$(actions.BRANCH_PREVIEW_STATISTIC_FILTER)
  .subscribe(async ({ id, statisticId, filter, isOverall }) => {
    if (!statisticId) return
    const statisticIds = statisticId.split(' ')
    const filterableStatistics = {
      totalEvents: 'getBranchStatisticsV2TotalEvents',
      totalCancelledEvents: 'getBranchStatisticsV2TotalCancelledEvents',
      totalOnlineEvents: 'getBranchStatisticsV2TotalOnlineEvents',
      totalInternalEvents: 'getBranchStatisticsV2TotalInternalEvents',
      sickDenialsStatistics: 'getBranchStatisticsV2SickDenialsStatistics',
      vacationDenialsStatistics: 'getBranchStatisticsV2VacationDenialsStatistics',
      blockerDenialsStatistics: 'getBranchStatisticsV2BlockerDenialsStatistics',
      totalResourcesWorkingTimes: 'getBranchStatisticsV2TotalResourcesWorkingTimes',
      totalResourcesWorkingBookedTimes: 'getBranchStatisticsV2TotalResourcesWorkingBookedTimes',
      totalResourcesOnlineBookedTimes: 'getBranchStatisticsV2TotalResourcesOnlineBookedTimes',
      totalResourcesOnlineTimes: 'getBranchStatisticsV2TotalResourcesOnlineTimes',
      groupBookings: 'getBranchStatisticsV2GroupBookings',
      singleBookings: 'getBranchStatisticsV2SingleBookings'
    }
    statisticIds.forEach(async statistic => {
      const queryName = filterableStatistics[statistic]
      if (!queryName) return
      const state = store.getState()
      const branches = state.branches.list || []
      const branch = branches.find(item => item.id === id)
      const { region } = branch || {}
      const dailyStatisticsFilter = formatBranchStatisticsFilter('DAILY', false, id, region)
      dailyStatisticsFilter.partialStatsFilter = filter
      const monthlyStatisticsFilter = formatBranchStatisticsFilter('MONTHLY', true, id, region)
      monthlyStatisticsFilter.partialStatsFilter = filter
      if (!isOverall) {
        const filteredDailyStatisticData = await q(queryName, dailyStatisticsFilter)
        const { error: dailyStatsError } = filteredDailyStatisticData
        if (dailyStatsError) return dailyStatsError || {}
        const filteredMonthlyStatisticData = await q(queryName, monthlyStatisticsFilter)
        const { error: monthlyStatsError } = filteredMonthlyStatisticData || {}
        if (monthlyStatsError) return monthlyStatsError
        const filteredStats = Object.keys(filteredMonthlyStatisticData)
        filteredStats.forEach(item => {
          handlers.branchPreviewStatisticUpdate({
            id,
            statisticId: item,
            filteredDailyStatistic: filteredDailyStatisticData[item],
            filteredMonthlyStatistic: filteredMonthlyStatisticData[item],
            statisticsId: statisticId,
            isOverall
          })
        })
      } else {
        const filteredOverallStatisticData = await q(queryName, { companyId: id, region, filter: { statisticsMode: 'OVERALL', month: 'IGNORE', year: 'IGNORE' }, partialStatsFilter: filter })
        const filteredStats = Object.keys(filteredOverallStatisticData)
        filteredStats.forEach(item => {
          handlers.branchPreviewStatisticUpdate({
            id,
            statisticId: item,
            filteredOverallStatistic: filteredOverallStatisticData[item],
            statisticsId: statisticId,
            isOverall
          })
        })
      }
    })
  })

payloads$(actions.BRANCH_PREVIEW_STATISTIC_FILTERS_RESET)
  .subscribe(async () => {
    const state = store.getState()
    const { forms } = state || {}
    const singleStatsFilters = Object.keys(forms).filter(item => item.includes('StatisticsForm'))
    singleStatsFilters.forEach(formName => {
      handlers.formSet(formName, {
        search: { value: '' },
        label: { value: '' }
      })
    })
  })

// SAVE CREATE
payloads$(actions.BRANCHES_FORM_SAVE_CREATE)
  .subscribe(async (form, scrollToError) => {
    const { branches, account } = store.getState()
    const { enterprise } = account || {}
    const { region: enterpriseRegion } = enterprise || {}
    const isCustomRegion = !ALLOWED_REGIONS.includes(enterpriseRegion)
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    const branchExternalIds = branchesList.map(branch => branch.externalId).filter(Boolean)
    const errors = formValidateCreate(form, branchExternalIds, isCustomRegion)
    if (errors.length) return formSaveErrors(errors, scrollToError)
    const createdBranch = await q('createBranch', createSaveTransform(form))
    const { error, id } = createdBranch || {}
    if (error) return formSaveErrors(branchesCreateFormServerErrorsTransform(error), scrollToError)
    handlers.branchesFormReady()
    handlers.branchesListGet(true)
    handlers.navigateToPath(`/branches/${id}/preview`)
  })

// SAVE EDIT
payloads$(actions.BRANCHES_FORM_SAVE_EDIT)
  .subscribe(async (form, scrollToError) => {
    const { id: editedBranchId } = form || {}
    const { branches } = store.getState()
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    const selectedBranch = branchesList.find(branch => branch.id === editedBranchId) || {}
    let branchExternalIds = branchesList.map(branch => branch.externalId)
    branchExternalIds = branchExternalIds.filter(externalId => externalId !== selectedBranch.externalId)
    const errors = formValidateEdit(form, branchExternalIds)
    if (errors.length) return formSaveErrors(errors, scrollToError)
    const locales = store.getState().staticData.locales || []
    const editedBranch = await q('updateBranch', editSaveTransform(form, locales))
    const { error, id } = editedBranch || {}
    if (error) return formSaveErrors(branchesEditFormServerErrorsTransform(error), scrollToError)
    handlers.branchesFormReady()
    handlers.branchesListGet(true)
    handlers.navigateToPath(`/branches/${id}/preview`)
  })

const formSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branches', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesFormReady()
}

// Unlink
payloads$(actions.BRANCH_RESOURCE_FORM_EMAIL_UNLINK)
  .subscribe(async ({ id, branch }) => {
    if (!id) return
    const { id: companyId, region } = branch || {}
    const savedResource = await q('unlinkBranchResource', { companyId, region, id })
    const { error } = savedResource || { error: {} }
    if (error) return handlers.navigateToPath(`/branches/${companyId}/preview@@resources`)
    const { email } = savedResource || {}
    handlers.formFieldsUpdate('branchesResource', {
      isConfirmed: { value: false },
      email: { value: email, disabled: false },
      hasAccount: { value: false }
    })
    handlers.branchResourceFormEmailUnlinkReady()
  })

// Delete form save
payloads$(actions.BRANCH_DELETE_FORM_SAVE)
  .subscribe(async ({ branch, scrollToError }) => {
    const { id, password } = branch || {}
    let { value: passwordValue } = password || {}
    passwordValue = passwordValue || ''
    if (!id) return
    const branches = store.getState().branches.list || []
    const { region } = branches.find(item => item.id === id) || {}
    const errors = branchDeleteFormValidate(branch)
    if (errors.length) return branchDeleteFormSaveErrors(errors, scrollToError)
    const { error } = await q('deleteBranch', { companyId: id, region, password: passwordValue }) || {}
    if (error) return branchDeleteFormSaveErrors(branchesDeleteFormServerErrorsTransform(error), scrollToError)
    handlers.branchDeleted(id)
    handlers.popupHide('branch-delete')
    handlers.branchDeleteFormReady()
    setTimeout(() => { handlers.popupSet() }, 500)
    setTimeout(() => handlers.branchRemoveDeleted(id), 0)
    setTimeout(() => handlers.navigateToPath('/branches'), 0)
  })

const branchDeleteFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesDelete', errors)
  scrollToError && scrollToError(errors)
  handlers.branchDeleteFormReady()
}

payloads$(actions.BRANCHES_LOGIN)
  .subscribe(async ({ id, isCustomisationIgnored }) => {
    if (!id) return
    const state = store.getState()
    const { branches, app } = state || {}
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    let { locale } = app || {}
    locale = locale || DEFAULT_LOCALE
    const branch = branchesList.find(item => item.id === id) || {}
    const { region } = branch || {}
    const generateToken = await q('loginCompanyWithEnterpriseToken', {
      companyId: id,
      region,
      isCustomisationIgnored
    })
    const { error } = generateToken
    if (error) return
    // window.open() keeps reference to this tab and can cause issues when closing webapp later
    // so creating <a> works in this case
    const a = document.createElement('a')
    a.setAttribute('target', '_blank')
    a.href = `${WEBAPP_URL}/login?locale=${locale}&token=${generateToken}`
    a.click()
    a.remove()
  })

payloads$(actions.TAGS_LIST_POPULATE)
  .subscribe(({ tags }) => {
    if (!tags) return
    tags = tags || []
    const tagNamesOptions = tags.map(tag => ({
      label: tag.name || '',
      value: tag.name || ''
    }))
    const branchesTagsForm = {
      tagsFilter: {
        value: '',
        options: tagNamesOptions
      }
    }
    handlers.branchesTagsFormPopulate(branchesTagsForm)
  })

// Branches Import
payloads$(actions.BRANCHES_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchesImportFormValidate(form)
    if (errors.length) return setBranchesImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { company = {} } = state
    const result = await q('importBranches', branchesImportFormSaveTransform(form, company))
    const { error } = result || { error: {} }
    if (error) return setBranchesImportFormSaveErrors(branchesImportFormServerErrorsTransform(error, t('branches.list.importBranches.file.error')), scrollToError)
    handlers.branchesImportFormReady()
    handlers.branchesImportShowMessage()
    handlers.navigateToPath('/branches')
  })

const setBranchesImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesImportFormReady()
}

// Resources Import
payloads$(actions.BRANCH_RESOURCES_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchResourcesImportFormValidate(form)
    if (errors.length) return setBranchResourcesImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { branches, router } = state
    const { list: branchesList } = branches || {}
    const { data } = router || {}
    const { id: branchId } = data || {}
    const branch = branchesList.find(item => item.id === branchId) || {}
    const result = await q('importResources', branchResourcesImportFormSaveTransform(form, branch))
    const { error } = result || { error: {} }
    if (error) {
      if (error.code !== 'PasswordInvalid') {
        handlers.formFieldsUpdate('branchResourcesImport', {
          files: {
            selected: null,
            values: [],
            rawFiles: [],
            file: null
          }
        })
      }
      return setBranchResourcesImportFormSaveErrors(branchResourcesImportFormServerErrorsTransform(error, t('branches.list.importBranches.file.error')), scrollToError)
    }
    handlers.branchResourcesImportFormReady()
    handlers.branchResourcesImportShowMessage()
    handlers.navigateToPath(`/branches/${branchId}/preview@@resources`)
  })

const setBranchResourcesImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchResourcesImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchResourcesImportFormReady()
}

// Resource Categories Import
payloads$(actions.BRANCH_RESOURCE_CATEGORIES_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchResourceCategoriesImportFormValidate(form)
    if (errors.length) return setBranchResourceCategoriesImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { branches, router } = state
    const { list: branchesList } = branches || {}
    const { data } = router || {}
    const { id: branchId } = data || {}
    const branch = branchesList.find(item => item.id === branchId) || {}
    const result = await q('importResourceCategories', branchResourceCategoriesImportFormSaveTransform(form, branch))
    const { error } = result || { error: {} }
    if (error) {
      setBranchResourceCategoriesImportFormSaveErrors(branchResourceCategoriesImportFormServerErrorsTransform(error), scrollToError)
    } else {
      handlers.branchResourceCategoriesImportFormReady()
      handlers.branchResourceCategoriesImportShowMessage()
      handlers.navigateToPath(`/branches/${branchId}/preview@@resources`)
    }
  })

const setBranchResourceCategoriesImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchResourceCategoriesImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchResourceCategoriesImportFormReady()
}

// Branch Services Import
payloads$(actions.BRANCH_SERVICES_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchServicesImportFormValidate(form)
    if (errors.length) return setBranchServicesImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { branches, router } = state
    const { list: branchesList } = branches || {}
    const { data } = router || {}
    const { id: branchId } = data || {}
    const branch = branchesList.find(item => item.id === branchId) || {}
    const result = await q('importServices', branchServicesImportFormSaveTransform(form, branch))
    const { error } = result || { error: {} }
    if (error) {
      setBranchServicesImportFormSaveErrors(branchServicesImportFormServerErrorsTransform(error), scrollToError)
    } else {
      handlers.branchServicesImportFormReady()
      handlers.branchServicesImportShowMessage()
      handlers.navigateToPath(`/branches/${branchId}/preview@@services`)
    }
  })

const setBranchServicesImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchServicesImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchServicesImportFormReady()
}

// Branch Service Categories Import
payloads$(actions.BRANCH_SERVICE_CATEGORIES_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchServiceCategoriesImportFormValidate(form)
    if (errors.length) return setBranchServiceCategoriesImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { branches, router } = state
    const { list: branchesList } = branches || {}
    const { data } = router || {}
    const { id: branchId } = data || {}
    const branch = branchesList.find(item => item.id === branchId) || {}
    const result = await q('importServiceCategories', branchServiceCategoriesImportFormSaveTransform(form, branch))
    const { error } = result || { error: {} }
    if (error) {
      setBranchServiceCategoriesImportFormSaveErrors(branchServiceCategoriesImportFormServerErrorsTransform(error), scrollToError)
    } else {
      handlers.branchServiceCategoriesImportFormReady()
      handlers.branchServiceCategoriesImportShowMessage()
      handlers.navigateToPath(`/branches/${branchId}/preview@@services`)
    }
  })

const setBranchServiceCategoriesImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchServiceCategoriesImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchServiceCategoriesImportFormReady()
}

// Branch Groups Import
payloads$(actions.BRANCH_GROUPS_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchGroupsImportFormValidate(form)
    if (errors.length) return setBranchGroupsImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { branches, router } = state
    const { list: branchesList } = branches || {}
    const { data } = router || {}
    const { id: branchId } = data || {}
    const branch = branchesList.find(item => item.id === branchId) || {}
    const result = await q('importServices', branchGroupsImportFormSaveTransform(form, branch))
    const { error } = result || { error: {} }
    if (error) {
      setBranchGroupsImportFormSaveErrors(branchGroupsImportFormServerErrorsTransform(error), scrollToError)
    } else {
      handlers.branchGroupsImportFormReady()
      handlers.branchGroupsImportShowMessage()
      handlers.navigateToPath(`/branches/${branchId}/preview@@groups`)
    }
  })

const setBranchGroupsImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchGroupsImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchGroupsImportFormReady()
}

// Branch Group Categories Import
payloads$(actions.BRANCH_GROUP_CATEGORIES_IMPORT_FORM_SUBMIT)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchGroupCategoriesImportFormValidate(form)
    if (errors.length) return setBranchGroupCategoriesImportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { branches, router } = state
    const { list: branchesList } = branches || {}
    const { data } = router || {}
    const { id: branchId } = data || {}
    const branch = branchesList.find(item => item.id === branchId) || {}
    const result = await q('importServiceCategories', branchGroupCategoriesImportFormSaveTransform(form, branch))
    const { error } = result || { error: {} }
    if (error) {
      setBranchGroupCategoriesImportFormSaveErrors(branchGroupCategoriesImportFormServerErrorsTransform(error), scrollToError)
    } else {
      handlers.branchGroupCategoriesImportFormReady()
      handlers.branchGroupCategoriesImportShowMessage()
      handlers.navigateToPath(`/branches/${branchId}/preview@@groups`)
    }
  })

const setBranchGroupCategoriesImportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchGroupCategoriesImport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchGroupCategoriesImportFormReady()
}

// Template CSVs export
payloads$(actions.BRANCHES_CSV_DOWNLOAD)
  .subscribe(async ({ type, isUrl }) => {
    if (!type) return
    if (isUrl) return window.open(type, '_blank')
    const queries = {
      exportTimezonesTemplate: 'exportTimezonesTemplate',
      exportBusinessSectorsTemplate: 'exportBusinessSectorsTemplate',
      exportBranchSettingsTemplate: 'exportBranchSettingsTemplate',
      exportBranchesTemplate: 'exportBranchesTemplate',
      exportServicesTemplate: 'exportServicesTemplate',
      exportServiceCategoriesTemplate: 'exportServiceCategoriesTemplate',
      exportResourcesTemplate: 'exportResourcesTemplate',
      exportResourceCategoriesTemplate: 'exportResourceCategoriesTemplate'
    }
    const OS = getOS()
    if (!queries[type]) return
    const options = {
      fileTypeName: OS === 'Apple' ? 'CSV' : 'EXCEL'
    }
    if (!queries[type]) return
    const result = await q(queries[type], options)
    const { error } = result || { error: {} }
    if (error) return
    window.open(result, '_blank')
  })

// Export

const setBookingExportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('branchesExport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchesExportFormReady()
}

payloads$(actions.BRANCHES_EXPORT_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const state = store.getState()
    const { company } = state || {}
    const { enterpriseCustomisation } = company || {}
    const { payload: customisationPayload } = enterpriseCustomisation || {}
    const { settings } = customisationPayload || {}
    const { exportTemplateCode } = settings || {}
    const errors = branchBookingExportFormValidate(form)
    if (errors.length) return setBookingExportFormSaveErrors(errors, scrollToError)
    const result = await q('exportCompanyBookingsEnterprise', branchBookingExportFormSaveTransform({ ...form, exportTemplateCode }))
    const { error } = result || { error: {} }
    if (error) return setBookingExportFormSaveErrors(branchExportFormServerErrorsTransform(error), scrollToError)
    handlers.branchesExportFormReady()
    // handlers.popupHide('bookings-actions')
    handlers.accountLastBranchesExportSet(momenttz.utc().format('YYYY-MM-DD HH:mm:ss'))
  })

payloads$(actions.BRANCH_STATISTICS_EXPORT_FORM_GET)
  .subscribe(() => {
    const state = store.getState()
    const { forms } = state || {}
    const { branchStatisticsFilter } = forms || {}

    handlers.formSet('statisticsExport', branchExportFormTransform({ branchStatisticsFilter }))
    handlers.branchStatisticsExportFormReady()
  })

payloads$(actions.BRANCH_STATISTICS_EXPORT_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = branchExportFormValidate(form)
    if (errors.length) return setBranchStatisticsExportFormSaveErrors(errors, scrollToError)
    const state = store.getState()
    const { data } = state.router || {}
    const { id } = data || {}
    const branch = state.branches.list.find(item => item.id === id) || {}
    const result = await q('getBranchStatisticsExport', branchExportFormSaveTransform({ ...form, region: branch.region, companyId: branch.id }))
    const { error } = result || { error: {} }
    if (error) return setBranchStatisticsExportFormSaveErrors(branchExportFormServerErrorsTransform(error), scrollToError)
    handlers.branchStatisticsExportFormReady()
    handlers.branchStatisticsExportMessageSet(t('statistics.export.messageSuccessText', [{ key: 'FILE_NAME', value: '.csv' }]))
    setTimeout(() => {
      handlers.modalHide()
      handlers.overlayHide('top')
      handlers.branchStatisticsExportMessageSet(null)
    }, 4000)
  })

const setBranchStatisticsExportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('statisticsExport', errors)
  scrollToError && scrollToError(errors)
  handlers.branchStatisticsExportFormReady()
}
store$
  .pipe(
    map(state => state.forms && state.forms.branches && state.forms.branches.locale
    ),
    distinctUntilChanged()
  ).subscribe(locale => {
    const state = store.getState() || {}
    let { value } = locale || {}
    const { forms, staticData, company } = state
    let { locale: appLocale } = company || {}
    appLocale = appLocale || ''
    let { locales } = staticData || {}
    locales = locales || []
    const { enterprises, branches } = forms || {}
    const { country } = locales.find(country => country.code.toLowerCase() === value.toLowerCase()) || {}
    const { country: defaultCountry } = locales.find(country => country?.code?.toLowerCase() === appLocale.toLowerCase()) || {}
    let { code: defaultCountryCode } = defaultCountry || {}
    defaultCountryCode = defaultCountryCode || ''
    let { code } = country || {}
    let { phone } = enterprises || {}
    phone = phone || {}
    value = value || ''
    code = code || null
    const existInCountries = countries.find(country => country.code === code) || null
    const phoneCountry = code && existInCountries ? code : defaultCountryCode

    handlers.formSet('branches', {
      ...branches,
      phone: { ...phone, phoneCountry }
    })
  })
