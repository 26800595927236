import React from 'react'
import { connect } from '../../Store'
import { BranchesServiceCombinationFormPrice } from '../../Beauties'

const BranchesServiceCombinationFormPriceWrapper = props => {
  const { hasPriceOverwrite, allowedSet } = props

  return (
    <BranchesServiceCombinationFormPrice allowedSet={allowedSet} hasPriceOverwrite={!!hasPriceOverwrite} />
  )
}

const maps = state => ({
  hasPriceOverwrite: state.forms.branchesService.hasPriceOverwrite && state.forms.branchesService.hasPriceOverwrite.value
})

export default connect(maps)(BranchesServiceCombinationFormPriceWrapper)
