import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '../../Common'
import { TagsListItem } from '../../Beauties'

const TagsList = props => {
  const { tags } = props

  return (
    <div className='ta-tags__list'>
      {tags.length > 0 && tags.map((tag, index) => (
        <TagsListItem
          key={tag.id}
          tag={tag}
          index={index}
        />
      ))}
      {(!tags.length &&
        <Alert noOffset label='No tags' theme='no-content' />
      )}
    </div>
  )
}

TagsList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default TagsList
