import React from 'react'
import { FontAwesome5, t } from '../../../../../Common'

import './MaximumLeadTimeMonthsExample.css'

const MaximumLeadTimeMonthsExample = props => {
  let { value, label } = props
  value = parseInt(value, 10)
  const classNames = ['ta-maximum-lead-time-example']
  classNames.push(`months-${Math.min(value, 12)}`)

  return (
    <div>
      <div className={classNames.join(' ')}>
        {(label &&
          <div className='ta-maximum-lead-time-example__label'>{label}</div>
        )}
        <div className='ta-maximum-lead-time-example__timeline'>
          <div className='ta-maximum-lead-time-example__timeline-active'>
            <div className='ta-maximum-lead-time-example__now'>{t('global.now')}</div>
            <div className='ta-maximum-lead-time-example__month month-1'><div>1</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-2'><div>2</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-3'><div>3</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-4'><div>4</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-5'><div>5</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-6'><div>6</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-7'><div>7</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-8'><div>8</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-9'><div>9</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-10'><div>10</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-11'><div>11</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
            <div className='ta-maximum-lead-time-example__month month-12'><div>{value > 12 ? '...' : 12}</div><span /><ul><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /><li /></ul></div>
          </div>
          <div className='ta-maximum-lead-time-example__timeline-inactive'>
            <div className='ta-maximum-lead-time-example__month month-empty'><div>{value > 12 ? value : '-'}</div><span /><FontAwesome5 icon='times' /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MaximumLeadTimeMonthsExample
