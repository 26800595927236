import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors, store } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { AccountForm } from '../../Beauties'

class AccountEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const { id } = this.props
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.accountFormGet(id)
  }

  onSubmit (cb) {
    const form = store.getState().forms.account
    handlers.accountFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  togglePasswordCurrentFieldType () {
    const form = store.getState().forms.login
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    return password.type
  }

  togglePasswordNewFieldType () {
    const form = store.getState().forms.login
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    return password.type
  }

  togglePasswordNewCheckFieldType () {
    const form = store.getState().forms.login
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    return password.type
  }

  render () {
    const { pending, errors, customisations } = this.props
    const { showAutocompleteCompanyEditForm } = customisations?.payload?.settings || {}

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <AccountForm
            title={t('account.form.heading')}
            cancelLink='/account'
            errors={errors}
            onSubmit={this.onSubmit}
            togglePasswordCurrentFieldType={this.togglePasswordCurrentFieldType}
            togglePasswordNewFieldType={this.togglePasswordNewFieldType}
            togglePasswordNewCheckFieldType={this.togglePasswordNewCheckFieldType}
            showAutocompleteCompanyEditForm={showAutocompleteCompanyEditForm}
          />
    )}
      </>
    )
  }
}

AccountEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = state => ({
  id: state.router.data.id,
  errors: state.account.errors || [],
  pending: !!(state.account && state.account.pendingForm),
  passwordCurrentFieldType: 'password',
  customisations: selectors.companyCustomisationsSelector(state)
})

export default connect(maps)(AccountEditWrapper)
