import React from 'react'
import {
  Title,
  Button,
  format,
  t,
  SimpleBox,
  ListItem,
  FontAwesome5
} from '../../../Common'

const CustomerPreviewBookingsDataFieldItem = props => {
  const { dataFields, timezones } = props

  return (
    <SimpleBox>
      <div className='ta-customers__preview__bookings__data-fields-preview'>
        <Title
          size='m'
          label={t('calendar.form.bookingDataFields.title')}
          icon='sliders-h'
          ignoreTopOffset
        />

        <div>
          {dataFields.map((dataField, index) => {
            let selectedOption = null
            if (dataField.type === 'SELECT') {
              selectedOption = dataField.selectOptions.find(option => option.value === dataField.value) || {}
            }

            return (
              <ListItem key={index}>
                <div className='ta-list__title'>{dataField.label}:</div>
                <div className='ta-list__text'>
                  {(dataField.type === 'EMAIL' &&
                    <Button as='link' to={`mailto:${dataField.value}`} target='_top' external>{dataField.value}</Button>
                  )}
                  {dataField.type === 'ADDRESS' && JSON.parse(dataField.value) && JSON.parse(dataField.value).formatted}
                  {dataField.type === 'PHONE' && JSON.parse(dataField.value) && JSON.parse(dataField.value).phone}
                  {dataField.type === 'DATE' && format(dataField.value, 'long', { isUTC: true })}
                  {dataField.type === 'TEXT' && dataField.value}
                  {dataField.type === 'TEXTAREA' && dataField.value}
                  {dataField.type === 'SELECT' && (selectedOption.label || t(selectedOption.translationKey))}
                  {(dataField.type === 'CHECKBOX' && dataField.value + '' === 'true'
                    ? <FontAwesome5 icon='check' color='#769851' />
                    : <FontAwesome5 icon='times' color='#cb3b43' />
                  )}
                  {dataField.type === 'TIMEZONE' && (timezones.find(timezone => timezone.code === dataField.value) || {}).label}
                </div>
              </ListItem>
            )
          })}
        </div>
      </div>
    </SimpleBox>
  )
}

export default CustomerPreviewBookingsDataFieldItem
