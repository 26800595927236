import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { connect, handlers } from '../../Store'
import { BranchesPreviewStatistics } from '../../Beauties'
import { Loader, t } from '../../Common'

const BranchesPreviewStatisticsWrapper = props => {
  const [isMessageShown, setIsMessageShown] = useState(true)
  let {
    id,
    branch,
    isPending,
    statisticsFiltersForm,
    statisticsMostBookedResourcesTypeForm,
    branchMarketingConversionsValue,
    signUpTimesValue,
    account,
    messageExport
  } = props
  isPending = !!isPending
  statisticsMostBookedResourcesTypeForm = statisticsMostBookedResourcesTypeForm || {}
  statisticsFiltersForm = statisticsFiltersForm || {}
  branch = branch || {}
  account = account || {}
  const { region, locale, lastStatisticsStateChange, areStatisticsFetched } = branch || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { branchesRead } = enterprisePermissions || {}
  branchesRead = isAdmin || !!branchesRead
  //* FILTER + BRANCHES INFORMATION *//
  const { type, year, month } = statisticsFiltersForm || {}
  const { value: filtersType } = type || {}
  const { value: filtersYear } = year || {}
  const { value: filtersMonth, options: monthOptions = [] } = month || {}

  useEffect(() => {
    if (branchesRead) {
      handlers.branchStatisticsFiltersGet({
        lifetime: t('statistics.lifetime.lifetime'),
        month: t('statistics.lifetime.month'),
        until: t('global.until')
      })
      handlers.branchPreviewStatisticsOverallGet(id, region)
      handlers.branchPreviewStatisticsGet(id)
      handlers.branchStatisticsMostBookedResourcesFiltersGet()
    } else {
      handlers.navigateToPath(`/branches/${id}/preview`)
    }
    return () => {
      handlers.branchPreviewStatisticsResetSingleStatisticFilters()
    }
  }, [id, region, branchesRead])

  const onFilterYearSubmit = year => {
    const currentYear = moment().format('YYYY')
    const currentMonth = moment().format('MM')
    const previousMonth = moment().subtract('1', 'month').format('MM')
    const lastDayOfLastMonth = moment().subtract('1', 'day').format('DD MMM')
    const previousDay = moment().subtract('1', 'day').format('DD')
    const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
    const isFirstDayOfCurrentMonth = moment().format('YYYY-MM-DD') === firstDayOfCurrentMonth
    const months = moment.months().map((item, i) => ({
      label: item,
      value: moment().month(i).format('MM'),
      i: i + 1
    }))
    const { month } = statisticsFiltersForm || {}
    const { value: filtersMonth } = month || {}
    const monthsOptions = months.filter(item => item.i <= (isFirstDayOfCurrentMonth ? previousMonth : currentMonth)).map(item => item.value === (isFirstDayOfCurrentMonth ? previousMonth : currentMonth)
      ? {
          label: `${item.label} (${t('global.until')} ${isFirstDayOfCurrentMonth ? lastDayOfLastMonth : previousDay} ${item.label.substring(0, 3)})`,
          value: item.value,
          i: item.i
        }
      : item
    )
    handlers.formFieldsUpdate('branchStatisticsFilter', {
      month: {
        options: year === '2020'
          ? months.filter(item => item.i >= 11)
          : year === currentYear
            ? monthsOptions
            : months,
        value: year === '2020'
          ? '12'
          : year === currentYear
            ? parseFloat(filtersMonth) > parseFloat(currentMonth)
              ? currentMonth
              : filtersMonth
            : filtersMonth
      }
    })
    handlers.branchPreviewStatisticsGet(id)
  }

  const onFilterMonthSubmit = () => {
    handlers.branchPreviewStatisticsGet(id)
  }

  const onFilterTypeChange = () => {
    handlers.branchStatisticsFiltersGet({
      lifetime: t('statistics.lifetime.lifetime'),
      month: t('statistics.lifetime.month'),
      until: t('global.until')
    })
  }

  const onExportClick = (e) => {
    e.stopPropagation()
    if (filtersType === 'lifetime') {
      handlers.branchStatisticsExportFormSave({
        ...statisticsFiltersForm,
        type: {
          value: 'lifetime'
        },
        exportMode: {
          value: 'ALL'
        }
      })
      return
    }
    handlers.modalSet('export-branch-statistics')
  }

  const statisticsLastUpdate = (lastStatisticsStateChange || '00 0070').split(' ')[1] || ''
  const {
    dailyStatistics = {},
    monthlyStatistics = {},
    overallStatistics = {},
    currency
  } = branch || {}

  const {
    averageBookingParticipantsPerCourseEvent,
    averageDailyServiceBookings,
    averageBookingGuestsPerCourseEvent,
    averageWeeklyServiceBookings,
    averageWeeklyCancelledBookings,
    blockerDenialsStatistics,
    cancelledEventsByDay,
    eventSignupsByDaysOfWeek,
    eventSignupsByDays,
    eventSignupsByDaysWithGuests,
    eventSignupsByHoursOfDay,
    eventsByDays,
    onlineMarketingUtmStatistics,
    sickDenialsStatistics,
    topBookingBookableResources,
    topBookingDependencyResources,
    topBookingResources,
    topBookingServices,
    topBookingServicesWithPrice,
    topCancelledBookingServices,
    totalCancelledEvents,
    totalCancelledInternalEvents,
    totalCancelledOnlineEvents,
    totalCourseBookingGuests,
    totalCourseBookingParticipants,
    totalCourseBookings,
    totalCourseBookingsWithId,
    totalCourseBookingsWithTitle,
    totalCourseEvents,
    totalPastCourseEventSeats,
    totalFutureCourseEventSeats,
    totalCourseEventsWithId,
    totalCourseEventsWithTitle,
    totalCourseInStorePaidRevenue,
    totalCourseOnlinePaidRevenue,
    totalCourseRevenue,
    totalCustomers,
    totalEvents,
    totalFutureCourseEventBookedSeats,
    totalInternalCourseBookingsWithId,
    totalInternalCourseBookingsWithTitle,
    totalInternalCustomers,
    totalInternalServiceEventsWithId,
    totalInternalServiceEventsWithIdAndCustomer,
    totalOnlineCourseBookingsWithId,
    totalOnlineCourseBookingsWithTitle,
    totalOnlineCustomers,
    totalOnlinePaidRevenue,
    totalOnlineServiceEventsWithId,
    totalOnlineServiceEventsWithIdAndCustomer,
    totalPastCourseEventBookedSeats,
    totalRescheduledEvents,
    totalResources,
    totalResourcesOnlineBookedTimes,
    totalResourcesOnlineTimes,
    totalResourcesWorkingTimes,
    totalResourcesWorkingBookedTimes,
    totalRevenue,
    totalServiceEvents,
    totalServiceEventsWithCustomer,
    totalServiceEventsWithId,
    totalServiceEventsWithIdAndCustomer,
    totalServiceEventsWithTitle,
    totalServiceEventsWithTitleAndCustomer,
    totalServiceInStorePaidRevenue,
    totalServiceOnlinePaidRevenue,
    totalServiceRevenue,
    vacationDenialsStatistics
  } = dailyStatistics || {}
  const {
    topBookingServices: topBookingServicesOverall,
    totalCourseBookings: totalCourseBookingsOverall,
    totalCourseBookingsWithId: totalCourseBookingsWithIdOverall,
    totalCourseBookingsWithTitle: totalCourseBookingsWithTitleOverall,
    totalCourseEvents: totalCourseEventsOverall,
    totalCourseEventsWithId: totalCourseEventsWithIdOverall,
    totalCourseEventsWithTitle: totalCourseEventsWithTitleOverall,
    totalCourseInStorePaidRevenue: totalCourseInStorePaidRevenueOverall,
    totalCourseOnlinePaidRevenue: totalCourseOnlinePaidRevenueOverall,
    totalCourseRevenue: totalCourseRevenueOverall,
    totalCustomers: totalCustomersOverall,
    totalInternalCourseBookingsWithId: totalInternalCourseBookingsWithIdOverall,
    totalInternalCourseBookingsWithTitle: totalInternalCourseBookingsWithTitleOverall,
    totalInternalCustomers: totalInternalCustomersOverall,
    totalInternalServiceEventsWithId: totalInternalServiceEventsWithIdOverall,
    totalInternalServiceEventsWithIdAndCustomer: totalInternalServiceEventsWithIdAndCustomerOverall,
    totalOnlineCourseBookingsWithId: totalOnlineCourseBookingsWithIdOverall,
    totalOnlineCourseBookingsWithTitle: totalOnlineCourseBookingsWithTitleOverall,
    totalOnlineCustomers: totalOnlineCustomersOverall,
    totalOnlineServiceEventsWithId: totalOnlineServiceEventsWithIdOverall,
    totalOnlineServiceEventsWithIdAndCustomer: totalOnlineServiceEventsWithIdAndCustomerOverall,
    totalRevenue: totalRevenueOverall,
    totalServiceEvents: totalServiceEventsOverall,
    totalServiceEventsWithCustomer: totalServiceEventsWithCustomerOverall,
    totalServiceEventsWithId: totalServiceEventsWithIdOverall,
    totalServiceEventsWithIdAndCustomer: totalServiceEventsWithIdAndCustomerOverall,
    totalServiceEventsWithTitle: totalServiceEventsWithTitleOverall,
    totalServiceEventsWithTitleAndCustomer: totalServiceEventsWithTitleAndCustomerOverall,
    totalServiceInStorePaidRevenue: totalServiceInStorePaidRevenueOverall,
    totalServiceOnlinePaidRevenue: totalServiceOnlinePaidRevenueOverall,
    totalServiceRevenue: totalServiceRevenueOverall
  } = overallStatistics || {}

  //* FILTER + BRANCHES INFORMATION *//
  const isFirstDayOfMonth = moment().date() === 1
  const currentMonth = moment().format('MM')
  const isFilteredMonthCurrent = currentMonth === filtersMonth
  const filteredMonthLabel = (monthOptions.find(item => item.value === filtersMonth) || { label: '' }).label.split(' ')[0]
  const { month: dailyStatisticsMonth } = dailyStatistics || {}
  const { month: monthlyStatisticsMonth } = monthlyStatistics || {}
  const areStatisticsForSameMonth = dailyStatisticsMonth === monthlyStatisticsMonth

  //* STATISTICS BY MONTH *//
  // Single bookings - all single bookings with or without customers
  const totalServiceEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEvents : totalServiceEvents
  const totalServiceEventsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithTitle : totalServiceEventsWithTitle
  const totalServiceEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithId : totalServiceEventsWithId
  const totalOnlineServiceEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineServiceEventsWithId : totalOnlineServiceEventsWithId
  const totalInternalServiceEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalServiceEventsWithId : totalInternalServiceEventsWithId
  // Single bookings - all single bookings with customers
  const totalServiceEventsWithTitleAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithTitleAndCustomer : totalServiceEventsWithTitleAndCustomer
  const totalServiceEventsWithCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithCustomer : totalServiceEventsWithCustomer
  const totalOnlineServiceEventsWithIdAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineServiceEventsWithIdAndCustomer : totalOnlineServiceEventsWithIdAndCustomer
  const totalInternalServiceEventsWithIdAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalServiceEventsWithIdAndCustomer : totalInternalServiceEventsWithIdAndCustomer
  const totalServiceEventsWithIdAndCustomerValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceEventsWithIdAndCustomer : totalServiceEventsWithIdAndCustomer
  // Avg single bookings /day
  const averageDailyServiceBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.averageDailyServiceBookings : averageDailyServiceBookings
  // Avg single bookings /week
  const averageWeeklyServiceBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.averageWeeklyServiceBookings : averageWeeklyServiceBookings
  // Group bookings
  const totalCourseEventsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseEventsWithTitle : totalCourseEventsWithTitle
  const totalCourseEventsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseEventsWithId : totalCourseEventsWithId
  const totalCourseEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseEvents : totalCourseEvents
  // Group booking sign-ups
  const totalOnlineCourseBookingsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineCourseBookingsWithTitle : totalOnlineCourseBookingsWithTitle
  const totalInternalCourseBookingsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalCourseBookingsWithTitle : totalInternalCourseBookingsWithTitle
  const totalCourseBookingsWithTitleValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingsWithTitle : totalCourseBookingsWithTitle
  const totalOnlineCourseBookingsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineCourseBookingsWithId : totalOnlineCourseBookingsWithId
  const totalInternalCourseBookingsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalCourseBookingsWithId : totalInternalCourseBookingsWithId
  const totalCourseBookingsWithIdValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingsWithId : totalCourseBookingsWithId
  const totalCourseBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookings : totalCourseBookings
  // Group guests vs participants + totalCourseBookings
  const totalCourseBookingParticipantsValue = (areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingParticipants : totalCourseBookingParticipants) || 0
  const totalCourseBookingGuestsValue = (areStatisticsForSameMonth ? monthlyStatistics.totalCourseBookingGuests : totalCourseBookingGuests) || 0
  const totalCourseBookingGuestsAndParticipantsValue = totalCourseBookingParticipantsValue + totalCourseBookingGuestsValue
  // Group capacity /NOT PRESENT DASHBOARD/
  const totalFutureCourseEventBookedSeatsValue = areStatisticsForSameMonth ? monthlyStatistics.totalFutureCourseEventBookedSeats : totalFutureCourseEventBookedSeats
  const totalPastCourseEventBookedSeatsValue = areStatisticsForSameMonth ? monthlyStatistics.totalPastCourseEventBookedSeats : totalPastCourseEventBookedSeats
  const totalCourseEventBookedSeatsValue = totalFutureCourseEventBookedSeatsValue + totalPastCourseEventBookedSeatsValue
  const totalPastCourseEventSeatsValue = areStatisticsForSameMonth ? monthlyStatistics.totalPastCourseEventSeats : totalPastCourseEventSeats
  const totalFutureCourseEventSeatsValue = areStatisticsForSameMonth ? monthlyStatistics.totalFutureCourseEventSeats : totalFutureCourseEventSeats
  const totalCourseEventSeatsValue = totalPastCourseEventSeatsValue + totalFutureCourseEventSeatsValue
  // Group participants /NOT PRESENT DASHBOARD/
  const totalCourseBookingParticipantsDaily = totalCourseBookingParticipants
  const totalCourseBookingParticipantsMonthly = monthlyStatistics.totalCourseBookingParticipants
  // Avg participants
  const averageBookingParticipantsPerCourseEventValue = areStatisticsForSameMonth ? monthlyStatistics.averageBookingParticipantsPerCourseEvent : averageBookingParticipantsPerCourseEvent
  // Group guests /NOT PRESENT DASHBOARD/
  const totalCourseBookingGuestsDaily = totalCourseBookingGuests
  const totalCourseBookingGuestsMonthly = monthlyStatistics.totalCourseBookingGuests
  // Avg guests
  const averageBookingGuestsPerCourseEventValue = areStatisticsForSameMonth ? monthlyStatistics.averageBookingGuestsPerCourseEvent : averageBookingGuestsPerCourseEvent
  // Peak sign-up times
  const eventSignupsByHoursOfDayValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByHoursOfDay : eventSignupsByHoursOfDay
  // Peak sign-up days
  const eventSignupsByDaysOfWeekValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByDaysOfWeek : eventSignupsByDaysOfWeek
  // Daily sign-up totals
  const eventSignupsByDaysValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByDays : eventSignupsByDays
  const eventSignupsByDaysWithGuestsValue = areStatisticsForSameMonth ? monthlyStatistics.eventSignupsByDaysWithGuests : eventSignupsByDaysWithGuests
  const eventSignUpsByDays = signUpTimesValue ? eventSignupsByDaysWithGuestsValue : eventSignupsByDaysValue
  // Daily calendar bookings
  const eventsByDaysValue = areStatisticsForSameMonth ? monthlyStatistics.eventsByDays : eventsByDays
  // Online marketing conversions
  const onlineMarketingStatisticsDaily = (onlineMarketingUtmStatistics && Array.isArray(onlineMarketingUtmStatistics[branchMarketingConversionsValue])) ? onlineMarketingUtmStatistics[branchMarketingConversionsValue] : []
  const onlineMarketingStatisticsMonthly = (monthlyStatistics.onlineMarketingUtmStatistics && Array.isArray(monthlyStatistics.onlineMarketingUtmStatistics[branchMarketingConversionsValue])) ? monthlyStatistics.onlineMarketingUtmStatistics[branchMarketingConversionsValue] : []
  const onlineMarketingStatisticsValue = areStatisticsForSameMonth ? onlineMarketingStatisticsMonthly : onlineMarketingStatisticsDaily
  // Most booked services
  const topBookingServicesValue = areStatisticsForSameMonth ? monthlyStatistics.topBookingServices : topBookingServices
  // Cancellations
  const totalCancelledInternalEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCancelledInternalEvents : totalCancelledInternalEvents
  const totalCancelledOnlineEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCancelledOnlineEvents : totalCancelledOnlineEvents
  const totalCancelledEventsValue = areStatisticsForSameMonth ? monthlyStatistics.totalCancelledEvents : totalCancelledEvents
  // Avg cancellations /week
  const averageWeeklyCancelledBookingsValue = areStatisticsForSameMonth ? monthlyStatistics.averageWeeklyCancelledBookings : averageWeeklyCancelledBookings
  // Rescheduled - totalRescheduledEvents => has daily + monthlyValue
  const totalRescheduledEventsDaily = totalRescheduledEvents
  const totalRescheduledEventsMonthly = monthlyStatistics.totalRescheduledEvents
  // Cancellations - pie chart /NOT PRESENT IN DASHBOARD/
  const topCancelledBookingServicesValue = areStatisticsForSameMonth ? monthlyStatistics.topCancelledBookingServices : topCancelledBookingServices
  // Cancellations /day - area chart /NOT PRESENT IN DASHBOARD/
  const cancelledEventsByDayValue = areStatisticsForSameMonth ? monthlyStatistics.cancelledEventsByDay : cancelledEventsByDay
  // Revenue
  const totalServiceOnlinePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceOnlinePaidRevenue : totalServiceOnlinePaidRevenue
  const totalServiceInStorePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceInStorePaidRevenue : totalServiceInStorePaidRevenue
  const totalServiceRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalServiceRevenue : totalServiceRevenue
  const totalCourseOnlinePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseOnlinePaidRevenue : totalCourseOnlinePaidRevenue
  const totalCourseInStorePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseInStorePaidRevenue : totalCourseInStorePaidRevenue
  const totalCourseRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalCourseRevenue : totalCourseRevenue
  const totalRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalRevenue : totalRevenue
  // Frequently booked services with a fee
  const topBookingServicesWithPriceValue = areStatisticsForSameMonth ? monthlyStatistics.topBookingServicesWithPrice : topBookingServicesWithPrice
  // Paid in advance vs. on-site + totalRevenue
  const totalOnlinePaidRevenueValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlinePaidRevenue : totalOnlinePaidRevenue
  // Total resources - totalResources => has daily + monthlyValue
  const totalResourcesDaily = totalResources
  const totalResourcesMonthly = monthlyStatistics.totalResources
  // Sick days
  const { days: sickDenialsDaysDaily } = sickDenialsStatistics || {}
  const { days: sickDenialsDaysMonthly } = monthlyStatistics.sickDenialsStatistics || {}
  // Blocked days
  const { days: blockerDenialsDaysDaily } = blockerDenialsStatistics || {}
  const { days: blockerDenialsDaysMonthly } = monthlyStatistics.blockerDenialsStatistics || {}
  // Holidays /WILL BE RELEASED IN V2/
  const { days: vacationDenialsDaysDaily } = vacationDenialsStatistics || {}
  const { days: vacationDenialsDaysMonthly } = monthlyStatistics.vacationDenialsStatistics || {}
  // Working hours capacity
  const {
    hours: totalResourcesWorkingHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesWorkingTimes : totalResourcesWorkingTimes) || {}
  const {
    hours: totalResourcesWorkingBookedHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesWorkingBookedTimes : totalResourcesWorkingBookedTimes) || {}
  // Booking hours capacity
  const {
    hours: totalResourcesOnlineHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesOnlineTimes : totalResourcesOnlineTimes) || {}
  const {
    hours: totalResourcesOnlineBookedHours
  } = (areStatisticsForSameMonth ? monthlyStatistics.totalResourcesOnlineBookedTimes : totalResourcesOnlineBookedTimes) || {}
  // Most booked resources
  const { type: statisticsMostBookedResourcesTypeFormType } = statisticsMostBookedResourcesTypeForm || {}
  const { value: statisticsMostBookedResourcesTypeFormValue } = statisticsMostBookedResourcesTypeFormType || {}
  const topBookingResourcesValue = {
    topBookingResources: areStatisticsForSameMonth ? monthlyStatistics.topBookingResources : topBookingResources,
    topBookingBookableResources: areStatisticsForSameMonth ? monthlyStatistics.topBookingBookableResources : topBookingBookableResources,
    topBookingDependencyResources: areStatisticsForSameMonth ? monthlyStatistics.topBookingDependencyResources : topBookingDependencyResources
  }[statisticsMostBookedResourcesTypeFormValue] || null
  // Customers
  const totalInternalCustomersValue = areStatisticsForSameMonth ? monthlyStatistics.totalInternalCustomers : totalInternalCustomers
  const totalOnlineCustomersValue = areStatisticsForSameMonth ? monthlyStatistics.totalOnlineCustomers : totalOnlineCustomers
  const totalCustomersValue = areStatisticsForSameMonth ? monthlyStatistics.totalCustomers : totalCustomers

  return (
    !isPending
      ? <BranchesPreviewStatistics
        // FILTER + BRANCHES INFORMATION
          branch={branch}
          dailyStatistics={dailyStatistics}
          dailyStatisticsMonth={dailyStatisticsMonth}
          monthlyStatisticsMonth={monthlyStatisticsMonth}
          statisticsLastUpdate={statisticsLastUpdate}
          isPending={!areStatisticsFetched}
          filtersType={filtersType}
          filtersYear={filtersYear}
          filtersMonth={filtersMonth}
          isFirstDayOfMonth={isFirstDayOfMonth}
          isFilteredMonthCurrent={isFilteredMonthCurrent}
          filteredMonthLabel={filteredMonthLabel}
          onFilterYearSubmit={onFilterYearSubmit}
          onFilterMonthSubmit={onFilterMonthSubmit}
          onFilterTypeChange={onFilterTypeChange}
          areStatisticsForSameMonth={areStatisticsForSameMonth}
          currency={currency}
          locale={locale}
          isMessageShown={isMessageShown}
          setIsMessageShown={setIsMessageShown}
          onExportClick={onExportClick}
          messageExport={messageExport}
        // OVERALL STATISTICS VALUES
          topBookingServicesOverall={topBookingServicesOverall}
          totalCourseBookingsOverall={totalCourseBookingsOverall}
          totalCourseBookingsWithIdOverall={totalCourseBookingsWithIdOverall}
          totalCourseBookingsWithTitleOverall={totalCourseBookingsWithTitleOverall}
          totalCourseEventsOverall={totalCourseEventsOverall}
          totalCourseEventsWithIdOverall={totalCourseEventsWithIdOverall}
          totalCourseEventsWithTitleOverall={totalCourseEventsWithTitleOverall}
          totalCourseInStorePaidRevenueOverall={totalCourseInStorePaidRevenueOverall}
          totalCourseOnlinePaidRevenueOverall={totalCourseOnlinePaidRevenueOverall}
          totalCourseRevenueOverall={totalCourseRevenueOverall}
          totalCustomersOverall={totalCustomersOverall}
          totalInternalCourseBookingsWithIdOverall={totalInternalCourseBookingsWithIdOverall}
          totalInternalCourseBookingsWithTitleOverall={totalInternalCourseBookingsWithTitleOverall}
          totalInternalCustomersOverall={totalInternalCustomersOverall}
          totalInternalServiceEventsWithIdOverall={totalInternalServiceEventsWithIdOverall}
          totalInternalServiceEventsWithIdAndCustomerOverall={totalInternalServiceEventsWithIdAndCustomerOverall}
          totalOnlineCourseBookingsWithIdOverall={totalOnlineCourseBookingsWithIdOverall}
          totalOnlineCourseBookingsWithTitleOverall={totalOnlineCourseBookingsWithTitleOverall}
          totalOnlineCustomersOverall={totalOnlineCustomersOverall}
          totalOnlineServiceEventsWithIdOverall={totalOnlineServiceEventsWithIdOverall}
          totalOnlineServiceEventsWithIdAndCustomerOverall={totalOnlineServiceEventsWithIdAndCustomerOverall}
          totalRevenueOverall={totalRevenueOverall}
          totalServiceEventsOverall={totalServiceEventsOverall}
          totalServiceEventsWithCustomerOverall={totalServiceEventsWithCustomerOverall}
          totalServiceEventsWithIdOverall={totalServiceEventsWithIdOverall}
          totalServiceEventsWithIdAndCustomerOverall={totalServiceEventsWithIdAndCustomerOverall}
          totalServiceEventsWithTitleOverall={totalServiceEventsWithTitleOverall}
          totalServiceEventsWithTitleAndCustomerOverall={totalServiceEventsWithTitleAndCustomerOverall}
          totalServiceInStorePaidRevenueOverall={totalServiceInStorePaidRevenueOverall}
          totalServiceOnlinePaidRevenueOverall={totalServiceOnlinePaidRevenueOverall}
          totalServiceRevenueOverall={totalServiceRevenueOverall}
        // NORMAL STATISTICS VALUES
          totalServiceEventsWithTitleAndCustomer={totalServiceEventsWithTitleAndCustomerValue}
          totalServiceEventsWithCustomer={totalServiceEventsWithCustomerValue}
          totalOnlineServiceEventsWithIdAndCustomer={totalOnlineServiceEventsWithIdAndCustomerValue}
          totalInternalServiceEventsWithIdAndCustomer={totalInternalServiceEventsWithIdAndCustomerValue}
          totalServiceEventsWithIdAndCustomer={totalServiceEventsWithIdAndCustomerValue}
          averageDailyServiceBookings={averageDailyServiceBookingsValue}
          averageWeeklyServiceBookings={averageWeeklyServiceBookingsValue}
          totalCourseEventsWithTitle={totalCourseEventsWithTitleValue}
          totalCourseEventsWithId={totalCourseEventsWithIdValue}
          totalCourseEvents={totalCourseEventsValue}
          totalOnlineCourseBookingsWithTitle={totalOnlineCourseBookingsWithTitleValue}
          totalInternalCourseBookingsWithTitle={totalInternalCourseBookingsWithTitleValue}
          totalCourseBookingsWithTitle={totalCourseBookingsWithTitleValue}
          totalOnlineCourseBookingsWithId={totalOnlineCourseBookingsWithIdValue}
          totalInternalCourseBookingsWithId={totalInternalCourseBookingsWithIdValue}
          totalCourseBookingsWithId={totalCourseBookingsWithIdValue}
          totalCourseBookingGuests={totalCourseBookingGuestsValue}
          totalCourseBookingGuestsAndParticipants={totalCourseBookingGuestsAndParticipantsValue}
          averageBookingParticipantsPerCourseEvent={averageBookingParticipantsPerCourseEventValue}
          averageBookingGuestsPerCourseEvent={averageBookingGuestsPerCourseEventValue}
          totalCancelledInternalEvents={totalCancelledInternalEventsValue}
          totalCancelledOnlineEvents={totalCancelledOnlineEventsValue}
          totalCancelledEvents={totalCancelledEventsValue}
          averageWeeklyCancelledBookings={averageWeeklyCancelledBookingsValue}
          totalRescheduledEventsDaily={totalRescheduledEventsDaily}
          totalRescheduledEventsMonthly={totalRescheduledEventsMonthly}
          eventSignupsByHoursOfDay={eventSignupsByHoursOfDayValue}
          eventSignupsByDaysOfWeek={eventSignupsByDaysOfWeekValue}
          eventSignupsByDays={eventSignUpsByDays}
          eventsByDays={eventsByDaysValue}
          topBookingServices={topBookingServicesValue}
          totalResourcesDaily={totalResourcesDaily}
          totalResourcesMonthly={totalResourcesMonthly}
          sickDenialsDaysDaily={sickDenialsDaysDaily}
          sickDenialsDaysMonthly={sickDenialsDaysMonthly}
          blockerDenialsDaysDaily={blockerDenialsDaysDaily}
          blockerDenialsDaysMonthly={blockerDenialsDaysMonthly}
          vacationDenialsDaysDaily={vacationDenialsDaysDaily}
          vacationDenialsDaysMonthly={vacationDenialsDaysMonthly}
          totalResourcesWorkingHours={totalResourcesWorkingHours}
          totalResourcesWorkingBookedHours={totalResourcesWorkingBookedHours}
          totalResourcesOnlineHours={totalResourcesOnlineHours}
          totalResourcesOnlineBookedHours={totalResourcesOnlineBookedHours}
          topBookingResourcesValue={topBookingResourcesValue} // for most booked resources chart
          totalInternalCustomers={totalInternalCustomersValue}
          totalOnlineCustomers={totalOnlineCustomersValue}
          totalCustomers={totalCustomersValue}
          totalServiceOnlinePaidRevenue={totalServiceOnlinePaidRevenueValue}
          totalServiceInStorePaidRevenue={totalServiceInStorePaidRevenueValue}
          totalServiceRevenue={totalServiceRevenueValue}
          totalCourseOnlinePaidRevenue={totalCourseOnlinePaidRevenueValue}
          totalCourseInStorePaidRevenue={totalCourseInStorePaidRevenueValue}
          totalCourseRevenue={totalCourseRevenueValue}
          totalRevenue={totalRevenueValue}
          topBookingServicesWithPrice={topBookingServicesWithPriceValue}
          totalOnlinePaidRevenue={totalOnlinePaidRevenueValue}
          totalEvents={totalEvents}
          totalServiceEvents={totalServiceEventsValue}
          totalCourseBookings={totalCourseBookingsValue}
          totalServiceEventsWithTitle={totalServiceEventsWithTitleValue}
          totalOnlineServiceEventsWithId={totalOnlineServiceEventsWithIdValue}
          totalInternalServiceEventsWithId={totalInternalServiceEventsWithIdValue}
          totalServiceEventsWithId={totalServiceEventsWithIdValue}
          totalCourseEventBookedSeats={totalCourseEventBookedSeatsValue}
          totalCourseEventSeats={totalCourseEventSeatsValue}
          totalCourseBookingParticipantsDaily={totalCourseBookingParticipantsDaily}
          totalCourseBookingParticipantsMonthly={totalCourseBookingParticipantsMonthly}
          totalCourseBookingGuestsDaily={totalCourseBookingGuestsDaily}
          totalCourseBookingGuestsMonthly={totalCourseBookingGuestsMonthly}
          onlineMarketingStatistics={onlineMarketingStatisticsValue}
          topCancelledBookingServices={topCancelledBookingServicesValue}
          cancelledEventsByDay={cancelledEventsByDayValue}
        />
      : <Loader active />
  )
}

const maps = state => {
  const { id } = (state.router && state.router.data) || { id: '' }
  const branches = (state.branches && state.branches.list) || []
  const branch = branches.find(item => item.id === id) || {}
  return {
    id: state.router.data.id || '',
    isPending: state.branches.pendingPreview,
    branch,
    statisticsFiltersForm: state.forms.branchStatisticsFilter,
    statisticsMostBookedResourcesTypeForm: state.forms.statisticsMostBookedResourcesType,
    branchMarketingConversionsValue: (state.forms.branchMarketingConversions && state.forms.branchMarketingConversions.type && state.forms.branchMarketingConversions.type.value) || '',
    signUpTimesValue: (state.forms.branchStatisticsSignUpTimes && state.forms.branchStatisticsSignUpTimes.type && state.forms.branchStatisticsSignUpTimes.type.value) || false,
    account: state.account,
    messageExport: state.branches.messageExport
  }
}

export default connect(maps)(BranchesPreviewStatisticsWrapper)
