import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { CoursesCategory, CoursesCategoryWithAccordion } from '../../Beauties'
import { sortByOrderIndex } from '../../Utils'

const CoursesCategoryWrapper = props => {
  let {
    category,
    pendingCategory,
    courses,
    isDragAndDropMode,
    index,
    areCategoriesExpanded,
    filters,
    enterpriseId,
    account
  } = props
  courses = courses || []
  pendingCategory = !!pendingCategory
  category = category || {}
  filters = filters || {}
  account = account || {}
  enterpriseId = enterpriseId || null
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsRead, globalGroupsWrite, globalGroupsDelete } = enterprisePermissions || {}
  globalGroupsRead = !!isAdmin || !!globalGroupsRead
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite
  globalGroupsDelete = !!isAdmin || !!globalGroupsDelete
  const { search } = filters || {}
  const { value: searchValue } = search || {}
  const pending = pendingCategory === category.id
  const filteredCourses = courses.filter(course => {
    if (!isDragAndDropMode && searchValue) {
      return course.categoryId === category.id && (course.name.toLowerCase().includes(searchValue.toLowerCase()) || course.id === searchValue)
    }
    return course.categoryId === category.id
  })

  return (
    <>
      {(isDragAndDropMode &&
        <CoursesCategory
          isDragAndDropMode={isDragAndDropMode}
          category={category}
          courses={filteredCourses}
          pending={pending}
          index={index}
          areCategoriesExpanded={areCategoriesExpanded}
          enterpriseId={enterpriseId}
          globalGroupsRead={globalGroupsRead}
          globalGroupsWrite={globalGroupsWrite}
          globalGroupsDelete={globalGroupsDelete}
        />
      )}
      {(!isDragAndDropMode && (!searchValue || (searchValue && filteredCourses.length > 0)) &&
        <CoursesCategoryWithAccordion
          category={category}
          courses={filteredCourses}
          pending={pending}
          index={index}
          search={searchValue}
          enterpriseId={enterpriseId}
          globalGroupsRead={globalGroupsRead}
          globalGroupsWrite={globalGroupsWrite}
          globalGroupsDelete={globalGroupsDelete}
        />
      )}
    </>
  )
}

CoursesCategoryWrapper.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object)
}

const maps = state => ({
  courses: (state.courses.list || []).sort(sortByOrderIndex),
  pendingCategory: state.courses.pendingCategory,
  categoriesList: state.courses.categoriesList,
  filters: state.forms.coursesFilters,
  enterpriseId: (state.account.enterprise && state.account.enterprise.id),
  account: state.account
})

export default memo(connect(maps)(CoursesCategoryWrapper))
