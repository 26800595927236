import { handlers, store } from '../../../Store'

export const coursesMoved = courses => {
  handlers.coursesListOrderChanged(courses)
}

export const courseUpdated = course => {
  if (!course) return
  const { courses = {} } = store.getState()
  const { isComplete, ts, resourcesCount } = courses.list.find(item => item.id === course.id) || {}
  if (ts === course.ts && resourcesCount === course.resourcesCount) return
  if (isComplete) return handlers.coursePreviewGet({ id: course.id, forceFetch: true })
  handlers.courseUpdate(course)
}

export const courseDeleted = id => {
  if (!id) return
  const { courses = {} } = store.getState()
  const course = courses.list.find(item => item.id === id) || {}
  if (!course || course.isDeleted) return
  handlers.courseDeleted(id)
  setTimeout(() => handlers.courseRemoveDeleted(id), 2000)
}

export const courseCategoriesMoved = categories => {
  handlers.courseCategoryOrderChanged(categories)
}

export const courseCategoryUpdated = category => {
  if (!category) return
  handlers.courseCategoryUpdate(category)
}

export const courseCategoryDeleted = id => {
  if (!id) return
  const { courses = {} } = store.getState()
  const category = courses.categoriesList.find(item => item.id === id) || {}
  if (!category || category.isDeleted) return
  handlers.courseCategoryDeleted(id)
  setTimeout(() => handlers.courseCategoryRemoveDeleted(id), 2000)
}
