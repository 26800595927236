import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { UserAccountSecurity } from '../../Beauties'

const UserAccountSecurityWrapper = (props) => {
  const { pending, form, recoveryCodes, settings } = props
  const { isAlreadyEnabled2FA, enabled2FA } = form
  const isEnterpriseRequire2FA = settings && settings.requiredEnterpriseAuth2FAType === 'OTP'

  useEffect(() => {
    handlers.userSecurityFormGet()

    return () => {
      handlers.twoFARecoveryCodesClear()
    }
  }, [])

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <UserAccountSecurity
          enabled2FA={enabled2FA && enabled2FA.value}
          isAlreadyEnabled2FA={isAlreadyEnabled2FA && isAlreadyEnabled2FA.value}
          isEnterpriseRequire2FA={isEnterpriseRequire2FA}
          recoveryCodes={recoveryCodes}
        />
      )}
    </>
  )
}

UserAccountSecurityWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
}

const maps = state => ({
  pending: state.user.pendingSecurityForm,
  form: state.forms.userSecurity || {},
  recoveryCodes: state.twoFA.recoveryCodes,
  settings: state.company && state.company.settings,
  // for some reasons, user account security tab is not rerendered when locale is changed back to default.
  // adding locale in props will force the tab to rerender
  locale: state.app.locale
})

export default connect(maps)(UserAccountSecurityWrapper)
