import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  MultipleSelect,
  Input,
  Error,
  Row,
  Col,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t,
  Alert
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './CourseCategoryForm.css'

const CourseCategoryForm = props => {
  let {
    title,
    onSubmit,
    isDefault,
    cancelLink,
    showExternalIds,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-courses__category-form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='courseCategory'>
            {(showExternalIds &&
              <FormSection>
                <Title size='m' label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <Title size='m' label={t('global.name')} icon='sliders-h' />
              <Row>
                <Col>
                  <Input
                    name='name'
                    label={t('global.name.label')}
                    hintText={t('servicesGroups.form.groupCategory.fieldName.hint')}
                    limit={50}
                    mandatory
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Title size='m' label={t('global.location')} icon='building' />
              <Row>
                <Col>
                  <HoverPopup disabled={!isDefault}>
                    <HoverPopupContent position='top' autoSize>
                      {t('branches.form.tooltip.defaultCategory.cannotEditLocations')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <MultipleSelect
                        name='location'
                        label={t('global.select.label')}
                        searchable
                        hasAll={hasAll}
                        disableSelected={type === 'edit'}
                        allLabel={t('global.allLocations')}
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(type === 'edit' &&
                <Alert theme='alert'>
                  <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
                </Alert>
              )}
            </FormSection>
            <FormButtons>
              <Error name='courseCategoryGlobalError' />
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CourseCategoryForm
