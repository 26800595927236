import gql from 'graphql-tag'
import { ApolloLink, concat, execute, makePromise } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { store, handlers } from '../../Store'
import {
  ENTERPRISE_SERVER_URL,
  ENTERPRISE_FILE_DOWNLOAD_URL,
  APP_VERSION,
  APP_VERSION_SUFFIX
} from '../../Settings'
import { isObject, decodeHtml } from '../../Utils'
import * as enterprise from './Enterprise'
import * as customersMiddleware from './CustomersMiddleware'
import * as fileDownload from './FileDownload'

const endpoints = {
  Enterprise: {
    url: ENTERPRISE_SERVER_URL,
    definitions: enterprise
  },
  CustomersMiddleware: {
    url: null, // Dynamic value. Must be set in q function
    definitions: customersMiddleware
  },
  FileDownload: {
    url: ENTERPRISE_FILE_DOWNLOAD_URL, // Dynamic value. Must be set in q function
    definitions: fileDownload
  }
}

// Debugging only on development and staging
const VERBOSE_QUERIES = !!['development', 'staging'].includes(process.env.REACT_APP_ENV)

const getDefinition = name => {
  const endpoint = Object
    .keys(endpoints)
    .map(name => endpoints[name])
    .find(endpoint => !!endpoint.definitions[name])

  const definition = endpoint && endpoint.definitions[name]
  if (!definition) throw new Error(`Please define the ${name} query definition`)
  return definition
}

const getEndpointUrl = name => {
  const endpoint = Object
    .keys(endpoints)
    .map(name => endpoints[name])
    .find(endpoint => !!endpoint.definitions[name])

  const endpointUrl = endpoint && endpoint.url
  if (!endpointUrl) throw new Error(`Please define the ${name} query definition`)
  return endpointUrl
}

const link = (name, ignoreAccessToken, headersArray, url) => {
  const httpLink = new HttpLink({ uri: url || getEndpointUrl(name) })
  const authTokenLink = new ApolloLink((operation, forward) => {
    const headers = {}
    const state = store.getState()
    const accessToken = (state.auth && state.auth.tokens && state.auth.tokens.accessToken) || ''
    if (headersArray) {
      headersArray.forEach(header => { headers[header.key] = header.value })
      operation.setContext({ headers })
    }
    if (!accessToken) return forward(operation)
    if (!ignoreAccessToken) {
      headers['authorization'] = 'Bearer ' + accessToken
      headers['pi'] = window.btoa(JSON.stringify({ p_n: APP_VERSION_SUFFIX, p_v: APP_VERSION }))
      operation.setContext({ headers })
    }
    return forward(operation)
  })

  return concat(authTokenLink, httpLink)
}

// run refresh tokens
const runRefreshTokens = async () => {
  const state = store.getState()
  let { refreshToken } = state.auth.tokens || {}
  // see if current access token expired then refresh the tokens
  // with access token expired and no refresh token we log out
  if (!refreshToken) return handlers.logout()
  // get new access token if error here logout
  let tokens = {}
  try {
    tokens = await q('refreshTokens', { refreshToken }, true)
  } catch (err) {
    // in case of error here then logout
    handlers.logout()
  }
  // not access token or duration then logout
  if (!tokens.accessToken || !tokens.sessionDuration) return handlers.logout()
  // consider five minute earlier the refresh
  const expires = (new Date()).getTime() + parseInt(tokens.sessionDuration, 10)
  handlers.authTokensPopulate({ ...tokens, expires })
}

// checks if has to refresh tokens
const checkRefresh = async queryName => {
  const state = store.getState()
  const tokens = state.auth.tokens || {}
  let { accessToken, expires } = tokens
  // see if current access token expired then refresh the tokens
  const expired = expires && expires < new Date().getTime() + (5 * 1000 * 60)
  if (queryName !== 'refreshTokens' && accessToken && expired) {
    await runRefreshTokens()
  }
}

// Decode all encoded chars to prevent double decoding from JSX
const decodeResponse = response => {
  if (!isObject(response)) return response
  if (Array.isArray(response)) {
    return Object
      .keys(response)
      .map(key => {
        const item = response[key]
        if (isObject(item)) return decodeResponse(item)
        return typeof item !== 'string' ? item : decodeHtml(item)
      })
  }
  return Object
    .keys(response)
    .reduce((acc, key) => {
      const item = response[key]
      if (typeof item === 'string') return { ...acc, [key]: decodeHtml(item) }
      return isObject(item) ? { ...acc, [key]: decodeResponse(item) } : { ...acc, [key]: item }
    }, {})
}

// runs a query or mutation
export const q = async (queryName, variables, ignoreAccessToken, headers, url) => {
  const query = getDefinition(queryName)
  // if query is not defined then throw
  if (!query) return console.error(`Define query or mutation with name: ${queryName}`)
  await checkRefresh(queryName)
  const operation = {
    query: gql(query),
    variables
  }
  let result = {}
  try {
    result = await makePromise(execute(link(queryName, ignoreAccessToken, headers, url), operation))
    // if success then connection is still online
    const state = store.getState()
    if (!state.auth.isConnected) handlers.connectionChange(true)
  } catch (e) {
    // special error when jwt expires then need to refresh automatically the tokens
    if (((e.result && e.result.errors) || []).some(({ extensions: { code } = {} }) => code === 'InvalidAccessToken')) {
      await runRefreshTokens()
      // rerun itself
      return q(queryName, variables, ignoreAccessToken, null, url)
    }
    // special error when jwt invalidates then need to logout
    if (((e.result && e.result.errors) || []).some(({ extensions: { code } = {} }) => code === 'JwtInvalidated')) {
      handlers.logout()
    }
    if (VERBOSE_QUERIES) console.error('Executed', queryName, 'with try error', e)

    // connection become offline
    handlers.connectionChange(false)
    return {
      error: {
        code: 'ServerDown',
        message: 'Server down'
      }
    }
  }
  // to review this, seems not ok
  if (!result) {
    return { error: { text: 'Not found ' } }
  }
  // deal with errors
  if (result.errors) {
    const error = result.errors[0]
    const { extensions: { code, exception = {} }, message } = error
    delete exception.stacktrace
    const err = {
      error: { code, message, data: { ...exception } },
      errors: result.errors.map(e => {
        const { extensions: { code, exception = {} }, message } = e
        delete exception.stacktrace
        return { code, message, data: { ...exception } }
      })
    }
    if (VERBOSE_QUERIES) console.error('Executed', queryName, 'with err ', err)
    return err
  }

  // one query at a time usually
  const queryNames = Object.keys(result.data)
  const specific = queryNames.length > 1
  const finalResult = specific ? result.data : result.data[queryNames[0]]
  if (VERBOSE_QUERIES) console.warn('Executed', queryName, 'with', finalResult)
  return decodeResponse(finalResult)
}

// keep here until final refactor
export const runMutation = null
export const runQuery = null
export const runCustomQuery = null
