import React from 'react'
import { connect } from '../../../Store'
import { SecuritySettings } from '../../../Beauties'

const SecuritySettingsWrapper = props => {
  let { settings, rawSettings, hash, account } = props
  settings = settings || {}
  rawSettings = rawSettings || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsRead, settingsWrite } = enterprisePermissions || {}
  settingsRead = !!isAdmin || !!settingsRead
  settingsWrite = !!isAdmin || !!settingsWrite

  return (
    <SecuritySettings
      {...settings}
      rawSettings={rawSettings}
      hash={hash}
      settingsRead={settingsRead}
      settingsWrite={settingsWrite}
    />
  )
}

const maps = state => ({
  settings: state.company.settings,
  rawSettings: state.company.rawSettings,
  hash: state.router.hash,
  account: state.account
})

export default connect(maps)(SecuritySettingsWrapper)
