import React from 'react'
import { GlobalsSettings } from '../../../Beauties'
import { Loader } from '../../../Common'
import { connect } from '../../../Store'

const GlobalsSettingsWrapper = props => {
  let { settings, isPending, hash, account } = props
  isPending = !!isPending
  settings = settings || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsRead, settingsWrite } = enterprisePermissions || {}
  settingsRead = !!isAdmin || !!settingsRead
  settingsWrite = !!isAdmin || !!settingsWrite

  return (
    isPending
      ? <Loader active />
      : (
        <GlobalsSettings
          settingsRead={settingsRead}
          settingsWrite={settingsWrite}
          settings={settings}
          hash={hash}
        />
        )
  )
}

const maps = state => ({
  isPending: state.globalsSettings.pendingPreview,
  hash: state.router.hash,
  settings: state.company.settings,
  account: state.account
})

export default connect(maps)(GlobalsSettingsWrapper)
