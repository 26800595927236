import React from 'react'
import { Avatar, FontAwesome5, Button } from '../../Common'

const NavigationAccount = props => {
  const { name, avatarUrl, isActive } = props
  const classNames = ['ta-navigation__account']
  if (isActive) classNames.push('active')

  return (
    <Button as='link' to='/user' className={classNames.join(' ')}>
      <Avatar name={name} image={avatarUrl} />
      <div className='ta-navigation__account__avatar-icon'>
        <FontAwesome5 icon='pencil' type='solid' />
      </div>
      <div className='ta-navigation__account__name'>{name}</div>
    </Button>
  )
}

export default NavigationAccount
