import {
  payloads$,
  store$,
  actions,
  handlers,
  store,
  globalActions
} from '../..'
import { q } from '../../API'
import {
  customerFieldListErrorTransform,
  customerFieldTransform,
  customerFieldFormValidate,
  customerFieldSaveTransform,
  customerFieldFormServerErrorsTransform,
  customerFieldCategoryFormValidate,
  customerFieldCategoryFormServerErrorsTransform
} from './utils'
import {
  customerFieldEnterpriseUpdated,
  customerFieldEnterpriseDeleted,
  customerFieldEnterpriseCategoryUpdated,
  customerFieldEnterpriseCategoryDeleted
} from './subscriptions'
import { sortByOrderIndex, serverErrorsTransform } from '../../../Utils'
import { t } from '../../../Common'
import { map, distinctUntilChanged } from 'rxjs/operators'

// Global actions
let isLoading = false

globalActions.populateCustomerFieldsAndCategories = async () => {
  if (isLoading) return
  isLoading = true
  const { areFetched = false, categoriesList, list } = store.getState().customerFields
  if (areFetched) {
    isLoading = false
    return {
      getEnterpriseCustomerFields: list,
      getEnterpriseCustomerFieldCategories: categoriesList
    }
  }
  const result = await q('getCustomerFieldsAndCategories')
  const { error } = result || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    isLoading = false
    handlers.customerFieldsListMessageSet(customerFieldListErrorTransform(error))
    return result
  }
  handlers.customerFieldsPopulate({
    customerFields: result.getEnterpriseCustomerFields || [],
    customerFieldsCategories: result.getEnterpriseCustomerFieldCategories || []
  })
  isLoading = false
  return result
}

globalActions.prepareCustomerFieldsForForm = fields => {
  const { list = [] } = store.getState().customerFields
  return (fields || list)
    .map(item => ({
      ...item,
      selectOptions: (item.selectOptions || []).map(option => ({
        label: option.label || t(option.translationKey),
        value: option.value
      }))
    }))
    .filter(item => item.isActive)
    .sort(sortByOrderIndex)
}

globalActions.prepareCustomerFieldsWithValues = values => {
  if (!values) return []
  const parsedValues = {}
  values.forEach(item => {
    parsedValues[item.id] = item.value
  })
  const { list = [] } = store.getState().customerFields
  return list
    .map(item => ({ ...item, value: parsedValues[item.id] === undefined ? null : parsedValues[item.id] }))
    .filter(item => item.isActive)
    .sort(sortByOrderIndex)
}

// List
payloads$(actions.CUSTOMER_FIELDS_LIST_GET)
  .subscribe(async () => {
    setTimeout(() => globalActions.populateCustomerFieldsAndCategories(), 0)
  })

payloads$(actions.CUSTOMER_FIELDS_GET)
  .subscribe(async () => {
    await globalActions.populateCustomerFieldsAndCategories()
    await globalActions.populateBranches()
    handlers.customerFieldsToDeleteGet()
  })

// List to delete
payloads$(actions.CUSTOMER_FIELDS_TO_DELETE_GET)
  .subscribe(async () => {
    const customerFieldsToDelete = await q('getEnterpriseCustomerFields', {
      filter: {
        isGloballyDeleted: true,
        onlyLocals: true
      }
    })
    const { error } = customerFieldsToDelete || {}
    if (error) return handlers.navigateToPath('/customers/customer-fields')
    handlers.customerFieldsToDeletePopulate({ customerFieldsToDelete })
  })

payloads$(actions.CUSTOMER_FIELD_TO_DELETE_DELETE)
  .subscribe(async ({ companyId, region, externalId, id }) => {
    if (!id) return
    const customerField = await q('deleteBranchCustomerField', { companyId, region, externalId, id })
    const { error } = customerField || {}
    if (error) return handlers.navigateToPath('/customers/customer-fields')
    handlers.customerFieldsToDeleteDeleteReady()
  })

// Preview
payloads$(actions.CUSTOMER_FIELD_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/customers/customer-fields')
      return
    }
    const customerFieldsAndCategories = await globalActions.populateCustomerFieldsAndCategories()
    let { getEnterpriseCustomerFields: customerFields } = customerFieldsAndCategories || {}
    customerFields = customerFields || []
    const customerField = customerFields.find(customerField => customerField.id === id) || {}
    const { internalId } = customerField || {}
    handlers.customerFieldLocallyChangedGet({ id, internalId })
    handlers.customerFieldPreviewPopulate(id)
  })

// Category Preview
payloads$(actions.CUSTOMER_FIELD_CATEGORY_PREVIEW_GET)
  .subscribe(async ({ id }) => {
    if (!id) {
      handlers.navigateToPath('/customers/customer-fields')
      return
    }
    handlers.customerFieldCategoryLocallyChangedGet(id)
    handlers.customerFieldCategoryPreviewPopulate(id)
  })

// Locally changed
payloads$(actions.CUSTOMER_FIELD_LOCALLY_CHANGED_GET)
  .subscribe(async ({ id, internalId }) => {
    if (!internalId) return handlers.navigateToPath('/customers/customer-fields')
    const customerFields = await q('getEnterpriseCustomerFields', {
      filter: {
        internalId,
        isUpdatedLocally: true,
        onlyLocals: true
      }
    })
    const { error } = customerFields || {}
    if (error || !customerFields) return handlers.navigateToPath('/customers/customer-fields')
    let branches = store.getState().branches.list || []
    if (branches.length === 0) branches = await globalActions.populateBranches()
    const locallyChangedData = customerFields.map(item => ({ id: item.id, branch: branches.find(branch => branch.id === item.companyId) }))
    handlers.customerFieldLocallyChangedPopulate({ id, locallyChangedData })
  })

payloads$(actions.CUSTOMER_FIELD_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const customerField = await q('resetGlobalBranchCustomerField', { companyId, region, internalId })
    const { error } = customerField || {}
    if (error || !customerField) return handlers.navigateToPath('/customers/customer-fields')
    handlers.customerFieldLocallyChangedResetReady({ customerField, companyId })
  })

// Form
payloads$(actions.CUSTOMER_FIELD_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateServicesAndServiceCategories()
    await globalActions.populateCoursesAndCourseCategories()
    await globalActions.populateCustomerFieldsAndCategories()

    const state = store.getState()
    const services = (state.services && state.services.list) || []
    const servicesCategories = (state.services && state.services.categoriesList) || []
    const courses = (state.courses && state.courses.list) || []
    const coursesCategories = (state.courses && state.courses.categoriesList) || []
    let { list: branches, areFetched } = state.branches || {}
    branches = branches || []
    if (!areFetched) {
      branches = await globalActions.populateBranches()
    }
    const { query = {} } = state.router || {}
    const { list = [], categoriesList = [] } = state.customerFields || {}
    const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || {}
    const defaultCategory = categoriesList.find(category => category.isDefault) || {}

    queryCategory.name = queryCategory.name || t(queryCategory.translationKey)
    defaultCategory.name = defaultCategory.name || t(defaultCategory.translationKey)

    if (!id) {
      handlers.customerFieldFormPopulate({
        customerField: {
          category: queryCategory || defaultCategory,
          availableServices: services,
          availableServicesCategories: servicesCategories,
          availableCourses: courses,
          availableCoursesCategories: coursesCategories,
          activeNameFields: []
        },
        branches,
        account: state.account
      })
      return
    }

    const selectedCustomerField = list.find(customerField => customerField.id === id) || {}
    const customerFieldCategory = categoriesList.find(category => category.id === selectedCustomerField.categoryId) || {}
    const { isComplete } = selectedCustomerField || {}
    const customerField = isComplete
      ? selectedCustomerField
      : customerFieldTransform(await q('getEnterpriseCustomerField', { id }))
    const { error } = customerField || {}
    if (error || !customerField) {
      handlers.navigateToPath(id ? `/customers/customer-fields/${id}` : '/customers/customer-fields')
      return
    }
    const filterByDefaultId = ['firstName', 'lastName', 'company']
    const activeNameFields = list.filter(item => filterByDefaultId.includes(item.defaultId) && item.isActive)
    const publishedNameFields = list.filter(item => filterByDefaultId.includes(item.defaultId) && item.isPublished)
    customerFieldCategory.name = customerFieldCategory.name || t(customerFieldCategory.translationKey)
    handlers.customerFieldUpdate({ ...customerField, isComplete: true })
    handlers.customerFieldFormPopulate({
      customerField: {
        ...customerField,
        label: customerField.label || t(customerField.translationKey),
        category: customerFieldCategory || defaultCategory,
        availableServices: services,
        availableServicesCategories: servicesCategories,
        availableCourses: courses,
        availableCoursesCategories: coursesCategories,
        activeNameFields,
        publishedNameFields
      },
      branches,
      account: state.account
    })
  })

payloads$(actions.CUSTOMER_FIELD_UPDATE)
  .subscribe(async customerField => {
    if (!customerField) return handlers.customerFieldPreviewPopulate()
    setTimeout(() => handlers.customerFieldUpdated(customerField), 2000)
  })

// Save
payloads$(actions.CUSTOMER_FIELD_FORM_SAVE)
  .subscribe(async ({ customerField, scrollToError }) => {
    const state = store.getState()
    const { branches, customerFields } = state
    const { list: branchesList } = branches || {}
    const { list } = customerFields || {}
    const errors = customerFieldFormValidate(customerField, list)
    if (errors.length) return setCustomerFieldFormSaveErrors(errors, scrollToError)
    const savedCustomerField = await q('saveEnterpriseCustomerField', customerFieldSaveTransform(customerField))
    const { error, id } = savedCustomerField || {}
    if (error) {
      return setCustomerFieldFormSaveErrors(customerFieldFormServerErrorsTransform({
        error,
        branches: branchesList
      }), scrollToError)
    }
    await globalActions.populateCustomerFieldsAndCategories()
    handlers.customerFieldUpdate({ ...savedCustomerField, isComplete: true })
    handlers.navigateToPath(`/customers/customer-fields/${id}`)
  })

const setCustomerFieldFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('customerFields', errors)
  scrollToError && scrollToError(errors)
  handlers.customerFieldFormReady()
}

// Delete
payloads$(actions.CUSTOMER_FIELD_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.customerFieldPreviewPopulate()
    const { error } = await q('deleteEnterpriseCustomerField', { id })
    if (error) {
      handlers.customerFieldPreviewPopulate()
      return
    }
    handlers.customerFieldDeleted(id)
    setTimeout(() => handlers.customerFieldRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/customers/customer-fields'), 0)
  })

// Delete form save
payloads$(actions.CUSTOMER_FIELD_DELETE_FORM_SAVE)
  .subscribe(async ({ customerField, scrollToError }) => {
    const { id, name } = customerField || {}
    let { value: nameValue } = name || {}
    nameValue = nameValue || ''
    if (!id) return
    const customerFields = store.getState().customerFields.list || []
    const selectedCustomerField = customerFields.find(item => item.id === id)
    if (!selectedCustomerField) return
    let { label } = selectedCustomerField || {}
    label = label || selectedCustomerField.defaultId
    if (!nameValue || (nameValue.toLowerCase() !== label.toLowerCase())) {
      handlers.formErrorsSet('customerFieldsDelete', [{ key: 'globalErrors', value: 'The data field name does not match' }])
      handlers.customerFieldDeleteFormReady()
      return
    }
    handlers.customerFieldDelete(id)
    handlers.popupHide('customer-fields-delete')
    setTimeout(() => { handlers.popupSet() }, 500)
  })

// Category form
payloads$(actions.CUSTOMER_FIELD_CATEGORY_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateCustomerFieldsAndCategories()
    const state = store.getState()
    const { customerFields, branches, account } = state
    const { categoriesList } = customerFields || {}
    let { list: branchesList, areFetched } = branches || {}
    branchesList = branchesList || []
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    const selectedCategory = categoriesList.find(category => category.id === id) || {}
    selectedCategory.name = selectedCategory.name || (selectedCategory.translationKey && t(selectedCategory.translationKey)) || ''
    handlers.customerFieldCategoryFormPopulate({ category: selectedCategory, branches: branchesList, account })
  })

// Category save
payloads$(actions.CUSTOMER_FIELD_CATEGORY_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const state = store.getState()
    let { branches, customerFields } = state
    branches = branches || {}
    customerFields = customerFields || {}
    let { list: branchesList } = branches || {}
    let { categoriesList } = customerFields || {}
    branchesList = branchesList || []
    categoriesList = categoriesList || []
    const categoryExternalIds = categoriesList.map(category => category.externalId).filter(Boolean)
    const errors = customerFieldCategoryFormValidate(form, categoryExternalIds)
    if (errors.length) return setCustomerFieldCategoryErrors(errors, scrollToError)
    delete form.ownExternalId
    const savedCategory = await q('saveEnterpriseCustomerFieldCategory', form)
    const { error } = savedCategory
    if (error) {
      return setCustomerFieldCategoryErrors(customerFieldCategoryFormServerErrorsTransform({
        error,
        branches: branchesList
      }), scrollToError)
    }
    handlers.customerFieldCategoryUpdate(savedCategory)
    handlers.navigateToPath('/customers/customer-fields')
  })

const setCustomerFieldCategoryErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('customerFieldCategory', errors)
  handlers.customerFieldCategoryFormReady()
  scrollToError && scrollToError(errors)
}

// Category delete
payloads$(actions.CUSTOMER_FIELD_CATEGORY_DELETE)
  .subscribe(async id => {
    if (!id) return
    const response = await q('deleteEnterpriseCustomerFieldCategory', { id })
    const { error } = response || { error: { text: 'errors.api.unavailable' } }
    if (error) return setCategoryError(id, serverErrorsTransform(error))
    handlers.customerFieldCategoryDeleted(id)
    setTimeout(() => handlers.customerFieldCategoryRemoveDeleted(id), 2000)
  })

const setCategoryError = (id, errors, scrollToError) => {
  handlers.formErrorsSet(`customerFieldCategory${id}`, errors)
  handlers.customerFieldCategoryDeleteFailed()
  scrollToError && scrollToError(errors)
  setTimeout(() => {
    handlers.formErrorsSet(`customerFieldCategory${id}`, [])
  }, 3500)
}

// Category preview
payloads$(actions.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_GET)
  .subscribe(async (categoryId) => {
    if (!categoryId) return handlers.navigateToPath('/customers/customer-fields')
    const { branches, customerFields } = store.getState()
    await globalActions.populateBranches()
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    let { categoriesList } = customerFields || {}
    categoriesList = categoriesList || []
    if (categoriesList.length < 1) {
      const customerFields = await globalActions.populateCustomerFieldsAndCategories() || {}
      let { getEnterpriseCustomerFieldCategories } = customerFields || {}
      getEnterpriseCustomerFieldCategories = getEnterpriseCustomerFieldCategories || []
      categoriesList = getEnterpriseCustomerFieldCategories || []
    }
    const category = categoriesList.find(item => item.id === categoryId) || {}
    const { locallyUpdatedBranches = {} } = category || {}
    const locallyUpdatedBranchesArr = Object.keys(locallyUpdatedBranches || {}) || []
    const locallyChangedData = locallyUpdatedBranchesArr.map(item => ({
      id: categoryId,
      branch: branchesList.find(branch => branch.id === item) || item
    }))
    handlers.customerFieldCategoryLocallyChangedPopulate({ id: categoryId, locallyChangedData })
  })

payloads$(actions.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const customerFieldCategory = await q('resetGlobalBranchCustomerFieldCategory', { companyId, region, internalId })
    const { error } = customerFieldCategory || {}
    if (error || !customerFieldCategory) return handlers.navigateToPath('/customers/customer-fields')
    handlers.customerFieldCategoryLocallyChangedResetReady({ customerFieldCategory, companyId })
  })

// Subscription
payloads$(actions.CUSTOMER_FIELDS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts, ids }) => {
    const updateSubscriptions = ['customerFieldEnterpriseUpdated', 'customerFieldEnterpriseGlobalUpdated']
    const deleteSubscriptions = ['customerFieldEnterpriseDeleted', 'customerFieldEnterpriseGlobalDeleted']
    const categoryUpdateSubscriptions = ['customerFieldCategoryEnterpriseUpdated', 'customerFieldCategoryEnterpriseGlobalUpdated']
    const categoryDeleteSubscriptions = ['customerFieldCategoryEnterpriseDeleted', 'customerFieldCategoryEnterpriseGlobalDeleted']

    // if (name === 'customerFieldsMoved' && data) customerFieldsMoved(data)
    if (updateSubscriptions.includes(name) && id) {
      const customerField = (await q('getEnterpriseCustomerField', { id })) || {}
      if (customerField.error) return
      customerFieldEnterpriseUpdated(customerField)
    }
    if (deleteSubscriptions.includes(name) && id) customerFieldEnterpriseDeleted(id)
    // if (name === 'customerFieldCategoriesMoved' && data) customerFieldCategoriesMoved(data)
    if (categoryUpdateSubscriptions.includes(name)) {
      const customerFieldCategory = (await q('getEnterpriseCustomerFieldCategory', { id })) || {}
      if (customerFieldCategory.error) return
      customerFieldEnterpriseCategoryUpdated(customerFieldCategory)
    }
    if (categoryDeleteSubscriptions.includes(name) && id) customerFieldEnterpriseCategoryDeleted(id)
    if (name === 'customerFieldsEnterpriseUpdated') handlers.customerFieldsUpdated(ids)
  })

payloads$(actions.CUSTOMER_FIELDS_UPDATED)
  .subscribe(async (ids) => {
    if (!ids.length) return
    let customerFields = await q('getEnterpriseCustomerFields', { filter: { ids } })
    const { error } = customerFields || { error: {} }
    if (error) return
    customerFields = customerFields || []
    customerFields.forEach(customerField => customerFieldEnterpriseUpdated(customerField))
  })

// Form label change
store$
  .pipe(
    map(state => state.forms.customerFields.label ? state.forms.customerFields.label.value || '' : ''),
    distinctUntilChanged()
  ).subscribe(async label => {
    const { forms } = store.getState()
    const { customerFields } = forms || {}
    const { translationKey } = customerFields || {}
    let isLabelEdited = false
    if (translationKey && label !== t(translationKey)) isLabelEdited = true
    handlers.formFieldsUpdate('customerFields', { isLabelEdited: { value: isLabelEdited } })
  })
