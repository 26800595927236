import {
  swapItemsInDnDList,
  sortByOrderIndex
} from '../../../Utils'
import {
  companyTagFormTransform,
  companyTagTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  listToDelete: [],
  locallyChangedList: [],
  pendingForm: true,
  pendingPreview: true,
  pendingList: true,
  selected: null,
  dragging: {},
  messagePreview: null,
  areFetched: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  companyTagsReset: () => dispatch(actions.COMPANY_TAGS_RESET),

  // Subscription
  companyTagsSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.COMPANY_TAGS_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  companyTagsListPopulate: ({ tags }) => dispatch(actions.COMPANY_TAGS_LIST_POPULATE, { tags }),
  companyTagsListReady: () => dispatch(actions.COMPANY_TAGS_LIST_READY),
  companyTagsListOrderChange: ({ id, oldIndex, newIndex }) => dispatch(actions.COMPANY_TAGS_LIST_ORDER_CHANGE, { id, oldIndex, newIndex }),
  companyTagsListOrderChanged: (updatedTags) => dispatch(actions.COMPANY_TAGS_LIST_ORDER_CHANGED, updatedTags),

  // One
  companyTagUpdate: tag => dispatch(actions.COMPANY_TAG_UPDATE, companyTagTransform(tag)),
  companyTagUpdated: tag => dispatch(actions.COMPANY_TAG_UPDATED, tag),
  companyTagDelete: id => dispatch(actions.COMPANY_TAG_DELETE, id),
  companyTagDeleted: id => dispatch(actions.COMPANY_TAG_DELETED, id),
  companyTagRemoveDeleted: id => dispatch(actions.COMPANY_TAG_REMOVE_DELETED, id),
  companyTagResetGlobal: internalId => dispatch(actions.COMPANY_TAG_RESET_GLOBAL, internalId),
  companyTagResetGlobalReady: () => dispatch(actions.COMPANY_TAG_RESET_GLOBAL_READY),
  companyTagsToDeleteGet: () => dispatch(actions.COMPANY_TAGS_TO_DELETE_GET),
  companyTagsToDeletePopulate: ({ companyTagsToDelete }) => dispatch(actions.COMPANY_TAGS_TO_DELETE_POPULATE, { companyTagsToDelete }),
  companyTagToDeleteDelete: ({ companyId, region, externalId, id }) => dispatch(actions.COMPANY_TAG_TO_DELETE_DELETE, { companyId, region, externalId, id }),
  companyTagsToDeleteDeleteReady: () => dispatch(actions.COMPANY_TAGS_TO_DELETE_READY),

  // Preview
  companyTagPreviewGet: (id, forceFetch) => dispatch(actions.COMPANY_TAG_PREVIEW_GET, { id, forceFetch }),
  companyTagPreviewPopulate: tag => dispatch(actions.COMPANY_TAG_PREVIEW_POPULATE, tag),
  companyTagsMessagePreviewSet: message => dispatch(actions.COMPANY_TAGS_MESSAGE_PREVIEW_SET, message),
  // Locally Changed
  companyTagLocallyChangedGet: ({ id, internalId }) => dispatch(actions.COMPANY_TAG_LOCALLY_CHANGED_GET, { id, internalId }),
  companyTagLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.COMPANY_TAG_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  companyTagLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.COMPANY_TAG_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  companyTagLocallyChangedResetReady: ({ companyTag, companyId }) => dispatch(actions.COMPANY_TAG_LOCALLY_CHANGED_RESET_READY, { companyTag, companyId }),

  // Form
  companyTagFormGet: id => dispatch(actions.COMPANY_TAG_FORM_GET, id),
  companyTagFormPopulate: ({ tag, branches, account }) => {
    handlers.formSet('companyTags', companyTagFormTransform({ tag, branches, account }))
    handlers.companyTagFormReady()
  },
  companyTagFormReady: () => dispatch(actions.COMPANY_TAG_FORM_READY),
  companyTagFormSave: (tag, scrollToError = () => { }) => dispatch(actions.COMPANY_TAG_FORM_SAVE, { tag, scrollToError })
})

// Reset
reducer.COMPANY_TAGS_RESET = state => reducer.initialState

// Subscription

reducer.COMPANY_TAGS_SUBSCRIPTION_SET = state => state

// List

reducer.COMPANY_TAGS_LIST_POPULATE = (state, { tags = [] }) => ({
  ...state,
  list: tags.sort(sortByOrderIndex),
  pendingList: false,
  areFetched: true
})

reducer.COMPANY_TAGS_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex }) => {
  return {
    ...state,
    list: swapItemsInDnDList({
      list: state.list,
      id,
      oldIndex,
      newIndex
    })
  }
}

reducer.COMPANY_TAGS_LIST_ORDER_CHANGED = (state, updatedTags) => {
  updatedTags = updatedTags.map(updatedTag => {
    const oldTag = state.list.find(tag => tag.id === updatedTag.id)
    return {
      ...oldTag,
      ...updatedTag
    }
  })
  return {
    ...state,
    list: state.list
      .filter(tag => !updatedTags.find(updatedTag => updatedTag.id === tag.id))
      .concat(updatedTags)
      .sort(sortByOrderIndex)
  }
}

// One
reducer.COMPANY_TAG_UPDATE = (state, tag) => {
  if (!tag) return state
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== tag.id)
      .concat([{ ...tag, isUpdated: true }])
      .sort(sortByOrderIndex)
  }
}

reducer.COMPANY_TAG_UPDATED = (state, tag) => {
  if (!tag) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === tag.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}

reducer.COMPANY_TAG_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.COMPANY_TAG_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}

reducer.COMPANY_TAG_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

reducer.COMPANY_TAGS_TO_DELETE_GET = state => ({
  ...state,
  pendingToDeleteList: true
})

reducer.COMPANY_TAGS_TO_DELETE_POPULATE = (state, { companyTagsToDelete }) => ({
  ...state,
  listToDelete: companyTagsToDelete || [],
  pendingToDeleteList: false
})

reducer.COMPANY_TAG_TO_DELETE_DELETE = (state, { id }) => {
  if (!id) return state
  const companyTagsToDelete = state.listToDelete || []
  const remainingFieldsToDelete = companyTagsToDelete.filter(item => item.id !== id)
  return {
    ...state,
    listToDelete: remainingFieldsToDelete,
    pendingToDeleteList: true
  }
}

reducer.COMPANY_TAGS_TO_DELETE_READY = state => ({
  ...state,
  pendingToDeleteList: false
})

reducer.COMPANY_TAG_RESET_GLOBAL = state => state

reducer.COMPANY_TAG_RESET_GLOBAL_READY = state => state

// Preview
reducer.COMPANY_TAG_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.COMPANY_TAG_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    selected: id
  }
}

reducer.COMPANY_TAGS_MESSAGE_PREVIEW_SET = (state, message) => ({
  ...state,
  pendingPreview: false,
  messagePreview: message,
  selected: null
})

reducer.COMPANY_TAG_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.COMPANY_TAG_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = {
    ...list[index],
    locallyChangedData
  }
  return {
    ...state,
    list,
    pendingPreview: false
  }
}

reducer.COMPANY_TAG_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingPreview: true
})

reducer.COMPANY_TAG_LOCALLY_CHANGED_RESET_READY = (state, { companyTag, companyId }) => {
  const { id, internalId } = companyTag || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.internalId === internalId)
  if (index < 0) return { ...state, pendingPreview: false }
  list[index].locallyChangedData = list[index].locallyChangedData.filter(item => item.id !== id)
  delete list[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    list,
    pendingPreview: false
  }
}

// Form
reducer.COMPANY_TAG_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.COMPANY_TAG_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.COMPANY_TAG_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
