import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { UserForm } from '../../Beauties'

class UserEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const { user } = this.props

    handlers.userFormPopulate(user)
  }

  onSubmit (cb) {
    const { form } = this.props

    handlers.userFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const { pending, errors } = this.props

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <UserForm
            title={t('global.editDetails')}
            cancelLink='/user'
            errors={errors}
            onSubmit={this.onSubmit}
          />
        )}
      </>
    )
  }
}

UserEditWrapper.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = state => {
  const phone = state.account.phone || state.account.enterprise.phone || {}
  return {
    form: state.forms.user || {},
    errors: state.user.errors || [],
    pending: !!(state.user && state.user.pendingForm),
    user: {
      name: state.account.name || '',
      email: state.account.email || '',
      phone,
      avatarUrl: state.account.avatarUrl
    }
  }
}

export default connect(maps)(UserEditWrapper)
