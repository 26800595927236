import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesPreviewGroups } from '../../Beauties'
import { Loader } from '../../Common'

class BranchesPreviewGroupsWrapper extends Component {
  componentDidMount () {
    let { id, account } = this.props
    account = account || {}
    let { enterprisePermissions, isAdmin } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { branchesRead } = enterprisePermissions || {}
    branchesRead = isAdmin || !!branchesRead
    if (branchesRead) {
      handlers.branchPreviewGroupsGet(id)
      handlers.branchImportServiceMessagesHide()
      handlers.branchImportResourceMessagesHide()
    } else {
      handlers.navigateToPath(`/branches/${id}/preview`)
    }
  }

  render () {
    let {
      id,
      isPending,
      branches,
      categoriesWithGroups,
      filter,
      showImportMessage,
      importType,
      account,
      company
    } = this.props
    isPending = !!isPending
    showImportMessage = !!showImportMessage
    branches = branches || []
    categoriesWithGroups = categoriesWithGroups || []
    importType = importType || ''
    company = company || {}
    const branch = branches.find(item => item.id === id)
    const { areGroupsFetched } = branch || {}
    account = account || {}
    let { enterprisePermissions, isAdmin } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { branchesWrite, branchesDelete } = enterprisePermissions || {}
    branchesWrite = isAdmin || !!branchesWrite
    branchesDelete = isAdmin || !!branchesDelete
    const { enterpriseCustomisation } = company || {}
    const { payload } = enterpriseCustomisation || {}
    const { settings } = payload || {}
    const {
      hideBranchEditGroupServiceBtn,
      hideBranchDeleteGroupServiceBtn
    } = settings || {}

    return (
      !isPending
        ? (
          <BranchesPreviewGroups
            branch={branch}
            categoriesWithGroups={categoriesWithGroups}
            filter={filter}
            isPending={!areGroupsFetched}
            showImportMessage={showImportMessage}
            importType={importType}
            branchesWrite={branchesWrite}
            branchesDelete={branchesDelete}
            hideBranchEditGroupServiceBtn={hideBranchEditGroupServiceBtn}
            hideBranchDeleteGroupServiceBtn={hideBranchDeleteGroupServiceBtn}
          />
          )
        : <Loader active />
    )
  }
}

const maps = (state, props) => {
  const branches = state.branches.list || []
  const id = (state.router.data && state.router.data.id) || ''
  const branch = branches.find(item => item.id === id) || {}
  const categories = (branch.groups && branch.groups.courseCategories) || []
  const groups = (branch.groups && branch.groups.courses) || []
  const filter = ((state.forms.branchGroupsFilter.name && state.forms.branchGroupsFilter.name.value) && state.forms.branchGroupsFilter.name.value.toLowerCase()) || ''
  const categoriesWithGroups = categories.map(category => ({
    ...category,
    groups: groups.filter(item => item.categoryId === category.id && item.name.toLowerCase().includes(filter))
  })) || []

  return {
    id,
    isPending: state.branches.pendingPreview,
    branches: state.branches.list,
    categoriesWithGroups,
    filter,
    showImportMessage: state.branches.showImportGroupsMessage,
    importType: state.branches.importGroupsType,
    account: state.account,
    company: state.company
  }
}

export default connect(maps)(BranchesPreviewGroupsWrapper)
