import { store } from '../../Store'

export const isRouteDisabledByPermissions = () => {
  const state = store.getState()
  let {
    users,
    router,
    account,
    chargebee
  } = state || {}
  users = users || {}
  router = router || {}
  account = account || {}
  let { list: usersList } = users
  usersList = usersList || {}
  const isChargebeeHidden = chargebee.isHidden || false
  const { name: route, data } = router
  const { id } = data
  const user = (usersList || []).find(user => user.id === id)
  let { roles: userRoles } = user || {}
  userRoles = userRoles || []
  const isUserOwner = userRoles.includes('OWNER')
  const { enterprisePermissions, isAdmin, isOwner: isAccountOwner, company } = account || {}
  let {
    branchesWrite,
    accountRead,
    usersWrite,
    allowFulfilment,
    tagsWrite,
    tagsRead,
    globalCustomersRead,
    globalCustomersWrite,
    globalDataFieldsWrite,
    globalDataFieldsRead,
    globalTagsWrite,
    globalTagsRead,
    globalResourceCategoriesRead,
    globalResourceCategoriesWrite,
    globalPermissionsGroupsRead,
    globalServicesWrite,
    globalGroupsWrite,
    globalGroupsRead,
    globalServicesRead,
    globalPermissionsGroupsWrite
  } = enterprisePermissions || {}
  const { enterpriseCustomisation } = company || {}
  const { payload: customisationPayload } = enterpriseCustomisation || {}
  const { settings } = customisationPayload || {}
  const { hideBranchAddBtn, hideBranchImportBtn, hideBranchLoginBtn } = settings || {}
  tagsRead = !!isAdmin || !!tagsRead
  tagsWrite = !!isAdmin || !!tagsWrite
  usersWrite = !!isAdmin || !!usersWrite
  accountRead = !!isAdmin || !!accountRead
  branchesWrite = !!isAdmin || !!branchesWrite
  allowFulfilment = !!isAdmin || !!allowFulfilment
  const customersRead = !!isAdmin || !!globalCustomersRead
  const customersWrite = !!isAdmin || !!globalCustomersWrite
  const resourceCategoriesRead = !!isAdmin || !!globalResourceCategoriesRead
  const resourceCategoriesWrite = !!isAdmin || !!globalResourceCategoriesWrite
  const permissionsGroupsRead = !!isAdmin || !!globalPermissionsGroupsRead
  const permissionsGroupsWrite = !!isAdmin || !!globalPermissionsGroupsWrite
  const groupsWrite = !!isAdmin || !!globalGroupsWrite
  const groupsRead = !!isAdmin || !!globalGroupsRead
  const servicesWrite = !!isAdmin || !!globalServicesWrite
  const servicesRead = !!isAdmin || !!globalServicesRead
  const dataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite
  const dataFieldsRead = !!isAdmin || !!globalDataFieldsRead
  globalTagsWrite = !!isAdmin || !!globalCustomersRead
  globalTagsRead = !!isAdmin || !!globalTagsRead

  const ROUTES_USERS_EDIT = ['usersEdit']
  const ROUTES_ACCOUNT_EDIT = ['accountEdit']
  const ROUTES_BRANCHES_ADD = ['branchesAdd']
  const ROUTES_BRANCHES_IMPORT = ['branchesImport']
  const ROUTES_BRANCHES_EDIT = ['branchesEdit']
  const ROUTES_BRANCHES_TAGS_ADD_EDIT = ['branchesTagsAdd', 'branchesTagsEdit']
  const ROUTES_BRANCHES_TAGS_PREVIEW = ['branchesTagsPreview']
  const ROUTES_CUSTOMERS_PREVIEW = ['customerPreview']
  const ROUTES_CUSTOMERS_ADD_EDIT = ['customersAdd', 'customersEdit']
  const ROUTES_CUSTOMER_FIELDS_CATEGORY_ADD_EDIT = ['customerFieldsCategoryAdd', 'customerFieldsCategoryEdit']
  const ROUTES_CUSTOMER_FIELDS_CATEGORY_PREVIEW = ['customerFieldCategoryPreview']
  const ROUTES_CUSTOMER_FIELDS_ADD_EDIT = ['customerFieldAdd', 'customerFieldsEdit']
  const ROUTES_COMPANY_TAGS_ADD_EDIT = ['companyTagAdd', 'companyTagEdit']
  const ROUTES_COMPANY_TAGS_PREVIEW = ['companyTagPreview']
  const ROUTES_RESOURCE_CATEGORIES_ADD_EDIT = ['resourceCategoryAdd', 'resourceCategoryEdit']
  const ROUTES_RESOURCE_CATEGORIES_PREVIEW = ['resourceCategoryPreview']
  const ROUTES_PERMISSION_TYPE_ADD_EDIT = ['permissionTypeAdd', 'permissionTypeEdit']
  const ROUTES_PERMISSION_TYPE_PREVIEW = ['permissionTypePreview']
  const ROUTES_SERVICE_CATEGORY_ADD_EDIT = ['serviceCategoryEdit', 'serviceCategoryAdd']
  const ROUTES_SERVICE_CATEGORY_PREVIEW = ['serviceCategoryPreview']
  const ROUTES_SERVICE_ADD_EDIT = ['serviceEdit', 'serviceAdd']
  const ROUTES_SERVICE_PREVIEW = ['servicePreview']
  const ROUTES_COURSE_CATEGORY_ADD_EDIT = ['courseCategoryAdd', 'courseCategoryEdit']
  const ROUTES_COURSE_CATEGORY_PREVIEW = ['courseCategoryPreview']
  const ROUTES_COURSE_ADD_EDIT = ['courseAdd', 'courseEdit']
  const ROUTES_COURSE_PREVIEW = ['coursePreview']
  const ROUTES_BILLING_EDITING = ['accountBillingEdit']

  return (
    (ROUTES_USERS_EDIT.includes(route) && (isUserOwner ? !isAccountOwner : !usersWrite)) ||
    (ROUTES_ACCOUNT_EDIT.includes(route) && !accountRead) ||
    (ROUTES_ACCOUNT_EDIT.includes(route) && !accountRead) ||
    (ROUTES_BRANCHES_ADD.includes(route) && !(branchesWrite && !hideBranchAddBtn)) ||
    (ROUTES_BRANCHES_IMPORT.includes(route) && !(branchesWrite && !hideBranchImportBtn)) ||
    (ROUTES_BRANCHES_EDIT.includes(route) && !(branchesWrite)) ||
    (ROUTES_BRANCHES_EDIT.includes(route) && !(allowFulfilment && !hideBranchLoginBtn)) ||
    (ROUTES_BRANCHES_TAGS_ADD_EDIT.includes(route) && !(tagsWrite)) ||
    (ROUTES_BRANCHES_TAGS_PREVIEW.includes(route) && !(tagsRead)) ||
    (ROUTES_CUSTOMERS_PREVIEW.includes(route) && !(customersRead)) ||
    (ROUTES_CUSTOMERS_ADD_EDIT.includes(route) && !(customersWrite)) ||
    (ROUTES_CUSTOMER_FIELDS_CATEGORY_ADD_EDIT.includes(route) && !(dataFieldsWrite)) ||
    (ROUTES_CUSTOMER_FIELDS_CATEGORY_PREVIEW.includes(route) && !(dataFieldsRead)) ||
    (ROUTES_CUSTOMER_FIELDS_ADD_EDIT.includes(route) && !(dataFieldsWrite)) ||
    (ROUTES_COMPANY_TAGS_ADD_EDIT.includes(route) && !(globalTagsWrite)) ||
    (ROUTES_COMPANY_TAGS_PREVIEW.includes(route) && !(globalTagsRead)) ||
    (ROUTES_RESOURCE_CATEGORIES_ADD_EDIT.includes(route) && !(resourceCategoriesWrite)) ||
    (ROUTES_RESOURCE_CATEGORIES_PREVIEW.includes(route) && !(resourceCategoriesRead)) ||
    (ROUTES_PERMISSION_TYPE_ADD_EDIT.includes(route) && !(permissionsGroupsWrite)) ||
    (ROUTES_PERMISSION_TYPE_PREVIEW.includes(route) && !(permissionsGroupsRead)) ||
    (ROUTES_SERVICE_CATEGORY_ADD_EDIT.includes(route) && !(servicesWrite)) ||
    (ROUTES_SERVICE_CATEGORY_PREVIEW.includes(route) && !(servicesRead)) ||
    (ROUTES_SERVICE_ADD_EDIT.includes(route) && !(servicesWrite)) ||
    (ROUTES_SERVICE_PREVIEW.includes(route) && !(servicesRead)) ||
    (ROUTES_COURSE_CATEGORY_ADD_EDIT.includes(route) && !(groupsWrite)) ||
    (ROUTES_COURSE_CATEGORY_PREVIEW.includes(route) && !(groupsRead)) ||
    (ROUTES_COURSE_ADD_EDIT.includes(route) && !(groupsWrite)) ||
    (ROUTES_COURSE_PREVIEW.includes(route) && !(groupsRead)) ||
    (ROUTES_BILLING_EDITING.includes(route) && isChargebeeHidden)
  )
}
