import React from 'react'
import { t } from '../../../../Common'
import './BookingRemindersExample.css'

const BookingRemindersExample = props => {
  let { values, label, size } = props
  values = (values || []).sort((a, b) => b - a)
  size = size || 'default'

  return (
    <div className='ta-booking-reminders-example'>
      {(label &&
        <div className='ta-booking-reminders-example__label'>{label}</div>
      )}
      <div className={`ta-booking-reminders-example__timeline ${size}`}>
        <div className={`ta-booking-reminders-example__timeline-inactive ${size} segments-${values.length}`}>
          {values.map(item => {
            const hours = (item / 60 || 0)
            return (
              <div className='ta-booking-reminders-example__hours' key={item}>
                {(hours < 24
                  ? `${hours.toString()} ${t('global.hours')}`
                  : `${(hours / 24).toString()} ${t('global.days')}`
                )}
                <div className='ta-booking-reminders-example__slot inactive' />
              </div>
            )
          })}
        </div>
        <div className='ta-booking-reminders-example__timeline-active' />
        <div className={`ta-booking-reminders-example__slot active ${size}`} />
      </div>
    </div>
  )
}

export default BookingRemindersExample
