import React from 'react'
import {
  FontAwesome5,
  IconAvatar,
  Button,
  t
} from '../../Common'

const UserPermissionsItem = props => {
  let { permissionType } = props
  permissionType = permissionType || {}
  const { name, color, icon, usersCount, id } = permissionType || {}

  return (
    <Button as='link' className='ta-permission-types__item' to={`/managers/user-permissions/${id}`}>
      <IconAvatar color={color} icon={icon} />
      <div className='ta-permission-types__item__content'>
        <div className='ta-permission-types__item__content__name'>
          {name}
          {id === 'ADMIN' && <FontAwesome5 icon='lock' type='solid' />}
        </div>
        <div className='ta-permission-types__item__content__resources'>
          <FontAwesome5 icon='cube' type='solid' />{usersCount || '0'} {t('global.users')}
        </div>
      </div>
    </Button>
  )
}

export default UserPermissionsItem
