import React from 'react'
import { connect } from '../../Store'
import { BranchesServiceCombinationFormDuration } from '../../Beauties'

const BranchesServiceCombinationFormDurationWrapper = props => {
  let {
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    allowedSet
  } = props
  showDurationBeforeAndAfter = !!showDurationBeforeAndAfter
  showDurationDaySelector = !!showDurationDaySelector

  return (
    <BranchesServiceCombinationFormDuration
      showDurationBeforeAndAfter={showDurationBeforeAndAfter}
      showDurationDaySelector={showDurationDaySelector}
      allowedSet={allowedSet}
    />
  )
}

const maps = state => ({
  showDurationBeforeAndAfter: state.forms.branchesService.showDurationBeforeAndAfter && state.forms.branchesService.showDurationBeforeAndAfter.value,
  showDurationDaySelector: state.account.companySettings && state.account.companySettings.showDurationDaySelector
})

export default connect(maps)(BranchesServiceCombinationFormDurationWrapper)
