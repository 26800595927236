import React from 'react'
import { truncateText } from '../../Utils'

const ChartAxisTickTruncated = props => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor='end' fill='#666'>{truncateText(payload.value, 20, true)}</text>
    </g>
  )
}

export default ChartAxisTickTruncated
