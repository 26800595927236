import React from 'react'
import {
  FontAwesome5,
  Button,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const NavigationLinks = props => {
  const { hideSupportLink, customSupportLink } = props

  return (
    <div className='ta-navigation__links'>
      {(!hideSupportLink &&
        <HoverPopup className='ta-navigation__links__link'>
          <HoverPopupContent position='right' autoSize>{t('navigation.support.tooltip')}</HoverPopupContent>
          <HoverPopupTrigger>
            <Button as='link' to={customSupportLink || 'https://support.timify.com/'} target='_blank' external>
              <FontAwesome5 icon='life-ring' type='solid' />
            </Button>
          </HoverPopupTrigger>
        </HoverPopup>
       )}
      {/* <HoverPopup className='ta-navigation__links__link'>
        <HoverPopupContent position='right' autoSize>{t('navigation.appcues.tooltip')}</HoverPopupContent>
        <HoverPopupTrigger>
          <Button as='link'>
            <FontAwesome5 icon='question-circle' type='regular' />
          </Button>
        </HoverPopupTrigger>
      </HoverPopup> */}
      <HoverPopup className='ta-navigation__links__link'>
        <HoverPopupContent position='right' autoSize>{t('global.logout')}</HoverPopupContent>
        <HoverPopupTrigger>
          <Button as='link' to='/logout'>
            <FontAwesome5 icon='sign-out-alt' type='solid' />
          </Button>
        </HoverPopupTrigger>
      </HoverPopup>
    </div>
  )
}

export default NavigationLinks
