import React, { memo, useEffect } from 'react'
import { generate } from 'generate-password'
import { connect, handlers } from '../../Store'
import { BranchesPreviewResourcesPasswordChangeForm } from '../../Beauties'
import { Loader } from '../../Common'

const BranchesPreviewResourcesPasswordChangeWrapper = props => {
  let {
    form,
    pending,
    branchId,
    generatedPassword,
    newPasswordFieldType,
    repeatPasswordFieldType,
    yourPasswordFieldType
  } = props
  form = form || {}
  newPasswordFieldType = newPasswordFieldType || 'password'
  repeatPasswordFieldType = repeatPasswordFieldType || 'password'
  yourPasswordFieldType = yourPasswordFieldType || 'password'

  useEffect(() => {
    handlers.branchPreviewResourcesGet(branchId)
    handlers.branchesResourcePasswordChangeFormGet()
  }, [branchId])

  const onSubmit = cb => {
    handlers.branchesResourcePasswordChangeFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const toggleNewPasswordFieldType = () => {
    const newPassword = {
      value: form.newPassword.value,
      type: form.newPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('branchesResourcePasswordChange', { newPassword })

    return newPassword.type
  }

  const toggleRepeatPasswordFieldType = () => {
    const repeatPassword = {
      value: form.repeatPassword.value,
      type: form.repeatPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('branchesResourcePasswordChange', { repeatPassword })

    return repeatPassword.type
  }

  const toggleYourPasswordFieldType = () => {
    const yourPassword = {
      value: form.yourPassword.value,
      type: form.yourPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('branchesResourcePasswordChange', { yourPassword })

    return yourPassword.type
  }

  const onGeneratePassword = () => {
    const newGeneratedPassword = generate({
      length: 30,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      strict: true
    })
    handlers.formFieldsUpdate('branchesResourcePasswordChange', {
      generatedPassword: { value: newGeneratedPassword }
    })
  }

  const onCopyPassword = () => {
    navigator.clipboard.writeText(generatedPassword)
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <BranchesPreviewResourcesPasswordChangeForm
          onSubmit={onSubmit}
          cancelLink={`/branches/${branchId}/preview@@resources`}
          onGeneratePassword={onGeneratePassword}
          generatedPassword={generatedPassword}
          onCopyPassword={onCopyPassword}
          newPasswordFieldType={newPasswordFieldType}
          repeatPasswordFieldType={repeatPasswordFieldType}
          yourPasswordFieldType={yourPasswordFieldType}
          toggleNewPasswordFieldType={toggleNewPasswordFieldType}
          toggleRepeatPasswordFieldType={toggleRepeatPasswordFieldType}
          toggleYourPasswordFieldType={toggleYourPasswordFieldType}
        />
      )}
    </>
  )
}

const maps = state => ({
  form: state.forms.branchesResourcePasswordChange,
  pending: state.branches.pendingForm,
  branchId: state.router && state.router.data && state.router.data.branchId,
  generatedPassword: state.forms.branchesResourcePasswordChange && state.forms.branchesResourcePasswordChange.generatedPassword && state.forms.branchesResourcePasswordChange.generatedPassword.value,
  newPasswordFieldType: state.forms.branchesResourcePasswordChange && state.forms.branchesResourcePasswordChange.newPassword && state.forms.branchesResourcePasswordChange.newPassword.type,
  repeatPasswordFieldType: state.forms.branchesResourcePasswordChange && state.forms.branchesResourcePasswordChange.repeatPassword && state.forms.branchesResourcePasswordChange.repeatPassword.type,
  yourPasswordFieldType: state.forms.branchesResourcePasswordChange && state.forms.branchesResourcePasswordChange.yourPassword && state.forms.branchesResourcePasswordChange.yourPassword.type
})

export default memo(connect(maps)(BranchesPreviewResourcesPasswordChangeWrapper))
