const flattenMessages = (o, key = '', res = {}) => {
  Object
    .keys(o)
    .forEach(k => {
      let v = o[k]
      if (typeof v === 'object') {
        flattenMessages(v, key + k + '.', res)
      } else {
        res[key + k] = v
      }
    })
  return res
}

export default flattenMessages
