import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../../Common'

const PermissionTypesLimitBanner = (props) => {
  const { permissionTypesAccountLimit, permissionTypesCount } = props
  const freeSlots = permissionTypesAccountLimit - permissionTypesCount

  if (freeSlots <= 2 && freeSlots > 0) {
    return (
      <div className='ta-permission-types__limit-banner ta-permission-types__limit-banner__near-limit'>
        <div className='ta-permission-types__limit-banner__title'>
          {t('permissionTypes.list.limitBanner.title')}
          <span>{permissionTypesCount} / {permissionTypesAccountLimit}</span>
        </div>
        <div className='ta-permission-types__limit-banner__content'>
          <div className='ta-permission-types__limit-banner__content__title'>
            {t('permissionTypes.list.limitBanner.nearLimitTitle')}
          </div>
          {t('permissionTypes.list.limitBanner.nearLimitText')}
        </div>
      </div>
    )
  }

  if (freeSlots === 0) {
    return (
      <div className='ta-permission-types__limit-banner ta-permission-types__limit-banner__at-limit'>
        <div className='ta-permission-types__limit-banner__title'>
          {t('permissionTypes.list.limitBanner.title')}
          <span>{permissionTypesCount} / {permissionTypesAccountLimit}</span>
        </div>
        <div className='ta-permission-types__limit-banner__content'>
          <div className='ta-permission-types__limit-banner__content__title'>
            {t('permissionTypes.list.limitBanner.atLimitTitle')}
          </div>
          {t('permissionTypes.list.limitBanner.atLimitText')}
        </div>
      </div>
    )
  }

  if (freeSlots < 0) {
    return (
      <div className='ta-permission-types__limit-banner ta-permission-types__limit-banner__above-limit'>
        <div className='ta-permission-types__limit-banner__title'>
          {t('permissionTypes.list.limitBanner.title')}
          <span>{permissionTypesCount} / {permissionTypesAccountLimit}</span>
        </div>
        <div className='ta-permission-types__limit-banner__content'>
          <div className='ta-permission-types__limit-banner__content__title'>
            {t('permissionTypes.list.limitBanner.aboveLimitTitle')}
          </div>
          {t('permissionTypes.list.limitBanner.aboveLimitText')}
        </div>
      </div>
    )
  }

  return (
    <div className='ta-permission-types__limit-banner'>
      <div className='ta-permission-types__limit-banner__title'>
        {t('permissionTypes.list.limitBanner.title')}
        <span>{permissionTypesCount} / {permissionTypesAccountLimit}</span>
      </div>
    </div>
  )
}

PermissionTypesLimitBanner.propTypes = {
  permissionTypesAccountLimit: PropTypes.number.isRequired,
  permissionTypesCount: PropTypes.number.isRequired
}

export default PermissionTypesLimitBanner
