import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { AppAuth } from '../../Beauties'
import { AccountSelectWrapper, AccountEnforcePasswordChangeWrapper } from '../../Beasts'

class AppAuthWrapper extends Component {
  componentDidMount () {
    const { language } = this.props
    handlers.languageChange(language)
  }

  render () {
    let {
      pendingLanguage,
      hasCompany,
      enterpriseCustomisation,
      enforcePasswordChange
    } = this.props
    pendingLanguage = !!pendingLanguage
    hasCompany = !!hasCompany
    enforcePasswordChange = !!enforcePasswordChange
    enterpriseCustomisation = enterpriseCustomisation || {}
    const { payload: customisaitonPayload } = enterpriseCustomisation || {}
    const { settings } = customisaitonPayload || {}
    const { cssOverwriteUrl } = settings || {}

    return (
      <>
        {!hasCompany && !pendingLanguage && !enforcePasswordChange && <AccountSelectWrapper />}
        {!hasCompany && !pendingLanguage && enforcePasswordChange && <AccountEnforcePasswordChangeWrapper />}
        {hasCompany && !pendingLanguage && <AppAuth hasOverwriteCss={!!cssOverwriteUrl} />}
        {hasCompany && pendingLanguage && <Loader active hideBackground />}
      </>
    )
  }
}

AppAuthWrapper.propTypes = {
  language: PropTypes.string,
  pendingLanguage: PropTypes.bool.isRequired
}

const maps = state => ({
  hasCompany: !!state.company.id,
  enforcePasswordChange: state.account.enforcePasswordChange,
  language: (state.app && state.app.locale) || 'en-gb',
  enterpriseCustomisation: state.company.enterpriseCustomisation,
  pendingLanguage: state.app.pendingLanguage
})

export default connect(maps)(AppAuthWrapper)
