import React from 'react'
import {
  FormSection,
  Title,
  Error,
  Row,
  Col,
  HintText,
  Input,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const CourseFormParticipants = props => {
  return (
    <FormSection>
      <Title size='m' isCompact label={t('servicesGroups.form.group.section.participants.heading')} icon='sliders-h' />
      <Row noOffset>
        <Col size={50}>
          <Input
            name='maxParticipants'
            label={t('servicesGroups.form.group.section.participants.fieldMax.label')}
            mandatory
          />
        </Col>
        <Col size={50}>
          <Input
            name='extraPersonsPerParticipant'
            label={t('global.additionalParticipant')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <HintText>{dangerousHTML(t('servicesGroups.form.group.section.participants.field.hint'))}</HintText>
        </Col>
      </Row>
      <Error name='participantsGlobalErrors' noTopOffset />
    </FormSection>
  )
}

export default CourseFormParticipants
