import React from 'react'
import {
  Title,
  ListItem,
  t
} from '../../../Common'

import './SecuritySettings.css'

const SecuritySettingsBranchManager = props => {
  const {
    requiredEnterpriseAuth2FAType
  } = props

  return (
    <>
      <Title size='m' isCompact label={t('user.preview.security.title')} icon='lock' ignoreTopOffset />
      <ListItem>
        {requiredEnterpriseAuth2FAType === 'OTP' ? t('settings.section.security.2fa.infoText.active') : t('settings.section.security.2fa.infoText')}
      </ListItem>
    </>
  )
}

export default SecuritySettingsBranchManager
