import React from 'react'
import { getInitialsByName } from '../../Utils'
import { Image } from '../../Common'
import { locationMapPlaceholder } from '../../Assets/images'

const AccountsItemMap = props => {
  const { address, name } = props
  const initials = getInitialsByName(name, 2)

  return (
    <>
      {(address &&
        // <GoogleStaticMap address={address} height={100} width={100} />
        <Image src={locationMapPlaceholder} alt='' />
      )}
      {(!address &&
        <div className='ta-accounts__item__initials'>{initials}</div>
      )}
    </>
  )
}

export default AccountsItemMap
