import React from 'react'
import {
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  Address,
  Title,
  Button,
  Badge,
  Row,
  Col,
  t
} from '../../../Common'

import './BranchesPreviewDetails.css'

const BranchesPreviewDetails = props => {
  let { branch, tagsRead } = props
  branch = branch || {}
  const phone = (branch.phone && `+${branch.phone.prefix} (0) ${branch.phone.number}`)

  return (
    <div className='ta-branch__preview__details'>
      <Row>
        <Col size={50}>
          <Title size='m' label={t('branches.preview.details.section.owner.title')} noIcon ignoreTopOffset />
          <IconListItem>
            <IconListItemTitle width='130px'>ID:</IconListItemTitle>
            <IconListItemBody offset='130px'>{branch.id}</IconListItemBody>
          </IconListItem>
          {(branch.externalId &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('global.externalId.label')}:</IconListItemTitle>
              <IconListItemBody offset='130px'>{branch.externalId}</IconListItemBody>
            </IconListItem>
          )}
          {(phone &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('global.telephone.label')}:</IconListItemTitle>
              <IconListItemBody offset='130px'>{phone}</IconListItemBody>
            </IconListItem>
          )}
          {(branch.ownerEmail &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('global.ownerEmail')}:</IconListItemTitle>
              <IconListItemBody offset='130px'>
                <Button as='link' to={`mailto:${branch.ownerEmail}`} external> {branch.ownerEmail} </Button>
              </IconListItemBody>
            </IconListItem>
          )}
          {(branch.notes &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('global.notes')}:</IconListItemTitle>
              <IconListItemBody offset='130px'>{branch.notes}</IconListItemBody>
            </IconListItem>
          )}
          {(branch.info &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('global.locationDescription')}:</IconListItemTitle>
              <IconListItemBody offset='130px'>{branch.info}</IconListItemBody>
            </IconListItem>
          )}
          {(tagsRead && branch.tags && branch.tags.length > 0 &&
            <>
              <Title size='m' label={t('global.tags')} icon='tag' />
              {branch.tags.map((tag, index) => <Badge key={index} background={tag.color} text={tag.name} />)}
            </>
          )}
        </Col>
        {(branch.address && branch.address.latitude &&
          <Col size={50}>
            <Title size='m' label={t('global.address')} noIcon ignoreTopOffset />
            <Address
              address={branch.address.formatted}
              details={branch.address.details}
              lat={branch.address.latitude}
              lng={branch.address.longitude}
            />
          </Col>
        )}
      </Row>
    </div>
  )
}

export default BranchesPreviewDetails
