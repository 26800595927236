import React from 'react'

import './List.css'

const ListItem = props => {
  const { children, className = '' } = props
  const classes = ['ta-list']
  if (className !== '') classes.push(className)

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  )
}

export default ListItem
