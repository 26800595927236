import React from 'react'
import { CompanyTagPreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const CompanyTagPreviewChangedLocallyWrapper = props => {
  let { tag, account } = props
  tag = tag || {}
  account = account || {}
  const { locallyChangedData, internalId } = tag || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalTagsWrite } = enterprisePermissions || {}
  globalTagsWrite = !!isAdmin || !!globalTagsWrite

  const onResetGlobalEntity = (companyId, region) => handlers.companyTagLocallyChangedReset({ companyId, region, internalId })

  return (
    <CompanyTagPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalTagsWrite={globalTagsWrite}
    />
  )
}

const maps = state => ({
  tag: (state.router.data.id && state.companyTags.list.find(item => item.id === state.router.data.id)) || {},
  account: state.account
})

export default connect(maps)(CompanyTagPreviewChangedLocallyWrapper)
