import React from 'react'
import {
  Title,
  Button,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Blur,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'
import {
  BookingSettingsBooking,
  BookingSettingsCalendar,
  BookingSettingsWidget,
  BookingSettingsOther
} from '../../../Beauties'

import './BookingSettings.css'

const BookingSettings = props => {
  const {
    hash,
    settings,
    rawSettings,
    tags,
    publicHolidays,
    regionsByUniqueKey,
    resourceCategories,
    customersMiddlewareUrl,
    settingsRead,
    settingsWrite,
    customisations,
    callCentreEnabled,
    isAdmin
  } = props
  const { settings: customisationsSettings } = customisations || {}
  const {
    // tabs
    hideSettingsBookingsOtherTab
  } = customisationsSettings || {}

  const availableTabs = ['booking', 'calendar', 'widget']
  if (!hideSettingsBookingsOtherTab) availableTabs.push('other')
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]

  return (
    <div className='ta-booking-settings'>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='cogs' iconTheme='grey' label={t('settings.section.booking.title')}>
            <PopupTriggerWrapper name='booking-settings-help-box' position='bottom' extraClassName='ta-help-box'>
              <Button as='link' className='ta-title__link' external>
                {t('global.help')} <FontAwesome5 icon='lightbulb' />
              </Button>
            </PopupTriggerWrapper>
            {(settingsWrite &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.settings.edit')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to={`/settings/bookings/edit@@${activeTab}`} isPrimary icon='pencil' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={settingsRead ? 'inactive' : ''} active={!settingsRead} icon='ban' label={t('global.accessDenied')}>
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='booking' icon='clock' iconType='solid' label={t('global.booking')} />
                <SimpleTabsHeaderItem name='calendar' icon='calendar-alt' iconType='solid' label={t('global.calendar')} />
                <SimpleTabsHeaderItem name='widget' icon='window-maximize' label={t('global.widget')} />
                {!hideSettingsBookingsOtherTab && (<SimpleTabsHeaderItem name='other' icon='file' iconType='solid' label={t('global.other')} />)}
              </SimpleTabsHeader>
              <SimpleTabsBody className={!settingsRead ? 'static' : ''}>
                <SimpleTabsBodyItem name='booking'>
                  <BookingSettingsBooking
                    {...settings}
                    tags={tags}
                    rawSettings={rawSettings}
                    customisations={customisations}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='calendar'>
                  <BookingSettingsCalendar
                    {...settings}
                    rawSettings={rawSettings}
                    publicHolidays={publicHolidays}
                    regionsByUniqueKey={regionsByUniqueKey}
                    resourceCategories={resourceCategories}
                    customisations={customisations}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='widget'>
                  <BookingSettingsWidget
                    {...settings}
                    rawSettings={rawSettings}
                    customersMiddlewareUrl={customersMiddlewareUrl}
                    customisations={customisations}
                  />
                </SimpleTabsBodyItem>
                {(!hideSettingsBookingsOtherTab &&
                  <SimpleTabsBodyItem name='other'>
                    <BookingSettingsOther
                      {...settings}
                      rawSettings={rawSettings}
                      customisations={customisations}
                      isAdmin={isAdmin}
                      callCentreEnabled={callCentreEnabled}
                    />
                  </SimpleTabsBodyItem>
                )}
              </SimpleTabsBody>
            </SimpleTabs>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BookingSettings
