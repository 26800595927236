import React from 'react'
import { connect } from '../../Store'
import {
  BranchesWrapper,
  TagsWrapper,
  CompanyTagsListWrapper,
  AccountWrapper,
  UsersListWrapper,
  CustomerFieldsWrapper,
  ServicesListWrapper,
  UserGroupsListWrapper,
  InvoicesWrapper,
  UserWrapper,
  AppsListWrapper,
  CustomersListWrapper,
  ResourceCategoriesListWrapper,
  CoursesListWrapper,
  GlobalsSettingsWrapper,
  BookingSettingsWrapper,
  CustomersSettingsWrapper,
  EmailSettingsWrapper,
  SecuritySettingsWrapper,
  PermissionTypesWrapper,
  UserPermissionsWrapper,
  BillingPreviewWrapper,
  ActivityLogsWrapper,
  SystemMessagesWrapper
} from '../../Beasts'

const LeftFirstSidebarWrapper = props => {
  const { route } = props
  const ROUTES_BRANCHES = [
    'branches',
    'branchesAdd',
    'branchesImport',
    'branchesEdit',
    'branchesPreview',
    'branchesResourceEdit',
    'branchesResourcePasswordChange',
    'branchesServiceEdit',
    'branchesServiceCombinationEdit',
    'branchesServicesImport',
    'branchesServiceCategoriesImport',
    'branchesGroupsImport',
    'branchesGroupCategoriesImport',
    'branchesResourcesImport',
    'branchesResourceCategoriesImport',
    'branchesGroupEdit',
    'branchesCustomerEdit'
  ]
  const ROUTES_BRANCHES_TAGS = ['branchesTags', 'branchesTagsAdd', 'branchesTagsEdit', 'branchesTagsPreview']
  const ROUTES_COMPANY_TAGS = ['companyTags', 'companyTagAdd', 'companyTagEdit', 'companyTagsReorder', 'companyTagPreview']
  const ROUTES_ACCOUNT = ['account', 'accountEdit']
  const ROUTES_USER = ['user', 'userEdit', 'userEditPassword', 'userEditEmail', 'userEditLanguage']
  const ROUTES_USERS = ['users', 'usersEdit', 'usersAdd', 'usersPreview']
  const ROUTES_CUSTOMER_FIELDS = [
    'customerFields',
    'customerFieldsPreview',
    'customerFieldAdd',
    'customerFieldsEdit',
    'customerFieldsCategoriesPreview',
    'customerFieldsCategoryEdit',
    'customerFieldsCategoryAdd',
    'customerFieldsCategoryPreview',
    'customerFieldsCategories',
    'customerFieldsReorder',
    'customerFieldCategoryPreview'
  ]
  const ROUTES_COURSES = [
    'courses',
    'courseRestore',
    'courseBranchRestore',
    'courseAdd',
    'courseEdit',
    'coursePreview',
    'courseCategoryAdd',
    'courseCategoryEdit',
    'coursesReorder',
    'courseCategoryPreview'
  ]
  const ROUTES_RESOURCES = ['resourceCategories', 'resourceCategoryAdd', 'resourceCategoryEdit', 'resourceCategoryPreview']
  const ROUTES_CUSTOMERS = ['customers', 'customerPreview', 'customersAdd', 'customersEdit']
  const ROUTES_SERVICES = [
    'services',
    'servicePreview',
    'serviceRestore',
    'serviceBranchRestore',
    'serviceAdd',
    'serviceEdit',
    'serviceCombinationAdd',
    'serviceCombinationEdit',
    'serviceCategoryEdit',
    'servicesReorder',
    'serviceCategoryAdd',
    'serviceCategoryPreview',
    'servicesCategories'
  ]
  const ROUTES_GLOBALS_SETTINGS = ['globalsSettings', 'globalsSettingsEdit']
  const ROUTES_USER_GROUPS = ['userGroups', 'userGroupsEdit', 'userGroupsAdd', 'userGroupsPreview']
  const ROUTES_BILLING = ['accountBilling', 'accountBillingEdit']
  const ROUTES_INVOICES = ['accountInvoices']
  const ROUTES_APPS = ['apps', 'appsPreview', 'appsPreview', 'appsIframe', 'appsUninstall', 'appsSubscription', 'appsSubscriptionAdd', 'appsSubscriptionCancelled', 'appsSubscriptionFailed', 'appsSubscriptionGone']
  // const ROUTES_WEBAPP_SETTINGS = ['webAppSettings', 'webAppSettingsEdit']
  const ROUTES_BOOKING_SETTINGS = ['bookingSettings', 'bookingSettingsEdit']
  const ROUTES_EMAIL_SETTINGS = ['emailSettings', 'emailSettingsEdit']
  const ROUTES_CUSTOMERS_SETTINGS = ['customersSettings', 'customersSettingsEdit']
  const ROUTES_SECURITY_SETTINGS = ['securitySettings', 'securitySettingsEdit']
  const ROUTES_PERMISSION_TYPES = ['permissionTypes', 'permissionTypePreview', 'permissionTypeEdit', 'permissionTypeAdd']
  const ROUTES_USER_PERMISSIONS = ['userPermissions', 'userPermissionsPreview', 'userPermissionsEdit', 'userPermissionsAdd']
  const ROUTES_ACTIVITY = ['activity']
  const ROUTES_NOTIFICATIONS = ['notifications']

  return (
    <>
      {ROUTES_BRANCHES.includes(route) && <BranchesWrapper />}
      {ROUTES_BRANCHES_TAGS.includes(route) && <TagsWrapper />}
      {ROUTES_COMPANY_TAGS.includes(route) && <CompanyTagsListWrapper />}
      {ROUTES_ACCOUNT.includes(route) && <AccountWrapper />}
      {ROUTES_CUSTOMERS.includes(route) && <CustomersListWrapper />}
      {ROUTES_USERS.includes(route) && <UsersListWrapper />}
      {ROUTES_USER_GROUPS.includes(route) && <UserGroupsListWrapper />}
      {ROUTES_CUSTOMER_FIELDS.includes(route) && <CustomerFieldsWrapper />}
      {ROUTES_COURSES.includes(route) && <CoursesListWrapper />}
      {ROUTES_SERVICES.includes(route) && <ServicesListWrapper />}
      {ROUTES_GLOBALS_SETTINGS.includes(route) && <GlobalsSettingsWrapper />}
      {ROUTES_BILLING.includes(route) && <BillingPreviewWrapper />}
      {ROUTES_INVOICES.includes(route) && <InvoicesWrapper />}
      {ROUTES_RESOURCES.includes(route) && <ResourceCategoriesListWrapper />}
      {ROUTES_USER.includes(route) && <UserWrapper />}
      {ROUTES_APPS.includes(route) && <AppsListWrapper />}
      {/* {ROUTES_WEBAPP_SETTINGS.includes(route) && <WebAppSettingsWrapper />} */}
      {ROUTES_BOOKING_SETTINGS.includes(route) && <BookingSettingsWrapper />}
      {ROUTES_EMAIL_SETTINGS.includes(route) && <EmailSettingsWrapper />}
      {ROUTES_CUSTOMERS_SETTINGS.includes(route) && <CustomersSettingsWrapper />}
      {ROUTES_SECURITY_SETTINGS.includes(route) && <SecuritySettingsWrapper />}
      {ROUTES_PERMISSION_TYPES.includes(route) && <PermissionTypesWrapper />}
      {ROUTES_USER_PERMISSIONS.includes(route) && <UserPermissionsWrapper />}
      {ROUTES_ACTIVITY.includes(route) && <ActivityLogsWrapper />}
      {ROUTES_NOTIFICATIONS.includes(route) && <SystemMessagesWrapper />}
    </>
  )
}

const maps = state => ({
  route: state.router.name
})

export default connect(maps)(LeftFirstSidebarWrapper)
