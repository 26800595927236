'use strict'

const toString = Object.prototype.toString
const isEnumerable = Object.prototype.propertyIsEnumerable
const getSymbols = Object.getOwnPropertySymbols

const assignDeep = (target, ...args) => {
  let i = 0
  if (isPrimitive(target)) target = args[i++]
  target = target || {}

  for (; i < args.length; i++) {
    if (isObject(args[i])) {
      for (const key of Object.keys(args[i])) {
        if (isValidKey(key)) {
          if (isObject(target[key]) && isObject(args[i][key])) assignDeep(target[key], args[i][key])
          else target[key] = args[i][key]
        }
      }
      assignSymbols(target, args[i])
    }
  }

  return target
}

const assignSymbols = (target, ...args) => {
  if (!isObject(target)) throw new TypeError('expected the first argument to be an object')
  if (args.length === 0 || typeof Symbol !== 'function' || typeof getSymbols !== 'function') return target

  for (const arg of args) {
    const names = getSymbols(arg)

    for (const key of names) {
      if (isEnumerable.call(arg, key)) target[key] = arg[key]
    }
  }

  return target
}

const isObject = (val) => typeof val === 'function' || toString.call(val) === '[object Object]'
const isPrimitive = (val) => typeof val === 'object' ? val === null : typeof val !== 'function'
const isValidKey = key => key !== '__proto__' && key !== 'constructor' && key !== 'prototype'

export default assignDeep
