import React from 'react'
import { FontAwesome5, Input, t } from '../../Common'

const CustomersListFilterSearch = ({ label }) => (
  <div className='ta-customers__filter-search'>
    <Input
      label={t(label)}
      type='text'
      name='search'
      clearable
      addon={<FontAwesome5 icon='search' />}
    />
  </div>
)

export default CustomersListFilterSearch
