import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { ServiceCategoryPreview } from '../../Beauties'

const ServiceCategoryPreviewWrapper = props => {
  let {
    categoryId,
    categories,
    hash,
    pending,
    account,
    allowOnlyGlobalCustomers
  } = props
  pending = !!pending
  categories = categories || []
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalServicesRead, globalServicesWrite, globalServicesDelete } = enterprisePermissions || {}
  globalServicesRead = !!isAdmin || !!globalServicesRead
  globalServicesWrite = !!isAdmin || !!globalServicesWrite
  globalServicesDelete = !!isAdmin || !!globalServicesDelete
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.serviceCategoryLocallyChangedGet(categoryId)
  }, [categoryId])
  const category = categories.find(item => item.id === categoryId) || {}

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServiceCategoryPreview
          category={category}
          hash={hash}
          pending={pending}
          globalServicesRead={globalServicesRead}
          globalServicesWrite={globalServicesWrite}
          globalServicesDelete={globalServicesDelete}
          allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: state.router.hash,
  categoryId: (state.router.data && state.router.data.categoryId),
  categories: state.services.categoriesList,
  pending: state.services.pendingCategoryPreview,
  account: state.account,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default connect(maps)(ServiceCategoryPreviewWrapper)
