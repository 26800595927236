import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { Branches } from '../../Beauties'
import { Loader } from '../../Common'
import { sortBy } from '../../Utils'

class BranchesWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  componentDidMount () {
    const { areBranchesFetched } = this.props
    if (!areBranchesFetched) handlers.branchesListGet()
  }

  async onClick ({ e, id, isCustomisationIgnored }) {
    e.stopPropagation()
    handlers.branchesLogin(id, isCustomisationIgnored)
  }

  onClickExportBranches () {
    setTimeout(() => {
      handlers.modalSet('export-branches')
    }, 0)
  }

  render () {
    const {
      branches,
      pending,
      filter,
      showImportMessage,
      enterpriseCustomisation,
      account
    } = this.props
    let { enterprisePermissions, isAdmin } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { branchesRead, branchesWrite, tagsRead, allowFulfilment } = enterprisePermissions || {}
    branchesRead = !!isAdmin || !!branchesRead
    branchesWrite = !!isAdmin || !!branchesWrite
    tagsRead = !!isAdmin || !!tagsRead
    allowFulfilment = !!isAdmin || !!allowFulfilment
    const { payload: customisaitonPayload } = enterpriseCustomisation || {}
    const { settings } = customisaitonPayload || {}
    const {
      hideBranchLoginBtn,
      hideBranchAddBtn,
      hideBranchImportBtn,
      exportTemplateCode,
      showBranchLoginWithoutCustomisationsBtn
    } = settings || {}
    const { search, tagsFilter } = filter || {}
    let { value: searchValue } = search || {}
    let { value: tagFilterValue } = tagsFilter || {}
    searchValue = searchValue ? searchValue.toLowerCase() : ''
    tagFilterValue = tagFilterValue || ''
    const filteredBranches = branches
      .filter(item => {
        let {
          id,
          name,
          personName,
          address,
          phone,
          tags,
          ownerEmail,
          externalId
        } = item
        let { phone: formattedPhone } = phone || {}
        let { city, country, formatted: formattedAddress } = address || {}
        id = id || ''
        name = name || ''
        personName = personName || ''
        ownerEmail = ownerEmail || ''
        formattedPhone = formattedPhone || ''
        city = city || ''
        country = country || ''
        formattedAddress = formattedAddress || ''
        externalId = externalId || ''
        tags = tags || []
        let tagNames
        let hasFilteredTags = true
        if (tagFilterValue) {
          tagNames = tags.map(tag => tag.name)
          hasFilteredTags = tagNames.includes(tagFilterValue)
        }

        return hasFilteredTags
          ? (
              (name.toLowerCase().includes(searchValue)) ||
          (personName.toLowerCase().includes(searchValue)) ||
          (ownerEmail.toLowerCase().includes(searchValue)) ||
          (formattedPhone.toLowerCase().includes(searchValue)) ||
          (formattedAddress.toLowerCase().includes(searchValue)) ||
          (city.toLowerCase().includes(searchValue)) ||
          (country.toLowerCase().includes(searchValue)) ||
          (externalId.toLowerCase().includes(searchValue)) ||
          (id === searchValue)
            )
          : false
      }).sort(sortBy('name'))

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <Branches
            branchesRead={branchesRead}
            branchesWrite={branchesWrite}
            tagsRead={tagsRead}
            branches={filteredBranches}
            search={searchValue}
            onClick={this.onClick}
            showImportMessage={showImportMessage}
            hideBranchLoginBtn={hideBranchLoginBtn}
            hideBranchAddBtn={hideBranchAddBtn}
            hideBranchImportBtn={hideBranchImportBtn}
            allowFulfilment={allowFulfilment}
            isAdmin={isAdmin}
            exportTemplateCode={exportTemplateCode}
            onClickExportBranches={this.onClickExportBranches}
            showBranchLoginWithoutCustomisationsBtn={showBranchLoginWithoutCustomisationsBtn}
          />
        )}
      </>
    )
  }
}

const maps = state => ({
  pending: state.branches.pending || false,
  branches: state.branches.list || [],
  areBranchesFetched: state.branches.areFetched || false,
  filter: state.forms.branchesFilter || {},
  showImportMessage: state.branches.showImportMessage || false,
  enterpriseCustomisation: state.company.enterpriseCustomisation || {},
  account: state.account
})

export default connect(maps)(BranchesWrapper)
