import React from 'react'
import {
  Button,
  Title,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  AccordionBody,
  AccordionTitle,
  Accordion,
  DnDDroppable,
  Form,
  Error,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { ServicesList } from '../../Beauties'
import { truncateText, dangerousHTML } from '../../Utils'

const ServicesCategoryWithAccordion = props => {
  let {
    category,
    pending,
    services,
    allServices,
    index,
    search,
    filterType,
    showAddServiceCombinations,
    globalServicesRead,
    globalServicesWrite,
    globalServicesDelete,
    isLastIndex
  } = props
  services = services || []
  const classNames = ['ta-services__category', 'has-buttons', 'global']
  if (index === 0) classNames.push('first')
  if (pending) classNames.push('pending')
  if (category.isDefault) classNames.push('default')
  if (category.isUpdated) classNames.push('updated')
  if (category.isDeleted) classNames.push('deleted')
  if (!globalServicesWrite) classNames.push('no-write-permissions')
  if (!globalServicesDelete) classNames.push('no-delete-permissions')
  const isDefaultCategory = category.isDefault
  const locallyChangedBranches = (category.locallyUpdatedBranches && Object.keys(category.locallyUpdatedBranches).length) || null
  const categoryName = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
  const categoryNameLimit = isDefaultCategory
    ? locallyChangedBranches ? 13 : 24
    : locallyChangedBranches ? 17 : 30
  const hasServices = !!(services?.length)

  const categoryExtraText = () => {
    return (
      <>
        {isDefaultCategory && <span className='ta-default-category__text'>{t('global.defaultCategory.hint')}</span>}
        {(locallyChangedBranches &&
          <HoverPopup>
            <HoverPopupContent position={index === 0 ? 'bottom' : 'top'}>
              <p className='ta-global-label__text'>
                {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
              </p>
            </HoverPopupContent>
            <div
              onClick={e => e.stopPropagation()}
            >
              <HoverPopupTrigger>
                <Button as='link' to={`/management/services/categories/${category.id}@@changedLocally`}>
                  <div className='ta-customer-fields__category-type global edited global-label'>
                    <FontAwesome5 icon='pencil' type='s' />
                    {t('global.changedLocally')}
                  </div>
                </Button>
              </HoverPopupTrigger>
            </div>
          </HoverPopup>
        )}
      </>
    )
  }

  return (
    <div key={category.id} className={classNames.join(' ')}>
      <Accordion inactive={isDefaultCategory} focusOnHover>
        <AccordionTitle>
          <Title
            size='m'
            label={truncateText(categoryName, categoryNameLimit, true)}
            extraText={categoryExtraText()}
            icon='folder'
          >
            {(globalServicesRead &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('couse.preview.category.openCategory')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' isSecondary icon='folder' to={`/management/services/categories/${category.id}`} />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
            {(globalServicesWrite &&
              <>
                {(globalServicesDelete && !isDefaultCategory &&
                  <PopupTriggerWrapper
                    name='service-category-delete'
                    position={index === 0 ? 'bottom' : 'top'}
                    id={category.id}
                    disable={hasServices}
                  >
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {(hasServices
                          ? t('global.category.delete.forbidden')
                          : t('global.category.delete')
                        )}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button as='link' isSecondary icon='trash-alt' disabled={hasServices} external />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>{t('buttons.editCategory.tooltip')}</HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' to={`/management/services/categories/${category.id}/edit`} isSecondary icon='pencil' />
                  </HoverPopupTrigger>
                </HoverPopup>
                {/* <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('servicesGroups.list.buttonAddService.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' to={`/management/services/add?c=${category.id}`} isPrimary icon='plus' />
                  </HoverPopupTrigger>
                </HoverPopup> */}
                {/* <PopupTriggerWrapper
                  name='services-create-type-select'
                  position='left'
                  id={category.id}
                >
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('servicesGroups.list.buttonAddService.tooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button as='link' isPrimary icon='plus' external />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </PopupTriggerWrapper> */}
                {(!showAddServiceCombinations &&
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('servicesGroups.list.buttonAddService.tooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button
                        as='link'
                        to={`/management/services/add?c=${category.id}`}
                        isPrimary
                        icon='plus'
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                )}
                {(showAddServiceCombinations &&
                  <PopupTriggerWrapper
                    name='services-create-type-select'
                    position='left'
                    id={category.id}
                  >
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('servicesGroups.list.buttonAddService.tooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button as='link' isPrimary icon='plus' external />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
              </>
            )}
          </Title>
        </AccordionTitle>
        <AccordionBody>
          <DnDDroppable id={category.id} type='service'>
            <ServicesList
              search={search}
              filterType={filterType}
              services={services}
              allServices={allServices}
              isDragAndDropMode={false}
              areCategoriesExpanded={false}
              categoryIndex={category.orderIndex}
              isCategoryLastIndex={isLastIndex}
              isDefaultCategory={category.isDefault}
            />
            <Form name={`serviceCategory${category.id}`}>
              <Error name='globalErrors' />
            </Form>
          </DnDDroppable>
        </AccordionBody>
      </Accordion>
    </div>
  )
}

export default ServicesCategoryWithAccordion
