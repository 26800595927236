import React from 'react'
import { ServiceCategoryPreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const ServiceCategoryPreviewChangedLocallyWrapper = props => {
  let { serviceCategory, account } = props
  serviceCategory = serviceCategory || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalServicesWrite } = enterprisePermissions || {}
  globalServicesWrite = !!isAdmin || !!globalServicesWrite
  const { locallyChangedData, internalId } = serviceCategory || {}

  const onResetGlobalEntity = (companyId, region) => handlers.serviceCategoryLocallyChangedReset({ companyId, region, internalId })

  return (
    <ServiceCategoryPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalServicesWrite={globalServicesWrite}
    />
  )
}

const maps = state => {
  const { router, services } = state
  const { categoriesList } = services || {}
  const categoryId = (router.data && router.data.categoryId) || ''
  const serviceCategory = (categoriesList || []).find(item => item.id === categoryId) || {}

  return {
    serviceCategory,
    account: state.account
  }
}

export default connect(maps)(ServiceCategoryPreviewChangedLocallyWrapper)
