import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { store$, handlers } from '../../Store'
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID
} from '../../Settings'
import subscriptions from '../Config/subscriptions'
const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID
}
firebase.initializeApp(config)
const database = firebase.database()

let userRef
let companyRef
let nowUser
let nowCompany

store$
  .pipe(
    map(state => state.account.userId || ''),
    distinctUntilChanged()
  ).subscribe((userId) => {
    if (userRef) {
      userRef.off()
      userRef = null
      nowUser = null
    }
    if (userId) {
      if (!nowUser) nowUser = new Date().getTime()
      userRef = database.ref(`partitions/${userId}`).orderByChild('ts').startAt(nowUser)
      nowUser = new Date().getTime()
      userRef.on('child_added', data => {
        const { name, id, ids, payload, ts } = data.val()
        if (!name) return
        const handlerName = subscriptions[name]
        const VERBOSE_QUERIES = !!['development', 'staging'].includes(process.env.REACT_APP_ENV)
        if (handlerName) {
          VERBOSE_QUERIES && console.warn(`------------------------------- \nSubscription: ${name} \nHandler:      ${handlerName} \nTS:           ${ts || '---'} \nID:           ${id || '---'} \nIDS:          ${ids || '---'} \nPayload:`, (payload || '     ---'), '\n---------------------------------')
          handlers[handlerName]({ name, id, ids, data: payload, ts })
        } else VERBOSE_QUERIES && console.warn(`------------------------------- \nTODO: SUBSCRIPTION NOT HANDLED \nid:          ${id || '---'} \nname:        ${name} \nhandlerName: ${handlerName || '---'} \n---------------------------------`)
      })
    }
  })

store$
  .pipe(
    map(state => state.company.id || ''),
    distinctUntilChanged()
  ).subscribe((companyId) => {
    if (companyRef) {
      companyRef.off()
      companyRef = null
      nowCompany = null
    }
    if (companyId) {
      if (!nowCompany) nowCompany = new Date().getTime()
      companyRef = database.ref(`partitions/${companyId}`).orderByChild('ts').startAt(nowCompany)
      nowCompany = new Date().getTime()
      companyRef.on('child_added', data => {
        const { name, id, ids, payload, ts } = data.val()
        if (!name) return
        const handlerName = subscriptions[name]
        const VERBOSE_QUERIES = !!['development', 'staging'].includes(process.env.REACT_APP_ENV)
        if (handlerName) {
          VERBOSE_QUERIES && console.warn(`------------------------------- \nSubscription: ${name} \nHandler:      ${handlerName} \nTS:           ${ts || '---'} \nID:           ${id || '---'} \nIDS:          ${ids || '---'} \nPayload:`, (payload || '     ---'), '\n---------------------------------')
          handlers[handlerName]({ name, id, ids, data: payload, ts })
        } else VERBOSE_QUERIES && console.warn(`------------------------------- \nTODO: SUBSCRIPTION NOT HANDLED \nid:          ${id || '---'} \nname:        ${name} \nhandlerName: ${handlerName || '---'} \n---------------------------------`)
      })
    }
  })
