import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesPreview } from '../../Beauties'
import { Loader } from '../../Common'

class BranchesPreviewWrapper extends Component {
  constructor (props) {
    super(props)
    this.onClickLogin = this.onClickLogin.bind(this)
  }

  componentDidMount () {
    const { id } = this.props
    handlers.branchesPreviewGet({ id })
  }

  componentWillUnmount () {
    handlers.branchImportServiceMessagesHide()
    handlers.branchImportResourceMessagesHide()
    handlers.branchImportGroupMessagesHide()
  }

  async onClickLogin (e, branch, isCustomisationIgnored) {
    e.stopPropagation()
    handlers.branchesLogin(branch.id, isCustomisationIgnored)
  }

  render () {
    let {
      id,
      branches,
      hash,
      isPending,
      roles,
      enterpriseCustomisation,
      account
    } = this.props
    let { enterprisePermissions, isAdmin } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { branchesRead, branchesWrite, tagsRead, allowFulfilment } = enterprisePermissions || {}
    branchesRead = !!isAdmin || !!branchesRead
    branchesWrite = !!isAdmin || !!branchesWrite
    allowFulfilment = !!isAdmin || !!allowFulfilment // branch login button
    tagsRead = !!isAdmin || !!tagsRead
    branches = branches || []
    isPending = !!isPending
    roles = roles || []
    enterpriseCustomisation = enterpriseCustomisation || {}
    const permissions = {
      delete: roles.includes('GOD') || roles.includes('OWNER')
    }
    const branch = branches.find(item => item.id === id) || {}
    const { payload: customisaitonPayload } = enterpriseCustomisation || {}
    const { settings } = customisaitonPayload || {}
    const { hideBranchLoginBtn, showBranchLoginWithoutCustomisationsBtn } = settings || {}

    return (
      !isPending
        ? (
          <BranchesPreview
            branch={branch}
            hash={hash}
            onClickLogin={this.onClickLogin}
            permissions={permissions}
            hideBranchLoginBtn={hideBranchLoginBtn}
            branchesRead={branchesRead}
            branchesWrite={branchesWrite}
            tagsRead={tagsRead}
            allowFulfilment={allowFulfilment}
            showBranchLoginWithoutCustomisationsBtn={showBranchLoginWithoutCustomisationsBtn}
          />
          )
        : <Loader active />
    )
  }
}

const maps = (state, props) => ({
  id: state.router.data.id,
  hash: state.router.hash,
  branches: state.branches.list,
  isPending: state.branches.pendingPreview,
  roles: state.account.roles,
  enterpriseCustomisation: state.company.enterpriseCustomisation,
  account: state.account
})

export default connect(maps)(BranchesPreviewWrapper)
