import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  Title,
  Button,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

import './Courses.css'

const Courses = props => {
  const {
    isDragAndDropMode,
    areExpanded,
    onCategoriesToggle,
    globalGroupsWrite
  } = props

  return (
    <div>
      {(isDragAndDropMode &&
        <Title icon='suitcase' iconTheme='grey' label={areExpanded ? t('servicesGroups.list.groupReorder.heading') : t('global.categoryOrder')}>
          {(globalGroupsWrite &&
            <>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {(areExpanded
                    ? t('global.switchCategoryOrder')
                    : t('servicesGroups.list.buttonHeading.switchGroupOrderTooltip')
                  )}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button
                    as='link'
                    icon={areExpanded ? 'compress-alt' : 'expand-alt'}
                    isSecondary
                    action={onCategoriesToggle}
                  />
                </HoverPopupTrigger>
              </HoverPopup>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.exitOrdering')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button
                    as='link'
                    icon='times'
                    to='/management/courses'
                    isPrimary
                  />
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
      {(!isDragAndDropMode &&
        <Title icon='suitcase' iconTheme='grey' label={t('global.groups')}>
          <PopupTriggerWrapper name='courses-help-box' position='bottom' extraClassName='ta-help-box'>
            <Button
              as='link'
              className='ta-title__link'
              external
            >
              {t('global.help')} <FontAwesome5 icon='lightbulb' />
            </Button>
          </PopupTriggerWrapper>
          {(globalGroupsWrite &&
            <>
              {/* <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.groupOrderTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button
                    as='link'
                    to='/management/courses/reorder'
                    btn-isSecondary
                    icon='arrows-v'
                  />
                </HoverPopupTrigger>
              </HoverPopup> */}
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.groupCategoryTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button
                    as='link'
                    className='services-folder-icon'
                    to='/management/courses/categories/add'
                    isPrimary
                  >
                    <FontAwesome5 icon='folder' type='solid' />
                  </Button>
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
    </div>
  )
}

export default Courses
