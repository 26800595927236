import React from 'react'
import PropTypes from 'prop-types'
import { BlueTemplate, Loader, FontAwesome5, Button, t } from '../../Common'
import { ForgottenPasswordForm } from '../../Beauties'

import './Login.css'

const ForgottenPassword = props => {
  const {
    pending,
    pendingLanguage,
    onSubmit,
    passwordFieldType,
    togglePasswordFieldType,
    onLanguageChange
  } = props
  const logoUrl = '/login'

  return (
    <div className='ta-login'>
      <BlueTemplate logoUrl={logoUrl}>
        {(!pendingLanguage &&
          <div className='ta-login__box'>
            <div className='ta-login__box-content'>
              <div className='ta-login__title'>
                <Button as='link' isSecondary to='login'>
                  <FontAwesome5 icon='long-arrow-left' type='solid' />
                </Button>
                {t('global.passwordForgotten')}
              </div>
              <Loader active={pendingLanguage || pending} />
              <ForgottenPasswordForm
                onSubmit={onSubmit}
                passwordFieldType={passwordFieldType}
                togglePasswordFieldType={togglePasswordFieldType}
                onLanguageChange={onLanguageChange}
              />
            </div>
          </div>
        )}
      </BlueTemplate>
    </div>
  )
}

ForgottenPassword.propTypes = {
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default ForgottenPassword
