import React, { useEffect } from 'react'
import moment from 'moment'

import { connect, handlers, selectors } from '../../../Store'
import { isRouteDisabledByCustomisations } from '../../../Common'
import { AppsSubscriptionCancelled } from '../../../Beauties'

// const mock = {
//   pricingType: 'monthly',
//   entityName: 'SMS',
//   isVariablePrice: true,
//   overviewText: 'You’ve cancelled your subscription for this app. You can still use it until 27.10.2022. Your last invoice will be generated on 28.10.2022 and will be automatically booked from your defined payment method. You can upgrade again any time you want.',
//   isActive: false,
//   price: 0.1,
//   priceRange: [0.0095, 0.30],
//   payPerUsageText: '* The price per SMS varies based on the country to which the SMS is sent to and the telecommunication operator.',
//   newOverviewText: 'You will be charged the amount you’ve cumulated over the period from the <strong>25.10.2023 until the 25.11.2023</strong>. Your invoice will be automatically generated on the 26th. An overview of your spendings will be available in the app. App usage and associated fees can be disabled from the Settings view of the app at any time.  You can cancel your subscription at any time before the 25th of the month, otherwise it will be automatically renewed.',
//   plans: [
//     {
//       name: 'basic',
//       text: 'Includes 50 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
//       price: 10
//     },
//     {
//       name: 'silver',
//       text: 'Includes 150 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
//       price: 22
//     },
//     {
//       name: 'gold',
//       text: 'Includes 250 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
//       price: 35
//     },
//     {
//       name: 'premium',
//       text: 'Includes 350 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
//       price: 100
//     }
//   ]
// }

const AppsSubscriptionCancelledWrapper = props => {
  let {
    id,
    app,
    pending,
    isOwner,
    isAdmin
  } = props
  app = app || {}
  const { appInstallation } = app || {}
  let {
    unitDescription,
    plans,
    hasUsage,
    isBlocked,
    cancelledAt,
    isPaidByEnterprise,
    unsubscribedAt,
    currencyCode
  } = appInstallation || {}
  plans = plans || []
  const plan = plans[0] || {} // only one plan for pay per usage
  const {
    usageMaxUnitPrice,
    usageMinUnitPrice,
    usageUnitPrice,
    price
  } = plan
  const pricingType = (hasUsage || (hasUsage === null && !isPaidByEnterprise)) ? 'payPerUsage' : 'monthly'
  const isVariablePrice = !!usageMaxUnitPrice && !!usageMinUnitPrice && usageMinUnitPrice !== usageMaxUnitPrice
  const priceRange = [usageMinUnitPrice, usageMaxUnitPrice]
  const isActive = !isBlocked
  const activeUntil = moment(cancelledAt, 'YYYY-MM-DD').format('L')
  const nextInvoiceDate = moment(cancelledAt, 'YYYY-MM-DD').add(1, 'days').format('L')
  const appsWrite = !!isAdmin

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
    }
    if (!appsWrite && isActive) {
      handlers.navigateToRoute('appsIframe', { id })
      return
    }
    if (!appsWrite && !isActive) {
      handlers.navigateToPath('/apps')
      return
    }
    if (!unsubscribedAt) {
      handlers.navigateToRoute('appsSubscription', { id })
      return
    }
    handlers.appPreviewGet({ id })
    handlers.paymentMethodsGet()
  }, [id, appsWrite, isActive, unsubscribedAt])

  const handleOnContinue = () => {
    handlers.navigateToRoute('appsIframe', { id })
  }

  const handleOnUninstall = () => {
    handlers.navigateToRoute('appsUninstall', { id })
  }

  const handleOnResubscribe = () => {
    handlers.appSubscriptionResubscribeFormSave()
  }

  return (
    <AppsSubscriptionCancelled
      {...app}
      currencyCode={currencyCode}
      onContinue={handleOnContinue}
      onUninstall={handleOnUninstall}
      onResubscribe={handleOnResubscribe}
      pricingType={pricingType}
      isVariablePrice={isVariablePrice}
      unitDescription={unitDescription}
      isActive={isActive}
      pending={pending || !cancelledAt}
      activeUntil={activeUntil}
      nextInvoiceDate={nextInvoiceDate}
      isOwner={isOwner}
      usageUnitPrice={usageUnitPrice}
      price={price}
      priceRange={priceRange}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    pending: selectors.appsFieldSelector(state, { field: 'pendingPreview' }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' })
  }
}

export default connect(maps)(AppsSubscriptionCancelledWrapper)
