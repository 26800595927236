import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { UserGroupsForm } from '../../Beauties'

class UserGroupsEditWrapper extends Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    // handlers.userGroupsFormGet(this.props.partnerId)
  }

  onSubmit (cb) {
    let { form } = this.props
    form = form || {}

    handlers.usersFormSave({ user: form }, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    return (
      <UserGroupsForm
        title='Edit user group'
        type='edit'
        cancelLink='/managers/users/user-groups'
        onSubmit={this.onSubmit}
      />

    )
  }
}

const maps = state => ({
  id: ((state.router && state.router.data) && state.router.data.id) || '',
  form: state.forms.userGroups
})

export default connect(maps)(UserGroupsEditWrapper)
