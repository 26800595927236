import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error, t, FontAwesome5, Button } from '../../../Common'

import './Input.css'

class Input extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const value = this.ref.value || ''
    let { name, form, formName, onChange, onChangeAddon } = this.props
    form = form || { value: '' }
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  onReset () {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: '' } })
  }

  render () {
    const { focused } = this.state
    let {
      label,
      hintText,
      prefix,
      addon,
      type,
      name,
      value,
      mandatory,
      disabled,
      clearable,
      hideError,
      className,
      limit,
      form,
      autoFocus,
      isAddonText,
      dataTestid,
      isPrefixText,
      hasAutocomplete
    } = this.props
    form = form || { value: '' }
    const hasError = form.errors && form.errors.length > 0
    const inputClassNames = ['ta-form-control']
    if (addon || limit) inputClassNames.push('has-addon')
    const addonClassNames = ['ta-form-control__addon']
    if (isAddonText) addonClassNames.push('is-text')
    const prefixClassNames = ['ta-form-control__prefix']
    if (isPrefixText) prefixClassNames.push('is-text')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value || typeof value === 'number' || typeof form.value === 'number'}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
          hasError={hasError}
          hasPrefix={prefix}
        >
          <div className='ta-form-control-wrapper'>
            {prefix && (
              <div className={prefixClassNames.join(' ')}>{prefix}</div>
            )}
            <input
              data-testid={dataTestid}
              className={inputClassNames.join(' ')}
              ref={ref => { this.ref = ref }}
              type={type || form.type || 'text'}
              name={name}
              value={value !== undefined ? value : form.value}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              maxLength={limit && limit}
              disabled={disabled || form.disabled}
              autoComplete={hasAutocomplete ? 'on' : 'off'}
              aria-autocomplete={hasAutocomplete ? 'both' : 'none'}
              autoFocus={autoFocus}
            />
          </div>
          {(addon &&
            <div className={addonClassNames.join(' ')}>
              {addon}
            </div>
          )}
          {(limit &&
            <div className='ta-form-control__addon is-text'>
              {`${form.value.length} / ${limit}`}
            </div>
          )}
          {((hintText || limit) &&
            <div className='ta-form-control__hint'>
              {!hintText && limit && t('errors.maxCharacters', [{ key: 'LIMIT', value: limit }])}
              {hintText}
            </div>
          )}
          {(clearable && form.value &&
            <Button as='link' className='ta-form-control__btn-clear' isSecondary onClick={this.onReset}>
              <FontAwesome5 icon='times' type='r' />
            </Button>
          )}
          {(!hideError &&
            <Error noOffset name={name} />
          )}
        </FormGroup>
      </div>
    )
  }
}

Input.propTypes = {
  label: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  limit: PropTypes.number,
  dataTestid: PropTypes.string,
  form: PropTypes.object,
  autoFocus: PropTypes.bool,
  isAddonText: PropTypes.bool,
  hasAutocomplete: PropTypes.bool
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(Input), FormContext)
