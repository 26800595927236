const reducer = {}

reducer.initialState = {
  isTopActive: false,
  isBottomActive: false,
  isMessageActive: false,
  message: null,
  buttonLabel: null
}

reducer.handlers = (dispatch, actions) => ({
  overlaySet: dynamicObject => dispatch(actions.OVERLAY_SET, dynamicObject),
  overlayHide: position => dispatch(actions.OVERLAY_HIDE, position),
  overlayMessageSet: ({ message, buttonLabel }) => dispatch(actions.OVERLAY_MESSAGE_SET, { message, buttonLabel })
})

reducer.OVERLAY_SET = (state, { position }) => {
  const result = { ...state }
  if (!position || position === 'top') result.isTopActive = true
  if (!position || position === 'bottom') result.isBottomActive = true

  return result
}

reducer.OVERLAY_HIDE = (state, position) => {
  const result = { ...state }
  if (!position || position === 'top') result.isTopActive = false
  if (!position || position === 'bottom') result.isBottomActive = false
  if (!position || position === 'message') result.isMessageActive = false

  return result
}

reducer.OVERLAY_MESSAGE_SET = (state, { message, buttonLabel }) => ({
  ...state,
  message,
  buttonLabel,
  isMessageActive: true
})

export default reducer
