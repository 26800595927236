import React from 'react'
import moment from 'moment'
import {
  Form,
  Row,
  Col,
  SingleSelect,
  Input,
  DateInput,
  t,
  FontAwesome5
} from '../../Common'

const ActivityLogsStatusFilters = props => {
  const { hasAdvancedFilter, toggleAdvancedOptions, count } = props
  // we will need this in the future, if we add more action types
  // const typeOptions = [
  //   { value: 'SYSTEM_ACTIONS', label: t('global.systemMessages') }
  // ]
  const operationOptions = [
    { value: 'APPROVED', label: t('global.aproved') },
    { value: 'EXPIRED', label: t('global.expired') },
    { value: 'DECLINED', label: t('global.declined') },
    { value: 'REVOKED', label: t('global.revoked') }
  ]

  return (
    <div className='ta-notifications__activity-logs__filter'>
      <Form name='activityLogsFilters'>
        <Row>
          <Col size={50}>
            <Input
              label={t('global.search')}
              name='search'
              clearable
            />
          </Col>
          {/* <Col size={35}>
            <SingleSelect
              name='type'
              label={t('global.segment')}
              options={typeOptions}
              clearable
            />
          </Col> */}
          <Col size={50}>
            <SingleSelect
              name='operation'
              label={t('global.type.label')}
              options={operationOptions}
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='ta-activity-logs__filter__emails-count'>
              {t('global.resultsWithCount', [{ key: 'COUNT', value: count || '0' }])}
            </span>
          </Col>
          <Col>
            <span className='ta-activity-logs__filter__btn' onClick={toggleAdvancedOptions}>
              {t('global.advancedFilters')}
              <FontAwesome5 icon={hasAdvancedFilter ? 'angle-up' : 'angle-down'} />
            </span>
          </Col>
        </Row>
        {(hasAdvancedFilter &&
          <Row>
            <Col size={50}>
              <DateInput
                name='from'
                label={t('global.from')}
                icon='calendar'
                maxDate={moment()}
                clearable
              />
            </Col>
            <Col size={50}>
              <DateInput
                className='period-until'
                name='until'
                label={t('global.until')}
                icon='calendar'
                maxDate={moment()}
                clearable
              />
            </Col>
          </Row>
        )}
      </Form>
    </div>
  )
}

export default ActivityLogsStatusFilters
