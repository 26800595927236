import React from 'react'
import { connect } from '../../Store'
import { Loader } from '../../Common'
import { Account } from '../../Beauties'

const AccountWrapper = props => {
  let {
    hash,
    pending,
    account
  } = props
  pending = !!pending
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  isAdmin = !!isAdmin
  let { accountRead } = enterprisePermissions || {}
  accountRead = !!accountRead

  return (
    pending
      ? <Loader active />
      : (
        <Account
          {...account}
          accountRead={isAdmin || accountRead}
          hash={hash}
        />
        )
  )
}

const maps = state => ({
  hash: state.router.hash,
  account: state.account,
  pending: state.account.pendingPreview
})

export default connect(maps)(AccountWrapper)
