import React, { Component } from 'react'
import { connect, handlers, store, selectors } from '../../Store'
import { Loader, t, isRouteDisabledByCustomisations, isRouteDisabledByPermissions } from '../../Common'
import { BillingForm } from '../../Beauties'

class BillingEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const isRouteDisabled = isRouteDisabledByCustomisations() || isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    const { id } = this.props
    handlers.billingFormGet(id)
  }

  onSubmit (cb) {
    const form = store.getState().forms.billing
    handlers.billingFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    let {
      pending,
      errors,
      country
    } = this.props
    errors = errors || []
    country = country || {}

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <BillingForm
            title={t('billing.edit.billingDetails.heading')}
            cancelLink='/account/billing'
            country={country}
            errors={errors}
            onSubmit={this.onSubmit}
          />
        )}
      </>
    )
  }
}

const maps = state => ({
  id: state.router.data.id,
  errors: state.billing.errors,
  pending: state.billing.pendingForm,
  enterprise: state.account.enterprise,
  countries: state.staticData.countries || [],
  country: selectors.countrySelector(state)
})

export default connect(maps)(BillingEditWrapper)
