import React from 'react'
import {
  Title,
  ListItem,
  FormSection,
  t
} from '../../../Common'

const BookingSettingsOther = props => {
  const {
    rawSettings,
    showExternalIds,
    showAddServiceCombinations,
    enableCrossAccountsBookings,
    callCenterLoginButton,
    isAdmin,
    callCentreEnabled,
    customisations
  } = props
  const { settings } = customisations || {}
  const { hideOtherSettingsCallCentre, hideOtherSettingsCrossAccountSync } = settings || {}

  return (
    <div className='ta-booking-settings__other'>
      <FormSection>
        <Title size='m' isCompact label={t('settings.section.showExternalID.heading')} icon='fingerprint' />
        <ListItem>
          {(rawSettings.showExternalIds === null &&
            <>
              <strong>{t('global.defaultValue')}</strong>
              <br />
            </>
          )}
          {showExternalIds && t('settings.section.showExternalID.infoText')}
          {!showExternalIds && t('settings.section.showExternalID.disabled.infoText')}
        </ListItem>
      </FormSection>
      <FormSection>
        <Title size='m' isCompact label={t('settings.section.showAddServiceCombinations.heading')} icon='layer-group' />
        <ListItem>
          {t(showAddServiceCombinations ? 'global.enabled' : 'global.disabled')}
        </ListItem>
      </FormSection>
      {(isAdmin && !hideOtherSettingsCrossAccountSync &&
        <FormSection>
          <Title size='m' isCompact label={t('settings.section.crossAccountSync.heading')} icon='sync' />
          <ListItem>
            {enableCrossAccountsBookings && t('global.enabled')}
            {!enableCrossAccountsBookings && t('global.disabled')}
          </ListItem>
        </FormSection>
      )}
      {(isAdmin && !hideOtherSettingsCallCentre && callCentreEnabled &&
        <FormSection>
          <Title size='m' isCompact label={t('settings.section.callCenterLoginButton.heading')} icon='phone-square' />
          <ListItem>
            {t(callCenterLoginButton ? 'global.enabled' : 'global.disabled')}
          </ListItem>
        </FormSection>
      )}
    </div>
  )
}

export default BookingSettingsOther
