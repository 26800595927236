import React from 'react'
import * as Sentry from '@sentry/react'
import { SENTRY_KEY, SENTRY_PROJECT } from '../../Settings'

const ErrorBoundary = props => {
  const { children } = props

  if (SENTRY_KEY && SENTRY_PROJECT && ['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
    return (
      <Sentry.ErrorBoundary
        fallback={() => (
          <div>An error has occurred</div>
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    )
  }
  return (
    <div>{children}</div>
  )
}

export default ErrorBoundary
