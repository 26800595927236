import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupUserPermissionsHelpBox = props => {
  return (
    <div className='ta-popup__permission-types-help-box'>
      <Alert noOffset noBorder label={t('userPermissionTypes.list.heading.helpTooltip.title')} theme='alert'>
        <div>{dangerousHTML(t('userPermissionTypes.list.heading.helpTooltip.text'))}</div>
      </Alert>
    </div>
  )
}

export default PopupUserPermissionsHelpBox
