import React, { useEffect } from 'react'

import { AppsSubscriptionForm } from '../../../Beauties'
import { connect, handlers, selectors } from '../../../Store'

// const mock = {
//   entityName: 'SMS',
//   hasBillingAddress: true
// }
const AppsSubscriptionFormWrapper = (props) => {
  let {
    hasThreshold,
    type,
    form,
    billingForm,
    app,
    billingData,
    pending,
    paymentMethods,
    chargebee,
    renderExtraContent,
    currentLimit,
    hasChangesBillingForm
  } = props
  app = app || {}
  form = form || {}
  billingForm = billingForm || {}
  chargebee = chargebee || {}
  const { noAutoCollect } = chargebee
  // const {
  // entityName,
  // hasBillingAddress
  // } = mock
  let {
    unitDescription,
    limitValue,
    nextLimitValue,
    extraConsumedCharges,
    currencyCode
  } = app.appInstallation || {}
  const {
    name,
    address1,
    zipCode,
    city
  } = billingData || {}
  const hasBillingDetails = name && address1 && zipCode && city
  const hasPaymentMethod = paymentMethods && paymentMethods.length > 0
  const thresholdChanged = nextLimitValue && nextLimitValue !== limitValue
  limitValue = thresholdChanged ? nextLimitValue : limitValue

  useEffect(() => {
    handlers.appSubscriptionFormGet()
  }, [])

  const handleOnSubmit = () => {
    if (hasChangesBillingForm) return
    handlers.appSubscriptionFormSave({ ...form, ...billingForm })
  }

  return (
    <AppsSubscriptionForm
      type={type}
      globalErrors={form?.globalErrors}
      currencyCode={currencyCode}
      onSubmit={handleOnSubmit}
      hasThreshold={hasThreshold}
      unitDescription={unitDescription}
      hasBillingDetails={hasBillingDetails}
      hasPaymentMethod={hasPaymentMethod}
      pending={pending}
      renderExtraContent={renderExtraContent}
      currentLimit={currentLimit}
      limitValue={limitValue}
      extraConsumedCharges={extraConsumedCharges}
      hasChangesBillingForm={hasChangesBillingForm}
      noAutoCollect={noAutoCollect}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    app: selectors.appsFindByIdSelector(state, { id }),
    form: selectors.formSelector(state, { formName: 'appSubscription' }),
    billingForm: selectors.formSelector(state, { formName: 'appSubscriptionBilling' }),
    hasThreshold: selectors.formFieldPropertySelector(state, { formName: 'appSubscription', name: 'hasThreshold', property: 'value' }),
    currentLimit: selectors.formFieldPropertySelector(state, { formName: 'appSubscription', name: 'threshold', property: 'value' }),
    billingData: selectors.billingDataSelector(state),
    pending: selectors.appsFieldSelector(state, { field: 'pendingSubscriptionForm' }),
    paymentMethods: state.paymentMethods.list,
    chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' }),
    hasChangesBillingForm: selectors.appsFieldSelector(state, { field: 'hasChangesBillingForm' })
  }
}

export default connect(maps)(AppsSubscriptionFormWrapper)
