import React from 'react'
import { StatisticsCompositeChildHeader } from '../../Beauties'
import {
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  FontAwesome5,
  Price
} from '../../Common'
import { dangerousHTML } from '../../Utils'

const StatisticsCompositeTotal = props => {
  const {
    isShort,
    number,
    text,
    isFinancial,
    currency,
    tooltip
  } = props
  const classNames = ['ta-statistics-composite-child', 'total']
  const bodyClassNames = ['ta-statistics-composite-child__body']
  if (isShort) {
    classNames.push('short')
    bodyClassNames.push('short')
  }
  const { text: tooltipText, position } = tooltip || {}
  const value = (isFinancial && currency)
    ? <><Price price={number} currency={currency} digits={number !== null ? 2 : 0} />{number === null && ',-'}</>
    : number === null
      ? '-'
      : number

  return (
    <div className={classNames.join(' ')}>
      <StatisticsCompositeChildHeader text={text} />
      <div className={bodyClassNames.join(' ')}>
        <p className='ta-statistics-composite-child__number'>{value}</p>
      </div>
      {((tooltipText && position) &&
        <HoverPopup className='ta-statistics__info-popup'>
          <HoverPopupContent position={position}>
            {dangerousHTML(tooltipText)}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className='ta-statistics-box__popup-icon'>
              <FontAwesome5 icon='info' type='solid' />
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
    )}
    </div>
  )
}

export default StatisticsCompositeTotal
