import React, { useEffect } from 'react'
import { CoursesToDelete } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'

const CoursesToDeleteWrapper = props => {
  let { coursesToDelete, branches, pending, account } = props
  coursesToDelete = coursesToDelete || []
  branches = branches || []
  pending = !!pending
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsRead, globalGroupsWrite } = enterprisePermissions || {}
  globalGroupsRead = !!isAdmin || !!globalGroupsRead
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite
  useEffect(() => {
    if (!globalGroupsRead) handlers.navigateToPath('/management/courses')
  }, [globalGroupsRead])

  const onDeleteGlobalEntity = (companyId, region, externalId, id) => handlers.courseToDeleteDelete({ companyId, region, externalId, id })
  const coursesToDeleteData = coursesToDelete.map(item => ({
    course: item,
    branch: branches.find(branch => branch.id === item.companyId)
  }))

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CoursesToDelete
          coursesToDeleteData={coursesToDeleteData}
          onDeleteGlobalEntity={onDeleteGlobalEntity}
          globalGroupsWrite={globalGroupsWrite}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  coursesToDelete: state.courses.listToDelete,
  pending: state.courses.pendingToDeleteList,
  account: state.account
})

export default connect(maps)(CoursesToDeleteWrapper)
