import React from 'react'
import { connect } from '../../Store'
import { ServiceFormDuration } from '../../Beauties'

const ServiceFormDurationWrapper = props => {
  const {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    splitDurationInIntervals,
    customisations,
    color
  } = props

  return (
    <ServiceFormDuration
      durationBefore={durationBefore}
      durationAfter={durationAfter}
      showDurationBeforeAndAfter={showDurationBeforeAndAfter}
      showDurationDaySelector
      splitDurationInIntervals={splitDurationInIntervals}
      customisations={customisations}
      color={color}
    />
  )
}

const maps = state => ({
  durationBefore: (state.forms.services.durationBefore && state.forms.services.durationBefore.value) || '',
  durationAfter: (state.forms.services.durationAfter && state.forms.services.durationAfter.value) || '',
  color: (state.forms.services.color && state.forms.services.color.value) || '',
  showDurationBeforeAndAfter: (state.forms.services.showDurationBeforeAndAfter && state.forms.services.showDurationBeforeAndAfter.value) || false,
  splitDurationInIntervals: (state.forms.services.splitDurationInIntervals && state.forms.services.splitDurationInIntervals.value) || false
})

export default connect(maps)(ServiceFormDurationWrapper)
