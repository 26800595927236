import React from 'react'

const TableRowCol = props => {
  const { children, size, textAlign, relative } = props
  const classNames = ['ta-table-row__col']
  if (textAlign === 'left') classNames.push('text-left')
  if (textAlign === 'right') classNames.push('text-right')
  if (relative) classNames.push('relative')
  const styles = {}
  if (size) styles.width = size

  return (
    <div className={classNames.join(' ')} style={styles}>
      {children}
    </div>
  )
}

export default TableRowCol
