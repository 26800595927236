import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { TwoFAForm } from '../../Beauties'

const TwoFaFormWrapper = props => {
  let {
    pending,
    form,
    authData,
    onCancel,
    onActivate
  } = props
  form = form || {}
  authData = authData || {}
  const { code } = form

  useEffect(() => {
    handlers.twoFAInit('OTP')
    handlers.twoFAFormPopulate()
  }, [])

  const onSubmit = cb => {
    handlers.twoFAUserSetup(form, (errors) => {
      cb && cb(errors)
      if (!errors && onActivate) {
        onActivate()
      }
    }, !!onActivate)
  }

  return (
    <TwoFAForm
      onSubmit={onSubmit}
      authData={authData}
      onCancel={onCancel}
      pinCode={code && code.value}
      isLogin={!!onActivate}
      pending={pending}
    />
  )
}

TwoFaFormWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  onCancel: PropTypes.func
}

const maps = state => ({
  form: state.forms.twoFA,
  pending: state.twoFA.pendingForm,
  authData: state.twoFA
})

export default connect(maps)(TwoFaFormWrapper)
