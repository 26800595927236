import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard'
import { Button, FontAwesome5 } from '../../Common'

import './CopyToClipboard.css'

class CopyToClipboard extends Component {
  constructor(props, context) {
    super(props)

    this.onCopy = this.onCopy.bind(this)
    this.state = { copied: false }
  }

  onCopy() {
    clearTimeout(this.timeout)
    this.setState({ copied: true })
    this.timeout = setTimeout(() => {
      this.props.onClickAddon()
      this.setState({ copied: false })
    }, 1000)
  }

  render() {
    const { copied } = this.state
    const { children, text, textButton, className, iconType } = this.props
    const classNames = ['ta-copy-to-clipboard']
    if (className) classNames.push(className)
    if (copied) classNames.push('copied')

    const buttonClassNames = ['ta-copy-to-clipboard__button', 'ta-btn', 'ta-btn-primary']
    if (textButton) buttonClassNames.push('text-button')

    return (
      <div className={classNames.join(' ')}>
        <Copy text={text} onCopy={this.onCopy}>
          <Button as='link' isPrimary className={buttonClassNames.join(' ')} title='Copy'>
            {textButton && textButton}
            {!textButton &&
              <div className='ta-copy-to-clipboard__button-text'>
                <FontAwesome5 icon='copy' type={iconType} />
              </div>
            }
          </Button>
        </Copy>
        {children}
      </div>
    )
  }
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired
}

export default CopyToClipboard
