import React from 'react'
import { handlers } from '../../../Store'
import { PopupPermissionTypeDelete } from '../../../Beauties'

const PopupPermissionTypeDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    const { id: ptId } = id || {}
    if (ptId) handlers.permissionTypeDelete(ptId)
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupPermissionTypeDelete approve={approve} reject={reject} />
  )
}

export default PopupPermissionTypeDeleteWrapper
