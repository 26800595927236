import { DEFAULT_COURSE_HEXCOLOR, EMAIL_VALIDATION_REGEX } from '../../../Settings'
import {
  dangerousHTML,
  normalizePrice,
  translateServerCode,
  validator,
  getFirstDayOfWeek,
  extractFromHtml,
  removeMultipleSpaces
} from '../../../Utils'

const days = [...Array(7).keys()].map(key => ({
  isActive: false,
  times: []
}))
const coursesDefaults = {
  bookingWeeklyAllowancePlan: [...days],
  workingWeeklyAllowancePlan: [...days],
  category: {}
}

// LIST

export const courseListTransform = courses => {
  if (!courses) return
  return courses.map(item => ({
    ...item,
    isBatch: (item.durationsPattern || []).length > 1
  }))
}

// PREVIEW
export const coursePreviewErrorTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// FORM

export const courseFormTransform = ({ course, branches, account }) => {
  const allowedBranchExternalIds = course.allowedBranchExternalIds || []
  const allowedBranchIds = course.allowedBranchIds || []
  const { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  const {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !course.id
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []
  if (!course) course = coursesDefaults
  if (!course.category) course.category = coursesDefaults.category
  if (!course.bookingWeeklyAllowancePlan) course.bookingWeeklyAllowancePlan = days
  if (!course.workingWeeklyAllowancePlan) course.workingWeeklyAllowancePlan = days
  const availableResources = course.availableResources || []
  const availableCategories = course.availableCategories || []
  const dependencies = {
    values: (course.dependencies && course.dependencies.map(dependency => {
      const dependencyCategory = dependency.specificResourceCategoryIds && dependency.specificResourceCategoryIds[0]
      // const resourcesCategoriesIds = [...new Set(
      //   (dependency.specificResourceIds && dependency.specificResourceIds.length > 0
      //     ? availableResources.filter(item => dependency.specificResourceIds.includes(item.id))
      //     : availableResources
      //   ).map(item => item.categoryId)
      // )]
      return {
        categoryId: dependencyCategory,
        workInParallel: false,
        resourceIds: null
        // categoryId: dependency.specificResourceIds
        //   ? resourcesCategoriesIds.length > 1 ? 'all' : resourcesCategoriesIds[0]
        //   : dependencyCategory,
        // workInParallel: dependency.workInParallel,
        // resourceIds: dependency.specificResourceIds
        // ? dependency.specificResourceIds.length === 0
        //   ? ['all']
        //   : dependency.specificResourceIds
        // : ['all']
      }
    })) || [],
    resources: availableResources.map(resource => ({
      value: resource.id,
      label: resource.name,
      categoryId: resource.categoryId,
      avatarImage: resource.avatarUrl,
      avatarName: resource.name,
      avatarColor: resource.color
    })),
    categories: availableCategories.map(category => ({
      value: category.id,
      label: category.name,
      isDependency: category.isDependency
    }))
  }
  const companyCustomerEmailRemindersMinutes = course.companySettings && course.companySettings.customerEmailRemindersMinutes
  const companyResourceEmailRemindersMinutes = course.companySettings && course.companySettings.resourceEmailRemindersMinutes
  const courseCustomReminderCustomerSwitch = !!course.customerEmailRemindersMinutes
  const courseCustomReminderStaffSwitch = !!course.resourceEmailRemindersMinutes
  const customerEmailRemindersMinutes = courseCustomReminderCustomerSwitch ? (course.customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes) : null
  const resourceEmailRemindersMinutes = courseCustomReminderStaffSwitch ? (course.resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes) : null
  const resourceRemindersEmailOtherRecipients = course.companySettings && course.companySettings.resourceRemindersEmailRecipients && course.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? course.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']
  const result = {
    name: {
      value: course.name || ''
    },
    description: {
      value: course.description || ''
    },
    color: {
      value: course.color || DEFAULT_COURSE_HEXCOLOR
    },
    price: {
      value: (course.price && course.price.toFixed(2)) || ''
    },
    duration: {
      value: course.duration || null
    },
    durationBefore: {
      value: course.durationBefore || null
    },
    durationAfter: {
      value: course.durationAfter || null
    },
    showDurationBeforeAndAfter: {
      value: course.durationBefore || course.durationAfter
    },
    splitDurationInIntervals: {
      value: course.isBatch
    },
    intervals: {
      values: course.durationsPattern && course.durationsPattern.length > 2 ? course.durationsPattern : [0, 0, 0]
    },
    hasAllResources: {
      value: !!course.hasAllResources
    },
    resources: {
      values: (!course.hasAllResources && course.resources && course.resources.map(resource => resource.id)) || [],
      options: (course.availableResources && course.availableResources.map(resource => ({
        value: resource.id,
        label: resource.name
      }))) || []
    },
    dependencies,
    isActive: {
      value: course.isBookable === undefined ? true : !!course.isBookable
    },
    hasOnlinePayment: {
      value: course.hasOnlinePayment || false
    },
    isOnlinePaymentMandatory: {
      value: course.isPaymentMandatory || false
    },
    orderIndex: {
      value: course.orderIndex
    },
    category: {
      value: course.category.id,
      type: 'hidden'
    },
    categoryName: {
      value: course.category.name
    },
    maxParticipants: {
      value: course.maxParticipants || ''
    },
    extraPersonsPerParticipant: {
      value: course.extraPersonsPerParticipant || ''
    },
    externalId: {
      value: course.externalId || ''
    },
    hasSpecificBookingDays: {
      value: !!course.hasSpecificBookingDays
    },
    courseCustomReminderCustomerSwitch: {
      value: courseCustomReminderCustomerSwitch
    },
    courseReminderCustomerSwitch: {
      value: customerEmailRemindersMinutes !== null ? (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0) : false
    },
    customerEmailRemindersMinutes: {
      values: (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length) ? customerEmailRemindersMinutes : [0]
    },
    courseCustomReminderStaffSwitch: {
      value: courseCustomReminderStaffSwitch
    },
    courseReminderStaffSwitch: {
      value: resourceEmailRemindersMinutes !== null ? (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) > 0 : false
    },
    resourceEmailRemindersMinutes: {
      values: (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) ? resourceEmailRemindersMinutes : [0]
    },
    courseReminderStaffOwnerCheckBox: {
      value: course.companySettings && course.companySettings.resourceRemindersEmailRecipients ? course.companySettings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
    },
    courseReminderStaffResourcesCheckBox: {
      value: course.companySettings && course.companySettings.resourceRemindersEmailRecipients ? course.companySettings.resourceRemindersEmailRecipients.includes('resource') : false
    },
    courseReminderStaffOthersCheckBox: {
      value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
    },
    courseReminderStaffOthers: {
      values: resourceRemindersEmailOtherRecipients || ['']
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }
  const firstDayOfWeek = getFirstDayOfWeek()
  const firstIndex = firstDayOfWeek === 1 ? 7 : 0

  // Flatten booking weekly allowance plan
  if (course.calendar && course.calendar.bookingWeeklyAllowancePlan) {
    (course.calendar.bookingWeeklyAllowancePlan || []).forEach((item, itemIndex) => {
      result[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
        isActive: !!item.isActive,
        values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
      }
    })
  }

  if (course.id) result.id = course.id

  return result
}

export const courseFormValidate = (course, stripeMinPrice, locale, currency, externalIds) => {
  if (!course) return
  const courseExternalId = course.externalId && course.externalId.value
  const courseCustomReminderCustomerSwitch = course.courseCustomReminderCustomerSwitch && course.courseCustomReminderCustomerSwitch.value
  const courseReminderCustomerSwitch = course.courseReminderCustomerSwitch && course.courseReminderCustomerSwitch.value
  const customerEmailRemindersMinutes = (course.customerEmailRemindersMinutes && course.customerEmailRemindersMinutes.values) || []
  const courseCustomReminderStaffSwitch = course.courseCustomReminderStaffSwitch && course.courseCustomReminderStaffSwitch.value
  const courseReminderStaffSwitch = course.courseReminderStaffSwitch && course.courseReminderStaffSwitch.value
  const courseReminderStaffOthers = (course.courseReminderStaffOthersCheckBox.value && course.courseReminderStaffOthers && course.courseReminderStaffOthers.values) || []
  const resourceEmailRemindersMinutes = (course.resourceEmailRemindersMinutes && course.resourceEmailRemindersMinutes.values) || []
  const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)

  const isSplitted = (course.splitDurationInIntervals && course.splitDurationInIntervals.value)

  const rules = [
    { 'externalId.value': ['max:255'] },
    { 'name.value': ['required', 'max:255'] },
    { 'maxParticipants.value': ['required', 'digits', 'max:32'] },
    { 'extraPersonsPerParticipant.value': ['digits', 'max:32'] },
    { 'duration.value': [`requiredIf:${!isSplitted}`] },
    { 'price.value': [`requiredIf:${!!course.hasOnlinePayment.value}`, 'price', 'max:32'] }
  ]

  const messages = {
    externalId: {
      max: 'errors.invalidMaxLength'
    },
    name: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    },
    maxParticipants: {
      required: 'errors.required',
      digits: 'errors.fillNumbers',
      max: 'errors.invalidMaxLength'
    },
    extraPersonsPerParticipant: {
      digits: 'errors.fillNumbers',
      max: 'errors.invalidMaxLength'
    },
    duration: {
      requiredIf: 'errors.services.chooseDuration'
    },
    description: {
      max: 'errors.invalidMaxLength'
    },
    price: {
      requiredIf: 'errors.required',
      price: 'errors.price.invalid',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    externalId: {
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '255'
      }
    },
    description: {
      max: {
        key: 'MAX',
        value: '400'
      }
    },
    maxParticipants: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.maxParticipants',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    },
    extraPersonsPerParticipant: {
      max: {
        key: 'MAX',
        value: '32'
      }
    },
    price: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.price.label',
        translateReplace: true
      },
      max: {
        key: 'MAX',
        value: '32'
      }
    }
  }

  let tabWithError = 'global.summary'
  const errors = validator(course, rules, messages, replaces)

  // ADVANCED VALIDATION

  // location
  if (!course.location.values?.length) {
    errors.push({
      key: 'location',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.location', translateReplace: true }]
    })
  }

  // external Id
  if (externalIds.includes(courseExternalId)) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  // Duration (max 60 days)
  if (course.duration && course.duration.value && course.duration.value > 60 * 24 * 60) {
    errors.push({ key: 'duration', value: 'errors.duration.limit' })
  }

  // Prep-followUp times (max 60 days)
  if (course.durationBefore && course.durationBefore.value && course.durationBefore.value > 60 * 24 * 60) {
    errors.push({ key: 'durationBefore', value: 'errors.duration.limit' })
  }
  if (course.durationAfter && course.durationAfter.value && course.durationAfter.value > 60 * 24 * 60) {
    errors.push({ key: 'durationAfter', value: 'errors.duration.limit' })
  }

  // Price 0 and online payment
  if (course.price && course.price.value && course.price.value.toString() === '0' && !!course.hasOnlinePayment.value) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.required',
      replace: [replaces.price.requiredIf]
    })
  }

  // Description without HTML max 400 characters
  if (course.description && course.description.value && extractFromHtml(removeMultipleSpaces(course.description.value)).trim().length > 400) {
    tabWithError = 'global.summary'
    errors.push({
      key: 'description',
      value: messages.description.max,
      replace: [replaces.description.max]
    })
  }

  if (!!course.hasOnlinePayment.value && stripeMinPrice && course.price && course.price.value && parseFloat(course.price.value) < stripeMinPrice) {
    const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
    tabWithError = 'global.summary'
    errors.push({
      key: 'price',
      value: 'errors.courses.stripeMinPrice',
      replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
    })
  }

  // Resources
  if (course.dependencies.values.length > 0) {
    course.dependencies.values.forEach((item, index) => {
      if (!item.categoryId) {
        tabWithError = 'global.summary'
        errors.push({
          key: 'dependencies',
          value: 'errors.servicesGroups.selectResourceCategory',
          index
        })
      }
      // else if (!item.resourceIds || item.resourceIds.length === 0) {
      //   errors.push({
      //     key: 'dependencies',
      //     value: 'errors.servicesGroups.selectResourceCategory',
      //     index
      //   })
      // }
    })
  }

  // Max participants
  if (course.maxParticipants.value && course.maxParticipants.value < 1) {
    errors.push({ key: 'participantsGlobalErrors', value: 'errors.maxParticipants' })
  }
  if (
    course.extraPersonsPerParticipant.value &&
    course.extraPersonsPerParticipant.value > 0 &&
    parseInt(course.extraPersonsPerParticipant.value) >= course.maxParticipants.value
  ) {
    errors.push({ key: 'participantsGlobalErrors', value: 'errors.extraParticipantsHasToBeLessThanMaxParticipants' })
  }

  // Intervals
  if (course.splitDurationInIntervals.value && course.intervals.values.includes(0)) {
    errors.push({
      key: 'intervals',
      value: 'errors.intervals.invalid',
      indexes: course.intervals.values.reduce((acc, item, index) => {
        if (item !== 0) return acc
        return [...acc, index]
      }, [])
    })
  }
  if (
    course.splitDurationInIntervals.value &&
    course.intervals.values &&
    course.intervals.values.reduce((sum, item) => sum + item, 0) > 60 * 24 * 60
  ) {
    errors.push({ key: 'intervals', value: 'errors.duration.limit' })
  }

  // course specific customer reminders
  if (courseCustomReminderCustomerSwitch && courseReminderCustomerSwitch) {
    customerEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'customerEmailRemindersMinutes',
          value: 'errors.reminder.incorrect',
          replace: [{ key: 'FIELD_NAME', value: 'global.customer', translateReplace: true }]
        })
      }
    })
  }

  // course specific staff reminders
  if (courseCustomReminderStaffSwitch && courseReminderStaffSwitch) {
    if (!course.courseReminderStaffOthersCheckBox.value && !course.courseReminderStaffResourcesCheckBox.value && !course.courseReminderStaffOwnerCheckBox.value) {
      tabWithError = 'global.advanced'
      errors.push({
        key: 'courseReminderStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    courseReminderStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'courseReminderStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (!item) errors.push({ key: 'courseReminderStaffOthers', value: 'errors.email.required' })
    })
    resourceEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        tabWithError = 'global.advanced'
        errors.push({
          key: 'resourceEmailRemindersMinutes',
          value: 'errors.reminder.incorrect',
          replace: [{ key: 'FIELD_NAME', value: 'global.resource', translateReplace: true }]
        })
      }
    })
  }

  if (errors && errors.length) {
    errors.push({
      key: 'globalErrors',
      value: 'errors.tabs.followingTabs',
      replace: [{ key: 'TAB_NAME', value: tabWithError, translateReplace: true }]
    })
  }

  return errors
}

export const courseSaveTransform = course => {
  const dependencies = course.dependencies.values.map(dependency => ({
    workInParallel: false,
    specificResourceCategoryIds: [dependency.categoryId],
    specificResourceIds: null
  }))
  const hasDescription = course.description && extractFromHtml(course.description.value)

  const result = {
    course: {
      id: course.id,
      categoryId: course.category.value,
      name: course.name && course.name.value,
      description: hasDescription ? course.description.value : null,
      dependencies,
      durationBefore: 0,
      durationAfter: 0,
      color: course.color && course.color.value,
      price: (course.price.value && normalizePrice(course.price.value)) || 0,
      isBookable: course.isActive && course.isActive.value,
      hasOnlinePayment: course.hasOnlinePayment && course.hasOnlinePayment.value,
      isPaymentMandatory: course.isOnlinePaymentMandatory && course.isOnlinePaymentMandatory.value,
      maxParticipants: course.maxParticipants && course.maxParticipants.value,
      extraPersonsPerParticipant: (course.extraPersonsPerParticipant && course.extraPersonsPerParticipant.value) || 0,
      externalId: course.externalId.value || null,
      customerEmailRemindersMinutes: !course.courseCustomReminderCustomerSwitch.value ? null : !course.courseReminderCustomerSwitch.value ? [] : course.customerEmailRemindersMinutes.values,
      resourceEmailRemindersMinutes: !course.courseCustomReminderStaffSwitch.value ? null : !course.courseReminderStaffSwitch.value ? [] : course.resourceEmailRemindersMinutes.values
    }
  }

  // Normal booking
  if (!course.splitDurationInIntervals.value) {
    result.course.duration = course.duration.value
  }
  // Batch booking
  if (course.splitDurationInIntervals.value) {
    result.course.durationsPattern = course.intervals.values
  }

  // Duration before and after
  if (course.showDurationBeforeAndAfter.value) {
    const durationBefore = course.durationBefore.value
    const durationAfter = course.durationAfter.value
    if (durationBefore) result.course.durationBefore = durationBefore
    if (durationAfter) result.course.durationAfter = durationAfter
  }

  if (course.location && course.location.values && !course.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...course.location.values,
        ...(course.location.insufficient || [])
      ]
    }
  }

  return result
}

// UPDATE

export const courseTransform = course => {
  if (!course) return
  const { error } = course || {}
  if (error) return
  const result = {
    ...course,
    isBatch: (course.durationsPattern || []).length > 1
  }
  if (course.calendar) result.hasSpecificBookingDays = true
  return result
}

export const courseRestoreFormTransform = course => {
  return {
    restoreOptions: { value: 'ALL' },
    externalId: { value: '' },
    serviceName: { value: '' },
    serviceColor: { value: '' },
    serviceDescription: { value: '' },
    durationSplitInterval: { value: '' },
    preparationTime: { value: '' },
    followupTime: { value: '' },
    resourcesAndDependencies: { value: '' },
    price: { value: '' },
    settingDisplayInBookingWidget: { value: '' },
    settingOnlinePayment: { value: '' },
    settingCheckboxToMakeOnlinePaymentMandatory: { value: '' },
    customerEmailReminders: { value: '' },
    resourceEmailReminders: { value: '' }
  }
}

// CATEGORY FORM

export const courseCategoryFormTransform = ({ category, branches, account }) => {
  category = category || {}
  branches = branches || []
  const allowedBranchExternalIds = category.allowedBranchExternalIds || []
  const allowedBranchIds = category.allowedBranchIds || []
  const { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  const {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !category.id
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []
  return {
    id: {
      value: category.id || ''
    },
    externalId: {
      value: category.externalId || ''
    },
    ownExternalId: category.externalId, // needed for external id validation
    name: {
      value: category.name || ''
    },
    isDefault: category.isDefault,
    location: {
      disabled: category.isDefault,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }
}

export const courseCategoryFormValidate = (category, externalIds) => {
  if (!category) return

  const rules = [
    { 'name.value': ['required'] }
  ]
  const messages = {
    name: {
      required: 'errors.required'
    }
  }
  const replaces = {
    name: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      }
    }
  }

  const errors = validator(category, rules, messages, replaces)

  // location
  if (!category.location.values?.length) {
    errors.push({
      key: 'location',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.location', translateReplace: true }]
    })
  }

  // external Id
  const externalIdValue = category.externalId && category.externalId.value
  externalIds = externalIds.filter(item => item !== category.ownExternalId)
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}

export const courseCategoryFormServerErrorsTransform = error => {
  const errors = []

  if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code) {
    errors.push({ key: 'name', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'courseCategoryGlobalError', value: 'errors.somethingWentWrong' })
  }
  return errors
}

export const courseServerErrorsTransform = ({ error, branchesList }) => {
  const errors = []

  if (error.code === 'GlobalServiceSaveError') {
    const { data } = error || {}
    let { localErrors } = data || {}
    localErrors = localErrors || []
    const branchesWithErrors = localErrors.map(item => {
      const company = branchesList.find(branch => branch.id === item.companyId) || {}
      return company.name
    }) || []
    let type
    if (localErrors[0] && localErrors[0].code) {
      if (localErrors[0].code.includes('Resource')) type = 'resource'
      if (localErrors[0].code.includes('Service')) type = 'course'
    }
    const errorMessage = dangerousHTML(`Local ${type} category does not exist in the following branches: 
      <br/><br/>
      ${branchesWithErrors.join(' <br/>')}
    `)
    errors.push({ key: 'globalCategoryErrors', value: errorMessage })
  } else if (error.code === 'ExternalIdConflicts') {
    errors.push({ key: 'externalId', value: translateServerCode(error.code) })
  } else if (error.code === 'InvalidBranchExternalIds') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else if (error.code === 'InvalidBranchIds') {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}

// CATEGORY SAVE
export const courseCategorySaveTransform = category => {
  const result = {
    category: {
      id: category.id && category.id.value,
      name: category.name.value,
      externalId: (category.externalId && category.externalId.value && category.externalId.value.trim() !== '')
        ? category.externalId.value
        : null
    }
  }
  if (category.location && category.location.values && !category.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...category.location.values,
        ...(category.location.insufficient || [])
      ]
    }
  }
  return result
}

export const courseLocallyChangedResetServerErrorsTransform = (error) => {
  const errors = []

  if (error.code === 'InvalidGlobalCourseReset') {
    errors.push({
      key: 'globalErrors',
      value: translateServerCode(error.code)
    })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }

  return errors
}
