import React from 'react'
import { handlers } from '../../../Store'
import { PopupBranchCustomerDelete } from '../../../Beauties'

export const PopupBranchCustomerDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    if (id) handlers.branchCustomerDelete(id)
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupBranchCustomerDelete approve={approve} reject={reject} />
  )
}

export default PopupBranchCustomerDeleteWrapper
