import { payloads$, actions, handlers } from '../../../Store'
import { q } from '../../API'

payloads$(actions.CHARGEBEE_PORTAL_OPEN)
  .subscribe(async (section) => {
    const chargebee = window.Chargebee
    if (!chargebee) return
    const result = await q('getEnterpriseChargebeeSelfPortal')
    if (!result) return
    let chargebeeInstance = chargebee.init({ site: 'terminapp-timify-eu-test' })
    chargebeeInstance.setPortalSession(() => new Promise(async (resolve) => resolve(result)))
    const portal = chargebeeInstance.createChargebeePortal()
    portal.openSection({
      sectionType: chargebee.getPortalSections().PAYMENT_SOURCES
    }, {
      close: () => {
        chargebeeInstance.logout()
        handlers.paymentMethodsGet()
      }
    })
  })
