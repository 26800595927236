import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Loader,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  t
} from '../../../Common'
import { SystemMessagesList } from '../../../Beauties'

import './SystemMessages.css'

const SystemMessages = props => {
  const {
    pending,
    systemMessages,
    loadMore,
    hasMore,
    hasAdvancedFilter,
    systemMessagesCount,
    toggleAdvancedOptions
  } = props
  const classNames = ['ta-notifications__system-messages']
  if (hasAdvancedFilter) classNames.push('has-advanced-filter')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-notifications__title'>
            <Title label={t('global.systemMessages')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Loader active={pending} />
          {(!pending &&
            <SystemMessagesList
              systemMessages={systemMessages}
              loadMore={loadMore}
              hasMore={hasMore}
              hasAdvancedFilter={hasAdvancedFilter}
              systemMessagesCount={systemMessagesCount}
              toggleAdvancedOptions={toggleAdvancedOptions}
            />
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

SystemMessages.propTypes = {
  pending: PropTypes.bool.isRequired
}

export default SystemMessages
