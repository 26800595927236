import React, { useEffect } from 'react'
import { ResourceCategoriesToDelete } from '../../Beauties'
import { Loader } from '../../Common'
import { connect, handlers } from '../../Store'

const ResourceCategoriesToDeleteWrapper = props => {
  let { resourceCategoriesToDelete, branches, pending, account } = props
  resourceCategoriesToDelete = resourceCategoriesToDelete || []
  branches = branches || []
  pending = !!pending
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalResourceCategoriesRead } = enterprisePermissions || {}
  globalResourceCategoriesRead = !!isAdmin || !!globalResourceCategoriesRead
  useEffect(() => {
    if (!globalResourceCategoriesRead) handlers.navigateToPath('/management/resource-categories')
  }, [globalResourceCategoriesRead])
  const resourceCategoriesToDeleteData = resourceCategoriesToDelete.map(item => ({
    resourceCategory: item,
    branch: branches.find(branch => branch.id === item.companyId)
  }))
  const onClickLogin = async (branch, isCustomisationIgnored) => {
    handlers.branchesLogin(branch?.id, isCustomisationIgnored)
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ResourceCategoriesToDelete
          resourceCategoriesToDeleteData={resourceCategoriesToDeleteData}
          onClickLogin={onClickLogin}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  resourceCategoriesToDelete: state.resources.categoriesListToDelete,
  pending: state.resources.pendingCategoriesToDeleteList,
  account: state.account
})

export default connect(maps)(ResourceCategoriesToDeleteWrapper)
