import React from 'react'

const TableRowMainColContent = props => {
  const { children } = props

  return (
    <div className='ta-table-row__main-col__content'>
      {children}
    </div>
  )
}

export default TableRowMainColContent
