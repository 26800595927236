import React, { Component } from 'react'
import { connect, handlers, store } from '../../Store'
import { CustomerFields, CustomerFieldsMock } from '../../Beauties'
import { sortByOrderIndex } from '../../Utils'
import { Loader } from '../../Common'

class CustomerFieldsWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onDragStart = this.onDragStart.bind(this)
    this.onDragUpdate = this.onDragUpdate.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onCategoriesToggle = this.onCategoriesToggle.bind(this)
    this.state = { areExpanded: true }
  }

  componentDidMount () {
    const { areFetched = false } = store.getState().customerFields
    !areFetched && handlers.customerFieldsGet()
  }

  onCategoriesToggle () {
    this.setState(prevState => ({ areExpanded: !prevState.areExpanded }))
  }

  onDragStart (res) {
  }

  onDragUpdate (res) {
  }

  onDragEnd ({ type, id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) {
    if (type === 'category') {
      handlers.customerFieldCategoryOrderChange({ id, oldIndex, newIndex })
      return
    }

    handlers.customerFieldsListOrderChange({
      id,
      oldIndex,
      newIndex,
      sourceCategoryId,
      destinationCategoryId
    })
  }

  render () {
    const { areExpanded } = this.state
    let {
      categories,
      pending,
      route,
      account,
      message,
      hash
    } = this.props
    categories = categories || []
    pending = !!pending
    account = account || {}
    let { isAdmin, enterprisePermissions } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { globalDataFieldsRead, globalDataFieldsWrite } = enterprisePermissions || {}
    globalDataFieldsRead = !!isAdmin || !!globalDataFieldsRead
    globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite
    const isDragAndDropMode = !!(route === 'customerFieldsReorder')
    const globalCategories = categories.filter(item => item.internalId && !item.isDefault)
    const defaultCategory = categories.find(item => item.isDefault)
    const sortedCategories = [defaultCategory, ...globalCategories].filter(Boolean)

    return globalDataFieldsRead
      ? pending
        ? <Loader active />
        : (
          <CustomerFields
            customerFieldsWrite={globalDataFieldsWrite}
            categories={sortedCategories}
            pending={pending}
            isDragAndDropMode={isDragAndDropMode}
            onDragStart={this.onDragStart}
            onDragUpdate={this.onDragUpdate}
            onDragEnd={this.onDragEnd}
            areExpanded={areExpanded}
            onCategoriesToggle={this.onCategoriesToggle}
            message={message}
            hash={hash}
          />
          )
      : <CustomerFieldsMock />
  }
}

const maps = state => ({
  route: state.router.name,
  hash: state.router.hash,
  categories: (state.customerFields.categoriesList || []).sort(sortByOrderIndex),
  pending: state.customerFields.pendingList,
  account: state.account,
  message: state.customerFields.messageList
})

export default connect(maps)(CustomerFieldsWrapper)
