import {
  tokensFields,
  enterpriseUsersListFields,
  enterpriseUsersFields,
  enterpriseServicesFields,
  enterpriseServicesCategoryFields,
  enterpriseTagsListFields,
  enterpriseTagsFields,
  enterpriseSettingsFields,
  branchesListFields,
  branchesFields,
  branchPreviewDetailsFields,
  branchPreviewResourcesFields,
  branchPreviewServicesFields,
  branchPreviewGroupsFields,
  branchPreviewStatisticsFields,
  branchPreviewStatisticsV2Fields,
  branchHistoryStatisticsFields,
  appFields,
  appPreviewFields,
  appSubscriptionFields,
  enterpriseStatisticsFields,
  enterpriseStatisticsV2Fields,
  enterpriseStatisticsV2FieldsOverall,
  accountFields,
  userFields,
  companyTag,
  enterpriseUserProfileFields,
  enterpriseProfileFields,
  profileFields,
  enterpriseCustomerFields,
  enterpriseCustomerFieldCategoryFields,
  customerFieldCategoryFields,
  branchResourceFields,
  branchServiceFields,
  branchCourseFields,
  branchCustomerFields,
  branchCustomerFieldFields,
  branchCustomeCategoiresFields,
  branchPreviewStatisticsV2FieldsTotalEvents,
  branchPreviewStatisticsV2FieldsTotalOnlineEvents,
  branchPreviewStatisticsV2FieldsTotalInternalEvents,
  branchPreviewStatisticsV2FieldsTotalCancelledEvents,
  branchPreviewStatisticsV2FieldsSickDenialsStatistics,
  branchPreviewStatisticsV2FieldsVacationDenialsStatistics,
  branchPreviewStatisticsV2FieldsBlockerDenialsStatistics,
  branchPreviewStatisticsV2FieldsTotalResourcesWorkingTimes,
  branchPreviewStatisticsV2FieldsTotalResourcesWorkingBookedTimes,
  branchPreviewStatisticsV2FieldsTotalResourcesWorkingOnlineAvailabilityTimes,
  branchPreviewStatisticsV2FieldsOverall,
  branchPreviewStatisticsV2FieldsOverallTotalEvents,
  branchPreviewStatisticsV2FieldsOverallTotalOnlineEvents,
  branchPreviewStatisticsV2FieldsOverallTotalInternalEvents,
  branchPreviewStatisticsV2FieldsTotalResourcesOnlineBookedTimes,
  branchPreviewStatisticsV2FieldsTotalResourcesOnlineTimes,
  branchPreviewStatisticsV2FieldsSingleBookings,
  branchPreviewStatisticsV2FieldsCourseBookings,
  customerFieldsForList,
  customerFields,
  resourceCategoryFields,
  courseFields,
  courseCategoryFields,
  paymentMethodFields,
  invoiceFields,
  chargebeeAccountData,
  userPermissionsGroupsEnterpriseFields,
  permissionType,
  activityLogFields,
  systemMessagesFields
} from './fields'

// Queries

export const getStaticData = `
  query getStaticData {
    getAllBusinessSectors { code }
    getAllTimezones { code label }
    getAllLocales {
      code
      country {
        code
        label
        translationKey
        timezones
      }
      language {
        code
        label
        translationKey
      }
    }
    getAllCountries {
      stripeMinAmountOnlinePaymentsCountryCurrency
      code
      label
      currency {
        code
        symbol
        digits
      }
    }
    getStaticData {
      publicHolidaysData {
        id
        uniqueKey
        countryName
        countryCode
        stateName
        stateCode
      }
    }
  }
`
export const getEnterpriseUsers = `
  query getEnterpriseUsers {
    getEnterpriseUsers ${enterpriseUsersListFields}
  }
`
export const getEnterpriseUser = `
  query getEnterpriseUser($id: ID!) {
    getEnterpriseUser(id: $id) ${enterpriseUsersFields}
  }
`
export const getEnterpriseTags = `
  query getEnterpriseTags {
    getEnterpriseTags ${enterpriseTagsListFields}
  }
`
export const getEnterpriseTag = `
  query getEnterpriseTag($id: ID!) {
    getEnterpriseTag(id: $id) ${enterpriseTagsFields}
  }
`
export const getBranches = `
  query getBranches($filter: BranchCompaniesFilter) {
    getBranches(filter: $filter) ${branchesListFields}
  }
`
export const getBranch = `
  query getBranch($companyId: ID!, $region: Region!) {
    getBranch(companyId: $companyId, region: $region) ${branchesFields}
  }
`
export const getBranchPreviewDetails = `
  query getBranchPreviewDetails($companyId: ID!, $region: Region!) {
    getBranch(companyId: $companyId, region: $region) ${branchPreviewDetailsFields}
  }
`
export const getBranchResources = `
  query getBranchResources($companyId: ID!, $region: Region!) {
    getBranch(companyId: $companyId, region: $region) ${branchPreviewResourcesFields}
  }
`
export const getBranchServices = `
  query getBranchServices($companyId: ID!, $region: Region!) {
    getBranch(companyId: $companyId, region: $region) ${branchPreviewServicesFields}
  }
`
export const getBranchGroups = `
  query getBranchGroups($companyId: ID!, $region: Region!) {
    getBranch(companyId: $companyId, region: $region) ${branchPreviewGroupsFields}
  }
`
export const getBranchCustomers = `
  query getBranchCustomers($companyId: ID!, $region: Region, $filter: CustomersFilter, $sort: CustomersSort, $pagination: Pagination) {
    getBranchCustomers(companyId: $companyId, region: $region, filter: $filter, sort: $sort, pagination: $pagination) ${branchCustomerFields}
  }
`
export const getBranchCustomerFields = `
  query getBranchCustomerFields($companyId: ID!, $region: Region, $filter: CustomerFieldsFilter) {
    getBranchCustomerFields(companyId: $companyId, region: $region, filter: $filter) ${branchCustomerFieldFields}
  }
`
export const getBranchCustomerFieldCategories = `
  query getBranchCustomerFieldCategories($companyId: ID!, $region: Region, $filter: CustomerFieldCategoriesFilter) {
    getBranchCustomerFieldCategories(companyId: $companyId, region: $region, filter: $filter) ${branchCustomeCategoiresFields}
  }
`
export const getBranchStatistics = `
  query getBranchStatistics($companyId: ID!, $region: Region!) {
    getBranch(companyId: $companyId, region: $region) ${branchPreviewStatisticsFields}
  }
`
export const getBranchStatisticsV2 = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2Fields}
  }
`

export const getBranchStatisticsV2Overall = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsOverall}
  }
`
export const getBranchStatisticsV2OverallTotalEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsOverallTotalEvents}
  }
`
export const getBranchStatisticsV2OverallTotalOnlineEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsOverallTotalOnlineEvents}
  }
`
export const getCompanyStatisticsV2OverallTotalInternalEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsOverallTotalInternalEvents}
  }
`

export const getBranchStatisticsV2TotalEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalEvents}
  }
`
export const getBranchStatisticsV2TotalOnlineEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalOnlineEvents}
  }
`
export const getBranchStatisticsV2TotalInternalEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalInternalEvents}
  }
`
export const getBranchStatisticsV2TotalCancelledEvents = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalCancelledEvents}
  }
`
export const getBranchStatisticsV2SickDenialsStatistics = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsSickDenialsStatistics}
  }
`
export const getBranchStatisticsV2VacationDenialsStatistics = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsVacationDenialsStatistics}
  }
`
export const getBranchStatisticsV2BlockerDenialsStatistics = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsBlockerDenialsStatistics}
  }
`
export const getBranchStatisticsV2TotalResourcesWorkingTimes = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalResourcesWorkingTimes}
  }
`
export const getBranchStatisticsV2TotalResourcesWorkingBookedTimes = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalResourcesWorkingBookedTimes}
  }
`
export const getBranchStatisticsV2TotalResourcesWorkingOnlineAvailabilityTimes = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalResourcesWorkingOnlineAvailabilityTimes}
  }
`
export const getBranchStatisticsV2TotalResourcesOnlineBookedTimes = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalResourcesOnlineBookedTimes}
  }
`

export const getBranchStatisticsV2TotalResourcesOnlineTimes = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsTotalResourcesOnlineTimes}
  }
`
export const getBranchStatisticsV2SingleBookings = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsSingleBookings}
  }
`
export const getBranchStatisticsV2GroupBookings = `
  query getBranchStatisticsV2($companyId: ID!, $region: Region, $filter: CompanyStatisticsFilter!, $partialStatsFilter: PartialCompanyStatisticsFilter) {
    getBranchStatisticsV2(companyId: $companyId, region: $region, filter: $filter) ${branchPreviewStatisticsV2FieldsCourseBookings}
  }
`

export const getStatisticsLastStatisticsStateChange = `
  query getStatisticsLastStatisticsStateChange {
    getAccount {
      enterprise { lastStatisticsStateChange }
    }
  }
`

export const getCompanyHistoryStatisticsAdmin = `
  query getCompanyHistoryStatisticsAdmin($companyId: ID, $region: Region, $filter: CompanyHistoryStatisticsFilter, $sort: CompanyHistoryStatisticsSort, $pagination: Pagination) {
    getCompanyHistoryStatisticsAdmin(companyId: $companyId, region: $region, filter: $filter, sort: $sort, pagination: $pagination) ${branchHistoryStatisticsFields}
  }
`
export const getEnterpriseStatistics = `
  query getEnterpriseStatistics {
    getEnterpriseStatistics ${enterpriseStatisticsFields}
  }
`
export const getEnterpriseStatisticsV2 = `
  query getEnterpriseStatisticsV2($filter: EnterpriseStatisticsFilter!) {
    getEnterpriseStatisticsV2(filter: $filter) ${enterpriseStatisticsV2Fields}
  }
`
export const getEnterpriseStatisticsV2Overall = `
query getEnterpriseStatisticsV2($filter: EnterpriseStatisticsFilter!) {
  getEnterpriseStatisticsV2(filter: $filter) ${enterpriseStatisticsV2FieldsOverall}
}
`
export const getEnterpriseStatisticsV2TotalEvents = `
  query getEnterpriseStatisticsV2($filter: EnterpriseStatisticsFilter!, $filterWithTag: PartialCompanyStatisticsFilter ) {
    getEnterpriseStatisticsV2(filter: $filter) {
      totalEvents(filter: $filterWithTag)
    }
  }
`

export const getEnterpriseStatisticsV2TotalCancelledEvents = `
  query getEnterpriseStatisticsV2($filter: EnterpriseStatisticsFilter!, $filterWithTag: PartialCompanyStatisticsFilter ) {
    getEnterpriseStatisticsV2(filter: $filter) {
      totalCancelledEvents(filter: $filterWithTag)
    }
  }
`

export const getEnterpriseStatisticsV2TotalOnlineEvents = `
  query getEnterpriseStatisticsV2($filter: EnterpriseStatisticsFilter!, $filterWithTag: PartialCompanyStatisticsFilter ) {
    getEnterpriseStatisticsV2(filter: $filter) {
      totalOnlineEvents(filter: $filterWithTag)
    }
  }
`

export const getEnterpriseStatisticsV2TotalInternalEvents = `
  query getEnterpriseStatisticsV2($filter: EnterpriseStatisticsFilter!, $filterWithTag: PartialCompanyStatisticsFilter ) {
    getEnterpriseStatisticsV2(filter: $filter) {
      totalInternalEvents(filter: $filterWithTag)
    }
  }
`

export const getEnterpriseApps = `
  query getEnterpriseApps($locale: Locale!) {
    getEnterpriseApps(locale: $locale) ${appFields}
  }
`
export const getEnterpriseAppWithToken = `
  query getEnterpriseAppWithToken($locale: Locale!, $id: ID!) {
    getEnterpriseApp(locale: $locale, id: $id) ${appPreviewFields}
    getAppAccessToken(appId: $id) { refreshToken }
  }
`
export const getAppAccessToken = `
  query getAppAccessToken($id: ID!) {
    getAppAccessToken(appId: $id) { refreshToken }
  }
`
export const generateEnterprisePrivateAppsOneTimeCode = `
  mutation generateEnterprisePrivateAppsOneTimeCode {
    generateEnterprisePrivateAppsOneTimeCode
  }
`
export const installEnterpriseApp = `
  mutation installEnterpriseApp($appId: ID!, $appExternalId: ID, $isPaidByEnterprise: Boolean) {
    installEnterpriseApp(appId: $appId, appExternalId: $appExternalId, isPaidByEnterprise: $isPaidByEnterprise)
  }
`
export const uninstallEnterpriseApp = `
  mutation uninstallEnterpriseApp($appId: ID!, $appExternalId: ID, $keepData: Boolean, $password: String) {
    uninstallEnterpriseApp(appId: $appId, appExternalId: $appExternalId, keepData: $keepData, password: $password)
  }
`
export const getAccount = `
  query getAccount {
    getAccount ${accountFields}
  }
`
export const refreshTokens = `
  query refreshTokens($refreshToken: ID!) {
    refreshTokens(refreshToken: $refreshToken) ${tokensFields}
  }
`
export const selectAccount = `
  query selectAccount($accountId: ID!, $type: Auth2FAType, $code: ID) {
    selectAccount(accountId: $accountId, type: $type, code: $code) ${tokensFields}
  }
`

export const getEnterpriseCustomerFields = `
  query getEnterpriseCustomerFields($filter: CustomerFieldsFilter) {
    getEnterpriseCustomerFields(filter: $filter) ${enterpriseCustomerFields}
  }
`

export const getEnterpriseCustomerField = `
  query getEnterpriseCustomerField($id: ID!) {
    getEnterpriseCustomerField(id: $id) ${enterpriseCustomerFields}
  }
`

export const getCustomerFieldsAndCategories = `
  query getCustomerFieldsAndCategories {
    getEnterpriseCustomerFieldCategories ${enterpriseCustomerFieldCategoryFields}
    getEnterpriseCustomerFields ${enterpriseCustomerFields}
  }
`
export const getEnterpriseAvailableCustomerLetters = `
query Query {
  getEnterpriseAvailableCustomerLetters
}
`

export const getEnterpriseCustomerFieldCategory = `
  query getEnterpriseCustomerFieldCategory($id: ID, $externalId: ID) {
    getEnterpriseCustomerFieldCategory(id: $id, externalId: $externalId) ${enterpriseCustomerFieldCategoryFields}
  }
`

export const getEnterpriseServicesAndServiceCategories = `
  query getEnterpriseServicesAndServiceCategories {
    getEnterpriseServices ${enterpriseServicesFields}
    getEnterpriseServiceCategories ${enterpriseServicesCategoryFields}
  }
`

export const getEnterpriseServiceCategory = `
  query getEnterpriseServiceCategory($id: ID, $externalId: ID) {
    getEnterpriseServiceCategory(id: $id, externalId: $externalId) ${enterpriseServicesCategoryFields}
  }
`

export const getEnterpriseCoursesAndCourseCategories = `
  query getEnterpriseCoursesAndCourseCategories {
    getEnterpriseCourses ${courseFields}
    getEnterpriseCourseCategories ${courseCategoryFields}
  }
`

export const getEnterpriseServices = `
  query getEnterpriseServices($filter: ServicesFilter, $sort: ServicesSort, $pagination: Pagination) {
    getEnterpriseServices(filter: $filter, sort: $sort, pagination: $pagination) ${enterpriseServicesFields}
  }
`

export const getEnterpriseService = `
  query getEnterpriseService($id: ID, $externalId: ID) {
    getEnterpriseService(id: $id, externalId: $externalId) ${enterpriseServicesFields}
  }
`

export const saveEnterpriseServiceCategory = `
  mutation saveEnterpriseServiceCategory($category: ServiceCategoryPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseServiceCategory(category: $category, globalFilterConfig: $globalFilterConfig) ${enterpriseServicesCategoryFields}
  }
`

export const deleteEnterpriseService = `
  mutation deleteEnterpriseService($id: ID!, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseService(id: $id, globalFilterConfig: $globalFilterConfig)
  }
`

export const deleteEnterpriseServiceCategory = `
  mutation deleteEnterpriseServiceCategory($id: ID!, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseServiceCategory(id: $id, globalFilterConfig: $globalFilterConfig)
  }
`

export const getEnterpriseServiceCategories = `
  query getEnterpriseServiceCategories($filter: ServiceCategoriesFilter) {
    getEnterpriseServiceCategories(filter: $filter) ${enterpriseServicesCategoryFields}
  }
`

export const getEnterpriseCourses = `
  query getEnterpriseCourses($filter: ServicesFilter, $sort: ServicesSort, $pagination: Pagination) {
    getEnterpriseCourses(filter: $filter, sort: $sort, pagination: $pagination) ${courseFields}
  }
`

export const getEnterpriseCourseCategories = `
  query getEnterpriseCourseCategories($filter: ServiceCategoriesFilter) {
    getEnterpriseCourseCategories(filter: $filter) ${courseCategoryFields}
  }
`

export const getEnterpriseCustomers = `
  query getEnterpriseCustomers($filter: CustomersFilter, $sort:CustomersSort, $pagination:Pagination) {
    getEnterpriseCustomers(filter: $filter, sort: $sort, pagination: $pagination) ${customerFields}
  }
`

export const getEnterpriseCustomersWithTotal = `
  query getEnterpriseCustomersWithTotal($filter: CustomersFilter, $sort:CustomersSort, $pagination:Pagination) {
    getEnterpriseCustomersWithTotal(filter: $filter, sort: $sort, pagination: $pagination) ${customerFieldsForList}
  }
`

export const getEnterpriseCustomer = `
  query getEnterpriseCustomer($id: ID, $externalId: ID) {
    getEnterpriseCustomer(id: $id, externalId: $externalId) ${customerFields}
  }
`

export const getEnterpriseCustomersForList = `
  query getEnterpriseCustomersForList($id: ID!) {
    getEnterpriseCustomers(id: $id) ${customerFieldsForList}
  }
`

export const getEnterpriseResourceCategories = `
  query getEnterpriseResourceCategories($filter: ResourceCategoriesFilter, $sort: ResourcesSort, $pagination: Pagination) {
    getEnterpriseResourceCategories(filter: $filter, sort: $sort, pagination: $pagination) ${resourceCategoryFields}
  }
`

export const getEnterpriseResourceCategory = `
  query getEnterpriseResourceCategory($id: ID, $externalId: ID) {
    getEnterpriseResourceCategory(id: $id, externalId: $externalId) ${resourceCategoryFields}
  }
`

export const getEnterpriseCourse = `
  query getEnterpriseCourse($id: ID, $externalId: ID) {
    getEnterpriseCourse(id: $id, externalId: $externalId) ${courseFields}
  }
`

export const getEnterpriseCompanyTags = `
  query getEnterpriseCompanyTags($filter: CompanyTagsFilter) {
    getEnterpriseCompanyTags(filter: $filter) ${companyTag}
  }
`

export const getEnterpriseCompanyTag = `
  query getEnterpriseCompanyTag($id: ID!) {
    getEnterpriseCompanyTag(id: $id) ${companyTag}
  }
`

export const getEnterpriseChargebeeSelfPortal = `
  query getEnterpriseChargebeeSelfPortal {
    getEnterpriseChargebeeSelfPortal
  }
`
export const getEnterprisePaymentMethods = `
  query getEnterprisePaymentMethods {
    getEnterprisePaymentMethods ${paymentMethodFields}
  }
`
export const getEnterpriseChargebeeInvoices = `
  query getEnterpriseChargebeeInvoices {
    getEnterpriseChargebeeInvoices ${invoiceFields}
  }
`
export const getEnterpriseInvoiceUrl = `
  query getEnterpriseInvoiceUrl($id: ID!) {
    getEnterpriseInvoiceUrl(id: $id)
  }
`
export const getPermissionsGroupsEnterprise = `
  query getPermissionsGroupsEnterprise($filter: PermissionsGroupEnterpriseFilter) {
    getPermissionsGroupsEnterprise(filter: $filter) ${userPermissionsGroupsEnterpriseFields}
  }
`

export const getPermissionsGroupEnterprise = `
  query getPermissionsGroupEnterprise($id: ID!) {
    getPermissionsGroupEnterprise(id: $id) ${userPermissionsGroupsEnterpriseFields}
  }
`

// Mutations

export const loginEnterpriseAccountWithEmailAndPassword = `
  mutation loginEnterpriseAccountWithEmailAndPassword($email: String!, $password: String!, $longSession: Boolean, $locale: Locale){
    loginEnterpriseAccountWithEmailAndPassword(email: $email, password: $password, locale: $locale, longSession: $longSession) ${tokensFields}
  }
`
export const saveEnterpriseUser = `
  mutation saveEnterpriseUser($user: EnterpriseUserPayload!) {
    saveEnterpriseUser(user: $user) ${enterpriseUsersFields}
  }
`
export const deleteEnterpriseUser = `
  mutation deleteEnterpriseUser($id: ID!) {
    deleteEnterpriseUser(id: $id)
  }
`
export const deleteEnterprise = `
  mutation deleteEnterprise($id: ID!) {
    deleteEnterprise(id: $id)
  }
`
export const deleteEnterpriseTag = `
  mutation deleteEnterpriseTag($id: ID!) {
    deleteEnterpriseTag(id: $id)
  }
`
export const saveEnterpriseTag = `
  mutation saveEnterpriseTag($tag: EnterpriseTagPayload!) {
    saveEnterpriseTag(tag: $tag) ${enterpriseTagsFields}
  }
`

export const saveEnterpriseCompanyTag = `
mutation saveEnterpriseCompanyTag($tag: CompanyTagPayload!, $includeUpdatedLocally: Boolean, $globalFilterConfig: GlobalFilterConfig) {
  saveEnterpriseCompanyTag(tag: $tag, includeUpdatedLocally: $includeUpdatedLocally, globalFilterConfig: $globalFilterConfig) ${companyTag}
  }
`

export const deleteEnterpriseCompanyTag = `
mutation deleteEnterpriseCompanyTag($id: ID!, $globalFilterConfig: GlobalFilterConfig) {
  deleteEnterpriseCompanyTag(id: $id, globalFilterConfig: $globalFilterConfig)
  }
`
export const createBranch = `
  mutation createBranch($branch: BranchNewCompanyPayload!) {
    createBranch(branch: $branch) ${branchPreviewDetailsFields}
  }
`
export const updateBranch = `
  mutation updateBranch($companyId: ID!, $region: Region!, $branch: BranchExistingCompanyPayload!) {
    updateBranch(companyId: $companyId, region: $region, branch: $branch) ${branchPreviewDetailsFields}
  }
`
export const deleteBranch = `
  mutation deleteBranch($companyId: ID!, $region: Region!, $password: String!) {
    deleteBranch(companyId: $companyId, region: $region, password: $password)
  }
`
export const loginCompanyWithEnterpriseToken = `
  mutation loginCompanyWithEnterpriseToken($companyId: ID!, $region: Region!, $isCustomisationIgnored: Boolean) {
    loginCompanyWithEnterpriseToken(companyId: $companyId, region: $region, isCustomisationIgnored: $isCustomisationIgnored)
  }
`

export const loginCallCentreWithEnterpriseToken = `
  mutation loginCallCentreWithEnterpriseToken{
    loginCallCentreWithEnterpriseToken
  }
`

export const saveUserData = `
  mutation saveUserData($userData: UserData!) {
    saveUserData(userData: $userData) ${userFields}
  }
`
export const changeEnterpriseUserEmail = `
  mutation changeEnterpriseUserEmail($email: String!, $password: String!) {
    changeEnterpriseUserEmail(email: $email, password: $password) ${profileFields}
  }
`
export const cancelChangeEnterpriseUserEmail = `
  mutation cancelChangeEnterpriseUserEmail($password: String!) {
    cancelChangeEnterpriseUserEmail(password: $password)
  }
`
export const unlinkConnectedAccountToEnterprise = `
  mutation unlinkConnectedAccountToEnterprise($enterpriseId: ID!) {
    unlinkConnectedAccountToEnterprise(enterpriseId: $enterpriseId)
  }
`
export const unlinkConnectedAccountToCompanyResource = `
  mutation unlinkConnectedAccountToCompanyResource($companyId: ID!) {
    unlinkConnectedAccountToCompanyResource(companyId: $companyId)
  }
`
export const saveEnterpriseUserProfile = `
  mutation saveEnterpriseUserProfile($user: EnterpriseUserProfilePayload!) {
    saveEnterpriseUserProfile(user: $user) ${enterpriseUserProfileFields}
  }
`
export const saveEnterpriseProfile = `
  mutation saveEnterpriseProfile($enterprise: EnterpriseProfilePayload!) {
    saveEnterpriseProfile(enterprise: $enterprise) ${enterpriseProfileFields}
  }
`
export const saveEnterpriseSettings = `
  mutation saveEnterpriseSettings($settings: EnterpriseSettingsPayload!) {
    saveEnterpriseSettings(settings: $settings) ${enterpriseSettingsFields}
  }
`

export const getTokensWithOneTimeLoginToken = `
  query getTokensWithOneTimeLoginToken($authScope: AuthScope!, $loginToken: String!) {
    getTokensWithOneTimeLoginToken(
      authScope: $authScope
      loginToken: $loginToken
      longSession: false
    ) ${tokensFields}
  }
`

export const exportTimezonesTemplate = `
  query exportTimezonesTemplate($fileTypeName: FileTypeName) {
    exportTimezonesTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportBusinessSectorsTemplate = `
  query exportBusinessSectorsTemplate($fileTypeName: FileTypeName) {
    exportBusinessSectorsTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportBranchSettingsTemplate = `
  query exportBranchSettingsTemplate($fileTypeName: FileTypeName) {
    exportBranchSettingsTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportBranchesTemplate = `
  query exportBranchesTemplate($fileTypeName: FileTypeName) {
    exportBranchesTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportServicesTemplate = `
  query exportServicesTemplate($fileTypeName: FileTypeName) {
    exportServicesTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportServiceCategoriesTemplate = `
  query exportServiceCategoriesTemplate($fileTypeName: FileTypeName) {
    exportServiceCategoriesTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportResourcesTemplate = `
  query exportResourcesTemplate($fileTypeName: FileTypeName) {
    exportResourcesTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportResourceCategoriesTemplate = `
  query exportResourceCategoriesTemplate($fileTypeName: FileTypeName) {
    exportResourceCategoriesTemplate(fileTypeName: $fileTypeName)
  }
`

export const exportCompanyBookingsEnterprise = `
  query exportCompanyBookingsEnterprise($exportTemplateCode: ExportTemplateCode!, $fileTypeName: FileTypeName, $dateFrom: DateTime, $dateTo: DateTime) {
    exportCompanyBookingsEnterprise(exportTemplateCode: $exportTemplateCode, fileTypeName: $fileTypeName, dateFrom: $dateFrom, dateTo: $dateTo)
  }
`

export const sendEmailConfirmationCode = `
  mutation sendEmailConfirmationCode($accountId: ID!, $email: String!) {
    sendEmailConfirmationCode(accountId: $accountId, authScope: ENTERPRISE, email: $email)
  }
`

export const sendUserVerificationEmailEnterprise = `
  mutation sendUserVerificationEmailEnterprise($userId: ID!) {
    sendUserVerificationEmailEnterprise(userId: $userId)
  }
`

export const sendPasswordResetCodeEnterprise = `
  mutation sendPasswordResetCodeEnterprise($email: String!, $name: String) {
    sendPasswordResetCodeEnterprise(email: $email, name: $name)
  }
`

export const sendBranchAccountConfirmationEmail = `
  mutation sendBranchAccountConfirmationEmail($companyId: ID!, $region: Region!, $userId: ID!) {
    sendBranchAccountConfirmationEmail(companyId: $companyId, region: $region, userId: $userId)
  }
`

export const confirmBranchUserAccount = `
  mutation confirmBranchUserAccount($companyId: ID!, $region: Region!, $userId: ID, $email: String) {
    confirmBranchUserAccount(companyId: $companyId, region: $region, userId: $userId, email: $email)
  }
`

export const deleteBranchResource = `
  mutation deleteBranchResource($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchResource(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const deleteBranchService = `
  mutation deleteBranchService($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchService(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const deleteBranchCourse = `
  mutation deleteBranchCourse($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchCourse(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const deleteBranchCustomer = `
  mutation deleteBranchCustomer($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchCustomer(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const saveBranchResource = `
  mutation saveBranchResource($companyId: ID!, $region: Region, $resource: ResourcePayload!) {
    saveBranchResource(companyId: $companyId, region: $region, resource: $resource) ${branchResourceFields}
  }
`

export const saveBranchService = `
  mutation saveBranchService($companyId: ID!, $region: Region, $service: ServicePayload!) {
    saveBranchService(companyId: $companyId, region: $region, service: $service) ${branchServiceFields}
  }
`

export const saveBranchCourse = `
  mutation saveBranchCourse($companyId: ID!, $region: Region, $course: ServicePayload!) {
    saveBranchCourse(companyId: $companyId, region: $region, course: $course) ${branchCourseFields}
  }
`

export const saveBranchCustomer = `
  mutation saveBranchCustomer($companyId: ID!, $region: Region, $customer: CustomerPayload!) {
    saveBranchCustomer(companyId: $companyId, region: $region, customer: $customer) ${branchCustomerFields}
  }
`

export const refreshCompanyStatisticsAdmin = `
  mutation refreshCompanyStatisticsAdmin($companyId: ID!, $region: Region!) {
    refreshCompanyStatisticsAdmin(companyId: $companyId, region: $region)
  }
`

export const refreshEnterpriseStatisticsAdmin = `
  mutation refreshEnterpriseStatisticsAdmin($enterpriseId: ID!) {
    refreshEnterpriseStatisticsAdmin(enterpriseId: $enterpriseId)
  }
`

export const saveEnterpriseCustomerFieldCategory = `
  mutation saveEnterpriseCustomerFieldCategory($category: CustomerFieldCategoryPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseCustomerFieldCategory(category: $category, globalFilterConfig: $globalFilterConfig) ${customerFieldCategoryFields}
  }
`

export const deleteEnterpriseCustomerFieldCategory = `
  mutation deleteEnterpriseCustomerFieldCategory($id: ID!, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseCustomerFieldCategory(id: $id, externalId: $externalId, globalFilterConfig: $globalFilterConfig)
  }
`

export const saveEnterpriseCustomerField = `
  mutation saveEnterpriseCustomerField($customerField: CustomerFieldPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseCustomerField(customerField: $customerField, globalFilterConfig: $globalFilterConfig) ${enterpriseCustomerFields}
  }
`

export const deleteEnterpriseCustomerField = `
  mutation deleteEnterpriseCustomerField($id: ID!, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseCustomerField(id: $id, externalId: $externalId, globalFilterConfig: $globalFilterConfig)
  }
`

export const resetGlobalBranchCustomerField = `
  mutation resetGlobalBranchCustomerField($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCustomerField(companyId: $companyId, region: $region, internalId: $internalId) ${enterpriseCustomerFields}
  }
`

export const resetGlobalBranchCustomerFieldCategory = `
  mutation resetGlobalBranchCustomerFieldCategory($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCustomerFieldCategory(companyId: $companyId, region: $region, internalId: $internalId) ${enterpriseCustomerFieldCategoryFields}
  }
`

export const resetGlobalBranchCustomer = `
  mutation resetGlobalBranchCustomer($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCustomer(companyId: $companyId, region: $region, internalId: $internalId) ${customerFields}
  }
`

export const resetGlobalBranchCompanyTag = `
  mutation resetGlobalBranchCompanyTag($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCompanyTag(companyId: $companyId, region: $region, internalId: $internalId) ${companyTag}
  }
`

export const resetGlobalBranchCourse = `
  mutation resetGlobalBranchCourse($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCourse(companyId: $companyId, region: $region, internalId: $internalId) ${courseFields}
  }
`

export const resetGlobalBranchCourseCategory = `
  mutation resetGlobalBranchCourseCategory($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCourseCategory(companyId: $companyId, region: $region, internalId: $internalId) ${courseCategoryFields}
  }
`

export const resetGlobalBranchService = `
  mutation resetGlobalBranchService($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchService(companyId: $companyId, region: $region, internalId: $internalId) ${enterpriseServicesFields}
  }
`

export const resetToGlobalBranchServices = `
  mutation resetToGlobalBranchServices($companyIds: [ID]!, $region: Region!, $internalId: ID!, $keys: [ServiceLocalUpdateKeyType]!) {
    resetToGlobalBranchServices(companyIds: $companyIds, region: $region, internalId: $internalId, keys: $keys) ${enterpriseServicesFields}
  }
`

export const resetToGlobalBranchServiceCombinations = `
  mutation resetToGlobalBranchServiceCombinations($companyIds: [ID]!, $region: Region!, $internalId: ID!, $keys: [ServiceCombinationLocalUpdateKeyType]!) {
    resetToGlobalBranchServiceCombinations(companyIds: $companyIds, region: $region, internalId: $internalId, keys: $keys) ${enterpriseServicesFields}
  }
`

export const resetGlobalBranchServiceCategory = `
  mutation resetGlobalBranchServiceCategory($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchServiceCategory(companyId: $companyId, region: $region, internalId: $internalId) ${enterpriseServicesCategoryFields}
  }
`

export const resetToGlobalBranchCourses = `
  mutation resetToGlobalBranchCourses($companyIds: [ID]!, $internalId: ID!, $keys: [CourseLocalUpdateKeyType]!) {
    resetToGlobalBranchCourses(companyIds: $companyIds, internalId: $internalId, keys: $keys) ${enterpriseServicesFields}
  }
`

export const resetGlobalBranchResourceCategory = `
  mutation resetGlobalBranchResourceCategory($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchResourceCategory(companyId: $companyId, region: $region, internalId: $internalId) ${resourceCategoryFields}
  }
`

export const saveEnterpriseCustomer = `
  mutation saveEnterpriseCustomer($customer: CustomerPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseCustomer(customer: $customer, globalFilterConfig: $globalFilterConfig) ${customerFields}
  }
`
export const deleteEnterpriseCustomer = `
  mutation deleteEnterpriseCustomer($id: ID, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseCustomer(id: $id, externalId: $externalId,  globalFilterConfig: $globalFilterConfig)
  }
`

export const deleteEnterpriseResourceCategory = `
  mutation deleteEnterpriseResourceCategory($id: ID, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseResourceCategory(id: $id, externalId: $externalId,  globalFilterConfig: $globalFilterConfig)
  }
`

export const saveEnterpriseResourceCategory = `
  mutation saveEnterpriseResourceCategory($category: ResourceCategoryPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseResourceCategory(category: $category, globalFilterConfig: $globalFilterConfig) ${resourceCategoryFields}
  }
`

export const saveEnterpriseService = `
  mutation saveEnterpriseService($service: ServicePayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseService(service: $service, globalFilterConfig: $globalFilterConfig) ${enterpriseServicesFields}
  }
`

export const moveService = `
  mutation moveService($id: ID!, $orderIndex: Int!, $categoryId: ID!) {
    moveService(id: $id, orderIndex: $orderIndex, categoryId: $categoryId)
  }
`

export const saveEnterpriseCourseCategory = `
  mutation saveEnterpriseCourseCategory($category: ServiceCategoryPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseCourseCategory(category: $category, globalFilterConfig: $globalFilterConfig) ${courseCategoryFields}
  }
`

export const deleteEnterpriseCourseCategory = `
  mutation deleteEnterpriseCourseCategory($id: ID!, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseCourseCategory(id: $id, externalId: $externalId,  globalFilterConfig: $globalFilterConfig)
  }
`

export const moveCourseCategory = `
  mutation moveCourseCategory($id: ID!, $orderIndex: Int!) {
    moveCourseCategory(id: $id, orderIndex: $orderIndex)
  }
`

export const moveCourse = `
  mutation moveCourse($id: ID!, $orderIndex: Int!, $categoryId: ID!) {
    moveCourse(id: $id, orderIndex: $orderIndex, categoryId: $categoryId)
  }
`

export const saveEnterpriseCourse = `
  mutation saveEnterpriseCourse($course: ServicePayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseCourse(course: $course, globalFilterConfig: $globalFilterConfig) ${courseFields}
  }
`

export const deleteEnterpriseCourse = `
  mutation deleteEnterpriseCourse($id: ID, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseCourse(id: $id, externalId: $externalId, globalFilterConfig: $globalFilterConfig)
  }
`

export const deleteBranchCustomerField = `
  mutation deleteBranchCustomerField($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchCustomerField(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const deleteBranchCompanyTag = `
  mutation deleteBranchCompanyTag($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchCompanyTag(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const unlinkBranchResource = `
  mutation unlinkBranchResource($companyId: ID!, $region: Region!, $id: ID!) {
    unlinkBranchResource(companyId: $companyId, region: $region, id: $id) ${branchResourceFields}
  }
`

export const importBranches = `
  mutation importBranches($key: String!) {
    importBranches(key: $key)
  }
`

export const importServices = `
  mutation importServices($companyId: ID!, $password: String!, $region: Region!, $key: String!, $mergeRule: MergeRuleType, $wantsToBeOnline: Boolean) {
    importServices(companyId: $companyId, password: $password, region: $region, key: $key, mergeRule: $mergeRule, wantsToBeOnline: $wantsToBeOnline)
  }
`

export const importServiceCategories = `
  mutation importServiceCategories($companyId: ID!, $password: String!, $region: Region!, $key: String!, $mergeRule: MergeRuleType) {
    importServiceCategories(companyId: $companyId, password: $password, region: $region, key: $key, mergeRule: $mergeRule)
  }
`

export const importResources = `
  mutation importResources($companyId: ID!, $password: String!, $region: Region!, $key: String!, $mergeRule: MergeRuleType) {
    importResources(companyId: $companyId, password: $password, region: $region, key: $key, mergeRule: $mergeRule)
  }
`

export const importResourceCategories = `
  mutation importResourceCategories($companyId: ID!, $password: String!, $region: Region!, $key: String!, $mergeRule: MergeRuleType) {
    importResourceCategories(companyId: $companyId, password: $password, region: $region, key: $key, mergeRule: $mergeRule)
  }
`

// 2FA
export const initUser2FA = `
  mutation initUser2FA($type: Auth2FAType!) {
    initUser2FA(type: $type) {
      secretKey
      service
      account
    }
  }
`

export const setupUser2FA = `
  mutation setupUser2FA($type: Auth2FAType!, $code: ID!, $withAuth: Boolean) {
    setupUser2FA(type: $type, code: $code, withAuth: $withAuth) {
      type
      authTokens ${tokensFields}
      recoveryCodes
    }
  }
`

export const disable2FA = `
  mutation disable2FA($type: Auth2FAType!, $code: ID!) {
    disable2FA(type: $type, code: $code) ${tokensFields}
  }
`

export const refresh2FARecoveryCodes = `
  mutation refresh2FARecoveryCodes($type: Auth2FAType!, $code: ID!) {
    refresh2FARecoveryCodes(type: $type, code: $code)
  }
`

export const unblockBranchUser = `
  mutation unblockBranchUser($userId: ID!, $shouldInvalidateSessions: Boolean) {
    unblockBranchUser(userId: $userId, shouldInvalidateSessions: $shouldInvalidateSessions)
  }
`

export const resetUserPasswordBranch = `
  mutation resetUserPasswordBranch($userId: ID!, $userPassword: String, $enterprisePassword: String!) {
    resetUserPasswordBranch(userId: $userId, userPassword: $userPassword, enterprisePassword: $enterprisePassword)
  }
`

export const authenticate2FA = `
  query authenticate2FA($type: Auth2FAType, $code: ID) {
    authenticate2FA(type: $type, code: $code) ${tokensFields}
  }
`

export const saveEnterpriseBillingData = `
  mutation saveEnterpriseBillingData($billingData: BillingDataPayload!) {
    saveEnterpriseBillingData(billingData: $billingData) ${chargebeeAccountData}
  }
`
export const savePermissionsGroupEnterprise = `
  mutation savePermissionsGroupEnterprise($permissionsGroup: PermissionsGroupEnterprisePayload!) {
    savePermissionsGroupEnterprise(permissionsGroup: $permissionsGroup) ${userPermissionsGroupsEnterpriseFields}
  }
`

export const deletePermissionsGroupEnterprise = `
  mutation deletePermissionsGroupEnterprise($id: ID!) {
    deletePermissionsGroupEnterprise(id: $id)
  }
`

// SUBSCRIPTIONS

export const enterpriseStatisticsUpdated = `
  subscription enterpriseStatisticsUpdated {
    enterpriseStatisticsUpdated ${enterpriseStatisticsFields}
  }
`

// Permission types

export const getBranchPermissionTypes = `
  query getBranchPermissionsGroups($companyId:ID!, $filter: PermissionsGroupFilter, $region: Region) {
    getBranchPermissionsGroups(companyId: $companyId, filter: $filter, region: $region) ${permissionType}
  }
`

export const getBranchPermissionType = `
  query getBranchPermissionsGroup($id: ID!) {
    getBranchPermissionsGroup(id: $id) ${permissionType}
  }
`

export const saveBranchPermissionsGroup = `
  mutation saveBranchPermissionsGroup($companyId: ID!, $region: Region, $permissionsGroup: PermissionsGroupPayload!) {
    saveBranchPermissionsGroup(companyId: $companyId, region: $region, permissionsGroup: $permissionsGroup) ${permissionType}
  }
`

export const deleteBranchPermissionsGroup = `
  mutation deleteBranchPermissionsGroup($id: ID, $companyId: ID!, $region: Region) {
    deleteBranchPermissionsGroup(id: $id, companyId: $companyId, region: $region)
  }
`

export const getPermissionTypes = `
  query getEnterprisePermissionsGroups($filter: PermissionsGroupFilter) {
    getEnterprisePermissionsGroups(filter: $filter) ${permissionType}
  }
`
export const getPermissionType = `
  query getEnterprisePermissionsGroup($id: ID, $externalId: ID) {
    getEnterprisePermissionsGroup(id: $id, externalId: $externalId) ${permissionType}
  }
`

export const savePermissionType = `
  mutation saveEnterprisePermissionsGroup($permissionsGroup: PermissionsGroupPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterprisePermissionsGroup(permissionsGroup: $permissionsGroup, globalFilterConfig: $globalFilterConfig) ${permissionType}
  }
`

export const deletePermissionsGroup = `
  mutation deleteEnterprisePermissionsGroup($id: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterprisePermissionsGroup(id: $id, globalFilterConfig: $globalFilterConfig)
  }
`

export const resetGlobalBranchPermissionsGroup = `
  mutation resetGlobalBranchPermissionsGroup($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchPermissionsGroup(companyId: $companyId, region: $region, internalId: $internalId) ${permissionType}
  }
`

export const getRedirectBranchManagerURL = `
  query getRedirectBranchManagerURL($email: String!) {
    getRedirectBranchManagerURL(email: $email)
  }
`

export const subscribeEnterpriseAppToPlan = `
  mutation subscribeEnterpriseAppToPlan($appId: ID, $appExternalId: ID, $planName: String, $paymentMethodId: ID, $billingData: BillingDataPayload, $limitValue: Float, $isSimulation: Boolean) {
    subscribeEnterpriseAppToPlan(appId: $appId, appExternalId: $appExternalId, planName: $planName, paymentMethodId: $paymentMethodId, billingData: $billingData, limitValue: $limitValue, isSimulation: $isSimulation) ${appSubscriptionFields}
  }
`
export const unsubscribeEnterpriseAppFromPlan = `
  mutation unsubscribeEnterpriseAppFromPlan($appId: ID, $appExternalId: ID, $reason: String) {
    unsubscribeEnterpriseAppFromPlan(appId: $appId, appExternalId: $appExternalId, reason: $reason)
  }
`
export const updateEnterpriseAppSubscription = `
  mutation updateEnterpriseAppSubscription($appId: ID, $appExternalId: ID, $limitValue: Float) {
    updateEnterpriseAppSubscription(appId: $appId, appExternalId: $appExternalId, limitValue: $limitValue)
  }
`
export const cancelUnsubscribeEnterpriseAppFromPlan = `
  mutation cancelUnsubscribeEnterpriseAppFromPlan($appId: ID, $appExternalId: ID) {
    cancelUnsubscribeEnterpriseAppFromPlan(appId: $appId, appExternalId: $appExternalId)
  }
`
export const startEnterpriseAppTrial = `
  mutation startEnterpriseAppTrial($appId: ID, $appExternalId: ID) {
    startEnterpriseAppTrial(appId: $appId, appExternalId: $appExternalId)
  }
`
export const retryEnterpriseAppPayment = `
  mutation retryEnterpriseAppPayment($appId: ID, $appExternalId: ID) {
    retryEnterpriseAppPayment(appId: $appId, appExternalId: $appExternalId)
  }
`
export const getEnterpriseStatisticsExport = `
  query getEnterpriseStatisticsExport($fileTypeName: FileTypeName, $statisticsSections: [EnterPriseStatisticsSection], $filter: EnterpriseStatisticsFilter!) {
    getEnterpriseStatisticsExport(fileTypeName: $fileTypeName, statisticsSections: $statisticsSections, filter: $filter)
  }
`
export const getBranchStatisticsExport = `
  query getBranchStatisticsExport($companyId: ID!, $fileTypeName: FileTypeName, $statisticsSections: [EnterPriseStatisticsSection], $region: Region, $filter: CompanyStatisticsFilter!) {
    getBranchStatisticsExport(companyId: $companyId, fileTypeName: $fileTypeName, statisticsSections: $statisticsSections, region: $region, filter: $filter)
  }
`
export const getEnterpriseActivityLogs = `
  query getEnterpriseActivityLogs($filter: ActivityLogFilter, $pagination: Pagination) {
    getEnterpriseActivityLogs(filter: $filter, pagination: $pagination) ${activityLogFields}
    getEnterpriseActivityLogsCounter(filter: $filter)
  }
`
export const getEnterpriseActivityLog = `
  query getEnterpriseActivityLog($id: ID!) {
    getEnterpriseActivityLog(id: $id) ${activityLogFields}
  }
`
export const getEnterpriseSystemActions = `
  query getEnterpriseSystemActions($filter: TemporaryRemoteAccessMessageFilter, $pagination: Pagination, $region: Region) {
    getEnterpriseSystemActions (filter: $filter, pagination: $pagination) ${systemMessagesFields}
    getEnterpriseSystemActionsCount(filter: $filter, region: $region)
  }
`
export const getEnterpriseSystemAction = `
  query getEnterpriseSystemAction($id: ID!) {
    getEnterpriseSystemAction(id: $id) ${systemMessagesFields}
  }
`
export const declineEnterpriseRemoteAccess = `
  mutation declineEnterpriseRemoteAccess($declineEnterpriseRemoteAccessId: ID!, $region: Region!) {
    declineEnterpriseRemoteAccess(id: $declineEnterpriseRemoteAccessId, region: $region)
  }
`
export const revokeEnterpriseRemoteAccess = `
  mutation revokeEnterpriseRemoteAccess($revokeEnterpriseRemoteAccessId: ID!, $region: Region!) {
    revokeEnterpriseRemoteAccess(id: $revokeEnterpriseRemoteAccessId, region: $region)
  }
`
export const acceptEnterpriseRemoteAccess = `
mutation AcceptEnterpriseRemoteAccess($acceptEnterpriseRemoteAccessId: ID!, $region: Region) {
  acceptEnterpriseRemoteAccess(id: $acceptEnterpriseRemoteAccessId, region: $region) 
  ${systemMessagesFields}
}
`
