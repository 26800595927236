import React from 'react'
import PropTypes from 'prop-types'
import Highlight from 'react-highlight'

import './CodeHighlight.css'

const CodeHighlight = props => {
  const { children, withCopyButton, prefix, sufix, language = 'JavaScript' } = props
  const classNames = ['ta-code-highlight']
  if (withCopyButton) classNames.push('with-copy-button')

  return (
    <div className={classNames.join(' ')} key={Date.now()}>
      {prefix}
      <Highlight className={language}>
        {children}
      </Highlight>
      {sufix}
    </div>
  )
}

CodeHighlight.propTypes = {
  prefix: PropTypes.string,
  sufix: PropTypes.string,
  language: PropTypes.string
}

export default CodeHighlight
