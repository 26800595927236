import React from 'react'
import PropTypes from 'prop-types'
import { BlueTemplate, Loader, t } from '../../Common'
import { LoginForm } from '../../Beauties'

import './Login.css'

const Login = props => {
  const {
    pending,
    pendingLanguage,
    onSubmit,
    passwordFieldType,
    togglePasswordFieldType,
    onLanguageChange,
    showAutocompleteLoginPage,
    locale
  } = props
  const logoUrl = '/login'

  return (
    <div className='ta-login'>
      <BlueTemplate logoUrl={logoUrl}>
        {(!pendingLanguage &&
          <div className='ta-login__box'>
            <div className='ta-login__box-content'>
              <div className='ta-login__title'>{t('login.loginForKeyAccounts')}</div>
              <Loader active={pendingLanguage || pending} />
              <LoginForm
                locale={locale}
                onSubmit={onSubmit}
                passwordFieldType={passwordFieldType}
                togglePasswordFieldType={togglePasswordFieldType}
                onLanguageChange={onLanguageChange}
                showAutocompleteLoginPage={showAutocompleteLoginPage}
              />
            </div>
          </div>
        )}
      </BlueTemplate>
    </div>
  )
}

Login.propTypes = {
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  passwordFieldType: PropTypes.string.isRequired,
  togglePasswordFieldType: PropTypes.func.isRequired
}

export default Login
