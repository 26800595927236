import translateServerCode from './translateServerCode'

export default error => {
  const errors = []
  if (error.code === 'AtLeastOneItemInDependency') {
    errors.push({ key: 'globalErrors', value: error.message })
  } else if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}
