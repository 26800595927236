import { Children, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FontAwesome5 } from '..'
import PropTypes from 'prop-types'

import './Carousel.css'

const Carousel = ({
  className,
  children,
  controls,
  dots,
  autoplay = true,
  speed = 500,
  autoplaySpeed = 5000
}) => {
  const [current, setCurrent] = useState(1)
  const [translateX, setTranslateX] = useState(0)
  const containerRef = useRef()
  const activeTransitionRef = useRef(false)
  const classNames = ['ta-carousel']
  if (className) classNames.push(className)
  const slides = useMemo(() => {
    const length = children.length
    const slideClassName = 'ta-carousel__slide'
    if (length > 1) {
      const items = Children.map(children, (child, index) => (
        <div key={index} className={slideClassName}>{child}</div>
      ))

      return [
        <div key={length + 1} className={slideClassName}>{children[length - 1]}</div>,
        ...items,
        <div key={length + 2} className={slideClassName}>{children[0]}</div>
      ]
    }

    return <div className={slideClassName}>{children[0]}</div>
  }, [children])
  const actionHandler = useCallback(mode => {
    if (activeTransitionRef.current) return
    containerRef.current.style.transitionDuration = `${speed}ms`
    activeTransitionRef.current = true
    if (mode === 'prev') {
      if (current <= 1) {
        setTranslateX(0)
        setCurrent(children.length)
      } else {
        setTranslateX(containerRef.current.clientWidth * (current - 1))
        setCurrent(prev => --prev)
      }
    } else if (mode === 'next') {
      if (current >= children.length) {
        setTranslateX(containerRef.current.clientWidth * (children.length + 1))
        setCurrent(1)
      } else {
        setTranslateX(containerRef.current.clientWidth * (current + 1))
        setCurrent(prev => ++prev)
      }
    }
  }, [current, children])
  const onDotClick = index => {
    const slideIndex = index + 1
    if (slideIndex === current || activeTransitionRef.current) return
    containerRef.current.style.transitionDuration = `${speed}ms`
    setTranslateX(containerRef.current.clientWidth * slideIndex)
    setCurrent(slideIndex)
  }
  useLayoutEffect(() => {
    setTranslateX(containerRef.current.clientWidth * current)
  }, [])
  useEffect(() => {
    const transitionEnd = () => {
      activeTransitionRef.current = false
      if (current <= 1) {
        containerRef.current.style.transitionDuration = '0ms'
        setTranslateX(containerRef.current.clientWidth * current)
      }
      if (current >= children.length) {
        containerRef.current.style.transitionDuration = '0ms'
        setTranslateX(containerRef.current.clientWidth * children.length)
      }
    }
    document.addEventListener('transitionend', transitionEnd)

    return () => {
      document.removeEventListener('transitionend', transitionEnd)
    }
  }, [current])
  useEffect(() => {
    if (!autoplay) return
    const autoplayInterval = setInterval(() => {
      actionHandler('next')
    }, autoplaySpeed)

    return () => {
      if (autoplayInterval) {
        clearInterval(autoplayInterval)
      }
    }
  }, [actionHandler])

  return (
    <div className={classNames.join(' ')}>
      <div
        ref={containerRef}
        className='ta-carousel__container'
        style={{ transform: `translate3d(${-translateX}px, 0, 0)`, transitionDuration: `${speed}ms` }}
      >
        {slides}
      </div>
      {!!controls && (
        <>
          <button type='button' data-role='none' onClick={() => actionHandler('prev')} className='ta-carousel__button left'>
            <FontAwesome5 icon='fal fa-angle-left' />
          </button>
          <button type='button' data-role='none' onClick={() => actionHandler('next')} className='ta-carousel__button right'>
            <FontAwesome5 icon='fal fa-angle-right' />
          </button>
        </>
      )}
      {!!dots && (
        <div className='ta-carousel__control-dots'>
          {children.map((_, index) =>
            <div key={index} onClick={() => onDotClick(index)} className={`ta-carousel__dot ${index === (current - 1) ? 'active' : ''}`.trim()} />
          )}
        </div>
      )}
    </div>
  )
}

Carousel.propTypes = {
  className: PropTypes.string,
  controls: PropTypes.bool,
  dots: PropTypes.bool,
  autoplay: PropTypes.bool,
  speed: PropTypes.number,
  autoplaySpeed: PropTypes.number
}

export default Carousel
