import React from 'react'
import PropTypes from 'prop-types'
import {
  SimpleTabsHeaderItem,
  SimpleTabsBodyItem,
  FixedContentHeader,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FixedContent,
  SimpleTabs,
  Title,
  Button,
  t
} from '../../../Common'
import {
  UsersPreviewPermissionsWrapper,
  UsersPreviewDetailsWrapper,
  PopupTriggerWrapper
} from '../../../Beasts'

import './UsersPreview.css'

const UserPreview = props => {
  const {
    user,
    hash,
    usersWrite,
    isEditEnabled,
    isDeleteEnabled
  } = props
  const availableTabs = ['details', 'permissions']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]

  return (
    <div className='ta-users__preview'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={user.name || ''}>
            {(usersWrite &&
              <>
                {(isDeleteEnabled &&
                  <PopupTriggerWrapper name='users-delete' position='bottom' id={user.id}>
                    <Button isTertiary as='link' className='ta-btn ta-btn-delete' external icon='trash-alt' type='s' />
                  </PopupTriggerWrapper>
                )}
                {(isEditEnabled &&
                  <Button as='link' to={`/managers/users/${user.id}/edit`} isPrimary icon='pencil' type='s' />
                )}
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          {(user.id &&
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
                <SimpleTabsHeaderItem name='permissions' icon='unlock' label={t('globalSettings.form.tab.localUpdates')} />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='details'>
                  <UsersPreviewDetailsWrapper user={user} />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem renderOnlyIfActive name='permissions'>
                  <UsersPreviewPermissionsWrapper user={user} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

UserPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default UserPreview
