import React from 'react'
import {
  Row,
  Col,
  FormSection,
  Title,
  Checkbox,
  BorderedBox,
  Input,
  FormText,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  SingleSelect,
  Alert,
  t
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'

import '../SecuritySettings.css'

const SecuritySettingsWebappEditForm = props => {
  const {
    hasEnterprise,
    plan,
    useEnterpriseSession,
    isInactivityLoggedOut,
    settings
  } = props
  const sessionMetricOptions = [
    { value: 'MINUTES', label: t('global.minutes.label') },
    { value: 'HOURS', label: t('global.hours.label') },
    { value: 'DAYS', label: t('global.days') }
  ]
  return (
    <>
      <FormSection>
        <Title size='m' isCompact label={t('user.preview.security.title')} icon='lock' />
        <Row>
          <Col>
            <FormText noOffset>{t('settings.section.security.form.webapp.2fa.infoText')}</FormText>
          </Col>
        </Row>
        {(settings.requiredAuth2FAType === 'NONE' &&
          <Row>
            <Col>
              <FormText noOffset>
                {dangerousHTML(t('settings.section.security.form.webapp.2fa.warning'))}
              </FormText>
            </Col>
          </Row>
        )}
        <Row noOffset>
          <Col>
            <HoverPopupTrigger>
              <Checkbox
                name='useEnterprise2FA'
                label={t('global.enable')}
                theme='switch'
              />
            </HoverPopupTrigger>
          </Col>
        </Row>
      </FormSection>
      <FormSection>
        <Title size='m' isCompact label={t('settings.section.security.session.heading')} icon='user-clock' />
        <FormText>{t('settings.section.security.form.session.infoText')}</FormText>
        {(hasEnterprise &&
          <Row noOffset>
            <Col>
              <HoverPopup disabled={plan === 'ENTERPRISE'}>
                <HoverPopupContent position='top' autoSize>
                  {t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox
                    disabled={plan !== 'ENTERPRISE'}
                    name='useEnterpriseSession'
                    label={t('settings.applyEnterprise.checkbox.label')}
                    theme='switch'
                  />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
        )}
        {((!hasEnterprise || !useEnterpriseSession) &&
          <Row>
            <Col>
              <BorderedBox topOffset>
                <Alert theme='alert'>
                  {t('settings.section.security.form.session.changesApply.text')}
                </Alert>
                <Row>
                  <Col size={50}>
                    <Input
                      name='sessionLength'
                      label={t('settings.section.security.form.session.input.sessionLength.label')}
                      mandatory
                    />
                  </Col>
                  <Col size={50}>
                    <SingleSelect
                      name='sessionMetric'
                      options={sessionMetricOptions}
                      label={t('settings.section.security.form.session.select.sessionMetric.label')}
                    />
                  </Col>
                </Row>
                <Row className='left-offset'>
                  <Checkbox
                    name='isInactivityLoggedOut'
                    label={t('settings.section.security.form.session.checkbox.inactivityLogout.label')}
                  />
                </Row>
                {(isInactivityLoggedOut &&
                  <BorderedBox className='no-margin'>
                    <Row>
                      <Col size={50}>
                        <Input
                          name='inactivityLength'
                          label={t('settings.section.security.form.session.input.inactivityLength.label')}
                          mandatory
                        />
                      </Col>
                      <Col size={50}>
                        <SingleSelect
                          name='inactivityMetric'
                          options={sessionMetricOptions}
                          label={t('settings.section.security.form.session.select.inactivityMetric.label')}
                        />
                      </Col>
                    </Row>
                  </BorderedBox>
                )}
              </BorderedBox>
            </Col>
          </Row>
        )}
      </FormSection>
    </>
  )
}

export default SecuritySettingsWebappEditForm
