import React from 'react'

import { t } from '../../../Common'

import './AppsSubscription.css'

const AppsSubscriptionRow = (props) => {
  let {
    onClick,
    big,
    title,
    text,
    boxValue,
    boxLabel,
    bottomOffset,
    active,
    name,
    disabled
  } = props
  big = !!big

  const handleOnClick = () => {
    onClick && !disabled && onClick(name)
  }

  const classNames = ['ta-apps__subscription-row']
  if (onClick) classNames.push('clickable')
  if (active) classNames.push('active')
  if (disabled) classNames.push('disabled')
  if (bottomOffset) classNames.push('bottom-offset')
  const boxClassNames = ['ta-apps__subscription-row__box']
  if (big) boxClassNames.push('big')

  return (
    <div className={classNames.join(' ')} onClick={handleOnClick}>
      <div>
        <div className='ta-apps__subscription-row__title'>
          {title}
          {disabled && (
            <span>
              {' '}({t('subscriptions.currentPlan.title')})
            </span>
          )}
        </div>
        <div className='ta-apps__subscription-row__text'>{text}</div>
      </div>
      <div className={boxClassNames.join(' ')}>
        <div className='ta-apps__subscription-row__box__value'>{boxValue}</div>
        <div className='ta-apps__subscription-row__box__label'>{boxLabel}</div>
      </div>
    </div>
  )
}

export default AppsSubscriptionRow
