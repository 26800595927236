const reducer = {}

reducer.initialState = {
  businessSectors: [],
  timezones: [],
  countries: [],
  locales: []
}

reducer.handlers = (dispatch, actions) => ({
  staticDataPopulate: data => dispatch(actions.STATIC_DATA_POPULATE, data)
})

reducer.STATIC_DATA_POPULATE = (state, data) => ({
  ...state,
  businessSectors: data.businessSectors || [],
  timezones: data.timezones || [],
  countries: data.countries || [],
  locales: data.locales || [],
  publicHolidays: data.publicHolidays || []
})

export default reducer
