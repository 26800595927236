import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Error,
  Row,
  Col,
  Input,
  MultipleSelect,
  t
} from '../../../Common'
import { CustomerFormField } from '../../../Beauties'

import './CustomerForm.css'

const CustomerForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    groupedFields,
    availableFields,
    timezones,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type,
    showAutocompleteCustomerForm
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-customers__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='customers'>
            {(showExternalIds &&
              <FormSection>
                <Title size='m' isCompact label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                      hasAutocomplete
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            {groupedFields.map((item, i) => (
              <FormSection key={item.id || i} className='ta-cleaner'>
                <Title size='m' isCompact label={item.name || t(item.translationKey)} icon='folder' />
                {item.fields.map(field => (
                  <CustomerFormField
                    key={field.id}
                    field={field}
                    availableFields={availableFields}
                    timezones={timezones}
                    hasAutocomplete
                  />
                ))}
              </FormSection>
            ))}
            <FormSection className='ta-cleaner'>
              <Title size='m' isCompact label={t('global.tags')} icon='tag' />
              <Row>
                <Col>
                  <MultipleSelect
                    name='tags'
                    label={t('global.tags.label')}
                    searchable
                  />
                </Col>
              </Row>
            </FormSection>
            {(!allowOnlyGlobalCustomers &&
              <FormSection className='ta-cleaner'>
                <Title size='m' isCompact label={t('global.location.label')} icon='building' />
                <Row>
                  <Col>
                    <MultipleSelect
                      name='location'
                      label={t('global.select.label')}
                      searchable
                      position='top'
                      hasAll={hasAll}
                      disableSelected={type === 'edit'}
                      allLabel={t('global.allLocations')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormSection className='ta-cleaner'>
              <FormButtons>
                <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
                <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
              </FormButtons>
            </FormSection>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CustomerForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default CustomerForm
