import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Badge, Row, Col } from '../../Common'
import { ROLES_COLORS } from '../../Settings'
import { capitalize } from '../../Utils'

import './UserPreview.css'

const UserPreview = props => {
  const {
    name,
    avatarUrl,
    color,
    roles,
    hasPermissionTypeAssigned,
    permissionTypeName,
    permissionTypeColor
  } = props

  return (
    <div className='ta-user-preview'>
      <Avatar name={name} image={avatarUrl} color={color} />
      <div className='ta-user-preview__name'>
        {name}
        {(hasPermissionTypeAssigned &&
          <Row>
            <Col>
              <Badge text={permissionTypeName} background={permissionTypeColor} />
            </Col>
          </Row>
        )}
        {(!hasPermissionTypeAssigned && roles && roles.length > 0 &&
          <div className='ta-user-roles'>
            {roles.map(role => (
              <Badge key={role} color={role === 'OWNER' ? '#505a5e' : '#fff'} text={capitalize(role)} background={ROLES_COLORS[role.toString()]} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

UserPreview.propTypes = {
  name: PropTypes.string.isRequired
}

export default UserPreview
