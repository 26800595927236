import React from 'react'

import { AppsSubscriptionBillingPreview } from '../../../Beauties'
import { connect, selectors } from '../../../Store'

const AppsSubscriptionBillingPreviewWrapper = (props) => {
  let {
    billingData,
    country,
    paymentMethods,
    paymentMethodId
  } = props
  paymentMethods = paymentMethods || []
  const paymentMethod = paymentMethods.find(item => item.id === paymentMethodId) || {}

  return (
    <AppsSubscriptionBillingPreview
      billingData={billingData}
      country={country}
      paymentMethod={paymentMethod}
    />
  )
}

const maps = state => ({
  paymentMethods: state.paymentMethods.list,
  country: selectors.countrySelector(state),
  billingData: selectors.billingDataSelector(state),
  paymentMethodId: selectors.formFieldPropertySelector(state, { formName: 'appSubscriptionBilling', name: 'paymentMethod', property: 'value' })
})

export default connect(maps)(AppsSubscriptionBillingPreviewWrapper)
