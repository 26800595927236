import React from 'react'
import moment from 'moment'

import {
  AppsSkeleton,
  AppsSubscriptionInvoiceRow
} from '../../../Beauties'
import {
  Row,
  Col,
  Form,
  Button,
  Error,
  Loader,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t,
  format
} from '../../../Common'

import './AppsSubscription.css'

const AppsSubscriptionFailed = (props) => {
  const {
    name,
    version,
    plan,
    pending,
    onClickContinue,
    onClickPayNow,
    isOwner,
    paymentLink,
    invoiceLink,
    paymentAdded,
    appInstallation,
    noAutoCollect
  } = props
  const {
    blockedReasonCode,
    hasDuePayment,
    duePaymentAt,
    blockingAt,
    isBlocked
  } = appInstallation || {}
  const isStillDue = duePaymentAt && hasDuePayment && !isBlocked && moment(duePaymentAt).diff()
  const formattedDueDate = format(duePaymentAt, 'long', { isUTC: true, format: 'YYYY-MM-DD' })
  let headerText = t('apps.subscriptionFailed.header')
  if (!hasDuePayment) headerText = t('apps.subscriptionFailed.header.blocked')
  let description = ''
  if (!isOwner && hasDuePayment && !isStillDue) description = t('apps.subscriptionFailed.timeFinished.resource.text')
  if (!isOwner && hasDuePayment && isStillDue) {
    description = t('apps.subscriptionFailed.timeLeft.resource.text', [
      { key: 'DATE', value: `${formattedDueDate}` }
    ])
  }
  if (isOwner && hasDuePayment && isStillDue) {
    description = t('apps.subscriptionFailed.timeLeft.owner.text', [
      { key: 'DATE', value: `${formattedDueDate}` },
      { key: 'PAYMENT_LINK', value: paymentLink }
    ])
  }
  if (isOwner && hasDuePayment && !isStillDue) {
    description = t('apps.subscriptionFailed.timeFinished.owner.text', [
      { key: 'INVOICE_LINK', value: invoiceLink },
      { key: 'PAYMENT_LINK', value: paymentLink }
    ])
  }
  if (blockedReasonCode === 'MAX_UNITS') description = t('apps.subscriptionFailed.maxUnits')
  if (noAutoCollect) {
    const formattedBlockingAt = format(blockingAt, 'long', { isUTC: true, format: 'YYYY-MM-DD' })
    headerText = t('apps.subscriptionFailed.offline.header')
    description = description = t('apps.subscriptionFailed.offline.text', [
      { key: 'DATE', value: `${formattedBlockingAt}` }
    ])
  }

  if (pending) {
    return (<Loader active />)
  }

  return (
    <AppsSkeleton
      name={name}
      version={version}
      plan={plan}
      className='ta-apps__subscription-failed'
      headerText={headerText}
      headerImage='/images/paidApps/banner-subscription-failed.png'
    >
      <AppsSubscriptionInvoiceRow description={description} />
      {isOwner && hasDuePayment && !noAutoCollect && (
        <>
          <Row>
            <Col>
              <Button as='link' isBlock isPrimary to={paymentLink}>
                {t('buttons.paymentMethod.add')}
              </Button>
            </Col>
          </Row>
          <Form name='appPaymentRetry'>
            <Row noOffset={!(isStillDue && blockedReasonCode !== 'MAX_UNITS')}>
              <Col>
                <HoverPopup disabled={paymentAdded}>
                  <HoverPopupContent position='top'>
                    {t('apps.subscriptionFailed.payNow.tooltip.text')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button
                      as='link'
                      isBlock
                      isTertiary
                      isGhost
                      onClick={onClickPayNow}
                      disabled={!paymentAdded}
                    >
                      {t('buttons.payNow')}
                    </Button>
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
            <Error name='globalErrors' />
          </Form>
        </>
      )}
      {!!isStillDue && blockedReasonCode !== 'MAX_UNITS' && !noAutoCollect && (
        <>
          <div className='ta-apps__subscription-failed__divider' />
          <Button isBlock isPrimary onClick={onClickContinue}>
            {t('buttons.continueToApp')}
          </Button>
        </>
      )}
    </AppsSkeleton>
  )
}

export default AppsSubscriptionFailed
