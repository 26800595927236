import React, { Component } from 'react'

class AccordionBody extends Component {
  constructor (props, context) {
    super(props)
  }

  render () {
    const { children, animate } = this.props
    const classNames = ['ta-accordion__body']
    if (animate) classNames.push('ta-accordion__body__animate')

    return (
      <div className={classNames.join(' ')} data-testid='accordionBody'>
        {children}
      </div>
    )
  }
}

AccordionBody.defaultProps = {
  animate: true
}

export default AccordionBody
