import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { CustomerFieldCategoryForm } from '../../Beauties'

const CustomerFieldCategoryEditWrapper = props => {
  let {
    id,
    pending,
    form,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist
  } = props
  pending = !!pending
  form = form || {}
  showExternalIds = !!showExternalIds
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers

  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }
    handlers.customerFieldCategoryFormGet(id)
  }, [initial])

  const onSubmit = cb => {
    handlers.customerFieldCategoryFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return pending
    ? <Loader active />
    : <CustomerFieldCategoryForm
      title={t('customerFields.form.editCategory.heading')}
      type='edit'
      cancelLink='/customers/customer-fields'
      onSubmit={onSubmit}
      showExternalIds={showExternalIds}
      allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
      isDefault={form.isDefault}
      isAdmin={isAdmin}
      branchesAccessBlacklist={branchesAccessBlacklist}
      location={form.location && form.location.values}
    />
}

CustomerFieldCategoryEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.categoryId,
  pending: state.customerFields.pendingCategoryForm,
  form: state.forms.customerFieldCategory,
  showExternalIds: state.company.settings.showExternalIds,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default connect(maps)(CustomerFieldCategoryEditWrapper)
