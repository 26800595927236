import React from 'react'
import {
  Button,
  Title,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  DnDDroppable,
  Accordion,
  AccordionTitle,
  AccordionBody,
  Form,
  Error,
  t
} from '../../Common'
import { truncateText, dangerousHTML } from '../../Utils'
import { PopupTriggerWrapper } from '../../Beasts'
import { CustomerFieldsList } from '../../Beauties'

const CustomerFieldsCategory = props => {
  const {
    category,
    pending,
    customerFields = [],
    isDragAndDropMode,
    index,
    isLast,
    areCategoriesExpanded,
    inactive,
    enterpriseId,
    globalDataFieldsRead,
    globalDataFieldsWrite,
    globalDataFieldsDelete
  } = props
  const classNames = ['ta-customer-fields__category']
  if (index === 0) classNames.push('first')
  if (pending) classNames.push('pending')
  const isDefaultCategory = category.isDefault
  if (category.isUpdated) classNames.push('updated')
  if (category.isDeleted) classNames.push('deleted')
  if (isDefaultCategory) classNames.push('default')
  if (!globalDataFieldsWrite) classNames.push('no-write-permissions')
  if (!globalDataFieldsDelete) classNames.push('no-delete-permissions')
  const locallyChangedBranches = (category.locallyUpdatedBranches && Object.keys(category.locallyUpdatedBranches).length) || null
  const shouldUseTranslation = category.translationKey && !category.name
  const categoryName = shouldUseTranslation ? t(category.translationKey) : category.name
  const categoryNameLimit = isDefaultCategory
    ? locallyChangedBranches ? 13 : 24
    : locallyChangedBranches ? 13 : 29

  const categoryExtraText = () => {
    return (
      <>
        {isDefaultCategory && <span className='ta-default-category__text'>{t('global.defaultCategory.hint')}</span>}
        {(locallyChangedBranches &&
          <HoverPopup>
            <HoverPopupContent position={index === 0 ? 'bottom' : 'top'}>
              <p className='ta-global-label__text'>
                {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
              </p>
            </HoverPopupContent>
            <div
              onClick={e => e.stopPropagation()}
            >
              <HoverPopupTrigger>
                <div className='ta-customer-fields__category-type global edited global-label'>
                  <FontAwesome5 icon='pencil' type='s' />
                  {t('global.changedLocally')}
                </div>
              </HoverPopupTrigger>
            </div>
          </HoverPopup>
        )}
      </>
    )
  }

  return (
    <div className={classNames.join(' ')}>
      <Accordion inactive={inactive}>
        <AccordionTitle>
          <Title
            size='m'
            label={truncateText(categoryName, categoryNameLimit, true)}
            extraText={categoryExtraText()}
            icon='folder'
          >
            <div className='ta-section-title-actions'>
              {(globalDataFieldsRead &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('couse.preview.category.openCategory')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' isSecondary to={`/customers/customer-fields/categories/${category.id}`}>
                      <FontAwesome5 icon='folder' />
                    </Button>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(globalDataFieldsWrite &&
                <>
                  {(globalDataFieldsDelete && (!isDragAndDropMode && !isDefaultCategory && customerFields.length === 0) &&
                    <PopupTriggerWrapper
                      name='customer-field-category-delete'
                      position={isLast ? 'top' : 'bottom'}
                      id={category.id}
                    >
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('global.category.delete')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Button as='link' isSecondary external>
                            <FontAwesome5 icon='trash-alt' />
                          </Button>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </PopupTriggerWrapper>
                  )}
                  {(globalDataFieldsDelete && !isDefaultCategory && (!isDragAndDropMode && customerFields.length > 0) &&
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('global.category.delete.forbidden')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button as='link' disabled isSecondary className='ta-btn ta-customer-fields__category__delete-btn' external>
                          <FontAwesome5 icon='trash-alt' />
                        </Button>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                  {((isDragAndDropMode && !areCategoriesExpanded && !isDefaultCategory) &&
                    <Button className='ta-btn ta-grab' isSecondary icon='arrows-v' />
                  )}
                  {/* {(!isDragAndDropMode && !isDefaultCategory) && */}
                  {(!isDragAndDropMode &&
                    <>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('buttons.editCategory.tooltip')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Button
                            to={`/customers/customer-fields/categories/${category.id}/edit`}
                            isSecondary
                            as='link'
                          >
                            <FontAwesome5 icon='pencil' />
                          </Button>
                        </HoverPopupTrigger>
                      </HoverPopup>
                      {(!isDefaultCategory &&
                        <HoverPopup>
                          <HoverPopupContent position='left' autoSize>
                            {t('customerFields.list.buttonAddField.tooltip')}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            <Button
                              to={`/customers/customer-fields/add?c=${category.id}`}
                              isPrimary
                              as='link'
                            >
                              <FontAwesome5 icon='plus' />
                            </Button>
                          </HoverPopupTrigger>
                        </HoverPopup>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Title>
        </AccordionTitle>
        <AccordionBody>
          <DnDDroppable id={category.id} type='customerField' isDropDisabled={!!isDefaultCategory}>
            <CustomerFieldsList
              customerFields={customerFields}
              isDragAndDropMode={isDragAndDropMode}
              areCategoriesExpanded={areCategoriesExpanded}
              enterpriseId={enterpriseId}
              categoryIndex={category.orderIndex}
            />
            <Form name={`customerFieldCategory${category.id}`}>
              <Error name='globalErrors' />
            </Form>
          </DnDDroppable>
        </AccordionBody>
      </Accordion>
    </div>
  )
}

export default CustomerFieldsCategory
