import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { TwoFADisableForm } from '../../Beauties'

const TwoFADisableWrapper = props => {
  let {
    form,
    closePopup,
    settings,
    pending
  } = props
  form = form || {}
  const { code } = form
  const isEnterpriseRequire2FA = settings && settings.requiredEnterpriseAuth2FAType === 'OTP'

  useEffect(() => {
    handlers.twoFAFormPopulate()
  }, [])

  const onSubmit = () => {
    handlers.account2FADisable({ code: code && code.value, withLogout: isEnterpriseRequire2FA }, () => {
      closePopup()
    })
  }

  return (
    <TwoFADisableForm code={code && code.value} onSubmit={onSubmit} onCancel={closePopup} pending={pending} />
  )
}

TwoFADisableWrapper.propTypes = {
  form: PropTypes.object,
  settings: PropTypes.object,
  pending: PropTypes.bool
}

const maps = state => ({
  form: state.forms.twoFA,
  settings: state.company && state.company.settings,
  pending: state.twoFA.pendingForm
})

export default connect(maps)(TwoFADisableWrapper)
