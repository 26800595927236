import React from 'react'
import { Alert, t } from '../../Common'
import { BranchesListItem } from '../../Beauties'

const BranchesList = props => {
  let {
    branches,
    search,
    onClick,
    hideBranchLoginBtn,
    allowFulfilment,
    tagsRead,
    showBranchLoginWithoutCustomisationsBtn
  } = props
  branches = branches || []

  return (
    <div className='ta-branches__list'>
      {branches.map((branch, index) => (
        <BranchesListItem
          key={index}
          branch={branch}
          search={search}
          index={index}
          onClick={onClick}
          hideBranchLoginBtn={hideBranchLoginBtn}
          allowFulfilment={allowFulfilment}
          tagsRead={tagsRead}
          showBranchLoginWithoutCustomisationsBtn={showBranchLoginWithoutCustomisationsBtn}
        />
      ))}
      {(!branches.length &&
        <Alert noOffset label={t('branches.list.noBranches')} theme='no-content' />
      )}
    </div>
  )
}

export default BranchesList
