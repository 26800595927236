import React from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { AppsListItem } from '../../../Beauties'

const AppsListItemWrapper = props => {
  let {
    app,
    filters,
    activeTab,
    popupShownId,
    chargebee
  } = props
  app = app || {}
  filters = filters || {}
  const { search } = filters
  let { value: searchValue } = search || {}
  searchValue = searchValue || ''
  chargebee = chargebee || {}
  const { noAutoCollect } = chargebee

  const handleSetPopupShown = (id) => {
    handlers.appPopupShownIdSet(id)
  }

  return (
    <AppsListItem
      app={app}
      search={searchValue}
      activeTab={activeTab}
      popupShown={popupShownId === app.id}
      noAutoCollect={noAutoCollect}
      setPopupShown={handleSetPopupShown}
    />
  )
}

const maps = state => ({
  filters: selectors.formSelector(state, { formName: 'appsFilters' }),
  popupShownId: selectors.appsFieldSelector(state, { field: 'popupShownId' }),
  chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' })
})

export default connect(maps)(AppsListItemWrapper)
