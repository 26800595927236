import React from 'react'
import { CustomerFieldsCategoryWrapper } from '../../Beasts'
import {
  DnDWrapper,
  DnDDroppable,
  DnDDraggable
} from '../../Common'

const DataFieldsActive = props => {
  const {
    categories,
    isDragAndDropMode,
    areExpanded,
    onDragStart,
    onDragUpdate,
    onDragEnd
  } = props

  return (
    <div className='ta-data-fields__active'>
      <DnDWrapper
        onDragUpdate={onDragUpdate}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        {!isDragAndDropMode && categories
          .filter(category => category.isDefault)
          .map((category, index) => (
            <CustomerFieldsCategoryWrapper
              key={category.id}
              category={category}
              isDragAndDropMode={isDragAndDropMode}
              areCategoriesExpanded={areExpanded}
              index={index}
              isDragDisabled
            />
          ))}
        <DnDDroppable id='category' type='category'>
          {categories
            .filter(category => !category.isDefault)
            .map((category, index) => (
              <DnDDraggable
                className='ta-customer-fields__category__dnd-wrapper'
                key={category.id}
                type='service'
                id={category.id}
                index={index + 1} // index + 1 because we are skipping the first category (the default one)
                isDragDisabled={category.isDefault || !isDragAndDropMode || areExpanded}
              >
                <CustomerFieldsCategoryWrapper
                  key={category.id}
                  category={category}
                  isDragAndDropMode={isDragAndDropMode}
                  index={index}
                  isLast={index === categories.length - 2} // - 2 because we are skipping the first category (the default one)
                  areCategoriesExpanded={areExpanded}
                />
              </DnDDraggable>
            ))}
        </DnDDroppable>
      </DnDWrapper>
    </div>
  )
}

export default DataFieldsActive
