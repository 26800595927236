const reducer = {}

reducer.initialState = {}

reducer.handlers = (dispatch, actions) => ({
  persistSet: params => dispatch(actions.PERSIST_SET, params),
  persistDelete: key => dispatch(actions.PERSIST_DELETE, key)
})

reducer.PERSIST_SET = (state, params) => {
  Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
  return {
    ...state,
    ...params
  }
}

reducer.PERSIST_DELETE = (state, key) => {
  const params = { ...state }
  if (params[key]) delete params[key]
  return {
    ...params
  }
}

export default reducer
