import React from 'react'
import { CustomerFieldCategoryPreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const CustomerFieldCategoryPreviewChangedLocallyWrapper = props => {
  let { customerFieldCategory, account } = props
  customerFieldCategory = customerFieldCategory || {}
  account = account || {}
  const { locallyChangedData, internalId } = customerFieldCategory || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalDataFieldsWrite } = enterprisePermissions || {}
  globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite

  const onResetGlobalEntity = (companyId, region) => handlers.customerFieldCategoryLocallyChangedReset({ companyId, region, internalId })

  return (
    <CustomerFieldCategoryPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalDataFieldsWrite={globalDataFieldsWrite}
    />
  )
}

const maps = state => {
  const { router, customerFields } = state
  const { categoriesList } = customerFields || {}
  const categoryId = (router.data && router.data.categoryId) || ''
  const customerFieldCategory = (categoriesList || []).find(item => item.id === categoryId) || {}

  return {
    customerFieldCategory,
    account: state.account
  }
}

export default connect(maps)(CustomerFieldCategoryPreviewChangedLocallyWrapper)
