import React from 'react'
import {
  FixedContentHeader,
  FixedContentBody,
  ColorIconSelect,
  FixedContent,
  Title,
  FormSection,
  FormButtons,
  Error,
  Input,
  Button,
  Form,
  Row,
  Col,
  t
} from '../../../Common'
import { UserPermissionsFormPermissionsWrapper } from '../../../Beasts'
import './UserPermissionsForm.css'

const UserPermissionsForm = props => {
  const { cancelLink, title, showExternalIds, onSubmit } = props
  return (
    <div className='ta-user-permission-types__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='userPermissionType'>
            {(showExternalIds &&
              <FormSection>
                <Title
                  size='m'
                  isCompact
                  label={t('global.externalId')}
                  icon='fingerprint'
                />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <Title
                size='m'
                isCompact
                label={t('userPermissionTypes.edit.section.details.heading')}
                icon='sliders-h'
              />
              <Row>
                <Col>
                  <ColorIconSelect name='color' iconFieldName='icon'>
                    <Input
                      name='name'
                      mandatory
                      label={t('global.name.placeholder')}
                      hintText={t('userPermissionTypes.edit.section.details.name.example')}
                    />
                  </ColorIconSelect>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Title
                size='m'
                isCompact
                label={t('userPermissionTypes.edit.section.permissions.heading')}
                icon='unlock-alt'
              />
              <UserPermissionsFormPermissionsWrapper />
            </FormSection>
            <FormSection>
              <Error noOffset name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>
                {t('buttons.cancel.label')}
              </Button>
              <Button type='submit' isPrimary>
                {t('buttons.save.label')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default UserPermissionsForm
