import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import {
  permissionTypeFormValidate,
  permissionTypeSaveTransform,
  permissionTypeFormServerErrorsTransform
} from './utils'
import { t } from '../../../Common'
import { ADMIN_PERMISSION_TYPE } from './index'

// List

payloads$(actions.PERMISSION_TYPES_LIST_GET)
  .subscribe(async () => {
    await globalActions.populateBranches(true)
    const permissionTypes = await q('getPermissionTypes')
    const { error } = permissionTypes || { error: { text: 'errors.api.unavailable' } }

    if (error) {
      return handlers.permissionTypesListPopulate([ADMIN_PERMISSION_TYPE])
    }

    handlers.permissionTypesListPopulate([ADMIN_PERMISSION_TYPE, ...permissionTypes])
    handlers.permissionTypesFilterFormGet()

    return permissionTypes
  })

payloads$(actions.PERMISSION_TYPES_LIST_FILTER_FORM_GET)
  .subscribe(async () => {
    const state = store.getState()
    let { branches, permissionTypes } = state
    branches = branches || {}
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    const { list = [] } = permissionTypes || {}
    const translations = { all: t('global.allLocations') }
    handlers.permissionTypesFilterFormPopulate({ branchesList, permissionTypes: list, translations })
  })

payloads$(actions.PERMISSION_TYPES_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const permissionType = await q('resetGlobalBranchPermissionsGroup', { companyId, region, internalId })
    const { error } = permissionType || {}
    if (error || !permissionType) return handlers.navigateToPath('/management/permission-types')
    handlers.permissionTypesLocallyChangedResetReady({ permissionType, companyId })
  })

// Form
payloads$(actions.PERMISSION_TYPE_FORM_GET)
  .subscribe(async detail => {
    const { id } = detail || {}
    const state = store.getState()
    const { branches, account } = state
    let { list: branchesList, areFetched } = branches || {}
    branchesList = branchesList || []
    const permissionType = await q('getPermissionType', { id })
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    handlers.permissionTypeFormPopulate(permissionType, branchesList, account)
  })

// Save
payloads$(actions.PERMISSION_TYPE_FORM_SAVE)
  .subscribe(async ({ permissionType, scrollToError }) => {
    const state = store.getState()
    let { permissionTypes, resources, branches } = state
    permissionTypes = permissionTypes || {}
    let { list: permissionTypesList } = permissionTypes || {}
    permissionTypesList = permissionTypesList || []
    const { list: resourcesList } = resources || {}
    const { list: branchesList } = branches || {}
    const permissionTypeExternalIds = permissionTypesList.map(permissionType => permissionType.externalId).filter(Boolean) || []
    const errors = permissionTypeFormValidate(permissionType, permissionTypeExternalIds)
    if (errors && errors.length) return setPermissionTypeFormSaveErrors(errors, scrollToError)
    const transformedPermissionType = permissionTypeSaveTransform(permissionType, resourcesList)
    const globalFilterConfig = transformedPermissionType.globalFilterConfig
    if (globalFilterConfig) {
      delete transformedPermissionType.globalFilterConfig
    }
    const savedPermissionType = await q('savePermissionType', { permissionsGroup: transformedPermissionType, globalFilterConfig })
    const { error, id } = savedPermissionType || {}
    const translations = { resource: t('global.resourceCategory') }
    if (error) return setPermissionTypeFormSaveErrors(permissionTypeFormServerErrorsTransform({ error, branchesList, translations }), scrollToError)
    if (permissionType.id) {
      handlers.permissionTypeUpdate(savedPermissionType)
    } else {
      handlers.permissionTypeSave(savedPermissionType)
    }
    handlers.navigateToPath(`/management/permission-types/${id}`)
    handlers.permissionTypesFilterFormGet()
  })

const setPermissionTypeFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('permissionType', errors)
  setTimeout(() => {
    scrollToError && scrollToError(errors)
  }, 0)
  handlers.permissionTypeFormReady()
}

// Delete
payloads$(actions.PERMISSION_TYPE_DELETE)
  .subscribe(async ({ id }) => {
    await q('deletePermissionsGroup', { id })
    handlers.permissionTypeDeleted(id)

    handlers.navigateToPath('/management/permission-types')
    handlers.permissionTypesFilterFormGet()
  })
