import React from 'react'
import {
  MultipleSelect,
  Title,
  FormSection,
  ColorSelect,
  PriceInput,
  Textarea,
  Input,
  Row,
  Col,
  t,
  Alert
} from '../../../Common'
import {
  CourseFormParticipantsWrapper,
  CourseFormDurationWrapper,
  CourseFormSettingsWrapper
} from '../../../Beasts'
import { CourseFormDependencies } from '../../../Beauties'

import './CourseForm.css'
import { dangerousHTML } from '../../../Utils'

const CourseFormSummary = props => {
  const { showExternalIds, hasAll, type } = props

  return (
    <>
      {(showExternalIds &&
        <FormSection>
          <Title size='m' label={t('global.externalId.label')} icon='fingerprint' ignoreTopOffset />
          <Row>
            <Col>
              <Input
                name='externalId'
                label={t('global.externalId.label')}
                hintText={t('global.externalId.hint')}
              />
            </Col>
          </Row>
        </FormSection>
      )}
      <FormSection>
        <Title size='m' isCompact label={t('global.basicDetails')} icon='sliders-h' ignoreTopOffset={!showExternalIds} />
        <Row>
          <Col>
            <ColorSelect name='color'>
              <Input
                name='name'
                label={t('global.name.label')}
                hintText={t('servicesGroups.form.group.section.details.fieldName.hint')}
                mandatory
              />
            </ColorSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <Textarea
              useEditor
              name='description'
              label={t('global.description.label')}
              limit={400}
            />
          </Col>
        </Row>
      </FormSection>
      <CourseFormDurationWrapper />
      <CourseFormParticipantsWrapper />
      <CourseFormDependencies />
      <FormSection>
        <Title size='m' isCompact label={t('global.price.label')} icon='money-bill' />
        <PriceInput
          name='price'
          label={t('global.price.label')}
          hintText={t('global.price.hint')}
        />
      </FormSection>
      <CourseFormSettingsWrapper />
      <FormSection>
        <Title size='m' isCompact label={t('global.location')} icon='building' />
        <Row>
          <Col>
            <MultipleSelect
              name='location'
              label={t('global.select.label')}
              searchable
              hasAll={hasAll}
              disableSelected={type === 'edit'}
              allLabel={t('global.allLocations')}
            />
          </Col>
        </Row>
        {(type === 'edit' &&
          <Alert theme='alert'>
            <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
          </Alert>
        )}
      </FormSection>
    </>
  )
}

export default CourseFormSummary
