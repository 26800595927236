import React from 'react'
import { connect } from '../../Store'
import { ServiceFormReminders } from '../../Beauties'

const ServiceFormRemindersWrapper = props => {
  const {
    serviceCustomReminderCustomerSwitch,
    serviceReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    serviceCustomReminderStaffSwitch,
    serviceReminderStaffSwitch,
    serviceReminderStaffOthersCheckBox,
    serviceReminderStaffOthers,
    resourceEmailRemindersMinutes,
    settingsResourceRemindersEmailRecipients,
    settingsCustomerEmailRemindersMinutes
  } = props

  return (
    <ServiceFormReminders
      serviceCustomReminderCustomerSwitch={serviceCustomReminderCustomerSwitch}
      serviceReminderCustomerSwitch={serviceReminderCustomerSwitch}
      customerEmailRemindersMinutes={customerEmailRemindersMinutes}
      serviceCustomReminderStaffSwitch={serviceCustomReminderStaffSwitch}
      serviceReminderStaffSwitch={serviceReminderStaffSwitch}
      serviceReminderStaffOthersCheckBox={serviceReminderStaffOthersCheckBox}
      serviceReminderStaffOthers={serviceReminderStaffOthers || []}
      resourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
      settingsResourceRemindersEmailRecipients={settingsResourceRemindersEmailRecipients}
      settingsCustomerEmailRemindersMinutes={settingsCustomerEmailRemindersMinutes}
    />
  )
}

const maps = state => ({
  serviceCustomReminderCustomerSwitch: state.forms.services.serviceCustomReminderCustomerSwitch && state.forms.services.serviceCustomReminderCustomerSwitch.value,
  serviceReminderCustomerSwitch: state.forms.services.serviceReminderCustomerSwitch && state.forms.services.serviceReminderCustomerSwitch.value,
  customerEmailRemindersMinutes: state.forms.services.customerEmailRemindersMinutes && state.forms.services.customerEmailRemindersMinutes.values,
  serviceCustomReminderStaffSwitch: state.forms.services.serviceCustomReminderStaffSwitch && state.forms.services.serviceCustomReminderStaffSwitch.value,
  serviceReminderStaffSwitch: state.forms.services.serviceReminderStaffSwitch && state.forms.services.serviceReminderStaffSwitch.value,
  serviceReminderStaffOthersCheckBox: state.forms.services.serviceReminderStaffOthersCheckBox && state.forms.services.serviceReminderStaffOthersCheckBox.value,
  serviceReminderStaffOthers: state.forms.services.serviceReminderStaffOthers && state.forms.services.serviceReminderStaffOthers.values,
  resourceEmailRemindersMinutes: state.forms.services.resourceEmailRemindersMinutes && state.forms.services.resourceEmailRemindersMinutes.values,
  settingsResourceRemindersEmailRecipients: state.company.settings && state.company.settings.resourceRemindersEmailRecipients,
  settingsCustomerEmailRemindersMinutes: state.company.settings && state.company.settings.customerEmailRemindersMinutes
})

export default connect(maps)(ServiceFormRemindersWrapper)
