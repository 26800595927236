import React from 'react'
import { connect, handlers } from '../../Store'
import { Title, Button, t } from '../../Common'

const FormDiscardPopup = props => {
  let { isActive } = props
  isActive = !!isActive
  const classNames = ['ta-form__discard-popup']
  if (isActive) classNames.push('active')

  const onClickNo = () => {
    handlers.formDiscardPopupReset(null)
  }

  const onClickYes = () => {
    let { redirectUrl, router } = props
    redirectUrl = redirectUrl || null
    router = router || {}
    let { props: routerProps } = router || {}
    routerProps = routerProps || {}
    const { formDiscard } = routerProps || {}
    handlers.formDiscardPopupReset(formDiscard)
    redirectUrl && handlers.navigateToPath(redirectUrl)
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-form__discard-popup__overlay' />
      <div className='ta-form__discard-popup__content'>
        <Title size='m' label={t('global.discardChangesPopUp.title')} icon='save' ignoreTopOffset />
        <Button as='link' isSecondary onClick={onClickNo}>{t('buttons.cancel.label')}</Button>
        <Button as='link' isPrimary onClick={onClickYes}>{t('global.discard')}</Button>
      </div>
    </div>
  )
}

const maps = state => ({
  isActive: state.forms.isDiscardPopupActive,
  redirectUrl: state.forms.discardRedirectUrl,
  router: state.router
})

export default connect(maps)(FormDiscardPopup)
