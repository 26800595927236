import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupResourcesAccountUnlink = props => {
  return (
    <div className='ta-popup__resources-account-unlink'>
      <div className='ta-popup__text'>
        {dangerousHTML(t('popup.resourcesAccount.unlink'))}
      </div>
    </div>
  )
}

export default PopupResourcesAccountUnlink
