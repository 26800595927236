import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { handlers, store, connect } from '../../../Store'
import { PopupBranchDelete } from '../../../Beauties'
import { Loader } from '../../../Common'

export const PopupBranchDeleteWrapper = props => {
  let { pending, passwordFieldType, form } = props
  form = form || {}
  passwordFieldType = passwordFieldType || 'password'

  useEffect(() => {
    handlers.formFieldsUpdate('branchesDelete', { password: {} })
  }, [])

  const approve = cb => {
    const { id } = props
    if (id) {
      const form = store.getState().forms.branchesDelete || {}
      form.id = id
      handlers.branchDeleteFormSave(form, (errors) => {
        cb && cb(errors)
      })
    }
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  const togglePasswordFieldType = () => {
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('branchesDelete', { password })

    return password.type
  }

  return (
    <>
      <Loader active={pending} />
      <PopupBranchDelete
        approve={approve}
        reject={reject}
        togglePasswordFieldType={togglePasswordFieldType}
        passwordFieldType={passwordFieldType}
      />
    </>
  )
}

PopupBranchDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

const maps = state => ({
  form: state.forms.branchesDelete,
  pending: state.branches && state.branches.pendingDeleteForm,
  passwordFieldType: state.forms.branchesDelete && state.forms.branchesDelete.password && state.forms.branchesDelete.password.type
})

export default connect(maps)(PopupBranchDeleteWrapper)
