import React, { Component } from 'react'
import { feedContextInProps } from '../../Utils'
import { AccordionContext } from '../../Common'

class AccordionTitle extends Component {
  constructor (props, context) {
    super(props)
  }

  render () {
    const { children, toggle, className } = this.props
    const classNames = ['ta-accordion__title']
    if (className) classNames.push(className)

    return (
      <div className={classNames.join(' ')} onClick={toggle}>
        {children}
      </div>
    )
  }
}

export default feedContextInProps(AccordionTitle, AccordionContext)
