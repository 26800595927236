import React from 'react'
import {
  ServiceFormDurationWrapper,
  ServiceFormSettingsWrapper
} from '../../../Beasts'
import {
  ServiceFormDependencies,
  ServiceFormExternalId,
  ServiceFormBasicDetails,
  ServiceFormPrice
} from '../../../Beauties'
import {
  FormSection,
  Title,
  Row,
  Col,
  MultipleSelect,
  t,
  Alert
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './ServiceForm.css'

const ServiceFormSummary = props => {
  const { showExternalIds, customisations, hasAll, type } = props
  return (
    <>
      {(showExternalIds &&
        <ServiceFormExternalId />
      )}
      <ServiceFormBasicDetails ignoreTopOffset={!showExternalIds} />
      <ServiceFormDurationWrapper customisations={customisations} />
      <ServiceFormDependencies />
      <ServiceFormPrice />
      <ServiceFormSettingsWrapper customisations={customisations} />
      <FormSection>
        <Title size='m' isCompact label={t('global.location')} icon='building' />
        <Row>
          <Col>
            <MultipleSelect
              name='location'
              label={t('global.select.label')}
              position='top'
              searchable
              hasAll={hasAll}
              disableSelected={type === 'edit'}
              allLabel={t('global.allLocations')}
            />
          </Col>
        </Row>
        {(type === 'edit' &&
          <Alert theme='alert'>
            <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
          </Alert>
        )}
      </FormSection>
    </>
  )
}

export default ServiceFormSummary
