import React from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  SingleSelect,
  t
} from '../../Common'

const BranchesFilter = props => {
  return (
    <Form name='branchesFilter'>
      <Row>
        <Col>
          <Input
            name='search'
            label={t('global.search.label')}
            clearable
          />
        </Col>
        <Col size={50}>
          <SingleSelect
            label={t('global.tag.label')}
            name='tagsFilter'
            formName='branchesFilter'
            clearable
          />
        </Col>
      </Row>
    </Form>
  )
}

export default BranchesFilter
