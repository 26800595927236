import React from 'react'
import {
  SimpleListItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  FontAwesome5,
  t,
  Button
} from '../../Common'
import { truncateText, dangerousHTML } from '../../Utils'

const ResourceCategoriesListItem = props => {
  const { category, index, isLast } = props
  const { id, name, isDependency, locallyUpdatedBranches } = category || {}
  const globalLabelClassNames = ['label', 'edited']
  const locallyChangedBranches = (locallyUpdatedBranches && Object.keys(locallyUpdatedBranches).length) || null
  const resourceItemPopOverPosition = name.length > 1 ? index < 3 ? 'bottom' : 'top' : 'right'

  return (
    <div className='ta-resource-categories__category'>
      <SimpleListItem
        key={id}
        category={category}
        index={index}
        isLast={isLast} // - 2 because we are skipping the first category (the default one)
        to={`/management/resource-categories/${id}`}
      >
        <div className='ta-resource-categories__category-name'>
          {truncateText(name === 'default' ? t('branches.form.resource.permissions.sectionDefault.title') : name, 27, true)}
          {(locallyChangedBranches &&
            <HoverPopup className='ta-global-label'>
              <HoverPopupContent position={resourceItemPopOverPosition}>
                {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
              </HoverPopupContent>
              <Button as='link' to={`/management/resource-categories/${id}@@changedLocally`}>
                <HoverPopupTrigger>
                  <div className={globalLabelClassNames.join(' ')}>
                    <FontAwesome5 icon='pencil' type='s' />
                    {t('global.changedLocally')}
                  </div>
                </HoverPopupTrigger>
              </Button>
            </HoverPopup>
          )}
        </div>

        <div className={`ta-resoruce-category__type label ${isDependency ? 'dependency' : 'bookable'}`}>
          {isDependency ? t('global.dependency') : t('global.bookable')}
        </div>
      </SimpleListItem>
    </div>
  )
}

export default ResourceCategoriesListItem
