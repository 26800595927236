import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormSection,
  Row,
  Col,
  Input,
  Divider,
  FormButtons,
  Button,
  t,
  BorderedBox,
  FontAwesome5,
  Error
} from '../../../Common'

import './BranchesPreviewResourcesPasswordChangeForm.css'

const BranchesPreviewResourcesPasswordChangeForm = props => {
  const {
    onSubmit,
    cancelLink,
    onGeneratePassword,
    generatedPassword,
    onCopyPassword,
    newPasswordFieldType,
    repeatPasswordFieldType,
    yourPasswordFieldType,
    toggleNewPasswordFieldType,
    toggleRepeatPasswordFieldType,
    toggleYourPasswordFieldType
  } = props

  return (
    <div className='ta-branches-resources-password-change__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('global.passwordChange')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesResourcePasswordChange'>
            <FormSection>
              <Title size='m' label={t('branches.form.changePassword.section.newPassword.title')} icon='lock-alt' />
              <Row>
                <Col className='relative'>
                  <Input
                    name='newPassword'
                    label={t('branches.form.changePassword.section.newPassword.title')}
                    mandatory
                    type={newPasswordFieldType}
                  />
                  <div
                    className='ta-branches-resources-password-change__password-type-toggle-btn'
                    onClick={toggleNewPasswordFieldType}
                  >
                    <FontAwesome5 icon={newPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='relative'>
                  <Input
                    name='repeatPassword'
                    label={t('global.passwordRepeat.label')}
                    mandatory
                    type={repeatPasswordFieldType}
                  />
                  <div
                    className='ta-branches-resources-password-change__password-type-toggle-btn'
                    onClick={toggleRepeatPasswordFieldType}
                  >
                    <FontAwesome5 icon={repeatPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Divider>{t('global.or')}</Divider>
              <Button
                as='link'
                className='ta-btn ta-branches-resources-password-change__generate-btn'
                block
                isSecondary
                onClick={onGeneratePassword}
              >
                {t('branches.form.changePassword.generatePassword.button.label')}
              </Button>
              {(generatedPassword &&
                <Row noOffset>
                  <Col>
                    <BorderedBox topOffset className='ta-branches-resources-password-change__generate-box'>
                      <div className='ta-branches-resources-password-change__generate-title'>
                        {t('branches.form.changePassword.section.newPassword.title')}:
                      </div>
                      <div className='ta-branches-resources-password-change__generate-password'>
                        {generatedPassword}
                      </div>
                      <Button
                        as='link'
                        isSecondary
                        className='ta-btn ta-branches-resources-password-change__generate-copy'
                        onClick={onCopyPassword}
                      >
                        <FontAwesome5 icon='copy' type='solid' />
                      </Button>
                    </BorderedBox>
                  </Col>
                </Row>
              )}
            </FormSection>
            <FormSection ignoreBottomOffset>
              <Title size='m' label={t('branches.form.changePassword.section.confirm.title')} icon='check-double' />
              <Row>
                <Col className='relative'>
                  <Input
                    name='yourPassword'
                    label={t('global.yourPassword.label')}
                    mandatory
                    type={yourPasswordFieldType}
                  />
                  <div
                    className='ta-branches-resources-password-change__password-type-toggle-btn'
                    onClick={toggleYourPasswordFieldType}
                  >
                    <FontAwesome5 icon={yourPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
            </FormSection>
            <FormSection ignoreBottomOffset>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesPreviewResourcesPasswordChangeForm
