import { fromEventPattern } from 'rxjs'
import { map, startWith, distinctUntilChanged } from 'rxjs/operators'

import { handlers, store } from '../../Store'
import { getRoute, onBeforeEach, onAfterEach, history } from './lib'

const getFullPath = route => route && (route.pathname || '') + (route.search || '')
// All routes pulled from history event are dispatched into router store
fromEventPattern(history.listen)
  .pipe(
    map(() => getFullPath(history.location) + window.location.hash),
    startWith(getFullPath(history.location) + window.location.hash),
    distinctUntilChanged()
  ).subscribe(fullPathRequested => {
    const state = store.getState()
    const route = getRoute(fullPathRequested)

    const newRoute = {
      name: route.name,
      pattern: route.pattern,
      path: route.path,
      parentPath: route.parentPath,
      fullPath: route.fullPath,
      oldPath: state.router.fullPath,
      hash: route.hash,
      params: { ...route.params },
      query: { ...route.query },
      data: { ...route.params, ...route.query },
      props: { ...route.props || {} }
    }
    if (state.popup && state.popup.content) handlers.popupHide()
    if (state?.modal?.isActive) {
      handlers.modalHide()
      handlers.overlayHide('top')
    }
    // Hook onBefore each route
    if (onBeforeEach && onBeforeEach(newRoute, state, handlers)) return history.goBack()
    // Hook onBefore route
    if (route.onBefore && route.onBefore(newRoute, state, handlers)) return history.goBack()
    handlers.routeChanged(newRoute)
    // WARNING: without this setTimeout onAfter is not triggered
    setTimeout(() => {
    // Hook onAfter route
      route.onAfter && route.onAfter(newRoute, state, handlers)
      // Hook onAfter each route
      onAfterEach && onAfterEach(newRoute, state, handlers)
    }, 0)
  })
