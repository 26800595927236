import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAccountInvoicesHelpBox = props => {
  return (
    <Alert noOffset noBorder label={t('popup.account.invoices.help.label')} theme='alert'>
      <div className='ta-popup__text'>
        {dangerousHTML(t('popup.account.invoices.help.message'))}
      </div>
    </Alert>
  )
}

export default PopupAccountInvoicesHelpBox
