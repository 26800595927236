import React from 'react'
import {
  BranchesPreviewServiceFormSettingsWrapper,
  BranchesServiceCombinationFormDurationWrapper,
  BranchesServiceCombinationFormPriceWrapper
} from '../../../../Beasts'
import {
  BranchesServiceFormExternalId,
  BranchesServiceFormBasicDetails,
  BranchesServiceCombinationFormServicesSelector
} from '../../../../Beauties'
import { FormSection } from '../../../../Common'

import './BranchesServiceCombinationForm.css'

const BranchesServiceCombinationFormSummary = props => {
  const {
    showExternalIds,
    plan,
    allowedSet
  } = props

  return (
    <>
      {(showExternalIds &&
        <BranchesServiceFormExternalId allowedSet={allowedSet} />
      )}
      <BranchesServiceFormBasicDetails allowedSet={allowedSet} plan={plan} hideColorSelector ignoreTopOffset={!showExternalIds} />
      <FormSection>
        <BranchesServiceCombinationFormServicesSelector allowedSet={allowedSet} />
        <BranchesServiceCombinationFormDurationWrapper allowedSet={allowedSet} />
      </FormSection>
      <BranchesServiceCombinationFormPriceWrapper allowedSet={allowedSet} />
      <BranchesPreviewServiceFormSettingsWrapper isCombination allowedSet={allowedSet} />
    </>
  )
}

export default BranchesServiceCombinationFormSummary
