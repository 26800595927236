import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { CourseChangedLocallyRestore } from '../../Beauties'

const CourseChangedLocallyRestoreWrapper = props => {
  const { pending, form, course, branch } = props
  useEffect(() => {
    handlers.courseRestoreFormGet(course)
  }, [])

  const onSubmit = () => {
    if (!branch) {
      let { locallyUpdatedBranches } = course
      locallyUpdatedBranches = locallyUpdatedBranches || {}
      handlers.courseLocallyChangedReset({
        companyIds: Object.keys(locallyUpdatedBranches),
        internalId: course.internalId
      })
    } else {
      handlers.courseLocallyChangedReset({
        companyIds: [branch.id],
        internalId: course.internalId
      })
    }
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CourseChangedLocallyRestore
          title={t('globals.restore.form.heading', [{ key: 'ELEMENT', value: t('global.groups') }])}
          cancelLink={`/management/courses/${course.id}`}
          onSubmit={onSubmit}
          form={form}
          branch={branch}
          course={course}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  branch: (state.router.data.branchId && state.branches.list.find(b => b.id === state.router.data.branchId)),
  course: (state.router.data.id && state.courses.list.find(r => r.id === state.router.data.id)) || {},
  form: state.forms.courseChangedLocally,
  pending: state.courses.pendingForm
})

export default memo(connect(maps)(CourseChangedLocallyRestoreWrapper))
