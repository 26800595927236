import React, { useEffect } from 'react'
import { connect, handlers } from '../../../Store'
import { Loader } from '../../../Common'
import { UsersPreview } from '../../../Beauties'

const UserPreviewWrapper = props => {
  let { id, areFetched, account } = props
  account = account || {}
  let { enterprisePermissions, isAdmin, userId: accountUserId, isOwner: isAccountOwner } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { usersRead, usersWrite, usersDelete } = enterprisePermissions || {}
  isAccountOwner = !!isAccountOwner
  usersRead = !!isAdmin || !!usersRead
  usersWrite = !!isAdmin || !!usersWrite
  usersDelete = !!isAdmin || !!usersDelete
  useEffect(() => {
    if (!usersRead) return handlers.navigateToPath('/managers/users')
    if (areFetched) handlers.usersPreviewGet({ id })
  }, [id, areFetched, usersRead])

  let {
    pending,
    hash,
    users
  } = props
  users = users || []
  pending = usersRead && !!pending
  const user = users.find(user => user.id === id) || {}
  let { roles } = user || {}
  roles = roles || []
  const isOwner = roles.includes('OWNER')
  const isMyself = id === accountUserId
  const isDeleteEnabled = usersDelete && !isOwner && !isMyself
  const isEditEnabled = isOwner ? isAccountOwner : usersWrite

  return (
    pending
      ? <Loader active={pending} />
      : (
        <UsersPreview
          user={user}
          hash={hash}
          usersWrite={usersWrite}
          isDeleteEnabled={isDeleteEnabled}
          isEditEnabled={isEditEnabled}
        />
        )
  )
}

const maps = state => ({
  pending: state.users.pendingPreview,
  id: state.router.data.id,
  hash: state.router.hash,
  users: state.users.list,
  account: state.account,
  areFetched: state.users.areFetched
})

export default connect(maps)(UserPreviewWrapper)
