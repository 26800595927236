import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { ServicePreviewDetails } from '../../Beauties'

const ServicePreviewDetailsWrapper = props => {
  let {
    service,
    category,
    resources,
    services,
    availableResourceCategories
  } = props
  service = service || {}
  category = category || {}
  resources = resources || []
  services = services || []
  availableResourceCategories = availableResourceCategories || []
  const dependencies = (service.dependencies && service.dependencies.map(dependency => {
    const dependencyCategory = dependency.specificResourceCategoryIds && dependency.specificResourceCategoryIds[0]
    const resourcesCategories = [...new Set(
      (dependency.specificResourceIds && dependency.specificResourceIds.length > 0
        ? resources.filter(item => dependency.specificResourceIds.includes(item.id))
        : resources
      ).map(item => availableResourceCategories.find(category => category.id === item.categoryId))
    )]
    return {
      category: dependency.specificResourceIds
        ? resourcesCategories.length > 1 ? 'all' : resourcesCategories[0]
        : availableResourceCategories.find(item => item.id === dependencyCategory),
      workInParallel: dependency.workInParallel,
      resources: (dependency.specificResourceIds
        ? dependency.specificResourceIds.length === 0
          ? ['all']
          : dependency.specificResourceIds.map(item => resources.find(resource => resource.id === item))
        : ['all']
      ).filter(Boolean)
    }
  })) || []
  const dependenciesResources = dependencies.reduce((acc, item) => {
    item.resources.forEach(resource => {
      if (resource === 'all' && item.category === 'all') acc = resources
      if (resource === 'all' && item.category !== 'all') {
        const resourcesToAdd = resources.filter(res => res.categoryId === (item.category && item.category.id))
          .filter(res => !acc.find(item => item.id === res.id))
        acc = [...acc, ...resourcesToAdd]
      }
      if (resource !== 'all') {
        const resourceIncluded = acc.find(item => item.id === resource.id)
        if (!resourceIncluded) acc = [...acc, resource]
      }
    })
    return acc
  }, [])
  const dependenciesCategoriesWithAllResources = dependencies
    .filter(item => item.category !== 'all' && item.resources.includes('all'))
    .map(item => item.category && item.category.id)
  const dependenciesCategories = dependenciesResources.reduce((acc, item) => {
    const categoryIncluded = acc.find(category => category.id === item.categoryId)
    if (!categoryIncluded) return [...acc, availableResourceCategories.find(category => category.id === item.categoryId)]
    return acc
  }, []).filter(Boolean).map(item => ({
    ...item, hasAllResources: dependenciesCategoriesWithAllResources.includes(item.id)
  }))
  const areAllResourcesSelected = !!dependencies.find(item => item.category === 'all' && item.resources.includes('all'))

  return (
    <ServicePreviewDetails
      {...service}
      allServices={services}
      category={category}
      dependenciesResources={dependenciesResources}
      dependenciesCategories={dependenciesCategories}
      areAllResourcesSelected={areAllResourcesSelected}
    />
  )
}

ServicePreviewDetailsWrapper.propTypes = {
  service: PropTypes.object.isRequired,
  category: PropTypes.object
}

const maps = (state, props) => ({
  services: state.services.list,
  category: (props.service && state.services.categoriesList.find(category => category.id === props.service.categoryId)) || {},
  resources: state.resources.list,
  availableResourceCategories: state.resources.categoriesList
})

export default connect(maps)(ServicePreviewDetailsWrapper)
