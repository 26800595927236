import React from 'react'
import { Button, SimpleListItem, FontAwesome5, Alert, t } from '../../Common'

const ResourceCategoryToDelete = props => {
  let { resourceCategoriesToDeleteData, onClickLogin } = props
  resourceCategoriesToDeleteData = resourceCategoriesToDeleteData || []

  return (
    <div className='ta-resource-categories__to-delete'>
      {(resourceCategoriesToDeleteData.length > 0 &&
        <Alert
          label={t('resource.categories.delete.pending')}
          theme='alert'
        >
          {t('resource.category.delete.warning')}
        </Alert>
      )}
      {resourceCategoriesToDeleteData.map(item => {
        let { branch = {}, resourceCategory = {} } = item
        branch = branch || {}
        resourceCategory = resourceCategory || {}
        return (
          <SimpleListItem className='ta-resource-categories-to-delete--branch' key={resourceCategory.id}>
            <div className='ta-resource-categories-to-delete--branch__data'>
              <p className='ta-resource-categories-to-delete--branch__name'>{branch.name}</p>
              <div className='ta-resource-categories-to-delete--item'>
                <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
              </div>
              <div className='ta-resource-categories-to-delete--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{resourceCategory.id}</p>
              </div>
            </div>
            <Button as='link' className='ta-list-button' external onClick={() => onClickLogin(branch, true)}>
              <FontAwesome5 icon='key' />
            </Button>
          </SimpleListItem>
        )
      }
      )}
      {(!resourceCategoriesToDeleteData.length &&
        <Alert noOffset label={t('resource.category.noResults')} theme='no-content' />
      )}
    </div>
  )
}

export default ResourceCategoryToDelete
