// Validation
export const userGroupsFormValidate = userGroup => {
  if (!userGroup) return
  const errors = []
  if (!userGroup.name || (userGroup.name && userGroup.name.value === '')) errors.push({ key: 'name', value: 'Name is mandatory!' })
  if (!userGroup.email || (userGroup.email && userGroup.email.value === '')) errors.push({ key: 'email', value: 'Email is mandatory!' })
  return errors
}
export const userGroupsFormServerErrorsTransform = error => {
  return [{
    key: 'userGroupsGlobalErrors',
    value: error.message || 'Something went wrong!'
  }]
}
// FORM
export const userGroupsFormTransform = (userGroup) => {
  const result = {
    userType: {
      value: (userGroup && userGroup.type) || 'tapfiliate'
    },
    name: {
      value: (userGroup && userGroup.name) || ''
    },
    // TODO @Mitko this should be company name/ wait razvan
    nameCompany: {
      value: (userGroup && userGroup.name) || ''
    },
    phoneNumber: {
      value: (((userGroup && userGroup.phone) && userGroup.phone.number) && userGroup.phone.number) || ''
    },
    email: {
      value: (userGroup && userGroup.email) || ''
    },
    street: {
      value: ((userGroup && userGroup.address) && userGroup.address.street) || ''
    },
    zipCode: {
      value: ((userGroup && userGroup.address) && userGroup.address.zipCode) || ''
    },
    city: {
      value: ((userGroup && userGroup.address) && userGroup.address.city) || ''
    }
  }
  return result
}

export const userGroupsSaveTransform = (companyLive, userGroupId) => {
  return {
    id: userGroupId || '',
    type: (companyLive.userType && companyLive.userType.value) || '',
    name: (companyLive.nameCompany && companyLive.nameCompany.value) || '',
    email: (companyLive.email && companyLive.email.value) || '',
    phone: {
      number: (companyLive.phoneNumber && companyLive.phoneNumber.value) || '',
      userGroup: (companyLive.userGroupselect && companyLive.userGroupselect.value) || ''
    },
    address: {
      userGroup: (companyLive.userGroupselect && companyLive.userGroupselect.value) || '',
      street: (companyLive.street && companyLive.street.value) || '',
      zipCode: (companyLive.zipCode && companyLive.zipCode.value) || '',
      city: (companyLive.city && companyLive.city.value) || ''
    }
  }
}
