import React, { useEffect } from 'react'

import { connect, handlers, selectors } from '../../../Store'
import { isRouteDisabledByCustomisations } from '../../../Common'
import { AppsSubscriptionFailed } from '../../../Beauties'

// const mock = {
//   daysLeft: 0,
//   paymentAdded: false
// }

const AppsSubscriptionFailedWrapper = props => {
  let {
    id,
    app,
    isOwner,
    pending,
    paymentMethods,
    chargebee
  } = props
  app = app || {}
  // const {
  //   daysLeft
  //  paymentAdded
  // } = mock
  const paymentAdded = paymentMethods && paymentMethods.length > 0
  chargebee = chargebee || {}
  const { noAutoCollect } = chargebee

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.appPreviewGet({ id })
    handlers.paymentMethodsGet()
    handlers.formErrorsSet('appPaymentRetry', [])
  }, [id])

  const handleOnClickContinue = () => {
    handlers.navigateToRoute('appsIframe', { id })
  }

  const handleOnClickPayNow = () => {
    handlers.appPaymentRetry({ id, externalId: app.externalId })
  }

  return (
    <AppsSubscriptionFailed
      {...app}
      isOwner={isOwner}
      pending={pending}
      onClickContinue={handleOnClickContinue}
      onClickPayNow={handleOnClickPayNow}
      paymentLink='/account/billing@@payments'
      invoiceLink='/account/invoices'
      paymentAdded={paymentAdded}
      noAutoCollect={noAutoCollect}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    pending: selectors.appsFieldSelector(state, { field: 'pendingPaymentRetry' }),
    app: selectors.appsFindByIdSelector(state, { id }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    paymentMethods: state.paymentMethods.list,
    chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' })
  }
}

export default connect(maps)(AppsSubscriptionFailedWrapper)
