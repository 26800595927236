import React from 'react'
import { Users } from '../../Beauties'
import { Blur, t } from '../../Common'

const UsersMock = props => {
  const usersMock = [{ name: 'User One', email: 'user1@email.com' }, { name: 'User Two', email: 'usertwooo@email.com' }, { name: 'User Three', email: 'u3@email.com' }, { name: 'User Four', email: 'user44444@email.com' }, { name: 'User Five', email: 'userfive5@email.com' }]
  const userPermissionTypesMock = []
  const searchMock = ''

  return (
    <Blur active label={t('global.accessDenied')} icon='ban'>
      <Users users={usersMock} userPermissionTypes={userPermissionTypesMock} search={searchMock} />
    </Blur>
  )
}

export default UsersMock
