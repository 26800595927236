import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../../Store'
import { customersTypes, eugdprCustomerFrequencyUnit } from '../../../../Settings'
import {
  FixedContentHeader,
  FixedContentBody,
  FixedContent,
  Title,
  FontAwesome5,
  FormButtons,
  FormSection,
  BorderedBox,
  FormGroup,
  Checkbox,
  FormText,
  Alert,
  Select,
  Input,
  Error,
  Button,
  Form,
  Row,
  Col,
  t
} from '../../../../Common'
import { dangerousHTML } from '../../../../Utils'

import '../CustomersSettings.css'

const CustomersSettingsForm = props => {
  let {
    onSubmit,
    cancelLink,
    eugdprEnabled,
    eugdprRules,
    eugdprRulesErrors,
    tags,
    customersMiddlewareUrl
  } = props
  eugdprRules = eugdprRules || []
  let customersTypesOptions = customersTypes.map(item => ({
    value: item.value,
    label: item.label
  }))
  if (customersMiddlewareUrl) customersTypesOptions = customersTypesOptions.filter(item => item.value !== 'BOOKER')
  const frequencyUnitOptions = eugdprCustomerFrequencyUnit.map(item => ({
    value: item.value,
    label: item.label
  }))

  const onChange = (value, index, field) => {
    if (!eugdprRules[index]) return
    const newRules = [...eugdprRules].map(item => ({ ...item }))
    newRules[index][field] = value || null
    handlers.formFieldsUpdate('settingsCustomers', {
      eugdprRules: {
        values: [...newRules],
        errors: eugdprRulesErrors
      }
    })
  }

  const deleteRule = index => {
    handlers.formFieldsUpdate('settingsCustomers', {
      eugdprRules: {
        values: [...eugdprRules.filter((item, key) => key !== index)],
        errors: eugdprRulesErrors
      }
    })
  }

  const addRule = () => {
    handlers.formFieldsUpdate('settingsCustomers', {
      eugdprRules: {
        values: [
          ...eugdprRules,
          {
            customerType: null,
            customerTagId: null,
            frequency: '',
            frequencyUnit: null
          }
        ],
        errors: eugdprRulesErrors
      }
    })
  }

  return (
    <div className='ta-settings__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('settings.section.customers.form.title')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='settingsCustomers'>
            <FormSection>
              <Title size='m' isCompact label={t('settings.section.customers.eugdpr.heading')} icon='eraser' />
              <FormText>{t('settings.section.customers.form.eugdpr.infoText')}</FormText>
              <Row>
                <Col>
                  <Checkbox
                    name='eugdprEnabled'
                    label={t('global.enable')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(eugdprEnabled &&
                <>
                  <BorderedBox>
                    <Alert theme='alert'>
                      {dangerousHTML(t('settings.section.customers.form.eugdpr.warning.text'))}
                    </Alert>
                    <FormText className='thin-text'>{t('settings.section.customers.form.eugdpr.infoText.textTwo')}</FormText>

                    {eugdprRules.map((item, index) => {
                      const customerTypeClassNames = ['ta-settings-customers__eugdpr__rule-customer-type']
                      if (eugdprRules.length > 1 && item.customerType !== 'TAG') customerTypeClassNames.push('has-delete')
                      const customerTagClassNames = ['ta-settings-customers__eugdpr__rule-customer-tag']
                      if (eugdprRules.length > 1) customerTagClassNames.push('has-delete')

                      return (
                        <Row key={index}>
                          <Col>
                            <Row>
                              <Col>
                                <div className='ta-settings-customers__eugdpr__rule-container'>
                                  <FormText bold>{t('settings.section.customers.form.eugdpr.sectionTitle.label', [{ key: 'NUMBER', value: index + 1 }])}</FormText>
                                  <Row noOffset>
                                    <Col>
                                      <FormGroup
                                        className={customerTypeClassNames.join(' ')}
                                        filled={!!item.customerType}
                                        labelText={t('settings.section.customers.form.eugdpr.select.customerType.label')}
                                        labelMandatory
                                      >
                                        <Select
                                          className='ta-single-select'
                                          noResultsText={t('global.noResults')}
                                          value={item.customerType}
                                          onChange={(selectedValue) => onChange(selectedValue.value, index, 'customerType')}
                                          options={customersTypesOptions}
                                        />
                                      </FormGroup>
                                    </Col>
                                    {(item.customerType === 'TAG' &&
                                      <Col>
                                        <FormGroup
                                          className={customerTagClassNames.join(' ')}
                                          filled={!!item.customerTagId}
                                          labelText={t('settings.section.customers.types.tag')}
                                          labelMandatory
                                        >
                                          <Select
                                            className='ta-single-select'
                                            noResultsText={t('global.noResults')}
                                            value={item.customerTagId}
                                            onChange={(selectedValue) => onChange(selectedValue.value, index, 'customerTagId')}
                                            options={tags.filter(tag => item.customerTagId === tag.value || !eugdprRules.find(rule => rule.customerTagId === tag.value))}
                                          />
                                        </FormGroup>
                                      </Col>
                                    )}
                                  </Row>
                                  {(eugdprRules.length > 1 &&
                                    <Button className='ta-customer-settings__btn-delete' isSecondary icon='trash' onClick={() => deleteRule(index)} />
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <BorderedBox>
                              <FormText>{t('settings.section.customers.form.eugdpr.periodRule.infoText')}</FormText>
                              <Row>
                                <Col size={50}>
                                  <Input
                                    name='frequency'
                                    label={t('settings.section.customers.form.eugdpr.input.period.label')}
                                    mandatory
                                    value={item.frequency || ''}
                                    onChange={(name, value) => onChange(value, index, 'frequency')}
                                  />
                                </Col>
                                <Col size={50}>
                                  <FormGroup
                                    // className='ta-intervals-input__interval__from'
                                    filled={!!item.frequencyUnit}
                                    labelText={t('settings.section.customers.form.eugdpr.select.frequencyUnit.placeholder')}
                                  >
                                    <Select
                                      className='ta-single-select'
                                      noResultsText={t('global.noResults')}
                                      value={item.frequencyUnit}
                                      onChange={(selectedValue) => onChange(selectedValue.value, index, 'frequencyUnit')}
                                      options={frequencyUnitOptions}
                                      noLabel
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </BorderedBox>
                          </Col>
                        </Row>
                      )
                    })}
                    <Button iconType='regular' icon='plus' isTertiary isGhost isBlock className='ta-settings__btn-add' onClick={addRule}>
                      {t('settings.section.customers.form.eugdpr.button.addRule')}
                    </Button>
                  </BorderedBox>
                </>
              )}
            </FormSection>
            <FormSection>
              <Error name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>
                {t('buttons.cancel.label')}
              </Button>
              <Button type='submit' isPrimary>
                {t('buttons.saveChanges.label')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CustomersSettingsForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default CustomersSettingsForm
