import React from 'react'
import { ResourceCategoryPreviewChangedLocally } from '../../../Beauties'
import { connect, handlers } from '../../../Store'

const ResourceCategoryPreviewChangedLocallyWrapper = props => {
  let { resourceCategory, account } = props
  account = account || {}
  resourceCategory = resourceCategory || {}
  const { locallyChangedData, internalId } = resourceCategory || {}
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalResourceCategoriesWrite } = enterprisePermissions || {}
  globalResourceCategoriesWrite = !!isAdmin || !!globalResourceCategoriesWrite

  const onResetGlobalEntity = (companyId, region) => handlers.resourceCategoryLocallyChangedReset({ companyId, region, internalId })

  return (
    <ResourceCategoryPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalResourceCategoriesWrite={globalResourceCategoriesWrite}
    />
  )
}

const maps = state => ({
  resourceCategory: (state.router.data.categoryId && state.resources.categoriesList.find(item => item.id === state.router.data.categoryId)) || {},
  account: state.account
})

export default connect(maps)(ResourceCategoryPreviewChangedLocallyWrapper)
