import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Checkbox,
  FormText,
  BorderedBox,
  Error,
  t,
  SingleSelect,
  Radio,
  MultipleSelect,
  format
} from '../../../../Common'
import { dangerousHTML, getIntervals } from '../../../../Utils'
import { EmailSettingsRemindersSelect, EmailSettingsOtherRecipientsSelect } from '../../../../Beauties'

const EmailSettingsEditForm = props => {
  const {
    onSubmit,
    cancelLink,
    bookingReminderStaffSwitch,
    resourceEmailRemindersMinutes,
    resourceEmailRemindersMinutesErrors,
    bookingReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    customerEmailRemindersMinutesErrors,
    bookingConfirmationStaffOthersCheckBox,
    bookingConfirmationStaffSwitch,
    bookingConfirmationStaffOthers,
    bookingCancellationStaffOthersCheckBox,
    bookingCancellationStaffSwitch,
    bookingCancellationStaffOthers,
    bookingChangeStaffOthersCheckBox,
    bookingChangeStaffSwitch,
    bookingChangeStaffOthers,
    bookingReminderStaffOthersCheckBox,
    bookingReminderStaffOthers,
    dailyAgendaEmailsSwitch,
    dailyAgendaResources,
    resourcesCategories,
    customisations
  } = props
  const dailyIntervalTimeOptions = getIntervals(0, 24, 30).map((time) => ({ value: time, label: format(time, 'HH:mm', { isUTC: true, format: 'HH:mm' }) }))
  const isDailyAgendaSomeResources = dailyAgendaResources === 'some'
  const resourceCategoriesOptions = resourcesCategories.map(item => ({
    label: item.name === 'default' ? t('resources.list.sectionDefault.title') : item.name,
    value: item.id
  }))
  const { settings } = customisations || {}
  const { hideNotificationsDailyAgenda } = settings || {}
  return (
    <div className='ta-settings__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('global.editEmailSettings')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='settings'>
            <FormSection>
              <Title size='m' isCompact label={t('settings.section.confirmation.heading')} icon='calendar-check' ignoreTopOffset />
              <Row>
                <Col>
                  <FormText noOffset>{t('settings.section.emails.confirmation.form.infoText')}</FormText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingConfirmationCustomersSwitch'
                    label={t('servicesGroups.reminder.emails.customersAndParticipants')}
                    theme='switch'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingConfirmationStaffSwitch'
                    label={t('global.staff')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(bookingConfirmationStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingConfirmationStaffOwnerCheckBox'
                        label={t('global.accountOwner')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingConfirmationStaffResourcesCheckBox'
                        label={t('settings.emails.resources')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingConfirmationStaffOthersCheckBox'
                        label={t('settings.emails.others')}
                      />
                    </Col>
                  </Row>
                  {(bookingConfirmationStaffOthersCheckBox &&
                    <EmailSettingsOtherRecipientsSelect
                      recipients={bookingConfirmationStaffOthers}
                      name='bookingConfirmationStaffOthers'
                    />
                  )}
                </BorderedBox>
              )}
            </FormSection>
            <FormSection>
              <Title size='m' isCompact label={t('settings.emails.cancellation.heading')} icon='calendar-times' ignoreTopOffset />
              <Row>
                <Col>
                  <FormText noOffset>{t('settings.section.emails.cancellation.form.infoText')}</FormText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingCancellationCustomersSwitch'
                    label={t('servicesGroups.reminder.emails.customersAndParticipants')}
                    theme='switch'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingCancellationStaffSwitch'
                    label={t('global.staff')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(bookingCancellationStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingCancellationStaffOwnerCheckBox'
                        label={t('global.accountOwner')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingCancellationStaffResourcesCheckBox'
                        label={t('settings.emails.resources')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingCancellationStaffOthersCheckBox'
                        label={t('settings.emails.others')}
                      />
                    </Col>
                  </Row>
                  {(bookingCancellationStaffOthersCheckBox &&
                    <EmailSettingsOtherRecipientsSelect
                      recipients={bookingCancellationStaffOthers}
                      name='bookingCancellationStaffOthers'
                    />
                  )}
                </BorderedBox>
              )}
            </FormSection>
            <FormSection>
              <Title size='m' isCompact label={t('settings.emails.change.heading')} icon='calendar-edit' ignoreTopOffset />
              <Row>
                <Col>
                  <FormText noOffset>{t('settings.section.emails.change.form.infoText')}</FormText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingChangeCustomersSwitch'
                    label={t('servicesGroups.reminder.emails.customersAndParticipants')}
                    theme='switch'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingChangeStaffSwitch'
                    label={t('global.staff')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(bookingChangeStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingChangeStaffOwnerCheckBox'
                        label={t('global.accountOwner')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingChangeStaffResourcesCheckBox'
                        label={t('settings.emails.resources')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='bookingChangeStaffOthersCheckBox'
                        label={t('settings.emails.others')}
                      />
                    </Col>
                  </Row>
                  {(bookingChangeStaffOthersCheckBox &&
                    <EmailSettingsOtherRecipientsSelect
                      recipients={bookingChangeStaffOthers}
                      name='bookingChangeStaffOthers'
                    />
                  )}
                </BorderedBox>
              )}
            </FormSection>
            <FormSection>
              <Title size='m' isCompact label={t('settings.section.reminder.heading')} icon='bell' ignoreTopOffset />
              <Row>
                <Col>
                  <FormText noOffset>{dangerousHTML(t('settings.section.reminder.questionText'))}</FormText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    name='bookingReminderCustomerSwitch'
                    label={t('settings.section.reminder.customers.checkbox.label')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(bookingReminderCustomerSwitch &&
                <Row className='ta-row__no-margin'>
                  <Col>
                    <EmailSettingsRemindersSelect
                      reminders={customerEmailRemindersMinutes}
                      name='customerEmailRemindersMinutes'
                      errors={customerEmailRemindersMinutesErrors}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Checkbox
                    name='bookingReminderStaffSwitch'
                    label={t('settings.section.reminder.staff.checkbox.label')}
                    theme='switch'
                  />
                </Col>
              </Row>
              {(bookingReminderStaffSwitch &&
                <BorderedBox>
                  <Row>
                    <Col>
                      <BorderedBox className='ta-settings__form--outer-box'>
                        <Row>
                          <Col>
                            <Checkbox
                              name='bookingReminderStaffOwnerCheckBox'
                              label={t('global.accountOwner')}
                            />

                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox
                              name='bookingReminderStaffResourcesCheckBox'
                              label={t('settings.emails.resources')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Checkbox
                              name='bookingReminderStaffOthersCheckBox'
                              label={t('settings.emails.others')}
                            />
                          </Col>
                        </Row>
                        {(bookingReminderStaffOthersCheckBox &&
                          <EmailSettingsOtherRecipientsSelect
                            recipients={bookingReminderStaffOthers}
                            name='bookingReminderStaffOthers'
                          />
                        )}
                      </BorderedBox>
                    </Col>
                  </Row>
                  <EmailSettingsRemindersSelect
                    reminders={resourceEmailRemindersMinutes}
                    name='resourceEmailRemindersMinutes'
                    errors={resourceEmailRemindersMinutesErrors}
                    noOffset
                  />
                </BorderedBox>
              )}
            </FormSection>
            {!hideNotificationsDailyAgenda && (
              <FormSection>
                <Title size='m' isCompact label={t('settings.section.daily.agenda.heading')} icon='bell' ignoreTopOffset />
                <Row>
                  <Col>
                    <FormText noOffset>{t('settings.section.daily.agenda.form.infoText')}</FormText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Checkbox
                      name='dailyAgendaEmailsSwitch'
                      label={t('global.enable')}
                      theme='switch'
                    />
                  </Col>
                </Row>
                {dailyAgendaEmailsSwitch && (
                  <BorderedBox>
                    <Row>
                      <Col>
                        <SingleSelect
                          name='dailyAgendaTime'
                          options={dailyIntervalTimeOptions}
                          label={t('settings.section.daily.agenda.time.label')}
                          hintText={t('settings.section.daily.agenda.time.hintText')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio name='dailyAgendaResources' value='all' label={t('settings.section.dailyAgenda.allResources')} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio name='dailyAgendaResources' value='some' label={t('settings.section.daily.agenda.resourceGroup.label')} />
                      </Col>
                    </Row>
                    {isDailyAgendaSomeResources && (
                      <BorderedBox topOffset className='ta-settings__form__daily-agenda__resources-group__bordered-box'>
                        <Title theme='striped' label={t('settings.section.daily.agenda.resourceGroup.stripeTitle')} />
                        <Row>
                          <Col>
                            <MultipleSelect
                              name='dailyAgendaResourceCategory'
                              noResultsText={t('global.noResults')}
                              options={resourceCategoriesOptions}
                              label={t('settings.section.daily.agenda.resourceGroup.placeholder')}
                              searchable
                            />
                          </Col>
                        </Row>
                      </BorderedBox>
                    )}
                    <Row />
                  </BorderedBox>
                )}
              </FormSection>
            )}
            <FormSection>
              <Title size='m' isCompact label={t('global.language.label')} icon='envelope' ignoreTopOffset />
              <Row>
                <Col>
                  <FormText noOffset>{t('settings.section.notifications.eMailNotificationsLanguage.infoText')}</FormText>
                </Col>
              </Row>
              <Row> {/* extra margin needed at the bottom */}
                <Col>
                  <Row>
                    <Col>
                      <Checkbox
                        name='allowUserLocaleForCustomers'
                        label={t('settings.section.notifications.eMailNotificationsLanguage.customers.checkbox.text')}
                        theme='switch'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        name='allowUserLocaleForResources'
                        label={t('settings.section.notifications.eMailNotificationsLanguage.resources.checkbox.text')}
                        theme='switch'
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Error noOffset name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>
                {t('buttons.cancel.label')}
              </Button>
              <Button type='submit' isPrimary>
                {t('buttons.saveChanges.label')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default EmailSettingsEditForm
