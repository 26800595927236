import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { PermissionTypePreview } from '../../Beauties'

class PermissionTypePreviewWrapper extends PureComponent {
  componentDidMount () {
    const { account } = this.props
    let { enterprisePermissions, isAdmin } = account || {}
    isAdmin = !!isAdmin
    enterprisePermissions = enterprisePermissions || {}
    let { globalPermissionsGroupsRead } = enterprisePermissions || {}
    globalPermissionsGroupsRead = !!isAdmin || !!globalPermissionsGroupsRead
    if (!globalPermissionsGroupsRead) handlers.navigateToPath('/')
  }

  render () {
    let {
      pending,
      hash,
      detail,
      categoriesList,
      account
    } = this.props
    pending = !!pending
    categoriesList = categoriesList || []
    detail = detail || {}
    account = account || {}
    let { enterprisePermissions, isAdmin } = account || {}
    isAdmin = !!isAdmin
    enterprisePermissions = enterprisePermissions || {}
    let { globalPermissionsGroupsWrite, globalPermissionsGroupsDelete } = enterprisePermissions || {}
    globalPermissionsGroupsWrite = !!isAdmin || !!globalPermissionsGroupsWrite
    globalPermissionsGroupsDelete = !!isAdmin || !!globalPermissionsGroupsDelete

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <PermissionTypePreview
            detail={detail}
            hash={hash}
            onDelete={handlers.permissionTypeDelete}
            categoriesList={categoriesList}
            globalPermissionsGroupsWrite={globalPermissionsGroupsWrite}
            globalPermissionsGroupsDelete={globalPermissionsGroupsDelete}
          />
        )}
      </>
    )
  }
}

PermissionTypePreviewWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  detail: PropTypes.object,
  categoriesList: PropTypes.array.isRequired
}

const maps = (state) => ({
  hash: state.router.hash,
  categoriesList: state.resources.categoriesList || [],
  pending: state.permissionTypes.pendingList,
  detail: ((state.permissionTypes && state.permissionTypes.list) || []).find(permissionType => permissionType.id === state.router.data.id),
  account: state.account
})

export default connect(maps)(PermissionTypePreviewWrapper)
