import React from 'react'
import PropTypes from 'prop-types'
import { Alert, t } from '../../Common'
import { CoursesListItem } from '../../Beauties'

const CoursesList = props => {
  const {
    courses,
    isDragAndDropMode,
    areCategoriesExpanded,
    search,
    enterpriseId,
    categoryIndex
  } = props

  return (
    <div className='ta-courses__list'>
      {courses.length > 0 && courses.map((course, index) => (
        <CoursesListItem
          key={course.id}
          course={course}
          index={index}
          isDragAndDropMode={isDragAndDropMode}
          areCategoriesExpanded={areCategoriesExpanded}
          search={search}
          enterpriseId={enterpriseId}
          categoryIndex={categoryIndex}
        />
      ))}
      {(!courses.length &&
        <Alert noOffset label={t('servicesGroups.list.group.noResultsBox.empty.text')} theme='no-content' />
      )}
    </div>
  )
}

CoursesList.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default CoursesList
