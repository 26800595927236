import {
  customerFieldsForList,
  customerFields
} from '../Enterprise/fields'

export const getEnterpriseCustomersWithTotalCustomersMiddleware = `
  query getEnterpriseCustomersWithTotalCustomersMiddleware($filter: CustomersFilter, $sort:CustomersSort, $pagination:Pagination) {
    getEnterpriseCustomersWithTotalCustomersMiddleware(filter: $filter, sort: $sort, pagination: $pagination) ${customerFieldsForList}
  }
`

export const getEnterpriseCustomerCustomersMiddleware = `
  query getEnterpriseCustomerCustomersMiddleware($id: ID, $externalId: ID) {
    getEnterpriseCustomerCustomersMiddleware(id: $id, externalId: $externalId) ${customerFields}
  }
`

export const getEnterpriseCustomersCustomersMiddleware = `
  query getEnterpriseCustomersCustomersMiddleware($filter: CustomersFilter, $sort:CustomersSort, $pagination:Pagination) {
    getEnterpriseCustomersCustomersMiddleware(filter: $filter, sort: $sort, pagination: $pagination) ${customerFields}
  }
`

export const deleteBranchCustomerCustomersMiddleware = `
  mutation deleteBranchCustomerCustomersMiddleware($companyId: ID!, $region: Region, $id: ID, $externalId: ID) {
    deleteBranchCustomerCustomersMiddleware(companyId: $companyId, region: $region, id: $id, externalId: $externalId)
  }
`

export const resetGlobalBranchCustomerCustomersMiddleware = `
  mutation resetGlobalBranchCustomerCustomersMiddleware($companyId: ID!, $region: Region!, $internalId: ID!) {
    resetGlobalBranchCustomerCustomersMiddleware(companyId: $companyId, region: $region, internalId: $internalId) ${customerFields}
  }
`

export const saveEnterpriseCustomerCustomersMiddleware = `
  mutation saveEnterpriseCustomerCustomersMiddleware($customer: CustomerPayload!, $globalFilterConfig: GlobalFilterConfig) {
    saveEnterpriseCustomerCustomersMiddleware(customer: $customer, globalFilterConfig: $globalFilterConfig) ${customerFields}
  }
`

export const deleteEnterpriseCustomerCustomersMiddleware = `
  mutation deleteEnterpriseCustomerCustomersMiddleware($id: ID, $externalId: ID, $globalFilterConfig: GlobalFilterConfig) {
    deleteEnterpriseCustomerCustomersMiddleware(id: $id, externalId: $externalId,  globalFilterConfig: $globalFilterConfig)
  }
`

// resetGlobalCustomer

// moveCustomer

// exportCustomers

// getCustomerUpcomingEvents

// getCustomerPastEvents

// getCustomerDeletedEvents
