import React from 'react'
import PropTypes from 'prop-types'
import { Alert, InfiniteScroll, InfiniteScrollLoader, t } from '../../../Common'
import { SystemMessagesFilters } from '../../../Beauties'
import { NotificationCardWrapper } from '../../../Beasts'

const SystemMessagesList = props => {
  const {
    systemMessages,
    loadMore,
    hasMore,
    systemMessagesCount,
    hasAdvancedFilter,
    toggleAdvancedOptions
  } = props
  const loader = <InfiniteScrollLoader key='' />

  return (
    <div className='ta-notifications__system-messages__list'>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={loader}
        useWindow={false}
        initialLoad
      >
        <SystemMessagesFilters
          count={systemMessagesCount}
          hasAdvancedFilter={hasAdvancedFilter}
          toggleAdvancedOptions={toggleAdvancedOptions}
        />
        {systemMessages.length > 0 && systemMessages.map((systemMessage, index) => (
          <NotificationCardWrapper key={`${systemMessage.id}${systemMessage.expiresAt}`} id={systemMessage.id} />
        ))}
        {(!systemMessages.length &&
          <Alert noOffset label={t('notifications.noResultsBox.text')} theme='no-content' />
        )}
      </InfiniteScroll>
    </div>
  )
}

SystemMessagesList.propTypes = {
  systemMessages: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default SystemMessagesList
