import React from 'react'
import { connect } from '../../../Store'
import { SettingsBookingPreviewServiceAllocations } from '../../../Beauties'

const groupServicesByCategories = (list, categoriesList) => (categoriesList || []).reduce((acc, item) => ({
  ...acc,
  [item.name]: (list || []).filter(resource => resource.categoryId === item.id)
}), {})

const SettingsBookingPreviewServiceAllocationsWrapper = props => {
  let {
    settings,
    serviceAllocations,
    services,
    serviceCategories,
    courses,
    courseCategories
  } = props
  settings = settings || {}
  serviceAllocations = serviceAllocations || []
  services = services || []
  serviceCategories = serviceCategories || []
  courses = courses || []
  courseCategories = courseCategories || []
  const servicesByCategories = groupServicesByCategories(services, serviceCategories)
  const coursesByCategories = groupServicesByCategories(courses, courseCategories)

  return (
    <SettingsBookingPreviewServiceAllocations
      settings={settings}
      serviceAllocations={serviceAllocations}
      servicesByCategories={servicesByCategories}
      coursesByCategories={coursesByCategories}
    />
  )
}

const maps = state => ({
  services: state.services.list,
  serviceCategories: state.services.categoriesList,
  courses: state.courses.list,
  courseCategories: state.courses.categoriesList
})

export default connect(maps)(SettingsBookingPreviewServiceAllocationsWrapper)
