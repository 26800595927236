import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { ServiceCategoryForm } from '../../Beauties'

const ServiceCategoryAddWrapper = props => {
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.serviceCategoryFormGet()
  }, [])

  const onSubmit = cb => {
    const form = store.getState().forms.serviceCategory
    handlers.serviceCategoryFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  let {
    pending,
    showExternalIds,
    isAdmin,
    branchesAccessBlacklist,
    form
  } = props
  form = form || {}
  pending = !!pending
  showExternalIds = !!showExternalIds

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServiceCategoryForm
          title={t('servicesGroups.add.serviceCategory.heading')}
          type='add'
          cancelLink='/management/services'
          onSubmit={onSubmit}
          showExternalIds={showExternalIds}
          isAdmin={isAdmin}
          branchesAccessBlacklist={branchesAccessBlacklist}
          location={form.location && form.location.values}
        />
      )}
    </>
  )
}

ServiceCategoryAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  pending: state.services.pendingCategoryForm,
  showExternalIds: state.company.settings.showExternalIds,
  form: state.forms.serviceCategory,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default connect(maps)(ServiceCategoryAddWrapper)
