import React, { useState, useEffect } from 'react'
import { connect, handlers } from '../../../Store'
import { Loader } from '../../../Common'
import { EmailSettingsEditForm } from '../../../Beauties'

const EmailSettingsEditFormWrapper = props => {
  let {
    pending,
    form,
    account,
    // rawSettings,
    settings,
    bookingReminderStaffSwitch,
    resourceEmailRemindersMinutes,
    resourceEmailRemindersMinutesErrors,
    bookingReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    customerEmailRemindersMinutesErrors,
    bookingConfirmationCustomersSwitch,
    bookingConfirmationStaffSwitch,
    bookingConfirmationStaffOwnerCheckBox,
    bookingConfirmationStaffResourcesCheckBox,
    bookingConfirmationStaffOthersCheckBox,
    bookingConfirmationStaffOthers,
    bookingCancellationCustomersSwitch,
    bookingCancellationStaffSwitch,
    bookingCancellationStaffOwnerCheckBox,
    bookingCancellationStaffResourcesCheckBox,
    bookingCancellationStaffOthersCheckBox,
    bookingCancellationStaffOthers,
    bookingChangeCustomersSwitch,
    bookingChangeStaffSwitch,
    bookingChangeStaffOwnerCheckBox,
    bookingChangeStaffResourcesCheckBox,
    bookingChangeStaffOthersCheckBox,
    bookingChangeStaffOthers,
    bookingReminderStaffOthersCheckBox,
    bookingReminderStaffOthers,
    dailyAgendaEmailsSwitch,
    resourcesCategories,
    dailyAgendaResources,
    enterpriseCustomisation
  } = props
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsWrite } = enterprisePermissions || {}
  enterpriseCustomisation = enterpriseCustomisation || {}
  settingsWrite = !!isAdmin || !!settingsWrite
  const [initial] = useState({ settings, settingsWrite })
  const { payload: customisaitonPayload } = enterpriseCustomisation || {}

  useEffect(() => {
    const { settings, settingsWrite } = initial
    settingsWrite ? handlers.settingsNotificationsFormPopulate(settings || {}) : handlers.navigateToPath('/settings/email')
  }, [initial])

  const onSubmit = cb => {
    handlers.settingsNotificationsFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <EmailSettingsEditForm
          title='Edit Email Settings'
          cancelLink='/settings/email'
          onSubmit={onSubmit}
          bookingReminderStaffSwitch={bookingReminderStaffSwitch}
          resourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
          resourceEmailRemindersMinutesErrors={resourceEmailRemindersMinutesErrors}
          bookingReminderCustomerSwitch={bookingReminderCustomerSwitch}
          customerEmailRemindersMinutes={customerEmailRemindersMinutes}
          customerEmailRemindersMinutesErrors={customerEmailRemindersMinutesErrors}
          bookingConfirmationCustomersSwitch={bookingConfirmationCustomersSwitch}
          bookingConfirmationStaffSwitch={bookingConfirmationStaffSwitch}
          bookingConfirmationStaffOwnerCheckBox={bookingConfirmationStaffOwnerCheckBox}
          bookingConfirmationStaffResourcesCheckBox={bookingConfirmationStaffResourcesCheckBox}
          bookingConfirmationStaffOthersCheckBox={bookingConfirmationStaffOthersCheckBox}
          bookingConfirmationStaffOthers={bookingConfirmationStaffOthers}
          bookingCancellationCustomersSwitch={bookingCancellationCustomersSwitch}
          bookingCancellationStaffSwitch={bookingCancellationStaffSwitch}
          bookingCancellationStaffOwnerCheckBox={bookingCancellationStaffOwnerCheckBox}
          bookingCancellationStaffResourcesCheckBox={bookingCancellationStaffResourcesCheckBox}
          bookingCancellationStaffOthersCheckBox={bookingCancellationStaffOthersCheckBox}
          bookingCancellationStaffOthers={bookingCancellationStaffOthers}
          bookingChangeCustomersSwitch={bookingChangeCustomersSwitch}
          bookingChangeStaffSwitch={bookingChangeStaffSwitch}
          bookingChangeStaffOwnerCheckBox={bookingChangeStaffOwnerCheckBox}
          bookingChangeStaffResourcesCheckBox={bookingChangeStaffResourcesCheckBox}
          bookingChangeStaffOthersCheckBox={bookingChangeStaffOthersCheckBox}
          bookingChangeStaffOthers={bookingChangeStaffOthers}
          bookingReminderStaffOthersCheckBox={bookingReminderStaffOthersCheckBox}
          bookingReminderStaffOthers={bookingReminderStaffOthers}
          dailyAgendaEmailsSwitch={dailyAgendaEmailsSwitch}
          resourcesCategories={resourcesCategories}
          dailyAgendaResources={dailyAgendaResources}
          customisations={customisaitonPayload}
        />
      )}
    </>
  )
}

const maps = state => ({
  form: state.forms.settings || {},
  // rawSettings: state.company.rawSettings || {},
  settings: state.company.settings || {},
  pending: state.settings.pendingForm,
  enterpriseCustomisation: state.company.enterpriseCustomisation,
  bookingReminderStaffSwitch: state.forms.settings.bookingReminderStaffSwitch && state.forms.settings.bookingReminderStaffSwitch.value,
  resourceEmailRemindersMinutes: state.forms.settings.resourceEmailRemindersMinutes && state.forms.settings.resourceEmailRemindersMinutes.values,
  resourceEmailRemindersMinutesErrors: (state.forms.settings && state.forms.settings.resourceEmailRemindersMinutes && state.forms.settings.resourceEmailRemindersMinutes.errors) || [],
  bookingReminderCustomerSwitch: state.forms.settings.bookingReminderCustomerSwitch && state.forms.settings.bookingReminderCustomerSwitch.value,
  customerEmailRemindersMinutes: state.forms.settings.customerEmailRemindersMinutes && state.forms.settings.customerEmailRemindersMinutes.values,
  customerEmailRemindersMinutesErrors: (state.forms.settings && state.forms.settings.customerEmailRemindersMinutes && state.forms.settings.customerEmailRemindersMinutes.errors) || [],
  bookingConfirmationCustomersSwitch: state.forms.settings.bookingConfirmationCustomersSwitch && state.forms.settings.bookingConfirmationCustomersSwitch.value,
  bookingConfirmationStaffSwitch: state.forms.settings.bookingConfirmationStaffSwitch && state.forms.settings.bookingConfirmationStaffSwitch.value,
  bookingConfirmationStaffOwnerCheckBox: state.forms.settings.bookingConfirmationStaffOwnerCheckBox && state.forms.settings.bookingConfirmationStaffOwnerCheckBox.value,
  bookingConfirmationStaffResourcesCheckBox: state.forms.settings.bookingConfirmationStaffResourcesCheckBox && state.forms.settings.bookingConfirmationStaffResourcesCheckBox.value,
  bookingConfirmationStaffOthersCheckBox: state.forms.settings.bookingConfirmationStaffOthersCheckBox && state.forms.settings.bookingConfirmationStaffOthersCheckBox.value,
  bookingConfirmationStaffOthers: (state.forms.settings.bookingConfirmationStaffOthers && state.forms.settings.bookingConfirmationStaffOthers.values) || [],
  bookingCancellationCustomersSwitch: state.forms.settings.bookingCancellationCustomersSwitch && state.forms.settings.bookingCancellationCustomersSwitch.value,
  bookingCancellationStaffSwitch: state.forms.settings.bookingCancellationStaffSwitch && state.forms.settings.bookingCancellationStaffSwitch.value,
  bookingCancellationStaffOwnerCheckBox: state.forms.settings.bookingCancellationStaffOwnerCheckBox && state.forms.settings.bookingCancellationStaffOwnerCheckBox.value,
  bookingCancellationStaffResourcesCheckBox: state.forms.settings.bookingCancellationStaffResourcesCheckBox && state.forms.settings.bookingCancellationStaffResourcesCheckBox.value,
  bookingCancellationStaffOthersCheckBox: state.forms.settings.bookingCancellationStaffOthersCheckBox && state.forms.settings.bookingCancellationStaffOthersCheckBox.value,
  bookingCancellationStaffOthers: (state.forms.settings.bookingCancellationStaffOthers && state.forms.settings.bookingCancellationStaffOthers.values) || [],
  bookingChangeCustomersSwitch: state.forms.settings.bookingChangeCustomersSwitch && state.forms.settings.bookingChangeCustomersSwitch.value,
  bookingChangeStaffSwitch: state.forms.settings.bookingChangeStaffSwitch && state.forms.settings.bookingChangeStaffSwitch.value,
  bookingChangeStaffOwnerCheckBox: state.forms.settings.bookingChangeStaffOwnerCheckBox && state.forms.settings.bookingChangeStaffOwnerCheckBox.value,
  bookingChangeStaffResourcesCheckBox: state.forms.settings.bookingChangeStaffResourcesCheckBox && state.forms.settings.bookingChangeStaffResourcesCheckBox.value,
  bookingChangeStaffOthersCheckBox: state.forms.settings.bookingChangeStaffOthersCheckBox && state.forms.settings.bookingChangeStaffOthersCheckBox.value,
  bookingChangeStaffOthers: (state.forms.settings.bookingChangeStaffOthers && state.forms.settings.bookingChangeStaffOthers.values) || [],
  bookingReminderStaffOthersCheckBox: state.forms.settings.bookingReminderStaffOthersCheckBox && state.forms.settings.bookingReminderStaffOthersCheckBox.value,
  bookingReminderStaffOthers: (state.forms.settings.bookingReminderStaffOthers && state.forms.settings.bookingReminderStaffOthers.values) || [],
  account: state.account,
  dailyAgendaResources: (state.forms.settings.dailyAgendaResources && state.forms.settings.dailyAgendaResources.value) || 'all',
  resourcesCategories: state.resources.categoriesList,
  dailyAgendaEmailsSwitch: (state.forms.settings.dailyAgendaEmailsSwitch && state.forms.settings.dailyAgendaEmailsSwitch.value) || false
})

export default connect(maps)(EmailSettingsEditFormWrapper)
