import React from 'react'
import { CourseCategoryPreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const CourseCategoryPreviewChangedLocallyWrapper = props => {
  let { courseCategory, account } = props
  courseCategory = courseCategory || {}
  account = account || {}
  const { locallyChangedData, internalId } = courseCategory || {}
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsWrite } = enterprisePermissions || {}
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite

  const onResetGlobalEntity = (companyId, region) => handlers.courseCategoryLocallyChangedReset({ companyId, region, internalId })

  return (
    <CourseCategoryPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalGroupsWrite={globalGroupsWrite}
    />
  )
}

const maps = state => {
  const { router, courses } = state
  const { categoriesList } = courses || {}
  const categoryId = (router.data && router.data.categoryId) || ''
  const courseCategory = (categoriesList || []).find(item => item.id === categoryId) || {}

  return {
    courseCategory,
    account: state.account
  }
}

export default connect(maps)(CourseCategoryPreviewChangedLocallyWrapper)
