import React from 'react'
import { connect } from '../../Store'
import { ServiceFormSettings } from '../../Beauties'

const ServiceFormSettingsWrapper = props => {
  const {
    hasOnlinePayment,
    hasStripeConnected,
    customisations,
    isCombination
  } = props

  return (
    <ServiceFormSettings
      hasOnlinePayment={hasOnlinePayment}
      hasStripeConnected={hasStripeConnected}
      customisations={customisations}
      isCombination={isCombination}
    />
  )
}

const maps = state => ({
  hasOnlinePayment: (state.forms.services.hasOnlinePayment && state.forms.services.hasOnlinePayment.value) || false,
  hasStripeConnected: false
  // hasStripeConnected: state.onlinePaymentMethods.hasStripeConnected || false
})

export default connect(maps)(ServiceFormSettingsWrapper)
