import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { TwoFARefreshCodesForm } from '../../Beauties'

const TwoFARefreshCodesWrapper = props => {
  let { form, closePopup } = props
  form = form || {}
  const { code } = form
  const codeValue = code && code.value

  useEffect(() => {
    handlers.twoFAFormPopulate()
  }, [])

  const onSubmit = () => {
    handlers.twoFARefreshRecoveryCodes(codeValue, () => {
      closePopup()
    })
  }

  return (
    <TwoFARefreshCodesForm code={codeValue} onSubmit={onSubmit} onCancel={closePopup} />
  )
}

const maps = state => ({
  form: state.forms.twoFA,
  settings: state.company && state.company.settings
})

export default connect(maps)(TwoFARefreshCodesWrapper)
