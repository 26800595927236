import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServicesDurationTooltip = props => {
  return (
    <div className='ta-popup__services-duration-tooltip'>
      <div className='ta-popup__text'>{dangerousHTML(t('servicesGroups.form.section.duration.tooltip.text'))}</div>
    </div>
  )
}

export default PopupServicesDurationTooltip
