import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
// import { Loader } from '../../Common'
import { UserGroupPreview } from '../../Beauties'

class UserGroupsPreviewWrapper extends Component {
  componentDidMount () {
    handlers.userGroupsPreviewGet(this.props.id)
  }

  render () {
    const { user = {}, id, hash } = this.props
    return (
      <UserGroupPreview
        user={user}
        hash={hash}
        id={id}
      />
    )
  }
}

const maps = state => {
  return {
    id: state.router.data.id,
    hash: state.router.hash,
    userGroup: (state.usersGroup && state.usersGroup.userGroup) || {}
  }
}

export default connect(maps)(UserGroupsPreviewWrapper)
