import React from 'react'
import {
  DottedTimelineItem,
  IconListItemTitle,
  IconListItemBody,
  DottedTimeline,
  IconListItem,
  FontAwesome5,
  Title,
  t,
  Price
} from '../../../Common'
import {
  convertMinutesToDaysHoursMinutes,
  extractFromHtml,
  dangerousHTML
} from '../../../Utils'

const CourseDetails = props => {
  const {
    id,
    externalId,
    category,
    description,
    duration,
    durationsPattern,
    preparation,
    followUp,
    price,
    color,
    hasOnlinePayment,
    isPaymentMandatory,
    isBookable,
    maxParticipants,
    extraPersonsPerParticipant
  } = props
  const translations = {
    minutes: t('global.minutes'),
    hours: t('global.hours'),
    days: t('global.days')
  }
  const categoryName = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name

  return (
    <div className='ta-courses__preview__details'>
      {(extractFromHtml(description) &&
        <div className='ta-courses__preview__details__description'>
          <Title size='m' icon='sticky-note' label={t('servicesGroups.preview.description')} ignoreTopOffset />
          {dangerousHTML(description, false, true)}
        </div>
      )}
      <IconListItem icon='id-badge' iconType='regular'>
        <IconListItemTitle width='160px'>{t('global.groupID')}:</IconListItemTitle>
        <IconListItemBody offset='160px'>{id}</IconListItemBody>
      </IconListItem>
      {(externalId &&
        <IconListItem icon='fingerprint' type='regular'>
          <IconListItemTitle width='160px'>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      {(categoryName &&
        <IconListItem icon='folder' iconType='regular'>
          <IconListItemTitle width='160px'>{t('global.category')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>{categoryName}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon='hourglass-half' iconType='regular'>
        <IconListItemTitle width='160px'>{t('global.duration')}:</IconListItemTitle>
        <IconListItemBody offset='160px'>
          {duration > 0 && convertMinutesToDaysHoursMinutes(duration, translations)}
          {(durationsPattern && durationsPattern.length > 1 &&
            <DottedTimeline>
              {durationsPattern.map((item, index) => {
                const label = t(index % 2 === 0 ? 'global.interval' : 'global.gap')
                return (
                  <div key={index}>
                    <DottedTimelineItem type='big'><strong>{label}</strong></DottedTimelineItem>
                    <DottedTimelineItem type='small' className={index === durationsPattern.length - 1 ? 'last' : ''}>
                      {convertMinutesToDaysHoursMinutes(item, translations)}
                    </DottedTimelineItem>
                  </div>
                )
              })}
            </DottedTimeline>
          )}
        </IconListItemBody>
      </IconListItem>
      {(preparation > 0 &&
        <IconListItem icon='hourglass-start' iconType='regular'>
          <IconListItemTitle width='160px'>{t('global.duration.preparationTime')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>{convertMinutesToDaysHoursMinutes(preparation, translations)}</IconListItemBody>
        </IconListItem>
      )}
      {(followUp > 0 &&
        <IconListItem icon='hourglass-end' iconType='regular'>
          <IconListItemTitle width='160px'>{t('global.followUpTime')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>{convertMinutesToDaysHoursMinutes(followUp, translations)}</IconListItemBody>
        </IconListItem>
      )}
      {(price > 0 &&
        <IconListItem icon='money-bill' iconType='regular'>
          <IconListItemTitle width='160px'>{t('global.price.label')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>
            <Price price={price} />
          </IconListItemBody>
        </IconListItem>
      )}
      {(color &&
        <IconListItem icon='eye-dropper' iconType='regular'>
          <IconListItemTitle width='160px'>{t('global.color')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>
            <FontAwesome5 icon='square' type='solid' color={color} className='ta-courses__preview__details__color' />
          </IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon='cc-stripe' iconType='brand'>
        <IconListItemTitle width='160px'>{t('global.onlinePayment')}:</IconListItemTitle>
        <IconListItemBody offset='160px'>
          {hasOnlinePayment ? t('global.enabled') : t('global.disabled')}
          {(hasOnlinePayment &&
            <span> &amp; {isPaymentMandatory ? t('global.mandatory').toString().toLowerCase() : t('global.notMandatory').toString().toLowerCase()}</span>
          )}
        </IconListItemBody>
      </IconListItem>
      <IconListItem icon='users' iconType='solid'>
        <IconListItemTitle width='160px'>{t('global.maxParticipants')}:</IconListItemTitle>
        <IconListItemBody offset='160px'>{maxParticipants}</IconListItemBody>
      </IconListItem>
      {(extraPersonsPerParticipant > 0 &&
        <IconListItem icon='users' iconType='solid'>
          <IconListItemTitle width='160px'>{t('global.extraParticipant')}:</IconListItemTitle>
          <IconListItemBody offset='160px'>{extraPersonsPerParticipant}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon={isBookable ? 'check-square' : 'square'} iconType='solid'>
        <IconListItemTitle width='160px'>{t('global.status')}:</IconListItemTitle>
        <IconListItemBody offset='160px'>
          {t('servicesGroups.preview.status', [{ key: 'STATUS', value: isBookable ? t('global.enabled') : t('global.disabled') }])}
        </IconListItemBody>
      </IconListItem>
    </div>
  )
}

export default CourseDetails
