import React from 'react'

const SimpleListItemContent = props => {
  const { children } = props

  return (
    <div className='ta-simple-list-item__content'>{children}</div>
  )
}

export default SimpleListItemContent
