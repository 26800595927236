import { payloads$, actions, store } from '../../../Store'
import { q } from '../../API'
import { CALL_CENTRE_URL, DEFAULT_LOCALE } from '../../../Settings'

payloads$(actions.CALL_CENTRE_LOGIN)
  .subscribe(async () => {
    const state = store.getState()
    const { app } = state || {}
    let { locale } = app || {}
    locale = locale || DEFAULT_LOCALE
    const generateToken = await q('loginCallCentreWithEnterpriseToken')

    const params = []
    params.push(`locale=${locale}`)

    if (!generateToken) {
      window.open(`${CALL_CENTRE_URL}/login?${params.join('&')}`, '__blank')
      return
    }
    const { error } = generateToken
    if (error) {
      window.open(`${CALL_CENTRE_URL}/login?${params.join('&')}`, '__blank')
      return
    }
    params.push(`token=${generateToken}`)
    window.open(`${CALL_CENTRE_URL}/login?${params.join('&')}`, '__blank')
  })
