import {
  accountPopulateTransform,
  accountFormTransform,
  accountPasswordChangeSaveTransform,
  accountEmailChangeSaveTransform,
  accountEmailChangeCancelSaveTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  pendingOnlineStatus: false,
  messagePreview: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  accountResourceUnlink: data => dispatch(actions.ACCOUNT_UNLINK_RESOURCE, data),
  accountResourceDeleted: id => dispatch(actions.ACCOUNT_RESOURCE_DELETED, id),
  accountUnlink: id => dispatch(actions.ACCOUNT_UNLINK, id),
  accountDeleted: id => dispatch(actions.ACCOUNT_DELETED, id),
  accountUserDeleted: ({ userId, authScope }) => dispatch(actions.ACCOUNT_USER_DELETED, { userId, authScope }),
  accountPendingEmailPopulate: pendingEmail => dispatch(actions.ACCOUNT_PENDING_EMAIL_POPULATE, pendingEmail),
  account2FADisable: ({ withLogout, code }, cb) => dispatch(actions.ACCOUNT_TWO_FA_DISABLE, { withLogout, code, cb }),
  account2FADisabled: () => dispatch(actions.ACCOUNT_TWO_FA_DISABLED),
  account2FAActivated: () => dispatch(actions.ACCOUNT_TW_FA_ACTIVATED),
  account2FAJwtActivated: () => dispatch(actions.ACCOUNT_TWO_FA_JWT_ACTIVATED),
  accountPendingSet: (isPending) => dispatch(actions.ACCOUNT_PENDING_SET, { isPending }),
  // Reset
  accountReset: () => dispatch(actions.ACCOUNT_RESET),
  // Customisaitons
  accountCustomisationsApply: () => dispatch(actions.ACCOUNT_CUSTOMISATIONS_APPLY),
  // One
  accountSelect: id => dispatch(actions.ACCOUNT_SELECT, { accountId: id }),
  accountPopulate: account => dispatch(actions.ACCOUNT_POPULATE, accountPopulateTransform(account)),
  accountUpdate: () => dispatch(actions.ACCOUNT_UPDATE),
  accountChanged: account => dispatch(actions.ACCOUNT_CHANGED, account),
  accountEmailConfirmationSend: ({ accountId, email }) => dispatch(actions.ACCOUNT_EMAIL_CONFIRMATION_SEND, { accountId, email }),
  accountCompanySessionConfirm: id => dispatch(actions.ACCOUNT_COMPANY_SESSION_CONFIRM, { id }),
  accountSessionConfirm: ({ id, data }) => dispatch(actions.ACCOUNT_SESSION_CONFIRM, { id, data }),
  // Form
  accountFormGet: () => dispatch(actions.ACCOUNT_FORM_GET),
  accountFormPopulate: account => {
    handlers.accountFormReady()
    handlers.formSet('account', accountFormTransform(account))
  },
  accountFormReady: () => dispatch(actions.ACCOUNT_FORM_READY),
  accountFormSave: (account, scrollToError = () => { }) => dispatch(actions.ACCOUNT_FORM_SAVE, { account, scrollToError }),
  accountDeleteFormSave: (form, scrollToError = () => { }) => dispatch(actions.ACCOUNT_DELETE_FORM_SAVE, { form, scrollToError }),
  accountDeleteFormReady: () => dispatch(actions.ACCOUNT_DELETE_FORM_READY),
  accountFormPasswordChangeSave: (account, scrollToError = () => { }) =>
    dispatch(actions.ACCOUNT_FORM_PASSWORD_CHANGE_SAVE, { account: accountPasswordChangeSaveTransform(account), scrollToError }),
  accountFormPasswordChangeRedirect: () => dispatch(actions.ACCOUNT_FORM_PASSWORD_CHANGE_REDIRECT),
  accountFormEmailChangeSave: (account, scrollToError = () => { }) =>
    dispatch(actions.ACCOUNT_FORM_EMAIL_CHANGE_SAVE, { account: accountEmailChangeSaveTransform(account), scrollToError }),
  accountFormEmailChangeCancelSave: (account, scrollToError = () => { }) =>
    dispatch(actions.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_SAVE, { account: accountEmailChangeCancelSaveTransform(account), scrollToError }),
  accountFormEmailChangeCancelInvalidRequest: () => {
    handlers.formFieldsUpdate('account', { pendingEmail: null })
    handlers.accountFormEmailChangeCancelInvalidRequestReady()
  },
  accountFormEmailChangeCancelInvalidRequestReady: () => dispatch(actions.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_INVALID_REQUEST_READY),
  // Message
  accountPreviewMessageChange: ({ text, type = 'error' }) => dispatch(actions.ACCOUNT_PREVIEW_MESSAGE_CHANGE, { text, type }),
  accountPreviewMessageReset: () => dispatch(actions.ACCOUNT_PREVIEW_MESSAGE_RESET),

  // Permissions
  accountPermissionsChanged: () => dispatch(actions.ACCOUNT_PERMISSIONS_CHANGED),

  // Subscription
  accountSubscriptionSet: ({ name, id, data, ts, payload }) => dispatch(actions.ACCOUNT_SUBSCRIPTION_SET, { name, id, data, ts, payload }),

  // Export
  accountLastBranchesExportSet: date => dispatch(actions.ACCOUNT_LAST_BRANCHES_EXPORT_SET, date)

})

reducer.ACCOUNT_COMPANY_SESSION_CONFIRM = state => state

reducer.ACCOUNT_SESSION_CONFIRM = state => state

reducer.ACCOUNT_SUBSCRIPTION_SET = state => state

reducer.ACCOUNT_RESET = () => reducer.initialState

reducer.ACCOUNT_CHANGED = state => state

reducer.ACCOUNT_UNLINK = state => state

reducer.ACCOUNT_CUSTOMISATIONS_APPLY = state => state

reducer.ACCOUNT_UNLINK_RESOURCE = state => state

reducer.ACCOUNT_SELECT = state => ({
  ...state,
  messagePreview: null,
  pendingAccountSelect: true
})

reducer.ACCOUNT_PENDING_SET = (state, { isPending }) => ({
  ...state,
  pendingAccountSelect: isPending
})

reducer.ACCOUNT_EMAIL_CONFIRMATION_SEND = (state, { accountId }) => {
  if (!accountId) return state
  const accounts = [...state.userAccounts]
  const index = accounts.findIndex(item => item.accountId === accountId)

  if (index < 0) return state
  accounts[index] = { ...accounts[index], isConfirmationEmailReSent: true }
  return {
    ...state,
    userAccounts: accounts
  }
}

reducer.ACCOUNT_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_PENDING_EMAIL_POPULATE = (state, pendingEmail) => ({
  ...state,
  pendingEmail
})

reducer.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_INVALID_REQUEST_READY = state => ({
  ...state,
  pendingForm: false,
  pendingEmail: null
})

reducer.ACCOUNT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.ACCOUNT_POPULATE = (state, account = null) => ({
  ...state,
  ...account,
  pendingForm: true
})

reducer.ACCOUNT_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

reducer.ACCOUNT_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

reducer.ACCOUNT_ONLINE_STATUS_TOGGLE = state => ({
  ...state,
  pendingOnlineStatus: true
})

reducer.ACCOUNT_FORM_PASSWORD_CHANGE_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_PASSWORD_CHANGE_REDIRECT = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_EMAIL_CHANGE_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_DELETED = (state, id) => ({
  ...state,
  accounts: state.accounts.filter(item => item.id !== id),
  userAccounts: state.userAccounts.filter(item => item.accountId !== id)
})

reducer.ACCOUNT_USER_DELETED = (state, { userId, authScope }) => ({
  ...state,
  accounts: state.accounts.filter(item => item.userId !== userId || item.authScope !== authScope),
  userAccounts: state.userAccounts.filter(item => item.userId !== userId || item.authScope !== authScope)
})

reducer.ACCOUNT_RESOURCE_DELETED = (state, id) => ({
  ...state,
  accounts: state.accounts.filter(item => item.id !== id),
  userAccounts: state.userAccounts.filter(item => item.accountId !== id)
})

reducer.ACCOUNT_TWO_FA_DISABLE = state => state

reducer.ACCOUNT_TWO_FA_DISABLED = (state) => ({
  ...state,
  auth2FAs: state.auth2FAs.map(account => ({ ...account, isActive: false })),
  jwtAuth2FAs: [{ type: 'OTP', isAuth: false }]
})

reducer.ACCOUNT_TW_FA_ACTIVATED = state => ({
  ...state,
  auth2FAs: [{ type: 'OTP', isActive: true }],
  jwtAuth2FAs: [{ type: 'OTP', isAuth: true }]
})

reducer.ACCOUNT_PERMISSIONS_CHANGED = state => state

reducer.ACCOUNT_TWO_FA_JWT_ACTIVATED = state => ({
  ...state,
  jwtAuth2FAs: [{ type: 'OTP', isAuth: true }]
})

reducer.ACCOUNT_PREVIEW_MESSAGE_CHANGE = (state, { text, type }) => text
  ? {
      ...state,
      messagePreview: { text, type }
    }
  : state

reducer.ACCOUNT_PREVIEW_MESSAGE_RESET = state => ({
  ...state,
  messagePreview: null
})

reducer.ACCOUNT_LAST_BRANCHES_EXPORT_SET = (state, date) => ({
  ...state,
  executedCompanyBookingsExport: date || null
})

export default reducer
