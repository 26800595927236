import * as formSelectors from '../Forms/selectors'
import * as notificationsSelectors from '../Components/Notifications/selectors'
// import * as calendarSelectors from '../Components/Calendar/selectors'
import * as companySelectors from '../Components/Company/selectors'
// import * as customersSelectors from '../Components/Customers/selectors'
// import * as enterpriseSelectors from '../Components/Enterprise/selectors'
// import * as permissionTypesSelectors from '../Components/PermissionTypes/selectors'
// import * as resourcesSelectors from '../Components/Resources/selectors'
import * as staticDataSelectors from '../Components/StaticData/selectors'
import * as accountSelectors from '../Components/Account/selectors'
// import * as globalSelectors from '../Components/selectors'
import * as routerSelectors from '../Router/selectors'
// import * as statisticsSelectors from '../Components/Statistics/selectors'
// import * as subscriptionsSelectors from '../Components/Subscription/selectors'
// import * as invoicesSelectors from '../Components/Invoices/selectors'
// import * as navigationSelectors from '../Components/Navigation/selectors'
// import * as bookingsSelectors from '../Components/Bookings/selectors'
// import * as customerFieldsSelectors from '../Components/CustomerFields/selectors'
// import * as tagsSelectors from '../Components/Tags/selectors'
// import * as coursesSelectors from '../Components/Courses/selectors'
import * as appSelectors from '../Components/App/selectors'
import * as appsSelectors from '../Components/Apps/selectors'
// import * as servicesSelectors from '../Components/Services/selectors'
import * as billingSelectors from '../Components/Billing/selectors'
import * as activitySelectors from '../Components/Activity/selectors'

export default {
  ...accountSelectors,
  ...appSelectors,
  ...appsSelectors,
  ...formSelectors,
  ...notificationsSelectors,
  // ...calendarSelectors,
  ...companySelectors,
  // ...customersSelectors,
  // ...enterpriseSelectors,
  // ...permissionTypesSelectors,
  // ...resourcesSelectors,
  ...staticDataSelectors,
  // ...globalSelectors,
  ...routerSelectors,
  // ...statisticsSelectors,
  // ...subscriptionsSelectors,
  // ...invoicesSelectors,
  // ...navigationSelectors,
  // ...bookingsSelectors,
  // ...customerFieldsSelectors,
  // ...tagsSelectors,
  // ...coursesSelectors,
  // ...servicesSelectors,
  ...billingSelectors,
  ...activitySelectors
}
