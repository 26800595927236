import {
  createSelector
} from '../../Utils'

export const formsSelector = state => state.forms

export const formSelector = createSelector(
  formsSelector,
  (_, props) => props.formName,
  (forms, formName) => (forms || {})[formName]
)

export const formFieldSelector = createSelector(
  formSelector,
  (_, props) => props.name,
  (form, fieldName) => (form || {})[fieldName]
)

export const formFieldPropertySelector = createSelector(
  formFieldSelector,
  (_, props) => props.property,
  (field, property) => (field || {})[property]
)

export const formFieldHasErrorSelector = createSelector(
  formFieldSelector,
  formField => (formField && formField.errors && formField.errors.length > 0) || false
)
