import React from 'react'
import PropTypes from 'prop-types'
import { Image, t } from '../../Common'
import { loaderGIF } from '../../Assets/images'

import './Loader.css'

const Loader = props => {
  const {
    active,
    hideBackground,
    style
  } = props
  const classNames = ['ta-loader']
  if (active) classNames.push('active')
  if (hideBackground) classNames.push('no-background')

  return (
    <div className={classNames.join(' ')} style={style}>
      <Image src={loaderGIF} alt={t('global.loading')} />
    </div>
  )
}

Loader.propTypes = {
  active: PropTypes.bool,
  hideBackground: PropTypes.bool,
  style: PropTypes.object
}

export default Loader
