import React, { useEffect, useState, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { BranchesCustomerForm } from '../../Beauties'
import { groupCustomerFieldsByCategory } from '../../Utils'

const BranchesPreviewCustomerEditWrapper = props => {
  const { id } = props
  const [initial] = useState({ id })
  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    handlers.branchesCustomerFormGet(id)
  }, [initial])

  const {
    branches,
    branchId,
    pending,
    timezones,
    form
  } = props
  const branch = branches.find(item => item.id === branchId) || {}
  const customers = branch.customers || []
  const selectedCustomer = customers.find(item => item.id === id)
  const plan = (branch && branch.plan) || 'CLASSIC'
  const showExternalIds = (branch && branch.settings && branch.settings.showExternalIds) || false
  let { fields, fieldCategories } = selectedCustomer || {}
  fields = fields || []
  fieldCategories = fieldCategories || []
  const filteredFields = fields.filter(item => item.hasOverwrite && item.isActive)
  const groupedFields = groupCustomerFieldsByCategory(filteredFields, fieldCategories)
  const availableFields = filteredFields
    .filter(item => item.defaultId && item.isActive)
    .map(item => item.defaultId) || []

  const onSubmit = cb => {
    handlers.branchesCustomerFormSave(branch, form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <BranchesCustomerForm
          id={id}
          type='edit'
          cancelLink={`/branches/${branchId}/preview@@customers`}
          onSubmit={onSubmit}
          plan={plan}
          showExternalIds={showExternalIds}
          groupedFields={groupedFields}
          availableFields={availableFields}
          timezones={timezones}
        />
      )}
    </>
  )
}

const maps = state => ({
  id: state.router.data.id,
  branches: state.branches.list || [],
  branchId: state.router.data.branchId,
  pending: state.branches.pendingForm,
  form: state.forms.branchesCustomer || {}
})

export default memo(connect(maps)(BranchesPreviewCustomerEditWrapper))
