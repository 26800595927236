import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { UserPermissions } from '../../Beauties'
import { Loader } from '../../Common'

const UserPermissionsWrapper = props => {
  let { account } = props
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { permissionsGroupsRead, permissionsGroupsWrite } = enterprisePermissions || {}
  permissionsGroupsRead = !!isAdmin || !!permissionsGroupsRead
  permissionsGroupsWrite = !!isAdmin || !!permissionsGroupsWrite
  useEffect(() => {
    if (permissionsGroupsRead) handlers.userPermissionTypesGet()
  }, [permissionsGroupsRead])
  let {
    userPermissionTypes,
    pending,
    users
  } = props
  userPermissionTypes = userPermissionTypes || []
  users = users || []
  pending = !!pending
  const permissionTypesWithUsersCount = userPermissionTypes.map(userPermissionType => {
    if (userPermissionType.id === 'ADMIN') {
      const usersCount = users.filter(user => !user.enterprisePermissionsGroupId && user.roles.includes('ADMIN')).length || 0

      return {
        ...userPermissionType,
        usersCount
      }
    }
    const usersCount = users.filter(user => user.enterprisePermissionsGroupId === userPermissionType.id).length || 0

    return {
      ...userPermissionType,
      usersCount
    }
  })

  return (
    pending
      ? <Loader active />
      : (
        <UserPermissions
          permissionsGroupsWrite={permissionsGroupsWrite}
          userPermissionTypes={permissionTypesWithUsersCount}
          permissionsGroupsRead={permissionsGroupsRead}
        />
        )
  )
}

const maps = state => ({
  userPermissionTypes: state.userPermissions.list,
  pending: state.userPermissions.pending,
  users: state.users.list,
  account: state.account
})

export default connect(maps)(UserPermissionsWrapper)
