import fetch from 'isomorphic-fetch'
import { payloads$, actions, store, handlers } from '../../../Store'
import { PHRASE_PROJECT_ID } from '../../../Settings'

payloads$(actions.PHRASE_LOAD)
  .subscribe(() => {
    const { phrase, persist } = store.getState()
    const { isEnabled, defaultBranch } = phrase || {}
    const { selectedPhraseBranch, isPhraseActive: persistedActive, isDebuggingActive } = persist || {}
    const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)
    if (isStaging && (!isDebuggingActive || (isDebuggingActive && !persistedActive))) {
      window.PHRASEAPP_CONFIG = {}
    }
    if (isStaging && !isEnabled && persistedActive) {
      window.PHRASEAPP_CONFIG = {
        projectId: PHRASE_PROJECT_ID,
        autoLowercase: false,
        fullReparse: true,
        branch: isDebuggingActive ? selectedPhraseBranch || defaultBranch : defaultBranch
      }
      const phraseapp = document.createElement('script')
      phraseapp.type = 'text/javascript'
      phraseapp.async = true
      phraseapp.src = ['https://', 'phraseapp.com/assets/in-context-editor/2.0/app.js?', new Date().getTime()].join('')
      var s = document.getElementsByTagName('script')[0]
      if (s !== undefined) {
        s.parentNode.insertBefore(phraseapp, s)
      } else {
        document.body.appendChild(phraseapp)
      }
      handlers.phraseBranchesListGet()
      handlers.phraseReady()
    }
  })

payloads$(actions.PHRASE_BRANCHES_LIST_GET)
  .subscribe(async () => {
    const {
      REACT_APP_PHRASE_LOCALES_URL,
      REACT_APP_PHRASE_PROJECT_ID,
      REACT_APP_PHRASE_ACCESS_TOKEN
    } = process.env
    const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)
    if (isStaging) {
      const baseUrl = REACT_APP_PHRASE_LOCALES_URL
      const projectId = REACT_APP_PHRASE_PROJECT_ID
      const url = `${baseUrl}/${projectId}/branches`

      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'token ' + REACT_APP_PHRASE_ACCESS_TOKEN
        }
      })
        .then(res => res.json())
        .then(res => {
          res = res || []
          const branches = res.filter(item => item.name !== 'Master')
            .map(item => ({ label: item.name, value: item.name }))
          handlers.phraseBranchesListPopulate(branches)
          handlers.phraseReady()
        })
    }
  })

payloads$(actions.PHRASE_BRANCHES_LIST_POPULATE)
  .subscribe(async (branches) => {
    const { form } = store.getState()
    const { debugOptions } = form || {}
    const { phraseBranch } = debugOptions || {}
    handlers.formFieldsUpdate('debugOptions', { phraseBranch: { ...phraseBranch, options: branches } })
  })

payloads$(actions.PHRASE_UPDATE)
  .subscribe(async (options) => {
    const { phraseActive, phraseBranch } = options || {}
    const { value: isActive } = phraseActive || {}
    const { value: newBranch } = phraseBranch || {}
    const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)

    if (isStaging) {
      handlers.persistSet({ selectedPhraseBranch: newBranch, isPhraseActive: isActive })
      window.PHRASEAPP_CONFIG = {
        ...window.PHRASEAPP_CONFIG,
        branch: newBranch
      }
      window.location.reload()
    }
  })
