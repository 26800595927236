import React from 'react'
import { SimpleListItem, FontAwesome5, Alert, t, Button } from '../../Common'

const CustomersToDelete = props => {
  let { customersToDelete, onDeleteGlobalEntity, globalCustomersWrite } = props
  customersToDelete = customersToDelete || []

  return (
    <div className='ta-customers-to-delete'>
      {(customersToDelete.length > 0 &&
        <Alert label={t('customers.list.delete.pending')} theme='alert'>
          {t('customers.list.delete.warning')}
        </Alert>
      )}
      {customersToDelete.map(item => {
        let { branch, customer } = item
        branch = branch || {}
        customer = customer || {}
        return (
          <SimpleListItem className='ta-customers-to-delete--branch' key={customer.id}>
            <div className='ta-customers-to-delete--branch__data'>
              <p className='ta-customers-to-delete--branch__name'>{branch.name}</p>
              <div className='ta-customers-to-delete--item'>
                <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
              </div>
              <div className='ta-customers-to-delete--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{customer.id}</p>
              </div>
            </div>
            {(globalCustomersWrite &&
              <div className='ta-customers-to-delete--branch__buttons'>
                <Button onClick={() => onDeleteGlobalEntity(branch.id, branch.region, customer.externalId, customer.id)}>{t('global.delete')}</Button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {(!customersToDelete.length &&
        <Alert noOffset label={t('customers.list.noCustomers.delete')} theme='no-content' />
      )}
    </div>
  )
}

export default CustomersToDelete
