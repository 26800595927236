import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import { t } from '../../../Common'
import { userUpdated, userDeleted } from './subscriptions'
import {
  usersTransform,
  usersFormValidate,
  usersSaveTransform,
  usersFormServerErrorsTransform
} from './utils'

// Global actions
globalActions.populateUsers = async forceFetch => {
  const state = store.getState()
  let { users: usersState } = state
  const { areFetched, list } = usersState || {}
  if (areFetched && !forceFetch) return list
  const users = await q('getEnterpriseUsers')
  const { error } = users || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    return handlers.usersListPopulate({ users: [] })
  }
  handlers.usersListPopulate({ users })
  return users
}

// Subsciption
payloads$(actions.ENTERPRISE_USER_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'enterpriseUserUpdated' && id) userUpdated({ id, ts })
    if (name === 'enterpriseUserDeleted' && id) userDeleted({ id, ts })
  })

// List
payloads$(actions.USERS_LIST_GET)
  .subscribe(async () => {
    await globalActions.populateUserPermissionTypes()
    const users = await q('getEnterpriseUsers')
    const { error } = users
    if (error) return error
    handlers.usersListPopulate({ users })
  })

// List Filters
// payloads$(actions.FORM_FIELDS_UPDATE)
//   .pipe(
//     filter(fields => fields.form === 'usersFilters'),
//     tap(() => handlers.usersListPending()),
//     debounceTime(500)
//   ).subscribe(() => {
//     handlers.usersSearch()
//   })

// Preview
payloads$(actions.USERS_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/managers/users')
      return
    }
    await globalActions.populateBranches()
    const state = store.getState()
    const { list = [] } = state.users
    const selectedUser = list.find(user => user.id === id) || {}
    const { isComplete } = selectedUser
    if (isComplete && !forceFetch) {
      handlers.usersPreviewPopulate(id)
      return
    }
    const user = (await q('getEnterpriseUser', { id })) || { error: { text: 'Not found ' } }
    const { error } = user
    if (error) return
    handlers.usersUpdate({ ...user, isComplete: true })
    handlers.usersPreviewPopulate(id)
  })

// Form
payloads$(actions.USERS_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateBranches()
    const state = store.getState()
    let userPermissionsList = await globalActions.populateUserPermissionTypes()
    let { users, branches, account, router } = state
    let { data } = router || {}
    let permissionGroupId = data['permission-group-id'] || null
    let { list: branchesList } = branches || {}
    account = account || {}
    users = users || {}
    let { list: usersList } = users || {}
    usersList = usersList || []
    const constantPermissionOptions = [
      { label: t('global.admin'), value: 'ADMIN' },
      { label: t('global.custom'), value: 'CUSTOM', isUpdateAllowedByIssuer: true }
    ]
    const userPermissionTypesOptions = userPermissionsList
      .filter(permissionType => permissionType.id !== 'ADMIN')
      .map(permissionType => ({
        label: permissionType.name,
        value: permissionType.id,
        isUpdateAllowedByIssuer: permissionType.isUpdateAllowedByIssuer
      }))
    let permissionOptions = constantPermissionOptions.concat(userPermissionTypesOptions)
    if (!account.isAdmin) permissionOptions = permissionOptions.filter(item => !!item.isUpdateAllowedByIssuer)
    const myself = usersList.find(resource => resource.email === account.email) || {}
    let userDetails = { myself, account }
    if (permissionGroupId) userDetails = { ...userDetails, permissionType: permissionGroupId }
    if (!id) return handlers.usersFormPopulate(userDetails, permissionOptions, branchesList)
    const selectedUser = usersList.find(user => user.id === id) || {}
    const { isComplete } = selectedUser || {}
    const user = isComplete ? selectedUser : usersTransform(await q('getEnterpriseUser', { id }))
    const { error } = user
    if (error) return handlers.navigateToPath(id ? `/managers/users/${id}` : '/managers/users')
    handlers.usersUpdate({ ...user, isComplete: true })
    handlers.usersFormPopulate(
      { ...user, ...userDetails },
      permissionOptions,
      branchesList
    )
  })

// Save
payloads$(actions.USERS_FORM_SAVE)
  .subscribe(async ({ user, scrollToError, isEditingOwnUser }) => {
    const state = store.getState()
    const { users, account } = state
    const { list: usersList } = users || {}
    const { id: userId } = user || {}
    const { isCustom: amICustom } = account || {}
    const userEmails = usersList.filter(userItem => userItem.id !== userId).map(item => item.email) || []
    const errors = usersFormValidate({ userEmails })
    if (errors.length) return setUsersFormSaveErrors(errors, scrollToError)
    const savedUser = await q('saveEnterpriseUser', { user: usersSaveTransform(user, isEditingOwnUser, amICustom) })
    const { error, id } = savedUser || {}
    if (error) return setUsersFormSaveErrors(usersFormServerErrorsTransform(error), scrollToError)
    handlers.usersUpdate({ ...savedUser, isComplete: true })
    handlers.navigateToPath(`/managers/users/${id}/preview`)
  })

const setUsersFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('users', errors)
  scrollToError && scrollToError(errors)
  handlers.usersFormReady()
}

// Update
payloads$(actions.USERS_UPDATE)
  .subscribe(async user => {
    if (!user) return handlers.usersPreviewPopulate()
    setTimeout(() => handlers.usersUpdated(user), 2000)
  })

// Delete
payloads$(actions.USERS_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.usersPreviewPopulate()
    const { error } = await q('deleteEnterpriseUser', { id })
    if (error) return
    handlers.usersDeleted(id)
    setTimeout(() => handlers.usersRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/managers/users'), 0)
  })

// Delete form save
payloads$(actions.USER_DELETE_FORM_SAVE)
  .subscribe(async user => {
    const { id, name } = user || {}
    let { value: nameValue } = name || {}
    nameValue = nameValue || ''
    if (!id) return
    const users = store.getState().users.list || []
    const selectedUser = users.find(user => user.id === id)
    if (!selectedUser) return
    let { name: selectedUserName } = selectedUser || {}
    if (!nameValue || (nameValue.toLowerCase().trim() !== selectedUserName.toLowerCase().trim())) {
      handlers.formErrorsSet('usersDelete', [{ key: 'name', value: 'errors.userNameNotMatching' }])
      handlers.userDeleteFormReady()
      return
    }
    handlers.usersDelete(id)
    handlers.popupHide('users-delete')
    setTimeout(() => { handlers.popupSet() }, 500)
  })
