import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCustomerFieldsDataStorage = props => {
  return (
    <div className='ta-popup__customer-fields-services'>
      <div className='ta-popup__text'>{dangerousHTML(t('customerFields.form.section.dataStorage.heading.tooltip'))}</div>
    </div>
  )
}

export default PopupCustomerFieldsDataStorage
