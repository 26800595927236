import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../../Store'
import { PopupBranchResourceDelete } from '../../../Beauties'

class PopupBranchResourceDeleteWrapper extends PureComponent {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidMount () {
    handlers.branchResourceDeleteFormPopulate()
  }

  onSubmit (cb) {
    const { id } = this.props
    const form = store.getState().forms.branchResourceDelete
    form.id = id
    handlers.branchResourceDeleteFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  onCancel () {
    const { closePopup } = this.props
    closePopup && closePopup()
  }

  render () {
    const { id, pending } = this.props
    const resource = id.resource || {}

    return (
      <PopupBranchResourceDelete
        name={resource.name}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        pending={pending}
      />
    )
  }
}

PopupBranchResourceDeleteWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

const maps = state => ({
  pending: state.forms.branches.pending || false
})

export default connect(maps)(PopupBranchResourceDeleteWrapper)
