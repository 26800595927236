import React from 'react'
import { PopupTriggerWrapper } from '../../../Beasts'
import {
  BorderedBox,
  Checkbox,
  t,
  MultipleSelect,
  Title,
  Row,
  Col,
  HintText,
  Radio,
  Textarea,
  Alert,
  FontAwesome5
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const SettingsIcsFileCustomisationResource = props => {
  const {
    hasResourceIcsFileCustomisation,
    useCustomDataFieldsNoteResource,
    icsAddressTypeResource,
    useCustomNoteResource,
    customerFields
  } = props

  return (
    <>
      <Row>
        <Col>
          <Checkbox
            name='hasResourceIcsFileCustomisation'
            label={t('settings.section.hasResourceIcsFileCustomisation.titleOfBooking.title')}
            theme='switch'
          />
        </Col>
      </Row>
      {(hasResourceIcsFileCustomisation &&
        <Row>
          <Col>
            <BorderedBox className='ta-settings-ics__borderd_box_container' topOffset>
              <Title theme='striped' label={t('global.subject')} />
              <Row>
                <Col>
                  <HintText>{dangerousHTML(t('settings.section.icsFileCustomisation.titleOfBooking.title'))}</HintText>
                </Col>
              </Row>
              <Row>
                <Col size={95}>
                  <Radio name='icsSubjectResource' value='CUSTOMER_NAME' label={t('settings.section.icsFileCustomisation.resource.titleOfBooking.option.customerName')} />
                </Col>
                <Col className='ta-settings__ics_tooltip_container'>
                  <PopupTriggerWrapper
                    position='right'
                    extras={{ render: dangerousHTML(t('settings.section.icsFileCustomisation.resource.titleOfBooking.option.customerName.tooltip.message')) }}
                    name='settings-ics-tooltip'
                  >
                    <FontAwesome5 color='rgba(80, 90, 94, 0.6)' icon='question-circle' />
                  </PopupTriggerWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Radio name='icsSubjectResource' value='BOOKED_SERVICE' label={t('settings.section.icsFileCustomisation.resource.titleOfBooking.option.bookedService')} />
                </Col>
              </Row>
              <Title theme='striped' label={t('settings.section.icsFileCustomisation.resource.address.title')} />
              <Row>
                <Col>
                  <HintText>{t('settings.section.icsFileCustomisation.resource.address.hint.label')}</HintText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Radio name='icsAddressTypeResource' value='ACCOUNT_ADDRESS' label={t('settings.section.icsFileCustomisation.resource.bookingLocation.option.companyAddress')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='icsAddressTypeResource' value='CUSTOM_ADDRESS' label={t('settings.section.icsFileCustomisation.resource.bookingLocation.option.otherAddress')} />
                    </Col>
                  </Row>
                  {(icsAddressTypeResource === 'CUSTOM_ADDRESS' &&
                    <Row>
                      <Col>
                        <BorderedBox>
                          <Textarea
                            name='icsAddressResource'
                            label={t('settings.section.icsFileCustomisation.resource.bookingLocation.option.otherAddress.textarea.label')}
                          />
                        </BorderedBox>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Radio name='icsAddressTypeResource' value='NONE' label={t('settings.section.icsFileCustomisation.resource.bookingLocation.option.noAddress')} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Title theme='striped' label={t('global.notes')} />
              <Row>
                <Col>
                  <HintText>{dangerousHTML(t('settings.section.icsFileCustomisation.notes.title'))}</HintText>
                </Col>
              </Row>
              <Row>
                <Col size={95}>
                  <Checkbox name='customerNameNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.customerName')} />
                </Col>
                <Col className='ta-settings__ics_tooltip_container'>
                  <PopupTriggerWrapper
                    position='right'
                    extras={{ render: dangerousHTML(t('settings.section.icsFileCustomisation.resource.notes.options.customerName.tooltip')) }}
                    name='settings-ics-tooltip'
                  >
                    <FontAwesome5 color='rgba(80, 90, 94, 0.6)' icon='question-circle' />
                  </PopupTriggerWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='onlineBookableResourceNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.onlineBookableResourceName')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='dependantResourceNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.dependentResourceName')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='bookedServiceNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.bookedServices')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='bookedServiceDescriptionNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.bookedServicesDescription')} />
                </Col>
              </Row>
              <Row>
                <Col size={95}>
                  <Checkbox name='bookedServicePriceNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.bookedServicesPrice')} />
                </Col>
                <Col className='ta-settings__ics_tooltip_container'>
                  <PopupTriggerWrapper
                    position='right'
                    extras={{ render: dangerousHTML(t('settings.section.icsFileCustomisation.resource.notes.options.bookedServicesPrice.tooltip')) }}
                    name='settings-ics-tooltip'
                  >
                    <FontAwesome5 color='rgba(80, 90, 94, 0.6)' icon='question-circle' />
                  </PopupTriggerWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='useCustomDataFieldsNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.customDataFieldsValues')} />
                </Col>
              </Row>
              {(useCustomDataFieldsNoteResource &&
                <Row>
                  <Col>
                    <BorderedBox>
                      <Row>
                        <Col>
                          <MultipleSelect
                            name='customDataFieldsNoteResource'
                            label={t('settings.section.icsFileCustomisation.resource.bookingDataFields.label')}
                            hintText={t('settings.section.icsFileCustomisation.resource.bookingDataFields.hint')}
                            options={customerFields}
                            searchable
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Alert noOffset theme='alert'>
                            {dangerousHTML(t('settings.section.icsFileCustomisation.resource.bookingDataFields.warning.message'))}
                          </Alert>
                        </Col>
                      </Row>
                    </BorderedBox>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Checkbox name='linkToAppointmentResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.linkToAppointment')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='useCustomNoteResource' label={t('settings.section.icsFileCustomisation.resource.notes.options.useCustomNote')} />
                </Col>
              </Row>
              {(useCustomNoteResource &&
                <Textarea
                  name='customNoteResource'
                  label={t('settings.section.icsFileCustomisation.resource.notes.options.customNote.label')}
                  limit={4000}
                />
              )}
            </BorderedBox>
          </Col>
        </Row>
      )}
    </>
  )
}

export default SettingsIcsFileCustomisationResource
