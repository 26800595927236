import React from 'react'
import {
  HoverPopupContent,
  HoverPopupTrigger,
  FontAwesome5,
  BlueTemplate,
  HoverPopup,
  FormButtons,
  FormSection,
  Alert,
  Loader,
  Input,
  Error,
  Button,
  Form,
  Row,
  Col,
  t
} from '../../../Common'

import './AccountForm.css'

const AccountEnforcePasswordChangeForm = props => {
  const {
    pending,
    onSubmit,
    onRedirect,
    oldPasswordFieldType,
    newPasswordFieldType,
    toggleOldPasswordFieldType,
    toggleNewPasswordFieldType,
    message
  } = props

  return (
    <div className='ta-account-form__enforce-password-change'>
      <BlueTemplate logoUrl='/'>
        <div className='ta-account-select__box'>
          <Loader active={pending} />
          <div className='ta-account-select__box__title' data-testid='accountSelectBoxTitle'>
            {t('account.password.enfroceReset.title')}
            <HoverPopup className='ta-account-select__box__logout'>
              <HoverPopupContent position='top' autoSize>
                {t('navigation.logout.tooltip')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button as='link' to='/logout'>
                  <FontAwesome5 icon='sign-out-alt' type='solid' />
                </Button>
              </HoverPopupTrigger>
            </HoverPopup>
          </div>
          <div className='ta-account-select__box__content'>
            {(message &&
              <Alert theme={message.type}>
                {message.text}
                <br />
                <Button as='link' onClick={onRedirect}>
                  {t('account.password.enfroceReset.success.message.redirect')}
                </Button>
              </Alert>
            )}
            {(!message &&
              <>
                <Alert theme='alert'>
                  {t('account.password.enfroceReset.message')}
                </Alert>
                <Form onSubmit={onSubmit} name='account'>
                  <FormSection>
                    <Row>
                      <Col className='relative'>
                        <Input
                          type={oldPasswordFieldType}
                          name='oldPassword'
                          label={t('global.password')}
                          mandatory
                        />
                        <div className='ta-account__password-type-toggle-btn' onClick={toggleOldPasswordFieldType}>
                          <FontAwesome5 icon={oldPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='relative'>
                        <Input
                          type={newPasswordFieldType}
                          name='newPassword'
                          label={t('account.password.enforceReset.repeatPassword.label')}
                          mandatory
                        />
                        <div className='ta-account__password-type-toggle-btn' onClick={toggleNewPasswordFieldType}>
                          <FontAwesome5 icon={newPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                        </div>
                      </Col>
                    </Row>
                    <Error name='globalErrors' />
                  </FormSection>
                  <FormButtons>
                    <Button type='submit' isPrimary>{t('buttons.saveAndContinue.label')}</Button>
                  </FormButtons>
                </Form>
              </>
            )}
          </div>
        </div>
      </BlueTemplate>
    </div>
  )
}

export default AccountEnforcePasswordChangeForm
