import React, { useEffect, memo } from 'react'
import { UserPermissionsForm } from '../../Beauties'
import { Loader, t } from '../../Common'
import { connect, handlers } from '../../Store'

const UserPermissionsEditWrapper = props => {
  let {
    pending,
    form,
    showExternalIds,
    userPermissions,
    account,
    id
  } = props
  form = form || {}
  showExternalIds = !!showExternalIds
  userPermissions = userPermissions || []
  const userPermission = userPermissions.find(permissionType => permissionType.id === id) || {}
  const { name } = userPermission || {}
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { permissionsGroupsWrite } = enterprisePermissions || {}
  permissionsGroupsWrite = !!isAdmin || !!permissionsGroupsWrite

  useEffect(() => {
    if (permissionsGroupsWrite && userPermission) handlers.userPermissionTypesFormGet(userPermission)
  }, [permissionsGroupsWrite, userPermission])

  const onSubmit = cb => {
    handlers.userPermissionTypesFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <UserPermissionsForm
          title={name || t('global.edit')}
          onSubmit={onSubmit}
          cancelLink={`/managers/user-permissions/${id}`}
          showExternalIds={showExternalIds}
        />
      )}
    </>
  )
}

const maps = state => ({
  userPermissions: state.userPermissions.list,
  id: state.router.data.id,
  pending: state.userPermissions.pendingForm,
  form: state.forms.userPermissionType,
  showExternalIds: state.company.settings && state.company.settings.showExternalIds,
  account: state.account
})

export default memo(connect(maps)(UserPermissionsEditWrapper))
