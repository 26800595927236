import React from 'react'
import { Button, FontAwesome5 } from '../../../Common'

import './ListButton.css'

const ListButton = props => {
  const { className, icon, square, children, hasText, ...rest } = props
  const classNames = ['ta-list-button', 'ta-grab']
  if (className) classNames.push(className)
  if (square) classNames.push('square')
  if (hasText) classNames.push('has-text')

  return (
    <Button as='link' isSecondary {...rest} className={classNames.join(' ')}>
      {(icon &&
        <FontAwesome5 icon={icon} />
      )}
      {children}
    </Button>
  )
}

export default ListButton
