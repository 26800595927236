import React from 'react'
import { connect } from '../../Store'
import { BranchesServiceFormReminders } from '../../Beauties'

const BranchesPreviewServiceFormRemindersWrapper = props => {
  const {
    plan,
    serviceCustomReminderCustomerSwitch,
    serviceReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    serviceCustomReminderStaffSwitch,
    serviceReminderStaffSwitch,
    serviceReminderStaffOthersCheckBox,
    serviceReminderStaffOthers,
    resourceEmailRemindersMinutes,
    allowedSet
  } = props

  return (
    <BranchesServiceFormReminders
      plan={plan || 'CLASSIC'}
      serviceCustomReminderCustomerSwitch={serviceCustomReminderCustomerSwitch}
      serviceReminderCustomerSwitch={serviceReminderCustomerSwitch}
      customerEmailRemindersMinutes={customerEmailRemindersMinutes}
      serviceCustomReminderStaffSwitch={serviceCustomReminderStaffSwitch}
      serviceReminderStaffSwitch={serviceReminderStaffSwitch}
      serviceReminderStaffOthersCheckBox={serviceReminderStaffOthersCheckBox}
      serviceReminderStaffOthers={serviceReminderStaffOthers || []}
      resourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
      allowedSet={allowedSet}
    />
  )
}

const maps = state => ({
  serviceCustomReminderCustomerSwitch: state.forms.branchesService.serviceCustomReminderCustomerSwitch && state.forms.branchesService.serviceCustomReminderCustomerSwitch.value,
  serviceReminderCustomerSwitch: state.forms.branchesService.serviceReminderCustomerSwitch && state.forms.branchesService.serviceReminderCustomerSwitch.value,
  customerEmailRemindersMinutes: state.forms.branchesService.customerEmailRemindersMinutes && state.forms.branchesService.customerEmailRemindersMinutes.values,
  serviceCustomReminderStaffSwitch: state.forms.branchesService.serviceCustomReminderStaffSwitch && state.forms.branchesService.serviceCustomReminderStaffSwitch.value,
  serviceReminderStaffSwitch: state.forms.branchesService.serviceReminderStaffSwitch && state.forms.branchesService.serviceReminderStaffSwitch.value,
  serviceReminderStaffOthersCheckBox: state.forms.branchesService.serviceReminderStaffOthersCheckBox && state.forms.branchesService.serviceReminderStaffOthersCheckBox.value,
  serviceReminderStaffOthers: state.forms.branchesService.serviceReminderStaffOthers && state.forms.branchesService.serviceReminderStaffOthers.values,
  resourceEmailRemindersMinutes: state.forms.branchesService.resourceEmailRemindersMinutes && state.forms.branchesService.resourceEmailRemindersMinutes.values
})

export default connect(maps)(BranchesPreviewServiceFormRemindersWrapper)
