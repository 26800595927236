import React from 'react'

const HoverPopupContent = props => {
  const { children, position, autoSize, width, height, maxWidth, minWidth, horizontalOffset } = props
  const availablePositions = ['top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right', 'left', 'left-top', 'left-bottom', 'right', 'right-top', 'right-bottom']
  const selectedPosition = availablePositions.includes(position) ? position : availablePositions[0]
  const classNames = ['ta-hover-popup__content']
  let popupOffset = {}
  classNames.push(selectedPosition)
  if (autoSize) classNames.push('auto-size')
  if (maxWidth) classNames.push('has-max-width')
  if (minWidth) classNames.push('has-min-width')
  if (horizontalOffset) popupOffset = { transform: `translate(${horizontalOffset}px)` }

  return (
    <div className={classNames.join(' ')} style={{ width, height, maxWidth, minWidth, ...popupOffset }} data-testid='hover-popup-content'>
      {children}
    </div>
  )
}

export default HoverPopupContent
