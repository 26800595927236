import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  format,
  t,
  FontAwesome5,
  Countdown
} from '../../../../Common'

import './NotificationCard.css'
import momenttz from 'moment-timezone'

const NotificationCard = props => {
  const {
    systemMessage,
    acceptRequest,
    declineRequest,
    revokeRequest
  } = props
  const {
    status,
    createdAt,
    requesterName,
    requesterAvatarUrl,
    providerName,
    expiresAt,
    durationHours
  } = systemMessage || {}
  const classNames = ['ta-notifications__notification-card__event', status?.toLowerCase()]
  let messageTittle = ''
  let messageIcon = ''
  let messageContext = ''
  let messageTime = ''
  if (status === 'PENDING') {
    messageTittle = t('notifications.list.requested.title')
    messageIcon = 'question-circle'
    messageContext = t('notifications.list.requested.context', [{ key: 'REQUESTER_NAME', value: requesterName }, { key: 'DURATION_HOURS', value: durationHours }])
    messageTime = t('notifications.list.expiresIn')
  }
  if (status === 'ACCEPTED') {
    messageTittle = t('notifications.list.activated.title')
    messageIcon = 'shield-check'
    messageContext = t('notifications.list.activated.context', [{ key: 'REQUESTER_NAME', value: requesterName }])
    messageTime = t('notifications.list.timeLeft')
  }
  if (status === 'GRANTED') {
    messageTittle = t('notifications.list.granted.title')
    messageIcon = 'lock-open'
    messageContext = t('notifications.list.granted.context', [{ key: 'REQUESTER_NAME', value: requesterName }, { key: 'PROVIDER_NAME', value: providerName }])
    messageTime = t('notifications.list.timeLeft')
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-notifications__notification-card__event__head'>
        <div className='ta-notifications__notification-card__event__head-icon'>
          <FontAwesome5 icon={messageIcon} type='l' />
        </div>
        <div className='ta-notifications__notification-card__event__head-title'>
          <div className='ta-notifications__notification-card__event__head-title-text'>
            {messageTittle}
          </div>
          <div className='ta-notifications__notification-card__event__head-title-time'>
            <div className='ta-notifications__notification-card__event__head-title-time-left'>{messageTime} :</div>
            <FontAwesome5 className='ta-notifications__notification-card__event__head-title-time-right' icon='hourglass-half' type='r' />
            <Countdown date={momenttz.utc(expiresAt).toDate()} />
          </div>
        </div>
      </div>
      <div className='ta-notifications__notification-card__event__body'>
        <div className='ta-notifications__notification-card__event__date-time-box'>
          <div className='ta-notifications__notification-card__event__date-time-box__content'>
            <div className='ta-notifications__notification-card__event__date-time-box__content-date'>
              {createdAt ? format(createdAt, 'shortWithShortWeekDay') : '-'}
            </div>
            <div className='ta-notifications__notification-card__event__date-time-box__content-time'>
              {createdAt ? format(createdAt, 'time') : ''}
            </div>
          </div>
        </div>
        <div className='ta-notifications__notification-card__event__content'>
          <div className='ta-notifications__notification-card__event__content-body'>
            <Avatar className='ta-notifications__notification-card__event__content-body-avatar' name={requesterName} image={requesterAvatarUrl} />
            <div className='ta-notifications__notification-card__event__content-body-text'>
              {messageContext}
            </div>
          </div>
          <div className='ta-notifications__notification-card__event__content-actions'>
            {status === 'PENDING' && (
              <>
                <button onClick={declineRequest} className='ta-btn ta-btn-secondary ta-notifications__notification-card__event__content-actions-decline'>
                  <FontAwesome5 className='ta-notifications__notification-card__event__content-actions-decline-icon' icon='times-circle' type='r' />
                  {t('global.decline')}
                </button>
                <button onClick={acceptRequest} className='ta-btn ta-btn-primary ta-notifications__notification-card__event__content-actions-accept'>
                  <FontAwesome5 className='ta-notifications__notification-card__event__content-actions-accept-icon' icon='check-circle' type='r' />
                  {t('global.aprove')}
                </button>
              </>)}
            {status !== 'PENDING' && (
              <button onClick={revokeRequest} className='ta-btn ta-btn-primary ta-notifications__notification-card__event__content-actions-revoke'>
                <FontAwesome5 className='ta-notifications__notification-card__event__content-actions-revoke-icon' icon='ban' type='r' />
                {t('global.revoke')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

NotificationCard.propTypes = {
  systemMessage: PropTypes.object.isRequired
}

export default NotificationCard
