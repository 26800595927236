import React from 'react'
import { PermissionTypePreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const PermissionTypePreviewChangedLocallyWrapper = props => {
  let { permissionTypeId, permissionTypes, branches, account } = props
  account = account || {}
  branches = branches || []
  const permissionType = permissionTypes.find(branch => branch.id === permissionTypeId) || {}
  let { locallyUpdatedBranches, internalId } = permissionType || {}
  locallyUpdatedBranches = locallyUpdatedBranches || {}
  const locallyChangedData = Object.keys(locallyUpdatedBranches).map(id => ({
    branch: branches.find(branch => branch.id === id),
    id: permissionType.id
  }))

  const onResetGlobalEntity = (companyId, region) => handlers.permissionTypesLocallyChangedReset({ companyId, region, internalId })

  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalPermissionsGroupsWrite } = enterprisePermissions || {}
  globalPermissionsGroupsWrite = !!isAdmin || !!globalPermissionsGroupsWrite

  return (
    <PermissionTypePreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalPermissionsGroupsWrite={globalPermissionsGroupsWrite}
    />
  )
}

const maps = state => ({
  permissionTypeId: (state.router.data && state.router.data.id),
  permissionTypes: state.permissionTypes.list,
  branches: state.branches.list,
  account: state.account
})

export default connect(maps)(PermissionTypePreviewChangedLocallyWrapper)
