import React, { useEffect } from 'react'
import { connect, handlers, store, selectors } from '../../../Store'
import { PopupAppsCancelSubscription } from '../../../Beauties'

const PopupAppsCancelSubscriptionWrapper = props => {
  let {
    id,
    app,
    pending,
    closePopup
  } = props
  app = app || {}
  pending = !!pending

  useEffect(() => {
    handlers.appsSubscriptionCancelFormPopulate()
  }, [id])

  const handleOnSubmit = (cb) => {
    const form = store.getState().forms.popup
    handlers.appSubscriptionCancelFormSave({ ...form, id })
    closePopup && closePopup()
  }

  const handleOnCancel = () => {
    closePopup && closePopup()
  }

  return (
    <PopupAppsCancelSubscription
      onSubmit={handleOnSubmit}
      onCancel={handleOnCancel}
      pending={pending}
      name={app.name}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    pending: selectors.appsFieldSelector(state, { field: 'pendingSubscriptionCancelForm' })
  }
}

export default connect(maps)(PopupAppsCancelSubscriptionWrapper)
