import React from 'react'
import {
  Title,
  FormText,
  Alert,
  ServiceCombinationSelect,
  Row,
  Col,
  t
} from '../../../Common'

const ServiceCombinationFormServicesSelector = props => {
  return (
    <>
      <Title size='m' label={t('servicesGroups.form.section.serviceSelection.title')} icon='list' />
      <FormText>
        {t('servicesGroups.form.section.serviceSelection.description')}
      </FormText>
      <Alert theme='alert'>
        {t('servicesGroups.form.section.serviceSelection.warningMessage')}
      </Alert>
      <Row>
        <Col>
          <ServiceCombinationSelect
            name='services'
            label={t('servicesGroups.form.section.serviceSelection.select.title')}
          />
        </Col>
      </Row>
    </>
  )
}

export default ServiceCombinationFormServicesSelector
