// System messages

export const systemMessagesListTransform = (systemMessages, resources) => {
  return systemMessages.map(item => systemMessagesTransform(item, resources))
}

export const systemMessagesTransform = (item, resources) => {
  if (!item) return
  const { timifyUserId } = item || {}
  const accessUserData = resources?.find((resource) => resource?.id === timifyUserId) || {}
  const { avatarUrl: requesterAvatarUrl } = accessUserData

  const result = {
    ...item,
    requesterAvatarUrl,
    requesterName: item?.timifyUserName,
    providerName: item?.accessUserName
  }
  delete result.data
  return result
}
