import React from 'react'
import PropTypes from 'prop-types'
import { Button, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupResourcesAccountUnlinkConfirmation = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__resources__account-unlink-confirmations'>
      <div className='ta-popup__title'>{dangerousHTML(t('popup.resourcesAccount.unlink.confirmation'))}</div>
      <div className='ta-popup__buttons'>
        <Button as='link' isSecondary onClick={reject}>{t('global.no')}</Button>
        <Button as='link' isPrimary onClick={approve}>{t('global.yes')}</Button>
      </div>
    </div>
  )
}

PopupResourcesAccountUnlinkConfirmation.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupResourcesAccountUnlinkConfirmation
