import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { TwoFALoginForm } from '../../Beauties'

const TwoFALoginFormWrapper = props => {
  const {
    auth2FAs,
    onAccountSelect,
    recoveryCodes,
    company,
    onBack,
    pending
  } = props

  const isSetAccount2FA = auth2FAs && auth2FAs.length && auth2FAs[0] && auth2FAs[0].isActive

  useEffect(() => {
    return () => {
      handlers.twoFARecoveryCodesClear()
    }
  }, [])

  const loginToAccount = () => {
    handlers.accountSelect(company.accountId)
  }

  return (
    <TwoFALoginForm
      isSetAccount2FA={isSetAccount2FA}
      onAccountSelect={onAccountSelect}
      recoveryCodes={recoveryCodes}
      companyName={company && company.name}
      loginToAccount={loginToAccount}
      onBack={onBack}
      pending={pending}
    />
  )
}

TwoFALoginFormWrapper.propTypes = {
  onAccountSelect: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  pending: PropTypes.bool
}

const maps = state => ({
  auth2FAs: state.account.auth2FAs,
  recoveryCodes: state.twoFA.recoveryCodes,
  pending: state.account.pendingAccountSelect
})

export default connect(maps)(TwoFALoginFormWrapper)
