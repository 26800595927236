import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  TableRowHeader,
  TableRowMainCol,
  TableRowCol,
  Title,
  Button,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Alert,
  Blur,
  t
} from '../../Common'
import {
  BranchesList,
  BranchesFilter
} from '../../Beauties'

import './Branches.css'

const Branches = props => {
  const {
    branches,
    search,
    onClick,
    showImportMessage,
    hideBranchLoginBtn,
    hideBranchAddBtn,
    hideBranchImportBtn,
    branchesRead,
    branchesWrite,
    tagsRead,
    isAdmin,
    exportTemplateCode,
    allowFulfilment,
    onClickExportBranches,
    showBranchLoginWithoutCustomisationsBtn
  } = props
  const classNames = ['ta-branches']
  if (showImportMessage) classNames.push('import-message-open')
  if (!branchesRead) classNames.push('no-permissions')
  let buttonsColWidth = '32px'
  if (showBranchLoginWithoutCustomisationsBtn && !hideBranchLoginBtn) buttonsColWidth = '62px'

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='building' iconTheme='grey' label={t('branches.list.title')}>
            {(isAdmin && exportTemplateCode &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('branches.list.popup.exportTemplate')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' onClick={onClickExportBranches} isSecondary icon='file-download' iconType='s' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
            {(branchesWrite &&
              <>
                {(!hideBranchImportBtn &&
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('brances.list.popup.importBranches')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button as='link' to='/branches/import' isSecondary icon='upload' iconType='s' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                )}
                {(!hideBranchAddBtn &&
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('branches.list.addNew')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button as='link' to='/branches/add' isPrimary icon='plus' iconType='s' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                )}
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!branchesRead} icon='ban' label={t('global.accessDenied')}>
            {(branchesRead &&
              <BranchesFilter />
            )}
            {(showImportMessage &&
              <Alert label={t('branches.list.imoportStarted')} theme='success'>
                {t('branches.list.imoportStarted.message', [{ key: 'ENTITY', value: t('global.branch') }])}
              </Alert>
            )}
            <TableRowHeader>
              <TableRowMainCol>{t('global.location')}</TableRowMainCol>
              <TableRowCol size='110px'>{t('global.plan')}</TableRowCol>
              <TableRowCol size='110px'>{t('global.registered')}</TableRowCol>
              {(!hideBranchLoginBtn || showBranchLoginWithoutCustomisationsBtn) && (
                <TableRowCol size={buttonsColWidth} />
              )}
            </TableRowHeader>
            <BranchesList
              branches={branches}
              search={search}
              onClick={onClick}
              hideBranchLoginBtn={hideBranchLoginBtn}
              allowFulfilment={allowFulfilment}
              tagsRead={tagsRead}
              showBranchLoginWithoutCustomisationsBtn={showBranchLoginWithoutCustomisationsBtn}
            />
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Branches
