import React from 'react'

import { dangerousHTML } from '../../../Utils'

import './AppsSubscription.css'

const AppsSubscriptionInvoiceRow = (props) => {
  let {
    big,
    label,
    price,
    description,
    withDivider
  } = props
  big = !!big
  const classNames = ['ta-apps__subscription-invoice-row']
  if (big) classNames.push('big')
  if (withDivider) classNames.push('with-divider')

  return (
    <div className={classNames.join(' ')}>
      {label && (
        <div className='ta-apps__subscription-invoice-row__item'>
          <div>{label}</div>
          {!!price && price}
        </div>
      )}
      {description && (
        <div className='ta-apps__subscription-invoice-row__description'>
          {dangerousHTML(description)}
        </div>
      )}
    </div>
  )
}

export default AppsSubscriptionInvoiceRow
