import React from 'react'
import PropTypes from 'prop-types'
import {
  IconAvatar,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Button,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PermissionTypeItem = (props) => {
  const { item } = props
  const {
    name,
    color,
    icon,
    id,
    locallyUpdatedBranches
  } = item || {}
  const globalLabelClassNames = ['global-label', 'edited']
  const locallyChangedBrancheIds = (locallyUpdatedBranches && Object.keys(locallyUpdatedBranches)) || []
  const hasLocallyChangedBranches = !!locallyChangedBrancheIds.length

  return (
    <Button as='link' className='ta-permission-types__item' to={`/management/permission-types/${id}`}>
      <IconAvatar color={color} icon={icon} />
      <div className='ta-permission-types__item__content'>
        <div className='ta-permission-types__item__content__name'>
          <div className='ta-permission-types__item__content__name_title'>
            {name}
          </div>
          {(id === 'ADMIN' &&
            <div className='ta-permission-types__item__content__name__lock'>
              <FontAwesome5 icon='lock' type='solid' />
            </div>
          )}
          {(hasLocallyChangedBranches &&
            <div className='ta-permission-types__item__content__global'>
              <HoverPopup>
                <HoverPopupContent position='top'>
                  {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBrancheIds.length }]))}
                </HoverPopupContent>
                <Button as='link' to={`/management/permission-types/${id}@@changedLocally`}>
                  <div className={globalLabelClassNames.join(' ')}>
                    <HoverPopupTrigger>
                      <FontAwesome5 icon='pencil' type='s' />
                      {t('global.changedLocally')}
                    </HoverPopupTrigger>
                  </div>
                </Button>
              </HoverPopup>
            </div>
          )}
        </div>
      </div>
    </Button>
  )
}

PermissionTypeItem.propTypes = {
  item: PropTypes.object.isRequired
}

export default PermissionTypeItem
