import React, { useEffect, useState } from 'react'
import { t } from '../../../Common'

import './AddressInput.css'

const AddressInputNoResult = ({ isShown }) => {
  const [show, setShow] = useState(false)

  // loading and suggestios are coming one after the other, in other words:
  // if you are typing to search for results, and you get some results, this happens:
  // 1 - loading true, suggestions empty
  // 2 - loading false, suggestions empty
  // 3 - loading false, suggestions with values
  // This causes unexpected "No Results" message to appear while typing
  // FIX: wait 0.3s before rendering "No Results"

  useEffect(() => {
    if (!isShown) setShow(false)
    if (isShown) {
      setTimeout(() => {
        setShow(isShown)
      }, 300)
    }
  }, [isShown])

  if (!show || !isShown) return null
  return (
    <div className='ta-address-input__autocomplete-dropdown'>
      <div className='ta-address-input__suggestion-item'>
        <span>{t('global.noResults')}</span>
      </div>
    </div>
  )
}

export default AddressInputNoResult
