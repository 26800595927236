import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Alert,
  Blur,
  t
} from '../../../Common'
import { PopupTriggerWrapper, CustomerPreviewChangedLocallyWrapper } from '../../../Beasts'
import { CustomerPreviewDetails } from '../../../Beauties'

import './CustomerPreview.css'

const CustomerPreview = props => {
  let {
    customer,
    hash,
    timezones,
    message,
    enterpriseId,
    customersRead,
    customersWrite,
    customersDelete,
    allowOnlyGlobalCustomers
  } = props
  customer = customer || {}
  const availableTabs = ['details']
  if (!allowOnlyGlobalCustomers) availableTabs.push('changedLocally')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const {
    id,
    fullName,
    companyName,
    internalId,
    isUpdatedLocally,
    isGloballyDeleted
  } = customer || {}
  const isGlobal = enterpriseId && internalId
  const classNames = ['ta-customers__preview']
  const edited = isGlobal && isUpdatedLocally
  if (edited) classNames.push('edited global')
  const title = customersRead ? fullName || companyName || t('global.details') : t('global.accessDenied')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title}>
            {(id && customersWrite &&
              <>
                {(customersDelete &&
                  <PopupTriggerWrapper name='customers-delete' size='m' position='bottom' id={id}>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('customers.buttonHeading.deleteTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button isTertiary as='link' className='ta-btn ta-btn-delete' external icon='trash-alt' />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('customers.buttonHeading.editTooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' to={`/customers/${id}/edit`} isPrimary icon='pencil' />
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={customersRead ? 'inactive' : ''} active={!customersRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && isGlobal && !isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                <p>{t('globalEntities.preview.locallyEdittedWarning')}</p>
                <PopupTriggerWrapper name='customers-reset-global-entity' position='right' id={{ internalId }}>
                  <Button as='link' isPrimary isBlock className='ta-btn ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(isGloballyDeleted && isGlobal &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                <p>{t('globalEntities.preview.removedFromDatabase')}</p>
                <PopupTriggerWrapper name='customers-delete-global-entity' position='right' id={id}>
                  <Button as='link' isPrimary isBlock className='ta-btn ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(customer.id &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!customersRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <CustomerPreviewDetails customer={customer} timezones={timezones} />
                  </SimpleTabsBodyItem>
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsBodyItem name='changedLocally' renderOnlyIfActive>
                      <CustomerPreviewChangedLocallyWrapper customer={customer} activeTab={activeTab} />
                    </SimpleTabsBodyItem>
                  )}
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CustomerPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default CustomerPreview
