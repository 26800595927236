import React from 'react'
import {
  FontAwesome5,
  SimpleBox,
  Price,
  t
} from '../../Common'

import './StatisticsBoxNew.css'

const StatisticsBoxNew = props => {
  const {
    color,
    backgroundColor,
    isPending,
    title = null,
    secondDescription,
    secondData,
    icon,
    iconType = 'regular',
    iconColor,
    className,
    extraClassName,
    headerText,
    headerLabel,
    itemName,
    itemColor,
    headerSettingsButton,
    extraHeaderText,
    calculatePercentage,
    statisticId,
    isResourceStatistic,
    isOverallStatistic,
    currency = '',
    showAvailableCapacity,
    showAvailableCapacityText = 'Available',
    filterLabel,
    filterLabelMaxLength,
    labels,
    infoPopup,
    isBig
  } = props
  const classNames = ['ta-statistic-box']
  if (className) classNames.push(className)
  const titleIsValid = !isNaN(title) && title != null
  // const formattedTitle = typeof title === 'number' ? title.toFixed(2) : title
  const secondDataIsValid = !isNaN(secondData) && secondData != null
  // for percentage
  const {
    total,
    value,
    data1Description,
    data2Description,
    availabilityPercentage,
    availabilityText = 'available',
    useFirstPercentage,
    useSecondValue
  } = calculatePercentage || {}
  const value2 = total === 0 && value === 0 ? 0 : total - value
  const percentage1 = (value2 === 0 && value === 0
    ? 0
    : ((value / total) * 100)).toFixed(2)
  const percentage2 = (100 - percentage1).toFixed(2)
  const percentageValue1 = `${percentage1}%`
  const percentageValue2 = `${percentage2}%`
  const shouldCalculatePercentage = !isNaN(calculatePercentage && total && value) && (calculatePercentage && total && value) != null

  return (
    <SimpleBox
      className={extraClassName}
      isBig={isBig}
      headerText={headerText}
      extraHeaderText={extraHeaderText}
      headerLabel={headerLabel}
      itemName={itemName}
      headerSettingsButton={headerSettingsButton}
      itemColor={itemColor}
      isLoading={isPending}
      statisticId={statisticId}
      isResourceStatistic={isResourceStatistic}
      isOverallStatistic={isOverallStatistic}
      filterLabel={filterLabel}
      filterLabelMaxLength={filterLabelMaxLength}
      labels={labels}
      infoPopup={infoPopup}
    >
      <div className={classNames.join(' ')} style={{ backgroundColor, color }}>
        <div className={`ta-statistic-box__title ${!titleIsValid || (secondDataIsValid || shouldCalculatePercentage || secondDescription) ? '' : 'no-additional-data'}`} style={{ color }}>
          {(!shouldCalculatePercentage && titleIsValid) && currency ? <Price currency={currency} price={title} /> : title}
          {(!shouldCalculatePercentage && !titleIsValid && secondDataIsValid) && '-'}
          {(!shouldCalculatePercentage && (!titleIsValid && !secondDataIsValid)) &&
            <div className='ta-statistic-box__title__no-results'>
              <FontAwesome5 icon='sad-tear' /> <span className='ta-statistic-box__title__no-results--text'>{t('statistics.notEnoughData')}</span>
              <p>{statisticId === 'totalRevenue totalOnlinePaidRevenue' ? t('statistics.noOnlinePaymentActivated') : t('statistics.needMoreInfo')}</p>
            </div>
          }
          {(titleIsValid && icon && !shouldCalculatePercentage) && <FontAwesome5 className='ta-statistic-box__title__icon' icon={icon} type={iconType} color={iconColor === 'green' ? '#769851' : '#cb3b43'} />}
          {shouldCalculatePercentage &&
            <div className='percentages'>
              <div className='percentages__figures'>
                {showAvailableCapacity && <p><span>{value}</span> / {total}</p>}
                {showAvailableCapacity && <p className='percentages__available-capacity'>{percentageValue2} {showAvailableCapacityText}</p>}
                {!showAvailableCapacity && <p><span>{isNaN(value) ? '-' : value}</span> {availabilityPercentage && `(${useFirstPercentage ? percentage1 : percentage2}% ${availabilityText})`} {!availabilityPercentage && `(${percentageValue1})`}</p>}
                {!showAvailableCapacity && <p><span>{isNaN(value2) ? '-' : useSecondValue ? value2 : total}</span> {!availabilityPercentage && `(${percentageValue2})`}
                </p>}
              </div>
              <div className='percentage-container'>
                <div
                  className='percentage-bar'
                  style={{ width: percentage1 <= 100 ? percentageValue1 : '100%' }}
                />
              </div>
              <div className='percentages__data'>
                <div className='percentages__data__type'>
                  <div className='percentages__data__type--color' style={{ background: '#385f87' }} />
                  <span>{data1Description || '-'}</span>
                </div>
                <div className='percentages__data__type'>
                  <div className='percentages__data__type--color' style={{ background: '#d3d6d7' }} />
                  <span>{data2Description || '-'}</span>
                </div>
              </div>
            </div>
          }
        </div>
        {(secondDataIsValid || secondDescription) &&
          <div className='ta-statistic-box__info' style={{ color }}>
            <div className='ta-statistic-box__info__item'>
              {(secondDataIsValid || titleIsValid) && secondDescription}
              {!shouldCalculatePercentage && (secondDataIsValid || titleIsValid) && secondData !== undefined &&
                <>
                  <span className='ta-statistic-box__span'>
                    {(!shouldCalculatePercentage && secondDataIsValid) && secondData}
                    {(!shouldCalculatePercentage && !secondDataIsValid && titleIsValid) && '-'}
                  </span>
                </>
              }
            </div>
          </div>
        }
      </div>
    </SimpleBox>
  )
}

export default StatisticsBoxNew
