import React from 'react'
import PropTypes from 'prop-types'
import { Alert, t } from '../../Common'
import { CustomerFieldsListItem } from '../../Beauties'

import './CustomerFieldsList.css'

const CustomerFieldsList = props => {
  const {
    customerFields,
    isDragAndDropMode,
    areCategoriesExpanded,
    enterpriseId,
    categoryIndex
  } = props
  const classNames = ['ta-customer-fields__list']
  // if (isGlobalLabelOpen) classNames.push('global-label__opened')

  return (
    <div className={classNames.join(' ')}>
      {customerFields.length > 0 && customerFields.map((customerField, index) => (
        <CustomerFieldsListItem
          key={customerField.id}
          customerField={customerField}
          index={index}
          isDragAndDropMode={!customerField.isDefault && isDragAndDropMode}
          areCategoriesExpanded={areCategoriesExpanded}
          enterpriseId={enterpriseId}
          categoryIndex={categoryIndex}
        />
      ))}
      {(!customerFields.length &&
        <Alert noOffset label={t('customerFields.list.noResultsBox.empty.text')} theme='no-content' />
      )}
    </div>
  )
}

CustomerFieldsList.propTypes = {
  customerFields: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default CustomerFieldsList
