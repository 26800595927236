import React, { forwardRef } from 'react'
import {
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'

import './AppsList.css'

const AppsListActionsPopup = forwardRef((props, ref) => {
  const {
    active,
    isAdmin,
    isInvoiceShown,
    isSubscriptionAddShown,
    isSubscriptionViewShown,
    isUninstallShown,
    onClickInvoices,
    onClickSubscriptionAdd,
    onClickSubscriptionView,
    onClickUninstall,
    isPaidByEnterprise
  } = props
  const classNames = ['ta-apps-actions__popup']
  if (active) classNames.push('active')

  const onClick = e => {
    e.stopPropagation()
  }

  return (
    <div className={classNames.join(' ')} ref={ref} onClick={onClick}>
      {isInvoiceShown && (
        <div className='ta-apps-actions__popup__item' onClick={onClickInvoices}>
          <FontAwesome5 icon='eye' type='s' /> <p>{t('apps.list.acions.viewInvoices')}</p>
        </div>
      )}
      {isSubscriptionAddShown && (
        <HoverPopup disabled={isAdmin}>
          <HoverPopupContent position='left' autoSize>
            {t('global.accessDenied')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className={`ta-apps-actions__popup__item ${!isAdmin ? 'disabled' : ''}`} onClick={onClickSubscriptionAdd}>
              <FontAwesome5 icon='file-contract' type='s' />
              {' '}
              <p>
                {isPaidByEnterprise && t('apps.list.acions.addSubscription')}
                {!isPaidByEnterprise && t('apps.list.acions.viewSubscription')}
              </p>
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
      )}
      {isSubscriptionViewShown && (
        <HoverPopup disabled={isAdmin}>
          <HoverPopupContent position='left' autoSize>
            {t('global.accessDenied')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className={`ta-apps-actions__popup__item ${!isAdmin ? 'disabled' : ''}`} onClick={onClickSubscriptionView}>
              <FontAwesome5 icon='file-contract' type='s' /> <p>{t('apps.list.acions.viewSubscription')}</p>
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
      )}
      {isUninstallShown && (
        <HoverPopup disabled={isAdmin}>
          <HoverPopupContent position='left' autoSize>
            {t('global.accessDenied')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className={`ta-apps-actions__popup__item ${!isAdmin ? 'disabled' : ''}`} onClick={onClickUninstall}>
              <FontAwesome5 icon='trash' type='s' /> <p>{t('apps.list.acions.uninstall')}</p>
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
      )}
    </div>
  )
})

export default AppsListActionsPopup
