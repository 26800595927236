import { handlers, store } from '../../../Store'

export const userPermissionGroupUpdated = subscriptionData => {
  subscriptionData = subscriptionData || {}
  const { id, ts, permissionType } = subscriptionData || {}
  let { userPermissions } = store.getState()
  userPermissions = userPermissions || {}
  let { list: userPermissionsList } = userPermissions || {}
  userPermissionsList = userPermissionsList || []
  const { ts: permissionTypeTs, id: permissionTypeId } = userPermissionsList.find(permissionType => permissionType.id === id) || {}
  if (!permissionTypeId) return handlers.userPermissionTypesGet(true)
  if (ts === parseFloat(permissionTypeTs)) return
  handlers.userPermissionTypeUpdate(permissionType)
}

export const userPermissionGroupDeleted = subscriptionData => {
  subscriptionData = subscriptionData || {}
  const { id } = subscriptionData || {}
  let { userPermissions, router } = store.getState()
  userPermissions = userPermissions || {}
  let { list: userPermissionsList } = userPermissions || {}
  const { data, name } = router || {}
  const updatedUserPermissionTypesList = userPermissionsList.filter(permissionType => permissionType.id !== id)
  handlers.userPermissionTypesPopulate(updatedUserPermissionTypesList)
  if (['userPermissionsPreview', 'userPermissionsEdit'].includes(name)) {
    const { id: routerUserPermissionId } = data || {}
    if (routerUserPermissionId === id) handlers.navigateToRoute('userPermissions')
  }
}
