import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { Loader, t } from '../../../Common'
import { UsersForm } from '../../../Beauties'

const UsersAddWrapper = props => {
  useEffect(() => {
    handlers.usersFormGet()
  }, [])

  const onSubmit = cb => {
    let { form } = props
    form = form || {}

    handlers.usersFormSave({ user: form }, (errors) => {
      cb && cb(errors)
    })
  }

  let {
    pending,
    errors,
    permissionType: permissionTypeValue,
    permissionTypesOptions,
    isPermissionUpdateAllowedByEnterpriseIssuer,
    amIAdmin,
    allPermissionTypesOptions
  } = props
  pending = !!pending
  permissionTypesOptions = permissionTypesOptions || []
  let permissionType = allPermissionTypesOptions
    .map(item => ({
      value: item.id,
      label: item.name,
      isUpdateAllowedByIssuer: item.isUpdateAllowedByIssuer
    }))
    .find(item => item.value === permissionTypeValue)
  if (!permissionType) permissionType = { label: t('global.custom'), value: 'CUSTOM', isUpdateAllowedByIssuer: true }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <UsersForm
          title={t('users.list.add')}
          cancelLink='/managers/users'
          errors={errors}
          onSubmit={onSubmit}
          permissionType={permissionType}
          permissionTypesOptions={permissionTypesOptions}
          isPermissionUpdateAllowedByEnterpriseIssuer={isPermissionUpdateAllowedByEnterpriseIssuer}
          isCustom={permissionTypeValue === 'CUSTOM'}
          type='add'
          amIAdmin={amIAdmin}
        />
        )}
    </>
  )
}

UsersAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = state => ({
  pending: state.users.pendingForm,
  form: state.forms.users,
  errors: state.users.errors,
  permissionType: state.forms.users && state.forms.users.permissionType && state.forms.users.permissionType.value,
  permissionTypesOptions: state.forms.users && state.forms.users.permissionType && state.forms.users.permissionType.options,
  isPermissionUpdateAllowedByEnterpriseIssuer: state.forms.users && state.forms.users.isPermissionUpdateAllowedByEnterpriseIssuer,
  amIAdmin: state.account && state.account.isAdmin,
  allPermissionTypesOptions: state.userPermissions && state.userPermissions.list
})

export default connect(maps)(UsersAddWrapper)
