import React, { useEffect, useState, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { BranchesServiceCombinationForm } from '../../Beauties'
import { SERVICE_COMBINATION_INITIAL_KEYS } from '../../Settings/settings'

const BranchesPreviewServiceCombinationEditWrapper = props => {
  let {
    id,
    services,
    pending,
    form,
    plan,
    hash,
    settings,
    branch
  } = props
  let { showExternalIds, serviceCombinationLocalUpdates } = settings || {}
  const { isGlobal } = form || {}
  const { value: isGlobalValue } = isGlobal || {}
  if (!isGlobalValue) serviceCombinationLocalUpdates = SERVICE_COMBINATION_INITIAL_KEYS
  else {
    serviceCombinationLocalUpdates = serviceCombinationLocalUpdates.isForbidden
      ? []
      : !serviceCombinationLocalUpdates.keys.length
          ? SERVICE_COMBINATION_INITIAL_KEYS
          : serviceCombinationLocalUpdates.keys
  }
  services = services || []
  showExternalIds = !!showExternalIds
  form = form || {}
  branch = branch || {}
  plan = plan || 'CLASSIC'
  const [initial] = useState({ id, services, branch })
  const { id: branchId } = branch || {}
  const cancelLink = `/branches/${branchId}/preview@@services`

  useEffect(() => {
    const { id, services, branch } = initial
    const service = services.find(item => item.id === id) || {}
    if (!service.isCombination) return handlers.navigateToPath(cancelLink)
    handlers.branchesServiceCombinationFormGet({ service, branch })
  }, [initial, cancelLink])

  const onSubmit = cb => {
    handlers.branchesServiceCombinationFormSave(branch, form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <BranchesServiceCombinationForm
          id={id}
          title={t('servicesGroups.edit.serviceCombination.heading')}
          type='edit'
          cancelLink={cancelLink}
          onSubmit={onSubmit}
          showExternalIds={showExternalIds}
          allowedSet={serviceCombinationLocalUpdates}
          plan={plan}
          hash={hash}
        />
      )}
    </>
  )
}

const maps = state => {
  const branches = state.branches.list || []
  const branchId = state.router.data.branchId
  const branch = branches.find(item => item.id === branchId) || {}
  const { services: branchServices, plan } = branch || {}
  let { services } = branchServices || {}
  services = services || []
  return ({
    id: state.router.data.id,
    services,
    plan,
    pending: state.branches.pendingForm,
    form: state.forms.branchesService,
    settings: state.company.settings,
    branch,
    hash: state.router.hash
  })
}

export default memo(connect(maps)(BranchesPreviewServiceCombinationEditWrapper))
