import React from 'react'
import { dangerousHTML } from '../../../Utils'

const CustomerBookingsPrintHeader = props => {
  const { title, text, extraText } = props

  return (
    <div className='ta-customer__bookings-print__header'>
      <div className='ta-customer__bookings-print__header__info'>
        {(title &&
          <div className='ta-customer__bookings-print__header__title'>
            {dangerousHTML(title)}
          </div>
        )}
        {(text &&
          <div className='ta-customer__bookings-print__header__text'>
            {dangerousHTML(text)}
          </div>
        )}
        {(extraText &&
          <div className='ta-customer__bookings-print__header__extra-text'>
            {extraText}
          </div>
        )}
      </div>
      <div className='ta-cleaner' />
    </div>
  )
}

export default CustomerBookingsPrintHeader
