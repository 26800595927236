import React from 'react'
import {
  BookingIntervalsInput,
  HoverPopupContent,
  HoverPopupTrigger,
  DurationInput,
  Title,
  FontAwesome5,
  FormSection,
  BorderedBox,
  HoverPopup,
  FormText,
  Checkbox,
  Alert,
  Button,
  Row,
  Col,
  t
} from '../../../../Common'
import { PopupTriggerWrapper } from '../../../../Beasts'
import { convertMinutesToDaysHoursMinutes, hex2rgba, dangerousHTML } from '../../../../Utils'

const BranchesServiceFormDuration = props => {
  const {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    splitDurationInIntervals,
    plan,
    color,
    allowedSet
  } = props

  const translations = {
    minutes: t('global.minutes.short'),
    hours: t('global.hours.short'),
    days: t('global.days.short')
  }
  const bufferTimesStyles = { background: hex2rgba(color, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color, 1) }
  const isDurationsDisabled = !allowedSet?.includes('durationsPattern')
  const isDurationBeforeDisabled = !allowedSet?.includes('durationBefore')
  const isDurationAfterDisabled = !allowedSet?.includes('durationAfter')

  return (
    <FormSection>
      <Title size='m' label={t('servicesGroups.form.section.duration.heading')} icon='hourglass-half' />
      {(!splitDurationInIntervals &&
        <Row>
          <Col>
            <FormText>
              {t('global.duration')}
              <div className='ta-services-duration-tooltip'>
                <PopupTriggerWrapper name='service-duration-tooltip' position='left'>
                  <Button as='link' className='btn-small-icon-only' external>
                    <FontAwesome5 icon='question-circle' type='regular' />
                  </Button>
                </PopupTriggerWrapper>
              </div>
            </FormText>
            <HoverPopup disabled={plan === 'ENTERPRISE' && !isDurationsDisabled} className='ta-services__form__description-hover-popup'>
              <HoverPopupContent position='top'>
                {t('globalSettings.form.section.attributes.disabled')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <DurationInput disabled={isDurationsDisabled} name='duration' noDays={!showDurationDaySelector} />
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <HoverPopup disabled={plan === 'ENTERPRISE' && !isDurationsDisabled}>
            <HoverPopupContent position='right'>
              {isDurationsDisabled ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                disabled={plan !== 'ENTERPRISE' || isDurationsDisabled}
                name='splitDurationInIntervals'
                label={t('servicesGroups.form.section.duration.checkboxSplitBooking.description')}
                theme='switch'
                inline
              />
            </HoverPopupTrigger>
          </HoverPopup>

          <PopupTriggerWrapper name='courses-split-intervals' position='right'>
            <FontAwesome5 className='ta-branches-service-duration__question-circle-icon' icon='question-circle' type='regular' />
          </PopupTriggerWrapper>
        </Col>
      </Row>
      {(splitDurationInIntervals &&
        <BorderedBox>
          <Row>
            <Col>
              <HoverPopup disabled={!isDurationsDisabled} className='ta-services__form__description-hover-popup'>
                <HoverPopupContent position='top'>
                  {t('globalSettings.form.section.attributes.disabled')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <BookingIntervalsInput
                    name='intervals'
                    disabled={isDurationsDisabled}
                    limit={7}
                    color={color}
                    noDays={!showDurationDaySelector}
                  />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
        </BorderedBox>
      )}
      <Row>
        <Col>
          <HoverPopup disabled={plan !== 'CLASSIC' && (!isDurationBeforeDisabled || !isDurationAfterDisabled)}>
            <HoverPopupContent position='top'>
              {(isDurationBeforeDisabled && isDurationAfterDisabled) ? t('globalSettings.form.section.attributes.disabled') : t('global.premiumOnly')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                disabled={plan === 'CLASSIC' || (isDurationBeforeDisabled && isDurationAfterDisabled)}
                name='showDurationBeforeAndAfter'
                label={t('servicesGroups.form.section.duration.checkboxPrepFollowUpTime.description')}
                theme='switch'
                inline
              />
            </HoverPopupTrigger>
          </HoverPopup>
          <PopupTriggerWrapper name='services-duration-before-and-after' position='right'>
            <FontAwesome5 className='ta-branches-service-duration__question-circle-icon' icon='question-circle' type='regular' />
          </PopupTriggerWrapper>
        </Col>
      </Row>
      {(showDurationBeforeAndAfter &&
        <BorderedBox>
          <div className='ta-buffer-times'>
            <div className='ta-buffer-times__box' style={bufferTimesStyles}>
              <div className='ta-buffer-times__box__content' style={bufferTimesContentContentStyles} />
            </div>
            <Row>
              <Col>
                <HoverPopup disabled={!isDurationBeforeDisabled} className='ta-services__form__description-hover-popup'>
                  <HoverPopupContent position='top'>
                    {t('globalSettings.form.section.attributes.disabled')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Title theme='striped' label={t('global.duration.preparationTime')} />
                    <DurationInput disabled={isDurationBeforeDisabled} name='durationBefore' noDays={!showDurationDaySelector} />
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
            <Row>
              <Col>
                <HoverPopup disabled={!isDurationAfterDisabled} className='ta-services__form__description-hover-popup'>
                  <HoverPopupContent position='top'>
                    {t('globalSettings.form.section.attributes.disabled')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Title theme='striped' label={t('global.duration.followUpTime')} />
                    <DurationInput disabled={isDurationAfterDisabled} name='durationAfter' noDays={!showDurationDaySelector} />
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
          </div>
          {(!!(durationBefore || durationAfter) &&
            <Alert noOffset theme='alert'>
              {(durationBefore &&
                <div>
                  {t('servicesGroups.form.section.duration.notePrepDuration.text')}:<br />
                  <strong>{convertMinutesToDaysHoursMinutes(durationBefore, translations)}</strong>
                </div>
              )}
              {(durationAfter &&
                <div>
                  {dangerousHTML(t('servicesGroups.form.section.duration.noteFollowDuration.text'))}:<br />
                  <strong>{convertMinutesToDaysHoursMinutes(durationAfter, translations)}</strong>
                </div>
              )}
              <div>{dangerousHTML(t('servicesGroups.form.section.duration.noteDuration.text'))}</div>
            </Alert>
          )}
        </BorderedBox>
      )}
    </FormSection>
  )
}

export default BranchesServiceFormDuration
