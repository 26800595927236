import React, { useEffect } from 'react'

import { connect, handlers, selectors } from '../../../Store'
import { isRouteDisabledByCustomisations } from '../../../Common'
import { AppsSubscriptionGone } from '../../../Beauties'

const AppsSubscriptionGoneWrapper = props => {
  let { id, app, isOwner, isAdmin } = props
  app = app || {}
  const { appInstallation } = app || {}
  const { hasUsage, isPaidByEnterprise, plans = [], unitDescription, currencyCode } = appInstallation || {}
  const pricingType = hasUsage || isPaidByEnterprise ? 'payPerUsage' : 'monthly'
  const plan = plans[0] || {}
  const {
    usageMinUnitPrice,
    usageMaxUnitPrice,
    usageUnitPrice,
    price
  } = plan
  const isVariablePrice = !!usageMaxUnitPrice && !!usageMinUnitPrice && usageMinUnitPrice !== usageMaxUnitPrice
  const priceRange = [usageMinUnitPrice, usageMaxUnitPrice]

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.appPreviewGet({ id })
  }, [id])

  const handleOnClickUpgrade = () => {
    handlers.navigateToRoute('appsSubscriptionAdd', { id })
  }

  const handleOnUninstall = () => {
    handlers.navigateToRoute('appsUninstall', { id })
  }

  return (
    <AppsSubscriptionGone
      {...app}
      currencyCode={currencyCode}
      isOwner={isOwner}
      isAdmin={isAdmin}
      pricingType={pricingType}
      onClickUpgrade={handleOnClickUpgrade}
      onUninstall={handleOnUninstall}
      priceRange={priceRange}
      unitDescription={unitDescription}
      usageUnitPrice={usageUnitPrice}
      isVariablePrice={isVariablePrice}
      price={price}

    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' })
  }
}

export default connect(maps)(AppsSubscriptionGoneWrapper)
