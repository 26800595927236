import React from 'react'
import PropTypes from 'prop-types'
import { UsersPreviewDetails } from '../../../Beauties'
import { connect } from '../../../Store'

const UserPreviewDetailsWrapper = props => {
  let { user, userPermissionTypes } = props
  user = user || {}
  userPermissionTypes = userPermissionTypes || []
  const { enterprisePermissionsGroupId } = user || {}
  const userPermissionTypeAssignedToUser = userPermissionTypes.find(permissionType => permissionType.id === enterprisePermissionsGroupId) || {}

  return (
    <UsersPreviewDetails
      {...user}
      hasPermissionTypeAssigned={!!enterprisePermissionsGroupId}
      userPermissionTypes={userPermissionTypes}
      userPermissionTypeAssignedToUser={userPermissionTypeAssignedToUser}
    />
  )
}

UserPreviewDetailsWrapper.propTypes = {
  user: PropTypes.object.isRequired,
  category: PropTypes.object,
  account: PropTypes.object
}
const maps = state => ({
  userPermissionTypes: state.userPermissions.list
})

export default connect(maps)(UserPreviewDetailsWrapper)
