import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  Title,
  Button,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Blur,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'
import { SecuritySettingsWebapp, SecuritySettingsBranchManager } from '../../../Beauties'

import './SecuritySettings.css'

const SecuritySettings = props => {
  const {
    hash,
    plan,
    settingsRead,
    settingsWrite
  } = props

  const editClassNames = []
  if (plan !== 'ENTERPRISE') editClassNames.push('ta-btn-disabled')

  const availableTabs = ['branchManager', 'webapp']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  return (
    <div className='ta-settings__security'>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='cogs' iconTheme='grey' label={t('settings.section.security.title')}>
            <PopupTriggerWrapper name='settings-security-help-box' position='bottom' extraClassName='ta-help-box'>
              <Button as='link' className='ta-title__link' external>
                {t('global.help')} <FontAwesome5 icon='lightbulb' />
              </Button>
            </PopupTriggerWrapper>
            {(settingsWrite &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.settings.edit')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to={`/settings/security/edit@@${activeTab}`} isPrimary icon='pencil' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={settingsRead ? 'inactive' : ''} active={!settingsRead} icon='ban' label={t('global.accessDenied')}>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='branchManager' label={<><FontAwesome5 icon='building' type='solid' />{t('settings.section.security.tab.brachManager')}</>} />
                <SimpleTabsHeaderItem name='webapp' label={<><FontAwesome5 icon='cogs' type='solid' />{t('settings.section.security.tab.webApp')}</>} />
              </SimpleTabsHeader>
              <SimpleTabsBody className={!settingsRead ? 'static' : ''}>
                <SimpleTabsBodyItem name='branchManager'>
                  <SecuritySettingsBranchManager {...props} />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='webapp'>
                  <SecuritySettingsWebapp {...props} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default SecuritySettings
