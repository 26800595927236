import React from 'react'
import { connect } from '../../../Store'
import { UsersPreviewPermissions } from '../../../Beauties'

const UsersPreviewPermissionsWrapper = props => {
  let {
    user,
    userPermissionTypes,
    isLogedInUserAdmin,
    canLogedInUserReadUsers,
    branches
  } = props
  user = user || {}
  userPermissionTypes = userPermissionTypes || []
  const { enterprisePermissionsGroupId } = user || {}
  const permissionTypeAssignedToUser = enterprisePermissionsGroupId
    ? userPermissionTypes.find(permissionType => permissionType.id === enterprisePermissionsGroupId)
    : null
  return (
    <UsersPreviewPermissions
      {...user}
      canLogedInUserReadUsers={canLogedInUserReadUsers}
      isLogedInUserAdmin={isLogedInUserAdmin}
      branches={branches}
      permissionTypeAssignedToUser={permissionTypeAssignedToUser}
    />
  )
}

const maps = state => ({
  userPermissionTypes: state.userPermissions.list,
  branches: state.branches.list,
  isLogedInUserAdmin: state.account.isAdmin,
  canLogedInUserReadUsers: state.account.enterprisePermissions.usersRead,
  account: state.account
})

export default connect(maps)(UsersPreviewPermissionsWrapper)
