import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import {
  userPermissionTypeFormValidate,
  userPermissionTypeSaveTransform,
  userPermissionTypeFormServerErrorsTransform
} from './utils'
import {
  userPermissionGroupUpdated,
  userPermissionGroupDeleted
} from './subscriptions'
import { ADMIN_PERMISSION_TYPE } from './index'

globalActions.populateUserPermissionTypes = async forceFetch => {
  const state = store.getState()
  let { userPermissions } = state
  const { areFetched, list } = userPermissions || {}
  if (areFetched && !forceFetch) {
    handlers.userPermissionTypesReady() // stop loader
    return list
  }
  const data = await q('getPermissionsGroupsEnterprise')
  const { error } = data || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    return handlers.userPermissionTypesPopulate([ADMIN_PERMISSION_TYPE])
  }
  const permissionTypes = (data || []).map(item => ({
    ...item,
    permissions: {
      ...((item && item.permissions) || {}),
      branchesDelete: false
    }
  }))
  handlers.userPermissionTypesPopulate([ADMIN_PERMISSION_TYPE, ...permissionTypes])
  return permissionTypes
}

// List
payloads$(actions.USER_PERMISSION_TYPES_GET)
  .subscribe(async forceFetch => {
    await globalActions.populateBranches(true)
    const userPermissionTypes = await globalActions.populateUserPermissionTypes(forceFetch)
    await globalActions.populateUsers()
    return userPermissionTypes
  })

// Form
payloads$(actions.USER_PERMISSION_TYPES_FORM_GET)
  .subscribe(async permissionType => {
    const state = store.getState()
    const { branches, account } = state
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []

    if (!permissionType) {
      handlers.userPermissionTypesFormPopulate(null, branchesList, account)
    } else {
      handlers.userPermissionTypesFormPopulate(permissionType, branchesList, account)
    }
  })

// Save
payloads$(actions.USER_PERMISSION_TYPES_FORM_SAVE)
  .subscribe(async ({ permissionType, scrollToError }) => {
    const state = store.getState()
    let { userPermissions, account } = state
    userPermissions = userPermissions || {}
    let { list: userPermissionTypesList } = userPermissions
    userPermissionTypesList = userPermissionTypesList || []
    const { isCustom: amICustom } = account || {}
    const userPermissionTypeExternalIds = userPermissionTypesList.filter(item => item.id !== permissionType.id).map(permissionType => permissionType.externalId).filter(Boolean) || []
    const errors = userPermissionTypeFormValidate(permissionType, userPermissionTypeExternalIds)
    if (errors && errors.length) return setUserPermissionTypeFormSaveErrors(errors, scrollToError)
    const savedPermissionType = await q('savePermissionsGroupEnterprise', {
      permissionsGroup: userPermissionTypeSaveTransform(permissionType, amICustom)
    })
    const { error, id } = savedPermissionType || {}
    if (error) return setUserPermissionTypeFormSaveErrors(userPermissionTypeFormServerErrorsTransform(error), scrollToError)
    if (permissionType.id) {
      handlers.userPermissionTypeUpdate(savedPermissionType)
    } else {
      handlers.userPermissionTypeSave(savedPermissionType)
    }
    handlers.navigateToPath(`/managers/user-permissions/${id}`)
  })

const setUserPermissionTypeFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('userPermissionType', errors)
  setTimeout(() => {
    scrollToError && scrollToError(errors)
  }, 0)
  handlers.userPermissionTypesFormReady()
}

// Delete
payloads$(actions.USER_PERMISSION_TYPE_DELETE)
  .subscribe(async id => {
    await q('deletePermissionsGroupEnterprise', { id })
    handlers.userPermissionTypeDeleteReady(id)

    handlers.navigateToPath('/managers/user-permissions')
  })

// Subscriptions
payloads$(actions.USER_PERMISSION_TYPES_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'permissionsEnterpriseGroupUpdated') {
      const permissionType = (await q('getPermissionsGroupEnterprise', { id })) || {}
      if (permissionType.error) return
      userPermissionGroupUpdated({ id, data, ts, permissionType })
    }
    if (name === 'permissionEnterpriseGroupDeleted') {
      userPermissionGroupDeleted({ id, data, ts })
    }
  })
