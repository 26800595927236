import React from 'react'
import { connect } from '../../Store'
import { UserPermissionsFormPermissions } from '../../Beauties'

const UserPermissionsFormPermissionsWrapper = props => {
  return (
    <UserPermissionsFormPermissions {...props} />
  )
}

const maps = state => ({
  permissionsBranchesRead: state.forms.userPermissionType.permissionsBranchesRead && state.forms.userPermissionType.permissionsBranchesRead.value,
  permissionsBranchesWrite: state.forms.userPermissionType.permissionsBranchesWrite && state.forms.userPermissionType.permissionsBranchesWrite.value,
  // Tags
  permissionsTagsRead: state.forms.userPermissionType.permissionsTagsRead && state.forms.userPermissionType.permissionsTagsRead.value,
  permissionsTagsWrite: state.forms.userPermissionType.permissionsTagsWrite && state.forms.userPermissionType.permissionsTagsWrite.value,
  // Customers
  permissionsGlobalCustomersRead: state.forms.userPermissionType.permissionsGlobalCustomersRead && state.forms.userPermissionType.permissionsGlobalCustomersRead.value,
  permissionsGlobalCustomersWrite: state.forms.userPermissionType.permissionsGlobalCustomersWrite && state.forms.userPermissionType.permissionsGlobalCustomersWrite.value,
  // DataFields
  permissionsGlobalDataFieldsRead: state.forms.userPermissionType.permissionsGlobalDataFieldsRead && state.forms.userPermissionType.permissionsGlobalDataFieldsRead.value,
  permissionsGlobalDataFieldsWrite: state.forms.userPermissionType.permissionsGlobalDataFieldsWrite && state.forms.userPermissionType.permissionsGlobalDataFieldsWrite.value,
  // Tags
  permissionsGlobalTagsRead: state.forms.userPermissionType.permissionsGlobalTagsRead && state.forms.userPermissionType.permissionsGlobalTagsRead.value,
  permissionsGlobalTagsWrite: state.forms.userPermissionType.permissionsGlobalTagsWrite && state.forms.userPermissionType.permissionsGlobalTagsWrite.value,
  // Services
  permissionsGlobalServicesRead: state.forms.userPermissionType.permissionsGlobalServicesRead && state.forms.userPermissionType.permissionsGlobalServicesRead.value,
  permissionsGlobalServicesWrite: state.forms.userPermissionType.permissionsGlobalServicesWrite && state.forms.userPermissionType.permissionsGlobalServicesWrite.value,
  // Groups
  permissionsGlobalGroupsRead: state.forms.userPermissionType.permissionsGlobalGroupsRead && state.forms.userPermissionType.permissionsGlobalGroupsRead.value,
  permissionsGlobalGroupsWrite: state.forms.userPermissionType.permissionsGlobalGroupsWrite && state.forms.userPermissionType.permissionsGlobalGroupsWrite.value,
  // Resource Categories
  permissionsGlobalResourceCategoriesRead: state.forms.userPermissionType.permissionsGlobalResourceCategoriesRead && state.forms.userPermissionType.permissionsGlobalResourceCategoriesRead.value,
  permissionsGlobalResourceCategoriesWrite: state.forms.userPermissionType.permissionsGlobalResourceCategoriesWrite && state.forms.userPermissionType.permissionsGlobalResourceCategoriesWrite.value,
  // Global Permission Groups
  permissionsGlobalPermissionsGroupsRead: state.forms.userPermissionType.permissionsGlobalPermissionsGroupsRead && state.forms.userPermissionType.permissionsGlobalPermissionsGroupsRead.value,
  permissionsGlobalPermissionsGroupsWrite: state.forms.userPermissionType.permissionsGlobalPermissionsGroupsWrite && state.forms.userPermissionType.permissionsGlobalPermissionsGroupsWrite.value,
  // Settings
  permissionsSettingsRead: state.forms.userPermissionType.permissionsSettingsRead && state.forms.userPermissionType.permissionsSettingsRead.value,
  // Apps
  permissionsAppsRead: state.forms.userPermissionType.permissionsAppsRead && state.forms.userPermissionType.permissionsAppsRead.value,
  // Users
  permissionsUsersRead: state.forms.userPermissionType.permissionsUsersRead && state.forms.userPermissionType.permissionsUsersRead.value,
  permissionsUsersWrite: state.forms.userPermissionType.permissionsUsersWrite && state.forms.userPermissionType.permissionsUsersWrite.value,
  // Permission Groups
  permissionsPermissionsGroupsRead: state.forms.userPermissionType.permissionsPermissionsGroupsRead && state.forms.userPermissionType.permissionsPermissionsGroupsRead.value,
  permissionsPermissionsGroupsWrite: state.forms.userPermissionType.permissionsPermissionsGroupsWrite && state.forms.userPermissionType.permissionsPermissionsGroupsWrite.value,
  // Custom Access
  branchAccess: state.forms.userPermissionType.branchAccess && state.forms.userPermissionType.branchAccess.value,
  isBranchesAccessWhitelistDisabled: state.forms.userPermissionType.branchesAccessWhitelist && state.forms.userPermissionType.branchesAccessWhitelist.disabled,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist,
  isAdmin: state.account.isAdmin,
  canLogedInUserWriteUsers: state.account.enterprisePermissions.usersWrite
})

export default connect(maps)(UserPermissionsFormPermissionsWrapper)
