import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  BorderedBox,
  FormGroup,
  Input,
  Error,
  t
} from '../../../Common'
import './ServiceForm.css'

const ServiceOtherRecipientsSelect = props => {
  let { recipients, name } = props
  recipients = recipients || []

  return (
    <BorderedBox className='ta-services__form--inner-box'>
      {recipients.map((item, index) => (
        <Row>
          <Col>
            <div key={index} className='ta-services__form__recipient-container'>
              <FormGroup
                className='ta-services__form__recipient'
                filled={!!item}
                labelText={t('global.emailAddress.placeholder')}
                labelMandatory
                disabled
              >
                <Input value={item} />
              </FormGroup>
            </div>
          </Col>
        </Row>
      ))}
      <Error name={name} />
    </BorderedBox>
  )
}

ServiceOtherRecipientsSelect.propTypes = {
  name: PropTypes.string,
  reminders: PropTypes.array
}

export default ServiceOtherRecipientsSelect
