import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { filter, debounceTime } from 'rxjs/operators'
import { q } from '../../API'
import { systemMessagesListTransform } from './utils'
import { systemMessagesUpdated } from './subscriptions'

const limit = 10

const getSystemMessagesFilterFromState = state => {
  const activityLogsFilters = state.forms.systemMessagesFilters
  const {
    type,
    search,
    from,
    until
  } = activityLogsFilters || {}
  const result = {}
  if (type && type?.value) result.type = type.value
  if (search && search?.value) result.search = search.value
  if (from && from?.value) result.dateFrom = from.value
  if (until && until?.value) result.dateTo = (until.value || '').replace('00:00', '23:59')

  return result
}

const getSystemMessagesPageFromState = state => {
  return state.notifications ? state.notifications.systemMessagesPage : 1
}

const getRegionFromState = () => {
  const state = store.getState() || {}
  const account = state?.account || {}

  return account?.region || ''
}

let isLoading = false

globalActions.populateSystemMessages = async ({ systemMessagesPage, isLoadMore }) => {
  if (isLoading && !isLoadMore) return
  isLoading = true
  const state = store.getState()
  const filter = getSystemMessagesFilterFromState(state)
  const region = getRegionFromState()

  systemMessagesPage = systemMessagesPage || getSystemMessagesPageFromState(state)
  const result = await q('getEnterpriseSystemActions', { filter, pagination: { limit, page: systemMessagesPage }, region })
  const { error, getEnterpriseSystemActions = [], getEnterpriseSystemActionsCount = 0 } = result
  if (error) {
    isLoading = false
    return
  }

  handlers.systemMessagesListOptionsChange({ systemMessagesPage, systemMessagesLastPage: !result.length || (result.length < limit) })
  handlers.systemMessagesListPopulate(systemMessagesListTransform(getEnterpriseSystemActions), !!isLoadMore)
  handlers.systemMessagesListCounterPopulate(getEnterpriseSystemActionsCount)

  isLoading = false
  return result
}

// System messages
payloads$(actions.SYSTEM_MESSAGES_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    const { userIds = [] } = data
    const state = store.getState()
    const { account = {} } = state
    const { userId = '' } = account
    if (!(userIds?.includes(userId))) return

    const systemAction = (await q('getEnterpriseSystemAction', { id })) || {}
    if (name === 'temporaryRemoteAccessAccepted' || name === 'temporaryRemoteAccessRequested') {
      systemMessagesUpdated(systemAction)
    }
    if (name === 'temporaryRemoteAccessDeclined' || name === 'temporaryRemoteAccessRevoked' || name === 'temporaryRemoteAccessExpired' || name === 'temporaryRemoteAccessLapsed') {
      handlers.systemMessagesDeleted(id)
    }
  })

payloads$(actions.SYSTEM_MESSAGES_LIST_GET)
  .subscribe(async () => {
    setTimeout(() => globalActions.populateSystemMessages({ systemMessagesPage: 1 }), 0)
  })

// System messages page increment
payloads$(actions.SYSTEM_MESSAGES_LIST_PAGE_INCREMENT)
  .subscribe(async () => {
    globalActions.populateSystemMessages({ isLoadMore: true })
  })

// System Messages list Filters
payloads$(actions.FORM_FIELDS_UPDATE)
  .pipe(
    filter(fields => fields.form === 'systemMessagesFilters'),
    debounceTime(500)
  ).subscribe(() => {
    handlers.systemMessagesListGet()
  })

// System message decline
payloads$(actions.SYSTEM_MESSAGES_DECLINE)
  .subscribe(async id => {
    try {
      const region = getRegionFromState()
      await q('declineEnterpriseRemoteAccess', { declineEnterpriseRemoteAccessId: id, region })
    } catch (e) { }
  })

// System message revoke
payloads$(actions.SYSTEM_MESSAGES_REVOKE)
  .subscribe(async id => {
    try {
      const region = getRegionFromState()
      await q('revokeEnterpriseRemoteAccess', { revokeEnterpriseRemoteAccessId: id, region })
    } catch (e) { }
  })

// System message accept
payloads$(actions.SYSTEM_MESSAGES_ACCEPT)
  .subscribe(async id => {
    try {
      const region = getRegionFromState()
      const response = await q('acceptEnterpriseRemoteAccess', { acceptEnterpriseRemoteAccessId: id, region })
      handlers.systemMessagesAccepted(response)
    } catch (e) { }
  })
