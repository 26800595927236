import React, { Component } from 'react'
import moment from 'moment'
import { connect, handlers } from '../../Store'
import { Invoices, InvoicesMock } from '../../Beauties'
import { sortBy } from '../../Utils'

class InvoicesListWrapper extends Component {
  componentDidMount () {
    handlers.invoicesListGet()
  }

  onClickInvoiceLink = (id) => {
    if (!id) return
    handlers.invoiceOpen(id)
  }

  downloadFile = (url) => {
    handlers.appFileDownload({ url })
  }

  render () {
    let {
      pending,
      message,
      invoices,
      pendingDownload,
      isChargebeeHidden,
      account,
      isBillingRead
    } = this.props
    account = account || {}
    invoices = invoices || []
    const groupedInvoices = invoices
      .reduce((acc, invoice) => {
        invoice.emittedDay = invoice.emittedDay || invoice.day
        const year = moment.utc(invoice.day, 'YYYY-MM-DD').format('YYYY')
        acc[year]
          ? acc[year].items.push(invoice)
          : acc[year] = { year, items: [invoice] }
        acc[year].items.sort(sortBy('-emittedDay'))
        return acc
      }, {})
    const sortedGroupedInvoices = Object.keys(groupedInvoices).map(item => groupedInvoices[item]).sort(sortBy('-year'))
    const { enterprisePermissions, isAdmin } = account || {}
    const accountRead = !!isAdmin || !!enterprisePermissions.accountRead

    return isBillingRead && accountRead && !isChargebeeHidden
      ? (
        <Invoices
          invoices={sortedGroupedInvoices}
          pending={pending}
          message={message}
          pendingDownload={pendingDownload}
          onClickInvoiceLink={this.onClickInvoiceLink}
          downloadFile={this.downloadFile}
        />
      )
      : <InvoicesMock />
  }
}

const maps = state => ({
  pending: state.invoices.pendingList,
  message: state.invoices.message,
  invoices: state.invoices.list || [],
  pendingDownload: state.invoices.pendingDownload || [],
  isBillingRead: state.account && state.account.isBillingRead,
  account: state.account,
  countries: state.staticData.countries || [],
  isChargebeeHidden: state.chargebee.isHidden || false
})

export default connect(maps)(InvoicesListWrapper)
