import React from 'react'

import {
  Avatar,
  FontAwesome5,
  ListButton,
  t
} from '../../../Common'

const ServicePreviewResource = props => {
  let { resource } = props
  resource = resource || {}
  const statusClassNames = ['ta-services__preview__resource-status']
  if (resource.isBookable) statusClassNames.push('active')
  if (resource.hasOnlineWarning) statusClassNames.push('has-online-warning')

  return (
    <div className='ta-services__preview__resource'>
      <Avatar name={resource.name} image={resource.avatarUrl} color={resource.color} />
      {(resource.isOwner &&
        <div
          className='ta-resources__resource__label owner'
          title={t('resources.preview.sectionDefault.resourceOwner.tooltip')}
        >
          <FontAwesome5 icon='suitcase' type='solid' />
        </div>
      )}
      {(!resource.isOwner && resource.isAdmin &&
        <div
          className='ta-resources__resource__label admin'
          title={t('resources.preview.sectionDefault.resourceAdmin.tooltip')}
        >
          <FontAwesome5 icon='user-shield' type='solid' />
        </div>
      )}
      {(!resource.isOwner && resource.isCustom &&
        <div
          className='ta-resources__resource__label custom'
          title={t('resources.preview.sectionDefault.resourceCustom.tooltip')}
        >
          <FontAwesome5 icon='cogs' type='solid' />
        </div>
      )}
      <div>
        <div className='ta-services__preview__resource-name'>{resource.name}</div>
        <div className={statusClassNames.join(' ')}>
          {(resource.hasOnlineWarning
            ? <div>{t('global.warning')} <FontAwesome5 icon='exclamation-triangle' type='solid' /></div>
            : resource.isBookable
              ? t('resources.list.sectionDefault.resourceOnline.tag')
              : t('resources.list.sectionDefault.resourceOffline.tag')
          )}
        </div>
      </div>
      <div className='ta-services__preview__resource__button'>
        <ListButton icon='angle-right' to={`/management/resources/${resource.id}`} />
      </div>
    </div>
  )
}

export default ServicePreviewResource
