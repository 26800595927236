import { map, distinctUntilChanged } from 'rxjs/operators'
import { payloads$, actions, handlers, store, globalActions, store$ } from '../../../Store'
import { t } from '../../../Common'
import { q } from '../../API'
import {
  bookingSettingsSaveTransform,
  bookingSettingsFormValidate,
  bookingSettingsFormServerErrorsTransform
} from './utils'

// Form
payloads$(actions.BOOKING_SETTINGS_FORM_GET)
  .subscribe(async () => {
    const state = store.getState()
    const { company, companyTags, customerFields, account } = state || {}
    const { settings } = company || {}
    let { list: tags } = companyTags || {}
    let { list: customerFieldsList } = customerFields || {}
    tags = tags || []
    const serviceAllocationsTimeOptions = [
      { label: t('global.hours.label'), value: 1 },
      { label: t('global.days.label'), value: 24 }
    ]
    if (tags.length === 0) tags = await globalActions.populateGlobalTags()
    if (customerFieldsList.length === 0) {
      let result = await globalActions.populateCustomerFieldsAndCategories()
      result = result || {}
      customerFieldsList = result.getEnterpriseCustomerFields
    }
    let { isAdmin } = account || {}
    handlers.bookingSettingsFormPopulate({
      settings,
      tags: tags.filter(item => item.isForEvents),
      serviceAllocationsTimeOptions,
      customerFields: customerFieldsList,
      isAdmin
    })
  })

// Save
payloads$(actions.BOOKING_SETTINGS_FORM_SAVE)
  .subscribe(async ({ settings, scrollToError }) => {
    const state = store.getState()
    let { branches, services, customerFields, company, account } = state
    branches = branches || {}
    company = company || {}
    account = account || {}
    const { list: branchesList } = branches || {}
    const { list: servicesList } = services || {}
    const { list: customerFieldsList } = customerFields || {}
    const errors = bookingSettingsFormValidate(settings)
    if (errors && errors.length) return setBookingSettingsFormSaveErrors(errors, scrollToError)
    let { isAdmin } = account || {}
    const formattedSettings = bookingSettingsSaveTransform(settings, customerFieldsList, isAdmin) || {}
    const savedAccountSettings = await q('saveEnterpriseSettings', { settings: formattedSettings })
    const { error } = savedAccountSettings || {}
    if (error) return setBookingSettingsFormSaveErrors(bookingSettingsFormServerErrorsTransform(error, branchesList, servicesList), scrollToError)
    handlers.companyPopulate({ ...company, settings: savedAccountSettings })
    const { hash } = store.getState().router
    handlers.navigateToPath(`/settings/bookings@@${hash || 'booking'}`)
  })

const setBookingSettingsFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('bookingSettings', errors)
  handlers.bookingSettingsFormReady()
  scrollToError && scrollToError(errors)
}

// Preview
payloads$(actions.SETTINGS_PREVIEW_GET)
  .subscribe(async () => {
    await globalActions.populateGlobalTags()
    handlers.settingsPreviewReady()
  })

// Form public holidays switch change
store$
  .pipe(
    map(state => state.forms.bookingSettings.hasPublicHolidays && state.forms.bookingSettings.hasPublicHolidays.value),
    distinctUntilChanged()
  ).subscribe(hasPublicHolidays => {
    let { router, forms } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    let { formDiscard } = props || {}
    let { formsWithUnsavedChanges } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      const { bookingSettings } = forms || {}
      const { publicHolidays } = bookingSettings || {}
      let {
        values: publicHolidaysValues,
        deleted: publicHolidaysDeleted
      } = publicHolidays || {}
      publicHolidaysValues = publicHolidaysValues || []
      publicHolidaysDeleted = publicHolidaysDeleted || []

      if (!hasPublicHolidays) {
        handlers.formFieldsUpdate('bookingSettings', {
          publicHolidays: {
            values: [],
            modified: [],
            deleted: [...publicHolidaysDeleted, ...publicHolidaysValues.map(item => item.id)].filter(Boolean)
          }
        })
      }
      if (hasPublicHolidays && publicHolidaysValues.length === 0) {
        handlers.formFieldsUpdate('bookingSettings', {
          publicHolidays: {
            ...publicHolidays,
            values: [
              {
                publicHolidayKeys: [],
                resourceCategoryIds: ['all']
              }
            ]
          }
        })
      }
      handlers.formErrorsSet('bookingSettings', [])
    }
  })

// Form Booking Max lead time input change
store$
  .pipe(
    map(state => state.forms.bookingSettings.maximumLeadTime && state.forms.bookingSettings.maximumLeadTime.value),
    distinctUntilChanged()
  ).subscribe(value => {
    let { router, forms } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    let { formDiscard } = props || {}
    let { formsWithUnsavedChanges } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      value = value || ''
      handlers.formFieldsUpdate('bookingSettings', { maximumLeadTime: { value: `${value}`.substr(0, 4) } })
    }
  })

// Form Booking Min lead time input change
store$
  .pipe(
    map(state => state.forms.bookingSettings.minimumLeadTime && state.forms.bookingSettings.minimumLeadTime.value),
    distinctUntilChanged()
  ).subscribe(value => {
    let { router, forms } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    let { formDiscard } = props || {}
    let { formsWithUnsavedChanges } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      value = value || ''
      handlers.formFieldsUpdate('bookingSettings', { minimumLeadTime: { value: `${value}`.substr(0, 4) } })
    }
  })

// Form Service Allocations settings checkboxes change
store$
  .pipe(
    map(state => state.forms.bookingSettings && state.forms.bookingSettings.shouldDisplayInfo && state.forms.bookingSettings.shouldDisplayInfo.value),
    distinctUntilChanged()
  ).subscribe(showCustomOfflineBookingMessage => {
    let { forms, router } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    let { formDiscard } = props || {}
    let { formsWithUnsavedChanges } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      handlers.formFieldsUpdate('bookingSettings', {
        serviceAllocationSettingsErrors: {
          errors: []
        }
      })
    }
  })

// Form Service Allocations settings checkboxes change
store$
  .pipe(
    map(state => state.forms.bookingSettings && state.forms.bookingSettings.isServiceAllocationsEnabledForCalendarBookings && state.forms.bookingSettings.isServiceAllocationsEnabledForCalendarBookings.value),
    distinctUntilChanged()
  ).subscribe(showCustomOfflineBookingMessage => {
    let { forms, router } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    let { formDiscard } = props || {}
    let { formsWithUnsavedChanges } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      handlers.formFieldsUpdate('bookingSettings', {
        serviceAllocationSettingsErrors: {
          errors: []
        }
      })
    }
  })

// Form Service Allocations settings checkboxes change
store$
  .pipe(
    map(state => state.forms.bookingSettings && state.forms.bookingSettings.isServiceAllocationsEnabledFixedTime && state.forms.bookingSettings.isServiceAllocationsEnabledFixedTime.value),
    distinctUntilChanged()
  ).subscribe(showCustomOfflineBookingMessage => {
    let { forms, router } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    const { formDiscard } = props || {}
    let { formsWithUnsavedChanges, bookingSettings } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      handlers.formSet('bookingSettings', {
        ...bookingSettings,
        serviceAllocationSettingsErrors: {
          errors: []
        }
      })
    }
  })
