import React, { useEffect, useMemo } from 'react'
import { connect, globalActions } from '../../../Store'
import { BookingSettings } from '../../../Beauties'
import { Loader, t } from '../../../Common'

const BookingSettingsWrapper = props => {
  let {
    isPending,
    hash,
    settings,
    rawSettings,
    tags,
    publicHolidaysData,
    resourceCategories,
    customersMiddlewareUrl,
    account,
    callCentreEnabled,
    enterpriseCustomisation
  } = props
  rawSettings = rawSettings || {}
  settings = settings || {}
  tags = tags || []
  publicHolidaysData = publicHolidaysData || []
  resourceCategories = resourceCategories || []
  account = account || {}
  enterpriseCustomisation = enterpriseCustomisation || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsRead, settingsWrite } = enterprisePermissions || {}
  settingsRead = !!isAdmin || !!settingsRead
  settingsWrite = !!isAdmin || !!settingsWrite
  isPending = settingsRead && !!isPending
  const { payload: customisaitonPayload } = enterpriseCustomisation || {}

  useEffect(() => {
    const fetchNeededData = async () => {
      await globalActions.populateEnterpriseResourceCategories()
      await globalActions.populateCompanyTags()
      await globalActions.populateCoursesAndCourseCategories()
      await globalActions.populateServicesAndServiceCategories()
    }
    if (settingsRead) fetchNeededData()
  }, [settingsRead])

  let publicHolidays = settings.publicHolidays || []
  publicHolidays = publicHolidays.map(item => ({
    ...item,
    resourceCategoryIds: !item.resourceCategoryIds || (item.resourceCategoryIds && item.resourceCategoryIds.length === 0)
      ? []
      : item.resourceCategoryIds
  }))
  const regionsByUniqueKey = useMemo(() => publicHolidaysData.reduce((acc, item) => ({
    ...acc,
    [item.uniqueKey]: {
      value: item.uniqueKey,
      label: `${item.countryName}${item.stateName ? ` (${item.stateName})` : ''}`
    }
  }), {}), [publicHolidaysData])
  const transformedResourceCategories = resourceCategories.map(category => ({
    id: category.id,
    label: category.name === 'default' ? t('resources.list.sectionDefault.title') : category.name
  }))

  return isPending
    ? <Loader active />
    : (
      <BookingSettings
        hash={hash}
        rawSettings={rawSettings}
        settings={settings}
        tags={tags}
        customersMiddlewareUrl={customersMiddlewareUrl}
        publicHolidays={publicHolidays}
        regionsByUniqueKey={regionsByUniqueKey}
        resourceCategories={transformedResourceCategories}
        settingsRead={settingsRead}
        settingsWrite={settingsWrite}
        customisations={customisaitonPayload}
        isAdmin={isAdmin}
        callCentreEnabled={callCentreEnabled}
      />
      )
}

const maps = state => ({
  isPending: state.companyTags.pendingList,
  hash: state.router.hash,
  settings: state.company.settings,
  tags: state.companyTags.list,
  rawSettings: state.company.rawSettings,
  publicHolidaysData: state.staticData.publicHolidays,
  resourceCategories: state.resources.categoriesList,
  customersMiddlewareUrl: state.company.customersMiddlewareUrl,
  account: state.account,
  enterpriseCustomisation: state.company.enterpriseCustomisation,
  callCentreEnabled: state.company.settings.callCentreEnabled
})

export default connect(maps)(BookingSettingsWrapper)
