import React from 'react'
import { UserGroupListItem } from '../../Beauties'
import { Alert, t } from '../../Common'

const UserGroupList = props => {
  const { users } = props

  return (
    <div className='ta-user-groups__list'>
      {users.length > 0 && users.map((user, index) => (
        <UserGroupListItem
          key={index}
          user={user}
        />
      ))}
      {(!users.length &&
        <Alert noOffset label={t('userGroup.list.noUsers')} theme='no-content' />
      )}
    </div>
  )
}

export default UserGroupList
