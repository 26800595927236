import { translateServerCode, validator } from '../../../Utils'
import { EMAIL_VALIDATION_REGEX, NUMBERS_VALIDATION_REGEX, MAX_GDPR_YEARS, SESSION_LENGTH_DEFAULT, INACTIVITY_LENGTH_DEFAULT } from '../../../Settings'

// EMAIL
export const settingsNotificationsFormTransform = settings => {
  settings = settings || {}
  const resourceBookingCreateEmailOtherRecipients = settings.resourceBookingCreateEmailRecipients !== null && settings.resourceBookingCreateEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? settings.resourceBookingCreateEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']
  const resourceBookingDeleteEmailOtherRecipients = settings.resourceBookingDeleteEmailRecipients !== null && settings.resourceBookingDeleteEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? settings.resourceBookingDeleteEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']
  const resourceBookingUpdateEmailOtherRecipients = settings.resourceBookingUpdateEmailRecipients !== null && settings.resourceBookingUpdateEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? settings.resourceBookingUpdateEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']
  const resourceRemindersEmailOtherRecipients = settings.resourceRemindersEmailRecipients !== null && settings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
    ? settings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
    : ['']
  const result = {
    bookingConfirmationCustomersSwitch: {
      value: settings.customerBookingCreateEmailRecipients !== null ? settings.customerBookingCreateEmailRecipients.length > 0 : false
    },
    bookingConfirmationStaffSwitch: {
      value: settings.resourceBookingCreateEmailRecipients !== null ? settings.resourceBookingCreateEmailRecipients.length > 0 : false
    },
    bookingConfirmationStaffOwnerCheckBox: {
      value: settings.resourceBookingCreateEmailRecipients !== null ? settings.resourceBookingCreateEmailRecipients.includes('owner') : false
    },
    bookingConfirmationStaffResourcesCheckBox: {
      value: settings.resourceBookingCreateEmailRecipients !== null ? settings.resourceBookingCreateEmailRecipients.includes('resource') : false
    },
    bookingConfirmationStaffOthersCheckBox: {
      value: resourceBookingCreateEmailOtherRecipients.length >= 1 && resourceBookingCreateEmailOtherRecipients[0] !== ''
    },
    bookingConfirmationStaffOthers: {
      values: resourceBookingCreateEmailOtherRecipients || ['']
    },
    bookingCancellationCustomersSwitch: {
      value: settings.customerBookingDeleteEmailRecipients !== null ? settings.customerBookingDeleteEmailRecipients.length > 0 : false
    },
    bookingCancellationStaffSwitch: {
      value: settings.resourceBookingDeleteEmailRecipients !== null ? settings.resourceBookingDeleteEmailRecipients.length > 0 : false
    },
    bookingCancellationStaffOwnerCheckBox: {
      value: settings.resourceBookingDeleteEmailRecipients !== null ? settings.resourceBookingDeleteEmailRecipients.includes('owner') > 0 : false
    },
    bookingCancellationStaffResourcesCheckBox: {
      value: settings.resourceBookingDeleteEmailRecipients !== null ? settings.resourceBookingDeleteEmailRecipients.includes('resource') > 0 : false
    },
    bookingCancellationStaffOthersCheckBox: {
      value: resourceBookingDeleteEmailOtherRecipients.length >= 1 && resourceBookingDeleteEmailOtherRecipients[0] !== ''
    },
    bookingCancellationStaffOthers: {
      values: resourceBookingDeleteEmailOtherRecipients || ['']
    },
    bookingChangeCustomersSwitch: {
      value: settings.customerBookingUpdateEmailRecipients !== null ? settings.customerBookingUpdateEmailRecipients.length > 0 : false
    },
    bookingChangeStaffSwitch: {
      value: settings.resourceBookingUpdateEmailRecipients !== null ? settings.resourceBookingUpdateEmailRecipients.length > 0 : false
    },
    bookingChangeStaffOwnerCheckBox: {
      value: settings.resourceBookingUpdateEmailRecipients !== null ? settings.resourceBookingUpdateEmailRecipients.includes('owner') > 0 : false
    },
    bookingChangeStaffResourcesCheckBox: {
      value: settings.resourceBookingUpdateEmailRecipients !== null ? settings.resourceBookingUpdateEmailRecipients.includes('resource') > 0 : false
    },
    bookingChangeStaffOthersCheckBox: {
      value: resourceBookingUpdateEmailOtherRecipients.length >= 1 && resourceBookingUpdateEmailOtherRecipients[0] !== ''
    },
    bookingChangeStaffOthers: {
      values: resourceBookingUpdateEmailOtherRecipients || ['']
    },
    bookingReminderStaffSwitch: {
      value: settings.resourceEmailRemindersMinutes !== null && settings.resourceEmailRemindersMinutes.length > 0 && (settings.resourceEmailRemindersMinutes.length !== 1 || settings.resourceEmailRemindersMinutes[0] !== 0)
    },
    resourceEmailRemindersMinutes: {
      values: (settings.resourceEmailRemindersMinutes && settings.resourceEmailRemindersMinutes.length) ? settings.resourceEmailRemindersMinutes : [0]
    },
    bookingReminderCustomerSwitch: {
      value: settings.customerEmailRemindersMinutes !== null && settings.customerEmailRemindersMinutes.length > 0 && (settings.customerEmailRemindersMinutes.length !== 1 || settings.customerEmailRemindersMinutes[0] !== 0)
    },
    customerEmailRemindersMinutes: {
      values: (settings.customerEmailRemindersMinutes && settings.customerEmailRemindersMinutes.length) ? settings.customerEmailRemindersMinutes : [0]
    },
    bookingReminderStaffOwnerCheckBox: {
      value: settings.resourceRemindersEmailRecipients !== null ? settings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
    },
    bookingReminderStaffResourcesCheckBox: {
      value: settings.resourceRemindersEmailRecipients !== null ? settings.resourceRemindersEmailRecipients.includes('resource') : false
    },
    bookingReminderStaffOthersCheckBox: {
      value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
    },
    bookingReminderStaffOthers: {
      values: resourceRemindersEmailOtherRecipients || ['']
    },
    allowUserLocaleForCustomers: {
      value: settings.allowUserLocaleForCustomers || false
    },
    allowUserLocaleForResources: {
      value: settings.allowUserLocaleForResources || false
    },
    dailyAgendaTime: {
      value: settings.dailyAgenda && (settings.dailyAgenda.time || '05:00')
    },
    dailyAgendaEmailsSwitch: {
      value: settings.dailyAgenda && (settings.dailyAgenda.time || false)
    },
    dailyAgendaResources: {
      value: settings.dailyAgenda && (!!settings.dailyAgenda.specificResourceCategoryIds && !settings.dailyAgenda.specificResourceCategoryIds.length) ? 'all' : 'some'
    },
    dailyAgendaResourceCategory: {
      values: settings.dailyAgenda && !!settings.dailyAgenda.specificResourceCategoryIds && settings.dailyAgenda.specificResourceCategoryIds.length ? settings.dailyAgenda.specificResourceCategoryIds : []
    }
  }
  return result
}

export const settingsNotificationsSaveTransform = settings => {
  const {
    bookingConfirmationStaffSwitch,
    bookingConfirmationCustomersSwitch,
    bookingConfirmationStaffResourcesCheckBox,
    bookingConfirmationStaffOwnerCheckBox,
    bookingConfirmationStaffOthers,
    bookingConfirmationStaffOthersCheckBox,
    bookingCancellationStaffSwitch,
    bookingCancellationCustomersSwitch,
    bookingCancellationStaffResourcesCheckBox,
    bookingCancellationStaffOwnerCheckBox,
    bookingCancellationStaffOthers,
    bookingCancellationStaffOthersCheckBox,
    bookingChangeStaffSwitch,
    bookingChangeCustomersSwitch,
    bookingChangeStaffResourcesCheckBox,
    bookingChangeStaffOwnerCheckBox,
    bookingChangeStaffOthersCheckBox,
    bookingChangeStaffOthers,
    bookingReminderCustomerSwitch,
    bookingReminderStaffSwitch,
    bookingReminderStaffOwnerCheckBox,
    bookingReminderStaffResourcesCheckBox,
    bookingReminderStaffOthersCheckBox,
    bookingReminderStaffOthers,
    dailyAgendaEmailsSwitch,
    dailyAgendaTime,
    dailyAgendaResources,
    dailyAgendaResourceCategory
  } = settings || {}
  const customerBookingCreateEmailRecipients = bookingConfirmationCustomersSwitch.value ? ['customer'] : []
  const resourceBookingCreateEmailRecipients = []
  const resourceBookingDeleteEmailRecipients = []
  const resourceBookingUpdateEmailRecipients = []
  const resourceRemindersEmailRecipients = []
  if (bookingConfirmationStaffSwitch.value) {
    if (bookingConfirmationStaffOwnerCheckBox.value) resourceBookingCreateEmailRecipients.push('owner')
    if (bookingConfirmationStaffResourcesCheckBox.value) resourceBookingCreateEmailRecipients.push('resource')
    if (bookingConfirmationStaffOthersCheckBox.value && bookingConfirmationStaffOthers.values && bookingConfirmationStaffOthers.values.length > 0) {
      bookingConfirmationStaffOthers.values.forEach(item => resourceBookingCreateEmailRecipients.push(item))
    }
  }
  const customerBookingDeleteEmailRecipients = bookingCancellationCustomersSwitch.value ? ['customer'] : []
  if (bookingCancellationStaffSwitch.value) {
    if (bookingCancellationStaffOwnerCheckBox.value) resourceBookingDeleteEmailRecipients.push('owner')
    if (bookingCancellationStaffResourcesCheckBox.value) resourceBookingDeleteEmailRecipients.push('resource')
    if (bookingCancellationStaffOthersCheckBox.value && bookingCancellationStaffOthers.values && bookingCancellationStaffOthers.values.length > 0) {
      bookingCancellationStaffOthers.values.forEach(item => resourceBookingDeleteEmailRecipients.push(item))
    }
  }
  const customerBookingUpdateEmailRecipients = bookingChangeCustomersSwitch.value ? ['customer'] : []
  if (bookingChangeStaffSwitch.value) {
    if (bookingChangeStaffOwnerCheckBox.value) resourceBookingUpdateEmailRecipients.push('owner')
    if (bookingChangeStaffResourcesCheckBox.value) resourceBookingUpdateEmailRecipients.push('resource')
    if (bookingChangeStaffOthersCheckBox.value && bookingChangeStaffOthers.values && bookingChangeStaffOthers.values.length > 0) {
      bookingChangeStaffOthers.values.forEach(item => resourceBookingUpdateEmailRecipients.push(item))
    }
  }
  const customerEmailRemindersMinutes = !bookingReminderCustomerSwitch.value ? [] : settings.customerEmailRemindersMinutes.values
  const resourceEmailRemindersMinutes = !bookingReminderStaffSwitch.value ? [] : settings.resourceEmailRemindersMinutes.values
  const customerRemindersEmailRecipients = bookingReminderCustomerSwitch.value ? ['customer'] : null
  if (bookingReminderStaffSwitch.value) {
    if (bookingReminderStaffOwnerCheckBox.value) resourceRemindersEmailRecipients.push('owner')
    if (bookingReminderStaffResourcesCheckBox.value) resourceRemindersEmailRecipients.push('resource')
    if (bookingReminderStaffOthersCheckBox.value && bookingReminderStaffOthers.values && bookingReminderStaffOthers.values.length > 0) {
      bookingReminderStaffOthers.values.forEach(item => resourceRemindersEmailRecipients.push(item))
    }
  }
  const dailyAgenda = dailyAgendaEmailsSwitch.value
    ? {
      time: dailyAgendaTime.value,
      specificResourceCategoryIds: dailyAgendaResources.value === 'some' ? dailyAgendaResourceCategory.values.length ? dailyAgendaResourceCategory.values : null : []
    }
    : {}

  return {
    customerEmailRemindersMinutes,
    resourceEmailRemindersMinutes,
    allowUserLocaleForCustomers: settings.allowUserLocaleForCustomers.value,
    allowUserLocaleForResources: settings.allowUserLocaleForResources.value,
    resourceBookingCreateEmailRecipients,
    customerBookingCreateEmailRecipients,
    resourceBookingDeleteEmailRecipients,
    customerBookingDeleteEmailRecipients,
    resourceBookingUpdateEmailRecipients,
    customerBookingUpdateEmailRecipients,
    customerRemindersEmailRecipients,
    resourceRemindersEmailRecipients,
    dailyAgenda
  }
}

export const settingsNotificationsFormValidate = settings => {
  if (!settings) return
  let errors = []
  const resourceEmailRemindersMinutes = (settings.resourceEmailRemindersMinutes && settings.resourceEmailRemindersMinutes.values) || []
  const customerEmailRemindersMinutes = (settings.customerEmailRemindersMinutes && settings.customerEmailRemindersMinutes.values) || []
  const bookingConfirmationStaffOthers = (settings.bookingConfirmationStaffOthersCheckBox.value && settings.bookingConfirmationStaffOthers && settings.bookingConfirmationStaffOthers.values) || []
  const bookingCancellationStaffOthers = (settings.bookingCancellationStaffOthersCheckBox.value && settings.bookingCancellationStaffOthers && settings.bookingCancellationStaffOthers.values) || []
  const bookingChangeStaffOthers = (settings.bookingChangeStaffOthersCheckBox.value && settings.bookingChangeStaffOthers && settings.bookingChangeStaffOthers.values) || []
  const bookingReminderStaffOthers = (settings.bookingReminderStaffOthersCheckBox.value && settings.bookingReminderStaffOthers && settings.bookingReminderStaffOthers.values) || []
  const bookingConfirmationStaffSwitch = settings.bookingConfirmationStaffSwitch && settings.bookingConfirmationStaffSwitch.value
  const bookingCancellationStaffSwitch = settings.bookingCancellationStaffSwitch && settings.bookingCancellationStaffSwitch.value
  const bookingChangeStaffSwitch = settings.bookingChangeStaffSwitch && settings.bookingChangeStaffSwitch.value
  const bookingReminderStaffSwitch = settings.bookingReminderStaffSwitch && settings.bookingReminderStaffSwitch.value
  const bookingReminderCustomerSwitch = settings.bookingReminderCustomerSwitch && settings.bookingReminderCustomerSwitch.value
  const dailyAgendaResources = settings.dailyAgendaResources && settings.dailyAgendaResources.value === 'some'
  const dailyAgendaTime = settings.dailyAgendaTime && settings.dailyAgendaTime.value
  const dailyAgendaResourceCategory = settings.dailyAgendaResourceCategory && settings.dailyAgendaResourceCategory.values
  const dailyAgendaEmailsSwitch = settings.dailyAgendaEmailsSwitch && settings.dailyAgendaEmailsSwitch.value
  const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)
  if (bookingConfirmationStaffSwitch) {
    if (!settings.bookingConfirmationStaffOthersCheckBox.value && !settings.bookingConfirmationStaffResourcesCheckBox.value && !settings.bookingConfirmationStaffOwnerCheckBox.value) {
      errors.push({
        key: 'bookingConfirmationStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    bookingConfirmationStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        errors.push({
          key: 'bookingConfirmationStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (!item) errors.push({ key: 'bookingConfirmationStaffOthers', value: 'errors.email.required' })
    })
  }
  if (bookingCancellationStaffSwitch) {
    if (!settings.bookingCancellationStaffOthersCheckBox.value && !settings.bookingCancellationStaffResourcesCheckBox.value && !settings.bookingCancellationStaffOwnerCheckBox.value) {
      errors.push({
        key: 'bookingCancellationStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    bookingCancellationStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        errors.push({
          key: 'bookingCancellationStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (!item) errors.push({ key: 'bookingCancellationStaffOthers', value: 'errors.email.required' })
    })
  }
  if (bookingChangeStaffSwitch) {
    if (!settings.bookingChangeStaffOthersCheckBox.value && !settings.bookingChangeStaffResourcesCheckBox.value && !settings.bookingChangeStaffOwnerCheckBox.value) {
      errors.push({
        key: 'bookingChangeStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    bookingChangeStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        errors.push({
          key: 'bookingChangeStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (settings.bookingChangeStaffOthersCheckBox.value && !item) errors.push({ key: 'bookingChangeStaffOthers', value: 'errors.email.required' })
    })
  }
  if (bookingReminderStaffSwitch) {
    if (!settings.bookingReminderStaffOthersCheckBox.value && !settings.bookingReminderStaffResourcesCheckBox.value && !settings.bookingReminderStaffOwnerCheckBox.value) {
      errors.push({
        key: 'bookingReminderStaffOthersCheckBox',
        value: 'errors.service.reminders.atLeastOneStaff'
      })
    }
    bookingReminderStaffOthers.forEach(item => {
      if (item && !emailRegex.test(item)) {
        errors.push({
          key: 'bookingReminderStaffOthers',
          value: 'errors.email.invalidWithVariable',
          replace: [{ key: 'EMAIL', value: item }]
        })
      }
      if (!item) errors.push({ key: 'bookingReminderStaffOthers', value: 'errors.email.required' })
    })
    resourceEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        errors.push({
          key: 'resourceEmailRemindersMinutes',
          value: 'errors.reminder.incorrect'
        })
      }
    })
  }
  if (bookingReminderCustomerSwitch) {
    customerEmailRemindersMinutes.forEach(item => {
      if (!item || item < 0) {
        errors.push({
          key: 'customerEmailRemindersMinutes',
          value: `Select an option for the customer email reminder(s)`,
          replace: [{ key: 'FIELD_NAME', value: 'global.customer', translateReplace: true }]
        })
      }
    })
  }

  // dailyAgenda
  if (dailyAgendaEmailsSwitch && !dailyAgendaTime) {
    errors.push({ key: 'dailyAgendaTime', value: 'errors.settings.reminders.dailyAgenda.time' })
  }
  if (dailyAgendaEmailsSwitch &&
    dailyAgendaResources &&
    !dailyAgendaResourceCategory.length
  ) {
    errors.push({ key: 'dailyAgendaResourceCategory', value: 'errors.servicesGroups.selectResource' })
  }
  return errors
}

export const settingsNotificationsFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// CUSTOMERS

export const settingsCustomersFormTransform = settings => {
  settings = settings || {}
  const defaultEugdprRules = [{
    customerType: null,
    customerTagId: null,
    frequency: '',
    frequencyUnit: null
  }]
  const result = {
    eugdprEnabled: {
      value: (settings.EUGDPRRules && settings.EUGDPRRules.length > 0) || false
    },
    eugdprRules: {
      values: settings.EUGDPRRules && settings.EUGDPRRules.length > 0
        ? settings.EUGDPRRules
        : defaultEugdprRules
    }
  }
  return result
}

export const settingsCustomersFormValidate = settings => {
  if (!settings) return
  const errors = []
  const eugdprRules = (settings.eugdprRules && settings.eugdprRules.values) || []
  const eugdprEnabled = settings.eugdprEnabled && settings.eugdprEnabled.value

  // Advanced validations
  if (eugdprEnabled) {
    eugdprRules.forEach((item, index) => {
      if (!item.customerType) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'settings.section.customers.form.eugdpr.select.customerType.label', translateReplace: true }]
        })
      }
      if (item.customerType === 'TAG' && !item.customerTagId) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'global.tag', translateReplace: true }]
        })
      }
      if (!item.frequency) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'settings.section.customers.form.eugdpr.input.period.label', translateReplace: true }]
        })
      }
      if (!item.frequencyUnit) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.required',
          replace: [{ key: 'FIELD_NAME', value: 'settings.section.customers.form.eugdpr.select.frequencyUnit.label', translateReplace: true }]
        })
      }
      if (item.frequency && (parseInt(item.frequency) === 0 || !NUMBERS_VALIDATION_REGEX.test(item.frequency))) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.frequency.onlyNumbers'
        })
      }

      // Allow only frequency less than MAX_GDPR_YEARS
      // years
      if (item.frequency && item.frequencyUnit && item.frequencyUnit === 'YEARS' && parseInt(item.frequency) > MAX_GDPR_YEARS) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.frequency.lessThanThreeYears'
        })
      }
      // months
      if (item.frequency && item.frequencyUnit && item.frequencyUnit === 'MONTHS' && parseInt(item.frequency) > MAX_GDPR_YEARS * 12) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.frequency.lessThanThreeYears'
        })
      }
      // weeks
      if (item.frequency && item.frequencyUnit && item.frequencyUnit === 'WEEKS' && parseInt(item.frequency) > MAX_GDPR_YEARS * 52) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.frequency.lessThanThreeYears'
        })
      }
      // days
      if (item.frequency && item.frequencyUnit && item.frequencyUnit === 'DAYS' && parseInt(item.frequency) > MAX_GDPR_YEARS * 365) {
        errors.push({
          key: 'globalErrors',
          value: 'errors.frequency.lessThanThreeYears'
        })
      }
    })
  }
  return errors
}

export const settingsCustomersSaveTransform = settings => {
  settings = settings || {}
  const result = {
    EUGDPRRules: []
  }
  if (settings.eugdprEnabled && !settings.eugdprEnabled.value) return result

  const eugdprRules = (settings.eugdprRules && settings.eugdprRules.values) || []
  if (eugdprRules.length === 0) return result
  if (eugdprRules.length === 1 && !eugdprRules[0].customerTagId && !eugdprRules[0].customerType && !eugdprRules[0].frequency && !eugdprRules[0].frequencyUnit) return result

  return {
    EUGDPRRules: eugdprRules.map(item => ({ ...item, frequency: parseInt(item.frequency) }))
  }
}

export const settingsCustomersFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// SECURITY

export const settingsSecurityFormTransform = settings => {
  let {
    securitySessionConfig,
    securitySessionInactivityConfig,
    requiredEnterpriseAuth2FAType,
    requiredAuth2FAType
  } = settings
  securitySessionConfig = securitySessionConfig || {}
  securitySessionInactivityConfig = securitySessionInactivityConfig || {}
  const result = {
    isInactivityLoggedOut: {
      value: !!securitySessionInactivityConfig.value && !!securitySessionInactivityConfig.mode
    },
    sessionLength: {
      value: securitySessionConfig.value || SESSION_LENGTH_DEFAULT.value
    },
    sessionMetric: {
      value: securitySessionConfig.mode || SESSION_LENGTH_DEFAULT.mode
    },
    inactivityLength: {
      value: securitySessionInactivityConfig.value || INACTIVITY_LENGTH_DEFAULT.value
    },
    inactivityMetric: {
      value: (securitySessionInactivityConfig.mode === 'NONE' || !securitySessionInactivityConfig.mode) ? INACTIVITY_LENGTH_DEFAULT.mode : securitySessionInactivityConfig.mode
    },
    use2FA: {
      value: requiredEnterpriseAuth2FAType !== 'NONE'
    },
    useEnterprise2FA: {
      value: requiredAuth2FAType && requiredAuth2FAType !== 'NONE'
    }
  }

  return result
}

export const settingsSecurityFormValidate = settings => {
  if (!settings) return
  const isInactivityLoggedOut = settings.isInactivityLoggedOut && settings.isInactivityLoggedOut.value
  const sessionLength = settings.sessionLength && settings.sessionLength.value
  const sessionMetric = settings.sessionMetric && settings.sessionMetric.value
  const inactivityLength = settings.inactivityLength && settings.inactivityLength.value
  const inactivityMetric = settings.inactivityMetric && settings.inactivityMetric.value

  const rules = [
    { 'sessionLength.value': ['required'] },
    { 'sessionMetric.value': ['required'] },
    { 'inactivityLength.value': [`requiredIf:${isInactivityLoggedOut}`] },
    { 'inactivityMetric.value': [`requiredIf:${isInactivityLoggedOut}`] }
  ]

  const messages = {
    'sessionLength': {
      'required': 'errors.required'
    },
    'sessionMetric': {
      'required': 'errors.required'
    },
    'inactivityLength': {
      'requiredIf': 'errors.required'
    },
    'inactivityMetric': {
      'requiredIf': 'errors.required'
    }
  }

  const replaces = {
    'sessionLength': {
      'required': {
        key: 'FIELD_NAME',
        value: 'settings.section.security.form.session.input.sessionLength.label',
        translateReplace: true
      }
    },
    'sessionMetric': {
      'required': {
        key: 'FIELD_NAME',
        value: 'settings.section.security.form.session.select.sessionMetric.label',
        translateReplace: true
      }
    },
    'inactivityLength': {
      'requiredIf': {
        key: 'FIELD_NAME',
        value: 'settings.section.security.form.session.input.inactivityLength.label',
        translateReplace: true
      }
    },
    'inactivityMetric': {
      'requiredIf': {
        key: 'FIELD_NAME',
        value: 'settings.section.security.form.session.select.inactivityMetric.label',
        translateReplace: true
      }
    }
  }

  const errors = validator(settings, rules, messages, replaces)

  // Allow only number in lengths
  if (sessionLength && (parseInt(sessionLength) === 0 || !NUMBERS_VALIDATION_REGEX.test(sessionLength))) {
    errors.push({
      key: 'sessionLength',
      value: 'errors.frequency.onlyNumbers'
    })
  }
  if (isInactivityLoggedOut && inactivityLength && (parseInt(inactivityLength) === 0 || !NUMBERS_VALIDATION_REGEX.test(inactivityLength))) {
    errors.push({
      key: 'inactivityLength',
      value: 'errors.frequency.onlyNumbers'
    })
  }

  // Minimum of 2 minutes
  if (sessionMetric === 'MINUTES' && sessionLength && parseInt(sessionLength) < 2) {
    errors.push({
      key: 'sessionLength',
      value: 'errors.session.minimum',
      replace: [
        {
          key: 'MIN_VALUE',
          value: 2
        },
        {
          key: 'MIN_METRIC',
          value: 'global.minutes.label',
          translateReplace: true
        }
      ]
    })
  }
  if (isInactivityLoggedOut && inactivityMetric === 'MINUTES' && inactivityLength && parseInt(inactivityLength) < 2) {
    errors.push({
      key: 'inactivityLength',
      value: 'errors.session.minimum',
      replace: [
        {
          key: 'MIN_VALUE',
          value: 2
        },
        {
          key: 'MIN_METRIC',
          value: 'global.minutes.label',
          translateReplace: true
        }
      ]
    })
  }

  // Maximum of 4 days
  if (
    (sessionMetric === 'MINUTES' && sessionLength && parseInt(sessionLength) > 4 * 24 * 60) ||
    (sessionMetric === 'HOURS' && sessionLength && parseInt(sessionLength) > 4 * 24) ||
    (sessionMetric === 'DAYS' && sessionLength && parseInt(sessionLength) > 4)
  ) {
    errors.push({
      key: 'sessionLength',
      value: 'errors.session.maximum',
      replace: [
        {
          key: 'MAX_VALUE',
          value: 4
        },
        {
          key: 'MAX_METRIC',
          value: 'global.days.label',
          translateReplace: true
        }
      ]
    })
  }
  if (
    (isInactivityLoggedOut && inactivityMetric === 'MINUTES' && inactivityLength && parseInt(inactivityLength) > 4 * 24 * 60) ||
    (isInactivityLoggedOut && inactivityMetric === 'HOURS' && inactivityLength && parseInt(inactivityLength) > 4 * 24) ||
    (isInactivityLoggedOut && inactivityMetric === 'DAYS' && inactivityLength && parseInt(inactivityLength) > 4)
  ) {
    errors.push({
      key: 'inactivityLength',
      value: 'errors.session.maximum',
      replace: [
        {
          key: 'MAX_VALUE',
          value: 4
        },
        {
          key: 'MAX_METRIC',
          value: 'global.days.label',
          translateReplace: true
        }
      ]
    })
  }

  // Session length should be greater than inactivity length
  if (isInactivityLoggedOut) {
    let sessionInMinutes = sessionLength
    if (sessionMetric === 'HOURS') sessionInMinutes = sessionInMinutes * 60
    if (sessionMetric === 'DAYS') sessionInMinutes = sessionInMinutes * 60 * 24
    let inactivityInMinutes = inactivityLength
    if (inactivityMetric === 'HOURS') inactivityInMinutes = inactivityInMinutes * 60
    if (inactivityMetric === 'DAYS') inactivityInMinutes = inactivityInMinutes * 60 * 24
    if (sessionInMinutes <= inactivityInMinutes) errors.push({ key: 'globalErrors', value: 'errors.session.greaterInactivityLength' })
  }

  return errors
}

export const settingsSecuritySaveTransform = settings => {
  settings = settings || {}
  const isInactivityLoggedOut = settings.isInactivityLoggedOut && settings.isInactivityLoggedOut.value
  const requiredEnterpriseAuth2FAType = settings.use2FA.value ? 'OTP' : 'NONE'
  const requiredAuth2FAType = settings.useEnterprise2FA.value ? 'OTP' : 'NONE'

  return {
    requiredEnterpriseAuth2FAType,
    requiredAuth2FAType,
    securitySessionConfig: {
      value: (settings.sessionLength && Number(settings.sessionLength.value)) || null,
      mode: settings.sessionMetric && settings.sessionMetric.value
    },
    securitySessionInactivityConfig: {
      value: isInactivityLoggedOut
        ? (settings.inactivityLength && Number(settings.inactivityLength.value)) || null
        : undefined,
      mode: isInactivityLoggedOut
        ? settings.inactivityMetric && settings.inactivityMetric.value
        : undefined
    }
  }
}

export const settingsSecurityFormServerErrorsTransform = error => {
  const errors = []
  if (error.code === 'MinimumSessionExpirationError') {
    errors.push({
      key: 'globalErrors',
      value: 'errors.session.minimum',
      replace: [
        {
          key: 'MIN_VALUE',
          value: 2
        },
        {
          key: 'MIN_METRIC',
          value: 'global.minutes.label',
          translateReplace: true
        }
      ]
    })
  } else if (error.code === 'MaximumSessionExpirationError') {
    errors.push({
      key: 'globalErrors',
      value: 'errors.session.maximum',
      replace: [
        {
          key: 'MAX_VALUE',
          value: 4
        },
        {
          key: 'MAX_METRIC',
          value: 'global.days.label',
          translateReplace: true
        }
      ]
    })
  } else if (['SecuritySessionConfigLimitEnterprise', 'SecuritySessionInactivityConfigLimitEnterprise'].includes(error.code)) {
    errors.push({ key: 'globalErrors', value: 'global.enterpriseOnly' })
  } else if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}
