import { handlers, store } from '../..'
import { setSubscriptionIdsCollectorFactory } from '../../../Utils/subscriptionCollector'

const handleSubscriptionIdsCollection = setSubscriptionIdsCollectorFactory({ handlers, configNames: ['servicesUpdated'], shouldDebounce: true })

export const servicesMoved = services => handlers.servicesListOrderChanged(services)

export const serviceUpdated = service => {
  if (!service) return
  const { services = {} } = store.getState()
  const { isComplete, ts, resourcesCount } = services.list.find(item => item.id === service.id) || {}
  if (ts === service.ts && resourcesCount === service.resourcesCount) return
  if (isComplete) return handlers.servicePreviewGet({ id: service.id, forceFetch: true })
  handlers.serviceUpdate(service)
}

export const serviceUpdatedCollect = id => {
  if (!id) return
  handleSubscriptionIdsCollection({ handlerName: 'servicesUpdated', id })
}

export const serviceDeleted = id => {
  if (!id) return
  const { services = {} } = store.getState()
  const service = services.list.find(item => item.id === id) || {}
  if (!service || service.isDeleted) return
  handlers.serviceDeleted(id)
  setTimeout(() => handlers.serviceRemoveDeleted(id), 2000)
}

export const serviceCategoriesMoved = categories => {
  handlers.serviceCategoryOrderChanged(categories)
}

export const serviceCategoryUpdated = category => {
  if (!category) return
  handlers.serviceCategoryUpdate(category)
}

export const serviceCategoryDeleted = id => {
  if (!id) return
  const { services = {} } = store.getState()
  const category = services.categoriesList.find(item => item.id === id) || {}
  if (!category || category.isDeleted) return
  handlers.serviceCategoryDeleted(id)
  setTimeout(() => handlers.serviceCategoryRemoveDeleted(id), 2000)
}
