import React, { useEffect } from 'react'
import { globalActions, connect, handlers } from '../../Store'
import { ResourceCategoriesList } from '../../Beauties'

const ResourceCategoriesListWrapper = props => {
  let { categories, pending, hash, branchFilter, account } = props
  categories = categories || []
  pending = !!pending
  account = account || {}
  useEffect(() => {
    globalActions.populateEnterpriseResourceCategories(true)
    handlers.resourceCategoriesToDeleteGet()
  }, [])
  const filteredCategories = categories.filter(item => (item.allowedBranchIds || []).includes(branchFilter))
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalResourceCategoriesRead, globalResourceCategoriesWrite } = enterprisePermissions || {}
  globalResourceCategoriesRead = !!isAdmin || !!globalResourceCategoriesRead
  globalResourceCategoriesWrite = !!isAdmin || !!globalResourceCategoriesWrite

  return (
    <ResourceCategoriesList
      categories={branchFilter !== 'all' ? filteredCategories : categories}
      pending={pending}
      hash={hash}
      globalResourceCategoriesRead={globalResourceCategoriesRead}
      globalResourceCategoriesWrite={globalResourceCategoriesWrite}
    />
  )
}

const maps = state => ({
  hash: state.router.hash,
  categories: state.resources.categoriesList,
  pending: state.resources.pending,
  branchFilter: (state.forms.resourceCategoriesFilter && state.forms.resourceCategoriesFilter.branchName && state.forms.resourceCategoriesFilter.branchName.value),
  account: state.account
})

export default connect(maps)(ResourceCategoriesListWrapper)
