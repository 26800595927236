import React from 'react'
import moment from 'moment'
import {
  Form,
  Row,
  Col,
  SingleSelect,
  Input,
  t,
  DateInput,
  FontAwesome5
} from '../../../Common'

const SystemMessagesFilters = props => {
  const { hasAdvancedFilter, toggleAdvancedOptions, count = 0 } = props
  const typeOptions = [
    { value: 'GRANTED', label: t('global.granted') },
    { value: 'PENDING', label: t('global.requested') },
    { value: 'ACCEPTED', label: t('global.activated') }
  ]

  return (
    <div className='ta-notifications__system-messages__filter'>
      <Form name='systemMessagesFilters'>
        <Row>
          <Col size={50}>
            <Input
              label={t('global.search')}
              name='search'
              clearable
            />
          </Col>
          <Col size={50}>
            <SingleSelect
              name='type'
              label={t('global.segment')}
              options={typeOptions}
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <span className='ta-notifications__filter__notifications-count'>
              {t('global.resultsWithCount', [{ key: 'COUNT', value: count || '0' }])}
            </span>
          </Col>
          <Col>
            <span className='ta-notifications__filter__notifications-advanced-btn' onClick={toggleAdvancedOptions}>
              {t('global.advancedFilters')}
              <FontAwesome5 icon={hasAdvancedFilter ? 'angle-up' : 'angle-down'} />
            </span>
          </Col>
        </Row>
        {(hasAdvancedFilter &&
          <Row>
            <Col size={50}>
              <DateInput
                name='from'
                label={t('global.from')}
                icon='calendar'
                maxDate={moment()}
                clearable
              />
            </Col>
            <Col size={50}>
              <DateInput
                className='period-until'
                name='until'
                label={t('global.until')}
                icon='calendar'
                maxDate={moment()}
                clearable
              />
            </Col>
          </Row>
        )}
      </Form>
    </div>
  )
}

export default SystemMessagesFilters
