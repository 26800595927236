import React from 'react'
import { FormButtons, Button, t } from '../../../Common'

const ServiceFormButtons = props => {
  const { cancelLink } = props || {}

  return (
    <FormButtons>
      <Button as='link' to={cancelLink} isSecondary>
        {t('buttons.cancel.label')}
      </Button>
      <Button type='submit' isPrimary>
        {t('buttons.saveChanges.label')}
      </Button>
    </FormButtons>
  )
}

export default ServiceFormButtons
