import { userPermissionTypesFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  pending: false,
  pendingForm: false,
  pendingPreview: false,
  list: [],
  areFetched: false
}

export const ADMIN_PERMISSION_TYPE = {
  id: 'ADMIN',
  name: 'Admin',
  isGlobal: true,
  icon: 'user-shield',
  color: '#cb3b43',
  permissions: {
    // Account
    accountRead: true,
    // Statistics
    statisticsRead: true,
    // Branches
    branchesRead: true,
    branchesWrite: true,
    branchesDelete: false,
    allowFulfilment: true,
    // Tags
    tagsRead: true,
    tagsWrite: true,
    tagsDelete: true,
    // Customers
    globalCustomersRead: true,
    globalCustomersWrite: true,
    globalCustomersDelete: true,
    // DataFields
    globalDataFieldsRead: true,
    globalDataFieldsWrite: true,
    globalDataFieldsDelete: true,
    // Tags
    globalTagsRead: true,
    globalTagsWrite: true,
    globalTagsDelete: true,
    // Services
    globalServicesRead: true,
    globalServicesWrite: true,
    globalServicesDelete: true,
    // Groups
    globalGroupsRead: true,
    globalGroupsWrite: true,
    globalGroupsDelete: true,
    // Resource categories
    globalResourceCategoriesRead: true,
    globalResourceCategoriesWrite: true,
    globalResourceCategoriesDelete: true,
    // Global PG
    globalPermissionsGroupsRead: true,
    globalPermissionsGroupsWrite: true,
    globalPermissionsGroupsDelete: true,
    // Settings
    settingsRead: true,
    settingsWrite: true,
    // Apps
    appsRead: true,
    // Users
    usersRead: true,
    usersWrite: true,
    usersDelete: true,
    // PG
    permissionsGroupsRead: true,
    permissionsGroupsWrite: true,
    permissionsGroupsDelete: true
  }
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  userPermissionTypesReset: () => dispatch(actions.USER_PERMISSIONS_RESET),
  // Subscriptions
  userPermissionTypesSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.USER_PERMISSION_TYPES_SUBSCRIPTION_SET, { name, id, data, ts }),
  // List
  userPermissionTypesGet: forceFetch => dispatch(actions.USER_PERMISSION_TYPES_GET, forceFetch),
  userPermissionTypesPopulate: permissionTypes => dispatch(actions.USER_PERMISSION_TYPES_POPULATE, permissionTypes),
  userPermissionTypesReady: () => dispatch(actions.USER_PERMISSION_TYPES_READY),
  // Form
  userPermissionTypesFormGet: permissionType => dispatch(actions.USER_PERMISSION_TYPES_FORM_GET, permissionType),
  userPermissionTypesFormPopulate: (userPermissionTypes, branches, account) => {
    handlers.formSet('userPermissionType', userPermissionTypesFormTransform(userPermissionTypes, branches, account))
    handlers.userPermissionTypesFormReady()
  },
  userPermissionTypesFormReady: () => dispatch(actions.USER_PERMISSION_TYPES_FORM_READY),
  userPermissionTypesFormSave: (permissionType, scrollToError = () => { }) =>
    dispatch(actions.USER_PERMISSION_TYPES_FORM_SAVE, { permissionType, scrollToError }),
  // Save
  userPermissionTypeSave: permissionType => dispatch(actions.USER_PERMISSION_TYPE_SAVE, permissionType),
  // Update
  userPermissionTypeUpdate: permissionType => dispatch(actions.USER_PERMISSION_TYPE_UPDATE, permissionType),
  // Delete
  userPermissionTypeDelete: id => dispatch(actions.USER_PERMISSION_TYPE_DELETE, id),
  userPermissionTypeDeleteReady: id => dispatch(actions.USER_PERMISSION_TYPE_DELETE_READY, id)
})

// Reset
reducer.USER_PERMISSIONS_RESET = () => reducer.initialState

// Subscriptions
reducer.USER_PERMISSION_TYPES_SUBSCRIPTION_SET = state => state

// List
reducer.USER_PERMISSION_TYPES_GET = state => ({
  ...state,
  pending: true,
  pendingPreview: true
})

reducer.USER_PERMISSION_TYPES_POPULATE = (state, permissionTypes) => ({
  ...state,
  pending: false,
  pendingPreview: false,
  list: permissionTypes,
  areFetched: true
})

reducer.USER_PERMISSION_TYPES_READY = state => ({
  ...state,
  pending: false,
  pendingPreview: false
})

// Form
reducer.USER_PERMISSION_TYPES_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.USER_PERMISSION_TYPES_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.USER_PERMISSION_TYPES_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.USER_PERMISSION_TYPE_SAVE = (state, permissionType) => ({
  ...state,
  list: permissionType ? [...state.list, permissionType] : state.list
})

reducer.USER_PERMISSION_TYPE_UPDATE = (state, permissionType) => {
  const { id } = permissionType || {}
  if (!id) return { ...state, pendingForm: false }
  let newPermissionTypes = [...state.list]
  const selectedIndex = newPermissionTypes.findIndex(item => item.id === id)
  if (selectedIndex !== -1) newPermissionTypes[selectedIndex] = permissionType
  return {
    ...state,
    list: newPermissionTypes
  }
}

reducer.USER_PERMISSION_TYPE_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.USER_PERMISSION_TYPE_DELETE_READY = state => ({
  ...state,
  pendingPreview: true
})

reducer.USER_PERMISSION_TYPE_DELETE_READY = (state, id) => ({
  ...state,
  list: state.list.filter(permissionType => permissionType.id !== id),
  pendingPreview: false
})

export default reducer
