import React from 'react'
import {
  FormSection,
  Input,
  Row,
  Col,
  t
} from '../../Common'

const BillingFormFields = props => {
  const { country } = props
  const { label, skipVatId, code: countryCode } = country || {}

  return (
    <FormSection>
      <Row>
        <Col>
          <Input
            name='name'
            label={t('billing.list.section.billingDetails.fieldCompanyOrName.label')}
            mandatory
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name='address1'
            label={`${t('address.label')} 1`}
            mandatory
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            name='address2'
            label={`${t('address.label')} 2`}
          />
        </Col>
      </Row>
      <Row>
        <Col size={50}>
          <Input
            name='zipCode'
            label={t('address.zip')}
            mandatory
          />
        </Col>
        <Col size={50}>
          <Input
            name='city'
            label={t('address.city')}
            mandatory
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input name='country' label={t('global.country')} value={label} disabled />
        </Col>
      </Row>
      {!skipVatId && countryCode !== 'GB' && (
        <Row>
          <Col>
            <Input
              name='vatNo'
              label={t('global.vatNumber')}
              prefix={countryCode}
              isPrefixText
            />
          </Col>
        </Row>
      )}
    </FormSection>
  )
}

export default BillingFormFields
