import React from 'react'
import { connect } from '../../Store'
import { ServiceCombinationFormPrice } from '../../Beauties'

const ServiceCombinationFormPriceWrapper = props => {
  const { hasPriceOverwrite } = props

  return (
    <ServiceCombinationFormPrice hasPriceOverwrite={!!hasPriceOverwrite} />
  )
}

const maps = state => ({
  hasPriceOverwrite: state.forms.services.hasPriceOverwrite && state.forms.services.hasPriceOverwrite.value
})

export default connect(maps)(ServiceCombinationFormPriceWrapper)
