import React from 'react'
import PropTypes from 'prop-types'
import { Alert, t } from '../../Common'
import { ServicesListItem } from '../../Beauties'

const ServicesList = props => {
  const {
    services,
    allServices,
    isDragAndDropMode,
    areCategoriesExpanded,
    search,
    filterType,
    categoryIndex,
    isDefaultCategory,
    isCategoryLastIndex
  } = props

  return (
    <div className='ta-services__list'>
      {(services.length > 0 && services.map((service, index) => (
        <ServicesListItem
          search={search}
          filterType={filterType}
          key={service.id}
          service={service}
          allServices={allServices}
          index={index}
          isDragAndDropMode={isDragAndDropMode}
          areCategoriesExpanded={areCategoriesExpanded}
          categoryIndex={categoryIndex}
          isCategoryLastIndex={isCategoryLastIndex}
          isDefaultCategory={isDefaultCategory}
        />
      )))}
      {(!services.length &&
        <Alert noOffset label={t('servicesGroups.list.service.noResultsBox.empty.text')} theme='no-content' />
      )}
    </div>
  )
}

ServicesList.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default ServicesList
