import React from 'react'
import { MenuList } from '../../Beauties'

import './Menu.css'

const Menu = props => {
  return (
    <div className='ta-menu'>
      <MenuList {...props} />
    </div>
  )
}

export default Menu
