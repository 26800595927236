import React from 'react'
import DOMPurify from 'dompurify'
import { encodeHtml } from '../Utils'

export default (text, doHtmlEncoding, skipSanitization) => {
  if (!text) return null
  text = skipSanitization ? text : DOMPurify.sanitize(text)
  if (doHtmlEncoding) text = encodeHtml(text)
  return <span dangerouslySetInnerHTML={{ __html: text }} />
}
