import { feedContextInProps, useMouseLeave } from '../../Utils'
import { HoverPopupContext } from '../../Common'
import { useEffect } from 'react'

const HoverPopupTrigger = props => {
  const { children, setIsHover } = props
  const [mouseLeft, ref] = useMouseLeave()
  useEffect(() => { if (mouseLeft) { onMouseLeave() } }, [mouseLeft])
  const onMouseEnter = () => { setIsHover && setIsHover(true) }
  const onMouseLeave = () => { setIsHover && setIsHover(false) }

  return (
    <div ref={ref} className='ta-hover-popup__trigger' onMouseEnter={onMouseEnter} data-testid='hover-popup-trigger'>
      {children}
    </div>
  )
}

// TODO: Fix this old context
export default feedContextInProps(HoverPopupTrigger, HoverPopupContext)
