import { selectors, handlers, store } from '../..'
import { activityLogTransform } from './utils'

export const activityLogUpdated = activityLog => {
  if (!activityLog) return
  const state = store.getState()
  const { activity } = state
  const resources = selectors.resourcesListSelector(state)
  const { activityLogs } = activity || {}
  let newActivityLogs = []

  if (activityLogs.find(item => item.id === activityLog.id)) {
    newActivityLogs = activityLogs.map(item => {
      return item.id === activityLog.id ? activityLogTransform(activityLog, resources) : ({ ...item })
    })
  } else {
    newActivityLogs = [activityLogTransform(activityLog, resources), ...activityLogs]
  }
  handlers.activityLogsListPopulate(newActivityLogs, false, true)
  handlers.activityLogsListReady()
}
