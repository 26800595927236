import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { CoursePreview } from '../../Beauties'

const CoursePreviewWrapper = props => {
  let {
    id,
    account,
    courses,
    pending,
    hash,
    message,
    enterpriseId,
    settings
  } = props
  id = id || ''
  hash = hash || ''
  courses = courses || []
  pending = !!pending
  message = message || null
  settings = settings || {}
  const selectedCourse = courses.find(item => item.id === id) || {}
  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.coursePreviewGet({ id })
  }, [id])

  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsRead, globalGroupsWrite, globalGroupsDelete } = enterprisePermissions || {}
  globalGroupsRead = !!isAdmin || !!globalGroupsRead
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite
  globalGroupsDelete = !!isAdmin || !!globalGroupsDelete
  const {
    customerEmailRemindersMinutes, resourceEmailRemindersMinutes
  } = settings || {}

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CoursePreview
          course={selectedCourse}
          hash={hash}
          message={message}
          enterpriseId={enterpriseId}
          companyCustomerEmailRemindersMinutes={customerEmailRemindersMinutes}
          companyResourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
          globalGroupsRead={globalGroupsRead}
          globalGroupsWrite={globalGroupsWrite}
          globalGroupsDelete={globalGroupsDelete}
        />
      )}
    </>
  )
}

const maps = (state, props) => ({
  id: state.router.data && state.router.data.id,
  hash: state.router.hash,
  course: (state.courses.selected && state.courses.list.find(r => r.id === state.courses.selected)) || {},
  courses: state.courses.list,
  pending: state.courses.pendingPreview,
  account: state.account,
  message: state.courses.messagePreview,
  enterpriseId: state.account.enterprise && state.account.enterprise.id,
  settings: state.company.settings
})

export default memo(connect(maps)(CoursePreviewWrapper))
