import { map, distinctUntilChanged } from 'rxjs/operators'
import { payloads$, actions, handlers, store$, store, selectors } from '../..'
import { q } from '../../API'
import {
  settingsNotificationsSaveTransform,
  settingsNotificationsFormServerErrorsTransform,
  settingsNotificationsFormValidate,
  settingsCustomersSaveTransform,
  settingsCustomersFormServerErrorsTransform,
  settingsCustomersFormValidate,
  settingsSecuritySaveTransform,
  settingsSecurityFormServerErrorsTransform,
  settingsSecurityFormValidate
} from './utils'

// Notifications Save
payloads$(actions.SETTINGS_NOTIFICATIONS_FORM_SAVE)
  .subscribe(async ({ settings, scrollToError }) => {
    let { company } = store.getState()
    company = company || {}
    const errors = settingsNotificationsFormValidate(settings)
    if (errors.length) return setSettingsNotificationsFormSaveErrors(errors, scrollToError)
    const formattedSettings = settingsNotificationsSaveTransform(settings)
    const savedEnterpriseSettings = await q('saveEnterpriseSettings', { settings: formattedSettings })
    const { error } = savedEnterpriseSettings || {}
    if (error) return setSettingsNotificationsFormSaveErrors(settingsNotificationsFormServerErrorsTransform(error), scrollToError)
    handlers.companyPopulate({ ...company, settings: savedEnterpriseSettings })
    handlers.navigateToPath(`/settings/email`)
  })

const setSettingsNotificationsFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('settings', errors)
  handlers.settingsNotificationsFormReady()
  scrollToError && scrollToError(errors)
}

// Customers Save
payloads$(actions.SETTINGS_CUSTOMERS_FORM_SAVE)
  .subscribe(async ({ settings, scrollToError }) => {
    let { company } = store.getState()
    company = company || {}
    const errors = settingsCustomersFormValidate(settings)
    if (errors.length) return setSettingsCustomersFormSaveErrors(errors, scrollToError)
    const formattedSettings = settingsCustomersSaveTransform(settings)
    const savedEnterpriseSettings = await q('saveEnterpriseSettings', { settings: formattedSettings })
    const { error } = savedEnterpriseSettings
    if (error) return setSettingsCustomersFormSaveErrors(settingsCustomersFormServerErrorsTransform(error), scrollToError)
    handlers.companyPopulate({ ...company, settings: savedEnterpriseSettings })
    handlers.navigateToPath(`/settings/customers`)
  })

const setSettingsCustomersFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('settingsCustomers', errors)
  handlers.settingsCustomersFormReady()
  scrollToError && scrollToError(errors)
}

// Security Save
payloads$(actions.SETTINGS_SECURITY_FORM_SAVE)
  .subscribe(async ({ settings, scrollToError }) => {
    let { company } = store.getState()
    company = company || {}
    const errors = settingsSecurityFormValidate(settings)
    if (errors.length) return setSettingsSecurityFormSaveErrors(errors, scrollToError)
    const formattedSettings = settingsSecuritySaveTransform(settings)
    const savedEnterpriseSettings = await q('saveEnterpriseSettings', { settings: formattedSettings })
    const { error } = savedEnterpriseSettings
    if (error) return setSettingsSecurityFormSaveErrors(settingsSecurityFormServerErrorsTransform(error), scrollToError)
    const state = store.getState()
    const { hash } = state.router || {}
    const availableTabs = ['branchManager', 'webapp']
    const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
    handlers.companyPopulate({ ...company, settings: savedEnterpriseSettings })
    handlers.navigateToPath(`/settings/security@@${activeTab}`)
  })

const setSettingsSecurityFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('settingsSecurity', errors)
  handlers.settingsSecurityFormReady()
  scrollToError && scrollToError(errors)
}

// Form eugdpr switch change
store$
  .pipe(
    map(state => state.forms.settingsCustomers.eugdprEnabled && state.forms.settingsCustomers.eugdprEnabled.value),
    distinctUntilChanged()
  ).subscribe(() => {
    handlers.formErrorsSet('settingsCustomers', [])
  })

// Form Security Session inactivity checkbox change
store$
  .pipe(
    map(state => state.forms.settingsSecurity && state.forms.settingsSecurity.isInactivityLoggedOut && state.forms.settingsSecurity.isInactivityLoggedOut.value),
    distinctUntilChanged()
  ).subscribe(isInactivityLoggedOut => {
    let { router, forms } = store.getState()
    router = router || {}
    forms = forms || {}
    let { props } = router || {}
    props = props || {}
    let { formDiscard } = props || {}
    let { formsWithUnsavedChanges } = forms || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    if (formsWithUnsavedChanges.includes(formDiscard)) {
      if (!isInactivityLoggedOut) {
        const state = store.getState()
        const { forms } = state || {}
        const { settingsSecurity } = forms || {}
        let { inactivityLength, inactivityMetric } = settingsSecurity || {}

        handlers.formFieldsUpdate('settingsSecurity', {
          inactivityLength: {
            ...inactivityLength,
            error: '',
            errors: []
          },
          inactivityMetric: {
            ...inactivityMetric,
            error: '',
            errors: []
          },
          globalErrors: {
            error: '',
            errors: []
          }
        })
      }
    }
  })

// set past booking title `true` when change duration, resources, prepAndFollowUp, groupPrice
store$
  .pipe(
    map(state => {
      const { forms } = state
      const { bookingSettings } = forms || {}
      const {
        pastBookingDuration,
        pastBookingResources,
        pastBookingPrepAndFollowUp,
        pastBookingGroupPrice,
        pastBookingCustomDataFields
      } = bookingSettings || {}
      let { value: pastBookingDurationValue } = pastBookingDuration || {}
      pastBookingDurationValue = pastBookingDurationValue || false
      let { value: pastBookingResourcesValue } = pastBookingResources || {}
      pastBookingResourcesValue = pastBookingResourcesValue || false
      let { value: pastBookingPrepAndFollowUpValue } = pastBookingPrepAndFollowUp || {}
      pastBookingPrepAndFollowUpValue = pastBookingPrepAndFollowUpValue || false
      let { value: pastBookingGroupPriceValue } = pastBookingGroupPrice || {}
      pastBookingGroupPriceValue = pastBookingGroupPriceValue || false
      let { value: pastBookingCustomDataFieldsValue } = pastBookingCustomDataFields || {}
      pastBookingCustomDataFieldsValue = pastBookingCustomDataFieldsValue || false

      return JSON.stringify({
        pastBookingDurationValue,
        pastBookingResourcesValue,
        pastBookingPrepAndFollowUpValue,
        pastBookingGroupPriceValue,
        pastBookingCustomDataFieldsValue
      })
    }),
    distinctUntilChanged()
  ).subscribe(res => {
    const {
      pastBookingDurationValue,
      pastBookingResourcesValue,
      pastBookingPrepAndFollowUpValue,
      pastBookingGroupPriceValue,
      pastBookingCustomDataFieldsValue
    } = JSON.parse(res)
    const shouldBeTitleSelected = pastBookingDurationValue ||
      pastBookingResourcesValue ||
      pastBookingPrepAndFollowUpValue ||
      pastBookingGroupPriceValue ||
      pastBookingCustomDataFieldsValue

    if (shouldBeTitleSelected) {
      handlers.formFieldsUpdate('bookingSettings', {
        pastBookingTitle: {
          value: true,
          errors: []
        }
      })
    }
  })

store$.pipe(map(state => state &&
  state.forms &&
  state.forms.bookingSettings &&
  state.forms.bookingSettings.allowPartialUpdateOfBookingsInThePast &&
  state.forms.bookingSettings.allowPartialUpdateOfBookingsInThePast.value),
distinctUntilChanged()
).subscribe((allowPartialUpdateOfBookingsInThePastValue) => {
  if (!allowPartialUpdateOfBookingsInThePastValue) {
    const state = store.getState()
    const form = selectors.formSelector(state, { formName: 'bookingSettings' })
    handlers.formSet('bookingSettings', {
      ...form,
      pastBookingCustomDataFields: {
        value: false,
        errors: []
      },
      pastBookingCustomer: {
        value: false,
        errors: []
      },
      pastBookingDuration: {
        value: false,
        errors: []
      },
      pastBookingGroupPrice: {
        value: false,
        errors: []
      },
      pastBookingNotes: {
        value: false,
        errors: []
      },
      pastBookingParticipantsAndGuests: {
        value: false,
        errors: []
      },
      pastBookingPrepAndFollowUp: {
        value: false,
        errors: []
      },
      pastBookingResources: {
        value: false,
        errors: []
      },
      pastBookingStartTime: {
        value: false,
        errors: []
      },
      pastBookingTags: {
        value: false,
        errors: []
      },
      pastBookingTitle: {
        value: false,
        errors: []
      }
    })
  }
})

store$.pipe(map(state => state &&
  state.forms &&
  state.forms.bookingSettings &&
  state.forms.bookingSettings.forbidUpdatingBookingsInThePast &&
  state.forms.bookingSettings.forbidUpdatingBookingsInThePast.value),
distinctUntilChanged()
).subscribe((forbidUpdatingBookingsInThePastValue) => {
  if (!forbidUpdatingBookingsInThePastValue) {
    const state = store.getState()
    const form = selectors.formSelector(state, { formName: 'bookingSettings' })
    handlers.formSet('bookingSettings', {
      ...form,
      pastBookingCustomDataFields: {
        value: false,
        errors: []
      },
      pastBookingCustomer: {
        value: false,
        errors: []
      },
      pastBookingDuration: {
        value: false,
        errors: []
      },
      pastBookingGroupPrice: {
        value: false,
        errors: []
      },
      pastBookingNotes: {
        value: false,
        errors: []
      },
      pastBookingParticipantsAndGuests: {
        value: false,
        errors: []
      },
      pastBookingPrepAndFollowUp: {
        value: false,
        errors: []
      },
      pastBookingResources: {
        value: false,
        errors: []
      },
      pastBookingStartTime: {
        value: false,
        errors: []
      },
      pastBookingTags: {
        value: false,
        errors: []
      },
      pastBookingTitle: {
        value: false,
        errors: []
      },
      allowPartialUpdateOfBookingsInThePast: {
        value: false,
        errors: []
      }
    })
  }
})

store$.pipe(map(state => state &&
  state.forms &&
  state.forms.settings &&
  state.forms.settings.dailyAgendaEmailsSwitch &&
  state.forms.settings.dailyAgendaEmailsSwitch.value),
distinctUntilChanged()
).subscribe((dailyAgendaEmailsSwitch) => {
  if (!dailyAgendaEmailsSwitch) {
    const state = store.getState()
    const form = selectors.formSelector(state, { formName: 'settings' })
    handlers.formSet('settings', {
      ...form,
      dailyAgendaResources: {
        value: 'all',
        errors: []
      },
      dailyAgendaResourceCategory: {
        values: [],
        errors: []
      },
      dailyAgendaTime: {
        value: '05:00',
        errors: []
      }
    })
  }
})
