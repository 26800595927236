import React from 'react'
import { Alert, t, Spinner } from '../../../Common'
import { UserPermissionTypeUserItem } from '../../../Beauties'

const UserPermissionTypeUsers = props => {
  let { users, pendingUsers } = props
  users = users || []

  return (
    pendingUsers
      ? (
        <div className='ta-user-permission-types__users-spinner'>
          <Spinner />
        </div>
        )
      : users.length > 0
        ? users.map(user => <UserPermissionTypeUserItem key={user.id} user={user} />)
        : <Alert noOffset label={t('userPermissionTypes.detail.tabUsers.noResults')} theme='no-content' />
  )
}

export default UserPermissionTypeUsers
