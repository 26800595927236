const reducer = {}

reducer.initialState = {}

reducer.handlers = (dispatch, actions, handlers) => ({
  callCentreLogin: id => dispatch(actions.CALL_CENTRE_LOGIN, id)
})

reducer.CALL_CENTRE_LOGIN = state => state

export default reducer
