import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { UserGroup } from '../../Beauties'
import { Loader } from '../../Common'

class UserGroupsListWrapper extends Component {
  componentDidMount () {
    handlers.userGroupsGet()
  }

  render () {
    const { users, pending } = this.props

    return (
      <>
        <Loader active={pending} />
        <UserGroup users={users} />
      </>
    )
  }
}

UserGroupsListWrapper.propTypes = {}

const maps = state => ({
  pending: !!(state.users && state.users.pending),
  users: (state.users && state.userGroups.userGroupsList) || []
})

export default connect(maps)(UserGroupsListWrapper)
