import React from 'react'

const FormSection = props => {
  const { children, className, ignoreBottomOffset } = props
  const classNames = ['ta-form-section']
  if (className) classNames.push(className)
  if (ignoreBottomOffset) classNames.push('ignore-bottom-offset')

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default FormSection
