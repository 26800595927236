import React, { useEffect } from 'react'
import { ServicesToDelete } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'

const ServicesToDeleteWrapper = props => {
  let { servicesToDelete, branches, pending, account } = props
  servicesToDelete = servicesToDelete || []
  branches = branches || []
  pending = !!pending
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalServicesRead, globalServicesWrite } = enterprisePermissions || {}
  globalServicesRead = !!isAdmin || !!globalServicesRead
  globalServicesWrite = !!isAdmin || !!globalServicesWrite
  useEffect(() => {
    if (!globalServicesRead) handlers.navigateToPath('/management/services')
  }, [globalServicesRead])

  const onDeleteGlobalEntity = (companyId, region, externalId, id) => handlers.serviceToDeleteDelete({ companyId, region, externalId, id })
  const servicesToDeleteData = servicesToDelete.map(item => ({
    service: item,
    branch: branches.find(branch => branch.id === item.companyId)
  }))

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServicesToDelete
          servicesToDeleteData={servicesToDeleteData}
          onDeleteGlobalEntity={onDeleteGlobalEntity}
          globalServicesWrite={globalServicesWrite}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  servicesToDelete: state.services.listToDelete,
  pending: state.services.pendingToDeleteList,
  account: state.account
})

export default connect(maps)(ServicesToDeleteWrapper)
