import React from 'react'
import PropTypes from 'prop-types'
import { CompanyTagPreviewDetails } from '../../Beauties'

const CompanyTagPreviewDetailsWrapper = props => {
  const { tag = {} } = props
  return (
    <CompanyTagPreviewDetails {...tag} />
  )
}

CompanyTagPreviewDetailsWrapper.propTypes = {
  tag: PropTypes.object.isRequired
}

export default CompanyTagPreviewDetailsWrapper
