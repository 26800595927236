import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../../Common'

import './DefaultTitle.css'
import './TabletDefaultTitle.css'

const ICON_THEMES = [
  'grey',
  'dark-grey'
]

const SIZES = {
  s: 'ta-title-sm',
  m: 'ta-title-md',
  l: ''
}

const DefaultTitle = ({
  label,
  children,
  isCompact,
  icon,
  iconBoxStyle,
  addOn,
  onIconClick,
  iconTheme,
  isIconDarkGrey,
  className,
  isCentered,
  ignoreTopOffset,
  iconType,
  size,
  extraText
}) => {
  const classNames = ['ta-title default']
  const iconClassName = ['ta-title__label__icon']
  if (className) classNames.push(className)
  if (isCompact) classNames.push('ta-title__compact')
  if (ignoreTopOffset) classNames.push('ignore_top_offset')
  if (iconTheme && ICON_THEMES.includes(iconTheme)) iconClassName.push(`icon-${iconTheme}`)
  if (isIconDarkGrey) classNames.push('icon-dark-grey')
  if (isCentered) { classNames.push('ta-title__centered') }
  if (size && SIZES[size]) classNames.push(SIZES[size])

  return (
    <div className={classNames.join(' ')} data-testid='title'>
      <div className='ta-title__label' data-testid='title-label'>
        {(icon &&
          <div className={iconClassName.join(' ')} style={iconBoxStyle} onClick={onIconClick}>
            <FontAwesome5 icon={icon} type={iconType} />
          </div>
        )}
        <p className='ta-title__label__text'>{label}</p>
        {extraText && (
          <span className='ta-section-title__extra-text'>
            {extraText}
          </span>
        )}
        {(addOn &&
          <div className='ta-title__label__addon' data-testid='title-addOn'>
            {addOn}
          </div>
        )}
      </div>
      {!isCentered && <div className='ta-title__content' data-testid='title-content'>{children}</div>}
    </div>
  )
}

DefaultTitle.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconBoxStyle: PropTypes.object,
  addOn: PropTypes.node,
  onIconClick: PropTypes.func,
  isCompact: PropTypes.bool,
  isCentered: PropTypes.bool,
  ignoreTopOffset: PropTypes.bool
}

DefaultTitle.defaultProps = {
  label: '',
  icon: null,
  addOn: null,
  onIconClick: null,
  isCompact: false,
  isCentered: false,
  ignoreTopOffset: false
}

export default DefaultTitle
