import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Row,
  Col,
  Error,
  t
} from '../../Common'
import { BillingFormFields } from '../../Beauties'

import './BillingForm.css'

const BillingForm = props => {
  const {
    title,
    onSubmit,
    cancelLink,
    country
  } = props

  return (
    <div className='ta-billing__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='billing'>
            <BillingFormFields country={country} />
            <FormSection>
              <Row>
                <Col>
                  <Error name='globalErrors' />
                </Col>
              </Row>
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.save.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BillingForm
