import { translateServerCode, validator, sortBy, uniq } from '../../../Utils'

// List

export const permissionTypesListErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// One

export const permissionTypeDetailErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// Form

export const userPermissionTypesFormTransform = (permissionType, branches, account) => {
  branches = branches || []
  let {
    isUpdateAllowedByIssuer,
    permissions: permissionTypePermissions,
    name,
    externalId,
    color,
    icon,
    id
  } = permissionType || {}
  permissionTypePermissions = permissionTypePermissions || {}
  const {
    branchesAccessWhitelist,
    branchesAccessBlacklist
  } = permissionTypePermissions
  let { enterprisePermissions, enterprisePermissionsGroupId, isOwner: amIOwner, isAdmin: amIAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let {
    branchesAccessBlacklist: enterpriseBranchesAccessBlacklist,
    branchesAccessWhitelist: enterpriseBranchesAccessWhitelist
  } = enterprisePermissions
  enterpriseBranchesAccessWhitelist = enterpriseBranchesAccessWhitelist || []
  let branchAccess = 'ALLOW'
  if (branchesAccessBlacklist && branchesAccessBlacklist.length) branchAccess = 'DENY'
  isUpdateAllowedByIssuer = typeof isUpdateAllowedByIssuer === 'boolean'
    ? isUpdateAllowedByIssuer
    : true
  const arePermissionChangeDisabled = !isUpdateAllowedByIssuer || id === enterprisePermissionsGroupId
  const isTypeAdd = !id
  const amICustom = !amIOwner && !amIAdmin
  const branchesAccessWhitelistOptions = branches
    .filter(branch => !(branchesAccessBlacklist || []).includes(branch))
    .map(branch => ({
      label: branch.name,
      value: branch.id
    }))
    .sort(sortBy('label'))
  const branchesAccessBlacklistOptions = branches
    .filter(branch => !(branchesAccessWhitelist || []).includes(branch))
    .map(branch => ({
      label: branch.name,
      value: branch.id
    }))
    .sort(sortBy('label'))
  const defaultCustomPermissions = {
    // Account
    accountRead: false,
    // Statistics
    statisticsRead: false,
    // Branches
    branchesRead: false,
    branchesWrite: false,
    branchesDelete: false,
    allowFulfilment: false,
    // Tags
    tagsRead: false,
    tagsWrite: false,
    tagsDelete: false,
    // Customers
    globalCustomersRead: false,
    globalCustomersWrite: false,
    globalCustomersDelete: false,
    // DataFields
    globalDataFieldsRead: false,
    globalDataFieldsWrite: false,
    globalDataFieldsDelete: false,
    // Tags
    globalTagsRead: false,
    globalTagsWrite: false,
    globalTagsDelete: false,
    // Services
    globalServicesRead: false,
    globalServicesWrite: false,
    globalServicesDelete: false,
    // Groups
    globalGroupsRead: false,
    globalGroupsWrite: false,
    globalGroupsDelete: false,
    // Resource categories
    globalResourceCategoriesRead: false,
    globalResourceCategoriesWrite: false,
    globalResourceCategoriesDelete: false,
    // Permission groups
    globalPermissionsGroupsRead: false,
    globalPermissionsGroupsWrite: false,
    globalPermissionsGroupsDelete: false,
    // Settings
    settingsRead: false,
    settingsWrite: false,
    // Apps
    appsRead: false,
    // Users
    usersRead: false,
    usersWrite: false,
    usersDelete: false,
    // Permission groups
    permissionsGroupsRead: false,
    permissionsGroupsWrite: false,
    permissionsGroupsDelete: false
  }

  const permissionsBranchesRead = isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.branchesRead))
    ? false
    : permissionTypePermissions.branchesRead !== undefined
      ? !!permissionTypePermissions.branchesRead
      : defaultCustomPermissions.branchesRead

  const newPermissionType = {
    // Account
    permissionsAccountRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.accountRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.accountRead))
        ? false
        : permissionTypePermissions.accountRead !== undefined
          ? !!permissionTypePermissions.accountRead
          : defaultCustomPermissions.accountRead
    },
    // Statistics
    permissionsStatisticsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.statisticsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.statisticsRead))
        ? false
        : permissionTypePermissions.statisticsRead !== undefined
          ? !!permissionTypePermissions.statisticsRead
          : defaultCustomPermissions.statisticsRead
    },
    // Branches
    permissionsBranchesRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.branchesRead),
      value: permissionsBranchesRead
    },
    permissionsBranchesWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.branchesWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.branchesWrite))
        ? false
        : permissionTypePermissions.branchesWrite !== undefined
          ? !!permissionTypePermissions.branchesWrite
          : defaultCustomPermissions.branchesWrite
    },
    permissionsBranchesDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.branchesDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.branchesDelete))
        ? false
        : defaultCustomPermissions.branchesDelete
    },
    permissionsAllowFulfilment: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.allowFulfilment),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.allowFulfilment))
        ? false
        : permissionTypePermissions.allowFulfilment !== undefined
          ? !!permissionTypePermissions.allowFulfilment
          : defaultCustomPermissions.allowFulfilment
    },
    branchAccess: {
      disabled: arePermissionChangeDisabled || (amICustom && (!enterprisePermissions.branchesRead || !enterprisePermissions.usersWrite)),
      value: branchAccess
    },
    branchesAccessWhitelist: {
      disabled: arePermissionChangeDisabled || (amICustom && (!enterprisePermissions.branchesRead || !enterprisePermissions.usersWrite)),
      options: branchesAccessWhitelistOptions,
      insufficient: amICustom && branchAccess === 'ALLOW' && branchesAccessWhitelist && branchesAccessWhitelist.length
        ? branchesAccessWhitelist.filter(branch => enterpriseBranchesAccessBlacklist ? enterpriseBranchesAccessBlacklist.includes(branch) : !enterpriseBranchesAccessWhitelist.includes(branch))
        : [],
      values: branchAccess === 'ALLOW' && branchesAccessWhitelist && branchesAccessWhitelist.length
        ? branchesAccessWhitelist.filter(branch => !(enterpriseBranchesAccessBlacklist || []).includes(branch))
        : permissionsBranchesRead && branchAccess === 'ALLOW' && (!amICustom || !isTypeAdd)
          ? ['all']
          : []
    },
    branchesAccessBlacklist: {
      disabled: amICustom,
      options: branchesAccessBlacklistOptions,
      insufficient: amICustom && branchAccess === 'DENY' && branchesAccessBlacklist && branchesAccessBlacklist.length
        ? branchesAccessBlacklist.filter(branch => enterpriseBranchesAccessBlacklist ? enterpriseBranchesAccessBlacklist.includes(branch) : !enterpriseBranchesAccessWhitelist.includes(branch))
        : amICustom && branchesAccessWhitelist && branchesAccessWhitelist.length === 0
          ? enterpriseBranchesAccessBlacklist || []
          : [],
      values: (branchesAccessBlacklist || []).filter(branch => !(enterpriseBranchesAccessBlacklist || []).includes(branch))
    },
    // Tags
    permissionsTagsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.tagsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.tagsRead))
        ? false
        : permissionTypePermissions.tagsRead !== undefined
          ? !!permissionTypePermissions.tagsRead
          : defaultCustomPermissions.tagsRead
    },
    permissionsTagsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.tagsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.tagsWrite))
        ? false
        : permissionTypePermissions.tagsWrite !== undefined
          ? !!permissionTypePermissions.tagsWrite
          : defaultCustomPermissions.tagsWrite
    },
    permissionsTagsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.tagsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.tagsDelete))
        ? false
        : permissionTypePermissions.tagsDelete !== undefined
          ? !!permissionTypePermissions.tagsDelete
          : defaultCustomPermissions.tagsDelete
    },
    // Customers
    permissionsGlobalCustomersRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalCustomersRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalCustomersRead))
        ? false
        : permissionTypePermissions.globalCustomersRead !== undefined
          ? !!permissionTypePermissions.globalCustomersRead
          : defaultCustomPermissions.globalCustomersRead
    },
    permissionsGlobalCustomersWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalCustomersWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalCustomersWrite))
        ? false
        : permissionTypePermissions.globalCustomersWrite !== undefined
          ? !!permissionTypePermissions.globalCustomersWrite
          : defaultCustomPermissions.globalCustomersWrite
    },
    permissionsGlobalCustomersDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalCustomersDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalCustomersDelete))
        ? false
        : permissionTypePermissions.globalCustomersDelete !== undefined
          ? !!permissionTypePermissions.globalCustomersDelete
          : defaultCustomPermissions.globalCustomersDelete
    },
    // DataFields
    permissionsGlobalDataFieldsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalDataFieldsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalDataFieldsRead))
        ? false
        : permissionTypePermissions.globalDataFieldsRead !== undefined
          ? !!permissionTypePermissions.globalDataFieldsRead
          : defaultCustomPermissions.globalDataFieldsRead
    },
    permissionsGlobalDataFieldsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalDataFieldsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalDataFieldsWrite))
        ? false
        : permissionTypePermissions.globalDataFieldsWrite !== undefined
          ? !!permissionTypePermissions.globalDataFieldsWrite
          : defaultCustomPermissions.globalDataFieldsWrite
    },
    permissionsGlobalDataFieldsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalDataFieldsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalDataFieldsDelete))
        ? false
        : permissionTypePermissions.globalDataFieldsDelete !== undefined
          ? !!permissionTypePermissions.globalDataFieldsDelete
          : defaultCustomPermissions.globalDataFieldsDelete
    },
    // Tags
    permissionsGlobalTagsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalTagsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalTagsRead))
        ? false
        : permissionTypePermissions.globalTagsRead !== undefined
          ? !!permissionTypePermissions.globalTagsRead
          : defaultCustomPermissions.globalTagsRead
    },
    permissionsGlobalTagsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalTagsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalTagsWrite))
        ? false
        : permissionTypePermissions.globalTagsWrite !== undefined
          ? !!permissionTypePermissions.globalTagsWrite
          : defaultCustomPermissions.globalTagsWrite
    },
    permissionsGlobalTagsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalTagsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalTagsDelete))
        ? false
        : permissionTypePermissions.globalTagsDelete !== undefined
          ? !!permissionTypePermissions.globalTagsDelete
          : defaultCustomPermissions.globalTagsDelete
    },
    // Services
    permissionsGlobalServicesRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalServicesRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalServicesRead))
        ? false
        : permissionTypePermissions.globalServicesRead !== undefined
          ? !!permissionTypePermissions.globalServicesRead
          : defaultCustomPermissions.globalServicesRead
    },
    permissionsGlobalServicesWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalServicesWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalServicesWrite))
        ? false
        : permissionTypePermissions.globalServicesWrite !== undefined
          ? !!permissionTypePermissions.globalServicesWrite
          : defaultCustomPermissions.globalServicesWrite
    },
    permissionsGlobalServicesDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalServicesDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalServicesDelete))
        ? false
        : permissionTypePermissions.globalServicesDelete !== undefined
          ? !!permissionTypePermissions.globalServicesDelete
          : defaultCustomPermissions.globalServicesDelete
    },
    // Groups
    permissionsGlobalGroupsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalGroupsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalGroupsRead))
        ? false
        : permissionTypePermissions.globalGroupsRead !== undefined
          ? !!permissionTypePermissions.globalGroupsRead
          : defaultCustomPermissions.globalGroupsRead
    },
    permissionsGlobalGroupsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalGroupsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalGroupsWrite))
        ? false
        : permissionTypePermissions.globalGroupsWrite !== undefined
          ? !!permissionTypePermissions.globalGroupsWrite
          : defaultCustomPermissions.globalGroupsWrite
    },
    permissionsGlobalGroupsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalGroupsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalGroupsDelete))
        ? false
        : permissionTypePermissions.globalGroupsDelete !== undefined
          ? !!permissionTypePermissions.globalGroupsDelete
          : defaultCustomPermissions.globalGroupsDelete
    },
    // Resource categories
    permissionsGlobalResourceCategoriesRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalResourceCategoriesRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalResourceCategoriesRead))
        ? false
        : permissionTypePermissions.globalResourceCategoriesRead !== undefined
          ? !!permissionTypePermissions.globalResourceCategoriesRead
          : defaultCustomPermissions.globalResourceCategoriesRead
    },
    permissionsGlobalResourceCategoriesWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalResourceCategoriesWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalResourceCategoriesWrite))
        ? false
        : permissionTypePermissions.globalResourceCategoriesWrite !== undefined
          ? !!permissionTypePermissions.globalResourceCategoriesWrite
          : defaultCustomPermissions.globalResourceCategoriesWrite
    },
    permissionsGlobalResourceCategoriesDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalResourceCategoriesDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalResourceCategoriesDelete))
        ? false
        : permissionTypePermissions.globalResourceCategoriesDelete !== undefined
          ? !!permissionTypePermissions.globalResourceCategoriesDelete
          : defaultCustomPermissions.globalResourceCategoriesDelete
    },
    // Global Permission groups
    permissionsGlobalPermissionsGroupsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalPermissionsGroupsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalPermissionsGroupsRead))
        ? false
        : permissionTypePermissions.globalPermissionsGroupsRead !== undefined
          ? !!permissionTypePermissions.globalPermissionsGroupsRead
          : defaultCustomPermissions.globalPermissionsGroupsRead
    },
    permissionsGlobalPermissionsGroupsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalPermissionsGroupsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalPermissionsGroupsWrite))
        ? false
        : permissionTypePermissions.globalPermissionsGroupsWrite !== undefined
          ? !!permissionTypePermissions.globalPermissionsGroupsWrite
          : defaultCustomPermissions.globalPermissionsGroupsWrite
    },
    permissionsGlobalPermissionsGroupsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalPermissionsGroupsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.globalPermissionsGroupsDelete))
        ? false
        : permissionTypePermissions.globalPermissionsGroupsDelete !== undefined
          ? !!permissionTypePermissions.globalPermissionsGroupsDelete
          : defaultCustomPermissions.globalPermissionsGroupsDelete
    },
    // Settings
    permissionsSettingsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.settingsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.settingsRead))
        ? false
        : permissionTypePermissions.settingsRead !== undefined
          ? !!permissionTypePermissions.settingsRead
          : defaultCustomPermissions.settingsRead
    },
    permissionsSettingsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.settingsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.settingsWrite))
        ? false
        : permissionTypePermissions.settingsWrite !== undefined
          ? !!permissionTypePermissions.settingsWrite
          : defaultCustomPermissions.settingsWrite
    },
    // Apps
    permissionsAppsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.appsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.appsRead))
        ? false
        : permissionTypePermissions.appsRead !== undefined
          ? !!permissionTypePermissions.appsRead
          : defaultCustomPermissions.appsRead
    },
    permissionsAppsWrite: {
      disabled: true,
      value: false
    },
    // Users
    permissionsUsersRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.usersRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.usersRead))
        ? false
        : permissionTypePermissions.usersRead !== undefined
          ? !!permissionTypePermissions.usersRead
          : defaultCustomPermissions.usersRead
    },
    permissionsUsersWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.usersWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.usersWrite))
        ? false
        : permissionTypePermissions.usersWrite !== undefined
          ? !!permissionTypePermissions.usersWrite
          : defaultCustomPermissions.usersWrite
    },
    permissionsUsersDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.usersDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.usersDelete))
        ? false
        : permissionTypePermissions.usersDelete !== undefined
          ? !!permissionTypePermissions.usersDelete
          : defaultCustomPermissions.usersDelete
    },
    // Permission groups
    permissionsPermissionsGroupsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.permissionsGroupsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.permissionsGroupsRead))
        ? false
        : permissionTypePermissions.permissionsGroupsRead !== undefined
          ? !!permissionTypePermissions.permissionsGroupsRead
          : defaultCustomPermissions.permissionsGroupsRead
    },
    permissionsPermissionsGroupsWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.permissionsGroupsWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.permissionsGroupsWrite))
        ? false
        : permissionTypePermissions.permissionsGroupsWrite !== undefined
          ? !!permissionTypePermissions.permissionsGroupsWrite
          : defaultCustomPermissions.permissionsGroupsWrite
    },
    permissionsPermissionsGroupsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !enterprisePermissions.permissionsGroupsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !enterprisePermissions.permissionsGroupsDelete))
        ? false
        : permissionTypePermissions.permissionsGroupsDelete !== undefined
          ? !!permissionTypePermissions.permissionsGroupsDelete
          : defaultCustomPermissions.permissionsGroupsDelete
    },
    isUpdateAllowedByIssuer,
    name: {
      value: name || ''
    },
    externalId: {
      value: externalId || ''
    },
    icon: {
      value: icon || 'user'
    },
    color: {
      value: color || '#82a774'
    }
  }

  if (id) {
    newPermissionType.id = id
  }

  return newPermissionType
}

export const userPermissionTypeSaveTransform = (permissionType, amICustom) => {
  permissionType = permissionType || {}
  const permissions = {}

  // Account
  permissions.accountRead = false
  if (permissionType.permissionsAccountRead.value) {
    permissions.accountRead = true
  }
  // Statistics
  permissions.statisticsRead = false
  if (permissionType.permissionsStatisticsRead.value) {
    permissions.statisticsRead = true
  }
  // Branches
  permissions.branchesRead = null
  permissions.branchesWrite = false
  permissions.branchesDelete = false
  permissions.allowFulfilment = null
  if (permissionType.permissionsBranchesRead.value) {
    permissions.branchesRead = true
    if (permissionType.permissionsBranchesWrite.value) {
      permissions.branchesWrite = true
      // if (permissionType.permissionsBranchesDelete.value) {
      //   permissions.branchesDelete = true
      // }
    }
    if (permissionType.permissionsAllowFulfilment.value) {
      permissions.allowFulfilment = true
    }
  }
  const { branchAccess } = permissionType
  const { value: branchAccessValue } = branchAccess || {}
  const isAllowedMode = branchAccessValue === 'ALLOW'
  if (permissionType.branchesAccessWhitelist.values.length && (permissionType.branchesAccessBlacklist.values.length === 0 || isAllowedMode) && permissionType.branchesAccessWhitelist.values[0] === 'all') {
    permissions.branchesAccessWhitelist = null
    permissions.branchesAccessBlacklist = []
  } else {
    permissions.branchesAccessWhitelist = isAllowedMode && permissionType.branchesAccessWhitelist.values.length ? permissionType.branchesAccessWhitelist.values : null
    if (isAllowedMode) permissions.branchesAccessBlacklist = null
    if (!isAllowedMode && !amICustom) permissions.branchesAccessBlacklist = permissionType.branchesAccessBlacklist.values.length ? permissionType.branchesAccessBlacklist.values : null
    if (!isAllowedMode && amICustom) {
      permissions.branchesAccessBlacklist = permissionType.branchesAccessBlacklist.values.length
        ? uniq([...(permissionType.branchesAccessBlacklist.values || []), ...(permissionType.branchesAccessBlacklist.insufficient || [])])
        : null
    }
  }
  // Tags
  permissions.tagsRead = null
  permissions.tagsWrite = false
  permissions.tagsDelete = false
  if (permissionType.permissionsTagsRead.value) {
    permissions.tagsRead = true
    if (permissionType.permissionsTagsWrite.value) {
      permissions.tagsWrite = true
      if (permissionType.permissionsTagsDelete.value) {
        permissions.tagsDelete = true
      }
    }
  }
  // Customers
  permissions.globalCustomersRead = null
  permissions.globalCustomersWrite = false
  permissions.globalCustomersDelete = false
  if (permissionType.permissionsGlobalCustomersRead.value) {
    permissions.globalCustomersRead = true
    if (permissionType.permissionsGlobalCustomersWrite.value) {
      permissions.globalCustomersWrite = true
      if (permissionType.permissionsGlobalCustomersDelete.value) {
        permissions.globalCustomersDelete = true
      }
    }
  }
  // Data Fields
  permissions.globalDataFieldsRead = null
  permissions.globalDataFieldsWrite = false
  permissions.globalDataFieldsDelete = false
  if (permissionType.permissionsGlobalDataFieldsRead.value) {
    permissions.globalDataFieldsRead = true
    if (permissionType.permissionsGlobalDataFieldsWrite.value) {
      permissions.globalDataFieldsWrite = true
      if (permissionType.permissionsGlobalDataFieldsDelete.value) {
        permissions.globalDataFieldsDelete = true
      }
    }
  }
  // Global Tags
  permissions.globalTagsRead = null
  permissions.globalTagsWrite = false
  permissions.globalTagsDelete = false
  if (permissionType.permissionsGlobalTagsRead.value) {
    permissions.globalTagsRead = true
    if (permissionType.permissionsGlobalTagsWrite.value) {
      permissions.globalTagsWrite = true
      if (permissionType.permissionsGlobalTagsDelete.value) {
        permissions.globalTagsDelete = true
      }
    }
  }
  // Services
  permissions.globalServicesRead = null
  permissions.globalServicesWrite = false
  permissions.globalServicesDelete = false
  if (permissionType.permissionsGlobalServicesRead.value) {
    permissions.globalServicesRead = true
    if (permissionType.permissionsGlobalServicesWrite.value) {
      permissions.globalServicesWrite = true
      if (permissionType.permissionsGlobalServicesDelete.value) {
        permissions.globalServicesDelete = true
      }
    }
  }
  // Groups
  permissions.globalGroupsRead = null
  permissions.globalGroupsWrite = false
  permissions.globalGroupsDelete = false
  if (permissionType.permissionsGlobalGroupsRead.value) {
    permissions.globalGroupsRead = true
    if (permissionType.permissionsGlobalGroupsWrite.value) {
      permissions.globalGroupsWrite = true
      if (permissionType.permissionsGlobalGroupsDelete.value) {
        permissions.globalGroupsDelete = true
      }
    }
  }
  // Resource Categories
  permissions.globalResourceCategoriesRead = null
  permissions.globalResourceCategoriesWrite = false
  permissions.globalResourceCategoriesDelete = false
  if (permissionType.permissionsGlobalResourceCategoriesRead.value) {
    permissions.globalResourceCategoriesRead = true
    if (permissionType.permissionsGlobalResourceCategoriesWrite.value) {
      permissions.globalResourceCategoriesWrite = true
      if (permissionType.permissionsGlobalResourceCategoriesDelete.value) {
        permissions.globalResourceCategoriesDelete = true
      }
    }
  }
  // Global Permission groups
  permissions.globalPermissionsGroupsRead = null
  permissions.globalPermissionsGroupsWrite = false
  permissions.globalPermissionsGroupsDelete = false
  if (permissionType.permissionsGlobalPermissionsGroupsRead.value) {
    permissions.globalPermissionsGroupsRead = true
    if (permissionType.permissionsGlobalPermissionsGroupsWrite.value) {
      permissions.globalPermissionsGroupsWrite = true
      if (permissionType.permissionsGlobalPermissionsGroupsDelete.value) {
        permissions.globalPermissionsGroupsDelete = true
      }
    }
  }
  // Settings
  permissions.settingsRead = null
  permissions.settingsWrite = false
  if (permissionType.permissionsSettingsRead.value) {
    permissions.settingsRead = true
    if (permissionType.permissionsSettingsWrite.value) {
      permissions.settingsWrite = true
    }
  }
  // Apps
  permissions.appsRead = false
  if (permissionType.permissionsAppsRead.value) {
    permissions.appsRead = true
  }
  // Users
  permissions.usersRead = null
  permissions.usersWrite = false
  permissions.usersDelete = false
  if (permissionType.permissionsUsersRead.value) {
    permissions.usersRead = true
    if (permissionType.permissionsUsersWrite.value) {
      permissions.usersWrite = true
      if (permissionType.permissionsUsersDelete.value) {
        permissions.usersDelete = true
      }
    }
  }
  // Permission groups
  permissions.permissionsGroupsRead = null
  permissions.permissionsGroupsWrite = false
  permissions.permissionsGroupsDelete = false
  if (permissionType.permissionsPermissionsGroupsRead.value) {
    permissions.permissionsGroupsRead = true
    if (permissionType.permissionsPermissionsGroupsWrite.value) {
      permissions.permissionsGroupsWrite = true
      if (permissionType.permissionsPermissionsGroupsDelete.value) {
        permissions.permissionsGroupsDelete = true
      }
    }
  }
  const result = {
    name: permissionType.name.value,
    icon: permissionType.icon.value,
    externalId: permissionType.externalId.value,
    color: permissionType.color.value,
    permissions
  }

  if (permissionType.id) {
    result.id = permissionType.id
  }

  if (!permissionType.isUpdateAllowedByIssuer) {
    delete result.permissions
  }

  return result
}

export const userPermissionTypeFormServerErrorsTransform = (error) => {
  const errors = []
  errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  return errors
}

// Validators
export const userPermissionTypeFormValidate = (permissionType, externalIds) => {
  if (!permissionType) return

  const rules = [
    { 'name.value': ['required'] }
  ]

  const messages = {
    'name': {
      'required': 'errors.required'
    }
  }

  const replaces = {
    'name': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      }
    }
  }

  const errors = validator(permissionType, rules, messages, replaces)
  const { permissionsBranchesRead, branchAccess, branchesAccessWhitelist, branchesAccessBlacklist } = permissionType
  if (permissionsBranchesRead && permissionsBranchesRead.value && branchAccess.value === 'ALLOW' && !branchesAccessWhitelist.values.length) {
    errors.push({ key: 'branchesAccessWhitelist', value: 'errors.branchesAccessWhitelist.required' })
  }
  if (permissionsBranchesRead && permissionsBranchesRead.value && branchAccess.value === 'DENY' && !branchesAccessBlacklist.values.length) {
    errors.push({ key: 'branchesAccessBlacklist', value: 'errors.branchesAccessBlacklist.required' })
  }

  // external Id
  const externalIdValue = permissionType.externalId && permissionType.externalId.value
  if (externalIds.includes(externalIdValue)) {
    errors.push({
      key: 'externalId',
      value: 'errors.externalId.exists'
    })
  }

  return errors
}
