import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Input,
  Error,
  Row,
  Col,
  MultipleSelect,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t,
  Alert
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

import './CustomerFieldCategoryForm.css'

const CustomerFieldCategoryForm = props => {
  let {
    title,
    onSubmit,
    isDefault,
    cancelLink,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-customer-fields__category-form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='customerFieldCategory'>
            {(showExternalIds &&
              <FormSection>
                <Title size='m' label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                      mandatory
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection>
              <Title size='m' label={t('global.name')} icon='sliders-h' />
              <Input
                name='name'
                label={t('global.name.label')}
                hintText={t('customerFields.form.category.fieldName.hint')}
                mandatory
                limit={50}
              />
            </FormSection>
            {(!allowOnlyGlobalCustomers &&
              <FormSection>
                <Title size='m' label={t('global.location')} icon='building' />
                <Row>
                  <Col>
                    <HoverPopup disabled={!isDefault}>
                      <HoverPopupContent position='top' autoSize>
                        {t('branches.form.tooltip.defaultCategory.cannotEditLocations')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <MultipleSelect
                          name='location'
                          label={t('global.select.label')}
                          searchable
                          hasAll={hasAll}
                          disableSelected={type === 'edit'}
                          allLabel={t('global.allLocations')}
                        />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </Col>
                </Row>
                {(type === 'edit' &&
                  <Alert theme='alert'>
                    <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
                  </Alert>
                )}
              </FormSection>
            )}
            <FormButtons>
              <Error name='globalErrors' />
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomerFieldCategoryForm
