import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { Chart } from '../../Common'

const ChartWrapper = props => {
  const {
    options,
    form,
    stats,
    extractKey,
    data,
    barsKey
  } = props
  const { type } = form || {}
  const { value: formValue } = type || {}
  const { syncId, dataKey } = options || {}
  useEffect(() => {
    // hadler to get specific statistic gets called here by passing syncId
    handlers.dashboardStatisticsTypeGet(syncId)
    handlers.dashboardStatisticsFormGet(syncId)
  }, [syncId])

  return (
    <Chart
      data={data || stats.map((item) => ({ [extractKey]: item[extractKey], [dataKey || barsKey]: item.value }))}
      formValue={formValue}
      form={form}
      {...props}
    />
  )
}

const maps = (state, props) => {
  const { options } = props
  const { syncId } = options || {}
  return {
    form: (state.forms && state.forms[syncId]) || {},
    stats: (state.dashboard && state.dashboard.statistics && state.dashboard.statistics[syncId]) || []
  }
}

export default connect(maps)(ChartWrapper)
