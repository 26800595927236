const reducer = {}

reducer.initialState = {
  isActive: false,
  content: '',
  params: {}
}

reducer.handlers = (dispatch, actions, handlers) => ({
  modalSet: (content, params) => {
    dispatch(actions.MODAL_SET, { content, params })
    handlers.overlaySet({ position: 'top' })
  },
  modalHide: () => dispatch(actions.MODAL_HIDE)
})

reducer.MODAL_SET = (state, { content, params }) => ({
  ...state,
  isActive: true,
  content,
  params
})

reducer.MODAL_HIDE = state => ({
  ...state,
  isActive: false,
  content: null,
  params: {}
})

export default reducer
