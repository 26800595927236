import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, globalActions } from '../../Store'
import { groupCustomerFieldsByCategory } from '../../Utils'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { CustomerPreview } from '../../Beauties'

class CustomerPreviewWrapper extends Component {
  constructor (props, context) {
    super(props)
  }

  componentDidMount () {
    const { id } = this.props
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }
    handlers.customerPreviewGet(id)
  }

  componentDidUpdate (prevProps, prevState) {
    let {
      id,
      account,
      customers,
      pending
    } = this.props
    customers = customers || []
    pending = !!pending
    account = account || {}
    const customer = customers.find(item => item.id === id) || {}
    let { isAdmin, enterprisePermissions } = account || {}
    isAdmin = !!isAdmin
    enterprisePermissions = enterprisePermissions || {}
    let { globalCustomersRead } = enterprisePermissions || {}
    globalCustomersRead = isAdmin || !!globalCustomersRead

    if (!globalCustomersRead || (!pending && !customer.id)) handlers.navigateToPath('/customers')
  }

  render () {
    let {
      customer,
      categories,
      tags,
      pending,
      hash,
      timezones,
      message,
      enterpriseId,
      account,
      allowOnlyGlobalCustomers
    } = this.props
    account = account || {}
    customer = customer || {}
    categories = categories || []
    tags = tags || []
    pending = !!pending
    timezones = timezones || []
    message = message || null
    enterpriseId = enterpriseId || null
    allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
    const filterByExternalId = ['firstName', 'lastName', 'avatar']
    const filterByType = ['FILE']
    const filteredTags = tags.filter(item => (customer.tagIds || []).includes(item.id))
    const customerFieldsWithValues = globalActions.prepareCustomerFieldsWithValues(customer.fields)
    const isAvatarActive = (customerFieldsWithValues.find(field => field.defaultId === 'avatar') || {}).isActive
    const customerFields = customerFieldsWithValues
      .filter(item =>
        item.hasOverwrite &&
        item.value !== null &&
        item.value !== '' &&
        !filterByExternalId.includes(item.defaultId) &&
        !filterByType.includes(item.type)
      ) || []
    const groupedFields = groupCustomerFieldsByCategory(customerFields, categories)
    const resultCustomer = { ...customer }
    resultCustomer.fields = customerFields
    resultCustomer.groupedFields = groupedFields
    resultCustomer.tags = filteredTags
    resultCustomer.isAvatarActive = isAvatarActive
    let { isAdmin, enterprisePermissions } = account || {}
    isAdmin = !!isAdmin
    enterprisePermissions = enterprisePermissions || {}
    let { globalCustomersRead, globalCustomersWrite, globalCustomersDelete } = enterprisePermissions || {}
    globalCustomersRead = isAdmin || !!globalCustomersRead
    globalCustomersWrite = isAdmin || !!globalCustomersWrite
    globalCustomersDelete = isAdmin || !!globalCustomersDelete

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <CustomerPreview
            customer={resultCustomer}
            customersRead={globalCustomersRead}
            customersWrite={globalCustomersWrite}
            customersDelete={globalCustomersDelete}
            hash={hash}
            timezones={timezones}
            message={message}
            enterpriseId={enterpriseId}
            allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
          />
        )}
      </>
    )
  }
}

CustomerPreviewWrapper.propTypes = {
  id: PropTypes.string,
  hash: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = (state, props) => ({
  id: (state.router.data && state.router.data.id),
  hash: state.router.hash,
  tags: state.companyTags.list,
  customer: state.customers.selected,
  customers: state.customers.list,
  categories: state.customerFields.categoriesList,
  pending: state.customers.pendingPreview,
  timezones: state.staticData.timezones,
  message: state.customers.messagePreview,
  enterpriseId: (state.account.enterprise && state.account.enterprise.id),
  account: state.account,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default connect(maps)(CustomerPreviewWrapper)
