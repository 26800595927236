import React, { useState, useEffect, memo } from 'react'
import { connect, handlers, globalActions } from '../../Store'
import { CompanyTags, CompanyTagsMock } from '../../Beauties'
import { Loader } from '../../Common'

const CompanyTagsListWrapper = props => {
  useEffect(() => {
    globalActions.populateCompanyTags()
    handlers.companyTagsToDeleteGet()
  }, [])

  let {
    tags,
    enterpriseId,
    pending,
    hash,
    account
  } = props
  pending = !!pending
  account = account || {}
  tags = tags || []
  enterpriseId = enterpriseId || null
  const [areExpanded] = useState(true)
  // saved for when reorder is implemented
  // const isDragAndDropMode = !!(route === 'companyTagsReorder')
  // const onDragStart = res => { }
  // const onDragUpdate = res => { }
  // const onDragEnd = ({ type, id, oldIndex, newIndex }) => {
  //   handlers.companyTagsListOrderChange({ id, oldIndex, newIndex })
  // }
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalTagsRead, globalTagsWrite } = enterprisePermissions || {}
  globalTagsRead = !!isAdmin || !!globalTagsRead
  globalTagsWrite = !!isAdmin || !!globalTagsWrite

  return globalTagsRead
    ? (
      <>
        <Loader active={pending} />
        {(!pending &&
          <CompanyTags
            tags={tags}
            hash={hash}
            // isDragAndDropMode={isDragAndDropMode}
            // onDragStart={onDragStart}
            // onDragUpdate={onDragUpdate}
            // onDragEnd={onDragEnd}
            areExpanded={areExpanded}
            enterpriseId={enterpriseId}
            globalTagsWrite={globalTagsWrite}
          />
        )}
      </>
      )
    : <CompanyTagsMock />
}

const maps = state => ({
  hash: state.router.hash,
  enterpriseId: (state.account.enterprise && state.account.enterprise.id),
  tags: state.companyTags.list,
  pending: state.companyTags.pendingList,
  account: state.account
})

export default memo(connect(maps)(CompanyTagsListWrapper))
