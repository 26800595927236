import React from 'react'
import {
  FormSection,
  Title,
  Row,
  Col,
  Input,
  ColorSelect,
  Textarea,
  t
} from '../../../Common'

const ServiceFormBasicDetails = props => {
  const { hideColorSelector, ignoreTopOffset } = props || {}

  return (
    <FormSection>
      <Title size='m' isCompact label={t('global.basicDetails')} icon='sliders-h' ignoreTopOffset={ignoreTopOffset} />
      <Row>
        <Col>
          <ColorSelect name='color' hide={hideColorSelector}>
            <Input
              name='name'
              label={t('global.name.label')}
              hintText={t('branches.form.group.edit.name.hint')}
              mandatory
            />
          </ColorSelect>
        </Col>
      </Row>
      <Row>
        <Col>
          <Textarea
            useEditor
            name='description'
            label={t('global.description.label')}
            limit={400}
          />
        </Col>
      </Row>
    </FormSection>
  )
}

export default ServiceFormBasicDetails
