import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { CourseForm } from '../../Beauties'

const CourseEditWrapper = props => {
  let {
    showExternalIds,
    pending,
    form,
    hash,
    id,
    isAdmin,
    branchesAccessBlacklist
  } = props
  id = id || null
  form = form || {}
  showExternalIds = !!showExternalIds

  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.courseFormGet(id)
  }, [initial])

  const onSubmit = cb => {
    handlers.courseFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CourseForm
          id={id}
          title={t('servicesGroups.edit.group.heading')}
          type='edit'
          cancelLink={`/management/courses/${id}`}
          onSubmit={onSubmit}
          showExternalIds={showExternalIds}
          hash={hash}
          isAdmin={isAdmin}
          branchesAccessBlacklist={branchesAccessBlacklist}
          location={form.location && form.location.values}
        />
      )}
    </>
  )
}

CourseEditWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.id,
  pending: state.courses.pendingForm,
  showExternalIds: state.company.settings.showExternalIds,
  form: state.forms.courses,
  hash: state.router.hash,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default memo(connect(maps)(CourseEditWrapper))
