import React from 'react'
import {
  FormSection,
  Title,
  Row,
  Col,
  Checkbox,
  FontAwesome5,
  Alert,
  DurationInput,
  FormText,
  BookingIntervalsInput,
  BorderedBox,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'
import { convertMinutesToDaysHoursMinutes, hex2rgba, dangerousHTML } from '../../../Utils'

const CourseFormDuration = props => {
  const {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    splitDurationInIntervals,
    color
  } = props

  const translations = {
    minutes: t('global.minutes.short'),
    hours: t('global.hours.short'),
    days: t('global.days.short')
  }
  const bufferTimesStyles = { background: hex2rgba(color, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color, 1) }

  return (
    <>
      <FormSection>
        <Title size='m' isCompact label={t('servicesGroups.form.section.duration.heading')} icon='hourglass-half' />
        {(!splitDurationInIntervals &&
          <Row>
            <Col>
              <FormText>
                {t('global.duration')}
                <div className='ta-services-duration-tooltip'>
                  <PopupTriggerWrapper name='service-duration-tooltip' position='left'>
                    <FontAwesome5 type='r' className='ta-service-duration__question-circle-icon' icon='question-circle' />
                  </PopupTriggerWrapper>
                </div>
              </FormText>
              <DurationInput name='duration' noDays={!showDurationDaySelector} />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Checkbox
              name='splitDurationInIntervals'
              label={t('servicesGroups.form.section.duration.checkboxSplitBooking.description')}
              theme='switch'
              inline
            />
            <PopupTriggerWrapper name='courses-split-intervals' position='right'>
              <FontAwesome5 type='r' className='ta-service-duration__question-circle-icon' icon='question-circle' />
            </PopupTriggerWrapper>
          </Col>
        </Row>
        {(splitDurationInIntervals &&
          <BorderedBox>
            <Row>
              <Col>
                <BookingIntervalsInput
                  name='intervals'
                  limit={7}
                  color={color}
                  noDays={!showDurationDaySelector}
                />
              </Col>
            </Row>
          </BorderedBox>
        )}
        <Row>
          <Col>
            <Checkbox name='showDurationBeforeAndAfter' label={t('servicesGroups.form.section.duration.checkboxPrepFollowUpTime.description')} theme='switch' inline />
            <PopupTriggerWrapper name='courses-duration-before-and-after' position='right'>
              <FontAwesome5 type='r' className='ta-service-duration__question-circle-icon' icon='question-circle' />
            </PopupTriggerWrapper>
          </Col>
        </Row>
        {(showDurationBeforeAndAfter &&
          <BorderedBox>
            <Row>
              <Col>
                <div className='ta-buffer-times'>
                  <div className='ta-buffer-times__box' style={bufferTimesStyles}>
                    <div className='ta-buffer-times__box__content' style={bufferTimesContentContentStyles} />
                  </div>
                  <Row>
                    <Col>
                      <FormText>{t('global.duration.preparationTime')}</FormText>
                      <DurationInput name='durationBefore' noDays={!showDurationDaySelector} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormText>{t('global.duration.followUpTime')}</FormText>
                      <DurationInput name='durationAfter' noDays={!showDurationDaySelector} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {(!!(durationBefore || durationAfter) &&
              <Alert noOffset theme='alert'>
                {(durationBefore &&
                  <div>
                    {t('servicesGroups.form.section.duration.notePrepDuration.text')}:<br />
                    <strong>{convertMinutesToDaysHoursMinutes(durationBefore, translations)}</strong>
                  </div>
                )}
                {(durationAfter &&
                  <div>
                    {dangerousHTML(t('servicesGroups.form.section.duration.noteFollowDuration.text'))}:<br />
                    <strong>{convertMinutesToDaysHoursMinutes(durationAfter, translations)}</strong>
                  </div>
                )}
                <div>{dangerousHTML(t('servicesGroups.form.section.duration.noteDuration.text'))}</div>
              </Alert>
            )}
          </BorderedBox>
        )}
      </FormSection>
    </>
  )
}

export default CourseFormDuration
