export default (price = '') => {
  let newPrice
  price = price.toString().replace(/\s/g, '')

  if (price.indexOf('.') !== -1) {
    newPrice = price.replace(/,/g, '')
  } else if ((price.match(/,/g) || []).length === 1) {
    newPrice = price.replace(/,/g, '.')
  } else {
    newPrice = price.replace(/,/g, '')
  }

  return parseFloat(newPrice)
}
