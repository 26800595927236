import React, { memo } from 'react'
import {
  Button,
  Title,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  AccordionBody,
  AccordionTitle,
  Accordion,
  DnDDroppable,
  Form,
  Error,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { CoursesList } from '../../Beauties'
import { truncateText, dangerousHTML } from '../../Utils'

const CoursesCategoryWithAccordion = props => {
  const {
    category,
    pending,
    courses = [],
    index,
    search,
    enterpriseId,
    globalGroupsRead,
    globalGroupsWrite,
    globalGroupsDelete
  } = props
  const classNames = ['ta-courses__category', 'has-buttons']
  if (index === 0) classNames.push('first')
  if (pending) classNames.push('pending')
  if (category.isDefault) classNames.push('default')
  if (category.isUpdated) classNames.push('updated')
  if (category.isDeleted) classNames.push('deleted')
  if (!globalGroupsWrite) classNames.push('no-write-permissions')
  if (!globalGroupsDelete) classNames.push('no-delete-permissions')
  const isDefaultCategory = category.isDefault
  const locallyChangedBranches = (category.locallyUpdatedBranches && Object.keys(category.locallyUpdatedBranches).length) || null
  const categoryName = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name
  const categoryNameLimit = isDefaultCategory
    ? locallyChangedBranches ? 15 : 28
    : locallyChangedBranches ? 17 : 28
  const hasCourses = !!(courses?.length)

  const categoryExtraText = () => {
    return (
      <>
        {isDefaultCategory && <span className='ta-default-category__text'>{t('global.defaultCategory.hint')}</span>}
        {(locallyChangedBranches &&
          <HoverPopup>
            <HoverPopupContent position={index === 0 ? 'bottom' : 'top'}>
              <p className='ta-global-label__text'>
                {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
              </p>
            </HoverPopupContent>
            <div onClick={e => e.stopPropagation()}>
              <HoverPopupTrigger>
                <Button as='link' to={`/management/courses/categories/${category.id}@@changedLocally`}>
                  <div className='ta-customer-fields__category-type global edited global-label'>
                    <FontAwesome5 icon='pencil' type='s' />
                    {t('global.changedLocally')}
                  </div>
                </Button>
              </HoverPopupTrigger>
            </div>

          </HoverPopup>
        )}
      </>
    )
  }

  return (
    <div key={category.id} className={classNames.join(' ')}>
      <Accordion inactive={isDefaultCategory} focusOnHover>
        <AccordionTitle>
          <Title
            size='m'
            isCompact
            label={truncateText(categoryName, categoryNameLimit, true)}
            extraText={categoryExtraText()}
            icon='folder'
          >
            {(globalGroupsRead &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('couse.preview.category.openCategory')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' isSecondary to={`/management/courses/categories/${category.id}`} icon='folder' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
            {(globalGroupsWrite &&
              <>
                {(globalGroupsDelete && !isDefaultCategory &&
                  <PopupTriggerWrapper
                    name='course-category-delete'
                    position={index === 0 ? 'bottom' : 'top'}
                    id={category.id}
                    disable={hasCourses}
                  >
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {(hasCourses
                          ? t('global.category.delete.forbidden')
                          : t('global.category.delete')
                        )}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button
                          as='link'
                          isSecondary
                          didsabled={hasCourses}
                          external
                          icon='trash-alt'
                        />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('buttons.editCategory.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button
                      as='link'
                      to={`/management/courses/categories/${category.id}/edit`}
                      isSecondary
                      icon='pencil'
                    />
                  </HoverPopupTrigger>
                </HoverPopup>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('servicesGroups.list.buttonAddGroup.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button
                      as='link'
                      to={`/management/courses/add?c=${category.id}`}
                      isPrimary
                      icon='plus'
                    />
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </Title>
        </AccordionTitle>
        <AccordionBody>
          <DnDDroppable id={category.id} type='course'>
            <CoursesList
              search={search}
              courses={courses}
              isDragAndDropMode={false}
              areCategoriesExpanded={false}
              enterpriseId={enterpriseId}
              categoryIndex={category.orderIndex}
            />
            <Form name={`courseCategory${category.id}`}>
              <Error name='globalErrors' />
            </Form>
          </DnDDroppable>
        </AccordionBody>
      </Accordion>
    </div>
  )
}

export default memo(CoursesCategoryWithAccordion)
