import React from 'react'
import {
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  ColorPreview,
  t
} from '../../../Common'

const CompanyTagPreviewDetails = props => {
  const {
    id,
    name,
    isForEvents,
    isForCustomers,
    color,
    externalId
  } = props

  return (
    <div className='ta-company-tags__preview__details'>
      <IconListItem icon='id-badge' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.tagID')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>{id}</IconListItemBody>
      </IconListItem>
      {(externalId &&
        <IconListItem icon='fingerprint'>
          <IconListItemTitle width='130px'>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody offset='130px'>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      <IconListItem icon='tag' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.name.label')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>{name}</IconListItemBody>
      </IconListItem>
      <IconListItem icon='calendar-check' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.appointmentTag')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>{t(isForEvents ? 'global.yes' : 'global.no')}</IconListItemBody>
      </IconListItem>
      <IconListItem icon='user-tag' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.customerTag')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>{t(isForCustomers ? 'global.yes' : 'global.no')}</IconListItemBody>
      </IconListItem>
      <IconListItem icon='eye-dropper' iconType='regular'>
        <IconListItemTitle width='130px'>{t('global.color')}:</IconListItemTitle>
        <IconListItemBody offset='130px'>
          <ColorPreview color={color} pattern='dotted' />
        </IconListItemBody>
      </IconListItem>

    </div>
  )
}

export default CompanyTagPreviewDetails
