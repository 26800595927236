import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { CoursePreviewDetails } from '../../Beauties'

const CoursePreviewDetailsWrapper = props => {
  const { course = {}, category = {}, plan } = props

  return (
    <CoursePreviewDetails {...course} category={category} plan={plan} />
  )
}

CoursePreviewDetailsWrapper.propTypes = {
  course: PropTypes.object.isRequired,
  category: PropTypes.object
}

const maps = (state, props) => ({
  category: (props.course && state.courses.categoriesList.find(category => category.id === props.course.categoryId)) || {},
  plan: (state.company && state.company.plan) || 'CLASSIC'
})

export default connect(maps)(CoursePreviewDetailsWrapper)
