import {
  createSelector
} from '../../../Utils'
import { selectors } from '../../../Store'

// Resources
export const resourcesSelector = state => state.resources

export const resourcesListSelector = createSelector(
  resourcesSelector,
  resources => (resources || {}).list
)

// System messages
export const notificationsSelector = state => state.notifications

export const notificationsMessageListSelector = createSelector(
  notificationsSelector,
  notifications => (notifications || {}).messageList
)

export const systemMessagesListSelector = createSelector(
  notificationsSelector,
  notifications => (notifications || {}).systemMessages
)

export const systemMessageSelector = createSelector(
  systemMessagesListSelector,
  (_, props) => props.id,
  (list, id) => (list || []).find(item => item.id === id)
)

export const systemMessageSelectorr = createSelector(
  (state, props) => systemMessageSelector(state, { id: props.systemMessageId }),
  (state) => selectors.resourcesListSelector(state),
  (systemMessage, resourcesList) => {
    systemMessage = systemMessage || {}
    resourcesList = resourcesList || []
    const accessUserData = resourcesList?.find((resource) => resource?.id === systemMessage?.accessUserId) || {}
    const { avatarUrl: requesterAvatarUrl } = accessUserData
    return {
      ...systemMessage,
      requesterAvatarUrl,
      requesterName: systemMessage?.timifyUserName,
      providerName: systemMessage?.accessUserName
    }
  }
)
