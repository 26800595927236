import {
  fileDownloadFields
} from './fields'

// Queries

export const downloadEnterpriseFile = `
  query downloadEnterpriseFile($key: String, $url: String) {
    downloadEnterpriseFile(key: $key, url: $url) ${fileDownloadFields}
  }
`
