import { DEFAULT_LOCALE } from '../../../Settings'

const reducer = {}

reducer.initialState = {
  isReady: false,
  pendingLanguage: true
}

reducer.handlers = (dispatch, actions) => ({
  appReady: () => dispatch(actions.APP_READY),
  languageChange: (locale) => dispatch(actions.LANGUAGE_CHANGE, locale),
  languageLoaded: (locale) => dispatch(actions.LANGUAGE_LOADED, locale),
  windowResize: (w) => dispatch(actions.WINDOW_RESIZE, w && {
    windowHeight: w.innerHeight,
    windowWidth: w.innerWidth
  }),
  appFileDownload: ({ url, accountId }) => dispatch(actions.APP_FILE_DOWNLOAD, { url, accountId }),
  appToastrShow: ({ title, message }) => dispatch(actions.APP_TOASTR_SHOW, { title, message }),
  appToastrHide: () => dispatch(actions.APP_TOASTR_HIDE)
})

reducer.APP_READY = state => ({
  ...state,
  isReady: true
})

reducer.LANGUAGE_CHANGE = state => ({
  ...state,
  pendingLanguage: true
})

reducer.LANGUAGE_LOADED = (state, locale = DEFAULT_LOCALE) => ({
  ...state,
  locale,
  pendingLanguage: false
})

reducer.WINDOW_RESIZE = (state, payload) => ({
  ...state,
  firstTrigger: !payload,
  windowHeight: payload && payload.windowHeight,
  windowWidth: payload && payload.windowWidth
})

reducer.APP_FILE_DOWNLOAD = state => state

reducer.APP_TOASTR_SHOW = (state, { message, title }) => ({
  ...state,
  toastrMessage: message,
  toastrTitle: title,
  isToastrVisible: true
})

reducer.APP_TOASTR_HIDE = state => ({
  ...state,
  toastrMessage: '',
  toastrTitle: '',
  isToastrVisible: false
})

export default reducer
