import React from 'react'
import { FontAwesome5 } from '../../Common'
import './Badge.css'

const Badge = props => {
  const { text = '', color = '#fff', background = 'none', icon = '', iconType = 's', className = '', onclick = null } = props
  const classNames = ['ta-badge']
  if (className) classNames.push(className)

  return (
    <div onClick={onclick} className={classNames.join(' ')} style={{ background, color, cursor: onclick ? 'pointer' : 'default' }}>
      {icon && <FontAwesome5 icon={icon} type={iconType} />}
      {text}
    </div>
  )
}

export default Badge
