import React from 'react'

const StatisticsCompositeChildHeader = props => {
  let { text } = props
  text = text || ''
  const classNames = ['ta-statistics-composite-child__header']

  return <div className={classNames.join(' ')}>
    <p className='ta-statistics-composite-child__header__text'>{text}</p>
  </div>
}

export default StatisticsCompositeChildHeader
