import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCustomersHelpBox = props => {
  return (
    <div className='ta-popup__customers-help-box'>
      <Alert noOffset noBorder label={t('popup.customers.help.label')} theme='alert'>
        <div>
          {dangerousHTML(t('popup.customers.help.message'))}
        </div>
      </Alert>
    </div>
  )
}

export default PopupCustomersHelpBox
