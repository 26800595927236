import React from 'react'
import PropTypes from 'prop-types'
import { Button, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCourseCategoryDelete = props => {
  const { approve, reject, defaultCategoryName } = props

  return (
    <div className='ta-popup__resources-delete'>
      <div className='ta-popup__title'>
        {dangerousHTML(t('global.category.delete.text'))}
      </div>
      <div className='ta-popup__text'>
        {dangerousHTML(t('servicesGroups.list.group.deleteCategoryNote.text', [{ key: 'DEFAULT_CATEGORY', value: defaultCategoryName() }]))}
      </div>
      <div className='ta-popup__buttons'>
        <Button as='link' isSecondary onClick={reject}>{t('buttons.cancel.label')}</Button>
        <Button as='link' isPrimary onClick={approve}>{t('global.delete')}</Button>
      </div>
    </div>
  )
}

PopupCourseCategoryDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupCourseCategoryDelete
