import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Blur,
  t
} from '../../../Common'
import {
  PopupTriggerWrapper,
  TagsPreviewDetailsWrapper
} from '../../../Beasts'

import './TagsPreview.css'

const TagPreview = props => {
  let {
    tag,
    hash,
    tagsRead,
    tagsWrite,
    tagsDelete
  } = props
  tag = tag || {}
  hash = hash || ''
  const availableTabs = ['details']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]

  return (
    <div className='ta-tags__preview'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={tagsRead ? tag.name || '' : t('global.accessDenied')}>
            {(tagsWrite &&
              <>
                {(tagsDelete &&
                  <PopupTriggerWrapper name='tags-delete' position='bottom' id={tag.id}>
                    <Button isTertiary as='link' className='ta-btn ta-btn-delete' external icon='trash-alt' />
                  </PopupTriggerWrapper>
                )}
                <Button as='link' to={`/branches/tags/${tag.id}/edit`} isPrimary icon='pencil' />
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={tagsRead ? 'inactive' : ''} active={!tagsRead} icon='ban' label={t('global.accessDenied')}>
            {(tag.id &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='tag' label={t('global.details')} />
                </SimpleTabsHeader>
                <SimpleTabsBody className={!tagsRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <TagsPreviewDetailsWrapper tag={tag} />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

TagPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default TagPreview
