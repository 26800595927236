export const companyPopulateTransform = company => {
  let { settings } = company || {}
  settings = settings || {}
  const result = {
    ...company,
    settings: {
      ...settings,
      hasNotifyOnResources: [null, undefined, 'SELECTED'].includes(settings.hasNotifyOnResources)
        ? 'default'
        : settings.hasNotifyOnResources === 'TRUE'
          ? 'selected'
          : 'deselected',
      hasNotifyOnCustomers: [null, undefined, 'SELECTED'].includes(settings.hasNotifyOnCustomers)
        ? 'default'
        : settings.hasNotifyOnCustomers === 'TRUE'
          ? 'selected'
          : 'deselected'
    }
  }
  return result
}
