import { createSelector } from '../../../Utils'

export const accountSelector = state => state.account

export const accountFieldSelector = createSelector(
  accountSelector,
  (_, props) => props.field,
  (account, field) => (account || {})[field]
)

// could use accountFieldSelector for isAdmin and permissions
export const accountPermissionSelector = createSelector(
  accountSelector,
  (_, props) => props.permission,
  (account, permission) => {
    let { isAdmin, permissions } = account || {}
    isAdmin = !!isAdmin
    permissions = permissions || {}
    return {
      [`${[permission]}Read`]: isAdmin || permissions[`${permission}Read`],
      [`${[permission]}Write`]: isAdmin || permissions[`${permission}Write`],
      [`${[permission]}Delete`]: isAdmin || permissions[`${permission}Delete`]
    }
  }
)
