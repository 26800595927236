import React, { useEffect } from 'react'
import { CustomersToDelete } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'

const CustomersToDeleteWrapper = props => {
  let { customersToDelete, branches, pending, account } = props
  customersToDelete = customersToDelete || []
  branches = branches || []
  pending = !!pending
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalCustomersRead, globalCustomersWrite } = enterprisePermissions || {}
  globalCustomersRead = !!isAdmin || !!globalCustomersRead
  globalCustomersWrite = !!isAdmin || !!globalCustomersWrite
  useEffect(() => {
    if (!globalCustomersRead) handlers.navigateToPath('/customers')
  }, [globalCustomersRead])
  const onDeleteGlobalEntity = (companyId, region, externalId, id) => handlers.customerToDeleteDelete({ companyId, region, externalId, id })
  const listToDelete = customersToDelete.map(item => ({
    customer: { ...item },
    branch: branches.find(branch => branch.id === item.companyId)
  }))

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CustomersToDelete
          customersToDelete={listToDelete}
          onDeleteGlobalEntity={onDeleteGlobalEntity}
          globalCustomersWrite={globalCustomersWrite}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  customersToDelete: state.customers.listToDelete,
  pending: state.customers.pendingToDeleteList,
  account: state.account
})

export default connect(maps)(CustomersToDeleteWrapper)
