import React, { Component } from 'react'
import { feedContextInProps } from '../../Utils'
import { AccordionSecondLevelContext } from '../../Common'

class AccordionSecondLevelTitle extends Component {
  constructor (props, context) {
    super(props)
  }

  render () {
    const { children, toggle } = this.props
    return (
      <div className='ta-accordion-second-level__title' onClick={toggle}>
        {children}
      </div>
    )
  }
}

export default feedContextInProps(AccordionSecondLevelTitle, AccordionSecondLevelContext)
