import React from 'react'
import { Form, Row, Col, Input, FontAwesome5, t } from '../../Common'

const CoursesFilters = props => {
  const { disabled } = props
  return (
    <div className='ta-courses__filters'>
      <Form name='coursesFilters'>
        <Row>
          <Col>
            <Input
              label={t('global.searchGroup.label')}
              type='text'
              name='search'
              clearable={!disabled}
              disabled={disabled}
              addon={<FontAwesome5 icon='search' type='r' />}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default CoursesFilters
