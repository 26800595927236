import React from 'react'
import { ImageInput, t } from '../../../Common'

const BranchesResourceFormAvatar = props => {
  const { name } = props
  const maxSize = 5

  return (
    <div className='ta-branches-resources__form__avatar'>
      <ImageInput
        formName='branchesResource'
        name={name}
        defaultZoom={1}
        defaultBorderRadius={100}
        controls={{ radius: false }}
        validation={{ maxSize }}
        title={t('global.uploadPhotoOrDrop')}
        text={t('global.uploadPhotoOrDrop.text')}
      />
    </div>
  )
}

export default BranchesResourceFormAvatar
