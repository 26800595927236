import React from 'react'
import { FontAwesome5, Alert, SimpleListItem, t, Button } from '../../../Common'

const CustomerPreviewChangedLocally = props => {
  let { locallyChangedData, onResetGlobalEntity, globalCustomersWrite } = props
  locallyChangedData = locallyChangedData || []

  return (
    <div className='ta-customer__preview--changed-locally'>
      {locallyChangedData.map(item => {
        let { branch, id: customerId } = item
        branch = branch || {}

        return (
          <SimpleListItem className='ta-customer__preview--changed-locally--branch' key={customerId}>
            <div className='ta-customer__preview--changed-locally--branch__data'>
              <p className='ta-customer__preview--changed-locally--branch__name'>{branch.name || t('global.insufficientPermissions')}</p>
              {(branch.id &&
                <div className='ta-customer__preview--changed-locally--item'>
                  <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
                </div>
              )}
              <div className='ta-customer__preview--changed-locally--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{customerId}</p>
              </div>
            </div>
            {(globalCustomersWrite && branch.id &&
              <div className='ta-customer__preview--changed-locally--branch__buttons'>
                <Button isTertiary isGhost onClick={() => onResetGlobalEntity(branch.id, branch.region)}>
                  {t('mobileApp.customers.filter.buttonReset.label')}
                </Button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {(!locallyChangedData.length &&
        <Alert noOffset label={t('global.noBranches')} theme='no-content' />
      )}
    </div>
  )
}

export default CustomerPreviewChangedLocally
