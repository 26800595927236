import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../Common'

const CustomersListFilterAlphabet = props => {
  const { letters = [], selected, onClick, activeLetters = letters || [] } = props

  return (
    <div className='ta-customers__filter-alphabet'>
      <div
        className={`ta-customers__filter-alphabet-letter ${!selected ? 'active' : ''}`}
        onClick={() => onClick('')}
      >
        {t('global.all')}
      </div>
      {letters.length > 0 && letters.map(letter => {
        const classNames = ['ta-customers__filter-alphabet-letter']
        if (letter === selected) {
          classNames.push('active')
        } else {
          if (
            !activeLetters ||
            activeLetters.length === 0 ||
            (
              Array.isArray(activeLetters) &&
              !(activeLetters.includes(letter) || activeLetters.includes(letter.toLowerCase()))
            )
          ) {
            classNames.push('disabled')
          }
        }

        return (
          <div
            key={letter}
            className={classNames.join(' ')}
            onClick={() => onClick(letter)}
          >
            {letter}
          </div>
        )
      })}
    </div>
  )
}

CustomersListFilterAlphabet.propTypes = {
  letters: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  activeLetters: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default CustomersListFilterAlphabet
