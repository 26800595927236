import React from 'react'
import PropTypes from 'prop-types'
import { OutsideClick } from '../../Common'

import './Modal.css'

const Modal = props => {
  const { isActive = false, children, onClickOutside } = props
  const classNames = ['ta-modal']

  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {(isActive &&
        <OutsideClick onClickOutside={onClickOutside}>
          {children}
        </OutsideClick>
      )}
    </div>
  )
}

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default Modal
