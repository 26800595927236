import React from 'react'
import {
  Title,
  t
} from '../../../../Common'
import { BranchesServiceFormAllowancePlanWrapper } from '../../../../Beasts'

import './BranchesServiceForm.css'

const BranchesServiceFormAvailability = props => {
  const { plan } = props
  return (
    <>
      <Title size='m' label={t('servicesGroups.section.service.availability')} icon='clock' ignoreTopOffset />
      <BranchesServiceFormAllowancePlanWrapper plan={plan} />
    </>
  )
}

export default BranchesServiceFormAvailability
