import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  ListButton,
  FontAwesome5,
  Price,
  Spinner,
  format,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../Common'
import { truncateText } from '../../Utils'

const InvoicesListItem = props => {
  let { invoice, pendingDownload, onClickInvoiceLink } = props
  pendingDownload = pendingDownload || []
  const isRefund = invoice.status === 'REFUNDED'
  let status = 'breaks'
  if (['PAID', 'REFUNDED'].includes(invoice.status)) status = 'green'
  if (['POSTED', 'PAYMENT_DUE', 'NOT_PAID', 'VOIDED', 'PENDING', 'ADJUSTED', 'REFUND_DUE'].includes(invoice.status)) status = 'red'
  const className = isRefund ? 'refund' : ''
  if (isRefund) invoice.amount = invoice.amount * -1

  return (
    <div className='ta-invoices__list__item'>
      <SimpleListItem status={status} className={className}>
        {(pendingDownload.includes(invoice.id) &&
          <div className='ta-invoices__list__loader'>
            <Spinner />
          </div>
        )}
        <SimpleListItemTitle
          title={`${invoice.id} (${truncateText(invoice.description, 30, true)})`}
          htmlTitle={`${invoice.id} (${invoice.description})`}
        />
        <SimpleListItemContent>
          <ul className='ta-list-inline'>
            <li>
              <FontAwesome5 icon='money-bill' type='solid' />
              {(invoice.amount < 0 &&
                <strong>
                  <Price price={invoice.amount * 0.01} currency={invoice.currencyCode} digits={2} />
                </strong>
              )}
              {(invoice.amount >= 0 &&
                <Price price={invoice.amount * 0.01} currency={invoice.currencyCode} digits={2} />
              )}
            </li>
            <li>
              <FontAwesome5 icon='calendar' /> {format(invoice.day, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}
            </li>
            <li>
              <FontAwesome5 icon='exchange' /> {format(invoice.beginDay, 'long', { isUTC: true, format: 'YYYY-MM-DD' })} - {format(invoice.endDay, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}
            </li>
          </ul>
        </SimpleListItemContent>
        <SimpleListItemButtons>
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('invoices.downloadInvoice.hover')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <ListButton
                className='ta-invoices__list__print'
                icon='file-invoice-dollar'
                onClick={() => onClickInvoiceLink(invoice.id)}
                external
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </SimpleListItemButtons>
      </SimpleListItem>
    </div>
  )
}

export default InvoicesListItem
