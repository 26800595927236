import moment from 'moment'
import { payloads$, actions, handlers, store } from '../../../Store'
import { q } from '../../API'
import {
  formatDashboardStatisticsFilter,
  dashboardMostBookedResourcesFilterFormPopulate,
  dashboardMarketingFormPopulate,
  dashboardFilterFormPopulate,
  dashboardExportFormTransform,
  dashboardExportFormValidate,
  dashboardExportFormSaveTransform,
  dashboardExportFormServerErrorsTransform
} from './utils'
import { t } from '../../../Common'

// GET LIST
payloads$(actions.DASHBOARD_GET)
  .subscribe(async () => {
    const currentMonth = moment().format('MM')
    const dailyStatisticsFilter = formatDashboardStatisticsFilter('DAILY')
    const dailyStatistics = await q('getEnterpriseStatisticsV2', dailyStatisticsFilter)
    const { error: dailyStatisticsError } = dailyStatistics || { error: {} }
    if (dailyStatisticsError) return dailyStatisticsError
    dailyStatistics.month = (dailyStatisticsFilter.filter && dailyStatisticsFilter.filter.month) || currentMonth
    const monthlyStatisticsFilter = formatDashboardStatisticsFilter('MONTHLY', true)
    const monthlyStatistics = await q('getEnterpriseStatisticsV2', monthlyStatisticsFilter)
    const { error: monthlyStatisticsError } = monthlyStatistics || { error: {} }
    if (monthlyStatisticsError) return monthlyStatisticsError
    monthlyStatistics.month = (monthlyStatisticsFilter.filter && monthlyStatisticsFilter.filter.month) || currentMonth
    handlers.dashboardPopulate({ dailyStatistics, monthlyStatistics })
    handlers.dashboardMarketingFormGet()
  })

payloads$(actions.DASHBOARD_STATISTICS_OVERALL_GET)
  .subscribe(async () => {
    const overallFilter = { filter: { statisticsMode: 'OVERALL', month: 'IGNORE', year: 'IGNORE' } }
    const overallStatistics = await q('getEnterpriseStatisticsV2Overall', overallFilter)
    const { error: overallStatisticsError } = overallStatistics || { error: {} }
    if (overallStatisticsError) return overallStatisticsError
    handlers.dashboardStatisticsOverallPopulate({ overallStatistics })
  })

payloads$(actions.DASHBOARD_UPDATED)
  .subscribe(async () => {
    handlers.dashboardStatisticsOverallGet()
    const currentMonth = moment().format('MM')
    const dailyStatisticsFilter = formatDashboardStatisticsFilter('DAILY')
    const dailyStatistics = await q('getEnterpriseStatisticsV2', dailyStatisticsFilter)
    const { error: dailyStatisticsError } = dailyStatistics
    if (dailyStatisticsError) return dailyStatisticsError
    dailyStatistics.month = (dailyStatisticsFilter.filter && dailyStatisticsFilter.filter.month) || currentMonth
    const monthlyStatisticsFilter = formatDashboardStatisticsFilter('MONTHLY', true)
    const monthlyStatistics = await q('getEnterpriseStatisticsV2', monthlyStatisticsFilter)
    const data = await q('getStatisticsLastStatisticsStateChange')
    const { enterprise } = data || {}
    const { lastStatisticsStateChange } = enterprise || {}
    if (lastStatisticsStateChange) handlers.companyLastStatisticsStateChangeSet(lastStatisticsStateChange)
    const { error: monthlyStatisticsError } = monthlyStatistics
    if (monthlyStatisticsError) return monthlyStatisticsError
    monthlyStatistics.month = (monthlyStatisticsFilter.filter && monthlyStatisticsFilter.filter.month) || currentMonth
    handlers.dashboardPopulate({ dailyStatistics, monthlyStatistics })
    handlers.dashboardMarketingFormGet()
  })

payloads$(actions.DASHBOARD_FILTER_FORM_GET)
  .subscribe(() => {
    handlers.formSet('dashboardFilters', dashboardFilterFormPopulate({
      lifetime: t('statistics.lifetime.lifetime'),
      month: t('statistics.lifetime.month'),
      until: t('global.until')
    }))
  })

payloads$(actions.DASHBOARD_MARKETING_FORM_GET)
  .subscribe(() => {
    handlers.formSet('marketingConversions', dashboardMarketingFormPopulate({
      campaign: t('statistics.marketing.campaign'),
      content: t('statistics.marketing.content'),
      source: t('statistics.marketing.source'),
      medium: t('statistics.marketing.medium'),
      utmTerm: t('statistics.marketing.term')
    }))
  })

payloads$(actions.DASHBOARD_MOST_BOOKED_RESOURCES_FILTERS_GET)
  .subscribe(() => {
    handlers.formSet('dashboardMostBookedResourcesType', dashboardMostBookedResourcesFilterFormPopulate({
      allKey: t('global.all'),
      dependenciesKey: t('resources.categories.dependency'),
      bookableKey: t('statistics.resources.bookable')
    }))
  })

// THIS IS THE FILTER FUNCTIONALITY FOR WEBAPP!!
payloads$(actions.DASHBOARD_STATISTIC_FILTER)
  .subscribe(async ({ statisticId, filter }) => {
    if (!statisticId) return
    const filterableStatistics = {
      totalEvents: 'getEnterpriseStatisticsV2TotalEvents',
      totalCancelledEvents: 'getEnterpriseStatisticsV2TotalCancelledEvents',
      totalOnlineEvents: 'getEnterpriseStatisticsV2TotalOnlineEvents',
      totalInternalEvents: 'getEnterpriseStatisticsV2TotalInternalEvents'
    }
    const queryName = filterableStatistics[statisticId]
    if (!queryName) return
    const filteredStatisticData = await q(queryName, filter)
    const { error } = filteredStatisticData
    if (error) return error
    handlers.dashboardStatisticUpdate({ statisticId, filteredStatistic: filteredStatisticData[statisticId] })
  })

// Export

payloads$(actions.DASHBOARD_STATISTICS_EXPORT_FORM_GET)
  .subscribe(() => {
    const state = store.getState()
    const { forms } = state || {}
    const { dashboardFilters } = forms || {}

    handlers.formSet('statisticsExport', dashboardExportFormTransform({ dashboardFilters }))
    handlers.dashboardStatisticsExportFormReady()
  })

payloads$(actions.DASHBOARD_STATISTICS_EXPORT_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = dashboardExportFormValidate(form)
    if (errors.length) return setDashboardStatisticsExportFormSaveErrors(errors, scrollToError)
    const result = await q('getEnterpriseStatisticsExport', dashboardExportFormSaveTransform(form))
    const { error } = result || { error: {} }
    if (error) return setDashboardStatisticsExportFormSaveErrors(dashboardExportFormServerErrorsTransform(error), scrollToError)
    handlers.dashboardStatisticsExportFormReady()
    handlers.dashboardStatisticsExportMessageSet(t('statistics.export.messageSuccessText', [{ key: 'FILE_NAME', value: '.csv' }]))
    setTimeout(() => {
      handlers.modalHide()
      handlers.overlayHide('top')
      handlers.dashboardStatisticsExportMessageSet(null)
    }, 4000)
  })

const setDashboardStatisticsExportFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('statisticsExport', errors)
  scrollToError && scrollToError(errors)
  handlers.dashboardStatisticsExportFormReady()
}
