import React from 'react'
import PropTypes from 'prop-types'
import { handlers, store } from '../../../Store'
import { PopupCourseCategoryDelete } from '../../../Beauties'
import { t } from '../../../Common'

const PopupCourseCategoryDeleteWrapper = props => {
  const { id, closePopup } = props
  const defaultCategoryName = () => {
    const categories = store.getState().courses.categoriesList || []
    const defaultCategory = categories.find(item => item.isDefault) || {}
    const defaultCategoryName = defaultCategory.name
    if (defaultCategoryName === 'default') return t('servicesGroups.list.group.defaultCategoryName')
    return defaultCategoryName
  }
  const approve = () => {
    if (id) handlers.courseCategoryDelete(id)
    closePopup && closePopup()
  }
  const reject = () => {
    closePopup && closePopup()
  }

  return (
    <PopupCourseCategoryDelete
      approve={approve}
      reject={reject}
      defaultCategoryName={defaultCategoryName}
    />
  )
}

PopupCourseCategoryDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  categoryName: PropTypes.string
}

export default PopupCourseCategoryDeleteWrapper
