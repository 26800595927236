import React, { Component } from 'react'

class AccordionSecondLevelBody extends Component {
  constructor (props, context) {
    super(props)
  }

  render () {
    const { children } = this.props

    return (
      <div className='ta-accordion-second-level__body'>
        {children}
      </div>
    )
  }
}

export default AccordionSecondLevelBody
