export default (hours) => {
  if (!hours) return 0

  let h = parseInt(hours.split(':')[0], 10)
  let m = parseInt(hours.split(':')[1], 10)

  if (m > 59) {
    return null
  }

  return (h * 60) + m
}
