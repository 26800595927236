import { validator } from '../../../Utils'

export const globalsSettingsFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    // TODO
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

export const globalsSettingsFormTransform = settings => {
  let {
    isCompanyTagsLocalUpdateForbidden,
    isCustomersLocalUpdateForbidden,
    isCustomerFieldsLocalUpdateForbidden,
    isResourceLocalUpdateForbidden,
    isServiceCategoryLocalUpdateForbidden,
    singleServiceEdit,
    courseEdit,
    combinationEdit,
    serviceLocalUpdates,
    serviceCombinationLocalUpdates,
    courseLocalUpdates
  } = settings || {}

  if (!serviceLocalUpdates.isForbidden) singleServiceEdit = !serviceLocalUpdates.keys.length ? 'ALL' : 'SOME'
  if (!courseLocalUpdates.isForbidden) courseEdit = !courseLocalUpdates.keys.length ? 'ALL' : 'SOME'
  if (!serviceCombinationLocalUpdates.isForbidden) combinationEdit = !serviceCombinationLocalUpdates.keys.length ? 'ALL' : 'SOME'

  return {
    isCompanyTagsLocalUpdateEnabled: { value: !isCompanyTagsLocalUpdateForbidden },
    isServiceLocalUpdateEnabled: { value: !serviceLocalUpdates.isForbidden },
    isCombinationServiceLocalUpdateEnabled: { value: !serviceCombinationLocalUpdates.isForbidden },
    isCourseLocalUpdateEnabled: { value: !courseLocalUpdates.isForbidden },
    isCustomersLocalUpdateEnabled: { value: !isCustomersLocalUpdateForbidden },
    isCustomerFieldsLocalUpdateEnabled: { value: !isCustomerFieldsLocalUpdateForbidden },
    isResourceLocalUpdateEnabled: { value: !isResourceLocalUpdateForbidden },
    isServiceCategoryLocalUpdateEnabled: { value: !isServiceCategoryLocalUpdateForbidden },

    singleServiceEdit: { value: singleServiceEdit || 'ALL' },
    singleServiceExternalID: { value: (serviceLocalUpdates.keys || []).includes('externalId') },
    singleServiceServiceName: { value: (serviceLocalUpdates.keys || []).includes('name') },
    singleServiceServiceDescription: { value: (serviceLocalUpdates.keys || []).includes('description') },
    singleServiceServiceColor: { value: (serviceLocalUpdates.keys || []).includes('color') },
    singleServiceDurationSplitInterval: { value: (serviceLocalUpdates.keys || []).includes('durationsPattern') },
    singleServiceFollowupTime: { value: (serviceLocalUpdates.keys || []).includes('durationAfter') },
    singleServicePreparationTime: { value: (serviceLocalUpdates.keys || []).includes('durationBefore') },
    singleServicePrice: { value: (serviceLocalUpdates.keys || []).includes('price') },
    singleServiceSettingDisplayInBookingWidget: { value: (serviceLocalUpdates.keys || []).includes('isBookable') },
    singleServiceSettingOnlinePayment: { value: (serviceLocalUpdates.keys || []).includes('hasOnlinePayment') },
    singleServiceSettingCheckboxToMakeOnlinePaymentMandatory: { value: (serviceLocalUpdates.keys || []).includes('isPaymentMandatory') },
    singleServiceResourcesAndDependencies: { value: (serviceLocalUpdates.keys || []).includes('dependencies') },
    singleServiceCustomerEmailReminders: { value: (serviceLocalUpdates.keys || []).includes('customerEmailRemindersMinutes') },
    singleServiceResourceEmailReminders: { value: (serviceLocalUpdates.keys || []).includes('resourceEmailRemindersMinutes') },

    courseEdit: { value: courseEdit || 'ALL' },
    courseExternalID: { value: (courseLocalUpdates.keys || []).includes('externalId') },
    courseServiceName: { value: (courseLocalUpdates.keys || []).includes('name') },
    courseServiceDescription: { value: (courseLocalUpdates.keys || []).includes('description') },
    courseServiceColor: { value: (courseLocalUpdates.keys || []).includes('color') },
    courseDurationSplitInterval: { value: (courseLocalUpdates.keys || []).includes('durationsPattern') },
    courseFollowupTime: { value: (courseLocalUpdates.keys || []).includes('durationAfter') },
    coursePreparationTime: { value: (courseLocalUpdates.keys || []).includes('durationBefore') },
    coursePrice: { value: (courseLocalUpdates.keys || []).includes('price') },
    courseSettingDisplayInBookingWidget: { value: (courseLocalUpdates.keys || []).includes('isBookable') },
    courseSettingOnlinePayment: { value: (courseLocalUpdates.keys || []).includes('hasOnlinePayment') },
    courseSettingCheckboxToMakeOnlinePaymentMandatory: { value: (courseLocalUpdates.keys || []).includes('isPaymentMandatory') },
    courseResourcesAndDependencies: { value: (courseLocalUpdates.keys || []).includes('dependencies') },
    // maxParticipants: { value: (courseLocalUpdates.keys || []).includes('courseResourcesAndDependencies') },
    courseGuests: { value: (courseLocalUpdates.keys || []).includes('extraPersonsPerParticipant') },
    courseMaxParticipants: { value: (courseLocalUpdates.keys || []).includes('maxParticipants') },
    courseCustomerEmailReminders: { value: (courseLocalUpdates.keys || []).includes('customerEmailRemindersMinutes') },
    courseResourceEmailReminders: { value: (courseLocalUpdates.keys || []).includes('resourceEmailRemindersMinutes') },

    combinationEdit: { value: combinationEdit || 'ALL' },
    combinationExternalID: { value: (serviceCombinationLocalUpdates.keys || []).includes('externalId') },
    combinationServiceName: { value: (serviceCombinationLocalUpdates.keys || []).includes('name') },
    // isCombinationPriceOverwritten (isCombinationPriceOverwritten + price)
    combinationServiceDescription: { value: (serviceCombinationLocalUpdates.keys || []).includes('description') },
    combinationFollowupTime: { value: (serviceCombinationLocalUpdates.keys || []).includes('durationAfter') },
    combinationPreparationTime: { value: (serviceCombinationLocalUpdates.keys || []).includes('durationBefore') },
    combinationPrice: { value: (serviceCombinationLocalUpdates.keys || []).includes('price') },
    combinationSettingDisplayInBookingWidget: { value: (serviceCombinationLocalUpdates.keys || []).includes('isBookable') },
    combinationSettingOnlinePayment: { value: (serviceCombinationLocalUpdates.keys || []).includes('hasOnlinePayment') },
    combinationSettingCheckboxToMakeOnlinePaymentMandatory: { value: (serviceCombinationLocalUpdates.keys || []).includes('isPaymentMandatory') },
    combinationServiceSelection: { value: (serviceCombinationLocalUpdates.keys || []).includes('combinationServiceIds') },
    combinationAssignToSameResource: { value: (serviceCombinationLocalUpdates.keys || []).includes('hasCombinationSameResourcesPreference') },
    combinationCustomerEmailReminders: { value: (serviceCombinationLocalUpdates.keys || []).includes('customerEmailRemindersMinutes') },
    combinationResourceEmailReminders: { value: (serviceCombinationLocalUpdates.keys || []).includes('resourceEmailRemindersMinutes') }
  }
}
export const globalsSettingsFormValidate = settings => {
  const {
    isServiceLocalUpdateEnabled,
    singleServiceEdit,
    singleServiceExternalID,
    singleServiceServiceName,
    singleServiceServiceColor,
    singleServiceServiceDescription,
    singleServiceDurationSplitInterval,
    singleServicePreparationTime,
    singleServiceFollowupTime,
    singleServiceResourcesAndDependencies,
    singleServicePrice,
    singleServiceSettingDisplayInBookingWidget,
    singleServiceSettingOnlinePayment,
    singleServiceSettingCheckboxToMakeOnlinePaymentMandatory,
    singleServiceCustomerEmailReminders,
    singleServiceResourceEmailReminders,

    isCombinationServiceLocalUpdateEnabled,
    combinationEdit,
    combinationExternalID,
    combinationServiceName,
    combinationServiceDescription,
    combinationServiceSelection,
    combinationPreparationTime,
    combinationFollowupTime,
    combinationPrice,
    combinationAssignToSameResource,
    combinationSettingDisplayInBookingWidget,
    combinationSettingOnlinePayment,
    combinationSettingCheckboxToMakeOnlinePaymentMandatory,
    combinationCustomerEmailReminders,
    combinationResourceEmailReminders,

    isCourseLocalUpdateEnabled,
    courseEdit,
    courseExternalID,
    courseServiceName,
    courseServiceColor,
    courseServiceDescription,
    courseDurationSplitInterval,
    coursePreparationTime,
    courseFollowupTime,
    courseGuests,
    courseMaxParticipants,
    courseResourcesAndDependencies,
    coursePrice,
    courseSettingDisplayInBookingWidget,
    courseSettingOnlinePayment,
    courseSettingCheckboxToMakeOnlinePaymentMandatory,
    courseCustomerEmailReminders,
    courseResourceEmailReminders
  } = settings

  const isServiceLocalUpdateRequired = isServiceLocalUpdateEnabled.value && singleServiceEdit.value === 'SOME' && ![
    singleServiceExternalID.value,
    singleServiceServiceName.value,
    singleServiceServiceColor.value,
    singleServiceServiceDescription.value,
    singleServiceDurationSplitInterval.value,
    singleServicePreparationTime.value,
    singleServiceFollowupTime.value,
    singleServiceResourcesAndDependencies.value,
    singleServicePrice.value,
    singleServiceSettingDisplayInBookingWidget.value,
    singleServiceSettingOnlinePayment.value,
    singleServiceSettingCheckboxToMakeOnlinePaymentMandatory.value,
    singleServiceCustomerEmailReminders.value,
    singleServiceResourceEmailReminders.value
  ].filter(item => item).length

  const isCombinationServiceLocalUpdateRequired = isCombinationServiceLocalUpdateEnabled.value && combinationEdit.value === 'SOME' && ![
    combinationExternalID.value,
    combinationServiceName.value,
    combinationServiceDescription.value,
    combinationServiceSelection.value,
    combinationPreparationTime.value,
    combinationFollowupTime.value,
    combinationPrice.value,
    combinationAssignToSameResource.value,
    combinationSettingDisplayInBookingWidget.value,
    combinationSettingOnlinePayment.value,
    combinationSettingCheckboxToMakeOnlinePaymentMandatory.value,
    combinationCustomerEmailReminders.value,
    combinationResourceEmailReminders.value
  ].filter(item => item).length

  const isCourseLocalUpdateRequired = isCourseLocalUpdateEnabled.value && courseEdit.value === 'SOME' && ![
    courseExternalID.value,
    courseServiceName.value,
    courseServiceColor.value,
    courseServiceDescription.value,
    courseDurationSplitInterval.value,
    coursePreparationTime.value,
    courseFollowupTime.value,
    courseGuests.value,
    courseMaxParticipants.value,
    courseResourcesAndDependencies.value,
    coursePrice.value,
    courseSettingDisplayInBookingWidget.value,
    courseSettingOnlinePayment.value,
    courseSettingCheckboxToMakeOnlinePaymentMandatory.value,
    courseCustomerEmailReminders.value,
    courseResourceEmailReminders.value
  ].filter(item => item).length

  const rules = []
  const messages = {}
  const errors = validator(settings, rules, messages, {})

  if (isServiceLocalUpdateRequired) errors.push({ key: 'singleServiceResourceEmailReminders', value: 'errors.attributes.required' })
  if (isCombinationServiceLocalUpdateRequired) errors.push({ key: 'combinationResourceEmailReminders', value: 'errors.attributes.required' })
  if (isCourseLocalUpdateRequired) errors.push({ key: 'courseResourceEmailReminders', value: 'errors.attributes.required' })

  return errors
}
export const globalsSettingsSaveTransform = settings => {
  const {
    isCompanyTagsLocalUpdateEnabled,
    isServiceLocalUpdateEnabled,
    isCombinationServiceLocalUpdateEnabled,
    isCourseLocalUpdateEnabled,
    isCustomersLocalUpdateEnabled,
    isCustomerFieldsLocalUpdateEnabled,
    isResourceLocalUpdateEnabled,
    isServiceCategoryLocalUpdateEnabled,

    singleServiceEdit,
    singleServiceExternalID,
    singleServiceServiceName,
    singleServiceServiceColor,
    singleServiceServiceDescription,
    singleServiceDurationSplitInterval,
    singleServicePreparationTime,
    singleServiceFollowupTime,
    singleServiceResourcesAndDependencies,
    singleServicePrice,
    singleServiceSettingDisplayInBookingWidget,
    singleServiceSettingOnlinePayment,
    singleServiceSettingCheckboxToMakeOnlinePaymentMandatory,
    singleServiceCustomerEmailReminders,
    singleServiceResourceEmailReminders,

    courseEdit,
    courseExternalID,
    courseServiceName,
    courseServiceColor,
    courseServiceDescription,
    courseDurationSplitInterval,
    coursePreparationTime,
    courseFollowupTime,
    courseGuests,
    courseMaxParticipants,
    courseResourcesAndDependencies,
    coursePrice,
    courseSettingDisplayInBookingWidget,
    courseSettingOnlinePayment,
    courseSettingCheckboxToMakeOnlinePaymentMandatory,
    courseCustomerEmailReminders,
    courseResourceEmailReminders,

    combinationEdit,
    combinationExternalID,
    combinationServiceName,
    combinationServiceDescription,
    combinationServiceSelection,
    combinationPreparationTime,
    combinationFollowupTime,
    combinationPrice,
    combinationAssignToSameResource,
    combinationSettingDisplayInBookingWidget,
    combinationSettingOnlinePayment,
    combinationSettingCheckboxToMakeOnlinePaymentMandatory,
    combinationCustomerEmailReminders,
    combinationResourceEmailReminders
  } = settings || {}

  const { value: isCompanyTagsLocalUpdateEnabledValue } = isCompanyTagsLocalUpdateEnabled || {}
  const { value: isCustomersLocalUpdateEnabledValue } = isCustomersLocalUpdateEnabled || {}
  const { value: isCustomerFieldsLocalUpdateEnabledValue } = isCustomerFieldsLocalUpdateEnabled || {}
  const { value: isResourceLocalUpdateEnabledValue } = isResourceLocalUpdateEnabled || {}
  const { value: isServiceCategoryLocalUpdateEnabledValue } = isServiceCategoryLocalUpdateEnabled || {}
  const { value: isServiceLocalUpdateEnabledValue } = isServiceLocalUpdateEnabled || {}
  const { value: isCombinationServiceLocalUpdateEnabledValue } = isCombinationServiceLocalUpdateEnabled || {}
  const { value: isCourseLocalUpdateEnabledValue } = isCourseLocalUpdateEnabled || {}
  const { value: singleServiceEditValue } = singleServiceEdit || {}
  const { value: courseEditValue } = courseEdit || {}
  const { value: combinationEditValue } = combinationEdit || {}

  let { value: singleServiceExternalIDValue } = singleServiceExternalID
  let { value: singleServiceServiceNameValue } = singleServiceServiceName
  let { value: singleServiceServiceColorValue } = singleServiceServiceColor
  let { value: singleServiceServiceDescriptionValue } = singleServiceServiceDescription
  let { value: singleServiceDurationSplitIntervalValue } = singleServiceDurationSplitInterval
  let { value: singleServicePreparationTimeValue } = singleServicePreparationTime
  let { value: singleServiceFollowupTimeValue } = singleServiceFollowupTime
  let { value: singleServiceResourcesAndDependenciesValue } = singleServiceResourcesAndDependencies
  let { value: singleServicePriceValue } = singleServicePrice
  let { value: singleServiceSettingDisplayInBookingWidgetValue } = singleServiceSettingDisplayInBookingWidget
  let { value: singleServiceSettingOnlinePaymentValue } = singleServiceSettingOnlinePayment
  let { value: singleServiceSettingCheckboxToMakeOnlinePaymentMandatoryValue } = singleServiceSettingCheckboxToMakeOnlinePaymentMandatory
  let { value: singleServiceCustomerEmailRemindersValue } = singleServiceCustomerEmailReminders
  let { value: singleServiceResourceEmailRemindersValue } = singleServiceResourceEmailReminders

  let { value: courseExternalIDValue } = courseExternalID
  let { value: courseServiceNameValue } = courseServiceName
  let { value: courseServiceColorValue } = courseServiceColor
  let { value: courseServiceDescriptionValue } = courseServiceDescription
  let { value: courseDurationSplitIntervalValue } = courseDurationSplitInterval
  let { value: coursePreparationTimeValue } = coursePreparationTime
  let { value: courseFollowupTimeValue } = courseFollowupTime
  let { value: courseGuestsValue } = courseGuests

  let { value: courseResourcesAndDependenciesValue } = courseResourcesAndDependencies
  let { value: courseMaxParticipantsValue } = courseMaxParticipants
  let { value: coursePriceValue } = coursePrice
  let { value: courseSettingDisplayInBookingWidgetValue } = courseSettingDisplayInBookingWidget
  let { value: courseSettingOnlinePaymentValue } = courseSettingOnlinePayment
  let { value: courseSettingCheckboxToMakeOnlinePaymentMandatoryValue } = courseSettingCheckboxToMakeOnlinePaymentMandatory
  let { value: courseCustomerEmailRemindersValue } = courseCustomerEmailReminders
  let { value: courseResourceEmailRemindersValue } = courseResourceEmailReminders

  let { value: combinationExternalIDValue } = combinationExternalID
  let { value: combinationServiceNameValue } = combinationServiceName
  let { value: combinationServiceDescriptionValue } = combinationServiceDescription
  let { value: combinationServiceSelectionValue } = combinationServiceSelection
  let { value: combinationPreparationTimeValue } = combinationPreparationTime
  let { value: combinationFollowupTimeValue } = combinationFollowupTime
  let { value: combinationPriceValue } = combinationPrice
  let { value: combinationAssignToSameResourceValue } = combinationAssignToSameResource
  let { value: combinationSettingDisplayInBookingWidgetValue } = combinationSettingDisplayInBookingWidget
  let { value: combinationSettingOnlinePaymentValue } = combinationSettingOnlinePayment
  let { value: combinationSettingCheckboxToMakeOnlinePaymentMandatoryValue } = combinationSettingCheckboxToMakeOnlinePaymentMandatory
  let { value: combinationCustomerEmailRemindersValue } = combinationCustomerEmailReminders
  let { value: combinationResourceEmailRemindersValue } = combinationResourceEmailReminders

  const canSendServicesSet = singleServiceEditValue === 'SOME' || !isServiceLocalUpdateEnabledValue
  const canSendCoursesSet = courseEditValue === 'SOME' || !isCourseLocalUpdateEnabledValue
  const canSendCombinationsSet = combinationEditValue === 'SOME' || !isCombinationServiceLocalUpdateEnabledValue

  singleServiceExternalIDValue = canSendServicesSet ? singleServiceExternalIDValue : false
  singleServiceServiceNameValue = canSendServicesSet ? singleServiceServiceNameValue : false
  singleServiceServiceColorValue = canSendServicesSet ? singleServiceServiceColorValue : false
  singleServiceServiceDescriptionValue = canSendServicesSet ? singleServiceServiceDescriptionValue : false
  singleServiceDurationSplitIntervalValue = canSendServicesSet ? singleServiceDurationSplitIntervalValue : false
  singleServicePreparationTimeValue = canSendServicesSet ? singleServicePreparationTimeValue : false
  singleServiceFollowupTimeValue = canSendServicesSet ? singleServiceFollowupTimeValue : false
  singleServiceResourcesAndDependenciesValue = canSendServicesSet ? singleServiceResourcesAndDependenciesValue : false
  singleServicePriceValue = canSendServicesSet ? singleServicePriceValue : false
  singleServiceSettingDisplayInBookingWidgetValue = canSendServicesSet ? singleServiceSettingDisplayInBookingWidgetValue : false
  singleServiceSettingOnlinePaymentValue = canSendServicesSet ? singleServiceSettingOnlinePaymentValue : false
  singleServiceSettingCheckboxToMakeOnlinePaymentMandatoryValue = canSendServicesSet ? singleServiceSettingCheckboxToMakeOnlinePaymentMandatoryValue : false
  singleServiceCustomerEmailRemindersValue = canSendServicesSet ? singleServiceCustomerEmailRemindersValue : false
  singleServiceResourceEmailRemindersValue = canSendServicesSet ? singleServiceResourceEmailRemindersValue : false

  courseExternalIDValue = canSendCoursesSet ? courseExternalIDValue : false
  courseServiceNameValue = canSendCoursesSet ? courseServiceNameValue : false
  courseServiceColorValue = canSendCoursesSet ? courseServiceColorValue : false
  courseServiceDescriptionValue = canSendCoursesSet ? courseServiceDescriptionValue : false
  courseDurationSplitIntervalValue = canSendCoursesSet ? courseDurationSplitIntervalValue : false
  coursePreparationTimeValue = canSendCoursesSet ? coursePreparationTimeValue : false
  courseFollowupTimeValue = canSendCoursesSet ? courseFollowupTimeValue : false
  courseGuestsValue = canSendCoursesSet ? courseGuestsValue : false
  courseResourcesAndDependenciesValue = canSendCoursesSet ? courseResourcesAndDependenciesValue : false
  courseMaxParticipantsValue = canSendCoursesSet ? courseMaxParticipantsValue : false
  coursePriceValue = canSendCoursesSet ? coursePriceValue : false
  courseSettingDisplayInBookingWidgetValue = canSendCoursesSet ? courseSettingDisplayInBookingWidgetValue : false
  courseSettingOnlinePaymentValue = canSendCoursesSet ? courseSettingOnlinePaymentValue : false
  courseSettingCheckboxToMakeOnlinePaymentMandatoryValue = canSendCoursesSet ? courseSettingCheckboxToMakeOnlinePaymentMandatoryValue : false
  courseCustomerEmailRemindersValue = canSendCoursesSet ? courseCustomerEmailRemindersValue : false
  courseResourceEmailRemindersValue = canSendCoursesSet ? courseResourceEmailRemindersValue : false

  combinationExternalIDValue = canSendCombinationsSet ? combinationExternalIDValue : false
  combinationServiceNameValue = canSendCombinationsSet ? combinationServiceNameValue : false
  combinationServiceDescriptionValue = canSendCombinationsSet ? combinationServiceDescriptionValue : false
  combinationServiceSelectionValue = canSendCombinationsSet ? combinationServiceSelectionValue : false
  combinationPreparationTimeValue = canSendCombinationsSet ? combinationPreparationTimeValue : false
  combinationFollowupTimeValue = canSendCombinationsSet ? combinationFollowupTimeValue : false
  combinationPriceValue = canSendCombinationsSet ? combinationPriceValue : false
  combinationAssignToSameResourceValue = canSendCombinationsSet ? combinationAssignToSameResourceValue : false
  combinationSettingDisplayInBookingWidgetValue = canSendCombinationsSet ? combinationSettingDisplayInBookingWidgetValue : false
  combinationSettingOnlinePaymentValue = canSendCombinationsSet ? combinationSettingOnlinePaymentValue : false
  combinationSettingCheckboxToMakeOnlinePaymentMandatoryValue = canSendCombinationsSet ? combinationSettingCheckboxToMakeOnlinePaymentMandatoryValue : false
  combinationCustomerEmailRemindersValue = canSendCombinationsSet ? combinationCustomerEmailRemindersValue : false
  combinationResourceEmailRemindersValue = canSendCombinationsSet ? combinationResourceEmailRemindersValue : false

  const serviceLocalUpdatesAllowedSet = {
    externalId: singleServiceExternalIDValue,
    name: singleServiceServiceNameValue,
    description: singleServiceServiceDescriptionValue,
    color: singleServiceServiceColorValue,
    durationsPattern: singleServiceDurationSplitIntervalValue,
    durationAfter: singleServiceFollowupTimeValue,
    durationBefore: singleServicePreparationTimeValue,
    price: singleServicePriceValue,
    isBookable: singleServiceSettingDisplayInBookingWidgetValue,
    hasOnlinePayment: singleServiceSettingOnlinePaymentValue,
    isPaymentMandatory: singleServiceSettingCheckboxToMakeOnlinePaymentMandatoryValue,
    dependencies: singleServiceResourcesAndDependenciesValue,
    customerEmailRemindersMinutes: singleServiceCustomerEmailRemindersValue,
    resourceEmailRemindersMinutes: singleServiceResourceEmailRemindersValue
  }

  const serviceCombinationLocalUpdatesAllowedSet = {
    externalId: combinationExternalIDValue,
    name: combinationServiceNameValue,
    description: combinationServiceDescriptionValue,
    durationAfter: combinationFollowupTimeValue,
    durationBefore: combinationPreparationTimeValue,
    price: combinationPriceValue,
    isBookable: combinationSettingDisplayInBookingWidgetValue,
    hasOnlinePayment: combinationSettingOnlinePaymentValue,
    isPaymentMandatory: combinationSettingCheckboxToMakeOnlinePaymentMandatoryValue,
    combinationServiceIds: combinationServiceSelectionValue,
    hasCombinationSameResourcesPreference: combinationAssignToSameResourceValue,
    customerEmailRemindersMinutes: combinationCustomerEmailRemindersValue,
    resourceEmailRemindersMinutes: combinationResourceEmailRemindersValue
  }

  const courseLocalUpdatesAllowedSet = {
    externalId: courseExternalIDValue,
    name: courseServiceNameValue,
    description: courseServiceDescriptionValue,
    color: courseServiceColorValue,
    durationsPattern: courseDurationSplitIntervalValue,
    durationAfter: courseFollowupTimeValue,
    durationBefore: coursePreparationTimeValue,
    price: coursePriceValue,
    isBookable: courseSettingDisplayInBookingWidgetValue,
    hasOnlinePayment: courseSettingOnlinePaymentValue,
    isPaymentMandatory: courseSettingCheckboxToMakeOnlinePaymentMandatoryValue,
    dependencies: courseResourcesAndDependenciesValue,
    maxParticipants: courseMaxParticipantsValue,
    extraPersonsPerParticipant: courseGuestsValue,
    customerEmailRemindersMinutes: courseCustomerEmailRemindersValue,
    resourceEmailRemindersMinutes: courseResourceEmailRemindersValue
  }

  const serviceLocalUpdatesSet = Object.keys(serviceLocalUpdatesAllowedSet).filter(item => !!serviceLocalUpdatesAllowedSet[item]).map(item => item)
  const serviceCombinationLocalUpdatesSet = Object.keys(serviceCombinationLocalUpdatesAllowedSet).filter(item => !!serviceCombinationLocalUpdatesAllowedSet[item]).map(item => item)
  const courseLocalUpdatesSet = Object.keys(courseLocalUpdatesAllowedSet).filter(item => !!courseLocalUpdatesAllowedSet[item]).map(item => item)

  return {
    isCompanyTagsLocalUpdateForbidden: !isCompanyTagsLocalUpdateEnabledValue,
    isCustomersLocalUpdateForbidden: !isCustomersLocalUpdateEnabledValue,
    isCustomerFieldsLocalUpdateForbidden: !isCustomerFieldsLocalUpdateEnabledValue,
    isResourceLocalUpdateForbidden: !isResourceLocalUpdateEnabledValue,
    isServiceCategoryLocalUpdateForbidden: !isServiceCategoryLocalUpdateEnabledValue,
    serviceLocalUpdates: {
      isForbidden: !isServiceLocalUpdateEnabledValue,
      keys: (singleServiceEditValue === 'ALL' || !Object.keys(serviceLocalUpdatesAllowedSet).filter(item => !serviceLocalUpdatesAllowedSet[item]).length)
        ? []
        : serviceLocalUpdatesSet
    },
    serviceCombinationLocalUpdates: {
      isForbidden: !isCombinationServiceLocalUpdateEnabledValue,
      keys: (combinationEditValue === 'ALL' || !Object.keys(serviceCombinationLocalUpdatesAllowedSet).filter(item => !serviceCombinationLocalUpdatesAllowedSet[item]).length)
        ? []
        : serviceCombinationLocalUpdatesSet
    },
    courseLocalUpdates: {
      isForbidden: !isCourseLocalUpdateEnabledValue,
      keys: (courseEditValue === 'ALL' || !Object.keys(courseLocalUpdatesAllowedSet).filter(item => !courseLocalUpdatesAllowedSet[item]).length)
        ? []
        : courseLocalUpdatesSet
    }
  }
}
