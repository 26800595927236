import React from 'react'
import { CustomerPreviewChangedLocally } from '../../Beauties'
import { connect, handlers } from '../../Store'

const CustomerPreviewChangedLocallyWrapper = props => {
  let { customer, account } = props
  account = account || {}
  const { locallyChangedData, internalId } = customer || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalCustomersWrite } = enterprisePermissions || {}
  globalCustomersWrite = !!isAdmin || !!globalCustomersWrite

  const onResetGlobalEntity = (companyId, region) => handlers.customerLocallyChangedReset({ companyId, region, internalId })

  return (
    <CustomerPreviewChangedLocally
      locallyChangedData={locallyChangedData}
      onResetGlobalEntity={onResetGlobalEntity}
      globalCustomersWrite={globalCustomersWrite}
    />
  )
}

const maps = state => ({
  customer: (state.router.data.id && state.customers.list.find(item => item.id === state.router.data.id)) || {},
  account: state.account
})

export default connect(maps)(CustomerPreviewChangedLocallyWrapper)
