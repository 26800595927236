import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { DnDContainer } from '../../Common'

const DnDDroppable = props => {
  const { children, id, type, className, isDropDisabled = false } = props

  return (
    <Droppable
      droppableId={id}
      type={type}
      isDropDisabled={isDropDisabled}
    >
      {provided =>
        <DnDContainer
          className={className}
          provided={provided}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </DnDContainer>
      }
    </Droppable>
  )
}

export default DnDDroppable
