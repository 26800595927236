import React from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'

const Map = (props) => {
  const {
    lat = 48.1926301,
    lng = 11.56950489999997,
    isDraggable,
    zoom = 15,
    isMarkerShown
  } = props

  const mapOptions = {
    draggable: isDraggable,
    defaultZoom: zoom,
    zoom,
    defaultCenter: { lat, lng },
    center: { lat, lng },
    mapContainerStyle: { height: '100%', position: 'relative', overflow: 'hidden' }
  }

  const markerOptions = {
    position: { lat, lng }
  }

  return (
    <GoogleMap getDraggable={(x, y) => console.warn('xy, ', x, y)} {...mapOptions}>
      {isMarkerShown &&
        <Marker {...markerOptions} />
      }
    </GoogleMap>
  )
}

const GoogleMaps = props => {
  return (
    <Map isMarkerShown {...props} />
  )
}

export default GoogleMaps
