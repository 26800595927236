import { payloads$, actions, handlers } from '../../../Store'
import { q } from '../../API'
import { openUrl } from '../../../Utils'

import { invoicesListErrorsTransform } from './utils'

// List
payloads$(actions.INVOICES_LIST_GET)
  .subscribe(async () => {
    const invoices = await q('getEnterpriseChargebeeInvoices')
    const { error } = invoices || {}
    if (error) {
      handlers.invoicesListMessageSet(invoicesListErrorsTransform(error))
      return
    }
    handlers.invoicesListPopulate(invoices)
  })

// Open
payloads$(actions.INVOICE_OPEN)
  .subscribe(async (id) => {
    if (!id) return
    const url = await q('getEnterpriseInvoiceUrl', { id })
    const { error } = url || {}
    handlers.invoiceOpenReady(id)
    if (error) return
    openUrl(url)
  })
