import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error, FontAwesome5, t, Button } from '../../../Common'

import 'react-datepicker/dist/react-datepicker.css'
import './DateInput.css'

class DateInput extends Component {
  constructor (props) {
    super(props)

    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnReset = this.handleOnReset.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOnFocus () {
    const { onFocusAddon } = this.props
    this.setState({ focused: true })
    onFocusAddon && onFocusAddon(true)
  }

  handleOnBlur () {
    const { onBlurAddon } = this.props
    this.setState({ focused: false })
    onBlurAddon && onBlurAddon(false)
  }

  handleOnChange (date) {
    let { formName, name, form, onChange, onChangeAddon } = this.props
    form = form || { value: '' }
    const valueMoment = moment(date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
    this.setState({ filled: !!valueMoment, focused: false })
    if (onChange) return onChange(name, valueMoment)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: valueMoment } })
    onChangeAddon && onChangeAddon(valueMoment, name)
  }

  handleOnReset () {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    this.setState({ focused: false })
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: '' } })
  }

  render () {
    const { focused } = this.state
    let {
      className,
      label,
      placeholder,
      name,
      hintText,
      dateFormat,
      timeFormat,
      timeInterval,
      showTimes,
      showMonth,
      showYear,
      mandatory,
      clearable,
      disabled,
      hideError,
      icon,
      iconType,
      timezone,
      minDate,
      maxDate,
      value: inputValue,
      form,
      position,
      locale,
      showMonthYearPicker,
      hasAutocomplete
    } = this.props
    form = form || { value: '' }
    iconType = iconType || 'regular'
    let formValue = moment.tz((inputValue || form.value), 'YYYY-MM-DD HH:mm', timezone).format('YYYY/MM/DD HH:mm') !== 'Invalid date' ? (inputValue || form.value) : ''
    const value = formValue ? moment.tz(formValue, 'YYYY-MM-DD HH:mm', timezone) : null
    let valueDate = value ? new Date(value.format('YYYY/MM/DD HH:mm')) : null
    if (valueDate && isNaN(valueDate.getDate())) {
      valueDate = null
      formValue = ''
    }
    const minDateValue = minDate ? new Date(minDate.format('YYYY/MM/DD HH:mm')) : null
    const maxDateValue = maxDate ? new Date(maxDate.format('YYYY/MM/DD HH:mm')) : null
    const classNames = ['ta-date-input']
    if (showMonth || showYear) classNames.push('has-dropdown')
    if (showTimes) classNames.push('has-times')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled={!!formValue}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
        >
          <div className={classNames.join(' ')}>
            <DatePicker
              ref={ref => { this.ref = ref }}
              className='ta-form-control'
              name={name}
              selected={valueDate}
              placeholderText={placeholder || form.placeholder}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnChange}
              dateFormat={dateFormat || (showTimes ? 'Pp' : 'P')}
              showTimeSelect={showTimes || false}
              shouldCloseOnSelect={!showTimes}
              timeFormat={timeFormat || 'p'}
              timeIntervals={timeInterval || 15}
              timeCaption={t('global.time')}
              showMonthDropdown={showMonth || false}
              showYearDropdown={showYear || false}
              scrollableYearDropdown={showYear || false}
              yearDropdownItemNumber={100}
              disabled={disabled || form.disabled}
              minDate={minDateValue}
              maxDate={maxDateValue}
              autoComplete={hasAutocomplete ? 'on' : 'off'}
              aria-autocomplete={hasAutocomplete ? 'both' : 'none'}
              locale={locale}
              popperPlacement={position}
              showMonthYearPicker={showMonthYearPicker}
            />
          </div>
          {icon && (
            <div className='ta-form-control__addon'>
              <FontAwesome5 icon={icon} type={iconType} />
            </div>
          )}
          {hintText && (
            <div className='ta-form-control__hint'>{hintText}</div>
          )}
          {clearable && formValue && (
             <Button isSecondary as='link' className='ta-form-control__btn-clear' onClick={this.handleOnReset}>
             <FontAwesome5 icon='times' type='r' />
             </Button>
          )}
          {!hideError && (
            <Error noOffset name={name} />
          )}
        </FormGroup>
      </div>
    )
  }
}

DateInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  format: PropTypes.string,
  showMonth: PropTypes.bool,
  showYear: PropTypes.bool,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object,
  position: PropTypes.string,
  hasAutocomplete: PropTypes.bool
}

const maps = (state, props) => ({
  form: state.forms[props.formName][props.name] || { value: '' }
})

export default feedContextInProps(connect(maps)(DateInput), FormContext)
