export default (a, b) => {
  const first = {
    permissionsIndex: a.isOwner ? 3 : a.isAdmin ? 2 : 1,
    confirmedIndex: a.isConfirmed ? 2 : 1
  }
  const second = {
    permissionsIndex: b.isOwner ? 3 : b.isAdmin ? 2 : 1,
    confirmedIndex: b.isConfirmed ? 2 : 1
  }
  return second.confirmedIndex === first.confirmedIndex
    ? second.permissionsIndex === first.permissionsIndex
      ? b.name < a.name ? 1 : -1
      : second.permissionsIndex > first.permissionsIndex ? 1 : -1
    : second.confirmedIndex > first.confirmedIndex ? 1 : -1
}
