import React, { Component } from 'react'
import { connect, handlers, store } from '../../Store'
import { SSO } from '../../Beauties'

class SSOWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    handlers.loginSSOFormPopulate()
  }

  onSubmit (cb) {
    const form = store.getState().forms.sso
    handlers.loginWithSSO(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const {
      pending,
      pendingOneTimeToken,
      pendingLanguage
    } = this.props

    return (
      <SSO
        pendingLanguage={pendingLanguage}
        pending={pending || pendingOneTimeToken}
        onSubmit={this.onSubmit}
      />
    )
  }
}

const maps = state => ({
  router: state.router,
  pendingLanguage: state.app.pendingLanguage,
  pending: state.auth.pendingForm,
  pendingOneTimeToken: state.auth.pendingOneTimeToken
})

export default connect(maps)(SSOWrapper)
