import React from 'react'
import {
  Alert,
  // Button,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServicesHelpBox = props => {
  return (
    <div className='ta-popup__services-help-box'>
      <Alert noOffset noBorder label={t('servicesGroups.list.service.heading.helpTooltip.title')} theme='alert'>
        <div>{dangerousHTML(t('servicesGroups.list.service.heading.helpTooltip.text'))}</div>
      </Alert>
    </div>
  )
}

export default PopupServicesHelpBox
