export default () => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const iOSPlatforms = ['iPhone', 'iPad', 'iPod']
  const applePlatforms = macPlatforms.concat(iOSPlatforms)
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  let os = null

  if (applePlatforms.indexOf(platform) !== -1) {
    os = 'Apple'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    // if we ever need to validate this
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    // if we ever need to validate this
    os = 'Linux'
  }

  return os
}
