import React from 'react'

import {
  Title,
  Form,
  FormSection,
  Checkbox,
  BorderedBox,
  SingleSelect,
  Alert,
  Row,
  Col,
  FormButtons,
  Button,
  FontAwesome5
} from '../../Common'

import './DebugOptions.css'

const DebugOptions = props => {
  const { active, closeDebugMode, phraseActive, onSubmit } = props
  const classNames = ['ta-debug-options']
  if (active) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-debug-options-overlay' onClick={closeDebugMode} />
      <div className='ta-debug-options-form'>
        <div className='ta-debug-options-close' onClick={closeDebugMode}>
          <FontAwesome5 icon='times' />
        </div>
        <Title label='Debug Options' />
        <Form name='debugOptions' onSubmit={onSubmit}>
          <FormSection>
            <Row>
              <Col>
                <Checkbox name='phraseActive' label='Activate Phrase' theme='switch' />
              </Col>
            </Row>
            {(phraseActive &&
              <Row>
                <Col>
                  <BorderedBox>
                    <SingleSelect name='phraseBranch' label='Branch' />
                    <br />
                    <Alert theme='alert'>Using Phrase, all the translation keys will be replaced with the Phrase values. The selected system language will be ignored and instead, the language selected in the Phrase debugger will be used</Alert>
                  </BorderedBox>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Checkbox name='intercomActive' label='Activate Intercom' theme='switch' />
              </Col>
            </Row>
          </FormSection>
          <FormButtons>
            <Row noOffset>
              <Col size={50}>
                <Button as='link' onClick={closeDebugMode} isBlock isSecondary>Cancel</Button>
              </Col>
              <Col size={50}>
                <Button type='submit' isBlock isPrimary>Save</Button>
              </Col>
            </Row>
          </FormButtons>
        </Form>
      </div>
    </div>
  )
}

export default DebugOptions
