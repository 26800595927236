import React from 'react'
import {
  t,
  IconListItem,
  IconListItemTitle,
  IconListItemBody
} from '../../Common'

const CourseCategoryPreviewDetails = props => {
  const { category } = props
  const { id, externalId, name } = category || {}
  const categoryName = name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : name

  return (
    <div className='ta-course-category__preview__details'>
      {(id &&
        <IconListItem icon='id-badge' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.categoryID')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{id}</IconListItemBody>
        </IconListItem>
      )}
      {(externalId &&
        <IconListItem icon='fingerprint' type='regular'>
          <IconListItemTitle width='180px'>{t('global.externalId')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{externalId}</IconListItemBody>
        </IconListItem>
      )}
      {(name &&
        <IconListItem icon='cube' iconType='solid'>
          <IconListItemTitle width='180px'>{t('global.name')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{categoryName}</IconListItemBody>
        </IconListItem>
      )}
    </div>
  )
}

export default CourseCategoryPreviewDetails
