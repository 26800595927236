import React from 'react'
import {
  BorderedBox
} from '../../../Common'
import SettingsIcsFileCustomisationCustomer from './SettingsIcsFileCustomisationCustomer'
import SettingsIcsFileCustomisationResource from './SettingsIcsFileCustomisationResource'

const SettingsIcsFileCustomisation = props => {
  return (
    <>
      <BorderedBox>
        <SettingsIcsFileCustomisationCustomer {...props} />
        <SettingsIcsFileCustomisationResource {...props} />
      </BorderedBox>
    </>
  )
}

export default SettingsIcsFileCustomisation
