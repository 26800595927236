import React, { useEffect } from 'react'
import moment from 'moment'

import { connect, handlers, selectors } from '../../../Store'
import { isRouteDisabledByCustomisations } from '../../../Common'
import { AppsSubscriptionAdd } from '../../../Beauties'

// const mock = {
//   pricingType: 'payPerUsage',
//   hasOneTimeSetup: false,
//   entityCount: 14,
//   entityName: 'SMS',
//   oneTimeSetupText: 'Includes the set up and 30min onboarding with a colleague from our customer experience team.',
//   oneTimeSetupValue: 25,
//   isVariablePrice: false,
//   price: 0.1,
//   priceRange: [0.0095, 0.30],
//   payPerUsageText: '* The price per SMS varies based on the country to which the SMS is sent to and the telecommunication operator.',
//   payPerUsageInvoiceText: 'You will be charged the amount you’ve cumulated over the period from the <strong>25.10.2023 until the 25.11.2023</strong>. Your invoice will be automatically generated on the 26th. An overview of your spendings will be available in the app. App usage and associated fees can be disabled from the Settings view of the app at any time.  You can cancel your subscription at any time before the 25th of the month, otherwise it will be automatically renewed.',
//   hasBillingAddress: false
// }

const AppsSubscriptionAddWrapper = (props) => {
  let {
    id,
    app,
    pending,
    billingData,
    paymentMethods,
    chargebee,
    isOwner,
    isAdmin
  } = props
  app = app || {}
  paymentMethods = paymentMethods || []
  chargebee = chargebee || {}
  const { noAutoCollect } = chargebee
  // const {
  // pricingType,
  // hasOneTimeSetup,
  // entityCount,
  // entityName,
  // oneTimeSetupText,
  // oneTimeSetupValue,
  // isVariablePrice,
  // price,
  // priceRange,
  // payPerUsageText,
  // payPerUsageInvoiceText
  // hasBillingAddress
  // } = mock
  const {
    appInstallation,
    installedVersion,
    usageMaxUnitPrice,
    usageMinUnitPrice,
    usageUnitPrice
  } = app || {}
  const {
    activationFeePrice,
    remainingUnits,
    unitDescription,
    unitDescriptionPlural,
    priceDescription,
    subscribedAt,
    unsubscribedAt,
    hasUsage,
    isTrial,
    isBlocked,
    blockedReasonCode,
    hasDuePayment,
    // cancelledAt,
    isPaidByEnterprise,
    blockingAt,
    currencyCode
  } = appInstallation || {}
  const {
    name,
    address1,
    zipCode,
    city
  } = billingData || {}
  const pricingType = (hasUsage || (hasUsage === null && !isPaidByEnterprise)) ? 'payPerUsage' : 'monthly'
  const isVariablePrice = !!usageMaxUnitPrice && !!usageMinUnitPrice && usageMinUnitPrice !== usageMaxUnitPrice
  const priceRange = [usageMinUnitPrice, usageMaxUnitPrice]
  const hasBillingDetails = name && address1 && zipCode && city
  const hasPaymentMethod = paymentMethods.length > 0
  const today = moment().format('L')
  const todayNextMonth = moment(today, 'L').add(1, 'month').format('L')
  const endDay = moment(todayNextMonth, 'L').format('Do')
  const nextInvoiceDay = moment(todayNextMonth, 'L').add(1, 'days').format('Do')
  const appsWrite = !!isAdmin

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.appPreviewGet({ id })
    handlers.paymentMethodsGet()
  }, [id, appsWrite])

  useEffect(() => {
    if (installedVersion && hasDuePayment && !noAutoCollect) handlers.navigateToRoute('appsSubscriptionFailed', { id })
    else if (installedVersion && noAutoCollect && isBlocked && blockingAt) handlers.navigateToRoute('appsSubscriptionFailed', { id })
    // else if (installedVersion && !hasDuePayment && cancelledAt) handlers.navigateToRoute('appsSubscriptionGone', { id })
    else if (installedVersion && subscribedAt && !unsubscribedAt && (!isBlocked || blockedReasonCode !== 'SUBSCRIBE')) handlers.navigateToRoute('appsSubscription', { id })
  }, [id, installedVersion, isBlocked, blockedReasonCode, unsubscribedAt, subscribedAt])

  const handleOnUninstallClick = () => {
    handlers.navigateToRoute('appsUninstall', { id })
  }

  return (
    <AppsSubscriptionAdd
      {...app}
      currencyCode={currencyCode}
      pending={pending}
      pricingType={pricingType}
      activationFeePrice={activationFeePrice}
      remainingUnits={remainingUnits}
      unitDescription={unitDescription}
      unitDescriptionPlural={unitDescriptionPlural}
      isVariablePrice={isVariablePrice}
      usageUnitPrice={usageUnitPrice}
      priceRange={priceRange}
      priceDescription={priceDescription}
      today={today}
      todayNextMonth={todayNextMonth}
      endDay={endDay}
      nextInvoiceDay={nextInvoiceDay}
      onUninstallClick={handleOnUninstallClick}
      hasBillingDetails={hasBillingDetails}
      hasPaymentMethod={hasPaymentMethod}
      noAutoCollect={noAutoCollect}
      isTrial={isTrial}
      isOwner={isOwner}
      isPaidByEnterprise={isPaidByEnterprise}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    billingData: selectors.billingDataSelector(state),
    pending: selectors.appsFieldSelector(state, { field: 'pendingPreview' }),
    paymentMethods: state.paymentMethods.list,
    chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' })
  }
}

export default connect(maps)(AppsSubscriptionAddWrapper)
