import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { AccountsWrapper } from '../../Beasts'

const NegativeLeftFirstSidebarWrapper = props => {
  const { route } = props
  const ROUTES_ACCOUNTS = ['accounts']

  return (
    <>
      {ROUTES_ACCOUNTS.includes(route) && <AccountsWrapper />}
    </>
  )
}

NegativeLeftFirstSidebarWrapper.propTypes = {
  route: PropTypes.string
}

const maps = state => ({
  route: state.router.name
})

export default connect(maps)(NegativeLeftFirstSidebarWrapper)
