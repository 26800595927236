import {
  swapItemsInDnDList,
  swapCategoriesInDnDList,
  sortByOrderIndex
} from '../../../Utils'

import {
  serviceCategoryFormTransform,
  serviceTransform,
  serviceListTransform,
  serviceFormTransform,
  serviceRestoreFormTransform,
  serviceCombinationFormTransform,
  serviceListCombineServiceFilter
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  locallyChangedList: [],
  pendingList: true,
  pendingListCategory: false,
  pendingForm: true,
  pendingPreview: true,
  pendingCategory: null,
  pendingCategoryPreview: null,
  pendingCategoryForm: true,
  selected: null,
  dragging: {},
  messagePreview: null,
  messageList: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  servicesReset: () => dispatch(actions.SERVICES_RESET),

  // Subscription
  servicesSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.SERVICES_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  servicesListPopulate: ({ services, serviceCategories }) => dispatch(actions.SERVICES_LIST_POPULATE, { services: serviceListTransform(services), serviceCategories }),
  servicesListReady: () => dispatch(actions.SERVICES_LIST_READY),
  servicesListOrderChange: ({ id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => dispatch(actions.SERVICES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }),
  servicesListOrderChanged: (updatedServices) => dispatch(actions.SERVICES_LIST_ORDER_CHANGED, updatedServices),
  servicesListMessageSet: message => dispatch(actions.SERVICES_LIST_MESSAGE_SET, message),
  servicesUpdated: id => dispatch(actions.SERVICES_UPDATED, id),

  // One
  serviceUpdate: service => dispatch(actions.SERVICE_UPDATE, serviceTransform(service)),
  serviceUpdated: service => dispatch(actions.SERVICE_UPDATED, service),
  serviceDelete: id => dispatch(actions.SERVICE_DELETE, id),
  serviceDeleted: id => dispatch(actions.SERVICE_DELETED, id),
  serviceRemoveDeleted: id => dispatch(actions.SERVICE_REMOVE_DELETED, id),
  serviceResetGlobal: internalId => dispatch(actions.SERVICE_RESET_GLOBAL, internalId),
  serviceResetGlobalReady: (payload) => dispatch(actions.SERVICE_RESET_GLOBAL_READY, payload),
  servicesToDeleteGet: () => dispatch(actions.SERVICES_TO_DELETE_GET),
  servicesToDeletePopulate: (servicesToDelete) => dispatch(actions.SERVICES_TO_DELETE_POPULATE, servicesToDelete),
  serviceToDeleteDelete: ({ companyId, region, externalId, id }) => dispatch(actions.SERVICE_TO_DELETE_DELETE, { companyId, region, externalId, id }),

  // Preview
  servicePreviewGet: ({ id, forceFetch }) => dispatch(actions.SERVICE_PREVIEW_GET, { id, forceFetch }),
  servicePreviewPopulate: service => dispatch(actions.SERVICE_PREVIEW_POPULATE, service),
  servicePreviewMessageSet: message => dispatch(actions.SERVICE_PREVIEW_MESSAGE_SET, message),
  serviceLocallyChangedGet: ({ id, internalId }) => dispatch(actions.SERVICE_LOCALLY_CHANGED_GET, { id, internalId }),
  serviceLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.SERVICE_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  serviceLocallyChangedReset: ({ companyIds, internalId }) => dispatch(actions.SERVICE_LOCALLY_CHANGED_RESET, { companyIds, internalId }),
  serviceLocallyChangedResetReady: ({ service, companyIds }) => dispatch(actions.SERVICE_LOCALLY_CHANGED_RESET_READY, ({ service, companyIds })),

  // Form
  serviceFormGet: id => dispatch(actions.SERVICE_FORM_GET, id),
  serviceFormPopulate: service => {
    handlers.formSet('services', serviceFormTransform(service))
    handlers.serviceFormReady()
  },
  serviceFormReady: () => dispatch(actions.SERVICE_FORM_READY),
  serviceFormSave: (service, scrollToError = () => { }) => dispatch(actions.SERVICE_FORM_SAVE, { service, scrollToError }),

  // Restore
  serviceRestoreFormGet: service => dispatch(actions.SERVICE_RESTORE_FORM_GET, service),
  serviceRestoreFormPopulate: service => {
    handlers.formSet('serviceChangedLocally', serviceRestoreFormTransform(service))
    handlers.serviceRestoreFormReady()
  },
  serviceRestoreFormReady: () => dispatch(actions.SERVICE_RESTORE_FORM_READY),

  // Combination Form
  serviceCombinationFormGet: id => dispatch(actions.SERVICE_COMBINATION_FORM_GET, id),
  serviceCombinationFormPopulate: service => {
    handlers.formSet('services', serviceCombinationFormTransform(service))
    handlers.serviceCombinationFormReady()
  },
  serviceCombinationFormReady: () => dispatch(actions.SERVICE_COMBINATION_FORM_READY),
  serviceCombinationFormSave: (service, scrollToError = () => { }) => dispatch(actions.SERVICE_COMBINATION_FORM_SAVE, { service, scrollToError }),

  // Category
  serviceCategoryUpdate: category => dispatch(actions.SERVICE_CATEGORY_UPDATE, category),
  serviceCategoryDelete: category => dispatch(actions.SERVICE_CATEGORY_DELETE, category),
  serviceCategoryDeleted: id => dispatch(actions.SERVICE_CATEGORY_DELETED, id),
  serviceCategoryDeleteFailed: () => dispatch(actions.SERVICE_CATEGORY_DELETE_FAILED),
  serviceCategoryRemoveDeleted: id => dispatch(actions.SERVICE_CATEGORY_REMOVE_DELETED, id),
  serviceCategoryOrderChange: ({ id, oldIndex, newIndex }) => dispatch(actions.SERVICE_CATEGORIES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex }),
  serviceCategoryOrderChanged: updatedCategories => dispatch(actions.SERVICE_CATEGORIES_LIST_ORDER_CHANGED, updatedCategories),
  serviceCategoryLocallyChangedGet: id => dispatch(actions.SERVICE_CATEGORY_LOCALLY_CHANGED_GET, id),
  serviceCategoryLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.SERVICE_CATEGORY_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  serviceCategoryLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.SERVICE_CATEGORY_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  serviceCategoryLocallyChangedResetReady: ({ serviceCategory, companyId }) => dispatch(actions.SERVICE_CATEGORY_LOCALLY_CHANGED_RESET_READY, { serviceCategory, companyId }),

  // Category form
  serviceCategoryFormGet: id => dispatch(actions.SERVICE_CATEGORY_FORM_GET, id),
  serviceCategoryFormPopulate: ({ category, branches, account }) => {
    handlers.formSet('serviceCategory', serviceCategoryFormTransform({ category, branches, account }))
    handlers.serviceCategoryFormReady()
  },
  serviceCategoryFormReady: () => dispatch(actions.SERVICE_CATEGORY_FORM_READY),
  serviceCategoryFormSave: (category, scrollToError = () => { }) => dispatch(actions.SERVICE_CATEGORY_FORM_SAVE, { category, scrollToError })
})

// Reset

reducer.SERVICES_RESET = state => reducer.initialState

// Subscription

reducer.SERVICES_SUBSCRIPTION_SET = state => state

// List

reducer.SERVICES_LIST_POPULATE = (state, { services, serviceCategories }) => ({
  ...state,
  list: (services || []).sort(sortByOrderIndex),
  categoriesList: (serviceCategories || []).sort(sortByOrderIndex),
  pendingList: false,
  messagePreview: null
})

reducer.SERVICES_LIST_READY = state => ({
  ...state,
  pendingList: false
})

reducer.SERVICES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  return {
    ...state,
    list: swapItemsInDnDList({
      categoryList: state.categoriesList,
      list: state.list,
      id,
      oldIndex,
      newIndex,
      sourceCategoryId,
      destinationCategoryId
    })
  }
}

reducer.SERVICES_LIST_ORDER_CHANGED = (state, updatedServices) => {
  updatedServices = updatedServices.map(uS => {
    const oldService = state.list.find(s => s.id === uS.id)
    return {
      ...oldService,
      ...uS
    }
  })
  return {
    ...state,
    list: state.list
      .filter(item => !updatedServices.find(uS => uS.id === item.id))
      .concat(updatedServices)
      .sort(sortByOrderIndex)
  }
}

reducer.SERVICES_LIST_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingList: false,
  messageList: message,
  messagePreview: null
})

// List to delete
reducer.SERVICES_TO_DELETE_GET = state => ({
  ...state,
  pendingToDeleteList: true
})

reducer.SERVICES_TO_DELETE_POPULATE = (state, servicesToDelete) => ({
  ...state,
  listToDelete: servicesToDelete || [],
  pendingToDeleteList: false
})

reducer.SERVICE_TO_DELETE_DELETE = (state, { id }) => {
  if (!id) return state
  const servicesToDelete = state.listToDelete || []
  const remainingServicesToDelete = servicesToDelete.filter(item => item.id !== id)
  return {
    ...state,
    listToDelete: remainingServicesToDelete,
    pendingToDeleteList: true
  }
}

reducer.SERVICES_TO_DELETE_READY = state => ({
  ...state,
  pendingToDeleteList: false
})

// One

reducer.SERVICE_UPDATE = (state, service) => {
  if (!service) return state
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== service.id)
      .concat([{ ...service, isUpdated: true }])
      .sort(sortByOrderIndex)
  }
}

reducer.SERVICE_UPDATED = (state, service) => {
  if (!service) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === service.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}

reducer.SERVICES_UPDATED = state => state

reducer.SERVICE_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.SERVICE_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}

reducer.SERVICE_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: serviceListCombineServiceFilter(state.list.filter(item => item.id !== id), id)
})

reducer.SERVICE_RESET_GLOBAL = state => ({
  ...state,
  pendingPreview: true
})

reducer.SERVICE_RESET_GLOBAL_READY = state => ({
  ...state,
  pendingPreview: false
})

// Preview

reducer.SERVICE_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true,
  messagePreview: null
})

reducer.SERVICE_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

reducer.SERVICE_PREVIEW_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingPreview: false,
  messagePreview: message,
  selected: null
})

reducer.SERVICE_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.SERVICE_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = {
    ...list[index],
    locallyChangedData
  }
  return {
    ...state,
    list,
    pendingPreview: false
  }
}

reducer.SERVICE_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingPreview: true,
  pendingForm: true
})

reducer.SERVICE_LOCALLY_CHANGED_RESET_READY = (state, { service }) => {
  const { list } = state || {}
  if (service) {
    const index = state.list.findIndex(({ internalId }) => internalId === service.internalId)
    if (index >= 0) {
      list[index] = {
        ...list[index],
        locallyUpdatedBranches: service.locallyUpdatedBranches
      }
    }
  }

  return {
    ...state,
    list,
    pendingPreview: false,
    pendingForm: false
  }
}

// Restore Form
reducer.SERVICE_RESTORE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.SERVICE_RESTORE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

// Form
reducer.SERVICE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.SERVICE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.SERVICE_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Combination Form

reducer.SERVICE_COMBINATION_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.SERVICE_COMBINATION_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.SERVICE_COMBINATION_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Category

reducer.SERVICE_CATEGORY_UPDATE = (state, category) => {
  if (!category) return state
  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => item.id !== category.id)
      .concat([category])
      .sort(sortByOrderIndex)
  }
}

reducer.SERVICE_CATEGORY_DELETE = (state, id) => ({
  ...state,
  pendingCategory: id
})

reducer.SERVICE_CATEGORY_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = { ...categoriesList[index], isDeleted: true }
  return {
    ...state,
    categoriesList,
    pendingCategory: null
  }
}

reducer.SERVICE_CATEGORY_REMOVE_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const defaultCategory = categoriesList.find(item => item.isDefault)
  const deletedCategoryServices = [...state.list]
    .filter(item => item.categoryId === id)
    .map(item => ({ ...item, categoryId: defaultCategory.id }))
  const list = [...state.list]
    .filter(item => item.categoryId !== id)
    .concat(deletedCategoryServices)
    .map((item, index) => ({ ...item, orderIndex: index + 1 }))
  return {
    ...state,
    list,
    categoriesList: state.categoriesList.filter(item => item.id !== id)
  }
}

reducer.SERVICE_CATEGORY_DELETE_FAILED = state => ({
  ...state,
  pendingCategory: null
})

reducer.SERVICE_CATEGORIES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  return {
    ...state,
    categoriesList: swapCategoriesInDnDList({
      list: state.categoriesList,
      id,
      oldIndex,
      newIndex
    })
  }
}

reducer.SERVICE_CATEGORIES_LIST_ORDER_CHANGED = (state, updatedCategories) => {
  updatedCategories = updatedCategories.map(uC => {
    const oldCategory = state.categoriesList.find(c => c.id === uC.id)
    return {
      ...oldCategory,
      ...uC
    }
  })

  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => !updatedCategories.find(uC => uC.id === item.id))
      .concat(updatedCategories)
      .sort(sortByOrderIndex)
  }
}

// Category preivew
reducer.SERVICE_CATEGORY_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingCategoryPreview: true
})

reducer.SERVICE_CATEGORY_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = {
    ...categoriesList[index],
    locallyChangedData
  }
  return {
    ...state,
    categoriesList,
    pendingCategoryPreview: false
  }
}

reducer.SERVICE_CATEGORY_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingCategoryPreview: true
})

reducer.SERVICE_CATEGORY_LOCALLY_CHANGED_RESET_READY = (state, { serviceCategory, companyId }) => {
  const { internalId } = serviceCategory || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  categoriesList[index].locallyChangedData = categoriesList[index].locallyChangedData.filter(item => item.branch.id !== companyId)
  delete categoriesList[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    categoriesList,
    pendingCategoryPreview: false
  }
}

// Category form

reducer.SERVICE_CATEGORY_FORM_GET = state => ({
  ...state,
  pendingCategoryForm: true
})

reducer.SERVICE_CATEGORY_FORM_READY = state => ({
  ...state,
  pendingCategoryForm: false
})

reducer.SERVICE_CATEGORY_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
