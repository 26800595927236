import React from 'react'
import {
  Title,
  Button,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

import { PopupTriggerWrapper } from '../../Beasts'
import './CompanyTags.css'

const CompanyTagsTitle = props => {
  const { isDragAndDropMode, globalTagsWrite } = props

  return (
    <div>
      {(isDragAndDropMode &&
        <Title icon='user-friends' iconTheme='grey' label={t('customerTags.list.reorder.heading')}>
          <PopupTriggerWrapper name='tags-help-box' position='bottom' extraClassName='ta-help-box'>
            <Button as='link' className='ta-title__link' external>
              {t('global.help')} <FontAwesome5 icon='lightbulb' />
            </Button>
          </PopupTriggerWrapper>
          {(globalTagsWrite &&
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {t('global.exitOrdering')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button as='link' to='/customers/company-tags' isPrimary icon='times' />
              </HoverPopupTrigger>
            </HoverPopup>
          )}
        </Title>
      )}
      {(!isDragAndDropMode &&
        <Title icon='user-friends' iconTheme='grey' label={t('customerTags.list.heading.helpTooltip.title')}>

          <PopupTriggerWrapper name='tags-help-box' position='bottom' extraClassName='ta-help-box'>
            <Button as='link' className='ta-title__link' external>
              {t('global.help')} <FontAwesome5 icon='lightbulb' />
            </Button>
          </PopupTriggerWrapper>
          {(globalTagsWrite &&
            // {/* Uncomment if there will be ordering */}
            //  {(tags.length > 0 &&
            //     <HoverPopup>
            //       <HoverPopupContent position='left' autoSize>
            //         {t('customerTags.list.buttonHeading.serviceOrderTooltip')}
            //       </HoverPopupContent>
            //       <HoverPopupTrigger>
            //         <Button as='link' to='/customers/company-tags/reorder' isSecondary icon='arrows-v' />
            //       </HoverPopupTrigger>
            //     </HoverPopup>
            //   )}
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {t('customerTags.list.buttonHeading.tooltip')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button as='link' to='/customers/company-tags/add' isPrimary icon='plus' />
              </HoverPopupTrigger>
            </HoverPopup>
          )}
        </Title>
      )}
    </div>
  )
}

export default CompanyTagsTitle
