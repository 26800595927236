import React from 'react'
import PropTypes from 'prop-types'
import {
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Blur,
  Alert,
  Button,
  t
} from '../../../Common'
import { AppsPrivateAppsWrapper, AppsListCategoryWrapper } from '../../../Beasts'

import './AppsList.css'
import AppsFilters from '../AppsFilters'

const AppsList = props => {
  const {
    installedApps,
    listedApps,
    privateApps,
    hasNoResultInstalledApps,
    hasNoResultListedApps,
    hasNoResultPrivateApps,
    appsRead,
    hash
  } = props
  const availableTabs = ['installedApps', 'listedApps', 'privateApps']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[installedApps.length > 0 || hasNoResultInstalledApps ? 0 : 1]

  return (
    <div className='ta-apps__list'>
      <SimpleTabs active={activeTab} scrollableContent>
        <SimpleTabsHeader>
          <SimpleTabsHeaderItem name='installedApps' label={t('apps.list.tabInstalled.label')} />
          <SimpleTabsHeaderItem name='listedApps' label={t('apps.list.tabPublic.label')} />
          <SimpleTabsHeaderItem name='privateApps' label={t('apps.list.tabPrivate.label')} />
        </SimpleTabsHeader>
        <SimpleTabsBody>
          <SimpleTabsBodyItem name='installedApps'>
            {(installedApps.length > 0 && installedApps.map(category => category.items.length > 0 &&
              <AppsListCategoryWrapper
                key={category.name}
                category={category}
                activeTab={activeTab}
              />
            ))}
            {(installedApps.length === 0 && !hasNoResultInstalledApps &&
              <Alert noOffset label={t('apps.list.tabInstalled.noResultsBox.text')} theme='no-content'>
                <Button as='link' to='/apps@@listedApps' isPrimary>{t('apps.list.tabInstalled.buttonNoResultsBox.label')}</Button>
              </Alert>
            )}
            {(hasNoResultInstalledApps &&
              <Alert noOffset label={t('global.noResults')} theme='no-content' />
            )}
          </SimpleTabsBodyItem>
          <SimpleTabsBodyItem name='listedApps'>
            {appsRead && listedApps.length > 0 && listedApps.map(category => category.items.length > 0 && (
              <AppsListCategoryWrapper
                key={category.name}
                category={category}
                activeTab={activeTab}
              />
            ))}
            {(appsRead && listedApps.length === 0 && !hasNoResultListedApps &&
              <Alert noOffset label={t('apps.list.tabInstalled.allInstalled')} theme='no-content' />
            )}
            {(appsRead && hasNoResultListedApps &&
              <Alert noOffset label={t('global.noResults')} theme='no-content' />
            )}
            {(!appsRead &&
              <Blur active label={t('global.accessDenied')} icon='lock' />
            )}
          </SimpleTabsBodyItem>
          <SimpleTabsBodyItem name='privateApps'>
            {(appsRead &&
              <AppsPrivateAppsWrapper />
            )}
            {privateApps.length > 0 && privateApps.map(category => category.items.length > 0 && (
              <AppsListCategoryWrapper
                key={category.name}
                category={category}
                activeTab={activeTab}
              />
            ))}
            {(privateApps.length === 0 && !hasNoResultPrivateApps &&
              <Alert noOffset label={t('apps.list.tabInstalled.allInstalled')} theme='no-content' />
            )}
            {(appsRead && hasNoResultPrivateApps &&
              <Alert noOffset label={t('global.noResults')} theme='no-content' />
            )}
            {(!appsRead &&
              <Blur active label={t('global.accessDenied')} icon='lock' />
            )}
          </SimpleTabsBodyItem>
        </SimpleTabsBody>
      </SimpleTabs>
    </div>
  )
}

AppsList.propTypes = {
  installedApps: PropTypes.arrayOf(PropTypes.object).isRequired,
  listedApps: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default AppsList
