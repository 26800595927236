const getIntervals = (min, max, interval) => {
  min = min < 0 ? 0 : min > 23 ? 23 : min
  max = max < 1 ? 1 : max > 24 ? 24 : max
  const toMinutes = str => str.split(':').reduce((h, m) => h * 60 + +m)
  const toString = min => (Math.floor(min / 60) + ':' + ((min % 60).toString().length === 1 ? `0${(min % 60)}` : (min % 60)))
    .replace(/\b\d\b/, '0$&')
  const start = toMinutes(`${toString(min)}:00`)
  const end = max === 24 ? toMinutes(`${toString(max)}:00`) - interval : toMinutes(`${toString(max)}:00`)

  return Array.from({ length: Math.floor((end - start) / interval) + 1 }, (_, i) => toString(start + i * interval))
}

export default getIntervals
