import React from 'react'
import PropTypes from 'prop-types'
import { ActivityLogsItemSystemActionWrapper } from '../../../Beasts'

const ActivityLogsItem = props => {
  const { activityLog } = props
  const { type, id } = activityLog || {}

  return (
    <div className='ta-notifications__activity-logs__item'>
      {type === 'TemporaryRemoteAccessMessage' && <ActivityLogsItemSystemActionWrapper id={id} />}
    </div>
  )
}

ActivityLogsItem.propTypes = {
  activityLog: PropTypes.object.isRequired
}

export default ActivityLogsItem
