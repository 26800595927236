import React from 'react'
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { CHART_DEFAULTS } from '../../Settings'
import { ChartCustomTooltip, ChartCustomizedDot } from '../../Common'

const ChartArea = props => {
  let { options, data } = props
  const { customTooltip } = options || {}
  let emptyFields = false
  data.forEach((item, i) => {
    if (item[options.dataKey] === undefined) {
      // for grey area - not needed right now
      if (!emptyFields) {
        data[i - 1].test = data[i - 1][options.dataKey]
      }
      data[i] = { [options.labelsKey]: item[options.labelsKey], test: !emptyFields ? data[i - 1][options.dataKey] : 20 + i + 5 }
      emptyFields = true
    }
  })

  return (
    <ResponsiveContainer width='100%' minHeight={options.containerMinHeight || '180px'}>
      <AreaChart
        syncId={options.syncId || new Date().getTime()}
        width={options.width || CHART_DEFAULTS.width}
        height={options.height || CHART_DEFAULTS.height}
        margin={options.margin || CHART_DEFAULTS.margin.line}
        data={data}
      >
        <XAxis dataKey={options.labelsKey} interval={options.interval} allowDecimals={false} />
        <YAxis allowDecimals={false} />
        <CartesianGrid
          strokeDasharray={CHART_DEFAULTS.strokeDasharry}
          horizontal={options.horizontalGrid}
          vertical={options.verticalGrid}
          verticalPoints={options.verticalPoints}
        />
        {options.tooltip &&
          <Tooltip cursor={options.cursor || CHART_DEFAULTS.barTooltipCursor} content={options.customTooltip
            ? <ChartCustomTooltip
              className={customTooltip.className}
              getNextLabel={customTooltip.getNextLabel}
              reverseLabelOrder={customTooltip.reverseLabelOrder}
              customLabels={customTooltip.customLabelKey ? data.map(item => ({ label: item[customTooltip.customLabelKey] })) : []}
              labelText={customTooltip.labelText}
              customLabelKey={customTooltip.customLabelKey}
              getLabelFromPayload={customTooltip.getLabelFromPayload}
              active={customTooltip.customLabelKey}
            />
            : null} />
        }
        <Area
          isAnimationActive={false}
          type='monotone'
          dataKey={options.dataKey}
          dot={<ChartCustomizedDot color={options.dotColor} />}
          stroke={options.stroke}
          fill={options.fill}
          fillOpacity={0.1}
          connectNulls
          strokeWidth={2}
        />
        {emptyFields &&
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='test'
            fill='#e5e6e7'
            dot={{ ...options.dot, stroke: '#b6babd' }}
            stroke='#b6babd'
          />
        }
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default ChartArea
