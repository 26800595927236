export default (minutes, translations = {}) => {
  if (!minutes) return '00:00'

  // TODO: Add support for AM/PM dates
  // Conver hh:mm A > HH:mm first
  // @yanko check how to translate this

  let h = Math.floor(minutes / 60)
  // h = h < 10 ? `0${h}` : h

  let d = h > 23 ? Math.floor(h / 24) : 0
  if (d > 0) {
    h = h - (d * 24)
    // h = h < 10 ? `0${h}` : h
  }

  let m = minutes % 60
  // m = m < 10 ? `0${m}` : m

  let result = ''
  if (m > 0) result = `${m} ${translations.minutes}`
  if (h > 0) result = `${h} ${translations.hours} ${result}`
  if (d > 0) result = `${d} ${translations.days} ${result}`

  return result
}
