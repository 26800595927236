import React, { useState, useEffect } from 'react'
import { connect, handlers } from '../../../Store'
import { Loader, t } from '../../../Common'
import { GlobalsSettingsForm } from '../../../Beauties'

const GlobalsSettingsEditWrapper = props => {
  let {
    pending,
    form,
    errors,
    hash,
    account
  } = props
  errors = errors || []
  pending = !!pending
  account = account || {}
  form = form || {}
  const {
    isCompanyTagsLocalUpdateEnabled,
    isServiceLocalUpdateEnabled,
    isCombinationServiceLocalUpdateEnabled,
    isCourseLocalUpdateEnabled,
    isCustomersLocalUpdateEnabled,
    isCustomerFieldsLocalUpdateEnabled,
    isResourceLocalUpdateEnabled,
    singleServiceEdit,
    combinationEdit,
    courseEdit
  } = form || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsWrite } = enterprisePermissions || {}
  settingsWrite = !!isAdmin || !!settingsWrite
  const [initial] = useState({ settingsWrite })

  // ComponentDidMount
  useEffect(() => {
    const { settingsWrite } = initial
    settingsWrite ? handlers.globalsSettingsFormGet() : handlers.navigateToPath('/settings/globals')
  }, [initial])

  const onSubmit = cb => {
    handlers.globalsSettingsFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <GlobalsSettingsForm
          title={t('global.editGlobals')}
          cancelLink='/settings/globals'
          isCompanyTagsLocalUpdateEnabled={isCompanyTagsLocalUpdateEnabled}
          isServiceLocalUpdateEnabled={isServiceLocalUpdateEnabled}
          isCombinationServiceLocalUpdateEnabled={isCombinationServiceLocalUpdateEnabled}
          isCourseLocalUpdateEnabled={isCourseLocalUpdateEnabled}
          isCustomersLocalUpdateEnabled={isCustomersLocalUpdateEnabled}
          isCustomerFieldsLocalUpdateEnabled={isCustomerFieldsLocalUpdateEnabled}
          isResourceLocalUpdateEnabled={isResourceLocalUpdateEnabled}
          singleServiceEdit={singleServiceEdit}
          combinationEdit={combinationEdit}
          courseEdit={courseEdit}
          errors={errors}
          onSubmit={onSubmit}
          hash={hash}
        />
      )}
    </>
  )
}

const maps = state => ({
  id: (state.router && state.router.data && state.router.data.id),
  hash: state.router.hash,
  form: state.forms.globalsSettings,
  errors: state.settings.errors,
  pending: state.globalsSettings.pendingForm,
  account: state.account
})

export default connect(maps)(GlobalsSettingsEditWrapper)
