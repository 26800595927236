import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Row, Col, Input, Error, FontAwesome5, t, Title } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupBranchDelete = props => {
  const { approve, reject, togglePasswordFieldType, passwordFieldType } = props

  return (
    <div className='ta-popup__branch-delete'>
      <Title size='m' isCompact label={dangerousHTML(t('popup.branch.delete'))} />
      <Form name='branchesDelete' onSubmit={approve}>
        <Row>
          <Col className='relative'>
            <Input
              type={passwordFieldType}
              name='password'
              label={t('global.password')}
              mandatory
            />
            <div className='ta-popup__branch-delete__password-type-toggle-btn' onClick={togglePasswordFieldType}>
              <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
            </div>
          </Col>
        </Row>
        <Error name='globalErrors' />
        <div className='ta-popup__buttons'>
          <Button as='link' isSecondary onClick={reject}>
            {t('buttons.cancel.label')}
          </Button>
          <Button type='submit' isPrimary>
            {t('global.delete')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

PopupBranchDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupBranchDelete
