import React from 'react'
import {
  SimpleListItemContent,
  SimpleListItemButtons,
  SimpleListItemHeading,
  SimpleListItemTitle,
  HoverPopupContent,
  HoverPopupTrigger,
  SimpleListItem,
  DnDDraggable,
  FontAwesome5,
  HoverPopup,
  ListButton,
  Price,
  t,
  DottedTimeline,
  DottedTimelineItem,
  Button
} from '../../Common'
import { convertMinutesToDaysHoursMinutes, highlight, truncateText, dangerousHTML } from '../../Utils'

const ServicesListItem = props => {
  const {
    service,
    allServices,
    index,
    isDragAndDropMode,
    areCategoriesExpanded,
    search,
    filterType,
    categoryIndex,
    isCategoryLastIndex
  } = props
  const combinationServiceIds = service.combinationServiceIds || []
  const combinationServices = allServices.filter(item => combinationServiceIds.includes(item.id)) || []
  if (service.isCombination) {
    service.duration = combinationServices.reduce((acc, item) => {
      if (item.duration) acc = acc + item.duration
      return acc
    }, 0)
    if (service.duration === 0) service.duration = null
  }
  const classNames = ['ta-services__service']
  const headerClassNames = ['ta-services__service__header']
  if (isDragAndDropMode) classNames.push('is-draggable')
  if (service.isDeleted) classNames.push('deleted')
  if (service.isUpdated) classNames.push('updated')
  if (service.isStalled) classNames.push('is-stalled') && headerClassNames.push('is-stalled')
  if (service.hasOnlineWarning) classNames.push('is-stalled') && headerClassNames.push('is-stalled')
  if (service.hasOnlinePaymentWarning) classNames.push('is-stalled') && headerClassNames.push('is-stalled')
  const { locallyUpdatedBranches } = service || {}
  const globalLabelClassNames = ['global-label', 'edited']
  const locallyChangedBranches = (
    locallyUpdatedBranches &&
    !!Object.keys(locallyUpdatedBranches || {})
      .filter(item => !Object.keys(locallyUpdatedBranches[item])
        .includes('locallyUpdatedServiceIds')).length
  ) || false
  const globalLabelPosition = 'top'

  let totalTime
  if (service.durationsPattern && service.durationsPattern.length > 1) {
    totalTime = service.durationsPattern.reduce((sum, duration) => sum + duration, 0)
  }
  const translations = {
    minutes: t('global.minutes'),
    hours: t('global.hours'),
    days: t('global.days')
  }

  return (
    <div className={classNames.join(' ')}>
      <DnDDraggable
        className='ta-services__service__dnd-wrapper'
        type='service'
        id={service.id}
        index={index}
        isDragDisabled={!isDragAndDropMode || !areCategoriesExpanded}
      >
        <SimpleListItem
          to={!isDragAndDropMode && `/management/services/${service.id}`}
          status={(service.isStalled || service.hasOnlineWarning || service.hasOnlinePaymentWarning
            ? 'stalled'
            : service.isBookable
              ? 'active'
              : 'inactive'
          )}
          draggable={isDragAndDropMode}
          hasHeading={service.isCombination && !filterType}
        >
          {(service.isCombination && !filterType &&
            <SimpleListItemHeading>{t('servicesGroups.list.serviceCombination.headline')}</SimpleListItemHeading>
          )}
          <SimpleListItemTitle title={highlight(truncateText(service.name, 37, true), search)}>
            {(service.hasOnlinePayment &&
              <FontAwesome5
                icon='cc-stripe'
                type='brand'
                className='ta-stripe-active'
              />
            )}
            {(service.offer &&
              <HoverPopup>
                <HoverPopupContent position={service.name.length > 35 ? 'left' : 'right'}>
                  <strong>{t('servicesGroups.list.group.warning.tooltip.offer')}:</strong>
                  <div>{service.offer.name}</div>
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <FontAwesome5 icon='bullhorn' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
            {(locallyChangedBranches &&
              <HoverPopup>
                <HoverPopupContent position={globalLabelPosition}>
                  {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
                </HoverPopupContent>
                <Button as='link' className='ta-services__service__branch-overtires-button' to={`/management/services/${service.id}@@changedLocally`}>
                  <div className={globalLabelClassNames.join(' ')}>
                    <HoverPopupTrigger>
                      <FontAwesome5 icon='pencil' type='s' />
                      {t('global.changedLocally')}
                    </HoverPopupTrigger>
                  </div>
                </Button>
              </HoverPopup>
            )}
          </SimpleListItemTitle>
          <SimpleListItemContent>
            <ul className='ta-list-inline'>
              {(service.isCombination &&
                <li>
                  <FontAwesome5 icon='layer-group' type='solid' /> {combinationServiceIds.length} {t('global.services')}
                </li>
              )}
              <li><FontAwesome5 icon='users' type='solid' /> {service.resourcesCount} {t('global.resources')}</li>
              {(service.duration &&
                <li><FontAwesome5 icon='hourglass-half' type='solid' /> {convertMinutesToDaysHoursMinutes(service.duration, translations)}</li>
              )}
              {(service.price > 0 &&
                <li><FontAwesome5 icon='money-bill' type='solid' /><Price price={service.price} /></li>
              )}
              {(service.durationsPattern && service.durationsPattern.length > 1 &&
                <>
                  <li>
                    <FontAwesome5 icon='hourglass-half' type='solid' /> {convertMinutesToDaysHoursMinutes(totalTime, translations)}
                  </li>
                  <li>
                    <HoverPopup>
                      <HoverPopupContent position={(!!categoryIndex && isCategoryLastIndex) ? 'top' : 'bottom'} autoSize>
                        <DottedTimeline>
                          {t('global.duration')}:
                          {service.durationsPattern.map((item, index) => {
                            const label = t(index % 2 === 0 ? 'global.interval' : 'global.gap')
                            return (
                              <div key={index}>
                                <DottedTimelineItem type='big'><strong>{label}</strong></DottedTimelineItem>
                                <DottedTimelineItem type='small' className={index === service.durationsPattern.length - 1 ? 'last' : ''}>
                                  {convertMinutesToDaysHoursMinutes(item, translations)}
                                </DottedTimelineItem>
                              </div>
                            )
                          })}
                        </DottedTimeline>
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <FontAwesome5 icon='info-circle' type='solid' />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </li>
                </>
              )}
            </ul>
          </SimpleListItemContent>
          {(isDragAndDropMode &&
            <SimpleListItemButtons>
              <ListButton icon='arrows-v' />
            </SimpleListItemButtons>
          )}
          {((service.isStalled || service.hasOnlineWarning || service.hasOnlinePaymentWarning) &&
            <HoverPopup className='ta-service__popup' disabled={!service.isStalled && !service.hasOnlineWarning && !service.hasOnlinePaymentWarning}>
              <HoverPopupContent position='left'>
                {service.isStalled && t('servicesGroups.list.service.warning.tooltip.dependencies')}
                {service.hasOnlineWarning && t('servicesGroups.list.service.warning.tooltip.online')}
                {service.hasOnlinePaymentWarning && t('servicesGroups.list.service.warning.onlinePayment')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className='ta-services__service__is-stalled'>
                  <FontAwesome5 icon='exclamation-triangle' type='solid' />
                </div>
              </HoverPopupTrigger>
            </HoverPopup>
          )}
        </SimpleListItem>
      </DnDDraggable>
    </div>
  )
}

export default ServicesListItem
