import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  DnDWrapper,
  DnDDroppable,
  Alert,
  Loader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  t
} from '../../Common'
import { CompanyTagsList, CompanyTagsTitle } from '../../Beauties'
import { CompanyTagsToDeleteWrapper } from '../../Beasts'
import { dangerousHTML } from '../../Utils'

import './CompanyTags.css'

const CompanyTags = props => {
  const {
    tags,
    hash,
    pending,
    onDragStart,
    onDragUpdate,
    onDragEnd,
    isDragAndDropMode,
    enterpriseId,
    areExpanded,
    globalTagsWrite
  } = props
  const availableTabs = ['activeCompanyTags', 'deletedCompanyTags']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeCompanyTags'

  return (
    <div className='ta-company-tags'>
      <FixedContent>
        <FixedContentHeader>
          <CompanyTagsTitle
            tags={tags}
            isDragAndDropMode={isDragAndDropMode}
            areExpanded={areExpanded}
            globalTagsWrite={globalTagsWrite}
          />
        </FixedContentHeader>
        <FixedContentBody>
          <Loader active={pending} />
          <div className='ta-company-tags__list'>
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='activeCompanyTags' label={t('tags.activeTags')} icon='sliders-h' />
                <SimpleTabsHeaderItem name='deletedCompanyTags' label={t('tags.forDeletion')} icon='minus-octagon' />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='activeCompanyTags'>
                  {(isDragAndDropMode &&
                    <Alert className='ta-company-tags__reorder-message' theme='alert'>
                      {t('global.changesSaved')}
                      <br />
                      {dangerousHTML(t('global.reordering.exit.text', [{ key: 'ICON', value: renderToString(<FontAwesome5 icon='times' type='solid' />) }]))}
                    </Alert>
                  )}
                  <DnDWrapper
                    onDragUpdate={onDragUpdate}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  >
                    <DnDDroppable id='tag' type='tag'>
                      <CompanyTagsList
                        tags={tags}
                        isDragAndDropMode={isDragAndDropMode}
                        enterpriseId={enterpriseId}
                      />
                    </DnDDroppable>
                  </DnDWrapper>
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='deletedCompanyTags' renderOnlyIfActive>
                  <CompanyTagsToDeleteWrapper />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CompanyTags
