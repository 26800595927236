import React from 'react'
import {
  Row,
  Col,
  t,
  ListItem
} from '../../../Common'

const SettingsBookingPreviewIcsFileCustomisationCustomer = ({ icsNotes, icsAddressObject, icsSubject, customerFields, addresses, subjects }) => {
  const { icsAddressType = '', icsAddress = '' } = icsAddressObject || {}
  let {
    companyNameNote = false,
    onlineBookableResourceNote = false,
    dependantResourceNote = false,
    bookedServiceNote = false,
    bookedServiceDescriptionNote = false,
    bookedServicePriceNote = false,
    customDataFieldsNote = [],
    eventCancellationLink,
    hasCustomNote,
    customNote = ''
  } = icsNotes || {}

  companyNameNote = companyNameNote || false
  onlineBookableResourceNote = onlineBookableResourceNote || false
  dependantResourceNote = dependantResourceNote || false
  bookedServiceNote = bookedServiceNote || false
  bookedServiceDescriptionNote = bookedServiceDescriptionNote || false
  bookedServicePriceNote = bookedServicePriceNote || false
  customDataFieldsNote = customDataFieldsNote || []
  eventCancellationLink = eventCancellationLink || false
  hasCustomNote = hasCustomNote || false
  customNote = customNote || ''

  const dataFields = customDataFieldsNote && customDataFieldsNote.length && customDataFieldsNote.map(item => {
    const field = customerFields.find(({ id }) => id === item.id) || {}
    return field.translationKey ? t(field.translationKey) : field.label || field.defaultId
  }).join('; ')

  return (
    <div className='ta-settings-ics__list_container'>
      {(icsSubject &&
        <>
          <Row>
            <Col>
              <ListItem className='bullet-item'>
                <strong>{t('settings.section.icsFileCustomisation.title.heading')}: </strong>
                {subjects[icsSubject]}
              </ListItem>
            </Col>
          </Row>
        </>
      )}
      {(icsAddressType &&
        <>
          <Row noOffset={icsAddressType === 'CUSTOM_ADDRESS'}>
            <Col>
              <ListItem className='bullet-item'>
                <strong>{t('settings.section.icsFileCustomisation.location.heading')}: </strong>
                {addresses[icsAddressType]}
              </ListItem>
            </Col>
          </Row>
          {(icsAddressType === 'CUSTOM_ADDRESS' &&
            <Row noOffset className='ta-settings-ics__list_items'>
              <Col>
                <ListItem>
                  {icsAddress}
                </ListItem>
              </Col>
            </Row>
          )}
        </>
      )}
      {(
        bookedServiceDescriptionNote ||
        bookedServiceNote ||
        bookedServicePriceNote ||
        companyNameNote ||
        dependantResourceNote ||
        (customDataFieldsNote && customDataFieldsNote.length) ||
        (hasCustomNote && customNote) ||
        onlineBookableResourceNote ||
        eventCancellationLink
      ) && (
        <>
          <Row>
            <Col>
              <ListItem className='bullet-item'>
                <strong>{t('settings.section.icsFileCustomisation.notes.heading')}:</strong>
              </ListItem>
            </Col>
          </Row>
          <Row className='ta-settings-ics__list_indented'>
            <Col>
              {(companyNameNote &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.companyName.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(onlineBookableResourceNote &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.onlineBookableResourceName.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(dependantResourceNote &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.dependentResourceName.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(bookedServiceNote &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.bookedServices.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(bookedServiceDescriptionNote &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.bookedServicesDescription.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(bookedServicePriceNote &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.bookedServicesPrice.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(!!(dataFields && dataFields.length) &&
                <>
                  <Row noOffset>
                    <Col>
                      <ListItem className='bullet-item'>
                        <strong>{t('settings.section.icsFileCustomisation.notes.options.customDataFieldsValues.preview.label')}: </strong>
                      </ListItem>
                    </Col>
                  </Row>
                  <Row noOffset className='ta-settings-ics__list_items'>
                    <Col>
                      <ListItem>
                        {dataFields}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(eventCancellationLink &&
                <>
                  <Row>
                    <Col>
                      <ListItem className='bullet-item'>
                        {t('settings.section.icsFileCustomisation.notes.options.eventCancellationLink.preview.label')}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
              {(hasCustomNote && customNote &&
                <>
                  <Row noOffset>
                    <Col>
                      <ListItem className='bullet-item'>
                        <strong>
                          {t('settings.section.icsFileCustomisation.notes.options.useCustomNote.preview.label')}:
                        </strong>
                      </ListItem>
                    </Col>
                  </Row>
                  <Row noOffset className='ta-settings-ics__list_items'>
                    <Col>
                      <ListItem>
                        {customNote}
                      </ListItem>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default SettingsBookingPreviewIcsFileCustomisationCustomer
