import React from 'react'

const SimpleListItemHeading = props => {
  const { children } = props

  return (
    <div className='ta-simple-list-item__heading' data-testid='simple-list-item-heading'>{children}</div>
  )
}

export default SimpleListItemHeading
