import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesome5 } from '../../Common'

const SelectArrows = (props) => {
  const { arrowRenderer, showOptions, disabled } = props

  const classNames = ['ta-select__arrow']
  if (disabled) classNames.push('disabled')

  return (
    <div
      className={classNames.join(' ')}
      onClick={() => !disabled && showOptions()}
    >
      {arrowRenderer
        ? arrowRenderer()
        : <FontAwesome5 icon='sort' type='solid' />
      }
    </div>
  )
}

SelectArrows.propTypes = {
  disabled: PropTypes.bool,
  showOptions: PropTypes.func,
  arrowRenderer: PropTypes.func
}

export default SelectArrows
