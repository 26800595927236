import React from 'react'
import { connect } from '../../Store'
import { StatisticsBoxNew, Chart } from '../../Common'

const StatisticsWrapper = props => {
  let {
    form,
    statisticType,
    tags,
    resources,
    isResourceStatistic,
    currency,
    isPending
  } = props
  const { label } = form || {}
  const { value: itemId } = label || {}
  tags = tags || []
  resources = resources.resources ? resources.resources : resources || []
  const selectedItem = (isResourceStatistic ? resources : tags).find(item => item.id === itemId) || {}
  const { name: itemName, color: itemColor } = selectedItem || {}

  return statisticType === 'box'
    ? <StatisticsBoxNew
        {...props}
        itemName={itemName}
        itemColor={itemColor}
        isPending={isPending}
        isResourceStatistic={isResourceStatistic}
        statisticType={statisticType}
      />
    : <Chart
        {...props}
        itemName={itemName}
        itemColor={itemColor}
        isPending={isPending}
        currency={currency}
      />
}

const maps = (state, props) => {
  const { router } = state
  const { data } = router || {}
  const { id } = data || {}
  const { statisticId, headerSettingsButton, isOverallStatistic } = props
  const branches = (state.branches && state.branches.list) || []
  const branch = branches.find(item => item.id === id) || {}
  const isPending = headerSettingsButton
    ? branch[`pending${isOverallStatistic ? statisticId + 'Overall' : statisticId}`]
    : state.dashboard && state.dashboard[`pending${isOverallStatistic ? statisticId + 'Overall' : statisticId}`]
  const branchResources = branch.resources || []
  const branchTags = (branch.companyTags || []).filter(item => item.isForEvents)

  return {
    form: (state.forms && state.forms[`${isOverallStatistic ? statisticId + 'Overall' : statisticId}StatisticsForm`]) || {},
    tags: branchTags || [],
    resources: branchResources || [],
    isPending: isPending || false
  }
}

export default connect(maps)(StatisticsWrapper)
