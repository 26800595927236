import React from 'react'
import { connect } from '../../../Store'
import { SettingsBookingPreviewIcsFileCustomisation } from '../../../Beauties'

const SettingsBookingPreviewIcsFileCustomisationWrapper = props => {
  let {
    settings,
    customerFields
  } = props
  settings = settings || {}

  return (
    <SettingsBookingPreviewIcsFileCustomisation
      settings={settings}
      customerFields={customerFields}
    />
  )
}

const maps = state => ({
  settings: state.company.settings,
  customerFields: state.customerFields.list
})

export default connect(maps)(SettingsBookingPreviewIcsFileCustomisationWrapper)
