import React from 'react'
import PropTypes from 'prop-types'
import { Button, t, Title } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServiceDelete = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__customers-delete'>
      <Title isCompact size='m' label={dangerousHTML(t('servicesGroups.preview.service.heading.buttonDelete.tooltip.title'))} />
      <div className='ta-popup__buttons'>
        <Button as='link' isSecondary onClick={reject}>{t('buttons.cancel.label')}</Button>
        <Button as='link' isPrimary onClick={approve}>{t('global.delete')}</Button>
      </div>
    </div>
  )
}

PopupServiceDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupServiceDelete
