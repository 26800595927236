import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../Common'

import './Overlay.css'

const Overlay = props => {
  const { isActive, position, onClick } = props
  const classNames = ['ta-overlay']
  if (isActive) classNames.push('active')
  if (position) classNames.push(`position-${position}`)

  return (
    <Button as='link' action={onClick}>
      <div className={classNames.join(' ')} />
    </Button>
  )
}

Overlay.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default Overlay
