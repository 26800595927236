import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServicesCombinationSameResource = props => {
  return (
    <div className='ta-popup__services-combination-same-resource'>
      <div className='ta-popup__text'>{dangerousHTML(t('servicesGroups.form.section.settings.serviceCombinationSameResource.tooltip.text'))}</div>
    </div>
  )
}

export default PopupServicesCombinationSameResource
