import React, { useEffect } from 'react'

import { AppsSubscriptionBillingForm } from '../../../Beauties'
import { connect, handlers, selectors } from '../../../Store'

const AppsSubscriptionBillingFormWrapper = (props) => {
  let {
    country,
    billingForm,
    paymentMethods,
    chargebee,
    isOwner,
    pendingBilling,
    closeAccordion,
    hasChangesBillingForm
  } = props
  country = country || {}
  billingForm = billingForm || {}
  paymentMethods = paymentMethods || []
  chargebee = chargebee || {}
  const { noAutoCollect } = chargebee

  useEffect(() => {
    handlers.appSubscriptionBillingFormGet()
    handlers.billingFormReady()
  }, [])

  const handleOnAfter = () => {
    handlers.appSubscriptionBillingFormGet()
    handlers.appSubscriptionBillingFormIsUpdated(false)
  }

  const handleOnBillingSubmit = (cb) => {
    if (!hasChangesBillingForm) return
    handlers.billingFormSave({ ...billingForm, noRedirect: true, onAfter: handleOnAfter }, (errors) => {
      cb && cb(errors)
    })
  }

  const handleOnBillingCancel = () => {
    handlers.appSubscriptionBillingFormGet()
    closeAccordion && closeAccordion()
  }

  const managePaymentMethods = () => handlers.chargebeePortalOpen('PAYMENT_SOURCES')

  return (
    <AppsSubscriptionBillingForm
      country={country}
      paymentMethods={paymentMethods}
      noAutoCollect={noAutoCollect}
      handleOnBillingSubmit={handleOnBillingSubmit}
      managePaymentMethods={managePaymentMethods}
      pending={pendingBilling}
      isOwner={isOwner}
      hasChangesBillingForm={hasChangesBillingForm}
      handleOnBillingCancel={handleOnBillingCancel}
    />
  )
}

const maps = state => ({
  billingForm: selectors.formSelector(state, { formName: 'appSubscriptionBilling' }),
  paymentMethods: state.paymentMethods.list,
  chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' }),
  country: selectors.countrySelector(state),
  pendingBilling: selectors.billingFieldSelector(state, { field: 'pendingForm' }),
  isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
  hasChangesBillingForm: selectors.appsFieldSelector(state, { field: 'hasChangesBillingForm' })
})

export default connect(maps)(AppsSubscriptionBillingFormWrapper)
