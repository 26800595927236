import React from 'react'
import PropTypes from 'prop-types'
import { PATH_TO_CIRCLE_FLAGS, DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'
import { Image, t } from '../../../Common'

const CountrySelector = props => {
  const {
    countries,
    selected = DEFAULT_LOCALE_COUNTRY_CODE,
    onChangeCountry,
    toggle
  } = props

  const country = countries.find(country => country.code === selected.toUpperCase()) || {
    code: 'GB',
    flag: 'GB.svg',
    name: 'global.country.greatBritain',
    phoneCountry: '44',
    active: true
  }

  return (
    <>
      <div className='ta-phone-input__country-selector'>
        <div className='ta-phone-input__selected-country' onClick={toggle}>
          <Image src={PATH_TO_CIRCLE_FLAGS[country.flag.split('.')[0]]} alt={country.code} />
        </div>
        <ul className='ta-phone-input__countries'>
          {countries
            .filter(c => c.active)
            .sort((a, b) => t(a.name) > t(b.name) ? 1 : -1)
            .map(c => (
              <li
                key={c.code}
                onClick={() => onChangeCountry(c.code)}
                className={c.code === country.code ? 'active' : ''}
              >
                <Image src={PATH_TO_CIRCLE_FLAGS[c.flag.split('.')[0]]} alt={c.code} />
                {t(c.name)}
                <span>(+{c.phoneCountry})</span>
              </li>
            ))
          }
        </ul>
      </div>
      <div className='ta-phone-input__country_code'>+{country.phoneCountry} (0)</div>
    </>
  )
}

CountrySelector.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string,
  onChangeCountry: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired
}

export default CountrySelector
