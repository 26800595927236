import React, { useEffect } from 'react'
import { connect, handlers, store, selectors } from '../../../Store'
import { PopupAppsPaidInstall } from '../../../Beauties'

const PopupAppsPaidInstallWrapper = props => {
  let {
    id,
    app,
    pending,
    closePopup,
    subscriptionScope,
    billingForm
  } = props
  pending = !!pending
  billingForm = billingForm || {}
  const { name, trialUnits, unitDescriptionPlural, externalId } = app || {}

  useEffect(() => {
    handlers.appSubscriptionBillingFormGet()
    handlers.appsSubscriptionRuleFormPopulate({ id, externalId })
  }, [id, externalId])

  const handleOnSubmit = (cb) => {
    const form = store.getState().forms.popup
    handlers.appSubscriptionRuleFormSave({ ...form, id, ...billingForm })
  }

  const handleOnCancel = () => {
    closePopup && closePopup()
  }

  return (
    <PopupAppsPaidInstall
      onSubmit={handleOnSubmit}
      onCancel={handleOnCancel}
      pending={pending}
      name={name}
      subscriptionScope={subscriptionScope}
      trialUnits={trialUnits}
      unitDescriptionPlural={unitDescriptionPlural}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    pending: selectors.appsFieldSelector(state, { field: 'pendingSubscriptionRuleForm' }),
    subscriptionScope: selectors.formFieldPropertySelector(state, { formName: 'popup', name: 'subscriptionScope', property: 'value' }),
    billingForm: selectors.formSelector(state, { formName: 'appSubscriptionBilling' })
  }
}

export default connect(maps)(PopupAppsPaidInstallWrapper)
