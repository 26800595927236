import React from 'react'
import moment from 'moment'
import {
  Title,
  HintText,
  DateInput,
  Form,
  FormButtons,
  FormSection,
  Spinner,
  FontAwesome5,
  Button,
  Row,
  Col,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  format,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'

const BranchesFormExport = props => {
  const {
    showMessage,
    onSubmit,
    onClose,
    pending,
    lastBranchesExport,
    areThereErrors,
    timezone
  } = props
  let isAvailable = true
  let formattedNextAvailableExportTime
  if (lastBranchesExport) {
    const now = moment.utc()
    const nextAvailableExportTime = moment.utc(lastBranchesExport).add(6, 'hours')
    formattedNextAvailableExportTime = format(nextAvailableExportTime.format('YYYY-MM-DDTHH:mm'), 'longWithTime')
    const diff = moment.duration(nextAvailableExportTime.diff(now)).asHours()
    isAvailable = diff < 0
  }
  isAvailable = ['development', 'staging'].includes(process.env.REACT_APP_ENV) ? true : isAvailable

  const classNames = ['ta-export-branches-form__wrapper']
  if (showMessage) classNames.push('success')
  if (areThereErrors) classNames.push('has-errors')

  return (
    <div className={classNames.join(' ')}>
      <Title size='m' label={t('branches.export.title')} isCompact />
      {pending
        ? <Spinner />
        : (
          <>
            {(showMessage &&
              <div className='ta-export-branches-form__success-container'>
                <Row>
                  <Col className='ta-export-branches-form__success-icon-container'>
                    <div className='ta-export-branches-form__success-icon'>
                      <FontAwesome5 icon='check' type='s' />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='ta-export-branches-form__success-text-container'>
                    {dangerousHTML(t('branches.export.messageSuccessText'))}
                  </Col>
                </Row>
                <FormButtons className='ta-export-branches-form__success-button-container'>
                  <Button
                    as='link'
                    external
                    isSecondary
                    onClick={onClose}
                    style={{ fontWeight: 'bold' }}
                  >
                    {t('buttons.closeWindow.label')}
                  </Button>
                </FormButtons>
              </div>
            )}
            {(!showMessage &&
              <Form name='branchesExport' onSubmit={onSubmit}>
                <FormSection>
                  <Row>
                    <Col size={50}>
                      <DateInput
                        name='begin'
                        label={t('global.from')}
                        icon='calendar'
                        timezone={timezone}
                        mandatory
                      />
                    </Col>
                    <Col size={50}>
                      <DateInput
                        name='end'
                        label={t('global.until')}
                        icon='calendar'
                        timezone={timezone}
                        mandatory
                      />
                    </Col>
                  </Row>
                </FormSection>
                <FormSection>
                  {/* <Row>
                    <Col>
                      <Radio name='exportMode' label={t('branches.export.csv')} value='CSV' hideError />
                      <Radio name='exportMode' label={t('branches.export.excel')} value='EXCEL' hideError />
                      <Radio name='exportMode' label={t('branches.export.excelSheet')} value='EXCEL_SHEET' hideError />
                    </Col>
                  </Row> */}
                  <HintText type='info' show>
                    {t('branches.export.file.hint')}
                    <br />
                  </HintText>
                </FormSection>
                <FormButtons className={(!areThereErrors && !showMessage) && 'no-error'}>
                  <Button
                    as='link'
                    external
                    isSecondary
                    onClick={onClose}
                  >
                    {t('buttons.cancel.label')}
                  </Button>
                  <HoverPopup disabled={isAvailable}>
                    <HoverPopupContent position='left'>
                      {t('branches.export.tryAgainLater', [{ key: 'TIME', value: `${formattedNextAvailableExportTime} (${timezone})` }])}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button
                        type='submit'
                        isSecondary={showMessage || !isAvailable}
                        isPrimary={!(showMessage || !isAvailable)}
                        disabled={showMessage || !isAvailable}
                      >
                        <FontAwesome5 icon='print' type='solid' /> {t('branches.export.exportBtn')}
                      </Button>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </FormButtons>
              </Form>
            )}
          </>
          )}
    </div>
  )
}

export default BranchesFormExport
