import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { TagsList } from '../../Beauties'
import { Loader } from '../../Common'
import { sortAlphabetically } from '../../Utils'

class TagsListWrapper extends Component {
  componentDidMount () {
    handlers.tagsListGet()
  }

  render () {
    const { tags, pending } = this.props

    return (
      <>
        <Loader active={pending} />
        {!pending && <TagsList tags={tags} />}
      </>
    )
  }
}

TagsListWrapper.propTypes = {}

const maps = state => {
  const tags = (state.tags && state.tags.list) || []
  const search = state.forms.tagsFilters && state.forms.tagsFilters.search && state.forms.tagsFilters.search.value.toString().toLowerCase()

  return {
    pending: !!(state.tags && state.tags.pendingList),
    tags: (search !== '')
      ? tags.sort(sortAlphabetically).filter(tag => tag.name && tag.name.toString().toLowerCase().search(search) !== -1)
      : tags.sort(sortAlphabetically)
  }
}

export default connect(maps)(TagsListWrapper)
