export default (hex, opacity, shading) => {
  if (!hex) return
  let R = parseInt(hex.substring(1, 3), 16)
  let G = parseInt(hex.substring(3, 5), 16)
  let B = parseInt(hex.substring(5, 7), 16)

  if (shading) {
    R = parseInt(R * (100 + shading) / 100, 10)
    G = parseInt(G * (100 + shading) / 100, 10)
    B = parseInt(B * (100 + shading) / 100, 10)
    R = (R < 255) ? R : 255
    G = (G < 255) ? G : 255
    B = (B < 255) ? B : 255
  }

  // Conver back to hex
  // const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
  // const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
  // const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))
  // return `#${RR}${GG}${BB}`

  // return `rgba(${
  //   (hex = hex.replace('#', ''))
  //     .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
  //     .map((l) => { return parseInt(hex.length % 2 ? l + l : l, 16) })
  //     .concat(opacity || 1).join(',')
  // })`
  return `rgba(${R}, ${G}, ${B}, ${opacity || 1})`
}
