import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import { Button, FontAwesome5 } from '../../Common'
import { truncateText } from '../../Utils'

const StatisticsCompositeHeader = props => {
  const {
    title,
    subTitle,
    className,
    statisticId,
    isResourceStatistic,
    isOverallStatistic,
    headerSettingsButton,
    itemColor,
    itemName,
    filterLabel,
    filterLabelMaxLength
  } = props
  const classNames = ['ta-statistics-composite__header']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-statistics-composite__header__text-wrapper'>
        <h3 className='ta-statistics-composite__header-main-text'>{title || ''}</h3>
        <h4 className='ta-statistics-composite__header-sub-text'>{subTitle || ''}</h4>
      </div>
      {(headerSettingsButton &&
        <div className='ta-statistics-composite__header__filter-wrapper'>
          {(filterLabel &&
            <div className='ta-simple-box-label-button' style={{ background: itemColor }}>{truncateText(itemName || filterLabel, filterLabelMaxLength, true)}</div>
          )}
          <PopupTriggerWrapper
            name={`${headerSettingsButton}-statistics-settings`}
            position='left'
            extraClassName='ta-statistics-box'
            id={{ statisticId, isResourceStatistic, isOverallStatistic }}
            customOffset={{ position: 'left', offset: '142px' }}
          >
            <Button as='link' className='ta-dashboard-statistics__link' onClick={(e) => e.target.classList.add('active')} external>
              <FontAwesome5 icon='filter' type='s' />
            </Button>
          </PopupTriggerWrapper>
        </div>
      )}
    </div>
  )
}

export default StatisticsCompositeHeader
