import React from 'react'
import {
  BorderedBox,
  Row,
  Col,
  Checkbox,
  Alert,
  SingleSelect,
  MultipleSelect,
  HintText,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { PermissionTypeAccordion } from '../../../Beauties'

import './PermissionTypesPermissionsForm.css'

const PermissionTypesPermissionsForm = props => {
  let {
    availableResourceCategories,
    // Calendar
    permissionsCalendarRead,
    permissionsCalendarReadOptions,
    permissionsCalendarWrite,
    // ShiftPlan
    permissionsShiftPlanRead,
    permissionsShiftPlanWrite,
    // Customers
    permissionsCustomersRead,
    permissionsCustomersReadOptions,
    permissionsCustomersWrite,
    // Resources
    permissionsResourcesRead,
    permissionsResourcesReadOptions,
    permissionsResourcesWrite,
    onCategoryChange
  } = props
  permissionsCalendarRead = !!permissionsCalendarRead
  permissionsResourcesRead = !!permissionsResourcesRead
  permissionsCalendarWrite = !!permissionsCalendarWrite
  permissionsShiftPlanRead = !!permissionsShiftPlanRead
  permissionsCustomersRead = !!permissionsCustomersRead
  permissionsShiftPlanWrite = !!permissionsShiftPlanWrite
  permissionsCustomersWrite = !!permissionsCustomersWrite
  permissionsResourcesWrite = !!permissionsResourcesWrite
  availableResourceCategories = availableResourceCategories || []
  permissionsCalendarReadOptions = permissionsCalendarReadOptions || ''
  permissionsCustomersReadOptions = permissionsCustomersReadOptions || ''
  permissionsResourcesReadOptions = permissionsResourcesReadOptions || ''
  const resourceCategoriesOptions = availableResourceCategories.map(item => ({
    label: item.name === 'default' ? t('resources.list.sectionDefault.title') : item.name,
    value: item.id
  }))

  return (
    <div className='ta-resources__form__acl'>
      <Alert theme='alert'>
        {dangerousHTML(t('resources.form.permissions.adminMessage'))}
      </Alert>
      <PermissionTypeAccordion title={t('global.account')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsAccountRead' theme='switch' />
          </Col>
        </Row>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.calendarAndScheduling')}>
        <div className='ta-resources__form__acl__row__wrapper'>
          <Row className='ta-resources__form__acl__row-title'>
            <Col size={15}>
              <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsCalendarRead' theme='switch' />
            </Col>
          </Row>
          {(permissionsCalendarRead &&
            <BorderedBox>
              <Row>
                <Col>
                  <SingleSelect
                    name='permissionsCalendarReadOptions'
                    label={t('global.read')}
                    hasAll
                    allLabel={t('permissionTypes.form.permissions.readSelected.calendarAll')}
                    options={[{
                      label: t('permissionTypes.form.permissions.readSelected.calendarSelected'),
                      value: 'selected'
                    }]}
                  />
                </Col>
              </Row>
              {(permissionsCalendarReadOptions === 'selected' &&
                <>
                  <HintText>
                    {t('resources.form.permissions.calendarReadSelected.hint')}
                    {/* This resource will have access to Calendars &amp; Schedulings for resources selected below and himself */}
                  </HintText>
                  <Row>
                    <Col>
                      <MultipleSelect
                        name='permissionsCalendarReadCategories'
                        label={t('resources.form.permissions.chooseResourceCategory.label')}
                        options={resourceCategoriesOptions}
                        onChangeAddon={onCategoryChange}
                        searchable
                      />
                    </Col>
                  </Row>
                </>
              )}
            </BorderedBox>
          )}
        </div>
        {(permissionsCalendarRead &&
          <>
            <Row className='ta-resources__form__acl__row-title'>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsCalendarWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsCalendarWrite &&
              <Row className='ta-resources__form__acl__row-title'>
                <Col size={15}>
                  <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsCalendarDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.shiftplan')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsShiftPlanRead' theme='switch' />
          </Col>
        </Row>
        {(permissionsShiftPlanRead &&
          <>
            <Row className='ta-resources__form__acl__row-title'>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsShiftPlanWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsShiftPlanWrite &&
              <Row className='ta-resources__form__acl__row-title'>
                <Col size={15}>
                  <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsShiftPlanDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.statistics')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsStatisticsRead' theme='switch' />
          </Col>
        </Row>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.notifications')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsNotificationsRead' theme='switch' />
          </Col>
        </Row>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.customers')}>
        <div className='ta-resources__form__acl__row__wrapper'>
          <Row className='ta-resources__form__acl__row-title'>
            <Col size={15}>
              <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsCustomersRead' theme='switch' />
            </Col>
          </Row>
          {(permissionsCustomersRead &&
            <BorderedBox>
              <Row>
                <Col>
                  <SingleSelect
                    name='permissionsCustomersReadOptions'
                    label={t('global.read')}
                    hasAll
                    allLabel={t('permissionTypes.form.permissions.readSelected.allCustomers')}
                    options={[{
                      label: t('permissionTypes.form.permissions.readSelected.selectedCustomers'),
                      value: 'selected'
                    }]}
                  />
                </Col>
              </Row>
              {(permissionsCustomersReadOptions === 'selected' &&
                <>
                  <HintText>
                    {t('resources.form.permissions.customersReadSelected.hint')}
                  </HintText>
                  <Row>
                    <Col>
                      <MultipleSelect
                        name='permissionsCustomersReadCategories'
                        label={t('resources.form.permissions.chooseResourceCategory.label')}
                        options={resourceCategoriesOptions}
                        onChangeAddon={onCategoryChange}
                        searchable
                      />
                    </Col>
                  </Row>
                </>
              )}
            </BorderedBox>
          )}
        </div>
        {(permissionsCustomersRead &&
          <>
            <Row className='ta-resources__form__acl__row-title'>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsCustomersWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsCustomersWrite &&
              <Row className='ta-resources__form__acl__row-title'>
                <Col size={15}>
                  <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsCustomersDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.resources')}>
        <div className='ta-resources__form__acl__row__wrapper'>
          <Row className='ta-resources__form__acl__row-title'>
            <Col size={15}>
              <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsResourcesRead' theme='switch' />
            </Col>
          </Row>
          {(permissionsResourcesRead &&
            <BorderedBox>
              <Row>
                <Col>
                  <SingleSelect
                    name='permissionsResourcesReadOptions'
                    label={t('global.read')}
                    hasAll
                    allLabel={t('permissionTypes.form.permissions.readSelected.allResources')}
                    options={[{
                      label: t('permissionTypes.form.permissions.readSelected.selectedResources'),
                      value: 'selected'
                    }]}
                  />
                </Col>
              </Row>
              {(permissionsResourcesReadOptions === 'selected' &&
                <>
                  <HintText>
                    {t('resources.form.permissions.resourceReadSelected.hint')}
                  </HintText>
                  <Row>
                    <Col>
                      <MultipleSelect
                        name='permissionsResourcesReadCategories'
                        label={t('resources.form.permissions.chooseResourceCategory.label')}
                        options={resourceCategoriesOptions}
                        onChangeAddon={onCategoryChange}
                        searchable
                      />
                    </Col>
                  </Row>
                </>
              )}
            </BorderedBox>
          )}
        </div>
        {(permissionsResourcesRead &&
          <>
            <Row className='ta-resources__form__acl__row-title'>
              <Col size={15}>
                <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsResourcesWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsResourcesWrite &&
              <Row className='ta-resources__form__acl__row-title'>
                <Col size={15}>
                  <div className='ta-resources__form__acl__label'>{t('global.delete')}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsResourcesDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.servicesAndGroups')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox disabled name='permissionsServicesRead' theme='switch' />
          </Col>
        </Row>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.write')}:</div>
          </Col>
          <Col>
            <Checkbox disabled name='permissionsServicesWrite' theme='switch' />
          </Col>
        </Row>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.delete').toUpperCase()}:</div>
          </Col>
          <Col>
            <Checkbox disabled name='permissionsServicesDelete' theme='switch' />
          </Col>
        </Row>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.apps')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsAppsRead' theme='switch' />
          </Col>
        </Row>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.permissionTypes')}>
        <Row className='ta-resources__form__acl__row-title'>
          <Col size={15}>
            <div className='ta-resources__form__acl__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox disabled name='permissionsGroupsRead' theme='switch' />
          </Col>
        </Row>
      </PermissionTypeAccordion>
    </div>
  )
}

export default PermissionTypesPermissionsForm
