import React, { memo } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesResourceFormAllowancePlan } from '../../Beauties'
import { sortWeekDays } from '../../Utils'

const BranchesPreviewResourceFormAllowancePlanWrapper = props => {
  const {
    plan,
    router,
    form,
    hasDifferentWeeklyAllowancePlans,
    hasDifferentTimezone,
    timezones,
    customisations
  } = props

  const { hash, query = {}, data = {} } = router
  const { branchId, id } = data
  const days = sortWeekDays([...Array(7).keys()])

  const onDifferentWeeklyAllowancePlansChange = (value, name) => {
    if (value) {
      const updateQuery = {}

      days.forEach(day => {
        updateQuery[`bookingWeeklyAllowancePlanDay${day}Intervals`] = {
          isActive: form[`workingWeeklyAllowancePlanDay${day}Intervals`].isActive,
          values: [...form[`workingWeeklyAllowancePlanDay${day}Intervals`].values.map(item => ({ ...item }))],
          errors: []
        }
      })
      handlers.formFieldsUpdate('resources', updateQuery)
    }

    const redirectUrl = `/branches/${branchId}/resources/${id}`
    const type = value ? 'bookingWeeklyAllowancePlan' : 'workingWeeklyAllowancePlan'
    const qry = query.c ? `?c=${query.c}` : ''

    handlers.navigateToPath(`${redirectUrl}${qry}@@${type}`)
  }

  return (
    <BranchesResourceFormAllowancePlan
      plan={plan}
      hasDifferentTimezone={hasDifferentTimezone}
      hasDifferentWeeklyAllowancePlans={hasDifferentWeeklyAllowancePlans}
      onDifferentWeeklyAllowancePlansChange={onDifferentWeeklyAllowancePlansChange}
      hash={hash}
      timezones={timezones.map(item => ({ value: item.code, label: item.label }))}
      customisations={customisations}
    />
  )
}

const maps = state => ({
  id: state.router.data.id,
  router: state.router || {},
  form: (state.forms && state.forms.branchesResource) || {},
  hasDifferentWeeklyAllowancePlans: (state.forms.branchesResource.hasDifferentWeeklyAllowancePlans && state.forms.branchesResource.hasDifferentWeeklyAllowancePlans.value) || false,
  hasDifferentTimezone: (state.forms.branchesResource.hasDifferentTimezone && state.forms.branchesResource.hasDifferentTimezone.value) || false,
  timezones: state.staticData.timezones || []
})

export default memo(connect(maps)(BranchesPreviewResourceFormAllowancePlanWrapper))
