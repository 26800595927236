import React from 'react'
import {
  UserGroupList,
  UserGroupTitle
} from '../../Beauties'

import {
  Button,
  Alert,
  t
} from '../../Common'

import './UserGroup.css'
// TODO @Mitko /wait for new designs Users/User groups
const UserGroup = props => {
  const classNames = ['ta-user-groups']

  const { users } = props

  return (
    <div className={classNames.join(' ')}>
      <UserGroupTitle />
      <div>
        {users.length > 0 && <UserGroupList users={users} />}
      </div>
      {(!users.length &&
        <Alert noOffset label={t('userGroup.list.noResults')} theme='no-content'>
          <Button as='link' isPrimary>{t('userGroup.list.add')}</Button>
        </Alert>
      )}
    </div>
  )
}

UserGroup.propTypes = {}

export default UserGroup
