import React from 'react'
import PropTypes from 'prop-types'
import { Spinner, Button } from '../../Common'
import { dangerousHTML } from '../../Utils'
import './OverlayMessage.css'

const OverlayMessage = props => {
  const { isActive, message, buttonLabel } = props
  const classNames = ['ta-overlay-message']
  if (isActive) classNames.push('active')
  const onClick = () => {
    window.location.reload()
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-overlay-message__message'>
        <Spinner />
        {dangerousHTML(message)}
        <br /><br />
        <Button as='link' onClick={onClick} isPrimary>
          {buttonLabel}
        </Button>
      </div>
    </div>
  )
}

OverlayMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  buttonLabel: PropTypes.string
}

export default OverlayMessage
