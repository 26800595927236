import React from 'react'

const ChartCustomTooltip = props => {
  let {
    active,
    payload,
    label,
    textBeforeLabel,
    textAfterLabel,
    labelText = '',
    customLabels = [],
    className,
    getNextLabel,
    reverseLabelOrder,
    getLabelFromPayload,
    customLabelKey
  } = props
  const { value: payloadValue, dataKey } = (payload || [{}])[0] || {}
  let customLabel = (customLabels.find((item = {}) => (item.label || '').includes(label)) || { label: '' }) || {}
  const customLabelIndex = customLabels.indexOf(customLabel)
  const nextCustomLabel = customLabels[customLabelIndex + 1] || customLabels[0]
  if (getNextLabel && customLabel) {
    customLabel = { label: (labelText || '').replace('%BEGIN%', customLabel.label).replace('%END%', nextCustomLabel.label) }
  }
  const classNames = ['ta-chart__custom-tooltip']
  if (className) classNames.push(className)
  if (active) {
    return (
      <div className={classNames.join(' ')}>
        {!reverseLabelOrder
          ? <>
            <p className='ta-chart__custom-tooltip__label'>{textBeforeLabel || ''}{customLabel.label || label}{textAfterLabel || ''}
            </p>
            <p className='ta-chart__custom-tooltip__data'>{`${dataKey} : ${payloadValue}`}</p>
          </>
          : <>
            <p className='ta-chart__custom-tooltip__data'>{`${payloadValue} ${dataKey}`}</p>
            <p className='ta-chart__custom-tooltip__label'>{
              getLabelFromPayload
                ? `${(payload || [{}])[0].payload[customLabelKey] || ''}`
                : `${textBeforeLabel || ''}${customLabel.label || label}${textAfterLabel || ''}`
            }</p>
          </>
        }
      </div>
    )
  }

  return null
}

export default ChartCustomTooltip
