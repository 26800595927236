
import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  Title,
  FontAwesome5,
  Button,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const TagsTitle = props => {
  const { tagsWrite } = props

  return (
    <div>
      <Title icon='building' iconTheme='grey' label={t('global.tags')}>
        <PopupTriggerWrapper name='tags-help-box' position='bottom' extraClassName='ta-help-box'>
          <Button as='link' className='ta-title__link' external>
            {t('global.heading.help')} <FontAwesome5 icon='lightbulb' type='r' />
          </Button>
        </PopupTriggerWrapper>
        {(tagsWrite &&
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('tags.add')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Button as='link' to='/branches/tags/add' isPrimary icon='plus' iconType='s' />
            </HoverPopupTrigger>
          </HoverPopup>
        )}
      </Title>
    </div>
  )
}

export default TagsTitle
