import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Input,
  ColorSelect,
  Checkbox,
  MultipleSelect,
  Error,
  Alert,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const CompanyTagForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    type,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist,
    location
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-company-tags__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='companyTags'>
            <FormSection>
              {(showExternalIds &&
                <FormSection>
                  <Title size='m' isCompact label={t('global.externalId')} icon='fingerprint' />
                  <Row>
                    <Col>
                      <Input
                        name='externalId'
                        label={t('global.externalId')}
                        hintText={t('global.externalId.hint')}
                      />
                    </Col>
                  </Row>
                </FormSection>
              )}
              <Title size='m' isCompact label={t('global.name')} icon='sliders-h' />
              <Row>
                <Col>
                  <ColorSelect name='color' pattern='dotted'>
                    <Input
                      name='name'
                      label={t('global.name.label')}
                      hintText={t('customerTags.form.fieldName.hint')}
                      mandatory
                    />
                  </ColorSelect>
                </Col>
              </Row>
              {(type === 'edit' &&
                <Alert theme='alert'>
                  <p>{dangerousHTML(t('customerTags.edit.note.text'))}</p>
                </Alert>
              )}
              <Row>
                <Col>
                  <Checkbox name='isForEvents' label={t('global.appointmentTag')} disabled={type === 'edit'} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox name='isForCustomers' label={t('global.customerTag')} disabled={type === 'edit'} />
                </Col>
              </Row>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            {(!allowOnlyGlobalCustomers &&
              <FormSection>
                <Title size='m' isCompact label={t('global.location.label')} icon='building' />
                <Row>
                  <Col>
                    <MultipleSelect
                      name='location'
                      label={t('global.select.label')}
                      searchable
                      hasAll={hasAll}
                      disableSelected={type === 'edit'}
                      allLabel={t('global.allLocations')}
                    />
                  </Col>
                </Row>
                {(type === 'edit' &&
                  <Alert theme='alert'>
                    <p>{dangerousHTML(t('form.edit.location.note.text'))}</p>
                  </Alert>
                )}
                <Row>
                  <Col>
                    <Error name='locationErrors' />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CompanyTagForm
