import React from 'react'
import { StatisticsCompositeChildHeader } from '../../Beauties'
import { Price } from '../../Common'

const StatisticsCompositeSingleNumber = props => {
  const {
    isShort,
    number,
    text,
    isFinancial,
    currency
  } = props
  const classNames = ['ta-statistics-composite-child', 'single-number']
  const bodyClassNames = ['ta-statistics-composite-child__body']
  if (isShort) {
    classNames.push('short')
    bodyClassNames.push('short')
  }
  const value = (isFinancial && currency)
    ? <><Price price={number} currency={currency} digits={number !== null ? 2 : 0} />{number === null && ',-'}</>
    : number === null
      ? '-'
      : number
  return (
    <div className={classNames.join(' ')}>
      <StatisticsCompositeChildHeader text={text} />
      <div className={bodyClassNames.join(' ')}>
        <p className='ta-statistics-composite-child__number'>{value}</p>
      </div>
    </div>
  )
}

export default StatisticsCompositeSingleNumber
