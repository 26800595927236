import React, { useEffect } from 'react'
import { handlers, connect } from '../../../Store'
import { PopupDashboardStatisticsSettings } from '../../../Beauties'

export const PopupDashboardStatisticsSettingsWrapper = props => {
  // to be refactored if used
  let { tags, id, form } = props
  const { statisticId } = id || {}
  useEffect(() => {
    !Object.keys(form).length && handlers.formSet(`${statisticId}StatisticsForm`, {
      search: { value: '' },
      label: { value: '' }
    })
    // reposition the modal
    const el = document.querySelector('.ta-popup__content.left.ta-statistics-box')
    const leftOffset = parseInt(el.style.left.replace('px', ''))
    el.style.left = `${leftOffset + 127}px`
    return () => {
      handlers.formFieldsUpdate(`${statisticId}StatisticsForm`, { search: { value: '' } })
    }
  }, [form, statisticId]) // ???????????????

  const onSelectTag = tag => {
    if (!tag) return
    const { closePopup } = props
    const { id: tagId } = tag || {}
    const filter = { tagId }
    closePopup && closePopup()
    handlers.dashboardStatisticFilter(statisticId, filter) // to be changed
    handlers.formFieldsUpdate(`${statisticId}StatisticsForm`, { label: { value: tagId } })
  }
  const { search } = form || {}
  let { value: searchValue } = search || {}
  searchValue = searchValue ? searchValue.toLowerCase() : ''
  tags = tags || []
  const filteredTags = tags
    .filter(item => {
      let { name } = item
      name = name || ''
      return (
        (name.toLowerCase().includes(searchValue))
      )
    })
  filteredTags.unshift({ name: 'View all', color: '' }) // color may vary depending on colors var

  return (
    <PopupDashboardStatisticsSettings
      search={searchValue}
      tags={filteredTags}
      statisticId={statisticId}
      onSelectTag={onSelectTag}
    />
  )
}

const maps = (state, props) => {
  const { id } = props
  const { statisticId = '' } = id || {}
  return {
    form: (state.forms && state.forms[`${statisticId}StatisticsForm`]) || {},
    tags: (state.tags && state.tags.list) || []
  }
}

export default connect(maps)(PopupDashboardStatisticsSettingsWrapper)
