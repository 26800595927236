import React from 'react'
import PropTypes from 'prop-types'
import { connect, selectors } from '../../Store'
import { ActivityLogsItemSystemAction } from '../../Beauties'

const ActivityLogsItemSystemActionWrapper = props => {
  let { search, systemActionsActivityLog } = props
  search = search || ''

  return (
    <ActivityLogsItemSystemAction
      activityLog={systemActionsActivityLog}
      search={search}
    />
  )
}

ActivityLogsItemSystemActionWrapper.propTypes = {
  id: PropTypes.string.isRequired
}

const maps = (state, props) => ({
  systemActionsActivityLog: selectors.activityLogsSystemActions(state, { systemActionId: props.id }),
  search: selectors.formFieldPropertySelector(state, { formName: 'activityLogsFilters', name: 'search', property: 'value' })
})

export default connect(maps)(ActivityLogsItemSystemActionWrapper)
