import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { feedContextInProps } from '../../../Utils'
import { SimpleTabsContext } from '../../../Common'

class SimpleTabsBodyItem extends Component {
  render () {
    const {
      name,
      children,
      className,
      renderOnlyIfActive,
      active
    } = this.props
    const classNames = ['ta-simple-tabs__body-item']
    if (className) classNames.push(className)
    if (name === active) classNames.push('active')
    classNames.push(`ta-simple-tabs-${name}`)

    return (
      <div className={classNames.join(' ')}>
        {renderOnlyIfActive && name === active &&
          children
        }
        {!renderOnlyIfActive &&
          children
        }
      </div>
    )
  }
}

SimpleTabsBodyItem.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default feedContextInProps(SimpleTabsBodyItem, SimpleTabsContext)
