import React from 'react'
import PropTypes from 'prop-types'
import {
  MenuWrapper,
  NavigationAccountWrapper,
  AccountsSwitchWrapper
} from '../../Beasts'
import {
  NavigationLinks,
  NavigationToggleArrow,
  LogoBadge
} from '../../Beauties'

import './Navigation.css'

const Navigation = props => {
  const {
    accountsCount,
    isCollapsed,
    locale,
    isHover,
    negativeLevel,
    expand,
    collapse,
    onMouseEnter,
    onMouseLeave,
    disabled,
    customisations
  } = props
  const {
    hideSupportLink,
    customSupportLink
  } = customisations || {}
  const showAccountSwitch = accountsCount > 1

  const classNames = ['ta-navigation']
  if (!isCollapsed) classNames.push('expand')
  if (isHover) classNames.push('hover')
  if (negativeLevel) classNames.push(`negative-${negativeLevel}`)
  if (showAccountSwitch) classNames.push('show-account-switch')

  const expandClassNames = ['ta-navigation__expand']
  if (!disabled && isCollapsed) expandClassNames.push('active')

  return (
    <>
      <div className={classNames.join(' ')}>
        <NavigationToggleArrow isCollapsed={isCollapsed} collapse={collapse} disabled={disabled} />
        <LogoBadge text='BRANCH MANAGER' color='#505a5e' background='#ecd007' />
        <NavigationAccountWrapper />
        {(showAccountSwitch &&
          <AccountsSwitchWrapper />
        )}
        <MenuWrapper />
        <NavigationLinks locale={locale} hideSupportLink={hideSupportLink} customSupportLink={customSupportLink} />
      </div>
      <div
        className={expandClassNames.join(' ')}
        onClick={expand}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  )
}

PropTypes.propTypes = {
  status: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired
}

export default Navigation
