import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCustomerFieldsSelectboxValues = props => {
  return (
    <div className='ta-popup__customer-fields-selectbox-values'>
      <div className='ta-popup__text'>{dangerousHTML(t('global.selectBox.values'))}</div>
    </div>
  )
}

export default PopupCustomerFieldsSelectboxValues
