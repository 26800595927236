import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCoursesDurationBeforeAndAfter = props => {
  return (
    <div className='ta-popup__courses-duration-before'>
      <div className='ta-popup__title'>{t('global.preparationTime.title')}</div>
      <div className='ta-popup__text'>{dangerousHTML(t('servicesGroups.form.section.duration.checkboxPrepFollowUpTime.tooltip'))}</div>
      <div className='ta-popup__title'>{t('global.followUpTime.title')}</div>
      <div className='ta-popup__text'>{dangerousHTML(t('servicesGroups.form.section.duration.checkboxFollowTime.tooltip'))}</div>
    </div>
  )
}

export default PopupCoursesDurationBeforeAndAfter
