import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { TagsForm } from '../../Beauties'

class TagsAddWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.tagsFormGet()
  }

  onSubmit (cb) {
    let { form } = this.props
    form = form || {}

    handlers.tagsFormSave({ ...form }, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    let {
      pending,
      errors,
      showExternalIds
    } = this.props
    pending = !!pending

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <TagsForm
            title={t('tags.add')}
            cancelLink='/branches/tags'
            errors={errors}
            onSubmit={this.onSubmit}
            showExternalIds={showExternalIds}
          />
        )}
      </>
    )
  }
}

TagsAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = state => ({
  form: state.forms.tags,
  errors: state.tags.errors,
  pending: state.tags.pendingForm,
  showExternalIds: state.company.settings.showExternalIds
})

export default connect(maps)(TagsAddWrapper)
