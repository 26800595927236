import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import {
  Row,
  Col,
  BorderedBox,
  FormGroup,
  Input,
  FontAwesome5,
  Error,
  t,
  Button
} from '../../../Common'
import './EmailSettings.css'

const EmailSettingsOtherRecipientsSelect = props => {
  const [focusedIndex, setFocusedIndex] = useState(null)
  let { recipients, name, isDisabled } = props
  recipients = recipients || []
  const deleteButtonClassNames = ['ta-settings__btn-delete']
  if (isDisabled) deleteButtonClassNames.push('ta-btn-disabled')
  const addButtonClassNames = ['ta-settings__btn-add']
  if (isDisabled) addButtonClassNames.push('ta-btn-disabled')

  const onFocus = (index) => {
    setFocusedIndex(index)
  }

  const onBlur = () => {
    setFocusedIndex(null)
  }

  const onChange = (value, index) => {
    recipients[index] = value || ''
    handlers.formFieldsUpdate('settings', {
      [name]: {
        values: [...recipients]
      }
    })
  }

  const onDelete = index => {
    if (isDisabled) return
    handlers.formFieldsUpdate('settings', {
      [name]: {
        values: [...recipients.filter((item, key) => key !== index)]
      }
    })
  }

  const onAdd = () => {
    if (isDisabled) return
    handlers.formFieldsUpdate('settings', {
      [name]: {
        values: [...recipients, '']
      }
    })
  }

  return (
    <BorderedBox className='ta-settings__form--inner-box'>
      {recipients.map((item, index) => {
        const recipientClassNames = ['ta-settings-notifications__recipient']
        if (recipients.length > 1) recipientClassNames.push('has-delete')

        return (
          <div key={index} className='ta-settings-notifications__recipients-container'>
            <Row>
              <Col>
                <FormGroup
                  className={recipientClassNames.join(' ')}
                  focused={focusedIndex === index}
                  filled={!!item}
                  labelText={t('global.email.label')}
                  labelMandatory
                >
                  <Input
                    disabled={isDisabled}
                    value={item}
                    onFocus={() => onFocus(index)}
                    onBlur={onBlur}
                    onChange={(name, value) => onChange(value, index)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {(recipients.length > 1 &&
              <div className={deleteButtonClassNames.join(' ')} onClick={() => onDelete(index)}>
                <FontAwesome5 icon='trash' type='regular' />
              </div>
            )}
          </div>
        )
      })}
      {(recipients.length < 3 &&
        <Button iconType='regular' icon='plus' isTertiary isGhost isBlock className={addButtonClassNames.join(' ')} onClick={onAdd}>
          {t('settings.section.recipients.form.button.addRecipient')}
        </Button>
      )}
      <Error noOffset name={name} />
    </BorderedBox>
  )
}

EmailSettingsOtherRecipientsSelect.propTypes = {
  name: PropTypes.string,
  reminders: PropTypes.array
}

export default EmailSettingsOtherRecipientsSelect
