import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, globalActions } from '../../Store'
import { Services, ServicesMock } from '../../Beauties'
import { Loader } from '../../Common'
import { sortByOrderIndex } from '../../Utils'

class ServicesListWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onDragStart = this.onDragStart.bind(this)
    this.onDragUpdate = this.onDragUpdate.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onCategoriesToggle = this.onCategoriesToggle.bind(this)
    this.state = { areExpanded: true }
  }

  componentDidMount () {
    globalActions.populateServicesAndServiceCategories()
    handlers.servicesToDeleteGet()
  }

  onCategoriesToggle () {
    this.setState(prevState => ({ areExpanded: !prevState.areExpanded }))
  }

  onDragStart (res) {}

  onDragUpdate (res) {}

  onDragEnd ({ type, id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) {
    if (type === 'category') {
      handlers.serviceCategoryOrderChange({ id, oldIndex, newIndex })
      return
    }

    handlers.servicesListOrderChange({
      id,
      oldIndex,
      newIndex,
      sourceCategoryId,
      destinationCategoryId
    })
  }

  render () {
    const { areExpanded } = this.state
    let {
      categories,
      pending,
      route,
      services,
      filters,
      hash,
      account
    } = this.props
    categories = categories || []
    services = services || []
    filters = filters || {}
    pending = !!pending
    account = account || {}
    let { isAdmin, enterprisePermissions } = account || {}
    enterprisePermissions = enterprisePermissions || {}
    let { globalServicesRead, globalServicesWrite } = enterprisePermissions || {}
    globalServicesRead = !!isAdmin || !!globalServicesRead
    globalServicesWrite = !!isAdmin || !!globalServicesWrite
    const isDragAndDropMode = !!(route === 'servicesReorder')
    const { search, type } = filters || {}
    let { value: searchValue } = search || {}
    searchValue = searchValue || ''
    const { value: typeValue } = type || {}
    const hasServiceCombinations = services.filter(item => item.isCombination).length > 0
    const hasFilteredServices = (searchValue || typeValue) && !services.some(item => {
      return (
        (item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.id === searchValue) &&
        (!typeValue || (typeValue === 'service' && !item.isCombination) || (typeValue === 'serviceCombination' && item.isCombination))
      )
    })
    const localCategories = categories.filter(item => !item.internalId)
    const globalCategories = categories.filter(item => item.internalId && !item.isDefault)
    const defaultCategory = categories.find(item => item.isDefault)
    const sortedCategories = [defaultCategory, ...localCategories, ...globalCategories].filter(Boolean)

    return (
      globalServicesRead
        ? pending
          ? <Loader active />
          : (
            <Services
              hash={hash}
              categories={sortedCategories}
              pending={pending}
              isDragAndDropMode={isDragAndDropMode}
              onDragStart={this.onDragStart}
              onDragUpdate={this.onDragUpdate}
              onDragEnd={this.onDragEnd}
              areExpanded={areExpanded}
              onCategoriesToggle={this.onCategoriesToggle}
              hasFilteredServices={hasFilteredServices}
              hasServiceCombinations={hasServiceCombinations}
              globalServicesWrite={globalServicesWrite}
            />
            )
        : <ServicesMock />
    )
  }
}

ServicesListWrapper.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  route: state.router.name,
  hash: state.router.hash,
  categories: (state.services.categoriesList || []).sort(sortByOrderIndex),
  pending: state.services.pendingList,
  services: (state.services.list || []).sort(sortByOrderIndex),
  filters: state.forms.servicesFilters,
  account: state.account
})

export default connect(maps)(ServicesListWrapper)
