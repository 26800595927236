import React from 'react'
import {
  AccordionTitle,
  AccordionBody,
  Title,
  Accordion,
  ListItem,
  t
} from '../../../Common'
import { truncateText, dangerousHTML } from '../../../Utils'

const allocationCategoriesTransform = (allocationServiceIds, servicesByCategories) => {
  Object.keys(servicesByCategories).forEach(categoryName => {
    const categoryServices = servicesByCategories[categoryName].map(service => service.id)
    if (categoryServices.length === 0 || !allocationServiceIds.some(serviceId => categoryServices.includes(serviceId))) {
      delete servicesByCategories[categoryName]
    } else {
      servicesByCategories[categoryName] = servicesByCategories[categoryName].filter(service => allocationServiceIds.includes(service.id))
    }
  })
  return servicesByCategories
}

const SettingsBookingPreviewServiceAllocations = props => {
  let {
    settings,
    serviceAllocations,
    servicesByCategories,
    coursesByCategories
  } = props
  settings = settings || {}
  serviceAllocations = serviceAllocations || []
  servicesByCategories = servicesByCategories || {}
  coursesByCategories = coursesByCategories || {}
  let {
    shouldDisplayInfo,
    shouldAllowBookingWidget,
    hoursBeforeAllocationExpiration
  } = settings || {}
  shouldDisplayInfo = !!shouldDisplayInfo
  shouldAllowBookingWidget = !!shouldAllowBookingWidget
  hoursBeforeAllocationExpiration = hoursBeforeAllocationExpiration || 0
  const isHoursBeforeAllocationExpirationInDays = hoursBeforeAllocationExpiration % 24 === 0
  const hoursBeforeAllocationExpirationInDays = hoursBeforeAllocationExpiration / 24
  const servicesAndCoursesList = Object.values(servicesByCategories).concat(Object.values(coursesByCategories)).flat()

  return (
    <>
      <ListItem className='no-offset'>
        <strong>{t('settings.section.serviceAllocations.enabled')}</strong>
      </ListItem>
      {(serviceAllocations.map((serviceAllocation, index) => {
        let { percentage, serviceIds, name } = serviceAllocation
        percentage = percentage || 0
        serviceIds = serviceIds || []
        name = name || ''
        const servicesInAllocation = servicesAndCoursesList.filter(item => serviceIds.includes(item.id))
        const serviceAllocationTitle = name || servicesInAllocation.reduce((acc, service, index) => {
          return `${acc}${index > 0 ? ' + ' : ''}${(service || {}).name}`
        }, '') || '-'
        const allocationServicesByCategories = { ...servicesByCategories }
        const allocationCoursesByCategories = { ...coursesByCategories }
        const allocationServicesGroupedByCategories = allocationCategoriesTransform(serviceIds, allocationServicesByCategories)
        const allocationCoursesGroupedByCategories = allocationCategoriesTransform(serviceIds, allocationCoursesByCategories)

        return (
          <Accordion expand={false} key={`${serviceAllocationTitle}-${index}`} className='ta-service-allocations__container'>
            <AccordionTitle>
              {truncateText(serviceAllocationTitle, 40, true)}
              <span className='ta-allocation-label'>{t('settings.section.serviceAllocations.allocation.percentage', [{ key: 'PERCENT', value: percentage || 0 }])}</span>
            </AccordionTitle>
            <AccordionBody>
              <p className='ta-service-allocations-categories__label'>{t('settings.section.serviceAllocations.allocation.previewCategories')}</p>
              {Object.keys(allocationServicesGroupedByCategories).map(category => (
                <div key={category}>
                  <Title theme='striped' label={truncateText(category === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category, 60, true)} />
                  <div className='ta-service-allocations__services-container'>
                    {allocationServicesGroupedByCategories[category].map(service => {
                      return (
                        <div className='ta-service-allocations__allocation-container' key={service.id}>
                          <div className='ta-service-allocations__allocation-name'>
                            {truncateText(service.name, 20, true)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
              <br />
              {Object.keys(allocationCoursesGroupedByCategories).map(category => (
                <div key={category}>
                  <Title theme='striped' label={truncateText(category === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category, 60, true)} />
                  <div className='ta-service-allocations__services-container'>
                    {allocationCoursesGroupedByCategories[category].map(course => {
                      return (
                        <div className='ta-service-allocations__allocation-container' key={course.id}>
                          <div className='ta-service-allocations__allocation-name'>
                            {truncateText(course.name, 20, true)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
              <p className='ta-service-allocations-categories__label percent'>{dangerousHTML(t('settings.section.serviceAllocations.previewPercentage', [{
                key: 'PERCENT',
                value: `<span class='ta-service-allocations-categories__percent-label'>${percentage}%</span>`
              }]
              ))}
              </p>
            </AccordionBody>
          </Accordion>
        )
      })
      )}
      <Title theme='striped' label={t('global.settings')} />
      {(shouldDisplayInfo &&
        <ListItem className='bullet-item service-allocations'>
          {dangerousHTML(t('settings.section.serviceAllocations.preview.enabledCalendar'))}
        </ListItem>
      )}
      {(shouldAllowBookingWidget &&
        <ListItem className='bullet-item service-allocations'>
          {dangerousHTML(t('settings.section.serviceAllocations.preview.enabledWidget'))}
        </ListItem>
      )}
      {(hoursBeforeAllocationExpiration > 0 &&
        <ListItem className='bullet-item service-allocations'>
          {(t(isHoursBeforeAllocationExpirationInDays
            ? 'settings.section.serviceAllocations.preview.enabledPreDays'
            : 'settings.section.serviceAllocations.preview.enabledPreHours', [{ key: isHoursBeforeAllocationExpirationInDays ? 'DAYS' : 'HOURS', value: `${isHoursBeforeAllocationExpirationInDays ? hoursBeforeAllocationExpirationInDays : hoursBeforeAllocationExpiration}` }])
          )}
        </ListItem>
      )}
    </>
  )
}

export default SettingsBookingPreviewServiceAllocations
