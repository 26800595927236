import React from 'react'

const ChartCustomizedDot = props => {
  const {
    cx,
    cy,
    color
  } = props
  return (
      <>
        <circle cx={cx} cy={cy} r='2.25' stroke='white' strokeWidth='3' fill='none' />
        <circle cx={cx} cy={cy} r='2' strokeWidth='2' fill={color || '#385f87'} />
      </>
  )
}

export default ChartCustomizedDot
