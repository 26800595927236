import moment from 'moment'
import { validator, sortByOrderIndex, translateServerCode } from '../../../Utils'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'

const customersDefaults = {}

// LIST
export const customersListTransform = customers => {
  if (!customers) return
  return customers.map(customer => {
    const item = {
      id: customer.id,
      fullName: customer.fullName,
      avatarUrl: customer.avatarUrl,
      companyName: customer.companyName,
      phone: customer.phone,
      email: customer.email,
      totalBookings: customer.totalBookings,
      ts: customer.ts,
      internalId: customer.internalId,
      externalId: customer.externalId,
      isUpdatedLocally: customer.isUpdatedLocally,
      isGloballyDeleted: customer.isGloballyDeleted,
      locallyUpdatedBranches: customer.locallyUpdatedBranches
    }
    if (customer.fields) item.fields = customer.fields.sort(sortByOrderIndex)
    return item
  })
}

export const customersListErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// PREVIEW

export const customerPreviewErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

export const customerPreviewBookingsErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// FORM

export const customerFormTransform = ({ customer, branches, account }) => {
  if (!customer) customer = customersDefaults
  const { isOwner: amIOwner, isAdmin: amIAdmin, enterprisePermissions: accountPermissions } = account || {}
  const amICustom = !amIOwner && !amIAdmin
  const {
    branchesAccessBlacklist: accountBranchesAccessBlacklist,
    branchesAccessWhitelist: accountBranchesAccessWhitelist
  } = accountPermissions || {}
  const isTypeAdd = !customer.id
  const companyLocale = customer.companyLocale || ''
  const customerFields = (customer.fields || []).filter(item => item.isActive)
  const allowedBranchExternalIds = customer.allowedBranchExternalIds || []
  const allowedBranchIds = customer.allowedBranchIds || []
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : []

  const result = {
    externalId: {
      value: customer.externalId || ''
    },
    tags: {
      values: customer.tagIds || [],
      options: (customer.availableTags || []).filter(item => item.isForCustomers).map(item => ({
        label: item.name,
        value: item.id,
        tagColor: item.color
      }))
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name,
        value: branch.id
      })),
      insufficient: amICustom && locationValues && locationValues.length
        ? locationValues.filter(branch => accountBranchesAccessBlacklist ? accountBranchesAccessBlacklist.includes(branch) : !(accountBranchesAccessWhitelist || []).includes(branch))
        : [],
      values: locationValues && locationValues.length
        ? amICustom
          ? locationValues.filter(branch => accountBranchesAccessBlacklist ? !accountBranchesAccessBlacklist.includes(branch) : (accountBranchesAccessWhitelist || []).includes(branch))
          : locationValues
        : amICustom && isTypeAdd
          ? []
          : ['all']
    }
  }

  customerFields
    .filter(item => item.hasOverwrite)
    .forEach(item => {
      result[`customerFields${item.id}`] = {
        value: item.value || ''
      }
      if (item.type === 'CHECKBOX') {
        result[`customerFields${item.id}`].value = item.value + '' === 'true'
      }
      if (item.type === 'SELECT') {
        if (item.defaultId === 'salutation') {
          result[`customerFields${item.id}`].options = item.selectOptions.map(option => ({ ...option, label: option.value === 'mrs' ? 'Ms.' : 'Mr.' }))
        } else {
          result[`customerFields${item.id}`].options = item.selectOptions
        }
        if (!(item.selectOptions || []).map(item => item.value).includes(item.value)) result[`customerFields${item.id}`].value = ''
      }
      if (item.type === 'PHONE') {
        result[`customerFields${item.id}`] = {
          phone: (item.value && item.value.number) || '',
          phoneCountry: item.value
            ? item.value.country
            : companyLocale.length > 2
              ? (companyLocale.split('-')[1] || '').toUpperCase()
              : DEFAULT_LOCALE_COUNTRY_CODE
        }
      }
      if (item.type === 'FILE' && item.defaultId === 'avatar') {
        result[`customerFields${item.id}`] = {
          avatarUrl: { value: item.value || '' },
          value: ''
        }
      }
      if (item.type === 'ADDRESS') {
        const address = item.value || {}
        result[`customerFields${item.id}`].value = address.formatted
        result[`customerFields${item.id}`].data = ((address.formatted && address.formatted !== '') && {
          placeId: address.placeId || '',
          city: address.city || '',
          streetName: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.zipCode || '',
          country: address.country || '',
          lat: address.latitude || '',
          lng: address.longitude || '',
          formattedAddress: address.formatted || '',
          details: address.details || ''
        }) || null
        result[`customerFields${item.id}Secondary`] = {
          value: address.details || ''
        }
      }
      result[`customerFields${item.id}`].type = item.type
      result[`customerFields${item.id}`].defaultId = item.defaultId
      result[`customerFields${item.id}`].isMandatory = item.isMandatoryOffline
      result[`customerFields${item.id}`].translationKey = item.translationKey
    })
  result.customerPrintUpcomingBookings = { value: false }
  result.customerPrintPastBookings = { value: false }
  if (customer.id) result.id = customer.id
  return result
}

// VALIDATION

export const customerFormValidate = customer => {
  if (!customer) return

  const combinedFields = Object.keys(customer).reduce((acc, key) => {
    if (key.indexOf('customerFields') !== -1) {
      acc.push({
        ...customer[key],
        name: key
      })
    }
    return acc
  }, [])

  const rules = []
  const messages = {}
  const replaces = {}
  const atLeastOneIsRequired = ['firstName', 'lastName', 'company']
  const atLeastOneIsRequiredValues = []
  const atLeastOneIsRequiredFields = []

  let atLeastOneCheck = true

  combinedFields.forEach((field, index) => {
    const name = field.name
    const defaultId = (field.defaultId && field.defaultId) || ''
    const type = field.type && field.type
    const value = field.value && field.value
    const isMandatory = !!field.isMandatory
    const translationKey = field.translationKey || 'global.theField'

    switch (type) {
      case 'FILE':
        if (defaultId.toLowerCase() === 'avatar') {
          if (isMandatory) {
            const isAvatarSet = !!(field.avatarUrl && field.avatarUrl.value && field.avatarUrl.value !== '')
            rules.push({ [`${name}.avatarFile`]: [`requiredIf:${!isAvatarSet}`] })
          }
          messages[name] = {
            requiredIf: 'errors.required'
          }
          replaces[name] = {
            requiredIf: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
          }
        }
        break

      case 'TEXT':
        if (atLeastOneIsRequired.includes(defaultId) && !atLeastOneIsRequiredValues.find(item => item === value)) {
          if (value && value.trim() !== '') atLeastOneIsRequiredValues.push(value)
          if (isMandatory) atLeastOneCheck = false
        }

        atLeastOneIsRequiredFields.push(name)

        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })

        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'TEXTAREA':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:3000'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 3000 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'SELECT':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'DATE':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'CHECKBOX':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:64'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 64 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'EMAIL':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['email', 'max:255'] })
        messages[name] = {
          email: 'errors.email.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'PHONE':
        if (isMandatory) rules.push({ [`${name}.phone`]: ['required'] })
        rules.push({ [`${name}.phone`]: ['phone', 'max:16'] })
        messages[name] = {
          phone: 'errors.phone.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 16 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      case 'ADDRESS':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required', 'max:800'] })
        const isAddressSet = (customer[name] && customer[name].value && customer[name].value !== '')
        if (isAddressSet) {
          rules.push({ [`${name}.data.city`]: [`requiredIf:${isAddressSet}`] })
          rules.push({ [`${name}.data.streetName`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.streetNumber`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.postalCode`]: [`requiredIf:${isAddressSet}`] })
        }

        let addressErrorMessage = 'errors.address.required'

        if (isAddressSet && customer[name] && customer[name].data) {
          if (!customer[name].data.city || customer[name].data.city === '') {
            addressErrorMessage = 'errors.address.fillCity'
          } else if (!customer[name].data.streetName || customer[name].data.streetName === '') {
            addressErrorMessage = 'errors.address.fillStreetName'
          }
          // else if (!customer[name].data.postalCode || customer[name].data.postalCode === '') {
          //   addressErrorMessage = 'errors.address.fillZipCode'
          // }
          // else if (!customer[name].data.streetNumber || customer[name].data.streetNumber === '') {
          //   addressErrorMessage = 'errors.address.fillStreetNumber'
          // }
        }

        messages[name] = {
          requiredIf: addressErrorMessage,
          max: 'errors.invalidMaxLength'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 800 },
          required: { key: 'FIELD_NAME', value: translationKey, translateReplace: true }
        }
        break

      default:
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })

        messages[name] = {
          max: 'invalidMaxLength',
          required: 'errors.required'
        }
        break
    }
  })

  const errors = validator(customer, rules, messages, replaces)

  // location
  if (!customer.location.values?.length) {
    errors.push({
      key: 'location',
      value: 'errors.required',
      replace: [{ key: 'FIELD_NAME', value: 'global.location.label', translateReplace: true }]
    })
  }

  if (atLeastOneCheck && atLeastOneIsRequiredValues.length === 0) {
    atLeastOneIsRequiredFields.forEach(item => {
      errors.push({
        key: item,
        value: 'errors.requiredOne'
      })
    })
  }

  return errors
}

// SAVE

export const customerSaveTransform = customer => {
  const fields = {}
  Object
    .keys(customer)
    .filter(item => item.includes('customerFields'))
    .forEach(item => {
      const field = customer[item]
      const id = item.replace('customerFields', '')
      if (field.type === 'DATE' && field.value) {
        const value = moment.utc(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
        if (value === 'Invalid date') {
          fields[id] = null
          field.value = null
        } else {
          fields[id] = value
        }
      }
      if (field.type === 'PHONE' && field.phone) {
        fields[id] = JSON.stringify({
          number: field.phone,
          country: field.phoneCountry
        })
      }
      if (field.type === 'CHECKBOX') {
        fields[id] = field.value + ''
      }
      if (field.type === 'FILE' && field.defaultId === 'avatar') {
        if (field.value === null) fields[id] = null
        if (field.avatarFile) fields[id] = field.avatarFile
      }
      if (field.type === 'ADDRESS' && field.value) {
        const value = fields[id] ? JSON.parse(fields[id]) : null
        const data = field.data || null
        const newValue = (data && {
          placeId: data && data.placeId,
          latitude: data && data.lat,
          longitude: data && data.lng,
          street: data && data.streetName,
          streetNumber: data && data.streetNumber,
          city: data && data.city,
          country: data && data.country,
          zipCode: data && data.postalCode,
          formatted: data && data.formattedAddress
        }) || null
        // if isSecondary address come first, we have to pre-fill it
        if (newValue && value && value.details) newValue.details = value.details
        fields[id] = JSON.stringify(newValue)
      }
      if (id.includes('Secondary')) {
        const addressId = id.replace('Secondary', '')
        const newValue = fields[addressId] ? JSON.parse(fields[addressId]) : null
        if (newValue) {
          newValue.details = field.value
          fields[addressId] = JSON.stringify(newValue)
        }
      }
      if (!fields[id] && field.type !== 'FILE') fields[id] = field.value || null
    })

  const result = {
    customer: {
      id: customer.id,
      externalId: customer.externalId.value || null,
      tagIds: customer.tags.values || null,
      fields: Object
        .keys(fields)
        .filter(item => item.length === 24)
        .map(id => ({ id, value: fields[id] }))
    }
  }
  if (customer.location && customer.location.values && !customer.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: [
        ...customer.location.values,
        ...(customer.location.insufficient || [])
      ]
    }
  }
  return result
}

export const customerFormServerErrorsTransform = ({ error, branchesList }) => {
  const errors = []

  if (error.code) {
    if (error.code === 'GlobalCustomerSaveError') {
      const { data } = error || {}
      let { localErrors } = data || {}
      localErrors = localErrors || []
      const branchesWithLocalErrorsInnitialValues = {
        globalFieldNotFound: [],
        localFieldNotFound: [],
        localTagsNotFound: []
      }
      const branchesWithLocalErrors = localErrors.reduce((acc, item) => {
        const company = branchesList.find(branch => branch.id === item.companyId) || {}
        if (item.code === 'GlobalCustomerFieldNotFound') {
          return {
            ...acc,
            globalFieldNotFound: [...acc.globalFieldNotFound, company.name]
          }
        }
        if (item.code === 'LocalCustomerFieldNotFound') {
          return {
            ...acc,
            localFieldNotFound: [...acc.localFieldNotFound, company.name]
          }
        }
        if (item.code === 'LocalCustomerTagNotFound') {
          return {
            ...acc,
            localTagsNotFound: [...acc.localTagsNotFound, company.name]
          }
        }
        return acc
      }, branchesWithLocalErrorsInnitialValues)

      Object.keys(branchesWithLocalErrors).forEach(item => {
        if (branchesWithLocalErrors[item].length > 0) {
          errors.push({
            key: 'globalErrors',
            value: `errors.customers.${item}`,
            replace: [{
              key: 'BRANCHES', value: branchesWithLocalErrors[item].join(' <br/>')
            }]
          })
        }
      })
    } else if (error.code === 'MissingOrInvalidPhone') {
      const splitMesage = error.message.split(' ')
      const customerFieldId = splitMesage[splitMesage.length - 1]
      errors.push({ key: `customerFields${customerFieldId}`, value: translateServerCode(error.code) })
    } else if (error.code === 'ExternalIdConflicts') {
      errors.push({ key: 'externalId', value: translateServerCode(error.code) })
    } else {
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
    }
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }

  return errors
}

// UPDATE
export const customerTransform = (customer, skipTransform) => {
  if (!customer) return
  if (skipTransform) return customer
  const PARSE_TYPES = ['PHONE', 'ADDRESS']
  return {
    id: customer.id,
    allowedBranchExternalIds: customer.allowedBranchExternalIds,
    allowedBranchIds: customer.allowedBranchIds,
    externalId: customer.externalId,
    internalId: customer.internalId,
    isUpdatedLocally: customer.isUpdatedLocally,
    isGloballyDeleted: customer.isGloballyDeleted,
    fullName: customer.fullName,
    companyName: customer.companyName,
    phone: customer.phone,
    email: customer.email,
    totalBookings: customer.totalBookings,
    avatarUrl: customer.avatarUrl,
    tagIds: customer.tagIds,
    ts: customer.ts,
    fields: customer.fields && customer.fields
      .map(item => ({
        id: item.id,
        type: item.type,
        value: item.value && PARSE_TYPES.includes(item.type)
          ? JSON.parse(item.value)
          : item.value
      }))
      .sort(sortByOrderIndex)
  }
}

// FORM
export const customerEventFormTransform = (options) => {
  const result = {
    type: {
      value: 'UPCOMING',
      options: [
        {
          label: options.upcoming,
          value: 'UPCOMING'
        },
        {
          label: options.past,
          value: 'PAST'
        },
        {
          label: options.cancelled,
          value: 'DELETED'
        }
      ]
    }
  }
  return result
}
