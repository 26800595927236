import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../../Store'
import { PopupCustomerFieldsDelete } from '../../../Beauties'

class PopupCustomerFieldDeleteWrapper extends PureComponent {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidMount () {
    handlers.customerFieldDeleteFormPopulate()
  }

  onSubmit (cb) {
    const { id } = this.props
    const form = store.getState().forms.customerFieldsDelete
    form.id = id
    handlers.customerFieldDeleteFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  onCancel () {
    const { closePopup } = this.props
    closePopup && closePopup()
  }

  render () {
    const { id, pending, customerFields } = this.props
    const customerField = customerFields.find(item => item.id === id) || {}

    return (
      <PopupCustomerFieldsDelete
        name={customerField.label}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        pending={pending}
      />
    )
  }
}

PopupCustomerFieldDeleteWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

const maps = state => ({
  pending: state.customerFields.pendingDeleteForm || false,
  customerFields: state.customerFields.list || [],
  id: (state.router && state.router.data && state.router.data.id) || ''
})

export default connect(maps)(PopupCustomerFieldDeleteWrapper)
