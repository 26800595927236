import React from 'react'
import { connect } from '../../Store'
import { BranchesResourceFormDetails } from '../../Beauties'

const BranchesPreviewResourceFormDetailsWrapper = props => {
  const {
    id,
    isDependency,
    hasAccount,
    hasDuplicateEmails,
    pendingEmailUnlink,
    isUser,
    branch
  } = props

  return (
    <BranchesResourceFormDetails
      id={id}
      hasAccount={hasAccount}
      isDependency={isDependency}
      pendingEmailUnlink={pendingEmailUnlink}
      hasDuplicateEmails={hasDuplicateEmails}
      isUser={isUser}
      branch={branch}
    />
  )
}

const maps = state => ({
  id: state.router.data.id,
  hasAccount: (state.forms.branchesResource.hasAccount && state.forms.branchesResource.hasAccount.value) || false,
  isDependency: state.forms.branchesResource.isDependency,
  hasDuplicateEmails: (state.forms.branchesResource.hasDuplicateEmails && state.forms.branchesResource.hasDuplicateEmails.value) || false,
  pendingEmailUnlink: (state.forms.branchesResource && state.forms.branchesResource.pendingEmailUnlink) || false,
  isUser: !!(state.account.userId === state.forms.branchesResource.userId || '')
})

export default connect(maps)(BranchesPreviewResourceFormDetailsWrapper)
