import React from 'react'

const ChartAxisTickWithCircle = props => {
  const { x, y, payload } = props

  return (
    <>
      <g transform={`translate(${x},${y})`}>
        <circle cx={0} cy={0} dy={16} r={3} fill='#d4d6d7' />
      </g>
      <g transform={`translate(${x},${y})`}>
        <text x={10} y={0} dy={16} textAnchor='end' fill='#666'>{payload.value.toUpperCase()}</text>
      </g>
    </>
  )
}

export default ChartAxisTickWithCircle
