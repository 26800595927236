import React from 'react'
import {
  Accordion,
  AccordionTitle,
  AccordionBody,
  Title
} from '../../Common'
import './UserPermissionTypeAccordion.css'

const UserPermissionTypeAccordion = ({ title, children, zIndex = 100 }) => {
  return (
    <Accordion
      zIndex={zIndex}
      className='ta-user-permission-types__custom-accordion'
      expand={false}
    >
      <AccordionTitle>
        <Title label={title} isCompact />
      </AccordionTitle>
      <AccordionBody>
        {children}
      </AccordionBody>
    </Accordion>
  )
}

export default UserPermissionTypeAccordion
