import { payloads$, actions, handlers, store } from '../../../Store'
import { q } from '../../API'
import {
  globalsSettingsSaveTransform,
  globalsSettingsFormValidate,
  globalsSettingsFormServerErrorsTransform
} from './utils'

// Form
payloads$(actions.GLOBALS_SETTINGS_FORM_GET)
  .subscribe(async () => {
    const state = store.getState()
    const { company } = state || {}
    const { settings } = company || {}
    handlers.globalsSettingsFormPopulate(settings)
  })

// Save
payloads$(actions.GLOBALS_SETTINGS_FORM_SAVE)
  .subscribe(async ({ settings, scrollToError }) => {
    let { company } = store.getState()
    company = company || {}
    const errors = globalsSettingsFormValidate(settings)
    if (errors && errors.length) return setGlobalsSettingsFormSaveErrors(errors, scrollToError)
    const formattedSettings = globalsSettingsSaveTransform(settings)
    const savedAccountSettings = await q('saveEnterpriseSettings', { settings: formattedSettings })
    const { error } = savedAccountSettings || {}
    if (error) return setGlobalsSettingsFormSaveErrors(globalsSettingsFormServerErrorsTransform(error), scrollToError)
    handlers.companyPopulate({ ...company, settings: savedAccountSettings })
    handlers.navigateToPath('/settings/globals')
  })

const setGlobalsSettingsFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('globalsSettings', errors)
  handlers.globalsSettingsFormReady()
  scrollToError && scrollToError(errors)
}
