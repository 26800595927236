import React from 'react'
import { t } from '../../../Common'

const PermissionTypeSummaryPermissionItem = (props) => {
  let {
    allEntitiesText,
    specificResourceCategoryIds,
    specificResourceIds,
    categoriesList,
    resourcesList,
    selectedResourceId,
    defaultTagName,
    shouldShowDefaultTag,
    isPermissionTypeAssigned
  } = props
  resourcesList = resourcesList || []
  if (specificResourceIds && specificResourceCategoryIds) {
    specificResourceIds = specificResourceIds.filter(id => {
      const categoryId = (resourcesList.find(item => item.id === id) || {}).categoryId
      return !specificResourceCategoryIds.includes(categoryId)
    })
  }

  const isAllResources = () => {
    return specificResourceCategoryIds && specificResourceIds && specificResourceCategoryIds.length === 0 && specificResourceIds.length === 0
  }

  const shouldRenderResources = () => {
    return (specificResourceCategoryIds && specificResourceCategoryIds.length > 0) || (specificResourceIds && specificResourceIds.length > 0)
  }

  if (isAllResources()) {
    return (
      <div className='ta-permission-types__preview__summary__permission__content'>
        <div className='ta-permission-types__preview__summary__permission__tag'>{allEntitiesText}</div>
      </div>
    )
  }

  const getCategoryName = (categoryId) => {
    const category = categoriesList.find(category => category.id === categoryId)
    const { name } = category || { name: t('global.insufficientPermissions') }

    return name === 'default' ? t('resources.list.sectionDefault.title') : name
  }

  if (shouldRenderResources()) {
    return (
      <div className='ta-permission-types__preview__summary__permission__content'>
        {(specificResourceCategoryIds && specificResourceCategoryIds.length > 0 &&
          <div className='ta-permission-types__preview__summary__permission__row'>
            <div className='ta-permission-types__preview__summary__permission__row__title'>{t('global.categories')}:</div>
            <div className='ta-permission-types__preview__summary__permission__tags__title'>
              {specificResourceCategoryIds.map(categoryId => (
                <div className='ta-permission-types__preview__summary__permission__tag' key={categoryId}>
                  {getCategoryName(categoryId)}
                </div>
              ))}
            </div>
          </div>
        )}
        {((specificResourceIds && specificResourceIds.length > 0)
          ? <div className='ta-permission-types__preview__summary__permission__row'>
            <div className='ta-permission-types__preview__summary__permission__row__title'>{t('global.resources')}:</div>
            <div className='ta-permission-types__preview__summary__permission__tags__title'>
              {specificResourceIds.map(resourceId => (
                <div className='ta-permission-types__preview__summary__permission__tag' key={resourceId}>
                  {(resourcesList.find(resource => resource.id === resourceId) || { name: t('global.insufficientPermissions') }).name}
                </div>
              ))}
              {(shouldShowDefaultTag &&
                <div className='ta-permission-types__preview__summary__permission__tag'>
                  {defaultTagName}
                </div>
              )}
            </div>
            </div>
          : (shouldShowDefaultTag &&
            <div className='ta-permission-types__preview__summary__permission__row'>
              <div className='ta-permission-types__preview__summary__permission__row__title'>{t('global.resources')}:</div>
              <div className='ta-permission-types__preview__summary__permission__tags__title'>
                <div className='ta-permission-types__preview__summary__permission__tag'>
                  {defaultTagName}
                </div>
              </div>
            </div>
            )
        )}
      </div>
    )
  } else if (shouldShowDefaultTag && specificResourceIds === null) {
    return (
      <div className='ta-permission-types__preview__summary__permission__content'>
        <div className='ta-permission-types__preview__summary__permission__row'>
          <div className='ta-permission-types__preview__summary__permission__row__title'>{t('global.resources')}:</div>
          <div className='ta-permission-types__preview__summary__permission__tags__title'>
            <div className='ta-permission-types__preview__summary__permission__tag'>
              {defaultTagName}
            </div>
          </div>
        </div>
      </div>
    )
  } else if (!isPermissionTypeAssigned && selectedResourceId) {
    return (
      <div className='ta-permission-types__preview__summary__permission__content'>
        <div className='ta-permission-types__preview__summary__permission__row'>
          <div className='ta-permission-types__preview__summary__permission__row__title'>{t('global.resources')}:</div>
          <div className='ta-permission-types__preview__summary__permission__tags__title'>
            <div className='ta-permission-types__preview__summary__permission__tag'>
              {(resourcesList.find(resource => resource.id === selectedResourceId) || { name: t('global.insufficientPermissions') }).name}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default PermissionTypeSummaryPermissionItem
