
import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  Title,
  FontAwesome5,
  Button,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const UsersTitle = props => {
  const { usersWrite } = props

  return (
    <div>
      <Title icon='user-ninja' iconTheme='grey' label={t('global.users')}>
        <PopupTriggerWrapper name='users-help-box' position='bottom' extraClassName='ta-help-box'>
          <Button as='link' className='ta-title__link' external>
            {t('global.heading.help')} <FontAwesome5 icon='lightbulb' type='r' />
          </Button>
        </PopupTriggerWrapper>
        {(usersWrite &&
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('users.title.add')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Button as='link' to='/managers/users/add' isPrimary icon='plus' iconType='s' />
            </HoverPopupTrigger>
          </HoverPopup>
        )}
      </Title>
    </div>
  )
}

export default UsersTitle
