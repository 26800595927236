import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import momenttz from 'moment-timezone'
import { connect, handlers, selectors } from '../../../Store'
import { Loader, isRouteDisabledByCustomisations } from '../../../Common'
import { AppsPreview } from '../../../Beauties'

const AppsPreviewWrapper = ({
  id,
  app,
  pending,
  companyPlan,
  isOwner,
  message,
  account,
  chargebee
}) => {
  app = app || {}
  companyPlan = companyPlan || 'CLASSIC'
  pending = !!pending
  chargebee = chargebee || {}
  const { noAutoCollect } = chargebee
  const {
    appInstallation,
    installedVersion,
    hasPayment,
    refreshToken
  } = app || {}
  const {
    isBlocked,
    blockedReasonCode,
    subscribedAt,
    unsubscribedAt,
    trialUnits,
    isPaidByEnterprise,
    hasDuePayment,
    cancelledAt,
    limitReached,
    blockingAt
  } = appInstallation || {}
  const now = momenttz().utc().format('YYYY-MM-DD HH:mm')
  const cancelledAtFormatted = cancelledAt && momenttz.tz(cancelledAt, 'UTC').utc().format('YYYY-MM-DD HH:mm')
  const isCancelled = cancelledAt && now >= cancelledAtFormatted
  const { isAdmin, enterprisePermissions } = account || {}
  let { appsRead } = enterprisePermissions || {}
  appsRead = !!isAdmin || !!appsRead
  const appsWrite = !!isAdmin

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
    }
  }, [])

  useEffect(() => {
    if (!appsRead) return handlers.navigateToPath('/apps')
    handlers.appPreviewGet({ id })
  }, [id, appsRead])

  useEffect(() => {
    if (
      installedVersion &&
      !noAutoCollect &&
      (hasDuePayment || (isBlocked && ['MAX_UNITS'].includes(blockedReasonCode)))
    ) handlers.navigateToRoute('appsSubscriptionFailed', { id })
    else if (installedVersion && noAutoCollect && isBlocked && blockingAt) handlers.navigateToRoute('appsSubscriptionFailed', { id })
    else if (installedVersion && !hasDuePayment && isCancelled) handlers.navigateToRoute('appsSubscriptionGone', { id })
    else if (installedVersion && unsubscribedAt) handlers.navigateToRoute('appsSubscriptionCancelled', { id })
    else if (installedVersion && limitReached && appsWrite) handlers.navigateToRoute('appsSubscription', { id })
    else if (installedVersion && !hasDuePayment && isBlocked && ['SUBSCRIBE'].includes(blockedReasonCode)) handlers.navigateToRoute('appsSubscriptionAdd', { id })
    else if (installedVersion && hasPayment && !subscribedAt && !trialUnits) handlers.navigateToRoute('appsSubscriptionAdd', { id })
    else if (installedVersion && refreshToken && (!hasPayment || !isBlocked)) handlers.navigateToRoute('appsIframe', { id })
  }, [id, installedVersion, hasPayment, isBlocked, blockedReasonCode, unsubscribedAt, refreshToken])

  const handleOnInstall = () => {
    handlers.appInstall(id)
  }

  return (
    <>
      <Loader active={pending} />
      {!pending && (
        <AppsPreview
          {...app}
          isOwner={isOwner}
          companyPlan={companyPlan}
          onSubmit={handleOnInstall}
          message={message}
          isPaidByEnterprise={isPaidByEnterprise}
        />
      )}
    </>
  )
}

AppsPreviewWrapper.propTypes = {
  id: PropTypes.string,
  app: PropTypes.object,
  pending: PropTypes.bool
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    pending: selectors.appsFieldSelector(state, { field: 'pendingPreview' }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    message: selectors.appsFieldSelector(state, { field: 'messagePreview' }),
    chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' }),
    account: selectors.accountSelector(state)
  }
}

export default connect(maps)(AppsPreviewWrapper)
