import React from 'react'
import { Sector } from 'recharts'

const ChartShapePieActive = props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    startAngle,
    endAngle,
    fill,
    innerRadius,
    outerRadius,
    percent,
    value,
    locale,
    currency
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 3) * cos
  const sy = cy + (outerRadius + 3) * sin
  const mx = cx + (outerRadius + 12) * cos
  const my = cy + (outerRadius + 12) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 12
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'
  const formattedPrice = currency ? new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency && currency.toUpperCase(),
    currencyDisplay: 'symbol'
  }).format(value) : ''

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 1}
        outerRadius={outerRadius + 3}
        fill={fill}
      />
      <path d={`M${sx},${sy} L${mx},${my} L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        className='ta-chart__pie-active-label'
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        dy={3}
        textAnchor={textAnchor}
        fill='#333'
      >{currency ? formattedPrice : value}</text>
      <text
        className='ta-chart__pie-active-percent'
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        dy={12}
        textAnchor={textAnchor}
        fill='#333'
      >({(percent * 100).toFixed(2)}%)</text>
    </g>
  )
}

export default ChartShapePieActive
