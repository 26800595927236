import React from 'react'
import { handlers } from '../../../Store'
import { PopupBranchResourcePasswordReset } from '../../../Beauties'

export const PopupBranchResourcePasswordResetWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    const { email, resourceId, branchId } = id
    if (email && resourceId) handlers.branchesPreviewPasswordResetSend({ email, resourceId, branchId })
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupBranchResourcePasswordReset approve={approve} reject={reject} />
  )
}

export default PopupBranchResourcePasswordResetWrapper
