import { sortByOrderIndex } from '../Utils'

export default (fields, categories) => {
  const categoryIds = new Set(fields.map(item => item.categoryId))
  const result = []
  categoryIds.forEach(id => {
    result.push({
      ...categories.find(category => category.id === id),
      fields: fields.filter(item => item.categoryId === id).sort(sortByOrderIndex)
    })
  })
  return result.sort(sortByOrderIndex)
}
