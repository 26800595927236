import React from 'react'
import PropTypes from 'prop-types'
import { highlight } from '../../Utils'

import './PlanBadge.css'

const PlanBadge = props => {
  const { plan, search } = props
  const classNames = ['ta-plan-badge']
  if (plan === 'CLASSIC') classNames.push('classic')
  if (plan === 'PREMIUM') classNames.push('premium')
  if (plan === 'ENTERPRISE') classNames.push('enterprise')
  if (plan === 'PRIVATE') classNames.push('private')
  // text-transform: capitalize; is boken when used together with highlight
  const transformedPlan = `${plan[0].toUpperCase()}${plan.substring(1).toLowerCase()}`

  return (
    <div className={classNames.join(' ')}>
      {highlight(transformedPlan, search)}
    </div>
  )
}

PropTypes.propTypes = {
  status: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired
}

export default PlanBadge
