import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCoursesSplitIntervals = props => {
  return (
    <div className='ta-popup__courses-split-intervals'>
      <div className='ta-popup__text'>{dangerousHTML(t('servicesGroups.form.section.duration.checkboxSplitBooking.tooltip'))}</div>
    </div>
  )
}

export default PopupCoursesSplitIntervals
