import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { ServiceForm } from '../../Beauties'

const ServiceAddWrapper = props => {
  let {
    pending,
    showExternalIds,
    form,
    customisations,
    hash,
    isAdmin,
    branchesAccessBlacklist,
    resourceRemindersEmailRecipients,
    customerEmailRemindersMinutes
  } = props
  showExternalIds = !!showExternalIds
  pending = !!pending
  form = form || {}
  customisations = customisations || {}
  showExternalIds = !!showExternalIds
  const hasCustomerReminders = customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0 && (customerEmailRemindersMinutes.length !== 1 || customerEmailRemindersMinutes[0] !== 0)
  const hasResourceReminders = resourceRemindersEmailRecipients && resourceRemindersEmailRecipients.length > 0
  const hasAdvancedTab = hasCustomerReminders || hasResourceReminders

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.serviceFormGet()
  }, [])

  const onSubmit = cb => {
    handlers.serviceFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <ServiceForm
          title={t('servicesGroups.add.service.heading')}
          type='add'
          cancelLink='/management/services'
          onSubmit={onSubmit}
          showExternalIds={showExternalIds}
          customisations={customisations}
          hash={hash}
          isAdmin={isAdmin}
          branchesAccessBlacklist={branchesAccessBlacklist}
          location={form.location && form.location.values}
          hasAdvancedTab={hasAdvancedTab}
        />
      )}
    </>
  )
}

const maps = state => ({
  pending: state.services.pendingForm,
  showExternalIds: state.company.settings && state.company.settings.showExternalIds,
  form: state.forms.services,
  customisations: state.company.customisations,
  hash: state.router.hash,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist,
  resourceRemindersEmailRecipients: state.company.settings && state.company.settings.resourceRemindersEmailRecipients,
  customerEmailRemindersMinutes: state.company.settings && state.company.settings.customerEmailRemindersMinutes
})

export default memo(connect(maps)(ServiceAddWrapper))
