import React from 'react'
import {
  Row,
  Col,
  Checkbox,
  DurationInput,
  BorderedBox,
  Title,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'
import { hex2rgba } from '../../../../Utils'

const BranchesServiceCombinationFormDuration = props => {
  const {
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    allowedSet
  } = props
  const color = '#385f87'
  const bufferTimesStyles = { background: hex2rgba(color, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color, 1) }
  const isDurationBeforeDisabled = !allowedSet?.includes('durationBefore')
  const isDurationAfterDisabled = !allowedSet?.includes('durationAfter')

  return (
    <>
      <Row>
        <Col>
          <HoverPopup disabled={!isDurationBeforeDisabled || !isDurationAfterDisabled} className='ta-services__form__description-hover-popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                name='showDurationBeforeAndAfter'
                label={t('servicesGroups.form.section.duration.checkboxPrepFollowUpTime.description')}
                theme='switch'
                disabled={isDurationBeforeDisabled && isDurationAfterDisabled}
                inline
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      {(showDurationBeforeAndAfter &&
        <BorderedBox>
          <div className='ta-buffer-times'>
            <div className='ta-buffer-times__box' style={bufferTimesStyles}>
              <div className='ta-buffer-times__box__content' style={bufferTimesContentContentStyles} />
            </div>
            <Row>
              <Col>
                <HoverPopup disabled={!isDurationBeforeDisabled} className='ta-services__form__description-hover-popup'>
                  <HoverPopupContent position='top'>
                    {t('globalSettings.form.section.attributes.disabled')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Title theme='striped' label={t('global.duration.preparationTime')} />
                    <DurationInput name='durationBefore' disabled={isDurationBeforeDisabled} noDays={!showDurationDaySelector} />
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
            <Row>
              <Col>
                <HoverPopup disabled={!isDurationAfterDisabled} className='ta-services__form__description-hover-popup'>
                  <HoverPopupContent position='top'>
                    {t('globalSettings.form.section.attributes.disabled')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Title theme='striped' label={t('global.duration.followUpTime')} />
                    <DurationInput name='durationAfter' disabled={isDurationAfterDisabled} noDays={!showDurationDaySelector} />
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
          </div>
        </BorderedBox>
      )}
    </>
  )
}

export default BranchesServiceCombinationFormDuration
