export default (options) => {
  let {
    position,
    year,
    month,
    day,
    separator,
    isShort
  } = options || {}
  if (!options || !position) return null
  if (isShort && day) day = day.replace('ddd. ', '').replace('dddd ', '')
  let format = position
  if (separator) format = format.split(' ').join(separator)
  if (day) format = format.replace('DD', day)
  if (month) format = format.replace('MM', month)
  if (year) format = format.replace('YYYY', year)

  return format
}
