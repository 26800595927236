import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, isRouteDisabledByCustomisations } from '../../Common'
import { BranchesServiceForm } from '../../Beauties'
import { SERVICE_INITIAL_KEYS } from '../../Settings/settings'

const BranchesPreviewServicesEditWrapper = props => {
  let {
    id,
    branches,
    branchId,
    pending,
    form,
    settings,
    hash
  } = props
  let { showExternalIds, serviceLocalUpdates } = settings || {}
  const { isGlobal } = form || {}
  const { value: isGlobalValue } = isGlobal || {}
  if (!isGlobalValue) serviceLocalUpdates = SERVICE_INITIAL_KEYS
  else {
    serviceLocalUpdates = serviceLocalUpdates.isForbidden
      ? []
      : !serviceLocalUpdates.keys.length
          ? SERVICE_INITIAL_KEYS
          : serviceLocalUpdates.keys
  }
  branches = branches || []
  pending = !!pending
  form = form || {}
  showExternalIds = !!showExternalIds
  const branch = branches.find(item => item.id === branchId) || {}
  let { plan, hasStripeConnected, areResourcesFetched } = branch || {}
  plan = plan || 'CLASSIC'
  hasStripeConnected = !!hasStripeConnected
  areResourcesFetched = !!areResourcesFetched
  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    const { id: companyId } = branch || {}
    areResourcesFetched ? handlers.branchesServiceFormGet(id, branch) : handlers.branchPreviewResourcesGet(companyId)
  }, [id, branch, areResourcesFetched])

  const onSubmit = cb => {
    handlers.branchesServiceFormSave(branch, form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <BranchesServiceForm
          id={id}
          type='edit'
          cancelLink={`/branches/${branchId}/preview@@services`}
          onSubmit={onSubmit}
          plan={plan}
          showExternalIds={showExternalIds}
          hasStripeConnected={hasStripeConnected}
          branch={branch}
          hash={hash}
          allowedSet={serviceLocalUpdates}
        />
      )}
    </>
  )
}

const maps = state => ({
  hash: state.router.hash,
  id: state.router.data.id,
  branches: state.branches.list,
  branchId: state.router.data.branchId,
  pending: state.branches.pendingForm,
  form: state.forms.branchesService,
  settings: state.company.settings
})

export default memo(connect(maps)(BranchesPreviewServicesEditWrapper))
