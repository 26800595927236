import React from 'react'

const Row = props => {
  const { children, className, noOffset } = props
  const classNames = ['ta-row']
  if (className) classNames.push(className)
  if (noOffset) classNames.push('no-offset')

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default Row
