import React from 'react'

const SimpleListItemButtons = props => {
  const { children, extraClassName } = props
  const classNames = ['ta-simple-list-item__buttons']
  if (extraClassName) classNames.push(extraClassName)

  return (
    <div className={classNames.join(' ')}>{children}</div>
  )
}

export default SimpleListItemButtons
