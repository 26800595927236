import React from 'react'
import { connect } from '../../Store'
import { BranchesServiceFormSettings } from '../../Beauties'

const BranchesPreviewServiceFormSettingsWrapper = props => {
  let {
    hasStripeConnected,
    hasOnlinePayment,
    isCombination,
    allowedSet
  } = props
  hasStripeConnected = !!hasStripeConnected
  hasOnlinePayment = !!hasOnlinePayment
  isCombination = !!isCombination

  return (
    <BranchesServiceFormSettings
      hasOnlinePayment={hasOnlinePayment}
      hasStripeConnected={hasStripeConnected}
      isCombination={isCombination}
      allowedSet={allowedSet}
    />
  )
}

const maps = state => ({
  hasOnlinePayment: state.forms.branchesService.hasOnlinePayment && state.forms.branchesService.hasOnlinePayment.value
})

export default connect(maps)(BranchesPreviewServiceFormSettingsWrapper)
