import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesPreviewResources } from '../../Beauties'
import { Loader } from '../../Common'

const BranchesPreviewResourcesWrapper = props => {
  let { id, branches, account, company } = props
  branches = branches || []
  account = account || {}
  company = company || {}
  const branch = branches.find(item => item.id === id) || {}
  let { areResourcesFetched } = branch || {}
  areResourcesFetched = !!areResourcesFetched
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { branchesRead, branchesWrite, branchesDelete } = enterprisePermissions || {}
  branchesRead = !!isAdmin || !!branchesRead
  branchesWrite = !!isAdmin || !!branchesWrite
  branchesDelete = !!isAdmin || !!branchesDelete
  const { enterpriseCustomisation } = company || {}
  const { payload } = enterpriseCustomisation || {}
  const { settings } = payload || {}
  const {
    hideBranchEditResourceBtn,
    hideBranchDeleteResourceBtn,
    hideBranchPasswordResetEmailBtn,
    showBranchResourcePasswordChangeBtn,
    showBranchResourceUnblockBtn,
    hideBranchResendConfirmationEmailBtn,
    hideBranchConfirmCompanyBtn
  } = settings || {}

  useEffect(() => {
    if (branchesRead && id) {
      if (!areResourcesFetched) {
        handlers.branchPreviewResourcesGet(id)
        handlers.branchImportServiceMessagesHide()
        handlers.branchImportGroupMessagesHide()
      }
    } else {
      handlers.navigateToPath(`/branches/${id}/preview`)
    }
  }, [id, areResourcesFetched, branchesRead])

  const handleOnClickConfirmForCompany = data => {
    handlers.branchesPreviewCompanyConfirm(data)
  }

  const handleOnClickResendConfirmationEmail = data => {
    handlers.branchesPreviewConfirmationResend(data)
  }

  const handleOnUnlock = (e, { resourceId, userId }) => {
    e.stopPropagation()
    handlers.branchesPreviewAccountUnlock({ resourceId, userId })
  }

  let {
    isPending,
    categoriesWithResources,
    filter,
    showImportMessage,
    importType
  } = props
  isPending = !!isPending
  showImportMessage = !!showImportMessage
  branches = branches || []
  categoriesWithResources = categoriesWithResources || []

  if (isPending) return <Loader active />
  return (
    <BranchesPreviewResources
      filter={filter}
      branch={branch}
      categoriesWithResources={categoriesWithResources}
      handleOnClickResendConfirmationEmail={handleOnClickResendConfirmationEmail}
      handleOnClickConfirmForCompany={handleOnClickConfirmForCompany}
      isPending={!areResourcesFetched}
      importType={importType}
      showImportMessage={showImportMessage}
      branchesWrite={branchesWrite}
      branchesDelete={branchesDelete}
      hideBranchEditResourceBtn={hideBranchEditResourceBtn}
      hideBranchDeleteResourceBtn={hideBranchDeleteResourceBtn}
      hideBranchPasswordResetEmailBtn={hideBranchPasswordResetEmailBtn}
      hideBranchResendConfirmationEmailBtn={hideBranchResendConfirmationEmailBtn}
      hideBranchConfirmCompanyBtn={hideBranchConfirmCompanyBtn}
      handleOnUnlock={handleOnUnlock}
      showBranchResourcePasswordChangeBtn={showBranchResourcePasswordChangeBtn}
      showBranchResourceUnblockBtn={showBranchResourceUnblockBtn}
    />
  )
}

const maps = (state, props) => {
  const branches = state.branches.list || []
  const id = (state.router.data && state.router.data.id) || ''
  const branch = branches.find(item => item.id === id) || {}
  const categories = (branch.resources && branch.resources.resourceCategories) || []
  const resources = (branch.resources && branch.resources.resources) || []
  const filter = ((state.forms.branchResourcesFilter.name && state.forms.branchResourcesFilter.name.value) && state.forms.branchResourcesFilter.name.value.toLowerCase()) || ''
  const categoriesWithResources = categories.map(category => ({
    ...category,
    resources: resources.filter(item => item.categoryId === category.id && item.name.toLowerCase().includes(filter))
  })) || []

  return {
    id,
    isPending: state.branches.pendingPreview,
    branches: state.branches.list,
    categoriesWithResources,
    filter,
    showImportMessage: state.branches.showImportResourcesMessage,
    importType: state.branches.importResourcesType,
    account: state.account,
    company: state.company,
    enterpriseCustomisation: state.account && state.account.enterprise && state.account.enterprise.enterpriseCustomisation
  }
}

export default connect(maps)(BranchesPreviewResourcesWrapper)
