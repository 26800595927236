import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { SystemMessages } from '../../../Beauties'
import { isRouteDisabledByCustomisations } from '../../../Common'

const SystemMessagesWrapper = (props) => {
  const [hasAdvancedFilter, sethasAdvancedFilter] = useState(true)
  let {
    pending,
    systemMessages,
    systemMessagesLastPage,
    systemMessagesCount
  } = props
  systemMessagesCount = systemMessagesCount || 0
  pending = !!pending

  const loadMore = () => {
    !pending && handlers.systemMessagesListPageIncrement()
  }

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }
    handlers.formSet('systemMessagesFilters', {})
    handlers.systemMessagesListGet()
  }, [])

  return (
    <SystemMessages
      systemMessages={systemMessages}
      pending={pending}
      hasMore={!systemMessagesLastPage}
      loadMore={loadMore}
      hasAdvancedFilter={hasAdvancedFilter}
      systemMessagesCount={systemMessagesCount}
      toggleAdvancedOptions={() => sethasAdvancedFilter((prev) => !prev)}
    />
  )
}

SystemMessagesWrapper.propTypes = {
  systemMessages: PropTypes.arrayOf(PropTypes.object).isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  systemMessages: state?.notifications?.systemMessages,
  systemMessagesCount: state?.notifications?.systemMessagesCount,
  systemMessagesLastPage: true,
  pending: false
})

export default connect(maps)(SystemMessagesWrapper)
