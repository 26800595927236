import React from 'react'
import { BorderedBox, Accordion } from '../../../Common'

import './AccordionList.css'

const AccordionList = props => {
  const { children, expand } = props

  return (
    <BorderedBox className='ta-accordion-list'>
      <Accordion expand={expand}>
        {children}
      </Accordion>
    </BorderedBox>
  )
}

export default AccordionList
