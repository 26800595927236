import React from 'react'
import { connect } from '../../Store'
import { BranchesGroupFormDuration } from '../../Beauties'

const BranchesPreviewServiceFormDurationWrapper = props => {
  const {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    showDurationDaySelector = false,
    splitDurationInIntervals,
    plan,
    customisations,
    color,
    allowedSet
  } = props

  return (
    <BranchesGroupFormDuration
      allowedSet={allowedSet}
      durationBefore={durationBefore}
      durationAfter={durationAfter}
      showDurationBeforeAndAfter={showDurationBeforeAndAfter}
      showDurationDaySelector={showDurationDaySelector}
      splitDurationInIntervals={splitDurationInIntervals}
      plan={plan}
      customisations={customisations}
      color={color}
    />
  )
}

const maps = state => ({
  durationBefore: (state.forms.branchesGroup.durationBefore && state.forms.branchesGroup.durationBefore.value) || '',
  durationAfter: (state.forms.branchesGroup.durationAfter && state.forms.branchesGroup.durationAfter.value) || '',
  color: (state.forms.branchesGroup.color && state.forms.branchesGroup.color.value) || '',
  showDurationBeforeAndAfter: (state.forms.branchesGroup.showDurationBeforeAndAfter && state.forms.branchesGroup.showDurationBeforeAndAfter.value) || false,
  splitDurationInIntervals: (state.forms.branchesGroup.splitDurationInIntervals && state.forms.branchesGroup.splitDurationInIntervals.value) || false,
  showDurationDaySelector: (state.account.companySettings && state.account.companySettings.showDurationDaySelector) || false
})

export default connect(maps)(BranchesPreviewServiceFormDurationWrapper)
