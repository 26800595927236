import {
  swapItemsInDnDList,
  swapCategoriesInDnDList,
  sortByOrderIndex
} from '../../../Utils'

import {
  courseCategoryFormTransform,
  courseTransform,
  courseListTransform,
  courseFormTransform,
  courseRestoreFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  pendingList: true,
  pendingListCategory: false,
  pendingForm: true,
  pendingPreview: true,
  pendingCategory: null,
  pendingCategoryPreview: null,
  pendingCategoryForm: true,
  selected: null,
  dragging: {},
  messagePreview: null,
  messageList: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  coursesReset: () => dispatch(actions.COURSES_RESET),

  // Subscription
  coursesSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.COURSES_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  coursesListPopulate: ({ courses, courseCategories }) => dispatch(actions.COURSES_LIST_POPULATE, { courses: courseListTransform(courses), courseCategories }),
  coursesListReady: () => dispatch(actions.COURSES_LIST_READY),
  coursesListOrderChange: ({ id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => dispatch(actions.COURSES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }),
  coursesListOrderChanged: (updatedcourses) => dispatch(actions.COURSES_LIST_ORDER_CHANGED, updatedcourses),

  // One
  courseUpdate: course => dispatch(actions.COURSE_UPDATE, courseTransform(course)),
  courseUpdated: course => dispatch(actions.COURSE_UPDATED, course),
  courseDelete: id => dispatch(actions.COURSE_DELETE, id),
  courseDeleted: id => dispatch(actions.COURSE_DELETED, id),
  courseRemoveDeleted: id => dispatch(actions.COURSE_REMOVE_DELETED, id),
  coursesToDeleteGet: () => dispatch(actions.COURSES_TO_DELETE_GET),
  coursesToDeletePopulate: (coursesToDelete) => dispatch(actions.COURSES_TO_DELETE_POPULATE, coursesToDelete),
  courseToDeleteDelete: ({ companyId, region, externalId, id }) => dispatch(actions.COURSE_TO_DELETE_DELETE, { companyId, region, externalId, id }),
  coursesToDeleteDeleteReady: course => dispatch(actions.COURSES_TO_DELETE_READY, course),

  // Preview
  coursePreviewGet: ({ id, forceFetch }) => dispatch(actions.COURSE_PREVIEW_GET, { id, forceFetch }),
  coursePreviewPopulate: course => dispatch(actions.COURSE_PREVIEW_POPULATE, course),
  coursePreviewMessageSet: message => dispatch(actions.COURSE_PREVIEW_MESSAGE_SET, message),

  // Locally Changed
  courseLocallyChangedGet: ({ id, internalId }) => dispatch(actions.COURSE_LOCALLY_CHANGED_GET, { id, internalId }),
  courseLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.COURSE_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  courseLocallyChangedReset: ({ companyIds, internalId }) => dispatch(actions.COURSE_LOCALLY_CHANGED_RESET, { companyIds, internalId }),
  courseLocallyChangedResetReady: ({ course, companyIds }) => dispatch(actions.COURSE_LOCALLY_CHANGED_RESET_READY, { course, companyIds }),

  // Form
  courseFormGet: id => dispatch(actions.COURSE_FORM_GET, id),
  courseFormPopulate: ({ course, branches, account }) => {
    handlers.formSet('courses', courseFormTransform({ course, branches, account }))
    handlers.courseFormReady()
  },
  courseFormReady: () => dispatch(actions.COURSE_FORM_READY),
  courseFormSave: (course, scrollToError = () => { }) => dispatch(actions.COURSE_FORM_SAVE, { course, scrollToError }),

  // Restore
  courseRestoreFormGet: service => dispatch(actions.COURSE_RESTORE_FORM_GET, service),
  courseRestoreFormPopulate: course => {
    handlers.formSet('courseChangedLocally', courseRestoreFormTransform(course))
    handlers.courseRestoreFormReady()
  },
  courseRestoreFormReady: () => dispatch(actions.COURSE_RESTORE_FORM_READY),

  // Category
  courseCategoryUpdate: category => dispatch(actions.COURSE_CATEGORY_UPDATE, category),
  courseCategoryDelete: id => dispatch(actions.COURSE_CATEGORY_DELETE, id),
  courseCategoryDeleted: id => dispatch(actions.COURSE_CATEGORY_DELETED, id),
  courseCategoryRemoveDeleted: id => dispatch(actions.COURSE_CATEGORY_REMOVE_DELETED, id),
  courseCategoryDeleteFailed: () => dispatch(actions.COURSE_CATEGORY_DELETE_FAILED),
  courseCategoryOrderChange: ({ id, oldIndex, newIndex }) => dispatch(actions.COURSE_CATEGORIES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex }),
  courseCategoryOrderChanged: updatedCategories => dispatch(actions.COURSE_CATEGORIES_LIST_ORDER_CHANGED, updatedCategories),
  courseCategoryLocallyChangedGet: id => dispatch(actions.COURSE_CATEGORY_LOCALLY_CHANGED_GET, id),
  courseCategoryLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.COURSE_CATEGORY_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  courseCategoryLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.COURSE_CATEGORY_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  courseCategoryLocallyChangedResetReady: ({ courseCategory, companyId }) => dispatch(actions.COURSE_CATEGORY_LOCALLY_CHANGED_RESET_READY, { courseCategory, companyId }),

  // Category form
  courseCategoryFormGet: id => dispatch(actions.COURSE_CATEGORY_FORM_GET, id),
  courseCategoryFormPopulate: ({ category, branches, account }) => {
    handlers.formSet('courseCategory', courseCategoryFormTransform({ category, branches, account }))
    handlers.courseCategoryFormReady()
  },
  courseCategoryFormReady: () => dispatch(actions.COURSE_CATEGORY_FORM_READY),
  courseCategoryFormSave: (category, scrollToError = () => { }) => dispatch(actions.COURSE_CATEGORY_FORM_SAVE, { category, scrollToError })
})

// Reset

reducer.COURSES_RESET = state => reducer.initialState

// Subscription

reducer.COURSES_SUBSCRIPTION_SET = state => state

// List

reducer.COURSES_LIST_POPULATE = (state, { courses, courseCategories }) => ({
  ...state,
  list: (courses || []).sort(sortByOrderIndex),
  categoriesList: (courseCategories || []).sort(sortByOrderIndex),
  pendingList: false
})

reducer.COURSES_LIST_READY = state => ({
  ...state,
  pendingList: false
})

reducer.COURSES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  return {
    ...state,
    list: swapItemsInDnDList({
      categoryList: state.categoriesList,
      list: state.list,
      id,
      oldIndex,
      newIndex,
      sourceCategoryId,
      destinationCategoryId
    })
  }
}

reducer.COURSES_LIST_ORDER_CHANGED = (state, updatedcourses) => {
  updatedcourses = updatedcourses.map(uS => {
    const oldcourse = state.list.find(s => s.id === uS.id)
    return {
      ...oldcourse,
      ...uS
    }
  })
  return {
    ...state,
    list: state.list
      .filter(item => !updatedcourses.find(uS => uS.id === item.id))
      .concat(updatedcourses)
      .sort(sortByOrderIndex)
  }
}

// List to delete
reducer.COURSES_TO_DELETE_GET = state => ({
  ...state,
  pendingToDeleteList: true
})

reducer.COURSES_TO_DELETE_POPULATE = (state, coursesToDelete) => ({
  ...state,
  listToDelete: coursesToDelete || [],
  pendingToDeleteList: false
})

reducer.COURSE_TO_DELETE_DELETE = (state, { id }) => {
  if (!id) return state
  const coursesToDelete = state.listToDelete || []
  const remainingFieldsToDelete = coursesToDelete.filter(item => item.id !== id)
  return {
    ...state,
    listToDelete: remainingFieldsToDelete,
    pendingToDeleteList: true
  }
}

reducer.COURSES_TO_DELETE_READY = state => ({
  ...state,
  pendingToDeleteList: false
})

// One

reducer.COURSE_UPDATE = (state, course) => {
  if (!course) return state
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== course.id)
      .concat([{ ...course, isUpdated: true }])
      .sort(sortByOrderIndex)
  }
}

reducer.COURSE_UPDATED = (state, course) => {
  if (!course) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === course.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}

reducer.COURSE_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.COURSE_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}

reducer.COURSE_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

// Preview

reducer.COURSE_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true,
  messagePreview: null
})

reducer.COURSE_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

reducer.COURSE_PREVIEW_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingPreview: false,
  messagePreview: message,
  selected: null
})

reducer.COURSE_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.COURSE_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = {
    ...list[index],
    locallyChangedData
  }
  return {
    ...state,
    list,
    pendingForm: false,
    pendingPreview: false
  }
}

reducer.COURSE_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingForm: true,
  pendingPreview: true
})

reducer.COURSE_LOCALLY_CHANGED_RESET_READY = (state, { course }) => {
  const { list } = state || {}
  if (course) {
    const index = state.list.findIndex(({ internalId }) => internalId === course.internalId)
    if (index >= 0) {
      list[index] = {
        ...list[index],
        locallyUpdatedBranches: course.locallyUpdatedBranches
      }
    }
  }

  return {
    ...state,
    list,
    pendingForm: false,
    pendingPreview: false
  }
}

// Restore Form

reducer.COURSE_RESTORE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.COURSE_RESTORE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

// Form
reducer.COURSE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.COURSE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.COURSE_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Category

reducer.COURSE_CATEGORY_UPDATE = (state, category) => {
  if (!category) return state
  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => item.id !== category.id)
      .concat([category])
      .sort(sortByOrderIndex)
  }
}

reducer.COURSE_CATEGORY_DELETE = (state, id) => ({
  ...state,
  pendingCategory: id
})

reducer.COURSE_CATEGORY_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = { ...categoriesList[index], isDeleted: true }
  return {
    ...state,
    categoriesList,
    pendingCategory: null
  }
}

reducer.COURSE_CATEGORY_REMOVE_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const defaultCategory = categoriesList.find(item => item.isDefault)
  const deletedCategorycourses = [...state.list]
    .filter(item => item.categoryId === id)
    .map(item => ({ ...item, categoryId: defaultCategory.id }))
  const list = [...state.list]
    .filter(item => item.categoryId !== id)
    .concat(deletedCategorycourses)
    .map((item, index) => ({ ...item, orderIndex: index + 1 }))
  return {
    ...state,
    list,
    categoriesList: state.categoriesList.filter(item => item.id !== id)
  }
}

reducer.COURSE_CATEGORY_DELETE_FAILED = state => ({
  ...state,
  pendingCategory: null
})

reducer.COURSE_CATEGORIES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  return {
    ...state,
    categoriesList: swapCategoriesInDnDList({
      list: state.categoriesList,
      id,
      oldIndex,
      newIndex
    })
  }
}

reducer.COURSE_CATEGORIES_LIST_ORDER_CHANGED = (state, updatedCategories) => {
  updatedCategories = updatedCategories.map(uC => {
    const oldCategory = state.categoriesList.find(c => c.id === uC.id)
    return {
      ...oldCategory,
      ...uC
    }
  })

  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => !updatedCategories.find(uC => uC.id === item.id))
      .concat(updatedCategories)
      .sort(sortByOrderIndex)
  }
}

// Category preivew
reducer.COURSE_CATEGORY_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingCategoryPreview: true
})

reducer.COURSE_CATEGORY_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = {
    ...categoriesList[index],
    locallyChangedData
  }
  return {
    ...state,
    categoriesList,
    pendingCategoryPreview: false
  }
}

reducer.COURSE_CATEGORY_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingCategoryPreview: true
})

reducer.COURSE_CATEGORY_LOCALLY_CHANGED_RESET_READY = (state, { courseCategory, companyId }) => {
  const { internalId } = courseCategory || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  categoriesList[index].locallyChangedData = categoriesList[index].locallyChangedData.filter(item => item.branch.id !== companyId)
  delete categoriesList[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    categoriesList,
    pendingCategoryPreview: false
  }
}

// Category form

reducer.COURSE_CATEGORY_FORM_GET = state => ({
  ...state,
  pendingCategoryForm: true
})

reducer.COURSE_CATEGORY_FORM_READY = state => ({
  ...state,
  pendingCategoryForm: false
})

reducer.COURSE_CATEGORY_FORM_SAVE = state => ({
  ...state,
  pendingCategoryForm: true
})

export default reducer
