import React from 'react'
import { connect } from '../../Store'
import { BranchesResourceFormPermissions } from '../../Beauties'

const BranchesPreviewResourceFormPermissionsWrapper = props => {
  const {
    isOwner,
    hasInvitation,
    hasAccount,
    permissions,
    email,
    plan,
    accountEmail,
    branch,
    isPermissionUpdateAllowedByIssuer
  } = props
  const isEditingOwnResource = accountEmail === email

  return (
    <BranchesResourceFormPermissions
      branch={branch}
      plan={plan}
      hasInvitation={hasInvitation}
      hasAccount={hasAccount}
      isOwner={isOwner}
      isCustom={permissions === 'CUSTOM'}
      isEditingOwnResource={isEditingOwnResource}
      isPermissionUpdateAllowedByIssuer={isPermissionUpdateAllowedByIssuer}
    />
  )
}

// Get the date you need and filter it in the component
const maps = (state, props) => {
  const { branch } = props
  const { plan = 'CLASSIC' } = branch || {}
  return {
    isOwner: state.forms.branchesResource.isOwner || false,
    hasInvitation: (state.forms.branchesResource.hasInvitation && state.forms.branchesResource.hasInvitation.value) || false,
    hasAccount: (state.forms.branchesResource.hasAccount && state.forms.branchesResource.hasAccount.value) || false,
    permissions: (state.forms.branchesResource.permissions && state.forms.branchesResource.permissions.value) || null,
    email: (state.forms.branchesResource.email && state.forms.branchesResource.email.value) || '',
    isPermissionUpdateAllowedByIssuer: state.forms.branchesResource.isPermissionUpdateAllowedByIssuer || false,
    plan,
    accountEmail: state.account && state.account.email
  }
}

export default connect(maps)(BranchesPreviewResourceFormPermissionsWrapper)
