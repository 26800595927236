import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import './AppsIframe.css'

const AppsIframe = (props, ref) => {
  const { iframeUrl } = props || {}

  return (
    <iframe ref={ref} className='ta-apps__preview__iframe' title='ta-app-iframe' height='100%' width='100%' src={iframeUrl} />
  )
}
export default forwardRef(AppsIframe)

AppsIframe.propTypes = {
  iframe: PropTypes.string
}

