import React from 'react'
import { connect } from '../../Store'
import { NavigationAccount } from '../../Beauties'

const NavigationAccountWrapper = props => {
  const { name, routerName, avatarUrl } = props
  const ROUTES_ACCOUNT = ['user', 'userEdit', 'userEditPassword', 'userEditEmail', 'userEditLanguage']
  const isActive = ROUTES_ACCOUNT.includes(routerName)

  return (
    <NavigationAccount
      name={name}
      avatarUrl={avatarUrl}
      isActive={isActive}
    />
  )
}

const maps = state => ({
  name: (state.account && state.account.name) || 'User',
  avatarUrl: (state.account && state.account.avatarUrl) || '',
  routerName: state.router.name
})

export default connect(maps)(NavigationAccountWrapper)
