import React from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  t
} from '../../../Common'

const BranchesPreviewCustomersFilter = props => {
  return (
    <Form name='branchPreviewCustomersFilter'>
      <Row>
        <Col>
          <Input
            name='search'
            label={t('global.search.label')}
            clearable
          />
        </Col>
      </Row>
    </Form>
  )
}

export default BranchesPreviewCustomersFilter
