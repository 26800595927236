import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { CourseCategoryPreview } from '../../Beauties'

const CourseCategoryPreviewWrapper = props => {
  let {
    categoryId,
    categories,
    hash,
    pending,
    account,
    allowOnlyGlobalCustomers
  } = props
  categories = categories || []
  pending = !!pending
  account = account || {}
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.courseCategoryLocallyChangedGet(categoryId)
  }, [categoryId])
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalGroupsRead, globalGroupsWrite, globalGroupsDelete } = enterprisePermissions || {}
  globalGroupsRead = !!isAdmin || !!globalGroupsRead
  globalGroupsWrite = !!isAdmin || !!globalGroupsWrite
  globalGroupsDelete = !!isAdmin || !!globalGroupsDelete
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
  const category = categories.find(item => item.id === categoryId) || {}

  return (
    pending
      ? <Loader active />
      : (
        <CourseCategoryPreview
          category={category}
          hash={hash}
          pending={pending}
          globalGroupsRead={globalGroupsRead}
          globalGroupsWrite={globalGroupsWrite}
          globalGroupsDelete={globalGroupsDelete}
          allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        />
        )
  )
}

const maps = state => ({
  hash: state.router.hash,
  categoryId: (state.router.data && state.router.data.categoryId),
  categories: state.courses.categoriesList,
  pending: state.courses.pendingCategoryPreview,
  account: state.account || {},
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default connect(maps)(CourseCategoryPreviewWrapper)
