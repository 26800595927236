import React from 'react'
import { t } from '../../../../../Common'
import './MinimumLeadTimeHoursExample.css'

const MinimumLeadTimeHoursExample = props => {
  let { value, label, duration } = props
  duration = duration || 'HOURS'
  value = parseInt(value, 10)
  let timelineValue = value
  if (!value || value < 2) timelineValue = 0
  else if (value < 4) timelineValue = 2
  else if (value < 8) timelineValue = 4
  else if (value < 12) timelineValue = 8
  else if (value < 24) timelineValue = 12
  else if (value < 48) timelineValue = 24
  else if (value < 72) timelineValue = 48
  else timelineValue = 72
  const classNames = ['ta-minimum-lead-time-example']
  classNames.push(`hours-${timelineValue}`)

  return (
    <div className={classNames.join(' ')}>
      {(label &&
        <div className='ta-minimum-lead-time-example__label'>{label}</div>
      )}
      <div className='ta-minimum-lead-time-example__timeline'>
        <div className='ta-minimum-lead-time-example__timeline-slots'>
          <div className='ta-minimum-lead-time-example__slot slot-1' />
          <div className='ta-minimum-lead-time-example__slot slot-2' />
          <div className='ta-minimum-lead-time-example__slot slot-3' />
          <div className='ta-minimum-lead-time-example__slot slot-4' />
          <div className='ta-minimum-lead-time-example__slot slot-5' />
          <div className='ta-minimum-lead-time-example__slot slot-6' />
          <div className='ta-minimum-lead-time-example__slot slot-7' />
          <div className='ta-minimum-lead-time-example__slot slot-8' />
        </div>
        <div className='ta-minimum-lead-time-example__timeline-inactive'>
          <div className='ta-minimum-lead-time-example__now'>Now</div>
          <div className='ta-minimum-lead-time-example__hours'>{(value || '0').toString()} {t(`global.${duration.toLowerCase()}`)}</div>
        </div>
        <div className='ta-minimum-lead-time-example__timeline-active' />
      </div>
    </div>
  )
}

export default MinimumLeadTimeHoursExample
