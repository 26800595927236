import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { ServicesCategory, ServicesCategoryWithAccordion } from '../../Beauties'
import { sortByOrderIndex } from '../../Utils'

const ServicesCategoryWrapper = props => {
  let {
    category,
    pendingCategory,
    services,
    isDragAndDropMode,
    index,
    areCategoriesExpanded,
    filters,
    isGlobalHover,
    settings,
    account,
    isLastIndex
  } = props
  pendingCategory = !!pendingCategory
  filters = filters || {}
  settings = settings || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalServicesRead, globalServicesWrite, globalServicesDelete } = enterprisePermissions || {}
  globalServicesRead = !!isAdmin || !!globalServicesRead
  globalServicesWrite = !!isAdmin || !!globalServicesWrite
  globalServicesDelete = !!isAdmin || !!globalServicesDelete
  let { showAddServiceCombinations } = settings || {}
  showAddServiceCombinations = !!showAddServiceCombinations
  const pending = pendingCategory === category.id
  const { search, type } = filters || {}
  let { value: searchValue } = search || {}
  const { value: typeValue } = type || {}
  searchValue = searchValue || ''
  let filteredServices = services.filter(item => item.categoryId === category.id) || []
  if (!isDragAndDropMode && (searchValue || typeValue)) {
    filteredServices = filteredServices.filter(item => {
      return (
        (item.name.toLowerCase().includes(searchValue.toLowerCase()) || item.id === searchValue) &&
        (!typeValue || (typeValue === 'service' && !item.isCombination) || (typeValue === 'serviceCombination' && item.isCombination))
      )
    }) || []
  }
  const hideCategory = (searchValue || typeValue) && filteredServices.length === 0

  return (
    <>
      {(isDragAndDropMode &&
        <ServicesCategory
          isDragAndDropMode={isDragAndDropMode}
          category={category}
          services={filteredServices}
          pending={pending}
          index={index}
          isGlobalHover={isGlobalHover}
          areCategoriesExpanded={areCategoriesExpanded}
          globalServicesRead={globalServicesRead}
          globalServicesWrite={globalServicesWrite}
          globalServicesDelete={globalServicesDelete}
        />
      )}
      {(!isDragAndDropMode && !hideCategory &&
        <ServicesCategoryWithAccordion
          category={category}
          services={filteredServices}
          allServices={services}
          pending={pending}
          index={index}
          search={searchValue}
          filterType={typeValue}
          showAddServiceCombinations={showAddServiceCombinations}
          globalServicesRead={globalServicesRead}
          globalServicesWrite={globalServicesWrite}
          globalServicesDelete={globalServicesDelete}
          isLastIndex={isLastIndex}
        />
      )}
    </>
  )
}

ServicesCategoryWrapper.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object)
}

const maps = state => ({
  services: (state.services.list || []).sort(sortByOrderIndex),
  pendingCategory: state.services.pendingCategory,
  filters: state.forms.servicesFilters,
  settings: state.company.settings,
  account: state.account
})

export default connect(maps)(ServicesCategoryWrapper)
