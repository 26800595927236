import React, { Component } from 'react'
import { connect, handlers } from '../../Store'
import { UserGroupsForm } from '../../Beauties'

class UserGroupsAddWrapper extends Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    // handlers.userGroupsFormGet()
  }

  onSubmit (cb) {
    let { form } = this.props
    form = form || {}

    handlers.usersFormSave({ user: form }, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    return (
      <UserGroupsForm
        title='Add new user group'
        type='add'
        cancelLink='/managers/users/user-groups'
        onSubmit={this.onSubmit}
      />
    )
  }
}

const maps = state => ({
  form: state.forms.userGroups
})

export default connect(maps)(UserGroupsAddWrapper)
