import React from 'react'
import {
  DependenciesInput,
  Title,
  FormSection,
  t
} from '../../../../Common'

const BranchesGroupFormDependencies = ({ allowedSet }) => {
  return (
    <FormSection>
      <Title size='m' label={t('global.resources')} icon='users' />
      <DependenciesInput name='dependencies' isBranchForm isCourse allowedSet={allowedSet} disabled={!allowedSet?.includes('dependencies')} />
    </FormSection>
  )
}

export default BranchesGroupFormDependencies
