import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { TagsPreview } from '../../Beauties'

const TagPreviewWrapper = props => {
  const { id } = props
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.tagsPreviewGet({ id })
  }, [id])
  let {
    tag,
    pending,
    hash,
    enterpriseTagId,
    account
  } = props
  tag = tag || {}
  pending = !!pending
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { tagsRead, tagsWrite, tagsDelete } = enterprisePermissions || {}
  tagsRead = !!isAdmin || !!tagsRead
  tagsWrite = !!isAdmin || !!tagsWrite
  tagsDelete = !!isAdmin || !!tagsDelete

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <TagsPreview
          enterpriseTagId={enterpriseTagId}
          account={account}
          tag={tag}
          hash={hash}
          tagsRead={tagsRead}
          tagsWrite={tagsWrite}
          tagsDelete={tagsDelete}
        />
        )}
    </>
  )
}

TagPreviewWrapper.propTypes = {
  id: PropTypes.string,
  hash: PropTypes.string,
  tag: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired
}

const maps = (state, props) => ({
  id: state.router.data.id,
  hash: state.router.hash,
  enterpriseTagId: (state.account && state.account.enterprise && state.account.enterprise.id) || '',
  tag: (state.tags.selected && state.tags.list.find(item => item.id === state.tags.selected)) || {},
  pending: !!(state.tags && state.tags.pendingPreview),
  account: state.account
})

export default connect(maps)(TagPreviewWrapper)
