import React from 'react'
import { connect } from '../../Store'
import { CustomerFieldPreviewDetails } from '../../Beauties'

const CustomerFieldPreviewDetailsWrapper = props => {
  const { customerField = {}, category = {} } = props

  return <CustomerFieldPreviewDetails {...customerField} category={category} />
}

const maps = (state, props) => ({
  category: (props.customerField && state.customerFields.categoriesList.find(category => category.id === props.customerField.categoryId)) || {}
})

export default connect(maps)(CustomerFieldPreviewDetailsWrapper)
