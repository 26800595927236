import React from 'react'

import './DottedTimeline.css'

const DottedTimeline = props => {
  const { children } = props

  return (
    <div className='ta-dotted-timeline'>{children}</div>
  )
}

export default DottedTimeline
