import { payloads$, actions, handlers, globalActions, store } from '../../../Store'
import { q } from '../../API'
import { globalTagslistTransform } from './utils'

// Global actions
globalActions.populateGlobalTags = async () => {
  const state = store.getState()
  const { globals } = state || {}
  let { tags, areTagsFetched } = globals || {}
  if (!areTagsFetched) {
    tags = await q('getEnterpriseCompanyTags')
    const { error } = tags || { error: {} }
    if (error) {
      return []
    }
    handlers.globalsTagsListPopulate(globalTagslistTransform(tags))
  }
  return tags
}

// TAGS LIST
payloads$(actions.GLOBALS_TAGS_LIST_GET)
  .subscribe(async () => {

  })
