import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  Title,
  Row,
  Col,
  Alert,
  Button,
  FontAwesome5,
  FilesInput,
  Form,
  FormButtons,
  FormSection,
  Input,
  Radio,
  HintText,
  Error,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'
import './Branches.css'

const BranchesResourceCategoriesImport = props => {
  const {
    onSubmit,
    cancelLink,
    enableButton,
    onClickDownload,
    myPasswordFieldType,
    toggleMyPasswordFieldType
  } = props

  return (
    <div className='ta-branches__import'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('branches.list.resourceCategories.import')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchResourceCategoriesImport'>
            <FormSection>
              <Alert label={t('branches.list.import.bulk.resourceCategories')} theme='alert'>
                {dangerousHTML(t('branches.list.import.message.resourceCategories'))}
                {/* Uncomment when Clare is done with article <Button as='link' to='...'>More information is available in this article.</Button> */}
              </Alert>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>1</div>
                    <p className='ta-branches__import__step-title'>{t('branches.list.import.downloadTemplate')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>{t('branches.list.import.clickToDownload')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button as='link' onClick={() => onClickDownload('exportResourceCategoriesTemplate')} isSecondary>
                    <FontAwesome5 icon='file-download' type='solid' /> {t('branches.list.import.downloadTemplate.title')}
                  </Button>
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>2</div>
                    <p className='ta-branches__import__step-title'>{t('branches.list.import.uploadCSV')}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className='ta-branches__import__step-hint'>
                    {dangerousHTML(t('branches.list.import.uploadCSVFinish'))}
                    <br /><br />
                    {t('branches.list.import.uploadCSVFinish.resourceCategories.note')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FilesInput
                    name='files'
                    maxFileSize='20MB'
                    acceptedFileTypes={[
                      'text/csv',
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]}
                    acceptedFileTypesLabel='csv'
                    label={t('global.chooseFileOrDnD')}
                    shouldNotTranslateError
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='ta-branches__import__step'>
                    <div className='ta-branches__import__step-number'>3</div>
                    <p className='ta-branches__import__step-title'>{t('global.settings')}</p>
                  </div>
                </Col>
              </Row>
              <Title size='m' label={t('branches.list.import.mergeRule')} icon='code-merge' />
              <Row noOffset>
                <Col>
                  <Radio name='mergeRule' value='OVERWRITE' label={t('branches.list.import.overwriteData')} />
                  <HintText>{t('global.uploadAndReplace')}</HintText>
                </Col>
              </Row>
              <Row noOffset>
                <Col>
                  <Radio name='mergeRule' value='IGNORE' label={t('branches.list.import.uploadNewData')} />
                  <HintText>{t('branches.list.import.uploadNewData.hint')}</HintText>
                </Col>
              </Row>
              <Title size='m' label={t('global.protection')} icon='shield-alt' />
              <Row>
                <Col className='relative'>
                  <Input
                    type={myPasswordFieldType}
                    name='myPassword'
                    hintText={t('global.yourPassword.hint')}
                    mandatory
                  />
                  <div className='ta-password__type-toggle-btn' onClick={toggleMyPasswordFieldType}>
                    <FontAwesome5 icon={myPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
              <Error name='globalErrors' shouldNotTranslate />
              <Error name='file' shouldNotTranslate />
              <Error name='requestLimit' shouldNotTranslate />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button
                disabled={!enableButton}
                type='submit'
                isPrimary
                className='ta-btn ta-btn-submit'
              >
                <FontAwesome5 type='s' icon='upload' /> {t('global.import')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesResourceCategoriesImport
