import React, { PureComponent } from 'react'
import { PopupBranchSignups } from '../../../Beauties'

class PopupBranchSignupsWrapper extends PureComponent {
  componentDidMount () {
    // reposition the modal
    const el = document.querySelector('.ta-popup__content.left.ta-statistics-box')
    const leftOffset = parseInt(el.style.left.replace('px', ''))
    el.style.left = `${leftOffset + 127}px`
  }

  componentWillUnmount () {
    const element = document.querySelector('i.active')
    if (element) element.classList.remove('active')
  }

  render () {
    return (
      <PopupBranchSignups />
    )
  }
}

export default PopupBranchSignupsWrapper
