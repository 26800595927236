import React from 'react'

const DnDContainer = props => {
  const { children, className, provided = {}, snapshot = {}, ...rest } = props
  const classNames = ['ta-dnd__container']
  if (className) classNames.push(className)
  if (snapshot.isDragging) classNames.push('is-dragging')

  return (
    <div className={classNames.join(' ')} ref={provided.innerRef} {...rest}>
      {children}
    </div>
  )
}

export default DnDContainer
