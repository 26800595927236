import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { connect } from '../../Store'
import { INTERCOM_APP_ID } from '../../Settings'

const IntercomWrapper = props => {
  let {
    appLocale,
    account,
    enterprise,
    persist,
    customisations
  } = props
  account = account || {}
  enterprise = enterprise || {}
  customisations = customisations || {}
  const { payload } = customisations || {}
  const { settings } = payload || {}
  const {
    hideIntercom: customisationsHideIntercom,
    intercomAppId: customisationsIntercomAppId
  } = settings || {}
  const { userId } = account || {}
  const { id: enterpriseId } = enterprise || {}
  const localeParts = appLocale ? appLocale.split('-') : []
  const appId = customisationsIntercomAppId || INTERCOM_APP_ID
  const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)
  const { isIntercomActive = true } = persist || {}
  let hideIntercom = customisationsHideIntercom || !userId || !enterpriseId
  if (isStaging && !isIntercomActive) hideIntercom = true

  if (hideIntercom) return null
  return (
    <IntercomProvider
      autoBoot
      appId={appId}
      autoBootProps={{
        userId: `${userId}-${enterpriseId}`,
        languageOverride: localeParts[0],
        company: { companyId: enterprise }
      }}
    />
  )
}

const maps = state => ({
  appLocale: state.app.locale,
  account: state.account,
  enterprise: state.account.enterprise,
  customisations: state.company.enterpriseCustomisation,
  persist: state.persist
})

export default connect(maps)(IntercomWrapper)
