import React from 'react'
import { TagsTitle } from '../../Beauties'
import { TagsListWrapper } from '../../Beasts'
import { Blur, FixedContent, FixedContentBody, FixedContentHeader, t } from '../../Common'

import './Tags.css'

const Tags = props => {
  let { tagsRead, tagsWrite, pending } = props
  const classNames = ['ta-tags']

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <TagsTitle tagsWrite={tagsWrite} />
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!tagsRead && !pending} icon='ban' label={t('global.accessDenied')}>
            <TagsListWrapper />
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Tags
