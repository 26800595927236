import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { FIVE_MINUTES_INTERVAL_TIMES } from '../../../Settings'
import {
  feedContextInProps,
  convertMinutesToHours
} from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  FormGroup,
  Select,
  Error,
  t,
  Button
} from '../../../Common'

import './WeekDayIntervalsInput.css'

class WeekDayIntervalsInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onActiveChange = this.onActiveChange.bind(this)
    this.addInterval = this.addInterval.bind(this)
    this.deleteInterval = this.deleteInterval.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.state = {
      focusedIndex: null,
      focusedField: null
    }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (index, field) {
    this.setState({
      focusedIndex: index,
      focusedField: field
    })
  }

  onBlur () {
    this.setState({
      focusedIndex: null,
      focusedField: null
    })
  }

  onChange (selectValue, index, field) {
    const { name, formName, isActive, intervals, errors } = this.props
    if (!intervals[index]) return
    intervals[index][field] = (selectValue && selectValue.value) || null
    const hasFromError = !!errors.find((error, key) => error.index === index && error.fields && error.fields.includes('from'))
    const hasUntilError = !!errors.find((error, key) => error.index === index && error.fields && error.fields.includes('until'))
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive,
        values: [...intervals],
        errors
      }
    })
    if (field === 'from' && hasUntilError) {
      const newErrors = errors.map(item => item.index === index ? ({ ...item, fields: ['until'] }) : item)
      handlers.formErrorsSet(formName, newErrors)
    }
    if (field === 'until' && hasFromError) {
      const newErrors = errors.map(item => item.index === index ? ({ ...item, fields: ['from'] }) : item)
      handlers.formErrorsSet(formName, newErrors)
    }
  }

  onActiveChange () {
    const { name, formName, isActive, intervals, errors } = this.props
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive: !isActive,
        values: intervals,
        errors
      }
    })
  }

  addInterval () {
    const { name, formName, isActive, intervals, errors } = this.props
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive,
        values: [...intervals, { from: null, until: null }],
        errors
      }
    })
  }

  deleteInterval (index) {
    const { name, formName, isActive, intervals, errors } = this.props
    if (!isActive) return
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive,
        values: [...intervals.filter((item, key) => key !== index)],
        errors
      }
    })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  render () {
    const { focusedIndex, focusedField } = this.state
    const { className, isActive, intervals, errors, label, limit = 5 } = this.props
    const classNames = ['ta-intervals-input']
    if (className) classNames.push(className)
    if (!isActive) classNames.push('disabled')
    const checkboxClassNames = ['ta-checkbox']
    if (isActive) checkboxClassNames.push('active')
    if (intervals.length === 0) intervals.push({ from: null, until: null })
    const options = FIVE_MINUTES_INTERVAL_TIMES.map(time => ({
      label: convertMinutesToHours(time),
      value: convertMinutesToHours(time)
    }))

    return (
      <div className={classNames.join(' ')}>
        <div className='ta-intervals-input__interval__checkbox'>
          <input
            className='ta-checkbox-field'
            type='checkbox'
            checked={isActive}
            onChange={() => { }}
            autoComplete='off'
          />
          <div className={checkboxClassNames.join(' ')} onClick={this.onActiveChange}>{label}</div>
        </div>
        {intervals.map((item, index) => {
          const hasFromError = !!errors.find((error, key) => error.index === index && error.fields && error.fields.includes('from'))
          const hasUntilError = !!errors.find((error, key) => error.index === index && error.fields && error.fields.includes('until'))

          return (
            <div className={`ta-intervals-input__interval ${intervals.length > 1 ? 'has-delete' : ''}`} key={index}>
              <FormGroup
                className='ta-intervals-input__interval__from'
                focused={focusedIndex === index && focusedField === 'from'}
                filled={!!item.from}
                labelText={t('global.from')}
                labelMandatory
                disabled={!isActive}
                hasError={hasFromError}
              >
                <Select
                  className='ta-single-select'
                  noResultsText={t('global.noResults')}
                  value={item.from}
                  arrowRenderer={this.renderArrows}
                  onFocus={() => this.onFocus(index, 'from')}
                  onBlur={this.onBlur}
                  onChange={(selectValue) => this.onChange(selectValue, index, 'from')}
                  options={options}
                  clearable={false}
                  searchable
                  disabled={!isActive}
                  autoComplete='off'
                  hasError={hasFromError}
                />
              </FormGroup>
              <div className='ta-intervals-input__interval__separator'>-</div>
              <FormGroup
                className='ta-intervals-input__interval__until'
                focused={focusedIndex === index && focusedField === 'until'}
                filled={!!item.until}
                labelText={t('global.until')}
                labelMandatory
                disabled={!isActive}
                hasError={hasUntilError}
              >
                <Select
                  className='ta-single-select'
                  noResultsText={t('global.noResults')}
                  value={item.until}
                  arrowRenderer={this.renderArrows}
                  onFocus={() => this.onFocus(index, 'until')}
                  onBlur={this.onBlur}
                  onChange={(selectValue) => this.onChange(selectValue, index, 'until')}
                  options={options}
                  clearable={false}
                  searchable
                  disabled={!isActive}
                  autoComplete='off'
                  hasError={hasUntilError}
                />
              </FormGroup>
              {(intervals.length > 1 &&
                <Button isSecondary className='ta-intervals-input__interval__btn-delete' onClick={() => this.deleteInterval(index)}>
                  <FontAwesome5 icon='trash' type='regular' />
                </Button>
              )}
              {errors.length > 0 && errors.map((error, key) => error.index === index &&
                <Error noOffset key={key} error={error} />
              )}
            </div>
          )
        })}
        {(intervals.length < limit &&
          <Button isSecondary isBlock className='ta-intervals-input__btn-add' onClick={this.addInterval}>
            <FontAwesome5 icon='plus' type='regular' /> {t('global.addInterval')}
          </Button>
        )}
      </div>
    )
  }
}

WeekDayIntervalsInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  isActive: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].isActive) || false,
  intervals: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].values) || [],
  errors: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].errors) || []
})

export default feedContextInProps(connect(maps)(WeekDayIntervalsInput), FormContext)
