import React from 'react'
import {
  Button,
  Title,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  DnDDroppable,
  Form,
  Error,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { ServicesList } from '../../Beauties'
import { truncateText } from '../../Utils'

const ServicesCategory = props => {
  const {
    category,
    pending,
    services = [],
    allServices,
    isDragAndDropMode,
    index,
    areCategoriesExpanded,
    enterpriseId,
    isGlobalHover,
    globalServicesWrite
  } = props
  const classNames = ['ta-services__category']
  const isGlobal = enterpriseId && category.internalId
  if (index === 0) classNames.push('first')
  if (isGlobalHover) classNames.push('global')
  if (pending) classNames.push('pending')
  if (category.isUpdated) classNames.push('updated')
  if (category.isDeleted) classNames.push('deleted')
  if (isGlobal) classNames.push('global')
  const isDefaultCategory = category.isDefault
  const categoryName = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
  const categoryNameLimit = isDefaultCategory ? 28 : 40

  return (
    <div key={category.id} className={classNames.join(' ')}>
      <Title
        size='m'
        label={truncateText(categoryName, categoryNameLimit, true)}
        extraText={isDefaultCategory ? t('global.defaultCategory.hint') : ''}
        icon='folder'
      >
        {(globalServicesWrite &&
          <>
            {(!isDragAndDropMode && !isDefaultCategory && !isGlobal &&
              <PopupTriggerWrapper
                name='service-category-delete'
                position={index === 0 ? 'bottom' : 'top'}
                id={category.id}
              >
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('global.category.delete')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' isSecondary icon='trash-alt' external />
                  </HoverPopupTrigger>
                </HoverPopup>
              </PopupTriggerWrapper>
            )}
            {(isDragAndDropMode && !areCategoriesExpanded && !isDefaultCategory &&
              <Button as='link' className='ta-btn ta-grab' isSecondary icon='arrows-v' />
            )}
            {(!isDragAndDropMode &&
              <>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>{t('buttons.editCategory.tooltip')}</HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' to={`/management/services/categories/${category.id}/edit`} isSecondary icon='pencil' />
                  </HoverPopupTrigger>
                </HoverPopup>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('servicesGroups.list.buttonAddService.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' to={`/management/services/add?c=${category.id}`} isPrimary icon='plus' />
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </>
        )}
      </Title>
      <DnDDroppable id={category.id} type='service'>
        <ServicesList
          services={services}
          allServices={allServices}
          isDragAndDropMode={isDragAndDropMode}
          areCategoriesExpanded={areCategoriesExpanded}
          enterpriseId={enterpriseId}
          categoryIndex={category.orderIndex}
        />
        <Form name={`serviceCategory${category.id}`}>
          <Error name='globalErrors' />
        </Form>
      </DnDDroppable>
    </div>
  )
}

export default ServicesCategory
