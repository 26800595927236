import { selectors, handlers, store } from '../..'
import { systemMessagesTransform } from './utils'

export const systemMessagesUpdated = systemMessage => {
  if (!systemMessage) return
  const state = store.getState()
  const { notifications } = state
  const resources = selectors.resourcesListSelector(state)
  const { systemMessages, systemMessagesCount } = notifications || {}
  let newSystemMessages = []

  if (systemMessages.find(item => item.id === systemMessage.id)) {
    newSystemMessages = systemMessages.map(item => {
      return item.id === systemMessage.id ? systemMessagesTransform(systemMessage, resources) : item
    })
  } else {
    newSystemMessages = [systemMessagesTransform(systemMessage, resources), ...systemMessages]
    handlers.systemMessagesListCounterPopulate(systemMessagesCount + 1)
  }
  handlers.systemMessagesListPopulate(newSystemMessages, false, true)
  handlers.systemMessagesListReady()
}
