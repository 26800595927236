import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { ResourceCategoryForm } from '../../Beauties'

class ResourceCategoryAddWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.resourceCategoryFormGet()
  }

  onSubmit (cb) {
    let { form } = this.props
    form = form || {}
    handlers.resourceCategoryFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    let {
      pending,
      showExternalIds,
      isAdmin,
      branchesAccessBlacklist,
      form
    } = this.props
    form = form || {}
    pending = !!pending
    showExternalIds = !!showExternalIds

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <ResourceCategoryForm
            title={t('resources.add.category.heading')}
            type='add'
            cancelLink='/management/resource-categories'
            onSubmit={this.onSubmit}
            showExternalIds={showExternalIds}
            isAdmin={isAdmin}
            branchesAccessBlacklist={branchesAccessBlacklist}
            location={form.location && form.location.values}
          />
        )}
      </>
    )
  }
}

ResourceCategoryAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  pending: state.resources.pendingCategoryForm,
  showExternalIds: (state.company.settings && state.company.settings.showExternalIds),
  form: state.forms.resourceCategory,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default connect(maps)(ResourceCategoryAddWrapper)
