import React from 'react'
import { t } from '../../../Common'

import CustomerBookingsPrintHeader from './CustomerBookingsPrintHeader'
import CustomerBookingsPrintList from './CustomerBookingsPrintList'

const CustomerBookingsPrintTemplate = props => {
  const { customer = {}, items = [], timezone = {} } = props

  return (
    <div className='ta-customer__bookings-print'>
      <div className='ta-customer__bookings-print__list'>
        <CustomerBookingsPrintHeader
          title={`<strong>${t('customers.tabBookings.print.title')}</strong>`}
          text={customer && customer.fullName && `<strong>${customer.fullName}</strong>`}
          extraText={timezone.label && `(${timezone.label})`}
        />
        <CustomerBookingsPrintList events={items} timezone={timezone} />
      </div>
    </div>
  )
}

export default CustomerBookingsPrintTemplate
