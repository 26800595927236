import React from 'react'
import { GOOGLE_MAPS_API_KEY } from '../../Settings'
import { Image } from '../../Common'
import './GoogleStaticMap.css'

const GoogleStaticMap = props => {
  const {
    height = 100,
    width = 100,
    size = '100x100',
    zoom = 15,
    address = '',
    markerColor = 'red',
    markerSize = 'medium'
  } = props
  const uri = `https://maps.googleapis.com/maps/api/staticmap?size=${size}&markers=size:${markerSize}%7Ccolor:${markerColor}%7C${address}&key=${GOOGLE_MAPS_API_KEY}&zoom=${zoom}&scale=2`

  return (
    <Image
      className='ta-google-static-map__img'
      src={uri}
      width={width}
      height={height}
      alt='Map'
    />
  )
}

export default GoogleStaticMap
