import React from 'react'

const TableRowMainCol = props => {
  const { children, verticalAlign, textAlign } = props
  const classNames = ['ta-table-row__main-col']
  if (verticalAlign === 'middle') classNames.push('middle')
  if (textAlign === 'center') classNames.push('text-center')
  if (textAlign === 'right') classNames.push('text-right')

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default TableRowMainCol
