import React from 'react'
import PropTypes from 'prop-types'
import { AccountsItem } from '../../Beauties'

const AccountsList = props => {
  const { accounts = [], onAccountChange, onResendEmailClick } = props

  return (
    <div className='ta-accounts__list'>
      {accounts.map(account => (
        <AccountsItem
          key={account.id}
          account={account}
          onAccountChange={onAccountChange}
          onResendEmailClick={onResendEmailClick}
        />
      ))}
    </div>
  )
}

AccountsList.propTypes = {
  accounts: PropTypes.array.isRequired
}

export default AccountsList
