import React from 'react'
import PropTypes from 'prop-types'
import {
  SimpleTabsHeaderItem,
  SimpleTabsBodyItem,
  FixedContentHeader,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FixedContent,
  SimpleTabs,
  HoverPopup,
  Alert,
  Title,
  Button,
  Blur,
  t
} from '../../../Common'
import {
  CoursePreviewChangedLocallyWrapper,
  CoursePreviewAdvancedWrapper,
  CoursePreviewDetailsWrapper,
  PopupTriggerWrapper
} from '../../../Beasts'

import './CoursePreview.css'

const CoursePreview = props => {
  let {
    course,
    hash,
    message,
    globalGroupsRead,
    globalGroupsWrite,
    globalGroupsDelete,
    companyCustomerEmailRemindersMinutes,
    companyResourceEmailRemindersMinutes
  } = props
  course = course || {}
  let {
    id,
    name,
    locallyUpdatedBranches,
    customerEmailRemindersMinutes,
    resourceEmailRemindersMinutes,
    isGloballyDeleted
  } = course || {}
  locallyUpdatedBranches = locallyUpdatedBranches || {}
  const hasCustomCustomerReminders = !!customerEmailRemindersMinutes
  const hasCustomResourceReminders = !!resourceEmailRemindersMinutes
  const availableTabs = ['summary', 'changedLocally']
  if (hasCustomCustomerReminders || hasCustomResourceReminders) availableTabs.push('advanced')
  console.warn({ hasCustomCustomerReminders, hasCustomResourceReminders, resourceEmailRemindersMinutes, companyCustomerEmailRemindersMinutes, customerEmailRemindersMinutes, companyResourceEmailRemindersMinutes })
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-courses__preview']
  const edited = !!Object.keys(locallyUpdatedBranches || {}).length
  if (edited) classNames.push('global edited')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const title = globalGroupsRead ? name || t('global.details') : t('global.accessDenied')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title}>
            {(globalGroupsWrite && id &&
              <>
                {(globalGroupsDelete &&
                  <PopupTriggerWrapper size='m' name='courses-delete' position='bottom' id={id}>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('servicesGroups.groups.buttonHeading.deleteTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button
                          isTertiary
                          as='link'
                          className='ta-btn ta-btn-delete'
                          external
                          icon='trash-alt'
                        />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
                {(edited && (
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('globalEntities.service.buttonHeading.restoreTooltip', [
                        { key: 'ELEMENT', value: t('global.groups') }
                      ])}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button
                        as='link'
                        to={`/management/courses/${id}/branches/restore`}
                        className='ta-btn ta-btn-delete'
                        icon='cloud-upload'
                        iconType='s'
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                ))}
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('servicesGroups.groups.buttonHeading.editTooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button
                      as='link'
                      to={`/management/courses/${id}/edit@@${hash}`}
                      isPrimary
                      icon='pencil'
                    />
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalGroupsRead ? 'inactive' : ''} active={!globalGroupsRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && !isGloballyDeleted &&
              <Alert
                className='ta-courses__alert-height-fix'
                theme='alert'
                label={
                  t('globalEntities.locallyEdittedWarning.title', [
                    { key: 'ELEMENT', value: t('global.groups') }
                  ])
                }
              >
                {t('globalEntities.preview.locallyEdittedWarning.message', [
                  { key: 'ELEMENT', value: t('global.groups') }
                ])}
              </Alert>
            )}
            {(isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                {t('globalEntities.preview.removedFromDatabase')}
                <PopupTriggerWrapper name='courses-delete-global-entity' position='right' id={id}>
                  <Button as='link' className='ta-btn ta-btn-delete-global' isBlock isPrimary external>
                    {t('globalEntities.delete')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(course.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='summary' icon='list-ul' label={t('global.summary')} />
                  {((hasCustomCustomerReminders || hasCustomResourceReminders) &&
                    <SimpleTabsHeaderItem
                      name='advanced'
                      icon='cogs'
                      label={t('global.advanced')}
                      tooltipPosition='bottom'
                    />
                  )}
                  {(edited &&
                    <SimpleTabsHeaderItem
                      name='changedLocally'
                      icon='pencil'
                      label={t('global.changedLocally')}
                    />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalGroupsRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='summary'>
                    <CoursePreviewDetailsWrapper course={course} />
                  </SimpleTabsBodyItem>
                  {((hasCustomCustomerReminders || hasCustomResourceReminders) &&
                    <SimpleTabsBodyItem name='advanced'>
                      <CoursePreviewAdvancedWrapper course={course} />
                    </SimpleTabsBodyItem>
                  )}
                  <SimpleTabsBodyItem name='changedLocally'>
                    <CoursePreviewChangedLocallyWrapper course={course} />
                  </SimpleTabsBodyItem>
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CoursePreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default CoursePreview
