import { handlers, store } from '../../../Store'
import { jsonDeepEqual } from '../../../Utils'

export const accountUpdated = updatedAccount => {
  if (!updatedAccount || !updatedAccount.userId) return
  const state = store.getState()
  const { account } = state || {}
  const { enterprisePermissions } = account || {}
  const { enterprisePermissions: updatedEnterprisePermissions } = updatedAccount

  const isEqualPermissions = jsonDeepEqual(enterprisePermissions, updatedEnterprisePermissions)

  if (!isEqualPermissions || (updatedAccount.isAdmin !== account.isAdmin)) {
    handlers.accountPermissionsChanged()
  }

  handlers.accountPopulate(updatedAccount)
}

export const accountDeleted = async payload => {
  const { userId, accountId, authScope } = payload
  if (!userId) return
  let { account } = store.getState()
  account = account || {}
  if (account.id === accountId && account.authScope === authScope) handlers.companyReset()
  handlers.accountUserDeleted({ userId, authScope })
  let { accounts } = store.getState().account
  accounts = accounts || []
  // No accounts
  if (accounts.length === 0) handlers.logout()
}
