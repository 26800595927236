import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  Button,
  IconListItem,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  SimpleListItem,
  SimpleListItemContent,
  SimpleListItemTitle,
  Spinner,
  Price,
  format,
  t,
  Alert,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Row,
  Col
} from '../../Common'
import { BillingPreviewMethod } from '../../Beauties'

import './BillingPreview.css'

const BillingPreview = props => {
  let {
    name,
    address1,
    address2,
    city,
    zipCode,
    vatNo,
    paymentMethods,
    managePaymentMethods,
    isPendingMethod,
    subscriptions,
    isOwner,
    isBillingWrite,
    hash
  } = props
  subscriptions = subscriptions || []
  const hasBillingDetails = name && address1 && zipCode && city
  const availableTabs = ['billing', 'payments', 'contracts']
  const activeTab = availableTabs.includes(hash)
    ? hash
    : availableTabs[0]

  return (
    <div className='ta-billing__preview'>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='briefcase' iconTheme='grey' label={t('global.billing')}>
            {(isBillingWrite &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('billing.edit.billingDetails.heading')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/account/billing/edit' icon='pen' isPrimary />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <SimpleTabs active={activeTab}>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem name='billing' label={t('billing.list.section.billingDetails.heading')} />
              <SimpleTabsHeaderItem name='payments' icon='credit-card' label={t('billing.list.section.paymentDetails.heading')} />
              <SimpleTabsHeaderItem name='contracts' icon='list' label={t('billing.list.section.contracts.heading')} />
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <SimpleTabsBodyItem name='billing'>
                {(hasBillingDetails &&
                  <>
                    <IconListItem>
                      <strong>{t('global.name')}:</strong> {name}
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('address.label')} 1:</strong> {address1}
                    </IconListItem>
                    {(address2 &&
                      <IconListItem>
                        <strong>{t('address.label')} 2:</strong> {address2}
                      </IconListItem>
                    )}
                    <IconListItem>
                      <strong>{t('address.city')}:</strong> {city}
                    </IconListItem>
                    <IconListItem>
                      <strong>{t('address.zip')}:</strong> {zipCode}
                    </IconListItem>
                    {(vatNo &&
                      <IconListItem>
                        <strong>{t('global.vatNumber')}:</strong> {vatNo}
                      </IconListItem>
                    )}
                  </>
                )}
                {(!hasBillingDetails &&
                  <Alert noOffset label={t('billing.list.section.billingDetails.noResultsBox.text')} theme='no-content'>
                    <Button as='link' to={isBillingWrite ? '/account/billing/edit' : null} isPrimary>{t('billing.list.section.billingDetails.noResultsBox.buttonLabel')}</Button>
                  </Alert>
                )}
              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='payments'>
                {(!isPendingMethod &&
                  <>
                    {(paymentMethods.length === 0 &&
                      <Row>
                        <Col>
                          <Alert noOffset label={t('global.noResults')} theme='no-content' />
                        </Col>
                      </Row>
                    )}
                    {(paymentMethods.length > 0 &&
                      paymentMethods.map(item => (
                        <BillingPreviewMethod item={item} key={item.id} />
                      ))
                    )}
                    {(isOwner &&
                      <Button as='link' isPrimary isBlock external onClick={managePaymentMethods}>
                        {t('billing.list.section.paymentDetails.buttonManage.label')}
                      </Button>
                    )}
                  </>
                )}
                {(isPendingMethod &&
                  <div className='ta-billing__preview__payment-methods__loader'>
                    <Spinner />
                  </div>
                )}
              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='contracts'>
                {subscriptions.length > 0 && subscriptions.map(item => (
                  <SimpleListItem key={item.id} className='contract'>
                    <SimpleListItemTitle title={`${item.id} | ${item.description}`} />
                    <SimpleListItemContent>
                      <ul className='ta-list-inline'>
                        <li><FontAwesome5 icon='repeat-alt' type='solid' />{item.frequency}</li>
                        <li>
                          <FontAwesome5 icon='money-bill' type='solid' /><Price price={item.amount} currency={item.currencyCode} />
                          {(item.currencyCode !== 'EUR' &&
                            <>
                              (<Price price={item.amountEUR} currency='EUR' />)
                            </>
                          )}
                        </li>
                        <li><FontAwesome5 icon='clock' type='solid' />{format(item.startedAt, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}</li>
                        <li><FontAwesome5 icon='arrow-right' type='solid' />{format(item.nextInvoice, 'long', { isUTC: true, format: 'YYYY-MM-DD' })}</li>
                      </ul>
                    </SimpleListItemContent>
                  </SimpleListItem>
                ))}
                {subscriptions.length === 0 && (
                  <Alert noOffset label={t('global.noResults')} theme='no-content' />
                )}
              </SimpleTabsBodyItem>
            </SimpleTabsBody>
          </SimpleTabs>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

BillingPreview.propTypes = {
  message: PropTypes.object
}

export default BillingPreview
