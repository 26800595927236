import React from 'react'
import PropTypes from 'prop-types'
import { Button, t, Title } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupUserPermissionDelete = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__resources-delete'>
      <Title size='m' isCompact label={dangerousHTML(t('userPermissionTypes.delete.text'))} />
      <div className='ta-popup__buttons'>
        <Button as='link' isSecondary onClick={reject}>{t('buttons.cancel.label')}</Button>
        <Button as='link' isPrimary onClick={approve}>{t('global.delete')}</Button>
      </div>
    </div>
  )
}

PopupUserPermissionDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupUserPermissionDelete
