import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { AVAILABLE_SERVICE_HEXCOLORS } from '../../../Settings'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  Error,
  IconSelect
} from '../../../Common'

import './ColorIconSelect.css'

class ColorIconSelect extends Component {
  constructor(props, context) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.state = { open: false }
  }

  componentDidMount() {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.addEventListener('mouseup', this.handleOutsideClick)
    }
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount() {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.removeEventListener('mouseup', this.handleOutsideClick)
    }
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOutsideClick(e) {
    if (this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  toggle() {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  onChange(color) {
    let { name, form } = this.props
    form = form || { value: '' }
    this.setState({ open: false })
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value: color } })
  }

  render() {
    const { open } = this.state

    let {
      name,
      pattern = '',
      colors = AVAILABLE_SERVICE_HEXCOLORS,
      value,
      children,
      form,
      hideError,
      formIcon,
      iconFieldName
    } = this.props
    form = form || { value: '' }
    formIcon = formIcon || { value: '' }
    const optionsClasses = ['ta-color-icon-select__options']
    const selectedOptionColorClasses = ['ta-color-icon-select__options__color']
    const selectedClassNames = ['ta-color-icon-select__selected']

    if (open) {
      optionsClasses.push('active')
      selectedClassNames.push('active')
    }
    if (pattern !== '') {
      optionsClasses.push(pattern)
      selectedOptionColorClasses.push(pattern)
    }

    return (
      <div ref={wrapper => { this.wrapper = wrapper?.getElementsByClassName('ta-color-icon-select__options')?.[0] }}>
        <div className='ta-color-icon-select'>
          <IconSelect name={iconFieldName} />
          {children}
          <div>
            <div className={selectedClassNames.join(' ')} onClick={this.toggle}>
              <div className={selectedOptionColorClasses.join(' ')} style={{ backgroundColor: value || form.value }} />
              <FontAwesome5 icon='sort' type='solid' />
            </div>
            <div className={optionsClasses.join(' ')}>
              {colors.map(color => (
                <div
                  key={color}
                  className={selectedOptionColorClasses.join(' ')}
                  style={{ backgroundColor: color }}
                  onClick={() => this.onChange(color)}
                >
                  <FontAwesome5 icon={formIcon.value} type='solid' />
                </div>
              ))}
            </div>
          </div>
        </div>
        {!hideError &&
          <Error noOffset name={name} />
        }
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' },
  formIcon: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.iconFieldName]) || { value: '' }
})

export default feedContextInProps(connect(maps)(ColorIconSelect), FormContext)
