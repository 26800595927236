import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../../Store'
import { PopupEmailChangeCancel } from '../../../Beauties'

class PopupEmailChangeCancelWrapper extends PureComponent {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidMount () {
    handlers.accountFormGet()
  }

  onSubmit (cb) {
    const form = store.getState().forms.account
    handlers.accountFormEmailChangeCancelSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  onCancel () {
    const { closePopup } = this.props
    closePopup && closePopup()
  }

  toggleOldPasswordFieldType () {
    const form = store.getState().forms.account
    const oldPassword = {
      value: form.oldPassword.value,
      type: form.oldPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { oldPassword })

    return oldPassword.type
  }

  render () {
    const { pending, oldPasswordFieldType } = this.props

    return (
      <PopupEmailChangeCancel
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        pending={pending}
        oldPasswordFieldType={oldPasswordFieldType}
        toggleOldPasswordFieldType={this.toggleOldPasswordFieldType}
      />
    )
  }
}

PopupEmailChangeCancelWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

const maps = state => ({
  pending: state.account.pendingForm || false,
  oldPasswordFieldType: (state.forms.account && state.forms.account.oldPassword && state.forms.account.oldPassword.type) || 'password'
})

export default connect(maps)(PopupEmailChangeCancelWrapper)
