import React from 'react'
import { connect } from '../../Store'
import { Content } from '../../Beauties'

const ContentWrapper = props => {
  const { expand } = props

  return (
    <Content expand={expand} />
  )
}

const maps = state => ({
  expand: state.navigation.isCollapsed || false
})

export default connect(maps)(ContentWrapper)
