import React from 'react'
import { StatisticsCompositeChildHeader } from '../../Beauties'
import { FontAwesome5, Price } from '../../Common'

const StatisticsCompositeEquation = props => {
  const { equation, text, isFinancial, currency } = props
  const {
    addend1,
    addend2,
    sum,
    label1Text,
    label1TextColor,
    label1Color,
    label1Icon,
    label2Text,
    label2TextColor,
    label2Color,
    label2Icon
  } = equation || {}
  const classNames = ['ta-statistics-composite-child', 'equation']
  const addend1Value = (isFinancial && currency)
    ? <><Price price={addend1} currency={currency} digits={addend1 === null ? 0 : 2} />{addend1 === null && ',-'}</>
    : addend1 === null
      ? '-'
      : addend1
  const addend2Value = (isFinancial && currency)
    ? <><Price price={addend2} currency={currency} digits={addend2 === null ? 0 : 2} />{addend2 === null && ',-'}</>
    : addend2 === null
      ? '-'
      : addend2
  const sumValue = (isFinancial && currency)
    ? <><Price price={sum} currency={currency} digits={sum === null ? 0 : 2} />{sum === null && ',-'}</>
    : sum === null
      ? '-'
      : sum

  return (
    <div className={classNames.join(' ')}>
      <StatisticsCompositeChildHeader text={text} />
      <div className='ta-statistics-composite-child__body equation'>
        <div className='ta-statistics-composite-child__body__equation-item'>
          <div className='ta-statistics-composite-child__body__equation-item__data'>
            <p className='ta-statistics-composite-child__body__equation-item__data-text' style={{ color: label1TextColor }}>{addend1Value}</p>
            <div className='ta-statistics-composite-child__body__equation-item__data-label' style={{ background: label1Color }}>
              <FontAwesome5 icon={label1Icon} type='s' color='#fff' /> {label1Text}
            </div>
          </div>
        </div>
        <div className='ta-statistics-composite-child__body__equation-sign plus'>
          <p>+</p>
          <div className='ta-borderline dashed' />
        </div>
        <div className='ta-statistics-composite-child__body__equation-item'>
          <div className='ta-statistics-composite-child__body__equation-item__data'>
            <div className='ta-statistics-composite-child__body__equation-item__data'>
              <p className='ta-statistics-composite-child__body__equation-item__data-text' style={{ color: label2TextColor }}>{addend2Value}</p>
              <div className='ta-statistics-composite-child__body__equation-item__data-label' style={{ background: label2Color }}>
                <FontAwesome5 icon={label2Icon} type='s' color='#fff' /> {label2Text}
              </div>
            </div>
          </div>
        </div>
        <div className='ta-statistics-composite-child__body__equation-sign equals'>
          <div className='ta-borderline' />
        </div>
        <div className='ta-statistics-composite-child__body__equation-item'>
          <div className='ta-statistics-composite-child__body__equation-item__data'>
            <div className='ta-statistics-composite-child__body__equation-item__data'>
              <p className='ta-statistics-composite-child__body__equation-item__data-text'>{sumValue}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsCompositeEquation
