import React from 'react'
import { renderToString } from 'react-dom/server'

import {
  AppsSkeleton,
  AppsSubscriptionInvoiceRow
} from '../../../Beauties'
import {
  Alert,
  Title,
  Price,
  Accordion,
  AccordionTitle,
  AccordionBody,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t,
  Button
} from '../../../Common'
import {
  PopupTriggerWrapper,
  AppsSubscriptionFormWrapper,
  AppsSubscriptionPlansFormWrapper
} from '../../../Beasts'
import { capitalize } from '../../../Utils'

import './AppsSubscription.css'

const AppsSubscription = (props) => {
  let {
    name,
    version,
    plan,
    pricingType,
    unitDescription,
    isVariablePrice,
    usageUnitPrice,
    priceRange,
    invoiceDate,
    invoiceDay,
    thresholdChanged,
    limitValue,
    oldThreshold,
    newThresholdDate,
    message,
    onContinue,
    plans,
    activePlan,
    planChanged,
    oldPlan,
    oldPlanText,
    previousPlan,
    isOwner,
    isBlocked,
    extraConsumedCharges,
    appDaysToBlock,
    noAutoCollect,
    currencyCode
  } = props
  plans = plans || []
  activePlan = activePlan || 'basic'
  let [minPrice, maxPrice] = priceRange || []
  minPrice = minPrice || ''
  maxPrice = maxPrice || ''
  let invoiceRowLabel = ''
  if (pricingType === 'payPerUsage' && isVariablePrice) invoiceRowLabel = t('apps.subscriptions.variablePrice')
  if (pricingType === 'payPerUsage' && !isVariablePrice) invoiceRowLabel = t('apps.subscriptions.entityPrice', [{ key: 'NAME', value: unitDescription }])
  if (pricingType === 'monthly' && plans.length === 1) invoiceRowLabel = t('apps.subscriptions.monthlyMembership')
  if (pricingType === 'monthly' && plans.length > 1) {
    invoiceRowLabel = t('apps.subscriptions.monthlyMembership.withPlan', [
      { key: 'APP', value: name },
      { key: 'PLAN', value: capitalize(activePlan) }
    ])
  }
  let invoicePrice
  if (pricingType === 'monthly') {
    invoicePrice = (
      <Price currency={currencyCode} price={(plans.find(item => item.name === activePlan) || {}).price} />
    )
  }
  if (pricingType === 'payPerUsage' && !isVariablePrice) {
    invoicePrice = (
      <span>
        <Price currency={currencyCode} price={usageUnitPrice} /> / {unitDescription}
      </span>
    )
  }
  if (pricingType === 'payPerUsage' && isVariablePrice) {
    invoicePrice = (
      <span>
        <Price currency={currencyCode} price={minPrice} /> - <Price currency={currencyCode} price={maxPrice} /> / {unitDescription}
      </span>
    )
  }
  const oldPlanPrice = (
    <Price currency={currencyCode} price={(plans.find(item => item.name === oldPlan) || {}).price} />
  )
  let accordionTitle = ''
  if (pricingType === 'monthly') accordionTitle = t('buttons.changeSubscriptionPlan')
  if (pricingType === 'payPerUsage' && limitValue) accordionTitle = t('buttons.changeThreshold')
  if (pricingType === 'payPerUsage' && !limitValue) accordionTitle = t('buttons.activateThreshold')
  let continueToAppTooltipMessage = 'apps.subscriptionFailed.timeFinished.admin.text'
  if (isOwner) continueToAppTooltipMessage = 'apps.subscriptionFailed.timeFinished.owner.text'
  if (noAutoCollect) continueToAppTooltipMessage = 'apps.subscriptionFailed.timeFinished.offline.text'
  const footer = (
    <HoverPopup disabled={!isBlocked} block>
      <HoverPopupContent>
        {t(continueToAppTooltipMessage)}
      </HoverPopupContent>
      <HoverPopupTrigger>
        <Button disabled={isBlocked} isBlock isPrimary onClick={onContinue}>
          {t('buttons.continueToApp')}
        </Button>
      </HoverPopupTrigger>
    </HoverPopup>
  )
  const invoiceText = t('apps.subscriptions.payPerUsage.invoice.text', [
    { key: 'DATE', value: invoiceDate },
    { key: 'DAY', value: invoiceDay }
  ])

  return (
    <AppsSkeleton
      name={name}
      version={version}
      plan={plan}
      className='ta-apps__subscription'
      headerText={t('apps.subscriptions.header')}
      headerImage='/images/paidApps/banner-subscription.png'
      footer={footer}
    >
      {message && (
        <Alert
          theme='success'
          label={message}
          hasCloseButton
        />
      )}
      <Alert theme='info'>
        {t('global.vatApplicability')}
      </Alert>
      <Title theme='striped' label={t('apps.subscriptions.subscriptionType.title')} />
      {pricingType === 'payPerUsage' && t('apps.preview.section.pricing.payPerUsage')}
      {pricingType === 'monthly' && t('apps.preview.section.pricing.monthlySubscription')}
      {pricingType === 'monthly' && plans.length > 1 && (
        <>
          <Title theme='striped' topOffset label={t('apps.subscriptions.appPlan')} />
          {capitalize(activePlan)}
        </>
      )}
      {!planChanged && (
        <>
          <Title theme='striped' topOffset label={t('global.overview')} />
          <AppsSubscriptionInvoiceRow
            label={invoiceRowLabel}
            description={invoiceText}
            price={invoicePrice}
          />
        </>
      )}
      {(noAutoCollect &&
        <Alert theme='info'>
          {t('apps.subscriptions.offline.info', [{ key: 'DAYS', value: appDaysToBlock }])}
        </Alert>
      )}
      {pricingType === 'monthly' && planChanged && (
        <>
          <Title theme='striped' topOffset label={t('apps.subscriptions.oldSubscriptionPlan')} />
          <AppsSubscriptionInvoiceRow
            description={oldPlanText}
            price={oldPlanPrice}
            label={t('apps.subscriptions.monthlyMembership.withPlan', [
              { key: 'APP', value: name },
              { key: 'PLAN', value: capitalize(oldPlan) }
            ])}
          />
          <Title theme='striped' topOffset label={t('apps.subscriptions.newSubscriptionPlan')} />
          <AppsSubscriptionInvoiceRow
            label={invoiceRowLabel}
            description={invoiceText}
            price={invoicePrice}
          />
        </>
      )}
      {pricingType === 'payPerUsage' && !thresholdChanged && !limitValue && (
        <>
          <Title theme='striped' topOffset label={t('apps.subscriptions.monthlyThreshold.label')} />
          <AppsSubscriptionInvoiceRow
            description={t('apps.subscriptions.noMonthlyThreshold.text')}
          />
        </>
      )}
      {pricingType === 'payPerUsage' && !thresholdChanged && !!limitValue && (
        <>
          <Title theme='striped' topOffset label={t('apps.subscriptions.monthlyThreshold.label')} />
          <AppsSubscriptionInvoiceRow
            description={t('apps.subscriptions.monthlyThreshold.text', [{ key: 'PRICE', value: renderToString(<Price currency={currencyCode} price={limitValue} />) }])}
          />
        </>
      )}
      {pricingType === 'payPerUsage' && !!thresholdChanged && (
        <>
          <Title theme='striped' topOffset label={t('apps.subscriptions.oldMonthlyThreshold.label')} />
          <AppsSubscriptionInvoiceRow
            description={t('apps.subscriptions.monthlyThreshold.text', [{ key: 'PRICE', value: renderToString(<Price currency={currencyCode} price={oldThreshold} />) }])}
          />
          <Title theme='striped' topOffset label={t('apps.subscriptions.newMonthlyThreshold.label')} />
          <AppsSubscriptionInvoiceRow
            description={limitValue < oldThreshold || limitValue < extraConsumedCharges
              ? t('apps.subscriptions.newMonthlyThreshold.text', [
                { key: 'PRICE', value: renderToString(<Price currency={currencyCode} price={limitValue} />) },
                { key: 'DATE', value: newThresholdDate }
              ])
              : t('apps.subscriptions.newMonthlyThreshold.immediately.text', [
                { key: 'PRICE', value: renderToString(<Price currency={currencyCode} price={limitValue} />) },
                { key: 'DATE', value: newThresholdDate }
              ])}
          />
        </>
      )}
      {isOwner && !isBlocked && (pricingType === 'payPerUsage' || plans.length > 1) && (
        <Accordion className='ta-apps__subscription__accordion' expand={false}>
          <AccordionTitle>
            <Button
              isBlock
              isSecondary
            >
              {accordionTitle}
            </Button>
          </AccordionTitle>
          <AccordionBody>
            {pricingType === 'payPerUsage' && (
              <AppsSubscriptionFormWrapper type='edit' />
            )}
            {pricingType === 'monthly' && (
              <AppsSubscriptionPlansFormWrapper previousPlan={previousPlan} />
            )}
          </AccordionBody>
        </Accordion>
      )}
      {isOwner && (
        <PopupTriggerWrapper name='apps-subscription-cancel' position='top'>
          <Button isTertiary isGhost>
            {t('apps.subscription.cancel')}
          </Button>
        </PopupTriggerWrapper>
      )}
    </AppsSkeleton>
  )
}

export default AppsSubscription
