import { payloads$, actions, handlers, store, globalActions } from '../..'
import { q } from '../../API'
import { serverErrorsTransform } from '../../../Utils'
import {
  companyTagTransform,
  companyTagFormValidate,
  companyTagsPreviewErrorsTransform,
  companyTagSaveTransform,
  companyTagFormServerErrorsTransform
} from './utils'
import {
  companyTagsMoved,
  companyTagUpdated,
  companyTagDeleted
} from './subscriptions'

globalActions.populateCompanyTags = async () => {
  await globalActions.populateBranches()
  const result = await q('getEnterpriseCompanyTags')
  const { error } = result || {}
  if (error) return error
  handlers.companyTagsListPopulate({ tags: result || [] })
  return result
}

globalActions.transformCustomerTag = tag => companyTagTransform(tag)

// Subscription
payloads$(actions.COMPANY_TAGS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    const updateSubscriptions = ['GLOBAL: companyTagEnterpriseUpdated', 'companyTagEnterpriseUpdated', 'companyTagEnterpriseGlobalUpdated']
    const deleteSubscriptions = ['companyTagEnterpriseDeleted', 'companyTagEnterpriseGlobalDeleted']

    if (name === 'companyTagsMoved' && data) companyTagsMoved(data)
    if (updateSubscriptions.includes(name) && id) {
      const tag = (await q('getEnterpriseCompanyTag', { id })) || {}
      if (tag.error) return
      companyTagUpdated(tag)
    }
    if (deleteSubscriptions.includes(name) && id) companyTagDeleted(id)
  })

// Preview
payloads$(actions.COMPANY_TAG_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/customers/company-tags')
      return
    }
    handlers.companyTagPreviewPopulate(id)
  })

// Form
payloads$(actions.COMPANY_TAG_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    const { list = [] } = state.companyTags || {}
    let { list: branchesList, areFetched } = state.branches || {}
    branchesList = branchesList || []
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    if (!id) {
      return handlers.companyTagFormPopulate({
        tag: {},
        branches: branchesList,
        account: state.account
      })
    }
    const selectedTag = list.find(tag => tag.id === id) || {}
    if (id && !selectedTag.id) return handlers.navigateToPath(`/customers/company-tags/${id}`)
    handlers.companyTagFormPopulate({
      tag: { ...selectedTag },
      branches: branchesList,
      account: state.account
    })
  })

// Save
payloads$(actions.COMPANY_TAG_FORM_SAVE)
  .subscribe(async ({ tag, scrollToError }) => {
    const state = store.getState()
    let { branches, companyTags } = state
    branches = branches || {}
    companyTags = companyTags || {}
    let { list: branchesList } = branches || {}
    let { list: companyTagsList } = companyTags || {}
    branchesList = branchesList || []
    companyTagsList = companyTagsList || []
    const companyTagExternalIds = companyTagsList.map(tag => tag.externalId).filter(Boolean) || []
    const errors = companyTagFormValidate(tag, companyTagExternalIds)
    if (errors.length) return setCustomerTagFormSaveErrors(errors, scrollToError)
    const savedTag = await q('saveEnterpriseCompanyTag', companyTagSaveTransform(tag))
    const { error, id } = savedTag
    if (error) {
      return setCustomerTagFormSaveErrors(companyTagFormServerErrorsTransform({
        error,
        branches: branchesList
      }), scrollToError)
    }
    if (error) return setCustomerTagFormSaveErrors(serverErrorsTransform(error), scrollToError)
    handlers.companyTagUpdate({ ...savedTag })
    handlers.navigateToPath(`/customers/company-tags/${id}`)
  })

const setCustomerTagFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('companyTags', errors)
  scrollToError && scrollToError(errors)
  handlers.companyTagFormReady()
}

// Update
payloads$(actions.COMPANY_TAG_UPDATE)
  .subscribe(async tag => {
    if (!tag) return handlers.companyTagPreviewPopulate()
    setTimeout(() => handlers.companyTagUpdated(tag), 2000)
  })

// Delete
payloads$(actions.COMPANY_TAG_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.companyTagPreviewPopulate()
    const { error } = await q('deleteEnterpriseCompanyTag', { id })
    if (error) {
      handlers.companyTagPreviewMessageChange(companyTagsPreviewErrorsTransform(error))
      handlers.companyTagPreviewPopulate()
      return
    }
    handlers.companyTagDeleted(id)
    setTimeout(() => handlers.companyTagRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/customers/company-tags'), 0)
  })

// List to delete
payloads$(actions.COMPANY_TAGS_TO_DELETE_GET)
  .subscribe(async () => {
    const companyTagsToDelete = await q('getEnterpriseCompanyTags', {
      filter: {
        isGloballyDeleted: true,
        onlyLocals: true
      }
    })
    const { error } = companyTagsToDelete || {}
    if (error) return handlers.navigateToPath('/customers/company-tags')
    handlers.companyTagsToDeletePopulate({ companyTagsToDelete })
  })

payloads$(actions.COMPANY_TAG_TO_DELETE_DELETE)
  .subscribe(async ({ companyId, region, externalId, id }) => {
    if (!id) return
    const companyTag = await q('deleteBranchCompanyTag', { companyId, region, externalId, id })
    const { error } = companyTag || {}
    if (error) return handlers.navigateToPath('/customers/company-tags')
    handlers.companyTagsToDeleteDeleteReady(id)
  })

// Reorder
payloads$(actions.COMPANY_TAGS_LIST_ORDER_CHANGE)
  .subscribe(async ({ id }) => {
    const { orderIndex } = store.getState().tags.list.find(tag => tag.id === id)
    const { error } = await q('moveCompanyTag', { id, orderIndex })
    if (error) console.warn('Drag n drop tag error!')
  })

// Locally changed
payloads$(actions.COMPANY_TAG_LOCALLY_CHANGED_GET)
  .subscribe(async ({ id, internalId }) => {
    if (!internalId) return handlers.navigateToPath('/customers/company-tags')
    const branches = store.getState().branches.list || []
    const companyTags = await q('getEnterpriseCompanyTags', {
      filter: {
        internalId,
        isUpdatedLocally: true,
        onlyLocals: true
      }
    })
    const { error } = companyTags || {}
    if (error || !companyTags) return handlers.navigateToPath('/customers/company-tags')
    const locallyChangedData = companyTags.map(item => ({ id: item.id, branch: branches.find(branch => branch.id === item.companyId) }))
    handlers.companyTagLocallyChangedPopulate({ id, locallyChangedData })
  })

payloads$(actions.COMPANY_TAG_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const companyTag = await q('resetGlobalBranchCompanyTag', { companyId, region, internalId })
    const { error } = companyTag || {}
    if (error || !companyTag) return handlers.navigateToPath('/customers/company-tags')
    handlers.companyTagLocallyChangedResetReady({ companyTag, companyId })
  })
