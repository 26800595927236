import React from 'react'
import { renderToString } from 'react-dom/server'
import { filter, debounceTime, tap } from 'rxjs/operators'
import momenttz from 'moment-timezone'
import { q } from '../../API'
import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { sortBy, print } from '../../../Utils'
import { CustomerBookingsPrintTemplate } from '../../../Beauties'
import {
  customerTransform,
  customerFormValidate,
  customerSaveTransform,
  customersListTransform,
  customersListErrorsTransform,
  customerPreviewErrorsTransform,
  customerPreviewBookingsErrorsTransform,
  customerFormServerErrorsTransform
} from './utils'
import {
  customerUpdated,
  customerDeleted
} from './subscriptions'
import { t } from '../../../Common'

const limit = 200

const getCustomersFilterFromState = state => {
  const { shouldUseMongoCustomerSearch } = state?.company?.settings || {}
  const customersFilters = state.forms.customersFilters ? state.forms.customersFilters : {}
  const { letter = {}, search = {}, tags = {}, useExactMatchSearch } = customersFilters
  const value = search.value || ''
  const filter = {
    name: value,
    nameFirstLetter: letter.value || '',
    tagIds: (tags.value && [tags.value]) || null,
    ...(shouldUseMongoCustomerSearch && { useExactMatchSearch: useExactMatchSearch?.value === 'exact' })
  }
  // TODO find better validation for ID in case this fails
  if (value.length === 24 && value[0] === '5') {
    delete filter.name
    filter.ids = [value]
  }
  return filter
}

const getCustomersSortFromState = state => {
  const customersFilters = state.forms.customersFilters ? state.forms.customersFilters : {}
  const { sort = {} } = customersFilters
  let ORDER = 'ASC'
  if (sort.value === 'name') ORDER = 'ASC'
  if (sort.value === 'createdAt') ORDER = 'DESC'
  if (sort.value === 'totalBookings') ORDER = 'DESC'
  return { [sort.value || 'name']: ORDER }
}

const getCustomersPageFromState = state => {
  return state.customers ? state.customers.page : 1
}

const getQueryName = name => {
  const state = store.getState()
  const { company } = state || {}
  const { customersMiddlewareUrl } = company || {}

  return `${name}${customersMiddlewareUrl ? 'CustomersMiddleware' : ''}`
}

// Global actions

let isLoading = false
globalActions.populateCustomers = async ({ page, isLoadMore }) => {
  if (isLoading && !isLoadMore) return
  isLoading = true
  const state = store.getState()
  const { company } = state || {}
  const { customersMiddlewareUrl } = company || {}
  const filter = getCustomersFilterFromState(state)
  const sort = getCustomersSortFromState(state)
  page = page || getCustomersPageFromState(state)
  const result = await q(getQueryName('getEnterpriseCustomersWithTotal'), { filter, sort, pagination: { limit, page } }, null, null, customersMiddlewareUrl)
  let { error, total, data } = result || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    isLoading = false
    handlers.customersListMessageSet(customersListErrorsTransform(error))
    return
  }
  data = data || []
  const availableCustomerLetters = await q('getEnterpriseAvailableCustomerLetters')
  handlers.customersListOptionsChange({ page, lastPage: !data.length || (data.length < limit) })
  handlers.customersListPopulate(customersListTransform(data || []), availableCustomerLetters, !!isLoadMore, total)
  isLoading = false
  return data
}

globalActions.transformCustomer = customer => {
  return customerTransform(customer)
}

// Subscription
payloads$(actions.CUSTOMERS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'customerEnterpriseUpdated' && id) {
      const state = store.getState()
      const { company } = state || {}
      const { customersMiddlewareUrl } = company || {}
      const customer = await q(getQueryName('getEnterpriseCustomer'), { id }, null, null, customersMiddlewareUrl)
      const { error } = customer || { error: {} }
      if (error) return
      customerUpdated(customer)
    }
    if (name === 'customerDeleted' && id) customerDeleted(id)
  })

// List
payloads$(actions.CUSTOMERS_LIST_GET)
  .subscribe(async page => {
    setTimeout(() => {
      globalActions.populateCustomers({ page })
      globalActions.populateCompanyTags()
    }, 0)
  })

// List Filters
payloads$(actions.FORM_FIELDS_UPDATE)
  .pipe(
    filter(fields => fields.form === 'customersFilters'),
    tap(() => handlers.customersListPending()),
    debounceTime(500)
  ).subscribe(() => {
    handlers.customersListGet()
  })

// Page increment
payloads$(actions.CUSTOMERS_LIST_PAGE_INCREMENT)
  .subscribe(async () => {
    globalActions.populateCustomers({ isLoadMore: true })
  })

// List to delete
payloads$(actions.CUSTOMERS_TO_DELETE_GET)
  .subscribe(async () => {
    const state = store.getState()
    const { company } = state || {}
    const { customersMiddlewareUrl } = company || {}
    const customersToDelete = await q(getQueryName('getEnterpriseCustomers'), {
      filter: {
        isGloballyDeleted: true,
        onlyLocals: true
      }
    }, null, null, customersMiddlewareUrl)
    const { error } = customersToDelete || {}
    if (error) return handlers.navigateToPath('/customers')
    handlers.customersToDeletePopulate({ customersToDelete })
  })

payloads$(actions.CUSTOMER_TO_DELETE_DELETE)
  .subscribe(async ({ companyId, region, externalId, id }) => {
    if (!id) return
    const state = store.getState()
    const { company } = state || {}
    const { customersMiddlewareUrl } = company || {}
    const customer = await q(getQueryName('deleteBranchCustomer'), { companyId, region, externalId, id }, null, null, customersMiddlewareUrl)
    const { error } = customer || {}
    if (error) return handlers.navigateToPath('/customers')
    handlers.customersToDeleteDeleteReady()
  })

// Preview
payloads$(actions.CUSTOMER_PREVIEW_GET)
  .subscribe(async id => {
    if (!id) {
      handlers.navigateToPath('/customers')
      return
    }
    await globalActions.populateCustomerFieldsAndCategories()
    const state = store.getState()
    const { company } = state || {}
    const { customersMiddlewareUrl } = company || {}
    const customer = await q(getQueryName('getEnterpriseCustomer'), { id }, null, null, customersMiddlewareUrl)
    const { error } = customer || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.customersPreviewMessageSet(customerPreviewErrorsTransform(error))
      handlers.customerPreviewPopulate()
      return
    }
    const { internalId } = customer || {}
    handlers.customerLocallyChangedGet({ id, internalId })
    // Save customer in "selected", because the list change depends on sort
    // and on refresh the record can be missing if selected from page > 1
    handlers.customerPreviewPopulate(customerTransform(customer))
  })

payloads$(actions.CUSTOMER_EVENT_FORM_GET)
  .subscribe(async () => {
    const options = {
      cancelled: t('customers.list.cancelled'),
      past: t('customers.list.past'),
      upcoming: t('customers.list.upcoming')
    }
    handlers.customerEventFormPopulate(options)
  })

// Preview Locally changed
payloads$(actions.CUSTOMER_LOCALLY_CHANGED_GET)
  .subscribe(async ({ id, internalId }) => {
    if (!internalId) return handlers.navigateToPath('/customers')
    await globalActions.populateBranches()
    const state = store.getState()
    const { company, branches } = state || {}
    const { customersMiddlewareUrl } = company || {}
    const { list: branchesList = [] } = branches || {}
    const customers = await q(getQueryName('getEnterpriseCustomers'), {
      filter: {
        internalId,
        isUpdatedLocally: true,
        onlyLocals: true
      }
    }, null, null, customersMiddlewareUrl)
    const { error } = customers || {}
    if (error || !customers) return handlers.navigateToPath('/customers')
    const locallyChangedData = customers.map(item => ({ id: item.id, branch: branchesList.find(branch => branch.id === item.companyId) }))
    handlers.customerLocallyChangedPopulate({ id, locallyChangedData })
  })

payloads$(actions.CUSTOMER_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const state = store.getState()
    const { company } = state || {}
    const { customersMiddlewareUrl } = company || {}
    const customer = await q(getQueryName('resetGlobalBranchCustomer'), { companyId, region, internalId }, null, null, customersMiddlewareUrl)
    const { error } = customer || {}
    if (error || !customer) return handlers.navigateToPath('/customers')
    handlers.customerLocallyChangedResetReady({ customer, companyId })
  })

// Preview bookings
payloads$(actions.CUSTOMER_BOOKINGS_GET)
  .subscribe(async id => {
    if (!id) return
    const state = store.getState()
    const { calendar = {}, company = {}, forms = {} } = state
    const { customersMiddlewareUrl } = company || {}
    const type = (forms.customerEvents && forms.customerEvents.type && forms.customerEvents.type.value) || ''
    if (!type) return
    const timezone = calendar.timezone || company.timezone
    let queryName = ''
    if (type === 'UPCOMING') queryName = getQueryName('getCustomerUpcomingEvents')
    if (type === 'PAST') queryName = getQueryName('getCustomerPastEvents')
    if (type === 'DELETED') queryName = getQueryName('getCustomerDeletedEvents')
    const result = await q(queryName, { id, timezone }, null, null, customersMiddlewareUrl)
    const { error } = result || { error: { text: 'errors.api.unavailable' } }
    const events = result[`${type.toLowerCase()}Events`]
    if (error) {
      handlers.customersPreviewBookingsMessageSet(customerPreviewBookingsErrorsTransform(error))
      return
    }
    handlers.customerBookingsPopulate({
      id,
      events: events ? globalActions.transformEvents(events) : []
    })
  })

//  Preview bookings page increment
payloads$(actions.CUSTOMERS_BOOKINGS_PAGE_INCREMENT)
  .subscribe(async () => {
    console.warn('--------------CUSTOMERS_BOOKINGS_PAGE_INCREMENT!')
    // globalActions.populateCustomers({ isLoadMore: true })
  })

// Form
payloads$(actions.CUSTOMER_FORM_GET)
  .subscribe(async ({ id, addon }) => {
    await globalActions.populateCustomerFieldsAndCategories()
    const state = store.getState()
    const { company, account } = state || {}
    const { customersMiddlewareUrl } = company || {}
    let availableTags = (state.companyTags.list || [])
    availableTags = availableTags || []
    if (availableTags.length === 0) availableTags = await globalActions.populateCompanyTags()
    const companyLocale = state.company.locale || 'en-gb'
    let { list: branchesList, areFetched } = state.branches || {}
    branchesList = branchesList || []
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    if (!id) {
      const fields = globalActions.prepareCustomerFieldsForForm()
      const firstNameFieldIndex = fields.findIndex(item => item.defaultId === 'firstName')
      const lastNameFieldIndex = fields.findIndex(item => item.defaultId === 'lastName')
      if (addon === 'addCustomer') {
        const addonData = state.bookings.addonData || {}
        const { name } = addonData || {}
        const parts = (name || '').split(' ')
        const lastName = parts[parts.length - 1]
        const firstName = parts.slice(0, -1).join(' ')
        if (firstNameFieldIndex > -1) fields[firstNameFieldIndex] = { ...fields[firstNameFieldIndex], value: firstName }
        if (lastNameFieldIndex > -1) fields[lastNameFieldIndex] = { ...fields[lastNameFieldIndex], value: lastName }
      }
      handlers.customerFormPopulate({
        customer: { fields, availableTags, companyLocale },
        branches: branchesList,
        account
      })
      return
    }
    const customer = await q(getQueryName('getEnterpriseCustomer'), { id }, null, null, customersMiddlewareUrl)
    const { error } = customer || { error: {} }
    if (error) {
      handlers.navigateToPath(id ? `/customers/customers/${id}` : '/customers/customers')
      return
    }
    const formattedCustomer = customerTransform(customer)
    const customerFieldsWithValues = globalActions.prepareCustomerFieldsWithValues(formattedCustomer.fields)
    handlers.customerFormPopulate({
      customer: {
        ...formattedCustomer,
        fields: globalActions.prepareCustomerFieldsForForm(customerFieldsWithValues),
        availableTags,
        companyLocale
      },
      branches: branchesList,
      account
    })
  })

// Save
payloads$(actions.CUSTOMER_FORM_SAVE)
  .subscribe(async ({ customer, scrollToError }) => {
    const state = store.getState()
    const { company, branches } = state
    const { customersMiddlewareUrl } = company || {}
    const { list: branchesList } = branches || {}
    const errors = customerFormValidate(customer)
    if (errors.length) return setCustomerFormSaveErrors(errors, scrollToError)
    const savedCustomer = await q(getQueryName('saveEnterpriseCustomer'), customerSaveTransform(customer), null, null, customersMiddlewareUrl)
    const { error, id } = savedCustomer || { error: { text: 'Not found ' } }
    if (error) return setCustomerFormSaveErrors(customerFormServerErrorsTransform({ error, branchesList }), scrollToError)
    // Handle form addon extra logic
    const { hash } = state.router || {}
    const { addon } = state.customers || {}
    if (addon) {
      const bookingForm = state.forms.booking || {}
      const fields = {}
      if (!hash || hash === 'singleBooking') {
        fields.bookingCustomer = {
          ...bookingForm.bookingCustomer,
          value: savedCustomer.fullName || savedCustomer.companyName,
          selectedId: savedCustomer.id,
          customer: globalActions.transformCustomer({ ...savedCustomer })
        }
      }
      if (hash === 'groupBooking') {
        const bookingForm = state.forms.booking || {}
        const price = bookingForm.price ? bookingForm.price.value : '0.00'
        const newCustomer = {
          id: savedCustomer.id,
          name: savedCustomer.fullName,
          extraPersons: 0,
          singlePrice: price,
          totalPrice: price
        }
        fields.participants = {
          ...bookingForm.participants,
          selected: [...bookingForm.participants.selected, newCustomer]
        }
      }
      handlers.formFieldsUpdate('booking', fields)
      handlers.bookingFormAddonPopulate()
      return
    }
    globalActions.populateCustomers({ page: 1 })
    handlers.customerUpdate({ ...savedCustomer })
    // if (customer.id && savedCustomer && selected.fullName !== savedCustomer.fullName) {
    //   handlers.bookingFormCustomerUpdate(savedCustomer)
    // }
    handlers.navigateToPath(`/customers/${id}`)
  })

const setCustomerFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('customers', errors)
  scrollToError && scrollToError(errors)
  handlers.customerFormReady()
}

// Update
payloads$(actions.CUSTOMER_UPDATE)
  .subscribe(async customer => {
    if (!customer) return
    const availableCustomerLetters = await q('getEnterpriseAvailableCustomerLetters')
    setTimeout(() => handlers.customerUpdated(customer, availableCustomerLetters), 2000)
  })

// Delete
payloads$(actions.CUSTOMER_DELETE)
  .subscribe(async id => {
    if (!id) return
    const state = store.getState()
    const { company } = state
    const { customersMiddlewareUrl } = company || {}
    const result = await q(getQueryName('deleteEnterpriseCustomer'), { id }, null, null, customersMiddlewareUrl)
    const { error } = result || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      const { code, data } = error || {}
      let { localErrors } = data || {}
      localErrors = localErrors || []
      if (code === 'HasFutureEvents' || localErrors.length > 0) {
        handlers.formErrorsSet('customerPreview', [{ key: 'globalErrors', value: 'errors.customerHasFutureEvents' }])
        handlers.customerPreviewPopulate()
        setTimeout(() => handlers.formErrorsSet('customerPreview', []), 6000)
      }
      return
    }
    const availableCustomerLetters = await q('getEnterpriseAvailableCustomerLetters')
    handlers.customerDeleted(id, availableCustomerLetters)
    setTimeout(() => handlers.customerRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/customers'), 0)
  })

// Reset global entity
payloads$(actions.CUSTOMER_RESET_GLOBAL)
  .subscribe(async internalId => {
    if (!internalId) return handlers.customerResetGlobalReady()
    const state = store.getState()
    const { company } = state
    const { customersMiddlewareUrl } = company || {}
    const result = await q(getQueryName('resetGlobalCustomer'), { internalId }, null, null, customersMiddlewareUrl)
    const { errors } = result || { errors: {} }
    if (errors) return
    handlers.customerResetGlobalReady()
    handlers.navigateToPath('/customers')
  })

// Reorder
payloads$(actions.CUSTOMERS_LIST_ORDER_CHANGE)
  .subscribe(async ({ id }) => {
    const state = store.getState()
    const { company } = state
    const { customersMiddlewareUrl } = company || {}
    const { orderIndex } = state.customers.list.find(customer => customer.id === id)
    const { error } = await q(getQueryName('moveCustomer'), { id, orderIndex }, null, null, customersMiddlewareUrl)
    if (error) console.warn('Drag n drop customer error!')
  })

// Print
payloads$(actions.CUSTOMER_BOOKING_PRINT)
  .subscribe(async () => {
    const bookings = []
    // const now = momenttz.utc().format('YYYY-MM-DD HH:mm')
    const { calendar = {}, company = {}, resources = {}, staticData = {}, customers = {} } = store.getState()
    // const { calendar = {}, company = {}, resources = {}, staticData = {}, customers = {}, forms = {} } = store.getState()
    const timezoneCode = calendar.timezone || company.timezone
    const timezone = staticData.timezones.find(item => item.code === timezoneCode)
    const customer = customers.selected
    // const { customerPrintUpcomingBookings, customerPrintPastBookings } = forms.customers

    customer.events && customer.events.forEach(event => {
      event.intervals && event.intervals.forEach(interval => {
        const booking = {
          ...event,
          interval,
          from: interval.from,
          batchNumber: interval.batchNumber + 1,
          batchCount: (!!event.isBatch && event.durationsPattern && (event.durationsPattern.length + 1) / 2) || 0,
          resources: (resources.list && resources.list.filter(item => event.resourceIds.includes(item.id))) || []
        }
        delete booking.intervals
        bookings.push(booking)
      })
    })

    // let events = bookings.filter(booking => {
    //   let show = false

    //   if (customerPrintUpcomingBookings.value) {
    //     show = booking.interval.from >= now
    //   }

    //   if (!show && customerPrintPastBookings.value) {
    //     show = booking.interval.from < now
    //   }

    //   return show
    // }).sort(sortBy('from'))
    const events = bookings.sort(sortBy('from'))
    const html = renderToString(<CustomerBookingsPrintTemplate items={events} timezone={timezone} customer={customer} />)
    print({ cssFiles: ['/cssPrint/Customers.css'], html })
  })

// Download CSV
payloads$(actions.CUSTOMERS_DOWNLOAD)
  .subscribe(async () => {
    const state = store.getState()
    const { company } = state
    const { customersMiddlewareUrl } = company || {}
    const url = await q(getQueryName('exportCustomers'), null, null, null, customersMiddlewareUrl)
    const { error } = url || {}
    if (error) return
    window.open(url, '_blank')
    handlers.companyLastCustomersDownloadSet(momenttz.utc().format('YYYY-MM-DD HH:mm:ss'))
  })
