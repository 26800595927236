import React from 'react'
import {
  Checkbox,
  Alert,
  Row,
  Col,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t,
  BorderedBox,
  Radio,
  MultipleSelect
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'
import { UserPermissionTypeAccordion } from '../../../Beauties'

import './UserFormCustomPermissions.css'

const UsersFormCustomPermissions = props => {
  const {
    // Branches
    permissionsBranchesRead,
    permissionsBranchesWrite,
    // Tags
    permissionsTagsRead,
    permissionsTagsWrite,
    // Customers
    permissionsGlobalCustomersRead,
    permissionsGlobalCustomersWrite,
    // DataFields
    permissionsGlobalDataFieldsRead,
    permissionsGlobalDataFieldsWrite,
    // Tags
    permissionsGlobalTagsRead,
    permissionsGlobalTagsWrite,
    // Services
    permissionsGlobalServicesRead,
    permissionsGlobalServicesWrite,
    // Groups
    permissionsGlobalGroupsRead,
    permissionsGlobalGroupsWrite,
    // Resource
    permissionsGlobalResourceCategoriesRead,
    permissionsGlobalResourceCategoriesWrite,
    // Global Permission groups
    permissionsGlobalPermissionsGroupsRead,
    permissionsGlobalPermissionsGroupsWrite,
    // Settings
    permissionsSettingsRead,
    // Apps
    permissionsAppsRead,
    // Users
    permissionsUsersRead,
    permissionsUsersWrite,
    // Permission groups
    permissionsPermissionsGroupsRead,
    permissionsPermissionsGroupsWrite,
    // Custom access
    branchAccess,
    isBranchesAccessWhitelistDisabled,
    branchesAccessBlacklist,
    canLogedInUserWriteUsers,
    isAdmin
  } = props
  const hasAll = isAdmin || isBranchesAccessWhitelistDisabled || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-user-permissions__form'>
      <Alert theme='alert'>
        {dangerousHTML(t('resources.form.permissions.adminMessage'))}
      </Alert>
      <UserPermissionTypeAccordion title={t('global.account')}>
        <Row className='ta-user-permissions__form__row-title'>
          <Col size={15}>
            <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsAccountRead' theme='switch' />
          </Col>
        </Row>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.statisticsDashboard')}>
        <Row className='ta-user-permissions__form__row-title'>
          <Col size={15}>
            <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsStatisticsRead' theme='switch' />
          </Col>
        </Row>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.branches')}>
        <div className='ta-user-permissions__form__acl__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsBranchesRead' theme='switch' />
            </Col>
          </Row>
          {((canLogedInUserWriteUsers || isAdmin) && permissionsBranchesRead &&
            <BorderedBox>
              <Row>
                <Col>
                  <Radio name='branchAccess' value='ALLOW' label={t('userPermissionTypes.branchAccess.allow')} />
                </Col>
              </Row>
              {(branchAccess === 'ALLOW' &&
                <Row>
                  <Col>
                    <MultipleSelect
                      name='branchesAccessWhitelist'
                      label={t('userPermissionTypes.branchAccess.select.label')}
                      hasAll={hasAll}
                      allLabel={t('global.allLocations')}
                      hasSelectAll
                      clearable
                      searchable
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <HoverPopup disabled={isAdmin}>
                    <HoverPopupContent position='top'>
                      {t('userPermissionTypes.branchAccess.deny.tooltip')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Radio
                        name='branchAccess'
                        value='DENY'
                        label={t('userPermissionTypes.branchAccess.deny')}
                        disabled={!isAdmin}
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              {(branchAccess === 'DENY' &&
                <Row>
                  <Col>
                    <MultipleSelect
                      name='branchesAccessBlacklist'
                      label={t('userPermissionTypes.branchAccess.select.label')}
                      clearable
                      searchable
                    />
                  </Col>
                </Row>
              )}
            </BorderedBox>
          )}
        </div>
        {(permissionsBranchesRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsBranchesWrite' theme='switch' />
              </Col>
            </Row>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={35}>
                <div className='ta-user-permissions__form__label'>{t('global.branchLogin').toUpperCase()}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsAllowFulfilment' theme='switch' />
              </Col>
            </Row>
            {(permissionsBranchesWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('permissions.tooltip.branchDelete')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox name='permissionsBranchesDelete' theme='switch' disabled />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.branchTags')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsTagsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsTagsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsTagsWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsTagsWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsTagsDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalCustomers')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalCustomersRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalCustomersRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalCustomersWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalCustomersWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalCustomersDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalCustomerFields')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalDataFieldsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalDataFieldsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalDataFieldsWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalDataFieldsWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalDataFieldsDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalTags')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalTagsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalTagsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalTagsWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalTagsWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalTagsDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalServices')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalServicesRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalServicesRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalServicesWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalServicesWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalServicesDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalGroups')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalGroupsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalGroupsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalGroupsWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalGroupsWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalGroupsDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalResourceCategories')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalResourceCategoriesRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalResourceCategoriesRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalResourceCategoriesWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalResourceCategoriesWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalResourceCategoriesDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('permissionTypes.global.label')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsGlobalPermissionsGroupsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsGlobalPermissionsGroupsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsGlobalPermissionsGroupsWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsGlobalPermissionsGroupsWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsGlobalPermissionsGroupsDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.settings')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsSettingsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsSettingsRead &&
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsSettingsWrite' theme='switch' />
            </Col>
          </Row>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.apps')}>
        <Row className='ta-user-permissions__form__row-title'>
          <Col size={15}>
            <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
          </Col>
          <Col>
            <Checkbox name='permissionsAppsRead' theme='switch' />
          </Col>
        </Row>
        {(permissionsAppsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsAppsWrite' theme='switch' />
              </Col>
            </Row>
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.users')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsUsersRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsUsersRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsUsersWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsUsersWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsUsersDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.userPermissionTypes')}>
        <div className='ta-user-permissions__form__row__wrapper'>
          <Row className='ta-user-permissions__form__row-title'>
            <Col size={15}>
              <div className='ta-user-permissions__form__label'>{t('global.read')}:</div>
            </Col>
            <Col>
              <Checkbox name='permissionsPermissionsGroupsRead' theme='switch' />
            </Col>
          </Row>
        </div>
        {(permissionsPermissionsGroupsRead &&
          <>
            <Row className='ta-user-permissions__form__row-title'>
              <Col size={15}>
                <div className='ta-user-permissions__form__label'>{t('global.write')}:</div>
              </Col>
              <Col>
                <Checkbox name='permissionsPermissionsGroupsWrite' theme='switch' />
              </Col>
            </Row>
            {(permissionsPermissionsGroupsWrite &&
              <Row className='ta-user-permissions__form__row-title'>
                <Col size={15}>
                  <div className='ta-user-permissions__form__label'>{t('global.delete').toUpperCase()}:</div>
                </Col>
                <Col>
                  <Checkbox name='permissionsPermissionsGroupsDelete' theme='switch' />
                </Col>
              </Row>
            )}
          </>
        )}
      </UserPermissionTypeAccordion>
    </div>
  )
}

export default UsersFormCustomPermissions
