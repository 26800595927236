import React, { useEffect } from 'react'
import { connect, globalActions } from '../../../Store'
import { EmailSettings } from '../../../Beauties'

const EmailSettingsWrapper = props => {
  let { settings, rawSettings, account, categoriesList, enterpriseCustomisation } = props
  settings = settings || {}
  rawSettings = rawSettings || {}
  enterpriseCustomisation = enterpriseCustomisation || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsRead, settingsWrite } = enterprisePermissions || {}
  settingsRead = !!isAdmin || !!settingsRead
  settingsWrite = !!isAdmin || !!settingsWrite
  const { payload: customisaitonPayload } = enterpriseCustomisation || {}

  useEffect(() => {
    const fetchNeededData = async () => {
      await globalActions.populateEnterpriseResourceCategories()
    }
    if (settingsRead) fetchNeededData()
  }, [settingsRead])

  return (
    <EmailSettings
      settingsRead={settingsRead}
      settingsWrite={settingsWrite}
      categoriesList={categoriesList}
      rawSettings={rawSettings}
      customisations={customisaitonPayload}
      {...settings}
    />
  )
}

const maps = state => ({
  settings: state.company.settings,
  rawSettings: state.company.rawSettings,
  account: state.account,
  enterpriseCustomisation: state.company.enterpriseCustomisation,
  categoriesList: state.resources.categoriesList || []
})

export default connect(maps)(EmailSettingsWrapper)
