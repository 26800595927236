import React from 'react'
import { connect, handlers } from '../../Store'
import { Modal } from '../../Beauties'
import { Iframe } from '../../Common'
import { DashboardStatisticsExportFormWrapper, BranchesStatisticsExportFormWrapper, BranchesFormExportWrapper } from '../../Beasts'

const ModalWrapper = props => {
  const { modal } = props
  const { isActive = false, content, params } = modal
  const onCloseModal = () => {
    handlers.modalHide()
    handlers.overlayHide('top')
  }

  return (
    <Modal isActive={isActive} onClickOutside={onCloseModal}>
      {content === 'iframe' && <Iframe {...params} />}
      {content === 'export-branches' && <BranchesFormExportWrapper onCloseModal={onCloseModal} {...params} />}
      {content === 'export-dashboard-statistics' && <DashboardStatisticsExportFormWrapper onCloseModal={onCloseModal} {...params} />}
      {content === 'export-branch-statistics' && <BranchesStatisticsExportFormWrapper onCloseModal={onCloseModal} {...params} />}
    </Modal>
  )
}

const maps = state => ({
  modal: state.modal || {}
})

export default connect(maps)(ModalWrapper)
