import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { CustomerFieldCategoryPreview } from '../../Beauties'

const CustomerFieldCategoryPreviewWrapper = props => {
  let {
    categoryId,
    categories,
    customerFields,
    hash,
    pending,
    account,
    allowOnlyGlobalCustomers
  } = props
  categories = categories || []
  customerFields = customerFields || []
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalDataFieldsRead, globalDataFieldsWrite, globalDataFieldsDelete } = enterprisePermissions || {}
  globalDataFieldsRead = !!isAdmin || !!globalDataFieldsRead
  globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite
  globalDataFieldsDelete = !!isAdmin || !!globalDataFieldsDelete
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.customerFieldCategoryLocallyChangedGet(categoryId)
  }, [categoryId])
  const category = categories.find(item => item.id === categoryId) || {}
  const categoryCustomerFields = customerFields.filter(item => item.categoryId === categoryId) || []

  return (
    pending
      ? <Loader active={pending} />
      : (
        <CustomerFieldCategoryPreview
          category={category}
          hash={hash}
          pending={pending}
          hasCustomerFields={categoryCustomerFields.length > 0}
          globalDataFieldsRead={globalDataFieldsRead}
          globalDataFieldsWrite={globalDataFieldsWrite}
          globalDataFieldsDelete={globalDataFieldsDelete}
          allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        />
        )
  )
}

const maps = state => ({
  hash: state.router.hash,
  categoryId: (state.router.data && state.router.data.categoryId),
  categories: state.customerFields.categoriesList,
  customerFields: state.customerFields.list,
  pending: state.customerFields.pendingCategoryPreview,
  account: state.account,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default connect(maps)(CustomerFieldCategoryPreviewWrapper)
