import React from 'react'
import { ServiceFormRemindersWrapper } from '../../../Beasts'

import './ServiceForm.css'

const ServiceFormAdvanced = props => {
  return <ServiceFormRemindersWrapper />
}

export default ServiceFormAdvanced
