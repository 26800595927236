import React, { useState, useEffect } from 'react'
import { connect, handlers } from '../../../Store'
import { Loader } from '../../../Common'
import { CustomersSettingsEditForm } from '../../../Beauties'

const CustomersSettingsEditFormWrapper = props => {
  let {
    pending,
    form,
    tags,
    settings,
    eugdprEnabled,
    eugdprRules,
    eugdprRulesErrors,
    customersMiddlewareUrl,
    account
  } = props
  pending = !!pending
  tags = tags || []
  form = form || {}
  account = account || {}
  settings = settings || {}
  const customerTags = tags.filter(item => item.isForCustomers)
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsWrite } = enterprisePermissions || {}
  settingsWrite = !!isAdmin || !!settingsWrite
  const [initial] = useState({ settings, settingsWrite })

  useEffect(() => {
    const { settings, settingsWrite } = initial
    settingsWrite ? handlers.settingsCustomersFormPopulate(settings || {}) : handlers.navigateToPath('/settings/customers')
  }, [initial])

  const onSubmit = cb => {
    handlers.settingsCustomersFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CustomersSettingsEditForm
          cancelLink='/settings/customers'
          tags={customerTags.map(item => ({ label: item.name, value: item.id }))}
          onSubmit={onSubmit}
          eugdprEnabled={eugdprEnabled}
          eugdprRules={eugdprRules}
          eugdprRulesErrors={eugdprRulesErrors}
          customersMiddlewareUrl={customersMiddlewareUrl}
        />
      )}
    </>
  )
}

const maps = state => ({
  form: state.forms.settingsCustomers || {},
  // rawSettings: state.company.rawSettings || {},
  customersMiddlewareUrl: state.company.customersMiddlewareUrl,
  settings: state.company.settings || {},
  tags: state.companyTags.list || [],
  // pending: state.settingsCustomers.pendingForm,
  eugdprEnabled: (state.forms.settingsCustomers && state.forms.settingsCustomers.eugdprEnabled && state.forms.settingsCustomers.eugdprEnabled.value),
  eugdprRules: (state.forms.settingsCustomers && state.forms.settingsCustomers.eugdprRules && state.forms.settingsCustomers.eugdprRules.values),
  eugdprRulesErrors: (state.forms.settingsCustomers && state.forms.settingsCustomers.eugdprRules && state.forms.settingsCustomers.eugdprRules.errors) || [],
  account: state.account
})

export default connect(maps)(CustomersSettingsEditFormWrapper)
