import React from 'react'
import { StagingIndicator } from '../../Beauties'

import './Staging.css'

const Staging = props => {
  return (
    <StagingIndicator />
  )
}

export default Staging
