import {
  sortByOrderIndex
} from '../../../Utils'

import {
  tagsTransform,
  tagsListTransform,
  tagsFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  pendingList: true,
  pendingForm: true,
  pendingPreview: true,
  selected: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  tagsReset: () => dispatch(actions.TAGS_RESET),

  // List
  tagsListGet: () => dispatch(actions.TAGS_LIST_GET),
  tagsListPopulate: ({ tags }) => dispatch(actions.TAGS_LIST_POPULATE, { tags: tagsListTransform(tags) }),
  tagsListPopulateSearch: ({ tags }) => dispatch(actions.TAGS_LIST_POPULATE_SEARCH, { tags: tagsListTransform(tags) }),
  tagsListReady: () => dispatch(actions.TAGS_LIST_READY),
  tagsListPending: () => dispatch(actions.TAGS_LIST_PENDING),

  // Search
  tagsSearch: () => dispatch(actions.TAGS_LIST_SEARCH),

  // One
  tagsUpdate: tag => dispatch(actions.TAGS_UPDATE, tagsTransform(tag)),
  tagsUpdated: tag => dispatch(actions.TAGS_UPDATED, tag),
  tagsDelete: id => dispatch(actions.TAGS_DELETE, id),
  tagsDeleted: id => dispatch(actions.TAGS_DELETED, id),
  tagsRemoveDeleted: id => dispatch(actions.TAGS_REMOVE_DELETED, id),

  // Preview
  tagsPreviewGet: ({ id, forceFetch }) => dispatch(actions.TAGS_PREVIEW_GET, { id, forceFetch }),
  tagsPreviewPopulate: tag => dispatch(actions.TAGS_PREVIEW_POPULATE, tag),

  // Form
  tagsFormGet: id => dispatch(actions.TAGS_FORM_GET, id),
  tagsFormPopulate: tag => {
    handlers.formSet('tags', tagsFormTransform(tag))
    handlers.tagsFormReady()
  },
  tagsFormReady: () => dispatch(actions.TAGS_FORM_READY),
  tagsFormSave: (tag, scrollToError = () => { }) => dispatch(actions.TAGS_FORM_SAVE, { tag, scrollToError })
})

// Reset
reducer.TAGS_RESET = state => reducer.initialState

// Search
reducer.TAGS_LIST_SEARCH = (state) => ({
  ...state,
  pendingList: true
})

// List
reducer.TAGS_LIST_GET = (state) => ({
  ...state,
  pendingList: true,
  areFetched: true
})
reducer.TAGS_LIST_POPULATE = (state, { tags = [] }) => ({
  ...state,
  list: tags.sort((a, b) => a - b),
  searchList: [],
  pendingList: false
})
reducer.TAGS_LIST_POPULATE_SEARCH = (state, { tags = [] }) => ({
  ...state,
  searchList: tags.sort((a, b) => a - b),
  pendingList: false
})
reducer.TAGS_LIST_READY = state => ({
  ...state,
  pendingList: false
})
reducer.TAGS_LIST_PENDING = state => ({
  ...state,
  pendingList: true
})

// One
reducer.TAGS_UPDATE = (state, tag) => {
  if (!tag) return state
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== tag.id)
      .concat([{ ...tag, isUpdated: true }])
      .sort(sortByOrderIndex)
  }
}
reducer.TAGS_UPDATED = (state, tag) => {
  if (!tag) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === tag.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}
reducer.TAGS_DELETE = state => ({
  ...state,
  pendingPreview: true
})
reducer.TAGS_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}
reducer.TAGS_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

// Preview
reducer.TAGS_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true
})
reducer.TAGS_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

// Form
reducer.TAGS_FORM_GET = state => ({
  ...state,
  pendingForm: true
})
reducer.TAGS_FORM_READY = state => ({
  ...state,
  pendingForm: false
})
reducer.TAGS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
