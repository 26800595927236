import React from 'react'
import { connect } from '../../Store'
import { CourseFormDuration } from '../../Beauties'

const CourseFormDurationWrapper = props => {
  const {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    splitDurationInIntervals,
    color
  } = props

  return (
    <CourseFormDuration
      durationBefore={durationBefore}
      durationAfter={durationAfter}
      showDurationDaySelector
      showDurationBeforeAndAfter={showDurationBeforeAndAfter}
      splitDurationInIntervals={splitDurationInIntervals}
      color={color}
    />
  )
}

const maps = state => ({
  durationBefore: (state.forms.courses.durationBefore && state.forms.courses.durationBefore.value) || '',
  durationAfter: (state.forms.courses.durationAfter && state.forms.courses.durationAfter.value) || '',
  showDurationBeforeAndAfter: (state.forms.courses.showDurationBeforeAndAfter && state.forms.courses.showDurationBeforeAndAfter.value) || false,
  splitDurationInIntervals: (state.forms.courses.splitDurationInIntervals && state.forms.courses.splitDurationInIntervals.value) || false,
  color: state.forms.courses.color && state.forms.courses.color.value
})

export default connect(maps)(CourseFormDurationWrapper)
