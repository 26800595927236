import React from 'react'
import moment from 'moment'
import { renderToString } from 'react-dom/server'
import {
  Row,
  Col,
  Title,
  Button,
  AccordionListBody,
  IconListItem,
  IconListItemBody,
  IconListItemTitle,
  IconListItemButtons,
  StatusBox,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  format,
  AccordionList,
  Spinner,
  Form,
  Error,
  Alert,
  AccordionTitle,
  Avatar,
  t
} from '../../../Common'
import { PopupTriggerWrapper } from '../../../Beasts'
import { BranchesPreviewResourcesFilter } from '../../'
import { getDayLabelByIndex, highlight } from '../../../Utils'

import './BranchesPreviewResources.css'

const BranchesPreviewResources = props => {
  const {
    isPending,
    branch,
    categoriesWithResources,
    filter,
    handleOnClickResendConfirmationEmail,
    handleOnClickConfirmForCompany,
    showImportMessage,
    branchesWrite,
    branchesDelete,
    hideBranchEditResourceBtn,
    hideBranchDeleteResourceBtn,
    hideBranchPasswordResetEmailBtn,
    handleOnUnlock,
    showBranchResourcePasswordChangeBtn,
    showBranchResourceUnblockBtn,
    hideBranchResendConfirmationEmailBtn,
    hideBranchConfirmCompanyBtn
  } = props
  const { region, id: branchId } = branch || {}

  return (
    <div className='ta-branch__preview__resources'>
      {(isPending &&
        <Spinner />
      )}
      {(!isPending && showImportMessage &&
        <Alert label={t('branches.importStarted')} theme='success'>
          {t('branches.list.imoportStarted.message', [{ key: 'ENTITY', value: t('global.resource') }])}
        </Alert>
      )}
      {(!isPending &&
        <BranchesPreviewResourcesFilter branchesWrite={branchesWrite} companyId={branchId} />
      )}
      {(!isPending && categoriesWithResources.map((category, categoryIndex) => {
        return (
          <div key={category.id}>
            <Title
              size='m'
              isCompact
              label={category.name === 'default' ? t('branches.preview.resources.section.categories.title') : category.name}
              icon='folder'
              ignoreTopOffset={categoryIndex === 0}
            />
            {category.resources.length === 0 && <Alert noOffset label={t('branches.preview.resources.section.noResources')} theme='no-content' />}
            <Form name='branchResourcePreview'>
              <Error name='globalErrors' />
            </Form>
            {(category.resources.length > 0 && category.resources.map((resource, resourceIndex) => {
              const {
                id: resourceId,
                externalId,
                totalBookings,
                name,
                color,
                isBookable,
                email,
                hasOnlineWarning,
                timezone,
                createdAt,
                updatedAt,
                calendar,
                account,
                companyId,
                pendingCompanyConfirm,
                confirmationError,
                avatarUrl,
                userLoginBlockedUntil
              } = resource
              let {
                isConfirmed,
                isConfirmationSent,
                roles,
                userId
              } = account || {}
              roles = roles || []
              isConfirmed = !!isConfirmed
              isConfirmationSent = !!isConfirmationSent
              const {
                bookingWeeklyAllowancePlan,
                workingWeeklyAllowancePlan
              } = calendar || {}
              const hasDifferentWeeklyAllowancePlans = JSON.stringify(workingWeeklyAllowancePlan) !== JSON.stringify(bookingWeeklyAllowancePlan)
              const extraInfo = []
              if (userId) extraInfo.push(renderToString(<FontAwesome5 icon='user' />))
              extraInfo.push(`${totalBookings} bookings`)
              extraInfo.push((isBookable && !hasOnlineWarning) ? t('global.online') : t('global.offline'))
              const isOwner = roles.includes('OWNER')
              const isAdmin = roles.includes('ADMIN')
              const isCustom = roles.includes('CUSTOM')
              const isBlocked = showBranchResourceUnblockBtn && userId && userLoginBlockedUntil > moment().utc().format('YYYY-MM-DD HH:mm:ss')

              const classNames = ['ta-branch__preview__resources__list-title']
              if (isBookable && !hasOnlineWarning) classNames.push('online')

              const bookingsClassNames = ['ta-branch__preview__resources__list-title-bookings']
              if (totalBookings === 0) bookingsClassNames.push('no-bookings')

              return (
                <AccordionList key={resourceId} expand={false}>
                  <AccordionTitle>
                    <div className={classNames.join(' ')}>
                      <Avatar
                        name={name}
                        image={avatarUrl}
                        color={color}
                      />
                      <div className='ta-branch__preview__resources__list-title-info'>
                        <div className='ta-branch__preview__resources__list-title-name'>
                          {highlight(name || '-', filter)}
                          {(isOwner &&
                            <div className='ta-branch__preview__resources__list-title-tag owner'>{t('account.list.item.title.owner')}</div>
                          )}
                          {(!isOwner && isAdmin &&
                            <div className='ta-branch__preview__resources__list-title-tag admin'>{t('account.list.item.title.admin')}</div>
                          )}
                          {(isCustom &&
                            <div className='ta-branch__preview__resources__list-title-tag custom'>{t('account.list.item.title.custom')}</div>
                          )}
                          {(isBlocked &&
                            <HoverPopup>
                              <HoverPopupContent position='top'>
                                {t('branches.preview.resources.section.details.popup.accountLocked')}
                              </HoverPopupContent>
                              <HoverPopupTrigger>
                                <div className='ta-branch__preview__resources__list-title-tag locked'>{t('account.list.item.title.locked')}</div>
                              </HoverPopupTrigger>
                            </HoverPopup>
                          )}
                        </div>
                        <div className='ta-branch__preview__resources__list-title-id'>
                          <FontAwesome5 icon='hashtag' type='s' />
                          {resourceId}
                        </div>
                        {(email &&
                          <div className='ta-branch__preview__resources__list-title-email'>
                            <FontAwesome5 icon='envelope' type='s' />
                            <IconListItemBody>
                              <Button as='link' to={`mailto:${email}`} external>{email}</Button>
                            </IconListItemBody>
                          </div>
                        )}
                      </div>
                      {(!isBlocked &&
                        <div className={bookingsClassNames.join(' ')}>
                          {totalBookings}
                          <div>{t('global.bookings')}</div>
                        </div>
                      )}
                      {(isBlocked &&
                        <HoverPopup className='ta-branch__preview__resources__list-title-unlock-hover'>
                          <HoverPopupContent position='top'>
                            {t('branches.preview.resources.section.details.popup.unlockAccount')}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            <div onClick={e => handleOnUnlock(e, { resourceId, userId })} className='ta-branch__preview__resources__list-title-unlock'>
                              {t('buttons.unlock.label')}
                            </div>
                          </HoverPopupTrigger>
                        </HoverPopup>
                      )}
                    </div>
                  </AccordionTitle>
                  <AccordionListBody>
                    <Row>
                      <Col size={60}>
                        <Title
                          isCompact
                          label={t('branches.preview.resources.section.details.title')}
                          icon='user'
                          ignoreTopOffset
                        />
                        <IconListItem>
                          <IconListItemTitle width='90px'>ID:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{resourceId}</IconListItemBody>
                        </IconListItem>
                        {(userId &&
                          <IconListItem>
                            <IconListItemTitle width='90px'>{t('global.userId')}:</IconListItemTitle>
                            <IconListItemBody offset='90px'>{userId}</IconListItemBody>
                          </IconListItem>
                        )}
                        {(externalId &&
                          <IconListItem>
                            <IconListItemTitle width='90px'>{t('global.externalId')}:</IconListItemTitle>
                            <IconListItemBody offset='90px'>{externalId}</IconListItemBody>
                          </IconListItem>
                        )}
                        <IconListItem>
                          <IconListItemTitle width='90px'>{t('global.name')}:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{name}</IconListItemBody>
                        </IconListItem>
                        {(email &&
                          <IconListItem>
                            <IconListItemTitle width='90px'>{t('global.email')}:</IconListItemTitle>
                            <IconListItemBody offset='90px'>{email}</IconListItemBody>
                          </IconListItem>
                        )}
                        <IconListItem>
                          <IconListItemTitle width='90px'>{t('global.createdAt')}:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{createdAt}</IconListItemBody>
                        </IconListItem>
                        <IconListItem>
                          <IconListItemTitle width='90px'>{t('global.updatedAt')}:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{updatedAt}</IconListItemBody>
                        </IconListItem>
                        <IconListItem>
                          <IconListItemTitle width='90px'>{t('global.timezone')}:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{timezone}</IconListItemBody>
                        </IconListItem>
                        <IconListItem>
                          <IconListItemTitle width='90px'>{t('global.color')}:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{color}</IconListItemBody>
                        </IconListItem>
                        <IconListItem>
                          <IconListItemTitle width='90px'>{t('global.bookings')}:</IconListItemTitle>
                          <IconListItemBody offset='90px'>{totalBookings}</IconListItemBody>
                        </IconListItem>
                        <Title
                          isCompact
                          label={t('branches.preview.resources.section.onlineStatus.title')}
                          icon='traffic-light'
                        />
                        <IconListItem>
                          <IconListItemTitle width='110px'>{t('global.onlineBookable')}:</IconListItemTitle>
                          <IconListItemBody offset='110px'>
                            <StatusBox status={hasOnlineWarning ? 'yellow' : isBookable ? 'green' : 'grey'}>
                              {isBookable ? t('global.yes') : t('global.no')}
                            </StatusBox>
                          </IconListItemBody>
                        </IconListItem>
                        <IconListItem>
                          <IconListItemTitle width='110px'>{t('global.canBeOnline')}:</IconListItemTitle>
                          <IconListItemBody offset='110px'>
                            <StatusBox status={hasOnlineWarning ? 'red' : 'green'}>
                              {hasOnlineWarning ? t('global.no') : t('global.yes')}
                            </StatusBox>
                          </IconListItemBody>
                        </IconListItem>
                        <Title
                          isCompact
                          label={t('branches.preview.resources.section.confirmationStatus.title')}
                          icon='check-circle'
                        />
                        <IconListItem>
                          <IconListItemTitle width='110px'>{t('global.confirmed')}:</IconListItemTitle>
                          <IconListItemBody offset='110px'>
                            <StatusBox status={isConfirmed ? 'green' : isConfirmationSent ? 'grey' : 'red'}>
                              {isConfirmed ? t('global.yes') : isConfirmationSent ? t('global.pendingConfirmation') : t('global.no')}
                            </StatusBox>
                            <Error className='ta-confirmation__error' error={confirmationError ? { value: confirmationError } : null} />
                          </IconListItemBody>
                          {(branchesWrite && (resourceId && !isConfirmed && userId) &&
                            <IconListItemButtons className='ta-confirmation__actions'>
                              {(!hideBranchConfirmCompanyBtn &&
                                <HoverPopup>
                                  <HoverPopupContent position='left' autoSize>
                                    {t('branches.preview.resources.section.details.popup.confirm')}
                                  </HoverPopupContent>
                                  <HoverPopupTrigger>
                                    <Button
                                      as='link'
                                      className={`ta-btn ta-btn-delete ${isConfirmed ? 'disabled' : ''}`}
                                      action={
                                        () =>
                                          !pendingCompanyConfirm &&
                                          handleOnClickConfirmForCompany({ resourceId, branchId: branch.id, companyId, userId, region, email })
                                      }
                                      external
                                      icon='check-double'
                                      iconType='solid'
                                    />
                                  </HoverPopupTrigger>
                                </HoverPopup>
                              )}
                              {(!hideBranchResendConfirmationEmailBtn && email && !isConfirmed &&
                                <HoverPopup>
                                  <HoverPopupContent position='left' autoSize>
                                    {t('branches.preview.resources.section.confirmationStatus.emailResend')}
                                  </HoverPopupContent>
                                  <HoverPopupTrigger>
                                    <Button
                                      as='link'
                                      icon='envelope'
                                      iconType='solid'
                                      className='ta-btn ta-btn-delete'
                                      action={() => handleOnClickResendConfirmationEmail({ resourceId, companyId, userId, region })}
                                      external
                                    />
                                  </HoverPopupTrigger>
                                </HoverPopup>
                              )}
                            </IconListItemButtons>
                          )}
                        </IconListItem>
                        <IconListItem>
                          <IconListItemTitle width='110px'>{t('branches.preview.resources.section.confirmationStatus.confirmationSent')}:</IconListItemTitle>
                          <IconListItemBody offset='110px'>
                            <StatusBox status={isConfirmationSent ? 'green' : 'red'}>
                              {isConfirmationSent ? t('global.yes') : t('global.no')}
                            </StatusBox>
                          </IconListItemBody>
                        </IconListItem>
                      </Col>
                      <Col size={40}>
                        {(branchesWrite && (!hideBranchEditResourceBtn || !hideBranchDeleteResourceBtn || !hideBranchPasswordResetEmailBtn) &&
                          <Row>
                            <Col>
                              <Title isCompact label={t('global.actions')} icon='cogs' ignoreTopOffset />
                              {(!hideBranchEditResourceBtn &&
                                <IconListItem>
                                  <IconListItemTitle>{t('branches.preview.resources.section.actions.edit')}:</IconListItemTitle>
                                  <IconListItemButtons>
                                    <Button as='link' isPrimary icon='pencil' iconType='solid' to={`/branches/${branch.id}/resources/${resourceId}?c=${category.id}`} />
                                  </IconListItemButtons>
                                </IconListItem>
                              )}
                              {(branchesDelete && !hideBranchDeleteResourceBtn &&
                                <IconListItem>
                                  <IconListItemTitle>{t('branches.preview.resources.section.actions.delete')}:</IconListItemTitle>
                                  <IconListItemButtons>
                                    <PopupTriggerWrapper
                                      name='branch-resource-delete' position='left' id={{
                                        resource, companyId, id: resourceId, externalId
                                      }}
                                    >
                                      <Button as='link' isPrimary icon='trash' iconType='solid' external />
                                    </PopupTriggerWrapper>
                                  </IconListItemButtons>
                                </IconListItem>
                              )}
                              {(!hideBranchPasswordResetEmailBtn &&
                                <IconListItem>
                                  <IconListItemTitle>{t('branches.preview.resources.section.actions.passwordReset')}:</IconListItemTitle>
                                  <IconListItemButtons>
                                    <PopupTriggerWrapper name='branch-resource-password-reset' position='left' id={{ email, resourceId, branchId }}>
                                      <Button as='link' isPrimary icon='paper-plane' iconType='solid' external />
                                    </PopupTriggerWrapper>
                                  </IconListItemButtons>
                                </IconListItem>
                              )}
                              {(showBranchResourcePasswordChangeBtn && userId &&
                                <IconListItem>
                                  <IconListItemTitle>{t('global.passwordChange')}:</IconListItemTitle>
                                  <IconListItemButtons>
                                    <HoverPopup>
                                      <HoverPopupContent position='left'>
                                        {t('branches.preview.resources.section.details.popup.passwordChange')}
                                      </HoverPopupContent>
                                      <HoverPopupTrigger>
                                        <Button as='link' isPrimary icon='user-lock' iconType='solid' to={`/branches/${branch.id}/resources-password-change/${resourceId}`} />
                                      </HoverPopupTrigger>
                                    </HoverPopup>
                                  </IconListItemButtons>
                                </IconListItem>
                              )}
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col size={hasDifferentWeeklyAllowancePlans ? 20 : 40}>
                            <Title
                              isCompact
                              label={
                                hasDifferentWeeklyAllowancePlans
                                  ? t('branches.preview.resources.section.hours.workingHours')
                                  : t('branches.preview.resources.section.hours.workingAndBookingHours')
                              }
                              icon='clock'
                              ignoreTopOffset
                            />
                            <div className='ta-allowance-plan'>
                              {workingWeeklyAllowancePlan.map((day, index) => (
                                <div key={`working-times${index}`} className='ta-allowance-plan__wrapper'>
                                  <div className={`ta-allowance-plan__day ${day.isActive ? '' : 'empty'}`}>
                                    {t(`weekdays.${getDayLabelByIndex(index)}.long`)}
                                  </div>
                                  {day.isActive && day.times.map((time, timeIndex) => (
                                    <div key={timeIndex} className='ta-allowance-plan__time'>
                                      {format(time.begin, 'time', { isUTC: true, format: 'HH:mm' })} - {format(time.end, 'time', { isUTC: true, format: 'HH:mm' })}
                                    </div>
                                  ))}
                                  {(!day.isActive &&
                                    <div className='ta-allowance-plan__time'>{t('global.unavailable')}</div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </Col>
                          {(hasDifferentWeeklyAllowancePlans &&
                            <Col size={20}>
                              <Title
                                isCompact
                                label={t('branches.preview.resources.section.bookingHours.title')}
                                icon='clock'
                                ignoreTopOffset
                              />
                              <div className='ta-allowance-plan'>
                                {bookingWeeklyAllowancePlan.map((day, index) => (
                                  <div key={`booking-times${index}`} className='ta-allowance-plan__wrapper'>
                                    <div className={`ta-allowance-plan__day ${day.isActive ? '' : 'empty'}`}>
                                      {t(`weekdays.${getDayLabelByIndex(index)}.long`)}
                                    </div>
                                    {day.isActive && day.times.map((time, timeIndex) => (
                                      <div key={timeIndex} className='ta-allowance-plan__time'>
                                        {format(time.begin, 'time', { isUTC: true, format: 'HH:mm' })} - {format(time.end, 'time', { isUTC: true, format: 'HH:mm' })}
                                      </div>
                                    ))}
                                    {(!day.isActive &&
                                      <div className='ta-allowance-plan__time'>{t('global.unavailable')}</div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </AccordionListBody>
                </AccordionList>
              )
            }))}
            {(!categoriesWithResources.length > 0 &&
              <Alert noOffset label={t('branches.preview.resources.section.noResources')} theme='no-content' />
            )}
          </div>
        )
      }))}
    </div>
  )
}

export default BranchesPreviewResources
