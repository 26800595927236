import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { UserLanguageForm } from '../../Beauties'

class UserLanguageEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    handlers.userLanguageChangeFormGet()
  }

  onSubmit (cb) {
    const { form } = this.props

    handlers.userLanguageFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const { pending, errors } = this.props

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <UserLanguageForm
            title={t('user.edit.language.heading')}
            cancelLink='/user'
            errors={errors}
            onSubmit={this.onSubmit}
          />
        )}
      </>
    )
  }
}

UserLanguageEditWrapper.propTypes = {
  pending: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.object)
}

const maps = state => ({
  errors: state.user.errors || [],
  pending: state.user.pendingLanguageForm,
  form: state.forms.userLanguageChange || {}
})

export default connect(maps)(UserLanguageEditWrapper)
