import React from 'react'
import { connect } from '../../Store'
import { BranchesGroupFormParticipants } from '../../Beauties'

const BranchesGroupFormParticipantsWrapper = props => {
  const {
    hasMinParticipants,
    minParticipants,
    deadlineBeforeEvent,
    allowedSet
  } = props

  return (
    <BranchesGroupFormParticipants
      hasMinParticipants={hasMinParticipants}
      minParticipants={minParticipants}
      deadlineBeforeEvent={deadlineBeforeEvent}
      allowedSet={allowedSet}
    />
  )
}

const maps = state => ({
  hasMinParticipants: (state.forms.branchesGroup.hasMinParticipants && state.forms.branchesGroup.hasMinParticipants.value) || false,
  minParticipants: (state.forms.branchesGroup.minParticipants && state.forms.branchesGroup.minParticipants.value) || 0,
  deadlineBeforeEvent: (state.forms.branchesGroup.deadlineBeforeEvent && state.forms.branchesGroup.deadlineBeforeEvent.value) || 0
})

export default connect(maps)(BranchesGroupFormParticipantsWrapper)
