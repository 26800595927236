import React from 'react'
import {
  FormSection,
  Title,
  PriceInput,
  t
} from '../../../Common'

const ServiceFormPrice = props => {
  return (
    <FormSection>
      <Title size='m' isCompact label={t('global.price.label')} icon='money-bill' />
      <PriceInput
        name='price'
        label={t('global.price.label')}
        hintText={t('global.price.hint')}
      />
    </FormSection>
  )
}

export default ServiceFormPrice
