import {
  createSelector
} from '../../../Utils'
import { selectors } from '../../../Store'

// Resources
export const resourcesSelector = state => state.resources

export const resourcesListSelector = createSelector(
  resourcesSelector,
  resources => (resources || {}).list
)

// Activity logs
export const activitySelector = state => state.activity

export const activityLogsMessageListSelector = createSelector(
  activitySelector,
  activity => (activity || {}).messageList
)

export const activityLogsLastPageSelector = createSelector(
  activitySelector,
  activity => (activity || {}).activityLogsLastPage
)

export const activityLogsCountSelector = createSelector(
  activitySelector,
  activity => (activity || {}).activityLogsCount
)

export const activityLogsPendingSelector = createSelector(
  activitySelector,
  activity => (activity || {}).pendingActivityLogs
)

export const activityLogsListSelector = createSelector(
  activitySelector,
  activity => (activity || {}).activityLogs
)

export const activityLogSelector = createSelector(
  activityLogsListSelector,
  (_, props) => props.id,
  (list, id) => (list || []).find(item => item.id === id)
)

export const activityLogsSystemActions = createSelector(
  (state, props) => activityLogSelector(state, { id: props.systemActionId }),
  (state) => selectors.resourcesListSelector(state),
  (activityLog, resourcesList) => {
    activityLog = activityLog || {}
    resourcesList = resourcesList || []
    const accessUserData = resourcesList?.find((resource) => resource?.id === activityLog?.accessUserId) || {}
    const { avatarUrl: requesterAvatarUrl } = accessUserData
    return {
      ...activityLog,
      requesterAvatarUrl
    }
  }
)
