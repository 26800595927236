import React, { useRef } from 'react'
import { handlers } from '../../../Store'
import {
  BillingPreviewMethod
} from '../../../Beauties'
import { AppsSubscriptionBillingFormWrapper } from '../../../Beasts'
import {
  Title,
  IconListItem,
  IconListItemBody,
  IconListItemTitle,
  InnerAccordion,
  Row,
  Col,
  t,
  Button,
  FormSection
} from '../../../Common'

import './AppsSubscription.css'

const AppsSubscriptionBillingPreview = (props) => {
  const {
    billingData,
    country,
    paymentMethod
  } = props
  const accordionRef = useRef()
  const { name: billingName, address1, address2, city, zipCode, vatNo } = billingData || {}
  const { label: countryName } = country || {}

  const closeAccordion = () => {
    // inner accordion
    if (accordionRef && accordionRef.current && accordionRef.current.handleOnClickToggle) {
      accordionRef.current.handleOnClickToggle()
    }
    // accordion
    if (accordionRef && accordionRef.current && accordionRef.current.toggle) {
      accordionRef.current.toggle()
    }
    handlers.appSubscriptionBillingFormIsUpdated(false)
  }

  return (
    <div className='ta-apps__subscription-billing__preview'>
      <Row>
        <Col>
          <Title theme='striped' label={t('billing.list.section.billingDetails.heading')} />
          <IconListItem noBorder flex>
            <IconListItemTitle width='120px' withBorder><strong>{t('global.name')}:</strong></IconListItemTitle>
            <IconListItemBody offset='130px' withBorder>{billingName}</IconListItemBody>
          </IconListItem>
          <IconListItem noBorder flex>
            <IconListItemTitle width='120px' withBorder><strong>{t('address.label')} 1:</strong></IconListItemTitle>
            <IconListItemBody offset='130px' withBorder>{address1}</IconListItemBody>
          </IconListItem>
          {address2 && (
            <IconListItem noBorder flex>
              <IconListItemTitle width='120px' withBorder><strong>{t('address.label')} 2:</strong></IconListItemTitle>
              <IconListItemBody offset='130px' withBorder>{address2}</IconListItemBody>
            </IconListItem>
          )}
          <IconListItem noBorder>
            <IconListItemTitle width='120px' withBorder><strong>{t('address.city')}:</strong></IconListItemTitle>
            <IconListItemBody offset='130px' withBorder>{city}</IconListItemBody>
          </IconListItem>
          <IconListItem noBorder>
            <IconListItemTitle width='120px' withBorder><strong>{t('global.country')}:</strong></IconListItemTitle>
            <IconListItemBody offset='130px' withBorder>{countryName}</IconListItemBody>
          </IconListItem>
          <IconListItem noBorder>
            <IconListItemTitle width='120px' withBorder><strong>{t('address.zip')}:</strong></IconListItemTitle>
            <IconListItemBody offset='130px' withBorder>{zipCode}</IconListItemBody>
          </IconListItem>
          {vatNo && (
            <IconListItem noBorder>
              <IconListItemTitle width='120px' withBorder><strong>{t('global.vatNumber')}:</strong></IconListItemTitle>
              <IconListItemBody offset='130px' withBorder>{vatNo}</IconListItemBody>
            </IconListItem>
          )}
        </Col>
      </Row>
      <FormSection>
        <BillingPreviewMethod item={{ ...paymentMethod, isDefault: false }} />
      </FormSection>
      <FormSection>
        <InnerAccordion
          ref={accordionRef}
          noArrowIcon
          isOpen={false}
          title={
            <Button
              borderSize='m'
              isBlock
              isGhost
              isTertiary
            >
              {t('buttons.billingDetails.update')}
            </Button>
          }
          className='ta-apps__subscription__inner-accordion'
        >
          <AppsSubscriptionBillingFormWrapper type='edit' closeAccordion={closeAccordion} />
        </InnerAccordion>
      </FormSection>
    </div>
  )
}

export default AppsSubscriptionBillingPreview
