import { globalActions, payloads$, handlers, actions, store } from '../../../Store'
import { t } from '../../../Common'
import { q } from '../../API'
import {
  resourceCategoryFormValidate,
  resourceCategorySaveTransform,
  resourceCategoriesServerErrorsTransform
} from './utils'
import { resourceCategoryEnterpriseUpdated } from './subscriptions'
import { serverErrorsTransform } from '../../../Utils'

globalActions.populateEnterpriseResourceCategories = async forceFetch => {
  await globalActions.populateBranches()
  const state = store.getState()
  let { branches, resources } = state
  branches = branches || []
  let { list: branchesList } = branches || {}
  branchesList = branchesList || []
  let { categoriesList } = resources || {}
  categoriesList = categoriesList || []
  if (categoriesList.length > 0 && !forceFetch) return categoriesList // already fetched
  let categories = await q('getEnterpriseResourceCategories')
  const { error } = categories || {}
  if (error) return
  categories = categories || []
  handlers.resourceCategoriesPopulate({ categories })
  handlers.resourceFilterFormGet({ categories, branchesList })
}

// Subscription
payloads$(actions.RESOURCES_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'resourceCategoryEnterpriseUpdated') {
      resourceCategoryEnterpriseUpdated({ name, id, data, ts })
    }
  })

payloads$(actions.RESOURCE_CATEGORY_GET)
  .subscribe(async id => {
    if (!id) return handlers.navigateToPath('/management/resource-categories')
    const category = await q('getEnterpriseResourceCategory', { id })
    const { error } = category || {}
    if (error) return
    handlers.resourceCategoryLocallyChangedGet(category)
    handlers.resourceCategoryPopulate({ category })
  })

// Category form
payloads$(actions.RESOURCE_CATEGORY_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    const { branches, account } = state
    let { list: branchesList, areFetched } = branches || {}
    branchesList = branchesList || []
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    const category = await q('getEnterpriseResourceCategory', { id })
    const { error } = category || {}
    if (error) return
    handlers.resourceCategoryFormPopulate({
      category: {
        ...category,
        defaultCategoryName: t('resources.list.sectionDefault.title')
      },
      branches: branchesList,
      account
    })
  })

const setResourceCategoryErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('resourceCategory', errors)
  handlers.resourceCategoryFormReady()
  scrollToError && scrollToError(errors)
}

// Category save
payloads$(actions.RESOURCE_CATEGORY_FORM_SAVE)
  .subscribe(async ({ category, scrollToError }) => {
    const state = store.getState()
    const { resources: resorucesInitial } = state
    let { categoriesList: categories } = resorucesInitial || {}
    categories = categories || []
    const categoryExternalIds = categories.map(category => category.externalId).filter(Boolean) || []
    const errors = resourceCategoryFormValidate(category, categoryExternalIds)
    if (errors.length) return setResourceCategoryErrors(errors, scrollToError)

    const savedCategory = await q('saveEnterpriseResourceCategory', resourceCategorySaveTransform(category))
    const { error } = savedCategory || {}
    if (error) return setResourceCategoryErrors(resourceCategoriesServerErrorsTransform(error), scrollToError)

    handlers.resourceCategoryUpdate(savedCategory)
    const { resources, branches } = store.getState()
    const { categoriesList } = resources || {}
    const { list: branchesList } = branches || {}
    handlers.resourceFilterFormGet({ categories: categoriesList, branchesList })
    handlers.navigateToPath('/management/resource-categories')
  })

// Category delete
payloads$(actions.RESOURCE_CATEGORY_DELETE)
  .subscribe(async id => {
    if (!id) return
    const response = await q('deleteEnterpriseResourceCategory', { id }) || { error: { text: 'errors.api.unavailable' } }
    const { error } = response
    if (error) return setCategoryErrors(id, serverErrorsTransform(error))
    handlers.resourceCategoryDeleted(id)
    setTimeout(() => {
      handlers.resourceCategoryRemoveDeleted(id)
      const { resources, branches } = store.getState()
      const { categoriesList } = resources || {}
      const { list: branchesList } = branches || {}
      handlers.resourceFilterFormGet({ categories: categoriesList, branchesList })
      handlers.navigateToPath('/management/resource-categories')
    }, 2000)
  })

const setCategoryErrors = (id, errors, scrollToError) => {
  handlers.formErrorsSet(`resourceCategory${id}`, errors)
  handlers.resourceCategoryDeleteFailed()
  scrollToError && scrollToError(errors)
  setTimeout(() => {
    handlers.formErrorsSet(`resourceCategory${id}`, [])
  }, 3500)
}

// Category list to delete
payloads$(actions.RESOURCE_CATEGORIES_TO_DELETE_GET)
  .subscribe(async () => {
    const resourceCategoriesToDelete = await q('getEnterpriseResourceCategories', {
      filter: {
        isGloballyDeleted: true,
        onlyLocals: true
      }
    })
    const { error } = resourceCategoriesToDelete || {}
    if (error) {
      handlers.resourceCategoriesToDeleteReady()
      return handlers.navigateToPath('/management/resource-categories')
    }
    handlers.resourceCategoriesToDeletePopulate(resourceCategoriesToDelete)
  })

payloads$(actions.RESOURCE_CATEGORY_TO_DELETE_DELETE)
  .subscribe(async ({ companyId, region, externalId, id }) => {
    if (!id) return
    const resourceCategory = await q('deleteBranchResourceCategory', { companyId, region, externalId, id })
    const { error } = resourceCategory || {}
    if (error) return handlers.navigateToPath('/management/resource-categories')
    handlers.resourceCategoriesToDeleteReady(id)
  })

// Category locally changed
payloads$(actions.RESOURCE_CATEGORY_LOCALLY_CHANGED_GET)
  .subscribe(async (category) => {
    let { locallyUpdatedBranches, id: categoryId } = category || {}
    if (!categoryId) return handlers.navigateToPath('/management/resource-categories')
    let { branches } = store.getState()
    branches = branches || {}
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    locallyUpdatedBranches = locallyUpdatedBranches || {}
    const locallyUpdatedBranchesArr = Object.keys(locallyUpdatedBranches || {}) || []
    const locallyChangedData = locallyUpdatedBranchesArr.map(item => ({ id: categoryId, branch: branchesList.find(branch => branch.id === item) }))
    handlers.resourceCategoryLocallyChangedPopulate({ id: categoryId, locallyChangedData })
  })

payloads$(actions.RESOURCE_CATEGORY_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const resourceCategory = await q('resetGlobalBranchResourceCategory', { companyId, region, internalId })
    const { error } = resourceCategory || {}
    if (error || !resourceCategory) return handlers.navigateToPath('/management/resource-categories')
    handlers.resourceCategoryLocallyChangedResetReady({ resourceCategory, companyId })
  })
