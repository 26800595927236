const initialState = {
  pendingForm: true,
  account: null,
  service: null,
  secretKey: null,
  recoveryCodes: null,
  pending2FASetup: false
}

const reducer = { initialState }

reducer.handlers = (dispatch, actions, handlers) => ({
  // 2FA Form
  twoFAFormPopulate: () => {
    handlers.formSet('twoFA', { code: '' })
    handlers.twoFAFormReady()
  },
  twoFADataPopulate: (data) => {
    dispatch(actions.TWO_FA_DATA_POPULATE, { data })
    dispatch(actions.TWO_FA_FORM_READY)
  },
  twoFADataClear: () => dispatch(actions.TWO_FA_DATA_CLEAR),
  twoFAFormReady: () => dispatch(actions.TWO_FA_FORM_READY),
  twoFAInit: (authType) => dispatch(actions.TWO_FA_INIT, { authType }),
  twoFAUserSetup: (data, cb, isWithCallback) => dispatch(actions.TWO_FA_USER_SETUP, { data, cb, isWithCallback }),
  twoFARecoveryCodesPopulate: ({ recoveryCodes }) => dispatch(actions.TWO_FA_RECOVERY_CODES_POPULATE, { recoveryCodes }),
  twoFARecoveryCodesClear: () => dispatch(actions.TWO_FA_RECOVERY_CODES_CLEAR),
  twoFARefreshRecoveryCodes: (code, cb) => dispatch(actions.TWO_FA_REFRESH_RECOVERY_CODES, { code, cb }),
  twoFACancel: () => {
    dispatch(actions.USER_SECURITY_FORM_GET)
    dispatch(actions.TWO_FA_DATA_CLEAR)
  },
  twoFAPendingSetupSet: pending => dispatch(actions.TWO_FA_PENDING_SETUP_SET, { pending }),
  twoFAPendingFormSet: pending => dispatch(actions.TWO_FA_FORM_SET_PENDING, { pendingForm: pending })
})

reducer.TWO_FA_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.TWO_FA_INIT = state => ({
  ...state,
  pendingForm: true
})

reducer.TWO_FA_DATA_CLEAR = () => ({
  ...initialState
})

reducer.TWO_FA_DATA_POPULATE = (_, { data }) => ({
  ...data,
  pendingForm: true
})

reducer.TWO_FA_USER_SETUP = state => ({
  ...state,
  pendingForm: true,
  pending2FASetup: true
})

reducer.TWO_FA_PENDING_SETUP_SET = (state, { pending }) => ({
  ...state,
  pending2FASetup: pending
})

reducer.TWO_FA_RECOVERY_CODES_POPULATE = (state, { recoveryCodes }) => ({
  ...state,
  recoveryCodes
})

reducer.TWO_FA_REFRESH_RECOVERY_CODES = state => state

reducer.TWO_FA_RECOVERY_CODES_CLEAR = state => ({
  ...state,
  recoveryCodes: null
})

reducer.TWO_FA_FORM_SET_PENDING = (state, { pendingForm }) => ({
  ...state,
  pendingForm
})

export default reducer
