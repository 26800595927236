import React from 'react'
import { BranchesPreviewServiceFormRemindersWrapper } from '../../../../Beasts'

import './BranchesServiceForm.css'

const BranchesServiceFormAdvanced = props => {
  const { plan, allowedSet } = props

  return (
    plan === 'ENTERPRISE' && <BranchesPreviewServiceFormRemindersWrapper plan={plan} allowedSet={allowedSet} />
  )
}

export default BranchesServiceFormAdvanced
