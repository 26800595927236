import React from 'react'
import { ListButton, Avatar } from '../../../Common'

const UserPermissionTypeUserItem = (props) => {
  const { user } = props
  const { name, avatarUrl, color, id } = user || {}

  return (
    <div className='ta-permission-types__user-item-wrap'>
      <div className='ta-permission-types__user-item__body'>
        <Avatar
          name={name}
          image={avatarUrl}
          color={color}
          className='ta-permission-types__user-item__avatar'
        />
        <div className='ta-permission-types__user-item__content'>
          <div className='ta-permission-types__user-item__content__user-name'>{ name }</div>
        </div>
      </div>
      <div className='ta-permission-types__user-item__buttons'>
        <ListButton icon='angle-right' to={`/managers/users/${id}/preview`} />
      </div>
    </div>
  )
}

export default UserPermissionTypeUserItem
