import React from 'react'
import {
  FormSection,
  Title,
  Row,
  Col,
  // BorderedBox,
  // Radio,
  Checkbox,
  // Alert,
  // Button,
  t
} from '../../../Common'
// import { dangerousHTML } from '../../../Utils'

const CourseFormSettings = props => {
  // const {
  //   hasOnlinePayment,
  //   hasStripeConnected,
  //   customisations
  // } = props
  // const hasOnlinePaymentDisabled = !hasStripeConnected && !hasOnlinePayment
  // const { settings } = customisations || {}
  // const { hideCoursesFormOnlinePayment } = settings || {}

  return (
    <FormSection>
      <Title size='m' isCompact label={t('global.settings')} icon='cogs' />
      <Row>
        <Col>
          <Checkbox name='isActive' label={t('servicesGroups.form.group.section.settings.checkboxOnlineBooking.label')} />
        </Col>
      </Row>
      {/* {!hideCoursesFormOnlinePayment &&
        <>
          <Row>
            <Col>
              <Checkbox
                name='hasOnlinePayment'
                label={t('servicesGroups.form.group.section.settings.checkboxPayment.label')}
                disabled={hasOnlinePaymentDisabled}
              />
            </Col>
          </Row> */}
      {/* {hasOnlinePayment &&
            <BorderedBox>
              <Row>
                <Col>
                  <Radio name='isOnlinePaymentMandatory' value={false} label={t('payments.settings.isOptional')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Radio name='isOnlinePaymentMandatory' value={!!true} label={t('payments.settings.isMandatory')} />
                </Col>
              </Row>
            </BorderedBox>
          } */}
      {/* </>
      } */}
      {/* {!hasStripeConnected && !hasOnlinePayment && !hideCoursesFormOnlinePayment &&
        <Alert label={t('servicesGroups.form.group.section.settings.checkboxPayment.note.connectStripe.title')} theme='alert'>
          <p>{dangerousHTML(t('servicesGroups.form.group.section.settings.checkboxPayment.note.connectStripe.text'))}</p>
          <Button as='link' isPrimary isBlock to='/booking-setup/online-payment-methods'>
            {t('servicesGroups.form.group.section.settings.checkboxPayment.note.connectStripe.buttonLabel')}
          </Button>
        </Alert>
      }
      {!hasStripeConnected && hasOnlinePayment && !hideCoursesFormOnlinePayment &&
        <Alert label={t('servicesGroups.form.group.section.settings.checkboxPayment.note.activatedStripeDisconnected.title')} theme='alert'>
          <p>{dangerousHTML(t('servicesGroups.form.group.section.settings.checkboxPayment.note.activatedStripeDisconnected.text'))}</p>
          <Button as='link' isPrimary isBlock to='/booking-setup/online-payment-methods'>
            {t('servicesGroups.form.group.section.settings.checkboxPayment.note.activatedStripeDisconnected.buttonLabel')}
          </Button>
        </Alert>
      } */}
    </FormSection>
  )
}

export default CourseFormSettings
