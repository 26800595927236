import React from 'react'
import { AccordionBody } from '../../../Common'

const AccordionListBody = props => {
  const { children } = props

  return (
    <AccordionBody>
      <div className='ta-accordion-list__body'>
        {children}
      </div>
    </AccordionBody>
  )
}

export default AccordionListBody
