import React from 'react'
import { SingleSelect, t } from '../../Common'

const CustomersListFilterSort = props => {
  const options = [
    { value: 'name', label: t('global.name.label') },
    { value: 'createdAt', label: t('customers.fieldSortBy.createdDate') },
    { value: 'totalBookings', label: t('customers.fieldSortBy.mostBookings') }
  ]

  return (
    <div className='ta-customers__filter-sort'>
      <SingleSelect
        label={t('customers.fieldSortBy.label')}
        name='sort'
        options={options}
        icon='sort'
      />
    </div>
  )
}

export default CustomersListFilterSort
