import React from 'react'
import { ServicePreviewResource } from '../../../Beauties'

const ServicePreviewResources = props => {
  let { resources } = props
  resources = resources || []

  return (
    <div className='ta-services__preview__resources'>
      {resources && resources.length > 0 && resources.map(resource => (
        <ServicePreviewResource resource={resource} key={resource.id} />
      ))}
    </div>
  )
}

export default ServicePreviewResources
