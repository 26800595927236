import React from 'react'
import {
  FixedContent,
  FixedContentHeader,
  FixedContentBody,
  Title,
  Button,
  FontAwesome5,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../Common'
import { PopupTriggerWrapper, CustomerFieldsToDeleteWrapper } from '../../Beasts'
import { CustomerFieldsActive } from '../../Beauties'
import './CustomerFields.css'

const CustomerFields = props => {
  const {
    categories,
    onCategoriesToggle,
    isDragAndDropMode,
    areExpanded,
    onDragStart,
    onDragUpdate,
    onDragEnd,
    hash,
    customerFieldsWrite
  } = props
  const availableTabs = ['activeDataFields', 'deletedDataFields']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeDataFields'
  const listClassNames = ['ta-customer-fields__list']

  return (
    <div className='ta-customer-fields'>
      <FixedContent>
        <FixedContentHeader>
          {(customerFieldsWrite && isDragAndDropMode &&
            <Title icon='user-friends' iconTheme='grey' label={t('customerFields.heading')}>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {(areExpanded
                    ? t('global.switchCategoryOrder')
                    : t('customerFields.list.buttonHeading.switchFieldOrderTooltip')
                  )}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' isSecondary action={onCategoriesToggle} icon={areExpanded ? 'compress-alt' : 'expand-alt'} />
                </HoverPopupTrigger>
              </HoverPopup>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.exitOrdering')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/customers/customer-fields' isPrimary>
                    <FontAwesome5 icon='times' />
                  </Button>
                </HoverPopupTrigger>
              </HoverPopup>
            </Title>
          )}
          {(!isDragAndDropMode &&
            <Title icon='user-friends' iconTheme='grey' label={t('customerFields.heading')}>
              <PopupTriggerWrapper name='customer-fields-help-box' position='bottom' extraClassName='ta-help-box'>
                <Button as='link' className='ta-title__link' external>
                  {t('global.help')}
                  <FontAwesome5 icon='lightbulb' />
                </Button>
              </PopupTriggerWrapper>
              {(customerFieldsWrite &&
                <>
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {t('global.category.add')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Button className='customer-fields-folder-icon' as='link' to='/customers/customer-fields/categories/add' isPrimary icon='folder' iconType='solid' />
                    </HoverPopupTrigger>
                  </HoverPopup>
                  {/* Uncomment if there will be ordering */}
                  {/* <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('customerFields.list.buttonHeading.fieldOrderTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button as='link' to='/customers/customer-fields/reorder' isSecondary icon='arrows-v' />
                      </HoverPopupTrigger>
                    </HoverPopup> */}
                </>
              )}
            </Title>
          )}
        </FixedContentHeader>
        <FixedContentBody>
          <div className={listClassNames.join(' ')}>
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='activeDataFields' label={t('customerFields.activeFields')} icon='sliders-h' />
                <SimpleTabsHeaderItem name='deletedDataFields' label={t('customerFields.markedForDelete')} icon='minus-octagon' />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='activeDataFields'>
                  <CustomerFieldsActive
                    categories={categories}
                    isDragAndDropMode={isDragAndDropMode}
                    areExpanded={areExpanded}
                    onDragStart={onDragStart}
                    onDragUpdate={onDragUpdate}
                    onDragEnd={onDragEnd}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem renderOnlyIfActive name='deletedDataFields'>
                  <CustomerFieldsToDeleteWrapper />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomerFields
