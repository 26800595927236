import React from 'react'
import {
  ChartLine,
  ChartBar,
  ChartPie,
  ChartArea,
  ChartComposed,
  Spinner,
  Button,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  FontAwesome5,
  t
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { dangerousHTML } from '../../Utils'

import './Chart.css'

const Chart = props => {
  const {
    label,
    labels,
    headerLabel,
    info,
    type,
    settingsButton,
    labelButton,
    buttons,
    children,
    bigHeader,
    isPending,
    tagName,
    tagColor,
    statisticId,
    data,
    infoPopup,
    extraHeaderText,
    labelsHoverClass,
    filterType,
    ...rest
  } = props
  const chartHeaderClassNames = ['ta-chart__header']
  if (bigHeader) chartHeaderClassNames.push('big')
  const hoverLabelClasses = ['ta-statistics__label']
  if (labelsHoverClass) hoverLabelClasses.push(labelsHoverClass)

  return (
    <div className='ta-chart'>
      {label &&
        <div className={chartHeaderClassNames.join(' ')}>
          <div className='ta-chart__header-label'>
            {label && <p className={`ta-chart__header-label-text ${headerLabel ? '' : 'no-extra-text'}`}>{label}
              {extraHeaderText &&
                <span className='ta-chart__header-info-text'> {extraHeaderText}</span>
              }
            </p>}
            {(headerLabel || tagName || (labels || []).length > 0) &&
              <div className='ta-chart-header-label__info-container'>
                {(tagName || headerLabel) &&
                  <div className='ta-chart__header-extra--text'>
                    {headerLabel && <div className='ta-chart__header-label-third'>{headerLabel}</div>}
                    {tagName &&
                      <div className='ta-chart__header-label-tag' style={{ background: tagColor }}>{tagName || 'all'}
                      </div>
                    }
                  </div>
                }
                {labels && labels.map((item, i) => {
                  const { icon, position, text, color } = item
                  return (
                    <React.Fragment key={i}>
                      <HoverPopup className={hoverLabelClasses.join(' ')}>
                        <HoverPopupContent position={position}>
                          {text}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <div className='ta-statistics-box__label' style={{ background: color }}>
                            <FontAwesome5 icon={icon} type='s' />
                          </div>
                        </HoverPopupTrigger>
                      </HoverPopup>
                      {i !== labels.length - 1 &&
                        <p className='ta-statistics__sign'>+</p>
                      }
                    </React.Fragment>
                  )
                })}
              </div>
            }
          </div>
          {settingsButton && <div className='ta-chart__header-settings-button'>{settingsButton}</div>}
          {info && <div className='ta-chart__header-info'>{info}</div>}
          {buttons && buttons}
          {settingsButton &&
            <PopupTriggerWrapper
              name={filterType || 'dashboard-statistics-settings'}
              position='left'
              extraClassName={`ta-statistics-box ${filterType && 'small'}`}
              id={{ statisticId }}
            >
              <Button as='link' className='ta-dashboard-statistics__link' external>
                <FontAwesome5 icon='filter' type='s' onClick={(e) => e.target.classList.add('active')} />
              </Button>
            </PopupTriggerWrapper>
          }
        </div>
      }
      {
        isPending
          ? <Spinner />
          : data.length
            ? <div className='ta-chart__canvas'>
              {type === 'line' && <ChartLine {...rest} data={data} />}
              {type === 'bar' && <ChartBar {...rest} data={data} />}
              {type === 'pie' && <ChartPie {...rest} data={data} />}
              {type === 'composed' && <ChartComposed {...rest} data={data} />}
              {type === 'area' && <ChartArea {...rest} data={data} />}
              {type === 'custom' && children}
            </div>
            : <div className='ta-chart__canvas'>
              <div className='ta-statistic-box__title__no-results'>
                <FontAwesome5 icon='sad-tear' /> <span className='ta-statistic-box__title__no-results--text'>{t('statistics.notEnoughData')}</span>
                <p>{t('statistics.needMoreInfo')}</p>
              </div>
            </div>
      }
      {
        infoPopup &&
        <HoverPopup className='ta-statistics__info-popup'>
          <HoverPopupContent position={infoPopup.position}>
            {dangerousHTML(infoPopup.text)}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className='ta-statistics-box__popup-icon'>
              <FontAwesome5 icon='info' type='solid' />
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
      }
    </div >
  )
}

export default Chart
