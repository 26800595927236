import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Input,
  Radio,
  Row,
  Col,
  HintText,
  Error,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  MultipleSelect,
  t
} from '../../../Common'

import './ResourceCategoryForm.css'

const ResourceCategoryForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    disableRadioBookable,
    disableRadioDependency,
    showExternalIds,
    isDefault,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  disableRadioBookable = !!disableRadioBookable
  disableRadioDependency = !!disableRadioDependency
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-resources__category-form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='resourceCategory'>
            {(showExternalIds &&
              <FormSection>
                <Title size='m' isCompact label={t('global.externalId')} icon='fingerprint' ignoreTopOffset />
                <Input
                  name='externalId'
                  label={t('global.externalId')}
                  hintText={t('global.externalId.hint')}
                />
              </FormSection>
            )}
            <FormSection>
              <Title size='m' isCompact label={t('global.name')} icon='sliders-h' />
              <Input
                name='name'
                label={t('resources.edit.category.fieldName.label')}
                hintText={t('resources.edit.category.fieldName.hint')}
                mandatory
                limit={50}
              />
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <HoverPopup disabled={!disableRadioBookable}>
                    <HoverPopupContent position='bottom'>
                      {t('resources.edit.category.tooltip.text')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Radio name='isDependency' value='bookable' disabled={disableRadioBookable}>
                        <div className='ta-resources__category-type'>{t('resources.list.section.tagBookable')}</div>
                        <div>{t('resources.edit.category.checkboxBookable.description')}</div>
                      </Radio>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>

              <HoverPopup disabled={!disableRadioDependency}>
                <HoverPopupContent position='bottom'>
                  {t('resources.edit.category.tooltip.text')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Radio name='isDependency' value='dependency' disabled={disableRadioDependency}>
                    <div className='ta-resources__category-type dependency'>{t('resources.list.section.tagDependency')}</div>
                    <div>{t('resources.edit.category.checkboxDependency.description')}</div>
                    <HintText>{t('resources.edit.category.checkboxDependency.hint')}</HintText>
                  </Radio>
                </HoverPopupTrigger>
              </HoverPopup>
              <FormSection>
                <Title size='m' isCompact label={t('global.location')} icon='building' />
                <HoverPopup disabled={!isDefault}>
                  <HoverPopupContent position='top' autoSize>
                    {t('branches.form.tooltip.defaultCategory.cannotEditLocations')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <MultipleSelect
                      name='location'
                      label={t('global.select.label')}
                      searchable
                      disableSelected={type === 'edit'}
                      hasAll={hasAll}
                      allLabel={t('global.allLocations')}
                    />
                  </HoverPopupTrigger>
                </HoverPopup>
              </FormSection>
              <Row>
                <Col>
                  <Error name='globalErrors' />
                  <Error name='globalResourceCategories' shouldNotTranslate />
                </Col>
              </Row>
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default ResourceCategoryForm
