import React, { useEffect } from 'react'
import { CompanyTagsToDelete } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'

const CompanyTagsToDeleteWrapper = props => {
  let { tagsToDelete, branches, pending, account } = props
  tagsToDelete = tagsToDelete || []
  branches = branches || []
  pending = !!pending
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  isAdmin = !!isAdmin
  enterprisePermissions = enterprisePermissions || {}
  let { globalTagsRead, globalTagsWrite } = enterprisePermissions || {}
  globalTagsRead = !!isAdmin || !!globalTagsRead
  globalTagsWrite = !!isAdmin || !!globalTagsWrite
  useEffect(() => {
    if (!globalTagsRead) handlers.navigateToPath('/customers/company-tags')
  }, [globalTagsRead])
  const onDeleteGlobalEntity = (companyId, region, externalId, id) => handlers.companyTagToDeleteDelete({ companyId, region, externalId, id })
  const listToDelete = tagsToDelete.map(item => ({
    tag: { ...item },
    branch: branches.find(branch => branch.id === item.companyId)
  }))

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CompanyTagsToDelete
          tagsToDelete={listToDelete}
          onDeleteGlobalEntity={onDeleteGlobalEntity}
          globalTagsWrite={globalTagsWrite}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  tagsToDelete: state.companyTags.listToDelete,
  pending: state.companyTags.pendingToDeleteList,
  account: state.account
})

export default connect(maps)(CompanyTagsToDeleteWrapper)
