import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5, t } from '../../Common'
import { AccountsItemMap } from '../../Beauties'

const AccountsItem = props => {
  const { account, onAccountChange, onResendEmailClick, selected } = props
  const classNames = ['ta-accounts__item']
  if (!account.isConfirmed) classNames.push('is-pending')
  if (!account.address || (account.address && !account.address.formatted)) classNames.push('no-address')
  if (selected) classNames.push('selected')

  return (
    <div className={classNames.join(' ')} onClick={() => account.isConfirmed && onAccountChange && onAccountChange(account.id)}>
      <div className='ta-accounts__item__map'>
        <AccountsItemMap
          name={account.name}
          address={account.address && account.address.formatted}
        />
      </div>
      <div className='ta-accounts__item__name'>{account.name}</div>
      <div className='ta-accounts__item__address'>{account.address && account.address.formatted}</div>
      {(account.isOwner &&
        <div className='ta-accounts__item__label owner' title={t('account.list.item.title.owner')}>
          <FontAwesome5 icon='suitcase' type='solid' />
        </div>
      )}
      {(!account.isOwner && account.isAdmin &&
        <div className='ta-accounts__item__label admin' title={t('account.list.item.title.owner')}>
          <FontAwesome5 icon='user-shield' type='solid' />
        </div>
      )}
      {(!account.isOwner && account.isCustom &&
        <div className='ta-accounts__item__label custom' title={t('account.list.item.title.custom')}>
          <FontAwesome5 icon='cogs' type='solid' />
        </div>
      )}
      {(!account.isConfirmationEmailReSent &&
        <div className='ta-accounts__item__invitation__cta' onClick={() => onResendEmailClick({ accountId: account.id, email: account.email })}>
          <FontAwesome5 icon='envelope' />
          {t('global.resendEmail')}
        </div>
      )}
    </div>
  )
}

AccountsItem.propTypes = {
  account: PropTypes.object.isRequired
}

export default AccountsItem
