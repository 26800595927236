import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { PermissionTypeAdd } from '../../Beauties'

const PermissionTypeEditWrapper = props => {
  let {
    id,
    pending,
    form,
    detail,
    showExternalIds,
    companyCustomisations,
    isAdmin,
    branchesAccessBlacklist
  } = props
  pending = !!pending
  form = form || {}
  detail = detail || {}
  const { name } = detail || {}
  showExternalIds = !!showExternalIds
  const customisations = companyCustomisations || {}
  const { settings } = customisations || {}
  const { readOnlyExternalIds } = settings || {}

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    if (detail.id) {
      handlers.permissionTypeFormGet(detail)
    }
  }, [detail])

  const onSubmit = cb => {
    handlers.permissionTypeFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <PermissionTypeAdd
          onSubmit={onSubmit}
          cancelLink={`/management/permission-types/${id}`}
          title={name}
          readOnlyExternalIds={readOnlyExternalIds}
          showExternalIds={showExternalIds}
          isAdmin={isAdmin}
          type={'edit'}
          branchesAccessBlacklist={branchesAccessBlacklist}
          location={form.location && form.location.values}
        />
      )}
    </>
  )
}

PermissionTypeEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  detail: PropTypes.object,
  form: PropTypes.object.isRequired
}

const maps = state => ({
  id: state.router.data.id,
  pending: state.permissionTypes.pendingForm,
  form: (state.forms && state.forms.permissionType) || {},
  detail: ((state.permissionTypes && state.permissionTypes.list) || []).find(permissionType => permissionType.id === state.router.data.id),
  showExternalIds: state.company.settings && state.company.settings.showExternalIds,
  companyCustomisations: state.company.customisations,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default memo(connect(maps)(PermissionTypeEditWrapper))
