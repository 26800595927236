import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Button,
  Blur,
  t
} from '../../../Common'
import { GlobalsSettingsList } from '../../../Beauties'

import './GlobalsSettings.css'

const GlobalsSettings = props => {
  const {
    hash,
    settings,
    settingsRead,
    settingsWrite
  } = props
  const availableTabs = ['settings']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]

  return (
    <div className='ta-globals-settings'>
      <FixedContent>
        <FixedContentHeader>
          <Title icon='cogs' iconTheme='grey' label={t('global.globalSettings')}>
            {(settingsWrite &&
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('globalSettings.preview.edit')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/settings/globals/edit' isPrimary icon='pencil' />
                </HoverPopupTrigger>
              </HoverPopup>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={settingsRead ? 'inactive' : ''} active={!settingsRead} icon='ban' label={t('global.accessDenied')}>
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='settings' label={t('globalSettings.form.tab.localUpdates')} />
              </SimpleTabsHeader>
              <SimpleTabsBody className={!settingsRead ? 'static' : ''}>
                <SimpleTabsBodyItem name='settings'>
                  <GlobalsSettingsList {...settings} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default GlobalsSettings
