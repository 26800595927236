import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  SingleSelect,
  Checkbox,
  t,
  Row,
  Col
} from '../../../Common'

const UserGroupsForm = props => {
  const {
    title,
    onSubmit,
    cancelLink
  } = props

  return (
    <div className='ta-users-form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-user-group__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='users'>
            <Title size='m' label={t('userGroups.form.section.details.title')} icon='users' />
            <SingleSelect label={t('global.userGroup.label')} form='userGroups' />

            <Title size='m' label={t('userGroups.form.section.permissions.title')} icon='users-cog' />
            <Row>
              <Col>
                <Checkbox label={t('userGroups.form.section.permissions.enterpriseLogin')} name='isCheckEnterprisePermission' form='userGroups' />
              </Col>
            </Row>
            <FormSection>
              <Title size='m' label={t('global.branches')} />
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.branchAccess')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.branchChange')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Title size='m' label={t('global.tags')} />
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.tagsAccess')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.tagsChange')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Title size='m' label={t('global.users')} />
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.userAccess')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.userChange')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.userGroupAccess')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox label={t('userGroups.form.section.permissions.userGroupChange')} name='isCheckBranches' form='userGroups' />
                </Col>
              </Row>
            </FormSection>

            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

UserGroupsForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default UserGroupsForm
