import { translateServerCode } from '../../../Utils'

// Activity logs

export const activityLogsListTransform = (activityLogs) => {
  return activityLogs.map(item => activityLogTransform(item)).filter(item => !!item)
}

export const activityLogTransform = (item) => {
  if (!item) return
  let { data, type = '', operation = '' } = item || {}
  const parsedData = (data && JSON.parse(data)) || {}
  const changedFields = (item.changedFields && JSON.parse(item.changedFields)) || []
  const isGroup = type === 'EVENT' && (!!parsedData.maxParticipants || !!parsedData.allParticipants)
  if (type === 'EVENT_PARTICIPANT') type = 'EVENTPARTICIPANT'
  if (type === 'SYSTEM_ACTIONS') type = 'SYSTEMACTIONS'

  const result = {
    ...item,
    ...parsedData,
    id: item.id,
    bookingId: parsedData.id,
    customerId: parsedData.id,
    changedFields,
    typeTranslationKey: `notifications.list.${type.toLowerCase()}${operation.charAt(0)}${(operation.slice(1) || '').toLowerCase()}${isGroup ? 'Group' : ''}`
  }
  if (parsedData.id) result.redirectId = parsedData.id
  delete result.data
  return result
}

export const activityLogsListErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}
