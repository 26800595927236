import React from 'react'
import {
  FormSection,
  Title,
  PriceInput,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'

const BranchesServiceFormPrice = props => {
  const isPriceDisabled = !props.allowedSet?.includes('price')

  return (
    <FormSection>
      <Title size='m' label={t('global.price.label')} icon='money-bill' />
      <HoverPopup disabled={!isPriceDisabled} className='ta-service-form-inputs__popup'>
        <HoverPopupContent position='top'>
          {t('globalSettings.form.section.attributes.disabled')}
        </HoverPopupContent>
        <HoverPopupTrigger>
          <PriceInput
            name='price'
            label={t('global.price.label')}
            hintText={t('global.price.hint')}
            disabled={isPriceDisabled}
          />
        </HoverPopupTrigger>
      </HoverPopup>
    </FormSection>
  )
}

export default BranchesServiceFormPrice
