import React, { Component } from 'react'
import { WeekDay } from '../../Beauties'

class WeekDayWrapper extends Component {
  render () {
    const { day, prefix } = this.props

    return (
      <WeekDay day={day} prefix={prefix} />
    )
  }
}

export default WeekDayWrapper
