// Add here all fields from redux store that will be persisted on localstorage
export default () => state => ({
  auth: {
    tokens: state.auth.tokens
  },
  app: {
    locale: state.app.locale
  },
  navigation: {
    isCollapsed: state.navigation.isCollapsed
  },
  persist: state.persist
})
