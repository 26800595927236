import React from 'react'
import {
  Title,
  Button,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

import { PopupTriggerWrapper } from '../../Beasts'
import './Services.css'

const Services = props => {
  const {
    isDragAndDropMode,
    areExpanded,
    onCategoriesToggle,
    globalServicesWrite
  } = props

  return (
    <div>
      {(isDragAndDropMode &&
        <Title icon='suitcase' iconTheme='grey' label={areExpanded ? t('servicesGroups.list.serviceReorder.heading') : t('global.categoryOrder')}>
          {(globalServicesWrite &&
            <>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {(areExpanded
                    ? t('global.switchCategoryOrder')
                    : t('servicesGroups.list.buttonHeading.switchServiceOrderTooltip')
                  )}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' isSecondary icon={areExpanded ? 'compress-alt' : 'expand-alt'} action={onCategoriesToggle} />
                </HoverPopupTrigger>
              </HoverPopup>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('global.exitOrdering')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/management/services' isPrimary icon='times' />
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
      {(!isDragAndDropMode &&
        <Title icon='suitcase' iconTheme='grey' label={t('global.services')}>
          <PopupTriggerWrapper name='services-help-box' position='bottom' extraClassName='ta-help-box'>
            <Button as='link' className='ta-title__link' external>
              {t('global.help')} <FontAwesome5 icon='lightbulb' />
            </Button>
          </PopupTriggerWrapper>
          {(globalServicesWrite &&
            <>
              {/* <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.serviceOrderTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button as='link' to='/management/services/reorder' isSecondary icon='arrows-v' />
                </HoverPopupTrigger>
              </HoverPopup> */}
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('servicesGroups.list.buttonHeading.serviceCategoryTooltip')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button className='services-folder-icon' as='link' to='/management/services/categories/add' isPrimary icon='folder' iconType='solid' />
                </HoverPopupTrigger>
              </HoverPopup>
            </>
          )}
        </Title>
      )}
    </div>
  )
}

export default Services
