import React from 'react'
import {
  Form,
  FormButtons,
  FormSection,
  Title,
  Error,
  Row,
  Col,
  t,
  Checkbox,
  FontAwesome5,
  Button
} from '../../../Common'

import './CustomerForm.css'

const CustomerBookingsPrintForm = props => {
  const { onCloseModal, form, onClickCustomerBookingsPrint } = props
  const { customerPrintUpcomingBookings, customerPrintPastBookings } = form

  return (
    <div className='ta-customers__bookings-print'>
      <Form name='customers'>
        <FormSection className='ta-cleaner'>
          <Title label={t('customers.tabBookings.form.print.heading')} />
          <Row>
            <Col>
              <div className='ta-customer__bookings-print__text'>{t('customers.tabBookings.form.print.text')}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Checkbox name='customerPrintUpcomingBookings' label={t('customers.tabBookings.form.print.upcoming.label')} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Checkbox name='customerPrintPastBookings' label={t('customers.tabBookings.form.print.past.label')} />
            </Col>
          </Row>
        </FormSection>
        <FormSection>
          <Error name='globalErrors' noTopOffset />
        </FormSection>
        <FormSection>
          <FormButtons>
            <Button onClick={onCloseModal} isSecondary>{t('buttons.cancel.label')}</Button>
            <Button
              type='button'
              className='ta-btn ta-print-btn'
              isPrimary
              disabled={(!customerPrintUpcomingBookings.value && !customerPrintPastBookings.value)}
              onClick={onClickCustomerBookingsPrint}
            >
              <FontAwesome5 icon='print' />
              {` ${t('customers.tabBookings.form.print.button.label')}`}
            </Button>
          </FormButtons>
          <div className='ta-cleaner' />
        </FormSection>
      </Form>
    </div>
  )
}

export default CustomerBookingsPrintForm
