import React from 'react'
import {
  OverlayWrapper,
  RouterOverlayWrapper,
  SidebarsWrapper,
  ModalWrapper,
  PopupWrapper,
  NavigationWrapper,
  ContentWrapper,
  DebugOptionsWrapper,
  OverlayMessageWrapper,
  ToastrWrapper
} from '../../Beasts'
import { VersionIndicator } from '../../Beauties'
import { FormDiscardPopup } from '../../Common'

const AppAuth = props => {
  const { hasOverwriteCss } = props
  const classNames = ['ta-application']
  if (hasOverwriteCss) classNames.push('overwrite')

  return (
    <div className={classNames.join(' ')}>
      <NavigationWrapper />
      <SidebarsWrapper />
      <ModalWrapper />
      <OverlayWrapper />
      <ToastrWrapper />
      <RouterOverlayWrapper />
      <PopupWrapper />
      <ContentWrapper />
      <VersionIndicator />
      <OverlayMessageWrapper />
      <FormDiscardPopup />
      <DebugOptionsWrapper />
    </div>
  )
}

export default AppAuth
