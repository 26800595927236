import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import { SERVICE_DEFAULTS } from '../../../Settings'
import {
  BorderedBox,
  FontAwesome5,
  FormGroup,
  Select,
  Error,
  Row,
  Col,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Button
} from '../../../Common'
import './CourseForm.css'

const CourseRemindersSelect = props => {
  let { reminders, name, formName, isDisabled, disabledKey } = props
  reminders = reminders || []
  const remindersOptions = SERVICE_DEFAULTS.serviceReminder.map(item => ({
    value: item * 60,
    label: item < 24
      ? t('servicesGroups.reminder.form.select.hours.label', [{ key: 'HOURS', value: item }])
      : t('servicesGroups.reminder.form.select.days.label', [{ key: 'DAYS', value: item / 24 }])
  }))
  const deleteButtonClassNames = ['ta-courses__form__btn-delete']
  if (isDisabled) deleteButtonClassNames.push('ta-btn-disabled')
  const addButtonClassNames = ['ta-courses__form__btn-add']
  if (isDisabled) addButtonClassNames.push('ta-btn-disabled')

  const onChange = (value, index) => {
    if (!reminders[index] && typeof reminders[index] !== 'number') return
    reminders[index] = value || null
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...reminders]
      }
    })
  }

  const onDelete = index => {
    if (isDisabled) return
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...reminders.filter((item, key) => key !== index)]
      }
    })
  }

  const onAdd = () => {
    if (isDisabled) return
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...reminders, 0]
      }
    })
  }

  return (
    <HoverPopup disabled={!isDisabled}>
      <HoverPopupContent position='top'>
        {t(disabledKey || 'global.enterpriseOnly')}
      </HoverPopupContent>
      <HoverPopupTrigger>
        <BorderedBox>
          {reminders.map((item, index) => {
            const reminderClassNames = ['ta-courses__form__reminder']
            if (reminders.length > 1) reminderClassNames.push('has-delete')

            return (
              <Row key={index}>
                <Col>
                  <div key={index} className='ta-courses__form__reminder-container'>
                    <FormGroup
                      className={reminderClassNames.join(' ')}
                      filled={!!item}
                      labelText={t('servicesGroups.reminder.form.select.label')}
                      labelMandatory
                      disabled={isDisabled}
                    >
                      <Select
                        className='ta-single-select'
                        noResultsText={t('global.noResults')}
                        value={item}
                        onChange={(selectedValue) => onChange(selectedValue.value, index)}
                        options={remindersOptions.filter(option => !reminders.includes(option.value) || option.value === item)}
                        disabled={isDisabled}
                      />
                    </FormGroup>
                    {(reminders.length > 1 &&
                      <Button icon='trash' iconType='regular' isSecondary className={deleteButtonClassNames.join(' ')} onClick={() => onDelete(index)} />
                    )}
                  </div>
                </Col>
              </Row>
            )
          })}
          {(reminders.length < 3 &&
            <Button iconType='regular' icon='plus' isTertiary isGhost isBlock className={addButtonClassNames.join(' ')} onClick={onAdd}>
              {t('servicesGroups.reminder.form.button.addReminder')}
            </Button>
          )}
          <Error name={name} />
        </BorderedBox>
      </HoverPopupTrigger>
    </HoverPopup>
  )
}

CourseRemindersSelect.propTypes = {
  name: PropTypes.string,
  reminders: PropTypes.array
}

export default CourseRemindersSelect
