import React from 'react'

import {
  Form,
  Row,
  Col,
  Checkbox,
  BorderedBox,
  PriceInput,
  Error,
  Loader,
  t
} from '../../../Common'
import { AppsSubscriptionFormButtons } from '../../../Beauties'

import './AppsSubscription.css'

const AppsSubscriptionForm = (props) => {
  const {
    type,
    onSubmit,
    hasThreshold,
    unitDescription,
    hasBillingDetails,
    hasPaymentMethod,
    noAutoCollect,
    pending,
    renderExtraContent,
    currentLimit,
    limitValue,
    extraConsumedCharges,
    hasChangesBillingForm,
    globalErrors,
    currencyCode
  } = props

  return (
    <>
      <Form
        className='ta-apps__subscription-form'
        name='appSubscription'
      >
        <Loader active={hasBillingDetails && pending} />
        <Row>
          <Col>
            <Checkbox name='hasThreshold' theme='switch' label={t('apps.subscriptions.form.hasThreshold.label')} />
            <div className='ta-apps__subscription-form__hint'>
              {t('apps.subscriptions.form.hasThreshold.hint', [{ key: 'NAME', value: unitDescription }])}
            </div>
          </Col>
        </Row>
        {hasThreshold && (
          <BorderedBox>
            <PriceInput
              currency={currencyCode}
              name='threshold'
              label={t('apps.subscriptions.form.threshold.label')}
            />
          </BorderedBox>
        )}
        {(globalErrors &&
          <Row>
            <Col>
              <Error name='globalErrors' />
            </Col>
          </Row>
        )}
      </Form>
      {renderExtraContent && renderExtraContent()}
      <AppsSubscriptionFormButtons
        type={type}
        currencyCode={currencyCode}
        hasBillingDetails={hasBillingDetails}
        hasPaymentMethod={hasPaymentMethod}
        noAutoCollect={noAutoCollect}
        onSubmit={onSubmit}
        showMessage={hasThreshold && currentLimit < extraConsumedCharges}
        limitValue={limitValue}
        extraConsumedCharges={extraConsumedCharges}
        hasChangesBillingForm={hasChangesBillingForm}
      />
    </>
  )
}

export default AppsSubscriptionForm
