import { createSelector } from '../../../Utils'
import { selectors } from '../../../Store'

export const staticDataSelector = state => state.staticData

export const countriesListSelector = createSelector(
  staticDataSelector,
  staticData => (staticData || {}).countries
)

export const timezonesListSelector = createSelector(
  staticDataSelector,
  staticData => (staticData || {}).timezones
)

export const countrySelector = createSelector(
  state => selectors.companyLocaleSelector(state),
  countriesListSelector,
  (locale, countries) => {
    locale = locale || 'en-gb'
    countries = countries || []
    const countryCode = locale.split('-')[1]
    return countries.find(item => item.code.toLowerCase() === countryCode)
  }
)
