import React from 'react'
import {
  Button,
  Form,
  Title,
  Row,
  Col,
  Loader,
  FormText,
  t,
  Error,
  Radio,
  Alert
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAppsPaidInstall = props => {
  const {
    onSubmit,
    onCancel,
    pending,
    name,
    subscriptionScope,
    trialUnits,
    unitDescriptionPlural
  } = props

  return (
    <div className='ta-popup__apps-paid-install'>
      <Loader active={pending} />
      <Title
        size='m'
        label={dangerousHTML(t('apps.subscription.install.popup.title'))}
        icon='money-bill'
        ignoreTopOffset
      />
      <Form name='popup' onSubmit={onSubmit}>
        <Row>
          <Col>
            <FormText noOffset>
              {dangerousHTML(t('apps.subscription.install.popup.text', [{ key: 'APP_NAME', value: name }]))}
            </FormText>
          </Col>
        </Row>
        <Row>
          <Col>
            <Radio
              name='subscriptionScope'
              label={t('apps.subscription.install.popup.form.radio.global')}
              value='global'
              hideError
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Radio
              name='subscriptionScope'
              label={t('apps.subscription.install.popup.form.radio.local')}
              value='local'
              hideError
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Alert theme='alert'>
              {subscriptionScope === 'local' && (
                dangerousHTML(t('apps.subscription.install.popup.form.message.local', [{ key: 'COUNT', value: `${trialUnits} ${unitDescriptionPlural}` }]))
              )}
              {subscriptionScope === 'global' && (
                dangerousHTML(t('apps.subscription.install.popup.form.message.global', [{ key: 'COUNT', value: `${trialUnits} ${unitDescriptionPlural}` }]))
              )}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <Error name='globalErrors' />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Button as='link' isSecondary onClick={onCancel}>
            {t('global.cancel')}
          </Button>
          <Button type='submit' isPrimary>
            {t('global.install')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default PopupAppsPaidInstall
