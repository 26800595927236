import {
  swapCategoriesInDnDList,
  sortByOrderIndex,
  swapItemsInDnDList
} from '../../../Utils'
import {
  customerFieldsTransform,
  customerFieldFormTransform,
  customerFieldDeleteFormTransform,
  customerFieldTransform,
  customerFieldCategoryFormTransform,
  customerFieldCategorySaveTransform,
  customerFieldsFiltersPopulate
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  locallyChangedList: [],
  pendingList: true,
  pendingListCategory: false,
  pendingForm: true,
  pendingPreview: true,
  pendingCategory: null,
  pendingCategoryPreview: false,
  pendingCategoryForm: true,
  pendingLocallyChangedPreview: false,
  selected: null,
  dragging: {},
  areFetched: false,
  messageList: null,
  messagePreview: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  customerFieldsReset: () => dispatch(actions.CUSTOMER_FIELDS_RESET),

  // Subscription
  customerFieldsSubscriptionSet: ({ name, id, data, ts, ids }) => dispatch(actions.CUSTOMER_FIELDS_SUBSCRIPTION_SET, { name, id, data, ts, ids }),

  // List
  customerFieldsGet: () => dispatch(actions.CUSTOMER_FIELDS_GET),
  customerFieldsPopulate: ({ customerFields, customerFieldsCategories }) => dispatch(actions.CUSTOMER_FIELDS_POPULATE, customerFieldsTransform({ customerFields, customerFieldsCategories })),
  customerFieldsFiltersPopulate: branches => {
    dispatch(actions.CUSTOMER_FIELDS_FILTERS_POPULATE)
    handlers.formSet('customerFieldsFilters', customerFieldsFiltersPopulate(branches))
  },
  customerFieldsListReady: () => dispatch(actions.CUSTOMER_FIELDS_LIST_READY),
  customerFieldsListOrderChange: ({ id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => dispatch(actions.CUSTOMER_FIELDS_LIST_ORDER_CHANGE, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }),
  customerFieldsListOrderChanged: (updatedCustomerFields) => dispatch(actions.CUSTOMER_FIELDS_LIST_ORDER_CHANGED, updatedCustomerFields),
  customerFieldsListMessageSet: message => dispatch(actions.CUSTOMER_FIELDS_LIST_MESSAGE_SET, message),
  customerFieldsUpdated: (ids) => dispatch(actions.CUSTOMER_FIELDS_UPDATED, ids),

  // One
  customerFieldUpdate: customerField => dispatch(actions.CUSTOMER_FIELD_UPDATE, customerFieldTransform(customerField)),
  customerFieldUpdated: customerField => dispatch(actions.CUSTOMER_FIELD_UPDATED, customerField),
  customerFieldDelete: id => dispatch(actions.CUSTOMER_FIELD_DELETE, id),
  customerFieldDeleted: id => dispatch(actions.CUSTOMER_FIELD_DELETED, id),
  customerFieldRemoveDeleted: id => dispatch(actions.CUSTOMER_FIELD_REMOVE_DELETED, id),
  customerFieldsToDeleteGet: () => dispatch(actions.CUSTOMER_FIELDS_TO_DELETE_GET),
  customerFieldsToDeletePopulate: ({ customerFieldsToDelete }) => dispatch(actions.CUSTOMER_FIELDS_TO_DELETE_POPULATE, { customerFieldsToDelete }),
  customerFieldToDeleteDelete: ({ companyId, region, externalId, id }) => dispatch(actions.CUSTOMER_FIELD_TO_DELETE_DELETE, { companyId, region, externalId, id }),
  customerFieldsToDeleteDeleteReady: () => dispatch(actions.CUSTOMER_FIELDS_TO_DELETE_READY),

  // Preview
  customerFieldPreviewGet: ({ id, forceFetch }) => dispatch(actions.CUSTOMER_FIELD_PREVIEW_GET, { id, forceFetch }),
  customerFieldPreviewPopulate: id => dispatch(actions.CUSTOMER_FIELD_PREVIEW_POPULATE, id),
  customerFieldMessagePreviewSet: message => dispatch(actions.CUSTOMER_FIELD_MESSAGE_PREVIEW_SET, message),
  customerFieldLocallyChangedGet: ({ id, internalId }) => dispatch(actions.CUSTOMER_FIELD_LOCALLY_CHANGED_GET, { id, internalId }),
  customerFieldLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.CUSTOMER_FIELD_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  customerFieldLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.CUSTOMER_FIELD_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  customerFieldLocallyChangedResetReady: ({ customerField, companyId }) => dispatch(actions.CUSTOMER_FIELD_LOCALLY_CHANGED_RESET_READY, { customerField, companyId }),

  // Form
  customerFieldFormGet: id => dispatch(actions.CUSTOMER_FIELD_FORM_GET, id),
  customerFieldFormPopulate: ({ customerField, branches, account }) => {
    handlers.formSet('customerFields', customerFieldFormTransform({ customerField, branches, account }))
    handlers.customerFieldFormReady()
  },
  customerFieldFormReady: () => dispatch(actions.CUSTOMER_FIELD_FORM_READY),
  customerFieldFormSave: (customerField, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELD_FORM_SAVE, { customerField, scrollToError }),
  customerFieldDeleteFormPopulate: () => {
    handlers.formSet('customerFieldsDelete', customerFieldDeleteFormTransform())
    handlers.customerFieldDeleteFormReady()
  },
  customerFieldDeleteFormSave: (customerField, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELD_DELETE_FORM_SAVE, { customerField, scrollToError }),
  customerFieldDeleteFormReady: () => dispatch(actions.CUSTOMER_FIELD_DELETE_FORM_READY),

  // Category
  customerFieldCategoryUpdate: category => dispatch(actions.CUSTOMER_FIELD_CATEGORY_UPDATE, category),
  customerFieldCategoryUpdateReady: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_UPDATE_READY),
  customerFieldCategoryDelete: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_DELETE, id),
  customerFieldCategoryDeleted: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_DELETED, id),
  customerFieldCategoryRemoveDeleted: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_REMOVE_DELETED, id),
  customerFieldCategoryDeleteFailed: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_DELETE_FAILED),
  customerFieldCategoryOrderChange: ({ id, oldIndex, newIndex }) => dispatch(actions.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex }),
  customerFieldCategoryOrderChanged: updatedCategories => dispatch(actions.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGED, updatedCategories),
  customerFieldCategoryLocallyChangedGet: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_GET, id),
  customerFieldCategoryLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  customerFieldCategoryLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  customerFieldCategoryLocallyChangedResetReady: ({ customerFieldCategory, companyId }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_RESET_READY, { customerFieldCategory, companyId }),
  customerFieldCategoryPreviewPopulate: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_PREVIEW_POPULATE, id),
  customerFieldCategoryPreviewGet: ({ id, forceFetch }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_PREVIEW_GET, { id, forceFetch }),
  // Category form
  customerFieldCategoryFormGet: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_FORM_GET, id),
  customerFieldCategoryFormPopulate: ({ category, branches, account }) => {
    handlers.formSet('customerFieldCategory', customerFieldCategoryFormTransform({ category, branches, account }))
    handlers.customerFieldCategoryFormReady()
  },
  customerFieldCategoryFormReady: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_FORM_READY),
  customerFieldCategoryFormSave: (form, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_FORM_SAVE, { form: customerFieldCategorySaveTransform(form), scrollToError })
})

// Reset

reducer.CUSTOMER_FIELDS_RESET = () => reducer.initialState

// Subscription

reducer.CUSTOMER_FIELDS_SUBSCRIPTION_SET = state => state

// List

reducer.CUSTOMER_FIELDS_GET = state => ({
  ...state,
  pendingList: true,
  messageList: null,
  messagePreview: null
})

reducer.CUSTOMER_FIELDS_POPULATE = (state, { customerFields = [], customerFieldsCategories = [] }) => ({
  ...state,
  list: customerFields.sort(sortByOrderIndex),
  categoriesList: customerFieldsCategories.sort(sortByOrderIndex),
  pendingList: false,
  areFetched: true
})

reducer.CUSTOMER_FIELDS_LIST_READY = state => ({
  ...state,
  pendingList: false
})

reducer.CUSTOMER_FIELDS_FILTERS_POPULATE = state => state

reducer.CUSTOMER_FIELDS_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  return {
    ...state,
    list: swapItemsInDnDList({
      categoryList: state.categoriesList,
      list: state.list,
      id,
      oldIndex,
      newIndex,
      sourceCategoryId,
      destinationCategoryId
    })
  }
}

reducer.CUSTOMER_FIELDS_LIST_ORDER_CHANGED = (state, updatedCustomerFields) => {
  updatedCustomerFields = updatedCustomerFields.map(uS => {
    const oldCustomerField = state.list.find(s => s.id === uS.id)
    return {
      ...oldCustomerField,
      ...uS
    }
  })

  return {
    ...state,
    list: state.list
      .filter(item => !updatedCustomerFields.find(uS => uS.id === item.id))
      .concat(updatedCustomerFields)
      .sort(sortByOrderIndex)
  }
}

reducer.CUSTOMER_FIELDS_LIST_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingList: false,
  messageList: message,
  messagePreview: null
})

reducer.CUSTOMER_FIELDS_UPDATED = (state) => state

// One

reducer.CUSTOMER_FIELD_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.CUSTOMER_FIELD_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}

reducer.CUSTOMER_FIELD_REMOVE_DELETED = (state, id) => {
  return {
    ...state,
    list: state.list.filter(item => item.id !== id)
  }
}

reducer.CUSTOMER_FIELDS_TO_DELETE_GET = state => ({
  ...state,
  pendingToDeleteList: true
})

reducer.CUSTOMER_FIELDS_TO_DELETE_POPULATE = (state, { customerFieldsToDelete }) => ({
  ...state,
  listToDelete: customerFieldsToDelete || [],
  pendingToDeleteList: false
})

reducer.CUSTOMER_FIELD_TO_DELETE_DELETE = (state, { id }) => {
  if (!id) return state
  const customerFieldsToDelete = state.listToDelete || []
  const remainingFieldsToDelete = customerFieldsToDelete.filter(item => item.id !== id)
  return {
    ...state,
    listToDelete: remainingFieldsToDelete,
    pendingToDeleteList: true
  }
}

reducer.CUSTOMER_FIELDS_TO_DELETE_READY = state => ({
  ...state,
  pendingToDeleteList: false
})

// Preview

reducer.CUSTOMER_FIELD_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true,
  messageList: null,
  messagePreview: null
})

reducer.CUSTOMER_FIELD_CATEGORY_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true,
  messageList: null,
  messagePreview: null
})

reducer.CUSTOMER_FIELD_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

reducer.CUSTOMER_FIELD_CATEGORY_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

reducer.CUSTOMER_FIELD_UPDATE = (state, customerField) => {
  if (!customerField) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === customerField.id)
  if (index < 0) list.push({ ...customerField, isUpdated: true })
  else list[index] = { ...customerField, isUpdated: true }
  return {
    ...state,
    list,
    pendingPreview: true
  }
}

reducer.CUSTOMER_FIELD_UPDATED = (state, customerField) => {
  if (!customerField) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === customerField.id)
  if (index < 0) return state
  list[index] = { ...customerField, isUpdated: false }
  return {
    ...state,
    list,
    pendingPreview: false
  }
}

reducer.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingCategoryPreview: true
})

reducer.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = {
    ...categoriesList[index],
    locallyChangedData
  }
  return {
    ...state,
    categoriesList,
    pendingCategoryPreview: false
  }
}

reducer.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingCategoryPreview: true
})

reducer.CUSTOMER_FIELD_CATEGORY_LOCALLY_CHANGED_RESET_READY = (state, { customerFieldCategory, companyId }) => {
  const { internalId } = customerFieldCategory || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  categoriesList[index].locallyChangedData = categoriesList[index].locallyChangedData.filter(item => item.branch.id !== companyId)
  delete categoriesList[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    categoriesList,
    pendingCategoryPreview: false
  }
}

reducer.CUSTOMER_FIELD_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingLocallyChangedPreview: true
})

reducer.CUSTOMER_FIELD_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = {
    ...list[index],
    locallyChangedData
  }
  return {
    ...state,
    list,
    pendingLocallyChangedPreview: false
  }
}

reducer.CUSTOMER_FIELD_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingLocallyChangedPreview: true
})

reducer.CUSTOMER_FIELD_LOCALLY_CHANGED_RESET_READY = (state, { customerField, companyId }) => {
  const { id, internalId } = customerField || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  list[index].locallyChangedData = list[index].locallyChangedData.filter(item => item.id !== id)
  delete list[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    list,
    pendingLocallyChangedPreview: false
  }
}

// Form

reducer.CUSTOMER_FIELD_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.CUSTOMER_FIELD_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.CUSTOMER_FIELD_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.CUSTOMER_FIELD_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

reducer.CUSTOMER_FIELD_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

// Category

reducer.CUSTOMER_FIELD_CATEGORY_UPDATE = (state, category) => {
  if (!category) return state
  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => item.id !== category.id)
      .concat([category])
      .sort(sortByOrderIndex),
    pendingCategory: category.id,
    pendingCategoryPreview: true
  }
}

reducer.CUSTOMER_FIELD_CATEGORY_UPDATE_READY = state => ({
  ...state,
  pendingCategory: null,
  pendingCategoryPreview: false
})

reducer.CUSTOMER_FIELD_CATEGORY_DELETE = (state, id) => ({
  ...state,
  pendingCategory: id
})

reducer.CUSTOMER_FIELD_CATEGORY_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = { ...categoriesList[index], isDeleted: true }
  return {
    ...state,
    categoriesList,
    pendingCategory: null
  }
}

reducer.CUSTOMER_FIELD_CATEGORY_REMOVE_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const defaultCategory = categoriesList.find(item => item.isDefault)
  const deletedCategorycustomerFields = [...state.list]
    .filter(item => item.categoryId === id)
    .map(item => ({ ...item, categoryId: defaultCategory.id }))
  const list = [...state.list]
    .filter(item => item.categoryId !== id)
    .concat(deletedCategorycustomerFields)
    .map((item, index) => ({ ...item, orderIndex: index + 1 }))
  return {
    ...state,
    list,
    categoriesList: state.categoriesList.filter(item => item.id !== id)
  }
}

reducer.CUSTOMER_FIELD_CATEGORY_DELETE_FAILED = state => ({
  ...state,
  pendingCategory: null
})

reducer.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
  return {
    ...state,
    categoriesList: swapCategoriesInDnDList({
      list: state.categoriesList,
      id,
      oldIndex,
      newIndex
    })
  }
}

reducer.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGED = (state, updatedCategories) => {
  updatedCategories = updatedCategories.map(uC => {
    const oldCategory = state.categoriesList.find(c => c.id === uC.id)
    return {
      ...oldCategory,
      ...uC
    }
  })

  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => !updatedCategories.find(uC => uC.id === item.id))
      .concat(updatedCategories)
      .sort(sortByOrderIndex)
  }
}

// Category form

reducer.CUSTOMER_FIELD_CATEGORY_FORM_GET = state => ({
  ...state,
  pendingCategoryForm: true
})

reducer.CUSTOMER_FIELD_CATEGORY_FORM_READY = state => ({
  ...state,
  pendingCategoryForm: false
})

reducer.CUSTOMER_FIELD_CATEGORY_FORM_SAVE = state => ({
  ...state,
  pendingCategoryForm: true
})

export default reducer
