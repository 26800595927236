import React, { useState } from 'react'
import { PopupTriggerWrapper } from '../../../Beasts'
import {
  AccordionTitle,
  AccordionBody,
  FontAwesome5,
  Title,
  SingleSelect,
  BorderedBox,
  Accordion,
  FormGroup,
  Checkbox,
  Alert,
  Select,
  Input,
  Error,
  Row,
  Col,
  t,
  Button
} from '../../../Common'
import { dangerousHTML, truncateText } from '../../../Utils'

const SettingsServiceAllocations = props => {
  let {
    shouldDisplayInfo,
    serviceAllocations,
    serviceIdsSelected,
    shouldAllowBookingWidget,
    servicesAllocationsTotalPercentage,
    isServiceAllocationsEnabledFixedTime,
    servicesAndCoursesCategoriesWithItems,
    hideErrors,
    onSettingsChangeAddon,
    onServiceAllocationAdd,
    onServiceAllocationDelete,
    onServiceAllocationNameChange,
    onServiceAllocationServicesChange,
    onServiceAllocationFixedTimeChange,
    onServiceAllocationPercentageChange
  } = props
  serviceAllocations = serviceAllocations || []
  const [focusedIndex, setFocusedIndex] = useState(null)
  const serviceAllocationsPercentageLeft = 100 - servicesAllocationsTotalPercentage
  const isSuspendSettingDisabled = serviceAllocations.length === 0 || (!shouldDisplayInfo && !shouldAllowBookingWidget)
  const isAddBtnDisabled = serviceAllocationsPercentageLeft < 1 || serviceAllocations.length >= 20

  const onFocus = (index) => {
    setFocusedIndex(index)
  }

  const onBlur = () => {
    setFocusedIndex(null)
  }

  return (
    <BorderedBox className='ta-service-allocations__container' topOffset>
      <Row>
        <Col>
          <Alert theme='alert'>
            {dangerousHTML(t('settings.section.serviceAllocations.instructions.text'))}
          </Alert>
        </Col>
      </Row>
      {serviceAllocations.map((serviceAllocation, index) => {
        let { id, serviceIds, percentage, name } = serviceAllocation || {}
        serviceIds = serviceIds || []
        const serviceIdsOfOtherAllocations = serviceIdsSelected.filter(selectedServiceId => !serviceIds.includes(selectedServiceId))
        // check if the category's items are already selected in other allocation groups and if yes, remove category from options. Otherwise, filter services selected in other allocation groups
        const servicesAndCoursesCategoriesOptions = servicesAndCoursesCategoriesWithItems.filter(category => !category.items.every(id => serviceIdsOfOtherAllocations.includes(id))).map(category => ({ ...category, items: category.items.filter(option => !serviceIdsOfOtherAllocations.includes(option.value)) }))
        const serviceAllocationTitle = (name || '').length > 0 ? name : t('settings.section.serviceAllocations.new')

        return (
          <Accordion key={id}>
            <AccordionTitle>
              {truncateText(serviceAllocationTitle, 40, true)}
              <span className='ta-allocation-label'>{t('settings.section.serviceAllocations.allocation.percentage', [{ key: 'PERCENT', value: percentage || 0 }])}</span>
              <FontAwesome5 icon='trash-alt' type='s' onClick={() => onServiceAllocationDelete(index)} />
            </AccordionTitle>
            <AccordionBody>
              <Row>
                <Col>
                  <FormGroup
                    focused={focusedIndex === index}
                    labelText={t('settings.section.serviceAllocations.servicesSelect.placeholder')}
                  >
                    <Select
                      className='ta-single-select'
                      noResultsText={t('global.noResults')}
                      value={serviceIds}
                      onFocus={() => onFocus(index)}
                      onBlur={onBlur}
                      onChange={(values) => onServiceAllocationServicesChange(values, index)}
                      onDelete={(values) => onServiceAllocationServicesChange(values, index, true)}
                      hasCategories
                      options={servicesAndCoursesCategoriesOptions}
                      multi
                      searchable
                      noLabel
                    />
                  </FormGroup>
                  <Error name={`serviceAllocation${id}ServiceErrors`} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    value={percentage}
                    onChange={(_, value) => onServiceAllocationPercentageChange(value, index)}
                    label={t('settings.section.serviceAllocations.percentageSelect.label')}
                    addon={<FontAwesome5 icon='percent' type='s' />}
                  />
                  <Error noOffset name={`serviceAllocation${id}PercentErrors`} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    value={name}
                    onChange={(_, value) => onServiceAllocationNameChange(value, index)}
                    label={t('settings.section.serviceAllocations.nameSelect.label')}
                    hintText={t('settings.section.serviceAllocations.nameSelect.hint')}
                  />
                  <Error noOffset name={`serviceAllocation${id}NameErrors`} />
                </Col>
              </Row>
              {(serviceIds.length > 0 &&
                <Alert noOffset theme='alert'>
                  {dangerousHTML(t('settings.section.serviceAllocations.allocationWarning.text', [
                    { key: 'PERCENTAGE', value: `${percentage}%` },
                    { key: 'ALLOCATION', value: serviceAllocationTitle }
                  ]))}
                </Alert>
              )}
            </AccordionBody>
          </Accordion>
        )
      })}
      <Button iconType='regular' icon='plus' isTertiary isGhost isBlock className={`ta-settings__btn-add service-allocations ${isAddBtnDisabled ? 'disabled' : ''}`} onClick={isAddBtnDisabled ? null : onServiceAllocationAdd}>
        {t('settings.section.serviceAllocations.button.add')}
      </Button>
      <Error name='serviceAllocationCountErrors' />
      {(serviceAllocations.length > 0 && serviceAllocationsPercentageLeft > 0 &&
        <Row>
          <Col>
            <Alert theme='alert'>
              {dangerousHTML(t('settings.section.serviceAllocations.percentage.remaining', [{ key: 'REMAINING', value: serviceAllocationsPercentageLeft }]))}
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Title theme='striped' label={t('global.settings')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            disabled={serviceAllocations.length === 0}
            name='shouldDisplayInfo'
            onChangeAddon={onSettingsChangeAddon}
          >
            {t('settings.section.serviceAllocations.options.first')}
            <PopupTriggerWrapper
              name='services-allocations-options'
              extraClassName='ta-service-allocations'
              id={{ text: t('settings.section.serviceAllocations.options.first.helpText') }}
              position='top'
            >
              <FontAwesome5 icon='question-circle' type='s' />
            </PopupTriggerWrapper>
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            disabled={serviceAllocations.length === 0}
            name='shouldAllowBookingWidget'
            onChangeAddon={onSettingsChangeAddon}
          >
            {t('settings.section.serviceAllocations.options.second')}
            <PopupTriggerWrapper
              name='services-allocations-options'
              extraClassName='ta-service-allocations'
              id={{ text: t('settings.section.serviceAllocations.options.second.helpText') }}
              position='top'
            >
              <FontAwesome5 icon='question-circle' type='s' />
            </PopupTriggerWrapper>
          </Checkbox>
          <Error name='serviceAllocationSettingsErrors' />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            disabled={isSuspendSettingDisabled}
            name='isServiceAllocationsEnabledFixedTime'
          >
            {t('settings.section.serviceAllocations.options.third')}
            <PopupTriggerWrapper
              name='services-allocations-options'
              extraClassName='ta-service-allocations'
              id={{ text: t('settings.section.serviceAllocations.options.third.helpText') }}
              position='top'
            >
              <FontAwesome5 icon='question-circle' type='s' />
            </PopupTriggerWrapper>
          </Checkbox>
        </Col>
      </Row>
      {(isServiceAllocationsEnabledFixedTime &&
        <BorderedBox className='ta-service-allocation__lead-time__box'>
          <Row noOffset>
            <Col size={50}>
              <Input
                disabled={isSuspendSettingDisabled}
                name='serviceAllocationsEnabledFixedTimeLeadTime'
                label={t('settings.section.serviceAllocations.input.label')}
                onChange={(_, value) => onServiceAllocationFixedTimeChange(value)}
              />
            </Col>
            <Col size={50}>
              <SingleSelect
                disabled={isSuspendSettingDisabled}
                name='serviceAllocationsEnabledFixedTimeUnits'
                label={t('settings.section.leadTimeDuration.select.label')}
                onChangeAddon={() => hideErrors(null, 'SuspendTime')}
              />
            </Col>
          </Row>
          <Error noOffset name='serviceAllocationSuspendTimeErrors' />
        </BorderedBox>
      )}
      <Error noOffset name='serviceAllocationTotalPercentErrors' />
    </BorderedBox>
  )
}

export default SettingsServiceAllocations
