import React from 'react'
import { connect } from '../../Store'
import { CourseFormSettings } from '../../Beauties'

const CourseFormSettingsWrapper = props => {
  let { hasOnlinePayment } = props
  hasOnlinePayment = !!hasOnlinePayment

  return <CourseFormSettings hasOnlinePayment={hasOnlinePayment} />
}

const maps = state => ({
  hasOnlinePayment: state.forms.courses.hasOnlinePayment && state.forms.courses.hasOnlinePayment.value
})

export default connect(maps)(CourseFormSettingsWrapper)
