import {
  companyPopulateTransform
} from './utils'

const reducer = {}

reducer.initialState = {}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  companyReset: () => dispatch(actions.COMPANY_RESET),

  // Subscription
  companySubscriptionSet: ({ name, ids, data, ts }) => dispatch(actions.COMPANY_SUBSCRIPTION_SET, { name, ids, data, ts }),

  // One
  companyPopulate: company => dispatch(actions.COMPANY_POPULATE, companyPopulateTransform(company)),
  companyLastStatisticsStateChangeSet: date => dispatch(actions.COMPANY_LAST_STATISTICS_STATE_CHANGE_SET, date),

  // Appcues
  companyAppcuesInit: () => dispatch(actions.COMPANY_APPCUES_INIT),
  companyAppcuesLogout: () => dispatch(actions.COMPANY_APPCUES_LOGOUT)
})

reducer.COMPANY_RESET = () => reducer.initialState

reducer.COMPANY_APPCUES_INIT = state => state

reducer.COMPANY_SUBSCRIPTION_SET = state => state

reducer.COMPANY_APPCUES_LOGOUT = state => state

reducer.COMPANY_POPULATE = (state, company = {}) => ({
  ...state,
  ...company
})

reducer.COMPANY_LAST_STATISTICS_STATE_CHANGE_SET = (state, date) => ({
  ...state,
  lastStatisticsStateChange: date
})

export default reducer
