import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServicesPriceOverriden = props => {
  return (
    <div className='ta-popup__services-duration-before-and-after'>
      <div className='ta-popup__title'>{t('global.price')}</div>
      <div className='ta-popup__text'>{dangerousHTML(t('serviceCombinations.priceOverride.message'))}</div>
    </div>
  )
}

export default PopupServicesPriceOverriden
