import React from 'react'
import {
  DnDDraggable,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  FontAwesome5,
  ListButton,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../Common'
import { truncateText, dangerousHTML } from '../../Utils'

const CustomerFieldsListItem = props => {
  const {
    customerField,
    index,
    isDragAndDropMode,
    areCategoriesExpanded,
    categoryIndex
  } = props
  const classNames = ['ta-customer-fields__customer-field']
  if (isDragAndDropMode) classNames.push('is-draggable')
  if (customerField.isDeleted) classNames.push('deleted')
  if (customerField.isUpdated) classNames.push('updated')
  if (!customerField.isActive) classNames.push('disabled')
  const { locallyUpdatedBranches } = customerField || {}
  const globalLabelClassNames = ['global-label', 'edited']
  const locallyChangedBranches = (locallyUpdatedBranches && Object.keys(locallyUpdatedBranches).length) || false
  const customerFieldName = customerField.label || t(customerField.translationKey)
  const isFirstInList = (categoryIndex === 1 || categoryIndex === 999999999) && customerField.orderIndex === 1
  const globalLabelPosition = isFirstInList
    ? customerFieldName.length > 28
      ? 'left'
      : customerFieldName.length < 19
        ? 'right'
        : 'bottom'
    : customerFieldName.length > 2
      ? 'top'
      : 'right'

  return (
    <div className={classNames.join(' ')}>
      <DnDDraggable
        className='ta-customer-fields__customer-field__dnd-wrapper'
        type='customerField'
        id={customerField.id}
        index={index}
        isDragDisabled={!isDragAndDropMode || !areCategoriesExpanded}
      >
        <SimpleListItem
          to={!isDragAndDropMode && `/customers/customer-fields/${customerField.id}`}
          status={(customerField.isPublished
            ? 'active'
            : (customerField.isActive
                ? 'title-booking'
                : 'inactive')
          )}
          draggable={isDragAndDropMode}
        >
          <SimpleListItemTitle className='global' title={truncateText(customerFieldName, 37, true)}>
            {(locallyChangedBranches &&
              <HoverPopup>
                <HoverPopupContent position={globalLabelPosition}>
                  {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
                </HoverPopupContent>
                <div
                  className={globalLabelClassNames.join(' ')}
                  onClick={e => e.stopPropagation()}
                >
                  <HoverPopupTrigger>
                    <FontAwesome5 icon='pencil' type='s' />
                    {t('global.changedLocally')}
                  </HoverPopupTrigger>
                </div>
              </HoverPopup>
            )}
          </SimpleListItemTitle>
          <SimpleListItemContent>
            <ul className='ta-list-inline'>
              {(customerField.type &&
                <li>
                  <FontAwesome5 icon='sliders-h' />
                  {t(`customerFields.fieldType.${customerField.type.toLowerCase()}`)}
                </li>
              )}
              {(customerField.hasOverwrite
                ? <li><FontAwesome5 icon='user' type='solid' /> {t('global.override')}</li>
                : <li><FontAwesome5 icon='calendar' type='solid' /> {t('global.keepCopies')}</li>
              )}
              {(!customerField.isAfterAuth
                ? <li><FontAwesome5 icon='check-square' type='solid' /> {t('global.before')}</li>
                : <li><FontAwesome5 icon='square' type='solid' /> {t('global.after')}</li>
              )}
              {(customerField.isUniqueKey
                ? <li><FontAwesome5 icon='check-square' type='solid' /> {t('global.isUniqueIdentifier')}</li>
                : <li><FontAwesome5 icon='square' type='solid' /> {t('global.isUniqueIdentifier')}</li>
              )}
            </ul>
          </SimpleListItemContent>
          {(isDragAndDropMode &&
            <SimpleListItemButtons>
              <ListButton icon='arrows-v' />
            </SimpleListItemButtons>
          )}
        </SimpleListItem>
      </DnDDraggable>
    </div>
  )
}

export default CustomerFieldsListItem
