const reducer = {}

reducer.initialState = {
  tags: [],
  areTagsFetched: false,
  pending: true
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  globalsReset: () => dispatch(actions.GLOBALS_RESET),

  // Tags
  globalsTagsListGet: () => dispatch(actions.GLOBALS_TAGS_LIST_GET),
  globalsTagsListPopulate: tags => dispatch(actions.GLOBALS_TAGS_LIST_POPULATE, tags),
  globalsTagsListReady: () => dispatch(actions.GLOBALS_TAGS_LIST_READY)
})

// Reset
reducer.GLOBALS_RESET = state => reducer.initialState

// Tags
reducer.GLOBALS_TAGS_LIST_GET = state => ({
  ...state,
  pending: true
})
reducer.GLOBALS_TAGS_LIST_POPULATE = (state, tags) => ({
  ...state,
  tags,
  pending: false,
  areTagsFetched: true
})
reducer.GLOBALS_TAGS_LIST_READY = state => ({
  ...state,
  pending: false
})

export default reducer
