import React from 'react'
import {
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from 'recharts'
import { CHART_DEFAULTS } from '../../Settings'
import { ChartCustomTooltip, ChartShapeRoundBar } from '../../Common'

const ChartComposed = props => {
  const { options, data } = props
  const { customTooltip } = options || {}

  return (
    <ResponsiveContainer width='100%' minHeight={options.containerMinHeight || '180px'}>
      <ComposedChart
        syncId={options.syncId}
        width={options.width || CHART_DEFAULTS.width}
        height={options.height || CHART_DEFAULTS.height}
        margin={options.margin || CHART_DEFAULTS.margin.line}
        data={data}
        layout='vertical'
      >
        <CartesianGrid
          strokeDasharray={CHART_DEFAULTS.strokeDasharry}
          horizontal={options.horizontalGrid}
          vertical={options.verticalGrid}
          verticalPoints={options.verticalPoints}
        />
        <XAxis type='number' padding={options.axisPadding} allowDecimals={false} interval={options.interval} />
        <YAxis dataKey={options.labelsKey} type='category' tick={options.customTick} allowDecimals={false} interval={options.interval} />
        {options.tooltip &&
          <Tooltip cursor={options.cursor || CHART_DEFAULTS.barTooltipCursor} content={options.customTooltip
            ? <ChartCustomTooltip
              className={customTooltip.className}
              textBeforeLabel={customTooltip.textBeforeLabel}
              getNextLabel={customTooltip.getNextLabel}
              labelText={customTooltip.labelText}
              reverseLabelOrder={customTooltip.reverseLabelOrder}
              customLabels={customTooltip.customLabelKey ? data.map(item => ({ label: item[customTooltip.customLabelKey] })) : []}
            />
            : null} />
        }
        {options.bars && options.bars.map((bar, index) => (
          <Bar
            key={index}
            isAnimationActive={false}
            type='monotone'
            dataKey={bar.key}
            fill={bar.fill}
            shape={<ChartShapeRoundBar horizontal />}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default ChartComposed
