import React from 'react'

const TableRowHeader = props => {
  const { children } = props

  return (
    <div className='ta-table-row__header'>
      {children}
    </div>
  )
}

export default TableRowHeader
