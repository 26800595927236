import React, { memo } from 'react'
import { connect } from '../../Store'
import { BranchesServiceFormAllowancePlan } from '../../Beauties'

const BranchesServiceFormAllowancePlanWrapper = props => {
  const {
    plan,
    services
  } = props
  let { hasSpecificBookingDays } = services || {}
  hasSpecificBookingDays = hasSpecificBookingDays || {}
  hasSpecificBookingDays = hasSpecificBookingDays.value || false
  const hasAllowancePlan = hasSpecificBookingDays && Object.keys(services).filter(
    key => key.indexOf('bookingWeeklyAllowancePlan') > -1
  ).length > 0

  return (
    <BranchesServiceFormAllowancePlan
      plan={plan || 'CLASSIC'}
      hasSpecificBookingDays={hasSpecificBookingDays}
      hasAllowancePlan={hasAllowancePlan}
    />
  )
}

const maps = state => ({
  services: state.forms.branchesService
})

export default memo(connect(maps)(BranchesServiceFormAllowancePlanWrapper))
