import React from 'react'

import './Content.css'
import { DashboardWrapper, IntercomWrapper } from '../../Beasts'

const Content = props => {
  const { expand } = props
  const classNames = ['ta-content']
  if (expand) classNames.push('expand')

  return (
    <div className={classNames.join(' ')} >
      <DashboardWrapper />
      <IntercomWrapper />
    </div>
  )
}

export default Content
