import React from 'react'

const FormButtons = props => {
  const { children } = props

  return (
    <div className='ta-form-buttons'>
      {children}
    </div>
  )
}

export default FormButtons
