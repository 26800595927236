import React from 'react'
import {
  FormSection,
  Title,
  Input,
  Row,
  Col,
  t,
  HoverPopupContent,
  HoverPopup,
  HoverPopupTrigger
} from '../../../../Common'

const BranchesServiceFormExternalId = props => {
  const { allowedSet } = props
  const externalIdDisabled = !allowedSet?.includes('externalId')

  return (
    <FormSection>
      <Title size='m' label={t('global.externalId.label')} icon='fingerprint' ignoreTopOffset />
      <Row>
        <Col>
          <HoverPopup disabled={!externalIdDisabled} className='ta-service-form-inputs__popup'>
            <HoverPopupContent position='top'>
              {t('globalSettings.form.section.attributes.disabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Input
                disabled={externalIdDisabled}
                name='externalId'
                label={t('global.externalId.label')}
                hintText={t('global.externalId.hint')}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
    </FormSection>
  )
}

export default BranchesServiceFormExternalId
