const compareTable = {
  // Global
  EmailRequired: 'errors.email.required',
  EmailNotValid: 'errors.email.invalid',
  EmailNotConfirmed: 'errors.email.notConfirmed',
  EmailNotVerified: 'errors.email.notVerified',
  SSOError: 'errors.ssoError',
  FailedToAuthenticate: 'errors.password.invalid',

  // User
  PasswordMandatory: 'password.required',
  PasswordWeak: 'errors.password.weak',
  PasswordInvalid: 'errors.password.invalid',
  NoScopeForUser: 'errors.user.noScope', // Booker or Enterprise account. No company yet.
  UserNotFound: 'errors.user.notFound',
  InvalidUserPermission: 'errors.users.permissions.cantEdit',
  InvalidUserCreatePermission: 'errors.users.permissions.create.invalid',
  InvalidUserUpdatePermission: 'errors.users.permissions.update.invalid',

  // Billing
  InvalidVAT: 'errors.billing.invalidVAT',
  InvalidZipCode: 'errors.billing.invalidZipCode',
  InvalidCompanyName: 'errors.billing.invalidCompanyName',

  // Auth
  LoginError: 'errors.loginError',

  // Account
  AccountNotApproved: 'errors.account.notApproved',
  AccountNotFound: 'errors.account.notFound',
  AccountDisabled: 'errors.account.disabled',
  MissingOrInvalidPhone: 'errors.phone.invalid',

  // Services
  ExternalIdConflicts: 'errors.externalId.exists',
  NoCalendarWithoutEnterprisePlan: 'errors.plan.needUpgrade',
  CombinationServicesNotFound: 'errors.serviceCombination.serviceDeleted',
  InvalidGlobalServiceReset: 'errors.serviceChangedLocally.reset',
  InvalidGlobalCourseReset: 'errors.courseChangedLocally.reset',

  // Offer
  OffersConflict: 'errors.offerConflict',
  OffersConflictServices: 'errors.offerConflictServices',
  OffersConflictCourses: 'errors.offerConflictCourses',

  // Customers
  FieldsMandatory: 'errors.customers.fillMandatoryFields',
  CustomerExistsUniqueKey: 'errors.customers.alreadyExists',

  // CustomerFields
  NoServiceAndCourse: 'errors.customerFields.noServiceOrCourse',
  SpecificServiceNotAllowed: 'errors.customerFields.serviceNotAllowed',
  SpecificCourseNotAllowed: 'errors.customerFields.serviceNotAllowed',

  // Settings
  GlobalHolidaySaveError: 'errors.publicHolidays.localCategoriesNotFound',

  // Other
  DependencyNotAvailable: 'errors.dependencyNotAvailable',
  BookingOverlap: 'errors.bookingOverlap',
  NotValidPhone: 'errors.phone.invalid',
  UnsupportedCustomRegion: 'errors.unsupportedCustomRegion',
  InvalidBranchExternalIds: 'errors.invalidBranchExternalIds',

  // Branch
  CompanyExists: 'errors.externalId.exists',

  // Branch Resources
  InvalidAbbreviation: 'errors.resources.abbreviation.invalid',

  // Permission types
  InvalidBranchIds: 'errors.permissionTypes.invalidBranch',

  // File download
  FileNotFound: 'errors.files.fileNotFound',

  // Apps
  TransactionAppError: 'errors.apps.transactionAppError',
  NeedsBillingDataForApp: 'errors.apps.needsBillingDataForApp',
  LimitTooBig: 'errors.apps.thresholdTooBig',
  CardNotCharged: 'errors.apps.cardNotCharged'
}

export default (serverCode) => {
  return compareTable[serverCode] || 'errors.somethingWentWrong'
}
