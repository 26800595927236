import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { q } from '../../API'
import {
  activityLogsListTransform,
  activityLogsListErrorsTransform
} from './utils'
import { activityLogUpdated } from './subscriptions'

const limit = 10

const getActivityLogsFilterFromState = state => {
  const activityLogsFilters = state.forms.activityLogsFilters
  const {
    type,
    operation,
    search,
    from,
    until
  } = activityLogsFilters || {}
  const result = {}
  if (type && type.value) result.type = type.value
  if (search && search.value) result.search = search.value
  if (operation && operation.value) result.operation = operation.value
  if (from && from.value) result.dateFrom = from.value
  if (until && until.value) result.dateTo = (until.value || '').replace('00:00', '23:59')
  if (type && type.value === 'SINGLE_EVENT') {
    result.type = 'EVENT'
    result.isCourse = false
  }
  if (type && type.value === 'GROUP_EVENT') {
    result.type = 'EVENT'
    result.isCourse = true
  }
  if (type && type.value === 'EVENT_PARTICIPANT' && operation && operation.value === 'DELETED') {
    result.operation = 'REMOVED'
  }
  return result
}

const getActivityLogsPageFromState = state => {
  return state.notifications ? state.notifications.activityLogsPage : 1
}

// Global actions

let isLoading = false

globalActions.populateActivityLogs = async ({ activityLogsPage, isLoadMore }) => {
  if (isLoading && !isLoadMore) return
  isLoading = true
  const state = store.getState()
  const filter = getActivityLogsFilterFromState(state)
  activityLogsPage = activityLogsPage || getActivityLogsPageFromState(state)
  const result = await q('getEnterpriseActivityLogs', { filter, pagination: { limit, page: activityLogsPage } })
  const { error, getEnterpriseActivityLogs, getEnterpriseActivityLogsCounter } = result || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    isLoading = false
    handlers.activityLogsListMessageSet(activityLogsListErrorsTransform(error))
    return
  }

  handlers.activityLogsListOptionsChange({ activityLogsPage, activityLogsLastPage: !getEnterpriseActivityLogs.length || (getEnterpriseActivityLogs.length < limit) })
  handlers.activityLogsListPopulate(activityLogsListTransform(getEnterpriseActivityLogs || []), !!isLoadMore)
  handlers.activityLogsListCounterPopulate(getEnterpriseActivityLogsCounter || 0)
  isLoading = false
  return result
}

// Activity logs
payloads$(actions.ACTIVITY_LOGS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'activityLogUpdated') {
      const activityLog = (await q('getEnterpriseActivityLog', { id })) || {}
      if (activityLog.error) return
      activityLogUpdated(activityLog)
    }
  })

payloads$(actions.ACTIVITY_LOGS_LIST_GET)
  .subscribe(() => {
    setTimeout(() => globalActions.populateActivityLogs({ activityLogsPage: 1 }), 0)
  })

// Activity logs page increment
payloads$(actions.ACTIVITY_LOGS_LIST_PAGE_INCREMENT)
  .subscribe(async () => {
    globalActions.populateActivityLogs({ isLoadMore: true })
  })

// Activity logs status list Filters
payloads$(actions.FORM_FIELDS_UPDATE)
  .pipe(
    filter(fields => fields.form === 'activityLogsFilters'),
    distinctUntilChanged(),
    debounceTime(500)
  ).subscribe((fields) => {
    handlers.activityLogsListGet()
  })
