import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../../Store'
import { feedContextInProps, dangerousHTML } from '../../../Utils'
import { FormContext, t, Alert } from '../../../Common'

import './Error.css'

const Error = ({ errors, error, noTopOffset, shouldNotTranslate }) => {
  const allErrors = (error && [error]) || errors
  if (allErrors.length < 1) return null

  return (
    <Alert className='ta-error' theme='error' noOffset noBorder isCompact hasTopOffset={!noTopOffset}>
      {allErrors.map((item, index) => <div key={index}>{shouldNotTranslate ? item.value : dangerousHTML(t(item.value, item.replace || []))}</div>)}
    </Alert>
  )
}

Error.propTypes = {
  noTopOffset: PropTypes.bool,
  message: PropTypes.string,
  error: PropTypes.object
}

const maps = (state, props) => ({
  errors: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].errors) || []
})

export default feedContextInProps(connect(maps)(Error), FormContext)
