import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { CustomerBookingsPrintForm } from '../../Beauties'

const CustomerBookingsPrintFormWrapper = props => {
  const {
    id,
    pending,
    form
  } = props

  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    handlers.customerFormGet({ id })
  }, [initial])

  const onCloseModal = () => {
    handlers.modalHide()
    handlers.overlayHide('top')
  }

  const onClickCustomerBookingsPrint = () => {
    handlers.customerBookingsPrint()
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CustomerBookingsPrintForm
          form={form}
          onCloseModal={onCloseModal}
          onClickCustomerBookingsPrint={onClickCustomerBookingsPrint}
        />
      )}
    </>
  )
}

CustomerBookingsPrintFormWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  form: PropTypes.object
}

const maps = state => ({
  pending: state.customers.pendingForm,
  form: state.forms.customers || {}
})

export default connect(maps)(CustomerBookingsPrintFormWrapper)
