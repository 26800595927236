import React, { useEffect, useState, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, isRouteDisabledByCustomisations } from '../../Common'
import { BranchesGroupForm } from '../../Beauties'
import { COURSE_INITIAL_KEYS } from '../../Settings/settings'

const BranchesPreviewGroupsEditWrapper = props => {
  let {
    id,
    branches,
    branchId,
    pending,
    settings,
    form,
    hash
  } = props
  let { showExternalIds, courseLocalUpdates } = settings || {}
  const { isGlobal } = form || {}
  const { value: isGlobalValue } = isGlobal || {}
  if (!isGlobalValue) courseLocalUpdates = COURSE_INITIAL_KEYS
  else {
    courseLocalUpdates =
      courseLocalUpdates.isForbidden
        ? []
        : !courseLocalUpdates.keys.length
            ? COURSE_INITIAL_KEYS
            : courseLocalUpdates.keys
  }
  pending = !!pending
  branches = branches || []
  form = form || {}
  showExternalIds = !!showExternalIds
  const branch = branches.find(item => item.id === branchId) || {}
  const customisations = (branch && branch.webappCustomisation) || {}
  let { plan, hasStripeConnected } = branch || {}
  plan = plan || 'CLASSIC'
  hasStripeConnected = !!hasStripeConnected
  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    const { id } = initial
    handlers.branchesGroupFormGet(id)
  }, [initial])

  const onSubmit = cb => {
    handlers.branchesGroupFormSave(branch, form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <BranchesGroupForm
          id={id}
          type='edit'
          cancelLink={`/branches/${branchId}/preview@@groups`}
          onSubmit={onSubmit}
          plan={plan}
          showExternalIds={showExternalIds}
          customisations={customisations}
          hash={hash}
          hasStripeConnected={hasStripeConnected}
          allowedSet={courseLocalUpdates}
        />
      )}
    </>
  )
}

const maps = state => ({
  id: state.router.data.id,
  branches: state.branches.list,
  branchId: state.router.data.branchId,
  pending: state.branches.pendingForm,
  form: state.forms.branchesGroup,
  settings: state.company.settings,
  hash: state.router.hash
})

export default memo(connect(maps)(BranchesPreviewGroupsEditWrapper))
