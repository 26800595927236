import React from 'react'

const IconListItemBody = props => {
  const { children, block, offset, withBorder } = props
  const classNames = ['ta-icon-list__body']
  if (block) classNames.push('block')
  if (withBorder) classNames.push('with-border')
  const styles = {}
  if (!block && offset) styles.width = `calc(100% - 5px - ${offset})`

  return (
    <div className={classNames.join(' ')} style={styles}>
      {children}
    </div>
  )
}

export default IconListItemBody
