import React from 'react'
import { Title, Button, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupServicesImportOptions = props => {
  const { closePopup, companyId } = props
  const onClick = () => {
    closePopup && closePopup()
  }

  return (
    <div className='ta-popup__services-import-select'>
      <Title
        size='m'
        label={dangerousHTML(t('popup.import.whatToImport'))}
        icon='upload'
        className='ta-smaller-margin'
        ignoreTopOffset
      />
      <div className='ta-popup__text'>
        <Button
          as='link'
          to={`/branches/${companyId}/services-import`}
          className='ta-popup__services-create-type-select__btn'
          action={onClick}
        >
          <span className='ta-popup__services-create-type-select__btn__title'>
            {t('popup.import.services')}
          </span>
        </Button>
        <Button
          as='link'
          to={`/branches/${companyId}/service-categories-import`}
          className='ta-popup__services-create-type-select__btn last'
          action={onClick}
        >
          <span className='ta-popup__services-create-type-select__btn__title'>
            {t('popup.import.serviceCategories')}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default PopupServicesImportOptions
