import React, { useEffect, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, t } from '../../Common'
import { UserPermissionsForm } from '../../Beauties'

const UserPermissionsAddWrapper = props => {
  let {
    pending,
    form,
    showExternalIds,
    account
  } = props
  form = form || {}
  showExternalIds = !!showExternalIds
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { permissionsGroupsWrite } = enterprisePermissions || {}
  permissionsGroupsWrite = !!isAdmin || !!permissionsGroupsWrite

  useEffect(() => {
    if (permissionsGroupsWrite) handlers.userPermissionTypesFormGet()
  }, [permissionsGroupsWrite])

  const onSubmit = cb => {
    handlers.userPermissionTypesFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <UserPermissionsForm
          onSubmit={onSubmit}
          cancelLink='/managers/user-permissions'
          title={t('userPermissionTypes.add.headingAddPermissionType')}
          showExternalIds={showExternalIds}
        />
      )}
    </>
  )
}

const maps = state => ({
  pending: state.userPermissions.pendingForm,
  form: state.forms.userPermissionType,
  showExternalIds: state.company.settings && state.company.settings.showExternalIds,
  account: state.account
})

export default memo(connect(maps)(UserPermissionsAddWrapper))
