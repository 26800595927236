import React from 'react'
import {
  BillingPreviewMethodCreditCard,
  BillingPreviewMethodPayPal
} from '../../Beauties'

const BillingPreviewMethod = props => {
  const { item } = props
  const { type, isDeleted } = item
  const classNames = ['ta-billing__preview__method']
  if (isDeleted) classNames.push('deleted')

  return (
    <div className={classNames.join(' ')}>
      {(type === 'BRAINTREE_CREDITCARD' &&
        <BillingPreviewMethodCreditCard {...item} />
      )}
      {(type === 'BRAINTREE_PAYPAL' &&
        <BillingPreviewMethodPayPal {...item} />
      )}
    </div>
  )
}

export default BillingPreviewMethod
