import { globalsSettingsFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: true
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  globalsSettingsReset: () => dispatch(actions.GLOBALS_SETTINGS_RESET),

  // Form
  globalsSettingsFormGet: () => dispatch(actions.GLOBALS_SETTINGS_FORM_GET),
  globalsSettingsFormPopulate: (settings) => {
    handlers.formSet('globalsSettings', globalsSettingsFormTransform(settings))
    handlers.globalsSettingsFormReady()
  },
  globalsSettingsFormReady: () => dispatch(actions.GLOBALS_SETTINGS_FORM_READY),
  globalsSettingsFormSave: (settings, scrollToError = () => {}) => dispatch(actions.GLOBALS_SETTINGS_FORM_SAVE, { settings, scrollToError }),

  // Preview
  globalsSettingsPreviewGet: () => dispatch(actions.GLOBALS_SETTINGS_PREVIEW_GET),
  globalsSettingsPreviewReady: () => dispatch(actions.GLOBALS_SETTINGS_PREVIEW_READY)
})

// Reset

reducer.GLOBALS_SETTINGS_RESET = state => reducer.initialState

// Form

reducer.GLOBALS_SETTINGS_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.GLOBALS_SETTINGS_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.GLOBALS_SETTINGS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Preview

reducer.GLOBALS_SETTINGS_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.GLOBALS_SETTINGS_PREVIEW_READY = state => ({
  ...state,
  pendingPreview: false
})

export default reducer
