import React from 'react'
import {
  Radio,
  Title,
  FormSection,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Col,
  Row,
  t
} from '../../../Common'
import { BranchesPreviewResourceFormCustomPermissionsWrapper } from '../../../Beasts'

const BranchesResourceFormPermissions = props => {
  const {
    isOwner,
    isCustom,
    branch,
    hasInvitation,
    hasAccount,
    isEditingOwnResource,
    plan,
    isPermissionUpdateAllowedByIssuer
  } = props
  const isPermissionsDisabled = !(hasInvitation || hasAccount) ||
    !isPermissionUpdateAllowedByIssuer ||
    isEditingOwnResource ||
    plan !== 'ENTERPRISE'

  return (
    <div className='ta-resources__form__permissions'>
      <FormSection>
        <Title size='m' label={t('global.permissionTypes')} icon='lock' />
        <Row>
          <Col>
            {(!(hasInvitation || hasAccount) &&
              <>
                <HoverPopup className='ta-branches-resources__form__permissions__overlay'>
                  <HoverPopupContent position='top'>
                    {t('branches.form.resource.permissions.popup.addResourceEmail')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <div className='ta-branches-resources__form__permissions__overlay__trigger' />
                  </HoverPopupTrigger>
                </HoverPopup>
                &nbsp;&nbsp;
              </>
            )}
            <Radio
              disabled={isPermissionsDisabled || isOwner}
              name='permissions'
              value={!isPermissionsDisabled ? 'ADMIN' : ''}
              label={t('global.admin')}
              inline
            />
            <Radio
              name='permissions'
              value='CUSTOM'
              label={t('global.custom')}
              inline
              disabled={isPermissionsDisabled || isOwner}
            />
          </Col>
        </Row>
        {isCustom && <BranchesPreviewResourceFormCustomPermissionsWrapper branch={branch} />}
      </FormSection>
    </div>
  )
}

export default BranchesResourceFormPermissions
