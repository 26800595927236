import React from 'react'
import {
  Title,
  FormSection,
  IconAvatar,
  t
} from '../../../Common'
import { UserPermissionTypeSummaryPermissions } from '../../../Beauties'
import './UserPermissionTypesPreview.css'

const UserPermissionTypeSummary = props => {
  let {
    userPermission,
    hideUserCount,
    branches,
    isLogedInUserAdmin,
    canLogedInUserReadUsers
  } = props
  userPermission = userPermission || {}
  hideUserCount = !!hideUserCount
  let {
    name,
    color,
    icon,
    users,
    id,
    permissions
  } = userPermission || {}
  users = users || []
  permissions = permissions || []
  const isAdminPermission = id === 'ADMIN'

  return (
    <div className='ta-permission-types__summary'>
      <div className='ta-permission-types__summary__header'>
        <IconAvatar
          color={color}
          icon={icon}
          className='ta-permission-types__summary__header__icon'
        />
        <div className='ta-permission-types__summary__header__content'>
          <div className='ta-permission-types__summary__header__content__name'>
            {name}
          </div>
          {(!hideUserCount &&
            <div className='ta-permission-types__summary__header__content__users'>
              {(users || []).length} {t('global.users')}
            </div>
          )}
        </div>
      </div>
      <FormSection>
        <Title
          size='m'
          isCompact
          label={t('globalSettings.form.tab.localUpdates')}
          icon='unlock-alt'
          ignoreTopOffset
        />
        <UserPermissionTypeSummaryPermissions
          isAdminPermission={isAdminPermission}
          isLogedInUserAdmin={isLogedInUserAdmin}
          canLogedInUserReadUsers={canLogedInUserReadUsers}
          permissions={permissions}
          branches={branches}
          isPermissionTypes
        />
      </FormSection>
    </div>
  )
}

export default UserPermissionTypeSummary
