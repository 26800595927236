export const calendarFields = `{
  id
  timezone
  accountId
  region
  workingWeeklyAllowancePlan {
    isActive
    times {
      begin
      end
    }
  }
  bookingWeeklyAllowancePlan {
    isActive
    times {
      begin
      end
    }
  }
}`

export const disclaimerConfigFields = `{
  active
  text
  displayOn
}`

export const allocationFields = `{
  serviceIds
  percentage
  name
}`

export const servicesAllocationFields = `{
  id
  companyId
  settings {
    shouldDisplayInfo
    shouldAllowBookingWidget
    hoursBeforeAllocationExpiration
  }
  allocations ${allocationFields}
  leftoverAllocation ${allocationFields}
  createdAt
  updatedAt
  ts
}`

export const customisationFields = `{
  id
  name
  type
  payload
}`

export const eugdprRuleFields = `{
  customerType
  customerTagId
  frequency
  frequencyUnit
}`

export const companySessionFields = `{
  value
  mode
}`

export const minMaxLeadTimeFields = `{
  value
  frequency
}`

export const cancelationLeadTimeFields = `{
  value
  frequency
}`

export const dateFormatFields = `{
  dateFormatPosition
  dateFormatYear
  dateFormatMonth
  dateFormatDay
  dateFormatSeparator
}`

export const timeFormatFields = `{
  timeFormatType
  timeFormatTwelve
  timeFormatTwentyFour
}`

export const enterpriseSettingsFields = `{
  callCentreEnabled
  snapToGridInterval
  internalSnapToGridInterval
  hasFacebookLogin
  hasTimifyLogin
  hasGuestBooking
  resourceEmailRemindersMinutes
  customerEmailRemindersMinutes
  showWidgetLanguageSelector
  isWidgetWeeklyView
  showWidgetCategoriesClosed
  showWidgetFirstCategoryOpen
  showWidgetTimezoneSelector
  showAddServiceCombinations
  customTermsAndConditionsUrl
  customPrivacyPolicyUrl
  disclaimerConfig ${disclaimerConfigFields}
  widgetExternalUrlRedirect
  hideServiceDuration
  hideResourcesSection
  hasNotifyOnResources
  hasNotifyOnCustomers
  showExternalIds
  enableCrossAccountsBookings
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  resourceRemindersEmailRecipients
  customerRemindersEmailRecipients
  resourceBookingCreateEmailRecipients
  resourceBookingUpdateEmailRecipients
  resourceBookingDeleteEmailRecipients
  customerBookingCreateEmailRecipients
  customerBookingUpdateEmailRecipients
  customerBookingDeleteEmailRecipients
  enforceResourcesAllowanceType
  enforceReschedulingTagRule
  enforceServiceDurationLimit
  resetCopyBookingClipboardAfterPaste
  hideFastWidgetTimeFrame
  allowUserLocaleForCustomers
  allowUserLocaleForResources
  allowOnlyGlobalCustomers
  EUGDPRRules ${eugdprRuleFields}
  forbidMoreThanOneBookingInFBW
  forbidBookingsInThePast {
    forbidAddingBookingsInThePast
    forbidUpdatingBookingsInThePast
    allowPartialUpdateOfBookingsInThePast
    forbidPastBookingsUpdate {
      title
      startTime
      duration
      resources
      prepAndFollowUp
      customer
      customDataFields
      notes
      tags
      participantsAndGuests
      groupPrice
    }
    forbidDeletingBookingInThePast
  }
  dailyAgenda {
    time,
    specificResourceCategoryIds
  }
  forbidCreatingAndUpdatingBookingsInThePast
  isCompanyTagsLocalUpdateForbidden
  isCustomersLocalUpdateForbidden
  isResourceLocalUpdateForbidden
  isCustomerFieldsLocalUpdateForbidden
  serviceLocalUpdates {
    isForbidden
    keys
  }
  courseLocalUpdates {
    isForbidden
    keys
  }
  serviceCombinationLocalUpdates {
    isForbidden
    keys
  }
  requiredEnterpriseAuth2FAType
  requiredAuth2FAType
  securitySessionConfig ${companySessionFields}
  securitySessionInactivityConfig ${companySessionFields}
  shouldUseMongoCustomerSearch
  publicHolidays {
    id
    publicHolidayKeys
    resourceCategoryIds
  }
  widgetConversions {
    facebookPixelId 
    googleAnalyticsId
    customMessage 
    messageTarget
  }
  maximumLeadTime ${minMaxLeadTimeFields}
  minimumLeadTime ${minMaxLeadTimeFields}
  servicesAllocation ${servicesAllocationFields}
  cancellationLeadTime ${cancelationLeadTimeFields}
  dateFormat ${dateFormatFields}
  timeFormat ${timeFormatFields}
  icsFileCustomisation {
    icsCustomisationCustomer {
      customerIcsSubject
      customerIcsAddress {
        icsAddressType
        icsAddress
      }
      customerIcsNotes {
        companyNameNote
        onlineBookableResourceNote
        dependantResourceNote
        bookedServiceNote
        bookedServiceDescriptionNote
        bookedServicePriceNote
        customDataFieldsNote {
          id
          internalId
        }
        eventCancellationLink
        hasCustomNote
        customNote
      }
    }
    icsCustomisationResource {
      resourceIcsSubject
      resourceIcsAddress {
        icsAddressType
        icsAddress
      }
      resourceIcsNotes {
        customerNameNote
        onlineBookableResourceNote
        dependantResourceNote
        bookedServiceNote
        bookedServiceDescriptionNote
        bookedServicePriceNote
        linkToTimifyCalendar
        customDataFieldsNote {
          id
          internalId
        }
        hasCustomNote
        customNote
      }
    }
  }
  callCenterLoginButton
}`

export const enterprisePermissionsFields = `{
  statisticsRead
  accountRead
  branchesRead
  branchesWrite
  branchesDelete
  branchesAccessWhitelist
  branchesAccessBlacklist
  tagsRead
  tagsWrite
  tagsDelete
  globalCustomersRead
  globalCustomersWrite
  globalCustomersDelete
  globalDataFieldsRead
  globalDataFieldsWrite
  globalDataFieldsDelete
  globalTagsRead
  globalTagsWrite
  globalTagsDelete
  globalServicesRead
  globalServicesWrite
  globalServicesDelete
  globalGroupsRead
  globalGroupsWrite
  globalGroupsDelete
  globalResourceCategoriesRead
  globalResourceCategoriesWrite
  globalResourceCategoriesDelete
  globalPermissionsGroupsRead
  globalPermissionsGroupsWrite
  globalPermissionsGroupsDelete
  settingsRead
  settingsWrite
  appsRead
  allowFulfilment
  usersRead
  usersWrite
  usersDelete
  permissionsGroupsRead
  permissionsGroupsWrite
  permissionsGroupsDelete
}`

export const chargebeeAccountData = `{
  appDaysToBlock
  isHidden
  noAutoCollect
  hasOfflinePayments
  paymentTermDays
  billingData {
    name
    address1
    address2
    zipCode
    city
    vatCountryCode
    vatNo
    viesNotAvailable
  }
  subscriptions {
    id
    description
    frequency
    nextInvoice
    startedAt
    amount
    amountEUR
    currencyCode
  }
}`

export const enterpriseAccountFields = `{
  id
  region
  customersMiddlewareUrl
  name
}`

export const accountUserAccountsFields = `{
  company {
    id
    name
  }
  enterprise ${enterpriseAccountFields}
  authScope
  accountId
  userId
  isConfirmed
  isConnected
  updatedAt
  isAdmin
  isCustom
  isOwner
  isBillingRead
  isBillingWrite
  roles
  email
  ts
}`

export const accountFields = `{
  userId
  pendingEmail
  timezone
  accounts {
    authScope
    accountId
    isConfirmed
    isAdmin
    userId
    isBillingRead
    isBillingWrite
    isCustom
    isOwner
    roles
    email
    enterprise {
      id
      region
      name
      slug
      lastStatisticsStateChange
      enterpriseCustomisation ${customisationFields}
      address { 
        placeId
        latitude
        longitude
        street
        streetNumber
        city
        country
        zipCode
        formatted
        details
      }
      settings {
        requiredEnterpriseAuth2FAType
        requiredAuth2FAType
      }
    }
    auth2FAs {
      type
      isAuth
      isActive
    }
    jwtAuth2FAs {
      type
      isAuth
      isActive
    }
  }
  enterprise {
    id
    region
    customersMiddlewareUrl
    name
    slug
    ownerEmail
    ownerName
    phone { number phone prefix country }
    totalCompanyPlans
    createdAt
    lastStatisticsStateChange
    address { 
      placeId
      latitude
      longitude
      street
      streetNumber
      city
      country
      zipCode
      formatted
      details
    }
    avatarPrefix
    locale
    settings ${enterpriseSettingsFields}
    rawSettings ${enterpriseSettingsFields}
    enterpriseCustomisation ${customisationFields}
    chargebeeId
    chargebee ${chargebeeAccountData}
  }
  enforcePasswordChange
  isAdmin
  isOwner
  isBillingRead
  isBillingWrite
  isCustom
  email
  authScope
  roles
  settings
  company {
    name
    calendar ${calendarFields}
    timezone
    currency
    hasStripeConnected
  }
  companySettings {
    showDurationDaySelector
  }
  companyId
  locale
  isConfirmed
  isVerified
  region
  userAccounts ${accountUserAccountsFields}
  auth2FAs {
    type
    isAuth
    isActive
  }
  jwtAuth2FAs {
    type
    isAuth
    isActive
  }
  enterprisePermissions ${enterprisePermissionsFields}
  enterprisePermissionsGroupId
}`

export const resourceCategoryFields = `{
  id
  ts
  name
  isDependency
  orderIndex
  isDefault
  companyId
  externalId
  internalId
  resources {
    id
    name
  }
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
}`

export const customerFieldsForList = `{
  total
  data {
    id
    ts
    fullName
    companyName
    internalId
    email
    isUpdatedLocally
    isGloballyDeleted
    allowedBranchIds
    allowedBranchExternalIds
    locallyUpdatedBranches
    companyId
    totalBookings
    phone {
      prefix
      country
      number
      phone 
    }
  }
}`

export const customerFields = `{
  id
  externalId
  internalId
  ts
  fullName
  companyName
  locallyUpdatedBranches
  email
  avatarUrl
  tagIds
  isUpdatedLocally
  isGloballyDeleted
  allowedBranchIds
  allowedBranchExternalIds
  companyId
  totalBookings
  phone {
    prefix
    country
    number
    phone 
  }
  fields {
    id
    value
    type
  }
}`

export const resourcePermissionsFields = `{
  accountRead
  calendarReadSet {
    specificResourceCategoryIds
    specificResourceIds
  }
  calendarWrite
  calendarDelete
  shiftRead
  shiftWrite
  shiftDelete
  statisticsRead
  notificationsRead
  customersReadSet {
    specificResourceCategoryIds
    specificResourceIds
  }
  customersWrite
  customersDelete
  appsRead
  resourcesReadSet {
    specificResourceCategoryIds
    specificResourceIds
  }
  resourcesWrite
  resourcesDelete
  resourcesReadResourceIds
  resourcesReadResourceCategoryIds
  calendarReadResourceIds
  calendarReadResourceCategoryIds
  customersReadResourceIds
  customersReadResourceCategoryIds
}`

export const dayFields = `{
  isActive
  times {
    begin
    end
  }
}`

export const courseFields = `{
  id
  externalId
  internalId
  ts
  name
  description
  orderIndex
  color
  duration
  durationsPattern
  durationBefore
  durationAfter
  price
  isBookable
  hasOnlinePayment
  isPaymentMandatory
  isStalled
  isUpdatedLocally
  categoryId
  resourcesCount
  maxParticipants
  extraPersonsPerParticipant
  isGloballyDeleted
  isUpdatedLocally
  allowedBranchIds
  allowedBranchExternalIds
  companyId
  locallyUpdatedBranches
  dependencies {
    name
    isStalled
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
  calendar {
    bookingWeeklyAllowancePlan ${dayFields}
    workingWeeklyAllowancePlan ${dayFields}
  }
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
}`

export const courseCategoryFields = `{
  id
  name
  orderIndex
  isDefault
  internalId
  externalId
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
}`

export const branchResourceFields = `{
  id
  companyId
  externalId
  abbreviation
  ts
  name
  totalBookings
  timezone
  createdAt
  updatedAt
  avatarUrl
  orderIndex
  isBookable
  hasOnlineWarning
  categoryId
  email
  color
  account {
    id
    isVerified
    isConfirmed
    isConfirmationSent
    isAdmin
    isCustom
    companyPermissions ${resourcePermissionsFields}
    roles
    providers
    ts
    userId
  }
  calendar {
    timezone
    workingWeeklyAllowancePlan ${dayFields}
    bookingWeeklyAllowancePlan ${dayFields}
  }
  userLoginBlockedUntil
}`

// export const accountFields = `{
//   -userId
//   -email
//   appId
//   facebookId
//   -isVerified
//   isVerificationSent
//   -locale
//   timezone
//   -authScope
//   accountId
//   -region
//   roles
//   -isConfirmed
//   isConfirmationSent
//   isCustom
//   isAdmin
//   isOwner
//   -rules
//   -settings
//   expiresAt
//   longSession
//   accounts
//   ts
//   developer: Developer
//   developerId: ID
//   booker: Booker
//   bookerId: ID
//   -company: Company
//   -companyId: ID
//   companySettings: CompanyUserSettings
//   resourceIds: [ID]
//   resourceCategoryIds: [ID]
//   -enterprise: Enterprise
//   enterpriseId: ID
//   companyPermissions: CompanyPermissions
// }`

export const userFields = `{
  userId
  email
  isVerified
  locale
  isVerificationSent
  roles
  createdAt
}`

export const profileFields = `{
  userId
  email
  isVerified
  locale
  isVerificationSent
  roles
  pendingEmail
}`

export const enterpriseUserProfileFields = `{
  id
  email
  name
  avatarUrl
  phone {
    number
    country
    prefix
    phone
  }
  isConfirmed
  roles
}`
export const enterpriseProfileFields = `{
  id
  name
  slug
  ownerEmail
  ownerName
  phone {
    number
    country
    prefix
    phone
  }
  address { 
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
    details
  }
  locale
  avatarPrefix
  isPublished
  settings ${enterpriseSettingsFields}
  rawSettings ${enterpriseSettingsFields}
}`

export const enterpriseCustomerFields = `{
  id
  ts
  defaultId
  internalId
  type
  label
  externalId
  displayOnHover
  displayOnBookingForm
  description
  placeholder
  translationKey
  isPublished
  isActive
  hasOverwrite
  isAfterAuth
  isMandatoryOnline
  isMandatoryOffline
  orderIndex
  isDefault
  isUniqueKey
  categoryId
  orderIndex
  specificCourseIds
  specificServiceIds
  isUpdatedLocally
  isGloballyDeleted
  allowedBranchIds
  allowedBranchExternalIds
  companyId
  locallyUpdatedBranches
  displayOnHover
  displayOnBookingForm
  selectOptions {
    label
    value
    translationKey
  }
}`

export const enterpriseCustomerFieldCategoryFields = `{
  id
  ts
  defaultId
  internalId
  name
  isUpdatedLocally
  translationKey
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  orderIndex
  isDefault
  externalId
}`

export const customerFieldCategoryFields = `{
  id
  ts
  defaultId
  internalId
  externalId
  name
  isUpdatedLocally
  translationKey
  allowedBranchIds
  allowedBranchExternalIds
  orderIndex
  isDefault
}`

export const tokensFields = `{
  accessToken
  refreshToken
  sessionDuration
  account ${accountFields}
}`

// Users
export const enterpriseUsersListFields = `{
  id
  name
  email
  enterprisePermissionsGroupId
  phone {
    phone
    prefix
    number
    country
  }
  roles
  isConfirmed
  avatarUrl
}`

export const enterpriseUsersFields = `{
  id
  name
  email
  enterprisePermissionsGroupId
  phone {
    phone
    prefix
    number
    country
  }
  roles
  rules ${enterprisePermissionsFields}
  isConfirmed
  avatarUrl
  isPermissionUpdateAllowedByEnterpriseIssuer
}`

export const userPermissionsGroupsEnterpriseFields = `{
  id
  enterpriseId
  externalId
  name
  icon
  color
  permissions ${enterprisePermissionsFields}
  createdAt
  updatedAt
  isDefault
  ts
  isUpdateAllowedByIssuer
}`

// Tags
export const enterpriseTagsFields = `{
  id
  name
  color
  note
  externalId
}`
export const enterpriseTagsListFields = `{
  id
  name
  color
  note
  externalId
}`

// Branches
export const branchesListFields = `{
  id
  name
  personName
  region
  locale
  timezone
  info
  currency
  tags {
    id
    name
    color
    note
  }
  tagIds
  contactEmail
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
  }
  companyTags { id name color isForCustomers isForEvents }
  phone {
    prefix
    country
    number
    phone
  }
  plan
  createdAt
  onlineStatus {
    wantsToBeOnline
    isOnline
  }
  settings {
    showExternalIds
    enableCrossAccountsBookings
    customerEmailRemindersMinutes
    resourceEmailRemindersMinutes
    resourceRemindersEmailRecipients
  }
  timezone
  ownerEmail
  hasStripeConnected
  webappCustomisation {
    id
    name
    type
    payload
  }
  lastStatisticsStateChange
  externalId
}`
export const branchPreviewDetailsFields = `{
  id
  externalId
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
  }
  phone {
    prefix
    number
  }
  tags {
    id
    name
    color
    note
  }
  ownerEmail
  info
  resources {
    color
    name
    id
    userLoginBlockedUntil
    avatarUrl
  }
  address {
    latitude
    longitude
    formatted
    details
  }
}`
export const branchPreviewResourcesFields = `{
  resources ${branchResourceFields}
  resourceCategories {
    id
    externalId
    orderIndex
    name
    translationKey
    isDefault
    isDependency
    createdAt
    updatedAt
    ts
  }
}`
export const branchPreviewServicesFields = `{
  services {
    id
    externalId
    internalId
    companyId
    ts
    name
    description
    orderIndex
    color
    duration
    durationsPattern
    durationBefore
    durationAfter
    price
    isBookable
    isStalled
    hasOnlinePayment
    isPaymentMandatory
    hasOnlineWarning
    hasOnlinePaymentWarning
    categoryId
    resourcesCount
    isUpdatedLocally
    isGloballyDeleted
    isCombination
    isCombinationPriceOverwritten
    hasCombinationSameResourcesPreference
    combinationServiceIds
    customerEmailRemindersMinutes
    resourceEmailRemindersMinutes
    dependencies {
      name
      isStalled
      workInParallel
      specificResourceCategoryIds
      specificResourceIds
    }
    calendar {
      bookingWeeklyAllowancePlan ${dayFields}
      workingWeeklyAllowancePlan ${dayFields}
    }
  }
  serviceCategories {
    id
    orderIndex
    name
    translationKey
    isDefault
  }
}`
export const branchPreviewGroupsFields = `{
  courses ${courseFields}
  courseCategories {
    isCourse
    id
    externalId
    orderIndex
    name
    translationKey
    isDefault
  }
}`
export const branchPreviewStatisticsFields = `{
  statistics {
    overallEvents {
      total
      totalOnline
      totalOffline
    }
    overallCustomers {
      total
      totalOnline
      totalOffline
    }
    currentMonthEvents {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    currentMonthCustomers {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    previousMonthEvents {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    previousMonthCustomers {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    totalResources
    cumulatedSales
    firstBookingDay
    revenueService
    revenueResource
    lastSevenDays
    nextSevenDays
    bookingsAverage
    eventsByDayOfWeek
    eventsByDayOfWeekUTC
    eventsByHours
    eventsByHoursUTC
  }
}`

export const branchPreviewStatisticsV2Fields = `{
  averageBookingGuestsPerCourseEvent
  averageBookingParticipantsPerCourseEvent
  averageDailyServiceBookings
  averageWeeklyServiceBookings
  averageWeeklyCancelledBookings
  blockerDenialsStatistics { hours days }
  cancelledEventsByDay
  eventSignupsByDaysOfWeek
  eventSignupsByDays
  eventSignupsByDaysWithGuests
  eventSignupsByHoursOfDay
  eventsByDays
  onlineMarketingUtmStatistics
  sickDenialsStatistics { hours days }
  topBookingBookableResources
  topBookingDependencyResources
  topBookingResources
  topBookingServices
  topBookingServicesWithPrice
  topCancelledBookingServices
  totalCancelledEvents
  totalCancelledInternalEvents
  totalCancelledOnlineEvents
  totalCourseBookingGuests
  totalCourseBookingParticipants
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEvents
  totalPastCourseEventSeats
  totalFutureCourseEventSeats
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalCourseInStorePaidRevenue
  totalCourseOnlinePaidRevenue
  totalCourseRevenue
  totalCustomers
  totalEvents
  totalFutureCourseEventBookedSeats
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalCustomers
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineCustomers
  totalOnlinePaidRevenue
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalPastCourseEventBookedSeats
  totalRescheduledEvents
  totalResources
  totalResourcesOnlineBookedTimes { hours days}
  totalResourcesOnlineTimes { hours days}
  totalResourcesWorkingTimes { hours days}
  totalResourcesWorkingBookedTimes { hours days}
  totalRevenue
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
  totalServiceInStorePaidRevenue
  totalServiceOnlinePaidRevenue
  totalServiceRevenue
  vacationDenialsStatistics { hours days }
}`
export const branchPreviewStatisticsV2FieldsOverall = `{
  topBookingServices
  topCancelledBookingServices
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEventGuests
  totalCourseEventParticipants
  totalCourseEvents
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalCourseInStorePaidRevenue
  totalCourseOnlinePaidRevenue
  totalCourseRevenue
  totalCustomers
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalCustomers
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineCustomers
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalRevenue
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
  totalServiceInStorePaidRevenue
  totalServiceOnlinePaidRevenue
  totalServiceRevenue
}`
export const enterpriseStatisticsV2FieldsOverall = `{
  topBookingServices
  topCancelledBookingServices
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEventGuests
  totalCourseEventParticipants
  totalCourseEvents
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalCourseInStorePaidRevenue
  totalCourseOnlinePaidRevenue
  totalCourseRevenue
  totalCustomers
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalCustomers
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineCustomers
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalRevenue
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
  totalServiceInStorePaidRevenue
  totalServiceOnlinePaidRevenue
  totalServiceRevenue
}`
export const branchPreviewStatisticsV2FieldsOverallTotalEvents = `{
  totalEvents(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsOverallTotalOnlineEvents = `{
  totalOnlineEvents(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsOverallTotalInternalEvents = `{
  totalInternalEvents(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsTotalEvents = `{
  totalEvents(filter: $partialStatsFilter)  
}`
export const branchPreviewStatisticsV2FieldsTotalOnlineEvents = `{
  totalOnlineEvents(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsTotalInternalEvents = `{
  totalInternalEvents(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsTotalCancelledEvents = `{
  totalCancelledEvents(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsSickDenialsStatistics = `{
  sickDenialsStatistics(filter: $partialStatsFilter) { hours days}  
}`
export const branchPreviewStatisticsV2FieldsVacationDenialsStatistics = `{
  vacationDenialsStatistics(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsBlockerDenialsStatistics = `{
  blockerDenialsStatistics(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsTotalResourcesWorkingTimes = `{
  totalResourcesWorkingTimes(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsTotalResourcesWorkingBookedTimes = `{
  totalResourcesWorkingBookedTimes(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsTotalResourcesWorkingOnlineAvailabilityTimes = `{
  totalResourcesOnlineAvailabilityTimes(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsTotalResourcesOnlineBookedTimes = `{
  totalResourcesOnlineBookedTimes(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsTotalResourcesOnlineTimes = `{
  totalResourcesOnlineTimes(filter: $partialStatsFilter) { hours days}
}`
export const branchPreviewStatisticsV2FieldsSingleBookings = `{
  totalServiceEventsWithTitle(filter: $partialStatsFilter)
  totalServiceEvents(filter: $partialStatsFilter)
  totalOnlineServiceEventsWithId(filter: $partialStatsFilter)
  totalInternalServiceEventsWithId(filter: $partialStatsFilter)
  totalServiceEventsWithId(filter: $partialStatsFilter)
}`
export const branchPreviewStatisticsV2FieldsCourseBookings = `{
  totalCourseEventsWithTitle(filter: $partialStatsFilter)
  totalCourseEventsWithId(filter: $partialStatsFilter)
  totalCourseEvents(filter: $partialStatsFilter)
}`

export const branchHistoryStatisticsFields = `{
  total
  limit
  page
  data {
    statisticsLevel
    statisticsType
    version
    operation
    model
    companyId
    region
    timezone
    locale
    plan
    companySize
    slug
    enterpriseId
    externalId
    country
    isOnline
    isBranch
    hour
    day
    month
    year
    timestamp
    hourTZ
    dayTZ
    monthTZ
    yearTZ
    timestampTZ
    data
  }
}`
export const branchesFields = `{
  id
  externalId
  name
  region
  locale
  timezone
  currency
  info
  tags {
    id
    name
    color
    note
  }
  tagIds
  contactEmail
  ownerEmail
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
  }
  plan
  createdAt
  onlineStatus {
    wantsToBeOnline
    isOnline
  }
  statistics {
    overallEvents {
      total
      totalOnline
      totalOffline
    }
    overallCustomers {
      total
      totalOnline
      totalOffline
    }
    currentMonthEvents {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    currentMonthCustomers {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    previousMonthEvents {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    previousMonthCustomers {
      total
      totalOnline
      totalOffline
      totalUTC
      totalOnlineUTC
      totalOfflineUTC
    }
    totalResources
    cumulatedSales
    firstBookingDay
    revenueService
    revenueResource
    lastSevenDays
    nextSevenDays
    bookingsAverage
    eventsByDayOfWeek
    eventsByDayOfWeekUTC
    eventsByHours
    eventsByHoursUTC
  }
  resources {
    id
    externalId
    orderIndex
    categoryId
    companyId
    totalBookings
    name
    color
    isBookable
    avatarUrl
    email
    hasOnlineWarning
    category { id }
    calendar ${calendarFields}
    account {
      userId
      email
      isConfirmed
      isConfirmationSent
      isVerified
      isVerificationSent
      roles
    }
    timezone
    createdAt
    updatedAt
    ts
  }
  resourceCategories {
    id
    externalId
    orderIndex
    name
    translationKey
    isDefault
    isDependency
    createdAt
    updatedAt
    ts
  } 
  services {
    id
    externalId
    orderIndex
    categoryId
    companyId
    name
    color
    isBookable
    hasOnlineWarning
    hasOnlinePaymentWarning
    description
    duration
    price
    hasOnlinePayment
    isPaymentMandatory
    isStalled
    hasStrictDependencies
    maxParticipants
    extraPersonsPerParticipant
    resourcesCount
    createdAt
    updatedAt
  }
  serviceCategories {
    id
    orderIndex
    name
    translationKey
    isDefault
  }
  courses {
    id
    externalId
    orderIndex
    categoryId
    name
    color
    isBookable
    hasOnlineWarning
    hasOnlinePaymentWarning
    duration
    price
    hasOnlinePayment
    isPaymentMandatory
    isStalled
    hasStrictDependencies
    maxParticipants
    extraPersonsPerParticipant
    resourcesCount
    createdAt
    updatedAt
  }
  courseCategories {
    isCourse
    id
    externalId
    orderIndex
    name
    translationKey
    isDefault
  }
}`

export const enterpriseServicesFields = `{
  id
  externalId
  internalId
  companyId
  ts
  name
  description
  orderIndex
  color
  duration
  durationsPattern
  durationBefore
  durationAfter
  price
  isBookable
  allowedBranchIds
  isStalled
  hasOnlinePayment
  isPaymentMandatory
  hasOnlineWarning
  hasOnlinePaymentWarning
  categoryId
  resourcesCount
  isUpdatedLocally
  isGloballyDeleted
  isCombination
  isCombinationPriceOverwritten
  hasCombinationSameResourcesPreference
  combinationServiceIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  localUpdates
  locallyUpdatedBranches
  dependencies {
    name
    isStalled
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
}`

export const enterpriseServicesCategoryFields = `{
  id
  name
  orderIndex
  isDefault
  externalId
  internalId
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  companyId
}`

export const appSubscriptionFields = `{
  country
  currencyCode
  planName
  monthlyPrice
  activationFeePrice
  includedUnits
  limitValue
  nextLimitValue
  usageUnitPrice
  usageMinUnitPrice
  usageMaxUnitPrice
}`

export const appPlanFields = `{
  name
  description
  monthlyPrice
  usageUnitPrice
  usageMinUnitPrice
  usageMaxUnitPrice
  includedUnits
}`

export const appInstallationFields = `{
  hasPayment
  plans ${appPlanFields}
  hasTrial
  isBlocked
  blockedReasonCode
  hasDuePayment
  duePaymentAt
  activationFeePrice
  activatedAt
  appPlanName
  monthlyPrice
  installedAt
  subscribedAt
  cancelledAt
  unsubscribedAt
  renewedAt
  renewAt
  isTrial
  trialEndAt
  trialDays
  hasUsage
  trialUnits
  remainingUnits
  usageUnitPrice
  usageMinUnitPrice
  usageMaxUnitPrice
  includedUnits
  limitValue
  nextLimitValue
  limitReached
  unitDescription
  unitDescriptionPlural
  priceDescription
  isPaidByEnterprise
  extraConsumedCharges
  allowedDaysBeforeBlock
  blockingAt
  currencyCode
}`

export const allowedCountriesFields = `{
  hasPayment
  hasTrial
  hasUsage
  country
  activationFeePrice
  trialUnits
  unitDescription
  unitDescriptionPlural
  priceDescription
  currencyCode
  plans {
    usageMaxUnitPrice
    usageMinUnitPrice
    usageUnitPrice
    name
    description
  }
}`

export const appFields = `{
  id
  name
  version
  imageUrl
  categories
  isPublic
  developer {
    companyName
    name
  }
  plan
  bmSidebarSize
  installedVersion
  appInstallation ${appInstallationFields}
  allowedCountries ${allowedCountriesFields}
  ts
}`

export const appPreviewFields = `{
  id
  name
  version
  imageUrl
  gallery
  roles {
    name
    types
  }
  benefits
  functionality
  tutorialUrl
  privacyUrl
  plan
  categories
  isPublic
  developer {
    website
  }
  installedVersion
  bmSidebarSize
  description
  copyright
  appInstallation ${appInstallationFields}
  allowedCountries ${allowedCountriesFields}
  ts
}`

export const enterpriseStatisticsFields = `{
  overallEvents {
    total
    totalOnline
    totalOffline
  }
  overallCustomers {
    total
    totalOnline
    totalOffline
  }
  currentMonthEvents {
    total
    totalOnline
    totalOffline
  }
  currentMonthCustomers {
    total
    totalOnline
    totalOffline
  }
  previousMonthEvents {
    total
    totalOnline
    totalOffline
  }
  previousMonthCustomers {
    total
    totalOnline
    totalOffline
  }
  revenueService
  topBranchesByEvents
  eventsByDayOfWeek
  eventsByHours
}`

export const enterpriseStatisticsV2Fields = `{
  averageBookingGuestsPerCourseEvent
  averageBookingParticipantsPerCourseEvent
  averageDailyServiceBookings
  averageServiceBookingsPerResource
  averageWeeklyServiceBookings
  averageWeeklyCancelledBookings
  blockerDenialsStatistics { hours days }
  eventSignupsByDaysOfWeekUTC
  eventSignupsByDaysUTC
  eventSignupsByDaysWithGuestsUTC
  eventSignupsByHoursOfDayUTC
  eventSignupsByHoursOfDayWithGuestsUTC
  eventsByDaysUTC
  onlineMarketingUtmStatistics
  sickDenialsStatistics { hours days }
  topBookingBookableResources
  topBookingBranches
  topBookingDependencyResources
  topBookingResources
  topBookingServices
  topBookingServicesWithPrice
  totalCancelledEvents
  totalCancelledInternalEvents
  totalCancelledOnlineEvents
  totalCourseBookingGuests
  totalCourseBookingParticipants
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEvents
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalCourseInStorePaidRevenue
  totalCourseOnlinePaidRevenue
  totalCourseRevenue
  totalCustomers
  totalEvents
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalCustomers
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineCustomers
  totalOnlinePaidRevenue
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalRescheduledEvents
  totalResources
  totalResourcesOnlineBookedTimes { hours days}
  totalResourcesOnlineTimes { hours days}
  totalResourcesWorkingTimes { hours days}
  totalResourcesWorkingBookedTimes { hours days}
  totalRevenue
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
  totalServiceInStorePaidRevenue
  totalServiceOnlinePaidRevenue
  totalServiceRevenue
  vacationDenialsStatistics { hours days }
}`

export const companyTag = `{
  id
  externalId
  name
  color
  allowedBranchIds
  allowedBranchExternalIds
  internalId
  companyId
  isUpdatedLocally
  locallyUpdatedBranches
  orderIndex
  isForEvents
  isForCustomers
  customersCount
  eventsCount
  ts
}`

export const branchServiceFields = `{
  id
  externalId
  companyId
  ts
  name
  description
  orderIndex
  color
  duration
  durationsPattern
  durationBefore
  durationAfter
  price
  isBookable
  isStalled
  hasOnlinePayment
  isPaymentMandatory
  hasOnlineWarning
  hasOnlinePaymentWarning
  categoryId
  resourcesCount
  isUpdatedLocally
  isGloballyDeleted
  isCombination
  isCombinationPriceOverwritten
  hasCombinationSameResourcesPreference
  combinationServiceIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  dependencies {
    name
    isStalled
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
  calendar {
    bookingWeeklyAllowancePlan ${dayFields}
    workingWeeklyAllowancePlan ${dayFields}
  }
}`

export const branchCourseFields = `{
  id
  externalId
  ts
  name
  description
  orderIndex
  color
  duration
  durationsPattern
  durationBefore
  durationAfter
  price
  isBookable
  hasOnlinePayment
  isPaymentMandatory
  isStalled
  categoryId
  resourcesCount
  maxParticipants
  extraPersonsPerParticipant
  dependencies {
    name
    isStalled
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
}`

export const branchCustomerFields = `{
  id
  externalId
  ts
  fullName
  companyName
  companyId
  totalBookings
  email
  avatarUrl
  tagIds
  phone {
    prefix
    country
    number
    phone 
  }
  fields {
    id
    value
    type
  }
}`

export const branchCustomerFieldFields = `{
  id
  ts
  defaultId
  type
  label
  description
  placeholder
  translationKey
  isPublished
  isActive
  hasOverwrite
  isAfterAuth
  isMandatoryOnline
  isMandatoryOffline
  orderIndex
  isDefault
  isUniqueKey
  categoryId
  orderIndex
  specificCourseIds
  specificServiceIds
  selectOptions {
    label
    value
    translationKey
  }
}`

export const branchCustomeCategoiresFields = `{
  id
  ts
  defaultId
  name
  translationKey
  orderIndex
  isDefault
}`

export const paymentMethodFields = `{
  isDefault
  type
  id
  data
  createdAt
}`

export const invoiceFields = `{
  id
  day
  amount
  currencyCode
  beginDay
  endDay
  description
  status
  isCreditNote
  plan
}`

export const permissionType = `{
  id
  name
  isGlobal
  icon
  color
  internalId
  externalId
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  permissions {
    accountRead
    calendarReadSet {
      specificResourceCategoryIds
      specificResourceIds
    }
    calendarReadResourceIds
    calendarReadResourceCategoryIds
    calendarWrite
    calendarDelete
    statisticsRead
    notificationsRead
    customersReadSet {
      specificResourceCategoryIds
      specificResourceIds
    }
    customersReadResourceIds
    customersReadResourceCategoryIds
    customersWrite
    customersDelete
    appsRead
    resourcesReadSet {
      specificResourceCategoryIds
      specificResourceIds
    }
    resourcesReadResourceIds
    resourcesReadResourceCategoryIds
    resourcesWrite
    resourcesDelete
    shiftRead
    shiftWrite
    shiftDelete
  }
  isGloballyDeleted
  isUpdatedLocally
  isDefault
}`

export const activityLogFields = `{
  updatedFields
  type
  resourceIds
  operation
  isOld
  isDeleted
  isCourse
  id
  data
  createdAt
  changedFieldsDifferences
  changedFields
  addedFrom
  addedByUserId
  addedByResourceName
  addedByResourceId
  addedByName
  addedByCustomer
  addedByAuthScope
  addedByApp
}`

export const systemMessagesFields = `{
  updatedAt
  type
  timifyUserName
  timifyUserId
  status
  region
  id
  expiresAt
  enterpriseId
  durationHours
  createdAt
  companyId
  accessUserName
  accessUserId
  acceptedAt
}`
