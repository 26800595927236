import React from 'react'
import {
  UsersList,
  UsersTitle
} from '../../Beauties'
import {
  FixedContentHeader,
  FixedContentBody,
  FixedContent,
  t,
  Form,
  Row,
  Col,
  Input,
  SingleSelect
} from '../../Common'

import './Users.css'

const Users = props => {
  const classNames = ['ta-users']
  const { users, userPermissionTypes, search, usersWrite } = props
  const userGroups = [
    { label: t('account.list.item.title.owner'), value: 'OWNER' },
    { label: t('global.custom'), value: 'CUSTOM' }
  ].concat(userPermissionTypes.map(userPermissionType => ({ label: userPermissionType.name, value: userPermissionType.id })))

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <UsersTitle usersWrite={usersWrite} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form name='usersFilters'>
            <Row>
              <Col size={50}>
                <Input name='search' label={t('users.list.search.placeholder')} clearable />
              </Col>
              <Col size={50}>
                <SingleSelect
                  name='userGroup'
                  label={t('global.userGroup.label')}
                  options={userGroups}
                  clearable
                />
              </Col>
            </Row>
          </Form>
          <UsersList users={users} userPermissionTypes={userPermissionTypes} search={search} />
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Users
