import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  BorderedBox,
  FormText,
  Row,
  Col,
  Error,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Select,
  FormGroup,
  t,
  Button
} from '../../../Common'

import './DependenciesInput.css'

class DependenciesInput extends Component {
  constructor (props, context) {
    super(props)

    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.generateEmptyDependency = this.generateEmptyDependency.bind(this)
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.onResourceChange = this.onResourceChange.bind(this)
    this.onWorkInParalelChange = this.onWorkInParalelChange.bind(this)
    this.addDependency = this.addDependency.bind(this)
    this.deleteDependency = this.deleteDependency.bind(this)
    this.renderCategory = this.renderCategory.bind(this)
    this.renderResources = this.renderResources.bind(this)
    this.renderWorkInParale = this.renderWorkInParale.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.state = {
      focusedItem: null,
      focusedIndex: null
    }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOnFocus (index, item) {
    this.setState({
      focusedItem: item,
      focusedIndex: index
    })
  }

  handleOnBlur () {
    this.setState({
      focusedItem: null,
      focusedIndex: null
    })
  }

  generateEmptyDependency () {
    const { categories } = this.props
    return {
      categoryId: categories.length === 1 ? categories[0].value : null,
      resourceIds: null,
      workInParallel: false
    }
  }

  onCategoryChange (selectValue, index) {
    const { name, formName, resources, categories, dependencies, errors } = this.props
    if (!dependencies[index]) return
    dependencies[index].categoryId = (selectValue && selectValue.value) || null
    dependencies[index].resourceIds = []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  onResourceChange (selectValues, index) {
    const { name, formName, resources, categories, dependencies, errors } = this.props
    if (!dependencies[index]) return

    dependencies[index].resourceIds = selectValues.length > 0
      ? selectValues[selectValues.length - 1].value === 'all'
        ? ['all']
        : [...selectValues.map(item => item.value).filter(value => value !== 'all')]
      : null

    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  onWorkInParalelChange (value, index) {
    const { name, formName, resources, categories, dependencies, errors } = this.props
    if (!dependencies[index]) return
    dependencies[index].workInParallel = !!value
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  addDependency () {
    const { name, formName, resources, categories, dependencies, errors } = this.props
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies, this.generateEmptyDependency()],
        resources,
        categories,
        errors
      }
    })
  }

  deleteDependency (index) {
    const { name, formName, resources, categories, dependencies, errors } = this.props
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies.filter((item, key) => key !== index)],
        resources,
        categories,
        errors
      }
    })
  }

  // RENDER

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  renderCategory (item, index) {
    let { categories, isBranchForm, errors, disabled, allowedSet } = this.props
    errors = errors || []
    categories = categories || []
    const { categoryId } = item || {}
    const selectedCategory = categories.find(item => item.value === categoryId) || {}
    const isSelectedCategoryDefault = selectedCategory.label === 'default'
    const isOnlyDefaultCategory = !!(categories.length === 1)
    const isDependenciesDisabled = allowedSet && !allowedSet?.includes('dependencies')
    const isDisabled = (isOnlyDefaultCategory && isSelectedCategoryDefault) || isDependenciesDisabled
    const displayCategories = categories.map(item => {
      return {
        ...item,
        label: (item.label === 'default') ? t('resources.list.sectionDefault.title') : item.label
      }
    }) || []
    if (isBranchForm) displayCategories.unshift({ value: 'all', label: t('global.all'), isDependency: false })

    return (
      <HoverPopup disabled={!isDependenciesDisabled} className='ta-service-form-inputs__popup'>
        <HoverPopupContent position='top'>
          {t('globalSettings.form.section.attributes.disabled')}
        </HoverPopupContent>
        <HoverPopupTrigger>
          <div className={`ta-dependencies-input__select-container ${disabled ? 'disabled' : ''}`}>
            <div className='ta-dependencies-input__select-container__label'>
              <span>{t('servicesGroups.form.section.resources.fieldResourceCategory.label')}</span>
            </div>
            <FormGroup
              filled={!!item.categoryId}
              labelText={t('servicesGroups.form.section.resources.fieldResourceCategory.placeholder')}
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={item.categoryId}
                arrowRenderer={this.renderArrows}
                onChange={(selectValue) => this.onCategoryChange(selectValue, index)}
                onFocus={() => this.handleOnFocus(index, 'category')}
                onBlur={this.handleOnBlur}
                options={displayCategories}
                searchable={false}
                disabled={isDisabled}
                autoComplete='off'
                hasError={errors.length > 0}
                noLabel
              />
            </FormGroup>
          </div>
        </HoverPopupTrigger>
      </HoverPopup>
    )
  }

  renderResources (item, index) {
    const { focusedIndex, focusedItem } = this.state
    let { resources, categories, errors, disabled, allowedSet } = this.props
    errors = errors || []
    resources = resources || []
    categories = categories || []
    const filteredResources = item.categoryId === 'all'
      ? resources
      : resources.filter(resource => resource.categoryId === item.categoryId)
    const options = [{ value: 'all', label: t('global.all') }, ...filteredResources]
    const category = item.categoryId === 'all'
      ? { value: 'all', label: t('global.all'), isDependency: false }
      : categories.find(category => category.value === item.categoryId) || {}
    const isDependenciesDisabled = allowedSet && !allowedSet?.includes('dependencies')

    return (
      <div className={`ta-dependencies-input__select-container ${disabled ? 'disabled' : ''}`}>
        <div className='ta-dependencies-input__select-container__label'>
          <span>{category.label === 'default' ? t('calendar.filter.yourResources.title') : category.label}:</span>
        </div>
        <HoverPopup disabled={!isDependenciesDisabled} className='ta-service-form-inputs__popup'>
          <HoverPopupContent position='top'>
            {t('globalSettings.form.section.attributes.disabled')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <FormGroup
              focused={focusedIndex === index && focusedItem === 'resource'}
              filled={item.resourceIds && item.resourceIds.length > 0}
              labelText={t('servicesGroups.form.section.resources.fieldResource.placeholder')}
            >
              <Select
                disabled={isDependenciesDisabled}
                inlineTags
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={item.resourceIds}
                arrowRenderer={this.renderArrows}
                onChange={(selectValues) => this.onResourceChange(selectValues, index)}
                onFocus={() => this.handleOnFocus(index, 'resource')}
                onBlur={this.handleOnBlur}
                multi
                searchable
                options={options}
                autoComplete='off'
                hasError={errors.length > 0}
                noLabel
              />
            </FormGroup>
          </HoverPopupTrigger>
        </HoverPopup>
      </div>
    )
  }

  renderWorkInParale (value, index) {
    let { isCourse, plan, disabled, allowedSet } = this.props
    plan = plan || 'CLASSIC'
    const yesClassNames = ['ta-radio']
    const noClassNames = ['ta-radio']
    if (plan === 'ENTERPRISE' && value) yesClassNames.push('active')
    if (plan !== 'ENTERPRISE') yesClassNames.push('disabled')
    if (!value) noClassNames.push('active')
    if (disabled) {
      yesClassNames.push('disabled')
      noClassNames.push('disabled')
    }
    // TODO: Update renderWorkInParale to consider also the plan
    // Make it available only for enterprise users and show enterpriseOnly label

    return (
      <div>
        <FormText>
          {t('servicesGroups.form.service.section.resources.dependency.options.title')}
        </FormText>
        <Row>
          <Col>
            <div className='ta-radio-wrapper'>
              <div className={noClassNames.join(' ')} onClick={() => (plan === 'ENTERPRISE' && !disabled) ? this.onWorkInParalelChange(false, index) : undefined}>
                {t(isCourse
                  ? 'servicesGroups.form.group.section.resources.dependency.options.one'
                  : 'servicesGroups.form.service.section.resources.dependency.options.one'
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <HoverPopup disabled={plan === 'ENTERPRISE' && !disabled}>
              <HoverPopupContent position='top'>
                {!allowedSet?.includes('dependencies') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className='ta-radio-wrapper'>
                  <div className={yesClassNames.join(' ')} onClick={() => (plan === 'ENTERPRISE' && !disabled) ? this.onWorkInParalelChange(true, index) : undefined}>
                    {t(isCourse
                      ? 'servicesGroups.form.group.section.resources.dependency.options.all'
                      : 'servicesGroups.form.service.section.resources.dependency.options.all'
                    )}
                  </div>
                </div>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      </div>
    )
  }

  renderButtons (item, index) {
    let { dependencies, isBranchForm, plan, disabled, allowedSet } = this.props
    plan = plan || 'CLASSIC'
    dependencies = dependencies || []
    const showAddBtn = dependencies.length === 1 ? !!item.categoryId : index === (dependencies.length - 1)
    const isDependenciesDisabled = allowedSet && !allowedSet?.includes('dependencies')

    return (
      isBranchForm
        ? (
          <div className='ta-dependencies-input__buttons'>
            {item.resourceIds && item.resourceIds.length > 0 && index === (dependencies.length - 1) &&
              <HoverPopup disabled={plan === 'ENTERPRISE'}>
                <HoverPopupContent position='top'>
                  {allowedSet?.includes('dependencies') ? t('globalSettings.form.section.attributes.disabled') : t('global.enterpriseOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <div className={`ta-btn ta-btn-primary ${(plan !== 'ENTERPRISE' || disabled) ? 'ta-btn-disabled' : ''}`} onClick={(plan === 'ENTERPRISE' && !disabled) ? this.addDependency : undefined}>
                    {t('servicesGroups.form.section.resources.buttonDependency.label')}
                  </div>
                </HoverPopupTrigger>
              </HoverPopup>}
            <Button isSecondary className={`ta-dependencies-input__buttons__btn-delete ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && this.deleteDependency(index)}>
              <FontAwesome5 icon='trash' type='regular' />
            </Button>
          </div>
          )
        : (
          <>
            <div className='ta-dependencies-input__buttons'>
              {showAddBtn && !isDependenciesDisabled &&
                <div className={`ta-btn ta-btn-primary ${disabled ? 'ta-btn-disabled' : ''}`} onClick={!disabled && this.addDependency}>
                  {t('servicesGroups.form.section.resources.buttonDependency.label')}
                </div>}
              <Button isSecondary className={`ta-dependencies-input__buttons__btn-delete ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && this.deleteDependency(index)}>
                <FontAwesome5 icon='trash' type='regular' />
              </Button>
            </div>
          </>
          )
    )
  }

  render () {
    const { className, dependencies, errors, isBranchForm } = this.props
    const classNames = ['ta-dependencies-input']
    if (className) classNames.push(className)
    if (dependencies.length === 0) dependencies.push(this.generateEmptyDependency())
    if (dependencies.length === 1) classNames.push('one')

    return (
      <div className={classNames.join(' ')}>
        {dependencies.map((item, index) => (
          <div className='ta-dependencies-input__group' key={index}>
            {this.renderCategory(item, index)}
            {isBranchForm && item.categoryId &&
              <>
                <BorderedBox>
                  {this.renderResources(item, index)}
                  {item.resourceIds && (item.resourceIds.length > 1 || (item.resourceIds.length === 1 && item.resourceIds[0] === 'all')) &&
                    this.renderWorkInParale(item.workInParallel, index)}
                </BorderedBox>
              </>}
            {errors.length > 0 && errors.map((error, key) => error.index === index &&
              <Row key={key}>
                <Col>
                  <Error error={error} />
                </Col>
              </Row>
            )}
            {this.renderButtons(item, index)}
          </div>
        ))}
      </div>
    )
  }
}

const maps = (state, props) => {
  const { router } = state
  const { data } = router || {}
  const { branchId } = data || {}
  let { list: branches } = state.branches || {}
  branches = branches || []
  const branch = branches.find(item => item.id === branchId) || {}
  const { plan } = branch || {}
  return {
    resources: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].resources) || [],
    categories: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].categories) || [],
    dependencies: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].values) || [],
    errors: (state.forms[props.formName] && state.forms[props.formName][props.name] && state.forms[props.formName][props.name].errors) || [],
    plan: plan || 'CLASSIC'
  }
}

export default feedContextInProps(connect(maps)(DependenciesInput), FormContext)
