export default (minutes) => {
  if (!minutes) return '00:00'

  // TODO: Add support for AM/PM dates
  // Conver hh:mm A > HH:mm first

  let h = Math.floor(minutes / 60)
  h = h < 10 ? `0${h}` : h

  let m = minutes % 60
  m = m < 10 ? `0${m}` : m

  return `${h}:${m}`
}
