import {
  userGroupsFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {}

reducer.handlers = (dispatch, actions, handlers) => ({
  // ONE
  userPreviewGet: (id) => dispatch(actions.USER_GROUP_PREVIEW_GET, id),
  userPreviewPopulate: userGroup => dispatch(actions.USER_GROUP_PREVIEW_POPULATE, { userGroup }),
  userPreviewReady: () => dispatch(actions.USER_GROUP_PREVIEW_READY),
  userDelete: id => dispatch(actions.USER_GROUP_DELETE, id),
  //  LIST
  userGroupsGet: () => dispatch(actions.USER_GROUPS_LIST_GET),
  userGroupsPopulate: userGroups => dispatch(actions.USER_GROUPS_LIST_POPULATE, { userGroups }),
  // FORM
  userGroupsFormGet: userGroupId => dispatch(actions.USER_GROUPS_FORM_GET, userGroupId),
  userGroupsFormPopulate: (userGroupId) => {
    handlers.formSet('userGroups', userGroupsFormTransform(userGroupId))
  },
  userGroupsFormReady: () => dispatch(actions.USER_GROUPS_FORM_READY),
  userGroupsFormSave: (userGroup, userGroupId, scrollToError = () => { }) => {
    dispatch(actions.USER_GROUPS_FORM_SAVE, { userGroup, userGroupId, scrollToError })
  }
})
// one
reducer.USER_GROUP_PREVIEW_GET = (state) => ({
  ...state,
  pendingPreview: true
})
reducer.USER_GROUP_PREVIEW_POPULATE = (state, { userGroup = {} }) => ({
  ...state,
  userGroup: userGroup
})
reducer.USER_GROUP_PREVIEW_READY = (state) => ({
  ...state,
  pendingPreview: false
})
reducer.USER_GROUP_DELETE = state => ({
  ...state,
  pendingPreview: true
})
// LIST
reducer.USER_GROUPS_LIST_GET = (state) => ({
  ...state,
  pending: true,
  areFetched: true
})

reducer.USER_GROUPS_LIST_POPULATE = (state, { userGroups = [] }) => ({
  ...state,
  userGroupsList: userGroups,
  pending: false
})
// FORM
reducer.USER_GROUPS_FORM_GET = (state) => ({
  ...state,
  pendingForm: true
})

reducer.USER_GROUPS_FORM_READY = (state) => ({
  ...state,
  pendingForm: false
})
reducer.USER_GROUPS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
