import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Row,
  Col,
  Input,
  PhoneInput,
  ImageInput,
  t
} from '../../../Common'

const UserForm = props => {
  const {
    title,
    message,
    onSubmit,
    cancelLink
  } = props

  return (
    <div className='ta-account__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <>{message && message.text}</>
          <Form onSubmit={onSubmit} name='user'>
            <FormSection>
              <Title isCompact label={t('global.detailsBasic')} icon='user' />
              <Row>
                <Col className='col-30'>
                  <ImageInput
                    formName='user'
                    name='avatar'
                    defaultZoom={1.5}
                    defaultBorderRadius={100}
                    controls={{ radius: false }}
                    validation={{ maxSize: 5 }}
                    title={t('global.uploadPhotoOrDrop')}
                    text={t('global.uploadPhotoOrDrop.text')}
                  />
                </Col>
                <Row>
                  <Col className='col-70'>
                    <Input name='name' label={t('global.name.label')} mandatory />
                  </Col>
                </Row>

                <Row>
                  <Col className='col-70'>
                    <PhoneInput name='phone' formName='user' label={t('global.telephone.label')} />
                  </Col>
                </Row>
              </Row>

            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

UserForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default UserForm
