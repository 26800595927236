import React, { Component, useEffect, useState } from 'react'

const FixedContentBody = (props) => {
  const { children, offsetTop, className } = props
  const style = {}
  if (offsetTop) style.top = offsetTop
  const classNames = ['ta-fixed-content__body']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  )
}

export default FixedContentBody
