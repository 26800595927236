const reducer = {}

reducer.initialState = {
  isCollapsed: false
}

reducer.handlers = (dispatch, actions) => ({
  navigationExpand: () => dispatch(actions.NAVIGATION_EXPAND),
  navigationCollapse: () => dispatch(actions.NAVIGATION_COLLAPSE)
})

reducer.NAVIGATION_EXPAND = state => ({
  ...state,
  isCollapsed: false
})

reducer.NAVIGATION_COLLAPSE = state => ({
  ...state,
  isCollapsed: true
})

export default reducer
