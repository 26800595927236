import React from 'react'
import { ServicePreviewChangedLocally } from '../../Beauties'
import { connect } from '../../Store'

const ServicePreviewChangedLocallyWrapper = props => {
  let { serviceId, services, branches, account } = props
  branches = branches || []
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalServicesWrite, globalServicesRead } = enterprisePermissions || {}
  globalServicesWrite = !!isAdmin || !!globalServicesWrite
  globalServicesRead = !!isAdmin || !!globalServicesRead
  const service = services.find(service => service.id === serviceId) || {}
  let { locallyUpdatedBranches } = service || {}
  locallyUpdatedBranches = locallyUpdatedBranches || {}
  const locallyChangedData = Object.keys(locallyUpdatedBranches)
    .filter(id => {
      const updates = Object.keys(locallyUpdatedBranches[id])
      if (
        (
          Object.keys(updates).includes('combinationServiceIds') &&
          service.combinationServiceIds.length !== updates.combinationServiceIds.length
        ) ||
        !Object.keys(locallyUpdatedBranches[id])
          .includes('locallyUpdatedServiceIds')
      ) {
        return true
      }
      return false
    }).map(id => ({
      branch: branches.find(branch => branch.id === id),
      id: service.id,
      localUpdates: locallyUpdatedBranches[id]
    }))

  return (
    <ServicePreviewChangedLocally
      services={services}
      service={service}
      locallyChangedData={locallyChangedData}
      globalServicesWrite={globalServicesWrite}
      globalServicesRead={globalServicesRead}
    />
  )
}

const maps = state => ({
  serviceId: (state.router.data && state.router.data.id),
  services: state.services.list,
  branches: state.branches.list,
  account: state.account
})

export default connect(maps)(ServicePreviewChangedLocallyWrapper)
