import { translateServerCode, validator, normalizePrice } from '../../../Utils'

// List
export const appsListTransform = (apps, companyLocale) => {
  if (!apps) return []
  companyLocale = companyLocale || ''
  const companyCountry = (companyLocale.split('-')[1] || '').toUpperCase()
  return apps.map(item => {
    const country = (item.allowedCountries || []).find(allowedCountry => allowedCountry.country === companyCountry) || {}
    return {
      id: item.id,
      name: item.name,
      imageUrl: item.imageUrl,
      version: item.version,
      installedVersion: item.installedVersion,
      isPublic: item.isPublic,
      plan: item.plan,
      developerName: item.developer && item.developer.name,
      developerCompanyName: item.developer && item.developer.companyName,
      categoryName: item.categories && item.categories[0],
      appInstallation: item.appInstallation,
      hasPayment: country.hasPayment,
      hasTrial: country.hasTrial,
      isBlocked: item.isBlocked
    }
  })
}

// One
export const appTransform = app => {
  if (!app) return
  let { companyLocale } = app || {}
  companyLocale = companyLocale || ''
  const companyCountry = (companyLocale.split('-')[1] || '').toUpperCase()
  const allowedCountry = (app.allowedCountries || []).find(allowedCountry => allowedCountry.country === companyCountry) || {}
  const plan = (allowedCountry.plans || [])[0] || {} // only one plan for pay per usage

  const result = {
    ...app,
    developerCompanyName: (app.developer && app.developer.companyName) || '',
    developerName: (app.developer && app.developer.name) || app.developerName,
    developerWebsite: (app.developer && app.developer.website) || app.developerWebsite,
    categoryName: (app.categories && app.categories[0]) || app.categoryName,
    usageMaxUnitPrice: plan.usageMaxUnitPrice,
    usageMinUnitPrice: plan.usageMinUnitPrice,
    usageUnitPrice: plan.usageUnitPrice,
    ...allowedCountry
  }
  delete result.developer
  delete result.category
  delete result.allowedCountries
  delete result.plans

  return result
}

// Form Subscription
export const appSubscriptionFormTransform = data => {
  data = data || {}
  let { paymentMethods } = data
  paymentMethods = paymentMethods || []
  const defaultPaymentMethod = paymentMethods.length > 0
    ? paymentMethods.find(item => item.isDefault)
    : {}
  const oldThreshold = data.nextLimitValue && data.nextLimitValue !== data.limitValue ? data.limitValue : null
  const threshold = data.nextLimitValue && data.nextLimitValue !== data.limitValue ? data.nextLimitValue : data.limitValue
  const result = {
    id: data.id,
    externalId: data.externalId,
    planName: data.planName,
    oldThreshold,
    hasThreshold: {
      value: !!data.hasThreshold || !!data.limitValue || !!data.nextLimitValue
    },
    threshold: {
      value: threshold ? threshold.toFixed(2) : ''
    },
    plan: {
      value: data.plan
    },
    paymentMethod: {
      value: defaultPaymentMethod.id
    }
  }
  return result
}

export const appSubscriptionFormValidate = (form) => {
  if (!form) return
  const thresholdRules = [`requiredIf:${form.hasThreshold && form.hasThreshold.value}`, 'price']
  const rules = [{
    'threshold.value': thresholdRules
  }]
  const messages = {
    threshold: {
      requiredIf: 'errors.required'
    }
  }
  const replaces = {
    threshold: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'apps.subscriptions.form.threshold.label',
        translateReplace: true
      }
    }
  }
  const errors = validator(form, rules, messages, replaces)

  return errors.length && errors
}

export const appSubscriptionFormSaveTransform = (form) => {
  if (!form) return
  const {
    hasThreshold,
    threshold,
    id,
    externalId,
    paymentMethod,
    hasBillingDetails,
    hasVatId,
    name,
    address1,
    address2,
    zipCode,
    city,
    vatNo,
    planName
  } = form || {}
  const { value: hasThresholdValue } = hasThreshold || {}
  const { value: thresholdValue } = threshold || {}
  const { value: paymentMethodValue } = paymentMethod || {}
  const { value: nameValue } = name || {}
  const { value: address1Value } = address1 || {}
  const { value: address2Value } = address2 || {}
  const { value: zipCodeValue } = zipCode || {}
  const { value: cityValue } = city || {}
  const { value: vatNoValue } = vatNo || {}
  const result = {
    appId: id,
    appExternalId: externalId,
    paymentMethodId: paymentMethodValue,
    planName
  }
  if (hasThresholdValue) {
    result.limitValue = thresholdValue ? normalizePrice(thresholdValue) : 0
  }
  if (!hasBillingDetails) {
    result.billingData = {
      name: nameValue,
      address1: address1Value,
      address2: address2Value,
      zipCode: zipCodeValue,
      city: cityValue
    }

    if (hasVatId) {
      result.billingData.vatNo = vatNoValue
    }
  }

  return result
}

export const appSubscriptionFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err.code === 'BillingDataError' && err.data && ['vat_number', 'billing_address[country]'].includes(err.data.field)) {
      errors.push({ key: 'vatNo', value: translateServerCode('InvalidVAT') })
    } else if (err.code === 'LimitTooBig') {
      errors.push({ key: 'threshold', value: translateServerCode('LimitTooBig') })
    } else if (err) {
      errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
    }
  }
  return errors
}

export const appSubscriptionBillingFormTransform = data => {
  data = data || {}
  let { paymentMethods, form } = data
  paymentMethods = paymentMethods || []
  const defaultPaymentMethod = paymentMethods.length > 0
    ? paymentMethods.find(item => item.isDefault)
    : {}
  form = form || {}
  const { value: formPaymentMethod } = form.paymentMethod || {}
  const result = {
    paymentMethod: {
      value: formPaymentMethod || defaultPaymentMethod.id
    },
    name: {
      value: data.name || ''
    },
    address1: {
      value: data.address1 || ''
    },
    address2: {
      value: data.address2 || ''
    },
    zipCode: {
      value: data.zipCode || ''
    },
    city: {
      value: data.city || ''
    },
    vatNo: {
      value: data.vatNo || ''
    },
    vatCountryCode: {
      value: data.vatCountryCode || ''
    }
  }
  return result
}

export const appSubscriptionBillingFormValidate = (form) => {
  if (!form) return
  const rules = [
    { 'name.value': [`requiredIf:${!form.hasBillingDetails}`] },
    { 'address1.value': [`requiredIf:${!form.hasBillingDetails}`] },
    { 'zipCode.value': [`requiredIf:${!form.hasBillingDetails}`] },
    { 'city.value': [`requiredIf:${!form.hasBillingDetails}`] }
  ]
  const messages = {
    name: {
      requiredIf: 'errors.required'
    },
    address1: {
      requiredIf: 'errors.required'
    },
    zipCode: {
      requiredIf: 'errors.required'
    },
    city: {
      requiredIf: 'errors.required'
    }
  }
  const replaces = {
    name: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'billing.list.section.billingDetails.fieldCompanyOrName.label',
        translateReplace: true
      }
    },
    address1: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'address.label',
        translateReplace: true
      }
    },
    zipCode: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'address.zip',
        translateReplace: true
      }
    },
    city: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'address.city',
        translateReplace: true
      }
    }
  }

  const errors = validator(form, rules, messages, replaces)

  return errors.length && errors
}

export const appSubscriptionCancelFormTransform = () => {
  return {
    reason: {
      value: ''
    }
  }
}

export const appSubscriptionCancelFormValidate = (form) => {
  if (!form) return
  const reasonMaxLength = 200
  const rules = [{
    'reason.value': [`max:${200}`]
  }]
  const messages = {
    reason: {
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    reason: {
      max: {
        key: 'MAX',
        value: reasonMaxLength
      }
    }
  }
  const errors = validator(form, rules, messages, replaces)

  return errors.length && errors
}

export const appSubscriptionCancelFormSaveTransform = (form) => {
  if (!form) return
  const { reason, id, externalId } = form || {}
  const { value: reasonValue } = reason || {}
  return {
    reason: reasonValue,
    appId: id,
    appExternalId: externalId
  }
}

export const appSubscriptionCancelFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}

export const appSubscriptionRuleFormTransform = (data) => {
  return {
    externalId: data.externalId,
    subscriptionScope: {
      value: 'global'
    }
  }
}

export const appSubscriptionRuleFormSaveTransform = (form) => {
  if (!form) return
  const {
    id,
    subscriptionScope,
    externalId
  } = form || {}
  const { value: subscriptionScopeValue } = subscriptionScope || {}
  const result = {
    appId: id,
    appExternalId: externalId,
    isPaidByEnterprise: subscriptionScopeValue === 'global'
  }

  return result
}

export const appSubscriptionRuleFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}

// Form Uninstall
export const appUninstallFormTransform = data => {
  data = data || {}
  const result = {
    id: data.id,
    externalId: data.externalId,
    keepData: {
      value: !!data.keepData
    },
    password: {
      value: '',
      type: 'password'
    }
  }
  return result
}

export const appUninstallFormValidate = fields => {
  const rules = [
    { 'password.value': ['required', 'min:6'] }
  ]

  const messages = {
    password: {
      required: 'errors.required',
      min: 'errors.password.weak'
    }
  }

  const replaces = {
    password: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.password',
        translateReplace: true
      },
      min: {
        key: 'MIN',
        value: 6
      }
    }
  }

  const errors = validator(fields, rules, messages, replaces)

  return errors.length && errors
}

export const appUninstallFormSaveTransform = (form) => {
  if (!form) return
  const { id, externalId, keepData, password } = form || {}
  const { value: keepDataValue } = keepData || {}
  const { value: passwordValue } = password || {}

  return {
    appId: id,
    appExternalId: externalId,
    keepData: keepDataValue,
    password: passwordValue
  }
}

export const appUninstallFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}

export const appPaymentRetryServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }
  return errors
}
