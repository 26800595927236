import React from 'react'

const PopupICSTooltip = (props) => {
  const { extras } = props || {}
  const { render } = extras || {}

  return (
    <div className='ta-popup__resources-allowance-type-inherit'>
      <div className='ta-popup__text ta-popup__text_ics_tooltip'>
        {render}
      </div>
    </div>
  )
}

export default PopupICSTooltip
