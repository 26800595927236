import React, { useState } from 'react'
import moment from 'moment'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Row,
  Col,
  Form,
  FormButtons,
  FormSection,
  Radio,
  Checkbox,
  FormText,
  BorderedBox,
  SingleSelect,
  HintText,
  Alert,
  Input,
  Error,
  FormGroup,
  Select,
  FontAwesome5,
  Accordion,
  AccordionTitle,
  AccordionBody,
  Textarea,
  format,
  t
} from '../../../../Common'
import { PopupTriggerWrapper, SettingsServiceAllocationsWrapper, SettingsIcsFileCustomisationWrapper } from '../../../../Beasts'
import {
  MaximumLeadTimeMonthsExample,
  MinimumLeadTimeHoursExample,
  SnapToGridIntervalExample
} from '../../../../Beauties'
import { BOOKING_SETTINGS_DEFAULTS } from '../../../../Settings'
import { dangerousHTML, generateDateFormat, generateTimeFormat } from '../../../../Utils'

const BookingSettingsEditForm = props => {
  let {
    onSubmit,
    cancelLink,
    hash,
    enforceResourcesAllowanceTypeCheckbox,
    enforceReschedulingTagRuleCheckbox,
    enforceServiceDurationLimitCheckbox,
    hasTags,
    maximumLeadTime,
    maximumLeadTimeDuration,
    minimumLeadTime,
    minimumLeadTimeDuration,
    snapToGridInterval,
    internalSnapToGridInterval,
    showWidgetCategoriesClosed,
    showCustomTermsAndConditions,
    showCustomPrivacyPolicy,
    showWidgetExternalUrlRedirect,
    hasDateFormat,
    dateFormatValues,
    timeFormatValues,
    hasTimeFormat,
    timeFormatType,
    hasPublicHolidays,
    publicHolidays,
    regionsByUniqueKey,
    onPublicHolidayRegionsChange,
    onPublicHolidayRadioClick,
    onPublicHolidayAdd,
    onPublicHolidayDelete,
    onPublicHolidayResourceCategoriesChange,
    resourceCategories,
    customersMiddlewareUrl,
    showDisclaimer,
    hasServiceAllocations,
    useCancellationLeadTime,
    cancellationLeadTimeAnyTime,
    useGoogleConversionTracking,
    useFacebookConversionTracking,
    useCustomiseConsentMessage,
    customisations,
    callCentreEnabled,
    isAdmin,
    forbidUpdatingBookingsInThePast,
    allowPartialUpdateOfBookingsInThePast,
    pastBookingPrepAndFollowUp,
    pastBookingResources,
    pastBookingGroupPrice,
    pastBookingDuration,
    pastBookingCustomDataFields
  } = props
  publicHolidays = publicHolidays || []
  regionsByUniqueKey = regionsByUniqueKey || {}
  const [focusedIndex, setFocusedIndex] = useState(null)
  const [focusedField, setFocusedField] = useState(null)
  const regions = Object.values(regionsByUniqueKey)
  const onFocus = (index, field) => {
    setFocusedIndex(index)
    setFocusedField(field)
  }
  const onBlur = () => {
    setFocusedIndex(null)
    setFocusedField(null)
  }
  const availableTabs = ['booking', 'calendar', 'widget', 'other']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]
  const addButtonClassNames = ['ta-settings__btn-add']
  if (publicHolidays.length > 0) addButtonClassNames.push('top-offset')
  let maximumLeadTimeDurationKey = 'global.months.counter'
  if (maximumLeadTimeDuration === 'WEEKS') maximumLeadTimeDurationKey = 'global.weeks.counter'
  let minimumLeadTimeDurationKey = 'global.hours.counter'
  if (minimumLeadTimeDuration === 'DAYS') minimumLeadTimeDurationKey = 'global.days.counter'
  const maximumLeadTimeDurationOptions = BOOKING_SETTINGS_DEFAULTS.maximumLeadTimeDurationOptions.map(item => ({
    value: item, label: t(`global.${item.toLowerCase()}.counter`)
  }))
  const minimumLeadTimeDurationOptions = BOOKING_SETTINGS_DEFAULTS.minimumLeadTimeDurationOptions.map(item => ({
    value: item, label: t(`global.${item.toLowerCase()}.counter`)
  }))
  // Customisations
  const { settings } = customisations || {}
  const {
    hideBookingSettingsServiceAllocations,
    hideWidgetSettingsConversions,
    hideCalendarSettingsDateFormat,
    hideCalendarSettingsTimeFormat,
    hideBookingSettingsIcsFileCustomisation,
    hideOtherSettingsCallCentre,
    hideOtherSettingsCrossAccountSync,
    hideCalendarSettingsPastBookings
  } = settings || {}
  const customMessageVisibilityOptions = [
    {
      label: t('settings.section.widget.conversionTracking.customMessageVisibility.option.onlyEU'),
      value: 'EU_ONLY'
    },
    {
      label: t('settings.section.widget.conversionTracking.customMessageVisibility.option.all'),
      value: 'ALL'
    }
  ]
  const nextYear = moment().add(1, 'years').format('YYYY')
  const exampleDate = `${nextYear}-02-01`
  const exampleTime = moment().format('HH:mm')
  const dateFormatPositionOptions = BOOKING_SETTINGS_DEFAULTS.dateFormatPositionOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{ key: 'DATE', value: format(exampleDate, item.value) }])
  }))
  const dateFormatYearOptions = BOOKING_SETTINGS_DEFAULTS.dateFormatYearOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{ key: 'YEAR', value: format(exampleDate, item.value) }])
  }))
  const dateFormatMonthOptions = BOOKING_SETTINGS_DEFAULTS.dateFormatMonthOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{ key: 'MONTH', value: format(exampleDate, item.value) }])
  }))
  const dateFormatDayOptions = BOOKING_SETTINGS_DEFAULTS.dateFormatDayOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{ key: 'DAY', value: format(exampleDate, item.value) }])
  }))
  const dateFormatSeparatorOptions = BOOKING_SETTINGS_DEFAULTS.dateFormatSeparatorOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{
      key: 'DATE',
      value: format(exampleDate, generateDateFormat({ position: 'YYYY MM DD', separator: item.value }))
    }])
  }))
  const timeFormatTypeOptions = BOOKING_SETTINGS_DEFAULTS.timeFormatTypeOptions.map(item => ({
    value: item.value,
    label: t(item.label)
  }))
  const timeFormatTwelveOptions = BOOKING_SETTINGS_DEFAULTS.timeFormatTwelveOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{
      key: 'TIME',
      value: format(
        exampleTime,
        generateTimeFormat({ type: '12', punctuation: item.value }),
        { isUTC: true, format: 'HH:mm' }
      )
    }])
  }))
  const timeFormatTwentyFourOptions = BOOKING_SETTINGS_DEFAULTS.timeFormatTwentyFourOptions.map(item => ({
    value: item.value,
    label: t(item.label, [{
      key: 'TIME',
      value: format(
        exampleTime,
        generateTimeFormat({ type: '24', separator: item.value }),
        { isUTC: true, format: 'HH:mm' }
      )
    }])
  }))
  const disablePastBookingTitle = pastBookingPrepAndFollowUp ||
    pastBookingResources ||
    pastBookingGroupPrice ||
    pastBookingDuration ||
    pastBookingCustomDataFields

  return (
    <div className='ta-settings__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('settings.section.booking.editTitle')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='bookingSettings'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='booking' label={t('global.booking')} icon='clock' iconType='solid' />
                <SimpleTabsHeaderItem name='calendar' label={t('global.calendar')} icon='calendar-alt' iconType='solid' />
                <SimpleTabsHeaderItem name='widget' label={t('global.widget')} icon='window-maximize' iconType='solid' />
                <SimpleTabsHeaderItem name='other' label={t('global.other')} icon='file' iconType='solid' />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                {/* BOOKING */}
                <SimpleTabsBodyItem name='booking'>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.maxLeadTime.heading')} icon='arrows-h' ignoreTopOffset />
                    <Row>
                      <Col>
                        <FormText noOffset>{dangerousHTML(t('settings.section.booking.maxLeadTime.questionText'))}</FormText>
                      </Col>
                    </Row>
                    <BorderedBox>
                      <Row>
                        <Col size={50}>
                          <Input
                            name='maximumLeadTime'
                            label={t('settings.section.leadTime.input.label')}
                            mandatory
                          />
                        </Col>
                        <Col size={50}>
                          <SingleSelect
                            name='maximumLeadTimeDuration'
                            options={maximumLeadTimeDurationOptions}
                            label={t('settings.section.leadTimeDuration.select.label')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <MaximumLeadTimeMonthsExample
                            value={maximumLeadTime}
                            label={dangerousHTML(t('settings.section.booking.maxLeadTime.infoText', [
                              { key: 'MAXIMUM_LEAD_TIME', value: isNaN(maximumLeadTime) ? '0' : maximumLeadTime },
                              { key: 'MAXIMUM_LEAD_TIME_DURATION', value: maximumLeadTimeDurationKey, translateReplace: true }
                            ]))}
                          />
                        </Col>
                      </Row>
                    </BorderedBox>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.minLeadTime.heading')} icon='clock' />
                    <Row>
                      <Col>
                        <FormText noOffset>{dangerousHTML(t('settings.section.booking.minLeadTime.questionText'))}</FormText>
                      </Col>
                    </Row>
                    <BorderedBox>
                      <Row>
                        <Col size={50}>
                          <Input
                            name='minimumLeadTime'
                            label={t('settings.section.leadTime.input.label')}
                            mandatory
                          />
                        </Col>
                        <Col size={50}>
                          <SingleSelect
                            name='minimumLeadTimeDuration'
                            options={minimumLeadTimeDurationOptions}
                            label={t('settings.section.leadTimeDuration.select.label')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <MinimumLeadTimeHoursExample
                            value={minimumLeadTime}
                            duration={minimumLeadTimeDuration}
                            label={dangerousHTML(t('settings.section.booking.minLeadTime.infoText', [
                              { key: 'MINIMUM_LEAD_TIME', value: isNaN(minimumLeadTime) ? '0' : minimumLeadTime },
                              { key: 'MINIMUM_LEAD_TIME_DURATION', value: minimumLeadTimeDurationKey, translateReplace: true }
                            ]))}
                          />
                        </Col>
                      </Row>
                    </BorderedBox>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.cancellation.heading')} icon='ban' />
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <FormText noOffset>{dangerousHTML(t('settings.section.cancellation.questionText'))}</FormText>
                          </Col>
                        </Row>
                        <BorderedBox>
                          <Row>
                            <Col>
                              <Checkbox
                                name='useCancellationLeadTime'
                                label={t('settings.section.cancellationLeadTime.enable.label')}
                                theme='switch'
                              />
                            </Col>
                          </Row>
                          {(useCancellationLeadTime &&
                            <BorderedBox>
                              <Row>
                                <Col>
                                  <Radio name='cancellationLeadTimeAnyTime' value='yes' label={t('settings.section.cancellationLeadTime.anyTime.label')} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Radio name='cancellationLeadTimeAnyTime' value='no' label={t('settings.section.cancellationLeadTime.specificTime.label')} />
                                </Col>
                              </Row>
                              {(cancellationLeadTimeAnyTime === 'no' &&
                                <Row>
                                  <Col size={50}>
                                    <Input
                                      name='cancellationLeadTime'
                                      label={t('settings.section.leadTime.input.label')}
                                      mandatory
                                    />
                                  </Col>
                                  <Col size={50}>
                                    <SingleSelect
                                      name='cancellationLeadTimeDuration'
                                      options={minimumLeadTimeDurationOptions}
                                      label={t('settings.section.leadTimeDuration.select.label')}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </BorderedBox>
                          )}
                        </BorderedBox>
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.enforceReschedulingTagRule.heading')} icon='calendar-edit' />
                    <Row>
                      <Col>
                        <FormText noOffset>
                          {dangerousHTML(t('settings.section.enforceReschedulingTagRule.questionText'))}
                        </FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='enforceReschedulingTagRuleCheckbox'
                          label={t('global.enable')}
                          theme='switch'
                          className={enforceReschedulingTagRuleCheckbox ? '' : 'space-bottom'}
                        />
                      </Col>
                    </Row>
                    {(enforceReschedulingTagRuleCheckbox &&
                      <BorderedBox>
                        <Row>
                          <Col>
                            <SingleSelect
                              name='enforceReschedulingTagRule'
                              label={t('settings.section.enforceReschedulingTagRule.selectTag.label')}
                              searchable
                              clearable
                            />
                          </Col>
                        </Row>
                        {(!hasTags &&
                          <Alert theme='alert'>
                            {t('settings.section.enforceReschedulingTagRule.noTags.label')} <Button as='link' className='underline' to='/customers/company-tags'>{t('settings.section.enforceReschedulingTagRule.noTags.add.label')}</Button>
                          </Alert>
                        )}
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.resourceAvailability.heading')} icon='pencil-ruler' ignoreTopOffset />
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <FormText noOffset>
                              {t('settings.section.resourceAvailability.questionText')}
                            </FormText>
                          </Col>
                        </Row>
                        <Checkbox
                          name='enforceResourcesAllowanceTypeCheckbox'
                          label={t('settings.edit.section.scheduleRules.form.enforceCheckbox.label')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {(enforceResourcesAllowanceTypeCheckbox &&
                      <BorderedBox>
                        <Row>
                          <Col>
                            <FormText noOffset>{dangerousHTML(t('settings.section.internalSchedulingRules.checkbox.enableCompanyRules.text'))}</FormText>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Radio
                              name='enforceResourcesAllowanceTypeRadio'
                              value='WORKING'
                              label={t('settings.section.internalSchedulingRules.checkbox.enableCompanyRules.radioOutsideWorkingHours')}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Radio
                              name='enforceResourcesAllowanceTypeRadio'
                              value='BOOKING'
                              label={t('settings.section.internalSchedulingRules.checkbox.enableCompanyRules.radioOutsideBookingHours')}
                            />
                          </Col>
                        </Row>
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.bookingSlots.heading')} icon='th' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.bookingSlots.questionText')}</FormText>
                      </Col>
                    </Row>
                    <BorderedBox>
                      <Row>
                        <Col>
                          <Radio name='snapToGridInterval' value='serviceLength' label={t('settings.section.bookingSlots.radioServiceLength')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='snapToGridInterval'
                            value='quarterly'
                            label={t('settings.section.bookingSlots.radioEveryMin', [{ key: 'MINUTES', value: 15 }])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='snapToGridInterval'
                            value='halfHourly'
                            label={t('settings.section.bookingSlots.radioEveryMin', [{ key: 'MINUTES', value: 30 }])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='snapToGridInterval'
                            value='hourly'
                            label={t('settings.section.bookingSlots.radioEveryMin', [{ key: 'MINUTES', value: 60 }])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SnapToGridIntervalExample
                            value={snapToGridInterval}
                            label={t('settings.section.bookingSlots.example')}
                            description
                            narrow
                          />
                        </Col>
                      </Row>
                    </BorderedBox>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.internalBookingSlots.heading')} icon='th' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.internalBookingSlots.questionText')}</FormText>
                      </Col>
                    </Row>
                    <BorderedBox>
                      <Row>
                        <Col>
                          <Radio name='internalSnapToGridInterval' value='serviceLength' label={t('settings.section.bookingSlots.radioServiceLength')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='internalSnapToGridInterval'
                            value='quarterly'
                            label={t('settings.section.bookingSlots.radioEveryMin', [{ key: 'MINUTES', value: 15 }])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='internalSnapToGridInterval'
                            value='halfHourly'
                            label={t('settings.section.bookingSlots.radioEveryMin', [{ key: 'MINUTES', value: 30 }])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            name='internalSnapToGridInterval'
                            value='hourly'
                            label={t('settings.section.bookingSlots.radioEveryMin', [{ key: 'MINUTES', value: 60 }])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SnapToGridIntervalExample
                            value={internalSnapToGridInterval}
                            label={t('settings.section.bookingSlots.example')}
                            description
                            narrow
                          />
                        </Col>
                      </Row>
                    </BorderedBox>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.internalBookingPreferredTimeframe.heading')} icon='calendar-check' />
                    <Row>
                      <Col>
                        <FormText noOffset>{dangerousHTML(t('settings.section.internalBookingPreferredTimeframe.questionText'))}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='showFastWidgetTimeFrame'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  {(!hideBookingSettingsServiceAllocations &&
                    <FormSection className='no-margin service-allocations'>
                      <Title size='m' isCompact label={t('settings.section.serviceAllocations.title')} icon='sort-numeric-down-alt' />
                      <Row>
                        <Col>
                          <FormText noOffset>{dangerousHTML(t('settings.section.serviceAllocations.infoText'))}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <Checkbox
                                name='hasServiceAllocations'
                                label={t('global.enable')}
                                theme='switch'
                              />
                            </Col>
                          </Row>
                          {hasServiceAllocations && <SettingsServiceAllocationsWrapper />}
                        </Col>
                      </Row>
                    </FormSection>
                  )}
                  {(!hideBookingSettingsIcsFileCustomisation &&
                    <FormSection className='no-margin ics-file-customisation'>
                      <Title size='m' isCompact label={t('settings.section.icsFileCustomisation.title')} icon='file-alt' />
                      <Row>
                        <Col>
                          <FormText noOffset>{dangerousHTML(t('settings.section.icsFileCustomisation.infoText'))}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SettingsIcsFileCustomisationWrapper />
                        </Col>
                      </Row>
                    </FormSection>
                  )}
                </SimpleTabsBodyItem>
                {/* CALENDAR */}
                <SimpleTabsBodyItem name='calendar'>
                  {(!hideCalendarSettingsDateFormat &&
                    <FormSection>
                      <Title size='m' isCompact label={t('settings.section.dateFormat.title')} icon='calendar-week' ignoreTopOffset />
                      <Row>
                        <Col>
                          <FormText noOffset>{dangerousHTML(t('settings.section.dateFormat.questionText'))}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='hasDateFormat'
                            label={t('global.enable')}
                            theme='switch'
                          />
                        </Col>
                      </Row>
                      {(hasDateFormat &&
                        <Row>
                          <Col>
                            <BorderedBox>
                              <Row>
                                <Col>
                                  <SingleSelect
                                    name='dateFormatPosition'
                                    label={t('settings.section.dateFormat.dateFormatPosition.label')}
                                    options={dateFormatPositionOptions}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <SingleSelect
                                    name='dateFormatYear'
                                    label={t('settings.section.dateFormat.dateFormatYear.label')}
                                    options={dateFormatYearOptions}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <SingleSelect
                                    name='dateFormatMonth'
                                    label={t('settings.section.dateFormat.dateFormatMonth.label')}
                                    options={dateFormatMonthOptions}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <SingleSelect
                                    name='dateFormatDay'
                                    label={t('settings.section.dateFormat.dateFormatDay.label')}
                                    options={dateFormatDayOptions}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <SingleSelect
                                    name='dateFormatSeparator'
                                    label={t('settings.section.dateFormat.dateFormatSeparator.label')}
                                    options={dateFormatSeparatorOptions}
                                  />
                                </Col>
                              </Row>
                              <Alert noOffset theme='info'>
                                {dangerousHTML(t('settings.section.dateFormat.example', [
                                  { key: 'DATE', value: format(exampleDate, generateDateFormat(dateFormatValues)) }
                                ]))}
                              </Alert>
                            </BorderedBox>
                          </Col>
                        </Row>
                      )}
                    </FormSection>
                  )}
                  {(!hideCalendarSettingsTimeFormat &&
                    <FormSection>
                      <Title size='m' isCompact label={t('settings.section.timeFormat.title')} icon='clock' ignoreTopOffset />
                      <Row>
                        <Col>
                          <FormText noOffset>{dangerousHTML(t('settings.section.timeFormat.questionText'))}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='hasTimeFormat'
                            label={t('global.enable')}
                            theme='switch'
                          />
                        </Col>
                      </Row>
                      {(hasTimeFormat &&
                        <Row>
                          <Col>
                            <BorderedBox>
                              <Row>
                                <Col>
                                  <SingleSelect
                                    name='timeFormatType'
                                    label={t('settings.section.timeFormat.timeFormatType.label')}
                                    options={timeFormatTypeOptions}
                                  />
                                </Col>
                              </Row>
                              {(timeFormatType === '12' &&
                                <Row>
                                  <Col>
                                    <SingleSelect
                                      name='timeFormat12'
                                      label={t('settings.section.timeFormat.timeFormatTwelve.label')}
                                      options={timeFormatTwelveOptions}
                                    />
                                  </Col>
                                </Row>
                              )}
                              {(timeFormatType === '24' &&
                                <Row>
                                  <Col>
                                    <SingleSelect
                                      name='timeFormat24'
                                      label={t('settings.section.timeFormat.timeFormatTwentyFour.label')}
                                      options={timeFormatTwentyFourOptions}
                                    />
                                  </Col>
                                </Row>
                              )}
                              <Alert noOffset theme='info'>
                                {dangerousHTML(t('settings.section.timeFormat.example', [
                                  { key: 'TIME', value: format(exampleTime, generateTimeFormat(timeFormatValues), { isUTC: true, format: 'HH:mm' }) }
                                ]))}
                              </Alert>
                            </BorderedBox>
                          </Col>
                        </Row>
                      )}
                    </FormSection>
                  )}
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.bookingLengthFlexibility.title')} icon='expand' />
                    <Row>
                      <Col>
                        <FormText noOffset>
                          {t('settings.bookingLengthFlexibility.questionText')}
                        </FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='enforceServiceDurationLimitCheckbox'
                          label={t('settings.edit.section.bookingLengthFlexibility.form.enforceDurationCheckbox.label')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {(enforceServiceDurationLimitCheckbox &&
                      <BorderedBox>
                        <Row>
                          <Col>
                            <SingleSelect
                              name='enforceServiceDurationLimit'
                              label={t('settings.edit.section.bookingLengthFlexibility.form.enforceDurationSelect.label')}
                              searchable
                              clearable
                            />
                          </Col>
                        </Row>
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.copyPaste.heading')} icon='copy' />
                    <Row>
                      <Col>
                        <FormText noOffset>
                          {t('settings.section.copyBooking.questionText')}
                        </FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio
                          name='resetCopyBookingClipboardAfterPaste'
                          value='multiple'
                          label={t('settings.section.copyBooking.radioKeepCopyAfterPaste')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio
                          name='resetCopyBookingClipboardAfterPaste'
                          value='once'
                          label={t('settings.section.copyBooking.radioClearAfterPaste')}
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  {(!hideCalendarSettingsPastBookings &&
                    <FormSection>
                      <Title size='m' isCompact label={t('settings.section.pastBooking.heading')} icon='ban' ignoreTopOffset />
                      <Row>
                        <Col>
                          <FormText noOffset>{dangerousHTML(t('settings.section.pastBooking.questionText'))}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <BorderedBox>
                          <Alert theme='alert'>
                            {t('settings.section.pastBooking.warningNote')}
                          </Alert>
                          <Row>
                            <Col>
                              <Checkbox
                                name='forbidAddingBookingsInThePast'
                                label={t('settings.section.pastBooking.preventAdding')}
                                theme='switch'
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Checkbox
                                name='forbidUpdatingBookingsInThePast'
                                label={t('settings.section.pastBooking.preventEditing')}
                                theme='switch'
                              />
                            </Col>
                          </Row>
                          {(forbidUpdatingBookingsInThePast &&
                            <Row>
                              <Col>
                                <BorderedBox className='no-margin'>
                                  <Row>
                                    <Col>
                                      <Radio name='allowPartialUpdateOfBookingsInThePast' value={false} label={t('settings.section.pastBooking.preventEditing.disableAllLabel')} />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Radio name='allowPartialUpdateOfBookingsInThePast' value label={t('settings.section.pastBooking.preventEditing.disableSomeLabel')} />
                                    </Col>
                                  </Row>
                                  {(allowPartialUpdateOfBookingsInThePast &&
                                    <Row>
                                      <Col>
                                        <BorderedBox>
                                          <Row>
                                            <Col size={95}>
                                              <Checkbox
                                                disabled={disablePastBookingTitle}
                                                name='pastBookingTitle'
                                                label={t('settings.section.pastBooking.preventEditing.disableSomeLabel.title')}
                                                theme='switch'
                                              />
                                            </Col>
                                            <Col>
                                              <PopupTriggerWrapper
                                                position='right'
                                                name='settings-past-booking-calendar'
                                              >
                                                <FontAwesome5 color='rgba(80, 90, 94, 0.6)' icon='question-circle' />
                                              </PopupTriggerWrapper>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingStartTime'
                                                label={t('settings.section.pastBooking.preventEditing.disableSomeLabel.startTime')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingDuration'
                                                label={t('settings.section.pastBooking.preventEditing.disableSomeLabel.duration')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingResources'
                                                label={t('branches.form.group.edit.resources.title')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingPrepAndFollowUp'
                                                label={t('global.duration.preparationAndFollowUpTimes')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingCustomer'
                                                label={t('global.customer')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingCustomDataFields'
                                                label={t('settings.section.pastBooking.preventEditing.disableSomeLabel.customDataFields')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingGroupPrice'
                                                label={t('global.price')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingParticipantsAndGuests'
                                                label={t('settings.section.pastBooking.preventEditing.disableSomeLabel.participantsAndGuests')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingNotes'
                                                label={t('global.notes')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <Checkbox
                                                name='pastBookingTags'
                                                label={t('global.tags')}
                                                theme='switch'
                                              />
                                            </Col>
                                          </Row>
                                        </BorderedBox>
                                      </Col>
                                    </Row>
                                  )}
                                </BorderedBox>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              <Checkbox
                                name='forbidDeletingBookingInThePast'
                                label={t('settings.section.pastBooking.preventDeleting')}
                                theme='switch'
                              />
                            </Col>
                          </Row>
                        </BorderedBox>
                      </Row>
                    </FormSection>
                  )}
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.bookingsInFBW.title')} icon='tasks' />
                    <Row>
                      <Col>
                        <FormText noOffset>
                          {t('settings.section.bookingsInFBW.infoText')}
                        </FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='forbidMoreThanOneBookingInFBW'
                          label={t('settings.section.bookingsInFBW.checkbox.label')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.publicHolidays.title')} icon='wreath' ignoreTopOffset />
                    <Row>
                      <Col>
                        <FormText noOffset>{dangerousHTML(t('settings.section.publicHolidays.infoText'))}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='hasPublicHolidays'
                          label={t('settings.section.publicHolidays.checkbox.hasPublicHolidays.label')}
                          theme='switch'
                        />
                        {(hasPublicHolidays &&
                          <BorderedBox topOffset>
                            {publicHolidays.map((item, index) => {
                              const holidayClassNames = ['ta-settings-calendar__holiday-select']
                              if (publicHolidays.length > 1) holidayClassNames.push('has-delete')
                              const options = regions.filter(region =>
                                !publicHolidays.find(holiday => holiday.publicHolidayKey === region.value) || item.publicHolidayKey === region.value
                              )
                              const radioLabelClassnamesSome = ['ta-radio']
                              const radioLabelClassnamesAll = ['ta-radio', 'all']
                              const resourceCategoryIds = item.resourceCategoryIds || []
                              resourceCategoryIds.includes('all')
                                ? radioLabelClassnamesAll.push('active')
                                : radioLabelClassnamesSome.push('active')

                              return (
                                <Accordion key={item.id}>
                                  <AccordionTitle>
                                    {t('global.resourceGroup')}
                                    {(item.publicHolidayKeys && item.publicHolidayKeys.length > 0 &&
                                      <span>
                                        &nbsp;
                                        (
                                        {regionsByUniqueKey[item.publicHolidayKeys[0]] && regionsByUniqueKey[item.publicHolidayKeys[0]].label}
                                        {item.publicHolidayKeys.length > 1 && ` + ${item.publicHolidayKeys.length - 1} ${t(`settings.section.publicHolidays.accordion.other${item.publicHolidayKeys.length - 1 === 1 ? '' : 's'}`)}`}
                                        )
                                      </span>
                                    )}
                                    <FontAwesome5 icon='trash-alt' type='s' onClick={() => onPublicHolidayDelete(index)} />
                                  </AccordionTitle>
                                  <AccordionBody>
                                    <Row>
                                      <Col>
                                        <FormGroup
                                          focused={focusedIndex === index && focusedField === 'region'}
                                          className={holidayClassNames.join(' ')}
                                          labelText={t('settings.section.publicHolidays.form.select.regions.placeholder')}
                                        >
                                          <Select
                                            className='ta-single-select'
                                            noResultsText={t('global.noResults')}
                                            value={item.publicHolidayKeys}
                                            onFocus={() => onFocus(index, 'region')}
                                            onBlur={onBlur}
                                            onChange={(values) => onPublicHolidayRegionsChange(values, index)}
                                            onDelete={(values) => onPublicHolidayRegionsChange(values, index, true)}
                                            options={options}
                                            multi
                                            searchable
                                          />
                                        </FormGroup>
                                        <br />
                                        <Row>
                                          <Col>
                                            <FormText noOffset>{t('settings.section.publicHolidays.form.resources.text')}</FormText>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <FormGroup>
                                              <input
                                                className='ta-radio-field'
                                                type='radio'
                                                checked={!!resourceCategoryIds.includes('all')}
                                                onChange={() => { }}
                                              />
                                              <div className={radioLabelClassnamesAll.join(' ')} onClick={() => onPublicHolidayRadioClick(index, 'all')}>
                                                {t('settings.section.publicHolidays.form.resources.radio.all.text')}
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <FormGroup>
                                              <input
                                                className='ta-radio-field'
                                                type='radio'
                                                checked={!resourceCategoryIds.includes('all')}
                                                onChange={() => { }}
                                              />
                                              <div className={radioLabelClassnamesSome.join(' ')} onClick={() => onPublicHolidayRadioClick(index, 'some')}>
                                                {t('settings.section.publicHolidays.form.resources.radio.some.text')}
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        {(!resourceCategoryIds.includes('all') &&
                                          <BorderedBox topOffset>
                                            <Row>
                                              <Col>
                                                <FormGroup
                                                  focused={focusedIndex === index && focusedField === 'resourceCategories'}
                                                  labelText={t('settings.section.publicHolidays.form.select.resourceCategories.placeholder')}
                                                >
                                                  <Select
                                                    className='ta-single-select'
                                                    noResultsText={t('global.noResults')}
                                                    value={resourceCategoryIds}
                                                    onFocus={() => onFocus(index, 'resourceCategories')}
                                                    onBlur={onBlur}
                                                    onChange={(values) => onPublicHolidayResourceCategoriesChange(values, index)}
                                                    onDelete={(values) => onPublicHolidayResourceCategoriesChange(values, index, true)}
                                                    options={resourceCategories}
                                                    multi
                                                    searchable
                                                  />
                                                </FormGroup>
                                              </Col>
                                            </Row>
                                          </BorderedBox>
                                        )}
                                      </Col>
                                    </Row>
                                  </AccordionBody>
                                </Accordion>
                              )
                            })}
                            {(publicHolidays.length < 10 &&
                              <Button icon='plus' iconType='r' className={addButtonClassNames.join(' ')} isTertiary isGhost isBlock onClick={onPublicHolidayAdd}>
                                {t('settings.section.publicHolidays.button.add')}
                              </Button>
                            )}
                          </BorderedBox>
                        )}
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.notifyCheckboxes.heading')} icon='envelope' ignoreTopOffset />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.notifyCheckboxes.questionText')}</FormText>
                      </Col>
                    </Row>
                    <BorderedBox>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <FormText noOffset>{dangerousHTML(t('settings.section.notifyCheckboxes.resource.title'))}</FormText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio name='hasNotifyOnResources' value='default' label={t('settings.section.notifyCheckboxes.radio.flexiblySelectedDefault')} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio name='hasNotifyOnResources' value='selected' label={t('settings.section.notifyCheckboxes.radio.alwaysSelected')} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio name='hasNotifyOnResources' value='deselected' label={t('settings.section.notifyCheckboxes.radio.neverSelected')} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <FormText noOffset>{dangerousHTML(t('settings.section.notifyCheckboxes.customer.title'))}</FormText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio name='hasNotifyOnCustomers' value='default' label={t('settings.section.notifyCheckboxes.radio.flexiblySelectedDefault')} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio name='hasNotifyOnCustomers' value='selected' label={t('settings.section.notifyCheckboxes.radio.alwaysSelected')} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio name='hasNotifyOnCustomers' value='deselected' label={t('settings.section.notifyCheckboxes.radio.neverSelected')} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </BorderedBox>
                  </FormSection>
                </SimpleTabsBodyItem>
                {/* WIDGET */}
                <SimpleTabsBodyItem name='widget'>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.language.heading')} icon='language' ignoreTopOffset />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.questionText')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='showWidgetLanguageSelector'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.form.availabilityDisplay.heading')} icon='calendar-day' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.availabilityDisplay.text')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio
                          name='isWidgetWeeklyView'
                          value={false}
                          label={t('global.daily')}
                        />
                        <HintText className='offset-left'>{t('settings.section.widget.availabilityDisplay.daily.infoText')}</HintText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Radio
                          name='isWidgetWeeklyView'
                          value
                          label={t('global.weekly')}
                        />
                        <HintText className='offset-left'>{t('settings.section.widget.availabilityDisplay.weekly.infoText')}</HintText>
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.closeCategories.heading')} icon='sort' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.checkbox.closeCategories.infoText')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='showWidgetCategoriesClosed'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {(showWidgetCategoriesClosed &&
                      <BorderedBox>
                        <Checkbox
                          name='showWidgetFirstCategoryOpen'
                          label={t('settings.section.widget.checkbox.categories.open')}
                        />
                      </BorderedBox>
                    )}
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.form.hideResources.heading')} icon='user-circle' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.checkbox.hideResources.infoText')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='hideResourcesSection'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.hideServiceDuration.heading')} icon='hourglass-half' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.hideServiceDuration.text')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='hideServiceDuration'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  {(!customersMiddlewareUrl &&
                    <FormSection>
                      <Title size='m' isCompact label={t('settings.section.registration.heading')} icon='user-secret' />
                      <Row>
                        <Col>
                          <FormText noOffset>{t('settings.section.registration.questionText')}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='hasTimifyLogin'
                            label={t('settings.section.registration.timify.label')}
                            inline
                          />
                          <HintText className='offset-left'>{t('settings.section.registration.hintText')}</HintText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='hasFacebookLogin'
                            label='Facebook' inline
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='hasGuestBooking'
                            label={t('global.guest')} inline
                          />
                        </Col>
                      </Row>
                      <Error noOffset name='settingsAuthGlobalErrors' />
                    </FormSection>
                  )}
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.customTermsAndPolicy.heading')} icon='file-signature' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.termsAndConditionsAndPrivacyPolicy.text')}</FormText>
                      </Col>
                    </Row>
                    <BorderedBox>
                      <Row>
                        <Col>
                          <Checkbox
                            wrapperClassName='no-top-offset'
                            name='showCustomTermsAndConditions'
                            label={t('settings.section.widget.checkbox.customTerms.label')} theme='switch'
                          />
                        </Col>
                      </Row>
                      {(showCustomTermsAndConditions &&
                        <Row>
                          <Col>
                            <BorderedBox>
                              <Input
                                name='customTermsAndConditionsUrl'
                                label={t('settings.section.widget.terms.label')}
                                hintText='http://mywebsite.com/terms.html, https://mywebsite.com/conditions'
                              />
                            </BorderedBox>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox
                            wrapperClassName='no-top-offset'
                            name='showCustomPrivacyPolicy'
                            label={t('settings.section.widget.checkbox.customPrivacy.label')}
                            theme='switch'
                          />
                        </Col>
                      </Row>
                      {(showCustomPrivacyPolicy &&
                        <Row>
                          <Col>
                            <BorderedBox>
                              <Input
                                name='customPrivacyPolicyUrl'
                                label={t('settings.section.widget.privacy.label')}
                                hintText='http://mywebsite.com/terms.html, https://mywebsite.com/conditions'
                              />
                            </BorderedBox>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox
                            wrapperClassName='no-top-offset'
                            name='showDisclaimer'
                            label={t('settings.section.widget.checkbox.disclaimer.label')}
                            theme='switch'
                          />
                        </Col>
                      </Row>
                      {(showDisclaimer &&
                        <BorderedBox noOffset>
                          <Row>
                            <Col>
                              <Textarea
                                className='ta-settings-widget__disclaimer'
                                name='disclaimerText'
                                label={t('settings.section.widget.input.disclaimer.label')}
                                useEditor
                                limit={400}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio value='CUSTOMER_DETAIL_FORM' name='disclaimerType' label={t('settings.section.widget.radio.disclaimer.customer.label')} />
                              <HintText className='offset-left'>{t('settings.section.widget.radio.disclaimer.customer.hint')}</HintText>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Radio value='BOOKING_SUMMARY_VIEW' name='disclaimerType' label={t('settings.section.widget.radio.disclaimer.booking.label')} />
                              <HintText className='offset-left'>{t('settings.section.widget.radio.disclaimer.booking.hint')}</HintText>
                            </Col>
                          </Row>
                        </BorderedBox>
                      )}
                    </BorderedBox>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.timezone.heading')} icon='clock' />
                    <Row>
                      <Col>
                        <Checkbox
                          name='showWidgetTimezoneSelector'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  {(!hideWidgetSettingsConversions &&
                    <FormSection className='no-margin'>
                      <Title size='m' isCompact label={t('settings.section.widget.conversionTracking.heading')} icon='chart-area' />
                      <Row>
                        <Col>
                          <FormText noOffset>{t('settings.section.widget.conversionTracking.infoText')}</FormText>
                        </Col>
                      </Row>
                      <BorderedBox>
                        <Row>
                          <Col>
                            <Checkbox
                              name='useFacebookConversionTracking'
                              theme='switch'
                            >
                              <Row>
                                <Col>
                                  {t('settings.section.widget.conversionTracking.facebookPixel.label')}
                                </Col>
                                <Col>
                                  <PopupTriggerWrapper name='facebook-conversion-tooltip' position='left'>
                                    <FontAwesome5 icon='question-circle' type='s' />
                                  </PopupTriggerWrapper>
                                </Col>
                              </Row>
                            </Checkbox>
                          </Col>
                        </Row>
                        {(useFacebookConversionTracking &&
                          <Row>
                            <Col>
                              <BorderedBox>
                                <Input
                                  name='facebookConversionTrackingID'
                                  label={t('settings.section.widget.conversionTracking.facebookPixel.ID.label')}
                                  hintText={t('settings.section.widget.conversionTracking.facebookPixel.ID.hint')}
                                />
                              </BorderedBox>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col>
                            <Checkbox
                              name='useGoogleConversionTracking'
                              theme='switch'
                            >
                              <Row>
                                <Col>
                                  {t('settings.section.widget.conversionTracking.googleAnalytics.label')}
                                </Col>
                                <Col>
                                  <PopupTriggerWrapper name='google-conversion-tooltip' position='left'>
                                    <FontAwesome5 icon='question-circle' type='s' />
                                  </PopupTriggerWrapper>
                                </Col>
                              </Row>
                            </Checkbox>
                          </Col>
                        </Row>
                        {(useGoogleConversionTracking &&
                          <Row>
                            <Col>
                              <BorderedBox>
                                <Input
                                  name='googleConversionTrackingID'
                                  label={t('settings.section.widget.conversionTracking.googleAnalytics.ID.label')}
                                  hintText={t('settings.section.widget.conversionTracking.googleAnalytics.ID.hint')}
                                />
                              </BorderedBox>
                            </Col>
                          </Row>
                        )}
                        {((useFacebookConversionTracking || useGoogleConversionTracking) &&
                          <>
                            <Alert theme='alert'>
                              {t('settings.section.widget.conversionTracking.warning.text')}
                            </Alert>
                            <Row>
                              <Col>
                                <SingleSelect
                                  label={t('settings.section.widget.conversionTracking.customMessageVisibility.label')}
                                  className='ta-single-select'
                                  noResultsText={t('global.noResults')}
                                  name='customMessageVisibility'
                                  options={customMessageVisibilityOptions}
                                  multi
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox
                                  name='useCustomiseConsentMessage'
                                  label={t('settings.section.widget.conversionTracking.customiseConsentMessage.label')}
                                  theme='switch'
                                />
                              </Col>
                            </Row>
                            {(useCustomiseConsentMessage &&
                              <Row>
                                <Col>
                                  <BorderedBox>
                                    <Textarea
                                      useEditor
                                      limit={1000}
                                      name='customConsentMessage'
                                      label={t('settings.section.widget.conversionTracking.customConsentMessage.label')}
                                      hintText={t('settings.section.widget.conversionTracking.customConsentMessage.hint')}
                                      className='big'
                                    />
                                  </BorderedBox>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </BorderedBox>
                    </FormSection>
                  )}
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.widget.redirectToExternal.heading')} icon='external-link-alt' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.widget.redirectToExternal.infoText')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='showWidgetExternalUrlRedirect'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                    {(showWidgetExternalUrlRedirect &&
                      <BorderedBox noOffset>
                        <Input
                          name='widgetExternalUrlRedirect'
                          label={t('settings.section.widget.checkbox.redirectToExternal.websiteField.label')}
                          hintText='http://mywebsite.com/terms.html, https://mywebsite.com/conditions'
                        />
                      </BorderedBox>
                    )}
                  </FormSection>
                </SimpleTabsBodyItem>
                {/* OTHER */}
                <SimpleTabsBodyItem name='other'>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.showExternalID.heading')} icon='fingerprint' ignoreTopOffset />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.showExternalID.infoText')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='showExternalIds'
                          label={t('global.enable')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  <FormSection>
                    <Title size='m' isCompact label={t('settings.section.showAddServiceCombinations.heading')} icon='layer-group' />
                    <Row>
                      <Col>
                        <FormText noOffset>{t('settings.section.showAddServiceCombinations.infoText')}</FormText>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          name='showAddServiceCombinations'
                          label={t('settings.section.showAddServiceCombinations.checkbox.enable.label')}
                          theme='switch'
                        />
                      </Col>
                    </Row>
                  </FormSection>
                  {(isAdmin && !hideOtherSettingsCrossAccountSync &&
                    <FormSection>
                      <Title size='m' isCompact label={t('settings.section.enableCrossAccountSync.heading')} icon='sync' ignoreTopOffset />
                      <Row>
                        <Col>
                          <FormText noOffset>{t('settings.section.enableCrossAccountSync.infoText')}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='enableCrossAccountsBookings'
                            label={t('global.enable')}
                            theme='switch'
                          />
                        </Col>
                      </Row>
                    </FormSection>
                  )}
                  {(isAdmin && !hideOtherSettingsCallCentre && callCentreEnabled &&
                    <FormSection>
                      <Title size='m' isCompact label={t('settings.section.callCenterLoginButton.heading')} icon='phone-square' />
                      <Row>
                        <Col>
                          <FormText noOffset>{t('settings.section.callCenterLoginButton.infoText')}</FormText>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox
                            name='callCenterLoginButton'
                            label={t('settings.section.callCenterLoginButton.checkbox.enable.label')}
                            theme='switch'
                          />
                        </Col>
                      </Row>
                    </FormSection>
                  )}
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
            <Row ignoreTopOffset>
              <Col>
                <Error name='globalErrors' />
                <Error noOffset name='errorInTabs' shouldNotTranslate />
              </Col>
            </Row>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BookingSettingsEditForm
