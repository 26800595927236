import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { CustomerFieldsCategory, CustomerFieldsCategoryWithAccordion } from '../../Beauties'
import { sortByOrderIndex } from '../../Utils'

const CustomerFieldsCategoryWrapper = props => {
  let {
    id,
    category,
    pendingCategory,
    customerFields,
    isDragAndDropMode,
    index,
    isLast,
    areCategoriesExpanded,
    routeName,
    enterpriseId,
    account
  } = props
  pendingCategory = !!pendingCategory
  account = account || {}
  enterpriseId = enterpriseId || null
  const pending = pendingCategory === category.id
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalDataFieldsRead, globalDataFieldsWrite, globalDataFieldsDelete } = enterprisePermissions || {}
  globalDataFieldsRead = !!isAdmin || !!globalDataFieldsRead
  globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite
  globalDataFieldsDelete = !!isAdmin || !!globalDataFieldsDelete

  const [initial] = useState({
    id,
    routeName,
    customerFieldsRead: globalDataFieldsRead
  })

  // ComponentDidMount
  useEffect(() => {
    const { id, routeName, customerFieldsRead } = initial
    if (!['customerFieldsCategoryEdit'].includes(routeName)) return
    customerFieldsRead
      ? handlers.customerFieldCategoryPreviewGet({ id })
      : handlers.navigateToPath('/customers/customer-fields')
  }, [initial])

  return globalDataFieldsRead
    ? (
      <>
        {(isDragAndDropMode &&
          <CustomerFieldsCategory
            isDragAndDropMode={isDragAndDropMode}
            category={category}
            customerFields={customerFields.filter(customerField => customerField.categoryId === category.id)}
            pending={pending}
            index={index}
            isLast={isLast}
            areCategoriesExpanded={areCategoriesExpanded}
            enterpriseId={enterpriseId}
            globalDataFieldsRead={globalDataFieldsRead}
            globalDataFieldsWrite={globalDataFieldsWrite}
            globalDataFieldsDelete={globalDataFieldsDelete}
          />
        )}
        {(!isDragAndDropMode &&
          <CustomerFieldsCategoryWithAccordion
            category={category}
            customerFields={customerFields.filter(customerField => customerField.categoryId === category.id)}
            pending={pending}
            index={index}
            isLast={isLast}
            enterpriseId={enterpriseId}
            globalDataFieldsRead={globalDataFieldsRead}
            globalDataFieldsWrite={globalDataFieldsWrite}
            globalDataFieldsDelete={globalDataFieldsDelete}
          />
        )}
      </>
      )
    : null
}

CustomerFieldsCategoryWrapper.propTypes = {
  customerFields: PropTypes.arrayOf(PropTypes.object)
}

const maps = state => ({
  id: state.router.data.categoryId,
  routeName: state.router.name,
  customerFields: (state.customerFields.list || []).sort(sortByOrderIndex),
  pendingCategory: state.customerFields.pendingCategory,
  enterpriseId: (state.account && state.account.enterprise && state.account.enterprise.id),
  account: state.account
})

export default connect(maps)(CustomerFieldsCategoryWrapper)
