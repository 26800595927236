import React from 'react'
import {
  FontAwesome5,
  SingleSelect,
  Input,
  Form,
  Row,
  Col,
  t
} from '../../Common'

const ServicesFilters = props => {
  const { disabled, hasServiceCombinations } = props
  const options = [
    { label: t('global.service'), value: 'service' },
    { label: t('global.serviceCombination'), value: 'serviceCombination' }
  ]

  return (
    <div className='ta-services__filters'>
      <Form name='servicesFilters'>
        <Row>
          <Col>
            <Input
              label={t('global.searchService.label')}
              type='text'
              name='search'
              clearable={!disabled}
              disabled={disabled}
              addon={<FontAwesome5 icon='search' type='r' />}
            />
          </Col>
          {(hasServiceCombinations &&
            <Col size={50}>
              <SingleSelect
                label={t('global.type.label')}
                name='type'
                options={options}
                clearable
                icon='sort'
              />
            </Col>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default ServicesFilters
