import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { Loader, isRouteDisabledByCustomisations } from '../../../Common'
import { DEFAULT_LOCALE, DEV_API_URL } from '../../../Settings'
import { AppsIframe } from '../../../Beauties'

const AppsIframeWrapper = props => {
  const iframeRef = useRef()
  let {
    id,
    app,
    pending,
    locale
  } = props
  app = app || {}
  pending = !!pending
  locale = locale || DEFAULT_LOCALE
  const {
    id: appId,
    plan,
    refreshToken,
    installedVersion,
  } = app || {}
  let iframeUrl = ''
  // Generate iframe url
  if (installedVersion) {
    // DEPRICATED company
    iframeUrl = `${DEV_API_URL}/${appId}/${installedVersion}/${refreshToken}/?locale=${locale}&enterprise=1`
  }

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.appPreviewGet({ id, forceFetch: true })
  }, [id, installedVersion])

  useEffect(() => {
    const listenMessage = (message) => {
      if (message.data.action === 'timify:apps:metadata') {
        handlers.appMetadataSet({ appId: message.data.appId, metadata: message.data.payload })
      }

      if (message.data.action === 'timify:apps:loaded') {
        iframeRef.current && handlers.appMetadataSend({ appId, ref: iframeRef })
      }

    }
    window.addEventListener('message', listenMessage, false)
    return () => window.removeEventListener('message', listenMessage, false)
  }, [])

  return (
    <>
      <Loader active={pending} />
      {!pending && (
        <AppsIframe ref={iframeRef} iframeUrl={iframeUrl} plan={plan} />
      )}
    </>
  )
}

AppsIframeWrapper.propTypes = {
  id: PropTypes.string,
  app: PropTypes.object,
  pending: PropTypes.bool
}

const maps = state => ({
  id: selectors.routerDataFieldSelector(state, { field: 'id' }),
  app: selectors.appsSelectedSelector(state),
  pending: selectors.appsFieldSelector(state, { field: 'pendingIframe' }),
  locale: selectors.appLocaleSelector(state)
})

export default connect(maps)(AppsIframeWrapper)
