import React from 'react'

import { AppsSkeleton, AppsSubscriptionInvoiceRow } from '../../../Beauties'
import {
  Title,
  Loader,
  t,
  Button,
  Alert,
  Price
} from '../../../Common'

import './AppsSubscription.css'

const AppsSubscriptionCancelled = (props) => {
  const {
    name,
    version,
    plan,
    onContinue,
    onUninstall,
    pricingType,
    isVariablePrice,
    unitDescription,
    isActive,
    pending,
    activeUntil,
    nextInvoiceDate,
    isOwner,
    onResubscribe,
    usageUnitPrice,
    price,
    priceRange,
    currencyCode
  } = props
  const headerImage = isActive ? '/images/paidApps/banner-cancelled-active.png' : '/images/paidApps/banner-cancelled-gone.png'
  let [minPrice, maxPrice] = priceRange || []
  minPrice = minPrice || ''
  maxPrice = maxPrice || ''
  let invoiceRowLabel = ''
  if (pricingType === 'payPerUsage' && isVariablePrice) invoiceRowLabel = t('apps.subscriptions.variablePrice')
  if (pricingType === 'payPerUsage' && !isVariablePrice) invoiceRowLabel = t('apps.subscriptions.entityPrice', [{ key: 'NAME', value: unitDescription }])
  if (pricingType === 'monthly') invoiceRowLabel = t('apps.preview.section.pricing.monthlySubscription')
  let invoicePrice
  if (pricingType === 'monthly') {
    invoicePrice = (
      <Price currency={currencyCode} price={price} />
    )
  }
  if (pricingType === 'payPerUsage' && !isVariablePrice) {
    invoicePrice = (
      <span>
        <Price currency={currencyCode} price={usageUnitPrice} /> / {unitDescription}
      </span>
    )
  }
  if (pricingType === 'payPerUsage' && isVariablePrice) {
    invoicePrice = (
      <span>
        <Price currency={currencyCode} price={minPrice} /> - <Price currency={currencyCode} price={maxPrice} /> / {unitDescription}
      </span>
    )
  }

  const footerClassNames = ['ta-btn', 'ta-btn-black-ghost']
  if (isActive) footerClassNames.push('ta-btn-block')
  const footer = (
    <Button isTertiary isGhost block={isActive} onClick={isActive ? onContinue : onUninstall}>
      {isActive && t('buttons.continueToApp')}
      {!isActive && t('buttons.uninstall.app')}
    </Button>
  )
  const overviewText = t('apps.subscriptionCancelled.overview.text', [
    { key: 'DATE', value: activeUntil },
    { key: 'INVOICE_DATE', value: nextInvoiceDate }
  ])

  if (pending) {
    return (<Loader active />)
  }

  return (
    <AppsSkeleton
      name={name}
      version={version}
      plan={plan}
      className='ta-apps__subscription-cancelled'
      headerText={t('apps.subscriptionCancelled.header')}
      headerImage={headerImage}
      footer={footer}
    >
      <Alert theme='info'>
        {t('global.vatApplicability')}
      </Alert>
      <Title theme='striped' label={t('apps.subscriptions.subscriptionType.title')} />
      {pricingType === 'payPerUsage' && t('apps.preview.section.pricing.payPerUsage')}
      {pricingType === 'monthly' && t('apps.preview.section.pricing.monthlySubscription')}
      <Title theme='striped' topOffset label={t('global.overview')} />
      <AppsSubscriptionInvoiceRow
        label={invoiceRowLabel}
        description={overviewText}
        price={invoicePrice}
      />
      {isOwner && (
        <Button onClick={onResubscribe} isBlock isPrimary>
          {t('buttons.resubscribeNow')}
        </Button>
      )}
    </AppsSkeleton>
  )
}

export default AppsSubscriptionCancelled
