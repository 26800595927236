import React from 'react'
import { connect, handlers } from '../../Store'
import { BranchesResourceFormCustomPermissions } from '../../Beauties'

const BranchesPreviewResourceFormCustomPermissionsWrapper = props => {
  const onCategoryChange = (values, name) => {
    const { branch, form } = props
    const { resources: branchResourcesObj } = branch || {}
    const resources = (branchResourcesObj && branchResourcesObj.resources) || []
    const filteredResourceIds = resources
      .filter(item => values.includes(item.categoryId))
      .map(item => item.id)
    const itemsName = name.replace('Categories', 'Items')
    const field = form[itemsName] || { values: [] }
    const newValues = field.values.filter(item => !filteredResourceIds.includes(item))
    if (field.values.length !== newValues.length) {
      handlers.formFieldsUpdate('branchesResources', { [itemsName]: { ...field, values: newValues } })
    }
  }

  return (
    <BranchesResourceFormCustomPermissions {...props} onCategoryChange={onCategoryChange} />
  )
}

const maps = (state, props) => {
  const { branch } = props
  const { plan = 'CLASSIC', resources: branchResourcesObj } = branch || {}
  const availableResources = (branchResourcesObj && branchResourcesObj.resources) || []
  const availableResourceCategories = (branchResourcesObj && branchResourcesObj.resourceCategories) || []

  return ({
    plan,
    availableResources,
    availableResourceCategories,
    isOwner: state.account.isOwner,
    form: state.forms.branchesResource || {},
    hasAccount: state.forms.branchesResource && state.forms.branchesResource.hasAccount && state.forms.branchesResource.hasAccount.value,
    hasInvitation: state.forms.branchesResource && state.forms.branchesResource.hasInvitation && state.forms.branchesResource.hasInvitation.value,
    // Account
    permissionsAccountRead: (state.forms.branchesResource.permissionsAccountRead && state.forms.branchesResource.permissionsAccountRead.value === 'YES') || false,
    // Billing
    permissionsBillingRead: (state.forms.branchesResource.permissionsBillingRead && state.forms.branchesResource.permissionsBillingRead.value === 'YES') || false,
    permissionsBillingWrite: (state.forms.branchesResource.permissionsBillingWrite && state.forms.branchesResource.permissionsBillingWrite.value === 'YES') || false,
    // Calendar
    permissionsCalendarRead: (state.forms.branchesResource.permissionsCalendarRead && state.forms.branchesResource.permissionsCalendarRead.value === 'YES') || false,
    permissionsCalendarReadOptions: (state.forms.branchesResource.permissionsCalendarReadOptions && state.forms.branchesResource.permissionsCalendarReadOptions.value) || '',
    permissionsCalendarReadCategories: (state.forms.branchesResource.permissionsCalendarReadCategories && state.forms.branchesResource.permissionsCalendarReadCategories.values) || [],
    permissionsCalendarWrite: (state.forms.branchesResource.permissionsCalendarWrite && state.forms.branchesResource.permissionsCalendarWrite.value === 'YES') || false,
    // ShiftPlan
    permissionsShiftPlanRead: (state.forms.branchesResource.permissionsShiftPlanRead && state.forms.branchesResource.permissionsShiftPlanRead.value === 'YES') || false,
    permissionsShiftPlanWrite: (state.forms.branchesResource.permissionsShiftPlanWrite && state.forms.branchesResource.permissionsShiftPlanWrite.value === 'YES') || false,
    // Customers
    permissionsCustomersRead: (state.forms.branchesResource.permissionsCustomersRead && state.forms.branchesResource.permissionsCustomersRead.value === 'YES') || false,
    permissionsCustomersReadOptions: (state.forms.branchesResource.permissionsCustomersReadOptions && state.forms.branchesResource.permissionsCustomersReadOptions.value) || '',
    permissionsCustomersWrite: (state.forms.branchesResource.permissionsCustomersWrite && state.forms.branchesResource.permissionsCustomersWrite.value === 'YES') || false,
    // Resources
    permissionsResourcesRead: (state.forms.branchesResource.permissionsResourcesRead && state.forms.branchesResource.permissionsResourcesRead.value === 'YES') || false,
    permissionsResourcesReadOptions: (state.forms.branchesResource.permissionsResourcesReadOptions && state.forms.branchesResource.permissionsResourcesReadOptions.value) || '',
    permissionsResourcesReadCategories: (state.forms.branchesResource.permissionsResourcesReadCategories && state.forms.branchesResource.permissionsResourcesReadCategories.values) || [],
    permissionsResourcesWrite: (state.forms.branchesResource.permissionsResourcesWrite && state.forms.branchesResource.permissionsResourcesWrite.value === 'YES') || false,
    // BookingSetup
    permissionsBookingSetupRead: (state.forms.branchesResource.permissionsBookingSetupRead && state.forms.branchesResource.permissionsBookingSetupRead.value === 'YES') || false,
    // Offers
    permissionsOffersRead: (state.forms.branchesResource.permissionsOffersRead && state.forms.branchesResource.permissionsOffersRead.value === 'YES') || false,
    permissionsOffersWrite: (state.forms.branchesResource.permissionsOffersWrite && state.forms.branchesResource.permissionsOffersWrite.value === 'YES') || false,
    // Apps
    permissionsAppsRead: (state.forms.branchesResource.permissionsAppsRead && state.forms.branchesResource.permissionsAppsRead.value === 'YES') || false
  })
}

export default connect(maps)(BranchesPreviewResourceFormCustomPermissionsWrapper)
