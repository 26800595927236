import React from 'react'
import momenttz from 'moment-timezone'
import { getDayLabelByIndex } from '../../../Utils'
import { t, format } from '../../../Common'

import CustomerBookingsPrintListItem from './CustomerBookingsPrintListItem'

const CustomerBookingsPrintList = props => {
  const { events = [] } = props
  let dayLabel
  let lastDate

  return (events && events.length > 0) && events.map((event, index) => {
    const dayIndex = Number(momenttz.utc(event.from, 'YYYY-MM-DD').day())
    const day = (
      dayLabel !== t(`weekdays.${getDayLabelByIndex(dayIndex)}.long`) ||
      lastDate !== momenttz.utc(event.from, 'YYYY-MM-DD').format('YYYY-MM-DD')
    ) &&
      (
        <div className='ta-customer__bookings-print__list__sub-title'>
          <strong>{t(`weekdays.${getDayLabelByIndex(dayIndex)}.long`)}</strong>
          {`, ${format(event.from, 'long', { isUTC: true, format: 'YYYY-MM-DD HH:mm' })}`}
        </div>
      )

    dayLabel = t(`weekdays.${getDayLabelByIndex(dayIndex)}.long`)
    lastDate = momenttz.utc(event.from, 'YYYY-MM-DD').format('YYYY-MM-DD')

    return (
      <>
        {day}
        <CustomerBookingsPrintListItem key={index} item={event} />
      </>
    )
  })
}

export default CustomerBookingsPrintList
