import React, { PureComponent } from 'react'
import { handlers, connect } from '../../../Store'
import { PopupBranchStatisticsSettings } from '../../../Beauties'

class PopupBranchStatisticsSettingsWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.onSelectItem = this.onSelectItem.bind(this)
  }

  componentDidMount () {
    const { id, form } = this.props
    const { statisticId, isOverallStatistic } = id || {}
    !Object.keys(form).length && handlers.formSet(`${isOverallStatistic ? statisticId + 'Overall' : statisticId}StatisticsForm`, {
      search: { value: '' },
      label: { value: '' }
    })
    // reposition the modal
    const el = document.querySelector('.ta-popup__content.left.ta-statistics-box')
    const leftOffset = parseInt(el.style.left.replace('px', ''))
    el.style.left = `${leftOffset + 127}px`
  }

  componentWillUnmount () {
    const { id } = this.props
    const { statisticId, isOverallStatistic } = id || {}
    handlers.formFieldsUpdate(`${isOverallStatistic ? statisticId + 'Overall' : statisticId}StatisticsForm`, { search: { value: '' } })
    const element = document.querySelector('i.active')
    if (element) element.classList.remove('active')
  }

  onSelectItem (item) {
    if (!item) return
    const { closePopup, companyId, id } = this.props
    const { statisticId, isResourceStatistic, isOverallStatistic } = id || {}
    const { id: itemId } = item || {}
    const filter = isResourceStatistic ? { resourceId: itemId } : { tagId: itemId }
    closePopup && closePopup()
    handlers.branchPreviewStatisticFilter(companyId, statisticId, filter, isOverallStatistic)
    handlers.formFieldsUpdate(`${isOverallStatistic ? statisticId + 'Overall' : statisticId}StatisticsForm`, { label: { value: itemId } })
  }

  render () {
    let {
      tags,
      resources,
      id,
      form
    } = this.props
    const { statisticId, isResourceStatistic, isOverallStatistic } = id || {}
    const { search } = form || {}
    let { value: searchValue } = search || {}
    searchValue = searchValue ? searchValue.toLowerCase() : ''
    tags = tags || []
    resources = resources.resources ? resources.resources : resources || []
    const itemsToFilter = isResourceStatistic ? resources : tags
    const filteredItems = itemsToFilter
      .filter(item => {
        let { name } = item
        name = name || ''
        return (
          (name.toLowerCase().includes(searchValue))
        )
      })
    filteredItems.unshift({ name: 'View all', color: '' })

    return (
      <PopupBranchStatisticsSettings
        search={searchValue}
        items={filteredItems}
        statisticId={statisticId}
        isOverallStatistic={isOverallStatistic}
        onSelectItem={this.onSelectItem}
      />
    )
  }
}

const maps = (state, props) => {
  const branches = (state.branches && state.branches.list) || []
  const { id } = props
  const { statisticId = '', isOverallStatistic } = id || {}
  const companyId = (state.router && state.router.data && state.router.data.id && state.router.data.id) || ''
  const branch = branches.find(item => item.id === companyId) || {}
  const branchResources = branch.resources || []
  const branchTags = (branch.companyTags || []).filter(item => item.isForEvents)

  return {
    form: (state.forms && state.forms[`${isOverallStatistic ? statisticId + 'Overall' : statisticId}StatisticsForm`]) || {},
    tags: branchTags,
    companyId,
    resources: branchResources
  }
}

export default connect(maps)(PopupBranchStatisticsSettingsWrapper)
