import { getCssFileLink } from '../Utils'

export default (props) => {
  const {
    html,
    cssFiles,
    pageStyle
  } = props

  if (!html) return null

  const printArea = document.querySelector('#printArea')
  const domDoc = printArea.contentDocument || printArea.contentWindow.document
  const styleEl = domDoc.createElement('style')
  const defaultPageStyle = pageStyle === undefined
    ? '@page { size: A4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }'
    : pageStyle

  styleEl.appendChild(domDoc.createTextNode(defaultPageStyle))

  domDoc.head.appendChild(styleEl)

  const defaultPrintCss = getCssFileLink('/cssPrint/Print.css')

  domDoc.open()
  domDoc.write(`<div className='ta-print'>${html}</div>`)
  domDoc.close()
  domDoc.head.appendChild(defaultPrintCss)

  if (cssFiles && cssFiles.length > 0) {
    cssFiles.forEach(file => {
      const cssUrlLink = getCssFileLink(file)
      domDoc.head.appendChild(cssUrlLink)
    })
  }

  setTimeout(() => {
    printArea.focus()
    printArea.contentWindow.print()
  }, 500)
}
