import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Row,
  Col,
  Alert,
  Input,
  Error,
  SingleSelect,
  t
} from '../../../Common'
import {
  BranchesPreviewResourceFormDetailsWrapper,
  // BranchesPreviewResourceFormPermissionsWrapper,
  BranchesPreviewResourceFormAllowancePlanWrapper
} from '../../../Beasts'
import { BranchesResourceFormAvatar } from '../../../Beauties'
import { dangerousHTML } from '../../../Utils'

import './BranchesResourceForm.css'

const BranchesResourceForm = props => {
  let {
    branch,
    onSubmit,
    cancelLink,
    type,
    isDependency,
    showExternalIds,
    customisations,
    plan,
    providers
  } = props
  providers = providers || []
  const { settings } = customisations || {}
  const { hideResourcesFormShiftPlanNote } = settings || {}

  const linkToShiftPlanClassNames = ['ta-btn ta-btn-isSecondary']
  if (type === 'add') linkToShiftPlanClassNames.push('disabled')
  return (
    <div className='ta-branches-resources__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title
            label={t('branches.form.group.edit.resourcesDetails.title')}
            addOn={isDependency && <p className='ta-branches-resources__form__dependency'>{t('branches.form.group.edit.resourcesDetails.title.extraText')}</p>}
          />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesResource'>
            {(showExternalIds &&
              <FormSection>
                <Title size='m' label={t('global.externalId')} icon='fingerprint' />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      label={t('global.externalId')}
                      hintText={t('global.externalId.hint')}
                      disabled={providers.includes('PRIVATE_APP')}
                      limit={60}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}
            <FormSection className='ta-cleaner'>
              <Title
                size='m'
                label={t('branches.form.group.edit.resourcesDetails.section.details.title')}
                icon='user'
              />
              <BranchesResourceFormAvatar name='avatar' />
              <BranchesPreviewResourceFormDetailsWrapper type={type} branch={branch} />
            </FormSection>
            <FormSection>
              <Title
                size='m'
                label={t('branches.form.group.edit.resourcesDetails.section.category.title')}
                icon='folder'
              />
              <Row>
                <Col>
                  <SingleSelect
                    disabled={plan !== 'ENTERPRISE'}
                    name='category'
                    label={t('branches.form.group.edit.resourcesDetails.section.category.select.label')}
                    mandatory
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection ignoreBottomOffset>
              <Title
                size='m'
                label={t('branches.form.group.edit.resourcesDetails.section.workingTimes.title')}
                icon='clock'
              />
              <BranchesPreviewResourceFormAllowancePlanWrapper plan={plan} customisations={customisations} />
              {(!hideResourcesFormShiftPlanNote &&
                <Alert
                  noOffset
                  label={dangerousHTML(t('branches.form.group.edit.resourcesDetails.section.workingTimes.warning'))}
                  theme='alert'
                >
                  {type === 'add' && <p>{dangerousHTML(t('branches.form.group.edit.resourcesDetails.section.workingTimes.warning.add'))}</p>}
                  {type === 'edit' && <p>{dangerousHTML(t('branches.form.group.edit.resourcesDetails.section.workingTimes.warning.edit'))}</p>}
                </Alert>
              )}
            </FormSection>
            {/* <BranchesPreviewResourceFormPermissionsWrapper branch={branch} /> */}
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesResourceForm
