import React, { useEffect } from 'react'

import { AppsSubscriptionPlansForm } from '../../../Beauties'
import { connect, handlers, selectors } from '../../../Store'

const mock = {
  newOverviewText: 'You will be charged the amount you’ve cumulated over the period from the <strong>25.10.2023 until the 25.11.2023</strong>. Your invoice will be automatically generated on the 26th. An overview of your spendings will be available in the app. App usage and associated fees can be disabled from the Settings view of the app at any time.  You can cancel your subscription at any time before the 25th of the month, otherwise it will be automatically renewed.',
  plans: [
    {
      name: 'basic',
      text: 'Includes 50 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 10
    },
    {
      name: 'silver',
      text: 'Includes 150 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 22
    },
    {
      name: 'gold',
      text: 'Includes 250 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 35
    },
    {
      name: 'premium',
      text: 'Includes 350 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 100
    }
  ],
  vat: 0.19
}
const AppsSubscriptionPlansFormWrapper = (props) => {
  let {
    plan,
    previousPlan,
    form,
    app,
    billingData,
    country,
    billingForm
  } = props
  app = app || {}
  country = country || {}
  billingForm = billingForm || {}
  const {
    // plans,
    newOverviewText,
    vat
  } = mock
  let {
    plans,
    currencyCode
  } = app.appInstallation || {}
  plans = plans || []
  const {
    name,
    address1,
    zipCode,
    city
  } = billingData || {}
  const hasBillingDetails = name && address1 && zipCode && city
  const { skipVatId } = country || {}
  const hasVatId = !skipVatId

  useEffect(() => {
    handlers.appSubscriptionFormGet()
    handlers.appSubscriptionBillingFormGet()
  }, [])

  const handleOnPlanClick = (plan) => {
    handlers.formFieldsUpdate('appSubscription', { plan: { value: plan } })
  }

  const handleOnSubmit = () => {
    handlers.appSubscriptionFormSave({ ...form, hasBillingDetails: true })
    handlers.appSubscriptionBillingFormSave({ ...billingForm, hasBillingDetails, hasVatId })
  }

  return (
    <AppsSubscriptionPlansForm
      plans={plans}
      activePlan={plan}
      currencyCode={currencyCode}
      previousPlan={previousPlan}
      onPlanClick={handleOnPlanClick}
      newOverviewText={newOverviewText}
      vat={vat}
      onSubmit={handleOnSubmit}
    />
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    app: selectors.appsFindByIdSelector(state, { id }),
    form: selectors.formSelector(state, { formName: 'appSubscription' }),
    plan: selectors.formFieldPropertySelector(state, { formName: 'appSubscription', name: 'plan', property: 'value' }),
    billingData: selectors.billingDataSelector(state),
    country: selectors.countrySelector(state),
    billingForm: selectors.formSelector(state, { formName: 'appSubscriptionBilling' })
  }
}

export default connect(maps)(AppsSubscriptionPlansFormWrapper)
