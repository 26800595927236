import React from 'react'
import {
  Title,
  AccordionList,
  AccordionListTitle,
  AccordionListBody,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  IconListItemButtons,
  Button,
  Row,
  Col,
  Spinner,
  t,
  Alert
} from '../../../Common'
import { BranchesPreviewCustomersFilter } from '../../../Beauties'
import { PopupTriggerWrapper } from '../../../Beasts'
import { highlight } from '../../../Utils'

const BranchesPreviewCustomers = props => {
  let {
    isPending,
    customers,
    search,
    branch
  } = props
  customers = customers || []
  return (
    <div className='ta-branch__preview__customers'>
      {isPending && <Spinner />}
      {(!isPending &&
        <>
          <BranchesPreviewCustomersFilter />
          <Title size='m' label={t('global.customers')} icon='folder' ignoreTopOffset />
          {customers.map(item => {
            const {
              id: customerId,
              companyId,
              externalId,
              totalBookings,
              fullName,
              phone,
              email
            } = item

            return (
              <AccordionList key={customerId} expand={false}>
                <AccordionListTitle>
                  <div>{highlight((fullName || email || customerId) || '', search)}</div>
                </AccordionListTitle>
                <AccordionListBody>
                  <Title size='m' label={t('branches.preview.customers.section.details.title')} noIcon ignoreTopOffset />
                  <Row>
                    <Col size={55}>
                      <IconListItem>
                        <IconListItemTitle width='110px'>ID:</IconListItemTitle>
                        <IconListItemBody offset='110px'>{customerId || '-'}</IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='110px'>{t('global.externalId')}:</IconListItemTitle>
                        <IconListItemBody offset='110px'>{externalId || '-'}</IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='110px'>{t('global.name')}:</IconListItemTitle>
                        <IconListItemBody offset='110px'>{fullName || '-'}</IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='110px'>{t('global.email')}:</IconListItemTitle>
                        <IconListItemBody offset='110px'>{email || '-'}</IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='110px'>{t('global.totalBookings')}:</IconListItemTitle>
                        <IconListItemBody offset='110px'>{totalBookings || '-'}</IconListItemBody>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle width='110px'>{t('global.phone')}:</IconListItemTitle>
                        <IconListItemBody offset='110px'>{(phone && phone.phone) || '-'}</IconListItemBody>
                      </IconListItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Title size='m' label={t('branches.preview.customers.section.actions.title')} icon='directions' />
                      <IconListItem>
                        <IconListItemTitle>{t('branches.preview.customers.section.editCustomer.title')}:</IconListItemTitle>
                        <IconListItemButtons>
                          <Button as='link' isPrimary icon='pencil' iconType='solid' to={`/branches/${branch.id}/customers/${customerId}`} />
                        </IconListItemButtons>
                      </IconListItem>
                      <IconListItem>
                        <IconListItemTitle>{t('branches.preview.customers.section.deleteCustomer.title')}:</IconListItemTitle>
                        <IconListItemButtons>
                          <PopupTriggerWrapper name='branch-customer-delete' position='left' id={{ companyId, id: customerId, externalId }}>
                            <Button as='link' isPrimary icon='trash' iconType='solid' external />
                          </PopupTriggerWrapper>
                        </IconListItemButtons>
                      </IconListItem>
                    </Col>
                  </Row>
                </AccordionListBody>
              </AccordionList>
            )
          })}
          {!customers.length > 0 && <Alert noOffset label={t('branches.preview.customers.section.noCustomers')} theme='no-content' />}
        </>
      )}
    </div>
  )
}

export default BranchesPreviewCustomers
