import React from 'react'
import {
  FixedContentHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBodyItem,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FixedContent,
  FormButtons,
  FormSection,
  SimpleTabs,
  Title,
  Error,
  Button,
  Form,
  t
} from '../../../../Common'
import {
  BranchesGroupFormSummary,
  BranchesGroupFormAdvanced
} from '../../../../Beauties'
import './BranchesGroupForm.css'

const BranchesGroupForm = props => {
  const {
    hash,
    plan,
    onSubmit,
    cancelLink,
    showExternalIds,
    hasStripeConnected,
    allowedSet
  } = props
  const availableTabs = ['summary', 'advanced']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]

  return (
    <div className='ta-branches-groups__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('branches.form.group.edit.title')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='branchesGroup'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem
                    name='summary'
                    icon='list-ul'
                    label={t('global.summary')}
                  />
                  <SimpleTabsHeaderItem
                    name='advanced'
                    icon='cogs'
                    label={t('global.advanced')}
                    disable={plan !== 'ENTERPRISE'}
                    tooltipText={plan !== 'ENTERPRISE' && t('global.enterpriseOnly')}
                    tooltipPosition='bottom'
                  />
                </SimpleTabsHeader>
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='summary'>
                  <BranchesGroupFormSummary
                    showExternalIds={showExternalIds}
                    hasStripeConnected={hasStripeConnected}
                    type='edit'
                    plan={plan}
                    allowedSet={allowedSet}
                  />
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='advanced'>
                  <BranchesGroupFormAdvanced allowedSet={allowedSet} plan={plan} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button disabled={!allowedSet.length} type='submit' isPrimary>{t('buttons.saveChanges.label')}</Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default BranchesGroupForm
