import React, { Component } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

export default class DnDWrapper extends Component {
  constructor (props) {
    super(props)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragUpdate = this.onDragUpdate.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragUpdate (dragResult) {
    this.props.onDragUpdate(dragResult)
  }

  onDragStart (dragResult) {
    this.props.onDragStart(dragResult)
  }

  onDragEnd (dragResult) {
    const {
      source, // The source category ID
      destination, // The target category ID
      draggableId, // The ID of the card that is beging dragged
      type
    } = dragResult

    // The column where the dragging began
    const sourceColumn = {
      categoryId: source.droppableId,
      itemIndex: source.index
    }

    // The column where the dragging ended
    const destinationColumn = destination && {
      categoryId: destination.droppableId,
      itemIndex: destination.index
    }

    // If the item is dropped outside a droppable component - don't do anything
    if (!destinationColumn) return

    this.props.onDragEnd({
      type,
      id: draggableId,
      oldIndex: sourceColumn.itemIndex,
      newIndex: destinationColumn.itemIndex,
      sourceCategoryId: sourceColumn.categoryId,
      destinationCategoryId: destinationColumn.categoryId
    })
  }

  render () {
    const { children } = this.props
    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        {children}
      </DragDropContext>
    )
  }
}
