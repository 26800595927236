import React from 'react'
import {
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  t
} from '../../Common'

const CustomerFieldCategoryPreviewDetails = props => {
  const { category } = props
  const { id, name, isDefault, translationKey } = category || {}
  const categoryName = isDefault ? t(translationKey) : name

  return (
    <div className='ta-customer-field-category__preview__details'>
      {(id &&
        <IconListItem icon='id-badge' iconType='regular'>
          <IconListItemTitle width='180px'>{t('global.categoryID')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{id}</IconListItemBody>
        </IconListItem>
      )}
      {(name &&
        <IconListItem icon='cube' iconType='solid'>
          <IconListItemTitle width='180px'>{t('global.name')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{categoryName}</IconListItemBody>
        </IconListItem>
      )}
    </div>
  )
}

export default CustomerFieldCategoryPreviewDetails
