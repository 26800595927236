import React from 'react'
import { format, t } from '../../../Common'

const CustomerBookingsPrintListItem = props => {
  const { item } = props

  return (
    <div className='ta-customer__bookings-print__list-item'>
      <div className='ta-customer__bookings-print__list-item__box'>
        {(item.from || item.until) && (
          <div className='ta-customer__bookings-print__list-item__info'>
            <div style={{ backgroundColor: item.color }} className='ta-customer__bookings-print__list-item__info-status' />
            <div className='ta-customer__bookings-print__list-item__info-content'>
              {(item.from && item.until) && <div className='ta-customer__bookings-print__list-item__time'>{`${format(item.from, 'time', { isUTC: true, format: 'YYYY-MM-DD HH:mm' })} - ${format(item.until, 'time', { isUTC: true, format: 'YYYY-MM-DD HH:mm' })}`}</div>}
              {item.title && <div className='ta-customer__bookings-print__list-item__title'>{item.title} {item.isBatch && `(${item.batchNumber}/${item.batchCount})`}</div>}
              {item.customerName && <div className='ta-customer__bookings-print__list-item__customer'>{item.customerName}</div>}
              {item.resources && (
                <div className='ta-customer__bookings-print__list-item__resources'>
                  {t('global.with')}: <strong>{item.resources.map((resource, index) => resource.name).join(', ')}</strong>
                </div>
              )}
            </div>
            <div className='ta-cleaner' />
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomerBookingsPrintListItem
