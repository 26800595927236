import React from 'react'
import { t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCustomerFieldsCourses = props => {
  return (
    <div className='ta-popup__customer-fields-courses'>
      <div className='ta-popup__text'>{dangerousHTML(t('customerFields.form.section.groups.heading.tooltip'))}</div>
    </div>
  )
}

export default PopupCustomerFieldsCourses
