import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FormSection,
  FormButtons,
  Error,
  Button,
  Form,
  Row,
  Col,
  Input,
  ColorIconSelect,
  MultipleSelect,
  t
} from '../../../Common'
import { PermissionsFormWrapper } from '../../../Beasts'

import './PermissionTypesAdd.css'

const PermissionTypeAdd = props => {
  let {
    cancelLink,
    title,
    readOnlyExternalIds,
    showExternalIds,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type
  } = props
  location = location || []
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={props.onSubmit} name='permissionType'>
            {(showExternalIds &&
              <FormSection>
                <Title
                  size='m'
                  isCompact
                  label={t('permissionTypes.edit.section.externalId.heading')}
                  icon='fingerprint'
                />
                <Row>
                  <Col>
                    <Input
                      name='externalId'
                      disabled={readOnlyExternalIds}
                      label={t('global.externalId.label')}
                      hintText={t('permissionTypes.edit.section.details.externalId.example')}
                    />
                  </Col>
                </Row>
              </FormSection>
            )}

            <FormSection>
              <Title
                size='m'
                isCompact
                label={t('permissionTypes.edit.section.details.heading')}
                icon='sliders-h'
              />
              <Row>
                <Col>
                  <ColorIconSelect name='color' iconFieldName='icon'>
                    <Input
                      name='name'
                      mandatory
                      label={t('global.name.placeholder')}
                      hintText={t('permissionTypes.edit.section.details.name.example')}
                    />
                  </ColorIconSelect>
                </Col>
              </Row>
            </FormSection>

            <FormSection>
              <Title
                size='m'
                isCompact
                label={t('permissionTypes.edit.section.permissions.heading')}
                icon='unlock-alt'
              />
              <PermissionsFormWrapper />
            </FormSection>
            <FormSection>
              <Title size='m' isCompact label={t('global.location')} icon='building' />
              <MultipleSelect
                name='location'
                label={t('global.select.label')}
                searchable
                disableSelected={type === 'edit'}
                position='top'
                hasAll={hasAll}
                allLabel={t('global.allLocations')}
              />
            </FormSection>
            <FormSection>
              <Error name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>
                {t('buttons.cancel.label')}
              </Button>
              <Button type='submit' isPrimary>
                {t('buttons.save.label')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default PermissionTypeAdd
