import React, { useEffect } from 'react'
import moment from 'moment'
import { connect, handlers } from '../../Store'
import { StatisticsExportForm } from '../../Beauties'
import { t } from '../../Common'
import { getOS } from '../../Utils'

const BranchesStatisticsExportFormWrapper = props => {
  let {
    onCloseModal,
    form,
    pending,
    messageExport
  } = props
  form = form || {}
  const { globalErrors, exportMode } = form
  let { errors } = globalErrors || {}
  errors = errors || []
  const { value: exportModeValue } = exportMode || {}
  const areThereErrors = errors.length > 0
  const OS = getOS()

  useEffect(() => {
    handlers.branchStatisticsExportFormGet()
  }, [])

  const onSubmit = cb => {
    handlers.branchStatisticsExportFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const onFilterYearSubmit = year => {
    const currentYear = moment().format('YYYY')
    const currentMonth = moment().format('MM')
    const previousMonth = moment().subtract('1', 'month').format('MM')
    const lastDayOfLastMonth = moment().subtract('1', 'day').format('DD MMM')
    const previousDay = moment().subtract('1', 'day').format('DD')
    const firstDayOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD')
    const isFirstDayOfCurrentMonth = moment().format('YYYY-MM-DD') === firstDayOfCurrentMonth
    const checkingMonth = isFirstDayOfCurrentMonth ? previousMonth : currentMonth
    const checkingDay = isFirstDayOfCurrentMonth ? lastDayOfLastMonth : previousDay
    const months = moment.months().map((item, i) => ({
      label: item,
      value: moment().month(i).format('MM'),
      i: i + 1
    }))
    const { month } = form || {}
    const { value: filtersMonth } = month || {}
    const monthsOptions = months
      .filter(item => item.i <= checkingMonth)
      .map(item => item.value === checkingMonth
        ? {
            label: `${item.label} (${t('statistics.untilDate', [{ key: 'DATE', value: checkingDay }])} ${item.label.substring(0, 3)})`,
            value: item.value,
            i: item.i
          }
        : item
      )
    handlers.formFieldsUpdate('statisticsExport', {
      month: {
        options: year === '2020'
          ? months.filter(item => item.i >= 11)
          : year === currentYear
            ? monthsOptions
            : months,
        value: year === '2020'
          ? '12'
          : year === currentYear
            ? parseFloat(filtersMonth) > parseFloat(currentMonth)
              ? currentMonth
              : filtersMonth
            : filtersMonth
      }
    })
  }

  return (
    <StatisticsExportForm
      onSubmit={onSubmit}
      messageExport={messageExport}
      onCloseModal={onCloseModal}
      areThereErrors={areThereErrors}
      pending={pending}
      exportMode={exportModeValue}
      onFilterYearSubmit={onFilterYearSubmit}
      OS={OS}
      type='branch'
      {...props}
    />
  )
}

const maps = state => ({
  form: state.forms.statisticsExport,
  pending: state.branches.pendingForm,
  messageExport: state.branches.messageExport
})

export default connect(maps)(BranchesStatisticsExportFormWrapper)
