import React from 'react'
import {
  BlueTemplate,
  Loader,
  FontAwesome5,
  Button,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Form,
  Input,
  t
} from '../../Common'
import { AccountsList } from '../../Beauties'

import './AccountSelect.css'

const AccountSelect = props => {
  const {
    accounts,
    onAccountChange,
    onResendEmailClick,
    pending,
    showSearch,
    search
  } = props
  const filteredAccounts = accounts.filter(item =>
    (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
    (item.address && item.address.formatted && item.address.formatted.toLowerCase().includes(search.toLowerCase()))
  )

  return (
    <div className='ta-account-select'>
      <BlueTemplate logoUrl='/'>
        <div className='ta-account-select__box'>
          <Loader active={pending} />
          <div className='ta-account-select__box__title'>
            <FontAwesome5 icon='store-alt' type='solid' />
            {t('accountSelector.heading')}
            <HoverPopup className='ta-account-select__box__logout'>
              <HoverPopupContent position='top' autoSize>
                {t('navigation.logout.tooltip')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button as='link' to='/logout'>
                  <FontAwesome5 icon='sign-out-alt' type='solid' />
                </Button>
              </HoverPopupTrigger>
            </HoverPopup>
          </div>
          <div className='ta-account-select__box__content'>
            {(showSearch &&
              <Form name='accountsSearch'>
                <Input
                  name='search'
                  label={t('accountSelector.searchAccount.label')}
                  clearable
                  autoFocus
                />
              </Form>
            )}
            <AccountsList
              accounts={filteredAccounts}
              onAccountChange={onAccountChange}
              onResendEmailClick={onResendEmailClick}
            />
          </div>
        </div>
      </BlueTemplate>
    </div>
  )
}

export default AccountSelect
