import { handlers, store, store$ } from '../../../Store'
import { map, distinctUntilChanged } from 'rxjs/operators'

// Set overlay from route params
store$
  .pipe(
    map(state => state.router.name),
    distinctUntilChanged()
  ).subscribe(() => {
    const { router = {} } = store.getState()
    const { overlay = {} } = router.props
    if (overlay.position) handlers.overlaySet({ position: overlay.position, fromRoute: true })
  })
