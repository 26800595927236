import { store, handlers, selectors } from '../../../Store'

export const appUninstalled = id => {
  if (!id) return
  const { apps } = store.getState()
  let { list: appsList } = apps || {}
  appsList = appsList || []
  appsList = appsList.map(app => app.id === id ? { ...app, installedVersion: null, isComplete: false } : app)
  handlers.appUninstalledRemove(appsList)
  handlers.navigateToPath('/apps')
}

export const appUpdated = ({ id, ts }) => {
  if (!id) return
  const state = store.getState()
  const app = selectors.appsFindByIdSelector(state, { id }) || {}
  if (ts === app.ts) return
  handlers.appInstallationGet({ id })
}
