import { permissionTypeFormTransform, permissionTypeFilterTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: null,
  pendingList: true,
  pendingForm: true
}

export const ADMIN_PERMISSION_TYPE = {
  id: 'ADMIN',
  name: 'Admin',
  isGlobal: true,
  icon: 'user-shield',
  color: '#cb3b43',
  permissions: {
    accountRead: true,
    calendarReadSet: {
      specificResourceCategoryIds: [],
      specificResourceIds: []
    },
    calendarWrite: true,
    calendarDelete: true,
    statisticsRead: true,
    notificationsRead: true,
    permissionsGroupsRead: true,
    permissionsGroupsWrite: true,
    permissionsGroupsDelete: true,
    servicesAndGroupsRead: true,
    servicesAndGroupsWrite: true,
    servicesAndGroupsDelete: true,
    customersReadSet: {
      specificResourceCategoryIds: [],
      specificResourceIds: []
    },
    customersWrite: true,
    customersDelete: true,
    appsRead: true,
    resourcesReadSet: {
      specificResourceCategoryIds: [],
      specificResourceIds: []
    },
    resourcesWrite: true,
    resourcesDelete: true,
    shiftRead: true,
    shiftWrite: true,
    shiftDelete: true
  }
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  permissionTypesReset: () => dispatch(actions.PERMISSION_TYPES_RESET),

  // One
  permissionTypeDelete: id => dispatch(actions.PERMISSION_TYPE_DELETE, { id }),
  permissionTypeDeleted: id => dispatch(actions.PERMISSION_TYPE_DELETED, id),
  permissionTypeSave: savedPermissionType => dispatch(actions.PERMISSION_TYPE_SAVE, { savedPermissionType }),
  permissionTypeUpdate: savedPermissionType => dispatch(actions.PERMISSION_TYPE_UPDATE, { savedPermissionType }),

  // List
  permissionTypesListGet: () => dispatch(actions.PERMISSION_TYPES_LIST_GET),
  permissionTypesListPopulate: permissionTypesList => dispatch(actions.PERMISSION_TYPES_LIST_POPULATE, { permissionTypesList }),
  permisisonTypesListDefaultPopulate: () => dispatch(actions.PERMISSION_TYPES_LIST_DEFAULT_POPULATE, { permissionTypesList: [ADMIN_PERMISSION_TYPE] }),

  // Form
  permissionTypeFormGet: detail => dispatch(actions.PERMISSION_TYPE_FORM_GET, detail),
  permissionTypeFormPopulate: (permissionType = {}, branches, account) => {
    handlers.formSet('permissionType', permissionTypeFormTransform(permissionType, branches, account))
    handlers.permissionTypeFormReady()
  },
  permissionTypeFormReady: () => dispatch(actions.PERMISSION_TYPE_FORM_READY),
  permissionTypeFormSave: (permissionType, scrollToError = () => { }) =>
    dispatch(actions.PERMISSION_TYPE_FORM_SAVE, { permissionType, scrollToError }),

  // Preview
  permissionTypesLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.PERMISSION_TYPES_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  permissionTypesLocallyChangedResetReady: ({ permissionType, companyId }) => dispatch(actions.PERMISSION_TYPES_LOCALLY_CHANGED_RESET_READY, ({ permissionType, companyId })),

  permissionTypesFilterFormGet: () => dispatch(actions.PERMISSION_TYPES_LIST_FILTER_FORM_GET),
  permissionTypesFilterFormPopulate: ({ permissionTypes, branchesList, translations }) => {
    handlers.formSet('permissionTypesFilter', permissionTypeFilterTransform({ permissionTypes, branchesList, translations }))
  }
})

// One

reducer.PERMISSION_TYPE_DELETE = state => ({
  ...state
})

reducer.PERMISSION_TYPE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(permissionType => permissionType.id !== id)
})

reducer.PERMISSION_TYPE_UPDATE = (state, { savedPermissionType }) => ({
  ...state,
  list: state.list.map(permissionType => permissionType.id === savedPermissionType.id ? savedPermissionType : permissionType)
})

// List

reducer.PERMISSION_TYPES_LIST_POPULATE = (state, { permissionTypesList }) => ({
  ...state,
  list: permissionTypesList,
  pendingList: false
})

reducer.PERMISSION_TYPES_LIST_GET = state => ({
  ...state,
  pendingList: true
})

reducer.PERMISSION_TYPES_LIST_DEFAULT_POPULATE = (state, { permissionTypesList }) => ({
  ...state,
  list: permissionTypesList,
  pendingList: false
})

reducer.PERMISSION_TYPE_SAVE = (state, { savedPermissionType }) => ({
  ...state,
  list: [...state.list, savedPermissionType],
  pendingForm: false
})

reducer.PERMISSION_TYPES_LIST_FILTER_FORM_GET = state => state

// Preview

reducer.PERMISSION_TYPES_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingList: true
})

reducer.PERMISSION_TYPES_LOCALLY_CHANGED_RESET_READY = (state, { permissionType, companyId }) => {
  const { internalId } = permissionType || {}
  if (!internalId) return { ...state, pendingList: false }
  const list = [...state.list]
  const index = list.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  delete list[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    list,
    pendingList: false
  }
}

// Form

reducer.PERMISSION_TYPE_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.PERMISSION_TYPE_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.PERMISSION_TYPE_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Reset

reducer.PERMISSION_TYPES_RESET = state => reducer.initialState

export default reducer
