import { createSelector } from '../../../Utils'

export const billingSelector = state => state.billing

export const billingFieldSelector = createSelector(
  billingSelector,
  (_, props) => props.field,
  (billing, field) => (billing || {})[field]
)

export const billingDataSelector = createSelector(
  billingSelector,
  billing => (billing || {}).data
)
