import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Error,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  FontAwesome5,
  t
} from '../../../../Common'
import { SecuritySettingsBranchManagerEditForm, SecuritySettingsWebappEditForm } from '../../../../Beauties'

import '../SecuritySettings.css'

const SecuritySettingsEditForm = props => {
  const {
    title,
    onSubmit,
    cancelLink,
    hash
  } = props

  const availableTabs = ['branchManager', 'webapp']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  return (
    <div className='ta-settings__form ta-security-settings__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='settingsSecurity'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='branchManager' label={<><FontAwesome5 icon='building' type='solid' />{t('settings.section.security.tab.brachManager')}</>} />
                <SimpleTabsHeaderItem name='webapp' label={<><FontAwesome5 icon='cogs' type='solid' />{t('settings.section.security.tab.webApp')}</>} />
              </SimpleTabsHeader>

              <SimpleTabsBody>
                <SimpleTabsBodyItem name='branchManager'>
                  <SecuritySettingsBranchManagerEditForm {...props} />
                </SimpleTabsBodyItem>

                <SimpleTabsBodyItem name='webapp'>
                  <SecuritySettingsWebappEditForm {...props} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
            <FormSection>
              <Error noOffset name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>
                {t('buttons.cancel.label')}
              </Button>
              <Button type='submit' isPrimary>
                {t('buttons.saveChanges.label')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

SecuritySettingsEditForm.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default SecuritySettingsEditForm
