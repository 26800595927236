import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import { PopupExampleDelete } from '../../../Beauties'

export const PopupExampleDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    if (id) {
      handlers.bookingDelete(id)
      handlers.bookingOptionsSet({})
    }
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupExampleDelete approve={approve} reject={reject} />
  )
}

PopupExampleDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

export default PopupExampleDeleteWrapper
