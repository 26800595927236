import React from 'react'
import moment from 'moment'
import { defaultAvatar } from '../../../Assets/images'
import {
  Row,
  Col,
  SingleSelect,
  Input,
  Textarea,
  Checkbox,
  PhoneInput,
  DateInput,
  ImageInput,
  AddressInput,
  Blur,
  t
} from '../../../Common'

const BranchesCustomerFormField = props => {
  const { field = {}, availableFields = [], plan, timezones = [] } = props
  const classNames = ['ta-branches-customers__form-row']
  if (field.isDefault) {
    if (field.defaultId === 'avatar') classNames.push('avatar')
    if (availableFields.includes('avatar') && field.defaultId !== 'avatar') classNames.push('with-avatar')
    if (
      availableFields.includes('salutation') &&
      availableFields.includes('title') &&
      (field.defaultId === 'salutation' || field.defaultId === 'title')
    ) {
      classNames.push('half')
      if (field.defaultId === 'title') classNames.push('left')
    }
  }
  const name = `customerFields${field.id}`
  const mandatory = field.isMandatoryOffline
  const label = field.label || t(field.translationKey)
  const TYPE_INPUT = ['TEXT', 'EMAIL']
  let type = field.type
  if (field.defaultId === 'avatar') type = 'AVATAR'

  return (
    <Row className={classNames.join(' ')}>
      <Col>
        {(TYPE_INPUT.includes(type) &&
          <Input
            name={name}
            label={label}
            mandatory={mandatory}
          />
        )}
        {(type === 'SELECT' &&
          <SingleSelect
            name={name}
            label={label}
            mandatory={mandatory}
            clearable
          />
        )}
        {(type === 'TIMEZONE' &&
          <SingleSelect
            name={name}
            label={label}
            mandatory={mandatory}
            options={timezones.map(item => ({ value: item.code, label: item.label }))}
            clearable
            searchable
          />
        )}
        {(type === 'TEXTAREA' &&
          <Textarea
            name={name}
            label={label}
            mandatory={mandatory}
            limit={400}
          />
        )}
        {(type === 'CHECKBOX' &&
          <Checkbox name={name} label={field.label} />
        )}
        {(type === 'PHONE' &&
          <PhoneInput
            name={name}
            label={label}
            mandatory={mandatory}
            top
          />
        )}
        {(type === 'DATE' &&
          <DateInput
            name={name}
            label={label}
            icon='calendar'
            timezone='UTC'
            showMonth
            showYear
            mandatory={mandatory}
            maxDate={field.defaultId === 'birthday' ? moment() : null}
            clearable
          />
        )}
        {(type === 'ADDRESS' &&
          <AddressInput
            name={name}
            label={label}
            defaultZoom={1.5}
            defaultBorderRadius={50}
            controls={{ radius: false }}
            validation={{ maxSize: 7 }}
            mandatory={mandatory}
            withMap
            clearable
          />
        )}
        {(type === 'FILE' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            title={t('global.uploadPhotoOrDrop')}
            text={t('global.uploadPhotoOrDrop.text')}
          />
        )}
        {(type === 'AVATAR' && plan !== 'CLASSIC' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            title={t('global.uploadPhotoOrDrop')}
            text={t('global.uploadPhotoOrDrop.text')}
          />
        )}
        {(type === 'AVATAR' && plan === 'CLASSIC' &&
          <Blur
            active
            icon='lock'
            link='/subscriptions'
            activateLabel={t('global.activatePremium')}
            label={t('branches.customer.form.tags.onlyEnterpriseAndPremium')}
          >
            <ImageInput
              preview={defaultAvatar}
              name='avatar_blur'
              defaultZoom={1}
              defaultBorderRadius={100}
              controls={{ radius: false }}
              title={t('global.uploadPhotoOrDrop')}
              text={t('global.uploadPhotoOrDrop.text')}
            />
          </Blur>
        )}
      </Col>
    </Row>
  )
}

export default BranchesCustomerFormField
