import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Button,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Alert,
  Blur,
  t
} from '../../../Common'
import {
  PopupTriggerWrapper,
  CustomerFieldPreviewDetailsWrapper,
  CustomerFieldPreviewChangedLocallyWrapper
} from '../../../Beasts'

import './CustomerFieldPreview.css'

const CustomerFieldPreview = props => {
  const {
    customerField,
    hash,
    message,
    enterpriseId,
    customerFieldsRead,
    customerFieldsDelete,
    customerFieldsWrite,
    allowOnlyGlobalCustomers
  } = props
  const availableTabs = ['details']
  if (!allowOnlyGlobalCustomers) availableTabs.push('changedLocally')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-customer-fields__preview']
  const { id, internalId, isUpdatedLocally, isGloballyDeleted } = customerField
  const isGlobal = enterpriseId && internalId
  const edited = isGlobal && isUpdatedLocally
  if (edited) classNames.push('edited global')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const title = customerFieldsRead ? customerField.label || t(customerField.translationKey) : t('global.accessDenied')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title}>
            {(customerFieldsWrite && customerField.id &&
              <>
                {((customerFieldsDelete && !customerField.isDefault) &&
                  <PopupTriggerWrapper name='customer-fields-delete' size='l' position='bottom' id={customerField.name}>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('customerFields.buttonHeading.deleteTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button isTertiary as='link' className='ta-btn ta-btn-delete' external icon='trash-alt' />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
                {(customerField.defaultId
                  ? (
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('customerFields.buttonHeading.editTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button as='link' to={`/customers/customer-fields/${customerField.id}/edit`} isPrimary icon='pencil' />
                      </HoverPopupTrigger>
                    </HoverPopup>
                    )
                  : (
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('customerFields.buttonHeading.editTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <div className='ta-btn ta-btn-primary ta-btn-icon-only disabled'>
                          <FontAwesome5 icon='pencil' />
                        </div>
                      </HoverPopupTrigger>
                    </HoverPopup>
                    )
                )}
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={customerFieldsRead ? 'inactive' : ''} active={!customerFieldsRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && isGlobal && !isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                <p>{t('globalEntities.preview.locallyEdittedWarning')}</p>
                <PopupTriggerWrapper name='customer-fields-reset-global-entity' position='right' id={{ internalId }}>
                  <Button as='link' isPrimary isBlock className='ta-btn ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(isGloballyDeleted && isGlobal &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                <p>{t('globalEntities.preview.removedFromDatabase')}</p>
                <PopupTriggerWrapper name='customer-fields-delete-global-entity' position='right' id={id}>
                  <Button as='link' isPrimary isBlock className='ta-btn ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(customerField.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!customerFieldsRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <CustomerFieldPreviewDetailsWrapper customerField={customerField} />
                  </SimpleTabsBodyItem>
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsBodyItem name='changedLocally' renderOnlyIfActive>
                      <CustomerFieldPreviewChangedLocallyWrapper customerField={customerField} />
                    </SimpleTabsBodyItem>
                  )}
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

CustomerFieldPreview.propTypes = {
  id: PropTypes.string,
  message: PropTypes.object
}

export default CustomerFieldPreview
