import React, { cloneElement, useEffect, useState } from 'react'
import PropType from 'prop-types'
import './FixedContent.css'

const FixedContent = props => {
  const [offsetTop, setOffsetTop] = useState(0)
  const { children, fixedFormButtons, className, theme } = props
  const classNames = ['ta-fixed-content', 'grey']
  const [headerRef, setHeaderRef] = useState(null)
  let [Header, Body] = Array.isArray(children) ? children : [null, children]
  useEffect(() => {
    setOffsetTop(headerRef?.current?.clientHeight || 0)
  }, [children, headerRef?.current?.clientHeight])
  const renderableChildren = []
  if (Header) renderableChildren.push(cloneElement(Header, { key: 'header', addHeaderRef: setHeaderRef }))
  if (Body) renderableChildren.push(cloneElement(Body, { key: 'body', offsetTop }))
  if (fixedFormButtons) classNames.push('fixed-form-buttons')
  if (className) classNames.push(className)

  return (
    <>
      <div className={classNames.join(' ')} data-testid='fixed-content'>{renderableChildren}</div>
      <div className='ta-fixed-content_before' style={{ top: offsetTop }} />
    </>
  )
}

export default FixedContent
