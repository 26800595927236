import { billingFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: true,
  data: {}
}

reducer.handlers = (dispatch, actions, handlers) => ({
  billingReset: () => dispatch(actions.BILLING_RESET),
  billingPopulate: ({ billingData, subscriptions }) => dispatch(actions.BILLING_POPULATE, { billingData, subscriptions }),
  billingFormGet: () => dispatch(actions.BILLING_FORM_GET),
  billingFormPopulate: data => {
    handlers.billingFormReady()
    handlers.formSet('billing', billingFormTransform(data))
  },
  billingFormReady: () => dispatch(actions.BILLING_FORM_READY),
  billingFormSave: (data, scrollToError = () => {}) => dispatch(actions.BILLING_FORM_SAVE, { data, scrollToError }),
  billingFormDelete: () => dispatch(actions.BILLING_FORM_DELETE)
})

reducer.BILLING_RESET = state => reducer.initialState

reducer.BILLING_POPULATE = (state, { billingData: data, subscriptions }) => ({
  ...state,
  data,
  subscriptions
})

reducer.BILLING_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BILLING_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BILLING_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BILLING_FORM_DELETE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
