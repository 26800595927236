import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  FormText,
  Title,
  Input,
  Row,
  Col,
  Loader,
  FontAwesome5,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupEmailChangeCancel = props => {
  const {
    onSubmit,
    onCancel,
    pending,
    oldPasswordFieldType,
    toggleOldPasswordFieldType
  } = props

  return (
    <div className='ta-popup__email-change-cancel'>
      <Loader active={pending} />
      <Title
        size='m'
        label={dangerousHTML(t('user.preview.tabDetails.buttonCancel.tooltip.title'))}
        icon='trash-alt'
        ignoreTopOffset
      />
      <Form name='account' onSubmit={onSubmit}>
        <FormText>{dangerousHTML(t('user.preview.tabDetails.buttonCancel.tooltip.text'))}</FormText>
        <Row>
          <Col className='relative'>
            <Input
              type={oldPasswordFieldType}
              name='oldPassword'
              label={t('user.edit.password.sectionPassword.fieldOld.label')}
              mandatory
            />
            <div className='ta-popup__email-change-cancel__password-type-toggle-btn' onClick={toggleOldPasswordFieldType}>
              <FontAwesome5 icon={oldPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
            </div>
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Button as='link' isSecondary onClick={onCancel}>{t('global.no')}</Button>
          <Button type='submit' isPrimary>{t('user.preview.tabDetails.buttonCancel.tooltip.buttonCancel')}</Button>
        </div>
      </Form>
    </div>
  )
}

PopupEmailChangeCancel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default PopupEmailChangeCancel
