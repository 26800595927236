import React, { useEffect } from 'react'
import { connect, globalActions } from '../../../Store'
import { CustomersSettings } from '../../../Beauties'
import { Loader } from '../../../Common'

const CustomersSettingsWrapper = props => {
  let {
    isPending,
    settings,
    rawSettings,
    tags,
    areTagsFetched,
    account
  } = props
  areTagsFetched = !!areTagsFetched
  rawSettings = rawSettings || {}
  settings = settings || {}
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { settingsRead, settingsWrite } = enterprisePermissions || {}
  settingsRead = !!isAdmin || !!settingsRead
  settingsWrite = !!isAdmin || !!settingsWrite
  isPending = settingsRead && !!isPending
  useEffect(() => {
    if (settingsRead && !areTagsFetched) globalActions.populateCompanyTags()
  }, [areTagsFetched, settingsRead])
  const { EUGDPRRules } = settings || {}

  return isPending
    ? <Loader active />
    : (
      <CustomersSettings
        EUGDPRRules={EUGDPRRules}
        rawSettings={rawSettings}
        tags={tags}
        settingsRead={settingsRead}
        settingsWrite={settingsWrite}
      />
      )
}

const maps = state => ({
  isPending: state.companyTags.pendingList,
  settings: state.company.settings,
  rawSettings: state.company.rawSettings,
  tags: state.companyTags.list,
  areTagsFetched: state.companyTags.areFetched,
  account: state.account
})

export default connect(maps)(CustomersSettingsWrapper)
