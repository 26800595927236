import React from 'react'
import {
  ListButton,
  FontAwesome5,
  Price,
  HoverPopup,
  HoverPopupContent,
  DottedTimeline,
  DottedTimelineItem,
  HoverPopupTrigger,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  SimpleListItemButtons,
  t,
  Alert
} from '../../../Common'
import {
  convertMinutesToDaysHoursMinutes,
  dangerousHTML,
  truncateText
} from '../../../Utils'

const ServicePreviewServices = props => {
  let { services } = props
  services = services || []
  const translations = {
    minutes: t('global.minutes'),
    hours: t('global.hours'),
    days: t('global.days')
  }

  return (
    <div className='ta-services__preview__services'>
      {services.map((item, index) => {
        let {
          id,
          name,
          hasOnlinePayment,
          duration,
          durationsPattern,
          resourcesCount,
          price,
          offer,
          isStalled,
          hasOnlineWarning,
          locallyUpdatedBranches,
          hasOnlinePaymentWarning,
          isBookable
        } = item || {}
        name = name || ''
        let totalTime
        if (durationsPattern && durationsPattern.length > 1) {
          totalTime = durationsPattern.reduce((sum, duration) => sum + duration, 0)
        }
        const locallyChangedBranches = (
          locallyUpdatedBranches &&
          !!Object.keys(locallyUpdatedBranches || {})
            .filter(item => !Object.keys(locallyUpdatedBranches[item])
              .includes('locallyUpdatedServiceIds')).length
        ) || false

        return (
          <SimpleListItem
            key={id}
            className='ta-services ta-services__preview__service'
            status={(isStalled || hasOnlineWarning || hasOnlinePaymentWarning
              ? 'stalled'
              : isBookable
                ? 'active'
                : 'inactive'
            )}
          >
            <div className='ta-services__preview__service__index'>
              0{index + 1}.
            </div>
            <SimpleListItemTitle title={truncateText(name, 40, true)}>
              {(hasOnlinePayment &&
                <FontAwesome5
                  icon='cc-stripe'
                  type='brand'
                  className='ta-stripe-active'
                />
              )}
              {(offer &&
                <HoverPopup>
                  <HoverPopupContent position={name.length > 35 ? 'left' : 'right'}>
                    <strong>{t('servicesGroups.list.group.warning.tooltip.offer')}:</strong>
                    <div>{offer.name}</div>
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <FontAwesome5 icon='bullhorn' />
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(locallyChangedBranches &&
                <HoverPopup>
                  <HoverPopupContent
                    position='top'
                  >
                    {dangerousHTML(t('tags.item.popup.locallyChanged', [{ key: 'BRANCHES', value: locallyChangedBranches }]))}
                  </HoverPopupContent>
                  <div
                    className='global-label edited'
                    onClick={e => e.stopPropagation()}
                  >
                    <HoverPopupTrigger>
                      <FontAwesome5 icon='pencil' type='s' />
                      {t('global.changedLocally')}
                    </HoverPopupTrigger>
                  </div>
                </HoverPopup>
              )}
            </SimpleListItemTitle>
            <SimpleListItemContent>
              <ul className='ta-list-inline'>
                <li>
                  <FontAwesome5 icon='users' type='solid' /> {resourcesCount} {t('global.resources')}
                </li>
                {(duration &&
                  <li>
                    <FontAwesome5 icon='hourglass-half' type='solid' /> {convertMinutesToDaysHoursMinutes(duration, translations)}
                  </li>
                )}
                {(price > 0 &&
                  <li>
                    <FontAwesome5 icon='money-bill' type='solid' /> <Price price={price} />
                  </li>
                )}
                {(durationsPattern && durationsPattern.length > 1 &&
                  <>
                    <li>
                      <FontAwesome5 icon='hourglass-half' type='solid' /> {convertMinutesToDaysHoursMinutes(totalTime, translations)}
                    </li>
                    <li>
                      <HoverPopup>
                        <HoverPopupContent position='right' autoSize>
                          <DottedTimeline>
                            {t('global.duration')}:
                            {durationsPattern.map((item, durationIndex) => {
                              const label = t(durationIndex % 2 === 0 ? 'global.interval' : 'global.gap')
                              return (
                                <div key={durationIndex}>
                                  <DottedTimelineItem type='big'><strong>{label}</strong></DottedTimelineItem>
                                  <DottedTimelineItem type='small' className={durationIndex === durationsPattern.length - 1 ? 'last' : ''}>
                                    {convertMinutesToDaysHoursMinutes(item, translations)}
                                  </DottedTimelineItem>
                                </div>
                              )
                            })}
                          </DottedTimeline>
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <FontAwesome5 icon='info-circle' type='solid' />
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </li>
                  </>
                )}
              </ul>
            </SimpleListItemContent>
            <SimpleListItemButtons>
              <ListButton icon='angle-right' to={`/management/services/${id}`} />
            </SimpleListItemButtons>
          </SimpleListItem>
        )
      })}
      {(services.length === 0 &&
        <Alert noOffset label={t('servicesGroups.list.service.noResultsBox.empty.text')} theme='no-content' />
      )}
    </div>
  )
}

export default ServicePreviewServices
