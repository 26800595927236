import React from 'react'
import { PopupGroupsImportOptions } from '../../../Beauties'

export const PopupGroupsImportOptionsWrapper = props => {
  // to be refactored if used
  let { id, closePopup } = props
  const { companyId } = id || {}

  return <PopupGroupsImportOptions companyId={companyId} closePopup={closePopup} />
}

export default PopupGroupsImportOptionsWrapper
