import React from 'react'
import { Alert, t } from '../../Common'
import { CompanyTagsListItem } from '..'

const CompanyTagsList = props => {
  const {
    tags,
    isDragAndDropMode,
    enterpriseId
  } = props
  // if tags are global, they should not be listed when ordering /cc andreja
  const tagsList = (enterpriseId && isDragAndDropMode)
    ? tags.filter(item => !item.internalId)
    : tags

  return (
    <div className='ta-company-tags__list'>
      {tagsList.length > 0 && tagsList.map((tag, index) => (
        <CompanyTagsListItem
          key={tag.id}
          tag={tag}
          index={index}
          isDragAndDropMode={isDragAndDropMode}
          enterpriseId={enterpriseId}
        />
      ))}
      {(!tags.length &&
        <Alert noOffset label={t('tags.noResultsBox.empty.text')} theme='no-content' />
      )}
    </div>
  )
}

export default CompanyTagsList
