import React from 'react'
import {
  Checkbox,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  SingleSelect,
  Row,
  Col,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'
import { WeekDays } from '../../../Beauties'

const BranchesResourceFormAllowancePlan = props => {
  const {
    hash,
    hasDifferentTimezone,
    hasDifferentWeeklyAllowancePlans,
    onDifferentWeeklyAllowancePlansChange,
    timezones,
    plan,
    customisations
  } = props
  const availableTabs = ['workingWeeklyAllowancePlan']
  if (hasDifferentWeeklyAllowancePlans) availableTabs.push('bookingWeeklyAllowancePlan')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const { settings } = customisations || {}
  const { hideResourcesFormTimezone } = settings || {}

  return (
    <div className='ta-branches-resources__form__times'>
      {(!hideResourcesFormTimezone &&
        <>
          <Row>
            <Col>
              <HoverPopup className='ta-full-width' disabled={plan === 'ENTERPRISE'}>
                <HoverPopupContent position='top'>
                  {t('global.onlyEnterprise')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Checkbox
                    disabled={plan !== 'ENTERPRISE'}
                    name='hasDifferentTimezone'
                    label={t('branches.form.group.edit.resources.allowancePlan.differentTimezone.label')}
                  />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
          {(hasDifferentTimezone &&
            <Row>
              <Col>
                <SingleSelect
                  name='timezone'
                  label={t('branches.form.group.edit.resources.allowancePlan.resourceTimezone.label')}
                  options={timezones}
                  searchable
                  disabled={plan !== 'ENTERPRISE'}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      <Row>
        <Col>
          <Checkbox
            name='hasDifferentWeeklyAllowancePlans'
            label={t('branches.form.group.edit.resources.allowancePlan.differentPlan.label')}
            onChangeAddon={onDifferentWeeklyAllowancePlansChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SimpleTabs active={activeTab} availableTabs={availableTabs}>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem
                name='workingWeeklyAllowancePlan'
                label={hasDifferentWeeklyAllowancePlans ? t('branches.form.group.edit.resources.allowancePlan.workingPlan.workingTimes') : t('branches.form.group.edit.resources.allowancePlan.workingPlan.workingAndBookingTimes')}
              />
              {(hasDifferentWeeklyAllowancePlans &&
                <SimpleTabsHeaderItem
                  name='bookingWeeklyAllowancePlan'
                  label={t('branches.form.group.edit.resources.allowancePlan.workingPlan.onlineBooking.label')}
                />
              )}
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <SimpleTabsBodyItem name='workingWeeklyAllowancePlan'>
                <WeekDays prefix='workingWeeklyAllowancePlan' />
              </SimpleTabsBodyItem>
              {(hasDifferentWeeklyAllowancePlans &&
                <SimpleTabsBodyItem name='bookingWeeklyAllowancePlan'>
                  <WeekDays prefix='bookingWeeklyAllowancePlan' />
                </SimpleTabsBodyItem>
              )}
            </SimpleTabsBody>
          </SimpleTabs>
        </Col>
      </Row>
    </div>
  )
}

export default BranchesResourceFormAllowancePlan
