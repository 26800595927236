import React from 'react'
import {
  Title,
  FormSection,
  ColorSelect,
  PriceInput,
  Textarea,
  Input,
  Row,
  Col,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'
import {
  BranchesPreviewGroupFormDurationWrapper,
  BranchesPreviewGroupFormSettingsWrapper,
  BranchesPreviewGroupFormParticipantsWrapper
} from '../../../../Beasts'
import {
  BranchesGroupFormDependencies
} from '../../../../Beauties'

import './BranchesGroupForm.css'

const BranchesGroupFormSummary = props => {
  const { showExternalIds, hasStripeConnected, plan, allowedSet } = props

  const externalIdDisabled = !allowedSet?.includes('externalId')
  const isNameDisabled = !allowedSet?.includes('name')
  const isColorDisabled = !allowedSet?.includes('color')
  const isDescriptionDisabled = !allowedSet?.includes('description')
  const isPriceDisabled = !allowedSet?.includes('price')

  return (
    <>
      {(showExternalIds &&
        <FormSection>
          <Title size='m' label={t('global.externalId.label')} icon='fingerprint' ignoreTopOffset />
          <Row>
            <Col>
              <HoverPopup disabled={!externalIdDisabled} className='ta-service-form-inputs__popup'>
                <HoverPopupContent position='top'>
                  {t('globalSettings.form.section.attributes.disabled')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Input
                    name='externalId'
                    disabled={externalIdDisabled}
                    label={t('global.externalId.label')}
                    hintText={t('global.externalId.hint')}
                  />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
        </FormSection>
      )}
      <FormSection>
        <Title size='m' label={t('global.basicDetails')} icon='sliders-h' ignoreTopOffset={!showExternalIds} />
        <Row>
          <Col>
            <HoverPopup disabled={!isNameDisabled} className='ta-service-form-inputs__popup'>
              <HoverPopupContent position='top'>
                {t('globalSettings.form.section.attributes.disabled')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <ColorSelect disabled={isColorDisabled} hide={isColorDisabled} name='color'>
                  <Input
                    disabled={isNameDisabled}
                    name='name'
                    label={t('global.name.label')}
                    hintText={t('servicesGroups.form.group.section.details.fieldName.hint')}
                    mandatory
                  />
                </ColorSelect>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
        <Row>
          <Col>
            <HoverPopup disabled={!isDescriptionDisabled} className='ta-services__form__description-hover-popup'>
              <HoverPopupContent position='top'>
                {isDescriptionDisabled ? t('globalSettings.form.section.attributes.disabled') : t('global.premiumOnly')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Textarea
                  useEditor
                  disabled={!allowedSet?.includes('description')}
                  name='description'
                  label={t('global.description.label')}
                  limit={400}
                />
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      </FormSection>
      <BranchesPreviewGroupFormDurationWrapper allowedSet={allowedSet} plan={plan} />
      <BranchesPreviewGroupFormParticipantsWrapper allowedSet={allowedSet} />
      <BranchesGroupFormDependencies allowedSet={allowedSet} />
      <FormSection>
        <Title size='m' label={t('global.price.label')} icon='money-bill' />
        <HoverPopup disabled={!isPriceDisabled} className='ta-services__form__description-hover-popup'>
          <HoverPopupContent position='top'>
            {isPriceDisabled ? t('globalSettings.form.section.attributes.disabled') : t('global.premiumOnly')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <PriceInput
              disabled={isPriceDisabled}
              name='price'
              label={t('global.price.label')}
              hintText={t('global.price.hint')}
            />
          </HoverPopupTrigger>
        </HoverPopup>
      </FormSection>
      <BranchesPreviewGroupFormSettingsWrapper allowedSet={allowedSet} hasStripeConnected={hasStripeConnected} />
    </>
  )
}

export default BranchesGroupFormSummary
