import React from 'react'
import {
  SimpleListItem,
  SimpleListItemContent,
  FontAwesome5,
  SimpleListItemTitle,
  Badge,
  Avatar,
  t
} from '../../Common'
import { ROLES_COLORS } from '../../Settings'
import { capitalize, highlight } from '../../Utils'

const UsersListItem = props => {
  const { user, userPermissionTypes, search } = props
  const {
    id,
    name,
    phone,
    email,
    roles,
    avatarUrl,
    isDeleted,
    isUpdated,
    isStalled,
    isConfirmed,
    hasOnlineWarning,
    enterprisePermissionsGroupId
  } = user || {}
  const userPermissionTypeAssignedToUser = userPermissionTypes.find(permissionType => permissionType.id === enterprisePermissionsGroupId) || {}
  const { name: permissionTypeName, color: permissionTypeColor } = userPermissionTypeAssignedToUser || {}
  const phoneFormated = phone && '+' + user.phone.prefix + ' (0) ' + user.phone.number
  const status = isConfirmed ? 'active' : 'inactive'
  const classNames = ['ta-users__user']
  if (isDeleted) classNames.push('deleted')
  if (isUpdated) classNames.push('updated')
  if (isStalled) classNames.push('is-stalled')
  if (hasOnlineWarning) classNames.push('is-stalled')

  return (
    <div className={classNames.join(' ')}>
      <SimpleListItem to={`users/${id}/preview`} status={status}>
        <SimpleListItemContent>
          <div className='ta-users__avatar'>
            <Avatar name={name} image={avatarUrl} />
          </div>
          <SimpleListItemTitle title={highlight(name || '-', search)}>
            {enterprisePermissionsGroupId && <Badge text={permissionTypeName} background={permissionTypeColor} />}
            {!enterprisePermissionsGroupId && roles && roles.map((role, index) => <Badge color={role === 'OWNER' ? '#505a5e' : '#fff'} key={index} text={capitalize(role)} background={ROLES_COLORS[role.toString()]} />)}
          </SimpleListItemTitle>
          {((phone || email) &&
            <ul className='ta-list-inline'>
              {(phoneFormated &&
                <li className='ta-list-inline__item'>
                  <FontAwesome5 icon='phone' type='s' />
                  {phoneFormated}
                </li>
              )}
              {(email &&
                <li className='ta-list-inline__item'>
                  <FontAwesome5 icon='envelope' type='s' />
                  {email}
                </li>
              )}
            </ul>
            )}
          <ul className='ta-list-inline'>
            {(isConfirmed
              ? (
                <li className='ta-list-inline__item'>
                  <FontAwesome5 icon='unlock-alt' type='s' />
                  <span className='ta-stripe-active'>{t('global.confirmed')}</span>
                </li>
                )
              : (
                <li className='ta-list-inline__item'>
                  <FontAwesome5 icon='lock' type='s' />
                  <span className='ta-stripe-inactive'>{t('global.notConfirmed')}</span>
                </li>
                )
              )}
          </ul>
        </SimpleListItemContent>
      </SimpleListItem>
    </div>
  )
}

export default UsersListItem
