import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupSecuritySettingsHelpBox = props => {
  return (
    <div className='ta-popup__settings-security-help-box'>
      <Alert noOffset noBorder label={t('settings.security.heading.helpTooltip.title')} theme='alert'>
        {dangerousHTML(t('settings.security.heading.helpTooltip.text'))}
      </Alert>
    </div>
  )
}

export default PopupSecuritySettingsHelpBox
