import React from 'react'
import DefaultTitle from '../Default/DefaultTitle'
import StripedTitle from '../Striped/StripedTitle'
import PropTypes from 'prop-types'

const titleThemes = {
  default: DefaultTitle,
  striped: StripedTitle
}

const Title = ({ theme, ...props }) => {
  const availableThemes = ['default', 'striped', 'small']
  const C = titleThemes[availableThemes.indexOf(theme) > -1 ? theme : availableThemes[0]]
  return <C {...props} />
}

Title.propTypes = {
  theme: PropTypes.string
}

Title.defaultProps = {
  theme: 'default'
}

export default Title
