import React from 'react'
import { FontAwesome5, Alert, SimpleListItem, t, Button } from '../../Common'

const CourseCategoryPreviewChangedLocally = props => {
  let { locallyChangedData, onResetGlobalEntity, globalGroupsWrite } = props
  locallyChangedData = locallyChangedData || []

  return (
    <div className='ta-course-categories__preview--changed-locally'>
      {locallyChangedData.map((item, i) => {
        let { branch, id: courseCategoryId } = item
        branch = branch || {}

        return (
          <SimpleListItem className='ta-course-categories__preview--changed-locally--branch' key={branch.id || i}>
            <div className='ta-course-categories__preview--changed-locally--branch__data'>
              <p className='ta-course-categories__preview--changed-locally--branch__name'>{branch.name || t('global.insufficientPermissions')}</p>
              {(branch.id &&
                <div className='ta-course-categories__preview--changed-locally--item'>
                  <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
                </div>
              )}
              <div className='ta-course-categories__preview--changed-locally--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{courseCategoryId}</p>
              </div>
            </div>
            {(globalGroupsWrite && branch.id &&
              <div className='ta-course-categories__preview--changed-locally--branch__buttons'>
                <Button onClick={() => onResetGlobalEntity(branch.id, branch.region)}>{t('mobileApp.customers.filter.buttonReset.label')}</Button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {(!locallyChangedData.length &&
        <Alert noOffset label={t('tags.noBranches')} theme='no-content' />
      )}
    </div>
  )
}

export default CourseCategoryPreviewChangedLocally
