import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupSettingsHelpBox = props => {
  return (
    <div className='ta-popup__services-help-box'>
      <Alert noOffset noBorder label={t('global.settings')} theme='alert'>
        <div>
          {dangerousHTML(t('popup.settings.help.message'))}
        </div>
      </Alert>
    </div>
  )
}

export default PopupSettingsHelpBox
