import React from 'react'
import {
  StatisticsCompositeHeader,
  StatisticsCompositeEquation,
  StatisticsCompositeSingleNumber,
  StatisticsCompositeTotal
} from '../../Beauties'
import { Spinner } from '../../Common'
import './StatisticsComposite.css'

const StatisticsComposite = props => {
  const {
    isShort,
    data,
    texts,
    isFinancial,
    currency,
    headerSettingsButton,
    filterLabel,
    filterLabelMaxLength,
    isOverallStatistic,
    itemName,
    statisticId,
    itemColor,
    isPending = true,
    tooltip
  } = props
  const {
    addend1,
    addend2,
    equationStart,
    equationMiddle,
    sum
  } = data || {}
  const {
    textAddend1,
    textAddend2,
    textSum,
    title,
    subTitle
  } = texts || {}
  const classNames = ['ta-statistics-composite__wrapper']
  const bodyClassNames = ['ta-statistics-composite__body']
  if (isPending) bodyClassNames.push('pending')
  if (isShort) classNames.push('short')

  return (
    <div className={classNames.join(' ')}>
      <StatisticsCompositeHeader
        title={title}
        subTitle={subTitle}
        headerSettingsButton={headerSettingsButton}
        isOverallStatistic={isOverallStatistic}
        filterLabel={filterLabel}
        filterLabelMaxLength={filterLabelMaxLength}
        itemName={itemName}
        itemColor={itemColor}
        statisticId={statisticId}
      />
      <div className={bodyClassNames.join(' ')}>
        {(!isPending
          ? <>
            <div className='ta-statistics-composite__body-item'>
              {((equationStart && !isShort)
                ? <StatisticsCompositeEquation
                    equation={equationStart}
                    text={textAddend1}
                    isFinancial={isFinancial}
                    currency={currency}
                  />
                : <StatisticsCompositeSingleNumber
                    isShort={isShort}
                    number={addend1}
                    text={textAddend1}
                    isFinancial={isFinancial}
                    currency={currency}
                  />
              )}
            </div>
            <div className='ta-statistics-composite__sign'>+</div>
            <div className='ta-statistics-composite__body-item'>
              {((equationMiddle && !isShort)
                ? <StatisticsCompositeEquation
                    equation={equationMiddle}
                    text={textAddend2}
                    isFinancial={isFinancial}
                    currency={currency}
                  />
                : <StatisticsCompositeSingleNumber
                    isShort={isShort}
                    number={addend2}
                    text={textAddend2}
                    isFinancial={isFinancial}
                    currency={currency}
                  />
              )}
            </div>
            <div className='ta-statistics-composite__sign'>=</div>
            <div className='ta-statistics-composite__body-item'>
              <StatisticsCompositeTotal
                isShort={isShort}
                number={sum}
                text={textSum}
                isFinancial={isFinancial}
                currency={currency}
                tooltip={tooltip}
              />
            </div>
          </>
          : <Spinner active />
        )}
      </div>
    </div>
  )
}

export default StatisticsComposite
