import React, { Component } from 'react'
import { connect, handlers, store } from '../../Store'
import { Sidebars } from '../../Beauties'

class SidebarsWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onClose = this.onClose.bind(this)
    this.state = { ignoreOnClose: false }
  }

  onClose () {
    const { ignoreOnClose } = this.state
    const { router } = this.props
    let { redirectOnClose, defaultRedirect, formDiscard } = router.props
    defaultRedirect = defaultRedirect || '/'
    const state = store.getState() || {}
    const redirectUrl = `${defaultRedirect}${router.hash ? '@@' + router.hash : ''}`
    const formsWithUnsavedChanges = state.forms.formsWithUnsavedChanges || []
    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, redirectUrl)
    }

    // Fix sidebar overlay double click
    if (ignoreOnClose) return null
    this.setState({ ignoreOnClose: true })
    setTimeout(() => this.setState({ ignoreOnClose: false }), 500)

    redirectOnClose
      ? redirectOnClose()
      : handlers.navigateToPath(redirectUrl)
  }

  render () {
    const { sidebar, router } = this.props
    const { name } = router || {}

    return (
      <Sidebars {...sidebar} route={name} onClose={this.onClose} />
    )
  }
}

const maps = state => ({
  sidebar: state.router.props.sidebar || {},
  router: state.router || {}
})

export default connect(maps)(SidebarsWrapper)
