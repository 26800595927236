import React from 'react'
// import PropTypes from 'prop-types'
import {
  SimpleListItem,
  SimpleListItemContent,
  FontAwesome5,
  Button,
  Row,
  Col,
  SimpleListItemTitle,
  t
} from '../../Common'

const UserGroupListItem = props => {
  const { user } = props

  return (
    <Row>
      <SimpleListItem>
        <SimpleListItemContent>
          <Col className='ta-col-big'>
            <SimpleListItemTitle title={user.name} />

            {(user.phone &&
              <ul className='ta-list-inline'>
                <li className='ta-list-inline__item'> <FontAwesome5 icon='user-secret' type='s' /></li>
                <li>{user.phone}</li>
              </ul>
              )}
            <ul className='ta-list-inline'>
              <li className='ta-list-inline__item'> <FontAwesome5 icon='envelope' type='s' /></li>
              {(user.enable && <li className='ta-stripe-active'>{t('global.enable')}</li>) || <li className='ta-stripe-inactive'>{t('global.disable')}</li>}
            </ul>

          </Col>
          <Col className='ta-col-small' size={9}>
            {(user.tag &&
              <>
                {(user.tag &&
                  <div className='ta-user-groups-plan ta-user-groups-box-tag ta-user-groups-box-enterprise'>
                    {user.tag}
                  </div>
                )}
              </>
            )}
          </Col>
          <Col size={3}>
            <Button as='link' isSecondary className='ta-btn ta-user-groups-list-btn' to={`/managers/users/user-groups/${user.id}/edit`}>
              <FontAwesome5 icon='pen' type='s' />
            </Button>
            <Button isSecondary className='ta-btn ta-user-groups-list-btn'><FontAwesome5 icon='trash' type='s' /></Button>
          </Col>
        </SimpleListItemContent>
      </SimpleListItem>
    </Row>
  )
}

UserGroupListItem.propTypes = {

}

export default UserGroupListItem
