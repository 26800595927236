import { invoicesListTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  messageList: null,
  pendingList: true,
  pendingDownload: []
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  invoicesReset: () => dispatch(actions.INVOICES_RESET),
  // Subscription
  invoicesSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.INVOICE_SUBSCRIPTION_SET, { name, id, data, ts }),
  // List
  invoicesListGet: () => dispatch(actions.INVOICES_LIST_GET),
  invoicesListPopulate: invoices => dispatch(actions.INVOICES_LIST_POPULATE, invoicesListTransform(invoices)),
  invoicesListMessageSet: message => dispatch(actions.INVOICES_LIST_MESSAGE_SET, message),
  // One
  invoiceUpdate: invoice => dispatch(actions.INVOICE_UPDATE, invoice),
  invoiceOpen: id => dispatch(actions.INVOICE_OPEN, id),
  invoiceOpenReady: id => dispatch(actions.INVOICE_OPEN_READY, id)
})

// Reset

reducer.INVOICES_RESET = state => reducer.initialState

// Subscription

reducer.INVOICE_SUBSCRIPTION_SET = state => state

// List

reducer.INVOICES_LIST_GET = state => ({
  ...state,
  pendingList: true,
  messageList: null
})

reducer.INVOICES_LIST_POPULATE = (state, invoices) => ({
  ...state,
  pendingList: false,
  list: invoices
})

reducer.INVOICES_LIST_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingList: false,
  messageList: message
})

// One

reducer.INVOICE_UPDATE = (state, invoices) => {
  if (!invoices) return state
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== invoices.id)
      .concat([invoices])
  }
}

reducer.INVOICE_OPEN = (state, id) => ({
  ...state,
  pendingDownload: [ ...state.pendingDownload, id ]
})

reducer.INVOICE_OPEN_READY = (state, id) => ({
  ...state,
  pendingDownload: [...state.pendingDownload].filter(item => item !== id)
})

export default reducer
