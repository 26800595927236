import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import { PopupResourcesAccountUnlinkConfirmation } from '../../../Beauties'

const PopupResourcesAccountUnlinkConfirmationWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    const { id: resourceId, branch } = id
    if (id) handlers.branchResourceFormEmailUnlink({ id: resourceId, branch })
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupResourcesAccountUnlinkConfirmation approve={approve} reject={reject} />
  )
}

PopupResourcesAccountUnlinkConfirmationWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

export default PopupResourcesAccountUnlinkConfirmationWrapper
