import React from 'react'
import PropTypes from 'prop-types'

import './ColorPreview.css'

const ColorPreview = props => {
  const { className, color, pattern } = props
  const classNames = ['ta-color-preview']
  if (pattern) classNames.push(pattern)
  if (className) classNames.push(className)
  const styles = {}
  if (color) styles.backgroundColor = color

  return (
    <div className={classNames.join(' ')} style={styles} />
  )
}

ColorPreview.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  pattern: PropTypes.string
}

export default ColorPreview
