import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../Store'
import { Overlay } from '../../Beauties'

const RouterOverlayWrapper = props => {
  const { isActive } = props
  const onClick = () => {
    handlers.overlayHide('bottom')
    let { router, forms } = store.getState()
    router = router || {}
    forms = forms || {}
    const { hash } = router || {}
    let { redirectOnClose, defaultRedirect, formDiscard } = router.props || {}
    defaultRedirect = defaultRedirect || ''
    const redirectUrl = `${defaultRedirect}/${hash ? '@@' + hash : ''}`
    const formsWithUnsavedChanges = forms.formsWithUnsavedChanges || []
    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, redirectUrl)
    }
    redirectOnClose
      ? redirectOnClose()
      : handlers.navigateToPath(redirectUrl)
  }

  return (
    <Overlay position='bottom' isActive={isActive} onClick={onClick} />
  )
}

RouterOverlayWrapper.propTypes = {
  redirectOnClose: PropTypes.func,
  defaultRedirect: PropTypes.string,
  hash: PropTypes.string
}

const maps = state => ({
  isActive: (state.router.props && state.router.props.sidebar) || false
})

export default connect(maps)(RouterOverlayWrapper)
