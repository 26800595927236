import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Blur,
  t
} from '../../Common'
import { InvoicesList } from '../../Beauties'

import './Invoices.css'

const InvoicesMock = props => {
  const invoices = [{
    id: 1,
    year: 2018,
    items: [{
      id: 1,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200,
      paymentStatus: 'REJECTED',
      plan: 'ENTERPRISE'
    }, {
      id: 2,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200,
      paymentStatus: 'CONFIRMED',
      plan: 'PREMIUM'
    }, {
      id: 3,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200
    }, {
      id: 4,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200,
      paymentStatus: 'CONFIRMED',
      plan: 'ENTERPRISE'
    }, {
      id: 5,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200,
      paymentStatus: 'REJECTED',
      plan: 'ENTERPRISE'
    }, {
      id: 6,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200,
      paymentStatus: 'CONFIRMED'
    }, {
      id: 7,
      emittedDay: '2018-1-24',
      beginDay: '2018-11-24',
      endDay: '2018-12-24',
      invoiceSerialNo: '135154842132',
      invoicedAmount: 200
    }]
  }]
  return (
    <div className='ta-invoices'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-invoices__title'>
            <Title label={t('global.invoices')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur label={t('global.accessDenied')} icon='ban' active>
            <InvoicesList invoices={invoices} />
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default InvoicesMock
