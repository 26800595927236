import React, { Component } from 'react'
import { renderToString } from 'react-dom/server'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, Error, FontAwesome5, t } from '../../../Common'
import { FILE_UPLOAD_URL } from '../../../Settings'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import './FilesInput.css'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
)

class FilesInput extends Component {
  constructor (props, context) {
    super(props)

    this.onAddFile = this.onAddFile.bind(this)
    this.onRemoveFile = this.onRemoveFile.bind(this)
    this.state = { files: [], isFileUploaded: false }
  }

  componentDidMount () {
    const { addRef, name, form, formName } = this.props
    addRef && addRef(this)
    let { rawFiles, errors } = form || {}
    rawFiles = rawFiles || []
    errors = errors || []
    const hasFileError = errors.find(error => error.key === 'files')
    if (rawFiles.length > 0 && !hasFileError) {
      // if user has mistake in form that's not related to files, keep file
      handlers.formFieldsUpdate(formName, { [name]: { ...form, values: [] } })
      this.setState({ files: rawFiles })
    } else {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, values: [], rawFiles: [] } })
      const errors = form.errors || []
      handlers.formErrorsSet(formName, errors)
      this.setState({ files: [] })
    }
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onAddFile ({ key, url }) {
    const { name, form, formName } = this.props
    let { values } = form || {}
    values = values || []
    values.push({ key, url })
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values } })
  }

  onRemoveFile (key) {
    const { name, form, formName } = this.props
    let { values } = form || {}
    values = values || []
    const newValues = values.filter(item => item.key !== key) || []
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues } })
    if (newValues.length === 0) this.setState({ isFileUploaded: false })
  }

  renderLabel () {
    let { label, maxFileSize, acceptedFileTypes, acceptedFileTypesLabel } = this.props
    acceptedFileTypes = acceptedFileTypes || []

    return renderToString(
      <div className='ta-files-input__label'>
        <FontAwesome5 icon='cloud-upload-alt' type='solid' />
        <div className='ta-files-input__drop-or-browse'>{label || t('global.selectOrDragFile')}</div>
        {maxFileSize &&
          <div className='ta-files-input__max-size'>Max. File size: {maxFileSize}</div>
        }
        {acceptedFileTypes.length > 0 &&
          <div className='ta-files-input__types'>
            {acceptedFileTypesLabel
              ? <div className='ta-files-input__type'>{acceptedFileTypesLabel}</div>
              : acceptedFileTypes.map(item => <div className='ta-files-input__type'>{acceptedFileTypes.map(item => item.split('/')[1]).join(', ')}</div>
              )}
          </div>
        }
      </div>
    )
  }

  render () {
    const { files, isFileUploaded } = this.state
    const {
      name,
      allowMultiple,
      maxFiles,
      maxFileSize,
      maxTotalFileSize,
      acceptedFileTypes,
      hideError,
      className,
      token,
      authToken,
      form,
      shouldNotTranslateError
    } = this.props
    const classNames = ['ta-files-input']
    if (className) classNames.push(className)
    let { selected } = form || {}
    selected = selected || []
    if (isFileUploaded) classNames.push('has-value')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        {selected.length === 0 &&
          <FilePond
            ref={ref => { this.pond = ref }}
            name='file'
            files={files}
            allowMultiple={allowMultiple}
            maxFiles={maxFiles || null}
            maxFileSize={maxFileSize || null}
            maxTotalFileSize={maxTotalFileSize || null}
            acceptedFileTypes={acceptedFileTypes || null}
            credits={false}
            labelTapToUndo=''
            labelFileProcessingComplete=''
            server={{
              url: FILE_UPLOAD_URL,
              headers: {
                authorization: `Bearer ${token || authToken}`
              },
              process: {
                onload: response => {
                  response = response ? JSON.parse(response) : null
                  const { url, key } = response || {}
                  this.onAddFile({ key, url })
                  return key
                }
              }
            }}
            onupdatefiles={fileItems => {
              const { name, form, formName } = this.props
              this.setState({ files: fileItems.map(fileItem => fileItem.file) })
              handlers.formFieldsUpdate(formName, { [name]: { ...form, rawFiles: fileItems.map(fileItem => fileItem.file) } })
            }}
            onprocessfilerevert={file => {
              file && this.onRemoveFile(file.serverId)
            }}
            onprocessfile={(error) => !error && this.setState({ isFileUploaded: true })}
            labelIdle={this.renderLabel()}
          />
        }
        {!hideError &&
          <Error name={name} shouldNotTranslate={shouldNotTranslateError} />
        }
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' },
  authToken: state.auth.tokens.accessToken
})

export default feedContextInProps(connect(maps)(FilesInput), FormContext)
