import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../../Common'

import './IconList.css'

const IconListItem = props => {
  const { icon, iconType, color, children, className, noBorder, flex } = props
  const [focused, setFocused] = useState(false)
  const iconListItemRef = useRef()

  const handleClick = (e) => {
    if (iconListItemRef && iconListItemRef.current && iconListItemRef.current.contains(e.target)) {
      setFocused(true)
    }
    if (iconListItemRef && iconListItemRef.current && !iconListItemRef.current.contains(e.target)) {
      setFocused(false)
    }
  }

  useEffect(() => {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mouseover', handleClick)
    } else {
      document.addEventListener('mouseover', handleClick)
    }

    return () => {
      if (!document.removeEventListener && document.detachEvent) {
        document.detachEvent('mouseover', handleClick)
      } else {
        document.removeEventListener('mouseover', handleClick)
      }
    }
  }, [])

  const styles = {}
  if (color) styles.color = color
  if (focused) styles.zIndex = 9990
  const classNames = ['ta-icon-list']
  if (!icon) classNames.push('no-icon')
  if (noBorder) classNames.push('no-border')
  if (className) classNames.push(className)
  if (flex) classNames.push('flex')

  return (
    <div ref={iconListItemRef} className={classNames.join(' ')} style={styles} data-testid='icon-list-item'>
      {icon && <FontAwesome5 className='ta-icon-list__icon' icon={icon} type={iconType} />}
      {children}
    </div>
  )
}

IconListItem.propTypes = {
  icon: PropTypes.string
}

export default IconListItem
