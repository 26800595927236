import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'
import { UserPreview } from '../../Beauties'

class UserWrapper extends Component {
  constructor (props) {
    super(props)

    this.onResendEmailClick = this.onResendEmailClick.bind(this)
    this.onAccountChange = this.onAccountChange.bind(this)
  }

  onResendEmailClick ({ accountId, email }) {
    handlers.accountEmailConfirmationSend({ accountId, email })
  }

  onAccountChange (id) {
    handlers.accountSelect(id)
  }

  render () {
    const { account, pending, hash, message, language } = this.props

    return (
      <div className='ta-account'>
        <Loader active={pending} />
        {(!pending &&
          <UserPreview
            account={account}
            hash={hash}
            message={message}
            language={language}
            onResendEmailClick={this.onResendEmailClick}
            onAccountChange={this.onAccountChange}
          />
        )}
      </div>
    )
  }
}

UserWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  id: PropTypes.string,
  message: PropTypes.object,
  hash: PropTypes.string,
  account: PropTypes.object.isRequired
}

const maps = state => ({
  message: state.account.messagePreview,
  hash: state.router.hash,
  account: state.account,
  language: state.app.locale,
  pending: !!(state.account && state.account.pendingPreview)
})

export default connect(maps)(UserWrapper)
