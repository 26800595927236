import React from 'react'
import { Price } from '../../Common'

const ChartLegendVertical = props => {
  const { payload, isBookingsStat, currency, locale } = props

  return (
    <ul className='ta-chart__vertical-legend'>
      {payload.map((entry, index) => {
        const { payload: entryPayload } = entry || {}
        let description = entryPayload.value
        const { totalBookings } = entryPayload || {}
        if (isBookingsStat) description = `${entryPayload.value} Bookings`
        if (currency) description = <Price currency={currency} price={entryPayload.value} locale={locale} />
        if (currency && totalBookings) {
          description =
            <>
              <Price currency={currency} price={entryPayload.value} locale={locale} /> from {totalBookings} bookings
            </>
        }
        return (
          <li key={index}>
            {entry.color &&
              <div className='ta-chart__vertical-legend__color' style={{ background: entry.color }} />
            }
            <div className='ta-chart__vertical-legend__value'>{entry.value}</div>
            <p className='ta-chart__vertical-legend__color__value--text'>{description}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default ChartLegendVertical
