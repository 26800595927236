import React from 'react'
import PropTypes from 'prop-types'
import {
  InfiniteScroll,
  InfiniteScrollLoader,
  t,
  Alert
} from '../../Common'
import { ActivityLogsFilters, ActivityLogsItem } from '../../Beauties'

const ActivityLogsList = props => {
  const {
    activityLogs,
    loadMore,
    hasMore,
    activityLogsCount,
    hasAdvancedFilter,
    toggleAdvancedOptions,
    pending,
    message
  } = props
  const loader = <InfiniteScrollLoader key='' />

  return (
    <div className='ta-activity-logs__list'>
      <ActivityLogsFilters
        count={activityLogsCount}
        hasAdvancedFilter={hasAdvancedFilter}
        toggleAdvancedOptions={toggleAdvancedOptions}
      />
      {(!pending && !message &&
        <InfiniteScroll
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={loader}
          useWindow={false}
          initialLoad
        >
          {activityLogs.length > 0 && activityLogs.map((activityLog, index) => (
            <ActivityLogsItem key={index} activityLog={activityLog} />
          ))}
          {(!activityLogs.length &&
            <Alert noOffset label={t('activity.noResultsBox.text')} theme='no-content' />
          )}
        </InfiniteScroll>
      )}
    </div>
  )
}

ActivityLogsList.propTypes = {
  activityLogs: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ActivityLogsList
