import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { feedContextInProps } from '../../../Utils'
import { SimpleTableContext } from '../../../Common'

class SimpleTableCell extends Component {
  render () {
    const { children, size, th, thSize } = this.props
    const styles = {}
    if (th && thSize) styles.width = thSize
    if (size) styles.width = size
    const classNames = ['ta-simple-table__cell']
    if (th) classNames.push('th')

    return (
      <div className={classNames.join(' ')} style={styles}>
        {children}
      </div>
    )
  }
}

SimpleTableCell.propTypes = {
  size: PropTypes.string
}

export default feedContextInProps(SimpleTableCell, SimpleTableContext)
