import React from 'react'
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'
import { CHART_DEFAULTS } from '../../Settings'

const ChartLine = props => {
  const { options, data = [] } = props

  return (
    <ResponsiveContainer width='100%' minHeight={options.containerMinHeight || '180px'}>
      <LineChart
        syncId={options.syncId}
        width={options.width || CHART_DEFAULTS.width}
        height={options.height || CHART_DEFAULTS.height}
        margin={options.margin || CHART_DEFAULTS.margin.line}
        data={data}
      >
        <XAxis dataKey={options.labelsKey} allowDecimals={false} />
        <YAxis allowDecimals={false} />
        <CartesianGrid
          strokeDasharray={CHART_DEFAULTS.strokeDasharry}
          horizontal={options.horizontalGrid}
          vertical={options.verticalGrid}
        />
        {options.tooltip &&
        <Tooltip cursor={options.cursor || CHART_DEFAULTS.lineTooltipCursor} />
        }
        {options.lines && options.lines.map((line, index) => (
          <Line
            key={index}
            isAnimationActive={false}
            dot={line.dot || CHART_DEFAULTS.lineDote}
            type='monotone'
            dataKey={line.key}
            stroke={line.stroke}
            strokeWidth={line.strokeWidth}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default ChartLine
