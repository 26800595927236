import { sortByOrderIndex } from '../../../Utils'
import { resourceCategoryFormTransform, resourceCategoryFilterTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  categoriesListToDelete: [],
  pending: true,
  pendingForm: true,
  pendingPreview: true,
  pendingCategoriesToDeleteList: true,
  selected: null,
  dragging: {},
  messagePreview: null
}

// WIP

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  resourcesReset: () => dispatch(actions.RESOURCES_RESET),

  // Subscriptions
  resourcesSubscriptoinsSet: data => dispatch(actions.RESOURCES_SUBSCRIPTION_SET, data),

  // Category
  resourceCategoriesPopulate: ({ categories }) => dispatch(actions.RESOURCE_CATEGORIES_POPULATE, { categories }),
  resourceCategoryGet: id => dispatch(actions.RESOURCE_CATEGORY_GET, id),
  resourceCategoryPopulate: ({ category }) => dispatch(actions.RESOURCE_CATEGORY_POPULATE, { category }),
  resourceCategoryUpdate: category => dispatch(actions.RESOURCE_CATEGORY_UPDATE, category),
  resourceCategoryDelete: id => dispatch(actions.RESOURCE_CATEGORY_DELETE, id),
  resourceCategoryDeleted: id => dispatch(actions.RESOURCE_CATEGORY_DELETED, id),
  resourceCategoryRemoveDeleted: id => dispatch(actions.RESOURCE_CATEGORY_REMOVE_DELETED, id),
  resourceCategoryDeleteFailed: () => dispatch(actions.RESOURCE_CATEGORY_DELETE_FAILED),

  // One category
  resourceCategoriesToDeleteGet: () => dispatch(actions.RESOURCE_CATEGORIES_TO_DELETE_GET),
  resourceCategoriesToDeletePopulate: (resourceCategoriesToDelete) => dispatch(actions.RESOURCE_CATEGORIES_TO_DELETE_POPULATE, resourceCategoriesToDelete),
  resourceCategoryToDeleteDelete: ({ companyId, region, externalId, id }) => dispatch(actions.RESOURCE_CATEGORY_TO_DELETE_DELETE, { companyId, region, externalId, id }),
  resourceCategoriesToDeleteReady: resourceCategory => dispatch(actions.RESOURCE_CATEGORIES_TO_DELETE_READY, resourceCategory),

  // Preview category
  resourceCategoryLocallyChangedGet: (category) => dispatch(actions.RESOURCE_CATEGORY_LOCALLY_CHANGED_GET, category),
  resourceCategoryLocallyChangedPopulate: ({ id, locallyChangedData }) => dispatch(actions.RESOURCE_CATEGORY_LOCALLY_CHANGED_POPULATE, { id, locallyChangedData }),
  resourceCategoryLocallyChangedReset: ({ companyId, region, internalId }) => dispatch(actions.RESOURCE_CATEGORY_LOCALLY_CHANGED_RESET, { companyId, region, internalId }),
  resourceCategoryLocallyChangedResetReady: ({ resourceCategory, companyId }) => dispatch(actions.RESOURCE_CATEGORY_LOCALLY_CHANGED_RESET_READY, { resourceCategory, companyId }),

  // Filter form
  resourceFilterFormGet: ({ categories, branchesList }) => {
    handlers.formSet('resourceCategoriesFilter', resourceCategoryFilterTransform({ categories, branchesList }))
    // handlers.resourceCategoryFormReady()
  },

  // Category form
  resourceCategoryFormGet: id => dispatch(actions.RESOURCE_CATEGORY_FORM_GET, id),
  resourceCategoryFormPopulate: ({ category, branches, account }) => {
    handlers.formSet('resourceCategory', resourceCategoryFormTransform({ category, branches, account }))
    handlers.resourceCategoryFormReady()
  },
  resourceCategoryFormReady: () => dispatch(actions.RESOURCE_CATEGORY_FORM_READY),
  resourceCategoryFormSave: (category, scrollToError = () => { }) => dispatch(actions.RESOURCE_CATEGORY_FORM_SAVE, { category, scrollToError })
})

// Reset
reducer.RESOURCES_RESET = state => reducer.initialState

// Subscription
reducer.RESOURCES_SUBSCRIPTION_SET = state => state

// List

reducer.RESOURCE_CATEGORIES_POPULATE = (state, { categories = [] }) => ({
  ...state,
  categoriesList: categories.sort(sortByOrderIndex),
  pending: false
})

reducer.RESOURCE_CATEGORY_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.RESOURCE_CATEGORY_POPULATE = (state, { category = {} }) => ({
  ...state,
  selected: category
})

// Category

reducer.RESOURCE_CATEGORY_UPDATE = (state, category) => {
  if (!category) return state
  return {
    ...state,
    categoriesList: state.categoriesList
      .filter(item => item.id !== category.id)
      .concat([category])
      .sort(sortByOrderIndex)
  }
}

reducer.RESOURCE_CATEGORY_DELETE = (state, id) => ({
  ...state,
  pendingPreview: true
})

reducer.RESOURCE_CATEGORY_DELETED = (state, id) => {
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return state
  categoriesList[index] = { ...categoriesList[index], isDeleted: true }
  return {
    ...state,
    categoriesList
  }
}

reducer.RESOURCE_CATEGORY_DELETE_FAILED = (state, id) => ({
  ...state,
  pendingPreview: false
})

reducer.RESOURCE_CATEGORY_REMOVE_DELETED = (state, id) => ({
  ...state,
  categoriesList: state.categoriesList.filter(item => item.id !== id),
  pendingPreview: false
})

// Category form

reducer.RESOURCE_CATEGORY_FORM_GET = state => ({
  ...state,
  pendingCategoryForm: true
})

reducer.RESOURCE_CATEGORY_FORM_READY = state => ({
  ...state,
  pendingCategoryForm: false
})

reducer.RESOURCE_CATEGORY_FORM_SAVE = state => ({
  ...state,
  pendingCategoryForm: true
})

// Category List to delete
reducer.RESOURCE_CATEGORIES_TO_DELETE_GET = state => ({
  ...state,
  pendingCategoriesToDeleteList: true
})

reducer.RESOURCE_CATEGORIES_TO_DELETE_POPULATE = (state, resourceCategoriesToDelete) => ({
  ...state,
  categoriesListToDelete: resourceCategoriesToDelete || [],
  pendingCategoriesToDeleteList: false
})

reducer.RESOURCE_CATEGORY_TO_DELETE_DELETE = (state, { id }) => {
  if (!id) return state
  const resourceCategoriesToDelete = state.categoriesListToDelete || []
  const remainingCategoriesToDelete = resourceCategoriesToDelete.filter(item => item.id !== id)
  return {
    ...state,
    categoriesListToDelete: remainingCategoriesToDelete,
    pendingCategoriesToDeleteList: true
  }
}

reducer.RESOURCE_CATEGORIES_TO_DELETE_READY = state => ({
  ...state,
  pendingCategoriesToDeleteList: false
})

// Category preview
reducer.RESOURCE_CATEGORY_LOCALLY_CHANGED_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.RESOURCE_CATEGORY_LOCALLY_CHANGED_POPULATE = (state, { id, locallyChangedData }) => {
  if (!id) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.id === id)
  if (index < 0) return { ...state, pendingPreview: false }
  categoriesList[index] = {
    ...categoriesList[index],
    locallyChangedData
  }
  return {
    ...state,
    categoriesList,
    pendingPreview: false
  }
}

reducer.RESOURCE_CATEGORY_LOCALLY_CHANGED_RESET = state => ({
  ...state,
  pendingPreview: true
})

reducer.RESOURCE_CATEGORY_LOCALLY_CHANGED_RESET_READY = (state, { resourceCategory, companyId }) => {
  const { internalId } = resourceCategory || {}
  if (!internalId) return { ...state, pendingPreview: false }
  const categoriesList = [...state.categoriesList]
  const index = categoriesList.findIndex(item => item.internalId === internalId)
  if (index < 0) return state
  categoriesList[index].locallyChangedData = categoriesList[index].locallyChangedData.filter(item => (item.branch || {}).id !== companyId)
  delete categoriesList[index].locallyUpdatedBranches[companyId]
  return {
    ...state,
    categoriesList,
    pendingPreview: false
  }
}

export default reducer
