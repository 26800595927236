import React from 'react'
import { FontAwesome5 } from '../../Common'

import './StatusBox.css'

const StatusBox = props => {
  const { children, status } = props
  const classNames = ['ta-status-box']
  if (status === 'green') classNames.push('green')
  if (status === 'grey') classNames.push('grey')
  if (status === 'red') classNames.push('red')
  if (status === 'yellow') classNames.push('yellow')

  return (
    <div className={classNames.join(' ')}>
      {status === 'green' &&
        <FontAwesome5 icon='check' type='solid' />
      }
      {status === 'red' &&
        <FontAwesome5 icon='times' type='solid' />
      }
      {children}
    </div>
  )
}

export default StatusBox
