import React from 'react'
import { Form, Checkbox, t } from '../../../Common'

const PopupBranchSignupsSettings = props => {
  return (
    <div className='ta-popup__brach-statistics'>
      <Form name='branchStatisticsSignUpTimes'>
        <Checkbox name='type' label={t('popup.signUps.label')} />
      </Form>
    </div>
  )
}

export default PopupBranchSignupsSettings
