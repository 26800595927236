import React from 'react'
import {
  ServiceFormSettingsWrapper,
  ServiceCombinationFormDurationWrapper,
  ServiceCombinationFormPriceWrapper
} from '../../../Beasts'
import {
  ServiceFormExternalId,
  ServiceFormBasicDetails,
  ServiceCombinationFormServicesSelector
} from '../../../Beauties'
import {
  FormSection,
  Title,
  Row,
  Col,
  MultipleSelect,
  t
} from '../../../Common'

import './ServiceCombinationForm.css'

const ServiceCombinationFormSummary = props => {
  const { showExternalIds, customisations, type } = props

  return (
    <>
      {(showExternalIds &&
        <ServiceFormExternalId />
      )}
      <ServiceFormBasicDetails hideColorSelector ignoreTopOffset={!showExternalIds} />
      <FormSection>
        <ServiceCombinationFormServicesSelector />
        <ServiceCombinationFormDurationWrapper />
      </FormSection>
      <ServiceCombinationFormPriceWrapper />
      <ServiceFormSettingsWrapper customisations={customisations} isCombination />
      <FormSection>
        <Title size='m' label={t('global.location')} icon='building' />
        <Row>
          <Col>
            <MultipleSelect
              name='location'
              label={t('global.select.label')}
              position='top'
              searchable
              disableSelected={type === 'edit'}
              hasAll
              allLabel={t('global.allLocations')}
            />
          </Col>
        </Row>
      </FormSection>
    </>
  )
}

export default ServiceCombinationFormSummary
