import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { TagsForm } from '../../Beauties'

class TagEditWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const { id } = this.props

    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.tagsFormGet(id)
  }

  onSubmit (cb) {
    const { form } = this.props
    handlers.tagsFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const { id, pending, showExternalIds } = this.props

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <TagsForm
            id={id}
            title='Edit tag'
            type='edit'
            cancelLink='/branches/tags'
            onSubmit={this.onSubmit}
            showExternalIds={showExternalIds}
          />
        )}
      </>
    )
  }
}

TagEditWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  form: state.forms.tags || {},
  id: state.router.data.id,
  pending: !!(state.tags && state.tags.pendingForm),
  showExternalIds: state.company.settings.showExternalIds
})

export default connect(maps)(TagEditWrapper)
