import React from 'react'
import { connect, selectors } from '../../../Store'
import { AppsListCategory } from '../../../Beauties'

const AppsListCategoryWrapper = props => {
  let {
    filters,
    category,
    activeTab
  } = props
  filters = filters || {}
  const { search } = filters
  let { value: searchValue } = search || {}
  searchValue = searchValue || ''

  return (
    <AppsListCategory
      category={category}
      search={searchValue}
      activeTab={activeTab}
    />
  )
}

const maps = state => ({
  filters: selectors.formSelector(state, { formName: 'appsFilters' })
})

export default connect(maps)(AppsListCategoryWrapper)
