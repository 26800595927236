import { connect, handlers } from '../../Store'
import { CustomersListFilterAlphabet } from '../../Beauties'
import { useEffect } from 'react'

const CustomersListFilterAlphabetWrapper = props => {
  let { customersFilters, availableLetters, query } = props
  query = query || {}
  availableLetters = availableLetters || []
  customersFilters = customersFilters || {}
  const selected = customersFilters?.letter?.value || ''
  const letters = [...Array(26).keys()].map(key => String.fromCharCode(key + 65))

  useEffect(() => {
    const currentLetter = query.letter || ''
    if (currentLetter) {
      handlers.formSet('customersFilters', {
        ...customersFilters,
        letter: {
          value: currentLetter.toUpperCase()
        }
      })
    }
  }, [])

  const onClick = letter => {
    const newQuery = { ...query }
    if (letter) newQuery.letter = letter.toUpperCase()
    else delete newQuery.letter
    handlers.customersListOptionsChange({ page: 1, pendingList: true })
    handlers.navigateToRoute('customers', null, newQuery)
    handlers.formSet('customersFilters', {
      ...customersFilters,
      letter: {
        value: letter.toUpperCase()
      }
    })
    handlers.customersListGet()
  }

  return (
    <CustomersListFilterAlphabet
      letters={letters}
      selected={selected}
      onClick={onClick}
      activeLetters={availableLetters}
    />
  )
}

const maps = state => ({
  availableLetters: state?.customers?.availableLetters || [],
  customersFilters: state?.forms?.customersFilters,
  query: state?.router?.query
})

export default connect(maps)(CustomersListFilterAlphabetWrapper)
