import React, { useState, useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesFormExport } from '../../Beauties'
import { getOS } from '../../Utils'

const BranchesFormExportWrapper = props => {
  let {
    onCloseModal,
    pending,
    accountSettings,
    form
  } = props
  const [showMessage, setShowMessage] = useState(false)
  const [timeout, setModalTimeout] = useState(null)
  form = form || {}
  pending = !!pending
  accountSettings = accountSettings || {}
  const { globalErrors } = form
  let { errors } = globalErrors || {}
  errors = errors || []
  const areThereErrors = errors.length > 0
  const { executedCompanyBookingsExport: lastBranchesExport } = accountSettings

  const OS = getOS()
  useEffect(() => {
    handlers.branchesExportFormGet()
  }, [])

  const onSubmit = cb => {
    let { form } = props
    form = form || {}
    setShowMessage(true)
    handlers.branchesExportFormSave(form, (errors) => {
      cb && cb(errors)
      setShowMessage(false)
    })
    setModalTimeout(setTimeout(onCloseModal, 4000))
  }

  const onClose = () => {
    clearTimeout(timeout)
    onCloseModal()
  }

  return (
    <BranchesFormExport
      onSubmit={onSubmit}
      showMessage={showMessage}
      onClose={onClose}
      pending={pending}
      areThereErrors={areThereErrors}
      lastBranchesExport={lastBranchesExport}
      OS={OS}
      {...props}
    />
  )
}

const maps = state => ({
  form: state.forms.branchesExport,
  pending: state.branches && state.branches.pendingForm,
  accountSettings: state.account,
  timezone: state.account.timezone || ''
})

export default connect(maps)(BranchesFormExportWrapper)
