import React from 'react'
import { connect } from '../../Store'
import { BranchesServiceFormDuration } from '../../Beauties'

const BranchesPreviewServiceFormDurationWrapper = props => {
  let {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    splitDurationInIntervals,
    plan,
    color,
    allowedSet
  } = props
  showDurationBeforeAndAfter = !!showDurationBeforeAndAfter
  showDurationDaySelector = !!showDurationDaySelector

  return (
    <BranchesServiceFormDuration
      allowedSet={allowedSet}
      durationBefore={durationBefore}
      durationAfter={durationAfter}
      showDurationBeforeAndAfter={showDurationBeforeAndAfter}
      showDurationDaySelector={showDurationDaySelector}
      splitDurationInIntervals={splitDurationInIntervals}
      plan={plan}
      color={color}
    />
  )
}

const maps = state => ({
  durationBefore: (state.forms.branchesService.durationBefore && state.forms.branchesService.durationBefore.value) || '',
  durationAfter: (state.forms.branchesService.durationAfter && state.forms.branchesService.durationAfter.value) || '',
  color: (state.forms.branchesService.color && state.forms.branchesService.color.value) || '',
  showDurationBeforeAndAfter: state.forms.branchesService.showDurationBeforeAndAfter && state.forms.branchesService.showDurationBeforeAndAfter.value,
  splitDurationInIntervals: state.forms.branchesService.splitDurationInIntervals && state.forms.branchesService.splitDurationInIntervals.value,
  showDurationDaySelector: state.account.companySettings && state.account.companySettings.showDurationDaySelector
})

export default connect(maps)(BranchesPreviewServiceFormDurationWrapper)
