import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesGroupCategoriesImport } from '../../Beauties'
import { Loader } from '../../Common'

const BranchesGroupCategoriesImportWrapper = props => {
  useEffect(() => {
    handlers.branchGroupCategoriesImportFormGet()
    return () => {
      handlers.formFieldsUpdate('branchGroupCategoriesImport', {
        files: {
          selected: null,
          values: [],
          rawFiles: [],
          file: null
        }
      })
    }
  }, [])

  const toggleMyPasswordFieldType = () => {
    const { form } = props
    const myPassword = {
      value: form.myPassword.value,
      type: form.myPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('branchGroupCategoriesImport', { myPassword })

    return myPassword.type
  }

  const onSubmit = () => {
    const { form } = props
    handlers.branchGroupCategoriesImportFormSubmit({ form })
  }

  const onClickDownload = (type, isUrl) => {
    handlers.branchesCSVDownload(type, isUrl)
  }

  const { pendingImportForm, form, branchId } = props
  const { files = {}, file = {}, myPassword } = form || {}
  const { type: myPasswordFieldType } = myPassword || {}
  const hasFormErrors = file && file.errors && (file.errors || []).length > 0
  const hasFormValues = files.values && (files.values || []).length > 0

  return pendingImportForm
    ? <Loader active />
    : <BranchesGroupCategoriesImport
        onSubmit={onSubmit}
        cancelLink={`/branches/${branchId}/preview@@groups`}
        enableButton={hasFormValues && !hasFormErrors}
        onClickDownload={onClickDownload}
        myPasswordFieldType={myPasswordFieldType}
        toggleMyPasswordFieldType={toggleMyPasswordFieldType}
      />
}

const maps = state => ({
  pendingImportForm: state.branches.pendingImportForm || false,
  form: state.forms.branchGroupCategoriesImport || {},
  branchId: (state.router && state.router.data && state.router.data.id) || ''
})

export default connect(maps)(BranchesGroupCategoriesImportWrapper)
