import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  DnDWrapper,
  DnDDroppable,
  DnDDraggable,
  Alert,
  t,
  FontAwesome5,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem
} from '../../Common'
import { CoursesTitle, CoursesFilters } from '../../Beauties'
import { CoursesCategoryWrapper, CoursesToDeleteWrapper } from '../../Beasts'
import { dangerousHTML } from '../../Utils'

import './Courses.css'

const Courses = props => {
  const {
    categories,
    hash,
    onDragStart,
    onDragUpdate,
    onDragEnd,
    isDragAndDropMode,
    areExpanded,
    onCategoriesToggle,
    hasFilteredCourses,
    globalGroupsWrite
  } = props
  const availableTabs = ['activeCourses', 'deletedCourses']
  const activeTab = availableTabs.includes(hash) ? hash : 'activeCourses'
  const classNames = ['ta-courses']
  if (isDragAndDropMode && !areExpanded) classNames.push('categories-collapse')

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <CoursesTitle
            isDragAndDropMode={isDragAndDropMode}
            areExpanded={areExpanded}
            onCategoriesToggle={onCategoriesToggle}
            globalGroupsWrite={globalGroupsWrite}
          />
        </FixedContentHeader>
        <FixedContentBody>
          <div className='ta-courses__list'>
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='activeCourses' label={t('course.list.tab.activeCourses')} icon='sliders-h' />
                <SimpleTabsHeaderItem name='deletedCourses' label={t('course.list.tab.forDeletion')} icon='minus-octagon' />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='activeCourses'>
                  <CoursesFilters disabled={isDragAndDropMode} />
                  {(isDragAndDropMode &&
                    <Alert className='ta-courses__reorder-message' theme='alert'>
                      {(!areExpanded &&
                        <div>{dangerousHTML(t('servicesGroups.list.reorder.note.defaultCategory.text'))}</div>
                      )}
                      {t('global.changesSaved')}
                      {(areExpanded &&
                        <div>
                          {dangerousHTML(t('global.reordering.categorySwitch.text', [{
                            key: 'ICON',
                            value: renderToString(<FontAwesome5 icon='compress-alt' />)
                          }]))}
                        </div>
                      )}
                      {(!areExpanded &&
                        <div>
                          {dangerousHTML(t('servicesGroups.list.reorder.note.groupSwitch.text', [{
                            key: 'ICON',
                            value: renderToString(<FontAwesome5 icon='expand-alt' />)
                          }]))}
                        </div>
                      )}
                      {dangerousHTML(t('global.reordering.exit.text', [{
                        key: 'ICON',
                        value: renderToString(<FontAwesome5 icon='times' />)
                      }]))}
                    </Alert>
                  )}
                  <DnDWrapper
                    onDragUpdate={onDragUpdate}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                  >
                    <DnDDroppable id='category' type='category'>
                      {categories.filter(category => !category.isDefault).map((category, index) => (
                        <DnDDraggable
                          className='ta-services__category__dnd-wrapper'
                          key={category.id}
                          type='service'
                          id={category.id}
                          index={index}
                          isDragDisabled={category.isDefault || !isDragAndDropMode || areExpanded}
                        >
                          <CoursesCategoryWrapper
                            key={category.id}
                            category={category}
                            isDragAndDropMode={isDragAndDropMode}
                            index={index}
                            areCategoriesExpanded={areExpanded}
                          />
                        </DnDDraggable>
                      ))}
                    </DnDDroppable>
                    {(!isDragAndDropMode || areExpanded) && categories.filter(category => category.isDefault).map((category, index) => (
                      <CoursesCategoryWrapper
                        key={category.id}
                        category={category}
                        isDragAndDropMode={isDragAndDropMode}
                        areCategoriesExpanded={areExpanded}
                        index={index}
                      />
                    ))}
                    {(hasFilteredCourses &&
                      <Alert noOffset label={t('servicesGroups.list.group.noResultsBox.empty.text')} theme='no-content' />
                    )}
                  </DnDWrapper>
                </SimpleTabsBodyItem>
                <SimpleTabsBodyItem name='deletedCourses' renderOnlyIfActive>
                  <CoursesToDeleteWrapper />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          </div>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Courses
