import React from 'react'
import moment from 'moment'
import {
  Row,
  Col,
  Spinner,
  ChartAxisTickTruncated,
  ChartAxisTickWithCircle,
  Form,
  SingleSelect,
  FontAwesome5,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  OptionsSelect,
  Alert,
  Button,
  t
} from '../../../Common'
import { StatisticsWrapper, StatisticsCompositeWrapper } from '../../../Beasts'
import { truncateText, dangerousHTML } from '../../../Utils'

const BranchesPreviewStatistics = props => {
  const {
    // Filter + branch data
    dailyStatisticsMonth,
    monthlyStatisticsMonth,
    statisticsLastUpdate,
    isPending,
    filtersType,
    filtersYear,
    isFirstDayOfMonth,
    isFilteredMonthCurrent,
    filteredMonthLabel,
    onFilterYearSubmit,
    onFilterMonthSubmit,
    // onFilterTypeChange,
    areStatisticsForSameMonth,
    currency,
    locale,
    isMessageShown,
    setIsMessageShown,
    onExportClick,
    messageExport,
    // Overall statistics
    topBookingServicesOverall,
    totalCourseBookingsOverall,
    totalCourseBookingsWithIdOverall,
    totalCourseBookingsWithTitleOverall,
    totalCourseEventsOverall,
    totalCourseEventsWithIdOverall,
    totalCourseEventsWithTitleOverall,
    totalCourseInStorePaidRevenueOverall,
    totalCourseOnlinePaidRevenueOverall,
    totalCourseRevenueOverall,
    totalCustomersOverall,
    totalInternalCourseBookingsWithIdOverall,
    totalInternalCourseBookingsWithTitleOverall,
    totalInternalCustomersOverall,
    totalInternalServiceEventsWithIdOverall,
    totalInternalServiceEventsWithIdAndCustomerOverall,
    totalOnlineCourseBookingsWithIdOverall,
    totalOnlineCourseBookingsWithTitleOverall,
    totalOnlineCustomersOverall,
    totalOnlineServiceEventsWithIdOverall,
    totalOnlineServiceEventsWithIdAndCustomerOverall,
    totalRevenueOverall,
    totalServiceEventsOverall,
    totalServiceEventsWithCustomerOverall,
    totalServiceEventsWithIdOverall,
    totalServiceEventsWithIdAndCustomerOverall,
    totalServiceEventsWithTitleOverall,
    totalServiceEventsWithTitleAndCustomerOverall,
    totalServiceInStorePaidRevenueOverall,
    totalServiceOnlinePaidRevenueOverall,
    totalServiceRevenueOverall,

    // Normal statistics
    totalServiceEventsWithTitleAndCustomer,
    totalServiceEventsWithCustomer,
    totalOnlineServiceEventsWithIdAndCustomer,
    totalInternalServiceEventsWithIdAndCustomer,
    totalServiceEventsWithIdAndCustomer,
    averageDailyServiceBookings,
    averageWeeklyServiceBookings,
    totalCourseEventsWithTitle,
    totalCourseEventsWithId,
    totalCourseEvents,
    totalOnlineCourseBookingsWithTitle,
    totalInternalCourseBookingsWithTitle,
    totalCourseBookingsWithTitle,
    totalOnlineCourseBookingsWithId,
    totalInternalCourseBookingsWithId,
    totalCourseBookingsWithId,
    totalCourseBookingGuests,
    totalCourseBookingGuestsAndParticipants,
    averageBookingParticipantsPerCourseEvent,
    averageBookingGuestsPerCourseEvent,
    totalCancelledInternalEvents,
    totalCancelledOnlineEvents,
    totalCancelledEvents,
    averageWeeklyCancelledBookings,
    totalRescheduledEventsDaily,
    totalRescheduledEventsMonthly,
    eventSignupsByHoursOfDay,
    eventSignupsByDaysOfWeek,
    eventSignupsByDays,
    eventsByDays,
    topBookingServices,
    totalResourcesDaily,
    totalResourcesMonthly,
    sickDenialsDaysDaily,
    sickDenialsDaysMonthly,
    blockerDenialsDaysDaily,
    blockerDenialsDaysMonthly,
    // will be deployed later
    // vacationDenialsDaysDaily,
    // vacationDenialsDaysMonthly,
    totalResourcesWorkingHours,
    totalResourcesWorkingBookedHours,
    totalResourcesOnlineHours,
    totalResourcesOnlineBookedHours,
    topBookingResourcesValue,
    totalInternalCustomers,
    totalOnlineCustomers,
    totalCustomers,
    totalServiceOnlinePaidRevenue,
    totalServiceInStorePaidRevenue,
    totalServiceRevenue,
    totalCourseOnlinePaidRevenue,
    totalCourseInStorePaidRevenue,
    totalCourseRevenue,
    totalRevenue,
    topBookingServicesWithPrice,
    totalOnlinePaidRevenue,
    totalServiceEvents,
    totalCourseBookings,
    totalServiceEventsWithTitle,
    totalOnlineServiceEventsWithId,
    totalInternalServiceEventsWithId,
    totalServiceEventsWithId,
    totalCourseEventSeats,
    totalCourseEventBookedSeats,
    totalCourseBookingParticipantsDaily,
    totalCourseBookingParticipantsMonthly,
    totalCourseBookingGuestsDaily,
    totalCourseBookingGuestsMonthly,
    onlineMarketingStatistics,
    topCancelledBookingServices,
    cancelledEventsByDay
  } = props
  const statsDescription = areStatisticsForSameMonth
    ? t('statistics.thisMonth')
    : t('statistics.prevMonth')
  const weekdays = moment.weekdays()
  const days = moment.weekdaysShort()
  const dailyStatisticsMonthName = moment(dailyStatisticsMonth, 'MM').format('MMMM')
  const monthlyStatisticsMonthName = moment(monthlyStatisticsMonth, 'MM').format('MMMM')
  const displayLifetimeStatistics = filtersType === 'lifetime'

  return (
    <div className='ta-branch__preview__statistics'>
      {isPending && <Spinner />}
      {(!isPending &&
        <>
          <Row>
            <Col>
              <Form name='branchStatisticsFilter' className='ta-branch-statistics__form'>
                <Row>
                  <Col size={80}>
                    <Row>
                      <Col size={25}>
                        <SingleSelect
                          name='type'
                          label={`${t('global.type.label')}:`}
                        />
                      </Col>
                      {(!displayLifetimeStatistics &&
                        <>
                          <Col size={25}>
                            <SingleSelect
                              name='year'
                              label={`${t('global.year.label')}:`}
                              onChangeAddon={val => onFilterYearSubmit(val)}
                            />
                          </Col>
                          <Col size={25}>
                            <SingleSelect
                              name='month'
                              label={`${t('global.month.label')}:`}
                              onChangeAddon={onFilterMonthSubmit}
                            />
                          </Col>
                        </>
                      )}
                      <Col>
                        <Button
                          as='link'
                          className='ta-btn ta-statistics__filter-submit-btn ta-info'
                          external
                          onClick={() => setIsMessageShown(true)}
                        >
                          <FontAwesome5 icon='info' color='#505a5e' type='s' />
                        </Button>
                      </Col>
                      {(displayLifetimeStatistics &&
                        <>
                          <Col size={25} />
                          <Col size={25} />
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col className='ta-dashboard-export-btn--container' size={20}>
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('statistics.export.tooltip.text')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button
                          as='link'
                          type='submit'
                          className='ta-btn ta-branches-export-btn'
                          onClick={onExportClick}
                        >
                          <FontAwesome5 icon='file-export' type='s' />
                          {t('global.exportData')}
                        </Button>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {(!!messageExport &&
            <Alert theme='success' label={messageExport} />
          )}
          {(isMessageShown &&
            <Alert
              theme='alert'
              // className={`${(!displayLifetimeStatistics && !isFilteredMonthCurrent && !isFirstDayOfMonth) && 'no-margin'} with-border`}
              noIcon
              label={displayLifetimeStatistics
                ? t('statistics.filter.all')
                : isFilteredMonthCurrent
                  ? t('statistics.info.currentMonthToDateHeading')
                  : `${filteredMonthLabel} ${filtersYear}`}
              hasCloseButton
              onCloseCallback={() => setIsMessageShown(false)}
            >
              {(displayLifetimeStatistics &&
                t('statistics.info.lifetimeSummary', [{ key: 'UPDATE', value: statisticsLastUpdate || '0700' }])
              )}
              {((!displayLifetimeStatistics && isFilteredMonthCurrent) &&
                t('statistics.info.currentMonthToDate', [{ key: 'UPDATE', value: statisticsLastUpdate || '0700' }])
              )}
              {((displayLifetimeStatistics || isFilteredMonthCurrent) && isFirstDayOfMonth &&
                <>
                  <br />
                  <br />
                </>
              )}
              {(isFirstDayOfMonth &&
                dangerousHTML(t('statistics.info.firsDayOfMonthMessage'))
              )}
            </Alert>
          )}
          {(displayLifetimeStatistics &&
            <>
              <Row>
                <Col>
                  <div className='ta-branch-preview__statistics--section first'>{t('global.overview')}</div>
                  <p className='ta-branch-preview__statistics--subsection'>{t('statistics.overview.info')}</p>
                </Col>
              </Row>
              <Row>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    headerSettingsButton='branch'
                    filterLabel={t('statistics.allTags')}
                    filterLabelMaxLength={14}
                    statisticId='singleBookings'
                    isOverallStatistic
                    data={{
                      addend1: totalServiceEventsWithTitleOverall,
                      sum: totalServiceEventsOverall,
                      equationMiddle: {
                        addend1: totalOnlineServiceEventsWithIdOverall,
                        addend2: totalInternalServiceEventsWithIdOverall,
                        sum: totalServiceEventsWithIdOverall,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      }
                    }}
                    texts={{
                      title: t('statistics.serviceBookings'),
                      subTitle: t('statistics.services.extraInfo'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.services'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.singleBookings.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    data={{
                      addend1: totalServiceEventsWithTitleAndCustomerOverall,
                      sum: totalServiceEventsWithCustomerOverall,
                      equationMiddle: {
                        addend1: totalOnlineServiceEventsWithIdAndCustomerOverall,
                        addend2: totalInternalServiceEventsWithIdAndCustomerOverall,
                        sum: totalServiceEventsWithIdAndCustomerOverall,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      }
                    }}
                    texts={{
                      title: t('statistics.singleBookingSignUps'),
                      subTitle: t('statistics.singleBookingSignUps.extraInfo'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.services'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.singleBookingSignUps.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    headerSettingsButton='branch'
                    filterLabel={t('statistics.allTags')}
                    filterLabelMaxLength={14}
                    statisticId='groupBookings'
                    isOverallStatistic
                    data={{
                      addend1: totalCourseEventsWithTitleOverall,
                      addend2: totalCourseEventsWithIdOverall,
                      sum: totalCourseEventsOverall
                    }}
                    texts={{
                      title: t('statistics.groupBookings'),
                      subTitle: t('statistics.groups.addedToCalendar'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.groups'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.groups.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    data={{
                      equationStart: {
                        addend1: totalOnlineCourseBookingsWithTitleOverall,
                        addend2: totalInternalCourseBookingsWithTitleOverall,
                        sum: totalCourseBookingsWithTitleOverall,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      },
                      equationMiddle: {
                        addend1: totalOnlineCourseBookingsWithIdOverall,
                        addend2: totalInternalCourseBookingsWithIdOverall,
                        sum: totalCourseBookingsWithIdOverall,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      },
                      sum: totalCourseBookingsOverall
                    }}
                    texts={{
                      title: t('statistics.groupsSignUps'),
                      subTitle: t('statistics.groupsSignUps.extraInfo'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.groups'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.groupsSignUps.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={50}>
                  <StatisticsWrapper
                    statisticType='chart'
                    data={(topBookingServicesOverall || []).map(({ name, value, color }) => ({
                      name: (
                        <HoverPopup>
                          <HoverPopupContent>
                            {name}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            {truncateText(name, 24, true)}
                          </HoverPopupTrigger>
                        </HoverPopup>
                      ),
                      value,
                      color
                    }))}
                    label={t('statistics.mostBooked')}
                    headerLabel={t('statistics.servicesAndGroups')}
                    type='pie'
                    options={{
                      syncId: 'topBookingServicesOverall',
                      containerMinHeight: '234px',
                      tooltip: true,
                      dataKey: 'value',
                      legend: {
                        align: 'right',
                        verticalAlign: 'middle',
                        isBookingsStat: true
                      }
                    }}
                    statisticId='topBookingServicesOverall'
                    bigHeader
                  />
                </Col>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    data={{
                      equationStart: {
                        addend1: totalServiceOnlinePaidRevenueOverall,
                        addend2: totalServiceInStorePaidRevenueOverall,
                        sum: totalServiceRevenueOverall,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.inAdvance'),
                        label1Icon: 'window-maximize',
                        label2Color: '#b26492',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.onSiteSales'),
                        label2Icon: 'store'
                      },
                      equationMiddle: {
                        addend1: totalCourseOnlinePaidRevenueOverall,
                        addend2: totalCourseInStorePaidRevenueOverall,
                        sum: totalCourseRevenueOverall,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.inAdvance'),
                        label1Icon: 'window-maximize',
                        label2Color: '#b26492',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.onSiteSales'),
                        label2Icon: 'store'
                      },
                      sum: totalRevenueOverall
                    }}
                    currency={currency}
                    isFinancial
                    texts={{
                      title: t('statistics.finances'),
                      subTitle: t('statistics.revenue.extraInfo'),
                      textAddend1: t('statistics.singleBookingSignUps'),
                      textAddend2: t('statistics.groupsSignUps'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.revenue.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsCompositeWrapper
                    isShort
                    data={{
                      addend1: totalInternalCustomersOverall,
                      addend2: totalOnlineCustomersOverall,
                      sum: totalCustomersOverall
                    }}
                    texts={{
                      title: t('global.customers'),
                      subTitle: t('statistics.customers.extraInfo'),
                      textAddend1: t('statistics.customers.manual'),
                      textAddend2: t('statistics.customers.widget'),
                      textSum: t('global.total')
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {(!displayLifetimeStatistics &&
            <>
              <Row>
                <Col>
                  <div className='ta-branch-preview__statistics--section first'>{t('statistics.tab.bookings')}</div>
                  <p className='ta-branch-preview__statistics--subsection'>{t('statistics.bookings.info')}</p>
                </Col>
              </Row>
              <Row>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    headerSettingsButton='branch'
                    filterLabel={t('statistics.allTags')}
                    filterLabelMaxLength={14}
                    statisticId='singleBookings'
                    data={{
                      addend1: totalServiceEventsWithTitle,
                      sum: totalServiceEvents,
                      equationMiddle: {
                        addend1: totalOnlineServiceEventsWithId,
                        addend2: totalInternalServiceEventsWithId,
                        sum: totalServiceEventsWithId,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      }
                    }}
                    texts={{
                      title: t('statistics.serviceBookings'),
                      subTitle: t('statistics.services.extraInfo'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.services'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.singleBookings.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    filterLabelMaxLength={14}
                    data={{
                      addend1: totalServiceEventsWithTitleAndCustomer,
                      sum: totalServiceEventsWithCustomer,
                      equationMiddle: {
                        addend1: totalOnlineServiceEventsWithIdAndCustomer,
                        addend2: totalInternalServiceEventsWithIdAndCustomer,
                        sum: totalServiceEventsWithIdAndCustomer,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      }
                    }}
                    texts={{
                      title: t('statistics.singleBookingSignUps'),
                      subTitle: t('statistics.singleBookingSignUps.extraInfo'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.services'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.singleBookingSignUps.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.bookings.avgRegular')}
                    extraHeaderText={t('statistics.perDay')}
                    title={averageDailyServiceBookings}
                    statisticId='avgerageDailyBookings'
                    labels={[
                      {
                        icon: 'calendar-alt',
                        position: 'right',
                        text: t('statistics.addedManuallyInCalendar.tooltip'),
                        color: '#505a5e'
                      },
                      {
                        icon: 'window-maximize',
                        position: 'right',
                        text: t('statistics.bookedViaWidget.tooltip'),
                        color: '#769851'
                      }
                    ]}
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.bookings.avgRegular')}
                    extraHeaderText={t('statistics.perWeek')}
                    infoPopup={{
                      position: 'top',
                      text: t('statistics.bookings.avgRegular.tooltip')
                    }}
                    title={averageWeeklyServiceBookings}
                    statisticId='avgerageWeeklyBookings'
                    labels={[
                      {
                        icon: 'calendar-alt',
                        position: 'top',
                        text: t('statistics.addedManuallyInCalendar.tooltip'),
                        color: '#505a5e'
                      },
                      {
                        icon: 'window-maximize',
                        position: 'top',
                        text: t('statistics.bookedViaWidget.tooltip'),
                        color: '#769851'
                      }
                    ]}
                  />
                </Col>
                <Col size={33} />
              </Row>
              <Row>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    headerSettingsButton='branch'
                    filterLabel={t('statistics.allTags')}
                    filterLabelMaxLength={14}
                    statisticId='groupBookings'
                    data={{
                      addend1: totalCourseEventsWithTitle,
                      addend2: totalCourseEventsWithId,
                      sum: totalCourseEvents
                    }}
                    texts={{
                      title: t('statistics.groupBookings'),
                      subTitle: t('statistics.groups.addedToCalendar'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.groups'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.groups.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
                <Col size={50}>
                  <StatisticsCompositeWrapper
                    filterLabelMaxLength={14}
                    data={{
                      equationStart: {
                        addend1: totalOnlineCourseBookingsWithTitle,
                        addend2: totalInternalCourseBookingsWithTitle,
                        sum: totalCourseBookingsWithTitle,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      },
                      equationMiddle: {
                        addend1: totalOnlineCourseBookingsWithId,
                        addend2: totalInternalCourseBookingsWithId,
                        sum: totalCourseBookingsWithId,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.viaWidget'),
                        label1Icon: 'window-maximize',
                        label2Color: '#505a5e',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.viaCalendar'),
                        label2Icon: 'calendar-alt'
                      },
                      sum: totalCourseBookings
                    }}
                    texts={{
                      title: t('statistics.groupsSignUps'),
                      subTitle: t('statistics.groupsSignUps.extraInfo'),
                      textAddend1: t('global.adhoc'),
                      textAddend2: t('global.groups'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.groupsSignUps.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.groupParticipants')}
                    title={totalCourseBookingParticipantsDaily}
                    statisticId='totalCourseBookingParticipants'
                    secondDescription={statsDescription}
                    secondData={totalCourseBookingParticipantsMonthly}
                    icon={(totalCourseBookingParticipantsDaily !== totalCourseBookingParticipantsMonthly
                      ? totalCourseBookingParticipantsDaily > totalCourseBookingParticipantsMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    iconColor={(totalCourseBookingParticipantsDaily > totalCourseBookingParticipantsMonthly) ? 'green' : 'red'}
                    labels={[
                      {
                        icon: 'calendar-alt',
                        position: 'top',
                        text: t('statistics.addedManuallyInCalendar.tooltip'),
                        color: '#505a5e'
                      },
                      {
                        icon: 'window-maximize',
                        position: 'top',
                        text: t('statistics.bookedViaWidget.tooltip'),
                        color: '#769851'
                      }
                    ]}
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.groupGuests')}
                    title={totalCourseBookingGuestsDaily}
                    statisticId='totalCourseBookingGuests'
                    secondDescription={statsDescription}
                    secondData={totalCourseBookingGuestsMonthly}
                    icon={(totalCourseBookingGuestsDaily !== totalCourseBookingGuestsMonthly
                      ? totalCourseBookingGuestsDaily > totalCourseBookingGuestsMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    iconColor={(totalCourseBookingGuestsDaily > totalCourseBookingGuestsMonthly) ? 'green' : 'red'}
                    labels={[
                      {
                        icon: 'calendar-alt',
                        position: 'left',
                        text: t('statistics.addedManuallyInCalendar.tooltip'),
                        color: '#505a5e'
                      },
                      {
                        icon: 'window-maximize',
                        position: 'left',
                        text: t('statistics.bookedViaWidget.tooltip'),
                        color: '#769851'
                      }
                    ]}
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.groupParticipantsVsGuests')}
                    statisticId='totalFutureCourseEventBookedSeats'
                    calculatePercentage={{
                      total: totalCourseBookingGuestsAndParticipants,
                      value: totalCourseBookingGuests,
                      data1Description: t('global.additionalParticipant'),
                      data2Description: t('global.participants'),
                      availabilityPercentage: true,
                      availabilityText: 'guests',
                      useFirstPercentage: true,
                      useSecondValue: true
                    }}
                    infoPopup={{
                      text: t('statistics.groupParticipantsVsGuests.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.avg.participants')}
                    title={averageBookingParticipantsPerCourseEvent}
                    statisticId='averageBookingParticipantsPerCourseEvent'
                    infoPopup={{
                      position: 'top',
                      text: t('statistics.avgParticipants.tooltip')
                    }}
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.avg.guests')}
                    title={averageBookingGuestsPerCourseEvent}
                    statisticId='averageBookingGuestsPerCourseEvent'
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.groups.capacity')}
                    statisticId='totalFutureCourseEventBookedSeats'
                    calculatePercentage={{
                      total: totalCourseEventSeats,
                      value: totalCourseEventBookedSeats,
                      data1Description: t('statistics.bookedSlots'),
                      data2Description: t('statistics.totalSlots'),
                      availabilityPercentage: true
                    }}
                    infoPopup={{
                      text: t('statistics.groups.capacity.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t(('statistics.peakSignUpTimes'))}
                    headerLabel={t('statistics.bookings.viaWidget')}
                    type='area'
                    extractKey='hour'
                    data={(eventSignupsByHoursOfDay || []).map((item, i) => ({ hour: `${i}:00`, 'sign-ups': item }))}
                    options={{
                      syncId: 'eventSignupsByHoursOfDay',
                      containerMinHeight: '234px',
                      labelsKey: 'hour',
                      tooltip: true,
                      fill: '#385f87',
                      dataKey: 'sign-ups',
                      interval: 3,
                      dot: { stroke: '#385f87', strokeWidth: 2, r: 1 },
                      customTooltip: {
                        className: 'new',
                        labelText: 'between %BEGIN% - %END%',
                        getNextLabel: true,
                        reverseLabelOrder: true,
                        customLabelKey: 'hour'
                      }
                    }}
                    statisticId='eventSignupsByHoursOfDay'
                    labelButton={t('global.all')}
                    bigHeader
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.bookings.viaWidget.tooltip')
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t('statistics.peakSignUpDays')}
                    headerLabel={t('statistics.bookings.viaWidget')}
                    type='bar'
                    extractKey='day'
                    barsKey='Average bookings'
                    data={(eventSignupsByDaysOfWeek || []).map((item, i) => ({ day: days[i], Bookings: item.value ? item.value : item }))}
                    options={{
                      syncId: 'eventSignupsByDaysOfWeek',
                      containerMinHeight: '229px',
                      bars: [{ key: 'Bookings', fill: '#385f87' }],
                      labelsKey: 'day',
                      tooltip: true,
                      customTick: <ChartAxisTickWithCircle />,
                      customTooltip: {
                        className: 'new',
                        textBeforeLabel: 'On ',
                        customLabelKey: 'day',
                        customLabels: weekdays.map(item => ({ label: item }))
                      },
                      verticalGrid: false
                    }}
                    statisticId='eventSignupsByDaysOfWeek'
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.dailySignUpTotals.tooltip')
                    }}
                    labelButton={t('global.all')}
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    settingsButton
                    filterType='branch-sign-ups'
                    label={t('statistics.dailySignUpTotals')}
                    headerLabel={t('statistics.bookings.viaWidget')}
                    type='area'
                    extractKey='date'
                    data={(eventSignupsByDays || []).map((item, i) => ({ date: `${i + 1} ` + `${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}`.toUpperCase().substring(0, 3), formattedDate: 'on ' + moment(`${filtersYear}-${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}-${i + 1}`, 'YYYY-MMMM-DD').format('DD.MM.YYYY'), bookings: item }))}
                    options={{
                      syncId: 'eventSignupsByDays',
                      containerMinHeight: '234px',
                      dataKey: 'bookings',
                      fill: '#385f87',
                      labelsKey: 'date',
                      tooltip: true,
                      interval: 3,
                      dot: { stroke: '#385f87', strokeWidth: 2, r: 1 },
                      secondChart: 'key2',
                      customTooltip: {
                        className: 'new',
                        customLabelKey: 'formattedDate',
                        getLabelFromPayload: true,
                        reverseLabelOrder: true
                      }
                    }}
                    statisticId='eventsByDays'
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.bookings.viaWidget.tooltip')
                    }}
                    interval={5}
                    labelButton={t('global.all')}
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t('statistics.dailyCalendarBookings')}
                    headerLabel={t('statistics.dailyCalendarBookings.extraInfo')}
                    type='area'
                    extractKey='date'
                    data={(eventsByDays || []).map((item, i) => ({ date: `${i + 1} ` + `${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}`.toUpperCase().substring(0, 3), formattedDate: 'on ' + moment(`${filtersYear}-${areStatisticsForSameMonth ? monthlyStatisticsMonthName : dailyStatisticsMonthName}-${i + 1}`, 'YYYY-MMMM-DD').format('DD.MM.YYYY'), bookings: item }))}
                    options={{
                      syncId: 'eventsByDays',
                      containerMinHeight: '234px',
                      dataKey: 'bookings',
                      fill: '#385f87',
                      labelsKey: 'date',
                      tooltip: true,
                      interval: 4,
                      dot: { stroke: '#385f87', strokeWidth: 2, r: 1 },
                      secondChart: 'key2',
                      customTooltip: {
                        className: 'new',
                        customLabelKey: 'formattedDate',
                        getLabelFromPayload: true,
                        reverseLabelOrder: true
                      }
                    }}
                    statisticId='eventsByDays'
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.dailyCalendarBookings.tooltip')
                    }}
                    interval={5}
                    labelButton={t('global.all')}
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t('statistics.marketing')}
                    type='composed'
                    data={(onlineMarketingStatistics || []).map((item) => {
                      const conversions = Object.values(item)[0] || 0
                      const activity = Object.keys(item)[0] || '-'
                      return { activity, Conversions: conversions }
                    })}
                    extractKey='activity'
                    barsKey='Conversions'
                    options={{
                      syncId: 'onlineMarketingUtmStatistics',
                      containerMinHeight: '234px',
                      bars: [{ key: 'Conversions', fill: '#385f87' }],
                      labelsKey: 'activity',
                      tooltip: true,
                      margin: {
                        top: 20,
                        right: 10,
                        left: 65,
                        bottom: 0
                      },
                      interval: 100,
                      horizontalGrid: false,
                      customTooltip: { className: 'new' },
                      customTick: <ChartAxisTickTruncated />
                    }}
                    statisticId='onlineMarketingUtmStatistics'
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.bookings.marketing.tooltip')
                    }}
                    buttons={(onlineMarketingStatistics !== null &&
                      <Form name='branchMarketingConversions'>
                        <OptionsSelect name='type' />
                      </Form>
                    )}
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t('statistics.mostBooked')}
                    headerLabel={t('statistics.servicesAndGroups')}
                    type='composed'
                    data={(topBookingServices || []).map((item) => ({ name: item.name, Bookings: item.value }))}
                    extractKey='name'
                    barsKey='Bookings'
                    options={{
                      syncId: 'topBookingResources',
                      containerMinHeight: '234px',
                      bars: [{ key: 'Bookings', fill: '#385f87' }],
                      labelsKey: 'name',
                      tooltip: true,
                      margin: {
                        top: 20,
                        right: 10,
                        left: 65,
                        bottom: 0
                      },
                      horizontalGrid: false,
                      interval: 0,
                      customTooltip: { className: 'new' },
                      customTick: <ChartAxisTickTruncated />
                    }}
                    labels={[
                      {
                        icon: 'calendar-alt',
                        position: 'top',
                        text: t('statistics.addedManuallyInCalendar.tooltip'),
                        color: '#505a5e'
                      },
                      {
                        icon: 'window-maximize',
                        position: 'top',
                        text: t('statistics.bookedViaWidget.tooltip'),
                        color: '#769851'
                      }
                    ]}
                    statisticId='topBookingServices'
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='ta-branch-preview__statistics--section'>Cancellations</div>
                  <p className='ta-branch-preview__statistics--subsection'>Track what booking and groups that were meant to take place got cancelled, or re-scheduled</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsCompositeWrapper
                    isShort
                    data={{
                      addend1: totalCancelledOnlineEvents,
                      addend2: totalCancelledInternalEvents,
                      sum: totalCancelledEvents
                    }}
                    texts={{
                      title: t('global.cancellations'),
                      subTitle: t('statistics.singleBookings.viaWidget'),
                      textAddend1: t('statistics.byCustomer'),
                      textAddend2: t('statistics.byStaff'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.cancellations.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col size={50}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.cancellations.avg')}
                    headerLabel={t('statistics.singleBookings.viaWidget')}
                    extraHeaderText={t('statistics.perWeek')}
                    title={averageWeeklyCancelledBookings}
                    statisticId='averageWeeklyCancelledBookings'
                  />
                </Col>
                <Col size={50}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.rescheduled')}
                    headerLabel={t('statistics.singleBookings.viaWidget')}
                    title={totalRescheduledEventsDaily}
                    statisticId='totalRescheduledEvents'
                    secondDescription={statsDescription}
                    secondData={totalRescheduledEventsMonthly}
                    icon={(totalRescheduledEventsDaily !== totalRescheduledEventsMonthly
                      ? totalRescheduledEventsDaily > totalRescheduledEventsMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    iconColor={(totalRescheduledEventsDaily > totalRescheduledEventsMonthly) ? 'red' : 'green'}
                    infoPopup={{ position: 'left', text: t('statistics.rescheduled.tooltip') }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    data={(topCancelledBookingServices || []).map(({ name, value, color }) => ({
                      name: (
                        <HoverPopup>
                          <HoverPopupContent>
                            {name}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            {truncateText(name, 50, true)}
                          </HoverPopupTrigger>
                        </HoverPopup>
                      ),
                      value,
                      color
                    }))}
                    label={t('global.cancellations')}
                    headerLabel={t('statistics.servicesAndGroups')}
                    type='pie'
                    options={{
                      syncId: 'topCancelledBookingServices',
                      containerMinHeight: '234px',
                      tooltip: true,
                      dataKey: 'value',
                      nameKey: 'name',
                      legend: {
                        align: 'right',
                        verticalAlign: 'middle',
                        isBookingsStat: true
                      }
                    }}
                    infoPopup={{ position: 'left', text: t('statistics.cancellations.tooltip') }}
                    statisticId='topCancelledBookingServices'
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t('global.cancellations')}
                    extraHeaderText={t('statistics.perDay')}
                    headerLabel={t('statistics.eventsCombined')}
                    type='area'
                    extractKey='hour'
                    data={(cancelledEventsByDay || []).map((item, i) => ({ date: `${i + 1} ` + `${areStatisticsForSameMonth ? dailyStatisticsMonthName : monthlyStatisticsMonthName}`.toUpperCase().substring(0, 3), formattedDate: 'on ' + moment(`${filtersYear}-${dailyStatisticsMonthName}-${i + 1}`, 'YYYY-MMMM-DD').format('DD.MM.YYYY'), cancellations: item }))}
                    options={{
                      syncId: 'cancelledEventsByDay',
                      containerMinHeight: '234px',
                      labelsKey: 'date',
                      tooltip: true,
                      fill: '#cb3b43',
                      dataKey: 'cancellations',
                      interval: 4,
                      dotColor: '#cb3b43',
                      stroke: '#cb3b43',
                      customTooltip: {
                        className: 'new',
                        customLabelKey: 'formattedDate',
                        getLabelFromPayload: true,
                        reverseLabelOrder: true
                      }
                    }}
                    statisticId='cancelledEventsByDay'
                    labelButton={t('global.all')}
                    infoPopup={{
                      position: 'left',
                      text: t('statistics.cancellations.perDay.tooltip')
                    }}
                    bigHeader
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className='ta-branch-preview__statistics--section'>{t('statistics.finances')}</div>
                  <p className='ta-branch-preview__statistics--subsection'>{t('statistics.revenue.info')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsCompositeWrapper
                    data={{
                      equationStart: {
                        addend1: totalServiceOnlinePaidRevenue,
                        addend2: totalServiceInStorePaidRevenue,
                        sum: totalServiceRevenue,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.inAdvance'),
                        label1Icon: 'window-maximize',
                        label2Color: '#b26492',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.onSiteSales'),
                        label2Icon: 'store'
                      },
                      equationMiddle: {
                        addend1: totalCourseOnlinePaidRevenue,
                        addend2: totalCourseInStorePaidRevenue,
                        sum: totalCourseRevenue,
                        label1Color: '#769851',
                        label1TextColor: '#769851',
                        label1Text: t('statistics.inAdvance'),
                        label1Icon: 'window-maximize',
                        label2Color: '#b26492',
                        label2TextColor: '#505a5e',
                        label2Text: t('statistics.onSiteSales'),
                        label2Icon: 'store'
                      },
                      sum: totalRevenue
                    }}
                    currency={currency}
                    isFinancial
                    texts={{
                      title: t('statistics.finances'),
                      subTitle: t('statistics.revenue.extraInfo'),
                      textAddend1: t('statistics.singleBookingSignUps'),
                      textAddend2: t('statistics.groupsSignUps'),
                      textSum: t('global.total')
                    }}
                    tooltip={{
                      text: t('statistics.revenue.tooltip'),
                      position: 'left'
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    data={(topBookingServicesWithPrice || []).map(({ name, value, color, totalBookings }) => ({
                      name: (
                        <HoverPopup>
                          <HoverPopupContent>
                            {name}
                          </HoverPopupContent>
                          <HoverPopupTrigger>
                            {truncateText(name, 24, true)}
                          </HoverPopupTrigger>
                        </HoverPopup>
                      ),
                      value,
                      color,
                      totalBookings
                    }))}
                    label={t(('statistics.servicesWithPrice'))}
                    headerLabel={t('statistics.paidInAdvance')}
                    type='pie'
                    options={{
                      syncId: 'topBookingServicesWithPrice',
                      containerMinHeight: '234px',
                      tooltip: true,
                      dataKey: 'value',
                      currency,
                      locale,
                      legend: {
                        align: 'right',
                        verticalAlign: 'middle',
                        currency
                      }
                    }}
                    statisticId='topBookingServicesWithPrice'
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.paidInAdvanceVsOnSite')}
                    statisticId='totalRevenue totalOnlinePaidRevenue'
                    infoPopup={{ position: 'top', text: t('statistics.finance.comparePaid.tooltip') }}
                    calculatePercentage={{
                      total: totalRevenue,
                      value: totalOnlinePaidRevenue,
                      useSecondValue: true,
                      data1Description: t('statistics.paidInAdvance'),
                      data2Description: t('statistics.paidInStore')
                    }}
                  />
                </Col>
                <Col size={33} />
                <Col size={33} />
              </Row>
              <Row>
                <Col>
                  <div className='ta-branch-preview__statistics--section'>{t('statistics.resourceActivity')}</div>
                  <p className='ta-branch-preview__statistics--subsection'>{t('statistics.resourceActivity.hintText')}</p>
                </Col>
              </Row>
              <Row>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.totalResources')}
                    title={totalResourcesDaily}
                    statisticId='totalResources'
                    isResourceStatistic
                    secondDescription={statsDescription}
                    secondData={totalResourcesMonthly}
                    icon={(totalResourcesDaily !== totalResourcesMonthly
                      ? totalResourcesDaily > totalResourcesMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    iconColor={(totalResourcesDaily > totalResourcesMonthly) ? 'green' : 'red'}
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.sickDays')}
                    title={sickDenialsDaysDaily}
                    statisticId='sickDenialsStatistics'
                    isResourceStatistic
                    headerSettingsButton='branch'
                    secondDescription={statsDescription}
                    secondData={sickDenialsDaysMonthly}
                    icon={(sickDenialsDaysDaily !== sickDenialsDaysMonthly
                      ? sickDenialsDaysDaily > sickDenialsDaysMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    filterLabel={t('global.allResources')}
                    filterLabelMaxLength={20}
                    infoPopup={{ position: 'top', text: t('statistics.resources.sickDays.tooltip') }}
                    iconColor={(sickDenialsDaysDaily > sickDenialsDaysMonthly) ? 'red' : 'green'}
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.blockedDays')}
                    title={blockerDenialsDaysDaily}
                    statisticId='blockerDenialsStatistics'
                    isResourceStatistic
                    infoPopup={{ position: 'left', text: t('statistics.resources.blockedDays.tooltip') }}
                    headerSettingsButton='branch'
                    secondDescription={statsDescription}
                    secondData={blockerDenialsDaysMonthly}
                    icon={(blockerDenialsDaysDaily !== blockerDenialsDaysMonthly
                      ? blockerDenialsDaysDaily > blockerDenialsDaysMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    )}
                    filterLabel={t('global.allResources')}
                    filterLabelMaxLength={20}
                    iconColor={(blockerDenialsDaysDaily > blockerDenialsDaysMonthly) ? 'red' : 'green'}
                  />
                </Col>
              </Row>
              <Row>
                {/* Will be deployed later */}
                {/* <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText='Holidays'
                    title={vacationDenialsDaysDaily}
                    statisticId='vacationDenialsStatistics'
                    isResourceStatistic
                    headerSettingsButton='branch'
                    secondDescription={statsDescription}
                    secondData={vacationDenialsDaysMonthly}
                    icon={vacationDenialsDaysDaily !== vacationDenialsDaysMonthly
                      ? vacationDenialsDaysDaily > vacationDenialsDaysMonthly
                        ? 'arrow-up'
                        : 'arrow-down'
                      : null
                    }
                    filterLabel='All resources'
                    filterLabelMaxLength={20}
                    iconColor={(vacationDenialsDaysDaily > vacationDenialsDaysMonthly) ? 'red' : 'green'}
                  />
                </Col> */}
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.resourceCapacityWorkingHrs')}
                    statisticId='totalResourcesWorkingTimes totalResourcesWorkingBookedTimes'
                    isResourceStatistic
                    headerSettingsButton='branch'
                    calculatePercentage={{
                      total: totalResourcesWorkingHours,
                      value: totalResourcesWorkingBookedHours,
                      data1Description: t('statistics.bookedHrs'),
                      data2Description: t('statistics.resourceWorkingHrs')
                    }}
                    infoPopup={{ position: 'top', text: t('statistics.workingHoursCapacity.tooltip') }}
                    filterLabel={t('global.allResources')}
                    filterLabelMaxLength={20}
                    showAvailableCapacity
                  />
                </Col>
                <Col size={33}>
                  <StatisticsWrapper
                    statisticType='box'
                    extraClassName='ta-simple-box-fixed-dimensions'
                    headerText={t('statistics.resourceCapacityBookingHrs')}
                    statisticId='totalResourcesOnlineTimes totalResourcesOnlineBookedTimes'
                    isResourceStatistic
                    headerSettingsButton='branch'
                    calculatePercentage={{
                      total: totalResourcesOnlineHours,
                      value: totalResourcesOnlineBookedHours,
                      data1Description: t('statistics.bookedHrs'),
                      data2Description: t('statistics.resourceWorkingHrs')
                    }}
                    infoPopup={{ position: 'top', text: t('statistics.resources.bookingHoursCapacity.tooltip') }}
                    filterLabel={t('global.allResources')}
                    filterLabelMaxLength={20}
                    showAvailableCapacity
                  />
                </Col>
                <Col size={33} />
              </Row>
              <Row>
                <Col>
                  <StatisticsWrapper
                    statisticType='chart'
                    label={t('statistics.mostBookedResource')}
                    type='composed'
                    data={(topBookingResourcesValue || []).map((item) => ({ name: item.name, Bookings: item.value }))}
                    extractKey='name'
                    barsKey='Bookings'
                    options={{
                      syncId: 'topBookingResources',
                      containerMinHeight: '234px',
                      bars: [{ key: 'Bookings', fill: '#385f87' }],
                      labelsKey: 'name',
                      tooltip: true,
                      margin: {
                        top: 20,
                        right: 10,
                        left: 65,
                        bottom: 0
                      },
                      horizontalGrid: false,
                      interval: 0,
                      customTooltip: { className: 'new' },
                      customTick: <ChartAxisTickTruncated />
                    }}
                    buttons={
                      <Form name='statisticsMostBookedResourcesType'>
                        <OptionsSelect name='type' />
                      </Form>
                    }
                    statisticId='topBookingResources'
                    bigHeader
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='ta-branch-preview__statistics--section'>{t('global.customers')}</div>
                  <p className='ta-branch-preview__statistics--subsection'>{t('statistics.customers.hintText')}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StatisticsCompositeWrapper
                    isShort
                    data={{
                      addend1: totalInternalCustomers,
                      addend2: totalOnlineCustomers,
                      sum: totalCustomers
                    }}
                    texts={{
                      title: t('global.customers'),
                      subTitle: t('statistics.customers.newNumber'),
                      textAddend1: t('statistics.customers.manual'),
                      textAddend2: t('statistics.customers.widget'),
                      textSum: t('global.total')
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default BranchesPreviewStatistics
