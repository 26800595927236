import { translateServerCode } from '../../../Utils'
// List
export const invoicesListTransform = (invoices) => {
  return invoices
}

export const invoicesListErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}
