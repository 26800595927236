import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import { PopupCustomerFieldCategoryDelete } from '../../../Beauties'

const PopupCustomerFieldCategoryDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    if (id) handlers.customerFieldCategoryDelete(id)
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupCustomerFieldCategoryDelete approve={approve} reject={reject} />
  )
}

PopupCustomerFieldCategoryDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

export default PopupCustomerFieldCategoryDeleteWrapper
