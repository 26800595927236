import React from 'react'
import { connect } from '../../../Store'
import { SettingsIcsFileCustomisation } from '../../../Beauties'
import { t } from '../../../Common'

const SettingsIcsFileCustomisationWrapper = props => {
  let {
    customerFields,
    hasCustomerIcsFileCustomisation,
    hasResourceIcsFileCustomisation,
    icsAddressTypeCustomer,
    useCustomDataFieldsNoteCustomer,
    useCustomNoteCustomer,
    icsAddressTypeResource,
    icsSubjectCustomer,
    customerNameNoteResource,
    useCustomDataFieldsNoteResource,
    useCustomNoteResource
  } = props

  customerFields = customerFields.filter(({ type }) => !['FILE', 'FILES'].includes(type)).map(({ id, defaultId, label, translationKey }) => ({
    value: id,
    label: translationKey ? t(translationKey) : label || defaultId
  }))

  return (
    <SettingsIcsFileCustomisation
      customerFields={customerFields}
      icsSubjectCustomer={icsSubjectCustomer}
      customerNameNoteResource={customerNameNoteResource}
      useCustomNoteCustomer={useCustomNoteCustomer}
      icsAddressTypeCustomer={icsAddressTypeCustomer}
      useCustomDataFieldsNoteCustomer={useCustomDataFieldsNoteCustomer}
      useCustomDataFieldsNoteResource={useCustomDataFieldsNoteResource}
      icsAddressTypeResource={icsAddressTypeResource}
      useCustomNoteResource={useCustomNoteResource}
      hasCustomerIcsFileCustomisation={hasCustomerIcsFileCustomisation}
      hasResourceIcsFileCustomisation={hasResourceIcsFileCustomisation}
    />
  )
}

const maps = state => ({
  hasCustomerIcsFileCustomisation: state.forms.bookingSettings.hasCustomerIcsFileCustomisation && state.forms.bookingSettings.hasCustomerIcsFileCustomisation.value,
  hasResourceIcsFileCustomisation: state.forms.bookingSettings.hasResourceIcsFileCustomisation && state.forms.bookingSettings.hasResourceIcsFileCustomisation.value,
  icsAddressTypeCustomer: state.forms.bookingSettings.icsAddressTypeCustomer && state.forms.bookingSettings.icsAddressTypeCustomer.value,
  useCustomDataFieldsNoteCustomer: state.forms.bookingSettings.useCustomDataFieldsNoteCustomer && state.forms.bookingSettings.useCustomDataFieldsNoteCustomer.value,
  useCustomNoteCustomer: state.forms.bookingSettings.useCustomNoteCustomer && state.forms.bookingSettings.useCustomNoteCustomer.value,
  icsAddressTypeResource: state.forms.bookingSettings.icsAddressTypeResource && state.forms.bookingSettings.icsAddressTypeResource.value,
  icsSubjectCustomer: state.forms.bookingSettings.icsSubjectCustomer && state.forms.bookingSettings.icsSubjectCustomer.value,
  customerNameNoteResource: state.forms.bookingSettings.customerNameNoteResource && state.forms.bookingSettings.customerNameNoteResource.value,
  useCustomDataFieldsNoteResource: state.forms.bookingSettings.useCustomDataFieldsNoteResource && state.forms.bookingSettings.useCustomDataFieldsNoteResource.value,
  useCustomNoteResource: state.forms.bookingSettings.useCustomNoteResource && state.forms.bookingSettings.useCustomNoteResource.value,
  customerFields: state.customerFields.list
})

export default connect(maps)(SettingsIcsFileCustomisationWrapper)
