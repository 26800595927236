import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import {
  courseTransform,
  courseFormValidate,
  courseSaveTransform,
  courseCategoryFormValidate,
  courseCategoryFormServerErrorsTransform,
  courseCategorySaveTransform,
  coursePreviewErrorTransform,
  courseServerErrorsTransform,
  courseLocallyChangedResetServerErrorsTransform
} from './utils'
import { t } from '../../../Common'
import {
  coursesMoved,
  courseUpdated,
  courseDeleted,
  courseCategoriesMoved,
  courseCategoryUpdated,
  courseCategoryDeleted
} from './subscriptions'

globalActions.populateCoursesAndCourseCategories = async () => {
  const { areFetched = false } = store.getState().courses || {}
  if (areFetched) return
  const result = await q('getEnterpriseCoursesAndCourseCategories')
  const { error } = result || { error: { text: 'errors.api.unavailable' } }
  if (error) {
    return result
  }
  handlers.coursesListPopulate({
    courses: result.getEnterpriseCourses || [],
    courseCategories: result.getEnterpriseCourseCategories || []
  })
  return result
}

// Global actions

globalActions.transformCourse = course => {
  return courseTransform(course)
}

// Subscription
payloads$(actions.COURSES_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'coursesMoved' && data) coursesMoved(data)
    if (name === 'courseUpdated' && id) {
      const course = (await q('getEnterpriseCourse', { id })) || {}
      if (course.error) return
      courseUpdated(course)
    }
    if (name === 'courseDeleted' && id) courseDeleted(id)
    if (name === 'courseCategoriesMoved' && data) courseCategoriesMoved(data)
    if (name === 'courseCategoryUpdated') {
      const courseCategory = (await q('getCourseCategory', { id })) || {}
      if (!courseCategory.id || courseCategory.error) return
      courseCategoryUpdated(courseCategory)
    }
    if (name === 'courseCategoryDeleted' && id) courseCategoryDeleted(id)
  })

// Preview
payloads$(actions.COURSE_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/management/courses')
      return
    }
    const state = store.getState()
    const { list = [] } = state.courses
    const selectedCourse = list.find(course => course.id === id) || {}
    const { isComplete } = selectedCourse || {}
    if (isComplete && !forceFetch) {
      handlers.coursePreviewPopulate(id)
      const { internalId } = selectedCourse || {}
      handlers.courseLocallyChangedGet({ id, internalId })
      return
    }
    const course = (await q('getEnterpriseCourse', { id })) || { error: { text: 'errors.api.unavailable' } }
    const { error } = course
    if (error) {
      handlers.coursePreviewMessageSet(coursePreviewErrorTransform(error))
      return
    }
    const { internalId } = course || {}
    handlers.courseLocallyChangedGet({ id, internalId })
    handlers.courseUpdate({ ...course, isComplete: true })
    handlers.coursePreviewPopulate(id)
  })

// Locally changed
payloads$(actions.COURSE_LOCALLY_CHANGED_GET)
  .subscribe(async ({ id, internalId }) => {
    if (!internalId) return handlers.navigateToPath('/management/courses')
    const branches = await globalActions.populateBranches()
    const courses = await q('getEnterpriseCourses', {
      filter: {
        internalId,
        isUpdatedLocally: true,
        onlyLocals: true
      }
    })
    const { error } = courses || {}
    if (error || !courses) return handlers.navigateToPath('/management/courses')
    const locallyChangedData = courses.map(item => ({ id: item.id, branch: branches.find(branch => branch.id === item.companyId) }))
    handlers.courseLocallyChangedPopulate({ id, locallyChangedData })
  })

payloads$(actions.COURSE_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyIds, internalId }) => {
    if (!internalId) return
    const state = store.getState()
    const { forms } = state || {}
    const { courseChangedLocally } = forms || {}
    const {
      customerEmailReminders,
      durationSplitInterval,
      externalId,
      followupTime,
      preparationTime,
      price,
      guests,
      resourceEmailReminders,
      resourcesAndDependencies,
      serviceColor,
      serviceDescription,
      serviceName,
      settingCheckboxToMakeOnlinePaymentMandatory,
      settingDisplayInBookingWidget,
      settingOnlinePayment,
      extraPersonsPerParticipant,
      restoreOptions
    } = courseChangedLocally || {}

    const { value: customerEmailRemindersValue } = customerEmailReminders || {}
    const { value: durationSplitIntervalValue } = durationSplitInterval || {}
    const { value: externalIdValue } = externalId || {}
    const { value: followupTimeValue } = followupTime || {}
    const { value: preparationTimeValue } = preparationTime || {}
    const { value: priceValue } = price || {}
    const { value: resourceEmailRemindersValue } = resourceEmailReminders || {}
    const { value: resourcesAndDependenciesValue } = resourcesAndDependencies || {}
    const { value: serviceColorValue } = serviceColor || {}
    const { value: serviceDescriptionValue } = serviceDescription || {}
    const { value: serviceNameValue } = serviceName || {}
    const { value: settingCheckboxToMakeOnlinePaymentMandatoryValue } = settingCheckboxToMakeOnlinePaymentMandatory || {}
    const { value: settingDisplayInBookingWidgetValue } = settingDisplayInBookingWidget || {}
    const { value: settingOnlinePaymentValue } = settingOnlinePayment || {}
    const { value: guestsValue } = guests || {}
    const { value: extraPersonsPerParticipantValue } = extraPersonsPerParticipant || {}

    const keysMap = {
      customerEmailRemindersMinutes: customerEmailRemindersValue,
      durationsPattern: durationSplitIntervalValue,
      externalId: externalIdValue,
      durationAfter: followupTimeValue,
      durationBefore: preparationTimeValue,
      price: priceValue,
      resourceEmailRemindersMinutes: resourceEmailRemindersValue,
      dependencies: resourcesAndDependenciesValue,
      color: serviceColorValue,
      description: serviceDescriptionValue,
      name: serviceNameValue,
      isPaymentMandatory: settingCheckboxToMakeOnlinePaymentMandatoryValue,
      isBookable: settingDisplayInBookingWidgetValue,
      hasOnlinePayment: settingOnlinePaymentValue,
      maxParticipants: guestsValue,
      extraPersonsPerParticipant: extraPersonsPerParticipantValue
    }
    const keys = Object.keys(keysMap).filter(item => !!keysMap[item]).map(item => item)
    const course = await q('resetToGlobalBranchCourses', { companyIds, internalId, keys: restoreOptions.value === 'ALL' ? [] : keys })
    const { error } = course || {}
    if (error) return setCourseLocallyChangedResetErrors(courseLocallyChangedResetServerErrorsTransform(error), course)
    const updatedCourse = await q('getEnterpriseCourse', { id: internalId })
    handlers.courseLocallyChangedResetReady({ course: updatedCourse, companyIds })
    handlers.navigateToPath(`/management/courses/${updatedCourse.internalId}`)
  })

const setCourseLocallyChangedResetErrors = (errors, course) => {
  handlers.formErrorsSet('courseChangedLocally', errors)
  handlers.courseLocallyChangedResetReady({ course })
}

// Form
payloads$(actions.COURSE_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateCoursesAndCourseCategories()
    const state = store.getState()
    let { company, router, account } = state
    company = company || {}
    router = router || {}
    let { settings: companySettings } = company || {}
    companySettings = companySettings || {}
    const { query = {} } = router || {}
    const { list = [], categoriesList = [] } = state.courses
    let { list: branchesList, areFetched } = state.branches || {}
    branchesList = branchesList || []
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || null
    const defaultCategory = categoriesList.find(category => category.isDefault)
    let category = queryCategory || defaultCategory
    if (!category) return handlers.navigateToPath('/management/courses')
    category.name = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name
    if (!id) {
      handlers.courseFormPopulate({
        course: {
          category,
          availableResources: state.resources.list,
          availableCategories: state.resources.categoriesList,
          companySettings
        },
        branches: branchesList,
        account
      })
      return
    }
    const selectedCourse = list.find(course => course.id === id) || {}
    const courseCategory = categoriesList.find(category => category.id === selectedCourse.categoryId) || null
    const { isComplete } = selectedCourse || {}
    let course = selectedCourse
    if (!isComplete) {
      const getEnterpriseCourse = await q('getEnterpriseCourse', { id })
      const { error: getEnterpriseCourseError } = getEnterpriseCourse || { error: { text: 'errors.api.unavailable' } }
      if (getEnterpriseCourseError) return getEnterpriseCourse
      course = courseTransform(getEnterpriseCourse)
    }
    const { error } = course
    if (error) {
      handlers.navigateToPath(id ? `/management/courses/${id}` : '/management/courses')
      return
    }
    category = courseCategory || defaultCategory
    category.name = category.name === 'default' ? t('servicesGroups.list.group.defaultCategoryName') : category.name
    handlers.courseUpdate({ ...course, isComplete: true })
    handlers.courseFormPopulate({
      course: {
        ...course,
        category,
        availableResources: state.resources.list,
        availableCategories: state.resources.categoriesList,
        companySettings
      },
      branches: branchesList,
      account
    })
  })

// Save
payloads$(actions.COURSE_FORM_SAVE)
  .subscribe(async ({ course, scrollToError }) => {
    const state = store.getState()
    let { branches, courses, company, staticData, router } = state
    branches = branches || {}
    courses = courses || {}
    company = company || {}
    staticData = staticData || {}
    let { list: branchesList } = branches || {}
    branchesList = branchesList || []
    let { list: coursesList } = courses || {}
    coursesList = coursesList || []
    const courseExternalIds = coursesList.filter(courseItem => courseItem.id !== course.id).map(course => course.externalId).filter(Boolean) || []
    const { hash } = router || {}
    let { timezone, locale } = company || {}
    locale = locale || 'en-gb'
    let { countries } = staticData || {}
    countries = countries || []
    const countryCode = locale.split('-')[1]
    const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    course.companyTimezone = timezone
    const errors = courseFormValidate(
      course,
      stripeMinAmountOnlinePaymentsCountryCurrency,
      locale,
      currencyCode,
      courseExternalIds
    )
    if (errors.length) return setCourseFormSaveErrors(errors, scrollToError)
    const savedcourse = await q('saveEnterpriseCourse', courseSaveTransform(course))
    const { error, id } = savedcourse || {}
    if (error) return setCourseFormSaveErrors(courseServerErrorsTransform({ error, branchesList }), scrollToError)
    handlers.courseUpdate({ ...savedcourse, isComplete: true })
    handlers.navigateToPath(`/management/courses/${id}@@${hash}`)
  })

const setCourseFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('courses', errors)
  scrollToError && scrollToError(errors)
  handlers.courseFormReady()
}

// Update
payloads$(actions.COURSE_UPDATE)
  .subscribe(async course => {
    if (!course) return handlers.coursePreviewPopulate()
    setTimeout(() => handlers.courseUpdated(course), 2000)
  })

// Delete
payloads$(actions.COURSE_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.coursePreviewPopulate()
    const { error } = await q('deleteEnterpriseCourse', { id })
    if (error) return
    handlers.courseDeleted(id)
    setTimeout(() => handlers.courseRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/management/courses'), 0)
  })

// Reorder
payloads$(actions.COURSES_LIST_ORDER_CHANGE)
  .subscribe(async ({ id }) => {
    const { orderIndex, categoryId } = store.getState().courses.list.find(course => course.id === id)
    const { error } = await q('moveCourse', { id, orderIndex, categoryId })
    if (error) console.warn('Drag n drop course error!')
  })

// Category form
payloads$(actions.COURSE_CATEGORY_FORM_GET)
  .subscribe(async id => {
    await globalActions.populateCoursesAndCourseCategories()
    const state = store.getState()
    const { categoriesList } = state.courses || {}
    let { list: branchesList, areFetched } = state.branches || {}
    branchesList = branchesList || []
    if (!areFetched) {
      branchesList = await globalActions.populateBranches()
    }
    const selectedCategory = categoriesList.find(category => category.id === id)
    if (id && !selectedCategory) return handlers.navigateToPath('/management/courses')
    handlers.courseCategoryFormPopulate({
      category: {
        ...selectedCategory,
        defaultCategoryName: t('servicesGroups.list.group.defaultCategoryName')
      },
      branches: branchesList,
      account: state.account
    })
  })

// Category save
payloads$(actions.COURSE_CATEGORY_FORM_SAVE)
  .subscribe(async ({ category, scrollToError }) => {
    const state = store.getState()
    let { courses } = state
    courses = courses || {}
    let { categoriesList } = courses || {}
    categoriesList = categoriesList || []
    const categoryExternalIds = categoriesList.map(category => category.externalId).filter(Boolean)
    const errors = courseCategoryFormValidate(category, categoryExternalIds)
    if (errors.length) return setCourseCategoryErrors(errors, scrollToError)
    const savedCategory = await q('saveEnterpriseCourseCategory', courseCategorySaveTransform(category))
    const { error } = savedCategory
    if (error) return setCourseCategoryErrors(courseCategoryFormServerErrorsTransform(error), scrollToError)
    handlers.courseCategoryUpdate(savedCategory)
    handlers.navigateToPath('/management/courses')
  })

const setCourseCategoryErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('courseCategory', errors)
  handlers.courseCategoryFormReady()
  scrollToError && scrollToError(errors)
}

// Category delete
payloads$(actions.COURSE_CATEGORY_DELETE)
  .subscribe(async id => {
    if (!id) return
    const response = await q('deleteEnterpriseCourseCategory', { id }) || { error: { text: 'errors.api.unavailable' } }
    const { error } = response
    if (error) return setCategoryErrors(courseServerErrorsTransform(error))
    handlers.courseCategoryDeleted(id)
    handlers.navigateToPath('/management/courses')
    setTimeout(() => handlers.courseCategoryRemoveDeleted(id), 2000)
  })

const setCategoryErrors = (id, errors, scrollToError) => {
  handlers.formErrorsSet(`courseCategory${id}`, errors)
  handlers.courseCategoryDeleteFailed()
  scrollToError && scrollToError(errors)
  handlers.formErrorsSet(`courseCategory${id}`, [])
}

// Category preview
payloads$(actions.COURSE_CATEGORY_LOCALLY_CHANGED_GET)
  .subscribe(async (categoryId) => {
    if (!categoryId) return handlers.navigateToPath('/management/courses')
    await globalActions.populateBranches()
    const { branches, courses } = store.getState()
    const { list: branchesList = [] } = branches || {}
    const { categoriesList = [] } = courses || {}
    if (categoriesList.length < 1) return handlers.navigateToPath('/management/courses')
    const category = categoriesList.find(item => item.id === categoryId) || {}
    const { locallyUpdatedBranches = {} } = category || {}
    const locallyUpdatedBranchesArr = Object.keys(locallyUpdatedBranches || {}) || []
    const locallyChangedData = locallyUpdatedBranchesArr.map(item => ({
      id: categoryId,
      branch: branchesList.find(branch => branch.id === item) || item
    }))
    handlers.courseCategoryLocallyChangedPopulate({ id: categoryId, locallyChangedData })
  })

payloads$(actions.COURSE_CATEGORY_LOCALLY_CHANGED_RESET)
  .subscribe(async ({ companyId, region, internalId }) => {
    if (!internalId) return
    const courseCategory = await q('resetGlobalBranchCourseCategory', { companyId, region, internalId })
    const { error } = courseCategory || {}
    if (error || !courseCategory) return handlers.navigateToPath('/management/courses')
    handlers.courseCategoryLocallyChangedResetReady({ courseCategory, companyId })
  })

// Service restore
payloads$(actions.COURSE_RESTORE_FORM_GET)
  .subscribe(async ({ service, scrollToError }) => {
    handlers.courseRestoreFormPopulate(service)
  })

// Categories reorder
payloads$(actions.COURSE_CATEGORIES_LIST_ORDER_CHANGE)
  .subscribe(async ({ id }) => {
    const { orderIndex } = store.getState().courses.categoriesList.find(category => category.id === id)
    const result = await q('moveCourseCategory', { id, orderIndex })
    const { error } = result || { error: { message: 'Something went wrong!' } }
    if (error) console.warn('Drag n drop course category error!')
  })

// List to delete
payloads$(actions.COURSES_TO_DELETE_GET)
  .subscribe(async () => {
    const coursesToDelete = await q('getEnterpriseCourses', {
      filter: {
        isGloballyDeleted: true,
        onlyLocals: true
      }
    })
    const { error } = coursesToDelete || {}
    if (error) return handlers.navigateToPath('/management/courses')
    handlers.coursesToDeletePopulate(coursesToDelete)
  })

payloads$(actions.COURSE_TO_DELETE_DELETE)
  .subscribe(async ({ companyId, region, externalId, id }) => {
    if (!id) return
    const course = await q('deleteBranchCourse', { companyId, region, externalId, id })
    const { error } = course || {}
    if (error) return handlers.navigateToPath('/management/courses')
    handlers.coursesToDeleteDeleteReady({ id, companyId })
  })
