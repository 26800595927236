import { dashboardPeakSignUpTimesFilterFormPopulate } from './utils'
const reducer = {}

reducer.initialState = {
  areStatisticsFetched: true
}

reducer.handlers = (dispatch, actions, handlers) => ({
  dashboardGet: () => dispatch(actions.DASHBOARD_GET),
  dashboardPopulate: ({ dailyStatistics, monthlyStatistics }) => dispatch(actions.DASHBOARD_POPULATE, { dailyStatistics, monthlyStatistics }),
  dashboardStatisticsOverallGet: () => dispatch(actions.DASHBOARD_STATISTICS_OVERALL_GET),
  dashboardStatisticsOverallPopulate: ({ overallStatistics }) => dispatch(actions.DASHBOARD_STATISTICS_OVERALL_POPULATE, { overallStatistics }),
  dashboardUpdated: data => dispatch(actions.DASHBOARD_UPDATED, data),
  dashboardFilterFormGet: () => dispatch(actions.DASHBOARD_FILTER_FORM_GET),
  dashboardMarketingFormGet: () => dispatch(actions.DASHBOARD_MARKETING_FORM_GET),
  dashboardMostBookedResourcesFiltersGet: () => dispatch(actions.DASHBOARD_MOST_BOOKED_RESOURCES_FILTERS_GET),
  dashboardPeakSignUpTimesFiltersGet: () => {
    dispatch(actions.DASHBOARD_MOST_BOOKED_RESOURCES_FILTERS_GET)
    handlers.formSet('dashboardStatisticsSignUpTimes', dashboardPeakSignUpTimesFilterFormPopulate())
  },
  // filter by tag functionality
  dashboardStatisticFilter: (statisticId, filter) => dispatch(actions.DASHBOARD_STATISTIC_FILTER, { statisticId, filter }),
  dashboardStatisticUpdate: ({ statisticId, filteredStatistic }) => dispatch(actions.DASHBOARD_STATISTIC_UPDATE, { statisticId, filteredStatistic }),

  // Export
  dashboardStatisticsExportFormGet: () => dispatch(actions.DASHBOARD_STATISTICS_EXPORT_FORM_GET),
  dashboardStatisticsExportFormReady: () => dispatch(actions.DASHBOARD_STATISTICS_EXPORT_FORM_READY),
  dashboardStatisticsExportFormSave: (form, scrollToError = () => {}) => dispatch(actions.DASHBOARD_STATISTICS_EXPORT_FORM_SAVE, { form, scrollToError }),
  dashboardStatisticsExportMessageSet: message => dispatch(actions.DASHBOARD_STATISTICS_EXPORT_MESSAGE_SET, message)
})

// LIST
reducer.DASHBOARD_GET = (state) => ({
  ...state,
  pending: true,
  areStatisticsFetched: false
})

reducer.DASHBOARD_STATISTICS_OVERALL_GET = state => state

reducer.DASHBOARD_FILTER_FORM_GET = state => state

reducer.DASHBOARD_POPULATE = (state, { dailyStatistics = {}, monthlyStatistics = {} }) => ({
  ...state,
  dailyStatistics,
  monthlyStatistics,
  areStatisticsFetched: true,
  pending: false
})

reducer.DASHBOARD_STATISTICS_OVERALL_POPULATE = (state, { overallStatistics = {} }) => ({
  ...state,
  overallStatistics
})

reducer.DASHBOARD_UPDATED = (state) => ({
  ...state,
  pending: true,
  areStatisticsFetched: false
})

reducer.DASHBOARD_MARKETING_FORM_GET = state => ({
  ...state,
  pending: false,
  areStatisticsFetched: true
})

reducer.DASHBOARD_MOST_BOOKED_RESOURCES_FILTERS_GET = state => state

reducer.DASHBOARD_STATISTICS_TYPES_FORM_GET = state => state

// needs update if will be used
reducer.DASHBOARD_STATISTIC_FILTER = (state, { statisticId }) => ({
  ...state,
  [`pending${statisticId}`]: true
})

reducer.DASHBOARD_STATISTIC_UPDATE = (state, { statisticId, filteredStatistic }) => ({
  ...state,
  statistics: {
    ...state.statistics,
    [statisticId]: filteredStatistic
  },
  [`pending${statisticId}`]: false
})

// Export

reducer.DASHBOARD_STATISTICS_EXPORT_FORM_GET = state => ({
  ...state,
  pendingForm: true,
  messageExport: null
})

reducer.DASHBOARD_STATISTICS_EXPORT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.DASHBOARD_STATISTICS_EXPORT_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.DASHBOARD_STATISTICS_EXPORT_MESSAGE_SET = (state, message) => ({
  ...state,
  messageExport: message
})

export default reducer
