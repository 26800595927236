import React, { useEffect } from 'react'

import { connect, handlers, selectors } from '../../../Store'
import { isRouteDisabledByCustomisations, Loader, format } from '../../../Common'
import { AppsSubscription } from '../../../Beauties'

const mock = {
  pricingType: 'monthly',
  entityName: 'SMS',
  isVariablePrice: true,
  price: 0.1,
  priceRange: [0.0095, 0.30],
  invoiceText: 'To use this app you’ve subscribed to a pay per usage plan. The next payment will be charged on the <strong>27.09.2023</strong>. You can cancel this subscription any time before the 26th of the month, otherwise it will be automatically renewed for the next month.',
  thresholdChanged: false,
  threshold: 0,
  oldThreshold: 420,
  newThresholdDate: '27.09.2023',
  message: 'Your subscription plan was successfully updated.',
  plans: [
    {
      name: 'basic',
      text: 'Includes 50 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 10
    },
    {
      name: 'silver',
      text: 'Includes 150 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 22
    },
    {
      name: 'gold',
      text: 'Includes 250 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 35
    },
    {
      name: 'premium',
      text: 'Includes 350 SMS per month. No credit transfer after the expired month if you didn’t use all credited SMS.',
      price: 100
    }
  ],
  planChanged: false,
  oldPlan: 'silver',
  oldPlanText: 'To use this app you’ve subscribed to a monthly plan. The next payment will be charged on the <strong>27.09.2023</strong> and will be in the amount of 11,90€ (includes 1,9€ - 19% VAT). You can cancel this membership any time before the 26th of the month, otherwise it will be automatically renewed for the next month.',
  previousPlan: 'basic'
}

const AppsSubscriptionWrapper = props => {
  let {
    id,
    app,
    pending,
    activePlan,
    message,
    isOwner,
    isAdmin,
    chargebee
  } = props
  app = app || {}
  chargebee = chargebee || {}
  const { noAutoCollect, appDaysToBlock } = chargebee
  const {
    // pricingType,
    // entityName,
    // isVariablePrice,
    // price,
    // priceRange,
    // invoiceText,
    // thresholdChanged,
    // threshold,
    // oldThreshold,
    // newThresholdDate,
    // message,
    // plans,
    planChanged,
    oldPlan,
    oldPlanText,
    previousPlan
  } = mock
  const {
    appInstallation
  } = app || {}
  let {
    unitDescription,
    limitValue,
    plans,
    hasUsage,
    renewAt,
    isPaidByEnterprise,
    unsubscribedAt,
    nextLimitValue,
    isBlocked,
    extraConsumedCharges,
    usageMaxUnitPrice,
    usageMinUnitPrice,
    usageUnitPrice,
    allowedDaysBeforeBlock
  } = appInstallation || {}
  plans = plans || []
  allowedDaysBeforeBlock = allowedDaysBeforeBlock || 30
  const thresholdChanged = nextLimitValue && nextLimitValue !== limitValue
  const oldThreshold = thresholdChanged ? limitValue : null
  limitValue = thresholdChanged ? nextLimitValue : limitValue
  const pricingType = (hasUsage || (hasUsage === null && !isPaidByEnterprise)) ? 'payPerUsage' : 'monthly'
  const isVariablePrice = !!usageMaxUnitPrice && !!usageMinUnitPrice && usageMinUnitPrice !== usageMaxUnitPrice
  const priceRange = [usageMinUnitPrice, usageMaxUnitPrice]
  const invoiceDate = format(renewAt, 'long', { format: 'YYYY-MM-DD' })
  const invoiceDay = format(renewAt, 'Do', { format: 'YYYY-MM-DD' })
  const newThresholdDate = format(renewAt, 'long', { format: 'YYYY-MM-DD' })
  const appsWrite = !!isAdmin

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
    }
    if (!appsWrite) {
      handlers.navigateToPath('/apps')
      return
    }
    if (unsubscribedAt) {
      handlers.navigateToRoute('appsSubscriptionCancelled', { id })
      return
    }

    handlers.appPreviewGet({ id })
    handlers.appSubscriptionFormGet()
    handlers.appSubscriptionBillingFormGet()
  }, [id, appsWrite, unsubscribedAt])

  const handleOnContinue = () => {
    if (!isBlocked) handlers.navigateToRoute('appsIframe', { id })
  }

  return (
    <>
      <Loader active={pending} />
      {!pending && (
        <AppsSubscription
          {...app}
          pricingType={pricingType}
          unitDescription={unitDescription}
          isVariablePrice={isVariablePrice}
          usageUnitPrice={usageUnitPrice}
          priceRange={priceRange}
          invoiceDate={invoiceDate}
          invoiceDay={invoiceDay}
          thresholdChanged={thresholdChanged}
          limitValue={limitValue}
          newThresholdDate={newThresholdDate}
          message={message}
          onContinue={handleOnContinue}
          plans={plans}
          activePlan={activePlan}
          planChanged={planChanged}
          oldPlan={oldPlan}
          oldPlanText={oldPlanText}
          previousPlan={previousPlan}
          isOwner={isOwner}
          oldThreshold={oldThreshold}
          isBlocked={isBlocked}
          extraConsumedCharges={extraConsumedCharges}
          allowedDaysBeforeBlock={allowedDaysBeforeBlock}
          appDaysToBlock={appDaysToBlock}
          noAutoCollect={noAutoCollect}
        />
      )}
    </>
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })
  return {
    id,
    app: selectors.appsFindByIdSelector(state, { id }),
    pending: selectors.appsFieldSelector(state, { field: 'pendingPreview' }),
    activePlan: selectors.formFieldPropertySelector(state, { formName: 'appSubscription', name: 'plan', property: 'value' }),
    message: selectors.appsFieldSelector(state, { field: 'messageSubscription' }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
    chargebee: selectors.companyFieldSelector(state, { field: 'chargebee' })
  }
}

export default connect(maps)(AppsSubscriptionWrapper)
