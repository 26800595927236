import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Input,
  Button,
  Form,
  Row,
  Col,
  t
} from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupUsersDelete = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__users-delete'>
      <Title
        size='m'
        label={dangerousHTML(t('popup.users.delete'))}
        icon='trash-alt'
        ignoreTopOffset
      />
      <Form name='usersDelete' onSubmit={approve}>
        <Row>
          <Col className='relative'>
            <Input
              name='name'
              label={t('global.name')}
              hintText={t('users.preview.heading.buttonDelete.tooltip.hint')}
              mandatory
            />
          </Col>
        </Row>
        <div className='ta-popup__buttons'>
          <Button as='link' isSecondary onClick={reject}>
            {t('buttons.cancel.label')}
          </Button>
          <Button type='submit' isPrimary>
            {t('global.delete')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

PopupUsersDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupUsersDelete
