import React from 'react'
import PropTypes from 'prop-types'
import { TagsPreviewDetails } from '../../Beauties'

const TagPreviewDetailsWrapper = props => {
  const { tag } = props

  return <TagsPreviewDetails {...tag} />
}

TagPreviewDetailsWrapper.propTypes = {
  tag: PropTypes.object.isRequired,
  category: PropTypes.object
}

export default TagPreviewDetailsWrapper
