import { translations, store } from '../../Store'

export const t = (key, replace = [], locale = store.getState().app.locale) => {
  if (!key) return 'Missing key!'
  if (!locale) return 'Something is wrong!'
  if (!translations[locale]) return 'Something is wrong!'

  const { router, phrase } = store.getState()

  const { query } = router || {}

  const { isEnabled } = phrase || {}
  const isStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV)

  let translation = isStaging && isEnabled
    ? `{{__phrase_${key}__}}`
    // REMOVE ONLY THE IF STATEMANT
    // ORIGINAL CODE let translation = translations[locale][key]
    : query.showTranslationKeys ? key : translations[locale][key]

  if (!translation) return `Missing translations: ${key}`
  if (replace.length) {
    replace.forEach(replaces => {
      let arr = []
      if (replaces instanceof Array) {
        arr = replaces
      } else arr = [replaces]

      arr.forEach(r => {
        if (r && r.key) {
          let replaceValue = typeof (r.value) === 'number' ? r.value.toString() : r.value || ''
          if (r.translateReplace && r.translateReplace === true) {
            replaceValue = t(r.value)
          }
          replaceValue = (replaceValue || '').toString()
          translation = translation.replace(new RegExp(`%${replaces.key}%`, 'g'), replaceValue)
        }
      })
    })
  }

  return translation
}
