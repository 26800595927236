import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormSection,
  Error,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  t
} from '../../../Common'
import {
  ServiceFormSummary,
  ServiceFormAdvanced,
  ServiceFormButtons
} from '../../../Beauties'

import './ServiceForm.css'

const ServiceForm = props => {
  let {
    title,
    onSubmit,
    cancelLink,
    showExternalIds,
    customisations,
    hash,
    isAdmin,
    branchesAccessBlacklist,
    location,
    type,
    hasAdvancedTab
  } = props
  location = location || []
  const availableTabs = ['summary', 'advanced']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]
  const hasAll = isAdmin || location.includes('all') || (branchesAccessBlacklist && branchesAccessBlacklist.length === 0)

  return (
    <div className='ta-services__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='services'>
            <SimpleTabs active={activeTab}>
              <SimpleTabsHeader>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='summary' icon='list-ul' label={t('global.summary')} />
                  {(hasAdvancedTab &&
                    <SimpleTabsHeaderItem
                      name='advanced'
                      icon='cogs'
                      label={t('global.advanced')}
                    />
                  )}
                </SimpleTabsHeader>
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='summary'>
                  <ServiceFormSummary
                    showExternalIds={showExternalIds}
                    customisations={customisations}
                    hasAll={hasAll}
                    type={type}
                  />
                </SimpleTabsBodyItem>
                {(hasAdvancedTab &&
                  <SimpleTabsBodyItem name='advanced'>
                    <ServiceFormAdvanced />
                  </SimpleTabsBodyItem>
                )}
              </SimpleTabsBody>
            </SimpleTabs>
            <FormSection>
              <Error name='globalErrors' />
            </FormSection>
            <ServiceFormButtons cancelLink={cancelLink} />
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

ServiceForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default ServiceForm
