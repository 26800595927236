import { payloads$, actions, handlers } from '../../../Store'
import { q } from '../../API'
import { userGroupsSaveTransform, userGroupsFormValidate } from './utils'

// GET ONE
payloads$(actions.USER_GROUP_PREVIEW_GET)
  .subscribe(async (id) => {
    // const userGroup = await q('getuser', {
    //   id: id
    // })
    const userGroup = {
      userGroup: 'userGroup'
    }
    const { error } = userGroup
    if (error) return error
    // TODO @Mitko wait razvan for more fields
    handlers.userPreviewPopulate({ ...userGroup }
    )
    handlers.userPreviewReady()
  })
// GET LIST
payloads$(actions.USER_GROUPS_LIST_GET)
  .subscribe(async () => {
    // const userGroupsList = await q('getuserGroups')
    const userGroupsList = [{
      id: '1',
      name: 'Mitko',
      phone: '0885847090',
      enable: true,
      tag: 'Admin'
    }, {
      id: '2',
      name: 'Vanio',
      phone: '0885847090',
      enable: false,
      tag: 'Noob'
    }, {
      id: '3',
      name: 'Gosho',
      phone: '0885847090',
      enable: true,
      tag: 'Emlpoyee'
    }]
    const { error } = userGroupsList
    if (error) return error
    handlers.userGroupsPopulate(userGroupsList.map(companyLive => ({
      ...companyLive
    })))
  })

// FORM
payloads$(actions.USER_GROUPS_FORM_GET)
  .subscribe(async (userGroupId) => {
    if (userGroupId) {
      const userGroup = await q('getuser', {
        id: userGroupId
      })
      const { error } = userGroup
      if (error) return error
      handlers.userGroupsFormPopulate(userGroup)
    } else {
      handlers.userGroupsFormPopulate()
    }
    handlers.userGroupsFormReady()
  })

// SAVE
payloads$(actions.USER_GROUPS_FORM_SAVE)
  .subscribe(async ({ userGroup, userGroupId, scrollToError }) => {
    const errors = userGroupsFormValidate(userGroup)
    if (errors.length) return userGroupsFormSaveErrors(errors, scrollToError)
    const saveduser = await q('saveuser', {
      userGroup: userGroupsSaveTransform(userGroup, userGroupId)
    })
    const { error } = saveduser
    if (error) return
    handlers.userGroupsFormReady()
    handlers.userGroupsGet()
    handlers.navigateToPath(`/userGroups`)
  })

// DELETE
payloads$(actions.USER_DELETE)
  .subscribe(async id => {
    // TODO here arrives id instead of email ???
    const { error } = await q('deleteuser', { id })
    if (error) {
      console.log(error)
    }
    handlers.userGroupsGet()
    handlers.navigateToPath(`/userGroups`)
  })

const userGroupsFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('userGroups', errors)
  scrollToError && scrollToError(errors)
  handlers.userGroupsFormReady()
}
