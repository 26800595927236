import { payloads$, actions, store } from '../../../Store'
import { companyPublicHolidaysDeleted, companyUpdated } from './subscriptions'
import { q } from '../../API'

// Appcues
payloads$(actions.COMPANY_APPCUES_INIT)
  .subscribe(() => {
    if (!document.getElementById('ta-appcuse-script')) {
      let appcuesElement = document.createElement('script')
      appcuesElement.setAttribute('id', 'ta-appcuse-script')
      appcuesElement.setAttribute('src', 'https://appcues.timify.com/29577.js')
      appcuesElement.setAttribute('async', true)
      document.body.insertBefore(appcuesElement, document.body.firstChild)
    }

    // 1 sec delay the script to load
    setTimeout(() => {
      if (!window.Appcues) return
      const state = store.getState()
      const app = state.app || {}
      const account = state.account || {}
      const company = state.company || {}
      const {
        locale: userLocale
      } = app || {}
      const {
        id: enterpriseId,
        locale: companyLocale = '',
        name: enterpriseName,
        createdAt
      } = company || {}
      const {
        isOwner,
        isAdmin,
        isCustom,
        isVerified,
        userId
      } = account || {}
      const userLocaleParts = companyLocale.split('-')
      const appcusesSettings = {
        enterpriseId,
        enterpriseName,
        createdAt,
        userLocale,
        userLanguage: userLocaleParts[0],
        isOwner,
        isAdmin,
        isCustom,
        isVerified,
        role: isOwner ? 'Owner' : isAdmin ? 'Admin' : 'Custom'
      }

      // Appcues auth
      window.Appcues.identify(userId, appcusesSettings)
    }, 1000)
  })

payloads$(actions.COMPANY_APPCUES_LOGOUT)
  .subscribe(() => {
    // logs current user out
    window.Appcues && window.Appcues.reset()
  })

// Subscription
payloads$(actions.COMPANY_SUBSCRIPTION_SET)
  .subscribe(async ({ name, ids, ...p }) => {
    if (name === 'publicHolidaysEnterpriseDeleted' && ids) companyPublicHolidaysDeleted(ids)
    if (name === 'enterpriseUpdated') {
      const account = (await q('getAccount')) || {}
      if (account.error) return
      const { enterprise } = account || {}
      companyUpdated(enterprise)
    }
  })

// import { payloads$, actions, store } from '../..'
// import { GOOGLE_MAPS_API_KEY } from '../../../Settings'

// // Populate
// payloads$(actions.COMPANY_POPULATE)
//   .subscribe(() => {
//     const state = store.getState()
//     const { company } = state || {}
//     const { locale } = company || {}

//     // Remove old maps api scripts && all its traces
//     window.google = undefined
//     const scripts = [...document.head.getElementsByTagName('script')]
//     scripts.forEach(node => {
//       if (node) {
//         const src = node.getAttribute('src')
//         if (src && src.includes('maps.googleapis.com')) {
//           node.remove()
//         }
//       }
//     })

//     // Append google maps script
//     const googleMapsScript = document.createElement('script')
//     googleMapsScript.async = true
//     googleMapsScript.id = 'timify-google-maps-api'
//     googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=${locale || 'en-gb'}`
//     document.head.appendChild(googleMapsScript)
//   })
