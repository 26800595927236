import React from 'react'
import { AccordionTitle } from '../../../Common'

const AccordionListTitle = props => {
  const { children } = props

  return (
    <AccordionTitle>
      <div className='ta-accordion-list__title'>
        {children}
      </div>
    </AccordionTitle>
  )
}

export default AccordionListTitle
