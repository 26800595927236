import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../../Common'
import { UsersForm } from '../../../Beauties'

const UserEditWrapper = props => {
  const { id } = props
  let {
    pending,
    userId,
    form,
    permissionType: permissionTypeValue,
    permissionTypesOptions,
    isPermissionUpdateAllowedByEnterpriseIssuer,
    amIAdmin,
    allPermissionTypesOptions
  } = props

  useEffect(() => {
    handlers.usersPreviewGet({ id })
  }, [id])

  useEffect(() => {
    if (isRouteDisabledByPermissions()) {
      handlers.navigateToPath('/')
    }
  }, [form])

  form = form || {}
  allPermissionTypesOptions = allPermissionTypesOptions || []
  permissionTypesOptions = permissionTypesOptions || []
  const isEditingOwnUser = userId === id
  let { isOwner } = form || {}
  isOwner = !!isOwner
  let permissionType = allPermissionTypesOptions
    .map(item => ({
      value: item.id,
      label: item.name,
      isUpdateAllowedByIssuer: item.isUpdateAllowedByIssuer
    }))
    .find(item => item.value === permissionTypeValue)
  if (!permissionType) permissionType = { label: t('global.custom'), value: 'CUSTOM', isUpdateAllowedByIssuer: true }

  useEffect(() => {
    handlers.usersFormGet(id)
  }, [id])

  const onSubmit = cb => {
    let { form } = props
    form = form || {}

    handlers.usersFormSave({ user: form, isEditingOwnUser }, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <UsersForm
          id={id}
          title={t('users.list.edit')}
          type='edit'
          cancelLink={`/managers/users/${id}/preview`}
          onSubmit={onSubmit}
          permissionType={permissionType}
          isEditingOwnUser={isEditingOwnUser}
          isOwner={isOwner}
          permissionTypesOptions={permissionTypesOptions}
          isPermissionUpdateAllowedByEnterpriseIssuer={isPermissionUpdateAllowedByEnterpriseIssuer}
          isCustom={permissionTypeValue === 'CUSTOM'}
          amIAdmin={amIAdmin}
        />
        )}
    </>
  )
}

UserEditWrapper.propTypes = {
  id: PropTypes.string,
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  pending: state.users.pendingForm,
  id: state.router.data.id,
  form: state.forms.users,
  userId: state.account.userId,
  permissionType: state.forms.users && state.forms.users.permissionType && state.forms.users.permissionType.value,
  permissionTypesOptions: state.forms.users && state.forms.users.permissionType && state.forms.users.permissionType.options,
  isPermissionUpdateAllowedByEnterpriseIssuer: state.forms.users && state.forms.users.isPermissionUpdateAllowedByEnterpriseIssuer,
  amIAdmin: state.account && state.account.isAdmin,
  allPermissionTypesOptions: state.userPermissions && state.userPermissions.list
})

export default connect(maps)(UserEditWrapper)
