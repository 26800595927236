import React from 'react'
import { handlers } from '../../../Store'
import { PopupBranchGroupDelete } from '../../../Beauties'

export const PopupBranchGroupDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    if (id) handlers.branchCourseDelete(id)
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupBranchGroupDelete approve={approve} reject={reject} />
  )
}

export default PopupBranchGroupDeleteWrapper
