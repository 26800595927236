import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FontAwesome5
} from '../../Common'

import './InnerAccordion.css'

class InnerAccordion extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: (props.isOpen) || false
    }

    this.handleOnClickToggle = this.handleOnClickToggle.bind(this)
  }

  handleOnClickToggle () {
    const { children = null } = this.props

    if (children) this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  render () {
    let {
      iconStyle,
      icon,
      title,
      children,
      iconWrapStyle,
      className,
      noArrowIcon
    } = this.props
    iconStyle = iconStyle || 'red'
    iconWrapStyle = iconWrapStyle || {}
    const { isOpen = false } = this.state
    const accordionClassNames = ['ta-inner-accordion']
    if (className) accordionClassNames.push(className)
    if (isOpen) accordionClassNames.push('open')
    const bodyWrapperClasses = ['ta-inner-accordion-body']
    if (isOpen) bodyWrapperClasses.push('open')
    const iconClasses = ['ta-inner-accordion-icon-wrapper']
    if (iconStyle === 'blue') iconClasses.push('ta-color-blue')
    if (iconStyle === 'red') iconClasses.push('ta-color-red')
    if (iconStyle === 'green') iconClasses.push('ta-color-green')
    if (iconStyle === 'grey') iconClasses.push('ta-color-grey')

    return (
      <div className={accordionClassNames.join(' ')}>
        <div
          className='ta-inner-accordion-header ta-cleaner no-select'
          onClick={this.handleOnClickToggle}
          style={(children && { cursor: 'pointer' }) || {}}
        >
          {icon && (
            <div className={iconClasses.join(' ')} style={iconWrapStyle}>
              <FontAwesome5 icon={icon} type='solid' />
            </div>
          )}

          {title && <div className='ta-inner-accordion-title'>{title}</div>}

          {children && !noArrowIcon && (
            <div className='ta-inner-accordion-btn'>
              {isOpen && <FontAwesome5 icon='angle-up' type='solid' />}
              {!isOpen && <FontAwesome5 icon='angle-down' type='solid' />}
            </div>
          )}
        </div>

        <div className={bodyWrapperClasses.join(' ')}>{children && children}</div>
      </div>
    )
  }
}

export const InnerAccordionContext = React.createContext({
  toggleInner: () => { }
})

InnerAccordion.propTypes = {
  title: PropTypes.any,
  icon: PropTypes.string
}

export default InnerAccordion
