import React from 'react'
import { connect, store, handlers } from '../../Store'
import { PermissionTypesPermissionsForm } from '../../Beauties'

const PermissionsFormWrapper = props => {
  let { availableResourceCategories } = props
  availableResourceCategories = availableResourceCategories || []
  const onCategoryChange = (values, name) => {
    const state = store.getState()
    let { resources: resourcesState, forms } = state || {}
    resourcesState = resourcesState || {}
    forms = forms || {}
    let { list: resources } = resourcesState || {}
    resources = resources || []
    const filteredResourceIds = resources || []
      .filter(item => values.includes(item.categoryId))
      .map(item => item.id)
    let { permissionType: form } = forms || {}
    form = form || {}
    const itemsName = name.replace('Categories', 'Items')
    const field = form[itemsName] || { values: [] }
    const newValues = field.values.filter(item => !filteredResourceIds.includes(item))
    if (field.values.length !== newValues.length) {
      handlers.formFieldsUpdate('permissionType', { [itemsName]: { ...field, values: newValues } })
    }
  }

  return (
    <PermissionTypesPermissionsForm
      {...props}
      availableResourceCategories={availableResourceCategories}
      onCategoryChange={onCategoryChange}
    />
  )
}

const maps = state => ({
  availableResources: state.resources.list,
  availableResourceCategories: state.resources.categoriesList,
  // Calendar
  permissionsCalendarRead: (state.forms.permissionType && state.forms.permissionType.permissionsCalendarRead && state.forms.permissionType.permissionsCalendarRead.value) || false,
  permissionsCalendarReadOptions: (state.forms.permissionType && state.forms.permissionType.permissionsCalendarReadOptions && state.forms.permissionType.permissionsCalendarReadOptions.value) || [],
  permissionsCalendarReadCategories: (state.forms.permissionType && state.forms.permissionType.permissionsCalendarReadCategories && state.forms.permissionType.permissionsCalendarReadCategories.value) || [],
  permissionsCalendarWrite: (state.forms.permissionType && state.forms.permissionType.permissionsCalendarWrite && state.forms.permissionType.permissionsCalendarWrite.value) || false,
  // ShiftPlan
  permissionsShiftPlanRead: (state.forms.permissionType && state.forms.permissionType.permissionsShiftPlanRead && state.forms.permissionType.permissionsShiftPlanRead.value) || false,
  permissionsShiftPlanWrite: (state.forms.permissionType && state.forms.permissionType.permissionsShiftPlanWrite && state.forms.permissionType.permissionsShiftPlanWrite.value) || false,
  // Customers
  permissionsCustomersRead: (state.forms.permissionType && state.forms.permissionType.permissionsCustomersRead && state.forms.permissionType.permissionsCustomersRead.value) || false,
  permissionsCustomersReadOptions: (state.forms.permissionType && state.forms.permissionType.permissionsCustomersReadOptions && state.forms.permissionType.permissionsCustomersReadOptions.value) || [],
  permissionsCustomersReadCategories: (state.forms.permissionType && state.forms.permissionType.permissionsCustomersReadCategories && state.forms.permissionType.permissionsCustomersReadCategories.value) || [],
  permissionsCustomersWrite: (state.forms.permissionType && state.forms.permissionType.permissionsCustomersWrite && state.forms.permissionType.permissionsCustomersWrite.value) || false,
  // Resources
  permissionsResourcesRead: (state.forms.permissionType && state.forms.permissionType.permissionsResourcesRead && state.forms.permissionType.permissionsResourcesRead.value) || false,
  permissionsResourcesReadOptions: (state.forms.permissionType && state.forms.permissionType.permissionsResourcesReadOptions && state.forms.permissionType.permissionsResourcesReadOptions.value) || [],
  permissionsResourcesReadCategories: (state.forms.permissionType && state.forms.permissionType.permissionsResourcesReadCategories && state.forms.permissionType.permissionsResourcesReadCategories.value) || [],
  permissionsResourcesWrite: (state.forms.permissionType && state.forms.permissionType.permissionsResourcesWrite && state.forms.permissionType.permissionsResourcesWrite.value) || false,
  // BookingSetup
  permissionsBookingSetupRead: (state.forms.permissionType && state.forms.permissionType.permissionsBookingSetupRead && state.forms.permissionType.permissionsBookingSetupRead.value) || false
})

export default connect(maps)(PermissionsFormWrapper)
