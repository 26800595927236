import React from 'react'
import { HintText, t } from '../../../../../Common'

import './SnapToGridIntervalExample.css'

const SnapToGridIntervalExample = props => {
  const { value, label, description, narrow } = props
  const classNames = ['ta-booking-interval-example']
  if (label) classNames.push('has-label')
  if (narrow) classNames.push('narrow')
  if (value === 'quarterly') classNames.push('quarterly')
  if (value === 'halfHourly') classNames.push('half-hourly')
  if (value === 'hourly') classNames.push('hourly')

  let hintText = t('settings.booking.default.hint')
  let gridCellLabel = '35 min'
  let slots = {
    first: '12:00',
    second: '12:35',
    third: '13:10',
    forth: '13:45',
    fifth: '14:20'
  }

  // 15 min
  if (value === 'quarterly') {
    classNames.push('quarterly')
    hintText = t('settings.booking.quarterly.hint')
    gridCellLabel = '15 mins'
    slots = {
      first: '12:00 (35 mins)',
      second: '12:15 (35 mins)',
      third: '12:30 (35 mins)',
      forth: '12:45 (35 mins)',
      fifth: '13:00 (35 mins)'
    }
  }

  // 30 min
  if (value === 'halfHourly') {
    classNames.push('half-hourly')
    hintText = t('settings.booking.half.hint')
    gridCellLabel = '30 mins'
    slots = {
      first: '12:00 (35 mins)',
      second: '12:30 (35 mins)',
      third: '13:00 (35 mins)',
      forth: '13:30 (35 mins)',
      fifth: '14:00 (35 mins)'
    }
  }

  // 60 min
  if (value === 'hourly') {
    classNames.push('hourly')
    hintText = t('settings.booking.hourly.hint')
    gridCellLabel = '60 mins'
    slots = {
      first: '12:00',
      second: '13:00',
      third: '14:00',
      forth: '15:00',
      fifth: '16:00'
    }
  }

  return (
    <>
      <div className={classNames.join(' ')}>
        {(label &&
          <div className='ta-booking-interval-example__label'>{label}</div>
        )}
        <div className='ta-booking-interval-example__grid'>
          <div className='ta-booking-interval-example__grid-col col-1'>
            <div className='ta-booking-interval-example__grid-col-label'>{gridCellLabel}</div>
          </div>
          <div className='ta-booking-interval-example__grid-col col-2'>
            <div className='ta-booking-interval-example__grid-col-label'>{gridCellLabel}</div>
          </div>
          <div className='ta-booking-interval-example__grid-col col-3'>
            <div className='ta-booking-interval-example__grid-col-label'>{gridCellLabel}</div>
          </div>
          <div className='ta-booking-interval-example__grid-col col-4'>
            <div className='ta-booking-interval-example__grid-col-label'>{gridCellLabel}</div>
          </div>
          <div className='ta-booking-interval-example__grid-col col-5'>
            <div className='ta-booking-interval-example__grid-col-label'>{gridCellLabel}</div>
          </div>
        </div>
        <div className='ta-booking-interval-example__slot slot-1'>{slots.first}</div>
        <div className='ta-booking-interval-example__slot slot-2'>{slots.second}</div>
        <div className='ta-booking-interval-example__slot slot-3'>{slots.third}</div>
        <div className='ta-booking-interval-example__slot slot-4'>{slots.forth}</div>
        <div className='ta-booking-interval-example__slot slot-5'>{slots.fifth}</div>
      </div>
      {(description &&
        <HintText>{hintText}</HintText>
      )}
    </>
  )
}

export default SnapToGridIntervalExample
