import { payloads$, actions, handlers, store, selectors } from '../../../Store'
import { q } from '../../API'
import {
  billingFormValidate,
  billingSaveTransform,
  billingServerErrorsTransform
} from './utils'

// Form
payloads$(actions.BILLING_FORM_GET)
  .subscribe(() => {
    const billingData = store.getState().billing.data
    handlers.billingFormPopulate(billingData)
  })

// Save
payloads$(actions.BILLING_FORM_SAVE)
  .subscribe(async ({ data, scrollToError }) => {
    const errors = billingFormValidate(data)
    if (errors.length) return setBillingFormSaveErrors(errors, scrollToError)
    const chargebeeData = await q('saveEnterpriseBillingData', { billingData: billingSaveTransform(data) })
    const { error } = chargebeeData || { error: 'Not found' }
    if (error) return setBillingFormSaveErrors(billingServerErrorsTransform(error), scrollToError)
    const { billingData } = chargebeeData || {}
    handlers.billingPopulate({ billingData })
    if (data.noRedirect) {
      handlers.billingFormReady()
      data.onAfter && data.onAfter()
      return
    }
    handlers.navigateToPath('/account/billing')
  })

const setBillingFormSaveErrors = (errors, scrollToError) => {
  const state = store.getState()
  const routerName = selectors.routerFieldSelector(state, { field: 'name' })
  handlers.formErrorsSet(routerName === 'appsSubscriptionAdd' ? 'appSubscriptionBilling' : 'billing', errors)
  handlers.formErrorsSet('billing', errors)
  scrollToError && scrollToError(errors)
  handlers.billingFormReady()
}
