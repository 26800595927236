import React from 'react'
import { connect } from '../../Store'
import { CourseFormParticipants } from '../../Beauties'

const CourseFormParticipantsWrapper = props => {
  const {
    hasMinParticipants,
    minParticipants,
    deadlineBeforeEvent
  } = props

  return (
    <CourseFormParticipants
      hasMinParticipants={hasMinParticipants}
      minParticipants={minParticipants}
      deadlineBeforeEvent={deadlineBeforeEvent}
    />
  )
}

const maps = state => ({
  hasMinParticipants: (state.forms.courses.hasMinParticipants && state.forms.courses.hasMinParticipants.value) || false,
  minParticipants: (state.forms.courses.minParticipants && state.forms.courses.minParticipants.value) || 0,
  deadlineBeforeEvent: (state.forms.courses.deadlineBeforeEvent && state.forms.courses.deadlineBeforeEvent.value) || 0
})

export default connect(maps)(CourseFormParticipantsWrapper)
