import React from 'react'
import {
  FormSection,
  Title,
  Row,
  Col,
  Checkbox,
  Button,
  FontAwesome5,
  Alert,
  DurationInput,
  FormText,
  BookingIntervalsInput,
  BorderedBox,
  t,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../../Common'
import { PopupTriggerWrapper } from '../../../../Beasts'
import { convertMinutesToDaysHoursMinutes, hex2rgba, dangerousHTML } from '../../../../Utils'

const BranchesGroupFormDuration = props => {
  const {
    durationBefore,
    durationAfter,
    showDurationBeforeAndAfter,
    showDurationDaySelector,
    splitDurationInIntervals,
    customisations,
    allowedSet,
    color
  } = props
  const { settings } = customisations || {}
  const { hideServicesFormDurationBeforeAndAfter } = settings || {}

  const translations = {
    minutes: 'min',
    hours: 'h',
    days: 'd'
  }
  const bufferTimesStyles = { background: hex2rgba(color, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color, 1) }
  const isDurationDisabled = !allowedSet?.includes('durationsPattern')
  const isDurationBeforeDisabled = !allowedSet?.includes('durationBefore')
  const isDurationAfterDisabled = !allowedSet?.includes('durationAfter')

  return (
    <>
      <FormSection>
        <Title size='m' label={t('global.details')} icon='hourglass-half' />
        {(!splitDurationInIntervals &&
          <Row>
            <Col>
              <FormText>
                {t('global.duration')}
                <div className='ta-services-duration-tooltip'>
                  <PopupTriggerWrapper name='service-duration-tooltip' position='left'>
                    <Button as='link' className='btn-small-icon-only' external>
                      <FontAwesome5 icon='question-circle' type='regular' />
                    </Button>
                  </PopupTriggerWrapper>
                </div>
              </FormText>
              <HoverPopup disabled={!isDurationDisabled} className='ta-services__form__description-hover-popup'>
                <HoverPopupContent position='top'>
                  {t('globalSettings.form.section.attributes.disabled')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <DurationInput disabled={isDurationDisabled} name='duration' noDays={!showDurationDaySelector} />
                </HoverPopupTrigger>
              </HoverPopup>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <HoverPopup disabled={!isDurationDisabled} className='ta-services__form__description-hover-popup'>
              <HoverPopupContent position='top'>
                {t('globalSettings.form.section.attributes.disabled')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Checkbox
                  disabled={isDurationDisabled}
                  name='splitDurationInIntervals'
                  label={t('branches.form.group.edit.serviceDuration.splitIntervals.label')}
                  theme='switch'
                  inline
                />
                <PopupTriggerWrapper name='courses-split-intervals' position='right'>
                  <FontAwesome5 type='r' className='ta-branches-group-duration__question-circle-icon' icon='question-circle' />
                </PopupTriggerWrapper>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
        {(splitDurationInIntervals &&
          <BorderedBox>
            <Row>
              <Col>
                <HoverPopup disabled={!isDurationDisabled} className='ta-services__form__description-hover-popup'>
                  <HoverPopupContent position='top'>
                    {t('globalSettings.form.section.attributes.disabled')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <BookingIntervalsInput
                      name='intervals'
                      limit={7}
                      disabled={isDurationDisabled}
                      color={color}
                      noDays={!showDurationDaySelector}
                    />
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
          </BorderedBox>
        )}
        {(!hideServicesFormDurationBeforeAndAfter &&
          <>
            <Row>
              <Col>
                <HoverPopup disabled={(!isDurationBeforeDisabled || !isDurationAfterDisabled)} className='ta-services__form__description-hover-popup'>
                  <HoverPopupContent position='top'>
                    {(isDurationBeforeDisabled && isDurationAfterDisabled) ? t('globalSettings.form.section.attributes.disabled') : t('global.premiumOnly')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Checkbox
                      name='showDurationBeforeAndAfter'
                      disabled={!allowedSet?.includes('durationBefore') && !allowedSet?.includes('durationAfter')}
                      label={t('branches.form.group.edit.serviceDuration.showDuration.label')}
                      theme='switch'
                      inline
                    />
                    <PopupTriggerWrapper name='services-duration-before-and-after' position='right'>
                      <FontAwesome5 className='ta-branches-group-duration__question-circle-icon' icon='question-circle' type='regular' />
                    </PopupTriggerWrapper>
                  </HoverPopupTrigger>
                </HoverPopup>
              </Col>
            </Row>
            {(showDurationBeforeAndAfter &&
              <BorderedBox>
                <div className='ta-buffer-times'>
                  <div className='ta-buffer-times__box' style={bufferTimesStyles}>
                    <div className='ta-buffer-times__box__content' style={bufferTimesContentContentStyles} />
                  </div>
                  <Row>
                    <Col>
                      <HoverPopup disabled={!isDurationBeforeDisabled} className='ta-services__form__description-hover-popup'>
                        <HoverPopupContent position='top'>
                          {t('globalSettings.form.section.attributes.disabled')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <FormText>{t('branches.form.group.edit.serviceDuration.prepTime.label')}</FormText>
                          <DurationInput name='durationBefore' noDays={!showDurationDaySelector} disabled={isDurationBeforeDisabled} />
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <HoverPopup disabled={!isDurationAfterDisabled} className='ta-services__form__description-hover-popup'>
                        <HoverPopupContent position='top'>
                          {t('globalSettings.form.section.attributes.disabled')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <FormText>{t('branches.form.group.edit.serviceDuration.followUpTime.label')}</FormText>
                          <DurationInput name='durationAfter' noDays={!showDurationDaySelector} disabled={isDurationAfterDisabled} />
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </Col>
                  </Row>
                </div>
                {(!!(durationBefore || durationAfter) &&
                  <Alert theme='alert' noOffset>
                    {(durationBefore &&
                      <div>
                        {t('branches.form.group.edit.serviceDuration.durationBefore')}:<br />
                        <strong>{convertMinutesToDaysHoursMinutes(durationBefore, translations)}</strong>
                      </div>
                    )}
                    {(durationAfter &&
                      <div>
                        {t('branches.form.group.edit.serviceDuration.durationAfter')}:<br />
                        <strong>{convertMinutesToDaysHoursMinutes(durationAfter, translations)}</strong>
                      </div>
                    )}
                    <div>{dangerousHTML(t('branches.form.group.edit.serviceDuration.warning'))}</div>
                  </Alert>
                )}
              </BorderedBox>
            )}
          </>
        )}
      </FormSection>
    </>
  )
}

export default BranchesGroupFormDuration
