import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  NUMBERS_VALIDATION_REGEX,
  FIVE_MINUTES_INTERVAL_TIMES,
  HOURS
} from '../../../Settings'
import {
  FormContext,
  Row,
  Col,
  FormGroup,
  FontAwesome5,
  Error,
  Select,
  t
} from '../../../Common'

import './DurationInput.css'

class DurationInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onDaysChange = this.onDaysChange.bind(this)
    this.onHoursChange = this.onHoursChange.bind(this)
    this.onMinutesChange = this.onMinutesChange.bind(this)
    this.state = {}
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (field) {
    this.setState({ focusedField: field })
  }

  onBlur () {
    this.setState({ focusedField: null })
  }

  onDaysChange () {
    const { name, formName, form = {} } = this.props
    const value = this.daysRef.value || ''
    const numbersRegex = new RegExp(NUMBERS_VALIDATION_REGEX)
    const days = value && numbersRegex.test(value) ? value : ''
    this.setState({ days })
    const valueDuration = moment.duration(form.value || 0, 'minutes')
    const valueDays = form.value && valueDuration.days()
    const valueHours = form.value && valueDuration.hours()
    const valueMinutes = form.value && valueDuration.minutes()
    const duration =
      (parseInt(days === null ? valueDays || 0 : days || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(valueHours || 0, 10) * 60) + // hours in minutes
      (parseInt(valueMinutes || 0, 10)) // minutes
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: duration || null } })
  }

  onHoursChange (value) {
    const { name, formName, form = {} } = this.props
    const hours = value ? value.value : 0
    this.setState({ hours: hours.toString() })
    const valueDuration = moment.duration(form.value || 0, 'minutes')
    const valueDays = form.value && valueDuration.days()
    const valueMinutes = form.value && valueDuration.minutes()
    const duration =
      (parseInt(valueDays || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(hours, 10) * 60) + // hours in minutes
      (parseInt(valueMinutes || 0, 10)) // minutes
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: duration } })
  }

  onMinutesChange (value) {
    const { name, formName, form = {} } = this.props
    const minutes = value ? value.value : 0
    this.setState({ minutes: minutes.toString() })
    const valueDuration = moment.duration(form.value || 0, 'minutes')
    const valueDays = form.value && valueDuration.days()
    const valueHours = form.value && valueDuration.hours()
    const duration =
      (parseInt(valueDays || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(valueHours || 0, 10) * 60) + // hours in minutes
      parseInt(minutes, 10) // minutes
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: duration } })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  render () {
    const { focusedField } = this.state
    const {
      className,
      noDays,
      hideError,
      name,
      form = {},
      disabled
    } = this.props
    const hoursOptions = HOURS.map(hour => ({
      label: hour,
      value: hour
    }))
    const minutesOptions = FIVE_MINUTES_INTERVAL_TIMES.filter(t => t < 60).map(time => ({
      label: time,
      value: time
    }))
    const duration = moment.duration(form.value || 0, 'minutes')
    const valueDays = (form.value && duration.days()) || ''
    const valueHours = (form.value && duration.hours().toString()) || ''
    const valueMinutes = (form.value && duration.minutes().toString()) || ''
    const hasError = form.errors && form.errors.length > 0
    const classNames = ['ta-duration-input']
    if (className) classNames.push(className)
    if (disabled) classNames.push('disabled')

    return (
      <div className={classNames.join(' ')}>
        <Row>
          {((!noDays || (form.value || 0) >= 1440) &&
            <Col size={30}>
              <FormGroup
                focused={focusedField === 'days'}
                filled={!!valueDays}
                labelText={t('global.days.label')}
                labelMandatory
                hasError={hasError}
                disabled={disabled}
              >
                <input
                  className='ta-form-control'
                  ref={daysRef => { this.daysRef = daysRef }}
                  type='text'
                  value={valueDays}
                  onChange={this.onDaysChange}
                  onFocus={() => this.onFocus('days')}
                  onBlur={this.onBlur}
                  autoComplete='off'
                  disabled={disabled}
                />
              </FormGroup>
            </Col>
          )}
          <Col size={30}>
            <FormGroup
              focused={focusedField === 'hours'}
              filled={!!valueHours}
              labelText={t('global.hours.label')}
              labelMandatory
              hasError={hasError}
              disabled={disabled}
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={valueHours}
                arrowRenderer={this.renderArrows}
                onChange={this.onHoursChange}
                onFocus={() => this.onFocus('hours')}
                onBlur={this.handleOnBlur}
                options={hoursOptions}
                clearable={false}
                searchable
                autoComplete='off'
                hasError={hasError}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col size={30}>
            <FormGroup
              focused={focusedField === 'minutes'}
              filled={!!valueMinutes}
              labelText={t('global.minutes.label')}
              labelMandatory
              hasError={hasError}
              disabled={disabled}
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={valueMinutes}
                arrowRenderer={this.renderArrows}
                onChange={this.onMinutesChange}
                onFocus={() => this.onFocus('minutes')}
                onBlur={this.handleOnBlur}
                options={minutesOptions}
                clearable={false}
                searchable
                autoComplete='off'
                hasError={hasError}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
        </Row>
        {(!hideError &&
          <Error noOffset name={name} />
        )}
      </div>
    )
  }
}

DurationInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  limit: PropTypes.number,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' }
})

export default feedContextInProps(connect(maps)(DurationInput), FormContext)
