
import React from 'react'
import {
  Checkbox,
  BorderedBox,
  Row,
  Col,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Alert,
  t
} from '../../../../Common'
import { WeekDays } from '../../../../Beauties'

const BranchesServiceFormAllowancePlan = props => {
  const {
    plan,
    hasAllowancePlan,
    hasSpecificBookingDays
  } = props

  const allowancePlanClassNames = ['ta-services__form__times__plan']
  if (plan !== 'ENTERPRISE') allowancePlanClassNames.push('disabled')

  return (
    <div className='ta-services__form__times'>
      <Row>
        <Col>
          <HoverPopup disabled={plan === 'ENTERPRISE' || hasAllowancePlan}>
            <HoverPopupContent autoSize>
              {t('global.enterpriseOnly')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                name='hasSpecificBookingDays'
                label={t('servicesGroups.form.section.settings.checkboxSpecificBookingDays.label')}
                disabled={plan !== 'ENTERPRISE' && !hasAllowancePlan}
                theme='switch'
              />
            </HoverPopupTrigger>
          </HoverPopup>
          {(plan !== 'ENTERPRISE' && hasAllowancePlan &&
            <Alert noOffset theme='alert'>
              {t('errors.plan.systemErrorWarning')}
            </Alert>
          )}
        </Col>
      </Row>
      {(hasSpecificBookingDays &&
        <Row>
          <Col>
            <BorderedBox className={allowancePlanClassNames.join(' ')}>
              <WeekDays prefix='bookingWeeklyAllowancePlan' intervalGapMessage={t('weekdays.interval.gap')} />
            </BorderedBox>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default BranchesServiceFormAllowancePlan
