import React from 'react'
import {
  SimpleTabsHeaderItem,
  FixedContentHeader,
  SimpleTabsBodyItem,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FixedContent,
  HoverPopup,
  SimpleTabs,
  Title,
  Button,
  t,
  Row,
  Col
} from '../../../Common'
import {
  UserPermissionTypeSummary,
  UserPermissionTypeUsers
} from '../../../Beauties'
import { PopupTriggerWrapper } from '../../../Beasts'

const UserPermissionsPreview = props => {
  let {
    userPermission,
    hash,
    pendingUsers,
    permissionsGroupsWrite,
    branches,
    isAdmin,
    canLogedInUserReadUsers,
    permissionsGroupsDelete,
    isEditingOwnPG
  } = props
  pendingUsers = !!pendingUsers
  let { id, name, users, isUpdateAllowedByIssuer } = userPermission || {}
  users = users || []
  const availableTabs = ['summary', 'users']
  const activeTab = availableTabs.includes(hash) ? hash : availableTabs[0]
  if (isAdmin) isUpdateAllowedByIssuer = true
  const addManagerButtonClassName = ['ta-btn']
  if (!isUpdateAllowedByIssuer) addManagerButtonClassName.push('ta-btn-disabled-opacity')
  const addButtonHoverPosition = users.length < 5 ? 'bottom' : 'top'

  return (
    <div className='ta-user-permission-types'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={name || t('userPermissionTypes.detail.heading.default')}>
            {(permissionsGroupsWrite && id !== 'ADMIN' &&
              <>
                {(permissionsGroupsDelete &&
                  <PopupTriggerWrapper name='user-permission-delete' size='l' disable={users.length > 0} id={id} position='left'>
                    <HoverPopup>
                      <HoverPopupContent position='left-top'>
                        {users.length > 0 ? t('userPermissionTypes.detail.buttonHeading.deleteDisabled') : t('userPermissionTypes.detail.buttonHeading.deleteTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Button isTertiary as='link' className='ta-btn ta-btn-delete' disabled={users.length > 0} external icon='trash-alt' type='s' />
                      </HoverPopupTrigger>
                    </HoverPopup>
                  </PopupTriggerWrapper>
                )}
                <HoverPopup disabled={!isEditingOwnPG}>
                  <HoverPopupContent position='left' autoSize>
                    {isEditingOwnPG && t('users.form.permissions.cannotEditOwn.tooltip')}
                    {!isEditingOwnPG && t('userPermissionTypes.buttonHeading.editTooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button
                      as='link'
                      to={`/managers/user-permissions/${id}/edit`}
                      isPrimary
                      disabled={isEditingOwnPG}
                      icon='pencil'
                    />
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <SimpleTabs active={activeTab}>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem name='summary' icon='shield-alt' label={t('global.summary')} />
              <SimpleTabsHeaderItem name='users' icon='user' label={t('global.users')} />
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <SimpleTabsBodyItem name='summary'>
                <UserPermissionTypeSummary
                  canLogedInUserReadUsers={canLogedInUserReadUsers}
                  isLogedInUserAdmin={isAdmin}
                  userPermission={userPermission}
                  branches={branches}
                />
              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='users'>
                <Row>
                  <Col>
                    <UserPermissionTypeUsers pendingUsers={pendingUsers} users={users} />
                  </Col>
                </Row>
                <div>
                  <Row>
                    <Col>
                      <HoverPopup className='ta-row'>
                        {(!isUpdateAllowedByIssuer &&
                          <HoverPopupContent position={addButtonHoverPosition}>
                            {t('permission.type.manager.add.hover')}
                          </HoverPopupContent>
                        )}
                        <HoverPopupTrigger>
                          <Button as='link' disabled={!isUpdateAllowedByIssuer} className={addManagerButtonClassName.join(' ')} isPrimary isBlock to={`/managers/users/add?permission-group-id=${id}`}>
                            {t('permission.type.manager.add')}
                          </Button>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </Col>
                  </Row>
                </div>
              </SimpleTabsBodyItem>
            </SimpleTabsBody>
          </SimpleTabs>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default UserPermissionsPreview
