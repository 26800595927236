import { createSelector } from '../../Utils'

export const routerSelector = state => state.router

export const routerFieldSelector = createSelector(
  routerSelector,
  (_, props) => props.field,
  (router, field) => (router || {})[field]
)

export const routerDataFieldSelector = createSelector(
  state => routerFieldSelector(state, { field: 'data' }),
  (_, props) => props.field,
  (data, field) => (data || {})[field]
)

export const routerPropsFieldSelector = createSelector(
  state => routerFieldSelector(state, { field: 'props' }),
  (_, props) => props.field,
  (props, field) => (props || {})[field]
)
