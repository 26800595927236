import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { NotificationCard } from '../../../Beauties'

const NotificationCardWrapper = (props) => {
  const { systemMessage, id } = props

  const acceptRequest = () => {
    handlers.systemMessagesAccept(id)
  }

  const declineRequest = () => {
    handlers.systemMessagesDecline(id)
  }

  const revokeRequest = () => {
    handlers.systemMessagesRevoke(id)
  }

  return (
    <NotificationCard
      acceptRequest={acceptRequest}
      declineRequest={declineRequest}
      revokeRequest={revokeRequest}
      systemMessage={systemMessage}
    />
  )
}

NotificationCardWrapper.propTypes = {
  id: PropTypes.string.isRequired
}

const maps = (state, props) => ({
  systemMessage: selectors.systemMessageSelectorr(state, { systemMessageId: props.id })
})

export default connect(maps)(NotificationCardWrapper)
