import React from 'react'
import { SimpleListItem, FontAwesome5, Alert, t, Button } from '../../Common'

const ServicesToDelete = props => {
  let { servicesToDeleteData = [], onDeleteGlobalEntity, globalServicesWrite } = props
  servicesToDeleteData = servicesToDeleteData || []

  return (
    <div className='ta-services--to-delete'>
      {(servicesToDeleteData.length > 0 &&
        <Alert label={t('service.list.delete.pending')} theme='alert'>
          {t('service.list.delete.message')}
        </Alert>
      )}
      {servicesToDeleteData.map(item => {
        let { branch = {}, service = {} } = item
        branch = branch || {}
        service = service || {}
        return (
          <SimpleListItem className='ta-services-to-delete--branch' key={service.id}>
            <div className='ta-services-to-delete--branch__data'>
              <p className='ta-services-to-delete--branch__name'>{branch.name}</p>
              <div className='ta-services-to-delete--item'>
                <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
              </div>
              <div className='ta-services-to-delete--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{service.id}</p>
              </div>
            </div>
            {(globalServicesWrite &&
              <div className='ta-services-to-delete--branch__buttons'>
                <Button onClick={() => onDeleteGlobalEntity(branch.id, branch.region, service.externalId, service.id)}>{t('global.delete')}</Button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {!servicesToDeleteData.length && <Alert noOffset label={t('service.list.delete.noServices')} theme='no-content' />}
    </div>
  )
}

export default ServicesToDelete
