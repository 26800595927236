import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { BranchesImport } from '../../Beauties'
import { Loader, isRouteDisabledByCustomisations, isRouteDisabledByPermissions } from '../../Common'

const BranchesImportWrapper = props => {
  const { pendingImportForm, form } = props
  const { files = {}, file = {} } = form || {}
  const hasFormErrors = file && file.errors && (file.errors || []).length > 0
  const hasFormValues = files.values && (files.values || []).length > 0
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations() || isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.branchesImportFormGet()
    return () => {
      handlers.formFieldsUpdate('branchesImport', { files: { ...props.formFiles, rawFiles: [] } })
    }
  }, [])

  const onSubmit = () => {
    const { form } = props
    handlers.branchesImportFormSubmit({ form })
  }

  const onClickDownload = (type, isUrl) => {
    handlers.branchesCSVDownload(type, isUrl)
  }

  const onCancelClick = () => {
    handlers.navigateToPath('/branches')
  }

  return pendingImportForm
    ? <Loader active />
    : <BranchesImport
      onSubmit={onSubmit}
      onCancelClick={onCancelClick}
      enableButton={hasFormValues && !hasFormErrors}
      onClickDownload={onClickDownload}
    />
}

const maps = state => {
  return ({
    formFiles: (state.forms && state.forms.branchesImport && state.forms.branchesImport.files) || { value: '' },
    pendingImportForm: state.branches.pendingImportForm || false,
    form: state.forms.branchesImport || {}
  })
}

export default connect(maps)(BranchesImportWrapper)
