import React from 'react'
import { connect } from '../../Store'
import { CourseFormReminders } from '../../Beauties'

const CourseFormRemindersWrapper = props => {
  let {
    courseCustomReminderCustomerSwitch,
    courseReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    courseCustomReminderStaffSwitch,
    courseReminderStaffSwitch,
    courseReminderStaffOthersCheckBox,
    courseReminderStaffOthers,
    resourceEmailRemindersMinutes
  } = props
  courseReminderStaffOthers = courseReminderStaffOthers || []

  return (
    <CourseFormReminders
      courseCustomReminderCustomerSwitch={courseCustomReminderCustomerSwitch}
      courseReminderCustomerSwitch={courseReminderCustomerSwitch}
      customerEmailRemindersMinutes={customerEmailRemindersMinutes}
      courseCustomReminderStaffSwitch={courseCustomReminderStaffSwitch}
      courseReminderStaffSwitch={courseReminderStaffSwitch}
      courseReminderStaffOthersCheckBox={courseReminderStaffOthersCheckBox}
      courseReminderStaffOthers={courseReminderStaffOthers}
      resourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
    />
  )
}

const maps = state => ({
  courseCustomReminderCustomerSwitch: state.forms.courses.courseCustomReminderCustomerSwitch && state.forms.courses.courseCustomReminderCustomerSwitch.value,
  courseReminderCustomerSwitch: state.forms.courses.courseReminderCustomerSwitch && state.forms.courses.courseReminderCustomerSwitch.value,
  customerEmailRemindersMinutes: state.forms.courses.customerEmailRemindersMinutes && state.forms.courses.customerEmailRemindersMinutes.values,
  courseCustomReminderStaffSwitch: state.forms.courses.courseCustomReminderStaffSwitch && state.forms.courses.courseCustomReminderStaffSwitch.value,
  courseReminderStaffSwitch: state.forms.courses.courseReminderStaffSwitch && state.forms.courses.courseReminderStaffSwitch.value,
  courseReminderStaffOthersCheckBox: state.forms.courses.courseReminderStaffOthersCheckBox && state.forms.courses.courseReminderStaffOthersCheckBox.value,
  courseReminderStaffOthers: state.forms.courses.courseReminderStaffOthers && state.forms.courses.courseReminderStaffOthers.values,
  resourceEmailRemindersMinutes: state.forms.courses.resourceEmailRemindersMinutes && state.forms.courses.resourceEmailRemindersMinutes.values
})

export default connect(maps)(CourseFormRemindersWrapper)
