import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { AccountsSwitch } from '../../Beauties'

const AccountsSwitchWrapper = props => {
  const { route, companyName } = props
  const isActive = route === 'accounts'

  return (
    <AccountsSwitch
      isActive={isActive}
      companyName={companyName}
    />
  )
}

AccountsSwitchWrapper.propTypes = {
  route: PropTypes.string
}

const maps = state => ({
  route: state.router.name,
  companyName: state.company.name || ''
})

export default connect(maps)(AccountsSwitchWrapper)
