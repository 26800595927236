import { handlers, store } from '../../../Store'
import { q } from '../../API'

// export const usersMoved = users => {
//   handlers.usersListOrderChanged(users)
// }

export const userUpdated = async ({ id, ts }) => {
  if (!id) return
  const state = store.getState()
  const { forms } = state || {}
  const { users } = forms || {}
  const { id: formId } = users || {}
  const user = (await q('getEnterpriseUser', { id })) || { error: { text: 'Not found ' } }
  if (ts === user.ts) return
  if (user.isComplete) return handlers.userPreviewGet({ id, forceFetch: true })
  handlers.usersUpdate(user)
  if (formId === id) {
    handlers.usersFormGet(id)
  }
}

export const userDeleted = ({ id }) => {
  if (!id) return
  const { users = {}, router = {} } = store.getState()
  const user = users.list.find(item => item.id === id) || {}
  if (!user || user.isDeleted) return
  handlers.usersDeleted(id)
  setTimeout(() => handlers.usersRemoveDeleted(id), 2000)
  const { params } = router
  if (params.id === id) setTimeout(() => handlers.navigateToPath('/managers/users'), 0)
}

// export const userCategoriesMoved = categories => {
//   handlers.userCategoryOrderChanged(categories)
// }

// export const userCategoryUpdated = category => {
//   if (!category) return
//   handlers.userCategoryUpdate(category)
// }

// export const userCategoryDeleted = id => {
//   if (!id) return
//   const { users = {} } = store.getState()
//   const category = users.categoriesList.find(item => item.id === id) || {}
//   if (!category || category.isDeleted) return
//   handlers.userCategoryDeleted(id)
//   setTimeout(() => handlers.userCategoryRemoveDeleted(id), 2000)
// }
