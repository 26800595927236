import React from 'react'
import { dangerousHTML } from '../../../Utils'

const PopupServiceAllocationsHelpBox = props => {
  let { id } = props
  id = id || {}
  let { text } = id || {}

  return (
    <div className='ta-popup__permission-types-help-box'>
      {dangerousHTML(text)}
    </div>
  )
}

export default PopupServiceAllocationsHelpBox
