import { getPath, history } from './lib'
export * from './lib'
const reducer = {}

reducer.initialState = {
  props: {}
}

reducer.handlers = (dispatch, actions) => ({
  routeChanged: route => dispatch(actions.ROUTE_CHANGED, route),
  sidebarSizeChange: sidebarSize => dispatch(actions.SIDEBAR_SIZE_CHANGE, sidebarSize),
  navigateToPath: (fullPath, replace = false) => {
    const { pathname = '', search = '' } = history.location
    const oldFullPath = pathname + search
    if (oldFullPath !== fullPath) {
      if (replace) history.replace(fullPath)
      else history.push(fullPath)
    }
  },
  navigateToRoute: (name, params, query) => {
    const { pathname = '', search = '' } = history.location
    const oldFullPath = pathname + search
    const fullPath = getPath(name, params, query)
    if (oldFullPath !== fullPath) history.push(fullPath)
  },
  addHashToCurrentRoute: (hash) => {
    const { pathname = '', search = '' } = history.location
    const oldFullPath = pathname + search
    const split = oldFullPath.split('@@')
    const noHash = split[0]
    history.push(noHash + '@@' + hash)
  },
  goBack: () => history.goBack()
})

reducer.ROUTE_CHANGED = (state, route) => ({ ...route })
reducer.SIDEBAR_SIZE_CHANGE = (state, sidebarSize) => {
  if (sidebarSize === 'M') {
    return {
      ...state,
      props: {
        ...state.props || {},
        sidebar: {
          ...state.props.sidebar,
          size: 'big'
        }
      }
    }
  } else {
    return {
      ...state,
      props: {
        ...state.props || {},
        sidebar: {
          ...state.props.sidebar,
          size: undefined
        }
      }
    }
  }
}

export default reducer
