import React from 'react'
import {
  Title,
  HintText,
  SingleSelect,
  Form,
  FormButtons,
  FormSection,
  Radio,
  Error,
  FontAwesome5,
  Button,
  Row,
  Col,
  Loader,
  BorderedBox,
  Alert,
  Checkbox,
  t
} from '../../../Common'

import './StatisticsExportForm.css'

const StatisticsExportForm = props => {
  const {
    messageExport,
    onSubmit,
    onCloseModal,
    areThereErrors,
    pending,
    exportMode,
    onFilterYearSubmit,
    OS,
    type
  } = props
  const exportBookingKey = type === 'branch' ? 'statistics.tab.bookings' : 'statistics.export.checkbox.bookingsAndCancellations'
  const classNames = ['ta-statistics-export-form']
  if (messageExport) classNames.push('success')
  if (areThereErrors) classNames.push('has-errors')

  return (
    <div className={classNames.join(' ')}>
      <Title label={t('statistics.export.title', [{ key: 'FILE_TYPE', value: OS === 'Apple' ? 'CSV' : 'XLS' }])} />
      <Loader active={pending} />
      <Form name='statisticsExport' onSubmit={onSubmit}>
        {(!!messageExport &&
          <Alert label={messageExport} theme='success' />
        )}
        {(!messageExport &&
          <>
            <FormSection>
              <Row>
                <Col size={50}>
                  <SingleSelect
                    name='year'
                    label={t('global.year.label')}
                    onChangeAddon={onFilterYearSubmit}
                    icon='calendar-alt'
                  />
                </Col>
                <Col size={50}>
                  <SingleSelect
                    name='month'
                    label={t('global.month.label')}
                    icon='calendar-alt'
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <Row>
                <Col>
                  <Radio name='exportMode' label={t('statistics.export.radio.all.label')} value='ALL' />
                  <HintText>{t('statistics.export.radio.all.hint', [{ key: 'FILE_TYPE', value: OS === 'Apple' ? 'CSV' : 'XLS' }])}</HintText>
                  <Radio name='exportMode' label={t('statistics.export.radio.some.label')} value='SOME' />
                  <HintText>{t('statistics.export.radio.some.hint')}</HintText>
                </Col>
              </Row>
              {(exportMode === 'SOME' &&
                <Row>
                  <Col>
                    <BorderedBox className='no-margin'>
                      <Row>
                        <Col>
                          <Checkbox name='exportBookings' label={t(exportBookingKey)} />

                        </Col>
                      </Row>
                      {(type === 'dashboard' &&
                        <Row>
                          <Col>
                            <Checkbox name='exportLocations' label={t('global.locations')} />
                          </Col>
                        </Row>)}
                      {(type === 'branch' &&
                        <Row>
                          <Col>
                            <Checkbox name='exportCancellations' label={t('global.cancellations')} />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox name='exportFinances' label={t('statistics.finances')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='exportResources' label={t('global.resources')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='exportCustomers' label={t('global.customers')} />
                        </Col>
                      </Row>
                    </BorderedBox>
                  </Col>
                </Row>
              )}
            </FormSection>
            <FormSection>
              <Error noOffset name='globalErrors' />
            </FormSection>
          </>
        )}
        {(!messageExport &&
          <FormButtons className={!areThereErrors && 'no-error'}>
            <Button
              as='link'
              external
              isSecondary
              onClick={onCloseModal}
            >
              {t('buttons.cancel.label')}
            </Button>
            <Button
              type='submit'
              isPrimary
              disabled={messageExport}
            >
              <FontAwesome5 icon='file-download' type='solid' /> {t('buttons.export.label', [{ key: 'FILE_TYPE', value: OS === 'Apple' ? 'CSV' : 'XLS' }])}
            </Button>
          </FormButtons>
        )}
        {(messageExport &&
          <FormButtons>
            <Button
              as='link'
              external
              className='ta-btn bold'
              block
              isSecondary
              onClick={onCloseModal}
            >
              {t('buttons.closeWindow.label')}
            </Button>
          </FormButtons>
        )}
      </Form>
    </div>
  )
}

export default StatisticsExportForm
