import React from 'react'
import { store } from '../../Store'

const Price = props => {
  const state = store.getState()
  const { staticData, account } = state || {}
  let { countries } = staticData || {}
  countries = countries || []
  const { enterprise } = account || {}
  const { locale: enterpriseLocale } = enterprise || {}
  const defaultLocale = enterpriseLocale || 'en-gb'
  const countryCode = defaultLocale.split('-')[1]
  const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
  const { currency: countryCurrency } = country || {}
  const { code: currencyCode } = countryCurrency || {}
  const defaultCurrency = currencyCode || ''

  let {
    currency,
    locale,
    price,
    digits,
    currencyStyle,
    className
  } = props
  currency = currency || defaultCurrency
  locale = locale || defaultLocale
  currencyStyle = currencyStyle || 'currency' // decimal / currency / percent
  price = price || 0
  const options = {
    style: currencyStyle,
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    currency: currency && currency.toUpperCase(),
    currencyDisplay: 'symbol'
  }
  if (!Intl) {
    window.alert('Browser support for Intl is missing.')
    return
  }
  const formatedPrice = new Intl.NumberFormat(locale, options).format(price)
  const classNames = []
  if (className) classNames.push(className)

  return <span className={classNames.join(' ')} data-testid='price'>{formatedPrice}</span>
}

export default Price
