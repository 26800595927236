import React from 'react'
import { connect, handlers } from '../../../Store'
import { SettingsServiceAllocations } from '../../../Beauties'
import { t } from '../../../Common'
import { sortByOrderIndex } from '../../../Utils'

const SettingsServiceAllocationsWrapper = props => {
  let {
    courses,
    services,
    courseCategories,
    shouldDisplayInfo,
    serviceCategories,
    serviceAllocations,
    shouldAllowBookingWidget,
    isServiceAllocationsEnabledFixedTime
  } = props
  serviceAllocations = serviceAllocations || {}
  services = services || []
  serviceCategories = serviceCategories || []
  courses = courses || []
  courseCategories = courseCategories || []
  let { values: serviceAllocationsValues } = serviceAllocations || {}
  serviceAllocationsValues = serviceAllocationsValues || []
  // services already selected
  const serviceIdsSelected = serviceAllocationsValues.reduce((allServiceIds, serviceAllocation) => {
    return allServiceIds.concat(serviceAllocation.serviceIds || [])
  }, [])
  const serviceCategoriesWithServices = serviceCategories.map(serviceCategory => ({
    name: serviceCategory.name === 'default'
      ? t('servicesGroups.list.service.defaultCategoryName')
      : serviceCategory.name,
    items: services.filter(service => service.categoryId === serviceCategory.id).map(service => ({ value: service.id, label: service.name }))
  })).filter(category => category.items.length > 0)
  const courseCategoriesWithServices = courseCategories.map(courseCategory => ({
    name: courseCategory.name === 'default'
      ? t('servicesGroups.list.group.defaultCategoryName')
      : courseCategory.name,
    items: courses.filter(service => service.categoryId === courseCategory.id).map(service => ({ value: service.id, label: service.name }))
  })).filter(category => category.items.length > 0)
  const servicesAndCoursesCategoriesWithItems = [...serviceCategoriesWithServices, ...courseCategoriesWithServices]
  const servicesAllocationsTotalPercentage = serviceAllocationsValues.reduce((acc, serviceAllocation) => {
    let { percentage } = serviceAllocation
    percentage = parseFloat(percentage || 0)
    return acc + percentage
  }, 0)

  const hideErrors = (id, type) => {
    handlers.formFieldsUpdate('bookingSettings', {
      [id ? `serviceAllocation${id}${type}Errors` : `serviceAllocation${type}Errors`]: {
        errors: [] // remove errors on change
      }
    })
  }

  const onServiceAllocationAdd = () => {
    if (servicesAllocationsTotalPercentage >= 100) return
    handlers.formFieldsUpdate('bookingSettings', {
      serviceAllocations: {
        values: [
          ...serviceAllocationsValues,
          {
            id: Math.random(), // needed for unique keys in form map method, is deleted in formSave
            serviceIds: [],
            percentage: 0
          }
        ]
      }
    })
    hideErrors(null, 'Count')
  }

  const onServiceAllocationDelete = index => {
    handlers.formFieldsUpdate('bookingSettings', {
      serviceAllocations: {
        values: [...serviceAllocationsValues.filter((item, key) => key !== index)]
      }
    })
  }

  const onServiceAllocationServicesChange = (serviceOptions, index, isDelete) => {
    if (!serviceAllocationsValues[index]) return
    const newAllocations = [...serviceAllocationsValues].map(item => ({ ...item }))
    if (isDelete) {
      newAllocations[index].serviceIds = [...newAllocations[index].serviceIds].filter(item => item !== serviceOptions) || []
    } else {
      const newServiceIds = serviceOptions.map(serviceOption => {
        const { value: serviceId } = serviceOption || {}
        return serviceId
      }).filter(serviceId => serviceId !== 'all')
      newAllocations[index].serviceIds = newServiceIds || []
    }
    handlers.formFieldsUpdate('bookingSettings', {
      serviceAllocations: {
        values: [...newAllocations]
      }
    })
    hideErrors(newAllocations[index].id, 'Service')
  }

  const onServiceAllocationPercentageChange = (value, index) => {
    value = value === '' ? 0 : parseFloat(value)
    if (isNaN(value) || value < 0 || value > 99) return
    const newAllocations = [...serviceAllocationsValues].map(item => ({ ...item }))
    newAllocations[index].percentage = value
    handlers.formFieldsUpdate('bookingSettings', {
      serviceAllocations: {
        values: [...newAllocations]
      }
    })
    hideErrors(newAllocations[index].id, 'Percent')
  }

  const onServiceAllocationNameChange = (value, index) => {
    const newAllocations = [...serviceAllocationsValues].map(item => ({ ...item }))
    newAllocations[index].name = value
    handlers.formFieldsUpdate('bookingSettings', {
      serviceAllocations: {
        values: [...newAllocations]
      }
    })
    hideErrors(newAllocations[index].id, 'Name')
  }

  const onServiceAllocationFixedTimeChange = (value) => {
    value = value === '' ? 0 : parseFloat(value)
    if (isNaN(value) || value < 0) return
    handlers.formFieldsUpdate('bookingSettings', {
      serviceAllocationsEnabledFixedTimeLeadTime: {
        value
      }
    })
    hideErrors(null, 'SuspendTime')
  }

  const onSettingsChangeAddon = value => {
    if ((!shouldDisplayInfo && !value) || (!shouldAllowBookingWidget && !value)) {
      handlers.formFieldsUpdate('bookingSettings', {
        serviceAllocationsEnabledFixedTimeLeadTime: {
          value: 0
        }
      })
      handlers.formFieldsUpdate('bookingSettings', {
        isServiceAllocationsEnabledFixedTime: {
          value: false
        }
      })
    }
  }

  return (
    <SettingsServiceAllocations
      serviceIdsSelected={serviceIdsSelected}
      serviceAllocations={serviceAllocationsValues}
      servicesAllocationsTotalPercentage={servicesAllocationsTotalPercentage}
      servicesAndCoursesCategoriesWithItems={servicesAndCoursesCategoriesWithItems}
      isServiceAllocationsEnabledFixedTime={isServiceAllocationsEnabledFixedTime}
      shouldDisplayInfo={shouldDisplayInfo}
      shouldAllowBookingWidget={shouldAllowBookingWidget}
      hideErrors={hideErrors}
      onSettingsChangeAddon={onSettingsChangeAddon}
      onServiceAllocationAdd={onServiceAllocationAdd}
      onServiceAllocationDelete={onServiceAllocationDelete}
      onServiceAllocationServicesChange={onServiceAllocationServicesChange}
      onServiceAllocationPercentageChange={onServiceAllocationPercentageChange}
      onServiceAllocationNameChange={onServiceAllocationNameChange}
      onServiceAllocationFixedTimeChange={onServiceAllocationFixedTimeChange}
    />
  )
}

const maps = state => ({
  serviceAllocations: state.forms.bookingSettings.serviceAllocations,
  hasServiceAllocations: state.forms.bookingSettings.hasServiceAllocations && state.forms.bookingSettings.hasServiceAllocations.value,
  isServiceAllocationsEnabledFixedTime: state.forms.bookingSettings.isServiceAllocationsEnabledFixedTime && state.forms.bookingSettings.isServiceAllocationsEnabledFixedTime.value,
  shouldDisplayInfo: state.forms.bookingSettings.shouldDisplayInfo && state.forms.bookingSettings.shouldDisplayInfo.value,
  shouldAllowBookingWidget: state.forms.bookingSettings.shouldAllowBookingWidget && state.forms.bookingSettings.shouldAllowBookingWidget.value,
  services: (state.services.list || []).sort(sortByOrderIndex),
  serviceCategories: state.services.categoriesList,
  courses: (state.courses.list || []).sort(sortByOrderIndex),
  courseCategories: state.courses.categoriesList
})

export default connect(maps)(SettingsServiceAllocationsWrapper)
