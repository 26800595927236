import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { BranchesForm } from '../../Beauties'
import { sortAlphabetically } from '../../Utils'
import { ALLOWED_REGIONS } from '../../Settings'

class BranchesEditWrapper extends Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount () {
    const { id } = this.props

    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.branchesFormGet({ id })
  }

  onSubmit (cb) {
    const { id, form } = this.props
    form.id = id

    handlers.branchesFormSaveEdit(form, (errors) => {
      cb && cb(errors)
    })
  }

  render () {
    const {
      pending,
      tags,
      branch,
      locales,
      timezones,
      form,
      id,
      enterpriseRegion
    } = this.props
    const locale = form.locale ? form.locale.value : null

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <BranchesForm
            branch={branch}
            title={t('global.location')}
            type='edit'
            cancelLink={`/branches/${id}/preview`}
            onSubmit={this.onSubmit}
            tags={tags}
            locale={locale}
            locales={locales}
            timezones={timezones}
            enterpriseRegion={ALLOWED_REGIONS.includes(enterpriseRegion) ? null : enterpriseRegion}
          />
        )}
      </>
    )
  }
}

BranchesEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  id: state.router.data.id,
  form: state.forms.branches || {},
  pending: !!(state.branches && state.branches.pendingForm),
  tags: (state.tags && state.tags.list.sort(sortAlphabetically)) || [],
  locales: state.staticData.locales || [],
  timezones: state.staticData.timezones || [],
  enterpriseRegion: (state.account && state.account.enterprise && state.account.enterprise.region) || 'EUROPE'
})

export default connect(maps)(BranchesEditWrapper)
