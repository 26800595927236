import { bookingSettingsFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: true,
  pending: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  bookingSettingsReset: () => dispatch(actions.BOOKING_SETTINGS_RESET),
  // Form
  bookingSettingsFormGet: () => dispatch(actions.BOOKING_SETTINGS_FORM_GET),
  bookingSettingsFormPopulate: settings => {
    handlers.formSet('bookingSettings', bookingSettingsFormTransform(settings))
    handlers.bookingSettingsFormReady()
  },
  bookingSettingsFormReady: () => dispatch(actions.BOOKING_SETTINGS_FORM_READY),
  bookingSettingsFormSave: (settings, scrollToError = () => {}) => dispatch(actions.BOOKING_SETTINGS_FORM_SAVE, { settings, scrollToError }),
  // Preview

  bookingSettingsPreviewGet: () => dispatch(actions.SETTINGS_PREVIEW_GET),
  bookingSettingsPreviewReady: () => dispatch(actions.SETTINGS_PREVIEW_READY)

})

reducer.BOOKING_SETTINGS_RESET = state => reducer.initialState

reducer.BOOKING_SETTINGS_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.BOOKING_SETTINGS_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.BOOKING_SETTINGS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

// Preview

reducer.BOOKING_SETTINGS_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true
})

reducer.BOOKING_SETTINGS_PREVIEW_READY = state => ({
  ...state,
  pendingPreview: false
})

export default reducer
