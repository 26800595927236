import React from 'react'
import {
  SimpleListItem,
  SimpleListItemTitle,
  FontAwesome5,
  t
} from '../../Common'

const BillingPreviewMethodPayPal = props => {
  const { data, isDefault } = props
  const { email } = data || {}

  return (
    <SimpleListItem status={isDefault ? 'active' : ''} className='payment-method paypal'>
      <SimpleListItemTitle title={email}>
        {(isDefault &&
          <span className='ta-billing__preview__method-primary'>{t('billing.list.section.paymentDetails.tabPrimary.label')}</span>
        )}
      </SimpleListItemTitle>
      <FontAwesome5 icon='cc-paypal' type='brand' className='ta-billing__preview__method-icon' />
    </SimpleListItem>
  )
}

export default BillingPreviewMethodPayPal
