import React from 'react'
import { handlers } from '../../../Store'
import { PopupBranchServiceDelete } from '../../../Beauties'

export const PopupBranchServiceDeleteWrapper = props => {
  const approve = () => {
    const { id, closePopup } = props
    if (id) handlers.branchServiceDelete(id)
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupBranchServiceDelete approve={approve} reject={reject} />
  )
}

export default PopupBranchServiceDeleteWrapper
