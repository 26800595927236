import React, { useEffect, useRef } from 'react'
import Row from '../Grid/Row'
import Col from '../Grid/Col'

const FixedContentHeader = props => {
  const { children, addHeaderRef, className, theme } = props
  const ref = useRef(null)

  useEffect(() => {
    if (addHeaderRef) addHeaderRef(ref)
  }, [addHeaderRef])

  const classNames = ['ta-fixed-content__header']
  if (theme === 'blank') classNames.push('header__blank')
  if (className) classNames.push(className)

  return (
    <Row>
      <Col>
        <div className={classNames.join(' ')} ref={ref}>{children}</div>
      </Col>
    </Row>
  )
}

export default FixedContentHeader
