import React from 'react'
import {
  Title,
  Button,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  Checkbox,
  Error,
  t,
  Alert,
  BorderedBox,
  Row,
  Col,
  Radio,
  HintText
} from '../../Common'

const keysList = [
  { key: 'externalId', locale: 'global.externalId', name: 'externalId' },
  { key: 'serviceName', locale: 'globalSettings.form.section.attributes.serviceName', name: 'name' },
  { key: 'serviceColor', locale: 'globalSettings.form.section.attributes.serviceColor', name: 'color' },
  { key: 'serviceDescription', locale: 'globalSettings.form.section.attributes.serviceDescription', name: 'description' },
  { key: 'combinationServiceIds', locale: 'globalSettings.form.section.attributes.serviceSelection', name: 'combinationServiceIds' },
  { key: 'durationSplitInterval', locale: 'globalSettings.form.section.attributes.duration', name: 'durationsPattern' },
  { key: 'preparationTime', locale: 'globalSettings.form.section.attributes.preparationTime', name: 'durationBefore' },
  { key: 'followupTime', locale: 'globalSettings.form.section.attributes.followup', name: 'durationAfter' },
  { key: 'resourcesAndDependencies', locale: 'globalSettings.form.section.attributes.resources', name: 'dependencies' },
  { key: 'price', locale: 'globalSettings.form.section.attributes.price', name: 'price' },
  { key: 'hasCombinationSameResourcesPreference', locale: 'globalSettings.form.section.attributes.assignToSameResource', name: 'hasCombinationSameResourcesPreference' },
  { key: 'settingDisplayInBookingWidget', locale: 'globalSettings.form.section.attributes.displayInBookingWidget', name: 'isBookable' },
  { key: 'settingOnlinePayment', locale: 'globalSettings.form.section.attributes.onlinePayment', name: 'hasOnlinePayment' },
  { key: 'settingCheckboxToMakeOnlinePaymentMandatory', locale: 'globalSettings.form.section.attributes.onlinePaymentMandatory', name: 'isPaymentMandatory' },
  { key: 'customerEmailReminders', locale: 'globalSettings.form.section.attributes.customerEmailReminders', name: 'customerEmailRemindersMinutes' },
  { key: 'resourceEmailReminders', locale: 'globalSettings.form.section.attributes.resourceEmailReminders', name: 'resourceEmailRemindersMinutes' }
]

const ServiceChangedLocallyRestore = props => {
  const { title, onSubmit, cancelLink, form, branch, service } = props
  const { restoreOptions } = form || {}
  const isCombination = service.combinationServiceIds && !!service.combinationServiceIds.length
  const localChanges = keysList.filter(({ name }) => Object.keys(service?.locallyUpdatedBranches?.[branch?.id] || {}).includes(name))

  return (
    <div className='ta-globals-settings__form'>
      <FixedContent>
        <FixedContentHeader>
          <Title label={title} />
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='serviceChangedLocally'>
            <Alert theme='alert'>
              {branch ? t('globals.reverseWarning.message') : t('globals.reverseWarning.all.message')}
            </Alert>
            <Row>
              <Col>
                <Radio name='restoreOptions' value='ALL' label={t('globals.form.attributes.resetAll.label')} />
                <HintText className='ta-globals__hint'>
                  {t('globals.form.attributes.resetAll.hint', [
                    { key: 'ELEMENT', value: t('global.service') }
                  ])}
                </HintText>
              </Col>
            </Row>
            <Row>
              <Col>
                <Radio name='restoreOptions' value='SOME' label={t('globals.form.attributes.resetSome.label')} />
                <HintText className='ta-globals__hint'>
                  {t('globals.form.attributes.resetSome.hint', [
                    { key: 'ELEMENT', value: t('global.service') }
                  ])}
                </HintText>
              </Col>
            </Row>
            {restoreOptions?.value === 'SOME' && (
              <BorderedBox className='global-settings_attributes'>
                {branch
                  ? (
                    <>
                      {localChanges.map(({ key, name, locale }) => (
                        <Row>
                          <Col>
                            <Checkbox key={name} name={key} label={t(locale)} />
                          </Col>
                        </Row>
                      ))}
                    </>
                  )
                  : (
                    <>
                      <Row>
                        <Col>
                          <Checkbox name='externalId' label={t('global.externalId')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='serviceName' label={t('globalSettings.form.section.attributes.serviceName')} />
                        </Col>
                      </Row>
                      {(!isCombination &&
                        <Row>
                          <Col>
                            <Checkbox name='serviceColor' label={t('globalSettings.form.section.attributes.serviceColor')} />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox name='serviceDescription' label={t('globalSettings.form.section.attributes.serviceDescription')} />
                        </Col>
                      </Row>
                      {(isCombination &&
                        <Row>
                          <Col>
                            <Checkbox name='combinationServiceIds' label={t('globalSettings.form.section.attributes.serviceSelection')} />
                          </Col>
                        </Row>
                      )}
                      {(!isCombination &&
                        <Row>
                          <Col>
                            <Checkbox name='durationSplitInterval' label={t('globalSettings.form.section.attributes.duration')} />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox name='preparationTime' label={t('globalSettings.form.section.attributes.preparationTime')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='followupTime' label={t('globalSettings.form.section.attributes.followup')} />
                        </Col>
                      </Row>
                      {(!isCombination &&
                        <Row>
                          <Col>
                            <Checkbox name='resourcesAndDependencies' label={t('globalSettings.form.section.attributes.resources')} />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox name='price' label={t('globalSettings.form.section.attributes.price')} />
                        </Col>
                      </Row>
                      {(isCombination &&
                        <Row>
                          <Col>
                            <Checkbox name='hasCombinationSameResourcesPreference' label={t('globalSettings.form.section.attributes.assignToSameResource')} />
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Checkbox name='settingDisplayInBookingWidget' label={t('globalSettings.form.section.attributes.displayInBookingWidget')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='settingOnlinePayment' label={t('globalSettings.form.section.attributes.onlinePayment')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='settingCheckboxToMakeOnlinePaymentMandatory' label={t('globalSettings.form.section.attributes.onlinePaymentMandatory')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='customerEmailReminders' label={t('globalSettings.form.section.attributes.customerEmailReminders')} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Checkbox name='resourceEmailReminders' label={t('globalSettings.form.section.attributes.resourceEmailReminders')} />
                        </Col>
                      </Row>
                    </>
                  )}
              </BorderedBox>
            )}
            <FormSection>
              <Error name='globalErrors' />
            </FormSection>
            <FormButtons>
              <Button as='link' to={cancelLink} isSecondary>{t('buttons.cancel.label')}</Button>
              <Button
                disabled={
                  restoreOptions?.value === 'SOME' &&
                  !keysList.filter(({ key }) => !!form[key]?.value).length
                }
                type='submit'
                isPrimary
              >
                {t('global.restore')}
              </Button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default ServiceChangedLocallyRestore
