import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors, store } from '../../Store'
import { Login } from '../../Beauties'

class LoginWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onLanguageChange = this.onLanguageChange.bind(this)
  }

  componentDidMount () {
    handlers.loginFormPopulate()
  }

  onSubmit (cb) {
    const form = store.getState().forms.login
    handlers.loginWithEmailAndPassword(form, (errors) => {
      cb && cb(errors)
    })
  }

  onLanguageChange () {
    const { router } = this.props
    const { query } = router || {}
    const {
      redirect,
      companyId,
      externalCompanyId,
      accountId,
      externalAccountId,
      enterpriseSlug,
      enterpriseId,
      customerId,
      externalCustomerId
    } = query || {}
    const form = store.getState().forms.login
    const params = [`locale=${form.language.value}`]
    if (redirect) params.push(`redirect=${encodeURIComponent(redirect)}`)
    if (companyId) params.push(`companyId=${companyId}`)
    if (externalCompanyId) params.push(`externalCompanyId=${externalCompanyId}`)
    if (accountId) params.push(`accountId=${accountId}`)
    if (externalAccountId) params.push(`externalAccountId=${externalAccountId}`)
    if (enterpriseSlug) params.push(`enterpriseSlug=${enterpriseSlug}`)
    if (enterpriseId) params.push(`enterpriseId=${enterpriseId}`)
    if (customerId) params.push(`customerId=${customerId}`)
    if (externalCustomerId) params.push(`externalCustomerId=${externalCustomerId}`)
    handlers.navigateToPath(`/login?${params.join('&')}`)

    // const form = store.getState().forms.login

    // handlers.navigateToPath(`/login?locale=${form.language.value}`)
  }

  togglePasswordFieldType () {
    const form = store.getState().forms.login
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }

    handlers.formFieldsUpdate('login', { password })
    return password.type
  }

  render () {
    const {
      pending,
      pendingOneTimeToken,
      pendingLanguage,
      passwordFieldType,
      locale,
      customisations
    } = this.props
    const { showAutocompleteLoginPage } = customisations?.payload?.settings || {}

    return (
      <Login
        locale={locale}
        pendingLanguage={pendingLanguage}
        pending={pending || pendingOneTimeToken}
        passwordFieldType={passwordFieldType}
        onSubmit={this.onSubmit}
        togglePasswordFieldType={this.togglePasswordFieldType}
        onLanguageChange={this.onLanguageChange}
        showAutocompleteLoginPage={showAutocompleteLoginPage}
      />
    )
  }
}

LoginWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  passwordFieldType: PropTypes.string.isRequired
}

const maps = state => ({
  locale: (state.forms.login && state.forms.login.language && state.forms.login.language.value) || '',
  router: state.router || {},
  pendingLanguage: !!(state.app && state.app.pendingLanguage),
  pending: state.auth.pendingForm,
  pendingOneTimeToken: state.auth.pendingOneTimeToken,
  passwordFieldType: (state.forms.login && state.forms.login.password && state.forms.login.password.type) || 'password',
  customisations: selectors.companyCustomisationsSelector(state)
})

export default connect(maps)(LoginWrapper)
