import { payloads$, actions, handlers, store, selectors } from '../../../Store'
import { q } from '../../API'

payloads$(actions.PAYMENT_METHODS_GET)
  .subscribe(async () => {
    const paymentMethods = await q('getEnterprisePaymentMethods')
    const { error } = paymentMethods || { error: {} }
    if (error) return handlers.paymentMethodsPopulate([])
    const state = store.getState()
    handlers.paymentMethodsPopulate(paymentMethods)
    const routerName = selectors.routerFieldSelector(state, { field: 'name' })
    const paymentMethod = selectors.formFieldPropertySelector(state, { formName: 'appSubscriptionBilling', name: 'paymentMethod', property: 'value' })
    const defaultPaymentMethod = paymentMethods.length > 0
      ? paymentMethods.find(item => item.isDefault)
      : {}
    if ((!paymentMethod || paymentMethod !== defaultPaymentMethod) && ['appsSubscriptionAdd', 'appsSubscriptionCancelled'].includes(routerName)) {
      handlers.formFieldsUpdate('appSubscriptionBilling', {
        paymentMethod: {
          value: defaultPaymentMethod.id
        }
      })
    }
  })
