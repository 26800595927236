import React, { useEffect, useState, memo } from 'react'
import { connect, handlers } from '../../Store'
import { Loader, isRouteDisabledByCustomisations } from '../../Common'
import { BranchesResourceForm } from '../../Beauties'

const BranchesPreviewResourcesEditWrapper = props => {
  let {
    id,
    branches,
    branchId,
    pending,
    form,
    isDependency,
    showExternalIds
  } = props
  branches = branches || []
  pending = !!pending
  form = form || {}
  showExternalIds = !!showExternalIds
  const branch = branches.find(item => item.id === branchId) || {}
  const branchResources = (branch.resources && branch.resources.resources) || []
  const customisations = (branch && branch.webappCustomisation) || {}
  const plan = (branch && branch.plan) || 'CLASSIC'
  const hasStripeConnected = branch.hasStripeConnected || false
  const resource = branchResources.find(resource => resource.id === id)
  let { providers } = resource || {}
  providers = providers || []
  const timezone = (branch && branch.timezone) || ''

  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    const { id } = initial
    handlers.branchesResourceFormGet(id)
  }, [initial])

  const onSubmit = cb => {
    handlers.branchesResourceFormSave(branch, form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <BranchesResourceForm
          id={id}
          type='edit'
          cancelLink={`/branches/${branchId}/preview@@resources`}
          branch={branch}
          onSubmit={onSubmit}
          plan={plan}
          isDependency={isDependency}
          showExternalIds={showExternalIds}
          customisations={customisations}
          hasStripeConnected={hasStripeConnected}
          providers={providers}
          timezone={timezone}
        />
      )}
    </>
  )
}

const maps = state => ({
  id: state.router.data.id,
  branches: state.branches.list,
  branchId: state.router.data.branchId,
  pending: state.branches.pendingForm,
  isDependency: state.forms.branchesResource.isDependency,
  form: state.forms.branchesResource,
  showExternalIds: state.company.settings.showExternalIds
})

export default memo(connect(maps)(BranchesPreviewResourcesEditWrapper))
