import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { CustomerFieldCategoryForm } from '../../Beauties'

const CustomerFieldCategoryAddWrapper = props => {
  let {
    pending,
    form,
    showExternalIds,
    allowOnlyGlobalCustomers,
    isAdmin,
    branchesAccessBlacklist
  } = props
  pending = !!pending
  form = form || {}
  showExternalIds = !!showExternalIds
  allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers

  // ComponentDidMount
  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByPermissions()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.customerFieldCategoryFormGet()
  }, [])

  const onSubmit = cb => {
    handlers.customerFieldCategoryFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return pending
    ? <Loader active />
    : <CustomerFieldCategoryForm
        title={t('customerFields.form.addCategory.heading')}
        type='add'
        cancelLink='/customers/customer-fields'
        onSubmit={onSubmit}
        showExternalIds={showExternalIds}
        allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
        isAdmin={isAdmin}
        branchesAccessBlacklist={branchesAccessBlacklist}
        location={form.location && form.location.values}
      />
}

CustomerFieldCategoryAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  pending: state.customerFields.pendingCategoryForm,
  form: state.forms.customerFieldCategory,
  showExternalIds: state.company.settings.showExternalIds,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers,
  isAdmin: state.account.isAdmin,
  branchesAccessBlacklist: state.account.enterprisePermissions && state.account.enterprisePermissions.branchesAccessBlacklist
})

export default connect(maps)(CustomerFieldCategoryAddWrapper)
