import React, { useState, useEffect } from 'react'

const Countdown = ({ date, onCountdownEnd }) => {
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, min: 0, sec: 0 })

  const calculateCountdown = (endDate) => {
    const currentTime = new Date().getTime()
    const endTime = new Date(endDate).getTime()
    let diff = (endTime - currentTime) / 1000

    if (diff <= 0) return false

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    }

    const timeUnits = {
      years: 365.25 * 86400, // 365.25 * 24 * 60 * 60
      days: 86400, // 24 * 60 * 60
      hours: 3600, // 60 * 60
      min: 60
    }

    Object.keys(timeUnits).forEach((unit) => {
      if (diff >= timeUnits[unit]) {
        timeLeft[unit] = Math.floor(diff / timeUnits[unit])
        diff -= timeLeft[unit] * timeUnits[unit]
      }
    })

    timeLeft.sec = Math.floor(diff)

    return timeLeft
  }

  const stop = (interval) => {
    clearInterval(interval)
  }

  const addLeadingZeros = (value = '') => {
    return value.toString().length === 1 ? `0${value}` : value
  }

  useEffect(() => {
    const timeLeft = calculateCountdown(date)
    setCountdown(timeLeft || { days: 0, hours: 0, min: 0, sec: 0 })

    if (!timeLeft) {
      if (typeof onCountdownEnd === 'function') {
        onCountdownEnd()
      }
      return
    }

    const interval = setInterval(() => {
      const updatedTimeLeft = calculateCountdown(date)
      setCountdown(updatedTimeLeft || { days: 0, hours: 0, min: 0, sec: 0 })

      if (!updatedTimeLeft) {
        stop(interval)
        if (typeof onCountdownEnd === 'function') {
          onCountdownEnd()
        }
      }
    }, 1000)

    return () => {
      stop(interval)
    }
  }, [date, onCountdownEnd])

  const { days, hours, min, sec } = countdown

  return (
    <div className='ta-countdown'>
      {(days > 0 &&
        <span className='ta-countdown__days' data-testid='countdown-days'>{addLeadingZeros(days)}:</span>
      )}
      <span className='ta-countdown__hours' data-testid='countdown-hours'>{addLeadingZeros(hours)}:</span>
      <span className='ta-countdown__min' data-testid='countdown-min'>{addLeadingZeros(min)}:</span>
      <span className='ta-countdown__sec' data-testid='countdown-sec'>{addLeadingZeros(sec)}</span>
    </div>
  )
}

export default Countdown
