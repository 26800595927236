import React, { useEffect } from 'react'
import { CustomerFieldsToDelete } from '../../Beauties'
import { connect, handlers } from '../../Store'
import { Loader } from '../../Common'

const CustomerFieldsToDeleteWrapper = props => {
  let { customerFieldsToDelete, branches, pending, account } = props
  customerFieldsToDelete = customerFieldsToDelete || []
  branches = branches || []
  pending = !!pending
  account = account || {}
  let { isAdmin, enterprisePermissions } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { globalDataFieldsRead, globalDataFieldsWrite } = enterprisePermissions || {}
  globalDataFieldsRead = !!isAdmin || !!globalDataFieldsRead
  globalDataFieldsWrite = !!isAdmin || !!globalDataFieldsWrite

  useEffect(() => {
    if (!globalDataFieldsRead) handlers.navigateToPath('/customers/customer-fields')
  }, [globalDataFieldsRead])

  const onDeleteGlobalEntity = (companyId, region, externalId, id) => handlers.customerFieldToDeleteDelete({ companyId, region, externalId, id })
  const listToDelete = customerFieldsToDelete.map(item => ({
    customerField: { ...item },
    branch: branches.find(branch => branch.id === item.companyId)
  }))

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <CustomerFieldsToDelete
          customerFieldsToDeleteData={listToDelete}
          onDeleteGlobalEntity={onDeleteGlobalEntity}
          globalDataFieldsWrite={globalDataFieldsWrite}
        />
      )}
    </>
  )
}

const maps = state => ({
  branches: state.branches.list,
  customerFieldsToDelete: state.customerFields.listToDelete,
  pending: state.customerFields.pendingToDeleteList,
  account: state.account
})

export default connect(maps)(CustomerFieldsToDeleteWrapper)
