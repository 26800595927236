import React from 'react'
import { BorderedBox, Col, Row, t } from '../../../Common'
import { UserPermissionTypeAccordion } from '../../../Beauties'
import './UserPermissionTypeSummaryPermissions.css'

const getPermissionTag = permission => {
  return permission
    ? (
      <div className='ta-permission-types__summary__permission__tag ta-permission-types__summary__permission__tag__yes'>
        {t('global.yes')}
      </div>
      )
    : (
      <div className='ta-permission-types__summary__permission__tag ta-permission-types__summary__permission__tag__no'>
        {t('global.no')}
      </div>
      )
}

const UserPermissionTypeSummaryPermissions = props => {
  let {
    permissions,
    branches,
    isLogedInUserAdmin,
    canLogedInUserReadUsers,
    isAdminPermission
  } = props
  permissions = permissions || {}
  let {
    // Account
    accountRead,
    // Statistics
    statisticsRead,
    // Branches
    branchesRead,
    branchesWrite,
    branchesDelete,
    allowFulfilment,
    branchesAccessWhitelist,
    branchesAccessBlacklist,
    // Tags
    tagsRead,
    tagsWrite,
    tagsDelete,
    // Customers
    globalCustomersRead,
    globalCustomersWrite,
    globalCustomersDelete,
    // DataFields
    globalDataFieldsRead,
    globalDataFieldsWrite,
    globalDataFieldsDelete,
    // Tags
    globalTagsRead,
    globalTagsWrite,
    globalTagsDelete,
    // Services
    globalServicesRead,
    globalServicesWrite,
    globalServicesDelete,
    // Groups
    globalGroupsRead,
    globalGroupsWrite,
    globalGroupsDelete,
    // Resource categories
    globalResourceCategoriesRead,
    globalResourceCategoriesWrite,
    globalResourceCategoriesDelete,
    // Global Permission groups
    globalPermissionsGroupsRead,
    globalPermissionsGroupsWrite,
    globalPermissionsGroupsDelete,
    // Settings
    settingsRead,
    settingsWrite,
    // Apps
    appsRead,
    // Users
    usersRead,
    usersWrite,
    usersDelete,
    // Permission groups
    permissionsGroupsRead,
    permissionsGroupsWrite,
    permissionsGroupsDelete
  } = permissions || {}
  branches = branches || []
  branchesAccessWhitelist = branchesAccessWhitelist || []
  branchesAccessBlacklist = branchesAccessBlacklist || []
  const appsWrite = !!isAdminPermission
  const insufficientWhiteListBranchesCount = branchesAccessWhitelist.filter(branch => !branches.find(({ id }) => id === branch)).length
  const insufficientBlackListBranchesCount = branchesAccessBlacklist.filter(branch => !branches.find(({ id }) => id === branch)).length

  return (
    <div className='ta-permission-types__summary__permissions'>
      <UserPermissionTypeAccordion title={t('global.account')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(accountRead)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.statisticsDashboard')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(statisticsRead)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.branches')}>
        <div className='ta-user-permissions__form__acl__row__wrapper'>
          <div className='ta-permission-types__summary__permission__title'>
            {t('global.read')}
            {getPermissionTag(branchesRead)}
          </div>
          {(branchesRead && (isLogedInUserAdmin || canLogedInUserReadUsers) &&
            <BorderedBox>
              {(!(branchesAccessBlacklist && !!branchesAccessBlacklist.length) &&
                <>
                  <Row>
                    <Col>
                      <div className='ta-permission-types__summary__custom__permission__title'>{t('userPermissionTypeSummary.allowedBranches.title')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-permission-types__summary__custom__permission__tag__container'>
                        {(!branchesAccessWhitelist.length &&
                          <div className='ta-permission-types__summary__custom__permission__tag'>{t('global.all')}</div>
                        )}
                        {(!!branchesAccessWhitelist.length &&
                          <>
                            {(branchesAccessWhitelist
                              .filter(branch => !!branches.find(({ id }) => id === branch))
                              .map(branch => (
                                <div key={branch} className='ta-permission-types__summary__custom__permission__tag'>
                                  {branches.find(({ id }) => id === branch).name}
                                </div>
                              ))
                            )}
                            {(!!insufficientWhiteListBranchesCount &&
                              <div className='ta-permission-types__summary__custom__permission__tag'>
                                {insufficientWhiteListBranchesCount} {t('global.insufficientPermissions')}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {(!!branchesAccessBlacklist.length &&
                <>
                  <Row>
                    <Col>
                      <div className='ta-permission-types__summary__custom__permission__title'>{t('userPermissionTypeSummary.deniedBranches.title')}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className='ta-permission-types__summary__custom__permission__tag__container'>
                        {(branchesAccessBlacklist
                          .filter(branch => !!branches.find(({ id }) => id === branch))
                          .map(branch => (
                            <div key={branch} className='ta-permission-types__summary__custom__permission__tag'>
                              {branches.find(({ id }) => id === branch).name}
                            </div>
                          ))
                        )}
                        {(!!insufficientBlackListBranchesCount &&
                          <div className='ta-permission-types__summary__custom__permission__tag'>
                            {insufficientBlackListBranchesCount} {t('global.insufficientPermissions')}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </BorderedBox>
          )}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(branchesWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.branchLogin')}
          {getPermissionTag(allowFulfilment)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(branchesDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.branchTags')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(tagsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(tagsWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(tagsDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalCustomers')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalCustomersRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalCustomersWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalCustomersDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalCustomerFields')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalDataFieldsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalDataFieldsWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalDataFieldsDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalTags')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalTagsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalTagsWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalTagsDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalServices')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalServicesRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalServicesWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalServicesDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalGroups')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalGroupsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalGroupsWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalGroupsDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('userPermissionTypes.globalResourceCategories')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalResourceCategoriesRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalResourceCategoriesWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalResourceCategoriesDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('permissionTypes.global.label')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(globalPermissionsGroupsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(globalPermissionsGroupsWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(globalPermissionsGroupsDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.settings')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(settingsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(settingsWrite)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.apps')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(appsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(appsWrite)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.users')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(usersRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(usersWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(usersDelete)}
        </div>
      </UserPermissionTypeAccordion>
      <UserPermissionTypeAccordion title={t('global.userPermissionTypes')}>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(permissionsGroupsRead)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(permissionsGroupsWrite)}
        </div>
        <div className='ta-permission-types__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(permissionsGroupsDelete)}
        </div>
      </UserPermissionTypeAccordion>
    </div>
  )
}

export default UserPermissionTypeSummaryPermissions
