import { getFirstDayOfWeek } from '../Utils'

export default (days) => {
  const availableDays = [...days]
  const firstDayOfWeek = getFirstDayOfWeek()
  availableDays.sort()
  if (firstDayOfWeek === 1 && availableDays.indexOf(0) > -1) {
    availableDays.push(7)
    availableDays.shift()
  }

  return availableDays
}
