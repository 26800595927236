import React from 'react'

const IconListItemTitle = props => {
  const { children, width, withBorder } = props
  const styles = {}
  if (width) styles.width = width
  const classNames = ['ta-icon-list__title']
  if (withBorder) classNames.push('with-border')

  return (
    <div className={classNames.join(' ')} style={styles}>
      {children}
    </div>
  )
}

export default IconListItemTitle
