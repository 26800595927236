import React from 'react'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Title,
  Blur,
  FontAwesome5,
  SimpleListItemTitle,
  SimpleListItem,
  SimpleListItemContent,
  t
} from '../../Common'

import './CustomerFields.css'

const CustomerFieldsMock = props => {
  const classNames = ['ta-customer-fields']

  const customerFields = [
    {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '1',
      isActive: true,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 1',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'TEXT'
    }, {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '2',
      isActive: true,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 2',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'SELECT'
    }, {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '3',
      isActive: true,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 3',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'CHECKBOX'
    }, {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '4',
      isActive: true,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 4',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'SELECT'
    }, {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '5',
      isActive: true,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 5',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'TEXTAREA'
    }, {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '6',
      isActive: false,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 6',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'CHECKBOX'
    }, {
      categoryId: '1',
      defaultId: null,
      hasOverwrite: false,
      id: '7',
      isActive: false,
      isAfterAuth: false,
      isDefault: null,
      isMandatoryOffline: true,
      isMandatoryOnline: true,
      isPublished: true,
      label: 'My field 7',
      orderIndex: 1,
      selectOptions: null,
      specificCourseIds: null,
      specificServiceIds: [],
      translationKey: null,
      type: 'SELECT'
    }
  ]

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <Title label={t('global.customerFields')} />
        </FixedContentHeader>
        <FixedContentBody>
          <Blur label={t('global.accessDenied')} icon='ban' active>
            <div className='ta-customer-fields-mock-container'>
              <div className='ta-customer-fields__category'>
                <Title
                  size='m'
                  label='Category name'
                  extraText='(default)'
                  icon='folder'
                />
                <div className='ta-customer-fields__list'>
                  {customerFields.length > 0 && customerFields.map((customerField, index) => (
                    <div className={classNames.join(' ')} key={index}>

                      <SimpleListItem
                        status={customerField.isPublished ? 'active' : (customerField.isActive ? 'title-booking' : 'inactive')}
                        draggable={false}
                      >
                        <SimpleListItemTitle title={customerField.label || t(customerField.translationKey)} />
                        <SimpleListItemContent>
                          <ul className='ta-list-inline'>
                            {(customerField.type &&
                              <li>
                                <FontAwesome5 icon='sliders-h' />
                                {t(`customerFields.fieldType.${customerField.type.toLowerCase()}`)}
                              </li>
                            )}
                            {(!customerField.isAfterAuth
                              ? <li><FontAwesome5 icon='check-square' type='solid' /> {t('global.before')}</li>
                              : <li><FontAwesome5 icon='square' type='solid' /> {t('global.after')}</li>
                            )}
                            {(customerField.hasOverwrite
                              ? <li><FontAwesome5 icon='check-square' type='solid' /> {t('global.override')}</li>
                              : <li><FontAwesome5 icon='square' type='solid' /> {t('global.keepCopies')}</li>
                            )}
                          </ul>
                        </SimpleListItemContent>
                      </SimpleListItem>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomerFieldsMock
