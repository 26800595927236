import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { TwoFACodeForm } from '../../Beauties'

const TwoFACodeFormWrapper = props => {
  let { form, onAccountSelect, pending } = props
  form = form || {}
  const { code } = form

  useEffect(() => {
    handlers.twoFAFormPopulate()
    handlers.loginPendingDefaultSet()
  }, [])

  const onSubmit = () => {
    onAccountSelect(code.value)
  }

  return (
    <TwoFACodeForm code={code && code.value} onSubmit={onSubmit} pending={pending} />
  )
}

TwoFACodeFormWrapper.propTypes = {
  onAccountSelect: PropTypes.func.isRequired,
  pending: PropTypes.bool
}

const maps = state => ({
  form: state.forms.twoFA,
  pending: state.auth.pendingForm
})

export default connect(maps)(TwoFACodeFormWrapper)
