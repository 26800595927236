
import React from 'react'
import { connect } from '../../Store'
import { BranchesGroupFormReminders } from '../../Beauties'

const BranchesPreviewGroupFormRemindersWrapper = props => {
  let {
    plan,
    courseCustomReminderCustomerSwitch,
    courseReminderCustomerSwitch,
    customerEmailRemindersMinutes,
    courseCustomReminderStaffSwitch,
    courseReminderStaffSwitch,
    courseReminderStaffOthersCheckBox,
    courseReminderStaffOthers,
    resourceEmailRemindersMinutes,
    allowedSet
  } = props
  plan = plan || 'CLASSIC'
  courseReminderStaffOthers = courseReminderStaffOthers || []

  return (
    <BranchesGroupFormReminders
      plan={plan}
      allowedSet={allowedSet}
      courseCustomReminderCustomerSwitch={courseCustomReminderCustomerSwitch}
      courseReminderCustomerSwitch={courseReminderCustomerSwitch}
      customerEmailRemindersMinutes={customerEmailRemindersMinutes}
      courseCustomReminderStaffSwitch={courseCustomReminderStaffSwitch}
      courseReminderStaffSwitch={courseReminderStaffSwitch}
      courseReminderStaffOthersCheckBox={courseReminderStaffOthersCheckBox}
      courseReminderStaffOthers={courseReminderStaffOthers}
      resourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
    />
  )
}

const maps = state => ({
  courseCustomReminderCustomerSwitch: state.forms.branchesGroup.courseCustomReminderCustomerSwitch && state.forms.branchesGroup.courseCustomReminderCustomerSwitch.value,
  courseReminderCustomerSwitch: state.forms.branchesGroup.courseReminderCustomerSwitch && state.forms.branchesGroup.courseReminderCustomerSwitch.value,
  customerEmailRemindersMinutes: state.forms.branchesGroup.customerEmailRemindersMinutes && state.forms.branchesGroup.customerEmailRemindersMinutes.values,
  courseCustomReminderStaffSwitch: state.forms.branchesGroup.courseCustomReminderStaffSwitch && state.forms.branchesGroup.courseCustomReminderStaffSwitch.value,
  courseReminderStaffSwitch: state.forms.branchesGroup.courseReminderStaffSwitch && state.forms.branchesGroup.courseReminderStaffSwitch.value,
  courseReminderStaffOthersCheckBox: state.forms.branchesGroup.courseReminderStaffOthersCheckBox && state.forms.branchesGroup.courseReminderStaffOthersCheckBox.value,
  courseReminderStaffOthers: state.forms.branchesGroup.courseReminderStaffOthers && state.forms.branchesGroup.courseReminderStaffOthers.values,
  resourceEmailRemindersMinutes: state.forms.branchesGroup.resourceEmailRemindersMinutes && state.forms.branchesGroup.resourceEmailRemindersMinutes.values
})

export default connect(maps)(BranchesPreviewGroupFormRemindersWrapper)
