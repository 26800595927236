import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'

import './SimpleTabs.css'

class SimpleTabs extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.state = { active: this.props.active }
  }

  onChange (name) {
    const { multiple } = this.props
    this.setState({ active: name })
    !multiple && handlers.addHashToCurrentRoute(name)
  }

  render () {
    const { children, scrollableContent, active } = this.props
    const classNames = ['ta-simple-tabs']
    if (scrollableContent) classNames.push('scrollable-content')
    const context = {
      active: active,
      onChange: this.onChange
    }

    return (
      <SimpleTabsContext.Provider value={context}>
        <div className={classNames.join(' ')}>
          {children}
        </div>
      </SimpleTabsContext.Provider>
    )
  }
}

SimpleTabs.propTypes = {
  active: PropTypes.string,
  multiple: PropTypes.bool
}

export const SimpleTabsContext = React.createContext({
  active: false,
  onChange: () => {}
})

export default SimpleTabs
