import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import './Form.css'

class Form extends Component {
  constructor (props, context) {
    super(props)

    this.addRef = this.addRef.bind(this)
    this.removeRef = this.removeRef.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.components = []
    this.state = {
      formName: props.name,
      addRef: this.addRef,
      removeRef: this.removeRef
    }
  }

  addRef (component) {
    if (this.components.indexOf(component) === -1) this.components.push(component)
  }

  removeRef (component) {
    if (this.components.indexOf(component) > -1) this.components.filter(c => c !== component)
  }

  onSubmit (e) {
    e.preventDefault()
    const { onSubmit } = this.props
    onSubmit && onSubmit((errors = []) => {
      const focus = errors.length > 0 ? errors[0].key : ''
      const component = this.components.find(c => c.props && c.props.name === focus)
      if (component) {
        if (component.wrapper) ReactDOM.findDOMNode(this).parentNode.scrollTop = ReactDOM.findDOMNode(component.wrapper).offsetTop - 10
        if (component.ref) ReactDOM.findDOMNode(component.ref).focus()
      }
    })
  }

  render () {
    let { children, className, ...rest } = this.props
    let classNames = ['ta-form']
    if (className) classNames.push(className)

    return (
      <FormContext.Provider value={this.state}>
        <form ref={ref => { this.ref = ref }} {...rest} className={classNames.join(' ')} onSubmit={this.onSubmit}>
          {children}
        </form>
      </FormContext.Provider>
    )
  }
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export const FormContext = React.createContext({
  formName: '',
  addRef: () => { },
  removeRef: () => { }
})

export default Form
