import React, { useEffect } from 'react'
import { connect, handlers } from '../../Store'
import { Users, UsersMock } from '../../Beauties'
import { Loader } from '../../Common'
import { sortAlphabetically } from '../../Utils'

const UsersListWrapper = props => {
  let { areFetched, account } = props
  account = account || {}
  let { enterprisePermissions, isAdmin } = account || {}
  enterprisePermissions = enterprisePermissions || {}
  let { usersRead, usersWrite } = enterprisePermissions || {}
  usersRead = !!isAdmin || !!usersRead
  usersWrite = !!isAdmin || !!usersWrite
  useEffect(() => {
    if (!areFetched && usersRead) handlers.usersListGet()
    return () => {
      handlers.formSet('usersFilters', {})
    }
  }, [areFetched, usersRead])
  let { users, pending, filter, userPermissionTypes } = props
  users = users || []
  userPermissionTypes = userPermissionTypes || []
  pending = usersRead && !!pending
  filter = filter || {}
  let { search, userGroup } = filter || {}
  search = search || {}
  userGroup = userGroup || {}
  let { value: searchValue } = search || {}
  const { value: userGroupValue } = userGroup || {}
  searchValue = (searchValue || '').toString().toLowerCase()
  let filteredUsers = users || []
  if (searchValue || userGroupValue) {
    filteredUsers = users.filter(user => {
      let { name, id, roles, enterprisePermissionsGroupId } = user
      roles = roles || []
      return (
        (name.toLowerCase().includes(searchValue) || id === searchValue) &&
        (!userGroupValue || (enterprisePermissionsGroupId === userGroupValue && !roles.includes(userGroupValue)) || (!enterprisePermissionsGroupId && roles.includes(userGroupValue)))
      )
    }) || []
  }
  const sortedAndFilteredUsers = filteredUsers.sort(sortAlphabetically)

  return (
    pending
      ? <Loader active />
      : usersRead
        ? (
          <Users
            users={sortedAndFilteredUsers}
            userPermissionTypes={userPermissionTypes}
            search={searchValue}
            usersWrite={usersWrite}
          />
          )
        : <UsersMock />
  )
}

const maps = state => ({
  pending: state.users.pendingList,
  users: state.users.list,
  filter: state.forms.usersFilters,
  userPermissionTypes: state.userPermissions.list,
  areFetched: state.users.areFetched,
  account: state.account
})

export default connect(maps)(UsersListWrapper)
