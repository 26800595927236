import {
  loginFormInitialValues,
  loginSaveTransform,
  loginSSOFormInitialValues
} from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: false,
  pendingOneTimeToken: false,
  isConnected: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  connectionChange: isConnected => dispatch(actions.CONNECTION_CHANGE, isConnected),
  logout: (noRedirect = false) => dispatch(actions.LOGOUT, { noRedirect }),
  loginWithSSO: (form, scrollToError = () => { }) => dispatch(actions.LOGIN_WITH_SSO, { form, scrollToError }),
  loginWithEmailAndPassword: (form, scrollToError = () => {}) => dispatch(actions.LOGIN_WITH_EMAIL_AND_PASSWORD, { fields: loginSaveTransform(form), scrollToError }),
  loginWithOneTimeToken: token => dispatch(actions.LOGIN_WITH_ONE_TIME_TOKEN, token),
  loginFormReady: () => dispatch(actions.LOGIN_FORM_READY),
  loginFormPopulate: () => {
    handlers.formSet('login', loginFormInitialValues())
    handlers.loginFormReady()
  },
  loginSSOFormPopulate: () => {
    handlers.formSet('sso', loginSSOFormInitialValues())
    handlers.loginFormReady()
  },
  authTokensChange: tokens => dispatch(actions.AUTH_TOKENS_CHANGE, tokens),
  authTokensPopulate: ({ accessToken, refreshToken, expires }) => dispatch(actions.AUTH_TOKENS_POPULATE, { accessToken, refreshToken, expires }),
  loginWith2FA: (code, callback) => dispatch(actions.AUTH_TWO_FA, { code, callback }),
  loggedInWith2FA: () => dispatch(actions.AUTH_LOGGED_IN_WITH_TWO_FA),
  loginPendingDefaultSet: () => dispatch(actions.LOGIN_PENDING_DEFAULT_SET)
})

reducer.CONNECTION_CHANGE = (state, isConnected) => ({
  ...state,
  isConnected: !!isConnected
})

reducer.LOGOUT = (state) => ({
  ...state,
  pendingForm: false,
  tokens: null
})

reducer.LOGIN_WITH_SSO = (state) => ({
  ...state,
  pendingForm: false
})

reducer.LOGIN_WITH_EMAIL_AND_PASSWORD = state => ({
  ...state,
  pendingForm: true
})

reducer.LOGIN_WITH_ONE_TIME_TOKEN = state => ({
  ...state,
  pendingOneTimeToken: true
})

reducer.LOGIN_FORM_READY = state => ({
  ...state,
  pendingForm: false,
  pendingOneTimeToken: false
})

reducer.AUTH_TOKENS_CHANGE = state => ({
  ...state,
  pendingOneTimeToken: false
})

reducer.AUTH_TOKENS_POPULATE = (state, tokens) => ({
  ...state,
  tokens
})

reducer.AUTH_TWO_FA = state => ({
  ...state,
  pendingForm: true
})

reducer.AUTH_LOGGED_IN_WITH_TWO_FA = state => ({
  ...state
})

reducer.LOGIN_PENDING_DEFAULT_SET = state => ({
  ...state,
  pendingForm: false,
  pendingOneTimeToken: false
})

export default reducer
