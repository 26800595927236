import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, store, handlers, globalActions } from '../../Store'
import { CustomerPreviewBookings } from '../../Beauties'
import { sortBy } from '../../Utils'

const CustomerPreviewBookingsWrapper = props => {
  const {
    id,
    resources,
    customer,
    isBookingsLastPage,
    companyTimezone,
    calendarTimezone,
    timezones,
    pending,
    hash,
    messageBookings,
    message,
    bookingsType,
    ...rest
  } = props
  const [initial] = useState({ id })

  // ComponentDidMount
  useEffect(() => {
    const { id } = initial
    handlers.customerEventFormGet()
    handlers.customerBookingsGet(id)
  }, [initial])
  const showBookingOnCalendar = (bookingId, date, resourceIds) => {
    resourceIds = resourceIds || []
    const state = store.getState()
    const { calendar } = state
    const { resourcesDaily } = calendar
    const allResources = [...resourceIds, ...resourcesDaily.filter(item => !resourceIds.includes(item))]
    handlers.formFieldsUpdate('calendarDailySettings', { resources: { value: allResources } })
    setTimeout(() => handlers.navigateToPath(`/?view=daily&date=${date}`), 1500)
  }

  const loadMoreBookings = () => {
    !pending && handlers.customerBookingsPageIncrement()
  }
  const timezone = calendarTimezone || companyTimezone
  const bookings = []
  const events = customer.events || []
  events.forEach(event => {
    event.intervals && event.intervals.forEach(interval => {
      const booking = {
        ...event,
        interval,
        batchCount: (!!event.isBatch && event.durationsPattern && (event.durationsPattern.length + 1) / 2) || 0,
        from: interval.from,
        resources: resources.filter(item => event.resourceIds.includes(item.id)) || [],
        dataFields: globalActions
          .prepareCustomerFieldsWithValues(event.fields)
          .filter(item =>
            item.value !== null &&
            item.value !== ''
          ) || []
      }

      delete booking.intervals
      bookings.push(booking)
    })
  })
  const getCustomerBookings = () => {
    const { id } = initial
    handlers.customerBookingsGet(id)
  }
  const onClickPrintBookings = () => {
    handlers.customerBookingsPrint()
  }

  return (
    <CustomerPreviewBookings
      bookings={bookings.sort(sortBy('from'))}
      hasMoreBookings={false} // {!isBookingsLastPage}
      loadMoreBookings={loadMoreBookings}
      timezone={timezone}
      hash={hash}
      timezones={timezones}
      pending={pending}
      showBookingOnCalendar={showBookingOnCalendar}
      message={message}
      messageBookings={messageBookings}
      onClickPrintBookings={onClickPrintBookings}
      getCustomerBookings={getCustomerBookings}
      bookingsType={bookingsType}
      resources={resources}
      {...rest}
    />
  )
}

CustomerPreviewBookingsWrapper.propTypes = {
  isBookingsLastPage: PropTypes.bool,
  pending: PropTypes.bool
}

const maps = state => ({
  id: (state.router.data && state.router.data.id) || '',
  isBookingsLastPage: state.customers.isBookingsLastPage || null,
  resources: state.resources.list || [],
  customer: state.customers.selected || {},
  pending: state.customers.pendingBookings || false,
  companyTimezone: state.company.timezone || '',
  calendarTimezone: (state.calendar && state.calendar.timezone) || '',
  timezones: state.staticData.timezones || [],
  message: state.customers.messagePreviewBookings || null,
  hash: state.router.hash || '',
  messageBookings: state.customers.messagePreviewBookings || null,
  bookingsType: (state.forms.customerEvents && state.forms.customerEvents.type && state.forms.customerEvents.type.value) || null
})

export default memo(connect(maps)(CustomerPreviewBookingsWrapper))
