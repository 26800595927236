import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { CoursePreviewAdvanced } from '../../Beauties'

const CoursePreviewAdvancedWrapper = props => {
  let {
    course,
    company
  } = props
  course = course || {}
  company = company || {}
  const { settings } = company
  const { resourceRemindersEmailRecipients, customerEmailRemindersMinutes, resourceEmailRemindersMinutes } = settings || {}

  return (
    <CoursePreviewAdvanced
      {...course}
      companyCustomerEmailRemindersMinutes={customerEmailRemindersMinutes}
      companyResourceEmailRemindersMinutes={resourceEmailRemindersMinutes}
      resourceRemindersEmailRecipients={resourceRemindersEmailRecipients}
    />
  )
}

CoursePreviewAdvancedWrapper.propTypes = {
  course: PropTypes.object.isRequired,
  plan: PropTypes.string
}

const maps = state => ({
  company: state.company
})

export default connect(maps)(CoursePreviewAdvancedWrapper)
