import React from 'react'
import { SimpleListItem, FontAwesome5, Alert, t, Button } from '../../Common'

const CoursesToDelete = props => {
  let { coursesToDeleteData, onDeleteGlobalEntity, globalGroupsWrite } = props
  coursesToDeleteData = coursesToDeleteData || []

  return (
    <div className='ta-courses-to-delete'>
      {(coursesToDeleteData.length > 0 &&
        <Alert
          label={t('course.list.delete.pendingDeletion')}
          theme='alert'
        >
          {t('course.list.delete.conflict.message')}
        </Alert>
      )}
      {coursesToDeleteData.map(item => {
        let { branch = {}, course = {} } = item
        branch = branch || {}
        course = course || {}
        return (
          <SimpleListItem className='ta-courses-to-delete--branch' key={course.id}>
            <div className='ta-courses-to-delete--branch__data'>
              <p className='ta-courses-to-delete--branch__name'>{branch.name}</p>
              <div className='ta-courses-to-delete--item'>
                <FontAwesome5 icon='hashtag' type='s' /> <p>{branch.id}</p>
              </div>
              <div className='ta-courses-to-delete--item'>
                <FontAwesome5 icon='external-link-square' type='s' /> <p>{course.id}</p>
              </div>
            </div>
            {(globalGroupsWrite &&
              <div className='ta-courses-to-delete--branch__buttons'>
                <Button onClick={() => onDeleteGlobalEntity(branch.id, branch.region, course.externalId, course.id)}>{t('global.delete')}</Button>
              </div>
            )}
          </SimpleListItem>
        )
      })}
      {(!coursesToDeleteData.length &&
        <Alert noOffset label={t('course.list.delete.noCourses')} theme='no-content' />
      )}
    </div>
  )
}

export default CoursesToDelete
