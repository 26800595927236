import React from 'react'
import {
  SimpleTabsHeaderItem,
  FixedContentHeader,
  SimpleTabsBodyItem,
  HoverPopupTrigger,
  HoverPopupContent,
  FixedContentBody,
  SimpleTabsHeader,
  SimpleTabsBody,
  FixedContent,
  SimpleTabs,
  HoverPopup,
  Alert,
  Title,
  Button,
  Blur,
  t
} from '../../Common'
import { PopupTriggerWrapper, CustomerFieldCategoryPreviewChangedLocallyWrapper } from '../../Beasts'
import { CustomerFieldCategoryPreviewDetails } from '../../Beauties'

import './CustomerFieldCategoryPreview.css'

const CustomerFieldCategoryPreview = props => {
  const {
    category,
    hash,
    message,
    enterpriseId,
    hasCustomerFields,
    globalDataFieldsRead,
    globalDataFieldsWrite,
    globalDataFieldsDelete,
    allowOnlyGlobalCustomers
  } = props
  const availableTabs = ['details']
  if (!allowOnlyGlobalCustomers) availableTabs.push('changedLocally')
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const classNames = ['ta-customer-fields__preview']
  const { id, internalId, isUpdatedLocally, isGloballyDeleted } = category || {}
  const deleteBtnClassNames = ['ta-btn', 'ta-btn-delete']
  if (hasCustomerFields) deleteBtnClassNames.push('ta-customer-fields__category__delete-btn')
  const isGlobal = enterpriseId && internalId
  const edited = isGlobal && isUpdatedLocally
  if (edited) classNames.push('edited global')
  if (isGloballyDeleted) classNames.push('marked-for-deletion global')
  const shouldUseTranslation = category.translationKey && !category.name
  const categoryName = shouldUseTranslation ? t(category.translationKey) : category.name

  return (
    <div className={classNames.join(' ')}>
      <FixedContent>
        <FixedContentHeader>
          <Title label={categoryName}>
            {(globalDataFieldsWrite && category.id &&
              <>
                {(globalDataFieldsDelete && !category.isDefault &&
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {(hasCustomerFields
                        ? t('customerFields.form.category.notEmpty')
                        : t('customerFields.form.category.delete')
                      )}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <PopupTriggerWrapper name='customer-field-category-delete' position='bottom' id={category.id} disable={hasCustomerFields}>
                        <Button as='link' className={deleteBtnClassNames.join(' ')} external icon='trash-alt' />
                      </PopupTriggerWrapper>
                    </HoverPopupTrigger>
                  </HoverPopup>
                )}
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('customerFields.form.category.edit')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Button as='link' to={`/customers/customer-fields/categories/${category.id}/edit`} isPrimary icon='pencil' />
                  </HoverPopupTrigger>
                </HoverPopup>
              </>
            )}
          </Title>
        </FixedContentHeader>
        <FixedContentBody>
          <Blur extraClassName={globalDataFieldsRead ? 'inactive' : ''} active={!globalDataFieldsRead} icon='ban' label={t('global.accessDenied')}>
            {(message &&
              <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
                {message.text && t(message.text)}
              </Alert>
            )}
            {(edited && !isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.locallyEditted')}>
                <p>{t('globalEntities.preview.locallyEdittedWarning')}</p>
                <PopupTriggerWrapper name='customer-fields-reset-global-entity' position='right' id={{ internalId }}>
                  <Button as='link' isBlock isPrimary className='ta-btn ta-btn-reset-global' external>
                    {t('mobileApp.customers.filter.buttonReset.label')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(isGloballyDeleted &&
              <Alert theme='alert' label={t('globalEntities.markedForDelete')}>
                <p>{t('globalEntities.preview.removedFromDatabase')}</p>
                <PopupTriggerWrapper name='customer-fields-delete-global-entity' position='right' id={id}>
                  <Button as='link' isBlock isPrimary className='ta-btn ta-btn-delete-global' external>
                    {t('globalEntities.delete')}
                  </Button>
                </PopupTriggerWrapper>
              </Alert>
            )}
            {(category.id && !message &&
              <SimpleTabs active={activeTab} scrollableContent>
                <SimpleTabsHeader>
                  <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsHeaderItem name='changedLocally' icon='pencil' label={t('global.changedLocally')} />
                  )}
                </SimpleTabsHeader>
                <SimpleTabsBody className={!globalDataFieldsRead ? 'static' : ''}>
                  <SimpleTabsBodyItem name='details'>
                    <CustomerFieldCategoryPreviewDetails category={category} />
                  </SimpleTabsBodyItem>
                  {(!allowOnlyGlobalCustomers &&
                    <SimpleTabsBodyItem name='changedLocally' renderOnlyIfActive>
                      <CustomerFieldCategoryPreviewChangedLocallyWrapper />
                    </SimpleTabsBodyItem>
                  )}
                </SimpleTabsBody>
              </SimpleTabs>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default CustomerFieldCategoryPreview
